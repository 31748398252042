import React from 'react';
import {defineMessages} from 'react-intl';

import {IModuleGridItem} from '@components/data-grid';
import {DataGridEntityColDef, DataGridEntityConfigFactory, renderCellHeader} from '@components/data-grid/mui';

import {PageViewViewModel} from '../types';

import {PageViewers} from './PageViewers';

const localizedHeaders = defineMessages({
    pageViewers: {
        id: 'pageViewersDataGrid_activeViewers',
        defaultMessage: 'Active Viewers',
    },
});

export type RecordWithPageViewers = IModuleGridItem & {
    page_viewers: PageViewViewModel[];
};

export type RecordWithPageViewersKeys = keyof Pick<RecordWithPageViewers, 'page_viewers'>;

export class DataGridPageViewersFactory extends DataGridEntityConfigFactory<RecordWithPageViewersKeys, RecordWithPageViewers> {
    getColumnConfig(): Partial<Record<RecordWithPageViewersKeys, DataGridEntityColDef<RecordWithPageViewersKeys, RecordWithPageViewers>>> {
        return {
            page_viewers: {
                field: 'page_viewers',
                localizedHeaderName: localizedHeaders.pageViewers,
                renderHeader: renderCellHeader,
                flex: 0.2,
                width: 200,
                sortable: false,
                valueGetter: p => this.getPageViewers(p.row),
                renderCell: p => (
                    <PageViewers
                        users={this.getPageViewers(p.row)
                            ?.filter(u => u?.email)
                            .map(u => ({
                                email: u.email,
                                id: u.id,
                                lastOpenedAtSeconds: u.lastOpenedAt?.seconds,
                                name: `${u.firstName} ${u.lastName}`,
                                username: u.email,
                            }))}
                    />
                ),
            },
        };
    }

    getPageViewers(row: RecordWithPageViewers): PageViewViewModel[] {
        return row?.page_viewers || [];
    }
}
