import {ChipType, ChipVariant} from '@components/chip/types';
import {NoteType} from '@models/generated/graphql';

import {localizedNoteType} from '../../../features/app/intl/shared-resources/notetype';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export const getNoteTypeChipsMapping = (chipType: ChipType) => ({
    [NoteType.Global]: {
        label: localizedNoteType[NoteType.Global],
        chipType,
        chipVariant: ChipVariant.DropdownDefault,
    },
    [NoteType.Request]: {
        label: localizedNoteType[NoteType.Request],
        chipType,
        chipVariant: ChipVariant.DropdownDefault,
    },
});
