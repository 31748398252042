import React, {memo} from 'react';
import EmailIcon from '@mui/icons-material/Email';
import {GridCellParams} from '@mui/x-data-grid';

import {OutlinedButton} from '@components/button/Buttons';
import {DropdownItem} from '@components/dropdown/DropdownItem';
import {Icon} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {UserProfile} from '@models/generated/graphql';

import {EditableColDef} from 'src/features/module-temp-storage/components/editable-components/types';
import {PlayerProfileGridItem} from '../../../../pages/registration-info/types';
import {EditableButton, EditableButtonType} from '../../../module-temp-storage/components/editable/EditableButton';
import {EditableModalProps, withEditableModal} from '../../../module-temp-storage/components/editable/EditableModalHoc';
import {getEditDisabledState, getGridRowData} from '../../../module-temp-storage/components/editable/utils';
import {MessageType} from '../../../module-temp-storage/types';
import {playerMessageActions} from '../../../player-message/actions';
import {defaultMessage, Message} from '../../../player-message/types';
import {ActionCenterModalTitle} from '../../common/ActionModalTitle';
import {localizedCommunicationActions} from '../localize';

import {SendMessageForm} from './SendMessageForm';

const SendMessage = ({disabled, onClick}: EditableModalProps<UserProfile, Message, Message>) => {
    return <EditableButton type={EditableButtonType.ICON} iconClassName={CustomIcon.SmsOutline} disabled={disabled} onClick={onClick} />;
};

const SendMessageOutlinedButton = ({disabled, onClick}: EditableModalProps<UserProfile, Message, Message>) => {
    return (
        <OutlinedButton
            fullWidth={true}
            label={localizedCommunicationActions.sendMessageToPlayer}
            startIcon={<Icon icon={CustomIcon.SmsOutline} />}
            onClick={onClick}
            disabled={disabled}
            data-testid="send-message-action"
        />
    );
};

const SendMessageMenuItem = ({disabled, onClick}: EditableModalProps<UserProfile, Message, Message>) => {
    return (
        <DropdownItem disabled={disabled} icon={<EmailIcon />} onClick={onClick} label={localizedCommunicationActions.sendMessageButton} />
    );
};

export const EditableSendMessage = memo(withEditableModal(SendMessage, SendMessageForm));
export const EditableSendMessageMenuItem = memo(withEditableModal(SendMessageMenuItem, SendMessageForm));
export const SendMessageOutlinedButtonAction = withEditableModal(SendMessageOutlinedButton, SendMessageForm, true);

export const withSendMessageCell =
    (
        WrappedComponent: React.ComponentType<EditableModalProps<UserProfile, Message, Message>>,
        messageType: MessageType,
        isReadonly?: (model: PlayerProfileGridItem) => boolean
    ) =>
    (params: GridCellParams) => {
        const {model, field, id, typeName} = getGridRowData<PlayerProfileGridItem>(params);
        const isEditable = (params.colDef as EditableColDef)?.isEditable;
        const isEditDisabled = getEditDisabledState(model, isEditable, isReadonly);

        return (
            <WrappedComponent
                value={defaultMessage}
                action={playerMessageActions.sendMessage}
                messageType={messageType}
                disabled={isEditDisabled}
                field={field}
                typeName={typeName}
                id={id}
                model={model}
                title={<ActionCenterModalTitle label={localizedCommunicationActions.sendMessageModalTitle} model={model} />}
                submitLabel={localizedCommunicationActions.sendButton}
                getSubmitValue={value => value}
            />
        );
    };

type SendMessageActionProps = {
    model: UserProfile;
    id: string;
    field: string;
    disabled?: boolean;
};

export const SendMessageAction = ({model, field, id, disabled}: SendMessageActionProps) => {
    return (
        <SendMessageOutlinedButtonAction
            value={defaultMessage}
            action={playerMessageActions.sendMessage}
            messageType={MessageType.ModuleMessage}
            disabled={disabled}
            field={field}
            typeName="send-message-action"
            id={id}
            model={model}
            title={<ActionCenterModalTitle label={localizedCommunicationActions.sendMessageModalTitle} model={model} />}
            submitLabel={localizedCommunicationActions.sendButton}
            getSubmitValue={value => value}
        />
    );
};
