import {BonusCodeHistory} from '@models/generated/graphql';

import {protectEpics} from '../../features/app/error-handling/epics';
import {BeBonusHistoryGridItem, BeBonusHistoryItem} from '../../features/be-bonus-history/types';
import {createGridEpics} from '../../features/module-shared/epics';
import {createGridSubscriberRealtimeEpics} from '../../features/realtime-updates/epics';
import {SubscriptionType} from '../../features/realtime-updates/types';

import {localized} from './localized';
import {columnsSelector, filterSelector, itemsSelector} from './selectors';
import {domain} from './types';

export default protectEpics(
    createGridEpics<BeBonusHistoryItem, BeBonusHistoryGridItem>(domain, c => c.beBonusHistoryService, filterSelector, columnsSelector),
    createGridSubscriberRealtimeEpics<BonusCodeHistory>(
        {realtimeKey: domain, domain, name: localized.bonusEngineDetails},
        itemsSelector,
        u => u.id,
        [SubscriptionType.Added],
        container => container.beBonusHistoryService,
        filterSelector
    )
);
