import React from 'react';
import {Box} from '@mui/material';

import {CustomIcon, Icon, IconColor} from '@components/icons';
import StyledTooltip from '@components/Tooltip';

import {useClasses} from './InfoMessageHoc.style';

export type WithInfoMessageProps = {
    infoMessage?: JSX.Element;
    showInfoMessage?: boolean;
};

export const withInfoMessage =
    <TProps extends object & WithInfoMessageProps>(WrappedComponent: React.ComponentType<TProps>) =>
    ({infoMessage, showInfoMessage, ...props}: TProps) => {
        const {classes} = useClasses();

        return (
            <Box className={classes.infoMessageContainer}>
                {infoMessage && showInfoMessage ? (
                    <StyledTooltip
                        element={
                            <Box>
                                <Icon color={IconColor.Secondary} icon={CustomIcon.WarningOutline} className={classes.infoMessageIcon} />
                            </Box>
                        }
                        title={<Box>{infoMessage}</Box>}
                    />
                ) : null}
                <Box className={classes.infoMessage}>
                    <WrappedComponent {...(props as TProps)} />
                </Box>
            </Box>
        );
    };
