import {defineMessages} from 'react-intl';

import {withUserProfileTextField} from '../../player-actions/pii-actions/components/UserProfileTextField';
import {addressInfoActions} from '../actions';

const localized = defineMessages({
    piiAddressStreetFieldName: {
        id: 'piiAddressStreetFieldName',
        defaultMessage: 'Street Address',
    },
});

export const UserProfileAddress = withUserProfileTextField({
    action: addressInfoActions.editAddressStreet,
    placeholder: localized.piiAddressStreetFieldName,
    submitModelMapper: m => ({
        fieldValue: m?.user?.address?.address,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            address: {
                address: m?.fieldValue as string,
            },
        },
        userId: m?.userId,
    }),
    isRequired: true,
});
