import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useAutocompleteChipClasses = makeStyles()((theme: CustomTheme) => ({
    autocompleteChipPreviouslyAssignedItemBadge: {
        '&.MuiBadge-root': {
            marginRight: theme.spacing(0.75),
        },
        '& .MuiBadge-anchorOriginTopRightRectangular': {
            position: 'relative',
            top: `${theme.spacing(1.1)} !important`,
            background: theme.palette.background.paper,
            width: theme.custom.chipDeleteIconSize,
            height: theme.custom.chipDeleteIconSize,
        },
    },
    autocompleteChipPreviouslyAssignedItemChip: {
        marginRight: theme.spacing(0.75),
    },
}));
