import React, {createRef} from 'react';
import {MessageDescriptor} from 'react-intl';
import CachedIcon from '@mui/icons-material/Cached';
import {GridCellParams} from '@mui/x-data-grid';

import {Button, IconButton, SquareIconButton} from '@components/button/Buttons';
import {IModuleGridItem} from '@components/data-grid';
import {DropdownItem} from '@components/dropdown/DropdownItem';
import {CustomIcon} from '@components/icons/types';

import {MessageType, PatchAsyncAction} from '../../types';
import {EditableColDef} from '../editable-components/types';
import {ErrorPopover, InfoPopover} from '../error/MessagePopover';

import {useTempStorageUpdateState} from './hooks';
import {getEditDisabledState, getGridRowData} from './utils';

export enum EditableButtonType {
    EDIT = 'Edit',
    MODAL = 'Modal',
    SUBMIT = 'Submit',
    DROPDOWN_SUBMIT = 'DropdownSubmit',
    ICON = 'Icon',
}

export type EditableButtonProps<T = null> = {
    type?: EditableButtonType;
    label?: MessageDescriptor;
    isUpdateInProgress?: boolean;
    id?: string;
    field?: string;
    typeName?: string;
    disabled: boolean;
    action?: PatchAsyncAction;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    value?: T;
    messageType?: MessageType;
    iconClassName?: string;
    'data-testid'?: string;
};

export const EditableButton = <T,>({
    action,
    field,
    id,
    typeName,
    disabled,
    type,
    label,
    onClick,
    value,
    messageType,
    iconClassName,
}: EditableButtonProps<T>) => {
    let result = <></>;
    const boxRef = createRef<HTMLButtonElement>();
    const {isUpdateInProgress, update, storageKey} = useTempStorageUpdateState<T>(action, field, id, typeName, messageType);

    switch (type) {
        case EditableButtonType.EDIT:
            if (isUpdateInProgress) {
                result = (
                    <IconButton disabled>
                        <CachedIcon />
                    </IconButton>
                );
            } else if (!disabled) {
                result = <IconButton data-testid="editButton" iconClassName={CustomIcon.WriteOutline} onClick={onClick}></IconButton>;
            }
            break;
        case EditableButtonType.MODAL:
            if (!disabled) {
                result = <Button label={label} onClick={onClick} />;
            }
            break;
        case EditableButtonType.ICON:
            if (!disabled) {
                result = <SquareIconButton iconClassName={iconClassName} onClick={onClick}></SquareIconButton>;
            }
            break;
        case EditableButtonType.SUBMIT:
            if (!disabled) {
                const submit = () => update(value);
                result = (
                    <>
                        <Button label={label} onClick={submit} ref={boxRef} />
                        <ErrorPopover ref={boxRef} messageKey={storageKey} />
                        <InfoPopover ref={boxRef} messageKey={storageKey} />
                    </>
                );
            }
            break;
        case EditableButtonType.DROPDOWN_SUBMIT:
            if (!disabled) {
                const submit = () => update(value);
                result = <DropdownItem onClick={submit} label={label} />;
            }
            break;
        default:
            break;
    }

    return result;
};

export interface EditableCellParams extends GridCellParams {
    isDropdown?: boolean;
    dropdownIndex?: number;
}

type RenderEditableButtonProps<T> = {
    label: MessageDescriptor;
    clickAction: PatchAsyncAction;
    messageType: MessageType;
    editableButtonType?: EditableButtonType;
    iconClassName?: string;
    isReadonly?: (model: T) => boolean;
};

export const renderEditableButton =
    <T extends IModuleGridItem>({
        label,
        clickAction,
        messageType,
        editableButtonType,
        iconClassName,
        isReadonly,
    }: RenderEditableButtonProps<T>) =>
    (params: EditableCellParams) => {
        const {model, field, defaultValue, id, typeName} = getGridRowData<T>(params);
        const isEditable = (params.colDef as EditableColDef)?.isEditable;
        const isEditDisabled = getEditDisabledState(model, isEditable, isReadonly);
        let defaultValueTemp = defaultValue;

        if (params.isDropdown) {
            defaultValueTemp = defaultValue[params.dropdownIndex];
        }

        let buttonType = params.isDropdown ? EditableButtonType.DROPDOWN_SUBMIT : EditableButtonType.SUBMIT;

        if (editableButtonType) buttonType = editableButtonType;

        return (
            <EditableButton
                type={buttonType}
                label={label}
                disabled={isEditDisabled}
                field={field}
                id={id}
                typeName={typeName}
                action={clickAction}
                value={defaultValueTemp}
                messageType={messageType}
                iconClassName={iconClassName}
            />
        );
    };
