import moment from 'moment-timezone';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {saveState} from '@storage/localStorage';
import {getJurisdiction, setMomentRelativeThresholds} from '@utils';

import {requestEpic} from 'src/common/epics';
import {globalConfig} from '../../../configuration';
import {protectEpics} from '../error-handling/epics';
import {loadLocalizationResourcesAction} from '../intl/actions';
import {locationSearchSelector} from '../routing/selectors';

import {loadJurisdictionConfigAction, setJurisdictionLocaleAction, setJurisdictionTimezoneAction} from './actions';
import {Jurisdiction} from './types';

const loadJurisdictionEpic: RootEpic = requestEpic(loadJurisdictionConfigAction, (_action, state, dependencies) => {
    const {jurisdiction: configJurisdiction} = globalConfig.getConfig();
    const search = locationSearchSelector(state);
    const urlJurisdiction = getJurisdiction(search) as Jurisdiction;
    return dependencies.jurisdictionConfigService.getConfigurationObservable(urlJurisdiction ?? configJurisdiction);
});

const jurisdictionLoadedEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(loadJurisdictionConfigAction.success)),
        mergeMap(action => {
            const locale = state$.value.jurisdictionConfig.locale ?? action.payload.locale;
            return of(setJurisdictionTimezoneAction(action.payload.timezone), setJurisdictionLocaleAction(locale));
        })
    );

const setTimezoneEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(setJurisdictionTimezoneAction)),
        mergeMap(action => {
            moment.tz.setDefault(action.payload);
            setMomentRelativeThresholds();
            return of();
        })
    );

const setLocaleEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(setJurisdictionLocaleAction)),
        mergeMap(action => {
            moment.locale(action.payload);
            saveState(state$.value);
            return of(loadLocalizationResourcesAction(action.payload));
        })
    );

const appConfigEpics = protectEpics(loadJurisdictionEpic, jurisdictionLoadedEpic, setTimezoneEpic, setLocaleEpic);

export default appConfigEpics;
