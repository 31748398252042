import {defineMessages} from 'react-intl';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {fileActions} from '@file/actions';
import {CsvDocumentBuilderAdapter} from '@file/services/CsvDocumentBuilderAdapter';
import {ServiceTypes} from '@inversify';
import {UserLogin} from '@models/generated/graphql';
import {mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {EntityFetchRequestPayload, EntityType, UserLoginQueryFields} from '@redux/entity';
import {UserLoginService} from '@services';
import {maxPageSize, ServerResponseStatus} from '@services/types';

import {showErrorAction} from '../message-snack-bar/actions';

import {userLoginActions} from './actions';
import {UserLoginDownloadModel, UserLoginViewModelKeys} from './types';

const localized = defineMessages({
    downloadFailure: {
        id: 'UserLoginActionsEpicsBuilder_failureMessage',
        defaultMessage: 'Error when downloading login history',
    },
    totalCountErrorMessage: {
        id: 'UserLoginActionsEpicsBuilder_totalCountErrorMessage',
        defaultMessage: 'Total count of downloaded items cannot be more than {maxPageSize}',
    },
});

@injectable()
export class UserLoginActionsEpicsBuilder extends BaseEpicsBuilder {
    private _fileAdapter: CsvDocumentBuilderAdapter<UserLoginViewModelKeys>;
    private _mapper: Mapper;
    private _userLoginService: UserLoginService;

    constructor(
        @inject(ServiceTypes.CsvFileAdapter) fileAdapter: CsvDocumentBuilderAdapter<UserLoginViewModelKeys>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.UserLoginService) userLoginService: UserLoginService
    ) {
        super();
        this._fileAdapter = fileAdapter;
        this._mapper = mapper;
        this._userLoginService = userLoginService;
    }

    protected buildEpicList(): Epic[] {
        return [this.buildDownloadEpic(), this.buildDownloadSuccessEpic(), this.buildDownloadFailureEpic()];
    }

    private buildDownloadEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(userLoginActions.download.request)),
                mergeMap(action => {
                    const {filter, fields, filename, worksheetKeys, headers} = action.payload;
                    const fetchPayload: EntityFetchRequestPayload<UserLoginQueryFields> = {
                        type: EntityType.UserLogin,
                        filter,
                        fields,
                    };
                    const failureObservable = of(userLoginActions.download.failure({message: localized.downloadFailure}));

                    return this._userLoginService.get(fetchPayload).pipe(
                        mergeMap(response => {
                            let result: Observable<unknown>;
                            if (response?.status === ServerResponseStatus.Success) {
                                if (response?.responsePayload?.total <= maxPageSize) {
                                    const worksheetData = response.responsePayload?.items?.map(i =>
                                        this._mapper.map(i, UserLogin, UserLoginDownloadModel)
                                    );
                                    result = this._fileAdapter.createFile(worksheetData, worksheetKeys, headers).pipe(
                                        map(arrayBuffer => userLoginActions.download.success({arrayBuffer, filename})),
                                        catchError(() => failureObservable)
                                    );
                                } else {
                                    result = of(
                                        userLoginActions.download.failure({
                                            message: localized.totalCountErrorMessage,
                                            values: {maxPageSize: maxPageSize.toString()},
                                        })
                                    );
                                }
                            } else {
                                result = failureObservable;
                            }
                            return result;
                        }),
                        catchError(() => failureObservable)
                    );
                })
            );
    }

    private buildDownloadSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(userLoginActions.download.success)),
                map(action => {
                    const {filename, arrayBuffer} = action.payload;
                    return fileActions.download({
                        file: new Blob([arrayBuffer]),
                        title: filename,
                        type: 'csv',
                    });
                })
            );
    }

    private buildDownloadFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(userLoginActions.download.failure)),
                map(({payload}) => showErrorAction(payload))
            );
    }
}
