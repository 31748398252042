import {Context, context, Span, SpanStatusCode, trace, Tracer} from '@opentelemetry/api';
import {injectable} from 'inversify';

import {ITracingService} from './ITracingService';

@injectable()
class TracingService implements ITracingService {
    getTracer(): Tracer {
        return trace.getTracer('admin-panel');
    }

    setSpanOnContext(span: Span) {
        const ctx: Context = parent ? trace.setSpan(context.active(), span) : undefined;

        return ctx;
    }

    endSpanOk(span: Span) {
        if (span) {
            span.setStatus({code: SpanStatusCode.OK});
            span.end();
        }
    }

    endSpanFailed(span: Span, message?: string) {
        if (span) {
            span.setStatus({code: SpanStatusCode.ERROR, message});
            span.addEvent(message);
            span.end();
        }
    }
}

export {TracingService};
