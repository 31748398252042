import React from 'react';
import {useSelector} from 'react-redux';
import {Box} from '@mui/material';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {GridColDef} from '@components/data-grid/mui';
import {ModuleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {Policy} from '@auth';

import {domain as entityDomain} from '../../../features/marketing-code/types';
import {withModule} from '../../../features/module-shared/components/ModuleHoc';
import ModulePage from '../../../features/module-shared/components/ModulePage';
import {useRealtimeUpdates} from '../../../features/realtime-updates/hooks';
import {SubscriptionType} from '../../../features/realtime-updates/types';
import {useMarketingBonusCodeData} from '../hooks';
import {selectedItemsSelector} from '../selectors';
import {domain} from '../types';

import config from './MarketingBonusCode.config';
import {localizedMarketingBonusCode} from './MarketingBonusCode.localize';
import {useMarketingBonusCodeClasses} from './MarketingBonusCode.styles';

const MarketingBonusCode = () => {
    const {classes} = useMarketingBonusCodeClasses();

    const gridData = useMarketingBonusCodeData();
    const selectedItems = useSelector(selectedItemsSelector);
    const {filters, columns, actions, defaultSorting} = config;

    useRealtimeUpdates(entityDomain, domain, [SubscriptionType.Added, SubscriptionType.Deleted]);

    return (
        <Box className={classes.marketingBonusCodeContainer} data-testid="marketingBonusCode">
            <ModulePage
                header={localizedMarketingBonusCode.marketingBonusCode}
                domain={domain}
                data={gridData}
                filters={filters}
                columns={columns as GridColDef[]}
                moduleActions={actions}
                alignActionsRight={false}
                checkboxSelection={true}
                selectedItems={selectedItems}
                defaultSorting={defaultSorting}
            />
        </Box>
    );
};

export const marketingBonusCodeResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'MarketingBonusCode',
    rules: new PoliciesAccessRule([new Policy(ModuleName.MarketingFunction, null, 'read')]),
});

export default withModule(MarketingBonusCode, [{moduleName: ModuleName.MarketingFunction, permissions: [PermissionEnum.Read]}]);
