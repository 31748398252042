import {GridSortModel} from '@mui/x-data-grid';
import {CallHistoryMethodAction, push, replace} from '@vs-centaurea/connected-react-router';
import {Location} from 'history';
import {createAction} from 'typesafe-actions';
import {PayloadAction} from 'typesafe-actions';

import {appendQuery} from '@utils';

import {Filter} from 'src/common/types';

const setFilter = '/setFilter';
const updateFilter = '/updateFilter';
const addFilters = '/addFilters';
const setDefaultSorting = '/setDefaultSorting';
const itemsFilter = '/itemsFilter';

export const filterActions = (domain: string) => ({
    setFilter: createAction(`${domain}${setFilter}`)<string>(),

    updateFilter: createAction(`${domain}${updateFilter}`)<string>(),

    addFilters: createAction(`${domain}${addFilters}`)<Filter[]>(),

    setDefaultSorting: createAction(`${domain}${setDefaultSorting}`)<GridSortModel>(),

    itemsFilter: createAction(`${domain}${itemsFilter}`)<Filter | Filter[]>(),
});

type UpdateFilterAction = CallHistoryMethodAction<[string, unknown?]> | PayloadAction<string, string>;

export const getFilterActions = (domain: string, updateSearchUrl: boolean) => {
    const getUpdateSearchFilterActions = (location: Location<unknown>, filter: string, shouldReplaceUrl = false): UpdateFilterAction[] => {
        const actions = filterActions(domain);
        const updateFilterActions: UpdateFilterAction[] = [];

        const updatedPath = appendQuery(location.pathname, filter);
        if (updateSearchUrl && `${location.pathname}${location.search}` !== updatedPath) {
            const updateQueryAction = shouldReplaceUrl ? replace(updatedPath) : push(updatedPath);
            updateFilterActions.push(updateQueryAction);
        }
        updateFilterActions.push(actions.setFilter(filter));
        return updateFilterActions;
    };

    return {getUpdateSearchFilterActions};
};
