import {defineMessages} from 'react-intl';

export const localizedNotificationHistoryDetailsModal = defineMessages({
    viewNotification: {
        id: 'userNotificationHistoryDetailsModal_viewNotification',
        defaultMessage: 'View Notification',
    },
    sender: {
        id: 'userNotificationHistoryDetailsModal_sender',
        defaultMessage: 'Sender',
    },
    date: {
        id: 'userNotificationHistoryDetailsModal_date',
        defaultMessage: 'Date',
    },
    close: {
        id: 'userNotificationHistoryDetailsModal_close',
        defaultMessage: 'Close',
    },
});
