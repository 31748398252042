import {Span} from '@opentelemetry/api';

const locationAttributesPrefix = 'location';
export const LocationAttributes = {
    LOCATION_PATHNAME: `${locationAttributesPrefix}.pathname`,
    LOCATION_SEARCH: `${locationAttributesPrefix}.search`,
    LOCATION_HOST: `${locationAttributesPrefix}.host`,
};

const userAttributesPrefix = 'auth.user';
export const UserAttributes = {
    USER_ID: `${userAttributesPrefix}.id`,
    USER_PERMISSIONS: `${userAttributesPrefix}.permissions`,
};

const httpAttributesPrefix = 'http';
const httpAttributesRequestPrefix = `${httpAttributesPrefix}.request`;
const httpAttributesResponsePrefix = `${httpAttributesPrefix}.response`;
export const HttpRequestAttributes = {
    HTTP_REQUEST_URL: `${httpAttributesRequestPrefix}.url`,
    HTTP_REQUEST_TYPE: `${httpAttributesRequestPrefix}.type`,
    HTTP_REQUEST_METHOD: `${httpAttributesRequestPrefix}.method`,
    HTTP_RESPONSE_ERROR: `${httpAttributesResponsePrefix}.error`,
    HTTP_RESPONSE_STATUS_CODE: `${httpAttributesResponsePrefix}.status_code`,
    HTTP_RESPONSE_TRACE_ID: `${httpAttributesResponsePrefix}.traceId`,
};

const reactPrefix = 'react';
export const ReactComponentAttributes = {
    REACT_COMPONENT: `${reactPrefix}.component`,
    REACT_COMPONENT_STACK: `${reactPrefix}.stack`,
};

export type TraceUser = {
    uid: string;
    permissions: string[];
};

export function applyLocationAttrs(span: Span) {
    span?.setAttributes({
        [LocationAttributes.LOCATION_PATHNAME]: window.location.pathname,
        [LocationAttributes.LOCATION_SEARCH]: window.location.search,
        [LocationAttributes.LOCATION_HOST]: window.location.host,
    });
}

export function applyUserAttrs(span: Span, user?: TraceUser) {
    if (user) {
        span?.setAttributes({
            [UserAttributes.USER_ID]: user?.uid,
            [UserAttributes.USER_PERMISSIONS]: user?.permissions?.join()?.substring(0, 10),
        });
    }
}

type HttpRequestType = 'xhr' | 'fetch';

export function applyHttpRequestAttrs(span: Span, url: string, method: string, type: HttpRequestType) {
    span?.setAttributes({
        [HttpRequestAttributes.HTTP_REQUEST_URL]: url,
        [HttpRequestAttributes.HTTP_REQUEST_METHOD]: method,
        [HttpRequestAttributes.HTTP_REQUEST_TYPE]: type,
    });
}

export function applyHttpResponseAttrs(span: Span, statusCode: string, traceId?: string, errorMessage?: string) {
    span?.setAttributes({
        [HttpRequestAttributes.HTTP_RESPONSE_STATUS_CODE]: statusCode,
        [HttpRequestAttributes.HTTP_RESPONSE_TRACE_ID]: traceId,
        [HttpRequestAttributes.HTTP_RESPONSE_ERROR]: errorMessage,
    });
}
