import {TransactionStatus, TransactionType, UserProfile} from '@models/generated/graphql';
import {TransactionViewModel, TransactionViewModelKeys} from '@models/transaction';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {TransactionFilterKeys} from '@redux/entity';
import {getLastNDaysDateRange} from '@utils/date';

import {Filter} from 'src/common/types';

export type TransactionWithUserProfileGridItem = TransactionViewModel & Omit<UserProfile, '__typename'>;

export type DataGridTransactionWithUserProfileColumns = UserProfileViewModelKeys | TransactionViewModelKeys;

const rangePeriodDays = 13;
const rangePeriod = getLastNDaysDateRange(rangePeriodDays);
export const transactionDateDefaultFilter: Filter<string | number, TransactionFilterKeys>[] = [
    {
        key: 'startedTs.from',
        value: rangePeriod[0],
    },
    {
        key: 'startedTs.to',
        value: rangePeriod[1],
    },
];

export const pendingCasesStatuses = [
    TransactionStatus.Pending,
    TransactionStatus.OnHoldRisk,
    TransactionStatus.OnHoldPayment,
    TransactionStatus.RiskAuthorized,
    TransactionStatus.InProgress,
];
export const closedCasesStatuses = [TransactionStatus.Rejected, TransactionStatus.Failed, TransactionStatus.Succeeded];

export const pendingCasesDefaultFilter: Filter<unknown, TransactionFilterKeys>[] = [
    ...transactionDateDefaultFilter,
    {
        key: 'transactionStatus',
        value: pendingCasesStatuses,
    },
    {
        key: 'defaultTransactionStatus',
        value: pendingCasesStatuses,
    },
    {key: 'transactionTypes', value: TransactionType.Withdrawal},
];

export const closedCasesDefaultFilter: Filter<unknown, TransactionFilterKeys>[] = [
    ...transactionDateDefaultFilter,
    {
        key: 'transactionStatus',
        value: closedCasesStatuses,
    },
    {
        key: 'defaultTransactionStatus',
        value: closedCasesStatuses,
    },
    {key: 'transactionTypes', value: TransactionType.Withdrawal},
];
