import {useEffect} from 'react';
import {MessageDescriptor} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {RouteUrl} from 'src/common/routeEnums';

import {setBreadcrumbAction} from './actions';

export const useBreadcrumb = () => {
    return useSelector((s: RootState) => s.breadcrumb);
};

export function useBreadcrumbInitializer(uid: string, route: RouteUrl, detailsRoute: RouteUrl, routeLocalized: string | MessageDescriptor) {
    const dispatch = useDispatch();
    useEffect(() => {
        const detailsUrl = `${detailsRoute}/${uid}`;

        dispatch(
            setBreadcrumbAction([
                {url: route, title: routeLocalized, clickable: route?.length ? true : false},
                {url: detailsUrl, title: uid, clickable: false},
            ])
        );
    }, [uid]);
}
