import {defineMessages} from 'react-intl';

import {PermissionEnum} from '@models/permissions';

export const localizedPermission = defineMessages({
    [PermissionEnum.Create]: {
        id: 'permissionCreate',
        defaultMessage: 'Create',
    },
    [PermissionEnum.Read]: {
        id: 'permissionRead',
        defaultMessage: 'Read',
    },
    [PermissionEnum.Update]: {
        id: 'permissionUpdate',
        defaultMessage: 'Update',
    },
    [PermissionEnum.Delete]: {
        id: 'permissionDelete',
        defaultMessage: 'Delete',
    },
    [PermissionEnum.All]: {
        id: 'permissionAll',
        defaultMessage: 'All',
    },
});
