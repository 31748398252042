import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {map} from '@otel';
import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {CountriesApiService} from '@services/rest-api';
import {countryFilterKey} from '@services/rest-api/countriesApiService';

@injectable()
export class StatesService implements IEntityReadService {
    private readonly _countriesApiService: CountriesApiService;

    constructor(@inject(ServiceTypes.CountriesApiService) countriesApiService: CountriesApiService) {
        this._countriesApiService = countriesApiService;
    }

    public get(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        const countryCode = this.getStateCountryIsoCode(requestPayload.filter);
        return this._countriesApiService.getStates(countryCode).pipe(
            map(res => ({
                ...res,
                requestPayload,
                responsePayload: {items: res?.responsePayload, total: res?.responsePayload?.length},
            }))
        );
    }

    private getStateCountryIsoCode(filter: string): string {
        const searchParams = new URLSearchParams(filter);

        return searchParams.get(countryFilterKey);
    }
}
