export type Operator = 'less' | 'greater' | 'equals' | 'between';

export type ValueRange = {
    from?: number;
    to?: number;
};

export type ValueRangeModel = {
    operator: Operator;
    value: ValueRange;
};

export const defaultValueRangeModel: ValueRangeModel = {
    operator: 'less',
    value: {},
};
