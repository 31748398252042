import {UserAction} from '@models/generated/graphql';

import {protectEpics} from '../app/error-handling/epics';
import {createGridEpics} from '../module-shared/epics';
import {createEntityRealtimeEpics} from '../realtime-updates/epics';

import {filterSelector, realtimeSubscribersSelector, subscribedTypesSelector} from './selectors';
import {ActionHistory, ActionHistoryGridItem, domain} from './types';

export default protectEpics(
    createGridEpics<ActionHistory, ActionHistoryGridItem>(domain, c => c.actionHistoryService, filterSelector, null, false),
    createEntityRealtimeEpics<UserAction>(
        domain,
        container => container.userActionRealtimeService,
        realtimeSubscribersSelector,
        subscribedTypesSelector
    )
);
