import React, {useContext} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';
import {Icon} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {ModalContext} from '@components/modal';

import {BlockPlayerLabelGroupModal} from '../../block-label-group-list/components/BlockPlayerLabelGroupModal';
import {LabelGroup} from '../../block-label-group-list/types';

const localized = defineMessages<string, MessageDescriptor>({
    modalTitle: {
        id: 'BlockPlayerLabelGroupEdit_modalTitle',
        defaultMessage: 'Edit Group',
    },
});

type BlockPlayerLabelGroupEditActionProps = {
    labelGroup: LabelGroup;
};

export function BlockPlayerLabelGroupEditAction({labelGroup}: BlockPlayerLabelGroupEditActionProps) {
    const {openModal} = useContext(ModalContext);

    function handleClick() {
        openModal({
            body: <BlockPlayerLabelGroupModal labelGroup={labelGroup} />,
            title: <LocalizedText label={localized.modalTitle} />,
        });
    }

    return (
        <Box onClick={handleClick}>
            <Icon icon={CustomIcon.Gear} />
        </Box>
    );
}
