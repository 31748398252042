import equal from 'fast-deep-equal/es6';
import {combineReducers, Reducer} from 'redux';
import {createReducer, PayloadAction} from 'typesafe-actions';

import {IModuleGridItem} from '@components/data-grid';
import {ModuleName, SubmoduleName} from '@models/modules';

import {ItemsPage} from 'src/common/types';
import routes, {getRouteUrl} from '../../app/router/routes';
import {getFilterReducer} from '../shared/filter/reducers';

import {
    columnConfigurationActions,
    contentClearAction,
    contentLoadSucceedAction,
    contentModuleActions,
    contentPushNewAction,
    contentUpdateItemAction,
    gridModuleActions,
} from './actions';
import {FormModuleData, IModuleItem, KeyValueDataStorageItem, KeyValueStorageData} from './types';

export const createGridModuleReducers = <T>(
    domain: string,
    moduleName: ModuleName,
    submoduleName?: SubmoduleName,
    additionalReducers?: Reducer<T>
) => {
    const gridModuleReducer = combineReducers({
        ...getGridModuleDataReducer(domain, moduleName, submoduleName),
        state: combineReducers(getGridModuleStateReducer(domain)),
    });

    const combined = combineReducers({
        domain: createReducer(domain),
        data: gridModuleReducer,
        additional: additionalReducers ?? createReducer(null as T),
    });

    return combined;
};

export const getModuleNameReducers = (moduleName: ModuleName, submoduleName?: SubmoduleName) => {
    const route = !submoduleName ? getRouteUrl(routes, moduleName) : getRouteUrl(routes, moduleName, submoduleName);

    return {
        moduleName: createReducer(moduleName),
        submoduleName: createReducer(submoduleName ?? null),
        route: createReducer(route),
    };
};

export const getGridModuleDataReducer = (domain: string, moduleName: ModuleName, submoduleName?: SubmoduleName) => {
    return {
        ...getContentItemsPageReducer(domain),
        ...getModuleNameReducers(moduleName, submoduleName),
    };
};

export const getContentItemsPageReducer = (domain: string) => {
    //NOTE: this reducer was updated to optimize the performace.
    //createReducer provide immutable updates and always new object returned. For data grid it migh be an issue
    const itemsReducer = (state = {items: [], total: 0} as ItemsPage<IModuleGridItem>, action: PayloadAction<string, any>) => {
        switch (action.type) {
            case `${domain}${contentUpdateItemAction}`: {
                const updatedItem = action.payload as IModuleGridItem;
                const itemIndex = state.items.findIndex(i => i.serverId === updatedItem.serverId && !equal(i, updatedItem));
                if (itemIndex >= 0) {
                    state.items[itemIndex] = updatedItem;
                }
                return state;
            }
            case `${domain}${contentPushNewAction}`: {
                const newItem = action.payload as IModuleGridItem;
                const newItems = [newItem, ...state.items];
                return {items: newItems, total: state.total ? state.total + 1 : 1};
            }
            case `${domain}${contentLoadSucceedAction}`: {
                const isEqual = equal(state, action.payload);
                return isEqual ? state : action.payload;
            }
            case `${domain}${contentClearAction}`: {
                return {items: [], total: 0};
            }
            default:
                return state;
        }
    };

    return {itemsPage: itemsReducer};
};

export const getContentSingleItemReducer = (domain: string) => {
    const actions = contentModuleActions(domain);

    const itemReducer = createReducer({})
        .handleAction(actions.contentLoad.success, (_, action) => action.payload)
        .handleAction(actions.contentUpdateItem, (_, action) => action.payload);

    return {
        item: itemReducer,
    };
};

export const getItemsSelectedReducer = (domain: string) => {
    const actions = gridModuleActions(domain);
    const itemReducer = createReducer([] as IModuleGridItem[]).handleAction(actions.itemsSelected, (_, action) => action.payload);

    return {
        selectedItems: itemReducer,
    };
};

export const getGridModuleStateReducer = (domain: string) => {
    return {
        ...getFilterReducer(domain),
        ...getColumnConfigurationReducer(domain),
        ...getItemsSelectedReducer(domain),
    };
};

export const getGridModuleItemReducer = (domain: string) => {
    const actions = gridModuleActions(domain);
    const itemReducer = createReducer(null as IModuleItem).handleAction(actions.itemLoad.success, (_, action) => action.payload);

    return {
        item: itemReducer,
    };
};

export const getColumnConfigurationReducer = (domain: string) => {
    const actions = columnConfigurationActions(domain);

    const columnsReducer = createReducer([] as string[]).handleAction(actions.setColumns, (_, action) => action.payload);

    return {
        columns: columnsReducer,
    };
};

export const getContentLoadingReducer = (domain: string) => {
    const actions = gridModuleActions(domain);

    const loadingReducer = createReducer(false as boolean)
        .handleAction(actions.contentLoad.request, () => true)
        .handleAction([actions.contentLoad.success, actions.contentLoad.failure], () => false);

    return {
        isLoading: loadingReducer,
    };
};

export const getContentModuleDataReducer = (domain: string, moduleName: ModuleName, submoduleName?: SubmoduleName) => {
    const actions = contentModuleActions(domain);

    const contentReducer = createReducer({item: undefined, validationMessage: null} as FormModuleData<IModuleItem>)
        .handleAction(actions.contentLoad.success, (state, action) => ({
            ...state,
            item: action.payload,
            validationMessage: null,
        }))
        .handleAction(actions.itemSave.failure, (state, action) => ({
            ...state,
            validationMessage: action.payload,
        }));

    return {
        ...getModuleNameReducers(moduleName, submoduleName),
        content: contentReducer,
    };
};

export const getContentModuleStateReducer = (domain: string) => {
    const actions = contentModuleActions(domain);
    const editModeReducer = createReducer(false as boolean)
        .handleAction(actions.changeMode, state => !state)
        .handleAction(actions.contentLoad.success, () => false);

    return {
        ...getFilterReducer(domain),
        isEditMode: editModeReducer,
    };
};

export const createContentModuleReducers = (domain: string, moduleName: ModuleName, submoduleName?: SubmoduleName) => {
    const contentModuleReducer = combineReducers({
        ...getContentModuleDataReducer(domain, moduleName, submoduleName),
        state: combineReducers(getContentModuleStateReducer(domain)),
    });

    const combined = combineReducers({
        domain: createReducer(domain),
        data: contentModuleReducer,
    });

    return combined;
};

const extendWithNewKey = <T>(state: KeyValueStorageData<T>, key: string, value: T): KeyValueStorageData<T> => ({
    ...state,
    [key]: value,
});

const removeKey = <T>(state: KeyValueStorageData<T>, key: string): KeyValueStorageData<T> => {
    const {[key]: _, ...newState} = state;
    return newState;
};

export const updateKeyValueState = <T>(state: KeyValueStorageData<T>, key: string, value: T): KeyValueStorageData<T> => {
    return value ? extendWithNewKey(state, key, value) : removeKey(state, key);
};

export const updateKeyValueStateBatch = <T>(
    state: KeyValueStorageData<T>,
    values: KeyValueDataStorageItem<T>[]
): KeyValueStorageData<T> => {
    let res: KeyValueStorageData<T> = state;

    for (const item of values) {
        res = updateKeyValueState(res, item.key, item.value);
    }

    return res;
};
