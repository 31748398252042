import {defineMessages} from 'react-intl';

export const localizedAutocomplete = defineMessages({
    autocompleteAddNewItemLabel: {
        id: 'AUTOCOMPLETE_ADD_ITEM',
        defaultMessage: 'Add "{value}"',
    },
    autocompleteNoOptionsLabel: {
        id: 'AUTOCOMPLETE_NO_OPTIONS',
        defaultMessage: 'No options',
    },
    autocompletePlaceholder: {
        id: 'AUTOCOMPLETE_PLACEHOLDER',
        defaultMessage: 'Nope',
    },
});
