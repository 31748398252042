import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {DateRangeFilter, FilterGroupNew, FilterGroupNewFilter, FilterProps, FiltersProps, SelectFilter} from '@components/filter';
import {TextInputWithDropdownFilter} from '@components/filter/TextFilterWithDropdown';
import {BonusCreditType, BonusStatus} from '@models/generated/graphql';
import {sharedLocalization} from '@localization';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {localizedBonusStatus} from '../../app/intl/shared-resources/beBonusStatus';
import {localizedBeCreditType} from '../../app/intl/shared-resources/beCreditType';
import {SelectOption} from '../../module-shared/types';
import {BonusCodeFilterModel, BonusCodeFilterName, BonusCodeTextFilterKeys, BonusCodeTextFilterValue} from '../types';

const localized = defineMessages({
    searchInBonusEnginePlaceholder: {
        id: 'BE_searchInBonusEnginePlaceholder',
        defaultMessage: 'Search in Bonus Engine',
    },
    bonusStatusFilterPlaceholder: {
        id: 'BE_bonusStatusFilterPlaceholder',
        defaultMessage: 'Any',
    },
    bonusId: {
        id: 'BE_bonusId',
        defaultMessage: 'Bonus ID',
    },
    bonusName: {
        id: 'BE_bonusName',
        defaultMessage: 'Bonus Name',
    },
    bonusTypeFilter: {
        id: 'BE_bonusTypeFilter',
        defaultMessage: 'Type',
    },
    bonusStatusFilter: {
        id: 'BE_bonusStatusFilter',
        defaultMessage: 'Status',
    },
});

const placeholderMapper: Record<BonusCodeTextFilterKeys, MessageDescriptor> = {
    id_name: localized.searchInBonusEnginePlaceholder,
    id: localized.searchInBonusEnginePlaceholder,
    name: localized.searchInBonusEnginePlaceholder,
};

export type BonusCodeFiltersProps = FiltersProps<BonusCodeFilterModel, BonusCodeFilterName>;

export function BonusCodeFilters({model, onChange, availableFilters}: BonusCodeFiltersProps) {
    const {locale} = useJurisdictionConfig();

    const filters: FilterGroupNewFilter<BonusCodeFilterModel, BonusCodeFilterName>[] = [
        {
            filterName: 'id_name',
            modelField: 'text',
            component: (props: FilterProps<BonusCodeTextFilterValue>) => (
                <TextInputWithDropdownFilter
                    options={[
                        {label: sharedLocalization.textFullFilter, value: 'id_name'},
                        {label: localized.bonusId, value: 'id'},
                        {label: localized.bonusName, value: 'name'},
                    ]}
                    inputPlaceholder={
                        props?.value?.option ? placeholderMapper[props?.value?.option] : localized.searchInBonusEnginePlaceholder
                    }
                    width="fixed"
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'bonus_status',
            modelField: 'bonus_status',
            component: (props: FilterProps<string>) => (
                <SelectFilter
                    multiple
                    label={localized.bonusStatusFilter}
                    {...props}
                    chipType={nameof<BonusStatus>()}
                    options={Object.values(BonusStatus).map(t => ({label: localizedBonusStatus[t], value: t} as SelectOption))}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'credit_type',
            modelField: 'credit_type',
            component: (props: FilterProps<string>) => (
                <SelectFilter
                    multiple
                    label={localized.bonusTypeFilter}
                    {...props}
                    chipType={nameof<BonusCreditType>()}
                    options={Object.values(BonusCreditType).map(t => ({label: localizedBeCreditType[t], value: t} as SelectOption))}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'create_time',
            modelField: 'create_time',
            component: props => <DateRangeFilter {...props} locale={locale} />,
            collapseOnMobile: false,
        },
    ];

    return (
        <FilterGroupNew
            model={model}
            onChange={onChange}
            mode="update-on-change"
            allFilters={filters}
            availableFilters={availableFilters}
            groupContainerFullWidth={false}
        />
    );
}
