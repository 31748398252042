import React from 'react';
import {defineMessages} from 'react-intl';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {LayoutQueueTabs} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import {RoutingTabs, Tab} from '@components/tab/RoutingTabs';
import {ModuleName, SubmoduleName} from '@models/modules';
import {withdrawalProcessingPermissions} from '@models/permissions/permissions';
import {Policy} from '@auth';

import {RouteUrl} from '../common/routeEnums';
import {getUrlWithoutOutdatedParams} from '../features/block-transaction-list';
import {withModule} from '../features/module-shared/components/ModuleHoc';

import WithdrawalHistoryCases from './WithdrawalHistoryCases';
import WithdrawalPendingCases from './WithdrawalPendingCases';

export const withdrawalWorkspaceHeaderActionContainerId = 'withdrawalWorkspaceHeaderActionContainerId';

const localized = defineMessages({
    title: {
        id: 'WithdrawalProcessing_title',
        defaultMessage: 'Withdrawal Processing',
    },
    pendingCases: {
        id: 'WithdrawalProcessing_pendingCases',
        defaultMessage: 'Pending Cases',
    },
    historyCases: {
        id: 'WithdrawalProcessing_historyCases',
        defaultMessage: 'History Cases',
    },
});

type WithdrawalProcessingProps = RouteComponentProps;

function WithdrawalProcessing({location}: WithdrawalProcessingProps) {
    const tabs: Tab[] = [
        {
            title: localized.pendingCases,
            component: WithdrawalPendingCases,
            path: `${RouteUrl.WithdrawalProcessing}${RouteUrl.WithdrawalPendingCases}`,
            id: 'withdrawalPendingCases',
        },
        {
            title: localized.historyCases,
            component: WithdrawalHistoryCases,
            path: `${RouteUrl.WithdrawalProcessing}${RouteUrl.WithdrawalHistoryCases}`,
            id: 'withdrawalHistoryCases',
        },
    ];

    const redirectUrl = getUrlWithoutOutdatedParams(location);

    return (
        <>
            {redirectUrl ? <Redirect to={redirectUrl} /> : null}
            <LayoutQueueTabs
                data-testid="withdrawalProcessing"
                header={
                    <PageHeader
                        header={localized.title}
                        actions={<div id={withdrawalWorkspaceHeaderActionContainerId}></div>}
                        removeBottomSpacing
                    />
                }
                body={<RoutingTabs tabs={tabs} />}
            />
        </>
    );
}

export const withdrawalProcessingResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'WithdrawalProcessing',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.WithdrawalProcessing, null, 'read'),
        new Policy(ModuleName.WithdrawalProcessing, SubmoduleName.Payment, 'read'),
        new Policy(ModuleName.WithdrawalProcessing, SubmoduleName.WithdrawalProcessingRisk, 'read'),
    ]),
});

export default withModule(withRouter(WithdrawalProcessing), withdrawalProcessingPermissions);
