import React, {memo} from 'react';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';

import {usePolicyAccessCheck} from '@access-control';
import {useAutoMapper} from '@auto-mapper';
import {ToggleGroup} from '@components/toggle';
import {AccountVerificationViewModelKeys} from '@models/account-verification';
import {AccountVerificationType} from '@models/generated/graphql';
import {agentReadPolicies} from '@models/permissions/permissions';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {AccountVerificationFilterKeys} from '@redux/entity';
import {RealtimeUpdatesMode} from '@redux/realtime';
import {getNonEmptyValueValidator, ViewType} from '@redux/view';

import {useMultiplePermissions} from 'src/features/app/permission/PermissionHoc';
import {InitiatePaymentKyc} from 'src/features/block-account-verification';
import {ManualTransactionAddButton} from 'src/features/block-transaction-actions';
import {Filter} from '../../../common/types';
import {useJurisdictionFeature} from '../../app/config/hooks';
import {FeatureName} from '../../app/config/types';
import {useAccountVerificationDetails} from '../../block-account-verification/hooks';
import {useUserProfileDetails} from '../../block-user-profile-details';
import {
    accountActionsReadPermissions,
    financeActionsReadPermissions,
    financeLockReadResource,
    gamesActionsReadPermissions,
    p2pActionReadResources,
    p2pLockReadResource,
} from '../permissions';

import {AccountLock} from './AccountLock';
import {AssignSecurityCaseIdButton} from './AssignSecurityCaseIdButton';
import {CasinoLock} from './CasinoLock';
import {DepositLock} from './DepositLock';
import {LobbyLock} from './LobbyLock';
import {P2PTransferLock} from './P2PTransferLock';
import {SendMessageCommunicationAction} from './SendMessageCommunicationAction';
import {SportsbookLock} from './SportsbookLock';
import {WithdrawalLock} from './WithdrawalLock';

const localized = defineMessages({
    displayName: {
        id: 'UserProfileActionsCollapsed_displayName',
        defaultMessage: 'User Profile Actions',
    },
    accessTitle: {
        id: 'UserProfileActionsCollapsed_accessTitle',
        defaultMessage: 'Account / Financial Lock',
    },
    featureTitle: {
        id: 'UserProfileActionsCollapsed_featureTitle',
        defaultMessage: 'Feature Lock',
    },
});

export type AccessLocks =
    | 'account-status'
    | 'deposit-status'
    | 'lobby-status'
    | 'withdrawal-status'
    | 'casino-status'
    | 'sportsbook-status';
export type FeatureLocks = 'p2p-transfer-status';
export type UserProfileActionLocks = AccessLocks | FeatureLocks;
export type UserProfileAction =
    | UserProfileActionLocks
    | 'init-payment-kyc'
    | 'send-message'
    | 'assign-security-case-id'
    | 'manual-transaction';

export type UserProfileActionsProps = {
    uid: string;
    includeGroupTitle?: boolean;
    actions?: UserProfileAction[];
    readonly?: boolean;
};

export type LockActionProps<T extends string = string> = {
    uid: string;
    status: T;
    disabled?: boolean;
};

type GroupedActionsProps = {
    title: MessageDescriptor;
    allActions: Partial<Record<UserProfileAction, JSX.Element>>;
    actions: UserProfileAction[];
    isAvailable: boolean;
};

function GroupedActions({allActions, actions, title, isAvailable}: GroupedActionsProps) {
    const {formatMessage} = useIntl();

    return (
        isAvailable && (
            <ToggleGroup title={title && actions?.length && formatMessage(title)}>{actions?.map(action => allActions[action])}</ToggleGroup>
        )
    );
}

function useUserProfileActionData(uid: string, actions: UserProfileAction[], withAccountVerification: boolean) {
    const viewType: ViewType = 'UserProfileActions';
    const mapper = useAutoMapper();

    const userProfileFields: UserProfileViewModelKeys[] = mapper.map<UserProfileAction[], UserProfileViewModelKeys[]>(
        actions,
        nameof<UserProfileAction>(),
        nameof<UserProfileViewModelKeys>()
    );
    const accountVerificationFields: AccountVerificationViewModelKeys[] = mapper.map<
        UserProfileAction[],
        AccountVerificationViewModelKeys[]
    >(actions, nameof<UserProfileAction>(), nameof<AccountVerificationViewModelKeys>());

    const {item: user, requestStatus: userRequestStatus} = useUserProfileDetails({
        id: uid,
        viewType,
        fields: userProfileFields,
        realtimeMode: RealtimeUpdatesMode.Confirm,
        displayName: localized.displayName,
    });
    const {item: accountVerification} = useAccountVerificationDetails({
        viewType,
        fields: accountVerificationFields,
        displayName: localized.displayName,
        realtimeMode: RealtimeUpdatesMode.Silent,
        defaultFilters: [
            {key: 'uid', value: uid},
            {key: 'type', value: [AccountVerificationType.Payment]},
        ],
        validateFilter: (filter: Filter<any, AccountVerificationFilterKeys>[]) =>
            withAccountVerification && getNonEmptyValueValidator<AccountVerificationFilterKeys>('uid')(filter),
    });

    return {user, isUserRequestInProgress: userRequestStatus === 'inProgress', accountVerification};
}

function UserProfileActionsInner({uid, actions, includeGroupTitle: includeTitle, readonly}: UserProfileActionsProps) {
    const isAccountVerificationAvailable = useMultiplePermissions({restrictedPermissions: agentReadPolicies});
    const isFinanceLockAndP2pLockPoliciesAvailiable = useJurisdictionFeature({
        featureName: FeatureName.FinanceActionsNewPolicies,
    });
    const isP2PLockHiddenForPH = useJurisdictionFeature({featureName: FeatureName.P2POptionsHideForPHRoles});

    const haveAccountReadPermission = useMultiplePermissions({
        allowedPermissions: accountActionsReadPermissions,
    });
    const haveGamesReadPermission = useMultiplePermissions({
        allowedPermissions: gamesActionsReadPermissions,
    });
    const haveFinanceActionsReadPermissions = useMultiplePermissions({
        allowedPermissions: financeActionsReadPermissions,
    });

    const haveFinanceLockReadPermission = usePolicyAccessCheck(financeLockReadResource);
    const haveP2pLockReadResource = usePolicyAccessCheck(p2pLockReadResource);
    const haveP2pActionReadResource = usePolicyAccessCheck(p2pActionReadResources);

    const isAccessLocksAvailable =
        haveAccountReadPermission ||
        haveGamesReadPermission ||
        (isFinanceLockAndP2pLockPoliciesAvailiable ? haveFinanceLockReadPermission : haveFinanceActionsReadPermissions);

    let isFeatureLocksAvailable = false;
    if (isP2PLockHiddenForPH && isFinanceLockAndP2pLockPoliciesAvailiable) {
        isFeatureLocksAvailable = haveP2pLockReadResource;
    } else if (isP2PLockHiddenForPH && !isFinanceLockAndP2pLockPoliciesAvailiable) {
        isFeatureLocksAvailable = haveP2pActionReadResource;
    } else if (isFinanceLockAndP2pLockPoliciesAvailiable) {
        isFeatureLocksAvailable = haveFinanceLockReadPermission;
    } else {
        isFeatureLocksAvailable = haveFinanceActionsReadPermissions;
    }

    const {user, isUserRequestInProgress, accountVerification} = useUserProfileActionData(uid, actions, isAccountVerificationAvailable);

    const isLockDisabled = !uid || readonly || isUserRequestInProgress;

    const allLocks: Record<UserProfileActionLocks, JSX.Element> = {
        'account-status': (
            <AccountLock uid={uid} status={user?.account_status} boUserId={user?.agent_info?.bo_agent_id} disabled={isLockDisabled} />
        ),
        'deposit-status': <DepositLock uid={uid} status={user?.deposit_status} disabled={isLockDisabled} />,
        'lobby-status': <LobbyLock uid={uid} status={user?.lobby_access_status} disabled={isLockDisabled} />,
        'withdrawal-status': <WithdrawalLock uid={uid} status={user?.withdrawal_status} disabled={isLockDisabled} />,
        'casino-status': <CasinoLock uid={uid} status={user?.casino_status} disabled={isLockDisabled} />,
        'sportsbook-status': <SportsbookLock uid={uid} status={user?.sportsbook_status} disabled={isLockDisabled} />,
        'p2p-transfer-status': <P2PTransferLock uid={uid} status={user?.p2p_transfer_status} disabled={isLockDisabled} />,
    };

    function getAccessLocks(): UserProfileAction[] {
        const accessLocks: AccessLocks[] = [
            'account-status',
            'deposit-status',
            'lobby-status',
            'withdrawal-status',
            'casino-status',
            'sportsbook-status',
        ];

        return actions?.filter(a => accessLocks.includes(a as AccessLocks));
    }

    function getFeatureLocks(): UserProfileAction[] {
        const featureLocks: FeatureLocks[] = ['p2p-transfer-status'];

        return actions?.filter(a => featureLocks.includes(a as FeatureLocks));
    }

    return (
        <>
            <GroupedActions
                allActions={allLocks}
                actions={getAccessLocks()}
                title={includeTitle && localized.accessTitle}
                isAvailable={isAccessLocksAvailable}
            />
            <GroupedActions
                allActions={allLocks}
                actions={getFeatureLocks()}
                title={includeTitle && localized.featureTitle}
                isAvailable={isFeatureLocksAvailable}
            />

            {actions?.includes('assign-security-case-id') && (
                <AssignSecurityCaseIdButton uid={user?.uid} securityCases={user?.security_cases} disabled={isLockDisabled} />
            )}
            {actions?.includes('init-payment-kyc') && (
                <InitiatePaymentKyc
                    uid={uid}
                    status={accountVerification?.account_verification_status}
                    reasons={accountVerification?.reason_codes}
                    disabled={uid === undefined || readonly}
                />
            )}
            {actions?.includes('send-message') && <SendMessageCommunicationAction uid={user?.uid} disabled={isLockDisabled} />}
            {actions?.includes('manual-transaction') && <ManualTransactionAddButton uid={user?.uid} disabled={isLockDisabled} />}
        </>
    );
}

export const UserProfileActions = memo(UserProfileActionsInner);
