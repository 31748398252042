export type Maybe<T> = T | null;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
    Long: any;
    /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
    DateTime: any;
};

export class TextFilter {
    text: Scalars['String'];
    search_in: Array<Maybe<Scalars['String']>>;
}

export enum ApplyPolicy {
    BeforeResolver = 'BEFORE_RESOLVER',
    AfterResolver = 'AFTER_RESOLVER',
}

export class Query {
    __typename?: 'Query';
    getLegalContentHistory?: Maybe<LegalContentManagementHistoryPage>;
}

export type QueryGetLegalContentHistoryArgs = {
    filter?: Maybe<LegalContentManagementHistoryFilter>;
    sort?: Maybe<Sorting>;
    start?: Maybe<Scalars['Int']>;
    end?: Maybe<Scalars['Int']>;
};

export class Subscriptions {
    __typename?: 'Subscriptions';
    transactionEvent?: Maybe<TransactionProto>;
    transactionPaymentSystemUidUpdateEvent?: Maybe<TransactionPaymentSystemUidUpdateProto>;
    transactionStatusUpdateEvent?: Maybe<TransactionStatusUpdateProto>;
    adminDisabledUsersTwoFAEvent?: Maybe<AdminDisabledUsersTwoFaProto>;
    adminModifiedUsersEmailEvent?: Maybe<AdminModifiedUsersEmailProto>;
    adminModifiedUsersMobileEvent?: Maybe<AdminModifiedUsersMobileProto>;
    adminResentUsersRestoreCodeEvent?: Maybe<AdminResentUsersRestoreCodeProto>;
    adminLockedUsersLoginEvent?: Maybe<AdminLockedUsersLoginProto>;
    adminUnlockedUsersLoginEvent?: Maybe<AdminUnlockedUsersLoginProto>;
    adminBlockedUserEvent?: Maybe<AdminBlockedUserProto>;
    adminLockedUsersLobbyAccessEvent?: Maybe<AdminLockedUsersLobbyAccessProto>;
    adminUnlockedUsersLobbyAccessEvent?: Maybe<AdminUnlockedUsersLobbyAccessProto>;
    adminLockedUsersWithdrawalEvent?: Maybe<AdminLockedUsersWithdrawalProto>;
    adminUnlockedUsersWithdrawalEvent?: Maybe<AdminUnlockedUsersWithdrawalProto>;
    adminLockedUsersDepositEvent?: Maybe<AdminLockedUsersDepositProto>;
    adminUnlockedUsersDepositEvent?: Maybe<AdminUnlockedUsersDepositProto>;
    adminUpdatedUsersWithdrawalLimitEvent?: Maybe<AdminUpdatedUsersWithdrawalLimitProto>;
    adminRemovedUsersWithdrawalLimitEvent?: Maybe<AdminRemovedUsersWithdrawalLimitProto>;
    adminModifiedUsersKYCStatusEvent?: Maybe<AdminModifiedUsersKycStatusProto>;
    adminUpdatedStakesAccessLimitEvent?: Maybe<AdminUpdatedStakesAccessLimitProto>;
    adminCommentedEvent?: Maybe<AdminCommentedProto>;
    adminDeletedCommentEvent?: Maybe<AdminDeletedCommentProto>;
    adminSecurityCaseOpenedEvent?: Maybe<AdminSecurityCaseOpenedProto>;
    adminSecurityCaseClosedEvent?: Maybe<AdminSecurityCaseClosedProto>;
    adminPlayerAccountClosedEvent?: Maybe<AdminPlayerAccountClosedProto>;
    adminGameMessageEvent?: Maybe<AdminGameMessageProto>;
    adminChangedFriendsGroupEvent?: Maybe<AdminChangedFriendsGroupProto>;
    adminUpdatedFriendsGroupUsersEvent?: Maybe<AdminUpdatedFriendsGroupUsersProto>;
    adminKickedUserEvent?: Maybe<AdminKickedUserProto>;
    adminChangedBlacklistIPEvent?: Maybe<AdminChangedBlacklistIpProto>;
    adminChangedBlacklistDeviceEvent?: Maybe<AdminChangedBlacklistDeviceProto>;
    blacklistedPlayerEvent?: Maybe<BlacklistedPlayerProto>;
    kickedBlacklistedPlayerEvent?: Maybe<KickedBlacklistedPlayerProto>;
    adminChangedBotGroupEvent?: Maybe<AdminChangedBotGroupProto>;
    adminChangedAmlLabelEvent?: Maybe<AdminChangedAmlLabelProto>;
    adminUpdatePlayerSkillLabelEvent?: Maybe<AdminUpdatePlayerSkillLabelProto>;
    adminBlacklistedPlayerEvent?: Maybe<AdminBlacklistedPlayerProto>;
    userRegisteredEvent?: Maybe<UserRegisteredProto>;
}

export class LegalContentManagementHistoryPage {
    __typename?: 'LegalContentManagementHistoryPage';
    items?: Maybe<Array<Maybe<LegalManagementHistory>>>;
    total_count?: Maybe<Scalars['Int']>;
}

export class LegalContentManagementHistoryFilter {
    date?: Maybe<DateRange>;
    version?: Maybe<Scalars['Long']>;
    text?: Maybe<Array<Maybe<TextFilter>>>;
}

export class Sorting {
    sort?: Maybe<Scalars['String']>;
    order: SortOrder;
}

export class TransactionProto {
    __typename?: 'TransactionProto';
    uid?: Maybe<Scalars['String']>;
    transaction_id?: Maybe<Scalars['String']>;
    started_at_ts?: Maybe<TimestampProto>;
    amount: Scalars['Float'];
    current_balance: Scalars['Float'];
    previous_balance: Scalars['Float'];
    transaction_type: TransactionTypeProto;
    currency: CurrencyProto;
    payment_system: PaymentSystemsProto;
    payment_system_uid?: Maybe<Scalars['String']>;
    payment_system_transaction_id?: Maybe<Scalars['String']>;
    payment_option: PaymentOptionsProto;
    payment_method_description?: Maybe<Scalars['String']>;
    payment_method_name?: Maybe<Scalars['String']>;
    transaction_reason: TransactionReasonProto;
    created_by_uid?: Maybe<Scalars['String']>;
}

export class TransactionPaymentSystemUidUpdateProto {
    __typename?: 'TransactionPaymentSystemUidUpdateProto';
    uid?: Maybe<Scalars['String']>;
    transaction_id?: Maybe<Scalars['String']>;
    payment_system_uid?: Maybe<Scalars['String']>;
    at_ts?: Maybe<TimestampProto>;
}

export class TransactionStatusUpdateProto {
    __typename?: 'TransactionStatusUpdateProto';
    uid?: Maybe<Scalars['String']>;
    action_by_admin_id?: Maybe<Scalars['String']>;
    transaction_id?: Maybe<Scalars['String']>;
    status: TransactionStatusProto;
    at_ts?: Maybe<TimestampProto>;
}

export class AdminDisabledUsersTwoFaProto {
    __typename?: 'AdminDisabledUsersTwoFAProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminModifiedUsersEmailProto {
    __typename?: 'AdminModifiedUsersEmailProto';
    uid?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminModifiedUsersMobileProto {
    __typename?: 'AdminModifiedUsersMobileProto';
    uid?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    area_code?: Maybe<Scalars['String']>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminResentUsersRestoreCodeProto {
    __typename?: 'AdminResentUsersRestoreCodeProto';
    uid?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminLockedUsersLoginProto {
    __typename?: 'AdminLockedUsersLoginProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminUnlockedUsersLoginProto {
    __typename?: 'AdminUnlockedUsersLoginProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminBlockedUserProto {
    __typename?: 'AdminBlockedUserProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    punishment_status: PunishmentStatusProto;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminLockedUsersLobbyAccessProto {
    __typename?: 'AdminLockedUsersLobbyAccessProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminUnlockedUsersLobbyAccessProto {
    __typename?: 'AdminUnlockedUsersLobbyAccessProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminLockedUsersWithdrawalProto {
    __typename?: 'AdminLockedUsersWithdrawalProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminUnlockedUsersWithdrawalProto {
    __typename?: 'AdminUnlockedUsersWithdrawalProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminLockedUsersDepositProto {
    __typename?: 'AdminLockedUsersDepositProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminUnlockedUsersDepositProto {
    __typename?: 'AdminUnlockedUsersDepositProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminUpdatedUsersWithdrawalLimitProto {
    __typename?: 'AdminUpdatedUsersWithdrawalLimitProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    period_type: PaymentPeriodLimitTypeProto;
    amount: Scalars['Float'];
    limit_id?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminRemovedUsersWithdrawalLimitProto {
    __typename?: 'AdminRemovedUsersWithdrawalLimitProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    period_type: PaymentPeriodLimitTypeProto;
    limit_id?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminModifiedUsersKycStatusProto {
    __typename?: 'AdminModifiedUsersKYCStatusProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    status: AccountVerificationStatusProto;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminUpdatedStakesAccessLimitProto {
    __typename?: 'AdminUpdatedStakesAccessLimitProto';
    uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    blocked_stakes_access?: Maybe<Array<Maybe<GameTableProto>>>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminCommentedProto {
    __typename?: 'AdminCommentedProto';
    id?: Maybe<Scalars['String']>;
    target_type: CommentTargetTypeProto;
    body?: Maybe<Scalars['String']>;
    posted_at?: Maybe<TimestampProto>;
    modified_at?: Maybe<TimestampProto>;
    posted_by_uid?: Maybe<Scalars['String']>;
    modified_by_uid?: Maybe<Scalars['String']>;
    users_tagged?: Maybe<Array<Maybe<Scalars['String']>>>;
    action_id?: Maybe<Scalars['String']>;
    target_id?: Maybe<Scalars['String']>;
}

export class AdminDeletedCommentProto {
    __typename?: 'AdminDeletedCommentProto';
    id?: Maybe<Scalars['String']>;
    action_at?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminSecurityCaseOpenedProto {
    __typename?: 'AdminSecurityCaseOpenedProto';
    id?: Maybe<Scalars['String']>;
    target_types?: Maybe<Array<SecurityCaseTypeProto>>;
    opened_at?: Maybe<TimestampProto>;
    modified_at?: Maybe<TimestampProto>;
    opened_by_uid?: Maybe<Scalars['String']>;
    modified_by_uid?: Maybe<Scalars['String']>;
    security_case_id?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminSecurityCaseClosedProto {
    __typename?: 'AdminSecurityCaseClosedProto';
    id?: Maybe<Scalars['String']>;
    action_at?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
    security_case_id?: Maybe<Scalars['String']>;
}

export class AdminPlayerAccountClosedProto {
    __typename?: 'AdminPlayerAccountClosedProto';
    action_at?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
    user_uid?: Maybe<Scalars['String']>;
}

export class AdminGameMessageProto {
    __typename?: 'AdminGameMessageProto';
    action_at?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
    mail_type: MailTypeProto;
    content?: Maybe<Scalars['String']>;
    subject?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    signature?: Maybe<Scalars['String']>;
    sender?: Maybe<Scalars['String']>;
    user_id?: Maybe<Scalars['String']>;
}

export class AdminChangedFriendsGroupProto {
    __typename?: 'AdminChangedFriendsGroupProto';
    id?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    action_type: FriendsGroupActionTypeProto;
    action_by_uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminUpdatedFriendsGroupUsersProto {
    __typename?: 'AdminUpdatedFriendsGroupUsersProto';
    id?: Maybe<Scalars['String']>;
    player_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
    action_type: FriendsGroupUsersActionTypeProto;
    action_by_uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminKickedUserProto {
    __typename?: 'AdminKickedUserProto';
    uid?: Maybe<Scalars['String']>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminChangedBlacklistIpProto {
    __typename?: 'AdminChangedBlacklistIPProto';
    ip_addresses?: Maybe<Array<Maybe<Scalars['String']>>>;
    action_type: BlacklistActionTypeProto;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminChangedBlacklistDeviceProto {
    __typename?: 'AdminChangedBlacklistDeviceProto';
    device_identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
    action_type: BlacklistActionTypeProto;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class BlacklistedPlayerProto {
    __typename?: 'BlacklistedPlayerProto';
    player_id?: Maybe<Scalars['String']>;
    device_uuid?: Maybe<Scalars['String']>;
    ip_address?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
    blacklist_target: BlacklistSourceProto;
    action_type: BlacklistedPlayerActionTypeProto;
}

export class KickedBlacklistedPlayerProto {
    __typename?: 'KickedBlacklistedPlayerProto';
    player_id?: Maybe<Scalars['String']>;
    device_uuid?: Maybe<Scalars['String']>;
    ip_address?: Maybe<Scalars['String']>;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
    blacklist_source: BlacklistSourceProto;
}

export class AdminChangedBotGroupProto {
    __typename?: 'AdminChangedBotGroupProto';
    group_id?: Maybe<Scalars['String']>;
    player_uids?: Maybe<Array<Maybe<Scalars['String']>>>;
    players_group: BotDetectionPlayersGroupProto;
    action_type: BotDetectionActionTypeProto;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminChangedAmlLabelProto {
    __typename?: 'AdminChangedAmlLabelProto';
    uid?: Maybe<Scalars['String']>;
    aml_labels?: Maybe<Array<AmlLabelProto>>;
    action_type: AmlLabelActionTypeProto;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminUpdatePlayerSkillLabelProto {
    __typename?: 'AdminUpdatePlayerSkillLabelProto';
    uid?: Maybe<Scalars['String']>;
    custom_label: PlayerSkillCustomLabelProto;
    action_type: PlayerSkillLabelActionTypeProto;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class AdminBlacklistedPlayerProto {
    __typename?: 'AdminBlacklistedPlayerProto';
    uid?: Maybe<Scalars['String']>;
    deposit_threshold: Scalars['Float'];
    action_type: PlayerBlacklistActionTypeProto;
    action_at_ts?: Maybe<TimestampProto>;
    action_by_uid?: Maybe<Scalars['String']>;
    action_id?: Maybe<Scalars['String']>;
}

export class UserRegisteredProto {
    __typename?: 'UserRegisteredProto';
    uid?: Maybe<Scalars['String']>;
    username?: Maybe<Scalars['String']>;
    date_of_joining?: Maybe<TimestampProto>;
    contact?: Maybe<UserContactInfoProto>;
    address?: Maybe<UserAddressInfoProto>;
    language?: Maybe<Scalars['String']>;
    account_status: UserAccountStatusProto;
    platform: PlatformProto;
    nickname?: Maybe<Scalars['String']>;
    timestamp?: Maybe<TimestampProto>;
    ip?: Maybe<Scalars['String']>;
    affiliate_code?: Maybe<Scalars['String']>;
    referral_code?: Maybe<Scalars['String']>;
    device?: Maybe<DeviceProto>;
}

export class LegalManagementHistory {
    __typename?: 'LegalManagementHistory';
    user_id?: Maybe<Scalars['String']>;
    version: Scalars['Long'];
    created_at: Scalars['DateTime'];
    ip?: Maybe<Scalars['String']>;
    document?: Maybe<Scalars['String']>;
}

export class DateRange {
    from?: Maybe<TimestampInput>;
    to?: Maybe<TimestampInput>;
}

export enum SortOrder {
    Desc = 'DESC',
    Asc = 'ASC',
}

export class TimestampProto {
    __typename?: 'TimestampProto';
    toDateTime: Scalars['DateTime'];
    toDateTimeOffset: Scalars['DateTime'];
    compareTo: Scalars['Int'];
    toDiagnosticString?: Maybe<Scalars['String']>;
    seconds: Scalars['Long'];
    nanos: Scalars['Int'];
}

export enum TransactionTypeProto {
    Undefined = 'Undefined',
    Deposit = 'Deposit',
    Withdrawal = 'Withdrawal',
    Reserve = 'Reserve',
    Release = 'Release',
    Debit = 'Debit',
    Credit = 'Credit',
}

export enum CurrencyProto {
    Undefined = 'Undefined',
    All = 'All',
    Dzd = 'Dzd',
    Ars = 'Ars',
    Aud = 'Aud',
    Bsd = 'Bsd',
    Bhd = 'Bhd',
    Bdt = 'Bdt',
    Amd = 'Amd',
    Bbd = 'Bbd',
    Bmd = 'Bmd',
    Btn = 'Btn',
    Bob = 'Bob',
    Bwp = 'Bwp',
    Bzd = 'Bzd',
    Sbd = 'Sbd',
    Bnd = 'Bnd',
    Mmk = 'Mmk',
    Bif = 'Bif',
    Khr = 'Khr',
    Cad = 'Cad',
    Cve = 'Cve',
    Kyd = 'Kyd',
    Lkr = 'Lkr',
    Clp = 'Clp',
    Cny = 'Cny',
    Cop = 'Cop',
    Kmf = 'Kmf',
    Crc = 'Crc',
    Hrk = 'Hrk',
    Cup = 'Cup',
    Czk = 'Czk',
    Dkk = 'Dkk',
    Dop = 'Dop',
    Svc = 'Svc',
    Etb = 'Etb',
    Ern = 'Ern',
    Fkp = 'Fkp',
    Fjd = 'Fjd',
    Djf = 'Djf',
    Gmd = 'Gmd',
    Gip = 'Gip',
    Gtq = 'Gtq',
    Gnf = 'Gnf',
    Gyd = 'Gyd',
    Htg = 'Htg',
    Hnl = 'Hnl',
    Hkd = 'Hkd',
    Huf = 'Huf',
    Isk = 'Isk',
    Inr = 'Inr',
    Idr = 'Idr',
    Irr = 'Irr',
    Iqd = 'Iqd',
    Ils = 'Ils',
    Jmd = 'Jmd',
    Jpy = 'Jpy',
    Kzt = 'Kzt',
    Jod = 'Jod',
    Kes = 'Kes',
    Kpw = 'Kpw',
    Krw = 'Krw',
    Kwd = 'Kwd',
    Kgs = 'Kgs',
    Lak = 'Lak',
    Lbp = 'Lbp',
    Lsl = 'Lsl',
    Lrd = 'Lrd',
    Lyd = 'Lyd',
    Mop = 'Mop',
    Mwk = 'Mwk',
    Myr = 'Myr',
    Mvr = 'Mvr',
    Mur = 'Mur',
    Mxn = 'Mxn',
    Mnt = 'Mnt',
    Mdl = 'Mdl',
    Mad = 'Mad',
    Omr = 'Omr',
    Nad = 'Nad',
    Npr = 'Npr',
    Ang = 'Ang',
    Awg = 'Awg',
    Vuv = 'Vuv',
    Nzd = 'Nzd',
    Nio = 'Nio',
    Ngn = 'Ngn',
    Nok = 'Nok',
    Pkr = 'Pkr',
    Pab = 'Pab',
    Pgk = 'Pgk',
    Pyg = 'Pyg',
    Pen = 'Pen',
    Php = 'Php',
    Qar = 'Qar',
    Rub = 'Rub',
    Rwf = 'Rwf',
    Shp = 'Shp',
    Sar = 'Sar',
    Scr = 'Scr',
    Sll = 'Sll',
    Sgd = 'Sgd',
    Vnd = 'Vnd',
    Sos = 'Sos',
    Zar = 'Zar',
    Ssp = 'Ssp',
    Szl = 'Szl',
    Sek = 'Sek',
    Chf = 'Chf',
    Syp = 'Syp',
    Thb = 'Thb',
    Top = 'Top',
    Ttd = 'Ttd',
    Aed = 'Aed',
    Tnd = 'Tnd',
    Ugx = 'Ugx',
    Mkd = 'Mkd',
    Egp = 'Egp',
    Gbp = 'Gbp',
    Tzs = 'Tzs',
    Usd = 'Usd',
    Uyu = 'Uyu',
    Uzs = 'Uzs',
    Wst = 'Wst',
    Yer = 'Yer',
    Twd = 'Twd',
    Uyw = 'Uyw',
    Ves = 'Ves',
    Cuc = 'Cuc',
    Zwl = 'Zwl',
    Byn = 'Byn',
    Tmt = 'Tmt',
    Ghs = 'Ghs',
    Sdg = 'Sdg',
    Uyi = 'Uyi',
    Rsd = 'Rsd',
    Mzn = 'Mzn',
    Azn = 'Azn',
    Ron = 'Ron',
    Che = 'Che',
    Chw = 'Chw',
    Try = 'Try',
    Xaf = 'Xaf',
    Xcd = 'Xcd',
    Xof = 'Xof',
    Xpf = 'Xpf',
    Xba = 'Xba',
    Xbb = 'Xbb',
    Xbc = 'Xbc',
    Xbd = 'Xbd',
    Xau = 'Xau',
    Xdr = 'Xdr',
    Xag = 'Xag',
    Xpt = 'Xpt',
    Xts = 'Xts',
    Xpd = 'Xpd',
    Xua = 'Xua',
    Zmw = 'Zmw',
    Srd = 'Srd',
    Mga = 'Mga',
    Cou = 'Cou',
    Afn = 'Afn',
    Tjs = 'Tjs',
    Aoa = 'Aoa',
    Bgn = 'Bgn',
    Cdf = 'Cdf',
    Bam = 'Bam',
    Eur = 'Eur',
    Mxv = 'Mxv',
    Uah = 'Uah',
    Gel = 'Gel',
    Bov = 'Bov',
    Pln = 'Pln',
    Brl = 'Brl',
    Clf = 'Clf',
    Xsu = 'Xsu',
    Usn = 'Usn',
    Xxx = 'Xxx',
}

export enum PaymentSystemsProto {
    PaymentSystemUndefined = 'PaymentSystemUndefined',
    PaymentSystemPaypal = 'PaymentSystemPaypal',
    PaymentSystemNuvei = 'PaymentSystemNuvei',
    PaymentSystemHexopay = 'PaymentSystemHexopay',
}

export enum PaymentOptionsProto {
    PaymentOptionNone = 'PaymentOptionNone',
    PaymentOptionPaypal = 'PaymentOptionPaypal',
    PaymentOptionYandex = 'PaymentOptionYandex',
    PaymentOptionNeteller = 'PaymentOptionNeteller',
    PaymentOptionQiwi = 'PaymentOptionQiwi',
    PaymentOptionFastbankttransfer = 'PaymentOptionFastbankttransfer',
    PaymentOptionCreditcard = 'PaymentOptionCreditcard',
    PaymentOptionDebitcard = 'PaymentOptionDebitcard',
}

export enum TransactionReasonProto {
    Undefined = 'Undefined',
    Fraud = 'Fraud',
    Confiscation = 'Confiscation',
    Collusion = 'Collusion',
    Cheating = 'Cheating',
    GoodwillGesture = 'GoodwillGesture',
    Compensation = 'Compensation',
}

export enum TransactionStatusProto {
    Undefined = 'Undefined',
    Created = 'Created',
    Pending = 'Pending',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Failed = 'Failed',
    Succeeded = 'Succeeded',
    Inprogress = 'Inprogress',
}

export enum PunishmentStatusProto {
    Nothing = 'Nothing',
    BanAccount = 'BanAccount',
    ConfiscateBalance = 'ConfiscateBalance',
}

export enum PaymentPeriodLimitTypeProto {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
}

export enum AccountVerificationStatusProto {
    InitLoginKyc = 'InitLoginKyc',
    InitWithdrawalKyc = 'InitWithdrawalKyc',
    InitDepositKyc = 'InitDepositKyc',
    Approved = 'Approved',
    DeniedLoginKyc = 'DeniedLoginKyc',
    DeniedWithdrawalKyc = 'DeniedWithdrawalKyc',
    DeniedDepositKyc = 'DeniedDepositKyc',
}

export class GameTableProto {
    __typename?: 'GameTableProto';
    game_type: GameTypeProto;
    game_mode: GameModeProto;
    limit: Scalars['Float'];
}

export enum CommentTargetTypeProto {
    Player = 'Player',
    BotGroup = 'BotGroup',
}

export enum SecurityCaseTypeProto {
    Undefined = 'Undefined',
    Bot = 'Bot',
    Collusion = 'Collusion',
    Aml = 'Aml',
    Kyc = 'Kyc',
    Finance = 'Finance',
    ExternalRequest = 'ExternalRequest',
    ChipDumping = 'ChipDumping',
    Geolocation = 'Geolocation',
    BlacklistProbable = 'BlacklistProbable',
    SharedInstance = 'SharedInstance',
}

export enum MailTypeProto {
    Mail = 'Mail',
    RealAnnounce = 'RealAnnounce',
    AsyncAnnounce = 'AsyncAnnounce',
}

export enum FriendsGroupActionTypeProto {
    Created = 'Created',
    Updated = 'Updated',
    Deleted = 'Deleted',
}

export enum FriendsGroupUsersActionTypeProto {
    Added = 'Added',
    Removed = 'Removed',
}

export enum BlacklistActionTypeProto {
    BlacklistAdded = 'BlacklistAdded',
    BlacklistRemoved = 'BlacklistRemoved',
}

export enum BlacklistSourceProto {
    Ip = 'Ip',
    Device = 'Device',
    Player = 'Player',
}

export enum BlacklistedPlayerActionTypeProto {
    Added = 'Added',
    Removed = 'Removed',
}

export enum BotDetectionPlayersGroupProto {
    BotDetectionUndefined = 'BotDetectionUndefined',
    BotDetectionBotPlayer = 'BotDetectionBotPlayer',
    BotDetectionEvidence = 'BotDetectionEvidence',
}

export enum BotDetectionActionTypeProto {
    Added = 'Added',
    Removed = 'Removed',
}

export enum AmlLabelProto {
    AccountReview = 'AccountReview',
    ActivityReview = 'ActivityReview',
    GameplayReview = 'GameplayReview',
    EddGameplay = 'EddGameplay',
    EddDeposit = 'EddDeposit',
    EddWithdrawal = 'EddWithdrawal',
    EddPep = 'EddPep',
    VerifyPaymentMethod = 'VerifyPaymentMethod',
}

export enum AmlLabelActionTypeProto {
    Added = 'Added',
    Removed = 'Removed',
}

export enum PlayerSkillCustomLabelProto {
    NoneStatus = 'NoneStatus',
    Vip = 'Vip',
}

export enum PlayerSkillLabelActionTypeProto {
    Added = 'Added',
    Removed = 'Removed',
}

export enum PlayerBlacklistActionTypeProto {
    Added = 'Added',
    Removed = 'Removed',
}

export class UserContactInfoProto {
    __typename?: 'UserContactInfoProto';
    email?: Maybe<Scalars['String']>;
    mobile?: Maybe<PhoneProto>;
}

export class UserAddressInfoProto {
    __typename?: 'UserAddressInfoProto';
    address?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    /** @deprecated Field no longer supported */
    country?: Maybe<Scalars['String']>;
    country_info?: Maybe<CountryInfoProto>;
    street?: Maybe<Scalars['String']>;
}

export enum UserAccountStatusProto {
    Active = 'Active',
    Inactive = 'Inactive',
    Disabled = 'Disabled',
    Blocked = 'Blocked',
}

export enum PlatformProto {
    None = 'None',
    Pkw = 'Pkw',
    Wpk = 'Wpk',
    Wpto = 'Wpto',
    Nj = 'Nj',
    Im = 'Im',
    Mt = 'Mt',
    Bl = 'Bl',
}

export class DeviceProto {
    __typename?: 'DeviceProto';
    uuid?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    operating_system?: Maybe<Scalars['String']>;
    model?: Maybe<Scalars['String']>;
    mac_address?: Maybe<Scalars['String']>;
    agent?: Maybe<Scalars['String']>;
}

export class TimestampInput {
    seconds?: Maybe<Scalars['Long']>;
    nanos?: Maybe<Scalars['Long']>;
}

export enum GameTypeProto {
    GameIdDummy = 'GameIdDummy',
    World = 'World',
    Texas = 'Texas',
    StarSeat = 'StarSeat',
    CowBoy = 'CowBoy',
    AofServer = 'AofServer',
    HumanBoy = 'HumanBoy',
    ZoomTexas = 'ZoomTexas',
    ZoomTexasMax = 'ZoomTexasMax',
    VideoCowboy = 'VideoCowboy',
    BetServer = 'BetServer',
    PokerMaster = 'PokerMaster',
    Jackfruit = 'Jackfruit',
    BlMtt = 'BlMtt',
    DataServer = 'DataServer',
}

export enum GameModeProto {
    Na = 'Na',
    Normal = 'Normal',
    Match = 'Match',
    Short = 'Short',
    Other = 'Other',
}

export class PhoneProto {
    __typename?: 'PhoneProto';
    area?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
}

export class CountryInfoProto {
    __typename?: 'CountryInfoProto';
    name?: Maybe<Scalars['String']>;
    iso_alpha2_code?: Maybe<Scalars['String']>;
}
