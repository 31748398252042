import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Snackbar} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {RootState} from 'typesafe-actions';

import {clearMessageAction} from '../actions';

const MessageSnackBar = () => {
    const message = useSelector((rootState: RootState) => rootState.messageSnackBar.message);
    const messageValues = useSelector((rootState: RootState) => rootState.messageSnackBar.values);
    const isError = useSelector((rootState: RootState) => rootState.messageSnackBar.isError);
    const dispatch = useDispatch();

    const clearErrors = () => dispatch(clearMessageAction());

    const handleSnackbarClose = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: string) => {
        if (reason !== 'clickaway') {
            clearErrors();
        }
    };

    const handleAlertClose = () => {
        clearErrors();
    };

    return (
        <Snackbar
            open={message !== null}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
            <MuiAlert onClose={handleAlertClose} elevation={6} variant="filled" severity={isError ? 'error' : 'success'}>
                {message ? <FormattedMessage {...message} values={messageValues} /> : <></>}
            </MuiAlert>
        </Snackbar>
    );
};

export default MessageSnackBar;
