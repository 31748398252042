import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionAccessibleItem, PermissionEnum} from '@models/permissions';
import {Policy} from '@auth';

export const withdrawalActionsPermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.Payment,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.WithdrawalProcessingRisk,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
    },
];

export const withdrawalRiskUpdatePermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.WithdrawalProcessingRisk,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
    },
];

export const withdrawalPaymentUpdatePermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.Payment,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.WithdrawalProcessing,
    },
];

export const redeemRevenueSharePermissions: PermissionAccessibleItem[] = [
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.EntityTransactions,
        submoduleName: SubmoduleName.RevenueShare,
    },
    {
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.EntityTransactions,
    },
];

export const manualTransactionAddPolicies: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Update]},
    {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.FinanceActions, permissions: [PermissionEnum.Update]},
];

export const manualTransactionCreateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'ManualTransactionCreateResource',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.ActionCenter),
        new Policy(ModuleName.ActionCenter, null, 'create'),
        new Policy(ModuleName.ActionCenter, SubmoduleName.ManualTransaction),
        new Policy(ModuleName.ActionCenter, SubmoduleName.ManualTransaction, 'create'),
    ]),
});
