import React from 'react';

import {usePolicyAccessCheck} from './hooks';
import {NoAccessInfoMessage} from './NoAccessInfoMessage';
import {AccessControlIndicator, PolicyRestrictedResource} from './types';

export function withPolicyAccessCheck<TProps>(
    WrappedComponent: React.ComponentType<TProps>,
    resource: PolicyRestrictedResource,
    indicator: AccessControlIndicator = AccessControlIndicator.InfoMessage
) {
    return function PolicyAccessCheck(props: TProps) {
        const hasAccess = usePolicyAccessCheck(resource);

        function renderNoAccessResult() {
            let result: JSX.Element;
            switch (indicator) {
                case AccessControlIndicator.InfoMessage:
                    result = <NoAccessInfoMessage />;
                    break;
                case AccessControlIndicator.Disabled:
                    result = <WrappedComponent {...props} disabled />;
                    break;
                default:
                    result = <></>;
                    break;
            }
            return result;
        }

        return hasAccess ? <WrappedComponent {...props} /> : renderNoAccessResult();
    };
}
