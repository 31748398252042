import {ManagedGameType} from '@redux/entity';

export class GameTemplateViewModel {
    gameType: ManagedGameType;
    id: string;
    name?: string;
    is_enabled?: boolean;
    labels?: number[];
    stakes?: StakesViewModel;
    players?: string;
    tables?: number[];
    updated_at?: number;
}

export class StakesViewModel {
    sb: number;
    bb: number;
    hasStraddle: boolean;
    ante: number;
    currency: string;
}

export type GameTemplateViewModelKeys = keyof GameTemplateViewModel | 'modify';

export class GameTemplateIdsResponse {
    available_template_id_list?: number[];
}

export class GameTemplateEnabledIdsResponse {
    enabled_templates_ids?: number[];
}

export class GameTemplateSettings {
    auto_buyin_val?: number;
    auto_buyout_val?: number;
    auto_start_num?: number;
    auto_withdraw?: boolean;
    bottom_limit?: number;
    currencyType?: GameCurrency;
    game_mode: number;
    force_showcard?: boolean;
    is_ban_vpn?: boolean;
    limitnums?: [
        {
            CurNum: number;
            max: number;
            min: number;
            num: number;
        }
    ];
    name?: string;
    openRoomLimit?: boolean;
    plats: GamePlatform[];
    player_count_max: number;
    rule_ante_amount: number;
    rule_blind_enum: number;
    rule_buyin_fold: number;
    rule_buyin_min_enum: number;
    rule_time_limit: number;
    rule_switch_anti_cheat?: number;
    rule_switch_force_straddle?: number;
}

export class GameTemplateSettingsViewModel {
    minPlayerCount: number;
    autoWithdraw: boolean;
    currencyType: GameCurrency;
    forceShowCard: boolean;
    vpnRestriction: boolean;
    name: string;
    newMemberRestriction: boolean;
    newPlayerCountSitTogether: number;
    playerCountMax: number;
    ante: number;
    autoBuyout: number;
    blindId: number;
    maxBuyIn: number;
    minBuyIn: number;
    gpsRestriction: boolean;
    forceStraddle: boolean;
}

export enum GameCurrency {
    USD = 101,
    CNY = 2,
}

export enum GamePlatform {
    Me = 0,
    WPK = 1,
    BL = 2,
    MemePoker = 3,
    WPTG = 4,
}

export class GameTemplateResponse {
    new_template_Id: number;
    template: {
        param: GameTemplateSettings;
        updated_at: number;
    };
}

export class GameTemplatePlayersResponse {
    data: {
        uids: number[];
    };
}

export class GameBlindConfigResponse {
    data: {
        blind_list: GameBlindConfig[];
    };
}

export class GameBlindConfig {
    id: number;
    sb: number;
    bb: number;
}

export type GameTemplateMappingArgs = {
    gameType: ManagedGameType;
    isEnabled: boolean;
    rooms: number[];
    players: number[];
    blinds: GameBlindConfig[];
};

export type GameTemplateViewModelMappingArgs = {
    currency: string;
};

export type EnableGameTemplateRequestBody = {
    enable_template_id_list: number[];
};

export type DisableGameTemplateRequestBody = {
    disable_template_id_list: number[];
};

export type DisableGameTemplateResponse = {
    disabled_templates_ids: number[];
};

export const defaultTimeLimit = 1;
export const defaultGameMode = 1;
export const defaultGamePlatform = GamePlatform.Me;
export const defaultPlayersMaxLimit = 1000;
