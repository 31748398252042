import {errorMessages} from '../app/intl/shared-resources/serverResponse';

import {SubscriptionError, SubscriptionErrorList} from './types';

const isSubscriptionValid = (i: SubscriptionError) => i?.message.includes(errorMessages.fieldUndefinedValidationError.defaultMessage);

export const isSubscriptionAvailable = (errorList: SubscriptionErrorList) => {
    return (
        (Array.isArray(errorList?.errors) && !errorList?.errors?.some(isSubscriptionValid)) ||
        (Array.isArray(errorList?.graphQLErrors) && !errorList?.graphQLErrors?.some(isSubscriptionValid))
    );
};
