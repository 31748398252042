import {from, of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {mergeMap} from '@otel';
import {RootEpic} from '@redux';

import {requestEpic} from 'src/common/epics';
import {protectEpics} from '../../features/app/error-handling/epics';
import {handleErrorResponseAction, showMessageAction} from '../../features/message-snack-bar/actions';
import {createGridEpics} from '../../features/module-shared/epics';
import {patchEpic} from '../../features/module-temp-storage/epics';

import {localizedRegistrationInfo} from './components/RegistrationInfo.localize';
import actions from './actions';
import {filterSelector} from './selectors';
import {domain, PlayerProfile, PlayerProfileGridItem} from './types';

const resetPasscodeEpic: RootEpic = requestEpic(actions.resetPasscode, (payload, _, container) => {
    return from(container.registrationInfo.resetPasscode(payload));
});

const resetPasscodeSucceedEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(actions.resetPasscode.success)),
        mergeMap(() => of(showMessageAction({message: localizedRegistrationInfo.resetPasscodeSuccess})))
    );

const resetPasscodeFailedEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(actions.resetPasscode.failure)),
        mergeMap(action => of(handleErrorResponseAction(action.payload)))
    );

const disableTwoFAEpic: RootEpic = patchEpic(actions.disableTwoFA, (payload, _, container) => {
    return from(container.registrationInfo.disableTwoFA(payload.id, payload.item));
});

const editEmailEpic: RootEpic = patchEpic(actions.editEmail, (payload, _, {registrationInfo}) => {
    return registrationInfo.editEmail(payload.id, payload.item);
});

const editPhoneEpic: RootEpic = patchEpic(actions.editPhone, (payload, _, {registrationInfo}) => {
    return registrationInfo.editPhone(payload.id, payload.item);
});

export default protectEpics(
    createGridEpics<PlayerProfile, PlayerProfileGridItem>(domain, c => c.registrationInfo, filterSelector),
    resetPasscodeEpic,
    resetPasscodeSucceedEpic,
    resetPasscodeFailedEpic,
    disableTwoFAEpic,
    editEmailEpic,
    editPhoneEpic
);
