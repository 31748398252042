import {MultiKeyFilterTextValue} from '@components/filter/types';

export class BoUserViewModel {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    roles?: string[];
    loginEnabled?: boolean;
    boAgentPlayerId?: string;
}

export type BoUserViewModelKeys = keyof BoUserViewModel;

export type BoUserFilterKeys = 'text';
export type BoUserTextFilterKeys = 'email' | 'firstName' | 'lastName' | 'agentPlayerId' | 'em_fn_ln';

export type BoUserFilterName = 'em_fn_ln_apId';

export class BoUserFilterModel implements Record<BoUserFilterKeys, unknown> {
    text: MultiKeyFilterTextValue<BoUserTextFilterKeys>;
}
