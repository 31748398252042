import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    dropzoneContentDefaultLinkToChooseFile: {
        id: 'DDZ_linkToChooseFile',
        defaultMessage: 'choose file',
    },
    dropzoneContentDefaultPlaceholder: {
        id: 'DDZ_defaultPlaceholder',
        defaultMessage: 'Drag and Drop file here or {linkToDialogWindow}',
    },
});
