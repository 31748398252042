import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useDropzoneClasses = makeStyles()((theme: CustomTheme) => ({
    dropzoneContainer: {
        color: theme.palette.secondary.main,
    },
    dropzone: {
        border: `2px ${theme.palette.secondary.main} dashed`,
        borderRadius: theme.shape.borderRadius * 2.5,
        position: 'relative',
        height: '410px',
    },
    dropzoneActive: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
    dropzoneFooter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
    },
}));
