import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {useDispatch} from 'react-redux';
import {ButtonGroup} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

import {setJurisdictionLocaleAction} from '../../features/app/config/actions';
import {useJurisdictionConfig} from '../../features/app/config/hooks';
import {JurisdictionLocales} from '../../features/app/config/types';

import {Dropdown} from './dropdown/Dropdown';
import {DropdownItem} from './dropdown/DropdownItem';

//TODO: [BO-2678] Move files to src/features/app/i18n
const useClasses = makeStyles()((theme: CustomTheme) => ({
    dropdown: {
        color: theme.palette.secondary.contrastText,
    },
}));

const localized = defineMessages({
    enLocale: {
        id: 'enLocale',
        defaultMessage: 'EN',
    },
    chLocale: {
        id: 'chLocale',
        defaultMessage: 'CH',
    },
    krLocale: {
        id: 'krLocale',
        defaultMessage: 'KR',
    },
});

const localeMap: Record<JurisdictionLocales, MessageDescriptor> = {
    en: localized.enLocale,
    'zh-CN': localized.chLocale,
    'ko-KR': localized.krLocale,
};

const LocaleSwitch = ({className}: React.HTMLAttributes<HTMLElement>) => {
    const {locale} = useJurisdictionConfig();
    const dispatch = useDispatch();
    const {classes} = useClasses();

    const switchLocale = (locale: JurisdictionLocales) => {
        dispatch(setJurisdictionLocaleAction(locale));
    };

    const allLocales: JurisdictionLocales[] = ['en', 'zh-CN', 'ko-KR'];

    return (
        <ButtonGroup className={className}>
            <Dropdown buttonContent={<LocalizedText label={localeMap[locale]} />} variant="text" className={classes.dropdown}>
                {allLocales.map((l: JurisdictionLocales) => (
                    <DropdownItem key={l} disabled={l === locale} onClick={() => switchLocale(l)} label={localeMap[l]} />
                ))}
            </Dropdown>
        </ButtonGroup>
    );
};

export default LocaleSwitch;
