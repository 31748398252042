import {DocumentNode, NormalizedCacheObject} from '@apollo/client';
import {Observable, Subscriber, Subscription} from 'rxjs';

import {Subscription as GqlSubscription} from '@models/generated/graphql';
import {GraphQLServiceBaseObsolete} from '@services/deprecated';
import {IGQlFilterVariables} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';

export abstract class RealtimeSubscriptionService {
    private readonly _client: GraphQLServiceBaseObsolete;
    private _activeSubscriptions: Subscription[] = [];
    private _subscriptionsToRemove: Subscription[] = [];

    constructor(client: ApolloClientProxy<NormalizedCacheObject>) {
        this._client = new GraphQLServiceBaseObsolete(client);
    }

    prepareSubscriptionToRemove(): void {
        this._subscriptionsToRemove = [...this._subscriptionsToRemove, ...this._activeSubscriptions];
        this._activeSubscriptions = [];
    }

    cancelSubscription(): void {
        this._subscriptionsToRemove.forEach(s => s?.unsubscribe());
        this._subscriptionsToRemove = [];
    }

    protected createSubscription(query: DocumentNode, variables?: IGQlFilterVariables): Observable<GqlSubscription> {
        return new Observable((subscriber: Subscriber<GqlSubscription>) => {
            this._activeSubscriptions.push(
                this._client.createSubscription(query, variables).subscribe({
                    next: args => {
                        return subscriber.next(args);
                    },
                    error: err => {
                        return subscriber.error(err);
                    },
                    complete: () => {
                        return subscriber.complete();
                    },
                })
            );
        });
    }
}
