import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {localizedFeatureAccessStatus} from '../../app/intl/shared-resources/featureAccessStatus';

export type LockedDepositValue = {
    deposit_status: FeatureAccessStatus;
};

export const useDepositStatusFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: LockedDepositValue = JSON.parse(json);

    return value?.deposit_status ? [{key: formatMessage(localizedFeatureAccessStatus[value.deposit_status])}] : null;
};
