import {makeStyles} from 'tss-react/mui';

export const useClasses = makeStyles()(theme => ({
    viewAutocomplete: {
        width: '100%',
        height: '32px',
    },
    viewAutocompletePopupIndicator: {
        padding: 0,
        marginTop: theme.spacing(-1),
        '&:hover': {
            background: 'none',
        },
    },
}));
