import React from 'react';
import {Box, Card, CardContent} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {defineAccessControlledResource, Resource, usePolicyAccessCheck} from '@access-control';
import {SortButton} from '@components/button/SortButton';
import {AttachmentsItem} from '@components/comment/AttachmentsItem';
import StyledPagination from '@components/StyledPagination';
import {Policy} from '@auth';

import {useAttachments, useAttachmentsActions} from '../hooks';
import {commentReadPolicyRule} from '../policies';

import {BasicNotesProps} from './types';

const useClasses = makeStyles()(theme => ({
    attachmentsCard: {
        height: '100%',
        overflowY: 'auto',
    },
    attachmentsContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        rowGap: `${theme.spacing(2)}px`,
    },
    attachmentsSorting: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        margin: theme.spacing(-0.5, 0),
    },
    attachmentsList: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
    },
    attachmentsPaging: {
        margin: theme.spacing(0, -2),
    },
}));

const resource: Resource<Policy[]> = defineAccessControlledResource({
    name: 'NotesAttachments',
    rules: commentReadPolicyRule,
});

export function NotesAttachments({readEntity, filterType}: BasicNotesProps) {
    const {classes} = useClasses();
    const hasAccess = usePolicyAccessCheck(resource);

    const {attachments, totalCount, page, pageSize, sortDirection, handleSortDirectionChange, handlePageSizeChange, handlePageChange} =
        useAttachments({viewType: 'NotesAttachments', entity: readEntity, filterType});
    const {download} = useAttachmentsActions();

    return hasAccess ? (
        <Card className={classes.attachmentsCard} data-testid="attachments">
            <CardContent className={classes.attachmentsContainer}>
                <Box className={classes.attachmentsSorting}>
                    <SortButton onSortChanged={handleSortDirectionChange} direction={sortDirection} />
                </Box>
                <Box className={classes.attachmentsList}>
                    {attachments?.map(item => (
                        <AttachmentsItem key={item.id} {...item} onDownload={() => download(item)} />
                    ))}
                </Box>
                {attachments?.length > 0 ? (
                    <Box className={classes.attachmentsPaging}>
                        <StyledPagination
                            count={totalCount}
                            page={page}
                            labelRowsPerPage=""
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            rowsPerPage={pageSize}
                        />
                    </Box>
                ) : null}
            </CardContent>
        </Card>
    ) : null;
}
