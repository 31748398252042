import React from 'react';
import {defineMessages} from 'react-intl';

import {FilterProps} from 'src/features/shared/filter/types';

import FilterValueRangePicker from './FilterValueRangePicker';
import {ValueRangePickerOptions, ValueRangePickerType} from './valueRangePickerTypes';

const localized = defineMessages({
    openDurationFilter: {
        id: 'DurationRangePicker_filterOpenDuration',
        defaultMessage: 'Open Duration',
    },
    rangeFilterLabel: {
        id: 'DurationRangePicker_filterRangeLabel',
        defaultMessage: 'Relative Hour(s)',
    },
});

type DurationRangePickerProps = Omit<FilterProps, 'filter'> & {
    keys: string | string[];
};

function DurationRangePicker({filterString, onFilterChange, domain, keys}: DurationRangePickerProps) {
    return (
        <FilterValueRangePicker
            onFilterChange={onFilterChange}
            domain={domain}
            filter={{
                label: localized.openDurationFilter,
                key: keys,
                options: {
                    type: ValueRangePickerType.Hours,
                    rangeLabel: localized.rangeFilterLabel,
                } as ValueRangePickerOptions,
            }}
            filterString={filterString}
        />
    );
}

export default DurationRangePicker;
