import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {map} from '@otel';
import {Policy, UserManagerExtended} from '@auth';
import {BrandConfig, CountryConfig, IBrandService} from '@brand';

export type Brand = 'WPTG' | 'WPTGO' | 'WPTPH';
const brandPolicies = ['wpt-global', 'wpt-asia', 'wpt-ph'] as const;
type BrandPolicy = (typeof brandPolicies)[number];
export enum LicenseType {
    NONE = null,
    ROW = 0,
    MX = 1,
    KR = 2,
    PH = 3,
}

@injectable()
export class BrandService implements IBrandService<Brand, LicenseType> {
    private readonly _userManager: UserManagerExtended;

    constructor(@inject(ServiceTypes.UserManager) userManager: UserManagerExtended) {
        this._userManager = userManager;
    }

    public getBrands(): Observable<BrandConfig<Brand, LicenseType>> {
        const policies = this._userManager.getPolicies();

        return policies.pipe(
            map(policies => {
                const brands: Brand[] = this.getBrandsFromPolicies(policies);
                const brandConfigs: BrandConfig<Brand, LicenseType>[] = this.getBrandConfigs(brands);

                return this.joinBrandConfigs(brandConfigs);
            })
        );
    }

    private joinBrandConfigs(brandConfigs: BrandConfig<Brand, LicenseType>[]): BrandConfig<Brand, LicenseType> {
        const includedCountries = brandConfigs?.filter(b => b.countries.mode === 'include')?.flatMap(b => b.countries.iso2codeList) ?? [];
        const excludedCountries = brandConfigs?.filter(b => b.countries.mode === 'exclude')?.flatMap(b => b.countries.iso2codeList) ?? [];

        const countries: CountryConfig =
            excludedCountries.length > 0 || includedCountries.length === 0
                ? {
                      iso2codeList: excludedCountries.filter(e => !includedCountries?.find(i => i === e)),
                      mode: 'exclude',
                  }
                : {iso2codeList: includedCountries, mode: 'include'};

        return {
            countries,
            brands: brandConfigs?.flatMap(c => c.brands),
            licenseTypes: [...new Set(brandConfigs?.flatMap(c => c.licenseTypes))],
        };
    }

    private getBrandConfigs(brands: Brand[]) {
        const brandConfigMap: Record<Brand, BrandConfig<Brand, LicenseType>> = {
            WPTG: this.getWPTGConfig(),
            WPTGO: this.getWPTGOConfig(),
            WPTPH: this.getWPTPHConfig(),
        };

        const brandConfigs: BrandConfig<Brand, LicenseType>[] = brands.map(b => brandConfigMap[b]);

        return brandConfigs;
    }

    private getBrandsFromPolicies(policies: Policy[]) {
        const policyBrandMap: Record<BrandPolicy, Brand> = {
            'wpt-global': 'WPTG',
            'wpt-asia': 'WPTGO',
            'wpt-ph': 'WPTPH',
        };

        const brands: Brand[] = Array.from(
            new Set(
                policies
                    ?.map(p => p.parse()?.module)
                    ?.filter(p => brandPolicies.find(brandPolicy => brandPolicy === p))
                    ?.map((p: BrandPolicy) => policyBrandMap[p])
            )
        );

        return brands;
    }

    private getWPTPHConfig(): BrandConfig<Brand, LicenseType> {
        return {countries: {iso2codeList: ['PH'], mode: 'include'}, brands: ['WPTPH'], licenseTypes: [LicenseType.PH]};
    }

    private getWPTGOConfig(): BrandConfig<Brand, LicenseType> {
        return {
            countries: {iso2codeList: ['KR', 'VN', 'BR', 'JP', 'TH', 'TW'], mode: 'include'},
            brands: ['WPTGO'],
            licenseTypes: [LicenseType.KR],
        };
    }

    private getWPTGConfig(): BrandConfig<Brand, LicenseType> {
        //NOTE: WPTG temporarily includes PH until data segregation is done for PH brand
        return {
            countries: {iso2codeList: ['PH', 'KR'], mode: 'exclude'},
            brands: ['WPTG'],
            licenseTypes: [LicenseType.NONE, LicenseType.MX, LicenseType.PH, LicenseType.ROW],
        };
    }
}
