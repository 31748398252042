import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';

const useClasses = makeStyles()(theme => ({
    layoutDetailsBlock: {
        margin: theme.spacing(0, 4, 4),
    },
    layoutDetailsBlockTitle: {
        margin: theme.spacing(3, 0, 2),
    },
    layoutDetailsBlockToolbar: {
        marginBottom: theme.spacing(2.5),
    },
}));

type LayoutDetailsBlockProps = {
    title?: string | MessageDescriptor;
    toolbar?: React.ReactNode;
    content: React.ReactNode;
};

export const LayoutDetailsBlock = ({content, toolbar, title}: LayoutDetailsBlockProps) => {
    const {classes} = useClasses();

    return (
        <Box className={classes.layoutDetailsBlock}>
            <Typography className={classes.layoutDetailsBlockTitle} variant="h6">
                <LocalizedText label={title} />
            </Typography>
            <Box className={classes.layoutDetailsBlockToolbar}>{toolbar}</Box>
            {content}
        </Box>
    );
};
