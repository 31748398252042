import {defineMessages} from 'react-intl';

export const localizedMarketingBonusCode = defineMessages({
    marketingBonusCode: {
        id: 'MBC_marketingBonusCode',
        defaultMessage: 'Marketing Bonus Code',
    },
    marketingCode: {
        id: 'MBC_marketingCode',
        defaultMessage: 'Marketing Code',
    },
    btag: {
        id: 'MBC_btag',
        defaultMessage: 'Btag / Referrer ID',
    },
    type: {
        id: 'MBC_type',
        defaultMessage: 'Type',
    },
    user: {
        id: 'MBC_user',
        defaultMessage: 'User',
    },
    timestamp: {
        id: 'MBC_timestamp',
        defaultMessage: 'Timestamp',
    },

    //filters
    marketingCodeFilter: {
        id: 'MBC_marketingCodeFilter',
        defaultMessage: 'Marketing Code',
    },
    marketingCodeFilterPlaceholder: {
        id: 'MBC_marketingCodeFilterPlaceholder',
        defaultMessage: 'Search by Marketing Code',
    },
    typeFilter: {
        id: 'MBC_typeFilter',
        defaultMessage: 'Type',
    },
});
