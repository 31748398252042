export const domain = 'legal-docs';

export class LegalDocumentItem {
    name: string;
    version: number;
    acceptedAt: Date;
    content: string;
    uid: string;
}

export enum LegalDocType {
    PrivacyPolicy = 'PrivacyPolicy',
    TermsAndConditions = 'TermsAndConditions',
}
