import React from 'react';

import {MuiDataGridServer} from '@components/data-grid/mui';
import {Entity, EntityFilterType, EntityType as EventLogEntityType} from '@models/generated/graphql';
import {EventLogFilterKeys, EventLogQueryFields} from '@redux/entity';

import {Filter, Sorting} from 'src/common/types';
import {useActionHistoryData} from '../hooks';
import {ActionType, domain} from '../types';

import {actionHistoryData} from './ActionHistory.config';

//TODO: [BO-2707] Rename action-history to workspace-block-action-history
export type ActionHistoryGridProps = {
    id: string;
    type: EventLogEntityType;
    filterType: EntityFilterType;
    parent?: Entity;
    actions?: ActionType[];
};

const ActionHistoryGrid = ({id, type, parent, actions, filterType}: ActionHistoryGridProps) => {
    const viewType = 'ActionHistoryGrid';
    const fields: EventLogQueryFields[] = [
        'id',
        'entity.id',
        'entity.type',
        'entity.parent.id',
        'entity.parent.type',
        'trigger_by_id',
        'event_name',
        'prev_value',
        'new_value',
        'trigger_at.seconds',
    ];
    const filters: Filter<any, EventLogFilterKeys>[] = [
        {key: 'entity', value: {id, type, parent}},
        {key: 'event_name', value: actions?.join('|')},
        {key: 'type', value: filterType},
    ];
    const sorting: Sorting<EventLogQueryFields>[] = [
        {
            field: 'trigger_at.seconds',
            sort: 'desc',
        },
    ];
    const {items, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useActionHistoryData(
        viewType,
        fields,
        filters,
        sorting
    );

    return (
        <MuiDataGridServer
            autoHeight={true}
            rows={items}
            rowCount={totalCount}
            columns={actionHistoryData.columns}
            domain={domain}
            page={searchFilter?.paging?.page}
            pageSize={searchFilter?.paging?.pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSortModelChange={handleSortChange}
            sortModel={searchFilter.sorting}
        />
    );
};

export default ActionHistoryGrid;
