import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {usePolicyAccessCheck} from '@access-control';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {useJurisdictionFeature} from '../../app/config/hooks';
import {FeatureName} from '../../app/config/types';
import {useMultiplePermissions} from '../../app/permission/PermissionHoc';
import {userProfileActions} from '../actions';
import {
    financeActionsReadPermissions,
    financeActionsUpdatePermissions,
    financeLockReadResource,
    financeLockUpdateResource,
    p2pActionReadResources,
    p2pActionUpdateResources,
    p2pLockReadResource,
    p2pLockUpdateResource,
} from '../permissions';

import {LockActionProps} from './UserProfileActions';
import {UserProfileLock} from './UserProfileLock';

const localized = defineMessages({
    label: {
        id: 'P2PTransferLock_label',
        defaultMessage: 'P2P transfer',
    },
});

type P2PTransferLockProps = LockActionProps<FeatureAccessStatus>;

function P2PTransferLockInner({uid, status, disabled}: P2PTransferLockProps) {
    const {formatMessage} = useIntl();

    return (
        <UserProfileLock
            action={userProfileActions.changeP2PTransferStatus}
            label={formatMessage(localized.label)}
            uid={uid}
            isLocked={status === FeatureAccessStatus.Locked}
            disabled={disabled}
            dataTestId="p2p-transfer-lock"
        />
    );
}
/**
 * @deprecated
 * <p>Use {@link P2PTransferLockUpdated} instead</p>
 */

function P2PTransferLockObsolete(props: P2PTransferLockProps) {
    const isFinanceLockPolicyAvailiable = useJurisdictionFeature({
        featureName: FeatureName.FinanceActionsNewPolicies,
    });

    const isReadAvailable = isFinanceLockPolicyAvailiable
        ? usePolicyAccessCheck(financeLockReadResource)
        : useMultiplePermissions({allowedPermissions: financeActionsReadPermissions});

    const isUpdateAvailable = isFinanceLockPolicyAvailiable
        ? usePolicyAccessCheck(financeLockUpdateResource)
        : useMultiplePermissions({allowedPermissions: financeActionsUpdatePermissions});

    const getComponent = () => (isUpdateAvailable ? <P2PTransferLockInner {...props} /> : <P2PTransferLockInner {...props} disabled />);

    return isReadAvailable ? getComponent() : <></>;
}

function P2PTransferLockUpdated(props: P2PTransferLockProps) {
    const isP2pLockPolicyAvailiable = useJurisdictionFeature({
        featureName: FeatureName.FinanceActionsNewPolicies,
    });

    const isReadAvailable = usePolicyAccessCheck(isP2pLockPolicyAvailiable ? p2pLockReadResource : p2pActionReadResources);

    const isUpdateAvailable = usePolicyAccessCheck(isP2pLockPolicyAvailiable ? p2pLockUpdateResource : p2pActionUpdateResources);

    const getComponent = () => (isUpdateAvailable ? <P2PTransferLockInner {...props} /> : <P2PTransferLockInner {...props} disabled />);

    return isReadAvailable ? getComponent() : <></>;
}

export function P2PTransferLock(props: P2PTransferLockProps) {
    const isAvailable = useJurisdictionFeature({featureName: FeatureName.P2POptionsHideForPHRoles});
    const Component: React.ComponentType<P2PTransferLockProps> = isAvailable ? P2PTransferLockUpdated : P2PTransferLockObsolete;
    return <Component {...props} />;
}
