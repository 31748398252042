import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledAccordion from '@components/StyledAccordion';
import {agentReadPolicy} from '@models/permissions/permissions';

import {useAgentPolicy} from '../../app/permission/PermissionHoc';
import {BlockPlayerLabels} from '../../block-label-group-list/components/BlockPlayerLabels';

import {UserAddressInfo} from './UserAddressInfo';
import {UserPersonalInfo} from './UserPersonalInfo';

const localized = defineMessages({
    personalInfo: {
        id: 'UserDetailsPersonalInfo_personalInfo',
        defaultMessage: 'Personal Info',
    },
});

const useClasses = makeStyles()(theme => ({
    userDetailsPersonalInfoContainer: {
        width: '100%',
        display: 'grid',
        rowGap: theme.spacing(2.5),
        marginBottom: theme.spacing(2),
    },
    userDetailsPersonalInfo: {
        paddingTop: 0,
        boxShadow: 'none',
    },
}));

type UserDetailsPersonalInfoProps = {
    userId: string;
};

export const UserDetailsPersonalInfo = ({userId}: UserDetailsPersonalInfoProps) => {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();
    const isBlockAvailable = useAgentPolicy('UserDetailsPersonalInfo', userId, agentReadPolicy);

    return isBlockAvailable ? (
        <StyledAccordion
            header={
                <>
                    <Box>{formatMessage(localized.personalInfo)}</Box>
                    <BlockPlayerLabels uid={userId} />
                </>
            }
            componentId="personalInfo"
            unmountOnCollapse
            children={
                <Box className={classes.userDetailsPersonalInfoContainer}>
                    <UserPersonalInfo uid={userId} />
                    <UserAddressInfo uid={userId} />
                </Box>
            }
        />
    ) : (
        <></>
    );
};
