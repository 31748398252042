import {GridCellParams} from '@mui/x-data-grid';

import {Jurisdiction} from '../../features/app/config/types';

import {
    LegalDocType,
    LegalDocumentRead,
    LegalDocumentWrite,
    LegalDocumentWriteKey,
    VersionAcceptanceDocument,
    VersionAcceptanceGridItem,
} from './types';

type VersionAcceptanceDocumentsData = {
    latestDocument: VersionAcceptanceDocument;
    previousDocuments: VersionAcceptanceDocument[];
};

export const getVersionDocumentsData = (params: GridCellParams, documentName: string): VersionAcceptanceDocumentsData => {
    const item = params.row as VersionAcceptanceGridItem;

    const acceptedDocuments = item.documents?.filter(d => d.accepted && d.name.includes(documentName));

    const latestDocument = acceptedDocuments?.reduce(
        (latest, document) => (latest && latest.createdAt > document.createdAt ? latest : document),
        null
    );

    const previousDocuments = acceptedDocuments?.length ? acceptedDocuments.filter(l => l.id !== latestDocument.id) : [];

    return {latestDocument, previousDocuments};
};

export function getLegalDocQueryKeyByDocType(
    docType: LegalDocType,
    jurisdiction: Jurisdiction,
    formatter: (jurisdiction: Jurisdiction, name: string) => string
) {
    return formatter(jurisdiction, docType.toString());
}

export function getLegalDocId(jurisdiction: Jurisdiction, name: string, version?: number) {
    const result = `${jurisdiction.toString()}/${name}`;

    return version !== undefined ? `${result}?${nameof<LegalDocumentRead>(i => i.version)}=${version}` : result;
}

export function getLegalDocPath(jurisdiction: Jurisdiction, name: string) {
    return `?${nameof<LegalDocumentWriteKey>(i => i.jurisdiction)}=${jurisdiction}&${nameof<LegalDocumentWriteKey>(i => i.name)}=${name}`;
}

export function convertReadToWriteModel(readModel: LegalDocumentRead): LegalDocumentWrite {
    const idParams = readModel.id.split('/');
    return {
        document: readModel.document,
        id: readModel.id,
        required: readModel.required,
        key: {
            jurisdiction: idParams[0] as Jurisdiction,
            name: idParams[1],
        },
    };
}
