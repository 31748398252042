export class TransactionAttemptsSummaryViewModel {
    currency?: string;
    failed_amount?: number;
    failed_count?: number;
    pending_amount?: number;
    pending_count?: number;
    rejected_amount?: number;
    rejected_count?: number;
    successful_amount?: number;
    successful_count?: number;
}

export class GgrViewModel {
    casino_connector?: number;
    casino_poker_flips?: number;
    mtt_service_fee?: number;
    poker_features_purchase?: number;
    poker_guess_hand?: number;
    poker_insurance?: number;
    poker_rake?: number;
}

export class AggregatedFinanceSummaryViewModel {
    uid: string;
    bonus_ratio?: number;
    created_at?: string;
    deposit?: TransactionAttemptsSummaryViewModel;
    end_at?: string;
    generated_rake?: number;
    ggr?: GgrViewModel;
    lifetime_bonus?: number;
    start_at?: string;
    withdrawal?: TransactionAttemptsSummaryViewModel;
}

type ViewModelKeys = keyof AggregatedFinanceSummaryViewModel;
const depositKey: ViewModelKeys = 'deposit';
const ggrKey: ViewModelKeys = 'ggr';
const withdrawalKey: ViewModelKeys = 'withdrawal';

export type AggregatedFinanceSummaryViewModelKeys =
    | keyof Omit<AggregatedFinanceSummaryViewModel, 'deposit' | 'ggr' | 'withdrawal'>
    | `${typeof depositKey}.${keyof TransactionAttemptsSummaryViewModel}`
    | `${typeof ggrKey}.${keyof GgrViewModel}`
    | `${typeof withdrawalKey}.${keyof TransactionAttemptsSummaryViewModel}`;
