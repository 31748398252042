import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {AddUserLabelInput, DeleteUserLabelInput, LabelGroup, UserProfile} from '@models/generated/graphql';
import {map} from '@otel';
import {RootEpic} from '@redux';
import {BaseEpicsBuilder} from '@redux';
import {ILabelGroupService} from '@services/labelGroupService';
import {GqlMutationRequest} from '@services/types';
import {IUserProfileService} from '@services/userProfileService';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {
    AddLabelGroupRequestPayload,
    AddLabelRequestPayload,
    blockUserLabelActions,
    UpdateLabelGroupRequestPayload,
    UpdateLabelRequestPayload,
} from './actions';

export const localized = defineMessages({
    addUserLabelSuccess: {
        id: 'BlockUserLabelEpicsBuilder_addUserLabelSuccess',
        defaultMessage: 'Labels are successfully added to user',
    },
    addUserLabelFailed: {
        id: 'BlockUserLabelEpicsBuilder_addUserLabelFailed',
        defaultMessage: 'Failed add labels to user',
    },
    deleteUserLabelSuccess: {
        id: 'BlockUserLabelEpicsBuilder_deleteUserLabelSuccess',
        defaultMessage: 'Label {labelId} successfully deleted for user {uid}',
    },
    deleteUserLabelFailed: {
        id: 'BlockUserLabelEpicsBuilder_deleteUserLabelFailed',
        defaultMessage: 'Failed to delete label {labelId} for user {uid}',
    },
    addLabelGroupSuccess: {
        id: 'BlockUserLabelEpicsBuilder_addLabelGroupSuccess',
        defaultMessage: 'Label Group {groupName} is successfully created',
    },
    addLabelGroupFailed: {
        id: 'BlockUserLabelEpicsBuilder_addLabelGroupFailed',
        defaultMessage: 'Failed to create label group {groupName}',
    },
    updateLabelGroupSuccess: {
        id: 'BlockUserLabelEpicsBuilder_updateLabelGroupSuccess',
        defaultMessage: 'Label Group {groupName} is successfully updated',
    },
    updateLabelGroupFailed: {
        id: 'BlockUserLabelEpicsBuilder_updateLabelGroupFailed',
        defaultMessage: 'Failed to update label group {groupName}',
    },
    addLabelSuccess: {
        id: 'BlockUserLabelEpicsBuilder_addLabelSuccess',
        defaultMessage: 'Label {labelName} is successfully created',
    },
    addLabelFailed: {
        id: 'BlockUserLabelEpicsBuilder_addLabelFailed',
        defaultMessage: 'Failed to create label {labelName}',
    },
    updateLabelSuccess: {
        id: 'BlockUserLabelEpicsBuilder_updateLabelSuccess',
        defaultMessage: 'Label {labelName} is successfully updated',
    },
    updateLabelFailed: {
        id: 'BlockUserLabelEpicsBuilder_updateLabelFailed',
        defaultMessage: 'Failed to update label {labelName}',
    },
});

@injectable()
export class BlockUserLabelEpicsBuilder extends BaseEpicsBuilder {
    private readonly _userService: IUserProfileService;
    private readonly _labelGroupService: ILabelGroupService;

    constructor(
        @inject(ServiceTypes.UserProfileService) userService: IUserProfileService,
        @inject(ServiceTypes.LabelGroupService) labelGroupService: ILabelGroupService
    ) {
        super();
        this._userService = userService;
        this._labelGroupService = labelGroupService;
    }

    protected buildEpicList(): RootEpic[] {
        return [
            this.buildAddUserLabelRequestEpic(),
            this.buildAddUserLabelSuccessEpics(),
            this.buildAddUserLabelFailureEpics(),
            this.buildDeleteUserLabelRequestEpic(),
            this.buildDeleteUserLabelSuccessEpics(),
            this.buildDeleteUserLabelFailureEpics(),
            this.buildAddLabelGroupRequestEpic(),
            this.buildAddLabelGroupSuccessEpics(),
            this.buildAddLabelGroupFailureEpics(),
            this.buildUpdateLabelGroupRequestEpic(),
            this.buildUpdateLabelGroupSuccessEpics(),
            this.buildUpdateLabelGroupFailureEpics(),
            this.buildAddLabelRequestEpic(),
            this.buildAddLabelSuccessEpics(),
            this.buildAddLabelFailureEpics(),
            this.buildUpdateLabelRequestEpic(),
            this.buildUpdateLabelSuccessEpics(),
            this.buildUpdateLabelFailureEpics(),
        ];
    }

    private buildAddUserLabelRequestEpic(): RootEpic {
        return this.buildRequestEpic<AddUserLabelInput, GqlMutationRequest, UserProfile>(blockUserLabelActions.addUserLabels, payload =>
            this._userService.addUserLabels(payload)
        );
    }

    private buildAddUserLabelSuccessEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.addUserLabels.success)),
                map(() => showMessageAction({message: localized.addUserLabelSuccess}))
            );
    }

    private buildAddUserLabelFailureEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.addUserLabels.failure)),
                map(() => showErrorAction({message: localized.addUserLabelFailed}))
            );
    }

    private buildDeleteUserLabelRequestEpic(): RootEpic {
        return this.buildRequestEpic<DeleteUserLabelInput, GqlMutationRequest, UserProfile>(
            blockUserLabelActions.deleteUserLabel,
            payload => this._userService.deleteUserLabel(payload)
        );
    }

    private buildDeleteUserLabelSuccessEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.deleteUserLabel.success)),
                map(res => {
                    const requestPayload: DeleteUserLabelInput = res?.payload?.requestPayload?.variables?.input;
                    return showMessageAction({
                        message: localized.deleteUserLabelSuccess,
                        values: {labelId: requestPayload.label_id, uid: requestPayload.uid},
                    });
                })
            );
    }

    private buildDeleteUserLabelFailureEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.deleteUserLabel.failure)),
                map(res => {
                    const requestPayload: DeleteUserLabelInput = res?.payload?.requestPayload?.variables?.input;
                    return showErrorAction({
                        message: localized.deleteUserLabelFailed,
                        values: {labelId: requestPayload.label_id, uid: requestPayload.uid},
                    });
                })
            );
    }

    private buildAddLabelGroupRequestEpic(): RootEpic {
        return this.buildRequestEpic<AddLabelGroupRequestPayload, GqlMutationRequest, LabelGroup>(
            blockUserLabelActions.addLabelGroup,
            payload => this._labelGroupService.addLabelGroup(payload.group)
        );
    }

    private buildAddLabelGroupSuccessEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.addLabelGroup.success)),
                map(res =>
                    showMessageAction({
                        message: localized.addLabelGroupSuccess,
                        values: {groupName: res?.payload?.requestPayload?.variables?.label_group?.name},
                    })
                )
            );
    }

    private buildAddLabelGroupFailureEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.addLabelGroup.failure)),
                map(res =>
                    showErrorAction({
                        message: localized.addLabelGroupFailed,
                        values: {groupName: res?.payload?.requestPayload?.variables?.label_group?.name},
                    })
                )
            );
    }

    private buildUpdateLabelGroupRequestEpic(): RootEpic {
        return this.buildRequestEpic<UpdateLabelGroupRequestPayload, GqlMutationRequest, LabelGroup>(
            blockUserLabelActions.updateLabelGroup,
            payload => this._labelGroupService.updateLabelGroup(payload.id, payload.group)
        );
    }

    private buildUpdateLabelGroupSuccessEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.updateLabelGroup.success)),
                map(res =>
                    showMessageAction({
                        message: localized.updateLabelGroupSuccess,
                        values: {groupName: res?.payload?.requestPayload?.variables?.label_group?.name},
                    })
                )
            );
    }

    private buildUpdateLabelGroupFailureEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.updateLabelGroup.failure)),
                map(res =>
                    showErrorAction({
                        message: localized.updateLabelGroupFailed,
                        values: {groupName: res?.payload?.requestPayload?.variables?.label_group?.name},
                    })
                )
            );
    }

    private buildAddLabelRequestEpic(): RootEpic {
        return this.buildRequestEpic<AddLabelRequestPayload, GqlMutationRequest, LabelGroup>(blockUserLabelActions.addLabel, payload =>
            this._labelGroupService.addLabel(payload.label)
        );
    }

    private buildAddLabelSuccessEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.addLabel.success)),
                map(res =>
                    showMessageAction({
                        message: localized.addLabelSuccess,
                        values: {labelName: res?.payload?.requestPayload?.variables?.label?.name},
                    })
                )
            );
    }

    private buildAddLabelFailureEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.addLabel.failure)),
                map(res =>
                    showErrorAction({
                        message: localized.addLabelFailed,
                        values: {labelName: res?.payload?.requestPayload?.variables?.label?.name},
                    })
                )
            );
    }

    private buildUpdateLabelRequestEpic(): RootEpic {
        return this.buildRequestEpic<UpdateLabelRequestPayload, GqlMutationRequest, LabelGroup>(
            blockUserLabelActions.updateLabel,
            payload => this._labelGroupService.updateLabel(payload.id, payload.label)
        );
    }

    private buildUpdateLabelSuccessEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.updateLabel.success)),
                map(res =>
                    showMessageAction({
                        message: localized.updateLabelSuccess,
                        values: {labelName: res?.payload?.requestPayload?.variables?.label?.name},
                    })
                )
            );
    }

    private buildUpdateLabelFailureEpics(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(blockUserLabelActions.updateLabel.failure)),
                map(res =>
                    showErrorAction({
                        message: localized.updateLabelFailed,
                        values: {labelName: res?.payload?.requestPayload?.variables?.label?.name},
                    })
                )
            );
    }
}
