import {createAsyncAction} from 'typesafe-actions';

import {gridModuleActions as getGridModuleActions} from '../../features/module-shared/actions';
import {IFailureResponse} from '../../features/module-shared/types';
import {PatchPayload} from '../../features/module-temp-storage/types';

import {domain} from './types';

const resetPasscodeAction = '/resetPasscode';
const resetPasscodeSucceedAction = '/resetPasscodeSucceed';
const resetPasscodeFailedAction = '/resetPasscodeFailed';

const disableTwoFAAction = '/disableTwoFA';
const disableTwoFASucceedAction = '/disableTwoFASucceed';
const disableTwoFAFailedAction = '/disableTwoFAFailed';

const emailEditAction = '/emailEditAction';
const emailEditSucceedAction = '/emailEditSucceedAction';
const emailEditFailedAction = '/emailEditFailedAction';

const phoneEditAction = '/phoneEditAction';
const phoneEditSucceedAction = '/phoneEditSucceedAction';
const phoneEditFailedAction = '/phoneEditFailedAction';

export default {
    ...getGridModuleActions(domain),

    resetPasscode: createAsyncAction(
        `${domain}${resetPasscodeAction}`,
        `${domain}${resetPasscodeSucceedAction}`,
        `${domain}${resetPasscodeFailedAction}`
    )<string, void, IFailureResponse>(),

    disableTwoFA: createAsyncAction(
        `${domain}${disableTwoFAAction}`,
        `${domain}${disableTwoFASucceedAction}`,
        `${domain}${disableTwoFAFailedAction}`
    )<PatchPayload, PatchPayload, PatchPayload>(),

    editEmail: createAsyncAction(`${domain}${emailEditAction}`, `${domain}${emailEditSucceedAction}`, `${domain}${emailEditFailedAction}`)<
        PatchPayload,
        PatchPayload,
        PatchPayload
    >(),

    editPhone: createAsyncAction(`${domain}${phoneEditAction}`, `${domain}${phoneEditSucceedAction}`, `${domain}${phoneEditFailedAction}`)<
        PatchPayload,
        PatchPayload,
        PatchPayload
    >(),
};
