import React from 'react';

import {NoteFilterKeys} from '@redux/entity';
import {ViewType} from '@redux/view';

import {Filter} from 'src/common/types';

import NoteFilterWorkspace from './NoteFilterWorkspace';

type NoteFilterType = 'workspace';

export type NoteFilterProps = {
    viewType?: ViewType;
    filter: string;
    defaultFilter?: Filter<unknown, NoteFilterKeys>[];
    onFilterChange: (newFilter: Filter[]) => void;
};

type NoteFiltersProps = NoteFilterProps & {
    filters: NoteFilterType[];
};

const NotesFilters = ({filters, ...props}: NoteFiltersProps) => {
    const filterMapping: Record<NoteFilterType, React.ComponentType<NoteFilterProps>> = {
        workspace: NoteFilterWorkspace,
    };

    return (
        <>
            {filters.map(type => {
                const FilterComponent = filterMapping[type];
                return <FilterComponent key={type} {...props} />;
            })}
        </>
    );
};

export default NotesFilters;
