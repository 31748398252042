import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';

const useClasses = makeStyles()(theme => ({
    layoutWorkspaceDetailsHeaderItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: theme.spacing(1),
    },
    layoutWorkspaceDetailsHeaderLabel: {
        color: theme.palette.text.secondary,
        flexShrink: 0,
    },
}));

type LayoutDetailsHeaderItemProps = {
    children: React.ReactNode;
    label?: string | MessageDescriptor;
};

export const LayoutDetailsHeaderItem = ({label, children}: LayoutDetailsHeaderItemProps) => {
    const {classes} = useClasses();

    return (
        <Box className={classes.layoutWorkspaceDetailsHeaderItem}>
            {label && (
                <Typography variant="body2" className={classes.layoutWorkspaceDetailsHeaderLabel}>
                    <LocalizedText label={label} />
                </Typography>
            )}
            {children}
        </Box>
    );
};
