import React from 'react';

import {usePolicyAccessCheck} from '@access-control';
import {customerSupportUpdatePermissions} from '@models/permissions/permissions';

import {useJurisdictionFeature} from 'src/features/app/config/hooks';
import {FeatureName} from 'src/features/app/config/types';
import {useMultiplePermissions} from 'src/features/app/permission/PermissionHoc';
import {BulkTransactionManualUploadStepButton} from '../../block-transaction-bulk-actions';
import {batchTransactionCreateResource} from '../permissions';

export function CustomerSupportBulkManualTransactionButton() {
    const isBatchTransactionPolicyAvailiable = useJurisdictionFeature({
        featureName: FeatureName.FinanceActionsNewPolicies,
    });

    const isAvailable = isBatchTransactionPolicyAvailiable
        ? usePolicyAccessCheck(batchTransactionCreateResource)
        : useMultiplePermissions({
              allowedPermissions: customerSupportUpdatePermissions,
          });

    return isAvailable ? <BulkTransactionManualUploadStepButton /> : <></>;
}
