import {fileActions} from '@file/actions';
import {entityActions} from '@redux/entity';
import {viewActions} from '@redux/view';

import * as globalActions from 'src/common/actions';
import {downloadActions} from 'src/features/block-download';
import {addressInfoActions} from 'src/features/block-user-profile-actions-address/actions';
import * as actionHistoryActions from '../features/action-history/actions';
import * as breadcrumbActions from '../features/app/breadcrumb/actions';
import * as appConfigActions from '../features/app/config/actions';
import * as localizationActions from '../features/app/intl/actions';
import {appSharedActions} from '../features/app-shared/actions';
import {beBonusActions} from '../features/be-bonus/actions';
import {beBonusHistoryActions} from '../features/be-bonus-history/actions';
import {accountVerificationActions} from '../features/block-account-verification/actions';
import {agentLevelReportActions} from '../features/block-agent-level-report/actions';
import {agentReportingActions} from '../features/block-agent-player-reporting-actions/actions';
import {bonusCodeActions} from '../features/block-bonus-code';
import {bulkActionsActions} from '../features/block-bulk-actions';
import {gameRoomActions} from '../features/block-game-room-actions';
import {gameTemplateActions} from '../features/block-game-template-actions';
import {blockUserLabelActions} from '../features/block-label-group-actions/actions';
import {noteActions} from '../features/block-note/actions';
import {notificationsActions} from '../features/block-notifications/actions';
import {playerBonusActions} from '../features/block-player-bonus/actions';
import {playerReferralActions} from '../features/block-player-referral-actions/actions';
import {transactionActions} from '../features/block-transaction-actions/actions';
import {transactionsBulkActions} from '../features/block-transaction-bulk-actions/actions';
import {userLoginActions} from '../features/block-user-login';
import {userProfileActions} from '../features/block-user-profile-actions/actions';
import {personalInfoActions} from '../features/block-user-profile-actions-pii/actions';
import {kycActions} from '../features/kyc/actions';
import * as legalDocsActions from '../features/legal-docs/actions';
import {marketingCodeActions} from '../features/marketing-code/actions';
import * as messageSnackBarActions from '../features/message-snack-bar/actions';
import * as tempStorageActions from '../features/module-temp-storage/actions';
import * as modulesActions from '../features/modules/actions';
import {actions as securityCasesActions} from '../features/player-actions/cases-actions/actions';
import {piiActions} from '../features/player-actions/pii-actions/actions';
import {actions as verificationActions} from '../features/player-actions/verification-actions/actions';
import {playerMessageActions} from '../features/player-message/actions';
import {realtimeNotificationActions} from '../features/realtime-updates/actions';
import {referralSettingsActions} from '../features/referral-settings/actions';
import {referrerSummaryActions} from '../features/referrer-summary/actions';
import * as asyncActions from '../features/shared/async-action/actions';
import * as transactionsActions from '../features/transactions/actions';
import {userProfileActionsObsolete} from '../features/user-profile/actions';
import {userNotificationHistoryActions} from '../features/workspace-details/user-notification-history/actions';
import * as userRegionRelatedInfoActions from '../features/workspace-details/user-region-related-info/actions';
import * as usersActions from '../pages/access-management/actions';
import * as bonusEngineDetailsActions from '../pages/bonus-engine-details/actions';
import {kycDetailsDocumentsActions} from '../pages/kyc-details/kyc-details-documents/actions';
import * as legalDocActions from '../pages/legal-docs/actions';
import * as legalHistoryActions from '../pages/legal-docs/history/actions';
import * as marketingBonusCodeActions from '../pages/marketing-bonus-code/actions';
import * as registrationInfoActions from '../pages/registration-info/actions';
import * as rolesActions from '../pages/role-management/actions';
import * as transactionHistoryActions from '../pages/transaction-history/actions';

const actions = {
    globalActions,
    messageSnackBarActions,
    modulesActions,
    usersActions,
    rolesActions,
    registrationInfoActions,
    transactionHistoryActions,
    legalDocActions,
    tempStorageActions,
    localizationActions,
    appConfigActions,
    breadcrumbActions,
    appSharedActions,
    transactionsActions,
    actionHistoryActions,
    legalHistoryActions,
    userRegionRelatedInfoActions,
    kycDetailsDocumentsActions,
    blockUserLabelActions,
    legalDocsActions,
    marketingBonusCodeActions,
    asyncActions,
    notificationsActions,
    bonusEngineDetailsActions,
    piiActions,
    entityActions,
    viewActions,
    securityCasesActions,
    verificationActions,
    realtimeNotificationActions,
    userProfileActionsObsolete,
    kycActions,
    referrerSummaryActions,
    marketingCodeActions,
    bulkActionsActions,
    playerMessageActions,
    userNotificationHistoryActions,
    beBonusActions,
    beBonusHistoryActions,
    transactionsBulkActions,
    fileActions,
    noteActions,
    referralSettingsActions,
    accountVerificationActions,
    transactionActions,
    personalInfoActions,
    userLoginActions,
    playerReferralActions,
    addressInfoActions,
    agentReportingActions,
    agentLevelReportActions,
    userProfileActions,
    gameTemplateActions,
    gameRoomActions,
    bonusCodeActions,
    playerBonusActions,
    downloadActions,
};

export default actions;

export type RootActions = typeof actions;
