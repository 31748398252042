import {defineMessages} from 'react-intl';

import {JumioDocumentType} from '@models/generated/graphql';

export const localizedJumioDocumentType = defineMessages({
    [JumioDocumentType.IdCard]: {
        id: 'jumioDocumentTypeID',
        defaultMessage: 'ID Card',
    },
    [JumioDocumentType.LicenseDriver]: {
        id: 'jumioDocumentTypeLicense',
        defaultMessage: 'License Driver',
    },
    [JumioDocumentType.Passport]: {
        id: 'jumioDocumentTypePassport',
        defaultMessage: 'Passport',
    },
    [JumioDocumentType.Unsupported]: {
        id: 'jumioDocumentTypeUnsupported',
        defaultMessage: 'Unsupported',
    },
    [JumioDocumentType.Visa]: {
        id: 'jumioDocumentTypeVisa',
        defaultMessage: 'Visa',
    },
    [JumioDocumentType.ProofOfAddress]: {
        id: 'jumioDocumentTypeProofOfAddress',
        defaultMessage: 'Proof Of Address',
    },
    [JumioDocumentType.ProofOfPayment]: {
        id: 'jumioDocumentTypeProofOfPayment',
        defaultMessage: 'Proof Of Payment',
    },
    [JumioDocumentType.CustomId]: {
        id: 'jumioDocumentTypeCustomId',
        defaultMessage: 'Custom Id',
    },
});
