import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {AgentRevenueShareWeeklyReport, QueryGetAgentRevenueShareWeeklyReportArgs} from '@models/generated/graphql';
import {AgentLevelReportQueryFields, AgentLevelReportServerFilterKeys, AgentLevelReportServerTextFilterKeys, Filter} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class AgentLevelReportService extends EntityBaseGqlService<
    QueryGetAgentRevenueShareWeeklyReportArgs,
    AgentLevelReportQueryFields,
    AgentLevelReportServerFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new AgentLevelReportRequestBuilder());
    }
}

export class AgentLevelReportRequestBuilder extends GqlRequestBuilder<
    QueryGetAgentRevenueShareWeeklyReportArgs,
    AgentLevelReportQueryFields,
    AgentLevelReportServerFilterKeys
> {
    public buildQuery = (fields: AgentLevelReportQueryFields[]): DocumentNode => gql`
        query GetAgentRevenueShareWeeklyReport($filter: AgentRevenueShareWeeklyReportFilter, $sort: Sorting, $start: Int, $end: Int) {
            getAgentRevenueShareWeeklyReport(filter: $filter, sort: $sort, start: $start, end: $end) {
                items {
                    week_start_date_ts @include(if: ${this.hasAnyField(fields, this.getWeekStartQueryItems())}) {
                        seconds @include(if: ${this.hasField(fields, 'week_start_date_ts.seconds')})
                    }
                    referrer_id @include(if: ${this.hasField(fields, 'referrer_id')})
                    referrer_username @include(if: ${this.hasField(fields, 'referrer_username')})
                    poker_ngr @include(if: ${this.hasField(fields, 'poker_ngr')})
                    casino_ggr @include(if: ${this.hasField(fields, 'casino_ggr')})
                    internal_casino_ggr @include(if: ${this.hasField(fields, 'internal_casino_ggr')})
                    external_casino_ggr @include(if: ${this.hasField(fields, 'external_casino_ggr')})
                    revenue_shared_total @include(if: ${this.hasField(fields, 'revenue_shared_total')})
                    referrer_psp_fees @include(if: ${this.hasField(fields, 'referrer_psp_fees')})
                    carry_over_negative_balance @include(if: ${this.hasField(fields, 'carry_over_negative_balance')})
                    adjusted_revenue_shared_total @include(if: ${this.hasField(fields, 'adjusted_revenue_shared_total')})
                    tiers @include(if: ${this.hasAnyField(fields, this.getTiersQueryItems())}) {
                        revenue_shared @include(if: ${this.hasField(fields, 'tiers.revenue_shared')})
                        casino_revenue_share @include(if: ${this.hasField(fields, 'tiers.casino_revenue_share')})
                        poker_revenue_share @include(if: ${this.hasField(fields, 'tiers.poker_revenue_share')})
                        generated_external_casino_ggr @include(if: ${this.hasField(fields, 'tiers.generated_external_casino_ggr')})
                        generated_internal_casino_ggr @include(if: ${this.hasField(fields, 'tiers.generated_internal_casino_ggr')})
                    }
                }
                total_count
            } 
        }
    `;

    protected buildFilter(filter: Filter<AgentLevelReportServerFilterKeys>): QueryGetAgentRevenueShareWeeklyReportArgs {
        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: AgentLevelReportServerTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string)
                    )
                ),
            },
        };
    }

    private getTiersQueryItems(): AgentLevelReportQueryFields[] {
        return [
            'tiers.casino_revenue_share',
            'tiers.poker_revenue_share',
            'tiers.revenue_shared',
            'tiers.generated_external_casino_ggr',
            'tiers.generated_internal_casino_ggr',
        ];
    }

    private getWeekStartQueryItems(): AgentLevelReportQueryFields[] {
        return ['week_start_date_ts.seconds'];
    }

    private readonly filterFieldsMapper: Record<AgentLevelReportServerTextFilterKeys, string[]> = {
        referrer_id: nameof.toArray<AgentRevenueShareWeeklyReport>(m => [m.referrer_id]),
        referrer_username: nameof.toArray<AgentRevenueShareWeeklyReport>(m => [m.referrer_username]),
        ref_id_uname: nameof.toArray<AgentRevenueShareWeeklyReport>(m => [m.referrer_id, m.referrer_username]),
    };
}
