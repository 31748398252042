import React from 'react';
import {useDispatch} from 'react-redux';

import {Button} from '@components/button/Buttons';

import {legalModuleActions} from '../actions';

import legalModuleData from './data';

type LegalEditActionProps = {
    domain: string;
    isEditMode: boolean;
};

const LegalEditAction = ({domain, isEditMode}: LegalEditActionProps) => {
    const actions = legalModuleActions(domain);
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(actions.changeMode());
    };

    return !isEditMode ? <Button color="primary" label={legalModuleData.localized.editLabel} onClick={onClick} /> : null;
};

export default LegalEditAction;
