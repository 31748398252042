import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';

import {AutocompleteOptionType} from '@components/autocomplete';
import {EditButtonProps} from '@components/button/EditButton';
import {withEditButton} from '@components/editable/EditButtonHoc';

import {useClasses} from './EditableAutocomplete.style';

type EditableAutocompleteInnerProps<TFilter extends Partial<Record<string, unknown>>> = {
    value: string | string[];
    label?: string;
    filter?: TFilter;
    isDisabled?: boolean;
    onValueChange?: (value: string | string[]) => void;
    onOptionChange?: (value: AutocompleteOptionType) => void;
    placeholder?: MessageDescriptor;
    errorMessage?: string;
    loading?: boolean;
};

export type EditableAutocompleteProps<TFilter extends Partial<Record<string, unknown>>> = Partial<EditButtonProps> &
    EditableAutocompleteInnerProps<TFilter>;

export const withEditableAutocomplete = <TFilter extends Record<string, unknown>>(
    WrappedComponent: React.ComponentType<EditableAutocompleteProps<TFilter>>
) =>
    withEditButton(({disabled, mode, ...props}: EditableAutocompleteProps<TFilter>) => {
        const {classes} = useClasses();

        const isEditDisabled = disabled || (mode !== 'Edit' && mode !== 'EditMultiStep');

        return (
            <Box className={classes.editableAutocompleteInfo}>
                <WrappedComponent {...props} mode={mode} isDisabled={isEditDisabled} />
            </Box>
        );
    });
