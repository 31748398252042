import {gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {DocumentNode} from 'graphql';
import {inject, injectable} from 'inversify';
import {map, Observable} from 'rxjs';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {Mutation, MutationUpsertPageViewArgs, QueryGetPageViewsArgs} from '@models/generated/graphql';
import {Filter, PageIdDescriptor, PageType, PageViewFilterKeys, PageViewQueryFields, UpsertPageViewInput} from '@redux/entity';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {EntityBaseGqlService} from './entity';
import {ApolloClientProxy} from './gql-api';
import {ServiceResponsePayload} from './types';

export interface IPageViewService {
    upsertPageView(input: UpsertPageViewInput): Observable<ServiceResponsePayload<UpsertPageViewInput, null>>;
}
export class PageIdDescriptorConverter {
    private readonly separator = '_';

    convert(descriptor: PageIdDescriptor): string {
        return `${descriptor?.pageType}${this.separator}${descriptor?.recordId}`;
    }

    parse(descriptor: string): PageIdDescriptor {
        const [pageType, recordId] = descriptor.split(this.separator);
        return {pageType: pageType as PageType, recordId};
    }
}

@injectable()
export class PageViewService
    extends EntityBaseGqlService<QueryGetPageViewsArgs, PageViewQueryFields, PageViewFilterKeys>
    implements IPageViewService
{
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new PageViewRequestBuilder());
    }

    upsertPageView(input: UpsertPageViewInput) {
        const payload: MutationUpsertPageViewArgs = {
            pageView: {
                last_opened_at: {
                    seconds: input.lastOpenedAtSeconds,
                },
                page_id: new PageIdDescriptorConverter().convert(input.pageIdDescriptor),
            },
        };
        return this._service
            .mutate<Mutation, MutationUpsertPageViewArgs>(this.getUpsertPageViewMutation(), payload)
            ?.pipe(map(r => ({...r, requestPayload: input, responsePayload: null})));
    }

    private getUpsertPageViewMutation() {
        return gql`
            mutation UpsertPageView($pageView: PageViewInput!) {
                upsertPageView(pageView: $pageView) {
                    last_opened_at {
                        seconds
                    }
                }
            }
        `;
    }
}

export class PageViewRequestBuilder extends GqlRequestBuilder<QueryGetPageViewsArgs, PageViewQueryFields, PageViewFilterKeys> {
    protected buildQuery(fields: PageViewQueryFields[]): DocumentNode {
        return gql`
            query GetPageViews($filter: PageViewFilter) {
                getPageViews(filter: $filter) {
                    page_id @include(if: ${this.hasField(fields, 'page_id')})
                    user_id @include(if: ${this.hasField(fields, 'user_id')})
                    last_opened_at @include(if: ${this.hasField(fields, 'last_opened_at')}) {
                        seconds
                    }
                }
            }
        `;
    }

    protected getGqlPageArgs(filter: Filter<PageViewFilterKeys>): QueryGetPageViewsArgs {
        return this.buildFilter(filter);
    }
    protected buildFilter(filter: Filter<PageViewFilterKeys>): Pick<QueryGetPageViewsArgs, 'filter'> {
        return {
            filter: {
                last_opened_at: this.toGQLDateRange(filter.lastOpenedAtFrom, filter.lastOpenedAtTo),
                page_ids: this.toGQLMultiselectFilter<PageIdDescriptor[]>(filter, 'pageIds')
                    ?.filter(d => d.pageType && d.recordId)
                    .map(d => new PageIdDescriptorConverter().convert(d)),
            },
        };
    }
}
