import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {BonusType, BulkItemInput, PlayerBonusTriggerInput} from '@models/generated/graphql';
import {map} from '@otel';
import type {IBonusCodeService} from '@services/bonusCodeService';
import {ServerResponseStatus} from '@services/types';
import {getUTCTimestamp} from '@utils';

import {JurisdictionCurrency} from '../../app/config/types';
import {failedOperationId, IBulkStrategy, PerformStrategyOperationResponse, PerformStrategyRequest} from '../../block-bulk-actions';
import {BonusTriggerViewModel} from '../types';

abstract class PerformTriggerBonusStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    private readonly _bonusService: IBonusCodeService;

    protected constructor(bonusService: IBonusCodeService) {
        this._bonusService = bonusService;
    }

    process({items}: PerformStrategyRequest): Observable<PerformStrategyOperationResponse> {
        const bulkItems: BulkItemInput[] = items.map(i => ({
            id: i.itemId,
            data: {
                bonus: this.getBonusTriggerInput(i.value as BonusTriggerViewModel),
            },
        }));

        return this._bonusService.triggerBonusToUsers(bulkItems).pipe(
            map(response => ({
                actionKey: bulkItems[0].data.bonus.bonus_id,
                id: response.status === ServerResponseStatus.Success ? response.responsePayload?.id : failedOperationId,
            }))
        );
    }

    protected abstract getBonusTriggerInput(triggerModel: BonusTriggerViewModel): PlayerBonusTriggerInput;
}

@injectable()
export class PerformTriggerMttBonusStrategy extends PerformTriggerBonusStrategy {
    constructor(@inject(ServiceTypes.BonusCodeService) bonusService: IBonusCodeService) {
        super(bonusService);
    }

    /**
     * To trigger multiple tickets multiple items with same bonus_id and uid are passed. They must have unique timestamp
     */
    protected getBonusTriggerInput(triggerModel: BonusTriggerViewModel): PlayerBonusTriggerInput {
        const now = getUTCTimestamp();

        return {
            uid: triggerModel.uid,
            timestamp: {seconds: now.seconds + triggerModel.ticket_index},
            event_type: BonusType.Register,
            bonus_id: triggerModel.bonus_id,
            bonus_name: triggerModel.bonus_name,
            meta: {
                marketing_code: triggerModel.marketing_code,
            },
        };
    }
}

@injectable()
export class PerformTriggerImmediateCashBonusStrategy extends PerformTriggerBonusStrategy {
    constructor(@inject(ServiceTypes.BonusCodeService) bonusService: IBonusCodeService) {
        super(bonusService);
    }

    protected getBonusTriggerInput(triggerModel: BonusTriggerViewModel): PlayerBonusTriggerInput {
        return {
            uid: triggerModel.uid,
            timestamp: getUTCTimestamp(),
            event_type: BonusType.Register,
            bonus_id: triggerModel.bonus_id,
            bonus_name: triggerModel.bonus_name,
            meta: {
                marketing_code: triggerModel.marketing_code,
            },
        };
    }
}

@injectable()
export class PerformTriggerDepositCashMatchBonusStrategy extends PerformTriggerBonusStrategy {
    constructor(@inject(ServiceTypes.BonusCodeService) bonusService: IBonusCodeService) {
        super(bonusService);
    }

    protected getBonusTriggerInput(triggerModel: BonusTriggerViewModel): PlayerBonusTriggerInput {
        return {
            uid: triggerModel.uid,
            timestamp: getUTCTimestamp(),
            event_type: BonusType.Deposit,
            bonus_id: triggerModel.bonus_id,
            bonus_name: triggerModel.bonus_name,
            meta: {
                amount: triggerModel.total_amount,
                currency: triggerModel.total_amount ? JurisdictionCurrency.USD : null,
                marketing_code: triggerModel.marketing_code,
            },
        };
    }
}
