import {SecurityCase, SecurityCaseType} from '@models/generated/graphql';
import {IGQlFilterVariables} from '@services/deprecated/types';

import {ToggleListEditModel} from '../../module-shared/types';
import {domain as baseDomain} from '../types';

export const domain = `${baseDomain}/security-cases-actions`;

export type EditBotCaseModel = {
    isBotCaseAssigned: boolean;
    isBotCaseIdChanged?: boolean;
    caseId: string;
    previousCaseId?: string;
};

export type SecurityCaseFilter = {
    type: SecurityCaseType;
    id: string;
    userId: string;
};

export class SecurityCaseEditModel extends SecurityCase {
    isNew?: boolean;
    totalCount?: number;
}

export type EditSecurityCasesSubmitModel = ToggleListEditModel<SecurityCaseEditModel>;

export type EditSecurityCasesFieldModel = {
    cases: SecurityCaseEditModel[];
};

export interface ISecurityCaseGqlQueryVariables extends IGQlFilterVariables {
    type: SecurityCaseType;
    case_id: String;
    limit: number;
}
