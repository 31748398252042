import React from 'react';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {push} from '@vs-centaurea/connected-react-router';
import {makeStyles} from 'tss-react/mui';

import {LinkText} from '@components/link';
import {CustomTheme} from '@style';

import {IBreadcrumb} from 'src/common/types';

interface BreadcrumbProps {
    breadcrumbs: Array<IBreadcrumb>;
    highlightCurrent?: boolean;
}

const useClasses = makeStyles<{highlightCurrent: boolean}>()((theme: CustomTheme, {highlightCurrent}) => ({
    breadcrumb: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    breadcrumbLinkParent: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.h6.fontSize,
    },
    breadcrumbLinkCurrent: {
        color: theme.palette.text.primary,
        fontSize: highlightCurrent ? theme.typography.h5.fontSize : theme.typography.h6.fontSize,
    },
}));

const Breadcrumb = ({breadcrumbs, highlightCurrent = true}: BreadcrumbProps) => {
    const dispatch = useDispatch();
    const {classes} = useClasses({highlightCurrent});

    const pushToRoute = (routeIndex: number) => {
        if (!breadcrumbs[routeIndex].clickable) {
            return;
        }

        if ((routeIndex !== 0 && breadcrumbs.length > 1) || breadcrumbs[routeIndex].clickable) {
            dispatch(push(breadcrumbs[routeIndex].url));
        }
    };

    const lastBreadcrumbIndex = breadcrumbs.length - 1;

    return breadcrumbs.length > 1 ? (
        <Box className={classes.breadcrumb}>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbs.slice(0, -1).map((breadcrumb, index) => (
                    <LinkText
                        key={breadcrumb.url}
                        title={breadcrumb.title}
                        onClick={() => pushToRoute(index)}
                        underline="none"
                        className={classes.breadcrumbLinkParent}
                    />
                ))}

                {breadcrumbs[lastBreadcrumbIndex] ? (
                    <LinkText
                        key={breadcrumbs[lastBreadcrumbIndex].url}
                        title={breadcrumbs[lastBreadcrumbIndex].title}
                        onClick={() => pushToRoute(lastBreadcrumbIndex)}
                        underline="none"
                        className={classes.breadcrumbLinkCurrent}
                    />
                ) : null}
            </Breadcrumbs>
        </Box>
    ) : null;
};

export default Breadcrumb;
