import React, {FC} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';

import {FormSelect} from '@components/input';
import {withLabel} from '@components/label';

import {useValueRangePickerClasses} from './valueRangePickerStyle';
import {valueRangePickerOptions} from './valueRangePickerTypes';

export type OperatorSelectProps = {
    label: MessageDescriptor;
};

export const OperatorSelect: FC<OperatorSelectProps> = withLabel((_props: OperatorSelectProps) => {
    const {classes} = useValueRangePickerClasses();
    const {control} = useFormContext();

    return (
        <Box className={classes.valueRangePickerOperatorSelectContainer}>
            <Controller
                render={({field, fieldState}) => (
                    <Box data-testid="operatorContainer" className={classes.valueRangePickerOperatorSelect}>
                        <FormSelect
                            key={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            options={valueRangePickerOptions}
                            fieldState={fieldState}
                            isCompareOptionsAvailable={false}
                        />
                    </Box>
                )}
                control={control}
                name="operator"
            />
        </Box>
    );
});
