import React, {useContext, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {Box, Typography} from '@mui/material';

import Button, {OutlinedButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons/types';
import {FormSelect, FormTextInputDefault, RuleType, useCustomValidationFormatter, useValidationFormatter} from '@components/input';
import {ModalContext} from '@components/modal';
import {BonusType, MarketingCodeInput} from '@models/generated/graphql';
import {useAuthUser} from '@auth';
import {getUTCTimestamp} from '@utils';

import {getBtagRegex} from 'src/features/block-player-referral-details';
import {marketingCodeActions} from '../../../features/marketing-code/actions';
import {SelectOption} from '../../../features/module-shared/types';
import {useAsyncActionState} from '../../../features/shared/async-action/hooks';
import {AgentIdAutocomplete} from '../../../features/view-autocomplete/components/AgentIdAutocomplete';

import {localizedMarketingBonusCodeCreateAction} from './MarketingBonusCodeCreateAction.localize';
import {useMarketingBonusCodeCreateActionClasses} from './MarketingBonusCodeCreateAction.styles';

const MarketingBonusCodeCreateActionForm = () => {
    const {classes} = useMarketingBonusCodeCreateActionClasses();
    const dispatch = useDispatch();
    const validationFormatter = useValidationFormatter();
    const customValidationFormatter = useCustomValidationFormatter();

    const {reset, control, handleSubmit, watch} = useForm<MarketingCodeInput>();
    const {requestStatus, isProgress} = useAsyncActionState(marketingCodeActions.addMarketingCode);
    const options = Object.values(BonusType).map(s => ({label: s, value: s} as SelectOption));

    const currentUser = useAuthUser();
    const {closeModal} = useContext(ModalContext);

    const codeType = watch('type');
    const isTeamCode = codeType === BonusType.Team;

    const onConfirmClicked = (newItem: MarketingCodeInput) => {
        newItem.added_by_uid = currentUser.sub;
        newItem.created_at = getUTCTimestamp();
        if (newItem.type === BonusType.Team) {
            newItem.btag = null;
        } else {
            newItem.referrer_id = null;
        }

        dispatch(marketingCodeActions.addMarketingCode.request({marketing_code: newItem}));
        closeModal();
    };

    useEffect(() => {
        if (requestStatus) {
            reset();
        }
    }, [requestStatus]);

    return (
        <>
            <div className={classes.marketingBonusCodeModalForm}>
                <Typography variant="body1">
                    <LocalizedText label={localizedMarketingBonusCodeCreateAction.marketingCode} />
                </Typography>
                <Controller
                    render={({field, fieldState}) => (
                        <FormTextInputDefault
                            key={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            fieldState={fieldState}
                            hideLabel={true}
                            placeholder={localizedMarketingBonusCodeCreateAction.placeholderEnterNameCode}
                            disabled={isProgress}
                            hasBottomSpacing={false}
                            fullWidth={false}
                            className={classes.marketingBonusCodeAddActionInput}
                        />
                    )}
                    name="marketing_code"
                    control={control}
                    rules={{
                        required: validationFormatter(RuleType.Required, localizedMarketingBonusCodeCreateAction.marketingCode),
                    }}
                />

                <Typography variant="body1">
                    <LocalizedText label={localizedMarketingBonusCodeCreateAction.type} />
                </Typography>
                <Controller
                    render={({field, fieldState}) => (
                        <FormSelect
                            key={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            options={options}
                            fieldState={fieldState}
                            isCompareOptionsAvailable={false}
                            label={localizedMarketingBonusCodeCreateAction.type}
                            displayEmpty
                            disabled={isProgress}
                            hasBottomSpacing={false}
                            fullWidth={false}
                            className={classes.marketingBonusCodeAddActionInput}
                        />
                    )}
                    control={control}
                    name="type"
                    rules={{
                        required: validationFormatter(RuleType.Required, localizedMarketingBonusCodeCreateAction.type),
                    }}
                />

                {!isTeamCode ? (
                    <>
                        <Typography variant="body1">
                            <LocalizedText label={localizedMarketingBonusCodeCreateAction.btag} />
                        </Typography>
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    key={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                    hideLabel={true}
                                    placeholder={localizedMarketingBonusCodeCreateAction.placeholderOptional}
                                    disabled={isProgress}
                                    hasBottomSpacing={false}
                                    fullWidth={false}
                                    className={classes.marketingBonusCodeAddActionInput}
                                />
                            )}
                            name="btag"
                            control={control}
                            rules={{
                                pattern: {
                                    value: getBtagRegex(),
                                    message: customValidationFormatter(localizedMarketingBonusCodeCreateAction.invalidBtagFormatMessage),
                                },
                            }}
                        />
                    </>
                ) : null}

                {isTeamCode ? (
                    <>
                        <Typography variant="body1">
                            <LocalizedText label={localizedMarketingBonusCodeCreateAction.referrerId} />
                        </Typography>
                        <Controller
                            render={({field}) => (
                                <AgentIdAutocomplete
                                    viewType="MarketingCodeReferrerIdAutocomplete"
                                    value={field.value}
                                    onValueChange={value => {
                                        field.onChange(value);
                                    }}
                                />
                            )}
                            name="referrer_id"
                            control={control}
                        />
                    </>
                ) : null}
            </div>

            <div className={classes.marketingBonusCodeAddActionButtons}>
                <OutlinedButton
                    className={classes.marketingBonusCodeAddActionFooterButton}
                    label={localizedMarketingBonusCodeCreateAction.cancel}
                    onClick={closeModal}
                />
                <Button
                    className={classes.marketingBonusCodeAddActionFooterButton}
                    color="primary"
                    label={localizedMarketingBonusCodeCreateAction.confirm}
                    onClick={handleSubmit(onConfirmClicked)}
                />
            </div>
        </>
    );
};

const MarketingBonusCodeCreateAction = () => {
    const {classes} = useMarketingBonusCodeCreateActionClasses();
    const {isProgress} = useAsyncActionState(marketingCodeActions.addMarketingCode);
    const {openModal} = useContext(ModalContext);

    const onAddNewClicked = () => {
        openModal({
            body: <MarketingBonusCodeCreateActionForm />,
            title: <LocalizedText label={localizedMarketingBonusCodeCreateAction.addMarketingBonusCode} />,
        });
    };

    return (
        <OutlinedButton
            data-testid="marketingBonusCodeCreateButton"
            label={localizedMarketingBonusCodeCreateAction.addNew}
            disabled={isProgress}
            startIcon={<Box component="span" className={CustomIcon.AddCircleOutline} fontSize={8} />}
            onClick={onAddNewClicked}
            className={classes.marketingBonusCodeAddNewButton}
        ></OutlinedButton>
    );
};

export const renderMarketingBonusCodeCreateAction = () => {
    return <MarketingBonusCodeCreateAction />;
};
