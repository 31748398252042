import {Span} from '@opentelemetry/api';
import {Observable, throwError} from 'rxjs';
import {ajax as ajaxRx, AjaxError, AjaxRequest} from 'rxjs/ajax';
import {catchError, map} from 'rxjs/operators';

import {applyHttpRequestAttrs, applyHttpResponseAttrs, applyLocationAttrs, applyUserAttrs, ITracingService, TraceUser} from '@otel';
import {AjaxResponse} from '@services/rest-api/types';

export type AjaxFunction = (request: Partial<AjaxRequest>, user: TraceUser, parentSpan?: Span) => Observable<AjaxResponse>;

export const ajax =
    (tracingService: ITracingService) =>
    (request: AjaxRequest, user: TraceUser, parentSpan?: Span): Observable<AjaxResponse> => {
        const ajaxRequest = request as AjaxRequest;
        const url = ajaxRequest?.url;
        const method = ajaxRequest?.method ?? 'GET';

        const tracer = tracingService.getTracer();
        const ctx = tracingService.setSpanOnContext(parentSpan);

        return tracer.startActiveSpan(`REST HTTP ${method}`, undefined, ctx, span => {
            applyLocationAttrs(span);
            applyUserAttrs(span, user);
            applyHttpRequestAttrs(span, url, method, 'xhr');

            return ajaxRx(ajaxRequest).pipe(
                map(r => {
                    applyHttpResponseAttrs(span, r.status?.toString());
                    tracingService.endSpanOk(span);
                    return r;
                }),
                catchError((e: AjaxError) => {
                    applyHttpResponseAttrs(span, e.status?.toString(), e.response?.traceId, e.message);
                    tracingService.endSpanFailed(span, e.message);
                    return throwError(e);
                })
            );
        });
    };
