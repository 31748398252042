import React, {ReactNode} from 'react';
import {IBounds, useLayer} from 'react-laag';
import type {Rectangle} from '@glideapps/glide-data-grid';
import {makeStyles} from 'tss-react/mui';

const useClasses = makeStyles()(theme => {
    const arrowSize = 10;

    return {
        glideGridTooltipContainer: {
            padding: theme.spacing(0, 0.5),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: `-${arrowSize}px`,
        },

        glideGridTooltip: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[1],
            borderRadius: theme.shape.borderRadius,
            fontSize: '1em',
            fontWeight: theme.typography.fontWeightMedium,
            padding: theme.spacing(2),
            display: 'block',
        },

        glideGridTooltipArrow: {
            width: 0,
            height: 0,
            borderLeft: `${arrowSize}px solid transparent`,
            borderRight: `${arrowSize}px solid transparent`,
            borderBottom: `${arrowSize}px solid`,
            borderBottomColor: theme.palette.common.white,
        },
    };
});

type GlideGridTooltipTempProps = {
    value: ReactNode;
    bounds: Rectangle;
};

export function GlideGridTooltip({bounds, value}: GlideGridTooltipTempProps) {
    const {classes} = useClasses();

    const {renderLayer, layerProps} = useLayer({
        isOpen: Boolean(value),
        auto: true,
        placement: 'bottom-center',
        trigger: {getBounds},
    });

    function getBounds(): IBounds {
        return bounds
            ? {
                  left: bounds.x,
                  top: bounds.y,
                  width: bounds.width,
                  height: bounds.height,
                  right: bounds.x + bounds.width,
                  bottom: bounds.y + bounds.height,
              }
            : {
                  left: 0,
                  top: 0,
                  width: 0,
                  height: 0,
                  bottom: 0,
                  right: 0,
              };
    }

    return value
        ? renderLayer(
              <div {...layerProps} className={classes.glideGridTooltipContainer}>
                  <div className={classes.glideGridTooltipArrow} />
                  <div className={classes.glideGridTooltip}>{value}</div>
              </div>
          )
        : null;
}
