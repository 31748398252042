import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {BaseFilterKeys, UserProfileQueryFields} from '@redux/entity';
import {maxPageSize} from '@services/types';
import {getFilterString} from '@utils';

import {gridUserProfileLocalizedHeaders} from '../block-user-profile-list/components/DataGridUserProfile.localize';
import {useAsyncActionState} from '../shared/async-action/hooks';

import {userProfileActions} from './actions';

type UseUserProfileDownloadButtonProps = {
    filterString: string;
    keys: UserProfileViewModelKeys[];
    filename: string;
};

type UseUserProfileDownloadButtonResult = {
    handleClick: () => void;
    isProgress: boolean;
};

export function useUserProfileDownloadButton({
    filterString,
    keys,
    filename,
}: UseUserProfileDownloadButtonProps): UseUserProfileDownloadButtonResult {
    const dispatch = useDispatch();
    const mapper = useAutoMapper();
    const {formatMessage} = useIntl();

    const headers: Record<UserProfileViewModelKeys, string> = keys?.reduce((previousValue, key) => {
        previousValue[key] = gridUserProfileLocalizedHeaders[key] ? formatMessage(gridUserProfileLocalizedHeaders[key]) : null;
        return previousValue;
    }, {} as Record<UserProfileViewModelKeys, string>);

    const {isProgress} = useAsyncActionState(userProfileActions.download);
    const pageFilterKey: BaseFilterKeys = 'page';
    const sizeFilterKey: BaseFilterKeys = 'size';
    const searchFilter = getFilterString(
        filterString,
        true,
        {key: pageFilterKey, value: '1'},
        {key: sizeFilterKey, value: `${maxPageSize}`}
    );

    const fields: UserProfileQueryFields[] = mapper.map<UserProfileViewModelKeys[], UserProfileQueryFields[]>(
        keys,
        nameof<UserProfileViewModelKeys>(),
        nameof<UserProfileQueryFields>()
    );

    function handleClick() {
        dispatch(userProfileActions.download.request({filter: searchFilter, fields, filename, worksheetKeys: keys, headers}));
    }

    return {handleClick, isProgress};
}
