import {Observable} from 'rxjs';

import {EntityType} from '@redux/entity';

export type DownloadKeys<TKeys extends string> = Partial<Record<EntityType, TKeys[]>>;

export type DownloadStrategyRequest<TKeys extends string> = {
    keys: DownloadKeys<TKeys>;
    filter: string;
};

export const downloadRequestFailedMessage = 'downloadRequestFailedMessage' as const;
export type DownloadRequestFailedMessageType = typeof downloadRequestFailedMessage;

export interface IDownloadStrategy<TKeys extends string> {
    process(request: DownloadStrategyRequest<TKeys>): Observable<Record<TKeys, string>[] | DownloadRequestFailedMessageType>;
}
