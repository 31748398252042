import React from 'react';
import {EditorComponent, OnChangeHTML, Remirror, useRemirror} from '@remirror/react';
import {
    BlockquoteExtension,
    BoldExtension,
    BulletListExtension,
    CodeBlockExtension,
    CodeExtension,
    FontFamilyExtension,
    FontSizeExtension,
    HardBreakExtension,
    HeadingExtension,
    HistoryExtension,
    IframeExtension,
    ImageExtension,
    ItalicExtension,
    LinkExtension,
    MarkdownExtension,
    NodeFormattingExtension,
    OrderedListExtension,
    StrikeExtension,
    SubExtension,
    SupExtension,
    TextColorExtension,
    UnderlineExtension,
} from 'remirror/extensions';
import {makeStyles} from 'tss-react/mui';

import {Message, MessageType} from '../alerts/Message';

import {EditorToolbar, EditorToolbarsButtons} from './EditorToolbar';

export type HtmlEditorProps = {
    value?: string;
    error?: React.ReactNode;
    onChange: (value: string) => void;
};

const useClasses = makeStyles()(theme => ({
    htmlEditorWrapper: {
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        '& .remirror-editor-wrapper': {
            paddingBottom: theme.spacing(2),
            overflow: 'auto',
            height: '90%',
        },
        '& .remirror-editor.ProseMirror-focused': {
            outline: 'none',
        },
    },
}));

export function HTMLEditor(props: HtmlEditorProps) {
    const {classes} = useClasses();
    const {manager} = useRemirror({
        content: props.value,
        extensions: () => [
            new HeadingExtension({}),
            new BlockquoteExtension({}),
            new BoldExtension({}),
            new ItalicExtension(),
            new LinkExtension({autoLink: false}),
            new BulletListExtension({}),
            new OrderedListExtension(),
            new TextColorExtension({}),
            new MarkdownExtension({}),
            new CodeExtension({}),
            new CodeBlockExtension({}),
            new FontFamilyExtension({}),
            new FontSizeExtension({defaultSize: '16', unit: 'px'}),
            new HistoryExtension({}),
            new StrikeExtension({}),
            new UnderlineExtension({}),
            new SubExtension({}),
            new SupExtension({}),
            new NodeFormattingExtension({}),
            new IframeExtension({enableResizing: true}),
            new ImageExtension({enableResizing: true}),
            new HardBreakExtension({}),
        ],
        stringHandler: 'html',
    });

    const buttons: EditorToolbarsButtons[] = [
        'bold',
        'italic',
        'underline',
        'strike',
        'code',
        'superscript',
        'subscript',
        'heading',
        'font-size',
        'font-family',
        'bullet-list',
        'ordered-list',
        'indent-outdent',
        'text-align',
        'text-color',
        'add-link',
        'remove-link',
        'iframe',
        'image',
        'history',
    ];

    return (
        <div className={classes.htmlEditorWrapper}>
            <Remirror manager={manager} initialContent={props.value}>
                <EditorToolbar buttons={buttons} />
                <EditorComponent />
                <OnChangeHTML onChange={props?.onChange} />
            </Remirror>
            <Message message={props.error} messageType={MessageType.Error} />
        </div>
    );
}
