import React, {ChangeEvent} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {CommandButton, useActive} from '@remirror/react';

import {CustomIcon, IconColor} from '@components/icons';
import {FormTextInput} from '@components/input/FormInput';

import {EditorDropdownButton} from './EditorDropdownButton';
import {useLinkState} from './hooks';
import {ToolbarIcon} from './ToolbarIcon';

const localized = defineMessages({
    removeLink: {
        id: 'LinkButton_removeLink',
        defaultMessage: 'Remove link',
    },
    title: {
        id: 'LinkButton_title',
        defaultMessage: 'Title',
    },
    url: {
        id: 'LinkButton_url',
        defaultMessage: 'Url',
    },
});

export function AddLinkButton() {
    const {formatMessage} = useIntl();
    const {title, href, setTitle, setHref, getLinkFromSelection, submit} = useLinkState();

    function handleTitleChange(e: ChangeEvent<HTMLInputElement>) {
        setTitle(e.target.value);
    }

    function handleHrefChange(e: ChangeEvent<HTMLInputElement>) {
        setHref(e.target.value);
    }

    return (
        <EditorDropdownButton
            applyAction
            cancelAction
            onDropdownOpen={getLinkFromSelection}
            onDropdownClose={() => {}}
            onApplyClick={submit}
            buttonContent={<ToolbarIcon color={IconColor.SuitBlack} icon={CustomIcon.Link} />}
            isApplyDisabled={!title || !href}
        >
            <FormTextInput value={title} label={formatMessage(localized.title)} onChange={handleTitleChange} autoFocus />
            <FormTextInput value={href} label={formatMessage(localized.url)} onChange={handleHrefChange} />
        </EditorDropdownButton>
    );
}

export function RemoveLinkButton() {
    const {formatMessage} = useIntl();
    const {removeLink} = useLinkState();
    const {link} = useActive();

    function handleClick() {
        removeLink();
    }

    return (
        <CommandButton
            commandName="removeLink"
            icon="linkUnlink"
            label={formatMessage(localized.removeLink)}
            onSelect={handleClick}
            enabled={link()}
        />
    );
}
