import React from 'react';
import {Box, Typography, TypographyVariant} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {CircleBox, CircleBoxProps} from './CircleBox';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    circleBoxContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    circleBoxText: {
        marginLeft: theme.spacing(1.5),
    },
}));

type CircleBoxWithTextProps = CircleBoxProps & {
    text: string;
    textBold?: boolean;
};

export function CircleBoxWithText({text, textBold, letter, ...circleProps}: CircleBoxWithTextProps) {
    const {classes: styles} = useStyles();
    const variant: TypographyVariant = textBold ? 'h4' : 'body1';

    return (
        <Box className={styles.circleBoxContainer}>
            {letter ? <CircleBox {...circleProps} letter={letter} /> : null}
            <Box className={styles.circleBoxText}>
                <Typography variant={variant}>{text}</Typography>
            </Box>
        </Box>
    );
}
