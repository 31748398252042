import {gql} from '@apollo/client';

import {GqlEntityArgs} from './types';

//TODO: [BO-2938] Cleanup file after Bonus module is moved to view feature
const fragmentAll = gql`
    fragment BeBonusHistoryFragment on BonusCodeHistory {
        id
        bonus_id
        uid
        email
        username
        created_at {
            seconds
        }
        transaction_amount
        unrealized_amount
        type
    }
`;

export const beBonusHistory: GqlEntityArgs = {
    fragmentAll,
    addSubscription: gql`
        ${fragmentAll}
        subscription OnBeBonusHistoryAdded {
            beBonusHistoryAdded {
                ...BeBonusHistoryFragment
            }
        }
    `,
};
