import {DateRangeFilterValue} from '@components/filter';
import {MultiKeyFilterTextValue} from '@components/filter/types';
import {Device, Location, LoginStatus, LogoutInfo, UserLogin} from '@models/generated/graphql';
import {Timestamp} from '@models/shared';

export class UserLoginDownloadModel implements Record<UserLoginViewModelKeys, string> {
    connection_type: string;
    ip: string;
    isp: string;
    logged_at_ts: string;
    login_status: string;
    username: string;
    logged_out_at_ts: string;
    'device.mac_address': string;
    'device.model': string;
    'device.name': string;
    'device.operating_system': string;
    'device.uuid': string;
    'location.city': string;
    'location.country': string;
    'location.state': string;
    id: string;
    session_id: string;
    uid: string;
}

export class DeviceViewModel {
    agent?: string;
    mac_address?: string;
    model?: string;
    name?: string;
    operating_system?: string;
    uuid?: string;
}

export class LocationViewModel {
    city?: string;
    country?: string;
    latitude?: number;
    longitude?: number;
    state?: string;
}

export class UserLoginViewModel {
    id: string;
    connection_type?: string;
    device?: DeviceViewModel;
    ip?: string;
    isp?: string;
    location?: LocationViewModel;
    logged_at_ts?: Timestamp;
    login_status?: LoginStatus;
    logged_out_at_ts?: Timestamp;
    session_id: string;
    uid: string;
    username?: string;
}

type DeviceViewModelKeys = keyof Omit<DeviceViewModel, 'agent'>;

type LocationViewModelKeys = keyof Omit<LocationViewModel, 'latitude' | 'longitude'>;

export type UserLoginViewModelKeys =
    | keyof Omit<UserLoginViewModel, 'device' | 'location'>
    | `device.${DeviceViewModelKeys}`
    | `location.${LocationViewModelKeys}`;

export type UserLoginFilterName = 'uid_username_ip_deviceId_country' | 'status' | 'loginDate';
export type UserLoginTextFilterKeys = 'uid_username_ip_deviceId_country' | 'uid' | 'username' | 'ip' | 'deviceId' | 'country';
export type UserLoginTextFilterValue = MultiKeyFilterTextValue<UserLoginTextFilterKeys>;

export class UserLoginFilterModel {
    text: UserLoginTextFilterValue;
    status: LoginStatus[];
    loginDate: DateRangeFilterValue;
}

const loggedAtModelKey: keyof UserLogin = 'logged_at_ts';
const logoutInfoModelKey: keyof UserLogin = 'logout_info';
const loggedOutAtModelKey: keyof LogoutInfo = 'logged_out_at_ts';
const deviceModelKey: keyof UserLogin = 'device';
const locationModelKey: keyof UserLogin = 'location';

export type UserLoginSortingFields =
    | keyof Omit<
          UserLogin,
          | 'device'
          | 'location'
          | 'logout_info'
          | 'logged_at_ts'
          | 'correlation_id'
          | 'current_balance'
          | 'ip_quality_score'
          | 'login_type'
          | 'recaptcha_reasons'
          | 'recaptcha_score'
          | 'language'
          | 'vpn'
          | '__typename'
      >
    | `${typeof loggedAtModelKey}.${keyof Pick<Timestamp, 'seconds'>}`
    | `${typeof logoutInfoModelKey}.${typeof loggedOutAtModelKey}.${keyof Pick<Timestamp, 'seconds'>}`
    | `${typeof deviceModelKey}.${keyof Omit<Device, '__typename' | 'agent'>}`
    | `${typeof locationModelKey}.${keyof Omit<Location, '__typename' | 'latitude' | 'longitude'>}`;
