import {IGQlFilterVariables} from '@services/deprecated/types';

import {ColumnConfiguration} from 'src/common/types';

export const domain = 'user-profile';

export interface IUserProfileGqlQueryVariables extends IGQlFilterVariables {
    uid: string;
}

export class UserProfileColumnsConfiguration extends ColumnConfiguration {
    withUsername: boolean;
    withNetworks: boolean;
    withNickName: boolean;
    withFirstName: boolean;
    withLastName: boolean;
    withEmail: boolean;
    withMobile: boolean;
    withBirthday: boolean;
    withGender: boolean;
    withAddress: boolean;
    withCity: boolean;
    withState: boolean;
    withCountry: boolean;
    withPostCode: boolean;
    withDateOfJoining: boolean;
    withAccountStatus: boolean;
    withTwoFAEnabled: boolean;
    withChallengeQuestions: boolean;
    withIpAddresses: boolean;
    withLatestLogin: boolean;
    withBalance: boolean;
    withGGR: boolean;
    withLifetimeBonus: boolean;
    withBonusRatio: boolean;
    withDepositPerMethodInfo: boolean;
    withDepositTotalInfo: boolean;
    withWithdrawalPerMethodInfo: boolean;
    withWithdrawalTotalInfo: boolean;
    withSecurity: boolean;
    withLatestVerification: boolean;
    withAmlLAbels: boolean;
    withPunishmentStatus: boolean;
    withWithdrawalStatus: boolean;
    withDepositStatus: boolean;
    withLobbyAccessStatus: boolean;
    withBusinessPool: boolean;
    withPoolId: boolean;
    withBlackList: boolean;
    withDevices: boolean;
    withSecurityCases: boolean;
    withFirstLogin: boolean;
    withCountries: boolean;
    withVpn: boolean;
    withPlatform: boolean;
    withAffiliate: boolean;
    withMarketingInfo: boolean;
    withOccupation: boolean;
    withTaxID: boolean;
    withNationalID: boolean;
    withNationality: boolean;
    withCountryOfBirth: boolean;
    withP2pTransferStatus: boolean;
    withReferrerId: boolean;
    withLabels: boolean;

    constructor() {
        super();
        this.withUsername = false;
        this.withNetworks = false;
        this.withNickName = false;
        this.withFirstName = false;
        this.withLastName = false;
        this.withEmail = false;
        this.withMobile = false;
        this.withBirthday = false;
        this.withGender = false;
        this.withAddress = false;
        this.withCity = false;
        this.withState = false;
        this.withCountry = false;
        this.withPostCode = false;
        this.withDateOfJoining = false;
        this.withAccountStatus = false;
        this.withTwoFAEnabled = false;
        this.withChallengeQuestions = false;
        this.withIpAddresses = false;
        this.withLatestLogin = false;
        this.withBalance = false;
        this.withGGR = false;
        this.withLifetimeBonus = false;
        this.withBonusRatio = false;
        this.withDepositPerMethodInfo = false;
        this.withDepositTotalInfo = false;
        this.withWithdrawalPerMethodInfo = false;
        this.withWithdrawalTotalInfo = false;
        this.withSecurity = false;
        this.withLatestVerification = false;
        this.withAmlLAbels = false;
        this.withPunishmentStatus = false;
        this.withWithdrawalStatus = false;
        this.withDepositStatus = false;
        this.withLobbyAccessStatus = false;
        this.withBusinessPool = false;
        this.withPoolId = false;
        this.withBlackList = false;
        this.withDevices = false;
        this.withSecurityCases = false;
        this.withFirstLogin = false;
        this.withCountries = false;
        this.withVpn = false;
        this.withPlatform = false;
        this.withAffiliate = false;
        this.withMarketingInfo = false;
        this.withOccupation = false;
        this.withTaxID = false;
        this.withNationalID = false;
        this.withNationality = false;
        this.withCountryOfBirth = false;
        this.withP2pTransferStatus = false;
        this.withReferrerId = false;
        this.withLabels = false;
    }
}

export const customerSupportFullSearch = 'customerSupportFullSearch';
export const customerSupportReferrerSearch = 'customerSupportReferrerSearch';
export const securityWorkspaceFullSearch = 'securityWorkspaceFullSearch';
export const securityWorkspaceReferrerSearch = 'securityWorkspaceReferrerSearch';
export const registrationInfoFullSearch = 'registrationInfoFullSearch';
