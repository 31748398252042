import {BonusCodeHistory} from '@models/generated/graphql';

import {createEntityRealtimeEpics} from '../realtime-updates/epics';

import {realtimeSubscribersSelector, subscribedTypesSelector} from './selectors';
import {domain} from './types';

export const beBonusHistoryEpics = createEntityRealtimeEpics<BonusCodeHistory>(
    domain,
    container => container.beBonusHistoryRealtimeService,
    realtimeSubscribersSelector,
    subscribedTypesSelector
);
