import {createAction, createAsyncAction} from 'typesafe-actions';

import {LoadPayload} from '../module-temp-storage/types';

//TODO: [BO-2712] Replace users loading with view feature (remove app-shared)
const initUsersLoadingAction = 'initUsersLoading';

const loadUsersAction = 'loadUsers';
const loadUsersSucceedAction = 'loadUsersSucceed';
const loadUsersFailedAction = 'loadUsersFailed';

export const appSharedActions = {
    initUsersLoading: createAction(`${initUsersLoadingAction}`)<void>(),

    loadUsers: createAsyncAction(`${loadUsersAction}`, `${loadUsersSucceedAction}`, `${loadUsersFailedAction}`)<
        LoadPayload<string>,
        LoadPayload<string>,
        LoadPayload<string>
    >(),
};
