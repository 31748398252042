import React from 'react';

import {BlockPlayerLabelAssignForm} from '../../block-label-group-actions/components/BlockPlayerLabelAssignForm';
import {usePlayerLabelGroups} from '../../block-label-group-actions/hooks';
import {UserLabelsForm} from '../../block-label-group-list/types';
import {UserProfileGridItem} from '../../data-grids/types';
import {useBulkPlayerLabelAssign} from '../hooks';

type BulkPlayerLabelAssignFormProps = {
    players: UserProfileGridItem[];
};

export function BulkLabelAssignToPlayerForm({players}: BulkPlayerLabelAssignFormProps) {
    const {labelGroups, labelIds} = usePlayerLabelGroups();
    const {handleSubmit} = useBulkPlayerLabelAssign(players);
    const defaultModel = getDefaultModel();

    function getDefaultModel(): UserLabelsForm {
        return {
            groups: labelGroups.map(g => ({
                id: g.id,
                labels: g.labels
                    ?.filter(l => l)
                    ?.map(l => {
                        const isSelected = false;
                        return {id: l.id, name: l.name, selected: isSelected, disabled: isSelected};
                    }),
            })),
        };
    }

    return (
        <BlockPlayerLabelAssignForm
            value={defaultModel}
            isEditable={false}
            onSubmit={handleSubmit}
            labelGroups={labelGroups}
            labelIds={labelIds}
        />
    );
}
