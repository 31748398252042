import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    autoAdjustedWidthTooltipLabel: {
        lineHeight: theme.typography.body1.lineHeight,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    autoAdjustedWidthTooltip: {
        whiteSpace: 'pre',
    },
}));
