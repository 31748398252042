import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useNotificationHistoryDetailsModalClasses = makeStyles()((theme: CustomTheme) => ({
    notificationHistoryDetailsModalHeader: {
        display: 'flex',
        columnGap: theme.spacing(4.5),
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    notificationHistoryDetailsModalHeaderSender: {
        display: 'flex',
        columnGap: theme.spacing(2),
        alignItems: 'center',
    },
    notificationHistoryDetailsModalHeaderDate: {
        display: 'flex',
        columnGap: theme.spacing(2),
        alignItems: 'center',
    },
    notificationHistoryDetailsModalBody: {
        padding: theme.spacing(3),
        background: theme.custom.palette.content.notificationHistoryDetailsModalBody,
        boxShadow: `0px 1px 1px ${theme.custom.palette.content.boxShadow}`,
        borderRadius: theme.spacing(1),
    },
    notificationHistoryDetailsModalBodyTitle: {
        marginBottom: theme.spacing(2),
    },
    notificationHistoryDetailsModalBodyContent: {
        marginBottom: theme.spacing(3),
    },
}));
