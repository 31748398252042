import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import produce from 'immer';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {Mutation, MutationForceExpireCashBonusArgs, PlayerBonus, QueryGetPlayerBonusesArgs} from '@models/generated/graphql';
import {map} from '@otel';
import {Filter, PlayerBonusFilterKeys, PlayerBonusQueryFields, PlayerBonusTextFilterKeys} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

export interface IPlayerBonusService {
    forceExpire(bonusId: string): Observable<ServiceResponsePayload<GqlMutationRequest<MutationForceExpireCashBonusArgs>, PlayerBonus>>;
}

@injectable()
export class PlayerBonusService
    extends EntityBaseGqlService<QueryGetPlayerBonusesArgs, PlayerBonusQueryFields, PlayerBonusFilterKeys>
    implements IPlayerBonusService
{
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new PlayerBonusRequestBuilder());
    }

    public forceExpire(
        bonusId: string
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationForceExpireCashBonusArgs>, PlayerBonus>> {
        return this._service
            .mutate<Mutation, MutationForceExpireCashBonusArgs>(this.getForceExpireMutation(), {bonus_id: bonusId})
            .pipe(map(res => ({...res, responsePayload: res?.responsePayload?.forceExpireCashBonus})));
    }

    private getForceExpireMutation(): DocumentNode {
        return gql`
            mutation ForceExpireCashBonus($bonus_id: String!) {
                forceExpireCashBonus(bonus_id: $bonus_id) {
                    bonus_id
                    bonus_name
                    bonus_status
                }
            }
        `;
    }
}

export class PlayerBonusRequestBuilder extends GqlRequestBuilder<QueryGetPlayerBonusesArgs, PlayerBonusQueryFields, PlayerBonusFilterKeys> {
    public buildQuery(fields: PlayerBonusQueryFields[]): DocumentNode {
        return gql`
            query GetPlayerBonuses($filter: PlayerBonusesFilter, $sort: Sorting, $start: Int, $end: Int) {
                getPlayerBonuses(filter: $filter, sort: $sort, end: $end, start: $start) {
                    items {
                        bonus_id
                        uid @include(if: ${this.hasField(fields, 'uid')})
                        bonus_engine_code @include(if: ${this.hasField(fields, 'bonus_engine_code')})
                        bonus_status @include(if: ${this.hasField(fields, 'bonus_status')})
                        marketing_code @include(if: ${this.hasField(fields, 'marketing_code')})
                        bonus_name @include(if: ${this.hasField(fields, 'bonus_name')})
                        desc @include(if: ${this.hasField(fields, 'desc')})
                        total_amount @include(if: ${this.hasField(fields, 'total_amount')})
                        realized_amount @include(if: ${this.hasField(fields, 'realized_amount')})
                        created_at @include(if: ${this.hasField(fields, 'created_at.seconds')}) {
                            seconds
                        }
                        expire_at @include(if: ${this.hasField(fields, 'expire_at.seconds')}) {
                            seconds
                        }
                        type @include(if: ${this.hasField(fields, 'type')})
                    }
                    total_count
                }
            }
        `;
    }

    protected buildFilter(filter: Filter<PlayerBonusFilterKeys>): Pick<QueryGetPlayerBonusesArgs, 'filter'> {
        const updatedFilter = produce(filter, draftState => {
            draftState.desc = '!(MTT Ticket)';
        });
        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: PlayerBonusTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], updatedFilter[key] as string)
                    )
                ),
                type: this.toGQLMultiselectFilter(updatedFilter, 'type'),
            },
        };
    }

    private filterFieldsMapper: Record<PlayerBonusTextFilterKeys, string[]> = {
        uid: nameof.toArray<PlayerBonus>(m => [m.uid]),
        bonus_engine_code: nameof.toArray<PlayerBonus>(m => [m.bonus_engine_code]),
        desc: nameof.toArray<PlayerBonus>(m => [m.desc]),
    };
}
