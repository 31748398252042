import {
    AgentPlayerReporting,
    AgentPlayerReportingKr,
    AgentPlayerReportingKrPage,
    AgentPlayerReportingPage,
    AgentPlayerReportingVn,
    AgentPlayerReportingVnPage,
    BonusSummary,
    DeductionSummary,
    GgrSummary,
    NgrSummary,
    OtherSummary,
    PhoneNumber,
    PlayerGamesSummary,
    ProSummary,
    UserContactInfo,
} from '@models/generated/graphql';
import type {Path} from '@models/types';

import {baseFilterKeys} from './base';
import {agentReferrerFilterKeys} from './entityAgentProfile';

export class AgentPlayerReportingExtended extends AgentPlayerReporting {
    is_summary?: boolean;
}

export class AgentPlayerReportingKrExtended extends AgentPlayerReportingKr {
    is_summary?: boolean;
}

export class AgentPlayerReportingVnExtended extends AgentPlayerReportingVn {
    is_summary?: boolean;
}

const agentPlayerReportingTextFilterKeys = [
    'uid',
    'contact.email',
    'uid_email',
    'uid_em_un_rmc',
    'register_marketing_code',
    'username',
    'countries',
] as const;

export type AgentPlayerReportingServerTextFilterKeys = (typeof agentPlayerReportingTextFilterKeys)[number];

export const AgentPlayerReportingDateRangeFilterPrefix = 'transactionStartedTs' as const;

const agentPlayerReportingOtherFilterKeys = [
    `${AgentPlayerReportingDateRangeFilterPrefix}.from`,
    `${AgentPlayerReportingDateRangeFilterPrefix}.to`,
    'region',
    'reportVersion',
    'countries',
] as const;

export const agentPlayerReportingFilterKeys = [
    ...baseFilterKeys,
    ...agentPlayerReportingTextFilterKeys,
    ...agentPlayerReportingOtherFilterKeys,
    ...agentReferrerFilterKeys,
];
export type AgentPlayerReportingServerFilterKeys = (typeof agentPlayerReportingFilterKeys)[number];

export type AgentReportRegion = 'vn' | 'kr';

type AgentPlayerReportingKeys = keyof Pick<
    AgentPlayerReporting,
    | 'uid'
    | 'username'
    | 'register_marketing_code'
    | 'referrer_agent_username'
    | 'referrer_agent_id'
    | 'iso_alpha2_country_code'
    | 'highest_blind_level_is_pro_nlhe'
>;
type AgentPlayerReportingVnKeys = keyof Omit<AgentPlayerReportingVn, 'contact'>;
type AgentPlayerReportingKrKeys = keyof AgentPlayerReportingKr;

const bonusSummaryKey: keyof AgentPlayerReporting = 'bonus_summary';
const deductionSummaryKey: keyof AgentPlayerReporting = 'deduction_summary';
const ggrSummaryKey: keyof AgentPlayerReporting = 'ggr_summary';
const ngrSummaryKey: keyof AgentPlayerReporting = 'ngr_summary';
const playerGamesSummaryKey: keyof AgentPlayerReporting = 'player_games_summary';
const otherSummaryKey: keyof AgentPlayerReporting = 'other_summary';
const contactKey: keyof AgentPlayerReporting = 'contact';
const pro_summary: keyof AgentPlayerReporting = 'pro_summary';

export type AgentPlayerReportingQueryFields =
    | Path<AgentPlayerReportingPage>
    | Path<AgentPlayerReportingVnPage>
    | Path<AgentPlayerReportingKrPage>;

export type AgentPlayerReportingSortingFields =
    | AgentPlayerReportingKeys
    | AgentPlayerReportingVnKeys
    | AgentPlayerReportingKrKeys
    | `${typeof bonusSummaryKey}.${keyof BonusSummary}`
    | `${typeof deductionSummaryKey}.${keyof DeductionSummary}`
    | `${typeof ggrSummaryKey}.${keyof GgrSummary}`
    | `${typeof ngrSummaryKey}.${keyof NgrSummary}`
    | `${typeof playerGamesSummaryKey}.${keyof PlayerGamesSummary}`
    | `${typeof otherSummaryKey}.${keyof OtherSummary}`
    | `${typeof contactKey}.${keyof Pick<UserContactInfo, 'email'>}`
    | `${typeof contactKey}.${keyof UserContactInfo}.${keyof PhoneNumber}`
    | `${typeof pro_summary}.${keyof ProSummary}`
    | 'summary';
