import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {BonusCodeHistory} from '@models/generated/graphql';
import {gqlQueryArgs} from '@services/query/all';

import {IRealtimeService} from '../../realtime-updates/services/IRealtimeService';
import {RealtimeSubscriptionService} from '../../realtime-updates/services/RealtimeUpdatesService';

//TODO: [BO-2716] Rename be-bonus-history to workspace-block-bonus-history
export class BeBonusHistoryRealtimeService extends RealtimeSubscriptionService implements IRealtimeService<BonusCodeHistory> {
    subscribeToCreate(): Observable<BonusCodeHistory> {
        return super.createSubscription(gqlQueryArgs.beBonusHistory.addSubscription).pipe(map(res => res.beBonusHistoryAdded));
    }
}
