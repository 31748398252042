import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import FileSaver from 'file-saver';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {AccountVerification, KycCheckResults, KycCheckResultsFilter, Query} from '@models/generated/graphql';
import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {BaseHttpService, PlayerManagementApiService, ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlSearchFilter} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';
import {AjaxResponse} from '@services/rest-api';
import {sortByTimestamp, toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';
import {IRealtimeGridReadService} from '../realtime-updates/services/IRealtimeGridReadService';

import {DownloadDocumentParams, KYCColumnsConfiguration, KycGridItem, KycItem} from './types';

export class KycService
    extends ReadonlyRealtimeGridServiceBase<KycItem, KycGridItem, KycCheckResults>
    implements IRealtimeGridReadService<string>
{
    private readonly playerHttpService: BaseHttpService;

    constructor(client: ApolloClientProxy<NormalizedCacheObject>, tracingService: ITracingService, userManager: UserManagerExtended) {
        super(client);
        this.playerHttpService = new PlayerManagementApiService(tracingService, userManager, 'knowyourcustomer/document');
    }

    getItemsIds(filter?: SearchFilter): Observable<string[]> {
        const configuration = this.getGQLConfiguration(new KYCColumnsConfiguration(), [
            nameof<KYCColumnsConfiguration>(c => c.withDocRefId),
        ]);
        return this._client.executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter)).pipe(
            map<Query, string[]>(res => {
                return res?.getUsersKYC.items.map(i => i.uid);
            })
        );
    }

    getItems(_filter?: SearchFilter): Observable<KycGridItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsPage(filter?: SearchFilter, itemFields?: string[]): Observable<ItemsPage<KycGridItem>> {
        const configuration = this.getGQLConfiguration(new KYCColumnsConfiguration(), itemFields);

        return this._client.executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter)).pipe(
            map<Query, ItemsPage<KycGridItem>>(res => {
                return {
                    items:
                        res?.getUsersKYC?.items.map((i, index) =>
                            this.mapModels(
                                {
                                    id: `${index}`,
                                    history: i?.history?.map(h => ({
                                        ...h,
                                        latestDocument: (h?.documents ?? [])[0],
                                    })),
                                    latestDocument: ((i?.history ?? [])[0]?.documents ?? [])[0],
                                } as KycGridItem,
                                i
                            )
                        ) ?? [],
                    total: res?.getUsersKYC?.total_count ?? 0,
                };
            })
        );
    }

    getItem(_id: string): Observable<KycItem> {
        throw new Error('Method not implemented.');
    }

    downloadDocument({docRefId, classifier}: DownloadDocumentParams): Observable<AjaxResponse> {
        return this.playerHttpService.get(`?scanReference=${docRefId}&classifier=${classifier}`, {responseType: 'blob'});
    }

    saveDocument(res: Blob): Observable<never> {
        FileSaver.saveAs(res);
        return of();
    }

    protected getItemsPageQuery(configuration: KYCColumnsConfiguration): DocumentNode {
        return gql`
            query GetUserKycPage($filter: KYCCheckResultsFilter, $sort: Sorting, $start: Int, $end: Int) {
                getUsersKYC(filter: $filter, sort: $sort, start: $start, end: $end) {
                    items {
                        uid
                        username ${configuration.withUsername ? '' : '@client'}
                        history {
                            id
                            kyc_case_id ${configuration.withKycCaseId ? '' : '@client'}
                            account_verification_status ${configuration.withAccountVerificationStatus ? '' : '@client'}
                            initiated_at ${configuration.withInitiatedAt ? '' : '@client'} {
                                seconds
                            }
                            type ${configuration.withType ? '' : '@client'}
                            documents {
                                did ${configuration.withDid ? '' : '@client'}
                                doc_ref_id ${configuration.withDocRefId ? '' : '@client'}
                                doc_type ${configuration.withDocType ? '' : '@client'}
                                status ${configuration.withStatus ? '' : '@client'}
                                uploaded_ts ${configuration.withUploadedTs ? '' : '@client'} {
                                    seconds
                                }
                                doc_id_sub_type ${configuration.withDocIdSubType ? '' : '@client'}
                                location  ${configuration.withLocation ? '' : '@client'} {
                                    line1
                                    line2
                                    country
                                    postal_code
                                    city
                                    subdivision
                                    formatted
                                }
                                client_ip ${configuration.withClientIp ? '' : '@client'}
                                number ${configuration.withNumber ? '' : '@client'}
                                first_name ${configuration.withFirstName ? '' : '@client'}
                                last_name ${configuration.withLastName ? '' : '@client'}
                                gender ${configuration.withGender ? '' : '@client'}
                                dob ${configuration.withDOB ? '' : '@client'}
                                ip_verified ${configuration.withIpVerified ? '' : '@client'}
                                device_verified ${configuration.withDeviceVerified ? '' : '@client'}
                                geolocation_verified ${configuration.withGeolocationVerified ? '' : '@client'}
                            }
                        }
                    }
                    total_count
                }
            }
        `;
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        const filter = {
            text: [
                toGQLTextFilter(
                    filters,
                    nameof.toArray<KycCheckResults>(m => [m.uid, m.username])
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<KycCheckResults>(m => [m.uid]),
                    nameof<KycCheckResults>(m => m.uid)
                ),
            ],
        } as KycCheckResultsFilter;

        return filter;
    }

    protected mapModels<TClientModel>(clientModel: TClientModel, serverModel: KycCheckResults): TClientModel {
        super.mapModels(clientModel, serverModel);
        if (serverModel && serverModel.history && serverModel.history.length > 0) {
            const sortHistory = (first: AccountVerification, second: AccountVerification): number =>
                sortByTimestamp((second?.documents ?? [])[0]?.uploaded_ts, (first?.documents ?? [])[0]?.uploaded_ts);
            const sortedHistory = [...serverModel.history].sort(sortHistory);
            Object.assign(clientModel, sortedHistory[0]);
        }

        return clientModel;
    }
}
