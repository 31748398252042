import {createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {AgentFilterViewModel} from '@models/agent-profile/types';
import {ReferrerFilter} from '@models/generated/graphql';

export class AgentProfileMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            ReferrerFilter,
            AgentFilterViewModel,
            forMember(
                vm => vm.is_downstream,
                mapFrom(m => m.is_downstream)
            ),
            forMember(
                vm => vm.referrer_player_id,
                mapFrom(m => m.referrer_player_id)
            )
        );
    }
}
