import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {SquareIconButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {ModalContext} from '@components/modal';
import {gameTemplateUpdatePermissions} from '@models/permissions/permissions';
import {ManagedGameType} from '@redux/entity';

import {LackOfPermissionIndicator, withMultiplePermission} from 'src/features/app/permission/PermissionHoc';
import {useAsyncActionState} from 'src/features/shared/async-action/hooks';
import {gameTemplateActions} from '../actions';

import {GameTemplateModal} from './GameTemplateModal';

const localized = defineMessages({
    modalTitle: {
        id: 'GameTemplateEditButton_modalTitle',
        defaultMessage: 'Modify Template',
    },
});

const useClasses = makeStyles()(() => ({
    gameTemplateEditButton: {
        margin: 'auto',
    },
}));

export type GameTemplateEditButtonProps = {
    id: string;
    gameType: ManagedGameType;
};

function GameTemplateEditButtonInner({id, gameType}: GameTemplateEditButtonProps) {
    const {classes} = useClasses();

    const {openModal, closeModal} = useContext(ModalContext);
    const {isProgress} = useAsyncActionState(gameTemplateActions.editTemplate);

    const handleClick = () => {
        openModal({
            title: <LocalizedText label={localized.modalTitle} />,
            body: <GameTemplateModal onClose={closeModal} id={id} mode="edit" gameType={gameType} />,
        });
    };

    return (
        <SquareIconButton
            className={classes.gameTemplateEditButton}
            onClick={handleClick}
            disabled={isProgress}
            iconClassName={CustomIcon.WriteOutline}
        />
    );
}

export const GameTemplateEditButton = withMultiplePermission(GameTemplateEditButtonInner, {
    allowedPermissions: gameTemplateUpdatePermissions,
    indicator: LackOfPermissionIndicator.Disabled,
});
