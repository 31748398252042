import React from 'react';

import {UserProfile} from '@models/generated/graphql';

import {EditableModalProps} from '../../../module-temp-storage/components/editable/EditableModalHoc';
import {EditSecurityCasesFieldModel, EditSecurityCasesSubmitModel} from '../types';

import {SecurityCasesFieldsForm} from './SecurityCasesFieldsForm';

export function SecurityCasesFieldsModal({
    id,
    value,
    typeName,
}: EditableModalProps<UserProfile, EditSecurityCasesFieldModel, EditSecurityCasesSubmitModel>) {
    return <SecurityCasesFieldsForm value={value} typeName={typeName} id={id} />;
}
