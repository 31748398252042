import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {Query} from '@models/generated/businessPoolGraphql';
import {LegalContentManagementHistoryFilter, LegalManagementHistory} from '@models/generated/businessPoolGraphql';
import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {ConfigApiService} from '@services/deprecated';
import {ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {RestHttpService} from '@services/deprecated';
import {IGQlSearchFilter} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';
import {toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';
import {ILegalDocsService, LegalDocumentRead, LegalDocumentWriteKey} from '../types';

import {LegalDocumentHistoryItem} from './types';

const query = gql`
    query GetLegalContentHistory($filter: LegalContentManagementHistoryFilter, $sort: Sorting, $start: Int, $end: Int) {
        getLegalContentHistory(filter: $filter, sort: $sort, start: $start, end: $end) {
            items {
                user_id
                version
                created_at
                ip
                document
            }
            total_count
        }
    }
`;

export class LegalDocHistoryService
    extends ReadonlyRealtimeGridServiceBase<LegalDocumentRead, LegalDocumentHistoryItem, LegalManagementHistory>
    implements ILegalDocsService
{
    private readonly legalDocManagement: RestHttpService;

    constructor(client: ApolloClientProxy<NormalizedCacheObject>, tracingService: ITracingService, userManager: UserManagerExtended) {
        super(client);
        this.legalDocManagement = new ConfigApiService('legal', tracingService, userManager);
    }

    getItem(id: string): Observable<LegalDocumentRead> {
        return this.legalDocManagement.getItem(id).pipe(
            map<LegalDocumentRead, LegalDocumentRead>(response => {
                response.id = id;
                return response;
            })
        );
    }

    getItems(_filter?: SearchFilter): Observable<LegalDocumentHistoryItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsPage(filter?: SearchFilter): Observable<ItemsPage<LegalDocumentHistoryItem>> {
        return this._client.executeQuery(this.getItemsPageQuery(), this.getGQLVariables(filter)).pipe(
            map<Query, ItemsPage<LegalDocumentHistoryItem>>(res => {
                return {
                    items:
                        res?.getLegalContentHistory?.items.map((i, index) => {
                            return this.mapModels({id: `${index}`, serverId: `${index}`} as LegalDocumentHistoryItem, i);
                        }) ?? [],

                    total: res?.getLegalContentHistory?.total_count ?? 0,
                };
            })
        );
    }

    protected getItemsPageQuery(): DocumentNode {
        return query;
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        const filter = {
            text: [
                toGQLTextFilter(
                    filters,
                    nameof.toArray<LegalManagementHistory>(m => [m.document]),
                    nameof<LegalDocumentWriteKey>(m => m.name)
                ),
            ],
        } as LegalContentManagementHistoryFilter;
        return filter;
    }

    subscribeToUpdates(_filter?: SearchFilter): Observable<void> {
        return of();
    }

    cancelSubscription(): void {}
}
