import {User} from 'oidc-client-ts';

function getUrlWithoutAuthParams(absolute: boolean): string {
    const {origin, pathname} = location;
    const query = getSearchQueryWithoutAuthParams(location.search);
    const relativeUrl = query ? `${pathname}?${query}` : pathname;

    return absolute ? `${origin}${relativeUrl}` : relativeUrl;
}

const authParams = {
    authCode: 'code',
    authScope: 'scope',
    authState: 'state',
    authSessionState: 'session_state',
} as const;

export function checkAuthQueryParams(searchString: string): boolean {
    const searchParams = new URLSearchParams(searchString);
    return Object.values(authParams).some(p => searchParams.has(p));
}

export function getRedirectUrls(origin?: string) {
    const relativeUrl = getUrlWithoutAuthParams(false);
    const absoluteUrl = getUrlWithoutAuthParams(true);
    return {
        relativeUrl,
        absoluteUrl: origin ? `${origin}${relativeUrl}` : absoluteUrl,
    };
}

export function getSearchQueryWithoutAuthParams(searchString: string): string {
    const searchParams = new URLSearchParams(searchString);
    searchParams.delete(authParams.authCode);
    searchParams.delete(authParams.authScope);
    searchParams.delete(authParams.authSessionState);
    searchParams.delete(authParams.authState);

    return searchParams.toString();
}

export function getPolicies(user: User): string[] {
    const policies = user?.profile?.policies;
    let result = (user?.scopes ?? []).concat();
    if (policies) {
        result = result.concat(typeof policies === 'string' ? [policies] : (policies as string[]));
    }

    return result;
}
