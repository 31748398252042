import React from 'react';

import {TransactionFilterKeys} from '@redux/entity';

import DurationRangePicker from 'src/features/shared/filter/components/value-range-picker/DurationRangePicker';

import {TransactionFilterProps} from './TransactionFilters';

function TransactionFilterDuration({filter, onFilterChange}: TransactionFilterProps) {
    const keys: TransactionFilterKeys[] = ['startedTs.from', 'startedTs.to'];
    return <DurationRangePicker onFilterChange={onFilterChange} domain={null} keys={keys} filterString={filter} />;
}

export default TransactionFilterDuration;
