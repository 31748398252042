import React, {PropsWithChildren} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Typography} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';

type BulkApplyStepLockGroupProps = {
    title: MessageDescriptor;
};

export function BulkChooseActionStepLockGroup({title, children}: PropsWithChildren<BulkApplyStepLockGroupProps>) {
    return (
        <div>
            <Typography variant="subtitle2" color="secondary">
                <LocalizedText label={title} />
            </Typography>
            {children}
        </div>
    );
}
