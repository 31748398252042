import React from 'react';
import {defineMessages} from 'react-intl';

import {useAutoMapper} from '@auto-mapper';
import {LayoutDetailsBlock} from '@components/layout/LayoutDetailsBlock';
import {agentReadPolicies} from '@models/permissions/permissions';
import {PlayerBonusViewModelKeys} from '@models/player-bonus';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils/string';

import {useMultiplePermissions} from '../../app/permission/PermissionHoc';
import {BonusCodeDetailsViewModelKeys} from '../../block-bonus-code/types';
import {DataGridPlayerBonusServer, usePlayerBonuses} from '../../block-player-bonus';
import {PlayerBonusColumn} from '../../block-player-bonus/components/DataGridPlayerBonus';

const localized = defineMessages({
    emptyList: {
        id: 'СashBonuses_emptyList',
        defaultMessage: 'Bonus codes list is empty',
    },
    title: {
        id: 'CashBonuses_title',
        defaultMessage: 'Cash Bonuses',
    },
});

type CashBonusesProps = {
    userId: string;
};

export const CashBonuses = ({userId}: CashBonusesProps) => {
    const viewType: ViewType = 'CashBonuses';
    const mapper = useAutoMapper();

    const isNotAgent = useMultiplePermissions({restrictedPermissions: agentReadPolicies});
    let columns: PlayerBonusColumn[] = [
        'bonus_status',
        'marketing_code',
        'bonus_name',
        'desc',
        'realized_amount',
        'total_amount',
        'remaining_amount',
        'expire_at.seconds',
        isNotAgent ? 'expireButton' : null,
    ];
    columns = columns.filter((c: PlayerBonusColumn) => c);

    const playerBonusKeys: PlayerBonusViewModelKeys[] = mapper.map(
        columns,
        `${nameof<PlayerBonusColumn>()}[]`,
        `${nameof<PlayerBonusViewModelKeys>()}[]`
    );
    const bonusCodeKeys: BonusCodeDetailsViewModelKeys[] = mapper.map(
        columns,
        `${nameof<PlayerBonusColumn>()}[]`,
        `${nameof<BonusCodeDetailsViewModelKeys>()}[]`
    );

    const {items, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = usePlayerBonuses(
        {
            viewType,
            fields: [...playerBonusKeys, 'bonus_engine_code'],
            realtimeMode: RealtimeUpdatesMode.Confirm,
            triggers: [{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Update}],
            defaultFilters: [{key: 'uid', value: userId}],
            defaultSorting: [{field: 'created_at.seconds', sort: 'desc'}],
            validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
        },
        {
            viewType,
            fields: bonusCodeKeys,
        }
    );

    return (
        <LayoutDetailsBlock
            title={localized.title}
            content={
                <DataGridPlayerBonusServer
                    autoHeight
                    columns={columns}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                    emptyMessage={localized.emptyList}
                />
            }
        />
    );
};
