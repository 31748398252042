import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {CustomTheme} from '@style';
import {sharedLocalization} from '@localization';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    multiStepFilterHeaderCloseContainer: {
        display: 'flex',
        minWidth: '50px',
        marginLeft: 'auto',
        alignItems: 'center',
    },
    multiStepFilterHeaderCloseChip: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    multiStepFilterHeaderCloseIcon: {
        fontSize: theme.typography.caption.fontSize,
    },
    multiStepFilterHeader: {
        display: 'flex',
        margin: theme.spacing(1.5),
    },
    multiStepFilterHeaderBackContainer: {
        display: 'flex',
        minWidth: '50px',
        marginRight: 'auto',
        alignItems: 'center',
    },
    multiStepFilterHeaderLabel: {
        alignItems: 'center',
    },
    multiStepFilterHeaderBackButton: {
        cursor: 'pointer',
        color: theme.palette.text.secondary,
        display: 'flex',
        marginRight: 'auto',
        minWidth: '50px',
        alignItems: 'center',
    },
    multiStepFilterHeaderIcon: {
        fontSize: theme.typography.caption.fontSize,
        marginRight: theme.spacing(1),
    },
}));

type MultiStepFilterHeaderProps = {
    onClose: () => void;
    onBack?: () => void;
    label: string | MessageDescriptor;
};

export function MultiStepFilterHeader({onBack, label, onClose}: MultiStepFilterHeaderProps) {
    const {classes} = useClasses();
    return (
        <div className={classes.multiStepFilterHeader}>
            <div className={classes.multiStepFilterHeaderBackContainer}>
                {onBack ? (
                    <Typography variant="body1" className={classes.multiStepFilterHeaderBackButton} onClick={onBack}>
                        <Icon icon={CustomIcon.LessThan} className={classes.multiStepFilterHeaderIcon}></Icon>
                        <LocalizedText label={sharedLocalization.backButtonLabel} />
                    </Typography>
                ) : null}
            </div>
            <Typography variant="h6" className={classes.multiStepFilterHeaderLabel}>
                <LocalizedText label={label} />
            </Typography>
            <Typography variant="body1" className={classes.multiStepFilterHeaderCloseContainer}>
                <Chip
                    fillWidth={false}
                    chipType={ChipType.Mark}
                    chipVariant={ChipVariant.Grey}
                    onClick={onClose}
                    className={classes.multiStepFilterHeaderCloseChip}
                    label={
                        <Icon
                            icon={CustomIcon.CancelOutline}
                            className={classes.multiStepFilterHeaderCloseIcon}
                            color={IconColor.Secondary}
                        />
                    }
                />
            </Typography>
        </div>
    );
}
