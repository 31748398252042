import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import LocalizedText from '@components/i18n/LocalizedText';
import StyledTooltip from '@components/Tooltip';

import {formatMethodLabel} from '../formatter';
import {FinanceSummaryModel} from '../types';

export const renderTransactionMethod = (params: GridCellParams) => {
    const value = formatMethodLabel((params.row as FinanceSummaryModel).method);
    return <StyledTooltip element={<LocalizedText label={value} />} title={<LocalizedText label={value} />} />;
};
