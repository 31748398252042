import {defineMessages} from 'react-intl';

import {AccountVerificationStatus} from '@models/generated/graphql';

//TODO: [BO-2710] Move src/features/intl/shared-resources to src/common/config/i18n
export const localizedAccountVerificationStatus = defineMessages({
    [AccountVerificationStatus.InitLoginKyc]: {
        id: 'accountVerificationStatusPending',
        defaultMessage: 'Pending',
    },
    [AccountVerificationStatus.InitWithdrawalKyc]: {
        id: 'accountVerificationStatusPending',
        defaultMessage: 'Pending',
    },
    [AccountVerificationStatus.InitDepositKyc]: {
        id: 'accountVerificationStatusPending',
        defaultMessage: 'Pending',
    },
    [AccountVerificationStatus.InitPaymentKyc]: {
        id: 'accountVerificationStatusPending',
        defaultMessage: 'Pending',
    },
    [AccountVerificationStatus.InitNdrpKyc]: {
        id: 'accountVerificationStatusPending',
        defaultMessage: 'Pending',
    },
    [AccountVerificationStatus.OnHoldLoginKyc]: {
        id: 'accountVerificationStatusOnHoldLoginKyc',
        defaultMessage: 'On Hold',
    },
    [AccountVerificationStatus.OnHoldWithdrawalKyc]: {
        id: 'accountVerificationStatusOnHoldWithdrawalKyc',
        defaultMessage: 'On Hold',
    },
    [AccountVerificationStatus.OnHoldDepositKyc]: {
        id: 'accountVerificationStatusOnHoldDepositKyc',
        defaultMessage: 'On Hold',
    },
    [AccountVerificationStatus.OnHoldPaymentKyc]: {
        id: 'accountVerificationStatusOnHoldPaymentKyc',
        defaultMessage: 'On Hold',
    },
    [AccountVerificationStatus.Approved]: {
        id: 'accountVerificationStatusApproved',
        defaultMessage: 'Approved',
    },
    [AccountVerificationStatus.DeniedLoginKyc]: {
        id: 'accountVerificationStatusReject',
        defaultMessage: 'Reject',
    },
    [AccountVerificationStatus.DeniedDepositKyc]: {
        id: 'accountVerificationStatusReject',
        defaultMessage: 'Reject',
    },
    [AccountVerificationStatus.DeniedWithdrawalKyc]: {
        id: 'accountVerificationStatusReject',
        defaultMessage: 'Reject',
    },
    [AccountVerificationStatus.DeniedPaymentKyc]: {
        id: 'accountVerificationStatusReject',
        defaultMessage: 'Reject',
    },
    [AccountVerificationStatus.DeniedNdrpKyc]: {
        id: 'accountVerificationStatus_DeniedNdrpKyc',
        defaultMessage: 'Reject',
    },
    pendingLabel: {
        id: 'accountVerificationStatusPending',
        defaultMessage: 'Pending',
    },
    onHoldLabel: {
        id: 'accountVerificationStatusOnHold',
        defaultMessage: 'On Hold',
    },
    approvedLabel: {
        id: 'accountVerificationStatusApproved',
        defaultMessage: 'Approved',
    },
    rejectLabel: {
        id: 'accountVerificationStatusReject',
        defaultMessage: 'Reject',
    },
});
