import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {AmlLabel} from '@models/generated/graphql';

import {localizedAmlLabel} from '../../app/intl/shared-resources/amlLabel';
import {emptyCellCharacter} from '../../module-shared/utils';

export type AmlLabelHistoryValue = {
    aml_labels: AmlLabel[];
};

export const useAmlLabelFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: AmlLabelHistoryValue = JSON.parse(json);

    const formatAmlLabel = (amlLabel: AmlLabel): ChipKeyValue => {
        return amlLabel ? {key: formatMessage(localizedAmlLabel[amlLabel])} : {key: emptyCellCharacter};
    };

    return value?.aml_labels?.map(formatAmlLabel) ?? [];
};
