import React from 'react';

import {getValueFromQueryFilter} from '@utils/query';

import {FilterProps as FilterPropsObsolete} from '../../shared/filter/types';
import {RegistrationCountryFilterKey, registrationCountryFilterKey} from '../types';

import {CountryFilter} from './CountryFilter';

// TODO: Remove after Withdrawal, KYC, CS workspaces are moved to new FilterGroup
/**
 * @deprecated Should be removed after Withdrawal, KYC, CS workspaces are moved to new FilterGroup. Use {@link CountryFilter}
 * */
export function CountryFilterAdapter({filterString, onFilterChange}: FilterPropsObsolete) {
    function getValue(filter: string): string[] {
        const stringValue = getValueFromQueryFilter<RegistrationCountryFilterKey, string>(filter, registrationCountryFilterKey);
        let value: string[];
        try {
            value = JSON.parse(stringValue);
        } catch (e) {
            value = JSON.parse(JSON.stringify(stringValue));
        }
        return value;
    }

    function handleChange(newValue: string[]) {
        onFilterChange([{key: registrationCountryFilterKey, value: newValue}]);
    }

    return <CountryFilter value={getValue(filterString)} onChange={handleChange} />;
}
