import {ChipType, ChipVariant} from '@components/chip/types';
import {BonusCreditType} from '@models/generated/graphql';

import {localizedBeCreditType} from '../../../features/app/intl/shared-resources/beCreditType';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export const getBeCreditTypeChipsMapping = (chipType: ChipType) => ({
    [BonusCreditType.MttToken]: {
        label: localizedBeCreditType[BonusCreditType.MttToken],
        chipType,
        chipVariant: ChipVariant.Green,
    },
    [BonusCreditType.CashBonus]: {
        label: localizedBeCreditType[BonusCreditType.CashBonus],
        chipType,
        chipVariant: ChipVariant.LightBlue,
    },
});
