import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {Chip} from '@components/chip/Chips';
import {ChipProps, ChipType, ChipVariant} from '@components/chip/types';

const useStyles = makeStyles()(theme => ({
    counterChipIcon: {
        backgroundColor: `${theme.palette.background.paper} !important`,
        height: 'auto',
        '& .MuiChip-label': {
            paddingLeft: theme.spacing(0.75),
            paddingRight: theme.spacing(0.75),
            fontSize: theme.typography.caption.fontSize,
        },
        '&.MuiChip-iconSmall': {
            marginLeft: theme.spacing(0.375),
        },
    },
}));

type ChipCounterProps = ChipProps & {
    count: number;
};

export function ChipCounter({count, ...props}: ChipCounterProps) {
    const {classes} = useStyles();

    return (
        <Chip
            {...props}
            icon={
                count > 1 ? (
                    <Chip
                        fillWidth={false}
                        className={classes.counterChipIcon}
                        chipType={ChipType.Status}
                        chipVariant={ChipVariant.Default}
                        label={count}
                    />
                ) : null
            }
        />
    );
}
