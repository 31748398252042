import React from 'react';
import {defineMessages} from 'react-intl';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {LayoutDetailsHeaderActions} from '@components/layout/LayoutDetailsHeaderActions';
import {LayoutDetailsHeaderItem} from '@components/layout/LayoutDetailsHeaderItem';
import {EntityFilterType, EntityType, UserAccountStatus} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {RealtimeUpdatesMode} from '@redux/realtime';

import {useUserProfileDetails} from 'src/features/block-user-profile-details/hooks';
import {ActionHistoryModalAction} from '../../action-history/components/ActionHistoryModalAction';
import {useBreadcrumbInitializer} from '../../app/breadcrumb/hooks';

import {WorkspaceHeader} from './WorkspaceHeader';

const localized = defineMessages({
    accountStatus: {
        id: 'CustomerSupportDetailsHeader_accountStatus',
        defaultMessage: 'Account Status',
    },
    playerDetailsRoute: {
        id: 'CustomerSupportDetailsHeader_PlayerDetailsRoute',
        defaultMessage: 'Player Details',
    },
});

type CustomerSupportDetailsHeaderProps = {
    uid: string;
};

export const CustomerSupportDetailsHeader = ({uid}: CustomerSupportDetailsHeaderProps) => {
    const fields: UserProfileViewModelKeys[] = ['uid', 'account_status'];
    const {item} = useUserProfileDetails({
        id: uid,
        viewType: 'CustomerSupportDetailsHeader',
        fields,
        realtimeMode: RealtimeUpdatesMode.Silent,
    });
    useBreadcrumbInitializer(uid, undefined, undefined, localized.playerDetailsRoute);

    return (
        <WorkspaceHeader>
            <LayoutDetailsHeaderItem label={localized.accountStatus}>
                <MemoizedChip chipName={nameof<UserAccountStatus>()} value={item?.account_status} />
            </LayoutDetailsHeaderItem>
            <LayoutDetailsHeaderActions>
                <ActionHistoryModalAction
                    uid={uid}
                    options={{
                        id: uid,
                        type: EntityType.Player,
                        filterType: EntityFilterType.AllRecords,
                    }}
                />
            </LayoutDetailsHeaderActions>
        </WorkspaceHeader>
    );
};
