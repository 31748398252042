import {merge, Observable, of, throwError} from 'rxjs';
import {bufferTime, catchError, delay, filter} from 'rxjs/operators';
import {isActionOf, PayloadAction, RootState} from 'typesafe-actions';

import {map, mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {equals} from '@redux/realtime';

import {ItemsPage, SearchFilter} from 'src/common/types';
import {ServiceContainer} from '../../app/inversify/serviceContainer';
import {logErrorAction} from '../app/error-handling/actions';
import {protectEpics} from '../app/error-handling/epics';
import {ErrorCategory} from '../app/error-handling/types';
import {contentActions} from '../module-shared/actions';
import {getFilterUtils} from '../shared/filter/utils';

import {IRealtimeGridReadService} from './services/IRealtimeGridReadService';
import {IRealtimeService} from './services/IRealtimeService';
import {createEntityRealtimeActions, createSubscriberRealtimeActions, realtimeNotificationActions} from './actions';
import {realtimeNotificationSelector} from './selectors';
import {RealtimeBufferTimeSpan, RealtimeUpdatesMeta, SubscriptionType} from './types';
import {isSubscriptionAvailable} from './utils';

export function createEntityRealtimeEpics<TSubscriptionResult>(
    domain: string,
    serviceResolver: (container: ServiceContainer) => IRealtimeService<TSubscriptionResult>,
    subscribersSelector: (state: RootState) => string[],
    subscribedTypesSelector: (state: RootState) => SubscriptionType[]
): RootEpic {
    const actions = createEntityRealtimeActions(domain);

    const enableSubscriptionEpic: RootEpic = (action$, state$) =>
        action$.pipe(
            filter(isActionOf(actions.enableRealtime)),
            mergeMap(action => {
                const subscriptionActions = [];
                const subscribedTypes = subscribedTypesSelector(state$.value);
                const isFirstSubscription = (type: SubscriptionType): boolean =>
                    action.payload.types.includes(type) && subscribedTypes.filter(t => t === type).length === 1;
                if (isFirstSubscription(SubscriptionType.Added)) {
                    subscriptionActions.push(actions.subscribeToCreate());
                }
                if (isFirstSubscription(SubscriptionType.Updated)) {
                    subscriptionActions.push(actions.subscribeToUpdate());
                }
                if (isFirstSubscription(SubscriptionType.Deleted)) {
                    subscriptionActions.push(actions.subscribeToDelete());
                }
                return subscriptionActions.length ? of(...subscriptionActions) : of();
            })
        );

    const createItemAddedSubscriptionEpic: RootEpic = (action$, state$, container) =>
        action$.pipe(
            filter(isActionOf(actions.subscribeToCreate)),
            mergeMap(() => serviceResolver(container).subscribeToCreate()),
            mergeMap(item =>
                of(...subscribersSelector(state$.value).map(s => createSubscriberRealtimeActions<TSubscriptionResult>(s).itemAdded(item)))
            ),
            catchError((error, source) => {
                const isSubscriptionEnabled = subscribedTypesSelector(state$.value).length > 0;
                return isSubscriptionAvailable(error) && isSubscriptionEnabled
                    ? merge(source, of(actions.subscribeToCreate(), logErrorAction({error, category: ErrorCategory.ReduxMiddleware})))
                    : throwError(error);
            })
        );

    const createItemUpdatedSubscriptionEpic: RootEpic = (action$, state$, container) =>
        action$.pipe(
            filter(isActionOf(actions.subscribeToUpdate)),
            mergeMap(() => serviceResolver(container).subscribeToUpdate()),
            mergeMap(item =>
                of(...subscribersSelector(state$.value).map(s => createSubscriberRealtimeActions<TSubscriptionResult>(s).itemUpdated(item)))
            ),
            catchError((error, source) => {
                const isSubscriptionEnabled = subscribedTypesSelector(state$.value).length > 0;
                return isSubscriptionAvailable(error) && isSubscriptionEnabled
                    ? merge(source, of(actions.subscribeToUpdate(), logErrorAction({error, category: ErrorCategory.ReduxMiddleware})))
                    : throwError(error);
            })
        );

    const createItemDeletedSubscriptionEpic: RootEpic = (action$, state$, container) =>
        action$.pipe(
            filter(isActionOf(actions.subscribeToDelete)),
            mergeMap(() => serviceResolver(container).subscribeToDelete()),
            mergeMap(() =>
                of(...subscribersSelector(state$.value).map(s => createSubscriberRealtimeActions<TSubscriptionResult>(s).itemDeleted()))
            ),
            catchError((error, source) => {
                const isSubscriptionEnabled = subscribedTypesSelector(state$.value).length > 0;
                return isSubscriptionAvailable(error) && isSubscriptionEnabled
                    ? merge(source, of(actions.subscribeToDelete(), logErrorAction({error, category: ErrorCategory.ReduxMiddleware})))
                    : throwError(error);
            })
        );

    const disableRealtimeEpic: RootEpic = (action$, state$) =>
        action$.pipe(
            filter(isActionOf(actions.disableRealtime)),
            mergeMap(() => (subscribersSelector(state$.value).length === 0 ? of(actions.stopRealtime()) : of()))
        );

    const cancelSubscriptionEpic: RootEpic = (action$, _, container) =>
        action$.pipe(
            filter(isActionOf(actions.stopRealtime)),
            map(() => serviceResolver(container).prepareSubscriptionToRemove()),
            delay(3000),
            mergeMap(() => {
                serviceResolver(container).cancelSubscription();
                return of();
            })
        );

    return protectEpics(
        enableSubscriptionEpic,
        createItemAddedSubscriptionEpic,
        createItemUpdatedSubscriptionEpic,
        createItemDeletedSubscriptionEpic,
        disableRealtimeEpic,
        cancelSubscriptionEpic
    );
}

export const createGridSubscriberRealtimeEpics = <TModel, TId = string>(
    realtimeUpdatesMeta: RealtimeUpdatesMeta,
    itemsSelector: (state: RootState) => ItemsPage<TModel>,
    idSelector: (item: TModel) => TId,
    subscriptionTypes: SubscriptionType[],
    serviceResolver: (container: ServiceContainer) => IRealtimeGridReadService<TId>,
    filterSelector: (state: RootState) => string,
    defaultFilterString?: string,
    bufferTimeSpan = RealtimeBufferTimeSpan
) => {
    const subscriberActions = createSubscriberRealtimeActions<TModel>(realtimeUpdatesMeta.realtimeKey);
    const updatesTriggeredAction = realtimeUpdatesMeta.forceReload
        ? realtimeNotificationActions.reload(realtimeUpdatesMeta.domain)
        : realtimeNotificationActions.notifyUpdates(realtimeUpdatesMeta);

    const batchAddedItemsEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(subscriberActions.itemAdded)),
            bufferTime(bufferTimeSpan),
            mergeMap(actions => {
                return actions.length > 0 ? of(subscriberActions.compareItemsWithPrefetch()) : of();
            })
        );

    const batchUpdatedItemsEpic: RootEpic = (action$, state$) =>
        action$.pipe(
            filter(isActionOf(subscriberActions.itemUpdated)),
            bufferTime(bufferTimeSpan),
            mergeMap(actions => {
                let result: Observable<PayloadAction<string, unknown>> = of();
                if (actions?.length > 0) {
                    const items: TModel[] = itemsSelector(state$.value)?.items;
                    const isUpdated = items?.some(item => {
                        let isItemUpdated = false;

                        if (item) {
                            const payloadItems = actions.filter(a => idSelector(a.payload) === idSelector(item)).map(a => a.payload);
                            isItemUpdated = payloadItems.some(p => !equals(item, p));
                        }

                        return isItemUpdated;
                    });
                    if (isUpdated) {
                        result = of(updatesTriggeredAction);
                    } else {
                        const itemsIds: TId[] = items?.map(idSelector);
                        const hasNewItems: boolean = actions.some(a => !itemsIds?.includes(idSelector(a.payload)));
                        if (hasNewItems) {
                            result = of(subscriberActions.compareItemsWithPrefetch());
                        }
                    }
                }

                return result;
            })
        );

    const batchDeletedItemsEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(subscriberActions.itemDeleted)),
            bufferTime(bufferTimeSpan),
            mergeMap(actions => {
                return actions.length > 0 ? of(subscriberActions.compareItemsWithPrefetch()) : of();
            })
        );

    const compareItemsIdsWithPrefetchEpic: RootEpic = (action$, state$, container) =>
        action$.pipe(
            filter(isActionOf(subscriberActions.compareItemsWithPrefetch)),
            mergeMap(() => {
                const {selectSearchFilter} = getFilterUtils(false, filterSelector);
                const searchFilter: SearchFilter = selectSearchFilter(state$.value, defaultFilterString);
                return serviceResolver(container).getItemsIds(searchFilter);
            }),
            mergeMap(payloadItemsIds => {
                const currentItemsIds = itemsSelector(state$.value)
                    ?.items?.filter(i => i)
                    ?.map(i => idSelector(i));
                return currentItemsIds?.length === payloadItemsIds?.length && currentItemsIds?.every(i => payloadItemsIds.includes(i))
                    ? of()
                    : of(updatesTriggeredAction);
            })
        );

    const conditionalEpics = [
        subscriptionTypes.includes(SubscriptionType.Added) ? batchAddedItemsEpic : null,
        subscriptionTypes.includes(SubscriptionType.Updated) ? batchUpdatedItemsEpic : null,
        subscriptionTypes.includes(SubscriptionType.Deleted) ? batchDeletedItemsEpic : null,
    ].filter(e => e !== null);

    return protectEpics(...conditionalEpics, compareItemsIdsWithPrefetchEpic);
};

export const createItemSubscriberRealtimeEpics = <TModel, TId = string>(
    realtimeUpdatesMeta: RealtimeUpdatesMeta,
    itemSelector: (state: RootState) => TModel,
    idSelector: (item: TModel) => TId,
    bufferTimeSpan = RealtimeBufferTimeSpan
) => {
    const subscriberActions = createSubscriberRealtimeActions<TModel>(realtimeUpdatesMeta.realtimeKey);
    const updatesTriggeredAction = realtimeUpdatesMeta.forceReload
        ? realtimeNotificationActions.reload(realtimeUpdatesMeta.domain)
        : realtimeNotificationActions.notifyUpdates(realtimeUpdatesMeta);

    const batchUpdatedEpic: RootEpic = (action$, state$) =>
        action$.pipe(
            filter(isActionOf(subscriberActions.itemUpdated)),
            bufferTime(bufferTimeSpan),
            mergeMap(actions => {
                let result: Observable<PayloadAction<string, unknown>> = of();
                if (actions?.length > 0) {
                    const item: TModel = itemSelector(state$.value);
                    const payloadItems = actions?.filter(a => idSelector(a.payload) === idSelector(item));
                    const isUpdated = payloadItems?.some(p => !equals(item, p.payload));
                    if (isUpdated) {
                        result = of(updatesTriggeredAction);
                    }
                }
                return result;
            })
        );

    return protectEpics(batchUpdatedEpic);
};

const createRealtimeGlobalEpics = () => {
    const reloadEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(realtimeNotificationActions.reload)),
            map(action => contentActions(action.payload).contentLoad.request())
        );

    const reloadAllEpic: RootEpic = (action$, state$) =>
        action$.pipe(
            filter(isActionOf(realtimeNotificationActions.reloadAll)),
            mergeMap(() => {
                const resultActions: PayloadAction<string, void>[] = [];
                const domains: string[] = realtimeNotificationSelector(state$.value).map(m => m.domain);
                resultActions.push(...domains.map(domain => contentActions(domain).contentLoad.request()));
                resultActions.push(realtimeNotificationActions.clearNotification());

                return of(...resultActions);
            })
        );

    return protectEpics(reloadEpic, reloadAllEpic);
};

export const realtimeNotificationEpic: RootEpic = createRealtimeGlobalEpics();
