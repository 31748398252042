import React from 'react';
import {defineMessages} from 'react-intl';

import {LayoutDetailsBlock} from '@components/layout/LayoutDetailsBlock';
import {SecurityCaseType} from '@models/generated/graphql';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {DataGridPlayersRelationClient} from '../../block-players-relation/components/DataGridPlayersRelation';
import {usePlayersRelations} from '../../block-players-relation/hooks';
import {PlayersRelationViewModelKeys} from '../../block-players-relation/types';

const localized = defineMessages({
    relatedPlayersTitle: {
        id: 'RealtimeMessage_relatedPlayer',
        defaultMessage: 'Related Player',
    },
    emptyList: {
        id: 'RP_emptyList',
        defaultMessage: 'Related players list is empty',
    },
});

type UserRelatedPlayersProps = {
    userId: string;
};

export const UserRelatedPlayers = ({userId}: UserRelatedPlayersProps) => {
    const viewType: ViewType = 'PlayersRelations';
    const keys: PlayersRelationViewModelKeys[] = [
        'id',
        'related_player.uid',
        'related_player.username',
        'relation_type',
        'relevance',
        'related_player.security_cases',
        'created_at',
    ];
    const {items} = usePlayersRelations({
        viewType,
        fields: keys,
        displayName: localized.relatedPlayersTitle,
        defaultFilters: [
            {key: 'player_uid', value: userId},
            {
                key: 'security_case_types',
                value: [
                    SecurityCaseType.Aml,
                    SecurityCaseType.BlacklistProbable,
                    SecurityCaseType.Bot,
                    SecurityCaseType.ChipDumping,
                    SecurityCaseType.Collusion,
                    SecurityCaseType.ExternalRequest,
                    SecurityCaseType.Finance,
                    SecurityCaseType.GeoLocation,
                    SecurityCaseType.Kyc,
                    SecurityCaseType.SharedInstance,
                    SecurityCaseType.Undefined,
                ],
            },
        ],
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'player_uid')?.value),
        realtimeMode: RealtimeUpdatesMode.Confirm,
        triggers: [{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Delete}],
    });

    return (
        <LayoutDetailsBlock
            title={localized.relatedPlayersTitle}
            content={
                /* for data grid currently client pagination used as gql query return all records (ignoring pagination) and total 1*/
                <DataGridPlayersRelationClient autoHeight columns={keys} rows={items} emptyMessage={localized.emptyList} />
            }
        ></LayoutDetailsBlock>
    );
};
