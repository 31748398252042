import {IModuleGridItem} from '@components/data-grid';
import {MuiDataGridClient, MuiDataGridServer, withDataGridEntityClient, withDataGridEntityServer} from '@components/data-grid/mui';
import {PlayerBonus, UserProfile} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';

import {DataGridUserProfileConfigFactory} from '../../block-user-profile-list';

import {DataGridPlayerBonusColumn, DataGridPlayerBonusConfigFactory} from './DataGridPlayerBonus';

export type DataGridUserProfileWithPlayerBonusColumns = UserProfileViewModelKeys | DataGridPlayerBonusColumn;

export const DataGridUserProfileWithPlayerBonusClient = withDataGridEntityClient<
    DataGridUserProfileWithPlayerBonusColumns,
    UserProfile & PlayerBonus & IModuleGridItem
>(MuiDataGridClient, options => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridPlayerBonusConfigFactory().getColumnConfig(options),
}));

export const DataGridUserProfileWithPlayerBonusServer = withDataGridEntityServer<
    DataGridUserProfileWithPlayerBonusColumns,
    UserProfile & PlayerBonus & IModuleGridItem
>(MuiDataGridServer, options => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridPlayerBonusConfigFactory().getColumnConfig(options),
}));
