import React, {Fragment} from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {PlayerProfile} from '../types';

import ResendPasscodeAction from './ResendPasscodeAction';

export const RegistrationInfoActions = (params: GridCellParams) => {
    const id = (params.row as PlayerProfile).uid;

    return (
        <Fragment>
            <ResendPasscodeAction id={id}></ResendPasscodeAction>
        </Fragment>
    );
};
