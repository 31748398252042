import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {AccountVerificationFilterKeys} from '@redux/entity';

import {JurisdictionSelectOption} from '../../module-shared/types';
import FilterTextWithDropdown from '../../shared/filter/components/FilterTextWithDropdown';
import {localizedFilters} from '../../shared/filter/localize';
import {MultipleKeysFilter} from '../../shared/filter/types';

import {AccountVerificationsFilterProps} from './AccountVerificationsFilters';

const localized = defineMessages({
    textFullFilter: {
        id: 'AccountVerificationsFilterText_textFullFilter',
        defaultMessage: 'All',
    },
    userIdFilter: {
        id: 'AccountVerificationsFilterText_userIdFilter',
        defaultMessage: 'User IDs',
    },
    caseIdFilter: {
        id: 'AccountVerificationsFilterText_caseIdFilter',
        defaultMessage: 'KYC ID',
    },
    emailFilter: {
        id: 'AccountVerificationsFilterText_emailFilter',
        defaultMessage: 'Email',
    },
    textFullFilterPlaceholder: {
        id: 'AccountVerificationsFilterText_textFullFilterPlaceholder',
        defaultMessage: 'Search in KYC Workspace',
    },
});

function AccountVerificationsFilterText({filter, onFilterChange}: AccountVerificationsFilterProps) {
    const keys: AccountVerificationFilterKeys[] = ['uidIdEmail', 'uid', 'id', 'email'];
    const textSearchOptions: JurisdictionSelectOption[] = [
        {label: localized.textFullFilter, value: 'uidIdEmail'},
        {label: localized.userIdFilter, value: 'uid'},
        {label: localized.caseIdFilter, value: 'id'},
        {
            label: localized.emailFilter,
            value: 'email',
        },
    ];

    const getFilterLabel = (key: string): MessageDescriptor => {
        return {
            ['uidIdEmail']: localized.textFullFilterPlaceholder,
            ['uid']: localizedFilters.searchFilterPlaceholder,
            ['id']: localizedFilters.searchFilterPlaceholder,
            ['email']: localizedFilters.searchFilterPlaceholder,
        }[key];
    };

    return (
        <FilterTextWithDropdown
            onFilterChange={onFilterChange}
            domain={null}
            filter={
                {
                    keys,
                    options: textSearchOptions,
                    getLabel: getFilterLabel,
                } as MultipleKeysFilter
            }
            filterString={filter}
            disallowSpecialCharacters
        />
    );
}

export default AccountVerificationsFilterText;
