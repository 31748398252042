import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {PhoneNumber, UserContactInfo} from '@models/generated/graphql';

export type ModifiedMobileHistoryValue = {
    contact: UserContactInfo;
};

export const useModifiedMobileFormatter = (json: string): ChipKeyValue[] => {
    const value: ModifiedMobileHistoryValue = JSON.parse(json);

    const formatMobilePhone = (phone: PhoneNumber) => {
        const phoneParts = [phone.area, phone.mobile].filter(p => p);
        return phoneParts.length > 0 ? phoneParts.join(' ') : null;
    };

    return value?.contact?.mobile ? [{key: formatMobilePhone(value.contact.mobile)}] : null;
};
