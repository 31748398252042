import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {AccountVerificationStatus, KycReasonCode} from '@models/generated/graphql';

import {localizedAccountVerificationStatus, localizedKYCReasonCodes} from '../../app/intl';

export type AccountVerificationStatusValue = {
    account_verification_status: AccountVerificationStatus;
    reason_codes?: KycReasonCode[];
};

export const useAccountVerificationStatusFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: AccountVerificationStatusValue = JSON.parse(json);
    const reasonCodes: string = value?.reason_codes?.map(code => formatMessage(localizedKYCReasonCodes[code]))?.join(', ');
    return value?.account_verification_status
        ? [
              {key: formatMessage(localizedAccountVerificationStatus[value?.account_verification_status])},
              ...(reasonCodes ? [{key: reasonCodes}] : []),
          ]
        : null;
};
