import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {BonusCodeDetails, BonusCodeInput, BonusStatus, MutationDeactivateBonusArgs} from '@models/generated/graphql';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {entityActions, EntityType} from '@redux/entity';
import {IBonusCodeService} from '@services/bonusCodeService';
import {GqlMutationRequest} from '@services/types';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {bonusCodeActions} from './actions';
import {BonusCodeNormalized} from './types';

const localized = defineMessages({
    deactivateBonusSuccessMessage: {
        id: 'BonusCodeEpicsBuilder_deactivateBonusSuccessMessage',
        defaultMessage: 'Bonus has been deactivated',
    },
    deactivateBonusFailureMessage: {
        id: 'BonusCodeEpicsBuilder_deactivateBonusFailureMessage',
        defaultMessage: 'Failed attempt to deactivate the bonus. Try again later',
    },
    addBonusCodeSuccessMessage: {
        id: 'BonusCodeEpicsBuilder_addBonusCodeSuccessMessage',
        defaultMessage: 'Bonus successfully created',
    },
    addBonusCodeFailureMessage: {
        id: 'BonusCodeEpicsBuilder_addBonusCodeFailureMessage',
        defaultMessage: 'Failed to create bonus',
    },
});

@injectable()
export class BonusCodeEpicsBuilder extends BaseEpicsBuilder {
    private readonly _service: IBonusCodeService;

    constructor(@inject(ServiceTypes.BonusCodeService) service: IBonusCodeService) {
        super();
        this._service = service;
    }

    protected buildEpicList(): Epic[] {
        return [
            this.buildDeactivateBonusRequestEpic(),
            this.buildDeactivateBonusSuccessEpic(),
            this.buildDeactivateBonusFailureEpic(),
            this.buildAddBonusCodeRequestEpic(),
            this.buildAddBonusCodeSuccessEpic(),
            this.buildAddBonusCodeFailureEpic(),
        ];
    }

    private buildDeactivateBonusRequestEpic(): Epic {
        return this.buildRequestEpic<MutationDeactivateBonusArgs, GqlMutationRequest, BonusCodeDetails>(
            bonusCodeActions.deactivateBonus,
            payload => this._service.deactivateBonus(payload)
        );
    }

    private buildDeactivateBonusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(bonusCodeActions.deactivateBonus.success)),
                mergeMap(({payload}) => {
                    const updatedItem: Partial<BonusCodeNormalized> = {bonus_status: BonusStatus.Inactive};
                    const entityUpdateItemAction = entityActions.updateItem({
                        type: EntityType.BonusCode,
                        id: payload?.requestPayload?.variables?.bonus_id,
                        updatedItem,
                    });

                    return of(entityUpdateItemAction, showMessageAction({message: localized.deactivateBonusSuccessMessage}));
                })
            );
    }

    private buildDeactivateBonusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(bonusCodeActions.deactivateBonus.failure)),
                map(() => showErrorAction({message: localized.deactivateBonusFailureMessage}))
            );
    }

    private buildAddBonusCodeRequestEpic(): Epic {
        return this.buildRequestEpic<BonusCodeInput, GqlMutationRequest, BonusCodeDetails>(bonusCodeActions.addBonusCode, payload =>
            this._service.addBonusCode(payload)
        );
    }

    private buildAddBonusCodeSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(bonusCodeActions.addBonusCode.success)),
                map(() => showMessageAction({message: localized.addBonusCodeSuccessMessage}))
            );
    }
    private buildAddBonusCodeFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(bonusCodeActions.addBonusCode.failure)),
                map(() => showErrorAction({message: localized.addBonusCodeFailureMessage}))
            );
    }
}
