import {useAutoMapper} from '@auto-mapper';
import {AggregatedFinanceSummaryViewModel, AggregatedFinanceSummaryViewModelKeys} from '@models/aggregated-finance-summary';
import {AggregatedFinanceSummary} from '@models/generated/graphql';
import {AggregatedFinanceSummaryFilterKeys, AggregatedFinanceSummaryQueryFields, EntityType} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';
import {getNonEmptyValueValidator, UseDetailsViewEntityProps, UseDetailsViewEntityResult, useViewInit} from '@redux/view';
import {updateKeyValueFilterArray} from '@utils';

import {Filter} from '../../common/types';

export function useAggregatedFinanceSummaryDetails({
    id,
    fields,
    viewType,
    displayName,
    cleanDelay = 0,
    realtimeMode,
    defaultFilters,
    validateFilter,
}: UseDetailsViewEntityProps<AggregatedFinanceSummaryFilterKeys, AggregatedFinanceSummaryViewModelKeys>): UseDetailsViewEntityResult<
    AggregatedFinanceSummaryViewModel,
    AggregatedFinanceSummaryViewModelKeys
> {
    const mapper = useAutoMapper();
    const queryFields: AggregatedFinanceSummaryQueryFields[] = mapper.map(
        fields,
        nameof<AggregatedFinanceSummaryViewModelKeys>(),
        nameof<AggregatedFinanceSummaryQueryFields>()
    );
    const defaultFilter: Filter<unknown, AggregatedFinanceSummaryFilterKeys>[] = updateKeyValueFilterArray(
        [{key: 'uid', value: id ?? null}],
        ...(defaultFilters ?? [])
    );
    const {
        items,
        searchFilter,
        handleFilterChange,
        viewEntity: {filter: filterString},
    } = useViewInit<AggregatedFinanceSummary, AggregatedFinanceSummaryFilterKeys, AggregatedFinanceSummaryQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.AggregatedFinanceSummary,
            fields: queryFields,
        },
        realtime: realtimeMode
            ? {entity: EntityType.AggregatedFinanceSummary, mode: realtimeMode, triggers: [{type: RealtimeMessageTrigger.Update}]}
            : null,
        defaultFilters: defaultFilter,
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: validateFilter ?? getNonEmptyValueValidator<AggregatedFinanceSummaryFilterKeys>('uid'),
        cleanDelay,
    });
    const aggregatedFinanceSummaryItem: AggregatedFinanceSummary = items?.length ? items[0] : null;
    const item: AggregatedFinanceSummaryViewModel = mapper?.map(
        aggregatedFinanceSummaryItem,
        AggregatedFinanceSummary,
        AggregatedFinanceSummaryViewModel
    );

    return {
        item,
        handleFilterChange,
        filterString,
        searchFilter,
    };
}
