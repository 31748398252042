import {useDispatch, useSelector} from 'react-redux';

import {notificationsActions} from './actions';
import {notificationsSelector} from './selectors';
import {Notification} from './types';

export function useNotifications() {
    const dispatch = useDispatch();
    const notifications = useSelector(notificationsSelector);

    function handleHide(id: string) {
        dispatch(notificationsActions.hide({id}));
    }

    function handleAction(id: string) {
        const notification = notifications[id];

        if (notification.action) {
            dispatch(notification.action);
        }
    }

    return {
        notifications: Object.values(notifications).map(n => n as Notification),
        handleHide,
        handleAction,
    };
}
