import React, {useEffect, useState} from 'react';

import {FilterProps} from '@components/filter/types';
import {MultiSelectWithExclude, MultiSelectWithExcludeModel} from '@components/select';
import {MultiSelectWithExcludeMode, MultiSelectWithExcludeProps} from '@components/select/MultiSelectWithExclude';
import {removeItemsFromArray} from '@utils/array';

export type MultiSelectWithExcludeFilterProps = Omit<
    MultiSelectWithExcludeProps,
    'value' | 'onValueChange' | 'showApplyButton' | 'onApply' | 'hasSearch' | 'filteredTotal' | 'inputChipColor'
> &
    Omit<FilterProps<string[]>, 'mode'>;

export function MultiSelectWithExcludeFilter({value: includedItems, onChange, ...props}: MultiSelectWithExcludeFilterProps) {
    const {total, options} = props;
    const [currentValue, setCurrentValue] = useState<MultiSelectWithExcludeModel>(getInitialValue());
    const [initialMode, setInitialMode] = useState<MultiSelectWithExcludeMode>('exclude');

    useEffect(() => {
        if (total > 0) {
            setCurrentValue(getInitialValue());
            setInitialMode(isIncludeMode(includedItems?.length) ? 'include' : 'exclude');
        }
    }, [total]);

    function getInitialValue(): MultiSelectWithExcludeModel {
        const result: MultiSelectWithExcludeModel = {include: [], exclude: []};
        if (includedItems?.length && total > 0) {
            if (isIncludeMode(includedItems?.length)) {
                result.include = includedItems;
            } else {
                result.exclude = options?.map(o => o.value?.toString())?.filter(o => !includedItems.includes(o));
            }
        }
        return result;
    }

    function handleValueChange(newModel: MultiSelectWithExcludeModel) {
        setCurrentValue(newModel);
    }

    function handleApply() {
        const allValues: string[] = options?.map(o => o.value as string) ?? [];
        let result: string[] = null;
        if (currentValue?.include?.length) {
            result = currentValue?.include;
        } else if (currentValue?.exclude?.length) {
            result = removeItemsFromArray(allValues, ...(currentValue?.exclude ?? []));
        }
        onChange(result);
    }

    /**
     * <p>We save and send to backend only included items. Initial mode is defined as included when there are fewer selected items than unselected items.</p>
     * <p>This will work incorrectly if user set `include` mode (`select all` is disabled) and then select more than half of the items. After reloading there will be `exclude` mode</p>
     */
    function isIncludeMode(includedItemsCount: number): boolean {
        return includedItemsCount <= total - includedItemsCount;
    }

    return (
        <MultiSelectWithExclude
            hasSearch={true}
            filteredTotal={props.total}
            value={currentValue}
            onValueChange={handleValueChange}
            showApplyButton
            onApply={handleApply}
            inputChipColor="primary"
            initialMode={initialMode}
            {...props}
        />
    );
}
