import {EntityType, NoteType, Workspace} from '@models/generated/graphql';
import {Timestamp, TimestampKeys} from '@models/shared';

export class EntityViewModel {
    id?: string;
    parent?: EntityViewModel;
    type?: EntityType;
}

export class AttachmentViewModel {
    extension?: string;
    filename?: string;
    id?: string;
    size?: number;
    type?: string;
    url?: string;
    posted_at?: Timestamp;
}

export class NoteViewModel {
    attachments?: AttachmentViewModel[];
    body: string;
    entity: EntityViewModel;
    id: string;
    note_type: NoteType;
    posted_at: Timestamp;
    posted_by_uid: string;
    users_tagged?: string[];
    workspace: Workspace;
    is_pinned: boolean;
}

export class NoteInputViewModel {
    files?: FileList;
    attachments?: AttachmentViewModel[];
    body: string;
    entity: EntityViewModel;
    is_pinned?: boolean;
    note_type: NoteType;
    posted_at: Timestamp;
    posted_by_uid: string;
    uid: string;
    users_tagged?: string[];
    workspace: Workspace;
    entityId: string;
}

type ViewModelKeys = keyof NoteViewModel;
const postedAtKey: ViewModelKeys = 'posted_at';
const entityKey: ViewModelKeys = 'entity';
type AttachmentViewModelKeys = keyof Omit<AttachmentViewModel, 'posted_at'>;
const attachmentsKey: ViewModelKeys = 'attachments';
export type EntityViewModelKeys = keyof EntityViewModel;
const parentKey: EntityViewModelKeys = 'parent';

const secondsKey: TimestampKeys = 'seconds';
export type NoteViewModelKeys =
    | keyof Omit<NoteViewModel, 'posted_at' | 'entity' | 'attachments'>
    | `${typeof postedAtKey}.${typeof secondsKey}`
    | `${typeof entityKey}.${keyof Omit<EntityViewModel, 'parent'>}`
    | `${typeof entityKey}.${typeof parentKey}.${keyof Omit<EntityViewModel, 'parent'>}`
    | `${typeof attachmentsKey}.${AttachmentViewModelKeys}`;
