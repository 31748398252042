import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {localizedNoteType} from '../../../features/app/intl/shared-resources/notetype';
import {localizedWorkspaces} from '../../../features/app/intl/shared-resources/workspace';
import {useWorkspaceFilterOptions} from '../../../features/block-note/hooks';
import {SelectOption} from '../../../features/module-shared/types';
import {EmptyPlaceholder, localizedEmptyPlaceholders} from '../select/Select';
import {SingleSelect} from '../select/SingleSelect';

import {NoteType, Workspace} from './types';

//TODO: [BO-2659] Remove dependency on features (introduce own model)
//TODO: [BO-2660] Remove dependency on services (introduce own models)
export const useClasses = makeStyles()((theme: CustomTheme) => ({
    commentSelect: {
        '& .MuiInputBase-root': {
            border: 'none',
            '& .MuiSelect-select': {
                paddingRight: '0 !important',
                '& .MuiChip-label': {
                    paddingRight: theme.spacing(3.5),
                },
                '& .MuiTypography-root:last-child': {
                    display: 'flex',
                    alignItems: 'center',
                    height: theme.spacing(3),
                    padding: theme.spacing(0, 3, 0, 1),
                    background: theme.custom.palette.status.dropdownDefault.light,
                    border: `1px solid ${theme.custom.palette.content.border}`,
                    borderRadius: theme.spacing(2),
                },
            },
        },
        '& .selectIcon': {
            right: 0,
            position: 'absolute',
            padding: theme.spacing(0, 1.5, 0, 1),
            fontSize: `${theme.typography.fontSize * 0.5}px !important`,
        },
    },
}));

type CommentSelectProps = {
    value?: string;
    onSubmit: (value: any) => void;
    onOpen?: () => void;
    onClose?: () => void;
    options?: SelectOption[];
    emptyPlaceholder?: EmptyPlaceholder;
    emptyValue?: MessageDescriptor | string;
    label?: string | MessageDescriptor;
    disabled?: boolean;
    chipType?: string;
    isSelectedValueChip?: boolean;
};

export const CommentSelect = ({
    value,
    chipType,
    options,
    emptyPlaceholder = EmptyPlaceholder.Any,
    isSelectedValueChip,
    onSubmit,
    onOpen,
    onClose,
}: CommentSelectProps) => {
    const {classes} = useClasses();

    const getEmptyValue = () => {
        return localizedEmptyPlaceholders[emptyPlaceholder] ?? localizedEmptyPlaceholders[EmptyPlaceholder.Dash];
    };

    const convertSelectedValueToString = (value: string | string[]) => {
        return Array.isArray(value) && value.length > 0 ? value[0] : value;
    };

    const handleOnSubmit = (value: string | string[]): void => {
        const val = convertSelectedValueToString(value);
        onSubmit(val);
    };

    return (
        <Box className={classes.commentSelect}>
            <SingleSelect
                value={value}
                chipType={chipType}
                options={options}
                emptyValue={getEmptyValue()}
                emptyPlaceholder={emptyPlaceholder}
                isSelectedValueChip={isSelectedValueChip}
                onSubmit={handleOnSubmit}
                onOpen={onOpen}
                onClose={onClose}
            />
        </Box>
    );
};

export const CommentSelectWorkspace = ({value, onSubmit, onOpen, onClose}: CommentSelectProps) => {
    const options = useWorkspaceFilterOptions();
    const commentProps = {
        value,
        onSubmit,
        onOpen,
        onClose,
        chipType: nameof<Workspace>(),
        options: [{label: localizedWorkspaces.Global, value: Workspace.Global}, ...options],
        emptyPlaceholder: EmptyPlaceholder.Flag,
        isSelectedValueChip: true,
    };

    return <CommentSelect {...commentProps} />;
};

export const CommentSelectNoteType = ({value, onSubmit, onOpen, onClose}: CommentSelectProps) => {
    const commentProps = {
        value,
        onSubmit,
        onOpen,
        onClose,
        chipType: nameof<NoteType>(),
        options: Object.values(NoteType).map(s => ({label: localizedNoteType[s], value: s} as SelectOption)),
        isSelectedValueChip: true,
        emptyPlaceholder: EmptyPlaceholder.All,
    };

    return <CommentSelect {...commentProps} />;
};
