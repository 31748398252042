import React, {useContext} from 'react';
import {defineMessages, IntlContext} from 'react-intl';
import {LabelDisplayedRowsArgs} from '@mui/material';
import {GridOverlay} from '@mui/x-data-grid';

import LocalizedText from '@components/i18n/LocalizedText';

//TODO: [BO-2704] Move files to src/common/components/data-grid (renamed grid folder)
const localized = defineMessages({
    noRowsLabel: {
        id: 'noRowsLabel',
        defaultMessage: 'No rows',
    },
    columnMenuLabel: {
        id: 'columnMenuLabel',
        defaultMessage: 'Menu',
    },
    columnMenuFilter: {
        id: 'columnMenuFilter',
        defaultMessage: 'Filter',
    },
    columnMenuHideColumn: {
        id: 'columnMenuHideColumn',
        defaultMessage: 'Hide',
    },
    columnMenuUnsort: {
        id: 'columnMenuUnsort',
        defaultMessage: 'Unsort',
    },
    columnMenuSortAsc: {
        id: 'columnMenuSortAsc',
        defaultMessage: 'Sort by ASC',
    },
    columnMenuSortDesc: {
        id: 'columnMenuSortDesc',
        defaultMessage: 'Sort by DESC',
    },
    displayedRowsLabel: {
        id: 'displayedRowsLabel',
        defaultMessage: 'of',
    },
});

const LocalizedNoRowsOverlay = () => {
    return (
        <GridOverlay>
            <LocalizedText label={localized.noRowsLabel} />
        </GridOverlay>
    );
};

export const useGridLocalization = () => {
    const {formatMessage} = useContext(IntlContext);

    const localeText = {
        columnMenuLabel: formatMessage(localized.columnMenuLabel),
        columnMenuFilter: formatMessage(localized.columnMenuFilter),
        columnMenuHideColumn: formatMessage(localized.columnMenuHideColumn),
        columnMenuUnsort: formatMessage(localized.columnMenuUnsort),
        columnMenuSortAsc: formatMessage(localized.columnMenuSortAsc),
        columnMenuSortDesc: formatMessage(localized.columnMenuSortDesc),
    };

    const getLabelDisplayedRows = ({from, to, count}: LabelDisplayedRowsArgs) => {
        return `${from}-${to}${count !== -1 ? ` ${formatMessage(localized.displayedRowsLabel)} ${count}` : ''}`;
    };

    return {
        localeText,
        components: {noRowsOverlay: LocalizedNoRowsOverlay},
        getLabelDisplayedRows,
    };
};
