import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Box} from '@mui/material';
import equal from 'fast-deep-equal/es6';

import {MessageOrientation} from '@components/alerts/Message';
import {EditableText} from '@components/editable/EditableText';
import {FormError, numberRegex, RuleType, useValidationFormatter} from '@components/input';
import {ReferralSettings} from '@models/generated/graphql';
import {ModuleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {useMessageFormatter} from '../../app/intl/hooks';
import {LackOfPermissionIndicator, useMultiplePermissions, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {useReduxForm, useReduxFormRequestMode} from '../../shared/form/hooks';
import {referralSettingsActions} from '../actions';

import {localizedReferralSettingsExpirationPeriod} from './ReferralSettingsExpirationPeriod.localized';
import {useReferralSettingsExpirationPeriodClasses} from './ReferralSettingsExpirationPeriod.style';

type ReferralSettingsExpirationPeriodProps = ReferralSettings & {
    disabled?: boolean;
};

const editPermission = {
    moduleName: ModuleName.MarketingFunction,
    permissions: [PermissionEnum.Update],
};

const minExpirationPeriod = 0;
const maxExpirationPeriod = 1000;

const ReferralSettingsExpirationPeriodInternal = (props: ReferralSettingsExpirationPeriodProps) => {
    const isEditable = useMultiplePermissions({allowedPermissions: [editPermission]});
    const {classes} = useReferralSettingsExpirationPeriodClasses();
    const validationMessageFormatter = useValidationFormatter();
    const formatter = useMessageFormatter();
    const {formatMessage} = useIntl();

    const formatDays = (value: unknown) => {
        return formatMessage(localizedReferralSettingsExpirationPeriod.daysFormat, {value: value as string});
    };

    const [fieldValueModel, setFieldValueModel] = useState<ReferralSettings>(props);

    const {control, state, submit, cancel, handleSubmit} = useReduxForm<ReferralSettings, ReferralSettings>({
        initialModel: fieldValueModel,
        asyncAction: referralSettingsActions.editReferralSettingsExpirationPeriod,
        map: m => ({
            revenue_share: props.revenue_share,
            expiration_period: Number(m?.expiration_period),
        }),
    });

    const {requestMode, resetRequestMode} = useReduxFormRequestMode<ReferralSettings>(
        referralSettingsActions.editReferralSettingsExpirationPeriod
    );

    useEffect(() => {
        if (!equal(fieldValueModel, props)) {
            setFieldValueModel(props);
        }
    }, [props]);

    return (
        <Box className={classes.referralSettingsExpirationPeriodContainer}>
            <form onSubmit={handleSubmit(submit)}>
                <Controller
                    render={({field, fieldState}) => (
                        <Box>
                            <EditableText
                                type="number"
                                mode={requestMode}
                                value={field.value}
                                onChange={e => {
                                    field.onChange(e);
                                    resetRequestMode();
                                }}
                                formatter={formatDays}
                                onSave={() => handleSubmit(submit)}
                                onCancel={cancel}
                                placeholder={formatter(localizedReferralSettingsExpirationPeriod.numberOfDays)}
                                disabled={!isEditable || props.disabled}
                                errorMessage={state.errorMessage ? formatMessage(state.errorMessage) : null}
                                errorMessageOrientation={MessageOrientation.Floating}
                            ></EditableText>
                            {fieldState.invalid ? <FormError>{fieldState.error.message}</FormError> : <></>}
                        </Box>
                    )}
                    control={control}
                    name="expiration_period"
                    defaultValue={props.expiration_period}
                    rules={{
                        required: validationMessageFormatter(
                            RuleType.Required,
                            localizedReferralSettingsExpirationPeriod.periodForRevenueShare
                        ),
                        pattern: {
                            value: numberRegex,
                            message: validationMessageFormatter(
                                RuleType.Number,
                                localizedReferralSettingsExpirationPeriod.periodForRevenueShare
                            ),
                        },
                        min: {
                            value: minExpirationPeriod,
                            message: validationMessageFormatter(
                                RuleType.Min,
                                localizedReferralSettingsExpirationPeriod.periodForRevenueShare,
                                minExpirationPeriod
                            ),
                        },
                        max: {
                            value: maxExpirationPeriod,
                            message: validationMessageFormatter(
                                RuleType.Max,
                                localizedReferralSettingsExpirationPeriod.periodForRevenueShare,
                                maxExpirationPeriod
                            ),
                        },
                    }}
                ></Controller>
            </form>
        </Box>
    );
};

export const ReferralSettingsExpirationPeriod = withMultiplePermission(ReferralSettingsExpirationPeriodInternal, {
    allowedPermissions: [editPermission],
    indicator: LackOfPermissionIndicator.Disabled,
});
