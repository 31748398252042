import type {Rectangle, Theme} from '@glideapps/glide-data-grid';

import type {CustomTheme} from '@style';

import {CanvasRenderingContext2DExtended} from '../CanvasRenderingContext2DExtended';
import {type GlideGridColumn, type Point, type SortField, rowHeight} from '../types';

type RenderHeaderCellProps = {
    ctx: CanvasRenderingContext2D;
    rect: Rectangle;
    column: GlideGridColumn;
    glideTheme: Theme;
    muiTheme: CustomTheme;
    sortModel: SortField[];
};

export function renderHeaderCell({ctx, rect, column, glideTheme, muiTheme, sortModel}: RenderHeaderCellProps) {
    const {fontFamily, fontWeight, fontSize} = muiTheme.typography.h4;
    ctx.font = `${fontWeight} ${fontSize} ${fontFamily}`;
    ctx.fillStyle = muiTheme.palette.secondary.main;

    const drawArea: Rectangle = {
        x: rect.x + glideTheme.cellHorizontalPadding,
        y: rect.y + glideTheme.cellVerticalPadding,
        width: rect.width - 2 * glideTheme.cellHorizontalPadding,
        height: rect.height - 2 * glideTheme.cellVerticalPadding,
    };

    if (column.sortable) {
        const sortIconWidth = 8;
        renderSortIcon({
            ctx,
            drawArea,
            sortIconWidth,
            sortModel,
            activeColor: muiTheme.palette.primary.main,
            columnId: column.id,
        });
        renderHeaderTitle({ctx, drawArea, spacing: sortIconWidth, columnName: column.columnName});
    } else {
        renderHeaderTitle({ctx, drawArea, columnName: column.columnName});
    }
}

type RenderTitleProps = {
    ctx: CanvasRenderingContext2D;
    drawArea: Rectangle;
    spacing?: number;
    columnName: string;
};

function renderHeaderTitle({ctx, drawArea, spacing = 0, columnName}: RenderTitleProps) {
    const columnNameMaxWidth = drawArea.width - spacing;
    const textCtx = new CanvasRenderingContext2DExtended(ctx);
    textCtx.fillTextWithEllipsis(columnName, columnNameMaxWidth, drawArea.x, drawArea.y + rowHeight / 2);
}

type RenderSortIconProps = {
    ctx: CanvasRenderingContext2D;
    drawArea: Rectangle;
    sortIconWidth: number;
    activeColor: string;
    sortModel: SortField[];
    columnId: string;
};

function renderSortIcon({ctx, drawArea, sortIconWidth, sortModel, activeColor, columnId}: RenderSortIconProps) {
    const sortIconHeight = sortIconWidth / 2;
    let sortIconPoints: Point[] = [
        {x: drawArea.x + drawArea.width, y: drawArea.y + drawArea.height / 2},
        {x: drawArea.x + drawArea.width - sortIconWidth, y: drawArea.y + drawArea.height / 2},
        {x: drawArea.x + drawArea.width - sortIconWidth / 2, y: drawArea.y + drawArea.height / 2 + sortIconHeight},
    ];
    if (sortModel[0]?.field === columnId) {
        ctx.fillStyle = activeColor;
        if (sortModel[0].sort === 'asc') {
            sortIconPoints = [
                {x: drawArea.x + drawArea.width, y: drawArea.y + drawArea.height / 2 + sortIconHeight},
                {x: drawArea.x + drawArea.width - sortIconWidth, y: drawArea.y + drawArea.height / 2 + sortIconHeight},
                {x: drawArea.x + drawArea.width - sortIconWidth / 2, y: drawArea.y + drawArea.height / 2},
            ];
        }
    }

    ctx.beginPath();
    ctx.moveTo(sortIconPoints[0].x, sortIconPoints[0].y);
    ctx.lineTo(sortIconPoints[1].x, sortIconPoints[1].y);
    ctx.lineTo(sortIconPoints[2].x, sortIconPoints[2].y);
    ctx.closePath();
    ctx.fill();
}
