import {gql} from '@apollo/client';
import {DocumentNode} from 'graphql';

import {GqlEntityArgs} from './types';

//TODO: [BO-2938] Cleanup file after Bonus module is moved to view feature
type PlayerBonusGqlEntityArgs = GqlEntityArgs & {
    triggerMutation: DocumentNode;
};

const fragmentAll = gql`
    fragment PlayerBonusFragment on PlayerBonus {
        bonus_engine_code
        bonus_id
        bonus_name
        bonus_status
        created_at {
            seconds
        }
        desc
        expire_at {
            seconds
        }
        marketing_code
        realized_amount
        total_amount
        type
        uid
    }
`;

export const playerBonus: PlayerBonusGqlEntityArgs = {
    fragmentAll,
    addMutation: gql`
        mutation TriggerPlayerBonuses($bulkData: BulkMutationInput!) {
            triggerPlayerBonuses(bulkData: $bulkData) {
                id
            }
        }
    `,
    triggerMutation: gql`
        ${fragmentAll}
        mutation TriggerPlayerBonuses($bonuses: [PlayerBonusTriggerInput!]!) {
            triggerPlayerBonuses(bonuses: $bonuses) {
                player_bonus {
                    ...PlayerBonusFragment
                }
                error
            }
        }
    `,
};
