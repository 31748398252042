import React from 'react';
import {defineMessages} from 'react-intl';

import {EmptyPlaceholder} from '@components/select';
import {TransactionType} from '@models/generated/graphql';
import {TransactionFilterType} from '@models/transaction/types';
import {TransactionFilterKeys} from '@redux/entity';
import {removeItemsFromArray} from '@utils/array';

import {Filter} from 'src/common/types';
import {localizedTransactionFilterType} from 'src/features/app/intl/shared-resources/transactionType';
import FilterMultiSelect from 'src/features/shared/filter/components/FilterMultiSelect';
import {PageModuleChipSelectFilter} from 'src/features/shared/filter/types';

import {TransactionFilterProps} from './TransactionFilters';

const localized = defineMessages({
    filterType: {
        id: 'TransactionFilterType_filterType',
        defaultMessage: 'Type',
    },
});

const availableTransactionTypes: TransactionType[] = [
    TransactionType.Bonus,
    TransactionType.BuyIn,
    TransactionType.BuyOut,
    TransactionType.Credit,
    TransactionType.Debit,
    TransactionType.Deposit,
    TransactionType.RevenueShare,
    TransactionType.Withdrawal,
    TransactionType.MttDebit,
    TransactionType.MttCredit,
    TransactionType.PokerAddonCredit,
    TransactionType.PokerAddonDebit,
    TransactionType.PokerFlipsPayout,
    TransactionType.PokerFlipsWager,
    TransactionType.CasinoWager,
    TransactionType.CasinoPayout,
    TransactionType.CasinoFreeSpinWager,
    TransactionType.CasinoFreeSpinPayout,
    TransactionType.CasinoWagerRefund,
    TransactionType.CasinoPayoutRefund,
    TransactionType.CasinoFreeSpinWagerRefund,
    TransactionType.CasinoFreeSpinPayoutRefund,
    TransactionType.P2PTransferCredit,
    TransactionType.P2PTransferDebit,
    TransactionType.Chargeback,
    TransactionType.Refund,
    TransactionType.Reverse,
    TransactionType.LiveEventBuyIn,
    TransactionType.SportsBuyin,
    TransactionType.SportsBuyout,
    TransactionType.SportsBetSettlement,
    TransactionType.Payin,
    TransactionType.Payout,
    TransactionType.PayoutReturn,
];

export const availableTransactionFilterOptions: TransactionFilterType[] = [...availableTransactionTypes, 'FEES'];

export const availableTransactionFilterValues: TransactionFilterType[] = [
    ...availableTransactionTypes,
    TransactionType.Fee,
    TransactionType.FeeReverse,
];

function TransactionFilterType({filter, onFilterChange}: TransactionFilterProps) {
    const filterKey: TransactionFilterKeys = 'transactionTypes';
    const feesFilterKey: TransactionFilterType = 'FEES';

    function handleFilterChange(newFilter: Filter[]) {
        let result = newFilter;
        let newValue: TransactionFilterType[] = newFilter?.find(f => f.key === filterKey)?.value;
        if (newValue && newValue?.includes(feesFilterKey)) {
            newValue = [...removeItemsFromArray(newValue, feesFilterKey), TransactionType.Fee, TransactionType.FeeReverse];
            result = [...result.filter(f => f.key !== filterKey), {key: filterKey, value: newValue}];
        }
        onFilterChange(result);
    }

    const feesValues: TransactionFilterType[] = [TransactionType.Fee, TransactionType.FeeReverse];
    function getFilterString(initFilterString: string) {
        const searchParams = new URLSearchParams(initFilterString ?? '');
        let filterValue: TransactionFilterType[];
        try {
            filterValue = JSON.parse(searchParams.get(filterKey));
        } catch (e) {
            filterValue = [];
        }

        if (filterValue?.length && (filterValue?.includes(TransactionType.Fee) || filterValue?.includes(TransactionType.FeeReverse))) {
            filterValue = [...filterValue.filter(v => !feesValues.includes(v)), 'FEES'];
            searchParams.set(filterKey, JSON.stringify(filterValue));
        }
        return searchParams.toString();
    }

    return (
        <FilterMultiSelect
            onFilterChange={handleFilterChange}
            filter={
                {
                    key: filterKey,
                    label: localized.filterType,
                    chipType: nameof<TransactionFilterType>(),
                    options: availableTransactionFilterOptions.map(t => ({
                        label: localizedTransactionFilterType[t],
                        value: t,
                    })),
                    emptyPlaceholder: EmptyPlaceholder.Any,
                    horizontalPosition: 'left',
                } as PageModuleChipSelectFilter
            }
            filterString={getFilterString(filter)}
        />
    );
}
export default TransactionFilterType;
