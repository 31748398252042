import React from 'react';

import LocalizedText from '@components/i18n/LocalizedText';

import {LinkProps, withLink, withReduxLink} from './LinkHOC';

function TextLinkInternal({title}: LinkProps) {
    return <LocalizedText label={title}></LocalizedText>;
}

export const LinkText = withLink(TextLinkInternal, true);

export const ReduxLinkText = withReduxLink(LinkText);
