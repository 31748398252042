import produce from 'immer';
import {combineLatest, concat, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {BoRole, Policy} from '@redux/entity';
import {AuthApiService} from '@services/deprecated';
import {IGridService} from '@services/deprecated';
import {RestHttpService} from '@services/deprecated';

import {ItemsPage, SearchFilter} from 'src/common/types';
import {filterJurisdictionModules} from '../../features/app/config/hooks';
import {JurisdictionFeature} from '../../features/app/config/types';
import {Module} from '../../features/modules/types';

import {AvailableModules, RoleGridItem} from './types';

export class RolesService implements IGridService<BoRole, RoleGridItem> {
    private readonly rolesHttpService: AuthApiService;
    private readonly policiesHttpService: AuthApiService;
    private readonly modulesHttpService: RestHttpService;
    private readonly submodulesHttpService: RestHttpService;

    constructor(tracingService: ITracingService, userManager: UserManagerExtended) {
        this.rolesHttpService = new AuthApiService('roles', tracingService, userManager, 'policies', 'policyIds');
        this.policiesHttpService = new AuthApiService('policies', tracingService, userManager);
        this.modulesHttpService = new AuthApiService('modules', tracingService, userManager);
        this.submodulesHttpService = new AuthApiService('submodules', tracingService, userManager);
    }

    getSearchFilter(filter?: SearchFilter): SearchFilter {
        return produce(filter ?? ({} as SearchFilter), f => {
            f.extend = ['userCount', 'module'];
        });
    }

    getItem(id: string): Observable<BoRole> {
        return this.rolesHttpService.getItem(id, this.getSearchFilter());
    }

    getItems(filter?: SearchFilter): Observable<RoleGridItem[]> {
        return this.rolesHttpService.getItems(filter);
    }

    getItemsPage(filter?: SearchFilter): Observable<ItemsPage<RoleGridItem>> {
        return this.rolesHttpService.getItemsPage(this.getSearchFilter(filter));
    }

    prepareItem(item: BoRole): BoRole {
        const newPolicies = item.policies
            .filter(policy => policy.permissions.length !== 0)
            .reduce((addPolicies, policy) => {
                let result = addPolicies;
                if (policy.module?.id !== undefined) {
                    result = [...result, policy];
                } else {
                    const moduleId = policy.submodule?.parent?.id;
                    const modulePolicy = item.policies.find(p => p.module?.id === moduleId);

                    const hasSameModulePermissions =
                        modulePolicy.permissions.length === policy.permissions.length &&
                        modulePolicy.permissions.map(per => per.action).every(el => policy.permissions.map(per => per.action).includes(el));
                    result = hasSameModulePermissions ? result : [...result, policy];
                }
                return result;
            }, [] as Policy[]);

        return {
            ...item,
            policies: newPolicies,
        };
    }

    addPolicy(policy: Policy): Observable<string> {
        const policy$ =
            policy.submodule?.id !== undefined
                ? this.submodulesHttpService.post(`/${policy.submodule.id}/policies`, policy)
                : this.modulesHttpService.post(`/${policy.module.id}/policies`, policy);

        return policy$.pipe(map(p => p.response.id));
    }

    addItem(item: BoRole): Observable<string> {
        const newRole = this.prepareItem(item);
        const role$ = this.rolesHttpService.addItem(newRole);

        const policies$ = combineLatest([...newRole.policies.map(policy => this.addPolicy(policy))]);

        return combineLatest([role$, policies$]).pipe(
            mergeMap(([roleId, policyIds]) =>
                concat(
                    this.rolesHttpService.assignItems(
                        roleId,
                        policyIds.map(policyId => ({id: policyId} as Policy))
                    )
                ).pipe(map(() => roleId))
            )
        );
    }

    editItem(item: BoRole): Observable<string> {
        const updatedRole = this.prepareItem(item);
        const role$ = this.getItem(updatedRole.id);

        return role$.pipe(
            mergeMap(oldRole => {
                const oldRolePolicyIds = oldRole.policies.map(p => p.id);
                const [assignedPolicies, editPolicies] = updatedRole.policies.reduce(
                    ([newPolicies, edit], policy) => {
                        let result = [newPolicies, edit];

                        if (!oldRolePolicyIds.includes(policy.id)) {
                            result = [[...newPolicies, policy], edit];
                        } else {
                            const oldRolePermissions = oldRole.policies.find(p => p.id === policy.id).permissions;
                            const sharedActions = oldRolePermissions
                                .map(p => p.action)
                                .filter(x => policy.permissions.map(per => per.action).includes(x));
                            const isEdited =
                                sharedActions.length !== oldRolePermissions.length || sharedActions.length !== policy.permissions.length;

                            result = isEdited ? [newPolicies, [...edit, policy]] : result;
                        }

                        return result;
                    },
                    [[] as Policy[], [] as Policy[]]
                );
                const unAssignedPolicies = oldRole.policies.filter(p => !updatedRole.policies.map(i => i.id).includes(p.id));

                const newPolicies$ =
                    assignedPolicies.length === 0
                        ? of(undefined)
                        : combineLatest([...assignedPolicies.map(policy => this.addPolicy(policy))]).pipe(
                              mergeMap(policiesIds =>
                                  this.rolesHttpService.assignItems(
                                      updatedRole.id,
                                      policiesIds.map(p => ({id: p} as Policy))
                                  )
                              )
                          );

                const deletePolicies$ =
                    unAssignedPolicies.length === 0
                        ? of(undefined)
                        : this.rolesHttpService
                              .unAssignItems(updatedRole.id, unAssignedPolicies)
                              .pipe(
                                  mergeMap(() => combineLatest([...unAssignedPolicies.map(i => this.policiesHttpService.deleteItem(i.id))]))
                              );

                const editPolicies$ =
                    editPolicies.length === 0
                        ? of(undefined)
                        : this.rolesHttpService.unAssignItems(updatedRole.id, editPolicies).pipe(
                              mergeMap(() => {
                                  const deleteOldEditPolicies$ = this.rolesHttpService
                                      .unAssignItems(updatedRole.id, editPolicies)
                                      .pipe(
                                          mergeMap(() =>
                                              combineLatest([...editPolicies.map(i => this.policiesHttpService.deleteItem(i.id))])
                                          )
                                      );
                                  const addNewEditPolicies$ = combineLatest([...editPolicies.map(policy => this.addPolicy(policy))]).pipe(
                                      mergeMap(policiesIds =>
                                          this.rolesHttpService.assignItems(
                                              updatedRole.id,
                                              policiesIds.map(p => ({id: p} as Policy))
                                          )
                                      )
                                  );

                                  return combineLatest([deleteOldEditPolicies$, addNewEditPolicies$]);
                              })
                          );

                return combineLatest([newPolicies$, deletePolicies$, editPolicies$, this.rolesHttpService.editItem(updatedRole)]).pipe(
                    map(() => updatedRole.id)
                );
            })
        );
    }

    deleteItem(id: string): Observable<string> {
        const role$ = this.getItem(id);
        return role$.pipe(
            mergeMap(oldRole => {
                const unAssignItems$ = this.rolesHttpService.unAssignItems(id, oldRole.policies);

                return unAssignItems$.pipe(() => {
                    return combineLatest([
                        ...oldRole.policies.map(p => this.policiesHttpService.deleteItem(p.id)),
                        this.rolesHttpService.deleteItem(id),
                    ]).pipe(map(() => id));
                });
            })
        );
    }

    loadModules(hidden: JurisdictionFeature[]): Observable<AvailableModules> {
        const filter = produce({} as SearchFilter, f => {
            f.extend = ['submodules', 'policies'];
        });
        return this.modulesHttpService
            .getItemsPage<Module>(filter)
            .pipe(
                mergeMap(r =>
                    this.modulesHttpService
                        .getItemsPage<Module>({paging: {page: 1, pageSize: r.total}, ...filter})
                        .pipe(map(page => this.getVisibleModules(page, hidden)))
                )
            );
    }

    private getVisibleModules(modulesPage: ItemsPage<Module>, hidden: JurisdictionFeature[]): AvailableModules {
        const modules = filterJurisdictionModules(hidden, modulesPage?.items).map(
            m =>
                ({
                    ...m,
                    submodules: filterJurisdictionModules(
                        hidden,
                        m.submodules.map(s => ({...s, parent: m} as Module))
                    ),
                } as Module)
        );
        const submodules = modules?.reduce(
            (result, module) => (module?.submodules ? [...result, ...module?.submodules] : result),
            []
        ) as Module[];

        return {
            modules: modules,
            submodules: submodules,
        };
    }
}
