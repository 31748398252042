import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {CurrencyCell} from '@components/data-grid/mui';
import {KeyValueCell, KeyValueColumn} from '@components/key-value-table';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import StyledAccordion from '@components/StyledAccordion';
import {GgrViewModel} from '@models/aggregated-finance-summary';

import {GGRTotalInfo} from './GGRTotalInfo';

const localized = defineMessages({
    title: {
        id: 'GGRModal_title',
        defaultMessage: 'GGR Details',
    },
    closeButtonLabel: {
        id: 'GGRModal_closeButtonLabel',
        defaultMessage: 'Close',
    },

    mttHeader: {
        id: 'GGRModal_mttHeader',
        defaultMessage: 'MTT',
    },
    pokerHeader: {
        id: 'GGRModal_pokerHeader',
        defaultMessage: 'Poker',
    },
    casinoHeader: {
        id: 'GGRModal_casinoHeader',
        defaultMessage: 'Casino',
    },

    mttServiceFee: {
        id: 'GGRModal_mttServiceFee',
        defaultMessage: 'Service Fee',
    },
    pokerRake: {
        id: 'GGRModal_pokerRake',
        defaultMessage: 'Rake',
    },
    pokerInsurance: {
        id: 'GGRModal_pokerInsurance',
        defaultMessage: 'Insurance',
    },
    pokerFeaturesPurchase: {
        id: 'GGRModal_pokerFeaturesPurchase',
        defaultMessage: 'Features Purchase',
    },
    pokerGuessHand: {
        id: 'GGRModal_pokerGuessHand',
        defaultMessage: 'Guess Hands',
    },
    casinoPokerFlips: {
        id: 'GGRModal_casinoPokerFlips',
        defaultMessage: 'Pokerflips',
    },
    casinoConnector: {
        id: 'GGRModal_casinoConnector',
        defaultMessage: 'Connector',
    },
});

const useStyles = makeStyles()(theme => ({
    accordion: {
        borderRadius: '0 !important',
        marginBottom: theme.spacing(0.25),
        '&.Mui-expanded': {
            boxShadow: 'none',
        },
        '& .MuiAccordionSummary-root': {
            padding: 0,
        },
    },
    field: {
        marginTop: theme.spacing(1.625),
        marginBottom: theme.spacing(1.625),
        '&:first-child': {
            marginTop: theme.spacing(1.5),
        },
        '&:last-child': {
            marginBottom: theme.spacing(3.375),
        },
    },
    fieldValue: {
        marginRight: theme.spacing(4.5),
    },
}));

type GGRModalProps = {
    handleClose: () => void;
    ggr: GgrViewModel;
    currency: string;
    dateTimeString: string;
};

export function GGRModal({handleClose, ggr, currency, dateTimeString}: GGRModalProps) {
    const {classes} = useStyles();
    const {formatMessage} = useIntl();

    const pokerTotal = ggr?.poker_rake + ggr?.poker_insurance + ggr?.poker_features_purchase + ggr?.poker_guess_hand;
    const casinoTotal = ggr?.casino_poker_flips + ggr?.casino_connector;

    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent>
                <GGRTotalInfo ggr={ggr} currency={currency} dateTimeString={dateTimeString} />
                <StyledAccordion
                    className={classes.accordion}
                    header={
                        <KeyValueColumn isGrid={false}>
                            <KeyValueCell title={formatMessage(localized.mttHeader)} titleVariant={'h6'} isGrid={false}>
                                <CurrencyCell value={ggr?.mtt_service_fee ?? null} currency={currency} />
                            </KeyValueCell>
                        </KeyValueColumn>
                    }
                    componentId={'GGRAccordion_MTT'}
                    isCollapsed
                    unmountOnCollapse
                    children={
                        <>
                            <KeyValueColumn isGrid={false} className={classes.field}>
                                <KeyValueCell
                                    isGrid={false}
                                    title={formatMessage(localized.mttServiceFee)}
                                    valueClassName={classes.fieldValue}
                                    titleVariant={'body1'}
                                >
                                    <CurrencyCell value={ggr?.mtt_service_fee} currency={currency} />
                                </KeyValueCell>
                            </KeyValueColumn>
                        </>
                    }
                />
                <StyledAccordion
                    className={classes.accordion}
                    header={
                        <KeyValueColumn isGrid={false}>
                            <KeyValueCell title={formatMessage(localized.pokerHeader)} titleVariant={'h6'} isGrid={false}>
                                <CurrencyCell value={isNaN(pokerTotal) ? null : pokerTotal} currency={currency} />
                            </KeyValueCell>
                        </KeyValueColumn>
                    }
                    componentId={'GGRAccordion_Poker'}
                    isCollapsed
                    unmountOnCollapse
                    children={
                        <>
                            <KeyValueColumn isGrid={false} className={classes.field}>
                                <KeyValueCell
                                    isGrid={false}
                                    title={formatMessage(localized.pokerRake)}
                                    valueClassName={classes.fieldValue}
                                    titleVariant={'body1'}
                                >
                                    <CurrencyCell value={ggr.poker_rake} currency={currency} />
                                </KeyValueCell>
                            </KeyValueColumn>
                            <KeyValueColumn isGrid={false} className={classes.field}>
                                <KeyValueCell
                                    isGrid={false}
                                    title={formatMessage(localized.pokerInsurance)}
                                    valueClassName={classes.fieldValue}
                                    titleVariant={'body1'}
                                >
                                    <CurrencyCell value={ggr.poker_insurance} currency={currency} />
                                </KeyValueCell>
                            </KeyValueColumn>
                            <KeyValueColumn isGrid={false} className={classes.field}>
                                <KeyValueCell
                                    isGrid={false}
                                    title={formatMessage(localized.pokerFeaturesPurchase)}
                                    valueClassName={classes.fieldValue}
                                    titleVariant={'body1'}
                                >
                                    <CurrencyCell value={ggr.poker_features_purchase} currency={currency} />
                                </KeyValueCell>
                            </KeyValueColumn>
                            <KeyValueColumn isGrid={false} className={classes.field}>
                                <KeyValueCell
                                    isGrid={false}
                                    title={formatMessage(localized.pokerGuessHand)}
                                    valueClassName={classes.fieldValue}
                                    titleVariant={'body1'}
                                >
                                    <CurrencyCell value={ggr.poker_guess_hand} currency={currency} />
                                </KeyValueCell>
                            </KeyValueColumn>
                        </>
                    }
                />
                <StyledAccordion
                    className={classes.accordion}
                    header={
                        <KeyValueColumn isGrid={false}>
                            <KeyValueCell title={formatMessage(localized.casinoHeader)} titleVariant={'h6'} isGrid={false}>
                                <CurrencyCell value={isNaN(casinoTotal) ? null : casinoTotal} currency={currency} />
                            </KeyValueCell>
                        </KeyValueColumn>
                    }
                    componentId={'GGRAccordion_Casino'}
                    isCollapsed
                    unmountOnCollapse
                    children={
                        <>
                            <KeyValueColumn isGrid={false} className={classes.field}>
                                <KeyValueCell
                                    isGrid={false}
                                    title={formatMessage(localized.casinoPokerFlips)}
                                    valueClassName={classes.fieldValue}
                                    titleVariant={'body1'}
                                >
                                    <CurrencyCell value={ggr.casino_poker_flips} currency={currency} />
                                </KeyValueCell>
                            </KeyValueColumn>
                            <KeyValueColumn isGrid={false} className={classes.field}>
                                <KeyValueCell
                                    isGrid={false}
                                    title={formatMessage(localized.casinoConnector)}
                                    valueClassName={classes.fieldValue}
                                    titleVariant={'body1'}
                                >
                                    <CurrencyCell value={ggr.casino_connector} currency={currency} />
                                </KeyValueCell>
                            </KeyValueColumn>
                        </>
                    }
                />
            </BulkModalContent>
            <ModalFooter>
                <Button key={'GGRModalCloseButton'} label={localized.closeButtonLabel} onClick={handleClose} />
            </ModalFooter>
        </>
    );
}
