import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

import BackButton from '../../module-shared/components/BackButton';

const localized = defineMessages({
    bulkApplyStepApplyButton: {
        id: 'bulkApplyStepApplyButton',
        defaultMessage: 'Apply',
    },
});

const useClasses = makeStyles()((theme: CustomTheme) => ({
    bulkApplyStepHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(8.5),
        borderRadius: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: theme.custom.palette.content.bulkActionHeaderBackground,
    },
    bulkApplyStepHeaderBackButton: {
        marginRight: theme.spacing(2),
    },
    bulkApplyStepHeaderLabel: {},
    bulkApplyStepHeaderApplyButton: {
        marginLeft: 'auto',
        height: theme.spacing(4.5),
        borderRadius: theme.spacing(1),
    },
}));

type BulkApplyStepHeaderProps = {
    back: () => void;
    apply: () => void;
    label: MessageDescriptor;
};

export const BulkApplyStepModalHeader = ({back, apply, label}: BulkApplyStepHeaderProps) => {
    const {classes} = useClasses();

    return (
        <Box className={classes.bulkApplyStepHeaderContainer}>
            <Box className={classes.bulkApplyStepHeaderBackButton}>
                <BackButton dispatchOnClick={false} onClick={back} />
            </Box>
            <Box className={classes.bulkApplyStepHeaderLabel}>
                <Typography variant="h6" color="textPrimary">
                    <LocalizedText label={label} />
                </Typography>
            </Box>
            <Button
                label={localized.bulkApplyStepApplyButton}
                color="primary"
                onClick={apply}
                className={classes.bulkApplyStepHeaderApplyButton}
            />
        </Box>
    );
};
