import {UserProfile} from '@models/generated/graphql';

class FieldsConfigurationManager {
    public getFields(country: string): string[] {
        const regionRelatedFieldsMapping: Record<string, string[]> = {
            MX: [
                nameof.full<UserProfile>(u => u.region_player_info.country_of_birth),
                nameof.full<UserProfile>(u => u.region_player_info.national_id),
                nameof.full<UserProfile>(u => u.region_player_info.tax_id),
                nameof.full<UserProfile>(u => u.region_player_info.occupation),
                nameof.full<UserProfile>(u => u.region_player_info.nationality),
            ],
        };

        return regionRelatedFieldsMapping[country];
    }

    public getStateFields(country: string): string[] {
        const defaultStateFields = [nameof.full<UserProfile>(i => i.address.state)];
        const stateFieldsMapping: Record<string, string[]> = {
            MX: [nameof.full<UserProfile>(i => i.region_player_info.district)],
        };

        return stateFieldsMapping[country] ?? defaultStateFields;
    }
}

export const configurationManager = new FieldsConfigurationManager();
