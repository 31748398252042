import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {IModuleGridItem} from '@components/data-grid';

import {EditableColDef} from 'src/features/module-temp-storage/components/editable-components/types';
import GridViewEditAction from '../../../features/module-shared/components/GridViewEditAction';
import {domain} from '../types';

export const renderEditAction = (params: GridCellParams) => {
    const id = params.row[nameof<IModuleGridItem>(u => u.id)];
    const isEditable = (params.colDef as EditableColDef)?.isEditable;
    return <GridViewEditAction domain={domain} id={id} isEditable={isEditable}></GridViewEditAction>;
};
