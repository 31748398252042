import {GridColDef} from '@mui/x-data-grid';

import {PermissionEnum} from '@models/permissions';

import {useAvailablePermissions} from '../../../../../features/app/permission/PermissionHoc';
import {PermissionEditableColDef} from '../../../../../features/module-temp-storage/components/editable-components/types';

//TODO: [BO-2721] Move useGridColumnConfiguration/useGridPermissions/useJurisdictionConfigForColumns to DataGridEntity
/**
 * @deprecated
 * <p>Should be removed</p>
 */
export const useGridPermissions = (columns: GridColDef[]): GridColDef[] => {
    const hasColumnPermission = (result: GridColDef[], column: GridColDef) => {
        const permissionColumn = column as PermissionEditableColDef;
        const availablePermissions = useAvailablePermissions(permissionColumn);
        const resultColumn = {
            ...permissionColumn,
            permissions: availablePermissions,
            isEditable: permissionColumn.isEditable && availablePermissions.some(p => p !== PermissionEnum.Read),
        } as PermissionEditableColDef;

        return availablePermissions?.length ? [...result, resultColumn] : result;
    };

    const accessibleColumns = columns.reduce(hasColumnPermission, []);

    return accessibleColumns;
};
