import React from 'react';

import {useAutoMapper} from '@auto-mapper';
import {QueryFilterAdapterModel, QueryFilterAdapterProps, withQueryFilter} from '@components/filter';
import {TicketStatus} from '@models/sportsbook-transaction/type';

import {SportsbookTransactionFilterKeys, SportsbookTransactionFilterModel, SportsbookTransactionFilterName} from '../types';

import {SportsbookTransactionFilters} from './SportsbookTransactionFilters';

type SportsbookTransactionQueryFilterAdapterProps = QueryFilterAdapterProps<
    SportsbookTransactionFilterKeys,
    SportsbookTransactionFilterName,
    string
>;

function SportsbookTransactionQueryFilterAdapter({model, onChange, ...props}: SportsbookTransactionQueryFilterAdapterProps) {
    const mapper = useAutoMapper();
    const filterModel: SportsbookTransactionFilterModel = {
        ticketId: model?.ticketId as number,
        matchId: model?.matchId as number,
        compUserId: model?.compUserId as string,
        tournamentName: model?.tournamentName as string,
        betTime: {
            from: model?.betTimeStart as number,
            to: model?.betTimeEnd as number,
        },
        ticketStatus: mapper.map<Number, TicketStatus>(model?.ticketStatus as number, nameof<Number>(), nameof<TicketStatus>()),
    };

    function handleChange(model: SportsbookTransactionFilterModel) {
        const result: QueryFilterAdapterModel<SportsbookTransactionFilterKeys, string> = {
            ticketId: model?.ticketId,
            matchId: model?.matchId,
            compUserId: model?.compUserId,
            tournamentName: model?.tournamentName,
            betTimeStart: model?.betTime.from,
            betTimeEnd: model?.betTime.to,
            ticketStatus: mapper.map<TicketStatus, Number>(model?.ticketStatus, nameof<TicketStatus>(), nameof<Number>()),
        };

        onChange(result);
    }

    return <SportsbookTransactionFilters model={filterModel} onChange={handleChange} {...props} />;
}

export const SportsbookTransactionQueryFilters = withQueryFilter<
    SportsbookTransactionFilterKeys,
    SportsbookTransactionFilterName,
    SportsbookTransactionFilterKeys
>(SportsbookTransactionQueryFilterAdapter, {});
