import {defineMessages} from 'react-intl';

import {UserStatisticsGameType} from '@models/generated/graphql';

export const localizedUserStatisticsGameType = defineMessages({
    [UserStatisticsGameType.Nl]: {
        id: 'userStatisticsGameTypeNL',
        defaultMessage: 'NL',
    },
    [UserStatisticsGameType.Sd]: {
        id: 'userStatisticsGameTypeSD',
        defaultMessage: 'SD',
    },
    [UserStatisticsGameType.Plo]: {
        id: 'userStatisticsGameTypePLO',
        defaultMessage: 'PLO',
    },
});
