import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {ModuleName, SubmoduleName} from '@models/modules';
import {BoRole} from '@redux/entity';

import {getGridModuleDataReducer, getGridModuleStateReducer} from '../../features/module-shared/reducers';
import {FormModuleData} from '../../features/module-shared/types';

import actions, {loadModulesWithDetailsAction} from './actions';
import {AvailableModules, domain} from './types';

export const modulesListReducer = createReducer({modules: [], submodules: []} as AvailableModules).handleAction(
    loadModulesWithDetailsAction.success,
    (_, action) => action.payload
);

const createRolesReducer = () => {
    const formInitialState = {item: undefined, validationMessage: null} as FormModuleData<BoRole>;
    const editFormReducer = createReducer(formInitialState)
        .handleAction(actions.openRoleForm, (state, action) => ({
            ...state,
            item: action.payload,
            validationMessage: null,
        }))
        .handleAction(actions.closeRoleForm, state => ({
            ...state,
            ...formInitialState,
        }))
        .handleAction(actions.saveRole.failure, (state, action) => ({
            ...state,
            validationMessage: action.payload,
        }));

    const combined = combineReducers({
        domain: createReducer(domain),
        data: combineReducers({
            ...getGridModuleDataReducer(domain, ModuleName.AccessManagement, SubmoduleName.Roles),
            editForm: editFormReducer,
            state: combineReducers(getGridModuleStateReducer(domain)),
        }),
        additional: modulesListReducer,
    });

    return combined;
};

export default createRolesReducer();
