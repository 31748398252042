import React, {FC} from 'react';
import {MessageDescriptor} from 'react-intl';

import {useChipValue} from './ChipValue.hooks';

type ChipTooltip = {
    chipName: string;
    value: string;
    label?: string | MessageDescriptor;
};

type LabelChipTooltip = {
    value: string;
};

export const withChipTooltip =
    <TProps extends ChipTooltip>(WrappedComponent: FC<TProps>) =>
    (props: TProps) => {
        const {chipLabel} = useChipValue(props.value, props.chipName, props.label);

        return <WrappedComponent tooltipLabel={chipLabel} {...props} />;
    };

export const withLabelChipTooltip =
    <TProps extends LabelChipTooltip>(WrappedComponent: FC<TProps>) =>
    (props: TProps) => {
        return <WrappedComponent tooltipLabel={props.value} {...props} />;
    };
