import {of} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {RootEpic} from '@redux';

import {protectEpics} from '../../features/app/error-handling/epics';

import {fileActions} from './actions';

const downloadEpic: RootEpic = (actions$, _, container) =>
    actions$.pipe(
        filter(isActionOf(fileActions.download)),
        mergeMap(action => {
            container.fileDownloadService.download(action.payload);

            return of();
        })
    );

export const actionFileEpics = protectEpics(downloadEpic);
