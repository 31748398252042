import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import equal from 'fast-deep-equal/es6';
import {denormalize} from 'normalizr';
import {RootState} from 'typesafe-actions';

import {BaseFilterKeys, entitiesSelectorWithoutReferences, EntityState, EntityType, schemaMapper} from '@redux/entity';
import {toSearchFilter} from '@utils';

import {Filter, Paging, SearchFilter, Sorting} from 'src/common/types';

import {viewActions} from './actions';
import {viewEntityFilterSelector, viewEntityKeysSelector, viewEntityRequestStatusSelector, viewEntityTotalSelector} from './selectors';
import {schemaIdKey, ViewEntityData, ViewMetaProps, ViewModel, ViewRealtimeData, ViewType} from './types';

type UseViewEntity<TFields extends string> = {
    entity: EntityType;
    fields: TFields[];
};

type UseViewEntityProps<TFilterKey extends string, TFields extends string, TSortingFields extends string = TFields> = ViewMetaProps & {
    entity: UseViewEntity<TFields>;
    realtime?: ViewRealtimeData;
    defaultFilters?: Filter<any, TFilterKey>[];
    defaultSorting?: Sorting<TSortingFields>[];
    defaultPaging?: Paging;
    syncWithUrl?: boolean;
    validateFilter: (filter: Filter<any, TFilterKey>[]) => boolean;
    cleanDelay?: number;
    blockFetchWithInvalidFilter?: boolean;
};

export type UseViewEntityResult<TItem, TFields extends string, TSortingFields extends string = TFields> = {
    items: ViewModel<TItem>[];
    totalCount: number;
    searchFilter: SearchFilter;
    viewEntity: ViewEntityData<TFields>;
    handlePageChange: (newPage: number) => void;
    handlePageSizeChange: (newPageSize: number) => void;
    handleFilterChange: (newFilter: Filter[]) => void;
    handleSortChange: (newSorting: Sorting<TSortingFields>[]) => void;
    getSearchFilter: (mapper: (field: TSortingFields) => string) => SearchFilter;
};

function useRealtime(viewType: ViewType, realtime: ViewRealtimeData) {
    const dispatch = useDispatch();
    const [prevTriggers, setPrevTriggers] = useState(realtime);

    useEffect(() => {
        //resubscribe when args change
        if (realtime) {
            if (JSON.stringify(prevTriggers) !== JSON.stringify(realtime)) {
                dispatch(viewActions.refreshSubscription({view: viewType, realtime}));
                setPrevTriggers(realtime);
            }
        }
    }, [JSON.stringify(realtime)]);
}

export function useRealtimePauseResume() {
    const dispatch = useDispatch();

    function pause() {
        dispatch(viewActions.pause());
    }

    function resume() {
        dispatch(viewActions.resume());
    }

    return {
        pause,
        resume,
    };
}

/**
 * Custom hook for managing view entity data in Redux.
 *
 * @template TItem - The type of the items in the view entity.
 * @template TFilterKey - The type of the filter keys.
 * @template TFields - The type of the fields in the view entity.
 * @template TSortingFields - The type of the sorting fields in the view entity.
 * @param {UseViewEntityProps<TFilterKey, TFields, TSortingFields>} props - The hook props.
 * @returns {UseViewEntityResult<TItem, TFields, TSortingFields>} - The hook result.
 */
export const useViewInit = <TItem, TFilterKey extends string, TFields extends string, TSortingFields extends string = TFields>({
    viewType,
    displayName,
    entity,
    realtime,
    defaultFilters,
    defaultSorting,
    defaultPaging,
    syncWithUrl = false,
    validateFilter,
    cleanDelay = 0,
    blockFetchWithInvalidFilter = false,
    isCancelable,
}: UseViewEntityProps<TFilterKey, TFields, TSortingFields>): UseViewEntityResult<TItem, TFields, TSortingFields> => {
    const dispatch = useDispatch();

    const keys = useSelector((state: RootState) => viewEntityKeysSelector(state, {entityType: entity.entity, viewType})) ?? [];
    const entitiesState = useSelector((state: RootState) => entitiesSelectorWithoutReferences(state));
    const schema = schemaMapper.getSchema(entity.entity);
    const entities = addSchemaIdsToEntitiesItems(entitiesState);
    //TODO: remove denormalize after test for large column amount grid if renders take a lot of time
    const items: ViewModel<TItem>[] = denormalize(keys, [schema], entities);

    const totalCount = useSelector((state: RootState) => viewEntityTotalSelector(state, {entityType: entity.entity, viewType}));
    const requestStatus = useSelector((state: RootState) => viewEntityRequestStatusSelector(state, {entityType: entity.entity, viewType}));

    const [defaultFilterValues, setDefaultFilters] = useState(defaultFilters);
    const [defaultSortingValues, setDefaultSorting] = useState<Sorting<string>>(convertSortingFromArrayToObject(defaultSorting));
    const stateFilter: string = useSelector((state: RootState) => viewEntityFilterSelector(state, {viewType, entityType: entity.entity}));
    const searchFilter = toSearchFilter(stateFilter);

    useRealtime(viewType, realtime);

    useEffect(() => {
        if (viewType !== null && viewType !== undefined) {
            dispatch(
                viewActions.init({
                    view: viewType,
                    entity: {...entity, keys: [], total: 0, status: 'inProgress'},
                    realtime,
                    displayName,
                    defaultFilters: getValidatedFilters(defaultFilters),
                    defaultSorting: convertSortingFromArrayToObject(defaultSorting),
                    defaultPaging,
                    syncWithUrl,
                    blockFetchWithInvalidFilter,
                    cleanDelay,
                    isCancelable,
                })
            );
        }

        return () => {
            if (viewType !== null && viewType !== undefined) {
                dispatch(viewActions.close({view: viewType}));
            }
        };
    }, []);

    useEffect(() => {
        // TODO: [BO-3023] Check the need of defaultFilter null check. It's probably can be removed
        if (defaultFilters && defaultFilters?.length && !equal(defaultFilterValues, defaultFilters)) {
            handleFilterChange(defaultFilters);
            setDefaultFilters(defaultFilters);
        }
    }, [defaultFilters?.map(f => `${f?.key}${f?.value}`).join(), defaultPaging?.page, defaultPaging?.pageSize]);

    function getValidatedFilters(filters: Filter<any, TFilterKey>[]): Filter<any, TFilterKey>[] {
        let result = filters;
        searchFilter?.filter?.forEach((filter: Filter<any, TFilterKey>) => {
            if (result && !result?.find(f => f.key === filter.key)) {
                result.push(filter);
            }
        });

        if (validateFilter) {
            const invalidKey: BaseFilterKeys = 'invalid';
            const invalidFilter = {
                key: invalidKey,
                value: validateFilter(result) ? null : true,
            } as Filter<any, TFilterKey>;
            const valueFilters = filters?.filter(f => f.key !== invalidKey) ?? [];
            result = [...valueFilters, invalidFilter];
        }

        return result;
    }

    const handleFilterChange = (filters: Filter<any, TFilterKey>[]) => {
        dispatch(
            viewActions.updateFilterKeys({
                entity: entity.entity,
                view: viewType,
                filters: getValidatedFilters(filters),
                syncWithUrl,
                isCancelable,
            })
        );
    };

    const handlePageChange = (newPage: number) => {
        const {pageSize} = searchFilter.paging;
        const newPaging = {page: newPage, pageSize};

        dispatch(
            viewActions.updateFilterKeys({
                entity: entity.entity,
                view: viewType,
                paging: newPaging,
                syncWithUrl,
                isCancelable,
            })
        );
    };

    const handlePageSizeChange = (newPageSize: number) => {
        const {page, pageSize} = searchFilter.paging;

        const getNewPageNumber = (rowsPerPage: number) => {
            const firstPageItemIndex = pageSize * (page - 1) + 1;
            const newPage = Math.ceil(firstPageItemIndex / rowsPerPage);
            return newPage;
        };

        //when page size changed we need to calculate page to keep the first item of the page on the same page
        const newPage = getNewPageNumber(newPageSize);
        const newPaging = {page: newPage, pageSize: newPageSize};

        dispatch(
            viewActions.updateFilterKeys({
                entity: entity.entity,
                view: viewType,
                paging: newPaging,
                syncWithUrl,
                isCancelable,
            })
        );
    };

    const handleSortChange = (newSorting: Sorting<TSortingFields>[]) => {
        const newSortingObj = convertSortingFromArrayToObject(newSorting);
        if (!equal(defaultSortingValues, newSortingObj)) {
            dispatch(
                viewActions.updateFilterKeys({
                    entity: entity.entity,
                    view: viewType,
                    sorting: newSortingObj,
                    syncWithUrl,
                    isCancelable,
                })
            );
            setDefaultSorting(newSortingObj);
        }
    };

    function addSchemaIdsToEntitiesItems(entities: Partial<Record<EntityType, EntityState>>): Partial<Record<EntityType, EntityState>> {
        const result: Partial<Record<EntityType, EntityState>> = {...(entities ?? {})};
        Object.keys(result).forEach((entityType: EntityType) => {
            const itemsWithSchemaIds = Object.entries(entities[entityType])?.reduce((previousValue, [key, item]: [string, object]) => {
                previousValue[key] = {...item, [schemaIdKey]: key};
                return previousValue;
            }, {} as Record<string, unknown>);

            result[entityType] = itemsWithSchemaIds;
        });
        return result;
    }

    function convertSortingFromArrayToObject(sorting: Sorting<TSortingFields>[]): Sorting<string> {
        let res: Sorting<string> = undefined;

        if (sorting && sorting.length > 0) {
            const fields = sorting.map(s => s.field).join();
            const sort = sorting.map(s => s.sort).join();
            res = {field: fields, sort: sort};
        }

        return res;
    }

    function getSearchFilter(mapper: (field: TSortingFields) => string): SearchFilter {
        let res = searchFilter;
        if (searchFilter?.sorting?.length > 0) {
            res = {...res, sorting: searchFilter?.sorting?.map(s => ({field: mapper(s.field as TSortingFields), sort: s.sort}))};
        }
        return res;
    }

    return {
        items,
        totalCount,
        searchFilter,
        viewEntity: {
            status: requestStatus,
            entity: entity.entity,
            fields: entity.fields,
            filter: stateFilter,
        },
        handleFilterChange,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        getSearchFilter,
    };
};

export function useView(view: ViewType) {
    const dispatch = useDispatch();

    function forceUpdateFilter(entity: EntityType, filter: string) {
        dispatch(viewActions.updateFilter({view, entity, filter}));
    }

    return {
        forceUpdateFilter,
    };
}
