import React from 'react';
import {CustomCell, CustomRenderer, GridCellKind, measureTextCached, Rectangle, Theme} from '@glideapps/glide-data-grid';
import {push} from '@vs-centaurea/connected-react-router';
import {Dispatch} from 'redux';

import {CustomTheme} from '@style';

import {GlideCellData, rowHeight, TooltipContentProps} from '../types';

import {GlideCell} from './GlideCell';

type LinkCellValue = {
    path: string;
    title: string;
};

export type LinkCellFormattedValue = {
    title: string;
    onClick: () => void;
};

export class GlideLinkCell<TModel> extends GlideCell<TModel, LinkCellValue, LinkCellFormattedValue> {
    private readonly _dispatch: Dispatch;
    private readonly _cellKind = 'link';

    constructor(valueGetter: (model: TModel) => LinkCellValue, dispatch: Dispatch) {
        super(valueGetter);
        this._dispatch = dispatch;
    }

    getRenderer(muiTheme: CustomTheme): CustomRenderer<CustomCell<GlideCellData<LinkCellFormattedValue>>> {
        return {
            kind: GridCellKind.Custom,
            isMatch: (cell: CustomCell<GlideCellData<LinkCellFormattedValue>>): cell is CustomCell<GlideCellData<LinkCellFormattedValue>> =>
                cell.data.kind === this._cellKind,
            needsHover: true,
            needsHoverPosition: true,
            draw: (args, cell) => {
                const {ctx, theme, rect, hoverX, hoverY, overrideCursor} = args;
                const drawArea: Rectangle = this.getDrawArea(rect, theme);

                const fontSizeProperty = muiTheme.typography.body1.fontSize;
                const fontSize: number = typeof fontSizeProperty === 'string' ? Number.parseInt(fontSizeProperty) : fontSizeProperty;
                ctx.font = `${fontSize}px ${theme.fontFamily}`;
                ctx.fillStyle = muiTheme.palette.primary.main;
                ctx.fillText(cell.data.value.title, drawArea.x, drawArea.y + rowHeight / 2);
                const textMetrics = measureTextCached(cell.data.value.title, ctx);
                const textRect = this.getRectangleWithText(textMetrics, drawArea, theme);
                ctx.fillRect(drawArea.x, drawArea.y + (rowHeight + fontSize) / 2, textMetrics.width, 1);
                if (
                    hoverX >= textRect.x &&
                    hoverX <= textRect.x + textRect.width &&
                    hoverY >= textRect.y &&
                    hoverY <= textRect.y + textRect.height
                ) {
                    overrideCursor('pointer');
                }
            },
            onClick: args => {
                const {cell, bounds, posX, posY, theme} = args;
                let result: CustomCell<GlideCellData<LinkCellFormattedValue>> | undefined = undefined;
                const fontSize = 14;
                const font = `${fontSize}px ${theme.fontFamily}`;
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d', {alpha: false});
                ctx.font = font;
                const textMetrics = measureTextCached(cell.data.value.title, ctx);
                if (textMetrics) {
                    const rect = this.getRectangleWithText(textMetrics, bounds, theme);
                    const isLinkClicked = posX >= rect.x && posX <= rect.x + rect.width && posY >= rect.y && posY <= rect.y + rect.height;
                    if (isLinkClicked && args.cell.data?.value?.onClick) {
                        args.cell.data.value.onClick();
                        result = cell;
                    }
                }

                return result;
            },
        };
    }

    protected getCustomContent({title, onClick}: LinkCellFormattedValue): CustomCell<GlideCellData<LinkCellFormattedValue>> {
        return {
            kind: GridCellKind.Custom,
            copyData: title,
            data: {
                kind: this._cellKind,
                value: {title, onClick},
                tooltip: TextCellTooltipContent,
            },
            allowOverlay: false,
        };
    }

    protected formatValue(value: LinkCellValue): LinkCellFormattedValue {
        return {title: value.title, onClick: () => this._dispatch(push(value.path))};
    }

    protected isCellEmpty(value: LinkCellFormattedValue): boolean {
        return !value?.title || !value?.onClick;
    }

    private getRectangleWithText(metrics: TextMetrics, rect: Rectangle, theme: Theme): Rectangle {
        const x = theme.cellHorizontalPadding;
        const y = rect.height / 2 - metrics.actualBoundingBoxAscent / 2;
        const width = metrics.width;
        const height = 14 + metrics.actualBoundingBoxAscent / 2 + 1;

        return {x, y, width, height};
    }
}

function TextCellTooltipContent({value}: TooltipContentProps<LinkCellFormattedValue>) {
    return <div>{value?.title}</div>;
}
