import {MessageDescriptor} from 'react-intl';

import {GlideTextCell} from './GlideTextCell';

export class GlideLocalizedTextCell<TModel> extends GlideTextCell<TModel, MessageDescriptor> {
    private readonly _formatter: (value: MessageDescriptor) => string;

    constructor(valueGetter: (model: TModel) => MessageDescriptor, formatter: (value: MessageDescriptor) => string) {
        super(valueGetter);
        this._formatter = formatter;
    }

    protected formatValue(value: MessageDescriptor): string {
        return value ? this._formatter(value) : null;
    }
}
