import React, {FC} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {MessageDescriptor} from 'react-intl';
import {Box, Grid} from '@mui/material';

import {FormTextInputDefault, positiveIntegerRegex, RuleType, useValidationFormatter} from '@components/input';
import {withLabel} from '@components/label';

import {localizedValueRangePicker} from './localizeValueRangePicker';
import {useValueRangePickerClasses} from './valueRangePickerStyle';
import {Operators} from './valueRangePickerTypes';

export type RelativeRangeProps = {
    label: MessageDescriptor;
    operator: Operators;
};

export const RelativeRange: FC<RelativeRangeProps> = withLabel((props: RelativeRangeProps) => {
    const {control} = useFormContext();
    const {classes} = useValueRangePickerClasses();
    const validationFormatter = useValidationFormatter();
    const showTo = props.operator === Operators.isLessThan || props.operator === Operators.between;
    const showFrom =
        props.operator === Operators.isGreaterThan || props.operator === Operators.between || props.operator === Operators.equals;
    const showBoth = showTo && showFrom;
    const watched = useWatch({control});
    const localizeFirstInput = showTo ? localizedValueRangePicker.firstValueRangeInput : localizedValueRangePicker.valueRangeValue;
    const localizeSecondInput = showFrom ? localizedValueRangePicker.secondValueRangeInput : localizedValueRangePicker.valueRangeValue;

    return (
        <Box className={classes.valueRangePickerRelativeRange}>
            <Grid container spacing={1}>
                {showFrom ? (
                    <Grid item xs={12} md={showBoth ? 6 : 12}>
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    key={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                    hideLabel={true}
                                    placeholder={localizeFirstInput}
                                />
                            )}
                            name="from"
                            control={control}
                            rules={{
                                ...(showTo && {
                                    max: {
                                        value: watched.to,
                                        message: validationFormatter(
                                            RuleType.Max,
                                            localizedValueRangePicker.firstValueRangeInput,
                                            watched.to
                                        ),
                                    },
                                }),
                                required: validationFormatter(RuleType.Required, localizeFirstInput),
                                pattern: {
                                    value: positiveIntegerRegex,
                                    message: validationFormatter(RuleType.PositiveInteger, localizeFirstInput),
                                },
                            }}
                        />
                    </Grid>
                ) : null}

                {showTo ? (
                    <Grid item xs={12} md={showBoth ? 6 : 12}>
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    key={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                    hideLabel={true}
                                    placeholder={localizeSecondInput}
                                />
                            )}
                            name="to"
                            control={control}
                            rules={{
                                ...(showFrom && {
                                    min: {
                                        value: watched.from,
                                        message: validationFormatter(
                                            RuleType.Min,
                                            localizedValueRangePicker.secondValueRangeInput,
                                            watched.from
                                        ),
                                    },
                                }),
                                required: validationFormatter(RuleType.Required, localizeSecondInput),
                                pattern: {
                                    value: positiveIntegerRegex,
                                    message: validationFormatter(RuleType.PositiveInteger, localizeSecondInput),
                                },
                            }}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </Box>
    );
});
