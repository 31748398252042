import {useContext} from 'react';
import {Container} from 'inversify';

import {InversifyContext} from './inversifyProvider';
import {ServiceTypes, ServiceTypesKeys} from './inversifyTypes';

function useContainer(): Container {
    const {container} = useContext(InversifyContext);
    if (!container) {
        throw new Error("Inversify inversifyContainer isn't available");
    }

    return container;
}

function getSymbol(identifier: ServiceTypesKeys | Symbol): symbol {
    return typeof identifier === 'string' ? ServiceTypes[identifier] : (identifier as symbol);
}

export function useService<TService>(identifier: ServiceTypesKeys | Symbol): TService {
    const container = useContainer();
    const symbol = getSymbol(identifier);
    return container.get<TService>(symbol);
}

export function useServiceViaFactory<TService, TParams>(identifier: ServiceTypesKeys, params: TParams): TService {
    const container = useContainer();
    const symbol = getSymbol(identifier);
    const factory = container.get<(params: TParams) => TService>(symbol);

    return factory(params);
}
