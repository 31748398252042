import React from 'react';
import {MessageDescriptor} from 'react-intl';

export type ModalWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

export type ModalStyleParams = {
    maxWidth?: ModalWidth;
    isLimitedBody?: boolean;
};

export type ModalButton = {
    id?: string;
    isPrimary?: boolean;
    disabled?: boolean;
    label: MessageDescriptor | string;
    onClick: () => void;
};

export type ModalContent = {
    title?: JSX.Element;
    subtitle?: JSX.Element;
    description?: JSX.Element;
    body: JSX.Element;
    subInfoMessage?: string;
    buttons?: ModalButton[];
    footer?: JSX.Element;
    styleParams?: ModalStyleParams;
    dialogContentClassName?: string;
    onClose?: () => void;
};

type ModalContextProps = {
    isModalOpened: boolean;
    modalContent: ModalContent;
    openModal: (content: ModalContent) => void;
    closeModal: () => void;
};

export const ModalContext = React.createContext<ModalContextProps>(undefined);
