import React, {forwardRef, Fragment, RefObject, useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Box, BoxProps} from '@mui/material';
import Alert from '@mui/material/Alert';
import Popover from '@mui/material/Popover';
import {makeStyles} from 'tss-react/mui';

import {LocalizedMessageData} from 'src/common/types';
import {useTempStorageErrorState, useTempStorageInfoState} from '../../hooks';

export type MessagePopoverProps = {
    messageKey: string;
};

const useStyles = makeStyles()(() => ({
    popoverMessage: {
        display: 'flex',
        flexDirection: 'column',
    },
    popoverMessageText: {
        whiteSpace: 'pre-wrap',
    },
}));

const MessagePopoverText = ({children, ...props}: BoxProps) => {
    const {classes} = useStyles();
    return (
        <Box className={classes?.popoverMessageText} {...props}>
            {children}
        </Box>
    );
};

const withMessagePopover = (
    isError: boolean,
    useMessageStateHook: (messageKey: string) => {
        message: LocalizedMessageData[];
        hasMessage: boolean;
        clearMessage: () => void;
    }
) =>
    forwardRef(({messageKey}: MessagePopoverProps, ref: RefObject<Element>) => {
        const timer = useRef(null);
        const duration = 5000;
        const {classes} = useStyles();
        const {message, hasMessage, clearMessage} = useMessageStateHook(messageKey);

        const [anchorEl, setAnchorEl] = useState(null);
        const popoverId = hasMessage ? `message-popover-${isError}` : undefined;
        const isOpen = anchorEl !== null && hasMessage;

        const showMessagePopover = () => {
            setAnchorEl(ref?.current);
        };

        const hideMessagePopover = () => {
            setAnchorEl(null);
            clearMessage();
        };

        useEffect(() => {
            return () => {
                clearTimeout(timer.current);
            };
        }, []);

        useEffect(() => {
            if (hasMessage) {
                showMessagePopover();
                timer.current = setTimeout(() => {
                    hideMessagePopover();
                }, duration);
            }
        }, [hasMessage]);

        return (
            <Popover
                id={popoverId}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={hideMessagePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity={isError ? 'error' : 'success'}>
                    <Box className={classes.popoverMessage}>
                        {message?.map((m, index) => (
                            <Fragment key={index}>
                                {m?.message ? <FormattedMessage {...m.message} values={m.values} tagName={MessagePopoverText} /> : <></>}
                            </Fragment>
                        ))}
                    </Box>
                </Alert>
            </Popover>
        );
    });

export const ErrorPopover = withMessagePopover(true, useTempStorageErrorState);
export const InfoPopover = withMessagePopover(false, useTempStorageInfoState);
