import {CustomCell, CustomRenderer, GridCell, GridCellKind, Rectangle, TextCell, Theme} from '@glideapps/glide-data-grid';

import {emptyCellCharacter} from '@components/data-grid';
import {CustomTheme} from '@style';

import type {GlideCellData} from '../types';

export abstract class GlideCell<TModel = unknown, TValue = unknown, TFormattedValue = TValue> {
    private readonly _valueGetter: (model: TModel) => TValue;

    protected constructor(valueGetter: (model: TModel) => TValue) {
        this._valueGetter = valueGetter;
    }

    public abstract getRenderer(muiTheme: CustomTheme): CustomRenderer<CustomCell<GlideCellData<TFormattedValue>>>;

    public getCellContent(model: TModel, isSummary?: boolean): GridCell {
        const value = this._valueGetter(model);
        const formattedValue: TFormattedValue = this.formatValue(value);

        return this.isCellEmpty(formattedValue) ? this.getNullCell(isSummary) : this.getCustomContent(formattedValue, isSummary);
    }

    protected abstract formatValue(value: TValue): TFormattedValue;

    protected abstract getCustomContent(value: TFormattedValue, isSummary: boolean): CustomCell<GlideCellData<TFormattedValue>>;

    protected abstract isCellEmpty(value: TFormattedValue): boolean;

    protected getDrawArea(cellRect: Rectangle, theme: Theme): Rectangle {
        return {
            x: cellRect.x + theme.cellHorizontalPadding,
            y: cellRect.y + theme.cellVerticalPadding,
            width: cellRect.width - 2 * theme.cellHorizontalPadding,
            height: cellRect.height - 2 * theme.cellVerticalPadding,
        };
    }

    private getNullCell(isSummary: boolean): TextCell {
        return {
            kind: GridCellKind.Text,
            data: null,
            displayData: isSummary ? '' : emptyCellCharacter,
            allowOverlay: false,
        };
    }
}
