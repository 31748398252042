import {RootState} from 'typesafe-actions';

import {MarketingCodeGridItem} from '../../features/marketing-code/types';

export const gridDataSelector = (state: RootState) => state.marketingBonusCode.grid;

export const filterSelector = (state: RootState) => state.marketingBonusCode.grid.data.state.filter;

export const columnsSelector = (state: RootState) => state.marketingBonusCode.grid.data.state.columns;

export const itemsSelector = (state: RootState) => state.marketingBonusCode.grid.data.itemsPage;

export const selectedItemsSelector = (state: RootState) =>
    state.marketingBonusCode.grid.data.state.selectedItems as MarketingCodeGridItem[];
