import {getEndOfWeek, getStartOfWeek} from '@utils/date';

const historicalRecordsWeeksPeriod = 51;
const defaultDateFilterWeeksAgo = 1;

export function getStartDateOfAvailableDateRange() {
    return getStartOfWeek(historicalRecordsWeeksPeriod);
}

export function getDefaultStartDate() {
    return getStartOfWeek(defaultDateFilterWeeksAgo);
}

export function getDefaultEndDate() {
    return getEndOfWeek(defaultDateFilterWeeksAgo);
}
