import React, {useRef} from 'react';

import {AutocompleteInfoMessage, AutocompletePlaceholder, FormAutocompleteInput} from '@components/autocomplete';
import {TreeView} from '@components/tree-view/TreeView';
import {TreeViewItemSelectOption} from '@components/tree-view/TreeViewItemSelectOption';

import {useAutocompleteClasses} from './Autocomplete.styles';
import {useAutocompleteState} from './hooks';
import {AutocompleteProps} from './types';

export const InlineAutocomplete = <TValue,>({
    value,
    defaultOption,
    options,
    placeholder,
    emptyPlaceholder,
    subInfoMessage,
    errorMessage,
    onValueChange,
    onFilterChange,
    errorMessageOrientation,
    dataTestId,
    allowEmptyValue,
    mode = 'server',
}: AutocompleteProps<TValue>) => {
    if (mode === 'client') {
        throw new Error('Mode not implemented.');
    }
    const {classes: styles} = useAutocompleteClasses();
    const autocompleteRef = useRef<HTMLDivElement>(null);

    const {open, inputValue, autocompleteState, handleInputChange, handleBlur, handleKeyDown, handleClear, handleAutocompleteChange} =
        useAutocompleteState({
            value,
            defaultOption,
            options,
            onValueChange,
            onFilterChange,
            allowFreeInputValue: false,
            allowEmptyValue,
            multiple: false,
            isAlwaysOpen: true,
        });

    return (
        <div className={styles.autocompleteContainer} data-testid={dataTestId} ref={autocompleteRef}>
            <FormAutocompleteInput
                value={inputValue}
                onInputChange={handleInputChange}
                onBlur={handleBlur}
                clear={handleClear}
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
            />
            {!inputValue && !options?.length ? (
                <AutocompletePlaceholder emptyPlaceholder={emptyPlaceholder} />
            ) : (
                <TreeView className={styles.autocompleteViewList}>
                    {options?.map(option => (
                        <TreeViewItemSelectOption
                            selected={option.value === value}
                            key={option.value}
                            nodeId={option.value}
                            label={option.label}
                            onClick={value => handleAutocompleteChange(null, value)}
                        ></TreeViewItemSelectOption>
                    ))}
                </TreeView>
            )}

            <AutocompleteInfoMessage
                subInfoMessage={subInfoMessage}
                autocompleteState={autocompleteState}
                hideMessage={open}
                containerRef={autocompleteRef}
                dataTestId="autocompleteInlineErrorMessage"
                message={errorMessage}
                messageOrientation={errorMessageOrientation}
            />
        </div>
    );
};
