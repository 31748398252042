import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {BoUser} from '@redux/entity';

import {protectEpics} from '../app/error-handling/epics';
import {loadDataEpic} from '../module-temp-storage/epics';

import {appSharedActions} from './actions';

const initUsersLoadingEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(appSharedActions.initUsersLoading)),
        mergeMap(() => of(appSharedActions.loadUsers.request({dataKey: nameof(BoUser)})))
    );

const usersLoadEpic: RootEpic = loadDataEpic(appSharedActions.loadUsers, (_payload, _, container) => {
    return container.sharedDataService.loadUsers();
});

const appSharedDataEpics = protectEpics(initUsersLoadingEpic, usersLoadEpic);

export default appSharedDataEpics;
