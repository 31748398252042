import React, {useEffect, useState} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {makeStyles} from 'tss-react/mui';

import {Chip} from '@components/chip/Chips';
import {ChipType} from '@components/chip/types';
import {TreeViewFilterItem} from '@components/filter';
import {FilterProps} from '@components/filter/types';
import {MultiSelectWithTextCounterValue, SelectButton} from '@components/select';
import {TreeOption, TreeView} from '@components/tree-view';
import {CustomTheme, SystemColorsEnum} from '@style';
import {removeItemsFromArray} from '@utils/array';

import {SelectOption} from 'src/features/module-shared/types';

const localized = defineMessages({
    any: {
        id: 'TreeViewFilter_defaultValueLabel',
        defaultMessage: 'Any',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    treeViewFilterContainer: {
        width: '400px',
        maxHeight: '250px',
        overflow: 'auto',
        paddingTop: theme.spacing(),
    },
    treeViewFilterDropdownActions: {
        display: 'flex',
        justifyContent: 'end',
        padding: theme.spacing(2, 2.5),
        gap: theme.spacing(1.5),
        borderTop: `1px solid ${theme.custom.palette.content.border}`,
    },
    treeViewFilterSelectedCountChip: {
        marginLeft: theme.spacing(),
    },
}));

type TreeViewFilterOption = Omit<TreeOption, 'subOptions'> & {
    subOptions: TreeViewFilterOption[];
    startIcon?: 'checkbox' | React.ReactNode;
};

type TreeViewFilterProps = FilterProps<string[]> & {options: TreeViewFilterOption[]; label: string | MessageDescriptor};

export function TreeViewFilter({value, onChange, options, label}: TreeViewFilterProps) {
    const {classes} = useStyles();
    const [selectedValues, setSelectedValues] = useState<string[]>(value ?? []);

    useEffect(() => {
        setSelectedValues(value ?? []);
    }, [value?.join(' ')]);

    function handleClick(value: string) {
        if (selectedValues?.includes(value)) {
            setSelectedValues(removeItemsFromArray(selectedValues, value));
        } else {
            setSelectedValues(prevState => [...prevState, value]);
        }
    }

    function handleApplyClick() {
        onChange(selectedValues);
    }

    function handleCancelClick() {
        setSelectedValues(value ?? []);
    }

    function getFlatOptions(options: TreeViewFilterOption[]): TreeViewFilterOption[] {
        return options?.length ? [options, ...options?.map(o => getFlatOptions(o.subOptions))]?.flat() : [];
    }

    function renderValueLabel() {
        const flatSelectOptions: SelectOption[] = getFlatOptions(options)
            ?.filter(o => value?.includes(o.value))
            ?.map(o => ({label: o.label, value: o.value}));
        return (
            <MultiSelectWithTextCounterValue
                label={label}
                selected={value}
                options={flatSelectOptions}
                emptyValue={localized.any}
                maxWidth={400}
            />
        );
    }

    return (
        <SelectButton
            buttonContent={renderValueLabel()}
            applyAction
            applyActionContent={
                selectedValues?.length ? (
                    <Chip
                        label={selectedValues?.length}
                        chipType={ChipType.Mark}
                        chipVariant={SystemColorsEnum.White}
                        size="small"
                        fillWidth={false}
                        className={classes.treeViewFilterSelectedCountChip}
                    />
                ) : null
            }
            onApplyClick={handleApplyClick}
            cancelAction
            onCancelClick={handleCancelClick}
            styles={{dropdownActions: classes.treeViewFilterDropdownActions}}
            fitDropdownToContent
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            transformOrigin={{vertical: 'top', horizontal: 'left'}}
        >
            <TreeView
                defaultCollapseIcon={<ExpandLessIcon />}
                defaultExpandIcon={<ExpandMoreIcon color="secondary" />}
                className={classes.treeViewFilterContainer}
            >
                {options?.map(o => (
                    <TreeViewFilterItem option={o} selectedValues={selectedValues} onClick={handleClick} showTotalCount />
                ))}
            </TreeView>
        </SelectButton>
    );
}
