import {createReducer, PayloadAction} from 'typesafe-actions';

import {AsyncActions, asyncActions} from './actions';
import {AsyncActionErrorPayload, AsyncActionsState, AsyncActionStatusPayload} from './types';

const defaultState: AsyncActionsState = {};

export const asyncActionsReducer = createReducer<AsyncActionsState, AsyncActions>(defaultState)
    .handleAction(asyncActions.setRequestStatus, (state: AsyncActionsState, action: PayloadAction<string, AsyncActionStatusPayload>) => {
        if (action.payload.requestAction) {
            return {
                ...state,
                [action.payload.requestAction]: {
                    requestStatus: action.payload.requestStatus,
                    errorMessage: state?.[action.payload.requestAction]?.errorMessage,
                    errorMessageValues: state?.[action.payload.requestAction]?.errorMessageValues,
                },
            };
        }

        return state;
    })
    .handleAction(asyncActions.setErrorMessage, (state: AsyncActionsState, action: PayloadAction<string, AsyncActionErrorPayload>) => {
        if (action.payload.requestAction) {
            return {
                ...state,
                [action.payload.requestAction]: {
                    requestStatus: state?.[action.payload.requestAction]?.requestStatus,
                    errorMessage: action.payload.errorMessage,
                    errorMessageValues: action.payload.errorMessageValues,
                },
            };
        }

        return state;
    });
