import React from 'react';
import {useDispatch} from 'react-redux';

import {IconButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons/types';

import {gridModuleActions} from '../actions';

type GridViewEditActionProps = {
    domain: string;
    id: string;
    isEditable: boolean;
    mode?: 'view' | 'edit';
};

const GridViewEditAction = ({domain, id, isEditable, mode = 'edit'}: GridViewEditActionProps) => {
    const moduleActions = gridModuleActions(domain);
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(moduleActions.itemLoad.request(id));
    };

    return (
        <IconButton
            onClick={onClick}
            disabled={!isEditable}
            iconClassName={mode === 'edit' ? CustomIcon.WriteOutline : CustomIcon.ViewOutline}
        />
    );
};

export default GridViewEditAction;
