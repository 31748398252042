import React from 'react';
import {defineMessages} from 'react-intl';

import {LinkButton} from '@components/link';

import {useKycLink} from '../../../../features/block-account-verification';

const localized = defineMessages({
    jumio: {
        id: 'KD_jumio',
        defaultMessage: 'Jumio',
    },
});

type KYCDocumentLinkProps = {
    docRefId: string;
};

export const KYCDocumentLink = ({docRefId}: KYCDocumentLinkProps) => {
    const {openLink} = useKycLink(docRefId);

    return <LinkButton icon="icon-linked" linkStyle="chip" onClick={openLink} title={localized.jumio} />;
};
