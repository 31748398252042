import {DocumentNode} from 'graphql';
import {produce} from 'immer';

import {BaseFilterKeys, Filter} from '@redux/entity';
import {endDateNow, getNHoursAgoAsSeconds} from '@utils';

import {DateRange, NumRange, Sorting as GqlSorting, SortOrder, TextFilter, Timestamp} from '../../models/generated/graphql';
import {GqlRequest} from '../types';

export type RequestPayload<TFields extends string = string> = {
    filter: string;
    fields: TFields[];
};

export abstract class GqlRequestBuilder<TQueryArgs, TQueryFields extends string, TFilterKeys extends string = BaseFilterKeys> {
    public buildGqlRequest(requestPayload: RequestPayload<TQueryFields>): GqlRequest<TQueryArgs> {
        const filter = this.mapToFilterObject(requestPayload.filter);

        return !this.isFilterInvalid(filter)
            ? {
                  query: this.buildQuery(requestPayload.fields),
                  variables: this.getGqlPageArgs(filter),
              }
            : null;
    }

    protected abstract buildQuery(fields: TQueryFields[]): DocumentNode;

    protected buildQueryFields(fields: string[]): string {
        const tree = this.buildQueryFieldTree(fields);
        return this.formatQueryFieldTree(tree);
    }

    private buildQueryFieldTree(queryFields: string[]): Record<string, any> {
        return produce({}, draft => {
            queryFields?.forEach(queryField => {
                let cursor: Record<string, any> = draft;
                const fields = queryField.split('.');
                fields.forEach(field => {
                    if (!cursor[field]) {
                        cursor[field] = {};
                    }
                    cursor = cursor[field];
                });
            });
        });
    }

    private formatQueryFieldTree(tree: Record<string, any>, nestingLevel: number = 0): string {
        let result: string = '';
        const tab: string = '  ';
        const currentSpacing = tab.repeat(nestingLevel);
        Object.keys(tree).forEach(field => {
            if (Object.keys(tree[field]).length > 0) {
                result = `${result}${currentSpacing}${field} {\n${this.formatQueryFieldTree(
                    tree[field],
                    nestingLevel + 1
                )}${currentSpacing}}\n`;
            } else {
                result = `${result}${currentSpacing}${field}\n`;
            }
        });

        return result;
    }

    /**
     * @deprecated
     * <p>Use {@link hasField} together with @include directive</p>
     */
    protected hasQueryItem(fields: TQueryFields[], field: TQueryFields) {
        return fields.includes(field) ? '' : ' @client ';
    }

    /**
     * @deprecated
     * <p>Use {@link hasAnyField} together with @include directive</p>
     */
    protected hasAnyOfQueryItems(fields: TQueryFields[], subFields: TQueryFields[]) {
        return fields.some(r => subFields.includes(r)) ? '' : ' @client ';
    }

    protected hasField(actualFields: TQueryFields[], expectedField: TQueryFields): boolean {
        return actualFields.includes(expectedField);
    }

    protected hasAnyField(actualFields: TQueryFields[], expectedFields: TQueryFields[]): boolean {
        return actualFields.some(r => expectedFields.includes(r));
    }

    protected mapToFilterObject(filter: string): Filter<TFilterKeys> {
        const result: Filter<TFilterKeys> = {};
        const searchParams = new URLSearchParams(filter);
        searchParams.forEach((value, key) => {
            result[key as TFilterKeys] = value;
        });
        return result;
    }

    protected isFilterInvalid({invalid}: Filter): boolean {
        return <boolean>invalid;
    }

    protected getGqlPageArgs(filter: Filter<TFilterKeys>): TQueryArgs {
        const paging = this.buildPaging(filter);
        return (filter ? {...this.buildFilter(filter), ...this.buildSort(filter), ...paging} : paging) as TQueryArgs;
    }

    protected abstract buildFilter(filter: Filter<TFilterKeys>): object;

    protected toGQLObjectFilter<TResult extends object>(filter: Filter<TFilterKeys>, filterName: TFilterKeys): TResult {
        const value = this.parseJSONFilter(filter, filterName);
        return (typeof value === 'object' ? value : undefined) as TResult;
    }

    protected toGQLStringFilter(filter: Filter<TFilterKeys>, filterName: TFilterKeys): string {
        return this.parseJSONFilter(filter, filterName)?.toString();
    }

    protected toGQLBooleanFilter(filter: Filter<TFilterKeys>, filterName: TFilterKeys): boolean {
        return !!this.parseJSONFilter(filter, filterName);
    }

    protected toGQLMultiselectFilter<TResult>(filter: Filter<TFilterKeys>, filterName: TFilterKeys): TResult {
        return filterName in filter
            ? ((this.getMultiselectFilterList(filter[filterName] as string) ?? filter[filterName]) as TResult)
            : undefined;
    }

    private parseJSONFilter<TResult>(filter: Filter<TFilterKeys>, filterName: TFilterKeys): TResult {
        let result: TResult;
        if (filterName in filter) {
            try {
                result = JSON.parse(filter[filterName] as string);
            } catch (e) {
                result = filter[filterName] as TResult;
            }
        } else {
            return undefined;
        }
        return result;
    }

    private getMultiselectFilterList(serializedFilter: string): string[] {
        try {
            const value = JSON.parse(serializedFilter);
            return Array.isArray(value) ? value?.flatMap(v => v) : null;
        } catch (e) {
            return null;
        }
    }

    protected getGQLTextFilter(textFilter: TextFilter[]): TextFilter[] {
        textFilter = textFilter.filter(f => f?.text?.length);
        return textFilter.length ? textFilter : undefined;
    }

    protected toGQLDateRange(from: unknown, to: unknown): DateRange {
        const getDateFilterPart = (t: string): Timestamp => {
            const seconds = Number(t);
            const result = isNaN(seconds) || !seconds ? null : seconds;
            return t ? (t === endDateNow ? {seconds: getNHoursAgoAsSeconds(0)} : {seconds: result}) : null;
        };

        return from || to
            ? {
                  from: getDateFilterPart(from as string),
                  to: getDateFilterPart(to as string),
              }
            : undefined;
    }

    protected toGQLNumberRangeFilter(min: unknown, max: unknown): NumRange {
        const getNumberRangeValue = (num: unknown) => {
            const value = Number(num);
            return isNaN(value) || value === null || value === undefined ? null : value;
        };
        return min || max
            ? {
                  min: getNumberRangeValue(min),
                  max: getNumberRangeValue(max),
              }
            : undefined;
    }

    protected toGQLTextFilter(fields: string[], value: string, transformTextMapper?: (value: string) => string): TextFilter {
        if (fields?.length && value) {
            let parsedValue;
            try {
                parsedValue = isNaN(Number(value)) ? JSON.parse(value) : value;
                parsedValue = Array.isArray(parsedValue) ? parsedValue.join(' ') : parsedValue;
            } catch (e) {
                parsedValue = value;
            }
            return {
                text: transformTextMapper ? transformTextMapper(parsedValue?.toString()) : parsedValue?.toString(),
                search_in: fields,
            };
        } else {
            return null;
        }
    }

    protected ignoreCase(value: string): string {
        const isSymbol = (str: string): boolean => {
            return !!str.match(/[a-z!@#$%^&()_+=[\]{};':"\\|,.<>/?]/i);
        };

        const getCaseInsensitiveWord = (str: string): string => {
            return str
                .split('')
                .map(c => (isSymbol(c) ? `[${c.toUpperCase()}${c.toLowerCase()}]` : c))
                .join('');
        };

        return value
            .toString()
            .split(' ')
            .map(i => `/${getCaseInsensitiveWord(i)}/`)
            .join(' ')
            .replaceAll('*', '.*');
    }

    protected phraseSearch(value: string): string {
        return `"${value}"`;
    }

    protected phraseListSearch(value: string, separator: string): string {
        return value?.length
            ? value
                  .split(separator)
                  .map(v => `"${v}"`)
                  .join(separator)
            : value;
    }

    protected buildSort(filter: Filter): {sort?: GqlSorting | GqlSorting[]} {
        return filter.sortOrder || filter.sortField
            ? {
                  sort: {
                      sort: filter.sortField as string,
                      order: [SortOrder.Asc, 'asc'].includes(filter.sortOrder as string) ? SortOrder.Asc : SortOrder.Desc,
                  },
              }
            : {};
    }

    //TODO: [BO-2912] Refactor sorting interfacte in GqlRequestBuilder to array of objects instead of 2 separate strings
    protected buildMultipleSort(filter: Filter): {sort?: GqlSorting[]} {
        if (filter.sortOrder || filter.sortField) {
            const sortFields = (filter.sortField as string)?.split(',');
            const sortOrders = (filter.sortOrder as string)?.split(',');
            return {
                sort: sortFields?.map((sortField, index) => ({
                    sort: sortField as string,
                    order: sortOrders?.[index] === 'asc' ? SortOrder.Asc : SortOrder.Desc,
                })),
            };
        } else return {};
    }

    protected buildPaging(filter: Filter): object {
        const defaultPaging = {end: 10, start: 0};
        return filter.page && filter.size
            ? {
                  start: ((filter.page as number) - 1) * (filter.size as number),
                  end: (filter.page as number) * (filter.size as number),
              }
            : defaultPaging;
    }
}
