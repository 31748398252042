import {createAsyncAction} from 'typesafe-actions';

import {LoadPayload} from '../../module-temp-storage/types';
import {PatchPayload} from '../../module-temp-storage/types';

import {domain, SecurityCaseFilter} from './types';

const updateSecurityCaseAction = '/updateSecurityCase';
const updateSecurityCaseSuccessAction = '/updateSecurityCaseSuccess';
const updateSecurityCaseFailedAction = '/updateSecurityCaseFailed';

const loadSecurityCasesAction = '/loadSecurityCases';
const loadSecurityCasesSuccessAction = '/loadSecurityCasesSuccess';
const loadSecurityCasesFailedAction = '/loadSecurityCasesFailed';

export const actions = {
    updateSecurityCase: createAsyncAction(
        `${domain}${updateSecurityCaseAction}`,
        `${domain}${updateSecurityCaseSuccessAction}`,
        `${domain}${updateSecurityCaseFailedAction}`
    )<PatchPayload, PatchPayload, PatchPayload>(),

    loadSecurityCases: createAsyncAction(
        `${domain}${loadSecurityCasesAction}`,
        `${domain}${loadSecurityCasesSuccessAction}`,
        `${domain}${loadSecurityCasesFailedAction}`
    )<LoadPayload<SecurityCaseFilter>, LoadPayload<SecurityCaseFilter>, LoadPayload<SecurityCaseFilter>>(),
};
