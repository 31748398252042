import React from 'react';

import {useService} from '@inversify/hooks';
import {EntityType} from '@redux/entity';

import {
    BulkActionKey,
    BulkStepExecutionProps,
    BulkStepSummaryContentChipList,
    BulkStepSummaryContentProps,
    PerformNoteStrategy,
    withBulkStepExecutionSummary,
} from '../../block-bulk-actions';
import {usePerformUserProfileActions} from '../../block-user-profile-bulk-actions';
import {PerformKYCRejectStrategy} from '../services/performStrategy';
import {ServiceTypes} from '../types';

function BulkStepSummaryChipList(props: BulkStepSummaryContentProps) {
    return <BulkStepSummaryContentChipList {...props} getChipValue={item => item.itemId} />;
}

const BulkStepAccountVerificationSummary = withBulkStepExecutionSummary(BulkStepSummaryChipList);

export function BulkKYCExecutionStepModal({onNext, hasRetry}: BulkStepExecutionProps) {
    const performUserProfileActions = usePerformUserProfileActions();
    const performNotesStrategy = useService<PerformNoteStrategy>('PerformNoteStrategy');
    const performKYCRejectStrategy = useService<PerformKYCRejectStrategy>(ServiceTypes.PerformKYCRejectStrategy);

    return (
        <BulkStepAccountVerificationSummary
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.UserProfile}
            actionsData={[
                ...Object.values(performUserProfileActions),
                {type: 'actionItems', actionKey: BulkActionKey.NotesAndAttachments, strategy: performNotesStrategy},
                {type: 'operation', actionKey: BulkActionKey.KYCReject, strategy: performKYCRejectStrategy},
            ]}
        />
    );
}
