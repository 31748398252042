import {ChipType, ChipVariant} from '@components/chip/types';
import {CustomIcon} from '@components/icons/types';
import {RevenueShareOfferingStatus} from '@models/generated/graphql';

import {localizedRevenueShareOfferingStatus} from '../../../features/app/intl/shared-resources/revenueShareOfferingStatus';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export const getRevenueShareOfferingStatusChipsMapping = () => ({
    [RevenueShareOfferingStatus.Active]: {
        label: localizedRevenueShareOfferingStatus[RevenueShareOfferingStatus.Active],
        chipType: ChipType.Mark,
        chipVariant: ChipVariant.LightGreen,
        startIconClass: CustomIcon.Target,
    },
    [RevenueShareOfferingStatus.Inactive]: {
        label: localizedRevenueShareOfferingStatus[RevenueShareOfferingStatus.Inactive],
        chipType: ChipType.TagMark,
        chipVariant: ChipVariant.Disabled,
        startIconClass: CustomIcon.Block,
    },
});
