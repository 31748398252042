import React from 'react';
import {StyledComponentProps} from '@mui/material/styles';

import {MessageOrientation} from '@components/alerts/Message';
import {EditableAutocompleteProps} from '@components/editable/EditableAutocomplete';
import {EntityType, Filter} from '@redux/entity';
import {ViewType} from '@redux/view';
import {CountriesApiFilterKeys, countryFilterKey} from '@services/rest-api/countriesApiService';
import {isStringNullOrEmpty} from '@utils';

import {State} from '../types';

import {ViewAutocomplete} from './ViewAutocomplete';
import {localized} from './ViewAutocomplete.localize';

type StateAutocompleteBaseProps = Omit<EditableAutocompleteProps<Filter<CountriesApiFilterKeys>>, 'disabled'>;

type StateAutocompleteProps = StateAutocompleteBaseProps & StyledComponentProps;

export const StateAutocomplete = (props: StateAutocompleteProps) => {
    const viewType: ViewType = 'StateAutocomplete';
    const selectedState = props.value ? ({code: props.value, name: props.label} as State) : null;

    return (
        <ViewAutocomplete
            {...props}
            value={selectedState}
            viewType={viewType}
            entityType={EntityType.State}
            displayName={localized.stateAutocompleteDisplayName}
            fields={[nameof<State>(i => i.code)]}
            optionMapper={(value: State) => (value ? {label: value.name ?? '', value: value.code} : null)}
            valueMapper={(value: State) => (value ? value.code : null)}
            filterKey={countryFilterKey}
            hasLargeOptions={true}
            errorMessageOrientation={MessageOrientation.Floating}
            validateFilter={filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'country')?.value)}
            mode="client"
        />
    );
};
