import {createAction} from 'typesafe-actions';

import {FileType} from '@file/types';
import {TransactionType} from '@models/generated/graphql';

export const domain = 'block-bulk-operation-transaction';

export type DownloadManualTransactionTemplateActionPayload = {
    fileType: FileType;
    transactionType: TransactionType;
};

export const transactionsBulkActions = {
    downloadManualTransactionTemplate: createAction(
        `${domain}/download-manual-transaction-template`
    )<DownloadManualTransactionTemplateActionPayload>(),
};
