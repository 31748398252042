import {inject, injectable} from 'inversify';
import {map, Observable} from 'rxjs';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {SportsbookTransactionRecord} from '@models/sportsbook-transaction/type';
import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload} from '@redux/entity';
import {endDateNow, getNHoursAgoAsSeconds} from '@utils/date';

import {
    GetRecordFilterNumber,
    GetRecordFilterString,
    GetRecordRequestPayload,
    ISportsbookApiService,
} from './rest-api/ISportsbookApiService';
import {IEntityReadService} from './entity';
import {IRequestParser} from './types';

@injectable()
export class SportsbookTransactionService implements IEntityReadService {
    private readonly _sportsbookApiService: ISportsbookApiService;
    private readonly _sportsbookRequestParser: IRequestParser<GetRecordRequestPayload>;
    constructor(@inject(ServiceTypes.SportsbookApiService) sportsbookApiService: ISportsbookApiService) {
        this._sportsbookApiService = sportsbookApiService;
        this._sportsbookRequestParser = new SportsbookRequestParser();
    }
    get(payload: EntityFetchRequestPayload<string>): Observable<EntityFetchServiceResponsePayload<SportsbookTransactionRecord>> {
        const requestPayload = this._sportsbookRequestParser.parse(payload);

        return this._sportsbookApiService.getRecord(requestPayload).pipe(map(r => ({...r, requestPayload: payload})));
    }
}

export class SportsbookRequestParser implements IRequestParser<GetRecordRequestPayload> {
    public parse(payload: EntityFetchRequestPayload): GetRecordRequestPayload {
        const requestPayload: GetRecordRequestPayload = {
            ...this.parseFilter(payload.filter),
            ...this.parsePagination(payload.filter),
            ...this.parseSorting(payload.filter),
            ...this.parseDateFilter(payload.filter),
        };

        return requestPayload;
    }
    private parseFilter(filter: string): GetRecordRequestPayload {
        const urlSearchParams = new URLSearchParams(filter);
        const filterKeysString: (keyof GetRecordFilterString)[] = ['compUserId', 'tournamentName'];
        const filterKeysNumber: (keyof GetRecordFilterNumber)[] = ['ticketId', 'matchId', 'ticketStatus'];
        const filters: GetRecordRequestPayload = {};
        filterKeysString.forEach(key => {
            if (urlSearchParams.has(key)) {
                const value = urlSearchParams.get(key);
                if (value) {
                    filters[key] = value;
                }
            }
        });
        filterKeysNumber.forEach(key => {
            if (urlSearchParams.has(key)) {
                const value = parseInt(urlSearchParams.get(key));
                if (!isNaN(value)) {
                    filters[key] = value;
                }
            }
        });

        return filters;
    }

    private parseDateFilter(filter: string): GetRecordRequestPayload {
        const urlSearchParams = new URLSearchParams(filter);
        const filters: GetRecordRequestPayload = {};
        const start: keyof GetRecordFilterNumber = 'betTimeStart';
        const end: keyof GetRecordFilterNumber = 'betTimeEnd';

        if (urlSearchParams.has(start)) {
            filters.betTimeStart = parseInt(urlSearchParams.get(start));
        }

        if (urlSearchParams.has(end)) {
            filters.betTimeEnd = parseEndValue(urlSearchParams.get(end));
        }

        function parseEndValue(value: string): number {
            let res: number;
            if (value === endDateNow) {
                res = getNHoursAgoAsSeconds(0);
            } else {
                res = parseInt(value);
            }
            return res;
        }

        return filters;
    }

    private parsePagination(filter: string): GetRecordRequestPayload {
        const urlSearchParams = new URLSearchParams(filter);
        let result: GetRecordRequestPayload = {};
        if (urlSearchParams.has('page') && urlSearchParams.has('size')) {
            const page = parseInt(urlSearchParams.get('page'));
            const pageSize = parseInt(urlSearchParams.get('size'));
            if (page > 0 && pageSize > 0) {
                result = {page, pageSize};
            }
        }

        return result;
    }

    private parseSorting(filter: string): GetRecordRequestPayload {
        const urlSearchParams = new URLSearchParams(filter);
        let result = {};
        if (urlSearchParams.has('sortField') && urlSearchParams.has('sortOrder')) {
            const sortOrder = urlSearchParams.get('sortOrder');
            if (sortOrder === 'asc' || sortOrder === 'desc') {
                const order = `${urlSearchParams.get('sortField')} ${urlSearchParams.get('sortOrder')}`;
                result = {order};
            }
        }

        return result;
    }
}
