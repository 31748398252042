import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {ChipGroup, GroupedChipList} from '@components/chip/GroupedChipList';
import {ChipType, ChipVariant} from '@components/chip/types';
import {OutlinedButtonDropdown} from '@components/dropdown/Dropdown';
import {CustomTheme} from '@style';

const localized = defineMessages({
    details: {
        id: 'BulkExecutionStepDetailsButton_details',
        defaultMessage: 'Details',
    },
    applied: {
        id: 'BulkExecutionStepDetailsButton_applied',
        defaultMessage: 'Applied',
    },
    missed: {
        id: 'BulkExecutionStepDetailsButton_missed',
        defaultMessage: 'Missed',
    },
});

const useBulkExecutionStepDetailsButtonStyles = makeStyles()((theme: CustomTheme) => ({
    bulkExecutionStepDetailsButtonDropdownContainer: {
        display: 'block',
        padding: theme.spacing(1, 0, 0, 1),
        height: '100%',
    },
    bulkExecutionStepDetailsButton: {
        height: theme.custom.smallPlusButtonHeight,
        minWidth: 'unset',
        padding: theme.spacing(1, 2),
    },
    bulkExecutionStepDetailsDropdownPaper: {
        width: '380px',
        height: '250px',
        border: `1px solid ${theme.custom.palette.content.border}`,
        padding: theme.spacing(1),
    },
}));

type BulkExecutionStepDetailsButtonProps = {
    applied: string[];
    missed: string[];
};

export const BulkExecutionStepDetailsButton = ({applied, missed}: BulkExecutionStepDetailsButtonProps) => {
    const {classes} = useBulkExecutionStepDetailsButtonStyles();

    function getGroups(): ChipGroup[] {
        return [
            {
                groupTitle: localized.applied,
                items: applied.map(item => ({
                    value: item,
                    variant: ChipVariant.Disabled,
                    type: ChipType.Status,
                })),
            },
            {
                groupTitle: localized.missed,
                items: missed.map(item => ({
                    value: item,
                    variant: ChipVariant.Bronze,
                    type: ChipType.Status,
                })),
            },
        ];
    }

    return (
        <OutlinedButtonDropdown
            data-testid="bulkExecutionStepDetailsButton"
            color="primary"
            label={localized.details}
            styles={{
                dropdownButton: classes.bulkExecutionStepDetailsButton,
                dropdownPaper: classes.bulkExecutionStepDetailsDropdownPaper,
            }}
            closeOnClickInside={false}
            hasMenuList={false}
        >
            <Box className={classes.bulkExecutionStepDetailsButtonDropdownContainer}>
                <GroupedChipList groups={getGroups()} rowSize="medium" charType="number" />
            </Box>
        </OutlinedButtonDropdown>
    );
};
