import React from 'react';

import {BulkModalProvider} from './BulkModalProvider';
import {Modal} from './Modal';
import {ModalContent, ModalContext} from './types';

type ModalProviderProps = {
    children: JSX.Element | React.ReactNode;
};

const ModalProvider = ({children}: ModalProviderProps) => {
    const [isModalOpened, setIsModalOpened] = React.useState(false);
    const [modalContent, setModalContent] = React.useState(null as ModalContent);

    const openModal = (content: ModalContent) => {
        setModalContent(content);
        setIsModalOpened(true);
    };

    const closeModal = () => {
        setModalContent(null);
        setIsModalOpened(false);
    };

    return (
        <ModalContext.Provider value={{isModalOpened, modalContent, openModal, closeModal}}>
            <BulkModalProvider>
                {children}
                <Modal />
            </BulkModalProvider>
        </ModalContext.Provider>
    );
};

export {ModalProvider};
