import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {BoUser, BoUserServerFilterKeys} from '@models/bo-user';
import {ReasonCode, TransactionStatus} from '@models/generated/graphql';
import {TransactionViewModelKeys} from '@models/transaction';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {useAuthUser} from '@auth';
import {BaseFilterKeys, EntityType, TransactionFilterKeys, TransactionQueryFields} from '@redux/entity';
import {useViewInit} from '@redux/view';
import {maxPageSize} from '@services/types';
import {getFilterString} from '@utils';

import {localizedTransactionDataGridHeaders} from '../block-transaction-list';
import {gridUserProfileLocalizedHeaders} from '../block-user-profile-list';
import {useAsyncActionState} from '../shared/async-action/hooks';

import {transactionActions} from './actions';
import {ActionType} from './types';

type UseChangeWithdrawalStatus = {
    isApproveDisabled: boolean;
    isOnHoldDisabled: boolean;
    isDeclineDisabled: boolean;
    handleChangeStatus: (actionType: ActionType, reason?: ReasonCode) => void;
};

export function useChangeWithdrawalStatus(
    transactionId: string,
    transactionStatus: TransactionStatus,
    isRiskStatus: boolean,
    uid: string
): UseChangeWithdrawalStatus {
    const dispatch = useDispatch();

    const {items: boUsers} = useViewInit<BoUser, BoUserServerFilterKeys, string>({
        viewType: 'WithdrawalStatusButtons',
        displayName: null,
        entity: {
            entity: EntityType.BoUser,
            fields: ['id', 'firstName', 'lastName'],
        },
        validateFilter: () => true,
    });
    const userData = useAuthUser();
    const user = boUsers?.find(i => i.id === userData.sub);
    const boUserName = `${user?.firstName} ${user?.lastName}`;

    const {isProgress: isPaymentStatusChangeInProgress} = useAsyncActionState(transactionActions.setWithdrawalPaymentStatus);
    const {isProgress: isRiskStatusChangeInProgress} = useAsyncActionState(transactionActions.setWithdrawalRiskStatus);
    const isStatusChangeInProgress = isPaymentStatusChangeInProgress || isRiskStatusChangeInProgress;

    const areApproveAndDeclineEnabled = [
        TransactionStatus.Pending,
        TransactionStatus.OnHoldRisk,
        TransactionStatus.RiskAuthorized,
        TransactionStatus.OnHoldPayment,
    ].includes(transactionStatus);
    const isOnHoldEnabled = [TransactionStatus.Pending, TransactionStatus.RiskAuthorized].includes(transactionStatus);

    function changeRiskStatus(actionType: ActionType, reason?: ReasonCode): void {
        const actionTypeToStatusMapper: Record<ActionType, TransactionStatus> = {
            Approve: TransactionStatus.RiskAuthorized,
            Decline: TransactionStatus.Rejected,
            OnHold: TransactionStatus.OnHoldRisk,
        };
        dispatch(
            transactionActions.setWithdrawalRiskStatus.request({
                transactionId,
                status: actionTypeToStatusMapper[actionType],
                reason,
                userId: uid,
                adminId: user.id,
                adminName: boUserName,
            })
        );
    }

    function changePaymentStatus(actionType: ActionType, reason?: ReasonCode): void {
        const actionTypeToStatusMapper: Record<ActionType, TransactionStatus> = {
            Approve: TransactionStatus.InProgress,
            Decline: TransactionStatus.Rejected,
            OnHold: TransactionStatus.OnHoldPayment,
        };
        dispatch(
            transactionActions.setWithdrawalPaymentStatus.request({
                transactionId,
                status: actionTypeToStatusMapper[actionType],
                reason,
                userId: uid,
                adminId: user.id,
                adminName: boUserName,
            })
        );
    }

    function handleChangeStatus(actionType: ActionType, reason?: ReasonCode): void {
        return isRiskStatus ? changeRiskStatus(actionType, reason) : changePaymentStatus(actionType, reason);
    }

    return {
        isApproveDisabled: !areApproveAndDeclineEnabled || isStatusChangeInProgress,
        isOnHoldDisabled: !isOnHoldEnabled || isStatusChangeInProgress,
        isDeclineDisabled: !areApproveAndDeclineEnabled || isStatusChangeInProgress,
        handleChangeStatus,
    };
}

type UseCashierLinkHandler = {
    handleOpenCashier: (transactionId: string) => void;
};

export function useCashierLinkHandler(): UseCashierLinkHandler {
    const dispatch = useDispatch();

    function handleOpenCashier(transactionId: string) {
        dispatch(transactionActions.openCashier.request({transactionId}));
    }

    return {handleOpenCashier};
}

export type UseTransactionDownloadButtonProps = {
    filterString: string;
    keys: TransactionViewModelKeys[];
    userProfileKeys?: UserProfileViewModelKeys[];
    filename: string;
    showTotal?: boolean;
};

type UseDownloadTransactionResult = {handleClick: () => void; isProgress: boolean};

export function useDownloadTransaction({
    filterString,
    keys,
    userProfileKeys,
    filename,
    showTotal = false,
}: UseTransactionDownloadButtonProps): UseDownloadTransactionResult {
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const mapper = useAutoMapper();
    const {isProgress} = useAsyncActionState(transactionActions.transactionDownloadCsv);

    const pageFilterKey: BaseFilterKeys = 'page';
    const sizeFilterKey: BaseFilterKeys = 'size';
    const showTotalKey: TransactionFilterKeys = 'show_total_amount';
    const filter = getFilterString(
        filterString,
        true,
        {key: pageFilterKey, value: '1'},
        {key: sizeFilterKey, value: `${maxPageSize}`},
        {key: showTotalKey, value: showTotal ? true : null}
    );
    const fields = mapper.map<TransactionViewModelKeys[], TransactionQueryFields[]>(
        keys,
        nameof<TransactionViewModelKeys>(),
        nameof<TransactionQueryFields>()
    );

    const transactionHeader: Record<TransactionViewModelKeys, string> = keys?.reduce<Record<TransactionViewModelKeys, string>>(
        (previousValue, key) => {
            previousValue[key] = formatMessage(localizedTransactionDataGridHeaders[key]);
            return previousValue;
        },
        {} as Record<TransactionViewModelKeys, string>
    );
    const userProfileHeader: Record<UserProfileViewModelKeys, string> = userProfileKeys?.reduce<Record<UserProfileViewModelKeys, string>>(
        (previousValue, key) => {
            previousValue[key] = formatMessage(gridUserProfileLocalizedHeaders[key]);
            return previousValue;
        },
        {} as Record<UserProfileViewModelKeys, string>
    );

    const handleClick = () => {
        dispatch(
            transactionActions.transactionDownloadCsv.request({
                fields: showTotal ? [...fields, 'total_amount'] : fields,
                worksheetKeys: keys,
                userWorksheetKeys: userProfileKeys,
                filter,
                filename,
                headers: {...transactionHeader, ...userProfileHeader},
            })
        );
    };

    return {isProgress, handleClick};
}

type UseRedeemRevenueShareProps = {
    uid: string;
    amount: number;
};

type UseRedeemRevenueShareResult = {
    handleRedeem: () => void;
    isProgress: boolean;
};

export function useRedeemRevenueShare({amount, uid}: UseRedeemRevenueShareProps): UseRedeemRevenueShareResult {
    const dispatch = useDispatch();
    const {isProgress} = useAsyncActionState(transactionActions.claimRevenueShare);

    function redeemRevenueShare(): void {
        dispatch(transactionActions.claimRevenueShare.request({amount, uid}));
    }

    return {handleRedeem: redeemRevenueShare, isProgress};
}
