import React from 'react';

import {FilterGroup} from '@components/filter/FilterGroup';
import {ViewType} from '@redux/view';

import {Filter} from 'src/common/types';
import {AgentFilter} from 'src/features/block-agent-profile';

import {UserProfileCountryFilter} from './UserProfileCountryFilter';
import UserProfileFilterAccountStatus from './UserProfileFilterAccountStatus';
import {UidEmailUsernameMarketingCodeFilterText, UserProfileFilterText} from './UserProfileFilterText';

export type UserProfileFilterType =
    | 'uid'
    | 'email'
    | 'username'
    | 'nickname'
    | 'firstName'
    | 'lastName'
    | 'labels'
    | 'nationalId'
    | 'marketingCode'
    | 'referrerId'
    | 'security_cases'
    | 'phone'
    | 'networks_PKW'
    | 'networks_BL'
    | 'accountStatus'
    | 'uidEmailUsernameMarketingCode'
    | 'uidNamesEmailReferrerText'
    | 'uidNamesEmailReferrerLabelCasesText'
    | 'agent'
    | 'registrationCountry';

export type UserProfileFilterProps = {
    viewType?: ViewType;
    domain?: string;
    filter: string;
    value?: unknown;
    onFilterChange: (newFilter: Filter[]) => void;
    filterKey?: UserProfileFilterType;
};

type UserProfileFiltersProps = UserProfileFilterProps & {
    filters: UserProfileFilterType[];
    mode?: 'default' | 'multi';
};

/**
 * @deprecated
 * <p>Should be removed. Use {@link UserProfileQueryFilters}</p>
 */
export function UserProfileFiltersObsolete({filters, mode, ...props}: UserProfileFiltersProps): JSX.Element {
    const filterMapping: Record<UserProfileFilterType, React.ComponentType<UserProfileFilterProps>> = {
        uid: UserProfileFilterText,
        email: UserProfileFilterText,
        username: UserProfileFilterText,
        nickname: UserProfileFilterText,
        firstName: UserProfileFilterText,
        lastName: UserProfileFilterText,
        labels: UserProfileFilterText,
        nationalId: UserProfileFilterText,
        marketingCode: UserProfileFilterText,
        referrerId: UserProfileFilterText,
        security_cases: UserProfileFilterText,
        phone: UserProfileFilterText,
        networks_PKW: UserProfileFilterText,
        networks_BL: UserProfileFilterText,
        accountStatus: UserProfileFilterAccountStatus,
        uidEmailUsernameMarketingCode: UidEmailUsernameMarketingCodeFilterText,
        uidNamesEmailReferrerText: UserProfileFilterText,
        uidNamesEmailReferrerLabelCasesText: UserProfileFilterText,
        agent: AgentFilter,
        registrationCountry: UserProfileCountryFilter,
    };

    return mode === 'multi' ? (
        <FilterGroup<Record<UserProfileFilterType, string | string[]>, UserProfileFilterProps>
            keys={filters}
            filterMapping={filterMapping}
            {...props}
        />
    ) : (
        <>
            {filters?.map(type => {
                const FilterComponent = filterMapping[type];
                return <FilterComponent key={type} {...props} />;
            })}
        </>
    );
}
