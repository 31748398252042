import React from 'react';
import {Box} from '@mui/material';
import {hasFlag} from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CountryInfo} from '@models/generated/graphql';

import {withEmptyCheck} from '../label/EmptyLabel';

type CountryFlagProps = {
    value: CountryInfo;
};

const useClasses = makeStyles()(theme => ({
    countryFlagContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
    },
    flagIcon: {
        width: '1em',
        height: '1em',
        display: 'inline-block',
    },
}));

const CountryFlag = withEmptyCheck(({value}: CountryFlagProps) => {
    const {classes} = useClasses();
    const Flag = Flags[value?.iso_alpha2_code as keyof typeof Flags];

    return (
        <Box className={classes.countryFlagContainer}>
            {hasFlag(value?.iso_alpha2_code) ? <Flag className={classes.flagIcon} /> : null}
            <Box>
                <LocalizedText label={value?.name} />
            </Box>
        </Box>
    );
});

export default CountryFlag;
