import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {FormSelect} from '@components/input';

import {SelectOption} from '../../module-shared/types';
import {RelationalOperator} from '../types';

import {RuleFormConditionProps, withRuleFormCondition} from './RuleFormConditionHoc';

const localized = defineMessages({
    defaultTrueOptionLabel: {
        id: 'RuleFormConditionSelect_defaultTrueOptionLabel',
        defaultMessage: 'Yes',
    },
    defaultFalseOptionLabel: {
        id: 'RuleFormConditionSelect_defaultFalseOptionLabel',
        defaultMessage: 'No',
    },
});

const useStyles = makeStyles()(theme => ({
    ruleFormConditionSelect: {
        marginBottom: 0,
        '& .MuiInputBase-root': {
            width: '100%',
            maxWidth: 'unset',
            gap: theme.spacing(2),
            overflow: 'hidden',
            paddingLeft: '0 !important',
        },
    },
    ruleFormConditionSelectDropdown: {
        minWidth: '150px !important',
        marginLeft: 0,
    },
}));

const RuleConditionSelectInternal = withRuleFormCondition(FormSelect);
type RuleConditionSelectProps = Omit<RuleFormConditionProps, 'operator'> & {
    options?: SelectOption[];
    operator?: RelationalOperator;
};

export function RuleFormConditionSelect(props: RuleConditionSelectProps) {
    const {classes} = useStyles();
    const defaultSelectConditionOptions: SelectOption[] = [
        {
            value: true,
            label: localized.defaultTrueOptionLabel,
        },
        {
            value: false,
            label: localized.defaultFalseOptionLabel,
        },
    ];
    return (
        <RuleConditionSelectInternal
            alignLeft
            dropdownClassName={classes.ruleFormConditionSelectDropdown}
            className={classes.ruleFormConditionSelect}
            options={defaultSelectConditionOptions}
            operator="=="
            {...props}
        />
    );
}
