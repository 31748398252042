import {createAction, createAsyncAction} from 'typesafe-actions';

import {IModuleGridItem} from '@components/data-grid';

import {ItemsPage, Paging, Sorting} from 'src/common/types';
import {filterActions} from '../shared/filter/actions';

import {FormModuleData, IFailureResponse, IModuleItem} from './types';

const contentLoadAction = '/contentLoad';
export const contentLoadSucceedAction = '/contentLoadSucceed';
const contentLoadFailedAction = '/contentLoadFailed';
export const contentUpdateItemAction = '/contentUpdateItem';
const contentUpdateRequiredAction = '/contentUpdateRequired';
export const contentClearAction = '/contentClearAction';
export const contentPushNewAction = '/contentPushNewItem';

const itemSaveAction = '/itemSave';
const itemSaveSucceedAction = '/itemSaveSucceed';
const itemSaveFailedAction = '/itemSaveFailed';

const itemsSorted = '/itemsSorted';
const itemsPagingChanged = '/itemsPagingChanged';
const itemsSelected = '/itemsSelected';

const itemsBatch = '/itemsBatch';

const itemLoadAction = '/itemLoad';
const itemLoadActionSucceed = '/itemLoadSucceed';
const itemLoadActionFailed = '/itemLoadFailed';

const changeMode = '/changeMode';

const init = '/init';

const setColumns = '/setColumns';

export const initActions = (domain: string) => ({
    init: createAction(`${domain}${init}`)<void>(),
});

export const pagingActions = (domain: string) => ({
    itemsPagingChanged: createAction(`${domain}${itemsPagingChanged}`)<Paging>(),
});

export const contentActions = <TModel extends IModuleItem | ItemsPage<IModuleGridItem>>(domain: string) => ({
    contentLoad: createAsyncAction(
        `${domain}${contentLoadAction}`,
        `${domain}${contentLoadSucceedAction}`,
        `${domain}${contentLoadFailedAction}`
    )<void, TModel, IFailureResponse>(),

    contentUpdateRequired: createAction(`${domain}${contentUpdateRequiredAction}`)<void>(),

    contentUpdateItem: createAction(`${domain}${contentUpdateItemAction}`)<IModuleGridItem>(),

    contentPushNewItem: createAction(`${domain}${contentPushNewAction}`)<IModuleGridItem>(),

    contentClear: createAction(`${domain}${contentClearAction}`)<void>(),
});

export const serverSortingActions = (domain: string) => ({
    itemsSorted: createAction(`${domain}${itemsSorted}`)<Sorting<string>[]>(),
});

export const itemsSelectedActions = (domain: string) => ({
    itemsSelected: createAction(`${domain}${itemsSelected}`)<IModuleGridItem[]>(),
});

export const columnConfigurationActions = (domain: string) => ({
    setColumns: createAction(`${domain}${setColumns}`)<string[]>(),
});

export const gridModuleActions = (domain: string) => ({
    ...initActions(domain),
    ...pagingActions(domain),
    ...filterActions(domain),
    ...serverSortingActions(domain),
    ...itemsSelectedActions(domain),
    ...contentActions<ItemsPage<IModuleGridItem>>(domain),
    ...columnConfigurationActions(domain),

    itemsBatch: createAction(`${domain}${itemsBatch}`)<string>(),

    itemLoad: createAsyncAction(`${domain}${itemLoadAction}`, `${domain}${itemLoadActionSucceed}`, `${domain}${itemLoadActionFailed}`)<
        string,
        IModuleItem,
        IFailureResponse
    >(),
});

export const contentModuleActions = (domain: string) => ({
    ...initActions(domain),
    ...filterActions(domain),
    ...contentActions<IModuleItem>(domain),

    itemSave: createAsyncAction(`${domain}${itemSaveAction}`, `${domain}${itemSaveSucceedAction}`, `${domain}${itemSaveFailedAction}`)<
        FormModuleData<IModuleItem>,
        FormModuleData<IModuleItem>,
        IFailureResponse
    >(),

    changeMode: createAction(`${domain}${changeMode}`)<void>(),
});
