import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {SecurityPunishmentStatus, UserAccountStatus} from '@models/generated/graphql';

import {localizedSecurityPunishmentStatus} from '../../app/intl/shared-resources/securityPunishmentStatus';

export type BlockedUserValue = {
    account_status: UserAccountStatus;
    punishment_status: SecurityPunishmentStatus;
};

export const useBlockedUserFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: BlockedUserValue = JSON.parse(json);

    return value?.punishment_status ? [{key: formatMessage(localizedSecurityPunishmentStatus[value.punishment_status])}] : null;
};
