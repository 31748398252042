import {Epic, StateObservable} from 'redux-observable';
import {merge, Observable, of} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import {catchError} from 'rxjs/operators';
import {PayloadAction} from 'typesafe-actions';

import {errorActions} from './action';
import {ErrorPayload} from './type';

class ProtectEpicCreator {
    createEpic<Input extends PayloadAction<string, unknown>, Output extends Input, State>(
        epic: Epic<Input, Output, State>
    ): Epic<Input, Output, State> {
        return (action$: Observable<Input>, state$: StateObservable<State>, dependencies: unknown): Observable<Output> =>
            epic(action$, state$, dependencies).pipe(
                catchError((err: Error, source) => {
                    let handledError: ErrorPayload = null;
                    if (err instanceof AjaxError) {
                        handledError = {error: err, type: 'ajax', code: err.status};
                    } else {
                        handledError = {error: err, type: 'middleware'};
                    }

                    const actions: PayloadAction<string, unknown>[] = [errorActions.error(handledError)];

                    return merge(source, ...actions.map(action => of(action as Output)));
                })
            );
    }
}

export {ProtectEpicCreator};
