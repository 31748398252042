import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, RootAction, RootState} from 'typesafe-actions';

import {map, mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {BoRole} from '@redux/entity';

import {requestEpic} from 'src/common/epics';
import {protectEpics} from '../../features/app/error-handling/epics';
import {successMessages} from '../../features/app/intl/shared-resources/serverResponse';
import {showMessageAction} from '../../features/message-snack-bar/actions';
import {createGridEpics} from '../../features/module-shared/epics';
import {loadModulesAction} from '../../features/modules/actions';

import actions from './actions';
import {loadModulesWithDetailsAction} from './actions';
import {filterSelector} from './selectors';
import {domain, RoleGridItem} from './types';

const rolesEpics = createGridEpics<BoRole, RoleGridItem>(domain, c => c.rolesService, filterSelector);

const itemAddEpic: RootEpic = requestEpic(actions.saveRole, (payload, _, container) => {
    const service = container.rolesService;
    return payload.isNew ? service.addItem(payload.item as BoRole) : service.editItem(payload.item as BoRole);
});

const itemAddedEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(actions.saveRole.success)),
        mergeMap(() =>
            of(
                showMessageAction({message: successMessages.operationSuccessfullyCompleted}),
                actions.closeRoleForm(),
                actions.contentUpdateRequired()
            )
        )
    );

const itemEditLoadedEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(actions.itemLoad.success)),
        map(action => actions.openRoleForm(action.payload as BoRole))
    );

const rolesChangedEpic: Epic<RootAction, any, RootState> = action$ =>
    action$.pipe(
        filter(isActionOf(actions.contentUpdateRequired)),
        mergeMap(() => of(loadModulesAction.request()))
    );

const loadModulesEpic: RootEpic = requestEpic(loadModulesWithDetailsAction, (payload, _state, container) =>
    container.rolesService.loadModules(payload)
);

export default protectEpics(rolesEpics, rolesChangedEpic, loadModulesEpic, itemAddEpic, itemAddedEpic, itemEditLoadedEpic);
