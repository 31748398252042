import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {UserManager} from 'oidc-client-ts';
import {from, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {PlayerMessage, PlayerMessageFilterInput, PlayerMessageInput, PlayerMessageSource, Query} from '@models/generated/graphql';
import {ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlSearchFilter} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';
import {getUTCTimestamp, toGQLDateFilter, toGQLStringFilter, toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';
import {IRealtimeGridReadService} from '../../realtime-updates/services/IRealtimeGridReadService';
import {sendMessageMutation} from '../mutations';
import {mailTypeMapping, Message, PlayerMessageColumnsConfiguration, PlayerMessageGridItem, PlayerMessageItem} from '../types';

const getPlayerMessagesQuery = (configuration: PlayerMessageColumnsConfiguration) => gql`
    query getPlayerMessages($filter: PlayerMessageFilterInput, $sort: Sorting, $start: Int, $end: Int) {
        getPlayerMessages(filter: $filter, sort: $sort, end: $end, start: $start)
        {
            items {
                id ${configuration.withId ? '' : '@client'}
                mail_type ${configuration.withMailType ? '' : '@client'}
                content ${configuration.withContent ? '' : '@client'}
                subject ${configuration.withSubject ? '' : '@client'}
                title ${configuration.withTitle ? '' : '@client'}
                signature ${configuration.withSignature ? '' : '@client'}
                sender ${configuration.withSender ? '' : '@client'}
                uid ${configuration.withUid ? '' : '@client'}
                sender_id ${configuration.withSenderId ? '' : '@client'}
                source ${configuration.withSource ? '' : '@client'}
                sent_at ${configuration.withSentAt ? '' : '@client'} {
                    seconds
                }
            }
            total_count
        }
    }
`;

export class PlayerMessageService
    extends ReadonlyRealtimeGridServiceBase<PlayerMessageItem, PlayerMessageGridItem, PlayerMessage>
    implements IRealtimeGridReadService<string>
{
    private readonly _userManager: UserManager;

    constructor(client: ApolloClientProxy<NormalizedCacheObject>, userManager: UserManager) {
        super(client);
        this._userManager = userManager;
    }

    getItem(): Observable<PlayerMessageItem> {
        throw new Error('Method not implemented.');
    }

    sendMessage(playerId: string, message: Message): Observable<unknown> {
        return from(this._userManager.getUser()).pipe(
            mergeMap(user => {
                const playerMessage: PlayerMessageInput = {
                    mail_type: mailTypeMapping[message.mailType],
                    content: message.content,
                    sender: message.sender,
                    sender_id: user?.profile?.sub,
                    signature: message.signature,
                    title: message.title,
                    subject: message.subject,
                    uid: playerId,
                    sent_at: getUTCTimestamp(),
                    source: PlayerMessageSource.Bo,
                };
                return this._client.executeMutation(sendMessageMutation, {uid: playerId, message: playerMessage});
            })
        );
    }

    getItemsIds(filter?: SearchFilter): Observable<string[]> {
        const configuration = this.getGQLConfiguration(new PlayerMessageColumnsConfiguration(), [
            nameof<PlayerMessageColumnsConfiguration>(c => c.withId),
        ]);

        return this._client
            .executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter))
            .pipe(map<Query, string[]>(res => res?.getPlayerMessages?.items?.map(i => i?.id) ?? []));
    }

    getItems(): Observable<PlayerMessageGridItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsPage(filter?: SearchFilter, itemFields?: string[]): Observable<ItemsPage<PlayerMessageGridItem>> {
        const configuration = this.getGQLConfiguration(new PlayerMessageColumnsConfiguration(), itemFields);

        return this._client.executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter)).pipe(
            map<Query, ItemsPage<PlayerMessageGridItem>>(res => {
                return {
                    items:
                        res?.getPlayerMessages?.items?.map(i => this.mapModels({id: i?.id, serverId: i?.id} as PlayerMessageGridItem, i)) ??
                        [],

                    total: res?.getPlayerMessages?.total_count ?? 0,
                };
            })
        );
    }

    protected getItemsPageQuery(configuration: PlayerMessageColumnsConfiguration): DocumentNode {
        return getPlayerMessagesQuery(configuration);
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        const filter = {
            mail_type: toGQLStringFilter(
                filters,
                nameof<PlayerMessageFilterInput>(m => m.mail_type)
            ),
            source: toGQLStringFilter(
                filters,
                nameof<PlayerMessageFilterInput>(m => m.source)
            ),
            text: [
                toGQLTextFilter(
                    filters,
                    nameof.toArray<PlayerMessage>(m => [m.uid]),
                    nameof<PlayerMessage>(m => m.uid)
                ),
            ],
        } as PlayerMessageFilterInput;

        const dateFilter = toGQLDateFilter(
            filters,
            nameof<PlayerMessageFilterInput>(m => m.sent_at)
        );

        if (dateFilter) {
            filter.sent_at = dateFilter;
        }

        return filter;
    }
}
