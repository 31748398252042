import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {ChipWithTooltip} from '@components/chip/Chips';
import {ChipType} from '@components/chip/types';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {usePlayerLabelUnassign} from '../../block-label-group-actions/hooks';
import {Label, LabelGroupSourceType} from '../../block-label-group-list/types';

const localized = defineMessages({
    blockPlayerActionLabelTooltip: {
        id: 'blockPlayerActionLabelTooltip',
        defaultMessage: '{label} of {group}',
    },
});

type BlockPlayerLabelProps = {
    label: Label;
    uid?: string;
    disabled?: boolean;
    size: 'small' | 'medium';
};

export function BlockPlayerLabelInternal({label, uid, disabled, size}: BlockPlayerLabelProps) {
    const {formatMessage} = useIntl();

    const {handleUnassign} = usePlayerLabelUnassign();
    const isSystemSource = label.group.source_type === LabelGroupSourceType.System;

    return (
        <ChipWithTooltip
            key={label.id}
            chipType={ChipType.Status}
            chipVariant={label.group?.color}
            label={label.name}
            fillWidth={false}
            size={size}
            onDelete={disabled || isSystemSource ? null : () => handleUnassign(uid, label)}
            title={formatMessage(localized.blockPlayerActionLabelTooltip, {label: label.name, group: label.group?.name})}
            hideTooltip={false}
            onClick={e => e.stopPropagation()}
        />
    );
}

export const blockPlayerLabelRemovePermissions = [
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesSecurity, permissions: [PermissionEnum.Delete]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.Payment, permissions: [PermissionEnum.Delete]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.KYC, permissions: [PermissionEnum.Delete]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesCustomerSupport, permissions: [PermissionEnum.Delete]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesPlayer, permissions: [PermissionEnum.Delete]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesBotDetection, permissions: [PermissionEnum.Delete]},
];

export const BlockPlayerLabel = withMultiplePermission(BlockPlayerLabelInternal, {
    allowedPermissions: blockPlayerLabelRemovePermissions,
    indicator: LackOfPermissionIndicator.Disabled,
});
