import React, {FC, memo} from 'react';
import {defineMessages, FormattedNumber} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import {CurrencyColumnValue} from '@components/data-grid/types';
import {LocalizedText} from '@components/i18n';
import {EmptyLabel, withEmptyCheck} from '@components/label';
import {MultiLineAutoAdjustedWidthTooltip} from '@components/tooltip/MultiLineAutoAdjustedWidthTooltip';

import {renderValueWithTooltip} from './GridCellWithTooltip';

export enum Currency {
    CasinoCoin = 'CasinoCoin',
}

const currencyLocalized = defineMessages<Currency>({
    [Currency.CasinoCoin]: {
        id: 'casinoCoinCurrency',
        defaultMessage: 'C Coin',
    },
});

type CurrencyCellProps = {
    value: number;
    currency: string;
    isNet?: boolean;
};

export const CurrencyCellFormatter = memo(({currency, isNet, ...props}: CurrencyCellProps) => {
    const value = props?.value ?? 0;
    return currency === Currency.CasinoCoin ? (
        <>
            {value?.toString() + ' '}
            <LocalizedText label={currencyLocalized.CasinoCoin} />
        </>
    ) : (
        <>
            {isNet && value > 0 ? '+' : null}
            <FormattedNumber value={value} style="currency" currency={currency} />
        </>
    );
});

export const CurrencyCell: FC<CurrencyCellProps> = withEmptyCheck((props: CurrencyCellProps) => {
    return <CurrencyCellFormatter {...props} />;
});

/**
 * @deprecated Should be removed. Use {@link renderMuiGridCurrencyCell}
 * */
export const renderCurrencyCell = (params: GridCellParams, currency: string) => {
    const value = params.value;
    return <CurrencyCell value={value as number} currency={currency}></CurrencyCell>;
};

/**
 * @deprecated Should be removed. Use {@link renderMuiGridCurrencyCell}
 * */
export const renderGridCurrencyWithTooltip = (params: GridCellParams, currency: string) => {
    const value = params.value as number;
    return renderValueWithTooltip(<CurrencyCellFormatter value={value} currency={currency} />);
};

export type AmountCurrencyCellProps = {
    amount: number;
    currency: string;
};

/**
 * @deprecated Should be removed. Use {@link renderMuiGridCurrencyCell}
 * */
export const renderAmountCurrencyCellWithTooltip = (params: GridCellParams) => {
    const value: AmountCurrencyCellProps = params.value as AmountCurrencyCellProps;
    return renderValueWithTooltip(<CurrencyCell value={value?.amount} currency={value?.currency} />);
};

export function renderMuiGridCurrencyCell(params: GridCellParams) {
    const {amount, currency} = params.value as CurrencyColumnValue;

    return currency ? (
        <MultiLineAutoAdjustedWidthTooltip linesCount={1}>
            <CurrencyCellFormatter value={amount} currency={currency} />
        </MultiLineAutoAdjustedWidthTooltip>
    ) : (
        <>{amount}</>
    );
}

export function renderCurrencyCellWithEmptyCheck(params: GridCellParams) {
    const {amount} = params.value as CurrencyColumnValue;
    const isEmpty = amount === null || amount === undefined;
    const Component = renderMuiGridCurrencyCell;
    return isEmpty ? <EmptyLabel defaultSymbol="dash" /> : <Component {...params} />;
}
