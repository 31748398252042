import React, {useEffect, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {MemoizedDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {DropdownType} from '@components/dropdown/DropdownHoc';
import {DropdownItem} from '@components/dropdown/DropdownItem';
import LocalizedText from '@components/i18n/LocalizedText';
import {SelectButton} from '@components/select';
import {CustomTheme} from '@style';

const localizedBulkLockItem = defineMessages({
    itemsLocked: {
        id: 'BulkLockItem_itemsLocked',
        defaultMessage: '{value} Locked',
    },
    itemsUnlocked: {
        id: 'BulkLockItem_itemsUnlocked',
        defaultMessage: '{value} Unlocked',
    },
    lockAll: {
        id: 'BulkLockItem_lockAll',
        defaultMessage: 'Lock All',
    },
    unlockAll: {
        id: 'BulkLockItem_unlockAll',
        defaultMessage: 'Unlock All',
    },
    noChanges: {
        id: 'BulkLockItem_noChanges',
        defaultMessage: 'No Changes',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    bulkApplyStepLockItemContainer: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(7.5),
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    bulkApplyStepLockItemLabel: {
        display: 'flex',
        width: theme.spacing(20),
    },
    bulkApplyStepLockItemSelectContainer: {
        display: 'flex',
        marginLeft: 'auto',
        position: 'relative',
        overflow: 'visible !important',
        padding: '0 !important',
    },
    bulkApplyStepLockItemSelect: {
        minWidth: 150,
        padding: theme.spacing(1, 1.5),
        justifyContent: 'space-between',
    },
    bulkApplyStepLockItemSelectInactive: {
        backgroundColor: theme.custom.palette.content.bulkActionSelectBackground,
        border: 'none',
        '&:active:not([disabled]), &:focus:not([disabled]), &:hover:not([disabled])': {
            backgroundColor: theme.custom.palette.content.bulkActionSelectBackground,
            border: 'none',
        },
    },
    bulkApplyStepLockItemSelectOpen: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.custom.palette.content.border}`,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    bulkApplyStepLockItemSelectOption: {
        '&:active:not([disabled]), &:focus:not([disabled]), &:hover:not([disabled]), &.Mui-selected': {
            backgroundColor: theme.palette.common.white,
            color: theme.custom.palette.status.dropdownDefault.hoverLight,
        },
    },
    bulkApplyStepLockItemCountArea: {
        display: 'flex',
        columnGap: theme.spacing(0.75),
    },
}));

export enum BulkLockItemValue {
    NoChanges = 'NoChanges',
    Checked = 'Checked',
    Unchecked = 'Unchecked',
}

type BulkLockItemModel = {
    value: BulkLockItemValue;
};

type BulkApplyStepLockTotalCountProps = {
    initialValues: boolean[];
};

function BulkApplyStepLockTotalCount({initialValues}: BulkApplyStepLockTotalCountProps) {
    const {classes} = useStyles();
    const {formatMessage} = useIntl();

    const totalCheckedItems = initialValues?.filter(i => i === true)?.length;
    const totalUncheckedItems = initialValues?.filter(i => i !== true)?.length;

    return (
        <Box className={classes.bulkApplyStepLockItemCountArea}>
            <MemoizedDefaultChip
                value={formatMessage(localizedBulkLockItem.itemsLocked, {value: totalCheckedItems})}
                variant={ChipVariant.Disabled}
                type={ChipType.StatusV2}
            />
            <MemoizedDefaultChip
                value={formatMessage(localizedBulkLockItem.itemsUnlocked, {value: totalUncheckedItems})}
                variant={ChipVariant.Disabled}
                type={ChipType.StatusV2}
            />
        </Box>
    );
}

type BulkApplyStepLockItemProps = {
    label: MessageDescriptor;
    initialValues: boolean[];
    resultValue: boolean;
    onSubmit: (value: BulkLockItemValue) => void;
};

export function BulkChooseActionStepLockItem({label, initialValues, resultValue, onSubmit}: BulkApplyStepLockItemProps) {
    const {classes} = useStyles();
    const containerRef = useRef<HTMLDivElement>(null);

    const defaultValue =
        resultValue === undefined || resultValue === null
            ? BulkLockItemValue.NoChanges
            : resultValue
            ? BulkLockItemValue.Checked
            : BulkLockItemValue.Unchecked;
    const {control, reset} = useForm<BulkLockItemModel>({defaultValues: {value: defaultValue}});

    useEffect(() => {
        reset({value: defaultValue});
    }, [JSON.stringify(initialValues)]);

    const allOptions = [
        {value: BulkLockItemValue.Checked, label: localizedBulkLockItem.lockAll},
        {value: BulkLockItemValue.Unchecked, label: localizedBulkLockItem.unlockAll},
        {value: BulkLockItemValue.NoChanges, label: localizedBulkLockItem.noChanges},
    ];

    return (
        <Box className={classes.bulkApplyStepLockItemContainer}>
            <Box className={classes.bulkApplyStepLockItemLabel}>
                <LocalizedText label={label}></LocalizedText>
            </Box>
            <BulkApplyStepLockTotalCount initialValues={initialValues} />
            <div className={classes.bulkApplyStepLockItemSelectContainer} ref={containerRef}>
                <Controller
                    render={({field}) => {
                        return (
                            <SelectButton
                                styles={{
                                    dropdownButton: `${classes.bulkApplyStepLockItemSelect} ${
                                        field.value === BulkLockItemValue.NoChanges ? classes.bulkApplyStepLockItemSelectInactive : ''
                                    }`,
                                    dropdownButtonOpen: classes.bulkApplyStepLockItemSelectOpen,
                                }}
                                selectedText={allOptions?.find(i => i.value === field.value)?.label}
                                container={containerRef.current}
                                dropdownType={DropdownType.Attached}
                                closeOnClickInside
                            >
                                {allOptions.map((option, index) => (
                                    <DropdownItem
                                        className={classes.bulkApplyStepLockItemSelectOption}
                                        key={index}
                                        onClick={() => {
                                            const submitValue = option.value;
                                            field.onChange(submitValue);
                                            onSubmit(submitValue);
                                        }}
                                        confirmIcon
                                        label={option.label}
                                        selected={field.value === option.value}
                                    />
                                ))}
                            </SelectButton>
                        );
                    }}
                    name="value"
                    control={control}
                />
            </div>
        </Box>
    );
}
