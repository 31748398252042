import {ActionType, createAction} from 'typesafe-actions';

import {AsyncActionErrorPayload, AsyncActionStatusPayload, domain} from './types';

const setRequestStatus = `${domain}/setRequestStatus`;
const setErrorMessage = `${domain}/setErrorMessage`;

export const asyncActions = {
    setRequestStatus: createAction(setRequestStatus)<AsyncActionStatusPayload>(),
    setErrorMessage: createAction(setErrorMessage)<AsyncActionErrorPayload>(),
};

export type AsyncActions = ActionType<typeof asyncActions>;
