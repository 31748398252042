import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {v4 as uuid} from 'uuid';

import {IMapper} from '@auto-mapper';
import {IFileFactory} from '@file/services/IFileFactory';
import {ServiceTypes} from '@inversify';
import {UserProfile} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {map} from '@otel';
import {
    EntityFetchRequestPayload,
    EntityType,
    Filter,
    TransactionFilterKeys,
    UserProfileQueryFields,
    UserProfileServerFilterKeys,
} from '@redux/entity';
import {mapViewFilterToString} from '@redux/view';
import {IEntityReadService} from '@services';

import {IBulkStrategy} from '../../block-bulk-actions';
import {BulkDebitCreditAddModel, P2PTransferCounterpartUser, ParseManualTransactionFileData} from '../types';

export type LoadManualTransactionsStrategyRequest = ParseManualTransactionFileData;

export type LoadManualTransactionsStrategyResponse = BulkDebitCreditAddModel[];

@injectable()
export class LoadManualTransactionsStrategy
    implements IBulkStrategy<LoadManualTransactionsStrategyRequest, LoadManualTransactionsStrategyResponse>
{
    private _templateFactory: IFileFactory<ParseManualTransactionFileData, BulkDebitCreditAddModel[]>;

    constructor(
        @inject(ServiceTypes.ManualTransactionExcelTemplateFactory)
        templateFactory: IFileFactory<ParseManualTransactionFileData, BulkDebitCreditAddModel[]>
    ) {
        this._templateFactory = templateFactory;
    }

    process(request: LoadManualTransactionsStrategyRequest): Observable<LoadManualTransactionsStrategyResponse> {
        return this._templateFactory.parseFile(request);
    }
}

export type LoadP2PTransferStrategyRequest = {
    uids: string[];
    keys: UserProfileViewModelKeys[];
};

export type LoadP2PTransferStrategyResponse = P2PTransferCounterpartUser[];

@injectable()
export class LoadP2PTransferStrategy implements IBulkStrategy<LoadP2PTransferStrategyRequest, LoadP2PTransferStrategyResponse> {
    private _userProfileService: IEntityReadService;
    private _mapper: IMapper;

    constructor(
        @inject(ServiceTypes.UserProfileService) userProfileService: IEntityReadService,
        @inject(ServiceTypes.AutoMapper) mapper: IMapper
    ) {
        this._userProfileService = userProfileService;
        this._mapper = mapper;
    }

    process({keys, uids}: LoadP2PTransferStrategyRequest): Observable<LoadP2PTransferStrategyResponse> {
        const fields: UserProfileQueryFields[] = this._mapper.map<UserProfileViewModelKeys[], UserProfileQueryFields[]>(
            keys,
            nameof<UserProfileViewModelKeys>(),
            nameof<UserProfileQueryFields>()
        );
        const filter: Filter<UserProfileServerFilterKeys> = {
            uid: uids.filter(u => u).join(' '),
            size: uids.length,
            page: 1,
        };
        const request: EntityFetchRequestPayload<UserProfileQueryFields> = {
            type: EntityType.UserProfile,
            fields,
            filter: mapViewFilterToString<TransactionFilterKeys>(filter, ['uid', 'page', 'size']),
        };

        return this._userProfileService.get(request).pipe(
            map(value =>
                (value?.responsePayload?.items as UserProfile[])?.map(i => {
                    const id = uuid();
                    return {...i, id, serverId: id};
                })
            )
        );
    }
}
