import {defineMessages} from 'react-intl';

import {AgentLevelReportViewModelKeys} from '../types';

export const localizedHeaders = defineMessages<AgentLevelReportViewModelKeys>({
    week_start_date_ts: {
        id: 'DataGridAgentLevelReport_weekStartDate',
        defaultMessage: 'Week Start Date',
    },
    referrer_id: {
        id: 'DataGridAgentLevelReport_agentId',
        defaultMessage: 'Agent ID',
    },
    referrer_username: {
        id: 'DataGridAgentLevelReport_agentUsername',
        defaultMessage: 'Agent Username',
    },
    poker_ngr: {
        id: 'DataGridAgentLevelReport_pokerNgr',
        defaultMessage: 'Poker NGR',
    },
    casino_ggr: {
        id: 'DataGridAgentLevelReport_casinoGgr',
        defaultMessage: 'Casino GGR',
    },
    internal_casino_ggr: {
        id: 'DataGridAgentLevelReport_internalCasinoGgr',
        defaultMessage: 'Internal Casino GGR',
    },
    external_casino_ggr: {
        id: 'DataGridAgentLevelReport_externalCasinoGgr',
        defaultMessage: 'External Casino GGR',
    },
    revenue_shared_total: {
        id: 'DataGridAgentLevelReport_revenueSharedTotal',
        defaultMessage: 'Revenue Shared Total',
    },
    referrer_psp_fees: {
        id: 'DataGridAgentLevelReport_referrerPspFees',
        defaultMessage: 'Referrer PSP Fees',
    },
    carry_over_negative_balance: {
        id: 'DataGridAgentLevelReport_carryOverNegativeBalance',
        defaultMessage: 'Carry Over Negative Balance',
    },
    adjusted_revenue_shared_total: {
        id: 'DataGridAgentLevelReport_adjustedRevenueSharedTotal',
        defaultMessage: 'Adjusted Revenue Shared Total',
    },
    tier_1_revenue_shared: {
        id: 'DataGridAgentLevelReport_tier1RevenueShared',
        defaultMessage: 'Tier 1 Revenue Shared',
    },
    tier_1_poker_revenue_share: {
        id: 'DataGridAgentLevelReport_tier1PokerRevenueShare',
        defaultMessage: 'Tier 1 Poker Revenue Share',
    },
    tier_1_casino_revenue_share: {
        id: 'DataGridAgentLevelReport_tier1CasinoRevenueShare',
        defaultMessage: 'Tier 1 Casino Revenue Share',
    },
    tier_1_generated_internal_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier1GeneratedInternalCasinoGgr',
        defaultMessage: 'Tier 1 Generated Internal Casino GGR',
    },
    tier_1_generated_external_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier1GeneratedExternalCasinoGgr',
        defaultMessage: 'Tier 1 Generated External Casino GGR',
    },
    tier_2_revenue_shared: {
        id: 'DataGridAgentLevelReport_tier2RevenueShared',
        defaultMessage: 'Tier 2 Revenue Shared',
    },
    tier_2_poker_revenue_share: {
        id: 'DataGridAgentLevelReport_tier2PokerRevenueShare',
        defaultMessage: 'Tier 2 Poker Revenue Share',
    },
    tier_2_casino_revenue_share: {
        id: 'DataGridAgentLevelReport_tier2CasinoRevenueShare',
        defaultMessage: 'Tier 2 Casino Revenue Share',
    },
    tier_2_generated_internal_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier2GeneratedInternalCasinoGgr',
        defaultMessage: 'Tier 2 Generated Internal Casino GGR',
    },
    tier_2_generated_external_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier2GeneratedExternalCasinoGgr',
        defaultMessage: 'Tier 2 Generated External Casino GGR',
    },
    tier_3_revenue_shared: {
        id: 'DataGridAgentLevelReport_tier3RevenueShared',
        defaultMessage: 'Tier 3 Revenue Shared',
    },
    tier_3_poker_revenue_share: {
        id: 'DataGridAgentLevelReport_tier3PokerRevenueShare',
        defaultMessage: 'Tier 3 Poker Revenue Share',
    },
    tier_3_casino_revenue_share: {
        id: 'DataGridAgentLevelReport_tier3CasinoRevenueShare',
        defaultMessage: 'Tier 3 Casino Revenue Share',
    },
    tier_3_generated_internal_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier3GeneratedInternalCasinoGgr',
        defaultMessage: 'Tier 3 Generated Internal Casino GGR',
    },
    tier_3_generated_external_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier3GeneratedExternalCasinoGgr',
        defaultMessage: 'Tier 3 Generated External Casino GGR',
    },
    tier_4_revenue_shared: {
        id: 'DataGridAgentLevelReport_tier4RevenueShared',
        defaultMessage: 'Tier 4 Revenue Shared',
    },
    tier_4_poker_revenue_share: {
        id: 'DataGridAgentLevelReport_tier4PokerRevenueShare',
        defaultMessage: 'Tier 4 Poker Revenue Share',
    },
    tier_4_casino_revenue_share: {
        id: 'DataGridAgentLevelReport_tier4CasinoRevenueShare',
        defaultMessage: 'Tier 4 Casino Revenue Share',
    },
    tier_4_generated_internal_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier4GeneratedInternalCasinoGgr',
        defaultMessage: 'Tier 4 Generated Internal Casino GGR',
    },
    tier_4_generated_external_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier4GeneratedExternalCasinoGgr',
        defaultMessage: 'Tier 4 Generated External Casino GGR',
    },
    tier_5_revenue_shared: {
        id: 'DataGridAgentLevelReport_tier5RevenueShared',
        defaultMessage: 'Tier 5 Revenue Shared',
    },
    tier_5_poker_revenue_share: {
        id: 'DataGridAgentLevelReport_tier5PokerRevenueShare',
        defaultMessage: 'Tier 5 Poker Revenue Share',
    },
    tier_5_casino_revenue_share: {
        id: 'DataGridAgentLevelReport_tier5CasinoRevenueShare',
        defaultMessage: 'Tier 5 Casino Revenue Share',
    },
    tier_5_generated_internal_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier5GeneratedInternalCasinoGgr',
        defaultMessage: 'Tier 5 Generated Internal Casino GGR',
    },
    tier_5_generated_external_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier5GeneratedExternalCasinoGgr',
        defaultMessage: 'Tier 5 Generated External Casino GGR',
    },
    tier_6_revenue_shared: {
        id: 'DataGridAgentLevelReport_tier6RevenueShared',
        defaultMessage: 'Tier 6 Revenue Shared',
    },
    tier_6_poker_revenue_share: {
        id: 'DataGridAgentLevelReport_tier6PokerRevenueShare',
        defaultMessage: 'Tier 6 Poker Revenue Share',
    },
    tier_6_casino_revenue_share: {
        id: 'DataGridAgentLevelReport_tier6CasinoRevenueShare',
        defaultMessage: 'Tier 6 Casino Revenue Share',
    },
    tier_6_generated_internal_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier6GeneratedInternalCasinoGgr',
        defaultMessage: 'Tier 6 Generated Internal Casino GGR',
    },
    tier_6_generated_external_casino_ggr: {
        id: 'DataGridAgentLevelReport_tier6GeneratedExternalCasinoGgr',
        defaultMessage: 'Tier 6 Generated External Casino GGR',
    },
});
