import * as React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {MemoizedChip, MemoizedDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {SecurityCasesList} from '@components/data-grid/mui';
import LocalizedText from '@components/i18n/LocalizedText';
import {BulkAccountVerificationStatus} from '@config/chip';
import {AccountVerificationStatus, FeatureAccessStatus, SecurityCase} from '@models/generated/graphql';

import {ToggleListEditModel} from '../../module-shared/types';
import {BulkActionKey} from '../types';

const localized = defineMessages({
    bulkSummaryValueLock: {
        id: 'bulkSummaryValueLock',
        defaultMessage: 'Lock All',
    },

    bulkSummaryValueUnlock: {
        id: 'bulkSummaryValueUnlock',
        defaultMessage: 'Unlock All',
    },
});

type BulkSummaryValueProps<T> = {
    actionValue: T;
};

export const BulkSummaryValueText = ({actionValue}: BulkSummaryValueProps<string | MessageDescriptor>) => {
    return <LocalizedText label={actionValue} />;
};

export const BulkSummaryChipValue = ({actionValue}: BulkSummaryValueProps<string>) => {
    return <MemoizedDefaultChip value={actionValue} variant={ChipVariant.Blue} type={ChipType.Status} />;
};

export const BulkSummarySecurityCasesValue = ({actionValue}: BulkSummaryValueProps<ToggleListEditModel<SecurityCase>>) => {
    return (
        <SecurityCasesList
            value={actionValue ? [...actionValue.add, ...actionValue.initial, ...actionValue.remove] : []}
        ></SecurityCasesList>
    );
};

export const BulkSummaryKYCValue = ({actionValue}: BulkSummaryValueProps<AccountVerificationStatus>) => {
    return <MemoizedChip value={actionValue} chipName={BulkAccountVerificationStatus} />;
};

type UseBulkSummaryValueProps = {
    actionKey: string;
    actionValue: unknown;
};

export const useBulkActionSummaryValue = ({actionKey, actionValue}: UseBulkSummaryValueProps) => {
    const mapping: Record<BulkActionKey, JSX.Element> = {
        [BulkActionKey.Message]: <BulkSummaryChipValue actionValue="1" />,
        [BulkActionKey.NotesAndAttachments]: <BulkSummaryChipValue actionValue="1" />,
        [BulkActionKey.SecurityCasesAdd]: <></>,
        [BulkActionKey.SecurityCasesRemove]: <></>,
        [BulkActionKey.SecurityCasesUpdate]: <></>,
        [BulkActionKey.AccountLock]: (
            <BulkSummaryValueText actionValue={actionValue === true ? localized.bulkSummaryValueLock : localized.bulkSummaryValueUnlock} />
        ),
        [BulkActionKey.DepositLock]: (
            <BulkSummaryValueText actionValue={actionValue === true ? localized.bulkSummaryValueLock : localized.bulkSummaryValueUnlock} />
        ),
        [BulkActionKey.WithdrawalLock]: (
            <BulkSummaryValueText actionValue={actionValue === true ? localized.bulkSummaryValueLock : localized.bulkSummaryValueUnlock} />
        ),
        [BulkActionKey.LobbyLock]: (
            <BulkSummaryValueText actionValue={actionValue === true ? localized.bulkSummaryValueLock : localized.bulkSummaryValueUnlock} />
        ),
        [BulkActionKey.CasinoLock]: (
            <BulkSummaryValueText
                actionValue={actionValue === FeatureAccessStatus.Locked ? localized.bulkSummaryValueLock : localized.bulkSummaryValueUnlock}
            />
        ),
        [BulkActionKey.SportsbookLock]: (
            <BulkSummaryValueText
                actionValue={actionValue === FeatureAccessStatus.Locked ? localized.bulkSummaryValueLock : localized.bulkSummaryValueUnlock}
            />
        ),
        [BulkActionKey.P2PTransferLock]: (
            <BulkSummaryValueText
                actionValue={actionValue === FeatureAccessStatus.Locked ? localized.bulkSummaryValueLock : localized.bulkSummaryValueUnlock}
            />
        ),
        [BulkActionKey.ManualTriggerMTTTicket]: <></>,
        [BulkActionKey.ManualTriggerDepositCashMatch]: <></>,
        [BulkActionKey.ManualTriggerDepositCashMatchAmount]: <></>,
        [BulkActionKey.ManualTriggerDepositCashMatchCode]: <></>,
        [BulkActionKey.ManualTriggerImmediateCashBonus]: <></>,
        [BulkActionKey.ManualTransactions]: <></>,
        [BulkActionKey.SecurityCases]: <></>,
        [BulkActionKey.SecurityCasesAll]: <></>,
        [BulkActionKey.AddUserLabel]: <></>,
        [BulkActionKey.RemoveUserLabel]: <></>,
        [BulkActionKey.P2PTransfer]: <></>,
        [BulkActionKey.KYCReject]: <></>,
        [BulkActionKey.InitiatePaymentKYC]: <BulkSummaryKYCValue actionValue={AccountVerificationStatus.InitPaymentKyc} />,
    };
    return actionValue !== null && actionValue !== undefined ? (
        mapping[actionKey as BulkActionKey] ?? <BulkSummaryValueText actionValue={actionValue?.toString()}></BulkSummaryValueText>
    ) : (
        <></>
    );
};
