import {
    ApolloCache,
    ApolloClient,
    ApolloClientOptions,
    ApolloQueryResult,
    DefaultContext,
    FetchResult,
    MutationOptions,
    OperationVariables,
    QueryOptions,
    SubscriptionOptions,
} from '@apollo/client';
import {Span} from '@opentelemetry/api';
import {print} from 'graphql';
import {from, Observable, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

import {applyHttpRequestAttrs, applyHttpResponseAttrs, applyLocationAttrs, applyUserAttrs, ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {HttpMethod, HttpStatusCode} from '@services/types';

const httpAttributesPrefix = 'http';
const httpAttributesRequestPrefix = `${httpAttributesPrefix}.request`;
const GqlAttributes = {
    GRAPHQL_METHOD: `${httpAttributesRequestPrefix}.gql.type`,
    GRAPHQL_METHOD_NAME: `${httpAttributesRequestPrefix}.gql.query`,
};

function applyGqlRequestAttrs(span: Span, gqlQueryType: 'QUERY' | 'MUTATION' | 'SUBSCRIPTION', gqlQuery: string) {
    span?.setAttributes({
        [GqlAttributes.GRAPHQL_METHOD]: gqlQueryType,
        [GqlAttributes.GRAPHQL_METHOD_NAME]: gqlQuery,
    });
}
export class ApolloClientProxy<TCacheShape> extends ApolloClient<TCacheShape> {
    private _tracingService: ITracingService;
    private _userManager: UserManagerExtended;
    private _uri: string;

    constructor(options: ApolloClientOptions<TCacheShape>, tracingService: ITracingService, userManager: UserManagerExtended) {
        super(options);
        this._uri = options?.uri as string;
        this._tracingService = tracingService;
        this._userManager = userManager;
    }

    query<T = any, TVariables = OperationVariables>(options: QueryOptions<TVariables, T>): Promise<ApolloQueryResult<T>> {
        const tracer = this._tracingService.getTracer();

        return this.getUser()
            .pipe(
                mergeMap(user =>
                    tracer.startActiveSpan('GRAPHQL QUERY', span => {
                        applyLocationAttrs(span);
                        applyUserAttrs(span, user.basicInfo);
                        applyHttpRequestAttrs(span, this._uri, HttpMethod.Post, 'xhr');
                        applyGqlRequestAttrs(span, 'QUERY', print(options.query));

                        return from(super.query(options)).pipe(
                            map(r => {
                                if (r.errors) {
                                    const message = this.getErrorMessageFromErrorResponse(r);
                                    applyHttpResponseAttrs(span, null, message);
                                    this._tracingService.endSpanFailed(span, message);
                                } else {
                                    applyHttpResponseAttrs(
                                        span,
                                        HttpStatusCode.Ok?.toString(),
                                        this.getErrorMessageFromQuerySuccessResponse<T>(r)
                                    );
                                    this._tracingService.endSpanOk(span);
                                }

                                return r;
                            }),
                            catchError(r => {
                                const message = this.getErrorMessageFromErrorResponse(r);
                                applyHttpResponseAttrs(span, HttpStatusCode.InternalServerError?.toString(), message);
                                this._tracingService.endSpanFailed(span, message);

                                return throwError(r);
                            })
                        );
                    })
                )
            )
            .toPromise();
    }

    mutate<TData = any, TVariables = OperationVariables, TContext = DefaultContext>(
        options: MutationOptions<TData, TVariables, TContext, ApolloCache<any>>
    ): Promise<FetchResult<TData, Record<string, any>, Record<string, any>>> {
        const tracer = this._tracingService.getTracer();

        return this.getUser()
            .pipe(
                mergeMap(user =>
                    tracer.startActiveSpan('GRAPHQL MUTATION', span => {
                        applyLocationAttrs(span);
                        applyUserAttrs(span, user.basicInfo);
                        applyHttpRequestAttrs(span, this._uri, HttpMethod.Post, 'xhr');
                        applyGqlRequestAttrs(span, 'MUTATION', print(options.mutation));

                        return from(super.mutate(options)).pipe(
                            map(r => {
                                if (r.errors) {
                                    const message = this.getErrorMessageFromErrorResponse(r);
                                    applyHttpResponseAttrs(span, null, message);
                                    this._tracingService.endSpanFailed(span, message);
                                } else {
                                    applyHttpResponseAttrs(
                                        span,
                                        HttpStatusCode.Ok?.toString(),
                                        this.getErrorMessageFromMutationSuccessResponse<TData>(r)
                                    );
                                    this._tracingService.endSpanOk(span);
                                }

                                return r;
                            }),
                            catchError(r => {
                                const message = this.getErrorMessageFromErrorResponse(r);
                                applyHttpResponseAttrs(span, HttpStatusCode.InternalServerError?.toString(), message);
                                this._tracingService.endSpanFailed(span, message);

                                return throwError(r);
                            })
                        );
                    })
                )
            )
            .toPromise();
    }

    subscribeRx<T = any, TVariables = OperationVariables>(options: SubscriptionOptions<TVariables, T>): Observable<FetchResult<T>> {
        return new Observable(subscriber =>
            this.subscribe(options).subscribe({
                next: args => {
                    return subscriber.next(args);
                },
                error: (...args) => {
                    return subscriber.error(args);
                },
                complete: () => {
                    return subscriber.complete();
                },
            })
        );
    }

    override subscribe<T = any, TVariables = OperationVariables>(options: SubscriptionOptions<TVariables, T>) {
        return super.subscribe(options);
    }

    private getUser() {
        return this._userManager.getUserExtended();
    }

    private getErrorMessageFromErrorResponse(r: any): string {
        return r.error || r.errors || r.networkError || r.graphQLErrors
            ? JSON.stringify({
                  error: r.error,
                  errors: r.errors,
                  networkError: r.networkErrors,
                  graphQLErrors: r.graphQLErrors,
              })
            : undefined;
    }

    private getErrorMessageFromQuerySuccessResponse<T = any>(r: ApolloQueryResult<T>): string {
        return r.error || r.errors ? JSON.stringify({error: r.error, errors: r.errors}) : undefined;
    }

    private getErrorMessageFromMutationSuccessResponse<T = any>(r: FetchResult<T>): string {
        return r.errors ? JSON.stringify({errors: r.errors}) : undefined;
    }
}
