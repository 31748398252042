import React from 'react';
import {defineMessages} from 'react-intl';

import {TransactionFilterKeys} from '@redux/entity';

import FilterTextField from 'src/features/shared/filter/components/FilterText';

import {TransactionFilterProps} from './TransactionFilters';

const localized = defineMessages({
    paymentMethod: {
        id: 'TransactionFilterPaymentMethod_paymentMethod',
        defaultMessage: 'Method',
    },
});

function TransactionFilterPaymentMethod({filter, onFilterChange}: TransactionFilterProps) {
    const filterKey: TransactionFilterKeys = 'paymentMethodName';
    return (
        <FilterTextField
            onFilterChange={onFilterChange}
            filter={{
                key: filterKey,
                name: localized.paymentMethod,
                label: localized.paymentMethod,
            }}
            filterString={filter}
        />
    );
}
export default TransactionFilterPaymentMethod;
