import React, {ReactNode, useState} from 'react';

import {RoutingTabsContextProps, Tab} from './RoutingTabs';

export const RoutingTabsContext = React.createContext<RoutingTabsContextProps>(undefined);

type RoutingTabsProviderProps = {
    children: JSX.Element | ReactNode;
};

export const RoutingTabsProvider = ({children}: RoutingTabsProviderProps) => {
    const [tabs, setTabs] = useState<Tab[]>([]);

    function saveTab(tab: Tab) {
        if (!tabs.find(t => t.path === tab.path)) {
            setTabs([...tabs, tab]);
        }
    }

    function findActiveTab(paths: string[]) {
        return tabs.find(t => paths.includes(t.path));
    }

    function removeTab(tab: Tab) {
        setTabs(tabs.filter(t => t.path !== tab.path));
    }

    return <RoutingTabsContext.Provider value={{saveTab, findTab: findActiveTab, removeTab}}>{children}</RoutingTabsContext.Provider>;
};
