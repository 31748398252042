import {FormatNumberOptions} from 'react-intl';

import {GlideTextCell} from './GlideTextCell';

type CurrencyColumnValue = {
    amount: number;
    currency: string;
};

export class GlideCurrencyCell<TModel> extends GlideTextCell<TModel, CurrencyColumnValue> {
    private readonly _formatter: (value: number, options: FormatNumberOptions) => string;

    constructor(valueGetter: (model: TModel) => CurrencyColumnValue, formatter: (value: number, options: FormatNumberOptions) => string) {
        super(valueGetter);
        this._formatter = formatter;
    }

    protected formatValue(value: CurrencyColumnValue): string {
        return value?.currency
            ? this._formatter(value.amount ?? 0, {style: 'currency', currency: value.currency})
            : value?.amount?.toString();
    }
}
