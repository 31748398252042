import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {GridColDef} from '@components/data-grid/mui';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {Policy} from '@auth';

import {useJurisdictionConfig} from '../../../features/app/config/hooks';
import {withModule} from '../../../features/module-shared/components/ModuleHoc';
import ModulePage from '../../../features/module-shared/components/ModulePage';
import {domain} from '../types';

import data from './TransactionHistory.config';

const TransactionHistory = () => {
    const {currency} = useJurisdictionConfig();
    const stateData = useSelector((state: RootState) => state.transactionHistory);

    return (
        <Fragment>
            <ModulePage
                domain={domain}
                data={stateData}
                header={data.localized.title}
                filters={data.filters}
                columns={data.getGridColumns(currency) as GridColDef[]}
                defaultSorting={data.defaultSorting}
            ></ModulePage>
        </Fragment>
    );
};

export const transactionHistoryResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'TransactionHistory',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.PlayerManagement, null, 'read'),
        new Policy(ModuleName.PlayerManagement, SubmoduleName.TransactionHistory, 'read'),
    ]),
});

export default withModule(TransactionHistory, [
    {
        moduleName: ModuleName.PlayerManagement,
        submoduleName: SubmoduleName.TransactionHistory,
        permissions: [PermissionEnum.Read],
    },
]);
