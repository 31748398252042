import React from 'react';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    expandMoreIcon: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(0.5),
        borderRadius: '100%',
        width: theme.spacing(2),
        height: theme.spacing(2),
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5,
    },
    expandMoreIconVerticalCentreLine: {
        '&::after': {
            width: theme.spacing(0.25),
            height: theme.spacing(),
            content: '""',
            backgroundColor: theme.palette.primary.contrastText,
            position: 'absolute',
            borderRadius: theme.shape.borderRadius,
        },
    },
    expandMoreIconHorizontalCentreLine: {
        '&::before': {
            display: 'block',
            width: theme.spacing(),
            height: theme.spacing(0.25),
            content: '""',
            top: '0.5em',
            left: '0.25em',
            backgroundColor: theme.palette.primary.contrastText,
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export function ExpandMorePlusIcon() {
    const {classes, cx} = useStyles();
    return (
        <div
            className={cx(classes.expandMoreIconVerticalCentreLine, classes.expandMoreIconHorizontalCentreLine, classes.expandMoreIcon)}
        ></div>
    );
}

export function ExpandMoreMinusIcon() {
    const {classes, cx} = useStyles();
    return <div className={cx(classes.expandMoreIconHorizontalCentreLine, classes.expandMoreIcon)}></div>;
}
