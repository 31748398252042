import {createAction} from 'typesafe-actions';

import {IFailureResponse} from '../module-shared/types';

import {Message} from './types';

const showMessage = 'message/showMessage';
const clearMessage = 'message/clearMessage';

const showError = 'message/showError';
const handleErrorResponse = 'message/handleErrorResponse';

export const showMessageAction = createAction(showMessage)<Message>();
export const showErrorAction = createAction(showError)<Message>();
export const clearMessageAction = createAction(clearMessage)<void>();
export const handleErrorResponseAction = createAction(handleErrorResponse)<IFailureResponse>();
