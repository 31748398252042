import React, {memo} from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons/types';
import {UserProfile} from '@models/generated/graphql';

import {EditableModalProps, withEditableModal} from '../../../module-temp-storage/components/editable/EditableModalHoc';
import {MessageType} from '../../../module-temp-storage/types';
import {ActionModalTitle} from '../../common/ActionModalTitle';
import {actions} from '../actions';
import {useSecurityCases} from '../hooks';
import {localizedSecurityCases} from '../localize';
import {EditSecurityCasesFieldModel, EditSecurityCasesSubmitModel} from '../types';

import {SecurityCasesFieldsModal} from './SecurityCasesFieldsModal';

const useClasses = makeStyles()(() => ({
    securityCasesCell: {
        flex: 1,
    },
    assignSecurityCaseIdButton: {
        width: '100%',
    },
    assignSecurityCaseId: {
        flex: 1,
        '& > .MuiBox-root': {
            padding: '0 !important',
            margin: '0 !important',
        },
    },
}));

function AssignSecurityCaseIdButton({
    onClick,
    disabled,
}: EditableModalProps<UserProfile, EditSecurityCasesFieldModel, EditSecurityCasesSubmitModel>) {
    const {classes} = useClasses();

    return (
        <OutlinedButton
            className={classes.assignSecurityCaseIdButton}
            disabled={disabled}
            label={localizedSecurityCases.assignSecurityCaseId}
            startIcon={<Box component="span" className={CustomIcon.Bag} />}
            onClick={onClick}
        />
    );
}

const AssignSecurityCaseIdAction = memo(withEditableModal(AssignSecurityCaseIdButton, SecurityCasesFieldsModal));

type AssignSecurityCaseIdProps = {
    model: UserProfile;
    id: string;
    field: string;
    defaultValue: any;
    disabled?: boolean;
};

export function AssignSecurityCaseId({model, defaultValue, field, id, disabled}: AssignSecurityCaseIdProps) {
    const {classes} = useClasses();
    const {initialValue, subInfoMessage, getSubmitValue} = useSecurityCases(defaultValue);

    return (
        <Box className={classes.assignSecurityCaseId} data-testid="assignSecurityCaseId">
            <AssignSecurityCaseIdAction
                value={initialValue}
                action={actions.updateSecurityCase}
                messageType={MessageType.ModuleMessage}
                field={field}
                disabled={disabled}
                typeName="assign-security-case-id"
                id={id}
                model={model}
                subInfoMessage={subInfoMessage}
                title={<ActionModalTitle label={localizedSecurityCases.securityCasesId} showTotal={false} />}
                getSubmitValue={getSubmitValue}
            />
        </Box>
    );
}
