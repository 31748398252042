import React, {memo} from 'react';

import SquaredChipList, {ChipKeyValue} from '../../../chip/SquaredChipList';

type ChipListCellProps = {
    values: ChipKeyValue[];
    hideTooltip?: boolean;
};

export const ChipListCell = memo(({values, hideTooltip}: ChipListCellProps) => {
    return <SquaredChipList list={values} hideTooltip={hideTooltip !== undefined ? hideTooltip : values?.length < 2} />;
});
