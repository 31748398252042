import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {getColumnConfigurationReducer, getContentItemsPageReducer, getContentModuleStateReducer} from '../../module-shared/reducers';

import {domain} from './types';

export const userNotificationHistoryReducer = combineReducers({
    domain: createReducer(domain),
    data: combineReducers(getContentItemsPageReducer(domain)),
    state: combineReducers({
        ...getColumnConfigurationReducer(domain),
        ...getContentModuleStateReducer(domain),
    }),
});
