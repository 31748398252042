import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {CommandMenuItem, DropdownButton, useActive, useCommands} from '@remirror/react';
import {makeStyles} from 'tss-react/mui';

const fontSizes = ['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'];

const localized = defineMessages({
    fontSize: {
        id: 'FontSizeSelect_fontSize',
        defaultMessage: 'Font size',
    },
});

export const useClasses = makeStyles()(theme => ({
    fontSizeSelectSelected: {
        '&.Mui-selected': {
            color: theme.palette.primary.main,
        },
    },
}));

export function FontSizeSelect() {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();
    const {setFontSize} = useCommands();
    const {fontSize} = useActive();
    let currentFontSize: string;
    fontSizes.find(size => {
        let result = false;
        if (fontSize({size})) {
            currentFontSize = size;
            result = true;
        }
        return result;
    });
    console.log(currentFontSize);
    return (
        <DropdownButton aria-label={formatMessage(localized.fontSize)} icon={currentFontSize ? <>{currentFontSize}</> : 'fontSize'}>
            {fontSizes.map(size => (
                <CommandMenuItem
                    key={size}
                    className={classes.fontSizeSelectSelected}
                    commandName="setFontSize"
                    onSelect={() => setFontSize(size)}
                    enabled={setFontSize.enabled(size)}
                    active={fontSize({size})}
                    label={size}
                    icon={null}
                    displayDescription={false}
                />
            ))}
        </DropdownButton>
    );
}
