import React, {useMemo} from 'react';
import {defineMessages} from 'react-intl';
import {useSelector} from 'react-redux';

import LocalizedText from '@components/i18n/LocalizedText';
import {LayoutDetails, LayoutDetailsActionsCollapsible} from '@components/layout';
import {LayoutDetailsActions} from '@components/layout/LayoutDetailsActions';
import {EntityType, NotesFilterType} from '@models/generated/graphql';
import {UserProfileServerFilterKeys} from '@redux/entity';
import {getNonEmptyValueValidator, ViewType} from '@redux/view';
import {getSelectedItemId} from '@utils';

import {RouteUrl} from 'src/common/routeEnums';
// TODO: Move from pages
import {KYCDocuments} from '../../../pages/kyc-details/kyc-details-documents/components/KYCDocuments';
import {locationSearchSelector} from '../../app/routing/selectors';
import {UserProfileAction, UserProfileActions} from '../../block-user-profile-actions';
import {
    KYCDetailsHeader,
    NotesAndAttachments,
    UserDetailsFinancialInfo,
    UserDetailsKYCInfo,
    UserDetailsMarketingInfo,
    UserDetailsNotificationHistoryInfo,
    UserDetailsPersonalInfo,
    UserDetailsPrivacyPolicyInfo,
    UserDetailsRegionRelatedInfo,
    UserDetailsSecurityInfo,
    UserDetailsTermsAndConditionsInfo,
} from '../../block-workspace-details';
import {useAccountVerificationDetails} from '../hooks';

import {KycStatusActions} from './KycStatusActions';

const localized = defineMessages({
    kycActions: {
        id: 'KYCD_kycActions',
        defaultMessage: 'KYC Actions',
    },
    kycProcessingRoute: {
        id: 'KYCD_kycProcessingRoute',
        defaultMessage: 'KYC Workspace',
    },
    ndrpProcessingRoute: {
        id: 'KYCD_ndrpProcessingRoute',
        defaultMessage: 'NDRP Workspace',
    },
});

type KycDetailsProps = {
    type: 'kyc' | 'ndrp';
};

export function KycDetails({type}: KycDetailsProps) {
    const viewType: ViewType = 'KYCDetails';
    const queryString = useSelector(locationSearchSelector);
    const accountVerificationId = getSelectedItemId(queryString);
    const {item} = useAccountVerificationDetails(
        {
            id: accountVerificationId,
            viewType,
            fields: ['id', 'uid'],
        },
        {
            fields: ['uid', 'country'],
            validateFilter: getNonEmptyValueValidator<UserProfileServerFilterKeys>('uid'),
        }
    );

    const userId = item?.uid;
    const queueUrl = type === 'kyc' ? RouteUrl.KycProcessing : RouteUrl.NDRPProcessing;
    const queueTitle = type === 'kyc' ? localized.kycProcessingRoute : localized.ndrpProcessingRoute;

    const actions: UserProfileAction[] = useMemo(
        () => [
            'account-status',
            'deposit-status',
            'lobby-status',
            'withdrawal-status',
            'casino-status',
            'sportsbook-status',
            'p2p-transfer-status',
            'init-payment-kyc',
            'send-message',
            'manual-transaction',
        ],
        []
    );

    return (
        <LayoutDetails
            header={<KYCDetailsHeader itemId={accountVerificationId} queueUrl={queueUrl} queueTitle={queueTitle} />}
            primaryInfo={
                <>
                    <UserDetailsPersonalInfo userId={userId} />
                    <UserDetailsRegionRelatedInfo country={item?.player_country} userId={userId} />
                    <UserDetailsFinancialInfo userId={userId} />
                    <UserDetailsKYCInfo userId={userId} />
                    <UserDetailsSecurityInfo userId={userId} />
                    <UserDetailsMarketingInfo userId={userId} />
                    <UserDetailsPrivacyPolicyInfo userId={userId} />
                    <UserDetailsTermsAndConditionsInfo userId={userId} />
                    <UserDetailsNotificationHistoryInfo userId={userId} />
                </>
            }
            secondaryInfoTop={
                type === 'kyc' ? (
                    <LayoutDetailsActionsCollapsible
                        header={
                            <>
                                <LocalizedText label={localized.kycActions} />
                                <KycStatusActions id={accountVerificationId} type="kyc" />
                            </>
                        }
                    >
                        <KYCDocuments itemId={accountVerificationId} />
                        <UserProfileActions uid={userId} actions={actions} includeGroupTitle={false} />
                    </LayoutDetailsActionsCollapsible>
                ) : (
                    <LayoutDetailsActions title={localized.kycActions}>
                        <KycStatusActions id={accountVerificationId} type="ndrp" />
                    </LayoutDetailsActions>
                )
            }
            secondaryInfoBottom={
                userId ? (
                    <NotesAndAttachments
                        createEntity={{id: accountVerificationId, type: EntityType.KycCase, parent: {id: userId, type: EntityType.Player}}}
                        readEntity={{id: userId, type: EntityType.Player, parent: {id: userId, type: EntityType.Player}}}
                        filterType={NotesFilterType.AllRecords}
                    />
                ) : (
                    <></>
                )
            }
        />
    );
}
