import React from 'react';

import {UserProfile} from '@models/generated/graphql';

import {ConfigurationKeyValuePair} from 'src/common/types';
import {UserProfileCountryOfBirth} from '../../../player-actions/pii-actions/components/UserProfileCountryOfBirth';
import {UserProfileNationalId} from '../../../player-actions/pii-actions/components/UserProfileNationalId';
import {UserProfileNationality} from '../../../player-actions/pii-actions/components/UserProfileNationality';
import {UserProfileOccupation} from '../../../player-actions/pii-actions/components/UserProfileOccupation';
import {UserProfileTaxId} from '../../../player-actions/pii-actions/components/UserProfileTaxId';
import {UserProfileColumnsConfiguration} from '../../../user-profile/types';

import {localized} from './UserRegionRelatedInfo.localize';

const getTableRows = (user: UserProfile): ConfigurationKeyValuePair[] => [
    {
        field: nameof.full<UserProfile>(i => i.region_player_info.occupation),
        key: localized.occupation,
        value: <UserProfileOccupation userId={user?.uid} user={user} />,
        configuration: [nameof<UserProfileColumnsConfiguration>(i => i.withOccupation)],
    },
    {
        field: nameof.full<UserProfile>(i => i.region_player_info.tax_id),
        key: localized.tax_id,
        value: <UserProfileTaxId userId={user?.uid} user={user} />,
        configuration: [nameof<UserProfileColumnsConfiguration>(i => i.withTaxID)],
    },
    {
        field: nameof.full<UserProfile>(i => i.region_player_info.national_id),
        key: localized.national_id,
        value: <UserProfileNationalId userId={user?.uid} user={user} />,
        configuration: [nameof<UserProfileColumnsConfiguration>(i => i.withNationalID)],
    },
    {
        field: nameof.full<UserProfile>(i => i.region_player_info.nationality),
        key: localized.nationality,
        value: <UserProfileNationality userId={user?.uid} user={user} />,
        configuration: [nameof<UserProfileColumnsConfiguration>(i => i.withNationality)],
    },
    {
        field: nameof.full<UserProfile>(i => i.region_player_info.country_of_birth),
        key: localized.country_of_birth,
        value: <UserProfileCountryOfBirth userId={user?.uid} user={user} />,
        configuration: [nameof<UserProfileColumnsConfiguration>(i => i.withCountryOfBirth)],
    },
];

export default getTableRows;
