import React, {useRef} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';
import {LinkText} from '@components/link';

import LegalDocumentView from '../../../pages/legal-docs/components/LegalDocumentView';
import {LegalDocumentItem, LegalDocumentRead} from '../../../pages/legal-docs/types';
import {GridModalActionProps, GridModalProps, withGridModal} from '../../data-grids/components/GridModalHoc';
import {emptyCellCharacter} from '../../module-shared/utils';
import {LegalDocType} from '../types';

import {localizedLegalDocs} from './LegalDocs.localize';

type LegalDocumentOptions = {
    document: LegalDocumentItem;
    documentName: MessageDescriptor;
};

const LegalDocumentAction = ({onClick}: GridModalActionProps): JSX.Element => {
    return !document ? null : <LinkText title={localizedLegalDocs.showVersion} onClick={onClick} />;
};

const LegalDocumentModal = ({options}: GridModalProps<any, any, LegalDocumentOptions>): JSX.Element => {
    const {documentName, document} = options;
    const modalId = useRef('legalDocumentModal');

    const legalDocument: LegalDocumentRead = {
        id: '',
        version: document.version,
        actionTime: document.acceptedAt,
        document: document.content,
    };

    return (
        <Box id={modalId.current}>
            {legalDocument?.document && <LegalDocumentView fullContent={true} document={legalDocument} documentName={documentName} />}
        </Box>
    );
};

export const LegalDocsModalAction = withGridModal<any, any, LegalDocumentOptions>(LegalDocumentAction, LegalDocumentModal);

type LegalDocsProps = {
    legalDocType: LegalDocType;
    uid: string;
    document: LegalDocumentItem;
};

export const LegalDocs = ({legalDocType, document}: LegalDocsProps) => {
    const documentName =
        legalDocType === LegalDocType.PrivacyPolicy ? localizedLegalDocs.privacyPolicy : localizedLegalDocs.termsAndConditions;

    return document?.acceptedAt ? (
        <LegalDocsModalAction
            modalStyleParams={{maxWidth: 'lg'}}
            title={<LocalizedText label={localizedLegalDocs.acceptanceVersion} />}
            options={{document, documentName}}
        />
    ) : (
        <>{emptyCellCharacter}</>
    );
};
