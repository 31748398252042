import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {BlackListItem, UserBlackList} from '@models/generated/graphql';

import {localizedBlackListItemType} from '../../app/intl/shared-resources/BlackListItemType';

export type BlacklistedPlayerValue = {
    black_list: UserBlackList;
};

export const useBlacklistedPlayerFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: BlacklistedPlayerValue = JSON.parse(json);

    const formatBlacklist = (blackListItem: BlackListItem): ChipKeyValue => ({
        key: formatMessage(localizedBlackListItemType[blackListItem.type]),
        value: blackListItem.key,
    });

    return value?.black_list?.items?.map(formatBlacklist) ?? [];
};
