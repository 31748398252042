import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {ManagedGameType} from '@redux/entity';

import {useAsyncActionState} from '../../shared/async-action/hooks';
import {gameRoomActions} from '../actions';

const localized = defineMessages({
    buttonLabel: {
        id: 'PlayerModalButton_buttonLabel',
        defaultMessage: 'Remove player',
    },
});

const useStyles = makeStyles()(theme => ({
    removePlayerActionButtonIcon: {
        fontSize: `${theme.spacing(1.5)} !important`,
    },
}));

export type RemovePlayerActionCellProps = {
    roomId: string;
    userId: string;
    gameType: ManagedGameType;
};

export function RemovePlayerButtonCell({roomId, userId, gameType}: RemovePlayerActionCellProps) {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const {isProgress} = useAsyncActionState(gameRoomActions.kickoffPlayer);

    const handleClick = () => {
        dispatch(gameRoomActions.kickoffPlayer.request({roomId, userId, gameType}));
    };

    return (
        <OutlinedButton
            smallPlus
            label={localized.buttonLabel}
            onClick={handleClick}
            startIcon={<Icon className={classes.removePlayerActionButtonIcon} icon={CustomIcon.Block} color={IconColor.Secondary} />}
            disabled={isProgress}
        />
    );
}

export function renderRemovePlayerActionCell(params: GridCellParams) {
    const colDef = params.colDef as GridColDef;
    const value: RemovePlayerActionCellProps = (
        colDef.valueFormatter ? params.formattedValue : params.value
    ) as RemovePlayerActionCellProps;

    return <RemovePlayerButtonCell {...value} />;
}
