import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';

import {usePolicyAccessCheck} from '@access-control';
import {OutlinedButton} from '@components/button/Buttons';
import {CustomIcon, Icon} from '@components/icons';
import {ModalContext} from '@components/modal';
import {agentReadPolicies} from '@models/permissions/permissions';

import {useJurisdictionFeature} from 'src/features/app/config/hooks';
import {FeatureName} from 'src/features/app/config/types';
import {useMultiplePermissions} from '../../app/permission/PermissionHoc';
import {manualTransactionAddPolicies, manualTransactionCreateResource} from '../permissions';

import {ManualTransactionAddModal} from './ManualTransactionAddModal';

const localized = defineMessages({
    title: {
        id: 'ManualTransactionAddButton_title',
        defaultMessage: 'Manual Debit/Credit',
    },
});

type ManualTransactionAddButtonProps = {
    uid: string;
    disabled?: boolean;
};

function ManualTransactionAddButtonInner({disabled, uid}: ManualTransactionAddButtonProps) {
    const {openModal} = useContext(ModalContext);
    function handleClick() {
        openModal({
            body: <ManualTransactionAddModal uid={uid} />,
            styleParams: {maxWidth: 'sm'},
        });
    }
    return (
        <OutlinedButton
            fullWidth={true}
            label={localized.title}
            startIcon={<Icon icon={CustomIcon.AddSquareOutline} />}
            onClick={handleClick}
            disabled={disabled}
            data-testid="manual-transaction-action"
        />
    );
}

export function ManualTransactionAddButton(props: ManualTransactionAddButtonProps) {
    const isManualTransactionPolicyAvailiable = useJurisdictionFeature({
        featureName: FeatureName.FinanceActionsNewPolicies,
    });

    const isCreateAvailable = isManualTransactionPolicyAvailiable
        ? usePolicyAccessCheck(manualTransactionCreateResource)
        : useMultiplePermissions({
              allowedPermissions: manualTransactionAddPolicies,
          });

    const isAgent = useMultiplePermissions({
        restrictedPermissions: agentReadPolicies,
    });

    const getComponent = () =>
        isCreateAvailable ? <ManualTransactionAddButtonInner {...props} /> : <ManualTransactionAddButtonInner {...props} disabled />;

    return isAgent ? getComponent() : <></>;
}
