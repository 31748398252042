import React from 'react';
import {DeepPartial, Path, UnpackNestedValue, useForm} from 'react-hook-form';

import {toSearchFilter} from '@utils';

import {FilterGroupNew, FilterGroupNewFilter} from './FilterGroupNew';
type Filter<T = unknown, TFilterKey = string> = {
    key: TFilterKey;
    value: T;
};

type BaseFilterProps<TModel> = {
    filter: string;
    onFilterChange: (newFilter: Filter[]) => void;
    filterKey?: Path<TModel>;
};

type FilterGroupProps<TModel, TFilterProps> = {
    keys: Path<TModel>[];
    filterMapping: Record<string, React.ComponentType<BaseFilterProps<TModel> & TFilterProps>>;
};

/**
 * @deprecated Use {@link FilterGroupNew} instead
 */
export function FilterGroup<TModel extends object, TFilterProps>({
    keys,
    filterMapping,
    onFilterChange,
    filter,
    ...props
}: FilterGroupProps<TModel, TFilterProps> & BaseFilterProps<TModel> & TFilterProps) {
    const form = useForm<TModel>({defaultValues: {} as UnpackNestedValue<DeepPartial<TModel>>});
    const model = getFormModel(filter);
    const filters = keys.map(key => {
        const FilterComponent: React.ComponentType<BaseFilterProps<TModel>> = filterMapping[key];
        const res: FilterGroupNewFilter<TModel, Path<TModel>> = {
            modelField: key,
            component: ({value, onChange}) => (
                <FilterComponent
                    filter={getFilterStringValue(key, value)}
                    filterKey={key}
                    key={key}
                    onFilterChange={(filters: Filter[]) => {
                        // TODO: [BO-3002] Some filters like date and agent use multiple keys and there are several items in filters list.
                        //  Need to think how to change solution as these filters won't work with current one
                        onChange(filters?.[0]?.value);
                    }}
                    {...props}
                />
            ),
            collapseOnMobile: false,
            filterName: key,
        };
        return res;
    });

    function handleSubmit(model: TModel) {
        const filters = getSubmitValue(model);
        if (filters?.length) {
            onFilterChange(filters);
        }
    }

    function getFormModel(filterString: string) {
        const searchFilter = toSearchFilter(filterString);
        keys?.forEach(key => {
            const value = searchFilter?.filter?.find(k => k.key === key)?.value ?? null;
            form.setValue(key, value);
        });

        return form.getValues() as TModel;
    }

    function getSubmitValue(model: TModel): Filter[] {
        return Object.keys(model)?.map((key: keyof {}): Filter => ({key, value: model?.[key]}));
    }

    function getFilterStringValue(key: string, value: unknown): string {
        return value || value === null || value === '' ? `${key}=${Array.isArray(value) ? JSON.stringify(value) : value}` : filter;
    }

    return (
        <FilterGroupNew
            model={model}
            onChange={handleSubmit}
            mode="update-on-submit"
            allFilters={filters}
            availableFilters={keys}
            viewMode="six-column-view"
        ></FilterGroupNew>
    );
}
