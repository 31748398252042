import {piiActions} from '../actions';

import {localized} from './UserProfileOccupation.localize';
import {withUserProfileOccupation} from './UserProfileOccupationHoc';

export const UserProfileOccupation = withUserProfileOccupation({
    action: piiActions.editOccupation,
    placeholder: localized.piiOccupationFieldName,
    submitModelMapper: m => ({
        fieldValue: m?.user?.region_player_info?.occupation,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            region_player_info: {
                occupation: m?.fieldValue,
            },
        },
        userId: m?.userId,
    }),
    //TODO: [IGP-2179] Added because of the backend partial update logic. Rework after backend change
    isRequired: true,
});
