import React from 'react';
import {defineMessages} from 'react-intl';
import {useSelector} from 'react-redux';

import {LayoutDetails} from '@components/layout';
import {EntityType, NotesFilterType} from '@models/generated/graphql';
import {ModuleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {RealtimeUpdatesMode} from '@redux/realtime';
import {ViewType} from '@redux/view';
import {getSelectedItemId} from '@utils';

import {UserProfileActionsCollapsed} from 'src/features/block-user-profile-actions';
import {locationSearchSelector} from '../features/app/routing/selectors';
import {useUserProfileDetails} from '../features/block-user-profile-details';
import {
    NotesAndAttachments,
    SecurityDetailsHeader,
    UserDetailsFinancialInfo,
    UserDetailsKYCInfo,
    UserDetailsMarketingInfo,
    UserDetailsNotificationHistoryInfo,
    UserDetailsPersonalInfo,
    UserDetailsPrivacyPolicyInfo,
    UserDetailsRegionRelatedInfo,
    UserDetailsSecurityInfo,
    UserDetailsTermsAndConditionsInfo,
} from '../features/block-workspace-details';
import {withModule} from '../features/module-shared/components/ModuleHoc';

const localized = defineMessages({
    securityActionsHeader: {
        id: 'SecurityDetails_SecurityActionsHeader',
        defaultMessage: 'Security Workspace actions',
    },
});

const SecurityDetails = () => {
    const viewType: ViewType = 'SecurityDetails';
    const queryString = useSelector(locationSearchSelector);
    const userId = getSelectedItemId(queryString);
    const fields: UserProfileViewModelKeys[] = ['uid', 'country'];
    const {item} = useUserProfileDetails({id: userId, viewType, fields, realtimeMode: RealtimeUpdatesMode.Confirm});
    return (
        <LayoutDetails
            header={<SecurityDetailsHeader uid={userId} />}
            primaryInfo={
                <>
                    <UserDetailsPersonalInfo userId={userId} />
                    <UserDetailsRegionRelatedInfo country={item?.address?.country_info?.iso_alpha2_code} userId={userId} />
                    <UserDetailsFinancialInfo userId={userId} />
                    <UserDetailsKYCInfo userId={userId} />
                    <UserDetailsSecurityInfo userId={userId} />
                    <UserDetailsMarketingInfo userId={userId} />
                    <UserDetailsPrivacyPolicyInfo userId={userId} />
                    <UserDetailsTermsAndConditionsInfo userId={userId} />
                    <UserDetailsNotificationHistoryInfo userId={userId} />
                </>
            }
            secondaryInfoTop={
                <UserProfileActionsCollapsed
                    title={localized.securityActionsHeader}
                    uid={userId}
                    actions={[
                        'account-status',
                        'deposit-status',
                        'lobby-status',
                        'withdrawal-status',
                        'casino-status',
                        'sportsbook-status',
                        'assign-security-case-id',
                        'p2p-transfer-status',
                        'init-payment-kyc',
                    ]}
                    includeGroupTitle={true}
                />
            }
            secondaryInfoBottom={
                userId && (
                    <NotesAndAttachments
                        readEntity={{id: userId, type: EntityType.Player, parent: {id: userId, type: EntityType.Player}}}
                        createEntity={{id: userId, type: EntityType.Player, parent: {id: userId, type: EntityType.Player}}}
                        filterType={NotesFilterType.AllRecords}
                    />
                )
            }
        />
    );
};

export default withModule(SecurityDetails, [{moduleName: ModuleName.SecurityWorkspace, permissions: [PermissionEnum.Read]}]);
