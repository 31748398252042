import {inject, injectable} from 'inversify';
import {forkJoin, Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {BoUser, BoUserPage} from '@models/bo-user';
import {map, mergeMap} from '@otel';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {
    GetUsersRequestPayload,
    GetUsersTextFilter,
    GetUsersTextFilterKey,
    IUserManagementApiService,
} from '@services/rest-api/IUserManagementApiService';
import {RestService} from '@services/rest-api/restService';
import {ServerResponseStatus, ServiceResponsePayload} from '@services/types';

@injectable()
export class KeycloakUserManagementApiService implements IUserManagementApiService {
    private readonly _restService: RestService;
    private readonly _apiRouteService: ApiRoutesService;
    private readonly _keycloakUsersRequestBuilder: KeycloakUsersRequestBuilder;

    constructor(
        @inject(ServiceTypes.RestFetchApiService) restService: RestService,
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService
    ) {
        this._restService = restService;
        this._apiRouteService = apiRouteService;
        this._keycloakUsersRequestBuilder = new KeycloakUsersRequestBuilder();
    }

    getUserPage(requestPayload: GetUsersRequestPayload): Observable<ServiceResponsePayload<GetUsersRequestPayload, BoUserPage>> {
        return requestPayload?.ids ? this.getUsersById(requestPayload) : this.getUsersBySearch(requestPayload);
    }

    private getUsersBySearch(
        requestPayload: GetUsersRequestPayload
    ): Observable<ServiceResponsePayload<GetUsersRequestPayload, BoUserPage>> {
        this._keycloakUsersRequestBuilder.reset();
        this._keycloakUsersRequestBuilder.buildFilter(requestPayload.textFilter);
        const userCountQuery = this._keycloakUsersRequestBuilder.getQuery();
        this._keycloakUsersRequestBuilder.buildPagination(requestPayload.page, requestPayload.size);
        const usersQuery = this._keycloakUsersRequestBuilder.getQuery();

        const users$ = this.getUsers(requestPayload, usersQuery);
        const userCount$ = this.getUserCount(requestPayload, userCountQuery);

        return forkJoin([users$, userCount$]).pipe(
            mergeMap(([usersResponse, userCountResponse]) => {
                return usersResponse.status === ServerResponseStatus.Success && userCountResponse.status === ServerResponseStatus.Success
                    ? of({
                          requestPayload,
                          status: ServerResponseStatus.Success,
                          responsePayload: {items: usersResponse.responsePayload, total: userCountResponse.responsePayload},
                      })
                    : of({
                          requestPayload,
                          status: ServerResponseStatus.Failed,
                          responsePayload: null,
                          errors: usersResponse?.errors ?? userCountResponse?.errors,
                          message: usersResponse?.message ?? userCountResponse?.message,
                      });
            })
        );
    }

    private getUsersById(requestPayload: GetUsersRequestPayload): Observable<ServiceResponsePayload<GetUsersRequestPayload, BoUserPage>> {
        return requestPayload.ids?.length > 0
            ? this.getUser(requestPayload, requestPayload.ids[0]).pipe(
                  map(userResponse => {
                      const users = userResponse?.responsePayload ? [userResponse?.responsePayload] : [];
                      return {
                          ...userResponse,
                          requestPayload,
                          responsePayload: {items: users, total: users?.length},
                      };
                  })
              )
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {items: [], total: 0},
              });
    }

    private getUsers(
        requestPayload: GetUsersRequestPayload,
        query: string
    ): Observable<ServiceResponsePayload<GetUsersRequestPayload, BoUser[]>> {
        const usersEndpoint: string = this._apiRouteService.getKeycloakUsersEndpoint();

        return this._restService
            .get({endpoint: usersEndpoint, query})
            .pipe(map(response => ({...response, requestPayload, responsePayload: response?.responsePayload})));
    }

    private getUser(
        requestPayload: GetUsersRequestPayload,
        id: string
    ): Observable<ServiceResponsePayload<GetUsersRequestPayload, BoUser>> {
        const userEndpoint: string = this._apiRouteService.getKeycloakUserEndpoint(id);

        return this._restService
            .get({endpoint: userEndpoint})
            .pipe(map(response => ({...response, requestPayload, responsePayload: response?.responsePayload})));
    }

    private getUserCount(
        requestPayload: GetUsersRequestPayload,
        query: string
    ): Observable<ServiceResponsePayload<GetUsersRequestPayload, number>> {
        const usersCountEndpoint: string = this._apiRouteService.getKeycloakUsersCountEndpoint();

        return this._restService
            .get({endpoint: usersCountEndpoint, query})
            .pipe(map(response => ({...response, requestPayload, responsePayload: response?.responsePayload})));
    }
}

class KeycloakUsersRequestBuilder {
    private urlQuery: URLSearchParams;

    public getQuery(): string {
        return this.urlQuery.toString();
    }

    public reset() {
        this.urlQuery = new URLSearchParams();
    }

    buildPagination(page: number, size: number): void {
        if ((page === 0 || page > 0) && size > 0) {
            this.urlQuery.set('first', ((page - 1) * size).toString());
        }
        if (size > 0) {
            this.urlQuery.set('max', size.toString());
        }
    }

    buildFilter(filters: GetUsersTextFilter[]): void {
        const filterKeyMap: Record<GetUsersTextFilterKey, KeycloakFilterParams> = {
            em_fn_ln: 'search',
            email: 'email',
            firstName: 'firstName',
            lastName: 'lastName',
        };

        filters?.forEach(filter => {
            if (filter.key && filter.value) {
                this.urlQuery.set(filterKeyMap[filter.key], filter.value);
            }
        });
    }
}

type KeycloakFilterParams = 'search' | 'email' | 'firstName' | 'lastName' | 'id';
