import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {VariableSizeList} from 'react-window';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {useCustomTheme} from '@style';

import {MemoizedDefaultChip, MemoizedDefaultChipProps} from './ChipRenderer';
import {CharType, GroupedChipListProps, RowSize} from './GroupedChipList';

const useClasses = makeStyles()(theme => ({
    virtualizedGroupedChipListGroupTitle: {
        display: 'block',
        marginBottom: theme.spacing(2),
    },
    virtualizedGroupedChipListChipRow: {
        display: 'flex',
        columnGap: theme.spacing(1),
    },
}));

type VirtualizedGroupedChipListProps = GroupedChipListProps & {
    listWidth: number;
    listHeight: number;
};

type ChipRowWithWidth = {
    items: MemoizedDefaultChipProps[];
    width: number;
};

type GroupListInfo = {
    items: (MessageDescriptor | MemoizedDefaultChipProps[])[];
    groupTitleIndexes: number[];
};

export function VirtualizedGroupedChipList({listWidth, listHeight, rowSize, groups, charType}: VirtualizedGroupedChipListProps) {
    const {classes} = useClasses();
    const theme = useCustomTheme();
    const groupList = getItems();

    function getItemWidth(text: string) {
        const charTypeWidthMap: Record<CharType, number> = {
            number: 9,
            letter: 13,
        };
        const charMaxWidth = charTypeWidthMap[charType];
        const paddingWidth = parseInt(theme.spacing(2), 10);
        const itemsGap = parseInt(theme.spacing(0.75), 10);

        return text?.length * charMaxWidth + paddingWidth + itemsGap;
    }

    function getChipRows(items: MemoizedDefaultChipProps[]): MemoizedDefaultChipProps[][] {
        const rowsWithWidth: ChipRowWithWidth[] = items.reduce<ChipRowWithWidth[]>(
            (rows, currentItem) => {
                const lastRow: ChipRowWithWidth = rows[rows.length - 1];
                const itemWidth = getItemWidth(currentItem.value);
                if (lastRow.width + itemWidth < listWidth) {
                    lastRow.items.push(currentItem);
                    lastRow.width += itemWidth;
                } else {
                    rows.push({items: [currentItem], width: itemWidth});
                }
                return rows;
            },
            [{items: [], width: 0}]
        );

        return rowsWithWidth.map(row => row.items);
    }

    function getItems(): GroupListInfo {
        const items: (MessageDescriptor | MemoizedDefaultChipProps[])[] = [];
        const titleIndexes: number[] = [];
        groups.forEach(group => {
            if (group.items.length > 0) {
                items.push(group.groupTitle);
                titleIndexes.push(items.length - 1);
                const chipRows = getChipRows(group.items);
                items.push(...chipRows);
            }
        });

        return {items, groupTitleIndexes: titleIndexes};
    }

    function getItemSize() {
        const charTypeWidthMap: Record<RowSize, number> = {
            small: 25,
            medium: 35,
            large: 45,
        };

        return charTypeWidthMap[rowSize];
    }

    return (
        <VariableSizeList height={listHeight} width={listWidth} itemSize={getItemSize} itemCount={groupList?.items?.length}>
            {({index, style}) =>
                groupList.groupTitleIndexes.includes(index) ? (
                    <Box style={style} className={classes.virtualizedGroupedChipListGroupTitle}>
                        <Typography variant="body1" color="secondary">
                            <LocalizedText label={groupList.items[index] as MessageDescriptor} />
                        </Typography>
                    </Box>
                ) : (
                    <Box style={style} className={classes.virtualizedGroupedChipListChipRow}>
                        {(groupList.items[index] as MemoizedDefaultChipProps[]).map(props => (
                            <MemoizedDefaultChip {...props} />
                        ))}
                    </Box>
                )
            }
        </VariableSizeList>
    );
}
