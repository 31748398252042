import React from 'react';
import {Box, Typography} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';
import {PlayerMessage} from '@models/generated/graphql';
import {formatTimestamp} from '@utils';

import {localizedNotificationHistoryDetailsModal} from './NotificationHistoryDetailsModal.localize';
import {useNotificationHistoryDetailsModalClasses} from './NotificationHistoryDetailsModal.styles';
import {NotificationHistorySender} from './NotificationHistorySender';

type NotificationHistoryDetailsModalProps = {
    playerMessage: PlayerMessage;
};

export const NotificationHistoryDetailsModal = ({playerMessage}: NotificationHistoryDetailsModalProps) => {
    const {title, sender_id, sent_at, content, sender} = playerMessage;
    const {classes} = useNotificationHistoryDetailsModalClasses();
    const formattedDate = formatTimestamp(sent_at, 'date');

    return (
        <Box>
            <Box className={classes.notificationHistoryDetailsModalHeader}>
                <Box className={classes.notificationHistoryDetailsModalHeaderSender}>
                    <Typography variant="body2" color="textSecondary">
                        <LocalizedText label={localizedNotificationHistoryDetailsModal.sender} />
                    </Typography>
                    <NotificationHistorySender senderId={sender_id} />
                </Box>
                <Box className={classes.notificationHistoryDetailsModalHeaderDate}>
                    <Typography variant="body2" color="textSecondary">
                        <LocalizedText label={localizedNotificationHistoryDetailsModal.date} />
                    </Typography>
                    <Box>{formattedDate}</Box>
                </Box>
            </Box>
            <Box className={classes.notificationHistoryDetailsModalBody}>
                <Box className={classes.notificationHistoryDetailsModalBodyTitle}>{title}</Box>
                <Box className={classes.notificationHistoryDetailsModalBodyContent}>{content}</Box>
                <Box>{sender}</Box>
            </Box>
        </Box>
    );
};
