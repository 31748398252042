import {ActionType, createAction} from 'typesafe-actions';

import {EntityType} from '@redux/entity';
import {ViewType} from '@redux/view';

import {domain, RealtimeMessageTrigger} from './types';

type RealtimeSubscriptionActionBasePayload = {
    entity: EntityType;
    view: ViewType;
    triggers: {
        type: RealtimeMessageTrigger;
        args?: unknown;
    }[];

    pauseResume?: boolean;
};

export type RealtimeSubscribeActionPayload = RealtimeSubscriptionActionBasePayload;

export type RealtimeUnsubscribeActionPayload = RealtimeSubscriptionActionBasePayload;

export type RealtimeSaveSubscriberActionPayload = RealtimeSubscriptionActionBasePayload;

export type RealtimeRemoveSubscriberActionPayload = RealtimeSubscriptionActionBasePayload;

export type RealtimeEventActionPayload = {
    entity: EntityType;
    items: unknown[];
    trigger: {
        type: RealtimeMessageTrigger;
        args?: unknown;
    };
};

export type RealtimeSaveUpdatedViewActionPayload = {
    entity: EntityType;
    views: ViewType[];
};

export const realtimeActions = {
    subscribe: createAction(`${domain}/subscribe`)<RealtimeSubscribeActionPayload>(),
    unsubscribe: createAction(`${domain}/unsubscribe`)<RealtimeUnsubscribeActionPayload>(),
    saveSubscriber: createAction(`${domain}/saveSubscriber`)<RealtimeSaveSubscriberActionPayload>(),
    removeSubscriber: createAction(`${domain}/removeSubscriber`)<RealtimeRemoveSubscriberActionPayload>(),
    event: createAction(`${domain}/event`)<RealtimeEventActionPayload>(),
    saveUpdatedViews: createAction(`${domain}/saveUpdatedViews`)<RealtimeSaveUpdatedViewActionPayload>(),
    cleanUpdatedViews: createAction(`${domain}/cleanUpdatedViews`)(),
    updateViews: createAction(`${domain}/updateViews`)(),
    pause: createAction(`${domain}/pause`)(),
    resume: createAction(`${domain}/resume`)(),
};

export type RealtimeActions = ActionType<typeof realtimeActions>;
