import {inject, injectable} from 'inversify';
import {denormalize, normalize, NormalizedSchema} from 'normalizr';
import {merge, of} from 'rxjs';
import {bufferTime, filter} from 'rxjs/operators';
import {Action, isActionOf, PayloadAction} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {map, mergeMap, switchMap} from '@otel';
import {RootEpic} from '@redux';
import {BaseEpicsBuilder} from '@redux';
import {EntitiesState, entityActions, EntityFetchRequestPayload, EntityState, EntityType} from '@redux/entity';
import {schemaMapper} from '@redux/entity';
import {entitiesSelector} from '@redux/entity';
import {viewActions, ViewData, viewsSelector, ViewsState, ViewType} from '@redux/view';
import {IEntityReadService} from '@services/entity';
import {IRealtimeService} from '@services/RealtimeService';
import {ServerResponseStatus} from '@services/types';

import {realtimeActions, RealtimeEventActionPayload} from './actions';
import {RealtimeState} from './reducers';
import {realtimeSelector} from './selectors';
import {RealtimeUpdatesMode} from './types';
import {equals, getFlattenedNestedFieldValuesDenormalized, getSubscriptionKey} from './utils';

@injectable()
export class RealtimeEpicsBuilder extends BaseEpicsBuilder {
    private readonly _fetchServiceFactory: (entityType: EntityType) => IEntityReadService;
    private readonly _realtimeService: IRealtimeService;
    constructor(
        @inject(ServiceTypes.RealtimeService) realtimeService: IRealtimeService,
        @inject(ServiceTypes.EntityReadServiceFactory) fetchServiceFactory: (entityType: EntityType) => IEntityReadService
    ) {
        super();
        this._realtimeService = realtimeService;
        this._fetchServiceFactory = fetchServiceFactory;
    }

    protected buildEpicList(): RootEpic[] {
        const subscribeEpics = this.buildSubscribeEpics();
        const notifyEpics = this.buildNotifyEpics();
        const updateViewsEpics = this.buildUpdateViewsEpic();
        return [...subscribeEpics, ...notifyEpics, ...updateViewsEpics];
    }

    public buildSubscribeEpics() {
        const subscribeEpic: RootEpic = action$ =>
            action$.pipe(
                filter(isActionOf(realtimeActions.subscribe)),
                switchMap(action => {
                    return this._realtimeService
                        .subscribe({
                            entity: action.payload.entity,
                            triggers:
                                action.payload.triggers?.map(t => ({
                                    ...t,
                                    key: getSubscriptionKey(action.payload.entity, t.type, t.args),
                                })) ?? [],
                        })
                        .pipe(
                            filter(res => res.responsePayload?.length && !res.errors?.length),
                            map(res => {
                                return realtimeActions.event({
                                    items: res.responsePayload,
                                    entity: res.requestPayload.entity,
                                    trigger: res.requestPayload.triggers?.[0],
                                });
                            })
                        );
                })
            );

        const saveSubscriberEpic: RootEpic = (action$, _) =>
            action$.pipe(
                filter(isActionOf(realtimeActions.subscribe)),
                filter(action => !action.payload.pauseResume),
                switchMap(action => {
                    return of(realtimeActions.saveSubscriber({...action.payload}));
                })
            );

        const unsubscribeEpic: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(realtimeActions.unsubscribe)),
                mergeMap(action => {
                    const {entity, triggers, pauseResume} = action.payload;
                    const unsubcribeTriggers = triggers.flatMap(trigger => {
                        const subscriptionKey = getSubscriptionKey(entity, trigger.type, trigger.args);
                        const activeSubscribers = state$?.value?.realtime?.subscribers?.[entity]?.[subscriptionKey]?.length;
                        //it means this subscriber is the latest - need to unsubscribe
                        return activeSubscribers > 1 && !pauseResume ? [] : [trigger];
                    });

                    return unsubcribeTriggers?.length
                        ? this._realtimeService
                              .unsubscribe({
                                  entity,
                                  triggers: unsubcribeTriggers?.map(t => ({...t, key: getSubscriptionKey(entity, t.type, t.args)})),
                              })
                              .pipe(mergeMap(() => of()))
                        : of();
                })
            );

        const removeSubscriberEpic: RootEpic = (action$, _) =>
            action$.pipe(
                filter(isActionOf(realtimeActions.unsubscribe)),
                filter(action => !action.payload.pauseResume),
                switchMap(action => {
                    return of(realtimeActions.removeSubscriber({...action.payload}));
                })
            );

        return [subscribeEpic, saveSubscriberEpic, unsubscribeEpic, removeSubscriberEpic];
    }

    public buildNotifyEpics() {
        const notifyBufferTime = 2000;
        const notifyUpdates: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(realtimeActions.event)),
                bufferTime(notifyBufferTime),
                mergeMap(actions => {
                    const viewsState = viewsSelector(state$.value);
                    const entityState = entitiesSelector(state$.value);
                    const realtimeState = realtimeSelector(state$.value);
                    const views = this.getSubscribedViews(actions, realtimeState);
                    const subscribedEntities = this.getSubscribedEntities(actions, realtimeState);
                    const viewsData = [...new Set(views)].map(view => viewsState[view]);

                    const fetchPayloads = this.prepareFetchActionPayloadsForIdleViews(viewsData, subscribedEntities);
                    const fetchRequests = fetchPayloads.map(r => this._fetchServiceFactory(r.type).get(r));
                    const fetchActions = merge(...fetchRequests).pipe(
                        map(res => {
                            let resultAction: PayloadAction<string, unknown> = null;

                            if (res.status === ServerResponseStatus.Success) {
                                const {type, filter} = res.requestPayload;
                                const {items, total} = res.responsePayload;
                                const {fetchedKeys} = this.normalizeResponse(type, items);

                                let updatedViews = this.findUpdatedViewsByKeys(type, filter, fetchedKeys, total, viewsState);
                                if (!updatedViews.length) {
                                    updatedViews = this.findUpdatedViewsByFieldValues(type, filter, items, viewsState, entityState);
                                }

                                resultAction = this.prepareUpdatesTriggerActionPayload(updatedViews, type, filter, items, total);
                            }

                            return resultAction;
                        }),
                        filter(res => res !== null),
                        mergeMap(action => {
                            return of(action);
                        })
                    );

                    const retriedActionPayloads = this.prepareRetriedActionPayloadsForInProgressViews(actions, views, viewsState);
                    const retryActions =
                        retriedActionPayloads.length > 0 ? merge(retriedActionPayloads.map(e => realtimeActions.event(e))) : of();

                    return merge(retryActions, fetchActions);
                })
            );

        return [notifyUpdates];
    }

    public buildUpdateViewsEpic() {
        const updateViewsEpic: RootEpic = (action$, state$) =>
            action$.pipe(
                filter(isActionOf(realtimeActions.updateViews)),
                mergeMap(() => {
                    const realtime = realtimeSelector(state$.value);
                    const updates: Partial<Record<string, ViewType[]>> = {};
                    Object.keys(realtime.updates).map((view: ViewType) => {
                        realtime.updates[view].entities.forEach(entity => {
                            if (!updates[entity]) {
                                updates[entity] = [];
                            }

                            if (!updates[entity].includes(view)) {
                                updates[entity].push(view);
                            }
                        });
                    });
                    const actions: Action[] = Object.keys(updates).map((entity: EntityType) =>
                        viewActions.update({entity, views: updates[entity]})
                    );
                    actions.push(realtimeActions.cleanUpdatedViews());
                    return of(...actions);
                })
            );

        return [updateViewsEpic];
    }

    private getSubscribedViews(actions: PayloadAction<string, RealtimeEventActionPayload>[], realtimeState: RealtimeState): ViewType[] {
        const views: ViewType[] = [];
        actions.forEach(action => {
            const {entity, trigger} = action.payload;
            const subscriptionKey = getSubscriptionKey(entity, trigger.type, trigger.args);
            const viewsPerEntityAndTrigger: ViewType[] = realtimeState.subscribers?.[entity]?.[subscriptionKey];
            if (viewsPerEntityAndTrigger?.length) {
                views.push(...viewsPerEntityAndTrigger);
            }
        });

        return views;
    }

    private getSubscribedEntities(
        actions: PayloadAction<string, RealtimeEventActionPayload>[],
        realtimeState: RealtimeState
    ): Partial<Record<EntityType, boolean>> {
        const subscribedEntities: Partial<Record<EntityType, boolean>> = {};
        actions.forEach(action => {
            const {entity, trigger} = action.payload;
            const subscriptionKey = getSubscriptionKey(entity, trigger.type, trigger.args);
            const viewsPerEntityAndTrigger: ViewType[] = realtimeState.subscribers?.[entity]?.[subscriptionKey];
            if (viewsPerEntityAndTrigger?.length) {
                subscribedEntities[entity] = true;
            }
        });

        return subscribedEntities;
    }

    private prepareRetriedActionPayloadsForInProgressViews(
        actions: PayloadAction<string, RealtimeEventActionPayload>[],
        subscribedViews: ViewType[],
        viewsState: ViewsState
    ): RealtimeEventActionPayload[] {
        const retriedEvents: RealtimeEventActionPayload[] = [];
        actions.forEach(action => {
            const {entity} = action.payload;
            subscribedViews.forEach(viewType => {
                if (viewsState?.[viewType]?.entities?.[entity]?.status === 'inProgress') {
                    retriedEvents.push(action.payload);
                }
            });
        });

        return retriedEvents;
    }

    private prepareFetchActionPayloadsForIdleViews(
        viewStates: ViewData[],
        subscribedEntities: Partial<Record<EntityType, boolean>>
    ): EntityFetchRequestPayload[] {
        //group by filter and entity and prepare fetch requests
        const fetchPayloads: EntityFetchRequestPayload[] = [];
        viewStates.forEach(viewState => {
            Object.values(viewState.entities)
                .filter(e => subscribedEntities[e.entity] && e.status === 'idle')
                .forEach(viewEntity => {
                    let fetchRequest = fetchPayloads.find(r => r.type === viewEntity.entity && r.filter === viewEntity.filter);

                    if (!fetchRequest) {
                        fetchRequest = {type: viewEntity.entity, filter: viewEntity.filter, fields: []};
                        fetchPayloads.push(fetchRequest);
                    }

                    fetchRequest.fields = viewEntity.fields?.length
                        ? [...new Set(fetchRequest.fields.concat(viewEntity.fields))]
                        : fetchRequest.fields;
                });
        });

        return fetchPayloads;
    }

    private normalizeResponse(type: EntityType, items: unknown[]) {
        let fetchedEntities: Partial<Record<EntityType, Record<string, unknown>>> = {};
        let fetchedKeys: string[] = [];

        if (items?.length) {
            const schema = schemaMapper.getSchema(type);
            const normalizedItems: NormalizedSchema<Record<EntityType, Record<string, unknown>>, string[]> = normalize(items, [schema]);
            fetchedEntities = normalizedItems.entities;
            fetchedKeys = normalizedItems.result;
        }

        return {fetchedEntities, fetchedKeys};
    }

    private denormalizeKeys(type: EntityType, keys: string[], state: Partial<Record<EntityType, EntityState>>) {
        let result: unknown[] = [];
        if (keys?.length) {
            const schema = schemaMapper.getSchema(type);
            result = denormalize(keys, [schema], state);
        }

        return result;
    }

    private findUpdatedViewsByKeys(type: EntityType, filter: string, fetchedKeys: string[], total: number, viewState: ViewsState) {
        let result: ViewData[] = [];

        const entityFilterBasedViews = Object.values(viewState)?.filter(v => v.entities[type]?.filter === filter);
        if (entityFilterBasedViews?.length) {
            const firstView = entityFilterBasedViews[0].entities[type];
            const existingKeys = firstView.keys;
            const existingTotal = firstView.total;
            if (
                total !== existingTotal ||
                fetchedKeys.length !== existingKeys.length ||
                fetchedKeys.find((key, index) => !equals(key, existingKeys[index]))
            ) {
                result = entityFilterBasedViews;
            }
        }

        return result;
    }

    private findUpdatedViewsByFieldValues(
        type: EntityType,
        filter: string,
        items: unknown[],
        viewState: ViewsState,
        entityState: EntitiesState
    ) {
        const result: ViewData[] = [];

        const entityFilterBasedViews = Object.values(viewState)?.filter(v => v.entities[type]?.filter === filter);
        entityFilterBasedViews.forEach(view => {
            const viewEntityState = view.entities[type];
            const denormalizedEntityState = this.denormalizeKeys(type, view.entities[type].keys, entityState.entities);
            const changed =
                viewEntityState.fields?.find(field => {
                    const fetchedFieldValues = getFlattenedNestedFieldValuesDenormalized(field, items);
                    const stateFieldValues = getFlattenedNestedFieldValuesDenormalized(field, denormalizedEntityState);
                    return (
                        fetchedFieldValues.length !== stateFieldValues.length ||
                        fetchedFieldValues.find((value, index) => !equals(value, stateFieldValues[index]))
                    );
                }) ?? null;

            if (changed) {
                result.push(view);
            }
        });

        return result;
    }

    private prepareUpdatesTriggerActionPayload(
        updatedViews: ViewData[],
        type: EntityType,
        filter: string,
        items: unknown[],
        total: number
    ) {
        let result: PayloadAction<string, unknown> = null;
        if (updatedViews?.length) {
            const silentlyUpdatedViews = updatedViews.filter(v => v.realtime[type].mode === RealtimeUpdatesMode.Silent);
            if (silentlyUpdatedViews?.length) {
                result = entityActions.fetchRequest.success(
                    {
                        requestPayload: {
                            fields: [...new Set(updatedViews.flatMap(v => v.entities[type].fields))],
                            filter,
                            type,
                        },
                        responsePayload: {
                            items,
                            total,
                        },
                        status: ServerResponseStatus.Success,
                    },
                    {}
                );
            } else {
                result = realtimeActions.saveUpdatedViews({
                    entity: type,
                    views: updatedViews.map(v => v.viewType as ViewType),
                });
            }
        }

        return result;
    }
}
