import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {ListWithChipTooltip} from '@components/chip/ListWithChipTooltip';
import {GamePlatform} from '@models/game-template';

export type GamePlatformsCellProps = {
    visiblePlatform: GamePlatform;
    hiddenPlatforms: GamePlatform[];
};

export function GamePlatformsCell({visiblePlatform, hiddenPlatforms}: GamePlatformsCellProps) {
    return (
        <ListWithChipTooltip
            visibleElement={<MemoizedChip value={visiblePlatform?.toString()} chipName={nameof<GamePlatform>()} />}
            hiddenElements={hiddenPlatforms?.map(p => (
                <MemoizedChip value={p?.toString()} chipName={nameof<GamePlatform>()} />
            ))}
        />
    );
}

export function renderGamePlatformCell(params: GridCellParams) {
    const value: GamePlatformsCellProps = params.value as GamePlatformsCellProps;
    return <GamePlatformsCell {...value} />;
}
