import {makeStyles} from 'tss-react/mui';

export const useClasses = makeStyles()(theme => ({
    multiInputEditableText: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: theme.spacing(1),
        overflow: 'hidden',
    },

    multiInputEditableTextInput: {
        width: 'auto',
        '& > .Mui-disabled': {
            color: 'inherit',
        },
    },

    multiInputEditableTextReadonly: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
