import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {GridColDef} from '@components/data-grid/mui';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {Policy} from '@auth';

import {withModule} from '../../../features/module-shared/components/ModuleHoc';
import ModulePage from '../../../features/module-shared/components/ModulePage';
import actions from '../actions';
import {domain} from '../types';

import RoleEditForm from './RoleEditForm';
import data from './rolesManagementData';

const RolesManagement = () => {
    const stateData = useSelector((state: RootState) => state.roles);
    return (
        <Fragment>
            <ModulePage
                data={stateData}
                domain={domain}
                columns={data.columns as GridColDef[]}
                header={data.localized.title}
                actions={data.actions}
                form={
                    <RoleEditForm
                        domain={domain}
                        headers={data.columns as GridColDef[]}
                        headerItemName={data.localized.item}
                        stateSelector={(state: RootState) => state.roles.data.editForm}
                        submitAction={actions.saveRole.request}
                        closeAction={actions.closeRoleForm}
                        isReadonly={() => true}
                    />
                }
                defaultSorting={[]}
            />
        </Fragment>
    );
};

export const rolesManagementResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'RolesManagement',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.AccessManagement, null, 'read'),
        new Policy(ModuleName.AccessManagement, SubmoduleName.Roles, 'read'),
    ]),
});

export default withModule(RolesManagement, [
    {moduleName: ModuleName.AccessManagement, submoduleName: SubmoduleName.Roles, permissions: [PermissionEnum.Read]},
]);
