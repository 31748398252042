import React from 'react';

import withModuleFilter, {FilterInnerProps} from '../../shared/filter/components/ModuleFilterHoc';

import {LabelFilter} from './LabelFilter';

function LabelFilterAdapter({value, onSubmit, filter}: FilterInnerProps<string[]>) {
    function handleChange(values: string[]) {
        return onSubmit({key: filter?.key, value: values});
    }
    return <LabelFilter value={value?.[0]?.value} onChange={handleChange} />;
}

export default withModuleFilter(LabelFilterAdapter);
