import React, {useRef, useState} from 'react';
import {defineMessages} from 'react-intl';
import {useSelector} from 'react-redux';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {renderDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {IconButtonDropdown} from '@components/dropdown/Dropdown';
import {CustomTheme} from '@style';

import {BulkSecurityCasesResultValue, securityCaseAllResultValueSelector} from '../../block-bulk-actions';
import {SecurityCaseEditModel} from '../../player-actions/cases-actions/types';

import {BulkUserProfileSecurityCasesDropdownValue} from './BulkUserProfileSecurityCasesDropdownValue';

const localized = defineMessages({
    bulkSecurityCasesResultValueDropdownAddedLabel: {
        id: 'addedLabel',
        defaultMessage: 'Added',
    },
    bulkSecurityCasesResultValueDropdownUpdatedLabel: {
        id: 'updatedLabel',
        defaultMessage: 'Updated',
    },
    bulkSecurityCasesResultValueDropdownRemovedLabel: {
        id: 'removedLabel',
        defaultMessage: 'Removed',
    },
});

const useClasses = makeStyles()((theme: CustomTheme) => ({
    bulkSecurityCasesContainer: {
        position: 'relative',
        overflow: 'visible !important',
        padding: '0 !important',
    },
    bulkSecurityCasesChip: {
        '&.MuiIconButton-root:hover': {
            background: 'none',
        },
    },
    bulkSecurityCasesListPaper: {
        width: 'auto',
        maxWidth: '300px',
        margin: 0,
    },
    bulkSecurityCasesListContainer: {
        padding: theme.spacing(2),
    },
    bulkSecurityCasesList: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
        margin: theme.spacing(2, 0),
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
}));

export const BulkUserProfileSecurityCasesDropdown = () => {
    const {classes} = useClasses();
    const containerRef = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState(false);
    const handleDropdownOpen = () => {
        setOpen(true);
    };

    const handleDropdownClose = () => {
        setOpen(false);
    };

    const value: BulkSecurityCasesResultValue = useSelector(securityCaseAllResultValueSelector);

    const getChangedCasesCount = () => {
        const sumCases = (initialCount: number, changedCases: SecurityCaseEditModel[]) =>
            changedCases?.length ? initialCount + changedCases.length : initialCount;

        return [value?.addedCases?.add, value?.updatedCases?.add, value?.removedCases?.remove].reduce(sumCases, 0);
    };

    const changedCasesCount = getChangedCasesCount();

    return changedCasesCount && value ? (
        <div className={classes.bulkSecurityCasesContainer} ref={containerRef}>
            <IconButtonDropdown
                value={changedCasesCount}
                buttonContent={renderDefaultChip(
                    changedCasesCount.toString(),
                    open ? ChipVariant.Blue : ChipVariant.DarkGrey,
                    ChipType.Status
                )}
                container={containerRef.current}
                onDropdownOpen={handleDropdownOpen}
                onDropdownClose={handleDropdownClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                className={classes.bulkSecurityCasesChip}
                styles={{
                    dropdownPaper: classes.bulkSecurityCasesListPaper,
                }}
                disableRipple
            >
                <Box className={classes.bulkSecurityCasesListContainer}>
                    {value?.addedCases?.add?.length ? (
                        <BulkUserProfileSecurityCasesDropdownValue
                            value={value?.addedCases?.add}
                            label={localized.bulkSecurityCasesResultValueDropdownAddedLabel}
                        />
                    ) : null}
                    {value?.updatedCases?.add?.length ? (
                        <BulkUserProfileSecurityCasesDropdownValue
                            value={value?.updatedCases?.add}
                            label={localized.bulkSecurityCasesResultValueDropdownUpdatedLabel}
                        />
                    ) : null}
                    {value?.removedCases?.remove?.length ? (
                        <BulkUserProfileSecurityCasesDropdownValue
                            value={value?.removedCases?.remove}
                            label={localized.bulkSecurityCasesResultValueDropdownRemovedLabel}
                        />
                    ) : null}
                </Box>
            </IconButtonDropdown>
        </div>
    ) : null;
};
