import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledAccordion from '@components/StyledAccordion';
import {agentReadPolicies} from '@models/permissions/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {configurationManager} from '../../block-user-profile-actions-address/config';
import {UserRegionRelatedInfo} from '../../workspace-details/user-region-related-info/components/UserRegionRelatedInfo';

const localized = defineMessages({
    regionInfo: {
        id: 'UD_regionInfo',
        defaultMessage: 'Country/Region Specific Info',
    },
});

const useUserRegionRelatedInfoClasses = makeStyles()(() => ({
    userDetailsRegionRelatedInfoContainer: {
        width: '100%',
    },
    userDetailsRegionRelatedInfo: {
        paddingTop: 0,
        boxShadow: 'none',
    },
}));

type UserDetailsRegionRelatedInfoProps = {
    userId: string;
    country: string;
};

function UserDetailsRegionRelatedInfoInner({userId, country}: UserDetailsRegionRelatedInfoProps) {
    const {classes} = useUserRegionRelatedInfoClasses();

    const regionFields = configurationManager.getFields(country);

    return regionFields?.length ? (
        <StyledAccordion
            header={localized.regionInfo}
            componentId="regionInfo"
            unmountOnCollapse
            isCollapsed
            children={
                <Box className={classes.userDetailsRegionRelatedInfoContainer}>
                    <UserRegionRelatedInfo itemId={userId} fields={regionFields} hideTitle />
                </Box>
            }
        />
    ) : null;
}

export const restrictedPermissions = agentReadPolicies;

export const UserDetailsRegionRelatedInfo = withMultiplePermission(UserDetailsRegionRelatedInfoInner, {restrictedPermissions});
