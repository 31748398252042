import {MessageDescriptor} from 'react-intl';

import {PolicyRestrictedResource} from '@access-control';
import {KeyValuePair} from '@components/KeyValueTable';

import {FeatureName} from '../features/app/config/types';

import {RouteUrl} from './routeEnums';

//TODO: [BO-2698] Move type to src/features/app/routing
export type Route = {
    name: string;
    url: RouteUrl;
    title: MessageDescriptor;
    subRoutes?: Route[];
    menuIcon?: string;
    featureName?: FeatureName;
    resource?: PolicyRestrictedResource;
};

//TODO: [BO-2698] Move type to src/features/app/routing
export interface IBreadcrumb {
    url: string;
    title: MessageDescriptor | string;
    clickable?: boolean;
}

//TODO: [BO-2699] Move type to src/common/models
export class ItemsPage<T> {
    items: T[];
    total: number;
}

//TODO: [BO-2699] Move type to src/common/models
export type Filter<T = any, TFilterKey = string | FilterKey> = {
    key: TFilterKey;
    value: T;
};

//TODO: [BO-2699] Move type to src/common/models
export enum FilterKey {
    FullTextSearch = 'q',
}

//TODO: [BO-2699] Move type to src/common/models
export type Sorting<TField extends string | number | symbol> = {
    field: TField;
    sort: 'asc' | 'desc' | null | undefined | string;
};

//TODO: [BO-2699] Move type to src/common/models
export type Paging = {
    page: number;
    pageSize: number;
};

//TODO: [BO-2699] Move type to src/common/models
export type SearchFilter = {
    selectedItemId?: string;
    filter: Filter[];
    sorting: Sorting<string>[];
    paging: Paging;
    extend?: string[];
};

//TODO: [BO-2699] Move type to src/common/models
export enum DocumentType {
    Excel,
    Pdf,
}

//TODO: [BO-2702] Move type to features/notification
export type LocalizedMessageData = {
    message: MessageDescriptor;
    values?: Record<string, any>;
};

//TODO: [BO-2699] Move type to src/common/models (think if this type is really needed)
export class ColumnConfiguration {
    [key: string]: boolean;
}

//TODO: [BO-2699] Move type to src/common/models (think if this type is really needed)
export interface ConfigurationKeyValuePair extends KeyValuePair {
    field: string;
    configuration?: string[];
}

//TODO: [BO-2703] Move files to appropriate features folder or src/common/utils
export const emptyField = '';
