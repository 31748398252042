import React from 'react';
import MenuListItem from '@mui/material/MenuItem';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon, Icon, IconColor} from '@components/icons';
import {CustomTheme} from '@style';

import {MemoizedDefaultChip, MemoizedDefaultChipProps} from '../chip/ChipRenderer';

import {DropdownItemProps} from './DropdownItem';

const useDropdownChipItemClasses = makeStyles<void, 'dropdownChipItemIcon'>()((theme: CustomTheme, _params, classes) => ({
    dropdownChipItem: {
        display: 'flex',
        columnGap: theme.spacing(2),
        '& .MuiChip-root': {
            transition: 'none',
            border: `1px solid ${theme.custom.palette.content.border}`,
            cursor: 'pointer',
        },
        [`&:hover .${classes.dropdownChipItemIcon}`]: {
            visibility: 'visible',
        },
    },
    dropdownChipItemColor: {
        '& .MuiChip-root': {
            color: theme.custom.palette.status.dropdownDefault.main,
            background: theme.custom.palette.status.dropdownDefault.light,
        },
        '&:hover .MuiChip-root': {
            color: theme.custom.palette.status.dropdownDefault.hoverMain,
            background: theme.custom.palette.status.dropdownDefault.hoverLight,
        },
    },
    dropdownChipItemIcon: {
        marginLeft: 'auto',
        visibility: 'hidden',
    },
}));

//TODO: [BO-2669] Move dropdown components to input folder (?)
type DropdownChipItemProps = Omit<DropdownItemProps, 'label'> & MemoizedDefaultChipProps;

export const DropdownChipItem = ({value, variant, type, ...menuItemProps}: DropdownChipItemProps) => {
    const {classes} = useDropdownChipItemClasses();

    return (
        <MenuListItem {...menuItemProps} key={value} className={`${classes.dropdownChipItem} ${classes.dropdownChipItemColor}`}>
            <MemoizedDefaultChip value={value} variant={variant} type={type} />
            <Icon icon={CustomIcon.Confirm} color={IconColor.Primary} className={classes.dropdownChipItemIcon} />
        </MenuListItem>
    );
};
