import {createAction} from 'typesafe-actions';

import {LocalizedMessageData} from 'src/common/types';
import {KeyValueDataStorageItem} from '../module-shared/types';

const domain = 'module-temp-storage';

const subscribeToUpdates = '/subscribeToGQLUpdates';

const changeInProgressState = '/changeInProgressState';
const changeInProgressStateBatch = '/changeInProgressStateBatch';

const changeErrorState = '/changeErrorState';
const changeInfoState = '/changeInfoState';
const changeEditState = '/changeEditState';

const changeLoadingState = '/changeLoadingState';
const changeLoadingData = '/changeLoadingData';

export default {
    subscribeToGQLUpdates: createAction(`${domain}${subscribeToUpdates}`)<void>(),

    changeInProgressState: createAction(`${domain}${changeInProgressState}`)<KeyValueDataStorageItem<boolean>>(),
    changeInProgressStateBatch: createAction(`${domain}${changeInProgressStateBatch}`)<KeyValueDataStorageItem<boolean>[]>(),

    changeErrorState: createAction(`${domain}${changeErrorState}`)<KeyValueDataStorageItem<LocalizedMessageData[]>>(),
    changeInfoState: createAction(`${domain}${changeInfoState}`)<KeyValueDataStorageItem<LocalizedMessageData[]>>(),
    changeEditState: createAction(`${domain}${changeEditState}`)<KeyValueDataStorageItem<boolean>>(),

    changeLoadingState: createAction(`${domain}${changeLoadingState}`)<KeyValueDataStorageItem<boolean>>(),
    changeLoadingData: createAction(`${domain}${changeLoadingData}`)<KeyValueDataStorageItem<any>>(),
};
