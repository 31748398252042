import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ReferralSettings, ReferralSettingsInput} from '@models/generated/graphql';
import {Filter, ReferralSettingsQueryFields} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

import {ServiceTypes} from '../inversify';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

export interface IReferralSettingsUpdateService {
    editRevenueShare(
        referralSettingsInput: ReferralSettingsInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest, ReferralSettings>>;
    editExpirationPeriod(
        referralSettingsInput: ReferralSettingsInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest, ReferralSettings>>;
}

@injectable()
export class ReferralSettingsService
    extends EntityBaseGqlService<unknown, ReferralSettingsQueryFields>
    implements IReferralSettingsUpdateService
{
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new ReferralSettingsRequestBuilder());
    }

    public editRevenueShare(
        referralSettingsInput: ReferralSettingsInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest, ReferralSettings>> {
        return this._service
            .mutate(this.getUpdateDefaultPlayerReferralSettingsMutation(), {referralSettings: referralSettingsInput})
            .pipe(map(r => ({...r, responsePayload: r.responsePayload.updateDefaultPlayerReferralSettings})));
    }

    public editExpirationPeriod(
        referralSettingsInput: ReferralSettingsInput
    ): Observable<ServiceResponsePayload<GqlMutationRequest, ReferralSettings>> {
        return this._service
            .mutate(this.getUpdateDefaultPlayerReferralSettingsMutation(), {referralSettings: referralSettingsInput})
            .pipe(map(r => ({...r, responsePayload: r.responsePayload.updateDefaultPlayerReferralSettings})));
    }

    private getUpdateDefaultPlayerReferralSettingsMutation() {
        return gql`
            mutation UpdateDefaultPlayerReferralSettings($referralSettings: ReferralSettingsInput!) {
                updateDefaultPlayerReferralSettings(referralSettings: $referralSettings) {
                    revenue_share
                    expiration_period
                }
            }
        `;
    }
}

export class ReferralSettingsRequestBuilder extends GqlRequestBuilder<unknown, ReferralSettingsQueryFields> {
    protected buildFilter(_filter: Filter): object {
        return {};
    }

    public buildQuery(fields: ReferralSettingsQueryFields[]): DocumentNode {
        return gql`
            query GetReferralSettings {
                getReferralSettings {
                    revenue_share ${this.hasQueryItem(fields, 'revenue_share')}
                    expiration_period ${this.hasQueryItem(fields, 'expiration_period')}
                }
            }
        `;
    }
}
