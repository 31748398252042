import {RootState} from 'typesafe-actions';

import {JurisdictionConfiguration} from '../../features/app/config/types';

const LOCALE_LOCALSTORAGE_KEY = 'locale';

export const loadState = () => {
    try {
        const locale = localStorage.getItem(LOCALE_LOCALSTORAGE_KEY);
        const config = {locale: locale ? JSON.parse(locale) : undefined} as JurisdictionConfiguration;
        return {jurisdictionConfig: config};
    } catch (error) {
        return undefined;
    }
};

export const saveState = (state: RootState) => {
    try {
        const locale = JSON.stringify(state.jurisdictionConfig.locale);
        localStorage.setItem(LOCALE_LOCALSTORAGE_KEY, locale);
    } catch (error) {
        //do nothing
    }
};
