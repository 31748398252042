import {defineMessages} from 'react-intl';

export const localizedHeaders = defineMessages({
    bulkResultsGridRowIndex: {
        id: 'bulkResultsGridRowIndex',
        defaultMessage: 'Row Index',
    },
    bulkResultsGridError: {
        id: 'bulkResultsGridError',
        defaultMessage: 'Error',
    },
});
