import React, {RefObject} from 'react';
import {Box} from '@mui/material';
import Checkbox, {CheckboxProps} from '@mui/material/Checkbox';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon} from '@components/icons';
import {CustomTheme} from '@style';

//TODO: [BO-2655] Move files to src/common/components/input
const useStyles = makeStyles()((theme: CustomTheme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    checkedIcon: {
        background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        boxSizing: 'border-box',
        borderRadius: 3,
    },
    checkedIconInner: {
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 0,
        margin: 0,
    },
    styledCheckboxInput: {
        zIndex: 'unset',
    },
}));

type CheckedIconType = 'checkmark' | 'minus';

type StyledCheckboxProps = CheckboxProps & {
    checkedIconType?: 'checkmark' | 'minus';
};

export const StyledCheckbox = React.forwardRef(({checkedIconType, ...props}: StyledCheckboxProps, ref: RefObject<HTMLButtonElement>) => {
    const {classes, cx} = useStyles();

    const checkedIcon = getIcon('checkmark');

    const minusIcon = getIcon('minus');

    function getIcon(type: CheckedIconType) {
        return (
            <Box className={cx(classes.checkedIcon, classes.icon)}>
                <Box component="span" className={`${classes.checkedIconInner} ${type !== 'minus' ? CustomIcon.Confirm : ''}`} fontSize={9}>
                    {type === 'minus' ? '—' : ''}
                </Box>
            </Box>
        );
    }

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={checkedIconType === 'minus' ? minusIcon : checkedIcon}
            icon={<Box className={classes.icon} />}
            inputProps={{'aria-label': 'decorative checkbox', className: classes.styledCheckboxInput}}
            {...props}
            ref={ref}
        />
    );
});
