import {createAction, createAsyncAction} from 'typesafe-actions';

import {BoRoleList, BoUser} from '@redux/entity';

import {gridModuleActions} from '../../features/module-shared/actions';
import {FormModuleData, IFailureResponse, IFormItem} from '../../features/module-shared/types';
import {PatchPayload} from '../../features/module-temp-storage/types';

import {domain} from './types';

const saveUserAction = '/saveUser';
const saveUserSucceedAction = '/saveUserSucceed';
const saveUserFailedAction = '/saveUserFailed';

const reset2FAAction = '/reset2FA';
const reset2FASucceedAction = '/reset2FASucceed';
const reset2FAFailedAction = '/reset2FAFailed';

const resetPasswordAction = '/resetPassword';
const resetPasswordSucceedAction = '/resetPasswordSucceed';
const resetPasswordFailedAction = '/resetPasswordFailed';

const openUserForm = '/openUserForm';
const closeUserForm = '/closeUserForm';

const getRolesAction = '/getRoles';
const getRolesSucceedAction = '/getRolesSucceed';
const getRolesFailedAction = '/getRolesFailed';

const setLoginAccessStatusAction = '/setLoginAccessStatus';
const setLoginAccessStatusSucceedAction = '/setLoginAccessStatusSucceed';
const setLoginAccessStatusFailedAction = '/setLoginAccessStatusFailed';

export default {
    ...gridModuleActions(domain),

    saveUser: createAsyncAction(`${domain}${saveUserAction}`, `${domain}${saveUserSucceedAction}`, `${domain}${saveUserFailedAction}`)<
        FormModuleData<BoUser>,
        IFormItem,
        IFailureResponse
    >(),

    openUserForm: createAction(`${domain}${openUserForm}`)<BoUser>(),

    closeUserForm: createAction(`${domain}${closeUserForm}`)<void>(),

    getRoles: createAsyncAction(`${domain}${getRolesAction}`, `${domain}${getRolesSucceedAction}`, `${domain}${getRolesFailedAction}`)<
        void,
        BoRoleList,
        IFailureResponse
    >(),

    reset2FA: createAsyncAction(`${domain}${reset2FAAction}`, `${domain}${reset2FASucceedAction}`, `${domain}${reset2FAFailedAction}`)<
        string,
        void,
        IFailureResponse
    >(),

    resetPassword: createAsyncAction(
        `${domain}${resetPasswordAction}`,
        `${domain}${resetPasswordSucceedAction}`,
        `${domain}${resetPasswordFailedAction}`
    )<string, void, IFailureResponse>(),

    setLoginAccessStatus: createAsyncAction(
        `${domain}${setLoginAccessStatusAction}`,
        `${domain}${setLoginAccessStatusSucceedAction}`,
        `${domain}${setLoginAccessStatusFailedAction}`
    )<PatchPayload, PatchPayload, PatchPayload>(),
};
