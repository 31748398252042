import React from 'react';
import {MessageDescriptor} from 'react-intl';
import MenuListItem from '@mui/material/MenuItem';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';

import {useDropdownItemClasses} from './DropdownItem.style';

//TODO: [BO-2669] Move dropdown components to input folder (?)
export type DropdownItemProps = {
    selected?: boolean;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    href?: string;
    icon?: JSX.Element;
    label: string | MessageDescriptor;
    component?: React.ElementType;
    className?: string;
    confirmIcon?: boolean;
};

export const DropdownItem = ({icon, label, confirmIcon, className, ...menuItemProps}: DropdownItemProps) => {
    const {classes} = useDropdownItemClasses();
    const key = typeof label === 'string' ? label : label.id;

    return (
        <MenuListItem className={`${classes.dropdownItem} ${className}`} {...menuItemProps} key={key}>
            {icon ? <span className={classes.dropdownItemIcon}>{icon}</span> : <></>}
            {label ? <LocalizedText label={label} /> : null}
            {confirmIcon ? <Icon icon={CustomIcon.Confirm} color={IconColor.Primary} className={classes.dropdownItemConfirmIcon} /> : null}
        </MenuListItem>
    );
};
