import React from 'react';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {withEmptyCheck} from '@components/label/EmptyLabel';
import {AmlLabel} from '@models/generated/graphql';

import {WorkspaceList} from './WorkspaceList';

type AmlLabelListProps = {
    value: AmlLabel[];
};

export const AmlLabelList = withEmptyCheck(({value}: AmlLabelListProps) => {
    return (
        <WorkspaceList>
            {value?.map(amlLabel => (
                <MemoizedChip value={amlLabel} chipName={nameof<AmlLabel>()} />
            ))}
        </WorkspaceList>
    );
});
