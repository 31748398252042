import React from 'react';
import {Box, Button as MuiButton, Divider, MenuItem} from '@mui/material';
import {Pagination} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {localizedSelect} from '@components/select/Select';
import {useStyledPaginationClasses} from '@components/StyledPagination';
import {CustomTheme} from '@style';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    multiSelectFooterContainer: {
        position: 'sticky',
        bottom: 0,
        background: theme.custom.palette.content.backgroundWhite,
    },
    multiSelectFooterSingleButton: {
        paddingLeft: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        color: theme.custom.palette.content.primary,
        '&:hover': {
            textDecoration: 'none',
        },
    },
    multiSelectFooterButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    multiSelectFooterPaginationButtons: {
        display: 'flex',
        maxWidth: '250px',
    },
    multiSelectFooterButton: {
        border: 'none',
    },
}));

type MultiSelectFooterProps = {
    onClear?: (e: React.BaseSyntheticEvent) => void;
    showResetButton?: boolean;
    showPagination?: boolean;
    page?: number;
    pageSize?: number;
    total?: number;
    onPageChange?: (page: number) => void;
    showApplyButton?: boolean;
    onApply?: () => void;
};

export function MultiSelectFooter({
    showResetButton,
    onClear,
    showPagination,
    onPageChange,
    page,
    pageSize,
    total,
    showApplyButton,
    onApply,
}: MultiSelectFooterProps) {
    const totalPageCount = Math.ceil(total / pageSize);
    const {classes} = useStyles();
    const {classes: paginationClasses} = useStyledPaginationClasses();

    return showResetButton || showPagination ? (
        <Box className={classes.multiSelectFooterContainer} onMouseDown={e => e.stopPropagation()}>
            <Divider />
            {showPagination || showApplyButton ? (
                <div className={classes.multiSelectFooterButtons}>
                    {showPagination ? (
                        <Pagination
                            page={page ?? 1}
                            count={totalPageCount ?? 1}
                            size="small"
                            shape="rounded"
                            siblingCount={0}
                            variant="outlined"
                            className={paginationClasses.pagination}
                            onChange={(_, page) => onPageChange(page)}
                        />
                    ) : null}
                    {showResetButton ? (
                        <MuiButton
                            color="primary"
                            data-testid="clearSelectButton"
                            onClick={onClear}
                            className={classes.multiSelectFooterButton}
                        >
                            <LocalizedText label={localizedSelect.selectClearAll} />
                        </MuiButton>
                    ) : null}
                    {showApplyButton ? (
                        <Button
                            color="primary"
                            data-testid="applySelectButton"
                            onClick={onApply}
                            className={classes.multiSelectFooterButton}
                            label={localizedSelect.selectApply}
                        />
                    ) : null}
                </div>
            ) : (
                <MenuItem className={classes.multiSelectFooterSingleButton} data-testid="clearSelectButton" onClick={onClear}>
                    <LocalizedText label={localizedSelect.selectClearAll} />
                </MenuItem>
            )}
        </Box>
    ) : null;
}
