import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {CurrencyCell} from '@components/data-grid/mui';
import {KeyValueCell, KeyValueColumn, KeyValueTable} from '@components/key-value-table';
import {RealtimeUpdatesMode} from '@redux/realtime';

import {useJurisdictionConfig} from 'src/features/app/config/hooks';
import {useUserProfileDetails} from 'src/features/block-user-profile-details';
import {FeatureName} from '../../app/config/types';
import {withPermissionAndJurisdictionConfig} from '../../app/permission/PermissionHoc';

const localized = defineMessages({
    balanceCellName: {
        id: 'UserFinanceSummary_balanceCellName',
        defaultMessage: 'Current Balance',
    },
});

type UserFinanceSummaryProps = {
    uid: string;
};

type UseUserFinanceSummary = {
    balance: number;
    currency: string;
};

function useUserFinanceSummary(uid: string): UseUserFinanceSummary {
    const {currency} = useJurisdictionConfig();
    const {item} = useUserProfileDetails({
        id: uid,
        viewType: 'UserFinanceSummary',
        fields: ['finance.balance'],
        realtimeMode: RealtimeUpdatesMode.Confirm,
    });

    return {balance: item?.finance?.balance ?? null, currency};
}

function UserFinanceSummaryInner({uid}: UserFinanceSummaryProps) {
    const {formatMessage} = useIntl();
    const {balance, currency} = useUserFinanceSummary(uid);

    return (
        <KeyValueTable>
            <KeyValueColumn keyWidth="lg">
                <KeyValueCell title={formatMessage(localized.balanceCellName)}>
                    <CurrencyCell currency={currency} value={balance} />
                </KeyValueCell>
            </KeyValueColumn>
        </KeyValueTable>
    );
}

export const UserFinanceSummary = withPermissionAndJurisdictionConfig(UserFinanceSummaryInner, {
    featureName: FeatureName.UserFinanceSummary,
});
