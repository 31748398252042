import React, {useEffect, useRef} from 'react';

import {defineAccessControlledResource, PolicyRestrictedResource, usePolicyAccessCheck} from '@access-control';
import {CommentEditor} from '@components/comment/CommentEditor';
import {Suggestion} from '@components/comment/types';

import {useNoteCreate} from '../hooks';
import {commentCreatePolicyRule} from '../policies';
import {EntityViewModel} from '../types';

const notesFormResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'RolesManagement',
    rules: commentCreatePolicyRule,
});

type NotesFormProps = {
    entity: EntityViewModel;
    suggestions: Suggestion[];
};

export function NotesForm({entity, suggestions}: NotesFormProps) {
    const commentEditorRef = useRef(null);
    const {addNote, isProgress, isNone} = useNoteCreate(entity);
    const hasAccess = usePolicyAccessCheck(notesFormResource);

    useEffect(() => {
        if (isNone && commentEditorRef?.current) {
            commentEditorRef.current.resetForm();
        }
    }, [isNone]);

    return hasAccess ? <CommentEditor ref={commentEditorRef} suggestions={suggestions} onSubmit={addNote} isProgress={isProgress} /> : null;
}
