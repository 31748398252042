import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme, PaletteColorOptions, SystemColorsEnum} from '@style';

import {addAlphaToColor} from '../../utils/style';

import {ChipProps, ChipType, ChipVariant} from './types';

//https://services-cloud.atlassian.net/browse/IGP-1705 use height, width, defaults etc... from style.ts once we got the exact design for chips

const colorMapping: Partial<Record<SystemColorsEnum, ChipVariant>> = {
    [SystemColorsEnum.Beige]: ChipVariant.Beige,
    [SystemColorsEnum.Bronze]: ChipVariant.Bronze,
    [SystemColorsEnum.Burgundy]: ChipVariant.Burgundy,
    [SystemColorsEnum.Coffee]: ChipVariant.Coffee,
    [SystemColorsEnum.DarkGrey]: ChipVariant.DarkGrey,
    [SystemColorsEnum.LightBlue]: ChipVariant.LightBlue,
    [SystemColorsEnum.LightGreen]: ChipVariant.LightGreen,
    [SystemColorsEnum.LightGrey]: ChipVariant.LightGrey,
    [SystemColorsEnum.Mint]: ChipVariant.Mint,
    [SystemColorsEnum.Orange]: ChipVariant.Orange,
    [SystemColorsEnum.Pink]: ChipVariant.Pink,
    [SystemColorsEnum.SkyBlue]: ChipVariant.SkyBlue,
    [SystemColorsEnum.Violet]: ChipVariant.Violet,
    [SystemColorsEnum.WarmYellow]: ChipVariant.WarmYellow,
    [SystemColorsEnum.YellowNeon]: ChipVariant.YellowNeon,
    [SystemColorsEnum.Red]: ChipVariant.Red,
};

function isChipVariant(value: ChipVariant | SystemColorsEnum | string): boolean {
    return Object.values(ChipVariant).includes(value as ChipVariant);
}

function isSystemColor(value: ChipVariant | SystemColorsEnum | string): boolean {
    return Object.values(SystemColorsEnum).includes(value as SystemColorsEnum);
}

export const useChipStyles = makeStyles<ChipProps>()((theme: CustomTheme, props) => {
    let res = {};
    const {chipVariant} = props;
    let variant: ChipVariant = undefined;

    if (isChipVariant(chipVariant)) {
        variant = chipVariant as ChipVariant;
    } else if (isSystemColor(chipVariant)) {
        variant = colorMapping[chipVariant as SystemColorsEnum];
    }

    if (Object.prototype.hasOwnProperty.call(theme.custom.palette.status, variant)) {
        const statusOption = theme.custom.palette.status[variant as keyof PaletteColorOptions];

        if (statusOption && statusOption?.main && statusOption?.light) {
            res = {
                color: statusOption.main,
                background: statusOption.light,
                border: statusOption.border,
                '&.MuiChip-deletable:focus, &.MuiChip-deletable:hover': {
                    backgroundColor: statusOption.light,
                },
                [`& .${props.startIconClass}`]: {
                    color: statusOption.main,
                },
            };
        }
    } else {
        const color = chipVariant as string;
        const bgColor = addAlphaToColor(color, 0.2);
        res = {
            color: color,
            background: addAlphaToColor(color, 0.2),
            '&.MuiChip-deletable:focus, &.MuiChip-deletable:hover': {
                backgroundColor: bgColor,
            },
            [`& .${props.startIconClass}`]: {
                color: color,
            },
        };
    }

    return {
        chipCursorPointer: {
            cursor: 'pointer',
        },
        chipLabelCrossedOut: {
            textDecoration: 'line-through',
        },
        chipTypeTag: {
            ...(theme.typography.subtitle2 as CSSObject),
            color: `${theme.palette.text.primary}!important`,
            padding: theme.spacing(0.5, 1),
            height: theme.custom.defaultChipHeight,
            background: '#fff',
            border: `1px solid ${theme.custom.palette.content.liner}`,
            borderRadius: '6px',
            '&:hover': {
                color: props.onDelete ? `${theme.palette.primary.main}!important` : theme.palette.primary.main,
                background: theme.palette.background.paper,
                '& .MuiSvgIcon-root': {
                    color: theme.palette.primary.main,
                },
            },
            '&.MuiChip-outlined:focus, &:active': {
                color: theme.palette.primary.main,
                background: theme.palette.background.paper,
                borderColor: theme.palette.primary.main,
            },
            '&:disabled': {
                color: theme.palette.secondary.main,
                background: theme.palette.background.paper,
                borderColor: theme.custom.palette.content.liner,
            },
            '& .MuiSvgIcon-root': {
                width: 10,
                height: 10,
                color: theme.palette.secondary.main,
            },
            '& .MuiChip-deleteIconSmall': {
                marginRight: '3px',
                marginLeft: '7px',
            },
            '& .MuiChip-labelSmall': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        chipTypeTagMark: {
            ...(theme.typography.subtitle2 as CSSObject),
            height: theme.custom.defaultChipHeight,
            background: '#fff',
            border: `1px solid ${theme.custom.palette.content.liner}`,
            borderRadius: 100,
            '& .MuiChip-iconSmall': {
                marginLeft: theme.spacing(0.75),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'transparent',
            },
        },
        chipTypeTagMarkIcon: {
            ...(theme.typography.subtitle2 as CSSObject),
            height: theme.custom.defaultChipHeight,
            background: `${theme.palette.secondary.light} !important`,
            border: `1px solid ${theme.palette.secondary.light}`,
            borderRadius: 100,
            color: `${theme.palette.text.primary} !important`,
            padding: '0 !important',
            '& .MuiChip-iconSmall': {
                marginLeft: theme.spacing(-0.25),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'transparent',
            },

            '& .MuiSvgIcon-root': {
                background: theme.custom.palette.content.chipDeleteIconMain,
                color: theme.palette.background.paper,
                width: theme.custom.chipDeleteIconSize,
                height: theme.custom.chipDeleteIconSize,
                marginRight: theme.spacing(0.75),
                borderRadius: 100,
            },
        },
        chipTypeStatus: {
            border: 0,
            '& .MuiChip-iconSmall': {
                marginLeft: theme.spacing(0.75),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'transparent',
            },
            '& .MuiSvgIcon-root.MuiChip-deleteIcon': {
                color: theme.palette.primary.contrastText,
                background: theme.custom.palette.content.chipDeleteBackground,
                padding: theme.spacing(0.5),
                borderRadius: '11px',
            },
            '& .MuiChip-deleteIconSmall': {
                padding: theme.spacing(0.25),
            },
        },
        chipTypeStatusV2: {
            border: 0,
            borderRadius: 8,
            height: theme.custom.defaultChipHeight,
        },
        chipTypeMark: {
            border: 0,
            borderRadius: 100,
            '& .MuiChip-iconSmall': {
                marginLeft: theme.spacing(0.75),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'transparent',
            },
            '& .MuiChip-deleteIconSmall': {
                marginRight: theme.spacing(0.75),
                width: theme.custom.chipDeleteWidth,
                height: theme.custom.chipDeleteHeight,
                background: theme.custom.palette.content.chipDeleteBackground,
                borderRadius: '100%',
                color: theme.custom.palette.content.chipDeleteColor,
            },
        },
        chipTypePrefix: {
            border: '1px solid #DFE2E7',
            borderRadius: 4,
            fontSize: '10px',
            lineHeight: '16px',
            '& .MuiChip-labelSmall::after': {
                display: props.chipType === ChipType.Prefix && props.value ? 'inline-block' : 'none',
                content: `"${props.value}"`,
                marginLeft: '4px',
                padding: '0px 5px',
                background: theme.palette.secondary.light,
                borderRadius: '100px',
            },
        },
        chipVariantColor: res,
        chipIcon: {
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
        },
    };
});
