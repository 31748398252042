import React, {useRef} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Box} from '@mui/material';

import {OutlinedButtonDropdown} from '@components/dropdown/Dropdown';
import {Icon} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {FormTextInputDefault} from '@components/input';
import {PlayerBonusTriggerMetaInput} from '@models/generated/graphql';

import {localizedBulkAddBonusCodeButton} from './BulkBonusActionButton.localize';
import {useBulkAddBonusCodeButtonStyles} from './BulkBonusActionButton.style';

type BulkAddBonusCodeButtonProps = {
    onSubmit: (value: string) => void;
    disabled?: boolean;
};

export const BulkBonusAddCodeButton = ({onSubmit, disabled}: BulkAddBonusCodeButtonProps) => {
    const {classes} = useBulkAddBonusCodeButtonStyles();
    const containerRef = useRef<HTMLDivElement>(null);

    const form = useForm<PlayerBonusTriggerMetaInput>({defaultValues: undefined});
    const {control, handleSubmit} = form;

    const callOnSubmit = (value: PlayerBonusTriggerMetaInput) => {
        onSubmit(value.marketing_code);
    };

    const onApplyClick = () => {
        form.handleSubmit(callOnSubmit)();
    };

    return (
        <OutlinedButtonDropdown
            startIcon={<Icon icon={CustomIcon.CodeBranch} />}
            label={localizedBulkAddBonusCodeButton.addBonusCodeLabel}
            closeOnClickInside={false}
            disabled={disabled}
            cancelAction={true}
            applyAction={localizedBulkAddBonusCodeButton.confirmLabel}
            onApplyClick={onApplyClick}
            styles={{dropdownActions: classes.bulkActionBonusFormActions}}
            container={containerRef.current}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(callOnSubmit)}>
                    <Box className={classes.bulkActionBonusFormContainer}>
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    className={classes.bulkActionBonusFormInput}
                                    value={field.value}
                                    onChange={field.onChange}
                                    placeholder={localizedBulkAddBonusCodeButton.fieldBonusCodePlaceholder}
                                    fieldState={fieldState}
                                    label={localizedBulkAddBonusCodeButton.fieldBonusCodeLabel}
                                    helperText={localizedBulkAddBonusCodeButton.fieldBonusCodeDescription}
                                />
                            )}
                            defaultValue={''}
                            name="marketing_code"
                            control={control}
                        />
                    </Box>
                </form>
            </FormProvider>
        </OutlinedButtonDropdown>
    );
};

export default BulkBonusAddCodeButton;
