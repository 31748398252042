import React from 'react';

import {TicketStatus} from '@models/sportsbook-transaction/type';

import {
    DataGridSportsbookTransactionClient,
    SportsbookTransactionViewModel,
    SportsbookTransactionViewModelKeys,
} from 'src/features/block-sportsbook-transaction';

import {Wrapper} from './WrapperDev';

const columns: SportsbookTransactionViewModelKeys[] = [
    'compUserId',
    'ticketId',
    'sabaTicketId',
    'ticketStatus',
    'matchIds',
    'matchNames',
    'tournamentNames',
    'betOptions',
    'betOdds',
    'betAmount',
    'settleAmount',
    'betTime',
];

const rows: SportsbookTransactionViewModel[] = [...Array(100).keys()].map(i => ({
    id: 'id-' + i,
    ticketId: i,
    betAmount: i,
    ticketStatus: TicketStatus.Canceled,
    sabaTicketId: i,
    compUserId: 'compId-' + i,
    betTime: {
        seconds: 1713866707 + i * 100000,
    },
    settleAmount: Math.abs(-50 + i),
    matchIds: [...Array(10).keys()].map(j => (i + 1) * 100000 + j),
    matchNames: [...Array(10).keys()].map(j => 'matchName-' + (i + 1) * 10 + j),
    tournamentNames: [...Array(10).keys()].map(j => 'tournamentName-' + (i + 1) * 10 + j),
    betOptions: [...Array(10).keys()].map(j => 'betOption-' + (i + 1) * 10 + j),
    betOdds: [...Array(10).keys()].map(j => (i + 1) * 10 + j + 0.5),
    currency: 'eur',
}));

export function SportbookDataGridDev() {
    return (
        <Wrapper label={'Sportsbook Data Grid'}>
            <DataGridSportsbookTransactionClient
                onPageChange={() => {}}
                onPageSizeChange={() => {}}
                onSortModelChange={() => {}}
                page={0}
                pageSize={10}
                sortModel={[]}
                columns={columns}
                rows={rows}
                style={{height: '600px'}}
            />
        </Wrapper>
    );
}
