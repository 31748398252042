import React from 'react';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import Grid from '@mui/material/Grid';
import {makeStyles} from 'tss-react/mui';

import {Card} from '@components/card/Card';
import {Currency, CurrencyCell, DurationCell, PercentCell, TextListCell} from '@components/data-grid/mui';
import {LocalizedText} from '@components/i18n';
import {KeyValueCell, KeyValueColumn, KeyValueTable} from '@components/key-value-table';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {RealtimeUpdatesMode} from '@redux/realtime';
import {ViewType} from '@redux/view';
import {CustomTheme} from '@style';

import {LastBonusCell, LastDepositCell, PlayerStatusCellValue} from 'src/features/block-transaction-details';
import {UserActivitySummaryViewModelKeys, useUserActivitySummary} from 'src/features/block-user-activity-summary';
import {useJurisdictionConfig} from '../../app/config/hooks';
import {useUserProfileDetails} from '../../block-user-profile-details';

const localized = defineMessages({
    title: {
        id: 'FinanceInfo_title',
        defaultMessage: 'Finance Info',
    },
    cashierTitle: {
        id: 'FinanceInfo_cashierTitle',
        defaultMessage: 'Cashier',
    },
    activityTitle: {
        id: 'FinanceInfo_activityTitle',
        defaultMessage: 'Activity',
    },
    valueTitle: {
        id: 'FinanceInfo_valueTitle',
        defaultMessage: 'Value',
    },
    bonusTitle: {
        id: 'FinanceInfo_bonusTitle',
        defaultMessage: 'Bonus',
    },

    // Cashier Card
    depositTotalTitle: {
        id: 'FinanceInfo_depositTotalTitle',
        defaultMessage: 'Total Deposit',
    },
    withdrawalTotalTitle: {
        id: 'FinanceInfo_withdrawalTotalTitle',
        defaultMessage: 'Total Withdrawal',
    },
    netDepositsTitle: {
        id: 'FinanceInfo_netDepositsTitle',
        defaultMessage: 'Net Deposits',
    },
    balanceTitle: {
        id: 'FinanceInfo_balanceTitle',
        defaultMessage: 'Current Balance',
    },
    p2pCreditTotalTitle: {
        id: 'FinanceInfo_p2pCreditTotalTitle',
        defaultMessage: 'Total P2P Credit',
    },
    p2pDebitTotalTitle: {
        id: 'FinanceInfo_p2pDebitTotalTitle',
        defaultMessage: 'Total P2P Debit',
    },
    statusTitle: {
        id: 'FinanceInfo_statusTitle',
        defaultMessage: 'Status',
    },

    // Activity Card
    daysSinceLastActiveTitle: {
        id: 'FinanceInfo_daysSinceLastActiveTitle',
        defaultMessage: 'Days since last Active',
    },
    daysValue: {
        id: 'FinanceInfo_daysValue',
        defaultMessage: '{days} days',
    },
    daysSinceRegistrationTitle: {
        id: 'FinanceInfo_daysSinceRegistrationTitle',
        defaultMessage: 'Days since Registration',
    },
    lastGameTitle: {
        id: 'FinanceInfo_lastGameTitle',
        defaultMessage: 'Last Game',
    },
    favCasinoGamesTitle: {
        id: 'FinanceInfo_favCasinoGamesTitle',
        defaultMessage: 'Fav Casino Games',
    },
    favSlotGamesTitle: {
        id: 'FinanceInfo_favSlotGamesTitle',
        defaultMessage: 'Fav Slot Games',
    },
    favPokerGamesTitle: {
        id: 'FinanceInfo_favPokerGamesTitle',
        defaultMessage: 'Fav Poker Games',
    },
    averageBetTitle: {
        id: 'FinanceInfo_averageBetTitle',
        defaultMessage: 'Average Bet',
    },
    averagePokerBuyInTitle: {
        id: 'FinanceInfo_averagePokerBuyInTitle',
        defaultMessage: 'Average Poker Buy-in',
    },

    // Value Card
    lifetimeGGRTitle: {
        id: 'FinanceInfo_lifetimeGGRTitle',
        defaultMessage: 'Lifetime GGR',
    },
    casinoGGRTitle: {
        id: 'FinanceInfo_casinoGGRTitle',
        defaultMessage: 'Casino GGR',
    },
    pokerGGRTitle: {
        id: 'FinanceInfo_pokerGGRTitle',
        defaultMessage: 'Poker GGR',
    },
    sportsGGRTitle: {
        id: 'FinanceInfo_sportsGGRTitle',
        defaultMessage: 'Sportsbook GGR',
    },
    casinoCoinBalanceTitle: {
        id: 'FinanceInfo_casinoCoinBalanceTitle',
        defaultMessage: 'Casino Coin Balance',
    },
    vipTitle: {
        id: 'FinanceInfo_vipTitle',
        defaultMessage: 'VIP',
    },

    // Bonus Card
    bonusTotalTitle: {
        id: 'FinanceInfo_bonusTotalTitle',
        defaultMessage: 'Total Bonus',
    },
    bonusRatioTitle: {
        id: 'FinanceInfo_bonusRatioTitle',
        defaultMessage: 'Bonus Ratio',
    },
    bonusToDepositTitle: {
        id: 'FinanceInfo_bonusToDepositTitle',
        defaultMessage: 'Bonus to Deposit',
    },
    eligibleForBonusTitle: {
        id: 'FinanceInfo_eligibleForBonusTitle',
        defaultMessage: 'Eligible for Bonus',
    },
    yesValue: {
        id: 'FinanceInfo_yesValue',
        defaultMessage: 'Yes',
    },
    noValue: {
        id: 'FinanceInfo_noValue',
        defaultMessage: 'No',
    },
    maxTitle: {
        id: 'FinanceInfo_maxTitle',
        defaultMessage: 'Max',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    financialInfoContainer: {
        padding: theme.spacing(0, 4),
    },
    financialInfoCard: {
        border: `1px solid ${theme.custom.palette.content.border}`,
        borderRadius: theme.shape.borderRadius * 3,
        width: '100%',
        height: '100%',
    },
    financialInfoCardTitle: {
        marginBottom: theme.spacing(),
    },
}));

type FinancialInfoProps = {
    userId: string;
};

export function FinancialInfo({userId}: FinancialInfoProps) {
    const viewType: ViewType = 'FinanceInfo';
    const {classes} = useStyles();
    const {currency} = useJurisdictionConfig();
    const {formatMessage} = useIntl();

    const keys: UserProfileViewModelKeys[] = [
        'uid',
        'deposit_total',
        'withdrawal_approved_amount',
        'balance',
        'finance.casino_coin_balance',
        'finance.p2p_credit.successful_amount',
        'finance.p2p_debit.successful_amount',
        'date_of_joining.seconds',
        'casino_ggr_lifetime',
        'poker_ggr_lifetime',
        'sports_ggr_lifetime',
        'lifetime_bonus',
        'ggr',
    ];
    const {item} = useUserProfileDetails({
        id: userId,
        fields: keys,
        viewType,
        realtimeMode: RealtimeUpdatesMode.Confirm,
        displayName: localized.title,
    });

    const activityKeys: UserActivitySummaryViewModelKeys[] = [
        'average_bet',
        'average_poker_buy_in',
        'casino_favorite_games',
        'days_from_last_active',
        'last_played_game',
        'poker_favorite_games',
        'skill_flag',
        'slot_favorite_games',
    ];
    const {item: userActivity} = useUserActivitySummary({
        id: userId,
        fields: activityKeys,
        viewType,
        displayName: localized.title,
    });

    // Cashier Card
    const currentBalance = item?.finance?.balance;
    const depositTotal = item?.finance?.deposit?.total?.successful_amount;
    const withdrawalTotal = item?.finance?.withdrawal?.total?.successful_amount;
    const p2pDebitTotal = item?.finance?.p2p_debit?.successful_amount;
    const p2pCreditTotal = item?.finance?.p2p_credit?.successful_amount;

    // Bonus Card
    const lifetimeBonus = item?.finance?.lifetime_bonus;
    const ggr = item?.finance?.ggr_lifetime?.casino + item?.finance?.ggr_lifetime?.poker + item?.finance?.ggr_lifetime?.sports;
    const bonusRatio: number = lifetimeBonus && ggr ? lifetimeBonus / ggr : null;

    return (
        <Grid container spacing={1.5} columns={16} className={classes.financialInfoContainer}>
            <FinancialInfoCard title={localized.cashierTitle}>
                <KeyValueCell title={formatMessage(localized.depositTotalTitle)} mode="card">
                    <CurrencyCell value={depositTotal} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.withdrawalTotalTitle)} mode="card">
                    <CurrencyCell value={withdrawalTotal} currency={currency} />
                </KeyValueCell>
                <LastDepositCell uid={userId} mode="card" />
                <KeyValueCell title={formatMessage(localized.netDepositsTitle)} mode="card">
                    <CurrencyCell value={(depositTotal ?? 0) - (withdrawalTotal ?? 0)} currency={currency} isNet />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.balanceTitle)} mode="card">
                    <CurrencyCell value={currentBalance} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.p2pDebitTotalTitle)} mode="card">
                    <CurrencyCell value={p2pDebitTotal} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.p2pCreditTotalTitle)} mode="card">
                    <CurrencyCell value={p2pCreditTotal} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.statusTitle)} mode="card">
                    <PlayerStatusCellValue
                        currentBalance={currentBalance}
                        depositTotal={depositTotal}
                        withdrawalTotal={withdrawalTotal}
                        p2pDebitTotal={p2pDebitTotal}
                        p2pCreditTotal={p2pCreditTotal}
                    />
                </KeyValueCell>
            </FinancialInfoCard>

            <FinancialInfoCard title={localized.activityTitle}>
                <KeyValueCell title={formatMessage(localized.daysSinceLastActiveTitle)} mode="card">
                    <DurationCell count={userActivity?.days_from_last_active} mode="days" />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.daysSinceRegistrationTitle)} mode="card">
                    <DurationCell timestamp={item?.date_of_joining} mode="days" />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.lastGameTitle)} mode="card">
                    <TextListCell list={userActivity?.last_played_game ? [userActivity?.last_played_game] : []} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.favCasinoGamesTitle)} mode="card">
                    <TextListCell list={userActivity?.casino_favorite_games} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.favSlotGamesTitle)} mode="card">
                    <TextListCell list={userActivity?.slot_favorite_games} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.favPokerGamesTitle)} mode="card">
                    <TextListCell list={userActivity?.poker_favorite_games} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.averageBetTitle)} mode="card">
                    <CurrencyCell value={userActivity?.average_bet} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.averagePokerBuyInTitle)} mode="card">
                    <CurrencyCell value={userActivity?.average_poker_buy_in} currency={currency} />
                </KeyValueCell>
            </FinancialInfoCard>

            <FinancialInfoCard title={localized.valueTitle}>
                <KeyValueCell title={formatMessage(localized.lifetimeGGRTitle)} mode="card">
                    <CurrencyCell value={ggr} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.pokerGGRTitle)} mode="card">
                    <CurrencyCell value={item?.finance?.ggr_lifetime?.poker} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.casinoGGRTitle)} mode="card">
                    <CurrencyCell value={item?.finance?.ggr_lifetime?.casino} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.sportsGGRTitle)} mode="card">
                    <CurrencyCell value={item?.finance?.ggr_lifetime?.sports} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.casinoCoinBalanceTitle)} mode="card">
                    <CurrencyCell value={item?.finance?.casino_coin_balance} currency={Currency.CasinoCoin} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.vipTitle)} mode="card">
                    <LocalizedText label={userActivity?.skill_flag ? localized.yesValue : localized.noValue} />
                </KeyValueCell>
            </FinancialInfoCard>

            <FinancialInfoCard title={localized.bonusTitle}>
                <KeyValueCell title={formatMessage(localized.bonusTotalTitle)} mode="card">
                    <CurrencyCell value={lifetimeBonus} currency={currency} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.bonusRatioTitle)} mode="card">
                    <PercentCell value={bonusRatio} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.bonusToDepositTitle)} mode="card">
                    <PercentCell value={lifetimeBonus && ggr ? lifetimeBonus / depositTotal : null} />
                </KeyValueCell>
                <LastBonusCell uid={userId} mode="card" />
                <KeyValueCell title={formatMessage(localized.eligibleForBonusTitle)} mode="card">
                    <LocalizedText label={bonusRatio > 25 ? localized.yesValue : localized.noValue} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.maxTitle)} mode="card">
                    <CurrencyCell value={(25 - bonusRatio) / 500} currency={currency} />
                </KeyValueCell>
            </FinancialInfoCard>
        </Grid>
    );
}

type FinancialInfoCardProps = {
    title: MessageDescriptor;
    children: React.ReactNode;
};

function FinancialInfoCard({title, children}: FinancialInfoCardProps) {
    const {classes} = useStyles();
    return (
        <Grid item xs={8}>
            <Card
                title={title}
                variant="h4"
                className={classes.financialInfoCard}
                titleClassName={classes.financialInfoCardTitle}
                styledContent={false}
            >
                <KeyValueTable mode="card">
                    <KeyValueColumn>{children}</KeyValueColumn>
                </KeyValueTable>
            </Card>
        </Grid>
    );
}
