import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {ErrorProps} from './type';

export const useClasses = makeStyles()(() => ({
    errorInfo: {
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'left',
    },
}));

export function ErrorDetails({error, componentStack}: ErrorProps) {
    const {classes} = useClasses();
    return (
        <>
            {error && (
                <>
                    <Box className={classes.errorInfo}>{error.message}</Box>
                    <Box className={classes.errorInfo}>{error.stack}</Box>
                </>
            )}
            {componentStack && <Box className={classes.errorInfo}>{componentStack}</Box>}
        </>
    );
}
