import {constructUsing, createMap, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {ActiveStatus, Rule} from '@models/rule/types';
import {RuleFields} from '@redux/entity';

import {
    allRuleConditions,
    BooleanExpression,
    RelationalExpression,
    RelationalOperator,
    RuleCondition,
    RuleViewModel,
    RuleViewModelKeys,
} from './types';
import {ExpressionBuilder} from './utils';

export class RuleMapping implements IMapping {
    private readonly _expressionBuilder: ExpressionBuilder;

    constructor() {
        this._expressionBuilder = new ExpressionBuilder();
    }

    createMapping(mapper: Mapper): void {
        createMap<Rule, RuleViewModel>(
            mapper,
            Rule,
            RuleViewModel,
            constructUsing((m, _) => {
                const conditionModel: Record<RuleCondition, unknown> = this.mapExpressionToViewModel(m.expression);
                return {
                    id: m.id,
                    name: m.name,
                    status: m.status === ActiveStatus.Active,
                    order: m.order,
                    ...conditionModel,
                } as RuleViewModel;
            })
        );
        createMap<RuleViewModel, Rule>(
            mapper,
            RuleViewModel,
            Rule,
            constructUsing((m, _) => {
                const expressionString: string = this.mapViewModelToExpression(m);
                return {
                    id: m.id,
                    name: m.name,
                    status: m.status ? ActiveStatus.Active : ActiveStatus.Inactive,
                    order: m.order,
                    expression: expressionString,
                } as Rule;
            })
        );
        createMap<RuleViewModelKeys[], RuleFields[]>(
            mapper,
            nameof<RuleViewModelKeys>(),
            nameof<RuleFields>(),
            constructUsing((m, _) => {
                const mapper: Record<RuleViewModelKeys, RuleFields[]> = {
                    id: ['id'],
                    name: ['name'],
                    status: ['status'],
                    registrationCountry: ['expression'],
                    withdrawalAmount: ['expression'],
                    successfulWithdrawalAmount24: ['expression'],
                    successfulWithdrawalAmountLifetime: ['expression'],
                    hasApprovedKYCHistory: ['expression'],
                    isFirstWithdrawal: ['expression'],
                    hasLocks: ['expression'],
                    isWithdrawalClosedLoop: ['expression'],
                    hasSecurityCasesAssigned: ['expression'],
                    order: ['order'],
                    actions: ['id', 'name', 'expression'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
    }

    private mapExpressionToViewModel(exp: string): Record<RuleCondition, unknown> {
        const result: Record<RuleCondition, unknown> = {} as Record<RuleCondition, unknown>;
        const expression: BooleanExpression | RelationalExpression = this._expressionBuilder.parse(exp);
        this.fillViewModelConditions(result, expression);
        return result;
    }

    private fillViewModelConditions(result: Record<RuleCondition, unknown>, expression: BooleanExpression | RelationalExpression) {
        if ('left' in expression && allRuleConditions?.includes(expression?.left as RuleCondition) && expression?.right) {
            this.setConditionValue(result, expression);
        } else if ((expression as BooleanExpression)?.conditions?.length) {
            (expression as BooleanExpression)?.conditions?.forEach(condition => this.fillViewModelConditions(result, condition));
        }
    }

    private setConditionValue(result: Record<RuleCondition, unknown>, exp: RelationalExpression) {
        const key: RuleCondition = exp?.left as RuleCondition;
        if (Array.isArray(result[key])) {
            result[key] = [...(result[key] as string[]), exp.right];
        } else if (result[key] && result[key] !== exp.right) {
            result[key] = [result[key], exp?.right];
        } else if (typeof this.mapExpressionValueToBoolean(exp.right as string) === 'boolean') {
            result[key] = this.mapExpressionValueToBoolean(exp.right as string);
        } else {
            result[key] = exp.right;
        }
    }

    private mapViewModelToExpression(model: RuleViewModel): string {
        const conditions: (RelationalExpression | BooleanExpression)[] = [];

        if (model?.registrationCountry?.length) {
            if (model?.registrationCountry?.length > 1) {
                conditions?.push({
                    operator: 'OR',
                    conditions: model?.registrationCountry?.map(c => this.getRelationalExpression('registrationCountry', '==', c)),
                });
            } else {
                conditions?.push(this.getRelationalExpression('registrationCountry', '==', model?.registrationCountry[0]));
            }
        }
        if (model?.withdrawalAmount) {
            conditions?.push(this.getRelationalExpression('withdrawalAmount', '<=', model.withdrawalAmount));
        }
        if (model?.successfulWithdrawalAmount24) {
            conditions?.push({
                operator: 'OR',
                conditions: [
                    this.getRelationalExpression('successfulWithdrawalAmount24', '<=', model?.successfulWithdrawalAmount24),
                    this.getRelationalExpression('hasApprovedKYCHistory', '==', model?.hasApprovedKYCHistory),
                ],
            });
        }
        if (model?.successfulWithdrawalAmountLifetime) {
            conditions?.push({
                operator: 'OR',
                conditions: [
                    this.getRelationalExpression('successfulWithdrawalAmountLifetime', '<=', model?.successfulWithdrawalAmountLifetime),
                    this.getRelationalExpression('hasApprovedKYCHistory', '==', model?.hasApprovedKYCHistory),
                ],
            });
        }
        if (typeof model?.isFirstWithdrawal === 'boolean') {
            conditions?.push(
                this.getRelationalExpression('isFirstWithdrawal', '==', this.mapBooleanToExpressionValue(model.isFirstWithdrawal))
            );
        }
        if (typeof model?.hasLocks === 'boolean') {
            conditions?.push(this.getRelationalExpression('hasLocks', '==', this.mapBooleanToExpressionValue(model.hasLocks)));
        }
        if (typeof model?.isWithdrawalClosedLoop === 'boolean') {
            conditions?.push(
                this.getRelationalExpression('isWithdrawalClosedLoop', '==', this.mapBooleanToExpressionValue(model.isWithdrawalClosedLoop))
            );
        }
        if (typeof model?.hasSecurityCasesAssigned === 'boolean') {
            conditions?.push(
                this.getRelationalExpression(
                    'hasSecurityCasesAssigned',
                    '==',
                    this.mapBooleanToExpressionValue(model.hasSecurityCasesAssigned)
                )
            );
        }

        return this._expressionBuilder.build({
            operator: 'AND',
            conditions,
        });
    }

    private getRelationalExpression(
        key: RuleCondition,
        operator: RelationalOperator,
        value: string | number | boolean
    ): RelationalExpression {
        return {left: key, operator, right: value};
    }

    private mapBooleanToExpressionValue(value: boolean): string {
        return value?.toString()?.toUpperCase();
    }

    private mapExpressionValueToBoolean(value: string): boolean {
        let result: boolean;
        try {
            result = JSON.parse(value.toLowerCase());
        } catch {
            result = undefined;
        }
        return result;
    }
}
