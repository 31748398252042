import React from 'react';
import {defineMessages} from 'react-intl';

import {EmptyPlaceholder} from '@components/select';
import {AccountVerificationFilter, AccountVerificationType} from '@models/generated/graphql';

import {localizedAccountVerificationType} from '../../app/intl/shared-resources/accountVerificationType';
import {SelectOption} from '../../module-shared/types';
import FilterMultiSelect from '../../shared/filter/components/FilterMultiSelect';
import {localizedFilters} from '../../shared/filter/localize';
import {PageModuleChipSelectFilter} from '../../shared/filter/types';

import {AccountVerificationsFilterProps} from './AccountVerificationsFilters';

const localized = defineMessages({
    kycTypeFilter: {
        id: 'AccountVerificationsFilterType_kycTypeFilter',
        defaultMessage: 'KYC Type',
    },
});

function AccountVerificationsFilterType({filter, onFilterChange}: AccountVerificationsFilterProps) {
    return (
        <FilterMultiSelect
            onFilterChange={onFilterChange}
            domain={null}
            filter={
                {
                    key: nameof<AccountVerificationFilter>(w => w.type),
                    label: localized.kycTypeFilter,
                    chipType: nameof<AccountVerificationType>(),
                    emptyPlaceholder: EmptyPlaceholder.Any,
                    placeholder: localizedFilters.statusFilterPlaceholder,
                    options: [AccountVerificationType.Payment, AccountVerificationType.Security].map(
                        s => ({label: localizedAccountVerificationType[s], value: s} as SelectOption)
                    ),
                } as PageModuleChipSelectFilter
            }
            filterString={filter}
        />
    );
}

export default AccountVerificationsFilterType;
