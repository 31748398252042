import {PlayerMessage} from '@models/generated/graphql';
import {RootEpic} from '@redux';

import {protectEpics} from '../app/error-handling/epics';
import {patchEpic} from '../module-temp-storage/epics';
import {createEntityRealtimeEpics} from '../realtime-updates/epics';

import {playerMessageActions} from './actions';
import {realtimeSubscribersSelector, subscribedTypesSelector} from './selectors';
import {domain, Message} from './types';

const sendMessageEpic: RootEpic = patchEpic(playerMessageActions.sendMessage, (payload, _state, container) => {
    const message = payload?.item as Message;
    return container.playerMessageService.sendMessage(payload.id, message);
});

export const playerMessageEpics = protectEpics(
    createEntityRealtimeEpics<PlayerMessage>(
        domain,
        container => container.playerMessageRealtimeService,
        realtimeSubscribersSelector,
        subscribedTypesSelector
    ),
    sendMessageEpic
);
