import {BonusCreditType, BonusReleaseMechanism, BonusType, PlayerBonusStatus} from '@models/generated/graphql';
import {TimestampKeys} from '@models/shared';

export class PlayerBonusViewModel {
    id: string;
    bonus_engine_code: string;
    bonus_id: string;
    bonus_name?: string;
    bonus_status: PlayerBonusStatus;
    created_at: string;
    desc?: string;
    expire_at: string;
    marketing_code?: string;
    realized_amount?: number;
    total_amount?: number;
    type?: BonusType;
    uid: string;
    remaining_amount?: number;
    release_mechanism?: BonusReleaseMechanism;
    credit_type?: BonusCreditType;
}

type ViewModelKeys = keyof PlayerBonusViewModel;
const createdAtKey: ViewModelKeys = 'created_at';
const expireAtKey: ViewModelKeys = 'expire_at';

const secondsKey: TimestampKeys = 'seconds';
export type PlayerBonusViewModelKeys =
    | keyof Omit<PlayerBonusViewModel, 'created_at' | 'expire_at'>
    | `${typeof createdAtKey}.${typeof secondsKey}`
    | `${typeof expireAtKey}.${typeof secondsKey}`;
