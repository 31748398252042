import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {useService} from '@inversify';
import {ModuleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {AccountVerificationGridItem} from '../../../pages/kyc-processing/types';
import {withPermissionAndJurisdictionConfig} from '../../app/permission/PermissionHoc';
import {BulkActionKey, bulkActionsActions, BulkChooseActionStepDropdownButton, itemsSelector} from '../../block-bulk-actions';
import {SelectOption} from '../../module-shared/types';
import {kycRejectResultValueSelector} from '../selectors';
import {ApplyKYCRejectRequest, ApplyKYCRejectStrategy} from '../services/applyStrategy';
import {ServiceTypes} from '../types';

const localized = defineMessages({
    buttonLabel: {
        id: 'BulkActionKYCReject_buttonLabel',
        defaultMessage: 'KYC Status',
    },
    rejectOptionLabel: {
        id: 'BulkActionKYCReject_rejectOptionLabel',
        defaultMessage: 'Reject',
    },
    nopeOptionLabel: {
        id: 'BulkActionKYCReject_noteOptionLabel',
        defaultMessage: 'Nope',
    },
});

export function BulkActionKYCRejectInner() {
    const options: SelectOption[] = [
        {label: localized.rejectOptionLabel, value: true, startIcon: CustomIcon.CancelOutline},
        {label: localized.nopeOptionLabel, value: null},
    ];

    const dispatch = useDispatch();
    const strategy = useService<ApplyKYCRejectStrategy>(ServiceTypes.ApplyKYCRejectStrategy);
    const accountVerifications: AccountVerificationGridItem[] = useSelector(itemsSelector<AccountVerificationGridItem>);
    const kycRejectValue = useSelector(kycRejectResultValueSelector);

    function handleClick(value?: boolean) {
        if (value) {
            dispatch(
                bulkActionsActions.apply({
                    request: {
                        items: accountVerifications,
                    } as ApplyKYCRejectRequest,
                    strategy,
                })
            );
        } else {
            handleDelete();
        }
    }

    function handleDelete() {
        dispatch(bulkActionsActions.removeBulkActionsWithKey(BulkActionKey.KYCReject));
    }

    return (
        <BulkChooseActionStepDropdownButton
            onClick={handleClick}
            label={localized.buttonLabel}
            iconClassName={CustomIcon.Union}
            options={options}
            endIconClassName={CustomIcon.WriteOutline}
            endIconSize={13}
        >
            {kycRejectValue ? (
                <Chip
                    startIconClass={CustomIcon.CancelOutline}
                    chipType={ChipType.Mark}
                    chipVariant={ChipVariant.Disabled}
                    label={<LocalizedText label={localized.rejectOptionLabel} />}
                    onDelete={handleDelete}
                />
            ) : null}
        </BulkChooseActionStepDropdownButton>
    );
}

export const BulkKYCRejectButton = withPermissionAndJurisdictionConfig(BulkActionKYCRejectInner, {
    permissions: [PermissionEnum.Update],
    moduleName: ModuleName.KycProcessing,
});
