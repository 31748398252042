import React from 'react';

import {UserLabelsForm} from '../../block-label-group-list/types';
import {usePlayerLabelAssign, usePlayerLabelGroups} from '../hooks';

import {BlockPlayerLabelAssignForm} from './BlockPlayerLabelAssignForm';

type BlockPlayerLabelAssignProps = {
    uid: string;
    playerLabelIds: string[];
    isEditable?: boolean;
};

export function BlockPlayerLabelAssign({uid, playerLabelIds, isEditable = true}: BlockPlayerLabelAssignProps) {
    const {labelGroups, labelIds} = usePlayerLabelGroups();
    const {handleAssign} = usePlayerLabelAssign(uid);
    const defaultModel = getDefaultModel();

    function getDefaultModel(): UserLabelsForm {
        return {
            groups: labelGroups.map(g => ({
                id: g.id,
                labels: g.labels
                    ?.filter(l => l)
                    ?.map(l => {
                        const isSelected = playerLabelIds.includes(l.id);
                        return {id: l.id, selected: isSelected, disabled: isSelected};
                    }),
            })),
        };
    }

    return (
        <BlockPlayerLabelAssignForm
            value={defaultModel}
            isEditable={isEditable}
            onSubmit={handleAssign}
            labelGroups={labelGroups}
            labelIds={labelIds}
        />
    );
}
