import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {MessageDescriptor} from 'react-intl';
import {useIntl} from 'react-intl';
import {Box} from '@mui/material';

import {EditableAutocompleteProps} from '@components/editable/EditableAutocomplete';
import {RuleType, useValidationFormatter} from '@components/input';
import {customerSupportUpdatePermissions} from '@models/permissions/permissions';
import {AsyncAction} from '@redux';

import {useMultiplePermissions} from '../../../app/permission/PermissionHoc';
import {useReduxForm, useReduxFormRequestMode} from '../../../shared/form/hooks';
import {UserProfileModel} from '../types';

type UserProfileCountryModel = {
    fieldValue: string;
    userId: string;
};

type UserProfileCountryOptions = {
    action: AsyncAction<string, UserProfileModel, string, unknown, string, unknown>;
    placeholder: MessageDescriptor;
    submitModelMapper: (submitModel: UserProfileModel) => UserProfileCountryModel;
    formModelMapper: (formModel: UserProfileCountryModel) => UserProfileModel;
    isRequired?: boolean;
};

type UserProfileCountryInnerProps = UserProfileModel & {
    disabled?: boolean;
};

export const withUserProfileCountry =
    <TFilter extends Record<string, unknown>>(
        WrapperComponent: React.ComponentType<EditableAutocompleteProps<TFilter>>,
        {submitModelMapper, action, formModelMapper, placeholder, isRequired}: UserProfileCountryOptions
    ) =>
    ({user, userId, disabled}: UserProfileCountryInnerProps) => {
        const isEditable = useMultiplePermissions({allowedPermissions: customerSupportUpdatePermissions});
        const {formatMessage} = useIntl();
        const validationFormatter = useValidationFormatter();

        const getFieldValue = () => (userId ? submitModelMapper({user, userId}) : undefined);

        const currentFieldValue = getFieldValue();
        const [fieldValueModel, setFieldValueModel] = useState<UserProfileCountryModel>(currentFieldValue);
        const {control, state, submit, cancel, handleSubmit} = useReduxForm<UserProfileCountryModel, UserProfileModel>({
            initialModel: fieldValueModel,
            asyncAction: action,
            map: formModelMapper,
        });

        const {requestMode, resetRequestMode} = useReduxFormRequestMode<UserProfileModel>(action);

        useEffect(() => {
            const currentFieldValue = getFieldValue();
            setFieldValueModel(currentFieldValue);
        }, [userId]);

        useEffect(() => {
            setFieldValueModel(currentFieldValue);
        }, [currentFieldValue?.fieldValue]);

        return (
            <form onSubmit={handleSubmit(submit)}>
                <Controller
                    render={({field, fieldState}) => (
                        <Box>
                            <WrapperComponent
                                mode={requestMode}
                                value={field.value}
                                onValueChange={v => {
                                    field.onChange(v);
                                    resetRequestMode();
                                }}
                                onSave={() => handleSubmit(submit)}
                                onCancel={cancel}
                                disabled={disabled || !isEditable}
                                placeholder={placeholder}
                                errorMessage={
                                    state.errorMessage
                                        ? formatMessage(state.errorMessage)
                                        : fieldState?.error?.message
                                        ? fieldState?.error?.message
                                        : null
                                }
                            />
                        </Box>
                    )}
                    control={control}
                    name="fieldValue"
                    defaultValue={fieldValueModel?.fieldValue}
                    rules={{
                        required: isRequired ? validationFormatter(RuleType.Required, placeholder) : null,
                    }}
                ></Controller>
            </form>
        );
    };
