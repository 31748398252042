import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

//TODO: [BO-2704] Move files to src/common/components/data-grid (renamed grid folder)
export const useGridStyle = makeStyles<{autoHeight: boolean}>()((theme: CustomTheme, {autoHeight}) => ({
    dataGridWrapper: {
        display: 'grid',
        flex: 1,
        width: '100%',
        overflowY: autoHeight ? 'unset' : 'hidden',
    },
    dataGridRoot: {
        border: 0,
        '& .MuiDataGrid-cellCheckbox': {
            width: `${theme.custom.gridCheckboxWidth}px !important`,
            minWidth: `${theme.custom.gridCheckboxWidth}px !important`,
            maxWidth: `${theme.custom.gridCheckboxWidth}px !important`,
            paddingLeft: '0 !important',
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
            width: `${theme.custom.gridCheckboxWidth}px !important`,
            minWidth: `${theme.custom.gridCheckboxWidth}px !important`,
            maxWidth: `${theme.custom.gridCheckboxWidth}px !important`,
        },

        '& .MuiDataGrid-columnHeaders': {
            minHeight: `${theme.custom.gridHeaderHeight}px !important`,
            height: theme.custom.gridHeaderHeight,
            maxHeight: `${theme.custom.gridHeaderHeight}px !important`,
            lineHeight: `${theme.custom.gridHeaderHeight}px !important`,
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
            backgroundColor: theme.palette.background.paper,
        },
        '& .MuiDataGrid-window': {
            top: `${theme.custom.gridHeaderHeight}px !important`,
        },
        '& div[data-rowIndex][role="row"]:nth-of-type(n)': {
            '& .MuiDataGrid-cell:last-of-type': {
                paddingRight: theme.spacing(2),
            },
            '& .MuiDataGrid-cell': {
                lineHeight: `${theme.custom.gridHeaderHeight - 1}px`,
                paddingLeft: theme.spacing(2),
                borderBottom: `1px solid ${theme.palette.secondary.light}`,
                borderRight: `1px solid ${theme.palette.secondary.light}`,
            },
        },
        '& div[data-rowIndex][role="row"]:nth-child(odd)': {
            backgroundColor: theme.palette.background.paper,
        },
        '& div[data-rowIndex][role="row"]:nth-child(even)': {
            backgroundColor: theme.palette.background.paper,
        },
        '& div[role="columnheader"]': {
            ...(theme.typography.h4 as CSSObject),
            minHeight: `${theme.custom.gridHeaderHeight}px !important`,
            height: theme.custom.gridHeaderHeight,
            padding: 0,
            color: theme.palette.secondary.main,
            '&:focus-within, &:focus, &:active': {
                outline: 'none',
            },
            '& .MuiDataGrid-columnHeaderDraggableContainer': {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    padding: 0,
                    whiteSpace: 'normal',
                    '& .MuiDataGrid-iconButtonContainer': {
                        visibility: 'visible',
                        marginLeft: 'auto',
                        width: 'auto',
                        '& .MuiIconButton-root': {
                            padding: 0,
                            borderLeft: '4px solid transparent',
                            borderRight: '4px solid transparent',
                            borderTop: `4px solid ${theme.palette.secondary.main}`,
                            '& .MuiSvgIcon-root': {
                                display: 'none',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent !important',
                            },
                        },
                    },
                },
            },
            '& .MuiDataGrid-columnSeparator': {
                visibility: 'unset',
                borderRight: `1px solid ${theme.palette.secondary.light}`,
                height: theme.custom.gridHeaderHeight,
                right: 0,
                '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                },
            },
        },
        '& div[role="columnheader"][aria-sort="descending"] .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer':
            {
                color: theme.palette.primary.main,
                '& .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                    borderLeft: '4px solid transparent',
                    borderRight: '4px solid transparent',
                    borderTop: `4px solid ${theme.palette.primary.main}`,
                    borderBottom: 0,
                    '& .MuiSvgIcon-root': {
                        display: 'none',
                    },
                },
            },
        '& div[role="columnheader"][aria-sort="ascending"] .MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-columnHeaderTitleContainer':
            {
                color: theme.palette.primary.main,
                '& .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                    borderLeft: '4px solid transparent',
                    borderRight: '4px solid transparent',
                    borderBottom: `4px solid ${theme.palette.primary.main}`,
                    borderTop: 0,
                    '& .MuiSvgIcon-root': {
                        display: 'none',
                    },
                },
            },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            justifyContent: 'center',
        },
    },
    dataGridEmptyMessage: {
        marginRight: 0,
        marginLeft: 0,
    },
    dataGridWithPinnedRow: {
        '& div[data-rowIndex][role="row"]:first-of-type': {
            position: 'sticky',
            '& .MuiDataGrid-cell': {
                fontWeight: 'bold',
                borderBottom: `1px solid ${theme.custom.palette.content.borderDark}`,
            },
        },
    },
    dataGridHideHeader: {
        '& .MuiDataGrid-columnsContainer': {
            display: 'none',
        },
        '& .MuiDataGrid-windowContainer': {
            height: 'auto !important',
        },
        '& .MuiDataGrid-window': {
            top: '0 !important',
            position: 'relative',
        },
    },
    dataGridHideRows: {
        '& .MuiDataGrid-overlay': {
            display: 'none',
        },
        '& .MuiDataGrid-columnsContainer': {
            position: 'relative',
        },
    },
}));
