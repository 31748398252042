import {IconColor} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {KycStatus} from '@models/generated/graphql';
import {SystemColorsEnum} from '@style';

import {EnumIcon} from './type';

export const kycStatusIcons: Record<KycStatus | 'undefined', EnumIcon> = {
    [KycStatus.Successful]: {icon: CustomIcon.CheckDouble, color: IconColor.Success},
    [KycStatus.Pending]: {icon: CustomIcon.ClockOutline, color: SystemColorsEnum.LightGrey},
    [KycStatus.Failed]: {icon: CustomIcon.CancelOutline, color: SystemColorsEnum.Bronze},
    [KycStatus.None]: {icon: CustomIcon.ClockOutline, color: SystemColorsEnum.LightGrey},
    undefined: {icon: null, color: SystemColorsEnum.LightGrey},
};
