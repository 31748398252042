import {ViewType} from '@redux/view';

import {useBoAgentPlayer} from '../block-agent-profile';

import {ReferralInfoMode} from './types';

type UseReferralInfoEditModeProps = {
    viewType: ViewType;
    referrerId: string;
};

export function useReferralActionAccess({viewType, referrerId}: UseReferralInfoEditModeProps): ReferralInfoMode {
    const {boAgentPlayerId} = useBoAgentPlayer({viewType});

    let result: ReferralInfoMode;
    if (boAgentPlayerId) {
        if (boAgentPlayerId === referrerId) {
            result = 'remove-only';
        } else {
            result = 'read-only';
        }
    } else {
        result = 'editable';
    }

    return result;
}
