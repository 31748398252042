import {EmbeddedDashboardType} from '@models/generated/graphql';
import {Timestamp, TimestampKeys} from '@models/shared';

export class EmbeddedReportViewModel {
    type: EmbeddedDashboardType;
    url: string;
    expire_at: Timestamp;
}

const expireAtKey: keyof EmbeddedReportViewModel = 'expire_at';
const secondsKey: TimestampKeys = 'seconds';
export type EmbeddedReportViewModelKeys = keyof Omit<EmbeddedReportViewModel, 'expire_at'> | `${typeof expireAtKey}.${typeof secondsKey}`;
