import React, {useContext} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {SquareIconButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons';
import {ActionConfirmationModal, ModalContext} from '@components/modal';

import {useAsyncActionState} from '../../shared/async-action/hooks';
import {ruleActions} from '../actions';

const localized = defineMessages({
    modalTitle: {
        id: 'DeleteRuleButton_modalTitle',
        defaultMessage: 'Delete Rule',
    },
    modalActionDescription: {
        id: 'DeleteRuleButton_modalActionDescription',
        defaultMessage: 'Do you want to delete {name} rule?',
    },
});
type DeleteRuleActionButtonProps = {
    ruleId: string;
    ruleName: string;
};

export function DeleteRuleButton({ruleId, ruleName}: DeleteRuleActionButtonProps) {
    const dispatch = useDispatch();
    const {openModal, closeModal} = useContext(ModalContext);
    const {isProgress} = useAsyncActionState(ruleActions.deleteRule);
    const {formatMessage} = useIntl();

    const handleConfirm = () => {
        dispatch(ruleActions.deleteRule.request({ruleId}));
        closeModal();
    };

    const handleClick = () => {
        openModal({
            body: (
                <ActionConfirmationModal
                    title={localized.modalTitle}
                    contentDescription={formatMessage(localized.modalActionDescription, {name: ruleName})}
                    handleConfirm={handleConfirm}
                    handleCancel={closeModal}
                />
            ),
            styleParams: {maxWidth: 'xs'},
        });
    };

    return <SquareIconButton onClick={handleClick} iconClassName={CustomIcon.DeleteOutlineAndroid} disabled={isProgress} />;
}
