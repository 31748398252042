import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {PlayersGroup} from '@models/generated/graphql';

import {localizedPlayersGroup} from '../../app/intl/shared-resources/playersGroup';

export type BotGroupChangedValue = {
    players_groups: string[];
};

export const useBotGroupChangedFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: BotGroupChangedValue = JSON.parse(json);
    return value?.players_groups?.map((g: PlayersGroup) => ({key: formatMessage(localizedPlayersGroup[g])})) ?? [];
};
