import {EntityType} from '@redux/entity';

import {DataGridAccountVerificationsClient} from '../../block-account-verification';
import {withBulkSummaryStep} from '../../block-bulk-actions';

export const BulkKYCSummaryStepModal = withBulkSummaryStep(
    DataGridAccountVerificationsClient,
    ['id', 'uid', 'username'],
    EntityType.AccountVerification
);
