import {defineMessages} from 'react-intl';

import {PlayersRelationType} from '@models/generated/graphql';

export const localizedPlayersRelationType = defineMessages({
    [PlayersRelationType.Device]: {
        id: 'playersRelationTypeDevice',
        defaultMessage: 'Device',
    },
    [PlayersRelationType.Ip]: {
        id: 'playersRelationTypeIp',
        defaultMessage: 'Ip Address',
    },
    [PlayersRelationType.BankCard]: {
        id: 'playersRelationTypeBankCard',
        defaultMessage: 'Bank Card',
    },
    [PlayersRelationType.Subnet]: {
        id: 'playersRelationTypeSubnet',
        defaultMessage: 'Subnet',
    },
    [PlayersRelationType.BankCardName]: {
        id: 'playersRelationTypeBankCardName',
        defaultMessage: 'Bank Card Name',
    },
});
