export class GameBlindConfigResponse {
    data: {
        blind_list: GameBlindConfig[];
    };
}

export class GameBlindConfig {
    id: number;
    sb: number;
    bb: number;
}

export class GameBlindViewModel {
    id: number;
    sb: number;
    bb: number;
}
