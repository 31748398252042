import React, {useContext} from 'react';
import {Controller, useFieldArray} from 'react-hook-form';
import {defineMessages, IntlContext} from 'react-intl';
import {useSelector} from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import {Box, Menu, MenuItem, TextField, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {RootState} from 'typesafe-actions';

import {Button} from '@components/button/Buttons';
import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import LocalizedText from '@components/i18n/LocalizedText';
import {FormTextInputDefault, RuleType, useValidationFormatter} from '@components/input';
import {BoRole, BoUser} from '@redux/entity';
import {CustomTheme} from '@style';

import withModuleEditForm, {ModuleFormInnerProps} from '../../../features/module-shared/components/ModuleEditForm';
import {RoleFormItem} from '../../role-management/types';
import {localizedAccessManagement} from '../localize';

export type UserFormData = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: BoRole[];
};

const localized = defineMessages({
    notRolesMessage: {
        id: 'notRolesMessage',
        defaultMessage: 'No roles assigned.',
    },
});

const makeClasses = makeStyles()((theme: CustomTheme) => ({
    form: {
        margin: theme.spacing(2, 0),
        overflowY: 'auto',
    },

    formControl: {
        margin: theme.spacing(2, 0),
    },
    formRolesHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    info: {
        textAlign: 'center',
    },

    userFormRoleMenu: {
        maxHeight: 400,
    },
}));

const UserForm = ({control, isNewValue, value}: ModuleFormInnerProps<BoUser>) => {
    const {formatMessage} = useContext(IntlContext);
    const validationMessageFormatter = useValidationFormatter();
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'roles',
        keyName: 'roleId',
    });
    const roles = useSelector((state: RootState) => state.users.data.roles?.items) as BoRole[];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const {classes} = makeClasses();

    const getAvailableRoles = () => {
        return roles?.filter(role => !fields.map((f: RoleFormItem) => f.name).includes(role.name));
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelectClose = () => {
        setAnchorEl(null);
    };

    const handleRoleSelect = (role: BoRole) => {
        append({name: role.name, id: role.id});
        handleSelectClose();
    };

    const availableRoles = getAvailableRoles();
    return (
        <Box className={classes.form}>
            <Box display="none">
                <Controller render={_ => <TextField hidden />} name="id" control={control} defaultValue={value?.id ?? ''} />
            </Box>
            <Controller
                render={({field, fieldState}) => (
                    <FormTextInputDefault
                        value={field.value}
                        onChange={field.onChange}
                        label={localizedAccessManagement.firstName}
                        placeholder={localizedAccessManagement.firstName}
                        fieldState={fieldState}
                        disabled={!isNewValue}
                        hideLabel={true}
                    />
                )}
                defaultValue={''}
                name="firstName"
                control={control}
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localizedAccessManagement.firstName),
                }}
            />
            <Controller
                render={({field, fieldState}) => (
                    <FormTextInputDefault
                        value={field.value}
                        onChange={field.onChange}
                        label={localizedAccessManagement.lastName}
                        placeholder={localizedAccessManagement.lastName}
                        fieldState={fieldState}
                        disabled={!isNewValue}
                        hideLabel={true}
                    />
                )}
                defaultValue={''}
                name="lastName"
                control={control}
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localizedAccessManagement.lastName),
                }}
            />
            <Controller
                render={({field, fieldState}) => (
                    <FormTextInputDefault
                        value={field.value}
                        onChange={field.onChange}
                        label={localizedAccessManagement.email}
                        placeholder={localizedAccessManagement.email}
                        fieldState={fieldState}
                        type="email"
                        disabled={!isNewValue}
                        hideLabel={true}
                    />
                )}
                defaultValue={''}
                name="email"
                control={control}
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localizedAccessManagement.email),
                }}
            />
            <Box className={classes.formControl}>
                <Box className={classes.formRolesHeader}>
                    <Typography variant="h6">{formatMessage(localizedAccessManagement.roles)}</Typography>
                    {availableRoles?.length ? (
                        <>
                            <Button color="primary" size="small" onClick={handleClick}>
                                <AddIcon />
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleSelectClose}
                                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                                slotProps={{
                                    paper: {className: classes.userFormRoleMenu},
                                }}
                            >
                                {availableRoles?.map(role => (
                                    <MenuItem key={role.id} onClick={() => handleRoleSelect(role)}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    ) : null}
                </Box>
                {fields.length ? (
                    fields.map((item: RoleFormItem, index) => (
                        <Box key={item.roleId}>
                            <Box display="none">
                                <Controller
                                    render={_ => <TextField hidden />}
                                    name={`roles.${index}.id`}
                                    control={control}
                                    defaultValue={item.id}
                                />
                            </Box>
                            <Box className={classes.formControl} key={item.id}>
                                <Controller
                                    render={_ => (
                                        <Chip
                                            fillWidth={false}
                                            chipType={ChipType.Tag}
                                            chipVariant={ChipVariant.Default}
                                            label={item.name}
                                            onDelete={() => remove(index)}
                                        />
                                    )}
                                    name={`roles.${index}.name`}
                                    control={control}
                                    defaultValue={item.name}
                                />
                            </Box>
                        </Box>
                    ))
                ) : (
                    <Typography className={classes.info} variant="body1">
                        <LocalizedText label={localized.notRolesMessage} />
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

const UserEditForm = withModuleEditForm<BoUser>(UserForm, BoUser);

export default UserEditForm;
