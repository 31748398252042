import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Box, Divider} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {FilterProps} from '@components/filter/types';
import {SelectButton} from '@components/select';
import {ToggleSwitch} from '@components/toggle';
import {agentReadPolicy} from '@models/permissions/permissions';
import {AgentProfileFilterKeys} from '@redux/entity';
import {extendedViewCleanDelay, ViewType} from '@redux/view';
import {CustomTheme} from '@style';
import {isStringNullOrEmpty} from '@utils';
import {sharedLocalization} from '@localization';

import {Filter} from '../../../common/types';
import {usePermission} from '../../app/permission/PermissionHoc';
import {useAgentProfiles} from '../hooks';

import {AgentAutocomplete} from './AgentAutocomplete';
import {localized} from './localized';

const useClasses = makeStyles()((theme: CustomTheme) => {
    return {
        agentPlayerReportingFilterReferrerContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '360px',
            padding: theme.spacing(2, 1.5, 1.5),
            [theme.breakpoints.down('sm')]: {
                width: 'unset',
            },
        },
        agentPlayerReportingFilterReferrerToggle: {
            paddingTop: 0,
            [theme.breakpoints.down('md')]: {
                margin: theme.spacing(2, 0),
                padding: 0,
                borderBottom: 'none',
            },
        },
        agentPlayerReportingFilterReferrer: {
            paddingTop: theme.spacing(1.25),
            minHeight: '170px',
        },
        agentPlayerReportingFilterReferrerList: {
            height: '170px',
            [theme.breakpoints.down('md')]: {
                flexGrow: 1,
                height: 'unset',
                '& > div': {
                    gap: theme.spacing(1),
                },
                '& .MuiTreeView-root': {
                    margin: `${theme.spacing(0, -2)} !important`,
                },
            },
        },
        agentPlayerReportingFilterReferrerTree: {
            height: 'max-content',
            '& p': {
                marginTop: theme.spacing(2.5),
            },
            [theme.breakpoints.down('md')]: {
                flexGrow: 1,
                height: 'unset',
                '& > div': {
                    gap: theme.spacing(1),
                },
            },
        },
        agentPlayerReportingFilterDivider: {
            marginBottom: theme.spacing(1.25),
            borderColor: theme.palette.secondary.light,
        },
        autocompleteItemsContainer: {
            [theme.breakpoints.down('md')]: {
                height: '300px',
                margin: `${theme.spacing(0, -2)} !important`,
            },
        },
    };
});

type AgentFilterValue = {
    agentId: string;
    isDownstream: boolean;
};

type AgentFilterProps = FilterProps<AgentFilterValue> & {
    boAgentPlayerId: string;
    withDownstream: boolean;
    viewType: ViewType;
    displayMode?: 'mobile' | 'desktop';
};

function AgentFilterNew({boAgentPlayerId, value, onChange, withDownstream, viewType, displayMode = 'desktop', ...props}: AgentFilterProps) {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();

    const currentAgentUsername = useCurrentAgentUsername({viewType, agentId: value?.agentId});

    const [isDownstream, setIsDownstream] = useState(value?.isDownstream);
    const [selectedAgentId, setSelectedAgentId] = useState(value?.agentId);
    const isMobile = displayMode === 'mobile';

    useEffect(() => {
        setSelectedAgentId(value?.agentId);
    }, [value?.agentId]);

    useEffect(() => {
        setIsDownstream(value?.isDownstream);
    }, [value?.isDownstream]);

    function handleApplyClick() {
        onChange({agentId: selectedAgentId, isDownstream});
    }

    useEffect(() => {
        if (isMobile) {
            handleApplyClick();
        }
    }, [selectedAgentId, isDownstream]);

    function handleAutocompleteChange(newAgentId: string) {
        setSelectedAgentId(newAgentId);
    }

    function handleDownstreamChange(value: boolean) {
        setIsDownstream(value);
    }

    const isTreeMode = usePermission(agentReadPolicy);

    const agentFilterInputs = (
        <AgentFilterInputs
            withDownstream={withDownstream}
            isDownstream={isDownstream}
            isTreeMode={isTreeMode}
            viewType={viewType}
            selectedAgentId={selectedAgentId}
            boAgentPlayerId={boAgentPlayerId}
            onDownstreamChange={handleDownstreamChange}
            onAutocompleteChange={handleAutocompleteChange}
            {...props}
        />
    );

    return isMobile ? (
        agentFilterInputs
    ) : (
        <SelectButton
            label={localized.agentLabel}
            selectedText={currentAgentUsername ?? formatMessage(localized.any)}
            applyAction={sharedLocalization.apply}
            cancelAction={localized.cancel}
            onApplyClick={handleApplyClick}
            styles={{
                dropdownContainer: classes.agentPlayerReportingFilterReferrerContainer,
            }}
        >
            {agentFilterInputs}
            <Divider className={classes.agentPlayerReportingFilterDivider} />
        </SelectButton>
    );
}

type AgentFilterInputsProps = {
    withDownstream: boolean;
    isDownstream: boolean;
    isTreeMode: boolean;
    viewType: ViewType;
    selectedAgentId: string;
    boAgentPlayerId: string;
    onDownstreamChange: (value: boolean) => void;
    onAutocompleteChange: (newAgentId: string) => void;
};

function AgentFilterInputs({
    withDownstream,
    isDownstream,
    isTreeMode,
    viewType,
    selectedAgentId,
    boAgentPlayerId,
    onDownstreamChange,
    onAutocompleteChange,
    ...props
}: AgentFilterInputsProps) {
    const {classes, cx} = useClasses();
    const {formatMessage} = useIntl();
    return (
        <>
            {withDownstream && (
                <ToggleSwitch
                    className={classes.agentPlayerReportingFilterReferrerToggle}
                    value={isDownstream}
                    onChange={onDownstreamChange}
                    fieldName={formatMessage(localized.downlineLabel)}
                    withBorder
                />
            )}
            <Box
                className={cx(
                    isTreeMode ? classes.agentPlayerReportingFilterReferrerTree : classes.agentPlayerReportingFilterReferrerList,
                    classes.agentPlayerReportingFilterReferrer
                )}
            >
                <AgentAutocomplete
                    viewType={viewType}
                    value={selectedAgentId}
                    onChange={onAutocompleteChange}
                    boAgentPlayerId={boAgentPlayerId}
                    isTreeMode={isTreeMode}
                    listContainerClassName={classes.autocompleteItemsContainer}
                    {...props}
                />
            </Box>
        </>
    );
}

type UseCurrentAgentUsernameProps = {
    viewType: ViewType;
    agentId: string;
};

export function useCurrentAgentUsername({viewType, agentId}: UseCurrentAgentUsernameProps): string {
    const {items: filterAgentProfile, handleFilterChange} = useAgentProfiles({
        viewType,
        fields: ['uid', 'username'],
        defaultFilters: [{key: 'uid', value: agentId}],
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
        cleanDelay: extendedViewCleanDelay,
    });
    const selectedAgentUsername = filterAgentProfile?.[0]?.username;

    useEffect(() => {
        const updatedFilter: Filter<string, AgentProfileFilterKeys>[] = [{key: 'uid', value: agentId}];
        handleFilterChange(updatedFilter);
    }, [agentId]);

    return selectedAgentUsername;
}

export {AgentFilterValue, AgentFilterNew};
