import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {FormTextInputDefault, FormTextInputDefaultProps} from './FormInput';
import {withStyledInputLabel} from './StyledFormInputHoc';

//TODO: [BO-2666] Merge multiple text inputs
const useClasses = makeStyles()((theme: CustomTheme) => ({
    input: {
        '& .MuiInputAdornment-root': {
            color: theme.custom.palette.content.bodyText,
        },
    },
}));

export const StyledInputComponent = ({className, ...props}: FormTextInputDefaultProps<string | number>) => {
    const {classes} = useClasses();
    return <FormTextInputDefault className={`${className ?? ''} ${classes.input}`} hideLabel variant="outlined" {...props} />;
};

export const StyledInput = withStyledInputLabel(StyledInputComponent);
