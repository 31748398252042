import React from 'react';
import {makeStyles} from 'tss-react/mui';

import Button, {IconButton} from '@components/button/Buttons';
import {CustomIcon, Icon} from '@components/icons';
import {CustomTheme} from '@style';
import {sharedLocalization} from '@localization';

const useStyles = makeStyles()((theme: CustomTheme) => {
    return {
        filterGroupButtonsContainer: {
            display: 'inline-flex',
            gap: theme.spacing(1.25),

            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
            },
        },
        filterGroupButtonsButton: {
            borderRadius: theme.shape.borderRadius * 2,
            maxHeight: theme.custom.denseButtonHeight,
        },
        filterGroupButtonsButtonIcon: {
            fontSize: `${theme.spacing(1.5)} !important`,
        },
        filterGroupButtonsExpandButton: {
            minWidth: 'unset',
            color: theme.palette.primary.main,
        },
    };
});

type FilterGroupButtonsProps = {
    hasExpandButton: boolean;
    hasExpandButtonMobile: boolean;
    hasSubmitButton: boolean;
    submitButton?: React.ReactNode;
    isExpanded: boolean;
    isExpandedMobile: boolean;
    onCollapse: () => void;
    onExpand: () => void;
    onCollapseMobile: () => void;
    onExpandMobile: () => void;
};

export function FilterGroupButtons({
    hasExpandButton,
    hasExpandButtonMobile,
    hasSubmitButton,
    submitButton,
    isExpanded,
    isExpandedMobile,
    onCollapse,
    onExpand,
    onCollapseMobile,
    onExpandMobile,
}: FilterGroupButtonsProps) {
    const {classes, cx} = useStyles();
    const updateOnSubmitButton = submitButton ?? (
        <Button
            type="submit"
            color="primary"
            label={sharedLocalization.submitButtonLabel}
            className={classes.filterGroupButtonsButton}
            startIcon={<Icon icon={CustomIcon.Search} className={classes.filterGroupButtonsButtonIcon} />}
        />
    );
    return hasExpandButton || hasSubmitButton || hasExpandButtonMobile ? (
        <div className={classes.filterGroupButtonsContainer}>
            {hasExpandButton ? (
                isExpanded ? (
                    <Button
                        label={sharedLocalization.collapseButtonLabel}
                        onClick={onCollapse}
                        className={cx(classes.filterGroupButtonsExpandButton, classes.filterGroupButtonsButton)}
                        endIcon={<Icon icon={CustomIcon.ArrowUp} className={classes.filterGroupButtonsButtonIcon} />}
                    />
                ) : (
                    <Button
                        label={sharedLocalization.expandButtonLabel}
                        onClick={onExpand}
                        className={cx(classes.filterGroupButtonsExpandButton, classes.filterGroupButtonsButton)}
                        endIcon={<Icon icon={CustomIcon.ArrowDown} className={classes.filterGroupButtonsButtonIcon} />}
                    />
                )
            ) : null}
            {hasSubmitButton ? updateOnSubmitButton : null}

            {hasExpandButtonMobile ? (
                <>
                    <IconButton
                        round
                        iconClassName={CustomIcon.Filter}
                        iconFontSize={16}
                        onClick={isExpandedMobile ? onCollapseMobile : onExpandMobile}
                    ></IconButton>
                </>
            ) : null}
        </div>
    ) : null;
}
