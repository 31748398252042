import {JumioDocumentType} from '@models/generated/graphql';

import {domain as baseDomain} from '../types';

export const domain = `${baseDomain}/documents`;

export const idDocumentTypes = [
    JumioDocumentType.Passport,
    JumioDocumentType.LicenseDriver,
    JumioDocumentType.IdCard,
    JumioDocumentType.Visa,
    JumioDocumentType.Unsupported,
];
