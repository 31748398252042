import {useContext} from 'react';
import {IntlContext} from 'react-intl';
import {v4 as uuid} from 'uuid';

import {SecurityCaseType} from '@models/generated/graphql';

import {localizedSecurityCases} from './localize';
import {EditSecurityCasesFieldModel, EditSecurityCasesSubmitModel, SecurityCaseEditModel} from './types';

export const getSecurityCasesTypes = () => Object.values(SecurityCaseType).filter(v => v !== SecurityCaseType.Undefined);

export const useSecurityCases = (defaultValue: SecurityCaseEditModel[]) => {
    const {formatMessage} = useContext(IntlContext);

    const initialCases: SecurityCaseEditModel[] = defaultValue ?? [];
    const initialCasesLength = initialCases?.length ?? 0;

    const allCases: SecurityCaseEditModel[] = getSecurityCasesTypes().reduce((result, type) => {
        const initialTypeCases = initialCases?.filter(i => i?.type === type) ?? [];

        return initialTypeCases.length
            ? [
                  ...result,
                  ...initialTypeCases.map(
                      initialCase =>
                          ({
                              type,
                              id: initialCase?.case_id ?? uuid(),
                              case_id: initialCase?.case_id,
                              opened_at: initialCase?.opened_at,
                              closed_at: initialCase?.closed_at,
                              totalCount: initialCase?.totalCount,
                              isNew: false,
                          } as SecurityCaseEditModel)
                  ),
              ]
            : [
                  ...result,
                  {
                      type,
                      id: uuid(),
                      case_id: undefined,
                      isNew: false,
                  } as SecurityCaseEditModel,
              ];
    }, [] as SecurityCaseEditModel[]);

    const initialValue: EditSecurityCasesFieldModel = {
        cases: allCases,
    };

    const equalCasePredicate = (i: SecurityCaseEditModel, s: SecurityCaseEditModel) => i?.type === s?.type && i?.case_id === s?.case_id;

    const addCasesToFormValue = (formValue: EditSecurityCasesFieldModel, addedCases: SecurityCaseEditModel[]) => {
        addedCases?.forEach(i => {
            const initialCase = formValue?.cases?.find(u => equalCasePredicate(u, i));
            if (!initialCase) {
                formValue.cases.push(i);
            }
        });
    };

    const removeCasesFromFormValue = (formValue: EditSecurityCasesFieldModel, removedCases: SecurityCaseEditModel[]) => {
        removedCases?.forEach(i => {
            formValue.cases = formValue.cases.filter(u => !equalCasePredicate(u, i));
        });
    };

    const getFormValue = (
        addResultValue: EditSecurityCasesSubmitModel,
        updateResultValue: EditSecurityCasesSubmitModel,
        removeResultValue: EditSecurityCasesSubmitModel,
        totalUsersCount: number
    ): EditSecurityCasesFieldModel => {
        const formValue: EditSecurityCasesFieldModel = {...initialValue};

        addCasesToFormValue(formValue, addResultValue?.add);
        addCasesToFormValue(formValue, updateResultValue?.add);
        removeCasesFromFormValue(formValue, updateResultValue?.remove);
        removeCasesFromFormValue(formValue, removeResultValue?.remove);

        formValue.cases = formValue.cases?.map(c =>
            c?.case_id || c?.isNew
                ? {
                      ...c,
                      totalCount: c?.totalCount ?? totalUsersCount,
                  }
                : c
        );

        return formValue;
    };

    const getSubmitValue = (updatedValue: EditSecurityCasesFieldModel): EditSecurityCasesSubmitModel => {
        const deleteCases = initialCases.filter(i => !updatedValue?.cases.find(u => equalCasePredicate(u, i))).filter(i => i?.case_id);
        const addCases = updatedValue?.cases
            .filter(u => !initialCases.find(i => equalCasePredicate(i, u)))
            .filter(i => i?.case_id !== undefined);

        return {initial: initialCases, add: addCases, remove: deleteCases};
    };
    const subInfoMessage =
        initialCasesLength > 1
            ? formatMessage(localizedSecurityCases.activeSecurityCasesMessageMultiple, {total: initialCases.length})
            : formatMessage(localizedSecurityCases.activeSecurityCaseMessage, {total: initialCases.length});

    return {initialValue, subInfoMessage, getSubmitValue, getFormValue};
};
