import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {MutationForceExpireCashBonusArgs, PlayerBonus} from '@models/generated/graphql';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {entityActions, EntityType} from '@redux/entity';
import {IPlayerBonusService} from '@services/playerBonusService';
import {GqlMutationRequest} from '@services/types';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {ForceExpireRequestPayload, playerBonusActions} from './actions';
import {PlayerBonusNormalized} from './types';

const localized = defineMessages({
    forceExpireSuccess: {
        id: 'PlayerBonusEpicsBuilder_forceExpireSuccess',
        defaultMessage: 'Bonus {name} is expired',
    },
    forceExpireFailed: {
        id: 'PlayerBonusEpicsBuilder_forceExpireFailed',
        defaultMessage: 'Failed to force expire bonus',
    },
});

@injectable()
export class PlayerBonusEpicsBuilder extends BaseEpicsBuilder {
    private readonly _playerBonusService: IPlayerBonusService;
    constructor(@inject(ServiceTypes.PlayerBonusService) playerBonusService: IPlayerBonusService) {
        super();
        this._playerBonusService = playerBonusService;
    }

    protected buildEpicList(): Epic[] {
        return [
            this.buildExpirePlayerBonusRequestEpic(),
            this.buildExpirePlayerBonusSuccessEpic(),
            this.buildExpirePlayerBonusFailureEpic(),
        ];
    }

    private buildExpirePlayerBonusRequestEpic(): Epic {
        return this.buildRequestEpic<ForceExpireRequestPayload, GqlMutationRequest<MutationForceExpireCashBonusArgs>, PlayerBonus>(
            playerBonusActions.forceExpire,
            payload => this._playerBonusService.forceExpire(payload.bonusId)
        );
    }

    private buildExpirePlayerBonusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerBonusActions.forceExpire.success)),
                mergeMap(action => {
                    const updatedItem: PlayerBonusNormalized = action.payload?.responsePayload;
                    return of(
                        showMessageAction({message: localized.forceExpireSuccess, values: {name: updatedItem.bonus_name}}),
                        entityActions.updateItem({type: EntityType.PlayerBonus, id: updatedItem?.bonus_id, updatedItem})
                    );
                })
            );
    }

    private buildExpirePlayerBonusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(playerBonusActions.forceExpire.failure)),
                map(() => showErrorAction({message: localized.forceExpireFailed}))
            );
    }
}
