import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {EmptyPlaceholder} from '@components/select';
import {TransactionStatus} from '@models/generated/graphql';
import {TransactionFilterKeys} from '@redux/entity';

import {localizedTransactionStatus} from 'src/features/app/intl/shared-resources/transactionStatus';
import {SelectOption} from 'src/features/module-shared/types';
import FilterMultiSelect from 'src/features/shared/filter/components/FilterMultiSelect';
import {PageModuleChipSelectFilter} from 'src/features/shared/filter/types';
import {closedCasesStatuses, pendingCasesStatuses} from '../types';

import {TransactionFilterProps} from './TransactionFilters';

const localized = defineMessages({
    transactionStatusFilter: {
        id: 'TransactionFilterStatuses_transactionStatusFilter',
        defaultMessage: 'Status',
    },
    withdrawalStatusFilter: {
        id: 'TransactionFilterStatuses_withdrawalStatusFilter',
        defaultMessage: 'Withdrawal Status',
    },
});

function withStatusFilter(WrappedComponent: typeof FilterMultiSelect, label: string | MessageDescriptor, statuses: TransactionStatus[]) {
    const availableOptions = statuses.map(s => ({label: localizedTransactionStatus[s], value: s} as SelectOption));
    const filterKey: TransactionFilterKeys = 'transactionStatus';
    return function TransactionFilterStatusHOC(props: TransactionFilterProps) {
        const {filter, onFilterChange, defaultFilter} = props;
        return (
            <WrappedComponent
                onFilterChange={onFilterChange}
                domain={null}
                filter={
                    {
                        key: filterKey,
                        label,
                        chipType: nameof<TransactionStatus>(),
                        options: availableOptions,
                        defaultValue: defaultFilter,
                        emptyPlaceholder: EmptyPlaceholder.Any,
                        horizontalPosition: 'left',
                    } as PageModuleChipSelectFilter
                }
                filterString={filter}
            ></WrappedComponent>
        );
    };
}

export const TransactionFilterPendingStatuses = withStatusFilter(FilterMultiSelect, localized.withdrawalStatusFilter, pendingCasesStatuses);
export const TransactionFilterClosedStatuses = withStatusFilter(FilterMultiSelect, localized.withdrawalStatusFilter, closedCasesStatuses);
export const TransactionFilterAllStatuses = withStatusFilter(FilterMultiSelect, localized.transactionStatusFilter, [
    ...pendingCasesStatuses,
    ...closedCasesStatuses,
]);
