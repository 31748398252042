import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {CurrencyWithDateCell, KeyValueCell} from '@components/key-value-table';
import {KeyValueTableMode} from '@components/key-value-table/types';
import {TransactionType} from '@models/generated/graphql';
import {ViewType} from '@redux/view';

import {useLastTransaction} from '../hooks';

const localized = defineMessages({
    lastBonusTitle: {
        id: 'LastBonusCell_lastBonusTitle',
        defaultMessage: 'Last Bonus Awarded',
    },
});

export type LastBonusCellProps = {
    uid: string;
    mode?: KeyValueTableMode;
};

export function LastBonusCell({uid, mode}: LastBonusCellProps) {
    const {formatMessage} = useIntl();
    const viewType: ViewType = 'LastBonus';
    const transaction = useLastTransaction({
        uid,
        viewType,
        type: TransactionType.Bonus,
    });

    const value = transaction
        ? {amount: transaction.amount, currency: transaction.currency, date: transaction.transaction_started_ts}
        : null;
    return (
        <KeyValueCell title={formatMessage(localized.lastBonusTitle)} mode={mode}>
            <CurrencyWithDateCell value={value} />
        </KeyValueCell>
    );
}
