import {customerSupportUpdatePermissions} from '@models/permissions/permissions';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../../app/permission/PermissionHoc';
import {EditableNationalityAutocomplete} from '../../../view-autocomplete/components/NationalityAutocomplete';
import {piiActions} from '../actions';

import {withUserProfileCountry} from './UserProfileCountryHoc';
import {localized} from './UserProfileNationality.localize';

export const UserProfileNationality = withMultiplePermission(
    withUserProfileCountry(EditableNationalityAutocomplete, {
        action: piiActions.editNationality,
        placeholder: localized.piiNationalityFieldName,
        submitModelMapper: m => ({
            fieldValue: m?.user?.region_player_info?.nationality,
            userId: m?.userId,
        }),
        formModelMapper: m => ({
            user: {
                uid: m?.userId,
                region_player_info: {
                    nationality: m?.fieldValue,
                },
            },
            userId: m?.userId,
        }),
        //TODO: [IGP-2179] Added because of the backend partial update logic. Rework after backend change
        isRequired: true,
    }),
    {allowedPermissions: customerSupportUpdatePermissions, indicator: LackOfPermissionIndicator.Disabled}
);
