import {GameBlindConfig, GamePlatform, GameTemplateSettings} from '@models/game-template/types';
import {ManagedGameType} from '@redux/entity';

import {ItemsPage} from '../../types';

export class GameRoomResponse {
    data: {
        PageNo: number;
        PageCount: number;
        MatchCount: number;
        RoomList: GameRoomResponseListItem[];
    };
}

export class GameRoomResponseListItem {
    RoomId: number;
    CreatePlayerId: number;
    RoomName: string;
    PlayerCount: number;
    Players: Player[];
    TemplateId: number;
}

export class Player {
    Name: string;
    PlayerId: number;
}

export class GameRoomFilterInput {
    page_no?: number;
    per_page_count?: number;
    room_name?: string;
    template_id?: number;
}

export class GameRoom {
    gameType: ManagedGameType;
    roomId: number;
    ownerId: number;
    roomName: string;
    playerCount: number;
    players: Player[];
    templateId: number;
    templateSettings: GameTemplateSettings;
    blind: GameBlindConfig;
}
export class GameRoomItemPage extends ItemsPage<GameRoom> {}

export class GameFeaturesViewModel {
    geo: boolean;
    ip: boolean;
    tot: boolean;
    sho: boolean;
}

export class GameRoomViewModel {
    id: string;
    gameType: ManagedGameType;
    roomId: number;
    ownerId: number;
    roomName: string;
    templateId: number;
    platforms: GamePlatform[];
    stakes: StakesViewModel;
    players: PlayersInfoViewModel;
    features: GameFeaturesViewModel;
}

export class StakesViewModel {
    sb: number;
    bb: number;
    hasStraddle: boolean;
    ante: number;
    currency: string;
}

export class PlayersInfoViewModel {
    playerCount: number;
    playerMaxCount: number;
    players: PlayerViewModel[];
}

export class PlayerViewModel {
    id: string;
    playerId: string;
    name: string;
    roomId: string;
    gameType: ManagedGameType;
}

export type GameRoomViewModelKeys = keyof GameRoomViewModel | 'close';
