import {ApolloError, DocumentNode, NormalizedCacheObject} from '@apollo/client';
import {FetchResult} from 'apollo-link';
import {ExecutionResult} from 'graphql';
import {Observable, Subscriber} from 'rxjs';

import {Subscription} from '@models/generated/graphql';
import {ApolloClientProxy} from '@services/gql-api';

import {ErrorHandlingService} from '../../../features/app/error-handling/services';
import {ErrorCategory} from '../../../features/app/error-handling/types';
import {UnknownSubscriptionErrors} from '../gql-api/gqlService';

import {IGraphQLServiceBaseObsolete} from './IGraphQLServiceBaseObsolete';
import {IGQlFilterVariables} from './types';

/**
 * @deprecated should use services from src/common/services
 */
export class GraphQLServiceBaseObsolete implements IGraphQLServiceBaseObsolete {
    private client: ApolloClientProxy<NormalizedCacheObject>;
    private errorHandlingService: ErrorHandlingService;

    public constructor(client: ApolloClientProxy<NormalizedCacheObject>) {
        this.client = client;
        this.errorHandlingService = new ErrorHandlingService();
    }

    executeQuery<TQuery>(query: DocumentNode, filter?: IGQlFilterVariables): Observable<TQuery> {
        return this.handleErrors(this.client.query<TQuery>({query, variables: filter}));
    }

    executeMutation<TMutation>(mutation: DocumentNode, variables?: unknown): Observable<TMutation> {
        return this.handleErrors(this.client.mutate<TMutation>({mutation: mutation, variables}));
    }

    createSubscription(query: DocumentNode, filter?: IGQlFilterVariables): Observable<Subscription> {
        return this.handleWebsocketErrors(
            new Observable(observer =>
                this.client
                    .subscribe({query, fetchPolicy: 'no-cache', variables: filter})
                    .map(i => i.data)
                    .subscribe(observer)
            )
        );
    }

    private handleErrors<TResult>(promise: Promise<ExecutionResult<TResult>>) {
        return new Observable((subscriber: Subscriber<TResult>) => {
            promise
                .then(res => {
                    const err = res.errors ? this.getError(res) : null;
                    if (err) {
                        this.logError(err, ErrorCategory.GraphQL);
                    }
                    return err ? subscriber.error(err) : subscriber.next(res.data);
                })
                .catch(err => {
                    const error = this.getError(err);
                    this.logError(error, ErrorCategory.GraphQL);
                    return subscriber.error(error);
                })
                .finally(() => subscriber.complete());
        });
    }

    private handleWebsocketErrors(promise: Observable<Subscription>) {
        return new Observable((subscriber: Subscriber<Subscription>) => {
            promise.subscribe({
                next: args => {
                    return subscriber.next(args);
                },
                error: err => {
                    const error = this.getError(err);
                    this.logError(error, ErrorCategory.WebSocket);
                    return subscriber.error(error);
                },
                complete: () => {
                    return subscriber.complete();
                },
            });
        });
    }

    private logError(err: Error, category: ErrorCategory) {
        this.errorHandlingService.logError(err, category, this.constructor);
    }

    private getError(err: UnknownSubscriptionErrors | FetchResult | ApolloError): Error {
        const errors = (err as any).errors;
        const error = errors?.length > 0 ? errors[0] : err;
        return error as Error;
    }
}
