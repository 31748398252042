import React from 'react';

import {EntityType} from '@redux/entity';

import {
    BulkActionKey,
    BulkStepExecutionProps,
    usePerformStrategy,
    withBulkStepExecutionSummary,
    withBulkStepSummaryContentDataGrid,
} from '../../block-bulk-actions';

import {DataGridBulkTransactionsClient} from './DataGridBulkTransaction';

const BulkTransactionManualExecutionStepGrid = withBulkStepExecutionSummary(
    withBulkStepSummaryContentDataGrid(DataGridBulkTransactionsClient, ['rowIndex', 'uid', 'errorRaw'])
);

const BulkTransactionP2PTransferExecutionStepGrid = withBulkStepExecutionSummary(
    withBulkStepSummaryContentDataGrid(DataGridBulkTransactionsClient, ['uid', 'type', 'amount', 'error'])
);

export function BulkTransactionManualExecutionStepModal({onNext, hasRetry, withFlexibleGrid}: BulkStepExecutionProps) {
    const strategy = usePerformStrategy('PerformManualTransactionsStrategy');

    return (
        <BulkTransactionManualExecutionStepGrid
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.Transaction}
            actionsData={[{type: 'operation', actionKey: BulkActionKey.ManualTransactions, strategy}]}
            withFlexibleGrid={withFlexibleGrid}
        />
    );
}

export function BulkTransactionP2PTransferExecutionStepModal({onNext, hasRetry, withFlexibleGrid}: BulkStepExecutionProps) {
    const strategy = usePerformStrategy('PerformP2PTransfersStrategy');

    return (
        <BulkTransactionP2PTransferExecutionStepGrid
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.Transaction}
            actionsData={[{type: 'operation', actionKey: BulkActionKey.P2PTransfer, strategy}]}
            withFlexibleGrid={withFlexibleGrid}
        />
    );
}
