import React, {useContext} from 'react';
import {IntlContext, MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {StyledComponentProps} from '@mui/material/styles';
import {makeStyles} from 'tss-react/mui';

import {HtmlContentViewer} from '@components/HtmlContentViewer';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

import {LegalDocumentRead} from '../types';

import data from './data';

type LegalDocumentViewProps = {
    document: LegalDocumentRead;
    documentName: MessageDescriptor;
    fullContent?: boolean;
};

const useClasses = makeStyles()((theme: CustomTheme) => ({
    container: {
        margin: theme.spacing(0, 2),
        backgroundColor: theme.custom.palette.content.default,
        position: 'relative',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    contentWrapper: {
        padding: theme.spacing(2),
        overflow: 'auto',
    },
    contentWrapperUnlimited: {
        maxHeight: 'none',
    },
    content: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
    },
    header: {
        display: 'flex',
        padding: theme.spacing(2),
        backgroundColor: theme.custom.palette.content.header,
        color: theme.palette.primary.contrastText,
        position: 'relative',
    },
    headerTitle: {
        fontSize: '1.5em',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
    },
    headerVersion: {
        padding: theme.spacing(0, 2),
        margin: theme.spacing('auto', '0', 'auto', 'auto'),
        whiteSpace: 'nowrap',
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));

const LegalDocumentView = ({documentName, document, fullContent}: LegalDocumentViewProps & StyledComponentProps) => {
    const {formatMessage} = useContext(IntlContext);
    const {classes} = useClasses();

    const getVersionLabel = () => formatMessage(data.localized.versionLabel, {version: document?.version});

    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                <Box className={classes.headerTitle}>
                    <LocalizedText label={documentName} />
                    {document.required ? (
                        <span>
                            {' '}
                            <LocalizedText label={data.localized.required} />
                        </span>
                    ) : (
                        <></>
                    )}
                </Box>
                <Box className={classes.headerVersion}>{getVersionLabel()}</Box>
            </Box>
            <Box className={`${classes.contentWrapper} ${fullContent ? classes.contentWrapperUnlimited : ''}`}>
                <HtmlContentViewer className={classes.content} content={document?.document} />
            </Box>
        </Box>
    );
};

export default LegalDocumentView;
