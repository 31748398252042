import {defineMessages} from 'react-intl';

import {PlayersGroup} from '@models/generated/graphql';

export const localizedPlayersGroup = defineMessages({
    [PlayersGroup.BotPlayer]: {
        id: 'BotPlayer',
        defaultMessage: 'Bot Player',
    },
    [PlayersGroup.Evidence]: {
        id: 'Evidence',
        defaultMessage: 'Evidence',
    },
    [PlayersGroup.Undefined]: {
        id: 'Unknown',
        defaultMessage: 'Unknown',
    },
});
