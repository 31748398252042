import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {CircleBoxWithColorMapping, CircleBoxWithColorMappingProps} from '@components/circle-box';
import {SystemColorsEnum} from '@style';

type Platforms = 'BL' | 'IM' | 'MEME' | 'MT' | 'NJ' | 'NONE' | 'PKW' | 'WPK' | 'WPTO' | 'WPTG' | 'WPTGO' | 'UNKNOWN';

type UserPlatformProps = Pick<CircleBoxWithColorMappingProps<Platforms>, 'value'>;

const localizedPlatform = defineMessages<Platforms>({
    NONE: {
        id: 'platformNone',
        defaultMessage: 'None',
    },
    UNKNOWN: {
        id: 'platformUnknown',
        defaultMessage: 'Unknown',
    },
    PKW: {
        id: 'platformPkw',
        defaultMessage: 'PKW',
    },
    WPK: {
        id: 'platformWPK',
        defaultMessage: 'WPK',
    },
    WPTO: {
        id: 'platformWPTO',
        defaultMessage: 'WPTG',
    },
    NJ: {
        id: 'platformNj',
        defaultMessage: 'NJ',
    },
    IM: {
        id: 'platformIm',
        defaultMessage: 'IM',
    },
    MT: {
        id: 'platformMt',
        defaultMessage: 'MT',
    },
    BL: {
        id: 'platformBl',
        defaultMessage: 'BL',
    },
    MEME: {
        id: 'platformMeme',
        defaultMessage: 'MEME',
    },
    WPTG: {
        id: 'platformWPTG',
        defaultMessage: 'WPTG',
    },
    WPTGO: {
        id: 'platformWPTGO',
        defaultMessage: 'WPTGO',
    },
});

export function UserPlatform({value}: UserPlatformProps) {
    const {formatMessage} = useIntl();
    const colorMapping: Record<Platforms, SystemColorsEnum> = {
        BL: SystemColorsEnum.SkyBlue,
        IM: SystemColorsEnum.DarkTeal,
        MEME: SystemColorsEnum.Violet,
        MT: SystemColorsEnum.Orange,
        NJ: SystemColorsEnum.Red,
        NONE: SystemColorsEnum.DarkGrey,
        PKW: SystemColorsEnum.DarkPink,
        WPK: SystemColorsEnum.Emerald,
        WPTO: SystemColorsEnum.LightBlue,
        WPTG: SystemColorsEnum.LightBlue,
        WPTGO: SystemColorsEnum.Burgundy,
        UNKNOWN: SystemColorsEnum.DarkGrey,
    };

    const res = value === 'UNKNOWN' || value === 'NONE' ? undefined : value;
    const label = res ? localizedPlatform[res] : undefined;
    return (
        <CircleBoxWithColorMapping
            value={res}
            label={label ? formatMessage(label) : undefined}
            colorMapping={colorMapping}
        ></CircleBoxWithColorMapping>
    );
}
