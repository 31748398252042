import React, {ReactNode, SyntheticEvent} from 'react';
import {useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {Chip} from './Chips';
import {ChipType, ChipVariant} from './types';

type CollapsedChipListProps = {
    visibleChips: ReactNode[];
    collapsedChips: ReactNode[];
};

const localized = defineMessages({
    expandableListShowAll: {
        id: 'expandableListShowAll',
        defaultMessage: 'Show All',
    },
    expandableListHide: {
        id: 'expandableListHide',
        defaultMessage: 'Hide',
    },
});

const useClasses = makeStyles()((theme: CustomTheme) => ({
    chipAccordion: {
        background: 'transparent',
        boxShadow: 'none',
    },
    chipAccordionSummary: {
        minHeight: 'auto',
        height: 'auto',
        margin: 0,
        padding: 0,
        '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
            margin: theme.spacing(1.5, 0),
            '&.Mui-expanded': {
                margin: theme.spacing(1.5, 0),
            },
        },
    },
    chipAccordionDetails: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        padding: 0,
        marginBottom: theme.spacing(1.5, 0),
    },
    chipAccordionSummaryNumber: {
        '& .MuiChip-label': {
            fontSize: theme.typography.body2.fontSize,
        },
    },
}));

export function ChipAccordion({visibleChips, collapsedChips, ...otherProps}: CollapsedChipListProps) {
    const {formatMessage} = useIntl();
    const [expanded, setExpanded] = useState(false);
    const {classes} = useClasses();

    const handleExpandClick = (e: SyntheticEvent<HTMLElement, Event>, expanded: boolean) => {
        e.stopPropagation();

        setExpanded(expanded);
    };

    return (
        <Accordion expanded={expanded} className={classes.chipAccordion} {...otherProps}>
            <AccordionSummary expandIcon={<></>} className={classes.chipAccordionSummary}>
                {visibleChips}
                {collapsedChips?.length > 0 && !expanded ? (
                    <Chip
                        chipType={ChipType.Status}
                        chipVariant={ChipVariant.Transparent}
                        label={formatMessage(localized.expandableListShowAll)}
                        onClick={e => handleExpandClick(e, true)}
                        size="medium"
                        fillWidth={false}
                        value={collapsedChips?.length}
                        deleteIcon={
                            <Chip
                                chipType={ChipType.Status}
                                className={classes.chipAccordionSummaryNumber}
                                chipVariant={ChipVariant.LightGrey}
                                label={`${collapsedChips?.length}`}
                                fillWidth={false}
                            />
                        }
                        onDelete={e => handleExpandClick(e, true)}
                    ></Chip>
                ) : null}
            </AccordionSummary>
            <AccordionDetails className={classes.chipAccordionDetails}>
                {collapsedChips}
                {expanded ? (
                    <Chip
                        chipType={ChipType.Status}
                        chipVariant={ChipVariant.Transparent}
                        label={formatMessage(localized.expandableListHide)}
                        onClick={e => handleExpandClick(e, false)}
                        size="medium"
                        fillWidth={false}
                    ></Chip>
                ) : null}
            </AccordionDetails>
        </Accordion>
    );
}
