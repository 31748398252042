import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';

import {SquareIconButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons/types';
import StyledTooltip from '@components/Tooltip';

import moduleActions from '../actions';

type ResendPasscodeActionProps = {
    id: string;
};

const localized = defineMessages({
    resendPasscodeLabel: {
        id: 'resendPasscodeLabel',
        defaultMessage: 'Reset passcode',
    },
});

const ResendPasscodeAction = ({id}: ResendPasscodeActionProps) => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(moduleActions.resetPasscode.request(id));
    };

    return (
        <StyledTooltip
            element={
                <Box>
                    <SquareIconButton onClick={onClick} iconClassName={CustomIcon.Mail} />
                </Box>
            }
            title={<LocalizedText label={localized.resendPasscodeLabel} />}
            hideTooltip={false}
        />
    );
};

export default ResendPasscodeAction;
