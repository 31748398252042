import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {ChangeOrderInput, CreateUpdateRuleInput, Rule} from '@models/rule/types';
import {map} from '@otel';
import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload} from '@redux/entity';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {RestService} from '@services/rest-api/restService';
import {ServerResponseError, ServerResponseStatus, ServiceResponsePayload} from '@services/types';

export type BaseRuleRequestPayload = {
    ruleId?: string;
};
export type UpdateRuleRequestPayload = BaseRuleRequestPayload & CreateUpdateRuleInput;
export type ChangeRuleOrderRequestPayload = BaseRuleRequestPayload & ChangeOrderInput;
export type BaseRuleResponsePayload = ServiceResponsePayload<BaseRuleRequestPayload, null>;
export type UpdateRuleResponsePayload = ServiceResponsePayload<UpdateRuleRequestPayload, Rule>;
export type ChangeRuleOrderResponsePayload = ServiceResponsePayload<ChangeRuleOrderRequestPayload, null>;

@injectable()
export class RuleApiService {
    private readonly _apiRouteService: ApiRoutesService;
    private readonly _restService: RestService;

    constructor(
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService,
        @inject(ServiceTypes.RestService) restService: RestService
    ) {
        this._apiRouteService = apiRouteService;
        this._restService = restService;
    }

    public getRules(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload<Rule>> {
        const rulesEndpoint = this._apiRouteService.getRulesEndpoint();
        return this._restService.get({endpoint: rulesEndpoint}).pipe(
            map(response => {
                const responseData: Rule[] = response?.responsePayload?.response;
                return {
                    ...response,
                    requestPayload,
                    responsePayload: responseData?.length ? {items: responseData, total: responseData?.length} : {items: [], total: 0},
                };
            }),
            catchError(error => {
                return this.getFailedServiceResponse(requestPayload, error);
            })
        );
    }

    public getRule(ruleId: string, requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload<Rule>> {
        const ruleEndpoint = this._apiRouteService.getRuleEndpoint(ruleId);
        return this._restService.get({endpoint: ruleEndpoint}).pipe(
            map(response => {
                const responseData: Rule = response?.responsePayload?.response;
                return {
                    ...response,
                    requestPayload,
                    responsePayload: responseData ? {items: [responseData], total: 1} : {items: [], total: 0},
                };
            }),
            catchError(error => {
                return this.getFailedServiceResponse(requestPayload, error);
            })
        );
    }

    public createRule(requestPayload: UpdateRuleRequestPayload): Observable<ServiceResponsePayload<UpdateRuleRequestPayload, Rule>> {
        const rulesEndpoint = this._apiRouteService.getRulesEndpoint();
        return this._restService.post({endpoint: rulesEndpoint, body: requestPayload}).pipe(
            map(response => {
                return {
                    ...response,
                    requestPayload,
                    responsePayload: (response?.responsePayload?.response ?? null) as Rule,
                };
            }),
            catchError(error => {
                return this.getFailedServiceResponse(requestPayload, error);
            })
        );
    }

    public editRule(requestPayload: UpdateRuleRequestPayload): Observable<ServiceResponsePayload<UpdateRuleRequestPayload, Rule>> {
        const ruleEndpoint = this._apiRouteService.getRuleEndpoint(requestPayload?.ruleId);
        return this._restService.put({endpoint: ruleEndpoint, body: requestPayload}).pipe(
            map(response => {
                return {
                    ...response,
                    requestPayload,
                    responsePayload: (response?.responsePayload?.response ?? null) as Rule,
                };
            }),
            catchError(error => {
                return this.getFailedServiceResponse(requestPayload, error);
            })
        );
    }

    public deleteRule(requestPayload: BaseRuleRequestPayload): Observable<BaseRuleResponsePayload> {
        const ruleDeleteEndpoint = this._apiRouteService.getRuleEndpoint(requestPayload?.ruleId);
        return this._restService.delete({endpoint: ruleDeleteEndpoint}).pipe(
            map(response => {
                return {
                    ...response,
                    requestPayload,
                    responsePayload: null,
                };
            }),
            catchError(error => {
                return this.getFailedServiceResponse(requestPayload, error);
            })
        );
    }

    public activateRule(requestPayload: BaseRuleRequestPayload): Observable<BaseRuleResponsePayload> {
        const ruleActivateEndpoint = this._apiRouteService.getActivateRuleEndpoint(requestPayload?.ruleId);
        return this._restService.delete({endpoint: ruleActivateEndpoint}).pipe(
            map(response => {
                return {
                    ...response,
                    requestPayload,
                    responsePayload: null,
                };
            }),
            catchError(error => {
                return this.getFailedServiceResponse(requestPayload, error);
            })
        );
    }

    public deactivateRule(requestPayload: BaseRuleRequestPayload): Observable<BaseRuleResponsePayload> {
        const ruleDeactivateEndpoint = this._apiRouteService.getDeactivateRuleEndpoint(requestPayload?.ruleId);
        return this._restService.put({endpoint: ruleDeactivateEndpoint}).pipe(
            map(response => {
                return {
                    ...response,
                    requestPayload,
                    responsePayload: null,
                };
            }),
            catchError(error => {
                return this.getFailedServiceResponse(requestPayload, error);
            })
        );
    }

    public changeRuleOrder(requestPayload: ChangeRuleOrderRequestPayload): Observable<ChangeRuleOrderResponsePayload> {
        const changeRuleOrderEndpoint = this._apiRouteService.getChangeRuleOrderEndpoint(requestPayload?.ruleId);
        return this._restService.put({endpoint: changeRuleOrderEndpoint, body: {order: requestPayload.order} as ChangeOrderInput}).pipe(
            map(response => {
                return {
                    ...response,
                    requestPayload,
                    responsePayload: null,
                };
            }),
            catchError(error => {
                return this.getFailedServiceResponse(requestPayload, error);
            })
        );
    }

    private getFailedServiceResponse<TRequest>(
        requestPayload: TRequest,
        error?: ServerResponseError
    ): Observable<ServiceResponsePayload<TRequest, null>> {
        return of({
            status: ServerResponseStatus.Failed,
            message: error?.message,
            errors: error ? [error] : undefined,
            requestPayload,
            responsePayload: null,
        });
    }
}
