import {MessageDescriptor} from 'react-intl';

export interface IModuleGridItem {
    id: string; // unique grid row id (server id or index)
    serverId?: string; // id from server
    isNew?: boolean;
    __typename?: string;
}

export type SortDirection = 'asc' | 'desc' | string;

export type ColumnTypes = 'link' | 'text' | 'localized_text' | 'currency';

export type DataGridColumn<TModel, TColumnValue = unknown, TColumnType extends ColumnTypes = ColumnTypes> = {
    type: TColumnType;
    valueGetter: (model: TModel) => TColumnValue;
    field: string;
    title: MessageDescriptor;
    width: number;
    sortable?: boolean;
    sortDirection?: SortDirection;
};

type LinkColumnValue = {
    path: string;
    title: string;
};

export type LinkColumn<TModel> = DataGridColumn<TModel, LinkColumnValue, 'link'>;

export type TextColumn<TModel> = DataGridColumn<TModel, string, 'text'>;

export type LocalizedTextColumn<TModel> = DataGridColumn<TModel, MessageDescriptor, 'localized_text'>;

export type CurrencyColumnValue = {
    amount: number;
    currency: string;
};

export type CurrencyColumn<TModel> = DataGridColumn<TModel, CurrencyColumnValue, 'currency'>;

type DataGridPagination = {
    page: number;
    pageCount?: number;
    pageSize: number;
    pagePrevHash?: string;
    pageNextHash?: string;
    rowCount: number;
};

type SortField = {
    sort: SortDirection;
    field: string;
};

type DataGridSorting = {
    sortModel: SortField[];
};

export type DataGridSelection<TModel> = {
    disabled: boolean;
    selectedIds: [string];
    idGetter: (row: TModel) => string;
};

export type GridModelWithSummary = {
    is_summary: boolean;
};

type ConfigurableColumns<TColumnKey> = {
    pinnedColumns?: TColumnKey[];
    visibleColumns: TColumnKey[];
    onVisibleColumnsChange: (visibleColumns: TColumnKey[]) => void;
};

type FreezeColumns = {
    mobile?: number;
    desktop?: number;
};

export type DataGridProps<TModel, TColumnKey extends string, TColumn extends DataGridColumn<TModel>> = {
    type: 'glide' | 'mui';
    mode: 'server' | 'client';
    columns: TColumn[];
    rows: TModel[];
    pinnedRow?: TModel;
    selection?: DataGridSelection<TModel>;
    emptyMessage?: MessageDescriptor;
    frozenColumnsCount?: FreezeColumns;
    paging: DataGridPagination;
    sortModel?: DataGridSorting;
    configurableColumns?: ConfigurableColumns<TColumnKey>;

    onSortChange?: (sortModel: SortField[]) => void;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    onSelect?: (ids: string[]) => void;
    onExpand?: (expandedId: number) => void;
};

export const emptyCellCharacter = '—';
