import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import {useMessageFormatter} from '../../../../../features/app/intl/hooks';
import {SystemColorsEnum} from '../../../../style';
import {getFirstCharacterInString} from '../../../../utils/string';
import {CircleBoxWithText} from '../../../circle-box/CircleBoxWithText';

type CircleWithTextCellProps = {
    value: string | MessageDescriptor;
};

const CircleWithTextCell = ({value}: CircleWithTextCellProps) => {
    const formatMessage = useMessageFormatter();
    const valueLocalized = formatMessage(value);

    return (
        <CircleBoxWithText
            letter={getFirstCharacterInString(valueLocalized)}
            color={SystemColorsEnum.LightBlue}
            size="small"
            text={valueLocalized}
        />
    );
};

export const renderCircleWithTextCell = (params: GridCellParams) => {
    const defaultValue = params.value;
    return <CircleWithTextCell value={defaultValue} />;
};
