import {createAsyncAction} from 'typesafe-actions';

import {IFailureResponse} from '../module-shared/types';

import {domain, LegalDocumentItem} from './types';

const loadAcceptedTermsAction = '/loadAcceptedTerms';
const loadAcceptedTermsSucceedAction = '/loadAcceptedTermsSucceed';
const loadAcceptedTermsFailedAction = '/loadAcceptedTermsFailed';

const loadAcceptedPrivacyPolicyAction = '/loadAcceptedPrivacyPolicy';
const loadAcceptedPrivacyPolicySucceedAction = '/loadAcceptedPrivacyPolicySucceed';
const loadAcceptedPrivacyPolicyFailedAction = '/loadAcceptedPrivacyPolicyFailed';

export default {
    loadAcceptedPrivacyPolicy: createAsyncAction(
        `${domain}${loadAcceptedPrivacyPolicyAction}`,
        `${domain}${loadAcceptedPrivacyPolicySucceedAction}`,
        `${domain}${loadAcceptedPrivacyPolicyFailedAction}`
    )<string, LegalDocumentItem, IFailureResponse>(),

    loadAcceptedTerms: createAsyncAction(
        `${domain}${loadAcceptedTermsAction}`,
        `${domain}${loadAcceptedTermsSucceedAction}`,
        `${domain}${loadAcceptedTermsFailedAction}`
    )<string, LegalDocumentItem, IFailureResponse>(),
};
