import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import moment from 'moment';

import {EditableProps, withEditButton} from '@components/editable/EditButtonHoc';
import {DateSinglePicker, DateSinglePickerProps} from '@components/input';
import {momentToTimestampSeconds, timestampSecondsToDate} from '@utils';

import {useJurisdictionConfig} from '../../../features/app/config/hooks';
import {
    convertInternalFormatToSeconds,
    convertSecondsToInternalFormat,
} from '../../../features/shared/filter/components/date-range-picker/filterDateRangePickerUtils';

import {useClasses} from './EditableDate.style';

//TODO: [BO-2664] Remove dependency to features (pass data through props)
const EditableDateInternal = ({mode, disableFutureDates = true, locale, ...props}: EditableProps & DateSinglePickerProps) => {
    const {classes} = useClasses();
    const {timezone} = useJurisdictionConfig();

    const {disabled, value, onChange, formatter, placeholder, hasBottomSpacing, fullWidth} = props;
    const [selectedDate, setSelectedDate] = useState(value);

    useEffect(() => {
        if (value) {
            setSelectedDate(convertSecondsToInternalFormat(momentToTimestampSeconds(moment(value)), timezone));
        } else {
            setSelectedDate(null);
        }
    }, [JSON.stringify(value)]);

    const [open, setOpen] = useState(false);

    const handleDateChange = (date: Date) => {
        onChange(date ? timestampSecondsToDate(convertInternalFormatToSeconds(true, date, timezone)) : null);
        setOpen(false);
    };

    const handleDropdownOpen = () => {
        setOpen(true);
    };

    const handleDropdownClose = () => {
        setOpen(false);
    };

    const isEditDisabled = disabled || mode !== 'Edit';

    return isEditDisabled ? (
        <Box className={classes.editableDateReadonly}>{formatter(selectedDate)}</Box>
    ) : (
        <DateSinglePicker
            className={classes.editableDate}
            value={selectedDate}
            formatter={formatter}
            open={open}
            disableFutureDates={disableFutureDates}
            placeholder={placeholder}
            onChange={handleDateChange}
            onDropdownOpen={handleDropdownOpen}
            onDropdownClose={handleDropdownClose}
            hasBottomSpacing={hasBottomSpacing}
            fullWidth={fullWidth}
            locale={locale}
            renderInsideInputConteiner={false}
        />
    );
};

export const EditableDate = withEditButton(EditableDateInternal);
