import produce from 'immer';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';

import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {BaseHttpService} from '@services/deprecated';
import {ConfigApiService} from '@services/deprecated';
import {IGridService} from '@services/deprecated';
import {PlayerManagementApiService} from '@services/deprecated';
import {RestHttpService} from '@services/deprecated';
import {AjaxResponse} from '@services/rest-api';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';

import {ILegalDocsService, LegalDocumentRead, VersionAcceptanceDocument, VersionAcceptanceGridItem} from './types';
import {convertReadToWriteModel} from './utils';

export class LegalDocManagementService implements ILegalDocsService {
    private readonly legalDocManagement: RestHttpService;
    private readonly playerHttpService: BaseHttpService;

    constructor(tracingService: ITracingService, userManager: UserManagerExtended) {
        this.legalDocManagement = new ConfigApiService('legal', tracingService, userManager);
        this.playerHttpService = new PlayerManagementApiService(tracingService, userManager);
    }

    getItem(id: string): Observable<LegalDocumentRead> {
        return this.legalDocManagement.getItem(id).pipe(
            map<LegalDocumentRead, LegalDocumentRead>(response => {
                response.id = id;
                return response;
            })
        );
    }

    editItem(item: LegalDocumentRead): Observable<string> {
        return this.playerHttpService.post('/legal', convertReadToWriteModel(item)).pipe<string>(map<AjaxResponse, string>(() => ''));
    }

    addItem(item: LegalDocumentRead): Observable<string> {
        return this.editItem(item);
    }
}

export class UserAcceptanceVersionService implements IGridService<VersionAcceptanceDocument, VersionAcceptanceGridItem> {
    private readonly versionAcceptanceHttpService: PlayerManagementApiService;
    private readonly documentHttpService: ConfigApiService;

    constructor(tracingService: ITracingService, userManager: UserManagerExtended) {
        this.versionAcceptanceHttpService = new PlayerManagementApiService(tracingService, userManager, 'legal');
        this.documentHttpService = new ConfigApiService('legal', tracingService, userManager);
    }

    getItem(id: string): Observable<VersionAcceptanceDocument> {
        return this.documentHttpService.getItem(id);
    }

    getItems(filter?: SearchFilter): Observable<VersionAcceptanceGridItem[]> {
        return this.versionAcceptanceHttpService.getItems(filter);
    }

    getItemsPage(filter?: SearchFilter): Observable<ItemsPage<VersionAcceptanceGridItem>> {
        let updatedFilter = filter ?? ({} as SearchFilter);

        updatedFilter = produce(updatedFilter, f => {
            f.filter = [
                ...(f.filter ?? []),
                {
                    key: nameof<VersionAcceptanceDocument>(i => i.accepted),
                    value: true.toString(),
                } as Filter,
            ];
        });

        return this.versionAcceptanceHttpService.getItemsPage(updatedFilter).pipe(
            map<ItemsPage<VersionAcceptanceGridItem>, ItemsPage<VersionAcceptanceGridItem>>(itemsPage => ({
                ...itemsPage,
                items: itemsPage.items.map(
                    i => ({...i, documents: i.documents?.map(i => ({...i, id: uuid()})), id: uuid()} as VersionAcceptanceGridItem)
                ),
            }))
        );
    }

    editItem(): Observable<string> {
        return of();
    }

    addItem() {
        return of();
    }
}
