import React from 'react';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ModuleName} from '@models/modules';
import {Policy} from '@auth';

import {EmbeddedReport} from '../features/block-embedded-report/components/EmbeddedReport';

export const embeddedReportResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'Embedded Agent Report',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageEmbeddedReport, null, 'read')]),
});

export function PlayerLevelEmbeddedReportInner() {
    return <EmbeddedReport module="PlayerLevelReport" />;
}
export const PlayerLevelEmbeddedReport = withPolicyAccessCheck(PlayerLevelEmbeddedReportInner, embeddedReportResource);

export function AgentLevelEmbeddedReportInner() {
    return <EmbeddedReport module="AgentLevelReport" />;
}
export const AgentLevelEmbeddedReport = withPolicyAccessCheck(AgentLevelEmbeddedReportInner, embeddedReportResource);
export function TransferEmbeddedReportInner() {
    return <EmbeddedReport module="TransferReport" />;
}
export const TransferEmbeddedReport = withPolicyAccessCheck(TransferEmbeddedReportInner, embeddedReportResource);
