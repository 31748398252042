import {Observable, of} from 'rxjs';

import {IModuleGridItem} from '@components/data-grid';

import {IFormItem, IModuleItem} from '../../../features/module-shared/types';

import {RealtimeGridServiceBase} from './realtimeGridServiceBase';

/**
 * @deprecated should use services from src/common/services
 */
export abstract class ReadOrUpdateRealtimeGridServiceBase<
    TItemModel extends IModuleItem,
    TGridItemModel extends IModuleGridItem,
    TServerModel
> extends RealtimeGridServiceBase<TItemModel, TGridItemModel, TServerModel> {
    addItem(_item: IFormItem): Observable<IFormItem> {
        return of();
    }
}
