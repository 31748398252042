import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {Alert as MuiAlert} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {CustomTheme} from '@style';

const useClasses = makeStyles<void, 'alertContent'>()((theme: CustomTheme, _params, classes) => ({
    alert: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius * 1.5,
        overflow: 'hidden',
        fontSize: theme.typography.body1.fontSize,
    },
    alertContent: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: theme.spacing(0.375),
        paddingLeft: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
    },
    alertIcon: {
        fontSize: theme.typography.body1.fontSize,
        marginRight: theme.spacing(1.5),
    },
    alertInfo: {
        color: theme.palette.secondary.main,
        background: theme.palette.secondary.main,
        [`& .${classes.alertContent}`]: {
            background: theme.custom.palette.content.backgroundLight,
        },
    },
    alertSuccess: {
        color: theme.palette.success.main,
        background: theme.palette.success.main,
        [`& .${classes.alertContent}`]: {
            background: theme.palette.success.light,
        },
    },
    alertError: {
        color: theme.custom.palette.status.bronze.main,
        background: theme.custom.palette.status.bronze.main,
        [`& .${classes.alertContent}`]: {
            background: theme.custom.palette.status.bronze.light,
        },
    },
}));

type AlertProps = {
    title: MessageDescriptor;
    className?: string;
    icon?: JSX.Element;
};

const Alert: React.FC<AlertProps> = ({title, className, icon}: AlertProps) => {
    const {classes, cx} = useClasses();

    return (
        <MuiAlert className={cx(className, classes.alert)}>
            <Box className={classes.alertContent}>
                {icon}
                <LocalizedText label={title} />
            </Box>
        </MuiAlert>
    );
};

export const InfoAlert: React.FC<AlertProps> = ({title, className}: AlertProps) => {
    const {classes, cx} = useClasses();

    return (
        <Alert
            title={title}
            icon={<Icon color={IconColor.Secondary} icon={CustomIcon.ErrorCircleOutline} className={classes.alertIcon} />}
            className={cx(classes.alertInfo, className)}
        />
    );
};

export const SuccessAlert: React.FC<AlertProps> = ({title, className}: AlertProps) => {
    const {classes, cx} = useClasses();

    return (
        <Alert
            title={title}
            icon={<Icon color={IconColor.Success} icon={CustomIcon.ConfirmCircle} className={classes.alertIcon} />}
            className={cx(classes.alertSuccess, className)}
        />
    );
};

export const ErrorAlert: React.FC<AlertProps> = ({title, className}: AlertProps) => {
    const {classes, cx} = useClasses();

    return (
        <Alert
            title={title}
            icon={<Icon color={IconColor.Bronze} icon={CustomIcon.ErrorCircleOutline} className={classes.alertIcon} />}
            className={cx(classes.alertError, className)}
        />
    );
};
