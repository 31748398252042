import {ValueButton, ValueButtonProps} from '@components/button';
import {StyledInput} from '@components/input/StyledInput';

import Button, {ButtonProps, IconButton, IconButtonProps, OutlinedButton, SelectButtonWithChip} from '../button/Buttons';

import {withDropdown} from './DropdownHoc';

//TODO: [BO-2669] Move dropdown components to input folder (?)
export const Dropdown = withDropdown<ButtonProps>(Button);
export const SelectButtonWithChipDropdown = withDropdown(SelectButtonWithChip);
export const StyledInputDropdown = withDropdown(StyledInput);
export const OutlinedButtonDropdown = withDropdown<ButtonProps>(OutlinedButton);
export const IconButtonDropdown = withDropdown<IconButtonProps>(IconButton);
export const ValueButtonDropdown = withDropdown<Omit<ValueButtonProps, 'onClick'>>(ValueButton);
