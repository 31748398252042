import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {usePolicyAccessCheck} from '@access-control';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {useJurisdictionFeature} from 'src/features/app/config/hooks';
import {FeatureName} from 'src/features/app/config/types';
import {useMultiplePermissions} from '../../app/permission/PermissionHoc';
import {userProfileActions} from '../actions';
import {
    financeActionsReadPermissions,
    financeActionsUpdatePermissions,
    financeLockReadResource,
    financeLockUpdateResource,
} from '../permissions';

import {LockActionProps} from './UserProfileActions';
import {UserProfileLock} from './UserProfileLock';

const localized = defineMessages({
    label: {
        id: 'WithdrawalLock_label',
        defaultMessage: 'Withdrawal Lock',
    },
});

type WithdrawalLockProps = LockActionProps<FeatureAccessStatus>;

function WithdrawalLockInner({uid, status, disabled}: WithdrawalLockProps) {
    const {formatMessage} = useIntl();

    return (
        <UserProfileLock
            action={userProfileActions.changeWithdrawalStatus}
            label={formatMessage(localized.label)}
            uid={uid}
            isLocked={status === FeatureAccessStatus.Locked}
            disabled={disabled}
            dataTestId="withdrawal-status-lock"
        />
    );
}

export function WithdrawalLock(props: WithdrawalLockProps) {
    const isFinanceLockPolicyAvailiable = useJurisdictionFeature({
        featureName: FeatureName.FinanceActionsNewPolicies,
    });

    const isReadAvailable = isFinanceLockPolicyAvailiable
        ? usePolicyAccessCheck(financeLockReadResource)
        : useMultiplePermissions({allowedPermissions: financeActionsReadPermissions});

    const isUpdateAvailable = isFinanceLockPolicyAvailiable
        ? usePolicyAccessCheck(financeLockUpdateResource)
        : useMultiplePermissions({allowedPermissions: financeActionsUpdatePermissions});

    const getComponent = () => (isUpdateAvailable ? <WithdrawalLockInner {...props} /> : <WithdrawalLockInner {...props} disabled />);

    return isReadAvailable ? getComponent() : <></>;
}
