import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {DialogTitle} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';

type BulkModalTitleProps = {
    title?: MessageDescriptor;
    children?: JSX.Element;
};

// TODO: [BO-3004] Update modal components
export const BulkModalTitle = ({title, children}: BulkModalTitleProps) => {
    return <DialogTitle>{children ?? <LocalizedText label={title} />}</DialogTitle>;
};
