import {useContext, useEffect, useState} from 'react';

import {UserSettings} from '@user-settings';

import {UserSettingsContext} from './components/UserSettingsProvider';
import {BaseSettingsType, IDbUserSettingsStorage} from './types';

export function useUserStorage(): IDbUserSettingsStorage {
    const {storage} = useContext(UserSettingsContext);

    return storage;
}

type UseUserSettingsProps = {
    userId: string;
    featureName: string;
    settingName: string;
};

export type UseUserSettingsResult<TValue> = {
    settings?: UserSettings<TValue>;
    onSettingsChange?: (value: TValue) => void;
};

export function useUserSettings<TValue>(props: UseUserSettingsProps): UseUserSettingsResult<TValue> {
    const {userId, featureName, settingName} = props;
    const userSettingsStorage = useUserStorage();
    const [settingsId, setSettingsId] = useState<number>(null);
    const [settings, setSettings] = useState<UserSettings<TValue>>(null);
    useEffect(() => {
        if (userId && featureName && settingName) {
            userSettingsStorage.userSettings
                .get({
                    userId,
                    featureName,
                    settingName,
                })
                .then((value: UserSettings<TValue>) => {
                    if (value) {
                        setSettings(value);
                        setSettingsId(value.id);
                    }
                });
        }
    }, [userId, featureName, settingName]);

    function handleSettingsChange(value: TValue) {
        function updateState() {
            setSettings({...settings, value});
        }
        if (settingsId) {
            userSettingsStorage.userSettings.update(settingsId, {value}).then(updateState);
        } else {
            userSettingsStorage.userSettings.add({userId, featureName, settingName, value}).then(id => {
                updateState();
                setSettingsId(id);
            });
        }
    }
    return {
        settings,
        onSettingsChange: handleSettingsChange,
    };
}

type UseColumnsSettingsProps<TSettingKey extends BaseSettingsType> = {
    userId: string;
    settingId: string;
    defaultColumns: (keyof TSettingKey)[];
    pinnedColumns?: (keyof TSettingKey)[];
};

type UseColumnsSettingsResult<TSettingKey extends BaseSettingsType> = {
    visible: (keyof TSettingKey)[];
    pinned: (keyof TSettingKey)[];
    onVisibleColumnsChange: (value: (keyof TSettingKey)[]) => void;
};

export function useColumnsSettings<TSettingKey extends BaseSettingsType>(
    {pinnedColumns, defaultColumns, settingId, ...props}: UseColumnsSettingsProps<TSettingKey>,
    _useUserSettings = useUserSettings
): UseColumnsSettingsResult<TSettingKey> {
    const {settings, onSettingsChange} = _useUserSettings<(keyof TSettingKey)[]>({
        ...props,
        featureName: settingId,
        settingName: 'visible_columns',
    });
    const storedColumns = settings?.value ? settings.value : defaultColumns;
    const pinned = pinnedColumns ?? [];
    return {
        pinned,
        visible: [...new Set([...pinned, ...storedColumns])],
        onVisibleColumnsChange: onSettingsChange,
    };
}
