import {useEffect, useRef, useState} from 'react';

//TODO: [BO-2693] Move files to src/common/components
export function useDebounce<T>(value: T, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
}

export function useDebouncedCallback<T>(value: T, delay: number, callback: (value: T) => void) {
    const debouncedValue = useDebounce(value, delay);

    const isFirstRun = useRef(true);
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        callback(debouncedValue);
    }, [debouncedValue]);
}
