import {PermissionEnum} from '@models/permissions';

import {Module} from 'src/features/modules/types';

export type Permission = {
    action: PermissionEnum;
};

export class Policy {
    id: string;
    module: Module;
    submodule: Module;
    permissions: Permission[];
}

export class BoRole {
    id: string;
    name: string;
    policies: Policy[];

    constructor() {
        this.policies = [];
    }
}

export type BoRoleList = {
    count: number;
    page: number;
    size: number;
    items: BoRole[];
};
