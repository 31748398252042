import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {CSSObject} from '@emotion/react';
import {Box} from '@mui/material';
import {Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {LinkText} from '@components/link';
import {CustomTheme} from '@style';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    pageHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        height: theme.custom.pageHeaderHeight,
        minHeight: theme.custom.pageHeaderHeight,
        alignItems: 'center',
        padding: theme.spacing(0, 5),
        gap: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.mixins.toolbar.minHeight,
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2),
            height: 'unset',
            minHeight: 'unset',
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.background.paper,
            paddingLeft: theme.spacing(5),
        },
    },
    pageHeaderSpacing: {
        marginBottom: theme.spacing(2.5),
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    pageHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    pageHeaderTitleStartBreadcrumb: {
        ...(theme.typography.h5 as CSSObject),
        display: 'flex',
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            ...(theme.typography.h6 as CSSObject),
        },
    },
    pageHeaderTitleStartBreadcrumbWithSubheader: {
        ...(theme.typography.h6 as CSSObject),
        color: theme.palette.secondary.main,
    },
    pageHeaderSubheader: {
        ...(theme.typography.h5 as CSSObject),
        [theme.breakpoints.down('sm')]: {
            ...(theme.typography.h6 as CSSObject),
        },
    },
    pageHeaderTitleSeparator: {
        alignSelf: 'center',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    pageHeaderAction: {
        display: 'inline-flex',
        gap: theme.spacing(2),
    },
    pageHeaderSubHeaderLink: {
        color: theme.palette.text.primary,
    },
}));

type PageHeaderProps = {
    header?: string | MessageDescriptor;
    subHeader?: string | MessageDescriptor;
    subHeaderLink?: string;
    removeBottomSpacing?: boolean;
    actions?: React.ReactNode;
};

const TitleSeparatorCharacter = '/';

const PageHeader = ({header, subHeader, subHeaderLink, removeBottomSpacing, actions}: PageHeaderProps) => {
    const {classes, cx} = useStyles();
    return (
        <div className={`${classes.pageHeader} ${!removeBottomSpacing ? classes.pageHeaderSpacing : ''}`}>
            <div className={classes.pageHeaderTitle}>
                {header ? (
                    <div
                        className={cx(
                            {
                                [classes.pageHeaderTitleStartBreadcrumbWithSubheader]: !!subHeader,
                            },
                            classes.pageHeaderTitleStartBreadcrumb
                        )}
                    >
                        <LocalizedText label={header} />
                        {subHeader ? <Box className={classes.pageHeaderTitleSeparator}>{TitleSeparatorCharacter}</Box> : null}
                    </div>
                ) : null}
                {subHeader ? (
                    <Typography variant="h5" color="textPrimary" data-testid="moduleSubHeader">
                        {subHeaderLink ? (
                            <LinkText underline="none" href={subHeaderLink} title={subHeader} className={classes.pageHeaderSubHeaderLink} />
                        ) : (
                            <LocalizedText label={subHeader} />
                        )}
                    </Typography>
                ) : null}
            </div>
            {actions ? <Box className={classes.pageHeaderAction}>{actions}</Box> : null}
        </div>
    );
};

export default PageHeader;
