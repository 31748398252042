import {defineMessages} from 'react-intl';

export const localizedCommunicationActions = defineMessages({
    sendMessageToPlayer: {
        id: 'sendMessageToPlayer',
        defaultMessage: 'Send Message to Player',
    },
    sendMessageModalTitle: {
        id: 'sendMessageModalTitle',
        defaultMessage: 'Send a message to {username}',
    },
    sendMessageButton: {
        id: 'sendMessageButton',
        defaultMessage: 'Send a message',
    },
    writeMessagePlaceholder: {
        id: 'writeMessagePlaceholder',
        defaultMessage: 'Write a message...',
    },
    sendButton: {
        id: 'sendButton',
        defaultMessage: 'Send',
    },
    messageTitle: {
        id: 'messageTitle',
        defaultMessage: 'Message from security team',
    },
    messageSubject: {
        id: 'messageSubject',
        defaultMessage: 'New message from PK Security',
    },
    messageSender: {
        id: 'messageSender',
        defaultMessage: 'Security',
    },
    messageSignature: {
        id: 'messageSignature',
        defaultMessage: 'PokerKing Security',
    },
    messageContentGreeting: {
        id: 'messageContentGreeting',
        defaultMessage: 'Hello {username},',
    },
    messageContent: {
        id: 'messageContent',
        defaultMessage:
            'Access to your account has been suspended for a routine security review. Please contact support at {email} to request more information.',
    },
    messageContentSignature: {
        id: 'messageContentSignature',
        defaultMessage: 'Regards,\nPokerKing Security',
    },

    messageFieldSubject: {
        id: 'messageFieldSubject',
        defaultMessage: 'Subject',
    },
    messageFieldTitle: {
        id: 'messageFieldTitle',
        defaultMessage: 'Title',
    },
    messageFieldContent: {
        id: 'messageFieldContent',
        defaultMessage: 'Content',
    },
    messageFieldSignature: {
        id: 'messageFieldSignature',
        defaultMessage: 'Signature',
    },
    messageFieldSender: {
        id: 'messageFieldSender',
        defaultMessage: 'Sender',
    },
});
