import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';

import {OutlinedButton} from '@components/button/Buttons';
import {ModalContext} from '@components/modal';
import {GgrViewModel} from '@models/aggregated-finance-summary';

import {GGRModal} from './GGRModal';

const localized = defineMessages({
    buttonLabel: {
        id: 'GGRDetailsButton_label',
        defaultMessage: 'Details',
    },
});

type GGRDetailsButtonProps = {
    ggr: GgrViewModel;
    currency: string;
    dateTimeString: string;
};

export function GGRDetailsButton(props: GGRDetailsButtonProps) {
    const {openModal, closeModal} = useContext(ModalContext);

    const handleClick = () => {
        openModal({body: <GGRModal handleClose={closeModal} {...props} />});
    };

    return <OutlinedButton label={localized.buttonLabel} onClick={handleClick} />;
}
