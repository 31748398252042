import {createAsyncAction} from 'typesafe-actions';

import {DownloadRequestActionPayload, DownloadSuccessActionPayload} from '@file/types';
import {
    MutationLockCasinoArgs,
    MutationLockDepositArgs,
    MutationLockP2pFeatureArgs,
    MutationLockSportsbookArgs,
    MutationLockWithdrawalArgs,
    UserProfile,
} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {AsyncActionMeta} from '@redux/async-action';
import {UserProfileQueryFields} from '@redux/entity';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

import {Message} from '../message-snack-bar/types';

const domain = 'user-profile-actions';

export type UserAccountLockRequestPayload = {
    uid: string;
    value: boolean;
    boUserId?: string;
};

export type UserProfileLockRequestPayload = {
    uid: string;
    value: boolean;
};

export type UpdatePlayerIdRequestPayload = {
    agentId: string;
    revenueShare: string;
    newPlayerId: string;
    oldPlayerId: string;
};

export const userProfileActions = {
    changeAccountStatus: createAsyncAction(
        `${domain}/changeAccountStatusRequest`,
        `${domain}/changeAccountStatusSucceed`,
        `${domain}/changeAccountStatusFailure`
    )<
        UserAccountLockRequestPayload,
        ServiceResponsePayload<UserAccountLockRequestPayload, null>,
        ServiceResponsePayload<UserAccountLockRequestPayload, null>
    >(),

    changeDepositStatus: createAsyncAction(
        `${domain}/changeDepositStatusRequest`,
        `${domain}/changeDepositStatusSucceed`,
        `${domain}/changeDepositStatusFailure`
    )<
        UserProfileLockRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationLockDepositArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationLockDepositArgs>, UserProfile>
    >(),

    changeWithdrawalStatus: createAsyncAction(
        `${domain}/changeWithdrawalStatusRequest`,
        `${domain}/changeWithdrawalStatusSucceed`,
        `${domain}/changeWithdrawalStatusFailure`
    )<
        UserProfileLockRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationLockWithdrawalArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationLockWithdrawalArgs>, UserProfile>
    >(),

    changeLobbyAccessStatus: createAsyncAction(
        `${domain}/changeLobbyAccessStatusRequest`,
        `${domain}/changeLobbyAccessStatusSucceed`,
        `${domain}/changeLobbyAccessStatusFailure`
    )<
        UserProfileLockRequestPayload,
        ServiceResponsePayload<UserProfileLockRequestPayload, null>,
        ServiceResponsePayload<UserProfileLockRequestPayload, null>
    >(),

    changeP2PTransferStatus: createAsyncAction(
        `${domain}/changeP2PTransferStatusRequest`,
        `${domain}/changeP2PTransferStatusSucceed`,
        `${domain}/changeP2PTransferStatusFailure`
    )<
        UserProfileLockRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationLockP2pFeatureArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationLockP2pFeatureArgs>, UserProfile>
    >(),

    updatePlayerId: createAsyncAction(
        `${domain}/updatePlayerIdRequest`,
        `${domain}/updatePlayerIdSuccess`,
        `${domain}/updatePlayerIdFailure`
    )<
        [UpdatePlayerIdRequestPayload, AsyncActionMeta],
        [ServiceResponsePayload<GqlMutationRequest, UserProfile>, AsyncActionMeta],
        [ServiceResponsePayload<GqlMutationRequest, UserProfile>, AsyncActionMeta]
    >(),

    // TODO: [BO-2903] Remove updateAccessManagementPlayerId after moving to view feature
    updateAccessManagementPlayerId: createAsyncAction(
        `${domain}/updateAccessManagementPlayerIdRequest`,
        `${domain}/updateAccessManagementPlayerIdSuccess`,
        `${domain}/updateAccessManagementPlayerIdFailure`
    )<
        [UpdatePlayerIdRequestPayload, AsyncActionMeta],
        [ServiceResponsePayload<GqlMutationRequest, UserProfile>, AsyncActionMeta],
        [ServiceResponsePayload<GqlMutationRequest, UserProfile>, AsyncActionMeta]
    >(),

    changeCasinoStatus: createAsyncAction(
        `${domain}/changeCasinoStatusRequest`,
        `${domain}/changeCasinoStatusSucceed`,
        `${domain}/changeCasinoStatusFailure`
    )<
        UserProfileLockRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationLockCasinoArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationLockCasinoArgs>, UserProfile>
    >(),

    changeSportsbookStatus: createAsyncAction(
        `${domain}/changeSportsboookStatusRequest`,
        `${domain}/changeSportsboookStatusSucceed`,
        `${domain}/changeSportsboookStatusFailure`
    )<
        UserProfileLockRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationLockSportsbookArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationLockSportsbookArgs>, UserProfile>
    >(),

    download: createAsyncAction(`${domain}/downloadRequest`, `${domain}/downloadSuccess`, `${domain}/downloadFailure`)<
        DownloadRequestActionPayload<UserProfileViewModelKeys, UserProfileQueryFields>,
        DownloadSuccessActionPayload,
        Message
    >(),
};
