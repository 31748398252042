import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {GeoCheckStatus} from '@models/generated/graphql';
import {formatSnakeCaseEnumValue} from '@utils';

import {UserSecurityInfoData} from './UserSecurityInfo';
import {WorkspaceList} from './WorkspaceList';

type UserGeoCheckSummaryProps = {
    value: UserSecurityInfoData;
};

const localized = defineMessages({
    failCount: {
        id: 'SI_failCount',
        defaultMessage: 'Fail Count: {failCount}',
    },
    failedCategories: {
        id: 'SI_failedCategories',
        defaultMessage: 'Failed Categories: {failedCategories}',
    },
});

const useClasses = makeStyles()(() => ({
    userGeoCheckSummary: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        whiteSpace: 'normal',
    },
}));

export const UserGeoCheckSummary = ({value}: UserGeoCheckSummaryProps) => {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();

    return (
        <WorkspaceList>
            <MemoizedChip value={value?.status} chipName={nameof<GeoCheckStatus>()} />
            {value?.fail_count ? (
                <Box className={classes.userGeoCheckSummary}>
                    <Box>
                        {formatMessage(localized.failCount, {
                            failCount: value?.fail_count,
                        })}
                    </Box>
                    <Box>
                        {formatMessage(localized.failedCategories, {
                            failedCategories: value?.reason_categories
                                ?.filter(i => i)
                                ?.map(i => formatSnakeCaseEnumValue(i))
                                ?.join(', '),
                        })}
                    </Box>
                </Box>
            ) : null}
        </WorkspaceList>
    );
};
