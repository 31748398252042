import React from 'react';
import {useDispatch} from 'react-redux';

import Button from '@components/button/Buttons';
import {Snackbar} from '@components/snackbar/Snackbar';
import {SnackbarStack} from '@components/snackbar/SnackbarStack';

import {showErrorAction, showMessageAction} from 'src/features/message-snack-bar/actions';

import {Wrapper, WrapperChild} from './WrapperDev';

export function SnackbarsDev() {
    const dispatch = useDispatch();

    const [isSnackbarsOpen, setIsSnackbarsOpen] = React.useState(false);

    function handleMessageSnackBarClick(message: string) {
        dispatch(showMessageAction({message: {id: 'test', defaultMessage: message}}));
    }

    function handleErrorMessageSnackBarClick(message: string) {
        dispatch(showErrorAction({message: {id: 'test1', defaultMessage: message}}));
    }

    const shortText = 'Short';
    const longText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vitae porta lacus. Nunc ut euismod sem.';
    const longTextWithoutSpace = 'LoremipsumdolorsitametconsecteturadipiscingelitAliquamvitaeportalacusNuncuteuismodsem';

    return (
        <Wrapper>
            <WrapperChild label="Snackbars" key={0}>
                <Button onClick={() => setIsSnackbarsOpen(!isSnackbarsOpen)}>Toggle Snackbars</Button>
                <SnackbarStack>
                    <Snackbar message={shortText} open={isSnackbarsOpen} onClose={() => setIsSnackbarsOpen(false)} />
                    <Snackbar message={longText} open={isSnackbarsOpen} onClose={() => setIsSnackbarsOpen(false)} />
                    <Snackbar message={longTextWithoutSpace} open={isSnackbarsOpen} onClose={() => setIsSnackbarsOpen(false)} />
                </SnackbarStack>
            </WrapperChild>
            <WrapperChild label="Message snackbars" key={1}>
                <Button onClick={() => handleMessageSnackBarClick(shortText)}>Short success</Button>
                <Button onClick={() => handleErrorMessageSnackBarClick(shortText)}>Short error</Button>
                <Button onClick={() => handleMessageSnackBarClick(longText)}>Long success</Button>
                <Button onClick={() => handleErrorMessageSnackBarClick(longText)}>Long error</Button>
                <Button onClick={() => handleMessageSnackBarClick(longTextWithoutSpace)}>Long success without spaces</Button>
                <Button onClick={() => handleErrorMessageSnackBarClick(longTextWithoutSpace)}>Long error without spaces</Button>
            </WrapperChild>
        </Wrapper>
    );
}
