import {defineMessages} from 'react-intl';

import {withUserProfileTextField} from '../../player-actions/pii-actions/components/UserProfileTextField';
import {personalInfoActions} from '../actions';

const localized = defineMessages({
    firstName: {
        id: 'UserProfileFirstName_firstName',
        defaultMessage: 'First Name',
    },
});

export const UserProfileFirstName = withUserProfileTextField({
    action: personalInfoActions.editFirstName,
    placeholder: localized.firstName,
    submitModelMapper: m => ({
        fieldValue: m?.user?.first_name,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            first_name: m?.fieldValue as string,
        },
        userId: m?.userId,
    }),
    isRequired: true,
});
