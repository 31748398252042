import {defineMessages} from 'react-intl';
import {injectable} from 'inversify';
import moment from 'moment/moment';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';

import {Timestamp} from '@models/shared';
import {mergeMap} from '@otel';
import {BaseEpicsBuilder, RootEpic} from '@redux';
import {entityActions, EntityFetchServiceResponsePayload, EntityType} from '@redux/entity';
import {formatTimestamp, momentToTimestampSeconds} from '@utils';

import {showErrorAction} from '../message-snack-bar/actions';
import {Message} from '../message-snack-bar/types';

const localized = defineMessages({
    tooMatchRequestsMessage: {
        id: 'EmbeddedReportEpicsBuilder_tooMatchRequestsMessage',
        defaultMessage: 'Too many requests to the dashboard. Try again on {dataTime}',
    },
});

@injectable()
export class EmbeddedReportEpicsBuilder extends BaseEpicsBuilder {
    private tooMatchRequestsCodeError = '20000066';

    protected buildEpicList(): RootEpic[] {
        return [this.buildFetchRequestFailureEpic()];
    }

    public buildFetchRequestFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(entityActions.fetchRequest.failure)),
                mergeMap((action: PayloadAction<string, EntityFetchServiceResponsePayload>) => {
                    const resultAction: PayloadAction<'message/showError', Message>[] = [];
                    if (
                        action?.payload?.requestPayload?.type === EntityType.EmbeddedReport &&
                        action?.payload?.errors?.[0]?.code === this.tooMatchRequestsCodeError
                    ) {
                        const blockTimeSeconds: number = (
                            action?.payload?.errors?.[0]?.data as {
                                block_time_seconds: number;
                            }
                        )?.block_time_seconds;
                        const blockTimeTimestamp: Timestamp = {
                            seconds: momentToTimestampSeconds(moment().add(blockTimeSeconds, 'seconds')),
                        };
                        resultAction.push(
                            showErrorAction({
                                message: localized.tooMatchRequestsMessage,
                                values: {
                                    dataTime: formatTimestamp(blockTimeTimestamp, 'date-time-with-seconds'),
                                },
                            })
                        );
                    }
                    return of(...resultAction);
                })
            );
    }
}
