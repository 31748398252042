import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';
import {Typography} from '@mui/material';

import InfoMessage from '@components/alerts/InfoMessage';
import LocalizedText from '@components/i18n/LocalizedText';
import {LinkText} from '@components/link';
import {ModalContent, ModalContext} from '@components/modal/types';

import {ErrorDetails} from './ErrorDetails';
import {localized} from './localize';
import {ErrorProps} from './type';

const localizedModal = defineMessages({
    errorModalHeader: {
        id: 'errorModalHeader',
        defaultMessage: 'Error',
    },
});

export function ErrorModal(props: ErrorProps) {
    const {openModal} = useContext(ModalContext);

    const handleOpenPopupClick = (content: ModalContent) => {
        openModal(content);
    };

    return (
        <InfoMessage>
            <Typography variant="body1">
                <LocalizedText label={localized.infoMessage} />
            </Typography>
            <LinkText
                title={localized.showMoreLabel}
                onClick={() =>
                    handleOpenPopupClick({
                        body: <ErrorDetails {...props}></ErrorDetails>,
                        title: <LocalizedText label={localizedModal.errorModalHeader} />,
                    })
                }
            />
        </InfoMessage>
    );
}
