import React, {useContext} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {RuleType, StyledInput, useValidationFormatter} from '@components/input';
import {ModalContext, ModalFooter} from '@components/modal';
import {LabelInput} from '@models/generated/graphql';
import {CustomTheme} from '@style';

import {PlayerLabelGroupAutocomplete} from '../../view-autocomplete/components/PlayerLabelGroupAutocomplete';
import {usePlayerLabelCreateLabel} from '../hooks';

const localized = defineMessages({
    nameInputLabel: {
        id: 'BlockPlayerLabelCreateLabelForm_nameInputLabel',
        defaultMessage: 'Label name',
    },
    nameInputPlaceholder: {
        id: 'BlockPlayerLabelCreateLabelForm_nameInputPlaceholder',
        defaultMessage: 'Please enter a new label name',
    },
    groupSelectLabel: {
        id: 'BlockPlayerLabelCreateLabelForm_selectLabel',
        defaultMessage: 'Group',
    },
    saveButton: {
        id: 'BlockPlayerLabelCreateLabelForm_saveButtonLabel',
        defaultMessage: 'Save',
    },
    closeButton: {
        id: 'BlockPlayerLabelCreateLabelForm_closeButtonLabel',
        defaultMessage: 'Close',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    formContentHeight: {
        marginTop: theme.spacing(2),
        height: theme.custom.modalContainerMinHeight / 2,
    },
}));

export function BlockPlayerLabelCreateLabelForm() {
    const {classes} = useStyles();
    const {closeModal} = useContext(ModalContext);
    const {formatMessage} = useIntl();
    const validationFormatter = useValidationFormatter();
    const {control, handleSubmit, formState} = useForm<LabelInput>({mode: 'onChange'});
    const {handleCreateLabel} = usePlayerLabelCreateLabel();

    function handleClose() {
        closeModal();
    }

    function handleSave(data: LabelInput) {
        handleCreateLabel(data);
        closeModal();
    }

    return (
        <form>
            <Box className={classes.formContentHeight}>
                <Controller
                    render={({field, fieldState}) => (
                        <StyledInput
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={localized.nameInputPlaceholder}
                            fieldState={fieldState}
                            label={localized.nameInputLabel}
                            labelVariant="default"
                        />
                    )}
                    name="name"
                    control={control}
                    rules={{required: validationFormatter(RuleType.Required, localized.nameInputLabel)}}
                />
                <Controller
                    render={({field}) => (
                        <PlayerLabelGroupAutocomplete
                            value={field.value}
                            onValueChange={field.onChange}
                            label={formatMessage(localized.groupSelectLabel)}
                            labelVariant="default"
                        />
                    )}
                    control={control}
                    name="group_id"
                    rules={{required: validationFormatter(RuleType.Required, localized.groupSelectLabel)}}
                    defaultValue={null}
                />
            </Box>

            <ModalFooter>
                <Button label={localized.closeButton} onClick={handleClose} />
                <Button color="primary" label={localized.saveButton} onClick={handleSubmit(handleSave)} disabled={!formState.isValid} />
            </ModalFooter>
        </form>
    );
}
