import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon, Icon} from '@components/icons';

const useStyles = makeStyles()(theme => ({
    selectArrowIcon: {
        fontSize: '10px !important',
        color: theme.palette.text.secondary,
    },
    selectArrowIconOpen: {
        color: theme.palette.primary.main,
    },
}));

type SelectArrowIconProps = {
    selectOpen: boolean;
};

export function SelectArrowIcon({selectOpen}: SelectArrowIconProps) {
    const {classes, cx} = useStyles();
    return (
        <Icon
            icon={selectOpen ? CustomIcon.ArrowUp : CustomIcon.ArrowDown}
            className={cx({[classes.selectArrowIconOpen]: selectOpen}, classes.selectArrowIcon)}
        />
    );
}
