import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';

const localized = defineMessages({
    noTablesLabel: {
        id: 'GameTemplateTableCell_noTablesLabel',
        defaultMessage: 'n/a',
    },
});

export type GameTemplateTableCellProps = {
    tables: number[];
};

function GameTemplateTableCell({tables}: GameTemplateTableCellProps) {
    const {formatMessage} = useIntl();
    return <>{tables?.length ? tables.join(' ') : formatMessage(localized.noTablesLabel)}</>;
}

export function renderGameTemplateTableCell(params: GridCellParams) {
    const colDef = params.colDef as GridColDef;
    const tableIds: number[] = (colDef.valueFormatter ? params.formattedValue : params.value) as number[];
    const value: GameTemplateTableCellProps = {tables: tableIds};
    return <GameTemplateTableCell {...value} />;
}
