import React from 'react';

import {DownloadCsvButton} from 'src/features/block-download';
import {useDownloadTransaction, UseTransactionDownloadButtonProps} from '../hooks';

type TransactionDownloadCsvButtonProps = UseTransactionDownloadButtonProps;

export function TransactionDownloadCsvButton(props: TransactionDownloadCsvButtonProps) {
    const {isProgress, handleClick} = useDownloadTransaction(props);
    return <DownloadCsvButton onClick={handleClick} loading={isProgress} />;
}
