import {gql} from '@apollo/client';

export const addMarketingCodeMutation = gql`
    mutation AddMarketingCode($marketing_code: MarketingCodeInput!) {
        addMarketingCode(marketing_code: $marketing_code) {
            marketing_code
            type
            added_by_uid
            created_at {
                seconds
            }
        }
    }
`;

export const deleteMarketingCodeMutation = gql`
    mutation DeleteMarketingCodes($codes: [MarketingCodeDeleteInput!]!) {
        deleteMarketingCodes(codes: $codes) {
            marketing_code
            type
        }
    }
`;
