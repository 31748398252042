import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {
    Box,
    Button as MaterialButton,
    ButtonProps as MuiButtonProps,
    Chip,
    CircularProgress,
    IconButton as MaterialIconButton,
    IconButtonProps as MuiIconButtonProps,
} from '@mui/material';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {CustomTheme} from '@style';

import StyledTooltip from '../Tooltip';

export type ButtonPlacement = 'stretch' | 'default';

export type ButtonProps = MuiButtonProps & {
    smallPlus?: boolean;
    label?: string | MessageDescriptor;
    children?: React.ReactNode;
    'data-testid'?: string;
    placement?: ButtonPlacement;
    fullWidth?: boolean;
    loading?: boolean;
};

type OutlinedButtonWithChipProps = ButtonProps & {
    total: number;
};

export type SelectButtonWithChipProps = ButtonProps & {
    open: boolean;
    selectedText?: string | MessageDescriptor;
    hasArrow?: boolean;
};

export type IconButtonProps = MuiIconButtonProps & {
    iconClassName?: string;
    round?: boolean;
    tooltip?: string | MessageDescriptor;
    buttonSmall?: boolean;
    iconFontSize?: number;
};

export const iconButtonSmallSize = 32;

const useStyles = makeStyles()((theme: CustomTheme) => {
    const {fontStyle, fontWeight, fontSize, lineHeight, letterSpacing, textTransform, textAlign} = theme.typography.button;
    return {
        //because of the default size of materialui button is medium and we used it in a lot of places with height 40
        //and for the outlined small button we used height 28, there is a need that fits between small and medium.
        //maybe we can consider refactoring medium to large, and smallPlus to medium when design team finish core lib.
        smallPlusButton: {
            height: theme.custom.smallPlusButtonHeight,
            minWidth: 'unset',
            padding: theme.spacing(1, 2),
        },
        buttonWithTotal: {
            display: 'flex',
        },
        iconButton: {
            ...(theme.custom.button as CSSObject),
            minWidth: 40,
            width: 40,
            height: 40,
            fontStyle,
            fontWeight,
            fontSize,
            lineHeight,
            letterSpacing,
            textTransform,
            textAlign,
            color: theme.palette.secondary.main,

            '&:hover': {
                color: theme.palette.primary.main,
                background: 'transparent',
            },
            '&:focus, &:active': {
                color: theme.palette.primary.main,
                background: 'transparent',
                borderColor: theme.palette.primary.main,
            },
            '&:disabled': {
                color: theme.palette.secondary.main,
                background: 'transparent',
                borderColor: theme.custom.palette.content.liner,
            },
            '& .MuiSvgIcon-root': {
                width: '20px',
                height: '20px',
            },
        },
        iconButtonSmall: {
            width: iconButtonSmallSize,
            height: iconButtonSmallSize,
            minWidth: iconButtonSmallSize,
            padding: 0,
        },
        iconButtonRound: {
            background: theme.palette.background.paper,
            border: `1px solid ${theme.custom.palette.content.liner}`,
            boxShadow: '0px 1px 1px rgba(19, 64, 106, 0.06)',
            boxSizing: 'border-box',
            borderRadius: '100%',
            padding: 0,
        },
        iconButtonSquare: {
            background: theme.palette.background.paper,
            border: `1px solid ${theme.custom.palette.content.liner}`,
            boxShadow: '0px 1px 1px rgba(19, 64, 106, 0.06)',
            boxSizing: 'border-box',
            borderRadius: 6,
        },
        chip: {
            borderRadius: 21,
            background: '#F0F2F5',
            color: theme.palette.secondary.main,
            marginLeft: theme.spacing(1),
            fontSize: '12px',
            lineHeight: '18px',
        },
        selectButton: {
            display: 'flex',
            height: theme.custom.denseButtonHeight,
            ...(theme.typography.body1 as CSSObject),
            '&:hover, &:focus, &:active': {
                color: theme.palette.text.primary,
                borderColor: theme.custom.palette.content.border,
            },
        },
        selectButtonLabel: {
            ...(theme.typography.body1 as CSSObject),
            color: theme.palette.text.secondary,
            '&:hover': {
                color: theme.palette.text.secondary,
            },
        },
        selectButtonEndIcon: {
            fontSize: '10px !important',
            color: theme.palette.text.secondary,
        },
        selectButtonStartIconContainer: {
            display: 'flex',
        },
        selectButtonStartIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: theme.spacing(1),
        },
        selectButtonOpen: {
            color: theme.palette.primary.main,
        },
        buttonFullWidth: {
            width: '100%',
        },
        stretchButton: {
            width: '100%',
            textAlign: 'left',
            border: 'none',
            borderRadius: 0,
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
            justifyContent: 'space-between',
            '& .MuiButton-iconSizeMedium > *:first-child': {
                fontSize: theme.typography.h6.fontSize,
            },
            '&:hover .MuiButton-iconSizeMedium > *:first-child': {
                color: theme.palette.primary.main,
            },
        },
        buttonCircularProgress: {
            marginLeft: theme.spacing(),
        },
    };
});

export const Button = React.memo(
    ({label, className, children, fullWidth, placement = 'default', loading, color = 'secondary', ...props}: ButtonProps) => {
        const {classes: styles, cx} = useStyles();

        return (
            <MaterialButton
                className={cx({[styles.buttonFullWidth]: fullWidth, [styles.stretchButton]: placement === 'stretch'}, className)}
                color={color}
                variant="contained"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                disabled={loading}
                {...props}
            >
                {label ? <LocalizedText label={label} /> : null}
                {children}
                {loading ? <CircularProgress color="secondary" size={16} className={styles.buttonCircularProgress} /> : null}
            </MaterialButton>
        );
    }
);

export const OutlinedButton = React.memo(({smallPlus, className, ...props}: ButtonProps) => {
    const {classes: styles} = useStyles();
    const smallPlusClass = smallPlus ? styles.smallPlusButton : '';

    return <Button color="primary" className={`${smallPlusClass} ${className}`} {...props} variant="outlined" />;
});

export const IconButton = React.memo(
    ({iconClassName, children, buttonSmall, round = false, iconFontSize = 14, ...props}: IconButtonProps) => {
        const {classes: styles} = useStyles();
        const buttonSmallStyle = buttonSmall ? styles.iconButtonSmall : '';

        return (
            <MaterialIconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                className={`${styles.iconButton} ${buttonSmallStyle} ${round ? styles.iconButtonRound : ''}`}
                size="small"
                {...props}
            >
                {children ?? <Box component="span" className={iconClassName} fontSize={iconFontSize}></Box>}
            </MaterialIconButton>
        );
    }
);

export const SquareIconButton = React.memo(
    ({iconClassName, buttonSmall = true, tooltip, iconFontSize = 13, className, ...props}: IconButtonProps) => {
        const {classes: styles} = useStyles();
        const buttonSmallStyle = buttonSmall ? styles.iconButtonSmall : '';

        return (
            <StyledTooltip
                element={
                    <MaterialIconButton
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        className={`${styles.iconButton} ${buttonSmallStyle} ${styles.iconButtonSquare} ${className}`}
                        size="small"
                        {...props}
                    >
                        <Box component="span" className={iconClassName} fontSize={iconFontSize}></Box>
                    </MaterialIconButton>
                }
                title={<Box>{tooltip ? <LocalizedText label={tooltip} /> : null}</Box>}
                hideTooltip={!tooltip}
            />
        );
    }
);

export const OutlinedButtonWithChip = React.memo(({total, classes, ...props}: OutlinedButtonWithChipProps) => {
    const {classes: styles} = useStyles();

    return (
        <OutlinedButton classes={{...classes, root: styles.buttonWithTotal}} {...props}>
            <Chip label={total} className={styles.chip} size="small" />
        </OutlinedButton>
    );
});

export const SelectButtonWithChip = React.memo(({open, label, selectedText, hasArrow = true, ...props}: SelectButtonWithChipProps) => {
    const {classes: styles} = useStyles();
    const arrow = open ? CustomIcon.ArrowUp : CustomIcon.ArrowDown;
    const openStyle = open ? styles.selectButtonOpen : '';

    return (
        <Button
            data-testid="selectButtonWithChip"
            className={`${styles.selectButton} ${openStyle}`}
            color="primary"
            {...props}
            startIcon={
                label || props.startIcon ? (
                    <Box className={styles.selectButtonStartIconContainer}>
                        {props.startIcon ? <Box className={styles.selectButtonStartIcon}>{props.startIcon}</Box> : null}
                        <Box component="span" className={styles.selectButtonLabel}>
                            {label ? <LocalizedText label={label} /> : null}
                        </Box>
                    </Box>
                ) : null
            }
            label={selectedText}
            endIcon={hasArrow ? <Box component="span" className={`${arrow} ${styles.selectButtonEndIcon} ${openStyle}`} /> : null}
            variant="outlined"
        />
    );
});

export default Button;
