import {defineMessages, MessageDescriptor} from 'react-intl';

import {TransactionType} from '@models/generated/graphql';
import {TransactionFilterType} from '@models/transaction/types';

const localizedTypes: Record<TransactionType, MessageDescriptor> = {
    [TransactionType.Deposit]: {
        id: 'transactionType_Deposit',
        defaultMessage: 'Deposit',
    },
    [TransactionType.Withdrawal]: {
        id: 'transactionType_Withdrawal',
        defaultMessage: 'Withdrawal',
    },
    [TransactionType.Debit]: {
        id: 'transactionType_Debit',
        defaultMessage: 'Debit',
    },
    [TransactionType.Credit]: {
        id: 'transactionType_Credit',
        defaultMessage: 'Credit',
    },
    [TransactionType.BuyIn]: {
        id: 'transactionType_BuyIn',
        defaultMessage: 'Buy-in',
    },
    [TransactionType.BuyOut]: {
        id: 'transactionType_Buyout',
        defaultMessage: 'Buyout',
    },
    [TransactionType.Bonus]: {
        id: 'transactionType_Bonus',
        defaultMessage: 'Bonus',
    },
    [TransactionType.RevenueShare]: {
        id: 'transactionType_RevenueShare',
        defaultMessage: 'Revenue Share',
    },
    [TransactionType.MttCredit]: {
        id: 'transactionType_MttCredit',
        defaultMessage: 'Mtt Credit',
    },
    [TransactionType.MttDebit]: {
        id: 'transactionType_MttDebit',
        defaultMessage: 'Mtt Debit',
    },
    [TransactionType.PokerAddonCredit]: {
        id: 'transactionType_PokerAddonCredit',
        defaultMessage: 'Poker Addon Credit',
    },
    [TransactionType.PokerAddonDebit]: {
        id: 'transactionType_PokerAddonDebit',
        defaultMessage: 'Poker Addon Debit',
    },
    [TransactionType.Release]: {
        id: 'transactionType_Release',
        defaultMessage: 'Release',
    },
    [TransactionType.PokerFlipsPayout]: {
        id: 'transactionType_PokerFlipsPayout',
        defaultMessage: 'PokerFlips Payout',
    },
    [TransactionType.PokerFlipsWager]: {
        id: 'transactionType_PokerFlipsWager',
        defaultMessage: 'PokerFlips Wager',
    },
    [TransactionType.CasinoWager]: {
        id: 'transactionType_CasinoWager',
        defaultMessage: 'Casino Wager',
    },
    [TransactionType.CasinoPayout]: {
        id: 'transactionType_CasinoPayout',
        defaultMessage: 'Casino Payout',
    },
    [TransactionType.CasinoFreeSpinWager]: {
        id: 'transactionType_CasinoFreeSpinWager',
        defaultMessage: 'Casino Free Spin Wager',
    },
    [TransactionType.CasinoFreeSpinPayout]: {
        id: 'transactionType_CasinoFreeSpinPayout',
        defaultMessage: 'Casino Free Spin Payout',
    },
    [TransactionType.CasinoWagerRefund]: {
        id: 'transactionType_CasinoWagerRefund',
        defaultMessage: 'Casino Wager Refund',
    },
    [TransactionType.CasinoPayoutRefund]: {
        id: 'transactionType_CasinoPayoutRefund',
        defaultMessage: 'Casino Payout Refund',
    },
    [TransactionType.CasinoFreeSpinWagerRefund]: {
        id: 'transactionType_CasinoFreeSpinWagerRefund',
        defaultMessage: 'Casino Free Spin Wager Refund',
    },
    [TransactionType.CasinoFreeSpinPayoutRefund]: {
        id: 'transactionType_CasinoFreeSpinPayoutRefund',
        defaultMessage: 'Casino Free Spin Payout Refund',
    },
    [TransactionType.P2PTransferCredit]: {
        id: 'transactionType_P2PTransferCredit',
        defaultMessage: 'P2P Transfer Credit',
    },
    [TransactionType.P2PTransferDebit]: {
        id: 'transactionType_P2PTransferDebit',
        defaultMessage: 'P2P Transfer Debit',
    },
    [TransactionType.Chargeback]: {
        id: 'transactionType_Chargeback',
        defaultMessage: 'Chargeback',
    },
    [TransactionType.Refund]: {
        id: 'transactionType_Refund',
        defaultMessage: 'Refund',
    },
    [TransactionType.Reserve]: {
        id: 'transactionType_Reserve',
        defaultMessage: 'Reserve',
    },
    [TransactionType.Reverse]: {
        id: 'transactionType_Reverse',
        defaultMessage: 'Reverse',
    },
    [TransactionType.LiveEventBuyIn]: {
        id: 'transactionType_LiveEventBuyIn',
        defaultMessage: 'Live Event Buy In',
    },
    [TransactionType.SportsBetSettlement]: {
        id: 'transactionType_SportsBetSettlement',
        defaultMessage: 'Sportsbook Bet Settlement',
    },
    [TransactionType.SportsBuyin]: {
        id: 'transactionType_SportsBuyin',
        defaultMessage: 'Sportsbook Buy In',
    },
    [TransactionType.SportsBuyout]: {
        id: 'transactionType_SportsBuyout',
        defaultMessage: 'Sportsbook Buyout',
    },
    [TransactionType.Fee]: {
        id: 'transactionType_Fee',
        defaultMessage: 'Fee',
    },
    [TransactionType.FeeReverse]: {
        id: 'transactionType_FeeReverse',
        defaultMessage: 'Fee Reverse',
    },
    [TransactionType.Payin]: {
        id: 'transactionType_Payin',
        defaultMessage: 'Pay In',
    },
    [TransactionType.Payout]: {
        id: 'transactionType_Payout',
        defaultMessage: 'Pay Out',
    },
    [TransactionType.PayoutReturn]: {
        id: 'transactionType_PayoutReturn',
        defaultMessage: 'Payout Return',
    },
};

export const localizedTransactionType = defineMessages<TransactionType>(localizedTypes);

export const localizedTransactionFilterType = defineMessages<TransactionFilterType>({
    ...localizedTypes,
    FEES: {
        id: 'transactionType_Fees',
        defaultMessage: 'Fees',
    },
});
