import {IModuleGridItem} from '@components/data-grid';
import {UserLogin, UserProfile} from '@models/generated/graphql';
import {isRecent} from '@utils';

import {IModuleItem} from '../module-shared/types';

export const domain = 'player-actions';

export class PlayerProfile extends UserProfile implements IModuleItem {
    get id() {
        return this.uid;
    }

    firstLogin?: UserLogin;

    serverId: string;

    //just mock for now - original data should be returned from GQL or via API and merged (fields can be renamed)
    game_summary?: {
        games_won: number;
        games_lost: number;
        money_won: number;
        money_lost: number;
    };
}

export class PlayerProfileGridItem extends UserProfile implements IModuleGridItem {
    id: string;
    actionHistory: null;

    serverId: string;

    get isNew() {
        return isRecent(this.date_of_joining, 1);
    }
}
