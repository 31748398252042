import React, {Children, createContext, ReactNode, useState} from 'react';
import {SwipeableDrawer, useTheme} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';
import {sharedLocalization} from '@localization';

import {MultiStepFilterHeader} from './MultiStepFilterHeader';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    multiStepFilterDrawer: {
        display: 'flex',
        overflowY: 'hidden',
        flexDirection: 'column',
        gap: theme.spacing(1.25),
        minHeight: '500px',
        maxHeight: '90%',
        borderTopLeftRadius: theme.spacing(1.75),
        borderTopRightRadius: theme.spacing(1.75),
    },
    multiStepFilterDrawerBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        flexGrow: 1,
    },
    multiStepFilterDrawerHiddenFilter: {
        display: 'none',
    },
    multiStepFilterDrawerActiveFilter: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
}));

type MultiStepFilterDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
};

type MultiStepFilterDrawerContextValues = {
    onActivate: () => void;
    onDeactivate: () => void;
    onClose: () => void;
};

export const DrawerContext = createContext<MultiStepFilterDrawerContextValues | null>(null);

export function MultiStepFilterDrawer({children, isOpen, onClose, onOpen}: MultiStepFilterDrawerProps & {children?: ReactNode}) {
    const {classes, cx} = useClasses();
    const theme = useTheme();
    const [activeFilterId, setActiveFilterId] = useState<Number>(null);

    function handleActivateFilter(index: Number) {
        setActiveFilterId(index);
    }

    function handleDeactivateFilter() {
        setActiveFilterId(null);
    }

    function handleClose() {
        handleDeactivateFilter();
        onClose();
    }

    return (
        <SwipeableDrawer
            data-testid="multiStepFilterDrawer"
            anchor="bottom"
            open={isOpen}
            onClose={handleClose}
            onOpen={onOpen}
            classes={{paper: classes.multiStepFilterDrawer}}
            // The default zIndex has been changed to display the Drawer above the burger menu
            style={{zIndex: theme.zIndex.tooltip}}
        >
            {activeFilterId === null ? <MultiStepFilterHeader label={sharedLocalization.drawerLabel} onClose={handleClose} /> : null}
            <div className={classes.multiStepFilterDrawerBody}>
                {Children.map(children, (child, index) => (
                    <>
                        <div
                            key={index}
                            className={cx(
                                activeFilterId !== null && index === activeFilterId && classes.multiStepFilterDrawerActiveFilter,
                                activeFilterId !== null && index !== activeFilterId && classes.multiStepFilterDrawerHiddenFilter
                            )}
                        >
                            <DrawerContext.Provider
                                value={{
                                    onClose: handleClose,
                                    onActivate: () => handleActivateFilter(index),
                                    onDeactivate: handleDeactivateFilter,
                                }}
                            >
                                {child}
                            </DrawerContext.Provider>
                        </div>
                    </>
                ))}
            </div>
        </SwipeableDrawer>
    );
}
