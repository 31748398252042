import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import {Chip, ChipWithTooltip} from '@components/chip/Chips';
import {ListWithChipTooltip} from '@components/chip/ListWithChipTooltip';
import {ChipType} from '@components/chip/types';
import {withEmptyCheck} from '@components/label/EmptyLabel';

type LabelGroup = {
    color: string;
    id: string;
    name: string;
};

type Label = {
    group: LabelGroup;
    id: string;
    name: string;
};

export type LabelsCellValue = {
    visibleLabel: Label;
    hiddenLabels: Label[];
};

export type LabelsCellProps = {
    value: LabelsCellValue;
};

function LabelsCellInner({value}: LabelsCellProps) {
    const {formatMessage} = useIntl();
    const visibleLabelTooltip = formatMessage(labelCellLocalized.visibleLabelTooltip, {
        label: value.visibleLabel?.name,
        group: value.visibleLabel?.group?.name,
    });
    return (
        <ListWithChipTooltip
            visibleElement={
                <ChipWithTooltip
                    key={value.visibleLabel.id}
                    label={value.visibleLabel.name}
                    chipType={ChipType.Status}
                    chipVariant={value.visibleLabel.group?.color}
                    fillWidth={false}
                    title={visibleLabelTooltip}
                    hideTooltip={false}
                />
            }
            hiddenElements={value.hiddenLabels?.map(label => (
                <Chip key={label.id} label={label.name} chipType={ChipType.Status} chipVariant={label.group?.color} fillWidth={false} />
            ))}
        />
    );
}

const LabelsCell = withEmptyCheck(LabelsCellInner);

export const renderLabelsCell = (params: GridCellParams) => {
    const value: LabelsCellValue = params.value as LabelsCellValue;

    return <LabelsCell value={value} />;
};

export const labelCellLocalized = defineMessages({
    labels: {
        id: 'DefaultLabelColumn_headerName',
        defaultMessage: 'Label',
    },
    visibleLabelTooltip: {
        id: 'DefaultLabelColumn_visibleLabelTooltip',
        defaultMessage: 'Label {label} of {group}',
    },
});

export const labelCellValueGetter = (value: Label[]): LabelsCellValue => {
    if (!value || value.length === 0) {
        return null;
    }

    const [visibleLabel, hiddenLabels] = [value[0], value.slice(1)];
    return {
        visibleLabel,
        hiddenLabels,
    };
};
