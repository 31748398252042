import React, {useEffect, useState} from 'react';
import {Controller, FormProvider} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {List, ListItem, ListItemText} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {useAutoMapper} from '@auto-mapper';
import Button from '@components/button/Buttons';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {FormError, FormTextInputDefault, RuleType, useValidationFormatter} from '@components/input';
import {ModalFooter} from '@components/modal';
import {Select} from '@components/select';
import {Rule} from '@models/rule/types';
import {UpdateRuleRequestPayload} from '@services/rest-api/ruleApiService';

import {SelectOption} from '../../module-shared/types';
import {useReduxForm} from '../../shared/form/hooks';
import {ruleActions} from '../actions';
import {useRule} from '../hooks';
import {allRuleConditions, ApprovedStatusValue, relatedConditionMapper, RuleCondition, RuleViewModel} from '../types';

import {RuleConditionCountries} from './RuleFormConditionCountries';
import {localizedRuleCondition} from './RuleFormConditionHoc';
import {RuleFormConditionInput} from './RuleFormConditionInput';
import {RuleFormConditionSelect} from './RuleFormConditionSelect';
import {RuleFormLogicOperationCondition} from './RuleFormLogicOperationCondition';

const localized = defineMessages({
    name: {
        id: 'RuleModal_nameLabel',
        defaultMessage: 'Rule name',
    },
    namePlaceholder: {
        id: 'RuleModal_namePlaceholder',
        defaultMessage: 'Enter rule name',
    },
    conditions: {
        id: 'RuleModal_conditions',
        defaultMessage: 'Conditions',
    },

    approvedSelectOptionLabel: {
        id: 'RuleModal_approvedOptionLabel',
        defaultMessage: 'Approved',
    },
    notApprovedSelectOptionLabel: {
        id: 'RuleModal_notApprovedOptionLabel',
        defaultMessage: 'Not Approved',
    },

    cancelButtonLabel: {
        id: 'RuleModal_cancelButtonLabel',
        defaultMessage: 'Cancel',
    },
    createButtonLabel: {
        id: 'RuleModal_createButtonLabel',
        defaultMessage: 'Create rule',
    },
    saveButtonLabel: {
        id: 'RuleModal_saveButtonLabel',
        defaultMessage: 'Save changes',
    },
});

const useStyles = makeStyles()(theme => ({
    ruleModalListItem: {
        padding: theme.spacing(0),
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: theme.spacing(),
    },
    ruleModalConditionsListItem: {
        padding: theme.spacing(0),
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    ruleModalContainer: {
        minHeight: '360px',
        height: '100vh',
    },
    ruleModalConditionsContainer: {
        maxHeight: '550px',
        overflowY: 'auto',
        marginTop: theme.spacing(2),
    },
    ruleModalCountryAutocomplete: {
        width: '100%',
        '& .MuiAutocomplete-root': {
            height: 'unset',
        },
        '& .MuiAutocomplete-inputRoot': {
            paddingTop: '2px !important',
            paddingBottom: '2px !important',
        },
    },
}));

type RuleModalProps = {
    ruleId?: string;
    mode: 'create' | 'edit';
    onClose: () => void;
};

export function RuleModal({mode = 'create', onClose, ruleId}: RuleModalProps) {
    const nameMaxLength = 50;
    const availableConditions: RuleCondition[] = [
        'registrationCountry',
        'withdrawalAmount',
        'successfulWithdrawalAmount24',
        'successfulWithdrawalAmountLifetime',
        'isFirstWithdrawal',
        'hasLocks',
        'isWithdrawalClosedLoop',
        'hasSecurityCasesAssigned',
    ];
    const defaultModel: RuleViewModel = {
        name: null,
        registrationCountry: [],
        withdrawalAmount: null,
        successfulWithdrawalAmount24: null,
        successfulWithdrawalAmountLifetime: null,
        hasApprovedKYCHistory: ApprovedStatusValue.Approved,
        isFirstWithdrawal: true,
        hasLocks: true,
        isWithdrawalClosedLoop: true,
        hasSecurityCasesAssigned: true,
    } as RuleViewModel;

    const {item: value} = useRule({
        id: ruleId,
        viewType: 'RuleModal',
        fields: ['order', 'name', 'status', 'actions'],
    });

    const defaultValue: RuleViewModel = value ?? defaultModel;

    const mapper = useAutoMapper();
    const {classes} = useStyles();
    const {formatMessage} = useIntl();
    const validationMessageFormatter = useValidationFormatter();

    const form = useReduxForm<RuleViewModel, UpdateRuleRequestPayload>({
        initialModel: value ?? {},
        asyncAction: mode === 'create' ? ruleActions.createRule : ruleActions.editRule,
        map: ruleVm => mapper.map(ruleVm, RuleViewModel, Rule),
    });
    const [visibleConditions, setVisibleConditions] = useState<RuleCondition[]>(
        mode === 'edit' ? getAvailableFormModelConditions(value) : []
    );

    useEffect(() => {
        setVisibleConditions(getAvailableFormModelConditions(value));
    }, [value?.id]);

    function handleRemoveKeyFromVisibleList(...keys: RuleCondition[]) {
        setVisibleConditions(prevState => {
            const result = [...prevState];
            keys?.forEach(key => {
                const index = result.indexOf(key);
                if (index > -1) {
                    result.splice(index, 1);
                }
            });
            return result;
        });
    }

    function handleVisibleConditionListChange(values: RuleCondition[]) {
        values = values ?? [];
        const allVisibleKeys: RuleCondition[] = getAvailableFormModelConditions();

        if (allVisibleKeys?.length > values?.length) {
            const hiddenValue = arrayDifference(allVisibleKeys, values);
            hiddenValue?.forEach(i => form.setValue(i, undefined));
        } else {
            const addedValue = arrayDifference(values, allVisibleKeys)[0];
            setFormValue(addedValue);
        }

        setVisibleConditions(getAvailableFormModelConditions());
    }

    function arrayDifference(reduced: RuleCondition[], subtracted: RuleCondition[]) {
        const subtractedSet = new Set(subtracted);
        return reduced?.filter(key => !subtractedSet?.has(key));
    }

    function setFormValue(key: RuleCondition) {
        form.setValue(key, value?.[key] ?? defaultModel?.[key]);
        const relatedCondition = relatedConditionMapper[key];
        if (relatedCondition) {
            form.setValue(relatedCondition, value?.[relatedCondition] ?? defaultModel?.[relatedCondition]);
        }
    }

    function getAvailableFormModelConditions(value: RuleViewModel = null): RuleCondition[] {
        const allVisibleKeys: RuleCondition[] = [];

        Object.entries(value ?? form.getValues())?.map(i => {
            if (i[1] !== undefined && allRuleConditions.includes(i[0] as RuleCondition)) {
                allVisibleKeys.push(i[0] as RuleCondition);
            }
        });
        return allVisibleKeys;
    }

    function getConditionSelectOptions(): SelectOption[] {
        return availableConditions?.map(i => ({value: i, label: localizedRuleCondition[i]})) ?? [];
    }

    function getApprovedSelectOptions(): SelectOption[] {
        return [
            {
                value: ApprovedStatusValue.Approved,
                label: localized.approvedSelectOptionLabel,
            },
            {
                value: ApprovedStatusValue.NotApproved,
                label: localized.notApprovedSelectOptionLabel,
            },
        ];
    }

    return (
        <>
            <FormProvider {...form}>
                <form className={classes.ruleModalContainer}>
                    <List>
                        <ListItem className={classes.ruleModalListItem}>
                            <ListItemText primary={formatMessage(localized.name)} />
                            <Controller
                                render={({field, fieldState}) => (
                                    <FormTextInputDefault
                                        value={field.value}
                                        onChange={field.onChange}
                                        fieldState={fieldState}
                                        textAlign="right"
                                        placeholder={localized.namePlaceholder}
                                    />
                                )}
                                control={form.control}
                                defaultValue={defaultValue?.name}
                                name="name"
                                rules={{
                                    required: validationMessageFormatter(RuleType.Required, localized.name),
                                    maxLength: {
                                        value: nameMaxLength,
                                        message: validationMessageFormatter(RuleType.MaxLength, localized.name, nameMaxLength),
                                    },
                                }}
                            />
                        </ListItem>
                        <ListItem className={classes.ruleModalConditionsListItem}>
                            <ListItemText primary={formatMessage(localized.conditions)} />
                            <Select
                                multiple
                                options={getConditionSelectOptions()}
                                value={visibleConditions}
                                onSubmit={handleVisibleConditionListChange}
                                iconLabel={<Icon icon={CustomIcon.Add} color={IconColor.Secondary} />}
                                showResetButton
                            />
                        </ListItem>

                        <div className={classes.ruleModalConditionsContainer}>
                            <RuleConditionCountries
                                multiple
                                ruleKey="registrationCountry"
                                operator="=="
                                defaultValue={defaultValue?.registrationCountry}
                                onRemove={handleRemoveKeyFromVisibleList}
                                formControlClassName={classes.ruleModalCountryAutocomplete}
                                requiredValidation
                            />
                            <RuleFormConditionInput
                                ruleKey="withdrawalAmount"
                                operator="<="
                                defaultValue={defaultValue?.withdrawalAmount}
                                type="number"
                                onRemove={handleRemoveKeyFromVisibleList}
                                positiveNumberCheck
                            />
                            <RuleFormLogicOperationCondition
                                primaryData={{
                                    type: 'number',
                                    ruleKey: 'successfulWithdrawalAmount24',
                                    operator: '<=',
                                    defaultValue: defaultValue?.successfulWithdrawalAmount24,
                                    component: RuleFormConditionInput,
                                    positiveNumberCheck: true,
                                }}
                                secondaryData={{
                                    ruleKey: 'hasApprovedKYCHistory',
                                    operator: '==',
                                    defaultValue: defaultValue?.hasApprovedKYCHistory,
                                    component: RuleFormConditionSelect,
                                    options: getApprovedSelectOptions(),
                                    disabled: true,
                                }}
                                onRemove={handleRemoveKeyFromVisibleList}
                            />
                            <RuleFormLogicOperationCondition
                                primaryData={{
                                    type: 'number',
                                    ruleKey: 'successfulWithdrawalAmountLifetime',
                                    operator: '<=',
                                    defaultValue: defaultValue?.successfulWithdrawalAmountLifetime,
                                    component: RuleFormConditionInput,
                                    positiveNumberCheck: true,
                                }}
                                secondaryData={{
                                    ruleKey: 'hasApprovedKYCHistory',
                                    operator: '==',
                                    defaultValue: defaultValue?.hasApprovedKYCHistory,
                                    component: RuleFormConditionSelect,
                                    options: getApprovedSelectOptions(),
                                    disabled: true,
                                }}
                                onRemove={handleRemoveKeyFromVisibleList}
                            />
                            <RuleFormConditionSelect
                                ruleKey={'isFirstWithdrawal'}
                                defaultValue={defaultValue?.isFirstWithdrawal}
                                onRemove={handleRemoveKeyFromVisibleList}
                            />
                            <RuleFormConditionSelect
                                ruleKey={'hasLocks'}
                                defaultValue={defaultValue?.hasLocks}
                                onRemove={handleRemoveKeyFromVisibleList}
                            />
                            <RuleFormConditionSelect
                                ruleKey={'isWithdrawalClosedLoop'}
                                defaultValue={defaultValue?.isWithdrawalClosedLoop}
                                onRemove={handleRemoveKeyFromVisibleList}
                            />
                            <RuleFormConditionSelect
                                ruleKey={'hasSecurityCasesAssigned'}
                                defaultValue={defaultValue?.hasSecurityCasesAssigned}
                                onRemove={handleRemoveKeyFromVisibleList}
                            />
                        </div>
                    </List>
                    {form.formattedErrorMessage ? <FormError>{form.formattedErrorMessage}</FormError> : null}
                </form>
            </FormProvider>

            <ModalFooter>
                <Button label={localized.cancelButtonLabel} onClick={onClose} />
                <Button
                    color="primary"
                    label={mode === 'create' ? localized.createButtonLabel : localized.saveButtonLabel}
                    onClick={form.handleSubmit(form.submit)}
                />
            </ModalFooter>
        </>
    );
}
