import {defineMessages} from 'react-intl';

export const localizedDatePickerFooterRangeMode = defineMessages({
    datePickerFooterRangeModeFromLabel: {
        id: 'datePickerFooterRangeModeFromLabel',
        defaultMessage: 'From',
    },
    datePickerFooterRangeModeToLabel: {
        id: 'datePickerFooterRangeModeToLabel',
        defaultMessage: 'To',
    },
});
