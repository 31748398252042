import {NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {BaseFilterKeys, EntityFetchRequestPayload, EntityFetchServiceResponsePayload} from '@redux/entity';
import {ApolloClientProxy, GraphQLService} from '@services/gql-api';

import {GqlQueryResponse, ServerResponseStatus} from '../types';

import {GqlRequestBuilder} from './GqlRequestBuilder';
import {IEntityReadService} from './IEntityReadService';
import {ResponsePayloadMapExtraArgs} from './mapper';

export class EntityBaseGqlService<TQueryArgs, TQueryFields extends string, TFilterKeys extends string = BaseFilterKeys>
    implements IEntityReadService
{
    protected _service: GraphQLService;
    private readonly _mapper: Mapper;
    private readonly _builder: GqlRequestBuilder<TQueryArgs, TQueryFields, TFilterKeys>;

    constructor(
        client: ApolloClientProxy<NormalizedCacheObject>,
        mapper: Mapper,
        builder: GqlRequestBuilder<TQueryArgs, TQueryFields, TFilterKeys>
    ) {
        this._service = new GraphQLService(client);
        this._mapper = mapper;
        this._builder = builder;
    }

    public get(requestPayload: EntityFetchRequestPayload<TQueryFields>): Observable<EntityFetchServiceResponsePayload> {
        const gqlRequest = this._builder.buildGqlRequest(requestPayload);
        return gqlRequest
            ? this._service.query(gqlRequest.query, gqlRequest.variables).pipe(
                  map(res =>
                      this._mapper.map(res, GqlQueryResponse, EntityFetchServiceResponsePayload, {
                          extraArgs: () => ({requestPayload} as ResponsePayloadMapExtraArgs),
                      })
                  )
              )
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {items: [], total: 0},
              });
    }
}
