//TODO: [BO-2692] Move files to src/common/model
export enum ConnectionType {
    Mobile = 'mobile',
    Corporate = 'corporate',
    VPN = 'VPN',
    Cloud = 'cloud',
    Proxy = 'proxy',
    Residential = 'residential',
    Hosting = 'hosting',
    Cellular = 'cellular',
    Business = 'business',
}
