import React from 'react';
import {Radio, RadioProps} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon, Icon} from '@components/icons';
import {CustomTheme, SystemColorsEnum} from '@style';

import {CircleBox} from '../circle-box/CircleBox';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    radioInputColor: {
        width: theme.spacing(3.75),
        height: theme.spacing(3.75),
        borderRadius: '50%',
        padding: 0,
    },
    radioInputColorDisabled: {
        cursor: 'default',
    },
    radioInputColorLabel: {
        width: theme.spacing(3),
        minWidth: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: theme.typography.subtitle2.fontSize,
    },
    radioInputColorChecked: {
        width: theme.spacing(3.75),
        minWidth: theme.spacing(3.75),
        height: theme.spacing(3.75),
        border: `1px solid ${theme.custom.palette.content.border}`,
    },
}));

type RadioInputColorProps = Omit<RadioProps, 'color'> & {
    color: SystemColorsEnum;
};

export function RadioInputColor({color, ...props}: RadioInputColorProps) {
    const {classes} = useClasses();
    return (
        <Radio
            className={classes.radioInputColor}
            classes={{disabled: classes.radioInputColorDisabled}}
            value={color}
            icon={<CircleBox color={color} className={classes.radioInputColorLabel} />}
            checkedIcon={
                <CircleBox
                    color={SystemColorsEnum.White}
                    className={classes.radioInputColorChecked}
                    letter={
                        <CircleBox
                            color={color}
                            className={classes.radioInputColorLabel}
                            size="small"
                            letter={<Icon icon={CustomIcon.Confirm} />}
                        />
                    }
                />
            }
            color="secondary"
            {...props}
        />
    );
}
