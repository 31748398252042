import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {defineMessages} from 'react-intl';
import {Box, PopoverOrigin, SelectProps as MuiSelectProps} from '@mui/material';

import {SelectOption} from '../../../features/module-shared/types';
import {emptyCellCharacter} from '../../../features/module-shared/utils';

import {MultiSelect} from './MultiSelect';
import {useFilterSelectClasses} from './selectStyle';
import {SingleSelect} from './SingleSelect';

//TODO: [BO-2668] Move to src/common/components/dropdown (rename or merge?)
//TODO: [BO-2669] Move dropdown components to input folder (?)
export enum EmptyPlaceholder {
    Any = 'any',
    Dash = 'dash',
    All = 'all',
    Flag = 'flag',
}

export const localizedEmptyPlaceholders = defineMessages({
    [EmptyPlaceholder.Any]: {
        id: 'anySelectValue',
        defaultMessage: 'Any',
    },
    [EmptyPlaceholder.All]: {
        id: 'allSelectValue',
        defaultMessage: 'All',
    },
    [EmptyPlaceholder.Flag]: {
        id: 'flagSelectValue',
        defaultMessage: 'Flag',
    },
    [EmptyPlaceholder.Dash]: {
        id: 'dashSelectValue',
        defaultMessage: '-',
    },
});

export const localizedSelect = defineMessages({
    [EmptyPlaceholder.Any]: {
        id: 'anySelectValue',
        defaultMessage: 'Any',
    },
    [EmptyPlaceholder.All]: {
        id: 'allSelectValue',
        defaultMessage: 'All',
    },
    [EmptyPlaceholder.Flag]: {
        id: 'flagSelectValue',
        defaultMessage: 'Flag',
    },
    selectClearAll: {
        id: 'clearAll',
        defaultMessage: 'Clear All',
    },
    selectApply: {
        id: 'selectApply',
        defaultMessage: 'Apply',
    },
});

export enum SelectStyle {
    Default = 'default',
    Filter = 'filter',
}

export type SelectProps<T> = Pick<MuiSelectProps, 'startAdornment' | 'renderValue'> & {
    options: SelectOption[];
    value: T;
    onSubmit: (value: any) => void;
    enumFormatter?: (value: string) => string | MessageDescriptor;
    multiple?: boolean;
    hasRadio?: boolean;
    selectStyle?: SelectStyle;
    emptyPlaceholder?: EmptyPlaceholder;
    emptyValue?: MessageDescriptor | string;
    label?: string | MessageDescriptor;
    iconLabel?: JSX.Element;
    disabled?: boolean;
    chipType?: string;
    isSelectedValueChip?: boolean;
    showResetButton?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    horizontalPosition?: PopoverOrigin['horizontal'];
    className?: string;
    hasSearch?: boolean;
    searchPlaceholder?: string | MessageDescriptor;
    mode?: 'server' | 'client';
    hasSelectAll?: boolean;
    onFilterChange?: (filter: string) => void;
    onPageChange?: (newPage: number) => void;
    showPagination?: boolean;
    page?: number;
    pageSize?: number;
    total?: number;
    onSelectAll?: (isSelectAll: boolean) => void;
    defaultSelectAll?: boolean;
    showApplyButton?: boolean;
    onApply?: () => void;
    virtualization?: boolean;
};

export const Select = React.memo(
    <T,>({
        options,
        value,
        hasRadio,
        multiple,
        selectStyle = SelectStyle.Filter,
        emptyPlaceholder = EmptyPlaceholder.Any,
        onSubmit,
        enumFormatter,
        label,
        iconLabel,
        disabled,
        chipType,
        isSelectedValueChip,
        showResetButton,
        hasSearch,
        className,
        horizontalPosition = 'right',
        startAdornment,
        ...props
    }: SelectProps<T>) => {
        const {classes: classesFilter} = useFilterSelectClasses();
        const selectClassName =
            selectStyle === SelectStyle.Default || isSelectedValueChip ? className : `${classesFilter.selectFilter} ${className}`;

        const getEmptyValue = () => {
            const localizedEmptyValue = localizedEmptyPlaceholders[emptyPlaceholder];
            return localizedEmptyValue ? localizedEmptyValue : emptyCellCharacter;
        };

        const renderMultiSelect = () => {
            return (
                <Box className={selectClassName}>
                    <MultiSelect
                        disabled={disabled}
                        options={options}
                        value={value as T[]}
                        emptyValue={getEmptyValue()}
                        onSubmit={onSubmit}
                        enumFormatter={enumFormatter}
                        label={label}
                        iconLabel={iconLabel}
                        chipType={chipType}
                        showResetButton={showResetButton}
                        horizontalPosition={horizontalPosition}
                        hasSearch={hasSearch}
                        {...props}
                    />
                </Box>
            );
        };

        const renderSingleSelect = () => {
            return (
                <Box className={selectClassName}>
                    <SingleSelect
                        disabled={disabled}
                        options={options}
                        value={value as string}
                        emptyValue={getEmptyValue()}
                        selectStyle={selectStyle}
                        onSubmit={onSubmit}
                        hasRadio={hasRadio}
                        enumFormatter={enumFormatter}
                        label={label}
                        chipType={chipType}
                        isSelectedValueChip={isSelectedValueChip}
                        showResetButton={showResetButton}
                        horizontalPosition={horizontalPosition}
                        startAdornment={startAdornment}
                    />
                </Box>
            );
        };

        return multiple ? renderMultiSelect() : renderSingleSelect();
    }
);
