import {renderChip} from '@components/chip/ChipRenderer';
import {GridColDef, renderCellHeader, renderCellWithTooltip, renderCurrencyCell} from '@components/data-grid/mui';
import {EmptyPlaceholder} from '@components/select';
import {TransactionType} from '@models/generated/graphql';
import {formatCamelCaseEnumValue} from '@utils';

import {JurisdictionCurrency} from '../../../app/config/types';
import {localizedTransactionType} from '../../../app/intl/shared-resources/transactionType';
import {SelectOption} from '../../../module-shared/types';
import FilterMultiSelect from '../../../shared/filter/components/FilterMultiSelect';
import {
    ClientFilter,
    ClientFilterType,
    Filter,
    FilterPlacement,
    PageModuleChipSelectFilter,
    PageModuleSelectFilter,
} from '../../../shared/filter/types';
import {formatMethodLabel} from '../formatter';
import {localized} from '../localized';
import {FinanceSummaryModel} from '../types';

import {renderTransactionMethod} from './TransactionMethod';

const getGridColumns = (currency: JurisdictionCurrency) => {
    const columns: GridColDef[] = [
        {
            field: nameof<FinanceSummaryModel>(m => m.id),
            hide: true,
        },
        {
            field: nameof<FinanceSummaryModel>(m => m.type),
            renderHeader: () => renderCellHeader(localized.type),
            renderCell: params => renderChip<TransactionType>((params.row as FinanceSummaryModel)?.type, nameof<TransactionType>()),
            flex: 2,
        },
        {
            field: nameof<FinanceSummaryModel>(m => m.method),
            renderHeader: () => renderCellHeader(localized.method),
            renderCell: renderTransactionMethod,
            flex: 2,
        },
        {
            field: nameof<FinanceSummaryModel>(m => m.reference),
            renderHeader: () => renderCellHeader(localized.reference),
            flex: 3,
        },
        {
            field: nameof<FinanceSummaryModel>(m => m.successful_count),
            renderHeader: () => renderCellHeader(localized.countsSuccessful),
            flex: 2,
        },
        {
            field: nameof<FinanceSummaryModel>(m => m.successful_amount),
            renderHeader: () => renderCellHeader(localized.amountSuccessful),
            renderCell: params => renderCurrencyCell(params, currency),
            flex: 3,
        },
        {
            field: nameof<FinanceSummaryModel>(m => m.failed_count),
            renderHeader: () => renderCellHeader(localized.countFailed),
            flex: 2,
        },
        {
            field: nameof<FinanceSummaryModel>(m => m.failed_amount),
            renderHeader: () => renderCellHeader(localized.amountFailed),
            renderCell: params => renderCurrencyCell(params, currency),
            flex: 3,
        },
        {
            field: nameof<FinanceSummaryModel>(m => m.rejected_count),
            renderHeader: () => renderCellHeader(localized.countRejected),
            flex: 2,
        },
        {
            field: nameof<FinanceSummaryModel>(m => m.rejected_amount),
            renderHeader: () => renderCellHeader(localized.amountRejected),
            renderCell: params => renderCurrencyCell(params, currency),
            flex: 3,
        },
    ];

    const defaultCol: GridColDef = {
        field: '',
        renderCell: renderCellWithTooltip,
        sortable: false,
        flex: 1,
    };

    return columns.map(c => ({...defaultCol, ...c}));
};

const getFilters = (model: FinanceSummaryModel[]): Filter[] => [
    {
        key: nameof<FinanceSummaryModel>(m => m.type),
        label: localized.filterType,
        component: FilterMultiSelect,
        chipType: nameof<TransactionType>(),
        options: [TransactionType.Deposit, TransactionType.Withdrawal].map(
            t => ({label: localizedTransactionType[t], value: t} as SelectOption)
        ),
        placement: FilterPlacement.Advanced,
        emptyPlaceholder: EmptyPlaceholder.Any,
        horizontalPosition: 'left',
    } as PageModuleChipSelectFilter,
    {
        key: nameof<FinanceSummaryModel>(m => m.method),
        label: localized.filterMethod,
        component: FilterMultiSelect,
        options: [...new Set(model.map(m => m.method).filter(m => m))].map(m => ({label: formatMethodLabel(m), value: m} as SelectOption)),
        placement: FilterPlacement.Advanced,
        emptyPlaceholder: EmptyPlaceholder.Any,
        enumFormatter: formatCamelCaseEnumValue,
        horizontalPosition: 'left',
    } as PageModuleSelectFilter,
];

const getClientFilters = (): ClientFilter<FinanceSummaryModel>[] => [
    {
        fieldSelector: i => i.type,
        filterKey: nameof<FinanceSummaryModel>(i => i.type),
        filterType: ClientFilterType.Multiselect,
    },
    {
        fieldSelector: i => i.method,
        filterKey: nameof<FinanceSummaryModel>(i => i.method),
        filterType: ClientFilterType.Multiselect,
    },
];

export const userFinanceSummaryData = {
    getGridColumns,
    getFilters,
    clientFilters: getClientFilters(),
};
