import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

//TODO: [BO-2773] Review InfoMessage, InfoAlert, Message, MessageWithIcon and refactor/rename them to keep single reused components for showing the message to user
type InfoMessageProps = {
    children?: React.ReactNode;
    dataTestId?: string;
    align?: 'center' | 'space-between';
};

const useClasses = makeStyles()(theme => ({
    container: {
        padding: theme.spacing(2),
        margin: 'auto',
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    alignSpaceBetweenContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: `${theme.spacing(2)} 0`,
        textAlign: 'unset',
        borderRadius: theme.spacing(1),
    },
}));

const InfoMessage = ({children, dataTestId, align = 'center'}: InfoMessageProps) => {
    const {classes, cx} = useClasses();

    return (
        <Box
            className={cx(align === 'space-between' ? classes.alignSpaceBetweenContainer : '', classes.container)}
            data-testid={dataTestId}
        >
            {children}
        </Box>
    );
};

export default InfoMessage;
