import {createAsyncAction} from 'typesafe-actions';

import {UserProfile} from '@models/generated/graphql';

import {IFailureResponse} from '../../module-shared/types';
import {domain as baseDomain} from '../types';

import {UserProfileModel} from './types';

export const domain = `${baseDomain}/pii-actions`;

const taxIdEditAction = '/taxIdEditAction';
const taxIdEditSucceedAction = '/taxIdEditSucceedAction';
const taxIdEditFailedAction = '/taxIdEditFailedAction';

const nationalIdEditAction = '/nationalIdEditAction';
const nationalIdEditSucceedAction = '/nationalIdEditSucceedAction';
const nationalIdEditFailedAction = '/nationalIdEditFailedAction';

const countryOfBirthEditAction = '/countryOfBirthEditAction';
const countryOfBirthEditSucceedAction = '/countryOfBirthEditSucceedAction';
const countryOfBirthEditFailedAction = '/countryOfBirthEditFailedAction';

const nationalityEditAction = '/nationalityEditAction';
const nationalityEditSucceedAction = '/nationalityEditSucceedAction';
const nationalityEditFailedAction = '/nationalityEditFailedAction';

const occupationEditAction = '/occupationEditAction';
const occupationEditSucceedAction = '/occupationEditSucceedAction';
const occupationEditFailedAction = '/occupationEditFailedAction';

export const piiActions = {
    editTaxId: createAsyncAction(`${domain}${taxIdEditAction}`, `${domain}${taxIdEditSucceedAction}`, `${domain}${taxIdEditFailedAction}`)<
        UserProfileModel,
        UserProfile,
        IFailureResponse
    >(),

    editNationalId: createAsyncAction(
        `${domain}${nationalIdEditAction}`,
        `${domain}${nationalIdEditSucceedAction}`,
        `${domain}${nationalIdEditFailedAction}`
    )<UserProfileModel, UserProfile, IFailureResponse>(),

    editNationality: createAsyncAction(
        `${domain}${nationalityEditAction}`,
        `${domain}${nationalityEditSucceedAction}`,
        `${domain}${nationalityEditFailedAction}`
    )<UserProfileModel, UserProfile, IFailureResponse>(),

    editCountryOfBirth: createAsyncAction(
        `${domain}${countryOfBirthEditAction}`,
        `${domain}${countryOfBirthEditSucceedAction}`,
        `${domain}${countryOfBirthEditFailedAction}`
    )<UserProfileModel, UserProfile, IFailureResponse>(),

    editOccupation: createAsyncAction(
        `${domain}${occupationEditAction}`,
        `${domain}${occupationEditSucceedAction}`,
        `${domain}${occupationEditFailedAction}`
    )<UserProfileModel, UserProfile, IFailureResponse>(),
};
