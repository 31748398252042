import React from 'react';
import {defineMessages} from 'react-intl';
import {Box, Typography} from '@mui/material';

import InfoMessage from '@components/alerts/InfoMessage';
import {CurrencyCell} from '@components/data-grid/mui';
import LocalizedText from '@components/i18n/LocalizedText';
import {GgrViewModel} from '@models/aggregated-finance-summary';

import {emptyCellCharacter} from '../../module-shared/utils';

const localized = defineMessages({
    title: {
        id: 'GGRTotalInfo_title',
        defaultMessage: 'Total GGR',
    },
    subTitle: {
        id: 'GGRTotalInfo_subTitle',
        defaultMessage: 'Last Update: {dateTime}',
    },
});

type GGRTotalInfoProps = {
    ggr: GgrViewModel;
    currency: string;
    dateTimeString: string;
};

export function GGRTotalInfo({dateTimeString, ggr, currency}: GGRTotalInfoProps) {
    const total = ggr
        ? Object.keys(ggr).reduce((previousValue, currentValue: keyof GgrViewModel) => previousValue + ggr[currentValue], 0)
        : null;

    return (
        <InfoMessage align={'space-between'}>
            <Box>
                <Typography variant={'h6'}>
                    <LocalizedText label={localized.title} />
                </Typography>
                <Typography variant={'subtitle1'}>
                    <LocalizedText label={localized.subTitle} labelParams={{dateTime: dateTimeString ?? emptyCellCharacter}} />
                </Typography>
            </Box>
            <Typography variant={'h6'}>
                <CurrencyCell currency={currency} value={total} />
            </Typography>
        </InfoMessage>
    );
}
