import React, {useEffect} from 'react';
import {Control, DeepMap, DeepPartial, FieldError, FieldValue, UnpackNestedValue, useForm} from 'react-hook-form';
import {MessageDescriptor} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Fade} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {PayloadActionCreator, RootState} from 'typesafe-actions';

import {Message, MessageType} from '@components/alerts/Message';
import {GridColDef} from '@components/data-grid/mui';
import {CustomTheme} from '@style';

import {FormModuleData, IFailurePayloadError, IModuleItem} from '../types';

import FormCloseAction from './FormCloseAction';
import FormHeader from './FormHeader';
import FormSubmitAction from './FormSubmitAction';

type ModuleFormProps<TItem> = {
    headers?: GridColDef[];
    headerItemName?: MessageDescriptor;
    domain: string;
    stateSelector: (state: RootState) => FormModuleData<IModuleItem>;
    className?: string;
    submitLabel?: string | MessageDescriptor;
    closeLabel?: string | MessageDescriptor;
    submitAction: PayloadActionCreator<string, FormModuleData<IModuleItem>>;
    closeAction: PayloadActionCreator<string, void>;
    buttonComponent?: React.ElementType;
    checkIsNewValue?: () => boolean;
    isReadonly?: (item: TItem) => boolean;
    hideCloseAction?: boolean;
    removeBottomSpacing?: boolean;
};

export type ModuleFormInnerProps<TItem> = {
    getHeader: (key: string) => string;
    value: UnpackNestedValue<DeepPartial<TItem>>;
    errors: DeepMap<DeepPartial<TItem>, FieldError>;
    control: Control<TItem>;
    setValue: <TFieldName extends string>(
        name: TFieldName,
        value: boolean | UnpackNestedValue<DeepPartial<TItem>> | string[] | FieldValue<TItem>
    ) => void;
    isNewValue: boolean;
    isEditDisabled: boolean;
};

const makeClasses = makeStyles()((theme: CustomTheme) => ({
    formWrapper: {
        flex: 2,
        padding: theme.spacing(0, 2),
        position: 'absolute',
        left: '50%',
        right: 0,
        top: 0,
        bottom: 0,
        overflowY: 'auto',
    },
    formWrapperFullWidth: {
        position: 'relative',
        padding: theme.spacing(0, 2),
        overflowY: 'auto',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        height: `calc(100% - ${theme.typography.h6.lineHeight})`,
    },
    formButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        marginTop: 'auto',
        zIndex: 1,
        columnGap: 10,
        marginBottom: theme.spacing(3),
    },
    formButtonsNoSpacing: {
        marginBottom: 0,
    },
    formAction: {
        height: theme.custom.formActionButtonHeight,
        borderRadius: theme.custom.formActionButtonBorderRadius,
        padding: theme.spacing(1, 2),
        textTransform: 'none',
    },
}));

const withModuleEditForm =
    <TItem extends IModuleItem>(
        WrappedComponent: React.ComponentType<ModuleFormInnerProps<TItem>>,
        type: {new (): TItem},
        isFullWidthForm?: boolean
    ) =>
    ({
        headerItemName,
        headers,
        stateSelector,
        className,
        submitLabel,
        closeLabel,
        submitAction,
        closeAction,
        checkIsNewValue,
        buttonComponent,
        isReadonly,
        hideCloseAction,
        removeBottomSpacing,
    }: ModuleFormProps<TItem>) => {
        const {classes} = makeClasses();
        const dispatch = useDispatch();

        const formData = useSelector(stateSelector);
        const value = formData.item as UnpackNestedValue<DeepPartial<TItem>>;

        const isNewValue = () => {
            return value !== undefined && Object.keys(value).length === 0;
        };

        const getDefaultValue = () => {
            return isNewValue() ? (new type() as UnpackNestedValue<DeepPartial<TItem>>) : value;
        };

        const getHeader = (key: string) => headers?.find(i => i.field === key)?.headerName ?? '';

        const {
            control,
            setValue,
            handleSubmit,
            reset,
            formState: {errors},
            setError,
        } = useForm<TItem>({
            defaultValues: getDefaultValue(),
        });

        useEffect(() => {
            reset(value);
        }, [value, value?.id]);

        useEffect(() => {
            if (formData?.validationMessage?.payload) {
                Object.keys(formData.validationMessage.payload).forEach((errorKey: any) => {
                    const errors = formData.validationMessage.payload[errorKey] as IFailurePayloadError[];
                    setError(errorKey, {
                        type: 'manual',
                        message: errors.map(e => e.description).join(' '),
                    });
                });
            }
        }, [formData?.validationMessage]);

        const onSubmit = (data: FormModuleData<IModuleItem>) => {
            dispatch(submitAction(data));
        };

        const isNew = checkIsNewValue ? checkIsNewValue() : isNewValue();

        const onFormSubmit = (data: IModuleItem) => {
            onSubmit({
                item: data,
                isNew: isNew,
            } as FormModuleData<IModuleItem>);
        };

        const isEditDisabled = !isNew && isReadonly && isReadonly(value as TItem);
        const componentProps = {
            getHeader,
            value,
            setValue,
            control,
            errors,
            isNewValue: isNew,
            isEditDisabled: isEditDisabled,
        } as ModuleFormInnerProps<TItem>;

        return value !== undefined ? (
            <Fade in timeout={200}>
                <Box className={`${isFullWidthForm ? classes.formWrapperFullWidth : classes.formWrapper} ${className}`}>
                    <FormHeader isNewValue={isNew} headerItemName={headerItemName}></FormHeader>
                    <form onSubmit={handleSubmit(onFormSubmit)} className={classes.form}>
                        <WrappedComponent {...componentProps} />
                        <Message message={formData.validationMessage?.description} messageType={MessageType.Error} />
                        <Box className={`${classes.formButtons} ${removeBottomSpacing ? classes.formButtonsNoSpacing : ''}`}>
                            {!isEditDisabled ? (
                                <FormSubmitAction className={classes.formAction} label={submitLabel} component={buttonComponent} />
                            ) : (
                                <></>
                            )}
                            {!hideCloseAction && (
                                <FormCloseAction
                                    className={classes.formAction}
                                    label={closeLabel}
                                    component={buttonComponent}
                                    action={closeAction}
                                />
                            )}
                        </Box>
                    </form>
                </Box>
            </Fade>
        ) : (
            <></>
        );
    };

export default withModuleEditForm;
