import React from 'react';

import {AgentReferrerFilterKeys} from '@redux/entity';
import {ViewType} from '@redux/view';

import {Filter} from 'src/features/shared/filter/types';

import {AgentFilterInternal} from './AgentFilterInternal';

export type AgentFilterProps = {
    viewType: ViewType;
    filter: string;
    onFilterChange: (newFilter: Filter[]) => void;
};

/**
 * @deprecated Use {@link AgentFilterNew} instead
 */
export function AgentFilterObsolete({viewType, filter, onFilterChange}: AgentFilterProps) {
    const keys: AgentReferrerFilterKeys[] = ['referrerPlayerId', 'isDownstream'];
    return <AgentFilterInternal onFilterChange={onFilterChange} filterString={filter} filter={{key: keys, options: {viewType}}} />;
}

export default AgentFilterObsolete;
