import {LOCATION_CHANGE} from '@vs-centaurea/connected-react-router';
import {injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {filter} from 'rxjs/operators';

import {map} from '@otel';

import {cancelRequests} from 'src/common/actions';
import {BaseEpicsBuilder} from '..';

import {actions} from './action';

@injectable()
class AsyncActionsEpicsBuilder extends BaseEpicsBuilder {
    protected buildEpicList(): Epic[] {
        return [this.buildCancelEpic(), this.buildDeprecatedCancelEpic()];
    }

    private buildCancelEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(action => action.type === LOCATION_CHANGE),
                map(() => actions.cancelRequests())
            );
    }

    /**
     * @deprecated
     * <p>Should be removed once refactoring to view-entity be finished. Use {@link AsyncActionRequestEpicCreator} </p>
     */
    private buildDeprecatedCancelEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(action => action.type === LOCATION_CHANGE),
                map(() => cancelRequests())
            );
    }
}

export {AsyncActionsEpicsBuilder};
