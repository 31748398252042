import {useCallback, useEffect} from 'react';
import {GridFeatureMode, GridSortModel} from '@mui/x-data-grid';

import {MuiDataGridProps} from '../MuiDataGrid';
import {SortableColDef} from '../types';

//TODO: [BO-2704] Move files to src/common/components/data-grid (renamed grid folder)
type UseGridSortingResult = {
    sortModel: GridSortModel;
    sortingMode: GridFeatureMode;
    onSortModelChange: (model: GridSortModel) => void;
};

export const useGridSorting = ({
    sortModel,
    defaultSortModel,
    sortingMode,
    columns,
    onSortModelChange,
}: Pick<MuiDataGridProps, 'sortModel' | 'defaultSortModel' | 'sortingMode' | 'onSortModelChange'> & {
    columns: SortableColDef[];
}): UseGridSortingResult => {
    const initGridSortModel = sortModel ?? defaultSortModel ?? [];

    if (!initGridSortModel?.length) {
        // Try to set default sorting with the follwing priority:
        // 1. find columns with default sorting (sortField, sortDirection)
        // 2. find first sortable column
        // 3. first not hidden column wich is primary (not expanded)
        const sortColumns = columns?.filter(c => c?.sortField) ?? [columns.find(c => c.sortable)] ?? [columns.find(c => !c.hide)];

        initGridSortModel.push(
            ...sortColumns.map(sortColumn => ({
                field: columns.find(c => c.field === sortColumn.sortField)?.field ?? sortColumn.field,
                sort: sortColumn.sortDirection ?? 'desc',
            }))
        );
    }
    const gridSortModel = initGridSortModel.length ? [initGridSortModel[0]] : [];

    useEffect(() => {
        //trigger onSortModelChange after initialization when no initial sorting was set
        if (initGridSortModel?.length === 0 && gridSortModel?.length > 0) {
            handleSortModelChange(gridSortModel);
        }
    }, [initGridSortModel.map(s => s.field).join(','), initGridSortModel.map(s => s.sort).join(',')]);

    const fields = columns.map(c => c.field);
    const sortables = columns.map(c => c.sortable);

    const handleSortModelChange = useCallback(
        (model: GridSortModel) => {
            if (onSortModelChange) {
                onSortModelChange({sortModel: model, columns, api: undefined});
            }
        },
        [onSortModelChange, `${fields}`, `${sortables}`]
    );

    return {
        sortingMode: sortingMode ?? 'client',
        sortModel: gridSortModel,
        onSortModelChange: handleSortModelChange,
    };
};
