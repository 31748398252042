import {defineMessages} from 'react-intl';

import {withUserProfileTextField} from '../../player-actions/pii-actions/components/UserProfileTextField';
import {personalInfoActions} from '../actions';

const localized = defineMessages({
    email: {
        id: 'UserProfileEmail_email',
        defaultMessage: 'Email',
    },
});

export const UserProfileEmail = withUserProfileTextField({
    action: personalInfoActions.editEmail,
    placeholder: localized.email,
    submitModelMapper: m => ({
        fieldValue: m?.user?.contact?.email,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            contact: {
                email: m?.fieldValue as string,
            },
        },
        userId: m?.userId,
    }),
    isRequired: true,
});
