import React from 'react';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';

import {GameTemplateEditButton, GameTemplateEditButtonProps} from 'src/features/block-game-template-actions';

export function renderGameTemplateModifyCell(params: GridCellParams) {
    const colDef = params.colDef as GridColDef;
    const value: GameTemplateEditButtonProps = (
        colDef.valueFormatter ? params.formattedValue : params.value
    ) as GameTemplateEditButtonProps;
    return <GameTemplateEditButton {...value} />;
}
