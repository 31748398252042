import {ModuleName} from '@models/modules';

import {
    FeatureName,
    Jurisdiction,
    JurisdictionConfiguration,
    JurisdictionCurrency,
    JurisdictionTimezone,
} from '../features/app/config/types';

export const configWPTO: JurisdictionConfiguration = {
    jurisdiction: Jurisdiction.WPTO,
    locale: 'en',
    timezone: JurisdictionTimezone.WPTO,
    currency: JurisdictionCurrency.USD,
    limitedFeatures: [
        //Modules
        {
            moduleName: ModuleName.PageRules,
        },

        //UserFinanceSummary
        {
            featureName: FeatureName.UserFinanceSummary,
        },

        //AggregatedFinancialSummary
        {
            featureName: FeatureName.AggregatedFinancialSummary,
        },

        {
            featureName: FeatureName.AgentReportingVnTotalGridRow,
        },

        {
            featureName: FeatureName.LoginHistoryLogoutInfo,
        },
    ],
};
