import React from 'react';
import {Typography} from '@mui/material';

import {Wrapper, WrapperChild} from './WrapperDev';

export const Typos = () => {
    return (
        <Wrapper label="Typos">
            <WrapperChild>
                <Typography variant="h5">Title | Variant "h5"</Typography>
            </WrapperChild>
            <WrapperChild>
                <Typography variant="h6">Headline | Variant "h6"</Typography>
            </WrapperChild>
            <WrapperChild>
                <Typography variant="body2">Text | Variant "body2"</Typography>
            </WrapperChild>
            <WrapperChild>
                <Typography variant="subtitle1">Subhead | Variant "subtitle1"</Typography>
            </WrapperChild>
            <WrapperChild>
                <Typography variant="caption">Caption Caps | Variant "caption"</Typography>
            </WrapperChild>
        </Wrapper>
    );
};
