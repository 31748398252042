import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {FeatureAccessStatus} from '@models/generated/graphql';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {userProfileActions} from '../actions';
import {gamesActionsReadPermissions, gamesActionsUpdatePermissions, gamesActionsUpdateRestrictPermissions} from '../permissions';

import {LockActionProps} from './UserProfileActions';
import {UserProfileLock} from './UserProfileLock';

const localized = defineMessages({
    label: {
        id: 'CasinoLock_label',
        defaultMessage: 'Casino Lock',
    },
});

type CasinoLockProps = LockActionProps<FeatureAccessStatus>;

function CasinoLockInner({uid, status, disabled}: CasinoLockProps) {
    const {formatMessage} = useIntl();

    return (
        <UserProfileLock
            action={userProfileActions.changeCasinoStatus}
            label={formatMessage(localized.label)}
            uid={uid}
            isLocked={status === FeatureAccessStatus.Locked}
            disabled={disabled}
            dataTestId="casino-status-lock"
        />
    );
}

export const CasinoLock = withMultiplePermission(
    withMultiplePermission(CasinoLockInner, {
        allowedPermissions: gamesActionsUpdatePermissions,
        indicator: LackOfPermissionIndicator.Disabled,
    }),
    {
        allowedPermissions: gamesActionsReadPermissions,
        restrictedPermissions: gamesActionsUpdateRestrictPermissions,
        indicator: LackOfPermissionIndicator.Hidden,
    }
);
