import {defineMessages} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import {renderDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType} from '@components/chip/types';
import {
    gridCellDefaultFormatter,
    GridColDef,
    renderCellHeader,
    renderCellWithTooltip,
    renderCircleWithTextCell,
} from '@components/data-grid/mui';
import {formatCamelCaseEnumValue, formatTimestamp} from '@utils';

import {getActionHistoryChipVariant} from '../hooks/useActionHistoryFormatter';
import {EventLogGridItem} from '../types';

import {getActionHistoryValue, renderActionHistoryValue} from './ActionHistoryValueTooltip';

const localized = defineMessages({
    actionAgent: {
        id: 'ActionHistory_Agent',
        defaultMessage: 'Agent',
    },
    actionType: {
        id: 'ActionHistory_actionType',
        defaultMessage: 'Action Type',
    },
    prevValue: {
        id: 'AH_prevValue',
        defaultMessage: 'Prev Value',
    },
    newValue: {
        id: 'AH_newValue',
        defaultMessage: 'New Value',
    },
    triggeredAt: {
        id: 'AH_triggeredAt',
        defaultMessage: 'Triggered At',
    },
});

const getGridColumns = () => {
    const columns: GridColDef[] = [
        {
            field: nameof<EventLogGridItem>(u => u.id),
            hide: true,
        },
        {
            field: nameof<EventLogGridItem>(u => u.actionAgent),
            renderHeader: () => renderCellHeader(localized.actionAgent),
            renderCell: renderCircleWithTextCell,
        },
        {
            field: nameof<EventLogGridItem>(u => u.event_name),
            renderHeader: () => renderCellHeader(localized.actionType),
            valueFormatter: gridCellDefaultFormatter<EventLogGridItem>(i => formatCamelCaseEnumValue(i.event_name)),
            renderCell: params =>
                renderDefaultChip(
                    params.colDef.valueFormatter ? params.formattedValue : (params.value as string),
                    getActionHistoryChipVariant(params.value as string),
                    ChipType.Status
                ),
            flex: 0.25,
        },
        {
            field: nameof<EventLogGridItem>(u => u.prev_value),
            renderHeader: () => renderCellHeader(localized.prevValue),
            valueGetter: (params: GridCellParams) => getActionHistoryValue(params, 'prev_value'),
            renderCell: renderActionHistoryValue,
            flex: 0.25,
        },
        {
            field: nameof<EventLogGridItem>(u => u.new_value),
            renderHeader: () => renderCellHeader(localized.newValue),
            valueGetter: (params: GridCellParams) => getActionHistoryValue(params, 'new_value'),
            renderCell: renderActionHistoryValue,
            flex: 0.25,
        },
        {
            field: nameof<EventLogGridItem>(u => u.trigger_at),
            renderHeader: () => renderCellHeader(localized.triggeredAt),
            valueFormatter: gridCellDefaultFormatter<EventLogGridItem>(i => formatTimestamp(i.trigger_at, 'date-time')),
            sortable: true,
            sortField: nameof.full<EventLogGridItem>(u => u.trigger_at.seconds),
        },
    ];

    const defaultCol: GridColDef = {
        field: '',
        flex: 0.2,
        sortable: false,
        renderCell: renderCellWithTooltip,
    } as GridColDef;

    return columns.map(c => ({
        ...defaultCol,
        ...c,
    }));
};

export const actionHistoryData = {
    columns: getGridColumns(),
    localized,
};
