import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {ModuleName, SubmoduleName} from '@models/modules';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';
import {extendedViewCleanDelay} from '@redux/view';

import {useJurisdictionFeature} from '../features/app/config/hooks';
import {FeatureName} from '../features/app/config/types';
import {UserLoginDownloadCsvButton, UserLoginViewModelKeys, useUserLoginList} from '../features/block-user-login';
import {DateGridUserLoginServer} from '../features/block-user-login/components/DataGridUserLogin';
import {UserLoginQueryFilters} from '../features/block-user-login/components/UserLoginQueryFilters';

const localized = defineMessages({
    title: {
        id: 'UserLogin_title',
        defaultMessage: 'Login History',
    },
});

function LoginHistory() {
    const {formatMessage} = useIntl();

    const isLogoutAvailable = useJurisdictionFeature({featureName: FeatureName.LoginHistoryLogoutInfo});
    const keys: UserLoginViewModelKeys[] = [
        'uid',
        'username',
        'logged_at_ts',
        isLogoutAvailable ? 'logged_out_at_ts' : null,
        'login_status',
        'ip',
        'isp',
        'connection_type',
        'location.country',
        'location.state',
        'location.city',
        'device.mac_address',
        'device.model',
        'device.uuid',
        'device.name',
        'device.operating_system',
        'session_id',
    ].filter(i => i) as UserLoginViewModelKeys[];
    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useUserLoginList({
            viewType: 'LoginHistory',
            displayName: localized.title,
            fields: keys,
            validateFilter: () => true,
            syncWithUrl: true,
            cleanDelay: extendedViewCleanDelay,
        });

    return (
        <LayoutQueue
            header={
                <PageHeader
                    header={localized.title}
                    actions={
                        <UserLoginDownloadCsvButton keys={keys} filterString={filterString} filename={formatMessage(localized.title)} />
                    }
                />
            }
            toolbar={
                <Toolbar>
                    <ToolbarItemsContainer primary>
                        <ToolbarItems streched>
                            <UserLoginQueryFilters
                                model={filterString}
                                onChange={handleFilterChange}
                                availableFilters={['uid_username_ip_deviceId_country', 'status', 'loginDate']}
                            />
                        </ToolbarItems>
                    </ToolbarItemsContainer>
                </Toolbar>
            }
            body={
                <DateGridUserLoginServer
                    columns={keys}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                />
            }
        ></LayoutQueue>
    );
}

export const loginHistoryPageResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'LoginHistoryPage',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.PlayerManagement, null, 'read'),
        new Policy(ModuleName.PlayerManagement, SubmoduleName.LoginHistory, 'read'),
    ]),
});

export default withTraceErrorBoundary(withPolicyAccessCheck(LoginHistory, loginHistoryPageResource), ErrorModal);
