import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Box} from '@mui/material';

import {FormTextInputDefault, RuleType, useValidationFormatter} from '@components/input';

import {Message} from '../../../player-message/types';
import {localizedCommunicationActions} from '../localize';

export const SendMessageForm = () => {
    const {control} = useFormContext<Message>();
    const validationMessageFormatter = useValidationFormatter();
    const contentMaxLength = 500;
    const fieldsMaxLength = 200;

    return (
        <Box>
            <Controller
                render={({field, fieldState}) => (
                    <Box data-testid="messageFieldSubject">
                        <FormTextInputDefault
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={localizedCommunicationActions.messageFieldSubject}
                            label={localizedCommunicationActions.messageFieldSubject}
                            fieldState={fieldState}
                            textAlign="right"
                            inputProps={{maxLength: fieldsMaxLength}}
                        ></FormTextInputDefault>
                    </Box>
                )}
                control={control}
                name="subject"
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localizedCommunicationActions.messageFieldSubject),
                }}
            />
            <Controller
                render={({field, fieldState}) => (
                    <Box data-testid="messageFieldTitle">
                        <FormTextInputDefault
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={localizedCommunicationActions.messageFieldTitle}
                            label={localizedCommunicationActions.messageFieldTitle}
                            fieldState={fieldState}
                            textAlign="right"
                            inputProps={{maxLength: fieldsMaxLength}}
                        ></FormTextInputDefault>
                    </Box>
                )}
                control={control}
                name="title"
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localizedCommunicationActions.messageFieldTitle),
                }}
            />
            <Controller
                render={({field, fieldState}) => (
                    <Box data-testid="messageFieldContent">
                        <FormTextInputDefault
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={localizedCommunicationActions.writeMessagePlaceholder}
                            label={localizedCommunicationActions.messageFieldContent}
                            fieldState={fieldState}
                            textAlign="right"
                            multiline
                            maxRows={10}
                            rows={5}
                            inputProps={{maxLength: contentMaxLength}}
                        ></FormTextInputDefault>
                    </Box>
                )}
                control={control}
                name="content"
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localizedCommunicationActions.messageFieldContent),
                }}
            />
            <Controller
                render={({field, fieldState}) => (
                    <Box data-testid="messageFieldSignature">
                        <FormTextInputDefault
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={localizedCommunicationActions.messageFieldSignature}
                            label={localizedCommunicationActions.messageFieldSignature}
                            fieldState={fieldState}
                            textAlign="right"
                            inputProps={{maxLength: fieldsMaxLength}}
                        ></FormTextInputDefault>
                    </Box>
                )}
                control={control}
                name="signature"
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localizedCommunicationActions.messageFieldSignature),
                }}
            />
            <Controller
                render={({field, fieldState}) => (
                    <Box data-testid="messageFieldSender">
                        <FormTextInputDefault
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={localizedCommunicationActions.messageFieldSender}
                            label={localizedCommunicationActions.messageFieldSender}
                            fieldState={fieldState}
                            textAlign="right"
                            inputProps={{maxLength: fieldsMaxLength}}
                        ></FormTextInputDefault>
                    </Box>
                )}
                control={control}
                name="sender"
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localizedCommunicationActions.messageFieldSender),
                }}
            />
        </Box>
    );
};
