import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {IconButton} from '@components/button/Buttons';
import {CustomIcon, Icon, IconColor} from '@components/icons';

const useStyles = makeStyles()(theme => ({
    ruleFormRemoveConditionButton: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(0.625),
        height: 'max-content',
    },
    ruleFormRemoveConditionButtonIcon: {
        fontSize: theme.spacing(2.5),
    },
}));

type RuleFormHideConditionButtonProps = {
    onRemove: () => void;
    className?: string;
};

export function RuleFormRemoveConditionButton({onRemove, className}: RuleFormHideConditionButtonProps) {
    const {classes, cx} = useStyles();
    return (
        <IconButton className={cx(classes.ruleFormRemoveConditionButton, className)} onClick={onRemove}>
            <Icon color={IconColor.Secondary} className={classes.ruleFormRemoveConditionButtonIcon} icon={CustomIcon.ErrorCircle} />
        </IconButton>
    );
}
