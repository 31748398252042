import React, {useEffect} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import CountryFlag from '@components/data-visualize/CountryFlag';
import KeyValueTable from '@components/KeyValueTable';
import StyledAccordion from '@components/StyledAccordion';
import {AccountVerificationType, KycDocument} from '@models/generated/graphql';
import {CustomTheme} from '@style';
import {formatTimestamp, getSelectedItemString} from '@utils';

import {localizedJumioDocumentType} from '../../../../features/app/intl/shared-resources/jumioDocumentType';
import {AccountVerificationDocumentStatus} from '../../../../features/block-account-verification';
import {domain as entityDomain} from '../../../../features/kyc/types';
import {useRealtimeUpdates} from '../../../../features/realtime-updates/hooks';
import {SubscriptionType} from '../../../../features/realtime-updates/types';
import {kycDetailsDocumentsActions} from '../actions';
import {filterSelector, itemSelector} from '../selectors';
import {domain, idDocumentTypes} from '../types';

import {KYCDocumentLink} from './KYCDocumentLink';

const localized = defineMessages({
    uploadedDate: {
        id: 'KD_uploaded',
        defaultMessage: 'Upload Timestamp',
    },
    location: {
        id: 'KD_location',
        defaultMessage: 'Location',
    },
    deviceModel: {
        id: 'KD_deviceModel',
        defaultMessage: 'Device Model',
    },
});

const useClasses = makeStyles()((theme: CustomTheme) => ({
    kycDetailsDocumentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    kycDocumentHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    kycDetailsDocument: {
        width: '100%',
    },
}));

type KYCDetailsDocumentsProps = {
    itemId: string;
};

export const KYCDocuments = ({itemId}: KYCDetailsDocumentsProps) => {
    const {classes} = useClasses();
    const dispatch = useDispatch();
    const kycItem = useSelector(itemSelector);
    const initialFilter = useSelector(filterSelector);

    useRealtimeUpdates(entityDomain, domain, [SubscriptionType.Updated]);

    useEffect(() => {
        const filter = getSelectedItemString(initialFilter, itemId);
        dispatch(kycDetailsDocumentsActions.setFilter(filter));
    }, [itemId]);

    const getRows = (document: KycDocument) => {
        const hasUploadInfo = kycItem?.type === AccountVerificationType.Security && idDocumentTypes.includes(document?.doc_type);
        return [
            {key: localized.uploadedDate, value: formatTimestamp(document?.uploaded_ts, 'date-time')},
            hasUploadInfo ? {key: localized.location, value: <CountryFlag value={document?.country_info} />} : null,
            hasUploadInfo ? {key: localized.deviceModel, value: document?.device_id} : null,
        ].filter(i => i);
    };

    return (
        <Box className={classes.kycDetailsDocumentContainer}>
            {kycItem?.documents?.map(document => {
                return (
                    <StyledAccordion
                        className={classes.kycDetailsDocument}
                        header={
                            <Typography className={classes.kycDocumentHeader} variant="body1" component="div">
                                <AccountVerificationDocumentStatus
                                    status={document?.status}
                                    docType={document?.doc_type}
                                    fullDocumentType
                                />
                                {document?.doc_ref_id ? <KYCDocumentLink docRefId={document?.doc_ref_id} /> : null}
                            </Typography>
                        }
                        isCollapsed
                        key={document.doc_type}
                        componentId={document.doc_type}
                        children={<KeyValueTable rows={getRows(document)} tableLabel={localizedJumioDocumentType[document.doc_type]} />}
                    />
                );
            })}
        </Box>
    );
};
