import {ChipType, ChipVariant} from '@components/chip/types';
import {Workspace} from '@models/generated/graphql';

import {localizedWorkspaces} from '../../../features/app/intl/shared-resources/workspace';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export const getWorkspaceChipsMapping = (chipType: ChipType) => ({
    [Workspace.Global]: {
        label: localizedWorkspaces[Workspace.Global],
        chipType: ChipType.TagMark,
        chipVariant: ChipVariant.DropdownDefault,
    },

    [Workspace.Security]: {
        label: localizedWorkspaces[Workspace.Security],
        chipType: chipType,
        chipVariant: ChipVariant.Purple,
    },

    [Workspace.Payment]: {
        label: localizedWorkspaces[Workspace.Payment],
        chipType: chipType,
        chipVariant: ChipVariant.LightBlue,
    },

    [Workspace.Kyc]: {
        label: localizedWorkspaces[Workspace.Kyc],
        chipType: chipType,
        chipVariant: ChipVariant.Turquoise,
    },

    [Workspace.CustomerSupport]: {
        label: localizedWorkspaces[Workspace.CustomerSupport],
        chipType: chipType,
        chipVariant: ChipVariant.LightGreen,
    },

    GlobalRead: {
        label: localizedWorkspaces.GlobalRead,
        chipType: ChipType.TagMark,
        chipVariant: ChipVariant.DropdownDefault,
    },
});
