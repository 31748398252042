import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    header: {
        id: 'RRI_header',
        defaultMessage: 'Country/Region Specific Info',
    },
    occupation: {
        id: 'RRI_occupation',
        defaultMessage: 'Occupation',
    },
    tax_id: {
        id: 'RRI_tax_id',
        defaultMessage: 'Tax ID',
    },
    national_id: {
        id: 'RRI_national_id',
        defaultMessage: 'National ID',
    },
    nationality: {
        id: 'RRI_nationality',
        defaultMessage: 'Nationality',
    },
    country_of_birth: {
        id: 'RRI_country_of_birth',
        defaultMessage: 'Country of birth',
    },
});
