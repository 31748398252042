import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {AutocompleteRenderInputParams, Box, InputAdornment} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import {CustomIcon} from '@components/icons';

import {IconButton} from './../button/Buttons';
import {localizedAutocomplete} from './Autocomplete.localize';
import {useAutocompleteClasses} from './Autocomplete.styles';

type AutocompleteInputProps = {
    params: AutocompleteRenderInputParams;
    placeholder?: MessageDescriptor;
    loading: boolean;
    multiple?: boolean;
    inputValue?: string;
    onClear?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    startIcon?: JSX.Element;
};

export const AutocompleteInput = ({params, placeholder, loading, multiple, inputValue, onClear, startIcon}: AutocompleteInputProps) => {
    const {formatMessage} = useIntl();
    const {classes: styles} = useAutocompleteClasses();

    return (
        <TextField
            {...params}
            ref={params.InputProps.ref}
            placeholder={placeholder ? formatMessage(placeholder) : formatMessage(localizedAutocomplete.autocompletePlaceholder)}
            multiline={multiple}
            InputProps={{
                inputProps: {'data-testid': 'autocompleteInput', ...params.inputProps, value: inputValue},
                ...params.InputProps,
                startAdornment: startIcon ? (
                    <Box className={styles.autocompleteStartAdornment}>{startIcon}</Box>
                ) : (
                    params.InputProps.startAdornment
                ),
                endAdornment: (
                    <React.Fragment>
                        {loading ? (
                            <Box className={styles.autocompleteCircularProgress}>
                                <CircularProgress size={12} />
                            </Box>
                        ) : inputValue && !multiple ? (
                            <InputAdornment position="end">
                                <IconButton onClick={onClear} edge="end" size="small" data-testid="clearButton">
                                    <Box component="span" className={CustomIcon.CancelOutline} fontSize={8} />
                                </IconButton>
                            </InputAdornment>
                        ) : null}
                        {multiple ? params?.InputProps.endAdornment : null}
                    </React.Fragment>
                ),
                disableUnderline: true,
            }}
        />
    );
};

export const renderInput = (
    multiple: boolean,
    params: AutocompleteRenderInputParams,
    placeholder: MessageDescriptor,
    loading: boolean,
    inputValue: string,
    onClear: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    startIcon?: JSX.Element
) => {
    return (
        <AutocompleteInput
            multiple={multiple}
            params={params}
            placeholder={placeholder}
            loading={loading}
            inputValue={inputValue}
            onClear={onClear}
            startIcon={startIcon}
        />
    );
};
