import React from 'react';
import {useEffect} from 'react';
import {useAuth} from 'react-oidc-context';
import {useDispatch} from 'react-redux';

import {initActions} from '../actions';

export class ModuleProps {
    domain: string;
}

export function withModuleContentLoad<Props extends ModuleProps>(WrappedComponent: React.ComponentType<Props>): React.ComponentType<Props> {
    return React.memo<Props>((props: Props) => {
        const {domain} = props;
        const dispatch = useDispatch();
        const actions = initActions(domain);
        const {isAuthenticated} = useAuth();

        useEffect(() => {
            if (isAuthenticated) {
                dispatch(actions.init());
            }
        }, [isAuthenticated]);

        return <WrappedComponent {...(props as Props)} />;
    });
}
