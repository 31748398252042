import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {
    Mutation,
    MutationAddPlayerReferralArgs,
    MutationRemoveReferrerArgs,
    MutationUpdatePlayerReferralArgs,
    MutationUpdateReferrerArgs,
    MutationUpdateRegularPlayerReferralSettingsArgs,
    PlayerReferral,
    PlayerReferralInput,
    PlayerReferralsFilter,
    ReferralAmountUpdateType,
    ReferralUpdateInput,
    RemovePlayerReferralInput,
    Timestamp,
} from '@models/generated/graphql';
import {Filter, PlayerReferralFilterKeys, PlayerReferralQueryFields, PlayerReferralTextFilterKeys} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {GqlMutationRequest, ServiceResponsePayload} from './types';

export enum ReferrerType {
    Affiliate = 'Affiliate',
    P2P = 'P2P',
}

export class UserReferralFormModel {
    referrer_id: string;
    referee_uid: string;
    referrer_type: ReferrerType;
    id: string;
}

export type UserReferralExpirationDateFormModel = {
    referee_uid: string;
    expiration_date: Timestamp;
};

export type UserReferralRevenueShareFormModel = {
    referee_uid: string;
    revenue_share: number;
};

export interface IPlayerReferralUpdateService {
    addReferrerInfo(
        referrer: UserReferralFormModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationAddPlayerReferralArgs>, PlayerReferral>>;

    updateReferrerInfo(referrer: UserReferralFormModel): Observable<ServiceResponsePayload<UserReferralFormModel, PlayerReferral>>;

    removeReferrerInfo(
        referrer: UserReferralFormModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationRemoveReferrerArgs>, PlayerReferral>>;

    editExpirationDate(
        model: UserReferralExpirationDateFormModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdatePlayerReferralArgs>, PlayerReferral>>;

    editRevenueShare(
        model: UserReferralRevenueShareFormModel
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdatePlayerReferralArgs>, PlayerReferral>>;
}

@injectable()
export class PlayerReferralService
    extends EntityBaseGqlService<unknown, PlayerReferralQueryFields>
    implements IPlayerReferralUpdateService
{
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new PlayerReferralRequestBuilder());
    }

    public addReferrerInfo(referrer: UserReferralFormModel) {
        return this._service
            .mutate<Mutation, MutationAddPlayerReferralArgs>(this.getAddReferrerMutation(), {
                referral: {
                    referrer_id: referrer.referrer_id,
                    referee_uid: referrer.referee_uid,
                } as PlayerReferralInput,
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload?.addReferrer})));
    }

    public updateReferrerInfo(referrer: UserReferralFormModel) {
        return this._service
            .mutate<Mutation, MutationUpdateReferrerArgs>(this.getUpdateReferrerInfoMutation(), {
                referral: {
                    referrer_id: referrer.referrer_id,
                    referee_uid: referrer.referee_uid,
                } as PlayerReferralInput,
            })
            .pipe(map(r => ({...r, requestPayload: referrer, responsePayload: r.responsePayload?.updateReferrer})));
    }

    public removeReferrerInfo(referrer: UserReferralFormModel) {
        return this._service
            .mutate<Mutation, MutationRemoveReferrerArgs>(this.getRemoveReferrerInfoMutation(), {
                referral: {
                    referrer_id: referrer.referrer_id,
                    referee_uid: referrer.referee_uid,
                } as RemovePlayerReferralInput,
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload?.removeReferrer})));
    }

    public editExpirationDate({expiration_date, referee_uid}: UserReferralExpirationDateFormModel) {
        const referral: ReferralUpdateInput = {
            uid: referee_uid,
            update_type: ReferralAmountUpdateType.Percentage,
            expiration_date: expiration_date,
        };
        return this._service
            .mutate<Mutation, MutationUpdateRegularPlayerReferralSettingsArgs>(this.getUpdateRegularPlayerReferralSettingsMutation(), {
                referral,
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload?.updateRegularPlayerReferralSettings})));
    }

    public editRevenueShare({revenue_share, referee_uid}: UserReferralRevenueShareFormModel) {
        const referral: ReferralUpdateInput = {
            uid: referee_uid,
            update_type: ReferralAmountUpdateType.Percentage,
            revenue_share: revenue_share,
        };
        return this._service
            .mutate<Mutation, MutationUpdateRegularPlayerReferralSettingsArgs>(this.getUpdateRegularPlayerReferralSettingsMutation(), {
                referral,
            })
            .pipe(map(r => ({...r, responsePayload: r.responsePayload?.updateRegularPlayerReferralSettings})));
    }

    private getAddReferrerMutation() {
        return gql`
            mutation AddReferrer($referral: PlayerReferralInput!) {
                addReferrer(referral: $referral) {
                    referrer_id
                    referee {
                        uid
                    }
                    revenue_share
                    revenue_generated_total
                    invite_code
                }
            }
        `;
    }

    private getUpdateReferrerInfoMutation() {
        return gql`
            mutation UpdateReferrer($referral: PlayerReferralInput!) {
                updateReferrer(referral: $referral) {
                    referrer_id
                    referee {
                        uid
                    }
                    revenue_share
                    revenue_generated_total
                    invite_code
                }
            }
        `;
    }

    private getRemoveReferrerInfoMutation() {
        return gql`
            mutation RemoveReferrer($referral: RemovePlayerReferralInput!) {
                removeReferrer(referral: $referral) {
                    referrer_id
                    referee {
                        uid
                    }
                    revenue_share
                    revenue_generated_total
                    invite_code
                }
            }
        `;
    }

    private getUpdateRegularPlayerReferralSettingsMutation() {
        return gql`
            mutation UpdateRegularPlayerReferralSettings($referral: ReferralUpdateInput!) {
                updateRegularPlayerReferralSettings(referral: $referral) {
                    referrer_id
                    referee {
                        uid
                    }
                    revenue_share
                    revenue_generated_total
                    invite_code
                    revenue_share_offering_status
                    expiration_date {
                        seconds
                    }
                }
            }
        `;
    }
}

export class PlayerReferralRequestBuilder extends GqlRequestBuilder<unknown, PlayerReferralQueryFields> {
    protected buildFilter(filter: Filter<PlayerReferralFilterKeys>): {filter: PlayerReferralsFilter} {
        return {
            filter: {
                text: this.getGQLTextFilter([
                    ...Object.keys(this.filterFieldsMapper).map((key: PlayerReferralTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string)
                    ),
                ]),
            },
        };
    }

    public buildQuery(fields: PlayerReferralQueryFields[]): DocumentNode {
        return gql`
            query GetPlayerReferrals($filter: PlayerReferralsFilter, $sort: Sorting, $start: Int, $end: Int) {
                getPlayerReferrals(filter: $filter, sort: $sort, start: $start, end: $end)
                {
                    items {
                        referrer_id ${this.hasQueryItem(fields, 'referrer_id')}
                        invite_code ${this.hasQueryItem(fields, 'invite_code')}
                        referee ${this.hasAnyOfQueryItems(fields, this.getRefereeQueryItems())} {
                            uid ${this.hasQueryItem(fields, 'referee.uid')}
                        }
                        revenue_share ${this.hasQueryItem(fields, 'revenue_share')}
                        revenue_generated_total ${this.hasQueryItem(fields, 'revenue_generated_total')}
                        revenue_share_offering_status ${this.hasQueryItem(fields, 'revenue_share_offering_status')}
                        expiration_date ${this.hasAnyOfQueryItems(fields, this.getExpirationDateQueryItems())} {
                            seconds ${this.hasQueryItem(fields, 'expiration_date.seconds')}
                        }
                    }
                    total_count
                }
            }
        `;
    }

    private getRefereeQueryItems(): PlayerReferralQueryFields[] {
        return ['referee.uid'];
    }

    private getExpirationDateQueryItems(): PlayerReferralQueryFields[] {
        return ['expiration_date.seconds'];
    }

    private filterFieldsMapper: Record<PlayerReferralTextFilterKeys, string[]> = {
        referee_uid: [nameof<PlayerReferral>(m => m.referee_uid)],
        referrer_id: [nameof<PlayerReferral>(m => m.referrer_id)],
    };
}
