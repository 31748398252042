import React from 'react';
import {defineMessages} from 'react-intl';
import {useSelector} from 'react-redux';

import {ValueButton} from '@components/button';
import {renderDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {CustomIcon} from '@components/icons/types';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {messageResultValueSelector} from '../../block-bulk-actions';

const localized = defineMessages({
    sendMessage: {
        id: 'BulkActionSendMessage_SendMessage',
        defaultMessage: 'Send a Message',
    },
});

type ActionSendMessageProps = {onClick: () => void};

function ActionSendMessage({onClick}: ActionSendMessageProps) {
    const value = useSelector(messageResultValueSelector);

    return (
        <ValueButton onClick={onClick} label={localized.sendMessage} iconClassName={CustomIcon.SmsOutline}>
            {value ? renderDefaultChip('1', ChipVariant.Blue, ChipType.Status) : null}
        </ValueButton>
    );
}

export const BulkUserProfileSendMessageButton = withMultiplePermission(ActionSendMessage, {
    allowedPermissions: [
        {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Create, PermissionEnum.Delete, PermissionEnum.Update]},
        {
            moduleName: ModuleName.ActionCenter,
            submoduleName: SubmoduleName.CommunicationActions,
            permissions: [PermissionEnum.Create, PermissionEnum.Delete],
        },
    ],
});
