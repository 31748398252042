import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import LocalizedText from '@components/i18n/LocalizedText';
import {EmptyLabel, isEmptyCellValue} from '@components/label/EmptyLabel';
import {TransactionDuration as TransactionDurationType} from '@models/transaction';
import {getTransactionDuration} from '@models/transaction/utils';

function TransactionDuration(props: TransactionDurationType) {
    const formattedValue = getTransactionDuration(props);
    return <LocalizedText label={formattedValue} />;
}

export const renderTransactionDuration = (params: GridCellParams) => {
    const transactionDurationProps: TransactionDurationType = params.value as TransactionDurationType;
    const isEmpty = isEmptyCellValue(transactionDurationProps?.started_at?.seconds);
    return isEmpty ? (
        <EmptyLabel defaultSymbol="dash" />
    ) : (
        <TransactionDuration history={transactionDurationProps?.history} started_at={transactionDurationProps?.started_at} />
    );
};
