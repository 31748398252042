import {Filter} from '../types';

type FilterType<TFilterKeys> = Filter<any, TFilterKeys>;

export function updateKeyValueFilterArray<TFilterKey>(
    currentFilters: FilterType<TFilterKey>[] = [],
    ...filters: FilterType<TFilterKey>[]
): FilterType<TFilterKey>[] {
    return filters?.reduce(
        (previousValue, currentValue) => {
            const newArray = previousValue?.filter(f => f.key !== currentValue.key);
            newArray?.push(currentValue);
            return newArray;
        },
        [...currentFilters]
    );
}
