import {from, of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {mergeMap} from '@otel';
import {RootEpic} from '@redux';

import {protectEpics} from '../error-handling/epics';

import {loadLocalizationResourcesAction, localizationResourcesLoadedAction} from './actions';

const loadLocalizationEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(loadLocalizationResourcesAction)),
        mergeMap(action =>
            from(import(`../../../../compiled-lang/${action.payload}.json`)).pipe(
                mergeMap(res => of(localizationResourcesLoadedAction(res)))
            )
        )
    );

export default protectEpics(loadLocalizationEpic);
