import React from 'react';
import {Box} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';

import {useDropzoneContentClasses} from './DropzoneContent.style';
import {localized} from './DropzoneContentSuccess.localize';

type DropzoneContentSuccessProps = {
    file: File;
};

const DropzoneContentSuccess = ({file}: DropzoneContentSuccessProps) => {
    const {classes, cx} = useDropzoneContentClasses();

    return (
        <>
            <Icon
                icon={CustomIcon.Confirm}
                color={IconColor.PrimaryContrastText}
                className={cx(classes.dropzoneContentSuccessIcon, classes.dropzoneContentResultIcon, classes.dropzoneContentIcon)}
            />
            <Box>
                <LocalizedText label={localized.dropzoneContentSuccessMessage} labelParams={{filename: <b>{file?.name ?? ''}</b>}} />
            </Box>
        </>
    );
};

export default DropzoneContentSuccess;
