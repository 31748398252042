import {useAutoMapper} from '@auto-mapper';
import {GameTemplateViewModelKeys} from '@models/game-template';
import {EntityType, GameTemplate, GameTemplateFields, GameTemplateFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger} from '@redux/realtime';
import {getNonEmptyValueValidator, UseDetailsViewEntityProps, UseDetailsViewEntityResult, useViewInit} from '@redux/view';
import {updateKeyValueFilterArray} from '@utils';

import {Filter} from '../../common/types';

export function useGameTemplate({
    id,
    viewType,
    displayName,
    fields,
    cleanDelay = 0,
    realtimeMode,
    defaultFilters,
    validateFilter,
}: UseDetailsViewEntityProps<GameTemplateFilterKeys, GameTemplateViewModelKeys>): UseDetailsViewEntityResult<
    GameTemplate,
    GameTemplateViewModelKeys
> {
    const mapper = useAutoMapper();

    const defaultFilter: Filter<unknown, GameTemplateFilterKeys>[] = updateKeyValueFilterArray(
        [{key: 'id', value: id ?? null}],
        ...(defaultFilters ?? [])
    );
    const serverModelFields: GameTemplateFields[] = mapper.map(fields, nameof<GameTemplateViewModelKeys>(), nameof<GameTemplateFields>());

    const {
        items,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
    } = useViewInit<GameTemplate, GameTemplateFilterKeys, string>({
        viewType,
        entity: {
            entity: EntityType.GameTemplate,
            fields: serverModelFields,
        },
        realtime: realtimeMode
            ? {entity: EntityType.GameTemplate, mode: realtimeMode, triggers: [{type: RealtimeMessageTrigger.Update}]}
            : null,
        displayName,
        defaultFilters: defaultFilter,
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: validateFilter ?? getNonEmptyValueValidator<GameTemplateFilterKeys>('id'),
        cleanDelay,
    });

    return {item: items?.length ? items[0] : null, handleFilterChange, searchFilter, filterString};
}
