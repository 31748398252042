import {ComponentType} from 'react';
import type {GridColumn} from '@glideapps/glide-data-grid';

import {SortDirection} from '../types';

import {GlideCell} from './cells';

export type GlideGridColumn<T = {}> = GridColumn & {
    /**
     * use instead {@link BaseGridColumn#title} for rendering custom header.
     * `title` must be set to empty string as it is always rendered by glide grid and can not be customized
     * */
    columnName: string;
    cell: GlideCell<T>;
    hide?: boolean;
    sortable: boolean;
};

export type SortField = {
    sort: SortDirection;
    field: string;
};

export type TooltipContentProps<TValue> = {
    value: TValue;
};

export type GlideCellData<TValue = unknown> = {
    kind: string;
    value: TValue;
    isSummary?: boolean;
    tooltip: ComponentType<TooltipContentProps<TValue>>;
};

export const rowHeight = 48;

export type Point = {
    x: number;
    y: number;
};
