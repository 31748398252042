import React from 'react';
import {defineMessages} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import {AgentPathStatisticsViewModelKeys} from '@models/agent-path-statistics';
import {ModuleName} from '@models/modules';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';
import {extendedViewCleanDelay, ViewType} from '@redux/view';

import {FeatureName} from '../features/app/config/types';
import {LackOfPermissionIndicator, withPermissionAndJurisdictionConfig} from '../features/app/permission/PermissionHoc';
import {DataGridAgentPathStatisticsServer, useAgentPathStatistics} from '../features/block-agent-path-statistics-list';

const localized = defineMessages({
    title: {
        id: 'AgentPathStatistics_title',
        defaultMessage: 'VN Path Statistics',
    },
});

function AgentManagementPathStatistics() {
    const viewType: ViewType = 'AgentPathStatistics';
    const keys: AgentPathStatisticsViewModelKeys[] = [
        'id',
        'agent_uid',
        'path_to_subagent',
        'registered_player_number',
        'active_player_number',
    ];

    const {items, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useAgentPathStatistics({
        viewType,
        fields: keys,
        displayName: localized.title,
        syncWithUrl: true,
        validateFilter: null,
        cleanDelay: extendedViewCleanDelay,
    });

    return (
        <LayoutQueue
            header={<PageHeader header={localized.title} />}
            body={
                <DataGridAgentPathStatisticsServer
                    columns={keys}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                />
            }
        />
    );
}

export const agentManagementPathStatisticsResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'AgentManagementPathStatistics',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageAgentReportVn), new Policy(ModuleName.PageAgentReportVn, null, 'read')]),
});

export default withTraceErrorBoundary(
    withPermissionAndJurisdictionConfig(
        withPolicyAccessCheck(AgentManagementPathStatistics, agentManagementPathStatisticsResource),
        {featureName: FeatureName.AgentPathStatisticsVn},
        LackOfPermissionIndicator.NotFound
    ),
    ErrorModal
);
