import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {localizedValueRangePicker} from './localizeValueRangePicker';
import {Operators, ValueRangePickerModel, ValueRangePickerType} from './valueRangePickerTypes';

export const useSelectBoxValue = (model: ValueRangePickerModel, valueRangePickerType: ValueRangePickerType): string => {
    const {formatMessage} = useContext(IntlContext);

    if (!model?.from && !model?.to) return formatMessage(localizedValueRangePicker.anyValue);

    const {operator, from, to} = model;
    const formats = {
        [ValueRangePickerType.Hours]: {
            [Operators.between]: formatMessage(localizedValueRangePicker.selectBoxBetween, {from, to}),
            [Operators.equals]: formatMessage(localizedValueRangePicker.selectBoxEquals, {from}),
            [Operators.isLessThan]: formatMessage(localizedValueRangePicker.selectBoxIsLessThan, {to}),
            [Operators.isGreaterThan]: formatMessage(localizedValueRangePicker.selectBoxIsGreaterThan, {from}),
        },
        [ValueRangePickerType.Number]: {
            [Operators.between]: formatMessage(localizedValueRangePicker.selectBoxBetween, {from, to}),
            [Operators.equals]: formatMessage(localizedValueRangePicker.selectBoxEquals, {from}),
            [Operators.isLessThan]: formatMessage(localizedValueRangePicker.selectBoxIsLessThan, {to}),
            [Operators.isGreaterThan]: formatMessage(localizedValueRangePicker.selectBoxIsGreaterThan, {from}),
        },
    };

    const formatted = formats[valueRangePickerType][operator];

    return formatted ?? formatMessage(localizedValueRangePicker.anyValue);
};
