import {createSelector} from 'reselect';
import {RootState} from 'typesafe-actions';

import {actionsDataSelector, BulkActionKey, BulkActionState} from '../block-bulk-actions';

import {BulkActionAddLabelItemPayload} from './types';

export const userLabelIdsSelector = createSelector<RootState, BulkActionKey, BulkActionState, BulkActionKey, string[]>(
    (state: RootState, _: BulkActionKey) => actionsDataSelector(state),
    (_: RootState, actionKey: BulkActionKey) => actionKey,
    (actionsState: BulkActionState, actionKey: BulkActionKey) => [
        ...new Set(
            Object.values(actionsState)
                .flat()
                .map(item => (item.actionKey === actionKey ? (item as BulkActionAddLabelItemPayload)?.value?.label_id : null))
                .filter(i => i)
        ),
    ]
);
