import React from 'react';
import {defineMessages} from 'react-intl';

import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {BulkActionKey, BulkChooseActionStepLockGroup} from '../../block-bulk-actions';

import {BulkUserProfileP2PLock} from './BulkUserProfileP2PLock';

const localized = defineMessages({
    bulkFeatureLock: {
        id: 'BulkStepChooseActions_FeatureLock',
        defaultMessage: 'Feature Lock',
    },
});

type FeatureLocksProps = {actionKeys: BulkActionKey[]};

function FeatureLocks({actionKeys}: FeatureLocksProps) {
    return actionKeys.includes(BulkActionKey.P2PTransferLock) ? (
        <BulkChooseActionStepLockGroup title={localized.bulkFeatureLock}>
            <BulkUserProfileP2PLock />
        </BulkChooseActionStepLockGroup>
    ) : null;
}

export const BulkUserProfileFeatureLockGroup = withMultiplePermission(FeatureLocks, {
    allowedPermissions: [
        {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.FinanceActions, permissions: [PermissionEnum.Update]},
    ],
});
