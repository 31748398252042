import React, {memo, useContext} from 'react';
import {Box} from '@mui/material';

import {SquareIconButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons/types';
import {ModalContext} from '@components/modal';
import StyledTooltip from '@components/Tooltip';
import {PlayerMessage} from '@models/generated/graphql';

import {NotificationHistoryDetailsModal} from './NotificationHistoryDetailsModal';
import {localizedNotificationHistoryDetailsModal} from './NotificationHistoryDetailsModal.localize';

type NotificationHistoryDetailsActionProps = {
    playerMessage: PlayerMessage;
};

export const NotificationHistoryDetailsAction = memo(({playerMessage}: NotificationHistoryDetailsActionProps) => {
    const {closeModal, openModal} = useContext(ModalContext);

    const onClick = () => {
        openModal({
            body: <NotificationHistoryDetailsModal playerMessage={playerMessage} />,
            title: <LocalizedText label={playerMessage?.subject} />,
            buttons: [
                {
                    label: localizedNotificationHistoryDetailsModal.close,
                    onClick: () => closeModal(),
                },
            ],
        });
    };

    return (
        <StyledTooltip
            element={
                <Box>
                    <SquareIconButton onClick={onClick} iconClassName={CustomIcon.ViewOutline} />
                </Box>
            }
            title={<LocalizedText label={localizedNotificationHistoryDetailsModal.viewNotification} />}
            hideTooltip={false}
        />
    );
});

export const renderNotificationHistoryDetailsAction = (playerMessage: PlayerMessage) => {
    return <NotificationHistoryDetailsAction playerMessage={playerMessage} />;
};
