import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {withStyledInputLabel} from '@components/input';
import {MultiSelectWithExclude, MultiSelectWithExcludeModel} from '@components/select';
import {CasinoSupplierFilterKeys} from '@models/casino-game';

import {Filter} from '../../../common/types';
import {SelectOption} from '../../module-shared/types';
import {useCasinoSuppliers} from '../hooks';

const localized = defineMessages({
    title: {
        id: 'CasinoSupplierAutocomplete_displayName',
        defaultMessage: 'Casino Supplier Autocomplete',
    },
    searchPlaceholder: {
        id: 'CasinoSupplierAutocomplete_searchPlaceholder',
        defaultMessage: 'Search...',
    },
    suppliers: {
        id: 'CasinoGameAutocomplete_suppliers',
        defaultMessage: 'suppliers',
    },
});

type CasinoSupplierAutocompleteProps = {
    disabled: boolean;
    value: MultiSelectWithExcludeModel;
    onValueChange: (options: MultiSelectWithExcludeModel) => void;
};

export function CasinoSupplierAutocomplete({disabled, value, onValueChange}: CasinoSupplierAutocompleteProps) {
    const {formatMessage} = useIntl();

    const {
        items,
        handleFilterChange,
        handlePageChange,
        totalCount: filteredTotalCount,
        searchFilter,
    } = useCasinoSuppliers({
        viewType: 'DepositBonusCasinoSuppliersAutocomplete',
        displayName: localized.title,
        defaultFilters: [],
        validateFilter: () => !disabled,
    });
    const {totalCount} = useCasinoSuppliers({
        viewType: 'DepositBonusCasinoSuppliersAutocomplete.Total',
        displayName: localized.title,
        defaultFilters: [],
        validateFilter: () => !disabled,
    });

    function handleNameFilterChange(value: string) {
        const nameFilterKey: CasinoSupplierFilterKeys = 'name';
        const newNameFilter: Filter<any, CasinoSupplierFilterKeys> = {key: nameFilterKey, value};
        const filters = searchFilter?.filter?.filter(f => f.key !== nameFilterKey) ?? [];
        handleFilterChange([...filters, newNameFilter]);
    }

    const options = items?.map<SelectOption>(i => ({value: i.code, label: i.name}));

    return (
        <MultiSelectWithExclude
            mode="server"
            value={value}
            options={options}
            hasSearch={true}
            searchPlaceholder={localized.searchPlaceholder}
            onValueChange={onValueChange}
            showResetButton
            showPagination
            onFilterChange={handleNameFilterChange}
            page={searchFilter?.paging?.page}
            pageSize={searchFilter?.paging?.pageSize}
            onPageChange={handlePageChange}
            total={totalCount}
            filteredTotal={filteredTotalCount}
            disabled={disabled}
            itemsName={formatMessage(localized.suppliers)}
        />
    );
}

export const CasinoSupplierAutocompleteFormInput = withStyledInputLabel(CasinoSupplierAutocomplete);
