import React, {useContext} from 'react';

import {Button, ButtonProps} from '@components/button/Buttons';
import {BulkModalContext} from '@components/modal';

import {BulkBonusSelectUsersModal} from '../../block-bonus-code';
import {BulkBonusImmediateCashApplyStep} from '../../block-bonus-code/components/BulkBonusApplyStep';
import {BulkBonusImmediateCashChangesSummaryStep} from '../../block-bonus-code/components/BulkBonusChangesSummaryStep';
import {BulkBonusImmediateCashExecutionStep} from '../../block-bonus-code/components/BulkBonusExecutionStep';
import {useBulkAction} from '../../block-bulk-actions';

type BulkBonusImmediateCashChooseActionButtonProps = ButtonProps & {bonusId: string};

export const BulkBonusImmediateCashChooseActionButton = ({bonusId, ...props}: BulkBonusImmediateCashChooseActionButtonProps) => {
    const {chooseStep, closeModal} = useContext(BulkModalContext);
    const {resetBulkState} = useBulkAction();

    const openChooseAction = () => chooseStep(chooseActionStep, 'md');
    const openChangesSummary = () => chooseStep(changesSummaryStep, 'md');
    const openExecutionSummary = () => chooseStep(executionSummaryStep, 'md');
    const openAddCodeStep = () => chooseStep(addCodeStep, 'md');

    const chooseActionStep = <BulkBonusSelectUsersModal onNext={openAddCodeStep} onPrev={closeModal} selectedBonusesIds={[bonusId]} />;
    const addCodeStep = <BulkBonusImmediateCashApplyStep onNext={openChangesSummary} onPrev={closeModal} />;
    const changesSummaryStep = <BulkBonusImmediateCashChangesSummaryStep onNext={openExecutionSummary} onPrev={closeModal} />;
    const executionSummaryStep = <BulkBonusImmediateCashExecutionStep onNext={closeModal} hasRetry={true} />;

    const handleBulkOpenClick = () => {
        resetBulkState();
        openChooseAction();
    };

    return <Button {...props} onClick={handleBulkOpenClick} />;
};
