import {MessageDescriptor} from 'react-intl';
import {GridCellParams, GridSortDirection} from '@mui/x-data-grid';

import {ColumnTypes, DataGridColumn} from '../types';

import {
    renderCellHeader,
    renderMuiGridCurrencyCell,
    renderMuiGridLinkCell,
    renderMuiGridLocalizedTextCell,
    renderMuiGridStringTextCell,
} from './cells';
import {GridColDef} from './types';

export class MuiDataGridColumnsConverter<TModel> {
    private readonly _formatMessage: (message: MessageDescriptor) => string;

    constructor(formatMessage: (message: MessageDescriptor) => string) {
        this._formatMessage = formatMessage;
    }

    public convert(columns: DataGridColumn<TModel>[], visibleColumns: string[]): GridColDef[] {
        return columns?.map(c => ({
            field: c.field,
            valueGetter: params => c.valueGetter(params.row as TModel),
            renderHeader: renderCellHeader,
            headerName: this._formatMessage(c.title),
            localizedHeaderName: c.title,
            renderCell: this.getRenderCell(c),
            width: c.width,
            sortable: c.sortable,
            sortDirection: c.sortDirection as GridSortDirection,
            hide: visibleColumns && !visibleColumns.includes(c.field),
        }));
    }

    private getRenderCell(column: DataGridColumn<TModel>): (params: GridCellParams) => JSX.Element {
        const renderCellMap: Record<ColumnTypes, (params: GridCellParams) => JSX.Element> = {
            text: renderMuiGridStringTextCell,
            localized_text: renderMuiGridLocalizedTextCell,
            link: renderMuiGridLinkCell,
            currency: renderMuiGridCurrencyCell,
        };

        return renderCellMap[column.type];
    }
}
