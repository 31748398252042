import React, {useEffect} from 'react';
import {DateRange} from 'react-day-picker';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {Box, Grid} from '@mui/material';
import {format, isBefore, isValid, parse} from 'date-fns';

import {Locale} from '@components/types';

import {DateInputMask} from './DatePicker.config';
import {localizedDatePickerFooterRangeMode} from './DatePickerFooterRangeMode.localize';
import {useDatePickerFooterRangeModeClasses} from './DatePickerFooterRangeMode.style';
import {FormTextInputDefault} from './FormInput';

type DatePickerFooterRangeModeModel = {
    from: string;
    to: string;
};

type DatePickerFooterRangeModeProps = {
    value?: DateRange;
    locale: Locale;
    onChange?: (dates: DateRange) => void;
};

//TODO: [BO-2664] Remove dependency to features (pass data through props)
export const DatePickerFooterRangeMode = ({value, locale, onChange}: DatePickerFooterRangeModeProps) => {
    const {classes} = useDatePickerFooterRangeModeClasses();
    const inputMask = DateInputMask[locale];
    const {control, setValue} = useForm<DatePickerFooterRangeModeModel>({defaultValues: {from: '', to: ''}});
    const watched = useWatch({control});
    const parsedFrom = parse(watched.from, inputMask, new Date());
    const parsedTo = parse(watched.to, inputMask, new Date());

    useEffect(() => {
        if (value?.from?.getTime() !== parsedFrom?.getTime()) {
            setValue('from', isValid(value?.from) ? format(value?.from, inputMask) : '');
        }
        if (value?.to?.getTime() !== parsedTo?.getTime()) {
            setValue('to', isValid(value?.to) ? format(value?.to, inputMask) : '');
        }
    }, [value]);

    useEffect(() => {
        if (isValid(parsedFrom) && isValid(parsedTo) && isBefore(parsedFrom, parsedTo)) {
            onChange({from: parsedFrom, to: parsedTo});
        }
    }, [watched.from, watched.to]);

    return (
        <Box mt={2}>
            <Grid container spacing={1}>
                <Grid item xs={6} md={6}>
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputDefault
                                className={classes.datePickerFooterRangeModeInput}
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                placeholder={inputMask}
                                label={localizedDatePickerFooterRangeMode.datePickerFooterRangeModeFromLabel}
                            />
                        )}
                        name="from"
                        control={control}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputDefault
                                className={classes.datePickerFooterRangeModeInput}
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                placeholder={inputMask}
                                label={localizedDatePickerFooterRangeMode.datePickerFooterRangeModeToLabel}
                            />
                        )}
                        name="to"
                        control={control}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
