import React from 'react';

import {customerSupportUpdatePermission} from '@models/permissions/permissions';

import {withPermissionAndJurisdictionConfig} from 'src/features/app/permission/PermissionHoc';
import {BulkManageButton} from '../../block-label-group-bulk-actions';

type CustomerSupportManageLabelButtonInnerProps = {
    selectedIds: string[];
};

export function CustomerSupportManageLabelButtonInner({selectedIds}: CustomerSupportManageLabelButtonInnerProps) {
    return <BulkManageButton uids={selectedIds} />;
}

export const CustomerSupportManageLabelButton = withPermissionAndJurisdictionConfig(CustomerSupportManageLabelButtonInner, {
    ...customerSupportUpdatePermission,
});
