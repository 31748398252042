import Dexie, {Table} from 'dexie';

import {dbUserSettingsStorageConfig, IDbUserSettingsStorage, UserSettings} from '@user-settings';

export class DbContext extends Dexie implements IDbUserSettingsStorage {
    userSettings: Table<UserSettings, number>;

    constructor() {
        super('BO');
        this.version(1).stores({
            ...dbUserSettingsStorageConfig,
        });
    }
}

export function initDatabase() {
    return new DbContext();
}
