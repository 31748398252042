export enum ErrorCategory {
    Rest = 'REST',
    GraphQL = 'GraphQl',
    WebSocket = 'WebSocket',
    ReactComponent = 'React Component',
    ReduxMiddleware = 'Redux Middleware',
    Unhandled = 'Unhandled',
}

export class LoggedError extends Error {
    isLogged?: boolean;
    instanceName?: string;
}

export class ReduxError extends Error {
    actionType?: string;
    actionPayload?: unknown;
}

export interface CustomAttributes extends Record<string, string | number> {
    actionRequestUrl?: string;
    actionMethod?: string;
    actionElement?: string;
    reduxActionType?: string;
    reduxActionPayload?: string;
    pageQuery?: string;
    traceId?: string;
    time: number;
    severity: string;
    category: string;
    jurisdiction: string;
    appVersion: string;
    instanceName?: string;
}

export enum ErrorSeverity {
    Trace = 'Trace',
    Debug = 'Debug',
    Information = 'Information',
    Warning = 'Warning',
    Error = 'Error',
    Fatal = 'Fatal',
}

export type LogErrorActionPayload = {
    error: Error;
    category: ErrorCategory;
    func?: Function;
};
