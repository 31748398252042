import React from 'react';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';

import {Snackbar} from '@components/snackbar/Snackbar';
import {SnackbarIconList, SnackbarIconSuccess, SnackbarIconUpdate, SnackbarIconWarning} from '@components/snackbar/SnackbarIcon';
import {SnackbarStack} from '@components/snackbar/SnackbarStack';
import {RealtimeNotification} from '@redux/realtime';

import MessageSnackBar from '../../message-snack-bar/components/MessageSnackBar';
import {RealtimeUpdateNotificationObsolete} from '../../realtime-updates/components/RealtimeUpdateNotificationObsolete';
import {useNotifications} from '../hooks';
import {Notification, NotificationType} from '../types';

const localized = defineMessages({
    notificationUndoAction: {
        id: 'notificationUndoAction',
        defaultMessage: 'Undo',
    },
    notificationUpdateAction: {
        id: 'notificationUpdateAction',
        defaultMessage: 'Update',
    },
    notificationOkAction: {
        id: 'notificationOkAction',
        defaultMessage: 'Ok',
    },
});

const defaultIcons: Record<NotificationType, React.ReactNode> = {
    ErrorMessage: <SnackbarIconWarning />,
    SuccessMessage: <SnackbarIconSuccess />,
    Undo: <SnackbarIconList />,
    RealtimeUpdate: <SnackbarIconUpdate />,
};

const defaultActionsLabels: Record<NotificationType, MessageDescriptor> = {
    ErrorMessage: localized.notificationOkAction,
    SuccessMessage: localized.notificationOkAction,
    Undo: localized.notificationUndoAction,
    RealtimeUpdate: localized.notificationUpdateAction,
};

export const Notifications = () => {
    const {formatMessage} = useIntl();
    const {notifications, handleAction, handleHide} = useNotifications();

    function handleActionClick(notification: Notification) {
        if (notification.type !== 'Undo') {
            handleAction(notification.id);
        }

        handleHide(notification.id);
    }

    function handleCloseClick(notification: Notification) {
        if (notification.type === 'Undo') {
            handleAction(notification.id);
        }

        handleHide(notification.id);
    }

    return (
        <SnackbarStack>
            {notifications.map(notification => (
                <Snackbar
                    key={notification.id}
                    open={true}
                    autoHideDuration={notification.autoHideDuration}
                    message={notification.text}
                    messageIcon={defaultIcons[notification.type]}
                    action={formatMessage(defaultActionsLabels[notification.type])}
                    onClose={() => handleCloseClick(notification)}
                    onAction={() => handleActionClick(notification)}
                    showCountDown={notification.type === 'Undo'}
                ></Snackbar>
            ))}
            {/* TODO: [BO-2772] Remove MessageSnackBar, RealtimeUpdateNotificationObsolete, RealtimeNotification and use notifications feature instead*/}
            <MessageSnackBar />
            <RealtimeUpdateNotificationObsolete />
            <RealtimeNotification />
        </SnackbarStack>
    );
};
