import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {
    AgentPlayerReporting,
    AgentPlayerReportVersion,
    QueryGetAgentPlayerReportingsArgs,
    QueryGetAgentPlayerReportingsVnArgs,
} from '@models/generated/graphql';
import {
    AgentPlayerReportingQueryFields,
    AgentPlayerReportingServerFilterKeys,
    AgentPlayerReportingServerTextFilterKeys,
    AgentReportRegion,
    Filter,
} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {GqlRequest} from '@services/types';

import {GqlRequestBuilder, RequestPayload} from './entity/GqlRequestBuilder';

@injectable()
export class AgentPlayerReportingService extends EntityBaseGqlService<
    QueryGetAgentPlayerReportingsArgs,
    AgentPlayerReportingQueryFields,
    AgentPlayerReportingServerFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new AgentPlayerReportingRequestBuilder());
    }
}

export class AgentPlayerReportingRequestBuilder extends GqlRequestBuilder<
    QueryGetAgentPlayerReportingsArgs,
    AgentPlayerReportingQueryFields,
    AgentPlayerReportingServerFilterKeys
> {
    public buildGqlRequest(requestPayload: RequestPayload<AgentPlayerReportingQueryFields>): GqlRequest<QueryGetAgentPlayerReportingsArgs> {
        const filter = this.mapToFilterObject(requestPayload.filter);

        return !this.isFilterInvalid(filter)
            ? {
                  query: this.getQueryByRegion(requestPayload.fields, filter.region as AgentReportRegion),
                  variables: this.getGqlPageArgs(filter),
              }
            : null;
    }

    private getQueryByRegion(fields: AgentPlayerReportingQueryFields[], country: AgentReportRegion): DocumentNode {
        const queryCountryMapper: Record<AgentReportRegion, DocumentNode> = {
            vn: this.buildQueryVn(fields),
            kr: this.buildQueryKr(fields),
        };
        return queryCountryMapper[country] ?? this.buildQuery(fields);
    }

    public buildQuery = (fields: AgentPlayerReportingQueryFields[]): DocumentNode => gql`
        query GetAgentPlayerReportings($filter: AgentPlayerReportingFilter, $sort: Sorting, $start: Int, $end: Int) {
            getAgentPlayerReportings(filter: $filter, sort: $sort, end: $end, start: $start) {
                ${this.buildQueryFields(fields)}
            }
        }
    `;

    private buildQueryVn(fields: AgentPlayerReportingQueryFields[]): DocumentNode {
        return gql`
            query GetAgentPlayerReportingsVn($filter: AgentPlayerReportingFilterVn, $sort: Sorting, $start: Int, $end: Int) {
                getAgentPlayerReportingsVn(filter: $filter, sort: $sort, end: $end, start: $start) {
                    ${this.buildQueryFields(fields)}
                }
            }
        `;
    }

    private buildQueryKr(fields: AgentPlayerReportingQueryFields[]): DocumentNode {
        return gql`
            query GetAgentPlayerReportingsKr($filter: AgentPlayerReportingFilter, $sort: Sorting, $start: Int, $end: Int) {
                getAgentPlayerReportingsKr(filter: $filter, sort: $sort, end: $end, start: $start) {
                    ${this.buildQueryFields(fields)}
                }
            }
        `;
    }

    protected buildFilter(
        filter: Filter<AgentPlayerReportingServerFilterKeys>
    ): QueryGetAgentPlayerReportingsArgs | QueryGetAgentPlayerReportingsVnArgs {
        const referrerId: string =
            this.toGQLStringFilter(filter, 'referrerPlayerId') ?? this.toGQLStringFilter(filter, 'defaultReferrerPlayerId');
        const dateFilter = this.toGQLDateRange(filter['transactionStartedTs.from'], filter['transactionStartedTs.to']);
        if (filter.region === 'vn' && dateFilter?.to?.seconds) {
            dateFilter.to.seconds = dateFilter?.to?.seconds + 1;
        }

        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: AgentPlayerReportingServerTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string, this.transformTextMapper[key])
                    )
                ),
                transaction_started_ts: dateFilter,
                referrer: referrerId
                    ? {
                          referrer_player_id: referrerId,
                          is_downstream: this.toGQLBooleanFilter(filter, 'isDownstream'),
                          is_agent: true,
                      }
                    : undefined,
                report_version: this.toGQLStringFilter(filter, 'reportVersion') as AgentPlayerReportVersion,
            },
        };
    }

    private readonly filterFieldsMapper: Record<AgentPlayerReportingServerTextFilterKeys, string[]> = {
        uid_email: nameof.toArray<AgentPlayerReporting>(m => [m.uid, nameof.full(m.contact.email, 1)]),
        uid_em_un_rmc: nameof.toArray<AgentPlayerReporting>(m => [
            m.uid,
            nameof.full(m.contact.email, 1),
            m.username,
            nameof<AgentPlayerReporting>(m => m.register_marketing_code),
        ]),
        uid: nameof.toArray<AgentPlayerReporting>(m => [m.uid]),
        'contact.email': nameof.toArray<AgentPlayerReporting>(m => [nameof.full(m.contact.email, 1)]),
        username: nameof.toArray<AgentPlayerReporting>(m => [m.username]),
        register_marketing_code: [nameof<AgentPlayerReporting>(m => m.register_marketing_code)],
        countries: nameof.toArray<AgentPlayerReporting>(m => [m.iso_alpha2_country_code]),
    };

    private transformTextMapper: Partial<Record<AgentPlayerReportingServerTextFilterKeys, (value: string) => string>> = {
        'contact.email': this.ignoreCase,
        countries: value => this.phraseListSearch(value.replaceAll(',', ' '), ' '),
    };
}
