import {schema} from 'normalizr';

import {EntityType, nestedEntitiesJoinCharacter} from '@redux/entity';
import {schemaMapper} from '@redux/entity';

import {RealtimeMessageTrigger} from './types';

const getValueByKey = <T, TKey extends keyof T>(obj: T, key: TKey) => obj[key];

const isPrimitive = (item: any) => {
    return item !== Object(item);
};

const isNullOrUndefined = (item: unknown) => item === null || item === undefined;

export const equals = <TModel>(currentItem: TModel, newItem: TModel): boolean => {
    if (isNullOrUndefined(currentItem)) {
        return currentItem === newItem;
    }

    if (isPrimitive(currentItem) && isPrimitive(newItem)) {
        return currentItem === newItem;
    }

    return (
        currentItem === newItem ||
        Object.keys(currentItem).every(prop => {
            const key = prop as keyof TModel;
            const isKeyExist = newItem && Object.prototype.hasOwnProperty.call(newItem, key);

            const compareArrays = (item1: unknown, item2: unknown) => {
                const array1 = Array.from(item1 as Iterable<unknown>);
                const array2 = Array.from(item2 as Iterable<unknown>);

                return (
                    array1.length === array2.length &&
                    array1.every((_, index) => (array2.length > index ? equals(array1[index], array2[index]) : false))
                );
            };

            const compare = (item1: TModel, item2: TModel, key: keyof TModel): boolean => {
                const isObjectKey = typeof getValueByKey(item1, key) === 'object' && item1[key];
                const isArray = Array.isArray(item1[key]);
                return isObjectKey
                    ? isArray
                        ? compareArrays(item1[key], item2[key])
                        : equals(item1[key], item2[key])
                    : item1[key] === item2[key];
            };

            const isEqual = newItem && currentItem && compare(currentItem, newItem, key);
            return !isKeyExist || isEqual;
        })
    );
};

export function getFlattenedNestedFieldValuesNormalized(
    field: string,
    keys: string[],
    type: EntityType,
    items: Partial<Record<EntityType, Record<string, unknown>>>
): unknown[] {
    const splitted = field.split(nestedEntitiesJoinCharacter);
    const definition = schemaMapper.getSchemaDefinitions(type);

    const result = keys
        .map(key => {
            let value: any = items?.[type][key];
            let nestedSchema = definition;

            if (value) {
                for (let index = 0; index < splitted.length; index++) {
                    const fieldPart = splitted[index];
                    if (fieldPart?.length) {
                        nestedSchema = nestedSchema?.[fieldPart as keyof typeof definition];
                        const isArray = Array.isArray(nestedSchema);
                        const nestedEntityType = isArray
                            ? ((nestedSchema as schema.Entity[])?.[0]?.key as EntityType)
                            : ((nestedSchema as schema.Entity)?.key as EntityType);
                        const nestedValue = value?.[fieldPart];
                        if (nestedEntityType && nestedValue) {
                            const subField = splitted.slice(index + 1).join(nestedEntitiesJoinCharacter);
                            const subKeys = isArray ? nestedValue : [nestedValue];
                            value = getFlattenedNestedFieldValuesNormalized(subField, subKeys, nestedEntityType, items);
                            break;
                        } else {
                            value = nestedValue;
                        }
                    }
                }
            }
            return value;
        })
        .flatMap(a => a);

    return result;
}

export function getFlattenedNestedFieldValuesDenormalized(field: string, items: unknown[]): unknown[] {
    const splitted = field.split(nestedEntitiesJoinCharacter);

    const result = items
        ? items
              .map(item => {
                  let value: any = item;

                  if (value) {
                      for (let index = 0; index < splitted.length; index++) {
                          const fieldPart = splitted[index];
                          if (fieldPart?.length) {
                              const isArray = Array.isArray(value);
                              value = isArray ? value?.map((i: any) => i?.[fieldPart]) : value?.[fieldPart];
                          }
                      }
                  }
                  return value;
              })
              .flatMap(a => a)
        : [];

    return result;
}

export function getSubscriptionKey(entity: EntityType, trigger: RealtimeMessageTrigger, args?: unknown): string {
    return `${entity}_${trigger}${args ? `_${JSON.stringify(args)}` : ''}`;
}
