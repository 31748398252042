import React, {FunctionComponent} from 'react';
import {MessageDescriptor} from 'react-intl';
import {FormControl, FormLabel} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {MessageWithIcon} from '@components/alerts/MessageWithIcon';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {CustomTheme} from '@style';

export class StyledInputProps {
    label?: string | MessageDescriptor;
    labelVariant?: 'caption' | 'default';
    labelPlacement?: 'top' | 'left';
    className?: string;
    fullWidth?: boolean;
    hasBottomSpacing?: boolean;
    hint?: MessageDescriptor;
}

const useStyles = makeStyles()((theme: CustomTheme) => ({
    formRow: {
        backgroundColor: 'transparent',
        color: theme.custom.palette.content.bodyText,
        width: '100%',
    },
    formRowLabelCaption: {
        textTransform: 'uppercase',
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: 1,
        fontWeight: 500,
        padding: theme.spacing(0.5, 0),
    },
    formRowLabelDefault: {
        color: theme.custom.palette.content.headingText,
        padding: theme.spacing(1, 0),
    },
    formRowSpacingBottom: {
        marginBottom: theme.spacing(2),
    },
    formRowWithLeftLabel: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        alignItems: 'center',
        columnGap: theme.spacing(1),
        rowGap: theme.spacing(1),
    },
    formRowHint: {
        color: theme.palette.secondary.main,
        gridColumn: 2,
    },
}));

export function withStyledInputLabel<T>(WrappedComponent: FunctionComponent<T>) {
    return function FormRow({className, label, labelVariant = 'caption', labelPlacement = 'top', hint, ...props}: T & StyledInputProps) {
        const {classes, cx} = useStyles();

        return (
            <FormControl
                className={cx(
                    {
                        [classes.formRowWithLeftLabel]: labelPlacement === 'left',
                    },
                    classes.formRow,
                    className
                )}
            >
                {label ? (
                    <FormLabel
                        focused={false}
                        className={cx({
                            [classes.formRowLabelCaption]: labelVariant === 'caption',
                            [classes.formRowLabelDefault]: labelVariant === 'default',
                        })}
                    >
                        <LocalizedText label={label} />
                    </FormLabel>
                ) : null}

                <WrappedComponent {...(props as T)}></WrappedComponent>
                {hint ? <MessageWithIcon className={classes.formRowHint} icon={CustomIcon.WarningOutline} label={hint} /> : null}
            </FormControl>
        );
    };
}
