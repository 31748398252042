import React from 'react';

import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionAccessibleItem, PermissionEnum} from '@models/permissions';
import {agentReadPolicies} from '@models/permissions/permissions';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {SendMessageAction} from '../../player-actions/communication-actions/components/SendMessageAction';
import {Message} from '../../player-message/types';

type SendMessageCommunicationActionProps = {
    disabled?: boolean;
    uid: string;
};

export function SendMessageCommunicationActionInner({disabled, uid}: SendMessageCommunicationActionProps) {
    return uid ? <SendMessageAction disabled={disabled} field={nameof(Message)} id={uid} model={{uid}} /> : null;
}

const allowedPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Read, PermissionEnum.Create, PermissionEnum.Delete]},
    {
        moduleName: ModuleName.ActionCenter,
        submoduleName: SubmoduleName.CommunicationActions,
        permissions: [PermissionEnum.Read, PermissionEnum.Create, PermissionEnum.Delete],
    },
];

const restrictedPermissions = agentReadPolicies;

export const SendMessageCommunicationAction = withMultiplePermission(
    withMultiplePermission(SendMessageCommunicationActionInner, {allowedPermissions, indicator: LackOfPermissionIndicator.Disabled}),
    {restrictedPermissions}
);
