import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {AuthApiService} from '@services/deprecated';

import {ItemsPage} from 'src/common/types';
import {UserGridItem} from '../../pages/access-management/types';

export class SharedDataService {
    private readonly usersHttpService: AuthApiService;

    constructor(tracingService: ITracingService, userManager: UserManagerExtended) {
        this.usersHttpService = new AuthApiService('users', tracingService, userManager);
    }

    loadUsers(): Observable<UserGridItem[]> {
        return this.usersHttpService.getItemsPage().pipe(map(res => (res as ItemsPage<UserGridItem>)?.items));
    }
}
