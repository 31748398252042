import {useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuid} from 'uuid';

import {IAsyncAction} from '@redux/async-action';

import {asyncActions} from './actions';
import {asyncActionErrorMessageSelector, asyncActionErrorMessageValuesSelector, asyncActionStatusSelector} from './selectors';
import {AsyncActionStateExtended, RequestStatus} from './types';

export const useAsyncActionState = (
    action: IAsyncAction,
    shouldHaveActionId = false,
    defaultActionId?: string
): AsyncActionStateExtended => {
    const actionRequest = action?.request(null)?.type;
    const [actionId] = useState(defaultActionId ?? uuid());
    const actionUniqueId = shouldHaveActionId ? `${actionRequest}/${actionId}` : actionRequest;
    const status = useSelector(asyncActionStatusSelector(actionUniqueId));
    const errorMessage: MessageDescriptor = useSelector(asyncActionErrorMessageSelector(actionUniqueId));
    const errorMessageValues = useSelector(asyncActionErrorMessageValuesSelector(actionUniqueId));
    const dispatch = useDispatch();

    const isNone = status === RequestStatus.None;
    const isError = status === RequestStatus.Error;
    const isProgress = status === RequestStatus.Progress;

    const resetRequestStatus = () => {
        dispatch(
            asyncActions.setRequestStatus({
                requestAction: actionUniqueId,
                requestStatus: RequestStatus.None,
            })
        );
    };

    const resetErrorMessage = () => {
        dispatch(
            asyncActions.setErrorMessage({
                requestAction: actionUniqueId,
                errorMessage: null,
                errorMessageValues: null,
            })
        );
    };

    const resetAsyncActionState = () => {
        resetRequestStatus();
        resetErrorMessage();
    };

    return {
        actionId,
        requestStatus: status,
        isNone,
        isError,
        isProgress,
        errorMessage,
        errorMessageValues,
        resetErrorMessage,
        resetRequestStatus,
        resetAsyncActionState,
    };
};
