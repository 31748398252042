import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {AjaxResponse} from '@services/rest-api';

import {globalConfig} from '../../../configuration';
import {IModuleItem} from '../../../features/module-shared/types';
import {SearchFilter} from '../../types';

import {ApiService} from './apiService';

/**
 * @deprecated should use services from src/common/services
 */
export class ConfigApiService extends ApiService {
    constructor(endpoint: string, tracingService: ITracingService, userManager: UserManagerExtended) {
        super(`${globalConfig.getConfig().configApiUrl}/api/v1`, endpoint, tracingService, userManager);
    }

    addItem<TModel extends IModuleItem>(item: TModel): Observable<string> {
        return this.editItem(item);
    }

    editItem<TModel extends IModuleItem>(item: TModel): Observable<string> {
        return this.post('', item).pipe<string>(map<AjaxResponse, string>(() => ''));
    }

    deleteItem(id: string): Observable<AjaxResponse> {
        return this.delete(`/${id}`, {});
    }

    protected toSearchQuery(filter: SearchFilter): string {
        let result = '';

        if (filter) {
            const filters = filter.filter ? filter.filter.map(f => `${f.key}=${f.value}`).join('&') : '';

            result = filters;
        }

        return result;
    }

    protected getItemsTotalCount(_response: AjaxResponse): number {
        return 0;
    }
}
