import {GridCellParams} from '@mui/x-data-grid';

import {ChipsTypes} from '../../../../config/chip';
import {renderChip, renderDefaultChip} from '../../../chip/ChipRenderer';
import {ChipType, ChipVariant} from '../../../chip/types';

export type ChipCellValue = {
    value: ChipsTypes;
    type: string;
};

export const renderChipCell = (params: GridCellParams) => {
    const {value, type} = params.value as ChipCellValue;
    return renderChip(value, type);
};

export const renderDefaultChipCell = (params: GridCellParams) => {
    const value = params.value as string;
    return renderDefaultChip(value, ChipVariant.Grey, ChipType.Status);
};
