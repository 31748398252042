import React from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {FormTextInputDefaultProps} from '@components/input/FormInput';
import {CustomTheme} from '@style';

import {SelectOption} from '../../module-shared/types';
import {RuleCondition, RuleViewModel} from '../types';

import {RuleFormConditionProps} from './RuleFormConditionHoc';
import {RuleFormRemoveConditionButton} from './RuleFormRemoveConditionButton';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    ruleFormLogicOperationCondition: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    ruleFormLogicOperationConditionSeparator: {
        margin: `${theme.spacing(6.5)} ${theme.spacing()} 0 ${theme.spacing()}`,
        color: theme.custom.palette.status.white.light,
    },
    ruleFormLogicOperationConditionHideButton: {
        marginTop: theme.spacing(6),
    },
}));

const localized = defineMessages<LogicOperation>({
    or: {
        id: 'RuleFormLogicOperationCondition_or',
        defaultMessage: 'or',
    },
    and: {
        id: 'RuleFormLogicOperationCondition_and',
        defaultMessage: 'and',
    },
});

type LogicOperation = 'or' | 'and';

type RuleFormLogicOperationItemData = Omit<RuleFormConditionProps, 'onRemove'> &
    Pick<FormTextInputDefaultProps<unknown>, 'type'> & {
        component: React.FC<RuleFormConditionProps>;
        options?: SelectOption[];
    };

type RuleFormLogicOperationConditionProps = {
    primaryData: RuleFormLogicOperationItemData;
    secondaryData: RuleFormLogicOperationItemData;
    onRemove: (...keys: RuleCondition[]) => void;
    operation?: LogicOperation;
};

export function RuleFormLogicOperationCondition({
    primaryData,
    secondaryData,
    operation = 'or',
    onRemove,
}: RuleFormLogicOperationConditionProps) {
    const {component: PrimaryComponent, ...primaryProps} = primaryData;
    const {component: SecondaryComponent, ...secondaryProps} = secondaryData;
    const primaryKey = primaryData?.ruleKey;

    const {classes} = useStyles();
    const {formatMessage} = useIntl();
    const {setValue} = useFormContext<RuleViewModel>();
    const watch = useWatch({name: primaryData.ruleKey});

    function handleRemove() {
        setValue(primaryKey, undefined);
        setValue(secondaryData.ruleKey, undefined);

        onRemove(primaryKey);
    }

    return watch !== undefined ? (
        <div className={classes.ruleFormLogicOperationCondition}>
            <PrimaryComponent {...primaryProps} />
            <span className={classes.ruleFormLogicOperationConditionSeparator}>{formatMessage(localized[operation])}</span>
            <SecondaryComponent {...secondaryProps} alwaysVisible />
            <RuleFormRemoveConditionButton className={classes.ruleFormLogicOperationConditionHideButton} onRemove={handleRemove} />
        </div>
    ) : null;
}
