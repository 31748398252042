import {createReducer} from 'typesafe-actions';

import {loadJurisdictionConfigAction, setJurisdictionLocaleAction} from './actions';
import {JurisdictionConfiguration} from './types';

const jurisdictionConfigReducer = createReducer({} as JurisdictionConfiguration)
    .handleAction(loadJurisdictionConfigAction.success, (state, action) => ({
        ...action.payload,
        locale: state.locale ?? action.payload.locale,
    }))
    .handleAction(setJurisdictionLocaleAction, (state, action) => ({...state, locale: action.payload}));

export const appConfigReducer = jurisdictionConfigReducer;
