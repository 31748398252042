import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Chip} from '@mui/material';

import Button from '@components/button/Buttons';
import {Icon, IconColor} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {MarketingCodeDeleteInput} from '@models/generated/graphql';

import {marketingCodeActions} from '../../../features/marketing-code/actions';
import {MarketingCodeGridItem} from '../../../features/marketing-code/types';
import {itemsSelectedActions} from '../../../features/module-shared/actions';
import {useAsyncActionState} from '../../../features/shared/async-action/hooks';
import {selectedItemsSelector} from '../selectors';
import {domain} from '../types';

import {localizedMarketingBonusCodeDeleteAction} from './MarketingBonusCodeDeleteAction.localize';
import {useMarketingBonusCodeDeleteActionClasses} from './MarketingBonusCodeDeleteAction.styles';

const MarketingBonusCodeDeleteAction = () => {
    const {classes} = useMarketingBonusCodeDeleteActionClasses();
    const dispatch = useDispatch();
    const {isProgress} = useAsyncActionState(marketingCodeActions.deleteMarketingCodes);
    const itemsSelected = useSelector(selectedItemsSelector) as MarketingCodeGridItem[];
    const actions = itemsSelectedActions(domain);
    const totalItems = itemsSelected?.length;

    const deleteItems = () => {
        dispatch(
            marketingCodeActions.deleteMarketingCodes.request({
                codes: itemsSelected.map(({marketing_code, type}) => ({marketing_code, type} as MarketingCodeDeleteInput)),
            })
        );
        dispatch(actions.itemsSelected([]));
    };

    return (
        <Box className={classes.marketingBonusCodeDeleteActionContainer}>
            {totalItems ? (
                <Button
                    data-testid="marketingBonusCodeDeleteButton"
                    className={classes.marketingBonusCodeDeleteButton}
                    label={localizedMarketingBonusCodeDeleteAction.delete}
                    color="primary"
                    onClick={deleteItems}
                    startIcon={
                        <Icon
                            icon={CustomIcon.DeleteOutlineAndroid}
                            color={IconColor.PrimaryContrastText}
                            className={classes.marketingBonusCodeDeleteButtonIcon}
                        />
                    }
                    disabled={isProgress}
                >
                    {totalItems ? <Chip label={totalItems} size="small" className={classes.marketingBonusCodeDeleteButtonChip} /> : null}
                </Button>
            ) : null}
        </Box>
    );
};

export const renderMarketingBonusCodeDeleteAction = () => {
    return <MarketingBonusCodeDeleteAction />;
};
