import React, {FC} from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    bulkModalContent: {
        overflowY: 'unset',
        height: theme.custom.bulkModalDialogContentHeight,
        overflow: 'auto',
        overflowX: 'hidden',
        maxHeight: theme.custom.bulkModalDialogContentHeight,
        flexDirection: 'column',
    },
    bulkModalContentInfoMessage: {
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
    },
    bulkModalContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        gap: theme.spacing(2),
    },
}));

type BulkModalContentProps = {
    className?: string;
    subInfoMessage?: string;
    children?: React.ReactNode;
};

export const BulkModalContent: FC<BulkModalContentProps> = ({subInfoMessage, children, className}) => {
    const {classes, cx} = useStyles();
    return (
        <Box className={cx(classes.bulkModalContent, className)}>
            {subInfoMessage ? <Box className={classes.bulkModalContentInfoMessage}>{subInfoMessage}</Box> : null}
            <div className={classes.bulkModalContentContainer}>{children}</div>
        </Box>
    );
};
