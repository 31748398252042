import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {Dropdown} from '@components/dropdown/Dropdown';
import {DropdownProps} from '@components/dropdown/DropdownHoc';
import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    editorDropdownButtonForm: {
        padding: theme.spacing(0.5),
        maxWidth: theme.custom.editorToolbarDropdownMaxWidth,
        minWidth: theme.custom.editorToolbarDropdownMinWidth,
        maxHeight: theme.custom.editorToolbarDropdownMaxHeight,
    },
    ediotrDropdownButtonDropdownActions: {
        '& .MuiButtonBase-root': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.background.default,
            '&:disabled': {
                color: theme.palette.text.disabled,
                backgroundColor: theme.palette.background.default,
            },
            '&:hover': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '&:focus': {
                borderColor: theme.palette.background.default,
                color: theme.palette.text.primary,
            },
        },
    },
}));

type EditorDropdownButtonProps = Pick<
    DropdownProps,
    | 'children'
    | 'buttonContent'
    | 'onDropdownOpen'
    | 'onDropdownClose'
    | 'applyAction'
    | 'onApplyClick'
    | 'cancelAction'
    | 'onCancelClick'
    | 'closeOnClickInside'
    | 'isApplyDisabled'
> & {};

export function EditorDropdownButton({children, closeOnClickInside = false, ...props}: EditorDropdownButtonProps) {
    const {classes} = useClasses();
    return (
        <Dropdown
            styles={{dropdownActions: classes.ediotrDropdownButtonDropdownActions}}
            {...props}
            variant="contained"
            size="small"
            closeOnClickInside={closeOnClickInside}
            reverseActionsOrder
        >
            <div className={classes.editorDropdownButtonForm}>{children}</div>
        </Dropdown>
    );
}
