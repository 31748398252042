import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {useService} from '@inversify';
import {EntityType, LabelGroupFilterKeys, LabelGroupQueryFields} from '@redux/entity';
import {useViewInit} from '@redux/view';

import {BulkActionKey, bulkActionsActions, IBulkApplyStrategy} from '../block-bulk-actions';
import {LabelGroup, labelGroupColors, UserLabelsForm} from '../block-label-group-list/types';
import {UserProfileGridItem} from '../data-grids/types';

import {ApplyAddUserLabelRequest, ApplyRemoveUserLabelRequest, ApplyRemoveUserLabelStrategy} from './services/applyStrategy';
import {userLabelIdsSelector} from './selectors';
import {BulkPlayerLabel, UserLabel} from './types';

const localized = defineMessages({
    bulkPlayerLabelsDisplayName: {
        id: 'bulkPlayerLabelsDisplayName',
        defaultMessage: 'Player Labels',
    },
});

function countUsersWithLabel(players: UserProfileGridItem[], labelId: string): number {
    return players.filter(p => p?.labels?.find(userLabel => userLabel?.id === labelId))?.length ?? 0;
}

function useExistingLabels(players: UserProfileGridItem[]): BulkPlayerLabel[] {
    const addedLabelIds = useSelector<RootState, string[]>((state: RootState) => userLabelIdsSelector(state, BulkActionKey.AddUserLabel));
    const removedLabelIds = useSelector<RootState, string[]>((state: RootState) =>
        userLabelIdsSelector(state, BulkActionKey.RemoveUserLabel)
    );

    const existingLabels: Record<string, BulkPlayerLabel> = players.reduce((bulkPlayerLabels: Record<string, BulkPlayerLabel>, player) => {
        player?.labels?.forEach(label => {
            if (!bulkPlayerLabels[label.id]) {
                bulkPlayerLabels[label.id] = {
                    ...label,
                    count: addedLabelIds?.includes(label.id) ? players.length : countUsersWithLabel(players, label.id),
                    isCrossedOut: removedLabelIds.includes(label.id),
                };
            }
        });
        return bulkPlayerLabels;
    }, {});

    return existingLabels ? Object.values(existingLabels) : [];
}

function useNewLabels(players: UserProfileGridItem[]): BulkPlayerLabel[] {
    const addedLabelIds = useSelector<RootState, string[]>((state: RootState) => userLabelIdsSelector(state, BulkActionKey.AddUserLabel));
    const {items: labelGroups} = useViewInit<LabelGroup, LabelGroupFilterKeys, LabelGroupQueryFields>({
        displayName: localized.bulkPlayerLabelsDisplayName,
        viewType: 'BulkPlayerLabels',
        entity: {
            entity: EntityType.LabelGroup,
            fields: ['id', 'color', 'labels.id', 'labels.name'],
        },
        defaultPaging: {page: 1, pageSize: labelGroupColors.length},
        validateFilter: () => true,
    });

    const playerLabels: UserLabel[] = players?.flatMap(p => p.labels)?.filter(l => l) ?? [];
    const newLabelsIds = addedLabelIds?.filter(id => !playerLabels.some(l => l.id === id)) ?? [];
    const newLabels = labelGroups
        ?.flatMap(group => group?.labels?.map<BulkPlayerLabel>(l => ({...l, group, count: players?.length})))
        ?.filter(l => l && newLabelsIds.includes(l.id));

    return newLabels ?? [];
}

type UseBulkPlayerLabels = {
    bulkLabels: BulkPlayerLabel[];
};

export function useBulkPlayerLabels(players: UserProfileGridItem[]): UseBulkPlayerLabels {
    const existingLabels: BulkPlayerLabel[] = useExistingLabels(players);
    const newLabels: BulkPlayerLabel[] = useNewLabels(players);
    const bulkLabels: BulkPlayerLabel[] = [...existingLabels, ...newLabels];

    return {bulkLabels};
}

type UseBulkPlayerLabelUnassign = {
    handleDelete: (labelId: string, labelName: string) => void;
};

export function useBulkPlayerLabelsUnassign(players: UserProfileGridItem[]): UseBulkPlayerLabelUnassign {
    const dispatch = useDispatch();
    const strategy = useService<ApplyRemoveUserLabelStrategy>('ApplyRemoveUserLabelStrategy');
    const addedLabelIds = useSelector<RootState, string[]>((state: RootState) => userLabelIdsSelector(state, BulkActionKey.AddUserLabel));

    const handleDelete = (labelId: string, labelName: string) => {
        if (addedLabelIds.includes(labelId)) {
            dispatch(bulkActionsActions.removeBulkActionsWithKey(labelId));
        }
        dispatch(
            bulkActionsActions.apply({request: {label: {id: labelId, name: labelName}, players} as ApplyRemoveUserLabelRequest, strategy})
        );
    };

    return {handleDelete};
}

export function useBulkPlayerLabelAssign(players: UserProfileGridItem[]) {
    const dispatch = useDispatch();
    const addStrategy = useService<IBulkApplyStrategy<ApplyAddUserLabelRequest>>('ApplyAddUserLabelStrategy');

    function handleSubmit(data: UserLabelsForm) {
        const addLabels = data.groups.flatMap(g => g?.labels)?.filter(l => l && l?.selected && !l?.disabled);
        if (addLabels.length > 0) {
            dispatch(bulkActionsActions.apply({request: {players, labels: addLabels} as ApplyAddUserLabelRequest, strategy: addStrategy}));
        }
    }

    return {
        handleSubmit,
    };
}
