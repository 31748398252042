import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {TransactionFilterKeys} from '@redux/entity';

import {JurisdictionSelectOption} from 'src/features/module-shared/types';
import FilterTextWithDropdown from 'src/features/shared/filter/components/FilterTextWithDropdown';
import {MultipleKeysFilter} from 'src/features/shared/filter/types';

import {TransactionFilterProps} from './TransactionFilters';

const localized = defineMessages({
    textFullFilter: {
        id: 'TransactionFilterText_textFullFilter',
        defaultMessage: 'All',
    },
    userIdFilter: {
        id: 'TransactionFilterText_userIdFilter',
        defaultMessage: 'User Id',
    },
    usernameFilter: {
        id: 'TransactionFilterText_usernameFilter',
        defaultMessage: 'Username',
    },
    withdrawalIdFilter: {
        id: 'TransactionFilterText_withdrawalIdFilter',
        defaultMessage: 'Withdrawal Id',
    },
    emailFilter: {
        id: 'TransactionFilterText_emailFilter',
        defaultMessage: 'Email',
    },
    labelFilter: {
        id: 'TransactionFilterText_labelFilter',
        defaultMessage: 'Label',
    },
    marketingCodeFilter: {
        id: 'TransactionFilterText_marketingCodeFilter',
        defaultMessage: 'Registration Bonus Code',
    },
    textFullFilterPlaceholder: {
        id: 'TransactionFilterText_textFullPlaceholder',
        defaultMessage: 'For example, 123',
    },
    withdrawalTextFullFilterPlaceholder: {
        id: 'TransactionFilterText_textFullFilterPlaceholder',
        defaultMessage: 'Search in Withdrawal Processing Workspace',
    },
    userIdFilterPlaceholder: {
        id: 'TransactionFilterText_userIdFilterPlaceholder',
        defaultMessage: '123',
    },
    usernameFilterPlaceholder: {
        id: 'TransactionFilterText_usernameFilterPlaceholder',
        defaultMessage: 'For example, Antonio',
    },
    withdrawalIdFilterPlaceholder: {
        id: 'TransactionFilterText_withdrawalIdFilterPlaceholder',
        defaultMessage: 'bbe7e0d8-8630-443e-b69a-7100c9dfb8b5',
    },
    emailFilterPlaceholder: {
        id: 'TransactionFilterText_emailFilterPlaceholder',
        defaultMessage: 'For example, antonio@xyz.com',
    },
    marketingCodeFilterPlaceholder: {
        id: 'TransactionFilterText_marketingCodeFilterPlaceholder',
        defaultMessage: 'REG_00001',
    },
    labelFilterPlaceholder: {
        id: 'TransactionFilterText_labelFilterPlaceholder',
        defaultMessage: '123',
    },
});

function withTransactionFilterText(WrappedComponent: typeof FilterTextWithDropdown, filterKeys: TransactionFilterKeys[]) {
    return function TransactionFilterText({filter, onFilterChange}: TransactionFilterProps) {
        const textSearchOptions: JurisdictionSelectOption[] = [
            {label: localized.textFullFilter, value: 'uid_em_un_rmc'},
            {label: localized.textFullFilter, value: 'uid_un_tid_em'},
            {label: localized.userIdFilter, value: 'uid'},
            {label: localized.usernameFilter, value: 'username'},
            {label: localized.withdrawalIdFilter, value: 'withdrawalId'},
            {
                label: localized.emailFilter,
                value: 'email',
            },
            {label: localized.marketingCodeFilter, value: 'register_marketing_code'},
            {label: localized.labelFilter, value: 'labelsText'},
        ].filter(o => filterKeys.includes(o.value as TransactionFilterKeys));

        const getFilterLabel = (key: string): MessageDescriptor => {
            return {
                ['uid_em_un_rmc']: localized.textFullFilterPlaceholder,
                ['uid_un_tid_em']: localized.withdrawalTextFullFilterPlaceholder,
                ['uid']: localized.userIdFilterPlaceholder,
                ['username']: localized.usernameFilterPlaceholder,
                ['withdrawalId']: localized.withdrawalIdFilterPlaceholder,
                ['email']: localized.emailFilterPlaceholder,
                ['register_marketing_code']: localized.marketingCodeFilterPlaceholder,
                ['labelsText']: localized.labelFilterPlaceholder,
            }[key];
        };

        return (
            <WrappedComponent
                onFilterChange={onFilterChange}
                domain={null}
                filter={
                    {
                        keys: filterKeys,
                        options: textSearchOptions,
                        getLabel: getFilterLabel,
                    } as MultipleKeysFilter
                }
                filterString={filter}
            />
        );
    };
}

export const UidUsernameTransactionIdEmailFilterText = withTransactionFilterText(FilterTextWithDropdown, [
    'uid_un_tid_em',
    'uid',
    'username',
    'transactionId',
    'email',
]);

export const UidEmailUsernameMarketingCodeFilterText = withTransactionFilterText(FilterTextWithDropdown, [
    'uid_em_un_rmc',
    'uid',
    'email',
    'register_marketing_code',
    'username',
]);
