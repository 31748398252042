import {BaseFilterKeys} from './base';

export enum ManagedGameType {
    PLO5 = 'PLO5',
}

export const gameDateRangeFilterPrefix = 'startedAt' as const;

export type GameTextFilterKeys = 'uid_ref_room_guuid' | 'playerUid' | 'roomId' | 'gameUuid';

export type GameFilterKeys =
    | `${typeof gameDateRangeFilterPrefix}.from`
    | `${typeof gameDateRangeFilterPrefix}.to`
    | 'gameMode'
    | 'gameName'
    | GameTextFilterKeys
    | BaseFilterKeys;

export type GameQueryFields =
    | 'game_uuid'
    | 'started_at.seconds'
    | 'room_id'
    | 'game_id'
    | 'game_mode'
    | 'game_name'
    | 'blind'
    | 'ante'
    | 'currency'
    | 'players.uid'
    | 'players.referral_id'
    | 'rounds.pre_flop'
    | 'rounds.flop'
    | 'rounds.turn'
    | 'rounds.river'
    | 'game_settlement.uid'
    | 'game_settlement.platform'
    | 'game_settlement.total_win'
    | 'game_settlement.insurance_bet'
    | 'game_settlement.insurance_winning'
    | 'game_settlement.jackpot_winning';
