import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import {EmptyLabel} from '@components/label';

const localized = defineMessages({
    winningPlayer: {
        id: 'PlayerStatusCell_winningPlayer',
        defaultMessage: 'Winning Player',
    },
    losingPlayer: {
        id: 'PlayerStatusCell_losingPlayer',
        defaultMessage: 'Losing Player',
    },
});

type PlayerStatusCellValueProps = {
    currentBalance: number;
    depositTotal: number;
    withdrawalTotal: number;
    p2pDebitTotal: number;
    p2pCreditTotal: number;
};

export function PlayerStatusCellValue({
    currentBalance,
    depositTotal,
    withdrawalTotal,
    p2pDebitTotal,
    p2pCreditTotal,
}: PlayerStatusCellValueProps) {
    const {formatMessage} = useIntl();
    let result: React.ReactNode = <EmptyLabel defaultSymbol="dash" />;
    const income = (currentBalance ?? 0) + (withdrawalTotal ?? 0) + (p2pDebitTotal ?? 0);
    const expense = (depositTotal ?? 0) + (p2pCreditTotal ?? 0);
    if (income > expense) {
        result = (
            <Chip
                fillWidth={false}
                chipType={ChipType.Status}
                chipVariant={ChipVariant.Green}
                label={formatMessage(localized.winningPlayer)}
            />
        );
    } else if (income < expense) {
        result = (
            <Chip
                fillWidth={false}
                chipType={ChipType.Status}
                chipVariant={ChipVariant.Purple}
                label={formatMessage(localized.losingPlayer)}
            />
        );
    }
    return result;
}
