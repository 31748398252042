import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {FilterGroupNew, FilterGroupNewFilter} from '@components/filter/FilterGroupNew';
import {TextInputWithDropdownFilter} from '@components/filter/TextFilterWithDropdown';
import {FilterProps, FiltersProps, MultiKeyFilterTextValue} from '@components/filter/types';
import {sharedLocalization} from '@localization';

import {BoUserFilterModel, BoUserFilterName, BoUserTextFilterKeys} from '../types';

const localized = defineMessages({
    textFullFilterPlaceholder: {
        id: 'BoUserFilter_textFullFilterPlaceholder',
        defaultMessage: 'Search by email, first name and last name',
    },
});

type BoUserFiltersProps = FiltersProps<BoUserFilterModel, BoUserFilterName>;

export function BoUserFilters({model, onChange, availableFilters}: BoUserFiltersProps) {
    const placeholderMapper: Record<BoUserTextFilterKeys, MessageDescriptor> = {
        em_fn_ln: localized.textFullFilterPlaceholder,
        email: sharedLocalization.emailFilterPlaceholder,
        firstName: sharedLocalization.usernameFilterPlaceholder,
        lastName: sharedLocalization.usernameFilterPlaceholder,
        agentPlayerId: sharedLocalization.registrationCodeFilterPlaceholder,
    };

    const filters: FilterGroupNewFilter<BoUserFilterModel, BoUserFilterName>[] = [
        {
            filterName: 'em_fn_ln_apId',
            modelField: 'text',
            component: (props: FilterProps<MultiKeyFilterTextValue<BoUserTextFilterKeys>>) => (
                <TextInputWithDropdownFilter
                    options={[
                        {label: sharedLocalization.textFullFilter, value: 'em_fn_ln'},
                        {label: sharedLocalization.emailFilter, value: 'email'},
                        {label: sharedLocalization.firstName, value: 'firstName'},
                        {label: sharedLocalization.lastName, value: 'lastName'},
                        {label: sharedLocalization.agentPlayerId, value: 'agentPlayerId'},
                    ]}
                    inputPlaceholder={props?.value?.option ? placeholderMapper[props?.value?.option] : localized.textFullFilterPlaceholder}
                    width="full"
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
    ];

    return (
        <FilterGroupNew
            model={model}
            onChange={onChange}
            mode="update-on-change"
            allFilters={filters}
            availableFilters={availableFilters}
        />
    );
}
