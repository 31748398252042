import {piiActions} from '../actions';

import {localized} from './UserProfileTaxId.localize';
import {withUserProfileTextField} from './UserProfileTextField';

export const UserProfileTaxId = withUserProfileTextField({
    action: piiActions.editTaxId,
    placeholder: localized.piiTaxIdFieldName,
    submitModelMapper: m => ({
        fieldValue: m?.user?.region_player_info?.tax_id,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            region_player_info: {
                tax_id: m?.fieldValue,
            },
        },
        userId: m?.userId,
    }),
    //TODO: [IGP-2179] Added because of the backend partial update logic. Rework after backend change
    isRequired: true,
});
