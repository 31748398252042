import React, {FC} from 'react';
import {makeStyles} from 'tss-react/mui';

import {CurrencyCell} from '@components/data-grid/mui';
import {DateCell} from '@components/data-grid/mui/cells/DateCell';
import {withEmptyCheck} from '@components/label';
import {Timestamp} from '@models/shared';

export type CurrencyWithDateCellProps = {
    value: {
        amount: number;
        currency: string;
        date: Timestamp;
    };
};

const useClasses = makeStyles()(theme => ({
    currencyWithDateCellDate: {
        marginLeft: theme.spacing(1),
        color: theme.palette.text.secondary,
        width: 'max-content',
    },
}));

function CurrencyWithDateCellInner({value}: CurrencyWithDateCellProps) {
    const {classes} = useClasses();
    return (
        <>
            <CurrencyCell value={value.amount} currency={value.currency} />
            <div className={classes.currencyWithDateCellDate}>
                <DateCell value={value.date} />
            </div>
        </>
    );
}

export const CurrencyWithDateCell: FC<CurrencyWithDateCellProps> = withEmptyCheck(CurrencyWithDateCellInner);
