import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {UserAccountStatus} from '@models/generated/graphql';

import {localizedUserAccountStatus} from '../../app/intl/shared-resources/userAccountStatus';

export type AccountClosedHistoryValue = {
    account_status: UserAccountStatus;
};

export const useAccountClosedFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: AccountClosedHistoryValue = JSON.parse(json);

    return value?.account_status ? [{key: formatMessage(localizedUserAccountStatus[value.account_status])}] : null;
};
