import React from 'react';

import {AccountVerificationFilterKeys} from '@redux/entity';

import LabelFilterAdapter from '../../block-label-group-list/components/LabelFilterAdapter';

import {AccountVerificationsFilterProps} from './AccountVerificationsFilters';

function AccountVerificationLabelFilter({filter, onFilterChange}: AccountVerificationsFilterProps) {
    const filterKey: AccountVerificationFilterKeys = 'labels';
    return <LabelFilterAdapter domain={null} filter={{key: filterKey}} filterString={filter} onFilterChange={onFilterChange} />;
}

export default AccountVerificationLabelFilter;
