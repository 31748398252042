import React from 'react';

import {TransactionFilterKeys} from '@redux/entity';

import LabelFilterAdapter from '../../block-label-group-list/components/LabelFilterAdapter';

import {TransactionFilterProps} from './TransactionFilters';

function TransactionLabelFilter({filter, onFilterChange}: TransactionFilterProps) {
    const filterKey: TransactionFilterKeys = 'labels';
    return <LabelFilterAdapter domain={null} filter={{key: filterKey}} filterString={filter} onFilterChange={onFilterChange} />;
}

export default TransactionLabelFilter;
