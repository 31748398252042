import {defineMessages} from 'react-intl';

import {GameCurrency} from '@models/game-template';

export const localizedGameCurrency = defineMessages({
    [GameCurrency.CNY]: {
        id: 'gameCurrencyCNY',
        defaultMessage: 'CNY',
    },
    [GameCurrency.USD]: {
        id: 'gameCurrencyUSD',
        defaultMessage: 'USD',
    },
});
