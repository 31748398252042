import React, {FC} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {LocalizedText} from '@components/i18n';
import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => {
    return {
        wrappedComponentLabel: {
            ...(theme.typography.subtitle2 as CSSObject),
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(1),
            display: 'flex',
        },
    };
});

type LabelHoc = {
    label: string | MessageDescriptor;
};

export const withLabel =
    <TProps extends LabelHoc>(WrappedComponent: FC<TProps>) =>
    (props: TProps) => {
        const {classes} = useClasses();
        const {label} = props;

        return (
            <Box>
                <Box component="span" className={classes.wrappedComponentLabel}>
                    <LocalizedText label={label} />
                </Box>
                <WrappedComponent {...props} />
            </Box>
        );
    };
