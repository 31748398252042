import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {
    EntityFetchRequestPayload,
    EntityFetchServiceResponsePayload,
    GameTemplate,
    GameTemplateFilterKeys,
    ManagedGameType,
} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {BaseGameManagementRequestBuilder} from '@services/gameRoomService';
import {getValueFromQueryFilter} from '@utils/query';

import {ItemsPage} from '../types';

import {
    CreateGameTemplatePayload,
    CreateTemplateServiceResponsePayload,
    DisableGameTemplatePayload,
    DisableTemplateServiceResponsePayload,
    EditGameTemplatePayload,
    EditGameTemplateResponsePayload,
    EnableGameTemplatePayload,
    EnableTemplateServiceResponsePayload,
    GetGameTemplatePayload,
    GetGameTemplatesPayload,
    IGameManagementApiService,
} from './rest-api/plo5GameManagementApiService';
import {ServerResponseStatus, ServiceResponsePayload} from './types';

@injectable()
export class GameTemplateService implements IEntityReadService {
    private readonly _apiServiceFactory: (gameType: ManagedGameType) => IGameManagementApiService;
    private readonly _builder: GameTemplateRequestBuilder;

    constructor(
        @inject(ServiceTypes.GameManagementApiServiceFactory) apiServiceFactory: (gameType: ManagedGameType) => IGameManagementApiService
    ) {
        this._apiServiceFactory = apiServiceFactory;
        this._builder = new GameTemplateRequestBuilder();
    }

    public get(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        const id: string = getValueFromQueryFilter<GameTemplateFilterKeys, string>(requestPayload.filter, 'id');
        const invalid = getValueFromQueryFilter<GameTemplateFilterKeys, boolean>(requestPayload.filter, 'invalid');
        return !(<boolean>invalid)
            ? id
                ? this.getGameTemplate(id, requestPayload)
                : this.getGameTemplates(requestPayload)
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {items: [], total: 0},
              });
    }

    public getGameTemplates(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        const gameType: ManagedGameType = getValueFromQueryFilter<GameTemplateFilterKeys, ManagedGameType>(
            requestPayload.filter,
            'gameType'
        );
        const apiService: IGameManagementApiService = this._apiServiceFactory(gameType);
        const payload = this._builder.getGameTemplatesPayload(requestPayload.filter);
        return apiService && payload
            ? apiService.getGameTemplates(payload).pipe(
                  map((r: ServiceResponsePayload<GetGameTemplatesPayload, ItemsPage<GameTemplate>>) => ({
                      ...r,
                      requestPayload,
                      responsePayload: {items: r.responsePayload?.items, total: r.responsePayload?.total ?? 0},
                  }))
              )
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {items: [], total: 0},
              });
    }

    public getGameTemplate(id: string, requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        const gameType: ManagedGameType = getValueFromQueryFilter<GameTemplateFilterKeys, ManagedGameType>(
            requestPayload.filter,
            'gameType'
        );
        const apiService: IGameManagementApiService = this._apiServiceFactory(gameType);
        const payload = this._builder.getGameTemplatePayload(id, gameType);
        return apiService && payload
            ? apiService.getGameTemplateExtended(payload).pipe(
                  map((r: ServiceResponsePayload<GetGameTemplatePayload, GameTemplate>) => {
                      return {
                          ...r,
                          requestPayload,
                          responsePayload: {items: [r.responsePayload], total: r.responsePayload ? 1 : 0},
                      };
                  })
              )
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {items: [], total: 0},
              });
    }

    public createGameTemplate(requestPayload: CreateGameTemplatePayload): Observable<CreateTemplateServiceResponsePayload> {
        const apiService: IGameManagementApiService = this._apiServiceFactory(requestPayload.gameType);
        return apiService?.createGameTemplate(requestPayload);
    }

    public editGameTemplate(
        requestPayload: EditGameTemplatePayload
    ): Observable<ServiceResponsePayload<EditGameTemplatePayload, EditGameTemplateResponsePayload>> {
        const apiService: IGameManagementApiService = this._apiServiceFactory(requestPayload.gameType);
        return apiService?.editGameTemplateWithCloseRooms(requestPayload);
    }

    public enableGameTemplates(requestPayload: EnableGameTemplatePayload): Observable<EnableTemplateServiceResponsePayload> {
        const apiService: IGameManagementApiService = this._apiServiceFactory(requestPayload.gameType);
        return apiService?.enableGameTemplate(requestPayload);
    }

    public disableGameTemplates(requestPayload: DisableGameTemplatePayload): Observable<DisableTemplateServiceResponsePayload> {
        const apiService: IGameManagementApiService = this._apiServiceFactory(requestPayload.gameType);
        return apiService?.disableGameTemplatesWithCloseRooms(requestPayload);
    }
}

export class GameTemplateRequestBuilder extends BaseGameManagementRequestBuilder {
    public getGameTemplatePayload(id: string, gameType: ManagedGameType): GetGameTemplatePayload {
        return {id: this.toNumber(id), gameType};
    }

    public getGameTemplatesPayload(filter: string): GetGameTemplatesPayload {
        const gameType: ManagedGameType = getValueFromQueryFilter<GameTemplateFilterKeys, ManagedGameType>(filter, 'gameType');
        return {gameType, ...this.buildPaging(filter)};
    }

    protected buildPaging(filter: string): Pick<GetGameTemplatesPayload, 'page' | 'size'> {
        const pageFilter = getValueFromQueryFilter<GameTemplateFilterKeys, string>(filter, 'page');
        const sizeFilter = getValueFromQueryFilter<GameTemplateFilterKeys, string>(filter, 'size');
        const page = this.toNumber(pageFilter);
        const size = this.toNumber(sizeFilter);
        const defaultPaging = {page: 1, size: 10};
        return page && size
            ? {
                  page: page && page > 0 ? page : defaultPaging.page,
                  size: size && size > 0 ? size : defaultPaging.size,
              }
            : defaultPaging;
    }
}
