import React from 'react';
import {useDispatch} from 'react-redux';
import {GridCellParams} from '@mui/x-data-grid';

import {ToggleSwitch} from '@components/toggle';
import {gameTemplateUpdatePermissions} from '@models/permissions/permissions';
import {ManagedGameType} from '@redux/entity';

import {useMultiplePermissions} from '../../app/permission/PermissionHoc';
import {useAsyncActionState} from '../../shared/async-action/hooks';
import {gameTemplateActions} from '../actions';

export type TemplateEnableToggleCellProps = {
    value: boolean;
    templateId: number;
    gameType: ManagedGameType;
};

export function TemplateEnableToggleCell({value, templateId, gameType}: TemplateEnableToggleCellProps) {
    const dispatch = useDispatch();
    const {isProgress: enableActionInProgress} = useAsyncActionState(gameTemplateActions.enableTemplate);
    const {isProgress: disableActionInProgress} = useAsyncActionState(gameTemplateActions.disableTemplate);
    const hasPermissions = useMultiplePermissions({allowedPermissions: gameTemplateUpdatePermissions});

    const handleClick = (value: boolean) => {
        const action = value
            ? gameTemplateActions.enableTemplate.request({enableTemplateIds: [templateId], gameType})
            : gameTemplateActions.disableTemplate.request({disableTemplateIds: [templateId], gameType});

        dispatch(action);
    };

    return (
        <ToggleSwitch
            value={value}
            onChange={handleClick}
            disabled={!hasPermissions || enableActionInProgress || disableActionInProgress}
        />
    );
}

export function renderTemplateEnableToggleCell(params: GridCellParams) {
    const props: TemplateEnableToggleCellProps = params.value as TemplateEnableToggleCellProps;
    return <TemplateEnableToggleCell {...props} />;
}
