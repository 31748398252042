import {defineMessages} from 'react-intl';

import {SecurityPunishmentStatus} from '@models/generated/graphql';

export const localizedSecurityPunishmentStatus = defineMessages({
    [SecurityPunishmentStatus.Nothing]: {
        id: 'securityPunishmentStatusNothing',
        defaultMessage: 'Nothing',
    },
    [SecurityPunishmentStatus.BanAccount]: {
        id: 'securityPunishmentStatusBanAccount',
        defaultMessage: 'Ban Account',
    },
    [SecurityPunishmentStatus.ConfiscateBalance]: {
        id: 'securityPunishmentStatusConfiscateBalance',
        defaultMessage: 'Confiscate Balance',
    },
});
