import {makeStyles} from 'tss-react/mui';

//TODO: [BO-2668] Move to src/common/components/dropdown (rename or merge?)
//TODO: [BO-2669] Move dropdown components to input folder (?)
export const useMenuButtonSelectClasses = makeStyles()(() => ({
    menuButtonSelectContainer: {
        position: 'relative',
        overflow: 'visible !important',
        padding: '0 !important',
    },
}));
