import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {getColumnConfigurationReducer, getContentModuleStateReducer, getContentSingleItemReducer} from '../module-shared/reducers';
import {createRealtimeStateReducer} from '../realtime-updates/reducers';

import {domain} from './types';

export const beBonusReducer = combineReducers({
    domain: createReducer(domain),
    data: combineReducers(getContentSingleItemReducer(domain)),
    state: combineReducers({
        ...getColumnConfigurationReducer(domain),
        ...getContentModuleStateReducer(domain),
        ...createRealtimeStateReducer(domain),
    }),
});
