import React, {createContext, useContext} from 'react';

import type {DynamicConfig} from './globalConfig';

const ConfigurationContext = createContext<DynamicConfig>(null);

type ConfigurationProviderProps = {
    children: React.ReactNode;
    config: DynamicConfig;
};

export function ConfigurationProvider({children, config}: ConfigurationProviderProps) {
    return <ConfigurationContext.Provider value={config}>{children}</ConfigurationContext.Provider>;
}

export function useConfig(): DynamicConfig {
    return useContext(ConfigurationContext);
}
