import {useMemo} from 'react';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {IModuleGridItem} from '@components/data-grid';
import {ServiceTypesKeys} from '@inversify';
import {useService} from '@inversify/hooks';
import {EntityType} from '@redux/entity';

import {bulkActionsActions, LoadItemsPayload} from './actions';
import {
    accountLockResultValueSelector,
    actionsDataSelector,
    addUserLabelResultValueSelector,
    casinoLockResultValueSelector,
    depositLockResultValueSelector,
    itemsSelector,
    lobbyLockResultValueSelector,
    manualTransactionResultValueSelector,
    manualTriggerDepositCashMatchResultValueSelector,
    manualTriggerImmediateCashBonusResultValueSelector,
    manualTriggerMTTTicketResultValueSelector,
    messageResultValueSelector,
    notesAttachmentsResultValueSelector,
    p2pTransferLockResultValueSelector,
    p2pTransferResultValueSelector,
    removeUserLabelResultValueSelector,
    securityCaseAddResultValueSelector,
    securityCaseAllResultValueSelector,
    securityCaseRemoveResultValueSelector,
    securityCaseUpdateResultValueSelector,
    simpleResultValueSelector,
    sportsbookLockResultValueSelector,
    withdrawalLockResultValueSelector,
} from './selectors';
import {
    BulkActionItemPayload,
    BulkActionKey,
    bulkItemFailedStatues,
    BulkItemStatus,
    IBulkStrategy,
    PerformActionData,
    PerformStrategyActionItemsResponse,
    PerformStrategyOperationResponse,
    PerformStrategyRequest,
} from './types';

export function useAffectedRecords<T extends IModuleGridItem>(): Partial<Record<string, T[]>> {
    const allActions = useSelector(actionsDataSelector);
    const affectedRecords = useSelector(itemsSelector) as T[];

    const result: Partial<Record<string, T[]>> = {};

    for (const actionKey in allActions) {
        result[actionKey] = affectedRecords?.filter(user => allActions[actionKey]?.map(i => i.itemId)?.includes(user.serverId));
    }

    return result;
}

type UseBulkActionResult = {
    resetBulkState: () => void;
};

export function useBulkAction(loadItemsPayload?: LoadItemsPayload): UseBulkActionResult {
    const dispatch = useDispatch();

    const resetBulkState = () => {
        dispatch(bulkActionsActions.clearBulkActions());
        if (loadItemsPayload) {
            dispatch(bulkActionsActions.loadItems(loadItemsPayload));
        }
    };

    return {resetBulkState};
}

export const useBulkModalStatus = () => {
    const actions = useSelector(actionsDataSelector);
    const actionItemPayloads = Object.values(actions)?.flatMap(i => i);
    const processingItemStatuses = [BulkItemStatus.Pending, BulkItemStatus.InProgress];
    const hasProcessingItem = actionItemPayloads?.some(i => processingItemStatuses.includes(i.status));
    const hasFailedItem = actionItemPayloads?.some(i => bulkItemFailedStatues.includes(i.status));

    return {
        hasProcessingItem,
        hasFailedItem,
    };
};

export const useBulkActionResultValue = () => {
    const resultValues: Partial<Record<string, unknown>> = {
        [BulkActionKey.AccountLock]: useSelector(accountLockResultValueSelector),
        [BulkActionKey.WithdrawalLock]: useSelector(withdrawalLockResultValueSelector),
        [BulkActionKey.DepositLock]: useSelector(depositLockResultValueSelector),
        [BulkActionKey.LobbyLock]: useSelector(lobbyLockResultValueSelector),
        [BulkActionKey.CasinoLock]: useSelector(casinoLockResultValueSelector),
        [BulkActionKey.SportsbookLock]: useSelector(sportsbookLockResultValueSelector),
        [BulkActionKey.SecurityCasesAdd]: useSelector(securityCaseAddResultValueSelector),
        [BulkActionKey.SecurityCasesUpdate]: useSelector(securityCaseUpdateResultValueSelector),
        [BulkActionKey.SecurityCasesRemove]: useSelector(securityCaseRemoveResultValueSelector),
        [BulkActionKey.SecurityCasesAll]: useSelector(securityCaseAllResultValueSelector),
        [BulkActionKey.Message]: useSelector(messageResultValueSelector),
        [BulkActionKey.NotesAndAttachments]: useSelector(notesAttachmentsResultValueSelector),
        [BulkActionKey.ManualTriggerDepositCashMatch]: useSelector(manualTriggerDepositCashMatchResultValueSelector),
        [BulkActionKey.ManualTriggerImmediateCashBonus]: useSelector(manualTriggerImmediateCashBonusResultValueSelector),
        [BulkActionKey.ManualTriggerMTTTicket]: useSelector(manualTriggerMTTTicketResultValueSelector),
        [BulkActionKey.ManualTransactions]: useSelector(manualTransactionResultValueSelector),
        [BulkActionKey.P2PTransferLock]: useSelector(p2pTransferLockResultValueSelector),
        [BulkActionKey.AddUserLabel]: useSelector(addUserLabelResultValueSelector),
        [BulkActionKey.RemoveUserLabel]: useSelector(removeUserLabelResultValueSelector),
        [BulkActionKey.P2PTransfer]: useSelector(p2pTransferResultValueSelector),
    };
    return resultValues;
};

const localized = defineMessages({
    bulkActionSummaryUsers: {
        id: 'bulkActionSummaryUsers',
        defaultMessage: 'users',
    },
    bulkActionSummaryTransactions: {
        id: 'bulkActionSummaryTransactions',
        defaultMessage: 'transactions',
    },
    bulkActionSummaryItems: {
        id: 'bulkActionSummaryItems',
        defaultMessage: 'items',
    },
});

export function useLabel(type: EntityType): string {
    const {formatMessage} = useIntl();
    const mapping: Partial<Record<EntityType, MessageDescriptor>> = {
        [EntityType.UserProfile]: localized.bulkActionSummaryUsers,
        [EntityType.Transaction]: localized.bulkActionSummaryTransactions,
    };

    return formatMessage(mapping[type] ?? localized.bulkActionSummaryItems);
}

export function useApply<TStrategy, TApplyModel>(strategySymbol: symbol, action: BulkActionKey) {
    const strategy = useService<TStrategy>(strategySymbol);
    const selector = (state: RootState) => simpleResultValueSelector(state, action);
    const applyValue = useSelector(selector) as TApplyModel;

    return {strategy, applyValue};
}

export function usePerformBulkOperation<TStrategy extends IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse>>(
    strategySymbol: symbol,
    action: BulkActionKey
): PerformActionData {
    const strategy = useService<TStrategy>(strategySymbol);

    return {
        actionKey: action,
        type: 'operation',
        strategy,
    };
}

export function usePerformMultipleRequests<TStrategy extends IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse>>(
    strategySymbol: symbol,
    action: BulkActionKey
): PerformActionData {
    const strategy = useService<TStrategy>(strategySymbol);

    return {
        actionKey: action,
        type: 'actionItems',
        strategy,
    };
}

export function useSelectedItems<TItem>() {
    const selectedItems = useSelector(itemsSelector) as TItem[];
    return selectedItems;
}

type UseExecutionStepResultProps<TValue> = {
    actionItems: BulkActionItemPayload<TValue>[];
    isOperationFailed: boolean;
};

type UseExecutionStepResult<TValue> = {
    failedItems: TValue[];
    partiallySuccessfulItems: TValue[];
    successfulItems: TValue[];
};

export function useExecutionStepResult<TValue>({
    actionItems,
    isOperationFailed,
}: UseExecutionStepResultProps<TValue>): UseExecutionStepResult<TValue> {
    const actionItemsIdsKey = actionItems.map(a => a.itemId).join(',');
    const actionItemsStatusesKey = actionItems.map(a => a.status).join(',');

    const failedItems = useMemo(
        () =>
            isOperationFailed
                ? actionItems?.map(i => i.value)
                : actionItems?.filter(i => i.status === BulkItemStatus.Failed)?.map(i => i.value),
        [actionItemsIdsKey, actionItemsStatusesKey, isOperationFailed]
    );
    const partiallySuccessfulItems = useMemo(
        () => (isOperationFailed ? [] : actionItems?.filter(i => i.status === BulkItemStatus.PartiallySuccessful)?.map(i => i.value)),
        [actionItemsIdsKey, actionItemsStatusesKey, isOperationFailed]
    );
    const successfulItems = useMemo(
        () => (isOperationFailed ? [] : actionItems?.filter(i => i.status === BulkItemStatus.Successful)?.map(i => i.value)),
        [actionItemsIdsKey, actionItemsStatusesKey, isOperationFailed]
    );

    return {
        failedItems,
        partiallySuccessfulItems,
        successfulItems,
    };
}

export function usePerformStrategy(strategyKey: ServiceTypesKeys): IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    return useService<IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse>>(strategyKey);
}
