import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {GridCellParams} from '@mui/x-data-grid';

import {OutlinedButton} from '@components/button/Buttons';
import {isFutureDate} from '@utils';

import actions from '../actions';
import {UserGridItem} from '../types';

const localized = defineMessages({
    resetPasswordLabel: {
        id: 'resetPasswordLabel',
        defaultMessage: 'Reset Password',
    },
});

export const renderResetPasswordAction = (params: GridCellParams) => {
    const dispatch = useDispatch();

    const email = params.row[nameof<UserGridItem>(u => u.email)];

    const lockoutEnd = params.row[nameof<UserGridItem>(u => u.lockoutEnd)];

    const isLockoutEndFutureDate = isFutureDate(lockoutEnd);

    const onClick = () => {
        dispatch(actions.resetPassword.request(email));
    };

    return (
        <OutlinedButton
            disabled={isLockoutEndFutureDate}
            size="small"
            label={localized.resetPasswordLabel}
            onClick={onClick}
            color="primary"
            data-testid="resetPassword"
        />
    );
};
