import {IModuleGridItem} from '@components/data-grid';
import {MarketingCode} from '@models/generated/graphql';

import {ColumnConfiguration} from 'src/common/types';
import {IModuleItem} from '../module-shared/types';

export const domain = 'marketing-code';

export class MarketingCodeItem extends MarketingCode implements IModuleItem {
    id: string;
}

export class MarketingCodeGridItem extends MarketingCode implements IModuleGridItem {
    id: string;
    isNew: boolean;
    serverId: string;

    addedByUsername: string;
}

export class MarketingCodeColumnsConfiguration extends ColumnConfiguration {
    withMarketingCode: boolean;
    withBtag: boolean;
    withType: boolean;
    withAddedByUid: boolean;
    withCreatedAt: boolean;

    constructor() {
        super();
        this.withMarketingCode = false;
        this.withBtag = false;
        this.withType = false;
        this.withAddedByUid = false;
        this.withCreatedAt = false;
    }
}
