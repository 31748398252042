import type {} from '@mui/lab/themeAugmentation';
import {PaletteColorOptions as MuiPaletteColorOptions, Theme} from '@mui/material';
import {cyan} from '@mui/material/colors';
import {enUS, koKR, Localization, zhCN} from '@mui/material/locale';
import {createTheme} from '@mui/material/styles';
import type {} from '@mui/x-tree-view/themeAugmentation';

export enum SystemColorsEnum {
    LightBlue = '#224ec0',
    Bronze = '#d1683f',
    LightGreen = '#49a500',
    Orange = '#d47d17',
    LightGrey = '#8993a4',
    DarkGrey = '#001529',
    Violet = '#6d6dff',
    Coffee = '#bd988c',
    Beige = '#e5d29b',
    Mint = '#85ffd9',
    YellowNeon = '#eaff52',
    Burgundy = '#a62b8b',
    SkyBlue = '#01bcd4',
    Pink = '#ff67a6',
    WarmYellow = '#ffd422',
    White = '#ffffff',
    Emerald = '#14aa57',
    DarkTeal = '#367e7f',
    Red = '#f44336',
    AliceBlue = '#f1f5f9',
    NavyBlue = '#0066ff',
    Green = '#00b247',
    DarkPink = '#ec4f79',
    DarkViolet = '#904ee2',
    SystemGrey = '#F3F4F5',
}

type Modify<T, R> = Omit<T, keyof R> & R;

type PaletteCustomColorOptions = MuiPaletteColorOptions & {
    extraLight: string;
};

export type ColorOption = {
    main: string;
    light: string;
    hoverMain: string;
    hoverLight: string;
    border?: string;
};

export type PaletteColorOptions = {
    transparent: ColorOption;
    disabled: ColorOption;
    default: ColorOption;
    orange: ColorOption;
    bronze: ColorOption;
    green: ColorOption;
    lightGreen: ColorOption;
    turquoise: ColorOption;
    blue: ColorOption;
    lightBlue: ColorOption;
    purple: ColorOption;
    yellow: ColorOption;
    grey: ColorOption;
    lightGrey: ColorOption;
    darkGrey: ColorOption;
    greyInverted: ColorOption;
    bronzeInverted: ColorOption;
    dropdownDefault: ColorOption;
    primaryButton: ColorOption;
    lime: ColorOption;
    coffee: ColorOption;
    beige: ColorOption;
    mint: ColorOption;
    yellowNeon: ColorOption;
    burgundy: ColorOption;
    skyBlue: ColorOption;
    pink: ColorOption;
    warmYellow: ColorOption;
    red: ColorOption;
    violet: ColorOption;
    darkTeal: ColorOption;
    white: ColorOption;
    aliceBlue: ColorOption;
    navyBlue: ColorOption;
    darkPink: ColorOption;
    darkViolet: ColorOption;
    systemGrey: ColorOption;
};

export type PaletteColorOptionsKeys = keyof PaletteColorOptions;

type CustomPalette = {
    grid: {
        new: string;
        evenRows: string;
    };
    content: {
        chipDeleteBackground: string;
        chipDeleteColor: string;
        liner: string;
        border: string;
        borderLight: string;
        borderDark: string;
        boxShadow: string;
        headingText: string;
        primary: string;
        bodyText: string;
        backgroundDark: string;
        backgroundLight: string;
        backgroundWhite: string;
        header: string;
        default: string;
        tagBackground: string;
        commentNewBackground: string;
        toggleBackground: string;
        light: string;
        secondarySuccess: string;
        secondaryFailed: string;
        secondaryFailedLight: string;
        secondaryInProgress: string;
        secondaryInProgressLight: string;
        scrollbarBackground: string;
        editorBackground: string;
        bulkActionHeaderBackground: string;
        bulkActionSelectBackground: string;
        notificationHistoryDetailsModalBody: string;
        chipDeleteIconMain: string;
        suitRed: string;
        suitBlack: string;
    };
    primary: PaletteCustomColorOptions;
    secondary: PaletteCustomColorOptions;
    status: PaletteColorOptions;
};

type CustomButton = {
    xSmall?: {
        height: string | number;
    };
    small?: {
        height?: string | number;
    };
    medium?: {
        height?: string | number;
    };
    large?: {
        height?: string | number;
    };
    xLarge?: {
        height?: string | number;
    };
};

type CustomOptions = {
    chipDeleteWidth: number;
    chipDeleteHeight: number;
    enrichedButtonHeight: number;
    chipDeleteIconSize: number;
    styledSelectMenuMaxHeight: number;
    defaultChipBoxShadow: string;
    defaultChipBorderRadius: number;
    defaultChipHeight: number;
    bulkSummaryDetailsItemValueWidth: number;
    bulkModalSummaryItemHeight: number;
    bulkModalDialogContentHeight: number;
    filterTextInputMaxWidth: number;
    layoutDetailsSecondaryInfoWidth: number;
    autocompleteBorderRadius: number;
    autocompleteMaxHeight: number;
    formActionButtonHeight: number;
    formActionButtonBorderRadius: number;
    borderWidth: number;
    smallSizesNavbarHeight: number;
    smallPlusButtonHeight: number;
    chipHeight: number;
    roundedButtonRadius: number;
    denseButtonHeight: number;
    pageHeaderHeight: number;
    gridHeaderHeight: number;
    gridCheckboxWidth: number;
    searchBarMaxWidth: number;
    numberRangeMaxWidth: number;
    sideBarWidth: number;
    sideBarMinimizedWidth: number;
    appModuleContainerSmallScreenWidth: number;
    sideBarItemWidth: number;
    sideBarItemHeight: number;
    sideModalWidth: number;
    topMenuHeight: number;
    formWidth: number;
    actionsMenuWidth: number;
    dataGridFontSize: string;
    textEditorMinHeight: number;
    textEditorMaxHeight: number;
    legalDocViewHeight: number;
    limitedModalHeight: number;
    tagSize: number;
    tagRadius: number;
    fieldWidth: number;
    autocompleteWidth: number;
    fieldMinWidth: number;
    scrollBarHeight: number;
    secondaryItemHeight: number;
    modalContainerMinHeight: number;
    modalActionButtonWidth: number;
    button: {
        fontFamily?: string;
        fontStyle?: string;
        fontWeight?: string | number;
        fontSize?: string | number;
        lineHeight?: string | number;
        letterSpacing?: string | number;
        textTransform?: string;
        textAlign?: string;
        display?: string;
        justifyContent?: string;
        alignItems?: string;
        padding?: string | number;
        height?: string | number;
    };
    palette: CustomPalette;
    transitions: {
        backgroundColor: string;
    };
    dropdownIconWidth: string;
    buttonIconWidth: string | number;
    keyValueTableCell: number;
    customButton: CustomButton;
    inputIconFontSize: number;
    editorToolbarDropdownMinWidth: number;
    editorToolbarDropdownMaxWidth: number;
    editorToolbarDropdownMaxHeight: number;
    menuItemHeight: number;
};

export type CustomTheme = Modify<
    Theme,
    {
        custom: CustomOptions;
    }
>;

export type Locale = 'zh-CN' | 'en' | 'ko-KR';

const localeMap: Record<Locale, Localization> = {
    'zh-CN': zhCN,
    en: enUS,
    'ko-KR': koKR,
};

export function createCustomTheme(locale: Locale): CustomTheme {
    const baseTheme = createTheme(
        {
            palette: {
                info: {
                    main: cyan[500],
                    light: cyan[300],
                    dark: cyan[700],
                },
                background: {
                    paper: '#fff',
                    default: 'rgba(238, 238, 238, 1)',
                },
                action: {
                    disabledOpacity: 0.2,
                },
                primary: {
                    light: 'rgba(238, 244, 255, 1)',
                    main: 'rgba(20, 111, 249, 1)',
                    dark: 'rgba(38, 89, 199, 1)',
                    contrastText: '#fff',
                },
                secondary: {
                    light: 'rgba(240, 242, 245, 1)',
                    main: 'rgba(137, 147, 164, 1)',
                    dark: 'rgba(208, 212, 220, 1)',
                    contrastText: 'rgba(0, 21, 41, 1)',
                },
                error: {
                    light: '#e57373',
                    main: '#f44336',
                    dark: '#d32f2f',
                    contrastText: '#fff',
                },
                text: {
                    primary: 'rgba(0, 21, 41, 1)',
                    secondary: 'rgba(137, 147, 164, 1)',
                    disabled: 'rgba(163, 170, 177, 1)',
                },
                success: {
                    light: 'rgba(232, 247, 239, 1)',
                    main: 'rgba(20, 170, 87, 1)',
                    dark: 'rgba(40, 199, 111, 1)',
                    contrastText: '#fff',
                },
                warning: {
                    light: 'rgba(212, 125, 23, 0.2)',
                    main: 'rgba(212, 125, 23, 1)',
                    dark: 'rgba(245, 124, 0, 1)',
                    contrastText: '#fff',
                },
            },
            typography: {
                fontFamily: 'Inter, Arial',
                // Typography -> Title
                h4: {
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '16px',
                },
                h5: {
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '22px',
                    lineHeight: '27px',
                    letterSpacing: '-1%',
                },
                // Typography -> Headline
                h6: {
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '-0.32%',
                },
                // Typography -> Text
                body1: {
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '-0.3%',
                    textTransform: 'unset',
                },
                body2: {
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '18px',
                    letterSpacing: '-0.32%',
                },
                // Typography -> Subhead
                subtitle1: {
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '-0.3%',
                    textTransform: 'unset',
                    color: 'rgba(137, 147, 164, 1)',
                },
                // Typography -> Subhead
                subtitle2: {
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '18px',
                    letterSpacing: '-0.32%',
                },
                // Typography -> Caption Caps
                caption: {
                    fontWeight: 500,
                    fontSize: '10px',
                    lineHeight: '14px',
                    letterSpacing: '1px',
                    color: 'rgba(137, 147, 164, 1)',
                },
                button: {
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '-0.3%',
                    textTransform: 'unset',
                },
            },
        },
        localeMap[locale]
    );

    const customButton: CustomButton = {
        medium: {
            height: 35,
        },
    };

    const customPalette = {
        grid: {
            new: 'rgba(183, 212, 241, 1)',
            evenRows: 'rgba(251, 252, 252, 1)',
        },
        content: {
            chipDeleteBackground: 'rgba(137, 147, 164, 0.4)', //Will be removed with https://services-cloud.atlassian.net/browse/IGP-1705
            chipDeleteColor: 'rgba(251, 252, 252, 1)', //Will be removed with https://services-cloud.atlassian.net/browse/IGP-1705
            liner: '#E1E7EB',
            header: 'rgba(169, 175, 182, 1)',
            default: 'rgba(222, 222, 222, 1)',
            primary: 'rgba(43, 75, 242, 1)',
            light: 'rgba(43, 75, 242, 0.15)',
            headingText: 'rgba(0, 21, 41, 1)',
            bodyText: 'rgba(0, 21, 41, 1)',
            backgroundDark: 'rgba(208, 212, 220, 1)',
            backgroundLight: 'rgba(247, 248, 249, 1)',
            backgroundWhite: 'rgba(255, 255, 255, 1)',
            tagBackground: 'rgba(230, 230, 242, 1)',
            commentNewBackground: 'rgba(238, 239, 255, 1)',
            toggleBackground: 'rgba(137, 147, 164, 0.1)',
            scrollbarBackground: 'rgba(137, 147, 164, 0.5)',
            border: 'rgba(225, 231, 235, 1)',
            borderLight: 'rgba(225, 231, 235, 0.5)',
            borderDark: 'rgba(27, 29, 32, 1)',
            boxShadow: 'rgba(19, 64, 106, 0.06)',
            buttonText: 'rgba(255, 255, 255, 1)',
            outlinedButtonText: 'rgba(0, 21, 41, 1)',
            failed: 'rgba(137, 147, 164, 1)',
            failedLight: 'rgba(137, 147, 164, 0.2)',
            secondarySuccess: 'rgba(20, 170, 87, 1)',
            secondaryFailedLight: 'rgba(137, 147, 164, 0.2)',
            secondaryFailed: 'rgba(137, 147, 164, 1)',
            secondaryInProgressLight: 'rgba(212, 125, 23, 0.2)',
            secondaryInProgress: 'rgba(212, 125, 23, 1)',
            editorBackground: 'rgba(240, 243, 245, 1)',
            bulkActionHeaderBackground: 'rgba(137, 147, 164, 0.06)',
            bulkActionSelectBackground: 'rgba(225, 231, 235, 0.3)',
            notificationHistoryDetailsModalBody: 'rgba(249, 250, 251, 1)',
            chipDeleteIconMain: 'rgba(175, 185, 198, 1)',
            suitRed: 'rgba(204, 57, 51, 1)',
            suitBlack: 'rgba(0, 21, 41, 1)',
        },
        status: {
            transparent: {
                main: 'rgba(0, 0, 0, 1)',
                light: 'transparent',
                hoverMain: 'rgba(0, 0, 0, 1)',
                hoverLight: 'transparent',
                border: '1px rgba(240, 242, 245, 1) solid',
            },
            disabled: {
                main: 'rgba(137, 147, 164, 1)',
                light: 'rgba(240, 242, 245, 1)',
                hoverMain: 'rgba(137, 147, 164, 1)',
                hoverLight: 'rgba(240, 242, 245, 1)',
            },
            default: {
                main: 'rgba(137, 147, 164, 1)',
                light: 'rgba(255, 255, 255, 1)',
                hoverMain: 'rgba(137, 147, 164, 1)',
                hoverLight: 'rgba(255, 255, 255, 1)',
            },
            defaultReversed: {
                main: 'rgba(255, 255, 255, 1)',
                light: 'rgba(137, 147, 164, 1)',
                hoverMain: 'rgba(255, 255, 255, 1)',
                hoverLight: 'rgba(137, 147, 164, 1)',
            },
            orange: {
                main: 'rgba(212, 125, 23, 1)',
                light: 'rgba(231, 132, 17, 0.2)',
                hoverMain: 'rgba(212, 125, 23, 1)',
                hoverLight: 'rgba(231, 132, 17, 0.2)',
            },
            orangeDark: {
                main: 'rgba(255, 255, 255, 1)',
                light: 'rgba(212, 125, 23, 1)',
                hoverMain: 'rgba(255, 255, 255, 1)',
                hoverLight: 'rgba(212, 125, 23, 1)',
            },
            bronze: {
                main: 'rgba(209, 104, 63, 1)',
                light: 'rgba(253, 236, 229, 1)',
                hoverMain: 'rgba(209, 104, 63, 1)',
                hoverLight: 'rgba(253, 236, 229, 1)',
            },
            green: {
                main: 'rgba(20, 170, 87, 1)',
                light: 'rgba(20, 174, 89, 0.2)',
                hoverMain: 'rgba(20, 170, 87, 1)',
                hoverLight: 'rgba(20, 174, 89, 0.2)',
            },
            greenDark: {
                main: 'rgba(255, 255, 255, 1)',
                light: 'rgba(20, 170, 87, 1)',
                hoverMain: 'rgba(255, 255, 255, 1)',
                hoverLight: 'rgba(20, 170, 87, 1)',
            },
            lightGreen: {
                main: 'rgba(73, 165, 0, 1)',
                light: 'rgba(230, 246, 217, 1)',
                hoverMain: 'rgba(73, 165, 0, 1)',
                hoverLight: 'rgba(230, 246, 217, 1)',
            },
            turquoise: {
                main: 'rgba(34, 154, 161, 1)',
                light: 'rgba(222, 244, 246, 1)',
                hoverMain: 'rgba(34, 154, 161, 1)',
                hoverLight: 'rgba(222, 244, 246, 1)',
            },
            lightBlue: {
                main: 'rgba(34, 78, 192, 1)',
                light: 'rgba(222, 229, 246, 1)',
                hoverMain: 'rgba(34, 78, 192, 1)',
                hoverLight: 'rgba(222, 229, 246, 1)',
            },
            blue: {
                main: 'rgba(255, 255, 255, 1)',
                light: 'rgba(20, 111, 249, 1)',
                hoverMain: 'rgba(255, 255, 255, 1)',
                hoverLight: 'rgba(20, 111, 249, 1)',
            },
            purple: {
                main: 'rgba(172, 41, 161, 1)',
                light: 'rgba(246, 222, 244, 1)',
                hoverMain: 'rgba(172, 41, 161, 1)',
                hoverLight: 'rgba(246, 222, 244, 1)',
            },
            violet: {
                main: 'rgba(109, 109, 255, 1)',
                light: 'rgba(109, 109, 255, 0.1)',
                hoverMain: 'rgba(109, 109, 255, 1)',
                hoverLight: 'rgba(109, 109, 255, 0.1)',
            },
            yellow: {
                main: 'rgba(166, 172, 16, 1)',
                light: 'rgba(246, 246, 222, 1)',
                hoverMain: 'rgba(166, 172, 16, 1)',
                hoverLight: 'rgba(246, 246, 222, 1)',
            },
            grey: {
                main: baseTheme.palette.text.primary,
                light: 'rgba(240, 242, 245, 0.5)',
                hoverMain: baseTheme.palette.text.primary,
                hoverLight: 'rgba(240, 242, 245, 0.5)',
            },
            lightGrey: {
                main: 'rgba(137, 147, 164, 1)',
                light: 'rgba(240, 242, 245, 0.5)',
                hoverMain: 'rgba(137, 147, 164, 1)',
                hoverLight: 'rgba(240, 242, 245, 0.5)',
            },
            darkGrey: {
                main: baseTheme.palette.text.primary,
                light: 'rgba(231, 236, 239, 1)',
                hoverMain: baseTheme.palette.text.primary,
                hoverLight: 'rgba(231, 236, 239, 1)',
            },
            greyInverted: {
                main: 'rgba(255, 255, 255, 1)',
                light: 'rgba(137, 147, 164, 1)',
                hoverMain: 'rgba(255, 255, 255, 1)',
                hoverLight: 'rgba(137, 147, 164, 1)',
            },
            bronzeInverted: {
                main: 'rgba(255, 255, 255, 1)',
                light: 'rgba(209, 104, 63, 1)',
                hoverMain: 'rgba(255, 255, 255, 1)',
                hoverLight: 'rgba(209, 104, 63, 1)',
            },
            dropdownDefault: {
                main: 'rgba(0, 21, 41, 1)',
                light: 'rgba(251, 252, 252, 1)',
                hoverMain: 'rgba(255, 255, 255, 1)',
                hoverLight: 'rgba(20, 111, 249, 1)',
            },
            primaryButton: {
                main: 'rgba(251, 252, 252, 1)',
                light: 'rgba(251, 252, 252, 0.3)',
                hoverMain: 'rgba(255, 255, 255, 0.3)',
                hoverLight: 'rgba(255, 255, 255, 0.3)',
            },
            lime: {
                main: 'rgba(2, 187, 166, 1)',
                light: 'rgba(246, 246, 222, 1)',
                hoverMain: 'rgba(2, 187, 166, 1)',
                hoverLight: 'rgba(246, 246, 222, 1)',
            },
            coffee: {
                main: 'rgba(189, 152, 140, 1)',
                light: 'rgba(189, 152, 140, 0.1)',
                hoverMain: 'rgba(189, 152, 140, 1)',
                hoverLight: 'rgba(189, 152, 140, 0.1)',
            },
            beige: {
                main: 'rgba(114, 88, 13, 1)',
                light: 'rgba(229, 210, 155, 0.25)',
                hoverMain: 'rgba(114, 88, 13, 1)',
                hoverLight: 'rgba(229, 210, 155, 0.25)',
            },
            mint: {
                main: 'rgba(66, 167, 135, 1)',
                light: 'rgba(133, 255, 217, 0.25)',
                hoverMain: 'rgba(66, 167, 135, 1)',
                hoverLight: 'rgba(133, 255, 217, 0.25)',
            },
            yellowNeon: {
                main: 'rgba(146, 163, 22, 1)',
                light: 'rgba(234, 255, 82, 0.25)',
                hoverMain: 'rgba(146, 163, 22, 1)',
                hoverLight: 'rgba(234, 255, 82, 0.25)',
            },
            burgundy: {
                main: 'rgba(167, 44, 140, 1)',
                light: 'rgba(167, 44, 140, 0.1)',
                hoverMain: 'rgba(167, 44, 140, 1)',
                hoverLight: 'rgba(167, 44, 140, 0.1)',
            },
            skyBlue: {
                main: 'rgba(1, 188, 212, 1)',
                light: 'rgba(1, 188, 212, 0.1)',
                hoverMain: 'rgba(1, 188, 212, 1)',
                hoverLight: 'rgba(1, 188, 212, 0.1)',
            },
            pink: {
                main: 'rgba(255, 103, 166, 1)',
                light: 'rgba(255, 103, 166, 0.1)',
                hoverMain: 'rgba(255, 103, 166, 1)',
                hoverLight: 'rgba(255, 103, 166, 0.1)',
            },
            warmYellow: {
                main: 'rgba(231, 186, 0, 1)',
                light: 'rgba(255, 212, 34, 0.2)',
                hoverMain: 'rgba(231, 186, 0, 1)',
                hoverLight: 'rgba(255, 212, 34, 0.2)',
            },
            red: {
                main: 'rgba(244, 76, 54, 1)',
                light: 'rgba(244, 76, 54, 0.2)',
                hoverMain: 'rgba(244, 76, 54, 1)',
                hoverLight: 'rgba(244, 76, 54, 0.2)',
            },
            darkTeal: {
                main: 'rgba(54, 126, 127,1)',
                light: 'rgba(255, 255, 255, 1)',
                hoverMain: 'rgba(54, 126, 127,1)',
                hoverLight: 'rgba(255, 255, 255, 1)',
            },
            white: {
                main: 'rgba(255, 255, 255, 1)',
                light: 'rgba(137, 147, 164, 1)',
                hoverMain: 'rgba(255, 255, 255, 1)',
                hoverLight: 'rgba(137, 147, 164, 1)',
            },
            aliceBlue: {
                main: 'rgba(241, 245, 249, 1)',
                light: 'rgba(241, 245, 249, 0.1)',
                hoverMain: 'rgba(241, 245, 249, 1)',
                hoverLight: 'rgba(241, 245, 249, 0.1)',
            },
            navyBlue: {
                main: 'rgba(0, 102, 255, 1)',
                light: 'rgba(0, 102, 255, 0.1)',
                hoverMain: 'rgba(0, 102, 255, 1)',
                hoverLight: 'rgba(0, 102, 255, 0.1)',
            },
            darkPink: {
                main: 'rgba(236, 79, 121, 1)',
                light: 'rgba(236, 79, 121, 0.1)',
                hoverMain: 'rgba(236, 79, 121, 1)',
                hoverLight: 'rgba(236, 79, 121, 0.1)',
            },
            darkViolet: {
                main: 'rgba(144, 78, 226, 1)',
                light: 'rgba(144, 78, 226, 0.1)',
                hoverMain: 'rgba(144, 78, 226, 1)',
                hoverLight: 'rgba(144, 78, 226, 0.1)',
            },
            systemGrey: {
                main: 'rgba(243, 244, 245,1)',
                light: 'rgba(241, 245, 249, 0.1)',
                hoverMain: 'rgba(243, 244, 245,1)',
                hoverLight: 'rgba(241, 245, 249, 0.1)',
            },
        },
        primary: {
            extraLight: 'rgba(157, 167, 177, 1)',
        },
        secondary: {
            extraLight: 'rgba(224, 238, 251, 1)',
        },
    } as CustomPalette;

    const commonButtonProps = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: baseTheme.spacing(1.25, 2.5),
        height: 36,
        minWidth: 100,
    };

    const menuItemHeight = 45;

    return {
        ...createTheme({
            ...baseTheme,
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        root: {
                            fontSize: baseTheme.typography.body1.fontSize,
                        },
                        body: {
                            backgroundColor: customPalette.content.backgroundLight,
                            scrollbarColor: `${customPalette.content.scrollbarBackground} ${baseTheme.palette.text.secondary}`,
                            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                                backgroundColor: customPalette.content.scrollbarBackground,
                                borderRadius: 100,
                                width: '0.4em',
                                height: '0.4em',
                            },
                            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                                borderRadius: 100,
                                backgroundColor: baseTheme.palette.text.secondary,
                            },
                        },
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        input: {
                            height: '30px',
                            paddingTop: 0,
                            paddingBottom: 0,
                        },
                    },
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            border: '0 !important',
                            boxShadow: 'none !important',
                        },
                    },
                },
                MuiFormGroup: {
                    styleOverrides: {
                        row: {
                            flexWrap: 'nowrap',
                            columnGap: baseTheme.spacing(1),
                        },
                    },
                },
                MuiInput: {
                    styleOverrides: {
                        underline: {
                            borderRadius: '0 !important',
                            border: '0 !important',
                            boxShadow: 'none !important',
                            height: '32px',
                            '&::before': {
                                borderBottom: 0,
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        input: {
                            padding: baseTheme.spacing(0, 1.5),
                            height: '30px',
                        },
                        sizeSmall: {
                            paddingBottom: 0,
                            paddingTop: 0,
                        },
                        notchedOutline: {
                            border: 0,
                        },
                        root: {
                            border: '1px solid #E1E7EB',
                            boxSizing: 'border-box',
                            boxShadow: `0px 1px 1px ${customPalette.content.boxShadow}`,
                            borderRadius: '8px',
                            '&.Mui-focused': {
                                border: `1px solid ${baseTheme.palette.primary.main}`,
                                boxSizing: 'border-box',
                                boxShadow: `0px 1px 1px ${customPalette.content.boxShadow}`,
                                borderRadius: '8px',
                            },
                        },
                        adornedEnd: {
                            paddingRight: baseTheme.spacing(1.5),
                        },
                        adornedStart: {
                            paddingLeft: baseTheme.spacing(1.5),
                        },
                        multiline: {
                            padding: 'unset',
                        },
                    },
                },
                MuiTextField: {
                    defaultProps: {
                        variant: 'standard',
                    },
                    styleOverrides: {
                        root: {
                            backgroundColor: baseTheme.palette.background.paper,
                            borderRadius: '8px',
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        colorDefault: {
                            backgroundColor: customPalette.content.backgroundLight,
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        contained: {
                            color: baseTheme.palette.text.primary,
                            background: baseTheme.palette.background.paper,
                            backgroundColor: baseTheme.palette.background.paper,
                            border: `1px solid ${customPalette.content.liner}`,
                            boxShadow: `0px 1px 1px ${customPalette.content.boxShadow}`,
                            boxSizing: 'border-box',
                            borderRadius: 4,
                            flexDirection: 'row',
                            whiteSpace: 'nowrap',
                            ...baseTheme.typography.button,
                            ...commonButtonProps,
                            '&:hover': {
                                color: baseTheme.palette.primary.main,
                                background: baseTheme.palette.background.paper,
                                backgroundColor: baseTheme.palette.background.paper,
                                borderColor: customPalette.content.liner,
                                boxShadow: 'none',
                            },
                            '&:focus, &:active': {
                                color: baseTheme.palette.primary.main,
                                background: baseTheme.palette.background.paper,
                                backgroundColor: baseTheme.palette.background.paper,
                                borderColor: baseTheme.palette.primary.main,
                                boxShadow: 'none',
                            },
                            '&:disabled': {
                                color: baseTheme.palette.secondary.main,
                                background: baseTheme.palette.background.paper,
                                borderColor: customPalette.content.liner,
                            },
                        },
                        containedPrimary: {
                            color: baseTheme.palette.primary.contrastText,
                            background: baseTheme.palette.primary.main,
                            borderRadius: 4,
                            flexDirection: 'row',
                            whiteSpace: 'nowrap',
                            ...baseTheme.typography.button,
                            ...commonButtonProps,
                            '&:hover, &:focus, &:active': {
                                color: baseTheme.palette.primary.contrastText,
                                backgroundColor: baseTheme.palette.primary.dark,
                                boxShadow: 'none',
                            },
                            '&:disabled': {
                                opacity: 0.5,
                                color: baseTheme.palette.primary.contrastText,
                                background: baseTheme.palette.primary.main,
                            },
                        },
                        outlinedPrimary: {
                            color: baseTheme.palette.text.primary,
                            background: baseTheme.palette.background.paper,
                            border: `1px solid ${customPalette.content.liner}`,
                            boxShadow: '0px 1px 1px rgba(19, 64, 106, 0.06)',
                            boxSizing: 'border-box',
                            borderRadius: 8,
                            flexDirection: 'row',
                            whiteSpace: 'nowrap',
                            ...baseTheme.typography.button,
                            ...commonButtonProps,
                            '&:hover': {
                                color: baseTheme.palette.primary.main,
                                background: baseTheme.palette.background.paper,
                                backgroundColor: baseTheme.palette.background.paper,
                                borderColor: customPalette.content.liner,
                                '& .MuiButton-startIcon': {
                                    color: baseTheme.palette.primary.main,
                                    fontSize: 15,
                                },
                            },
                            '@media (hover: none)': {
                                '&:hover': {
                                    backgroundColor: `${baseTheme.palette.background.paper} !important`,
                                },
                            },
                            '&:focus, &:active': {
                                color: baseTheme.palette.primary.main,
                                background: baseTheme.palette.background.paper,
                                backgroundColor: baseTheme.palette.background.paper,
                                borderColor: baseTheme.palette.primary.main,
                            },
                            '& .MuiButton-startIcon': {
                                color: baseTheme.palette.secondary.main,
                                fontSize: 15,
                                '& > *:first-child': {
                                    fontSize: 15,
                                },
                            },
                            '&:disabled': {
                                color: baseTheme.palette.secondary.main,
                                background: baseTheme.palette.background.paper,
                                borderColor: customPalette.content.liner,
                            },
                        },
                        outlinedSizeSmall: {
                            height: 28,
                            minWidth: 'unset',
                            padding: 8,
                        },
                        sizeSmall: {
                            minWidth: 'auto',
                            height: 24,
                            borderRadius: 6,
                            ...baseTheme.typography.subtitle2,
                        },
                    },
                },
                MuiBadge: {
                    styleOverrides: {
                        root: {
                            color: baseTheme.palette.secondary.main,
                        },
                        badge: {
                            background: baseTheme.palette.secondary.light,
                        },
                    },
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            color: baseTheme.palette.secondary.main,
                            textDecorationColor: baseTheme.palette.primary.main,
                        },
                    },
                },
                MuiRadio: {
                    styleOverrides: {
                        root: {
                            color: customPalette.content.backgroundDark,
                            padding: baseTheme.spacing(0.5, 1.5, 0.5, 0.5),
                            '& .MuiSvgIcon-root': {
                                fontSize: '1rem',
                            },
                        },
                        colorSecondary: {
                            '&.Mui-checked': {
                                color: customPalette.content.primary,
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            '&.Mui-checked:hover': {
                                backgroundColor: 'transparent !important',
                            },
                        },
                    },
                },
                MuiButtonGroup: {
                    styleOverrides: {
                        root: {
                            '& .MuiIconButton-root:not(:first-child)': {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                            },
                            '& .MuiIconButton-root:not(:last-child)': {
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            },
                        },
                    },
                },
                MuiToggleButtonGroup: {
                    styleOverrides: {
                        root: {
                            borderRadius: baseTheme.shape.borderRadius * 3,
                            background: customPalette.content.toggleBackground,
                        },
                        grouped: {
                            flexGrow: 1,
                            border: 'none',
                            margin: baseTheme.spacing(0.5),
                            '&:not(:first-child)': {
                                border: 'none',
                            },
                            '&:first-child': {
                                border: 'none',
                            },
                        },
                        groupedHorizontal: {
                            '&:not(:first-child)': {
                                borderRadius: baseTheme.shape.borderRadius * 2,
                                borderTopLeftRadius: baseTheme.shape.borderRadius * 2,
                                borderBottomLeftRadius: baseTheme.shape.borderRadius * 2,
                            },
                            '&:not(:last-child)': {
                                borderRadius: baseTheme.shape.borderRadius * 2,
                                borderTopRightRadius: baseTheme.shape.borderRadius * 2,
                                borderBottomRightRadius: baseTheme.shape.borderRadius * 2,
                            },
                        },
                    },
                },
                MuiToggleButton: {
                    styleOverrides: {
                        root: {
                            display: 'flex',
                            padding: baseTheme.spacing(0.5, 1),
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            lineHeight: '24px',
                            color: baseTheme.palette.text.primary,

                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            '&.Mui-selected': {
                                color: baseTheme.palette.text.primary,
                                backgroundColor: customPalette.content.backgroundWhite,
                                boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 6px 6px -6px rgba(0, 0, 0, 0.16)',
                                '&:hover': {
                                    backgroundColor: customPalette.content.backgroundWhite,
                                },
                            },
                        },
                    },
                },
                MuiTouchRipple: {
                    styleOverrides: {
                        root: {
                            opacity: 0,
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            '&.Mui-selected': {
                                backgroundColor: baseTheme.palette.background.paper,
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: baseTheme.palette.primary.light,
                            },
                            '&.Mui-disabled': {
                                opacity: 0.5,
                            },
                            height: menuItemHeight,
                        },
                    },
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        popupIndicator: {
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        },
                        endAdornment: {
                            paddingRight: baseTheme.spacing(1.5),
                            top: baseTheme.spacing(1.5),
                        },
                        listbox: {
                            padding: 0,
                            paddingTop: baseTheme.spacing(0.5),
                        },
                        option: {
                            height: menuItemHeight,
                        },
                        root: {
                            background: baseTheme.palette.background.paper,
                            border: '1px solid #E1E7EB',
                            boxSizing: 'border-box',
                            borderRadius: '6px',
                            paddingLeft: baseTheme.spacing(0),
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                        inputRoot: {
                            border: '0 !important',
                            flexWrap: 'nowrap',
                            '&:not(.MuiInputBase-multiline)': {
                                height: 32,
                            },
                            '& .MuiAutocomplete-input': {
                                paddingLeft: `${baseTheme.spacing(1)} !important`,
                                marginLeft: `${baseTheme.spacing(0.5)}`,
                            },
                            '&.MuiInputBase-multiline': {
                                paddingTop: `${baseTheme.spacing(0.5)} !important`,
                                paddingBottom: `${baseTheme.spacing(0.5)} !important`,
                            },
                        },
                        input: {
                            padding: '0 !important',
                            marginLeft: baseTheme.spacing(0.5),
                            height: '26px !important',
                            lineHeight: '26px !important',
                            marginTop: 3,
                            marginBottom: 3,
                        },
                        tag: {
                            height: '26px !important',
                            lineHeight: '26px !important',
                            padding: baseTheme.spacing(0, 0.75),
                            background: `${baseTheme.palette.secondary.light} !important`,
                            border: `1px solid ${baseTheme.palette.secondary.light}`,
                            borderRadius: 100,
                        },
                    },
                },
                MuiListItem: {
                    styleOverrides: {
                        button: {
                            '&:hover': {
                                backgroundColor: baseTheme.palette.primary.light,
                            },
                        },
                        gutters: {
                            paddingRight: baseTheme.spacing(1.5),
                            paddingLeft: baseTheme.spacing(1.5),
                            borderRadius: 6,
                        },
                    },
                },
                MuiMenu: {
                    styleOverrides: {
                        list: {
                            padding: 0,
                        },
                    },
                },
                MuiPopover: {
                    styleOverrides: {
                        paper: {
                            minWidth: 'unset !important',
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        rounded: {
                            borderRadius: 6,
                        },
                        elevation1: {
                            boxShadow: '0px 1px 1px rgba(19, 64, 106, 0.06), 0px 1px 1px rgba(19, 64, 106, 0.06)',
                        },
                        elevation8: {
                            filter: 'drop-shadow(0px 2px 4px rgba(0, 21, 41, 0.15))',
                            borderRadius: 6,
                            boxShadow: 'unset',
                            marginTop: baseTheme.spacing(1),
                            marginLeft: baseTheme.spacing(3),
                        },
                        elevation24: {
                            padding: baseTheme.spacing(3),
                            boxShadow: '0px 8px 36px rgba(0, 0, 0, 0.16)',
                            borderRadius: '6px',
                        },
                    },
                },
                MuiSelect: {
                    styleOverrides: {
                        select: {
                            minHeight: '16px',
                            paddingRight: `${baseTheme.spacing(2)} !important`,
                            '&:focus': {
                                backgroundColor: 'transparent',
                            },
                        },
                        root: {
                            ...baseTheme.typography.body2,
                            '&.Mui-focused': {
                                boxShadow: 'none',
                            },
                        },
                    },
                },
                MuiDialogTitle: {
                    styleOverrides: {
                        root: {
                            height: 40,
                            padding: 0,
                        },
                    },
                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: {
                            padding: 0,
                        },
                    },
                },
                MuiDialogActions: {
                    styleOverrides: {
                        root: {
                            height: 46,
                            padding: 0,
                        },
                    },
                },
                MuiDialog: {
                    styleOverrides: {
                        paperWidthMd: {
                            maxWidth: '960px',
                        },
                        paper: {
                            padding: baseTheme.spacing(3.125, 3.75, 1.5, 3.75),
                        },
                    },
                },
                MuiDivider: {
                    styleOverrides: {
                        root: ({ownerState, theme}) => ({
                            ...(ownerState.orientation === 'vertical' &&
                                ownerState.variant === 'middle' && {
                                    marginLeft: theme.spacing(2),
                                    marginRight: theme.spacing(2),
                                }),
                        }),
                    },
                },
                MuiTabs: {
                    styleOverrides: {
                        root: {
                            height: 56,
                            background: baseTheme.palette.background.paper,
                        },
                        flexContainer: {
                            height: 56,
                        },
                        indicator: {
                            borderRadius: '2px 2px 0px 0px',
                            borderBottom: `2px solid ${baseTheme.palette.primary.main}`,
                        },
                    },
                },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            ...baseTheme.typography.body2,
                            color: baseTheme.palette.text.primary,
                            '&:hover': {
                                color: baseTheme.palette.primary.main,
                            },
                            padding: 0,
                            minWidth: 'unset !important',
                            maxWidth: '264px',
                            marginLeft: 40,
                            '&.Mui-selected': {
                                color: baseTheme.palette.primary.main,
                            },
                        },
                        iconWrapper: {
                            width: 'auto',
                        },
                        textColorInherit: {
                            opacity: 1,
                        },
                    },
                },
                MuiTablePagination: {
                    styleOverrides: {
                        spacer: {
                            flex: 0,
                        },
                        input: {
                            marginRight: 'auto',
                        },
                    },
                },
                MuiChip: {
                    styleOverrides: {
                        root: {
                            minWidth: baseTheme.spacing(3),
                            '& .MuiChip-icon': {
                                fontSize: baseTheme.spacing(1.5),
                            },
                        },
                    },
                },
                MuiSwitch: {
                    styleOverrides: {
                        root: {
                            width: 36,
                            height: 21,
                            padding: 0,
                        },
                        input: {
                            width: 36,
                            height: 21,
                            left: 0,
                        },
                        colorSecondary: {
                            '&.Mui-checked': {
                                transform: 'translateX(14px)',
                                color: baseTheme.palette.common.white,
                                '& + .MuiSwitch-track': {
                                    backgroundColor: baseTheme.palette.success.dark,
                                    opacity: 1,
                                    border: 'none',
                                },
                            },
                            '&.Mui-disabled': {
                                color: baseTheme.palette.common.white,
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        },
                        switchBase: {
                            padding: 1,
                            '&.Mui-checked': {
                                color: baseTheme.palette.common.white,

                                '&.Mui-disabled': {
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: baseTheme.palette.secondary.main,
                                        opacity: 0.12,
                                    },
                                },

                                '& + .MuiSwitch-track': {
                                    backgroundColor: baseTheme.palette.success.dark,
                                    opacity: 1,
                                    border: 'none',
                                },
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        },
                        thumb: {
                            width: 15,
                            height: 15,
                            boxShadow: 'unset',
                            marginTop: 2,
                            marginLeft: 2.5,
                            backgroundColor: baseTheme.palette.background.paper,
                        },
                        track: {
                            borderRadius: 16.5,
                            backgroundColor: baseTheme.palette.secondary.main,
                            opacity: 0.3,
                            transition: baseTheme.transitions.create(['background-color']),
                        },
                        checked: {},
                    },
                },
                MuiCardContent: {
                    styleOverrides: {
                        root: {
                            '&:last-child': {
                                paddingBottom: baseTheme.spacing(2),
                            },
                        },
                    },
                },
                MuiAccordion: {
                    styleOverrides: {
                        root: {
                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: 0,
                            },
                        },
                        rounded: {
                            borderRadius: 4,
                        },
                    },
                },
                MuiAccordionDetails: {
                    styleOverrides: {
                        root: {
                            flexDirection: 'column',
                        },
                    },
                },
                MuiAccordionSummary: {
                    styleOverrides: {
                        root: {
                            padding: baseTheme.spacing(0, 2, 0, 4),
                            '&.Mui-expanded': {
                                minHeight: 50,
                            },
                            '&.Mui-focusVisible': {
                                background: 'transparent',
                                border: 'none',
                                boxShadow: 'none',
                            },
                        },
                        content: {
                            margin: baseTheme.spacing(2, 0),
                            alignItems: 'center',
                            '&.Mui-expanded': {
                                margin: baseTheme.spacing(2, 0),
                            },
                        },
                        expandIconWrapper: {
                            padding: baseTheme.spacing(2, 1.5),
                            alignSelf: 'flex-start',
                        },
                    },
                },
                MuiTreeItem: {
                    styleOverrides: {
                        root: {
                            '&.Mui-selected': {
                                color: 'inherit',
                                '& .MuiTreeItem-content"': {
                                    backgroundColor: baseTheme.palette.background.default,
                                },
                            },
                        },

                        content: {
                            height: '100%',
                            padding: baseTheme.spacing(1.5, 2.5),
                            '&:hover, &:active, &:focus': {
                                backgroundColor: 'transparent !important',
                            },
                            backgroundColor: 'transparent !important',
                        },

                        group: {
                            marginLeft: 0,
                            padding: baseTheme.spacing(0, 2),
                            paddingLeft: baseTheme.spacing(4),
                        },
                    },
                },
                MuiIcon: {
                    styleOverrides: {
                        root: {
                            display: 'inline',
                        },
                    },
                },
                MuiIconButton: {
                    styleOverrides: {
                        sizeSmall: {
                            padding: '3px',
                        },
                    },
                },
            },
        }),
        custom: {
            chipDeleteWidth: 16, //Will be removed with https://services-cloud.atlassian.net/browse/IGP-1705
            chipDeleteHeight: 16, //Will be removed with https://services-cloud.atlassian.net/browse/IGP-1705
            enrichedButtonHeight: 95,
            chipDeleteIconSize: 18,
            styledSelectMenuMaxHeight: 500,
            defaultChipBoxShadow: '0px 0.8125px 0.8125px rgba(19, 64, 106, 0.06)',
            defaultChipBorderRadius: 81.25,
            defaultChipHeight: 26,
            bulkSummaryDetailsItemValueWidth: 200,
            bulkModalSummaryItemHeight: 75,
            bulkModalDialogContentHeight: 590,
            filterTextInputMaxWidth: 400,
            layoutDetailsSecondaryInfoWidth: 500,
            autocompleteBorderRadius: 6,
            autocompleteMaxHeight: 300,
            formActionButtonHeight: 44,
            formActionButtonBorderRadius: 6,
            borderWidth: 1,
            smallSizesNavbarHeight: 56,
            smallPlusButtonHeight: 32,
            chipHeight: 24,
            roundedButtonRadius: 8,
            denseButtonHeight: 36,
            pageHeaderHeight: 80,
            gridHeaderHeight: 48,
            gridCheckboxWidth: 64,
            numberRangeMaxWidth: 400,
            searchBarMaxWidth: 400,
            sideBarWidth: 260,
            sideBarMinimizedWidth: 80,
            appModuleContainerSmallScreenWidth: 1280,
            sideBarItemWidth: 200,
            sideBarItemHeight: 50,
            sideModalWidth: 334,
            topMenuHeight: 60,
            formWidth: 400,
            actionsMenuWidth: 150,
            textEditorMinHeight: 300,
            textEditorMaxHeight: 550,
            legalDocViewHeight: 700,
            limitedModalHeight: 600,
            fieldWidth: 150,
            autocompleteWidth: 340,
            fieldMinWidth: 60,
            tagSize: 16,
            tagRadius: 3,
            dataGridFontSize: '0.875rem',
            scrollBarHeight: 18,
            secondaryItemHeight: 38,
            modalContainerMinHeight: 500,
            palette: customPalette,
            button: commonButtonProps,
            transitions: {
                backgroundColor: 'background-color 1s linear',
            },
            modalActionButtonWidth: 162,
            dropdownIconWidth: '1.5em',
            buttonIconWidth: 15,
            keyValueTableCell: 55,
            customButton,
            inputIconFontSize: 12,
            editorToolbarDropdownMinWidth: 100,
            editorToolbarDropdownMaxWidth: 200,
            editorToolbarDropdownMaxHeight: 300,
            menuItemHeight,
        },
    };
}
