import {defineMessages} from 'react-intl';

import {ConnectionType} from '@config/connectionType';

export const localizedConnectionType = defineMessages({
    [ConnectionType.Mobile]: {
        id: 'connectionType_Mobile',
        defaultMessage: 'Mobile',
    },
    [ConnectionType.Corporate]: {
        id: 'connectionType_Corporate',
        defaultMessage: 'Corporate',
    },
    [ConnectionType.VPN]: {
        id: 'connectionType_VPN',
        defaultMessage: 'VPN',
    },
    [ConnectionType.Cloud]: {
        id: 'connectionType_Cloud',
        defaultMessage: 'Cloud',
    },
    [ConnectionType.Proxy]: {
        id: 'connectionType_Proxy',
        defaultMessage: 'Proxy',
    },
    [ConnectionType.Residential]: {
        id: 'connectionType_Residential',
        defaultMessage: 'Residential',
    },
    [ConnectionType.Hosting]: {
        id: 'connectionType_Hosting',
        defaultMessage: 'Hosting',
    },
    [ConnectionType.Cellular]: {
        id: 'connectionType_Cellular',
        defaultMessage: 'Cellular',
    },
    [ConnectionType.Business]: {
        id: 'connectionType_Business',
        defaultMessage: 'Business',
    },
});
