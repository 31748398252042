import {defineMessages} from 'react-intl';

export const localizedTransactionHistory = defineMessages({
    title: {
        id: 'TH_title',
        defaultMessage: 'Transaction History',
    },
    id: {
        id: 'TH_id',
        defaultMessage: 'Unique Id',
    },
    userName: {
        id: 'TH_userName',
        defaultMessage: 'Username',
    },
    transactionStatus: {
        id: 'TH_transactionStatus',
        defaultMessage: 'Transaction Status',
    },
    transactionUpdatedDate: {
        id: 'TH_transactionUpdatedDate',
        defaultMessage: 'Updated Date',
    },
    paymentMethod: {
        id: 'TH_paymentMethod',
        defaultMessage: 'Payment Method',
    },
    paymentVendor: {
        id: 'TH_paymentVendor',
        defaultMessage: 'Payment Vendor',
    },
    paymentOption: {
        id: 'TH_paymentOption',
        defaultMessage: 'Payment Option',
    },
    paymentMethodDescription: {
        id: 'TH_paymentMethodDescription',
        defaultMessage: 'Payment Method Description',
    },
    userFilter: {
        id: 'TH_userFilter',
        defaultMessage: 'User',
    },
    userFilterLabel: {
        id: 'TH_userFilterLabel',
        defaultMessage: 'Search by user or transaction',
    },
    startedFromLabel: {
        id: 'TH_startedFromLabel',
        defaultMessage: 'Started from',
    },
    startedToLabel: {
        id: 'TH_startedToLabel',
        defaultMessage: 'to',
    },
});
