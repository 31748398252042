import {combineReducers} from 'redux';

import {getContentItemsPageReducer} from '../module-shared/reducers';
import {createRealtimeStateReducer} from '../realtime-updates/reducers';
import {getFilterReducer} from '../shared/filter/reducers';

import {domain} from './types';

const stateReducer = combineReducers({
    ...createRealtimeStateReducer(domain),
    ...getFilterReducer(domain),
});

export const actionHistoryReducer = combineReducers({
    data: combineReducers(getContentItemsPageReducer(domain)),
    state: stateReducer,
});
