import {createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {Timestamp as GqlTimestamp, TimestampInput} from '@models/generated/graphql';
import {Timestamp} from '@models/shared';

export class SharedMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            GqlTimestamp,
            Timestamp,
            forMember(
                vm => vm.seconds,
                mapFrom(m => m.seconds)
            ),
            forMember(
                vm => vm.nanos,
                mapFrom(m => m.nanos)
            )
        );
        createMap(
            mapper,
            Timestamp,
            TimestampInput,
            forMember(
                vm => vm.seconds,
                mapFrom(m => m.seconds)
            ),
            forMember(
                vm => vm.nanos,
                mapFrom(m => m.nanos)
            )
        );
        createMap(
            mapper,
            Date,
            Timestamp,
            forMember(
                vm => vm.seconds,
                mapFrom(m => Math.floor(m.getTime() / 1000))
            )
        );
    }
}
