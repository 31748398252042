import {NormalizedCacheObject} from '@apollo/client';
import produce from 'immer';
import {combineLatest, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {BoUserServerFilterKeys} from '@models/bo-user';
import {UserProfile} from '@models/generated/graphql';
import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {
    BoRoleList,
    BoUser,
    EntityFetchRequestPayload,
    EntityType,
    UserProfileQueryFields,
    UserProfileServerFilterKeys,
} from '@redux/entity';
import {UserProfileService} from '@services';
import {AuthApiService, GraphQLServiceBaseObsolete, IGridService} from '@services/deprecated';
import {ApolloClientProxy} from '@services/gql-api';
import {AjaxResponse} from '@services/rest-api';
import {getFilterString} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';

import {LoginAccessStatus, UserGridItem} from './types';

export class UserService implements IGridService<BoUser, UserGridItem> {
    private readonly usersHttpService: AuthApiService;
    private readonly rolesHttpService: AuthApiService;
    private readonly mfaHttpService: AuthApiService;
    protected _client: GraphQLServiceBaseObsolete;
    private readonly _userProfileService: UserProfileService;
    private readonly _userManager: UserManagerExtended;

    constructor(
        client: ApolloClientProxy<NormalizedCacheObject>,
        tracingService: ITracingService,
        userProfileService: UserProfileService,
        userManager: UserManagerExtended
    ) {
        this._client = new GraphQLServiceBaseObsolete(client);
        this.usersHttpService = new AuthApiService('users', tracingService, userManager, 'roles', 'roleIds');
        this.rolesHttpService = new AuthApiService('roles', tracingService, userManager);
        this.mfaHttpService = new AuthApiService('mfa', tracingService, userManager);
        this._userProfileService = userProfileService;
        this._userManager = userManager;
    }

    getItem(id: string): Observable<BoUser> {
        return this.usersHttpService.getItem(id);
    }

    getItems(filter?: SearchFilter): Observable<UserGridItem[]> {
        return this.usersHttpService.getItems(filter);
    }

    getItemsPage(filter?: SearchFilter): Observable<ItemsPage<UserGridItem>> {
        return this.buildFilter(filter).pipe(
            mergeMap(updatedFilter => {
                return this.usersHttpService.getItemsPage(updatedFilter).pipe(
                    mergeMap((usersPage: ItemsPage<UserGridItem>) => {
                        const boUserIds: string[] = usersPage?.items?.map(i => i.id);
                        const filters: Filter<unknown, UserProfileServerFilterKeys>[] = [
                            {key: 'boUserId', value: boUserIds?.join('* ')},
                            {key: 'page', value: 1},
                            {key: 'size', value: boUserIds?.length},
                        ];
                        return this.getPlayerProfiles(getFilterString('', true, ...filters)).pipe(
                            map<UserProfile[], ItemsPage<UserGridItem>>(players => {
                                return {
                                    items:
                                        usersPage?.items?.map(boUser => {
                                            const player = players?.find(i => i.agent_info?.bo_agent_id === boUser.id);
                                            const playerId = player?.uid;
                                            return {...boUser, playerId: playerId, serverId: `${boUser.id}`} as UserGridItem;
                                        }) ?? [],
                                    total: usersPage?.total ?? 0,
                                };
                            })
                        );
                    })
                );
            })
        );
    }

    addItem(item: BoUser): Observable<string> {
        const user$ = this._userManager.getSignInUrl().pipe(
            mergeMap(returnUrl =>
                this.usersHttpService.addItem({
                    ...item,
                    returnUrl,
                })
            )
        );

        return user$.pipe(mergeMap(userId => this.usersHttpService.assignItems(userId, item.roles).pipe(map(() => userId))));
    }

    editItem(item: BoUser): Observable<string> {
        const user$ = this.getItem(item.id);
        return user$.pipe(
            mergeMap(oldUser => {
                const assignedRoles = item.roles?.filter(role => !oldUser.roles?.map(r => r.id).includes(role.id));
                const unAssignedRoles = oldUser.roles?.filter(role => !item.roles?.map(r => r.id).includes(role.id));

                return combineLatest([
                    this.usersHttpService.assignItems(item.id, assignedRoles),
                    this.usersHttpService.unAssignItems(item.id, unAssignedRoles),
                ]);
            }),
            map(() => item.id)
        );
    }

    reset2FA(email: string): Observable<void> {
        return this.mfaHttpService.post('/reset', {userEmail: email}).pipe(map<AjaxResponse, void>(_ => void 0));
    }

    resetPassword(email: string): Observable<void> {
        return this._userManager
            .getSignInUrl()
            .pipe(
                mergeMap(returnUrl =>
                    this.usersHttpService.post('/passwords/reset', {email, returnUrl}).pipe(map<AjaxResponse, void>(_ => void 0))
                )
            );
    }

    /**
     * @deprecated
     * <p>Should be removed. Use {@link InternalUserApiService}</p>
     */
    setLoginAccessStatus(userId: string, loginAccessStatus: LoginAccessStatus): Observable<void> {
        return this.usersHttpService.put(`/${userId}/${loginAccessStatus}`, {}).pipe(map<AjaxResponse, void>(_ => void 0));
    }

    getRoles(): Observable<BoRoleList> {
        return this.rolesHttpService
            .getItems<BoRoleList>()
            .pipe(mergeMap(r => this.rolesHttpService.getItems<BoRoleList>({paging: {page: 1, pageSize: r?.count}} as SearchFilter)));
    }

    private buildFilter(filter: SearchFilter): Observable<SearchFilter> {
        let updatedFilter$: Observable<SearchFilter> = of(filter);

        const playerIdFilterKey: BoUserServerFilterKeys = 'playerId';
        const agentPlayerIdFilter = filter?.filter?.find(i => i.key === playerIdFilterKey);
        if (agentPlayerIdFilter) {
            const playerIdsFilter: Filter<string, UserProfileServerFilterKeys> = {
                key: 'uid',
                value: agentPlayerIdFilter?.value?.toString(),
            };
            updatedFilter$ = this.getPlayerProfiles(getFilterString('', false, playerIdsFilter)).pipe(
                map<UserProfile[], SearchFilter>(res => {
                    const boUserId = res?.[0]?.agent_info?.bo_agent_id;
                    return produce(filter ?? ({} as SearchFilter), f => {
                        f.filter = [
                            ...(f.filter?.filter(i => i?.key !== playerIdFilterKey) ?? []),
                            {
                                key: nameof<UserGridItem>(i => i.id),
                                value: boUserId,
                            } as Filter,
                        ];
                    });
                })
            );
        }
        return updatedFilter$;
    }

    private getPlayerProfiles(filter: string): Observable<UserProfile[]> {
        const request: EntityFetchRequestPayload<UserProfileQueryFields> = {
            type: EntityType.UserProfile,
            filter,
            fields: ['uid', 'agent_info.bo_agent_id'],
        };
        return this._userProfileService.get(request).pipe(map(res => res?.responsePayload?.items as UserProfile[]));
    }
}
