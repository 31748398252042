import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    editableTextContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    editableTextInput: {
        width: '100%',
        background: 'transparent',

        '& > .Mui-disabled': {
            color: 'inherit',
        },
        '& .MuiInputBase-input': {
            textOverflow: 'ellipsis',
        },
    },
    editableTextErrorMessage: {
        ...(theme.typography.caption as CSSObject),
        padding: theme.spacing(0.5),
        whiteSpace: 'normal',
    },
    editableTextReadonly: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
