import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {localizedFeatureAccessStatus} from '../../app/intl/shared-resources/featureAccessStatus';

export type LobbyAccessStatusValue = {
    lobby_access_status: FeatureAccessStatus;
};

export const useLobbyAccessStatusFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: LobbyAccessStatusValue = JSON.parse(json);
    return value?.lobby_access_status ? [{key: formatMessage(localizedFeatureAccessStatus[value?.lobby_access_status])}] : null;
};
