import {ItemPage} from '@services/entity/mapper';

export function getPageItemWithSummary<TItem extends object, TSummary extends object>(
    queryResponse: {items?: TItem[]; summary?: TSummary; total_count?: number},
    extendedItem: TItem & {is_summary?: boolean}
): ItemPage {
    return queryResponse
        ? {
              items: queryResponse?.summary ? [...queryResponse?.items, extendedItem] : queryResponse?.items,
              total_count: queryResponse?.total_count,
          }
        : undefined;
}
