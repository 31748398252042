import {AggregatedFinanceSummary, Ggr, TransactionAttemptsSummary} from '@models/generated/graphql';
import {TimestampKeys} from '@models/shared';

export const aggregatedFinanceSummaryDataRangePrefix = 'aggregationRange' as const;

export type AggregatedFinanceSummaryFilterKeys =
    | 'uid'
    | `${typeof aggregatedFinanceSummaryDataRangePrefix}.from`
    | `${typeof aggregatedFinanceSummaryDataRangePrefix}.to`;

type AggregatedFinanceSummaryKeys = keyof AggregatedFinanceSummary;
const depositKey: AggregatedFinanceSummaryKeys = 'deposit';
const ggrKey: AggregatedFinanceSummaryKeys = 'ggr';
const withdrawalKey: AggregatedFinanceSummaryKeys = 'withdrawal';
const startAtKey: AggregatedFinanceSummaryKeys = 'start_at';
const createdAtKey: AggregatedFinanceSummaryKeys = 'created_at';
const andAtKey: AggregatedFinanceSummaryKeys = 'end_at';

export type AggregatedFinanceSummaryQueryFields =
    | AggregatedFinanceSummaryKeys
    | `${typeof depositKey}.${keyof TransactionAttemptsSummary}`
    | `${typeof depositKey}.${keyof TransactionAttemptsSummary}`
    | `${typeof ggrKey}.${keyof Ggr}`
    | `${typeof withdrawalKey}.${keyof TransactionAttemptsSummary}`
    | `${typeof startAtKey}.${TimestampKeys}`
    | `${typeof createdAtKey}.${TimestampKeys}`
    | `${typeof andAtKey}.${TimestampKeys}`;
