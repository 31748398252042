import React, {PropsWithChildren} from 'react';
import {Container} from 'inversify';

type InversifyContextProps = {
    container: Container;
};

export const InversifyContext = React.createContext<InversifyContextProps>({container: null});

type InversifyProviderProps = PropsWithChildren<InversifyContextProps>;

export const InversifyProvider: React.FC<InversifyProviderProps> = ({children, container}: InversifyProviderProps) => {
    return <InversifyContext.Provider value={{container}}>{children}</InversifyContext.Provider>;
};
