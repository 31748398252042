import React, {FC} from 'react';
import {
    CenterAlignButton,
    DecreaseIndentButton,
    IncreaseIndentButton,
    JustifyAlignButton,
    LeftAlignButton,
    RedoButton,
    RightAlignButton,
    ToggleBlockquoteButton,
    ToggleBoldButton,
    ToggleBulletListButton,
    ToggleCodeBlockButton,
    ToggleCodeButton,
    ToggleItalicButton,
    ToggleOrderedListButton,
    ToggleStrikeButton,
    ToggleSubscriptButton,
    ToggleSuperscriptButton,
    ToggleUnderlineButton,
    Toolbar,
    UndoButton,
} from '@remirror/react';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon, IconColor} from '@components/icons';
import {CustomTheme} from '@style';

import {EmojiButton} from './AddEmojiButton';
import {AddIframeButton} from './AddIframeButton';
import {AddImageButton} from './AddImageButton';
import {FontFamilySelect} from './FontFamilySelect';
import {FontSizeSelect} from './FontSizeSelect';
import {HeadingSelect} from './HeadingSelect';
import {AddLinkButton, RemoveLinkButton} from './LinkButtons';
import {TextColorButton} from './SetTextColorButton';
import {ToolbarIcon} from './ToolbarIcon';

export type EditorToolbarsButtons =
    | 'bold'
    | 'italic'
    | 'strike'
    | 'code'
    | 'heading'
    | 'blockquote'
    | 'code-block'
    | 'history'
    | 'add-link'
    | 'remove-link'
    | 'bullet-list'
    | 'ordered-list'
    | 'text-color'
    | 'font-family'
    | 'font-size'
    | 'underline'
    | 'subscript'
    | 'superscript'
    | 'iframe'
    | 'text-align'
    | 'image'
    | 'indent-outdent'
    | 'emoji';

export type EditorToolbarProps = {
    buttons?: EditorToolbarsButtons[];
    customButtons?: React.ReactElement[];
};

export const toolbarButtonClassName = 'editor-toolbar-button';

const useToolbarStyles = makeStyles()((theme: CustomTheme) => ({
    editorToolbar: {
        '& .MuiStack-root': {
            overflow: 'hidden',
            alignItems: 'center',
            height: theme.spacing(4),
            margin: 0,
            marginBottom: theme.spacing(1),
            padding: theme.spacing(0, 0.25),
            backgroundColor: theme.custom.palette.content.editorBackground,
            borderColor: theme.custom.palette.content.editorBackground,
            gap: theme.spacing(0.25),
            '&>:not(style)~:not(style)': {
                marginLeft: 0,
            },
            [`& > .${toolbarButtonClassName}`]: {
                display: 'flex',
                gap: theme.spacing(0.25),
                '& > .MuiButtonBase-root, & > * > .MuiButtonBase-root': {
                    border: 0,
                    backgroundColor: theme.custom.palette.content.editorBackground,
                    boxShadow: 'none',
                    maxWidth: theme.spacing(5),
                    minHeight: theme.spacing(3.5),
                    color: theme.palette.text.primary,
                    borderRadius: theme.spacing(0.25),
                    padding: theme.spacing(0.5, 1),
                    '&:hover': {
                        boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.2)',
                    },
                    '&.Mui-selected': {
                        color: theme.palette.text.primary,
                        backgroundColor: theme.custom.palette.content.editorBackground,
                        borderLeft: '1px solid',
                        borderTop: '1px solid',
                        borderColor: 'rgba(0, 0, 0, 0.2)',
                        boxShadow: 'none',
                        ':hover': {
                            backgroundColor: theme.custom.palette.content.editorBackground,
                        },
                    },
                },
                '& > .MuiButtonBase-root': {
                    padding: theme.spacing(0.5),
                },
            },
        },
    },
    editorToolbarBoldIcon: {
        fontSize: theme.spacing(2.75),
        fontWeight: 'bold !important',
    },
}));

export function EditorToolbar({buttons, customButtons}: EditorToolbarProps) {
    const {classes} = useToolbarStyles();
    const toolbarButtonsMapper: Record<EditorToolbarsButtons, FC[]> = {
        bold: [
            () => (
                <ToggleBoldButton
                    icon={<ToolbarIcon color={IconColor.SuitBlack} icon={CustomIcon.Bold} className={classes.editorToolbarBoldIcon} />}
                />
            ),
        ],
        italic: [() => <ToggleItalicButton icon={<ToolbarIcon color={IconColor.SuitBlack} icon={CustomIcon.Italic} />} />],
        strike: [ToggleStrikeButton],
        code: [ToggleCodeButton],
        heading: [HeadingSelect],
        blockquote: [ToggleBlockquoteButton],
        'code-block': [ToggleCodeBlockButton],
        history: [UndoButton, RedoButton],
        'add-link': [AddLinkButton],
        'remove-link': [RemoveLinkButton],
        'bullet-list': [ToggleBulletListButton],
        'ordered-list': [ToggleOrderedListButton],
        'text-color': [TextColorButton],
        'font-family': [FontFamilySelect],
        'font-size': [FontSizeSelect],
        underline: [ToggleUnderlineButton],
        subscript: [ToggleSubscriptButton],
        superscript: [ToggleSuperscriptButton],
        iframe: [AddIframeButton],
        'text-align': [LeftAlignButton, CenterAlignButton, RightAlignButton, JustifyAlignButton],
        image: [AddImageButton],
        'indent-outdent': [IncreaseIndentButton, DecreaseIndentButton],
        emoji: [EmojiButton],
    };
    return (
        <div className={classes.editorToolbar}>
            <Toolbar>
                {buttons?.map((button, i) => {
                    const components = toolbarButtonsMapper[button];
                    return (
                        <div className={toolbarButtonClassName} style={{order: i}}>
                            {components.map(Component => (
                                <Component />
                            ))}
                        </div>
                    );
                })}
                {customButtons}
            </Toolbar>
        </div>
    );
}
