import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {loadLocalizationResourcesAction, localizationResourcesLoadedAction} from './actions';
import {defaultLocale} from './const';

const localizedMessagesReducer = createReducer({}).handleAction(localizationResourcesLoadedAction, (_, action) => action.payload);
const localeReducer = createReducer(defaultLocale).handleAction(loadLocalizationResourcesAction, (_, action) => action.payload);
export const localizationReducer = combineReducers({
    messages: localizedMessagesReducer,
    locale: localeReducer,
});
