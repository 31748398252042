import {constructUsing, createMap, forMember, mapFrom, Mapper, mapWithArguments} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {BackpackItem} from '@models/generated/graphql';
import {BackpackQueryFields} from '@redux/entity';
import {formatTimestamp} from '@utils/date';

import {BackpackViewModel, BackpackViewModelKeys} from './types';

export type BackpackMappingExtraArgs = {
    currency: string;
};

export class BackpackMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            BackpackItem,
            BackpackViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_name,
                mapFrom(m => m.bonus_name)
            ),
            forMember(
                vm => vm.bonus_status,
                mapFrom(m => m.bonus_status)
            ),
            forMember(
                vm => vm.created_at,
                mapFrom(m => formatTimestamp(m.created_at, 'date'))
            ),
            forMember(
                vm => vm.marketing_code,
                mapFrom(m => m.marketing_code)
            ),
            forMember(
                vm => vm.serial_number,
                mapFrom(m => m.serial_number)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.used_at,
                mapFrom(m => formatTimestamp(m.used_at, 'date'))
            ),
            forMember(
                vm => vm.value,
                mapFrom(m => m.value)
            ),
            forMember(
                vm => vm.tool_label,
                mapFrom(m => m.tool_label)
            ),
            forMember(
                vm => vm.currency,
                mapWithArguments((_, {currency}: BackpackMappingExtraArgs) => currency)
            )
        );
        createMap<BackpackViewModelKeys[], BackpackQueryFields[]>(
            mapper,
            nameof<BackpackViewModelKeys>(),
            nameof<BackpackQueryFields>(),
            constructUsing((m, _) => {
                const mapper: Record<BackpackViewModelKeys, BackpackQueryFields[]> = {
                    bonus_id: ['bonus_id'],
                    bonus_name: ['bonus_name'],
                    bonus_status: ['bonus_status'],
                    'created_at.seconds': ['created_at.seconds'],
                    marketing_code: ['marketing_code'],
                    serial_number: ['serial_number'],
                    uid: ['uid'],
                    'used_at.seconds': ['used_at.seconds'],
                    value: ['value'],
                    tool_label: ['tool_label'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
    }
}
