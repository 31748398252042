import {gql, NormalizedCacheObject} from '@apollo/client';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {PhoneNumber, Query, UserProfile} from '@models/generated/graphql';
import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {BaseHttpService} from '@services/deprecated';
import {PlayerManagementApiService} from '@services/deprecated';
import {ReadOrUpdateRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlSearchFilter} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';
import {AjaxResponse} from '@services/rest-api';
import {toGQLDateFilter, toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';
import {IModuleItem} from '../../features/module-shared/types';
import {registrationInfoFullSearch} from '../../features/user-profile/types';

import {GQLPlayerProfileSearchFilter, PlayerProfile, PlayerProfileGridItem} from './types';

export class RegistrationInfoService extends ReadOrUpdateRealtimeGridServiceBase<PlayerProfile, PlayerProfileGridItem, UserProfile> {
    private readonly playerHttpService: BaseHttpService;
    constructor(client: ApolloClientProxy<NormalizedCacheObject>, userManager: UserManagerExtended, tracingService: ITracingService) {
        super(client);
        this.playerHttpService = new PlayerManagementApiService(tracingService, userManager, 'player');
    }

    getItems(_filter?: SearchFilter): Observable<PlayerProfileGridItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsPage(filter?: SearchFilter): Observable<ItemsPage<PlayerProfileGridItem>> {
        return this._client.executeQuery(this.getItemsPageQuery(), this.getGQLVariables(filter)).pipe(
            map<Query, ItemsPage<PlayerProfileGridItem>>(res => {
                return {
                    items:
                        res?.getUserProfiles?.items?.map((i, index) =>
                            this.mapModels({id: `${index}`, serverId: i?.uid} as PlayerProfileGridItem, i)
                        ) ?? [],
                    total: res?.getUserProfiles?.total_count ?? 0,
                };
            })
        );
    }

    getItem(_id: string): Observable<PlayerProfile> {
        throw new Error('Method not implemented.');
    }

    editItem(_item: IModuleItem): Observable<string> {
        throw new Error('Method not implemented.');
    }

    resetPasscode(id: string): Observable<AjaxResponse> {
        return this.playerHttpService.post(`/${id}/restore-code/resend`, {});
    }

    //TODO: pass model instead of GridCellValue
    editEmail(id: string, email: string): Observable<AjaxResponse> {
        return this.playerHttpService.patch(`/${id}/email`, {email});
    }

    editPhone(id: string, phone: PhoneNumber): Observable<AjaxResponse> {
        return this.playerHttpService.patch(`/${id}/phone`, {areaCode: phone?.area, mobile: phone?.mobile});
    }

    disableTwoFA(id: string, model: boolean): Observable<AjaxResponse> {
        return !model ? this.playerHttpService.post(`/${id}/two-factor-authentication/disable`, {}) : of();
    }

    protected getItemsPageQuery() {
        return gql`
            fragment TimeInSec on Timestamp {
                seconds
            }

            fragment User on UserProfile {
                uid
                username
                first_name
                last_name
                nickname
                birthday {
                    ...TimeInSec
                }
                date_of_joining {
                    ...TimeInSec
                }
                account_status
                security_settings {
                    is_2fa_enabled
                    is_challenge_questions_enabled
                }
                identity_info {
                    last_ssn_digits
                }
                contact {
                    email
                    mobile {
                        area
                        mobile
                    }
                }
                address {
                    address
                    city
                    state
                    post_code
                    country_info {
                        name
                    }
                }
                ip_addresses
                language
                gender
            }

            query GetUserItems($filter: UserProfileFilter, $sort: Sorting, $start: Int, $end: Int) {
                getUserProfiles(filter: $filter, sort: $sort, start: $start, end: $end) {
                    items {
                        ...User
                    }
                    total_count
                }
            }
        `;
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        const filter = {
            text: [
                ...Object.keys(userProfileTextFilterFieldsMapper).map(key =>
                    toGQLTextFilter(filters, userProfileTextFilterFieldsMapper[key], key)
                ),
            ],
        } as GQLPlayerProfileSearchFilter;

        const dateFilter = toGQLDateFilter(
            filters,
            nameof<UserProfile>(m => m.date_of_joining)
        );
        if (dateFilter) {
            filter.date_of_joining = dateFilter;
        }
        return filter;
    }
}

const userProfileTextFilterFieldsMapper: Record<string, string[]> = {
    [registrationInfoFullSearch]: [
        nameof<UserProfile>(w => w.uid),
        nameof<UserProfile>(w => w.username),
        nameof<UserProfile>(w => w.first_name),
        nameof<UserProfile>(w => w.last_name),
        nameof.full<UserProfile>(w => w.contact.email),
        nameof.full<UserProfile>(w => w.address.country_info.name),
    ],
    uid: [nameof<UserProfile>(m => m.uid)],
    first_name: [nameof<UserProfile>(m => m.first_name)],
    last_name: [nameof<UserProfile>(m => m.last_name)],
    username: [nameof<UserProfile>(m => m.username)],
    'contact.email': [nameof.full<UserProfile>(m => m.contact.email)],
    'address.country_info.name': [nameof.full<UserProfile>(m => m.address.country_info.name)],
};
