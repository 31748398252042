import React from 'react';
import {StyledComponentProps} from '@mui/material/styles';

import {MessageOrientation} from '@components/alerts/Message';
import {EditableAutocompleteProps} from '@components/editable/EditableAutocomplete';
import {EntityType, Filter} from '@redux/entity';
import {ViewType} from '@redux/view';
import {CountriesApiFilterKeys, countryFilterKey} from '@services/rest-api/countriesApiService';

import {Country} from '../types';

import {ViewAutocomplete} from './ViewAutocomplete';
import {localized} from './ViewAutocomplete.localize';

type CountryAutocompleteBaseProps = Omit<EditableAutocompleteProps<Filter<CountriesApiFilterKeys>>, 'disabled'>;

export type CountryAutocompleteProps = CountryAutocompleteBaseProps & StyledComponentProps & {viewType: ViewType; multiple?: boolean};

export const CountryAutocompleteBase = (props: CountryAutocompleteProps) => {
    const value = props.multiple ? getMultipleModeSelectedValue(props) : getSelectedValue(props);

    function getSelectedValue({value, label}: Pick<CountryAutocompleteProps, 'value' | 'label'>): Country {
        return value ? ({iso2Code: value, name: label} as Country) : null;
    }
    function getMultipleModeSelectedValue({value}: Pick<CountryAutocompleteProps, 'value'>): Country[] {
        return value?.length ? (value as string[])?.map<Country>(v => ({iso2Code: v} as Country)) : [];
    }

    return (
        <ViewAutocomplete
            {...props}
            value={value}
            viewType={props.viewType}
            entityType={EntityType.Country}
            displayName={localized.countryAutocompleteDisplayName}
            fields={[nameof<Country>(i => i.iso2Code)]}
            optionMapper={(value: Country) => (value ? {label: value.name ?? '', value: value.iso2Code} : null)}
            valueMapper={(value: Country) => (value ? value.iso2Code : null)}
            filterKey={countryFilterKey}
            hasLargeOptions={true}
            errorMessageOrientation={MessageOrientation.Floating}
            validateFilter={() => true}
            mode="client"
        />
    );
};
