import React, {useContext} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {RadioInputColor, RuleType, StyledInput, StyledRadioInputColorGroup, useValidationFormatter} from '@components/input';
import {ModalContext, ModalFooter} from '@components/modal';

import {useLabelGroupModify} from '../../block-label-group-actions/hooks';
import {LabelGroup, LabelGroupForm} from '../types';

import {BlockPlayerLabelGroupIncludedLabels} from './BlockPlayerLabelGroupIncludedLabels';

const localized = defineMessages<string, MessageDescriptor>({
    cancel: {
        id: 'blockPlayerLabelGroupModal_cancel',
        defaultMessage: 'Cancel',
    },
    submit: {
        id: 'blockPlayerLabelGroupModal_submit',
        defaultMessage: 'Submit',
    },
    nameFieldLabel: {
        id: 'blockPlayerLabelGroupModal_nameFieldLabel',
        defaultMessage: 'Group name',
    },
    nameFieldRequiredValidationMessage: {
        id: 'blockPlayerLabelGroupModal_nameFieldRequiredValidationMessage',
        defaultMessage: 'Group name is required',
    },
    colorFieldLabel: {
        id: 'blockPlayerLabelGroupModal_colorFieldLabel',
        defaultMessage: 'Color Group',
    },
    colorFieldLabelRequiredValidationMessage: {
        id: 'blockPlayerLabelGroupModal_colorFieldLabelRequiredValidationMessage',
        defaultMessage: 'Color Group is required',
    },
    includedLabels: {
        id: 'blockPlayerLabelGroupModal_includedLabels',
        defaultMessage: 'Included labels',
    },
});

const useClasses = makeStyles()(theme => ({
    blockPlayerLabelGroupModalContent: {
        height: '400px',
    },
    blockPlayerLabelGroupModalIncludedLabels: {
        display: 'flex',
        columnGap: theme.spacing(1),
        marginTop: theme.spacing(1.5),
    },
}));

type BlockPlayerLabelGroupCreateProps = {
    labelGroup?: LabelGroup;
};

export function BlockPlayerLabelGroupModal({labelGroup}: BlockPlayerLabelGroupCreateProps) {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();
    const {closeModal} = useContext(ModalContext);
    const validationFormatter = useValidationFormatter();
    const {colors, saveGroup} = useLabelGroupModify(labelGroup);
    const {control, handleSubmit, formState, watch} = useForm<LabelGroupForm>({mode: 'onChange', defaultValues: labelGroup});
    const fields = watch();

    function handleCancel() {
        closeModal();
    }

    function handleSave(data: LabelGroupForm) {
        saveGroup(data);
        closeModal();
    }

    return (
        <>
            <form className={classes.blockPlayerLabelGroupModalContent}>
                <Controller control={control} name="id" render={({field}) => <input hidden {...field}></input>} />
                <Controller
                    control={control}
                    name="name"
                    render={({field, fieldState}) => (
                        <StyledInput
                            key={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            fieldState={fieldState}
                            label={formatMessage(localized.nameFieldLabel)}
                            labelVariant="default"
                        />
                    )}
                    rules={{
                        required: validationFormatter(RuleType.Required, localized.nameFieldRequiredValidationMessage),
                    }}
                />
                <Controller
                    control={control}
                    name="color"
                    render={({field}) => (
                        <StyledRadioInputColorGroup
                            name={field.name}
                            onChange={field.onChange}
                            defaultValue={labelGroup?.color}
                            label={formatMessage(localized.colorFieldLabel)}
                            labelVariant="default"
                        >
                            {colors.map(color => (
                                <RadioInputColor key={color} color={color} />
                            ))}
                        </StyledRadioInputColorGroup>
                    )}
                    rules={{
                        required: validationFormatter(RuleType.Required, localized.fieldAmountLabel),
                    }}
                />
                {labelGroup?.labels?.length > 0 ? (
                    <BlockPlayerLabelGroupIncludedLabels
                        labels={labelGroup.labels}
                        color={fields.color ?? labelGroup.color}
                        label={formatMessage(localized.includedLabels)}
                        labelVariant="default"
                    />
                ) : null}
            </form>
            <ModalFooter>
                <Button key="cancel" label={localized.cancel} onClick={handleCancel} />
                <Button
                    key="submit"
                    color="primary"
                    label={localized.submit}
                    onClick={handleSubmit(handleSave)}
                    disabled={!formState.isValid || (fields.name === labelGroup?.name && fields.color === labelGroup?.color)}
                />
            </ModalFooter>
        </>
    );
}
