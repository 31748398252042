import {createAsyncAction} from 'typesafe-actions';

import {GameTemplateSettings} from '@models/game-template';
import {ManagedGameType} from '@redux/entity';
import {
    CreateTemplateServiceResponsePayload,
    DisableGameTemplatePayload,
    DisableTemplateServiceResponsePayload,
    EditTemplateServiceResponsePayload,
    EnableGameTemplatePayload,
    EnableTemplateServiceResponsePayload,
} from '@services/rest-api/plo5GameManagementApiService';

export const domain = 'block-game-template-actions';

export type GameTemplateUpdateRequestPayload = {
    template: GameTemplateSettings;
    gameType: ManagedGameType;
};

export type GameTemplateCreateRequestPayload = GameTemplateUpdateRequestPayload;

export type GameTemplateEditRequestPayload = GameTemplateUpdateRequestPayload & {
    id: string;
};

export const gameTemplateActions = {
    createTemplate: createAsyncAction(
        `${domain}/createTemplateRequest`,
        `${domain}/createTemplateSuccess`,
        `${domain}/createTemplateFailed`
    )<GameTemplateCreateRequestPayload, void, CreateTemplateServiceResponsePayload>(),

    editTemplate: createAsyncAction(`${domain}/editTemplateRequest`, `${domain}/editTemplateSuccess`, `${domain}/editTemplateFailed`)<
        GameTemplateEditRequestPayload,
        EditTemplateServiceResponsePayload,
        EditTemplateServiceResponsePayload
    >(),

    enableTemplate: createAsyncAction(
        `${domain}/enableTemplateRequest`,
        `${domain}/enableTemplateSuccess`,
        `${domain}/enableTemplateFailure`
    )<EnableGameTemplatePayload, EnableTemplateServiceResponsePayload, EnableTemplateServiceResponsePayload>(),

    disableTemplate: createAsyncAction(
        `${domain}/disableTemplateRequest`,
        `${domain}/disableTemplateSuccess`,
        `${domain}/disableTemplateFailure`
    )<DisableGameTemplatePayload, DisableTemplateServiceResponsePayload, DisableTemplateServiceResponsePayload>(),
};
