import React from 'react';
import {defineMessages} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {AgentPlayerReportingViewModelKeys} from '@models/agent-player-reporting';
import {ModuleName} from '@models/modules';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';
import {AgentPlayerReportingServerFilterKeys} from '@redux/entity';
import {extendedViewCleanDelay, ViewType} from '@redux/view';
import {getTodayDateRange} from '@utils/date';

import {Filter} from '../common/types';
import {
    AgentPlayerReportingQueryFilters,
    DataGridAgentPlayerReporting,
    useAgentPlayerReportingKr,
} from '../features/block-agent-player-reporting-list';
import {useDefaultReferrerFilter} from '../features/block-agent-profile';

const localized = defineMessages({
    title: {
        id: 'AgentManagementPlayerReportKrDaily_title',
        defaultMessage: 'KR Agent Player Report Daily',
    },
});

function AgentKrDailyReport() {
    const viewType: ViewType = 'AgentKrDailyReport';
    const columns: AgentPlayerReportingViewModelKeys[] = [
        'referrer_agent_id',
        'referrer_agent_username',
        'uid_text',
        'revenue_share_type',
        'referee_type',
        'total_share',
        'poker_share',
        'casino_share',
    ];
    const nonColumnKeys: AgentPlayerReportingViewModelKeys[] = ['has_summary'];
    const keys: AgentPlayerReportingViewModelKeys[] = [...columns, ...nonColumnKeys];

    const transactionStartedDateRange = getTodayDateRange();
    const defaultFilters: Filter<unknown, AgentPlayerReportingServerFilterKeys>[] = [
        {key: 'transactionStartedTs.from', value: transactionStartedDateRange[0]},
        {key: 'transactionStartedTs.to', value: transactionStartedDateRange[1]},
    ];

    const {
        items,
        summary,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useAgentPlayerReportingKr({
        viewType,
        fields: keys,
        displayName: localized.title,
        defaultSorting: [{field: 'total_share', sort: 'desc'}],
        syncWithUrl: true,
        defaultFilters,
        validateFilter: null,
        cleanDelay: extendedViewCleanDelay,
    });

    const {boAgentPlayerId} = useDefaultReferrerFilter({viewType, onFilterChange: handleFilterChange});

    return (
        <LayoutQueue
            header={<PageHeader header={localized.title} />}
            toolbar={
                <Toolbar>
                    <ToolbarItemsContainer primary>
                        <ToolbarItems streched>
                            <AgentPlayerReportingQueryFilters
                                viewType="AgentManagementReportKrDailyFilters"
                                model={filterString}
                                onChange={handleFilterChange}
                                availableFilters={['uid', 'agent_no_downline', 'started_at']}
                                boAgentPlayerId={boAgentPlayerId}
                            />
                        </ToolbarItems>
                    </ToolbarItemsContainer>
                </Toolbar>
            }
            body={
                <DataGridAgentPlayerReporting
                    columns={columns}
                    rows={items}
                    paging={{page: searchFilter?.paging?.page, pageSize: searchFilter?.paging?.pageSize, rowCount: totalCount}}
                    sortModel={{sortModel: searchFilter?.sorting}}
                    pinnedRow={summary}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortChange={handleSortChange}
                />
            }
        />
    );
}

export const agentKrDailyReportResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'AgentKrDailyReport',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageAgentReportKr), new Policy(ModuleName.PageAgentReportKr, null, 'read')]),
});

export default withTraceErrorBoundary(withPolicyAccessCheck(AgentKrDailyReport, agentKrDailyReportResource), ErrorModal);
