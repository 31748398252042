import React, {createContext, PropsWithChildren} from 'react';

import {IDbUserSettingsStorage} from '../types';

type UserSettingsContextProps = {
    storage: IDbUserSettingsStorage;
};

export const UserSettingsContext = createContext<UserSettingsContextProps>(null);

type UserSettingsProviderProps = PropsWithChildren<{
    storage: IDbUserSettingsStorage;
}>;

export function UserSettingsProvider({storage, children}: UserSettingsProviderProps) {
    return <UserSettingsContext.Provider value={{storage}}>{children}</UserSettingsContext.Provider>;
}
