import type {AuthServer} from '../common/auth/config';
import {Jurisdiction} from '../features/app/config/types';

export enum Environment {
    Dev = 'DEV',
    Stage = 'STG',
    QA = 'QA',
    Prod = 'PROD',
}

type AuthenticationType = 'oidc' | 'api-key' | 'none';

export interface DynamicConfig {
    name: string;
    version: string;
    env: Environment;
    application: 'operations' | 'agents';
    auth: AuthServer;
    jurisdiction: Jurisdiction;
    authApiUrl: string;
    authClientId: string;
    authSilentRefreshPage: string;
    configApiUrl: string;
    igpGraphQlHttpUrl: string;
    igpGraphQLWsUrl: string;
    igpGraphQlAuthType: AuthenticationType;
    igpGraphQlApiKey: string;
    peGraphQlApiUrl: string;
    playerManagementApiUrl: string;
    paymentManagementApiUrl: string;
    plo5GameManagementApiUrl: string;
    ruleApiUrl: string;
    netverifyUrl: string;
    attachmentsUrl: string;
    countriesApiUrl: string;
    otelUrl: string;
    cmsUrl: string;
    sportsbookApiUrl: string;
}

export const defaultConfig: DynamicConfig = {
    env: Environment.Dev,
    jurisdiction: Jurisdiction.WPTO,
    name: 'admin-panel',
    version: '',

    application: 'operations',
    auth: 'internal',
    authApiUrl: 'https://auth.dev.wptg.a5-labs-cloud.com',
    authClientId: 'js',
    authSilentRefreshPage: 'silent-refresh.html',

    playerManagementApiUrl: 'https://connector.dev.wptg.a5-labs-cloud.com',
    paymentManagementApiUrl: 'https://payment-admin.dev.wptg.a5-labs-cloud.com',
    configApiUrl: 'https://config.dev.wptg.a5-labs-cloud.com',
    countriesApiUrl: 'https://countries.dev.wptg.a5-labs-cloud.com',
    plo5GameManagementApiUrl: 'https://plo5.dev.wptg.a5-labs-cloud.com',
    ruleApiUrl: '',

    netverifyUrl: 'https://customer-portal.netverify.com/app/#/verifications/{}?transactionReference={}',
    attachmentsUrl: 'https://attachments.dev.wptg.a5-labs-cloud.com',
    otelUrl: 'https://otel-collector.dev.wptg.a5-labs-cloud.com/v1',

    igpGraphQlHttpUrl: 'https://pam-gql.dev.wpto.a5-labs-cloud.com',
    igpGraphQLWsUrl: 'wss://pam-gql.dev.wpto.a5-labs-cloud.com',
    igpGraphQlAuthType: 'oidc',
    igpGraphQlApiKey: 'da2-mdllpoqjpbf6dlszmwnjdnrlom',
    peGraphQlApiUrl: 'https://connector.dev.wptg.a5-labs-cloud.com/graphql',
    cmsUrl: 'https://wptg-strapi-dev.a5labsapp.co',
    sportsbookApiUrl: 'https://internal-apisport.dev.wptg.a5-labs-cloud.com',
};

declare const __APP_VERSION: string;
declare const __APP_NAME: string;

class GlobalConfig {
    config: DynamicConfig = defaultConfig;
    notDefinedYet = true;

    public getConfig(): DynamicConfig {
        if (this.notDefinedYet) {
            try {
                this.config = (window as any)?.__env ?? defaultConfig;
                this.config.name = __APP_NAME;
                this.config.version = __APP_VERSION;
            } catch (ReferenceError) {
                // For test environment
                this.config = defaultConfig;
            }
        }

        return this.config;
    }
}

export const globalConfig = new GlobalConfig();
