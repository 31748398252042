import {createAction} from 'typesafe-actions';

import {Notification} from './types';

export type NotificationShowPayload = Omit<Notification, 'id'>;

type NotificationHidePayload = {
    id: string;
};

const domain = 'notifications';

export const notificationsActions = {
    show: createAction(`${domain}/show`)<NotificationShowPayload>(),

    hide: createAction(`${domain}/hide`)<NotificationHidePayload>(),
};
