import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {MessageDescriptor, useIntl} from 'react-intl';
import {Box} from '@mui/material';

import {MessageOrientation} from '@components/alerts/Message';
import {EditableText} from '@components/editable/EditableText';
import {FormError, RuleType, useValidationFormatter} from '@components/input';
import {agentReadPolicies, customerSupportUpdatePermissions} from '@models/permissions/permissions';
import {AsyncAction} from '@redux';

import {useMessageFormatter} from '../../../app/intl/hooks';
import {LackOfPermissionIndicator, useMultiplePermissions, withMultiplePermission} from '../../../app/permission/PermissionHoc';
import {useReduxForm, useReduxFormRequestMode} from '../../../shared/form/hooks';
import {UserProfileModel} from '../types';

type UserProfileTextFieldModel = {
    fieldValue: string;
    userId: string;
};

type UserProfileTextFieldProps = {
    action: AsyncAction<string, UserProfileModel, string, unknown, string, unknown>;
    placeholder: string | MessageDescriptor;
    submitModelMapper: (submitModel: UserProfileModel) => UserProfileTextFieldModel;
    formModelMapper: (formModel: UserProfileTextFieldModel) => UserProfileModel;
    isRequired?: boolean;
};

type UserProfileTextFieldInnerProps = UserProfileModel & {
    disabled?: boolean;
};

/**
 * @deprecated
 */
export const withUserProfileTextField = (props: UserProfileTextFieldProps) =>
    withMultiplePermission(
        (model: UserProfileTextFieldInnerProps) => {
            const isEditable = useMultiplePermissions({allowedPermissions: customerSupportUpdatePermissions});
            const validationMessageFormatter = useValidationFormatter();
            const formatter = useMessageFormatter();
            const {formatMessage} = useIntl();

            const getFieldValue = () => (model?.userId ? props.submitModelMapper(model) : undefined);

            const currentFieldValue = getFieldValue();
            const [fieldValueModel, setFieldValueModel] = useState<UserProfileTextFieldModel>(currentFieldValue);
            const {control, state, submit, cancel, handleSubmit} = useReduxForm<UserProfileTextFieldModel, UserProfileModel>({
                initialModel: fieldValueModel,
                asyncAction: props.action,
                map: props.formModelMapper,
            });

            const {requestMode, resetRequestMode} = useReduxFormRequestMode<UserProfileModel>(props.action);

            useEffect(() => {
                setFieldValueModel(currentFieldValue);
            }, [currentFieldValue?.fieldValue, currentFieldValue?.userId]);

            return (
                <form onSubmit={handleSubmit(submit)}>
                    <Controller
                        render={({field, fieldState}) => (
                            <Box>
                                <EditableText
                                    mode={requestMode}
                                    value={field.value}
                                    onChange={e => {
                                        field.onChange(e);
                                        resetRequestMode();
                                    }}
                                    onSave={() => handleSubmit(submit)}
                                    onCancel={cancel}
                                    placeholder={formatter(props.placeholder)}
                                    disabled={!isEditable || model.disabled}
                                    errorMessage={state.errorMessage ? formatMessage(state.errorMessage) : null}
                                    errorMessageOrientation={MessageOrientation.Floating}
                                ></EditableText>
                                {fieldState.invalid ? <FormError>{fieldState.error.message}</FormError> : <></>}
                            </Box>
                        )}
                        control={control}
                        name="fieldValue"
                        defaultValue={fieldValueModel?.fieldValue}
                        rules={{
                            required: props.isRequired ? validationMessageFormatter(RuleType.Required, props.placeholder) : null,
                        }}
                    ></Controller>
                </form>
            );
        },
        {
            allowedPermissions: customerSupportUpdatePermissions,
            restrictedPermissions: agentReadPolicies,
            indicator: LackOfPermissionIndicator.Disabled,
        }
    );
