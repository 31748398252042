import React from 'react';
import {DeleteOutlined} from '@mui/icons-material';

import {Button, IconButton, OutlinedButton, OutlinedButtonWithChip} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons/types';

import {Wrapper, WrapperChild} from './WrapperDev';

export const Buttons = () => {
    return (
        <Wrapper label="Buttons">
            <WrapperChild>
                <Button label="Button"></Button>
            </WrapperChild>
            <WrapperChild>
                <Button label="Disable" disabled></Button>
            </WrapperChild>
            <WrapperChild>
                <Button label="Primary" color="primary"></Button>
            </WrapperChild>
            <WrapperChild>
                <Button label="Disable" color="primary" disabled></Button>
            </WrapperChild>
            <WrapperChild>
                <OutlinedButton label="OutlinedButton" startIcon={<DeleteOutlined />}></OutlinedButton>
            </WrapperChild>
            <WrapperChild>
                <OutlinedButton label="OutlinedButton" disabled startIcon={<DeleteOutlined />}></OutlinedButton>
            </WrapperChild>
            <WrapperChild>
                <OutlinedButtonWithChip label="ButtonWithChip" total={0}></OutlinedButtonWithChip>
            </WrapperChild>
            <WrapperChild>
                <OutlinedButtonWithChip label="ButtonWithChip" disabled total={0}></OutlinedButtonWithChip>
            </WrapperChild>
            <WrapperChild>
                <OutlinedButtonWithChip label="ButtonWithChip" startIcon={<DeleteOutlined />} total={0}></OutlinedButtonWithChip>
            </WrapperChild>
            <WrapperChild>
                <OutlinedButtonWithChip label="ButtonWithChip" disabled startIcon={<DeleteOutlined />} total={0}></OutlinedButtonWithChip>
            </WrapperChild>
            <WrapperChild>
                <IconButton iconClassName={CustomIcon.Database}></IconButton>
            </WrapperChild>
            <WrapperChild>
                <IconButton>
                    <DeleteOutlined />
                </IconButton>
            </WrapperChild>
            <WrapperChild>
                <IconButton round iconClassName={CustomIcon.Database}></IconButton>
            </WrapperChild>
            <WrapperChild>
                <IconButton round>
                    <DeleteOutlined />
                </IconButton>
            </WrapperChild>
        </Wrapper>
    );
};
