import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {emptyCellCharacter} from '@components/data-grid';
import {ReduxLinkText} from '@components/link';

export type TextLinkCellProps = {
    id?: string;
    path: string;
    title?: string;
};

export const renderTextLink = (params: GridCellParams) => {
    const {path, id, title}: TextLinkCellProps = params.value as TextLinkCellProps;
    return path?.length && id ? <ReduxLinkText title={title ?? id} path={path} id={id} /> : <>{emptyCellCharacter}</>;
};
