import React from 'react';
import {defineMessages} from 'react-intl';
import {useSelector} from 'react-redux';

import {ValueButton} from '@components/button';
import {renderDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {CustomIcon} from '@components/icons/types';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {notesAttachmentsResultValueSelector} from '../../block-bulk-actions';

const localized = defineMessages({
    addNotesAndAttachment: {
        id: 'BulkAddNoteButton_AddNotesAndAttachment',
        defaultMessage: 'Add Notes and Attachment',
    },
});

type BulkAddNoteButtonProps = {onClick: () => void};

function BulkAddNoteButtonInner({onClick}: BulkAddNoteButtonProps) {
    const value = useSelector(notesAttachmentsResultValueSelector);

    return (
        <ValueButton onClick={onClick} label={localized.addNotesAndAttachment} iconClassName={CustomIcon.NotesOutline}>
            {value ? renderDefaultChip('1', ChipVariant.Blue, ChipType.Status) : null}
        </ValueButton>
    );
}

export const BulkNoteApplyStepButton = withMultiplePermission(BulkAddNoteButtonInner, {
    allowedPermissions: [
        {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesSecurity, permissions: [PermissionEnum.Create]},
        {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.Payment, permissions: [PermissionEnum.Create]},
        {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.KYC, permissions: [PermissionEnum.Create]},
        {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesCustomerSupport, permissions: [PermissionEnum.Create]},
        {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesPlayer, permissions: [PermissionEnum.Create]},
        {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesBotDetection, permissions: [PermissionEnum.Create]},
    ],
});
