import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {StyledComponentProps} from '@mui/material/styles';

import {Autocomplete, AutocompleteOptionType} from '@components/autocomplete';
import {SecurityCase, SecurityCaseType, UserProfile} from '@models/generated/graphql';
import {sortByTimestamp} from '@utils';

import {itemsSelector} from '../../block-bulk-actions';
import {useTempStorageDataLoad} from '../../module-temp-storage/components/editable-components/hooks';
import {actions} from '../../player-actions/cases-actions/actions';
import {SecurityCaseEditModel, SecurityCaseFilter} from '../../player-actions/cases-actions/types';

const localized = defineMessages({
    affectedItemsCountMessage: {
        id: 'BulkSecurityCasesAutocomplete_affectedItemsCountMessage',
        defaultMessage: 'Only {affectedUserCount} out of {totalUserCount} selected users will be applied',
    },
    createCaseLabel: {
        id: 'BulkSecurityCasesAutocomplete_createCaseLabel',
        defaultMessage: 'Create case',
    },
    enterCaseID: {
        id: 'BulkSecurityCasesAutocomplete_enterCaseID',
        defaultMessage: 'Enter Case ID',
    },
});

type BulkSecurityCasesAutocompleteProps = {
    userId: string;
    fieldKey: string;
    value: SecurityCaseEditModel[];
    defaultValue: SecurityCaseEditModel[];
    onChange?: (value: SecurityCaseEditModel[]) => void;
    type: SecurityCaseType;
};

// TODO: [BO-2750] use view autocomplete
export const BulkUserProfileSecurityCasesAutocomplete = ({
    value,
    defaultValue,
    onChange,
    fieldKey,
    type,
    userId,
    classes,
}: BulkSecurityCasesAutocompleteProps & StyledComponentProps) => {
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();

    const existingCases = value.filter(v => defaultValue.some(d => d.case_id === v.case_id));
    const usersWithExistingCasesCount = existingCases?.reduce(
        (result, securityCase) => (securityCase?.totalCount ? result + securityCase?.totalCount : result),
        0
    );
    const allUsers = useSelector(itemsSelector) as UserProfile[];
    const affectedUserCount = allUsers?.length ? allUsers.length - usersWithExistingCasesCount : 0;

    const {isLoading, data: cases} = useTempStorageDataLoad<SecurityCase[]>(fieldKey);

    const handleChange = (selectedOptions: AutocompleteOptionType[]) => {
        const updatedCases = selectedOptions
            ?.filter(o => o.value || o.isSuggested)
            ?.map(option => {
                const isSuggestedValue = option?.inputValue !== null && option?.inputValue !== undefined;
                return {
                    type: type,
                    case_id: option?.value,
                    isNew: isSuggestedValue,
                    totalCount: value?.find(i => i.case_id === option?.value)?.totalCount ?? affectedUserCount,
                };
            });
        onChange(updatedCases);
    };

    const handleFilterChange = (value: string) => {
        const filter: SecurityCaseFilter = {type: type, id: value, userId: userId};
        dispatch(
            actions.loadSecurityCases.request({
                dataKey: fieldKey,
                filter,
            })
        );
    };

    const getAutocompleteOption = (value: SecurityCaseEditModel): AutocompleteOptionType => {
        if (!value) {
            return null;
        }

        let mapped: AutocompleteOptionType = {label: value.case_id, value: value.case_id, isSuggested: value.isNew};

        if (value?.totalCount) {
            mapped = {...mapped, badgeContent: value.totalCount};
        }

        return mapped;
    };

    const getSelectedValue = (securityCaseEditModel: SecurityCaseEditModel[]): string[] => {
        return securityCaseEditModel?.length ? securityCaseEditModel.map(i => i.case_id) : [];
    };

    const getSelectedOption = (securityCaseEditModel: SecurityCaseEditModel[]): AutocompleteOptionType[] => {
        return securityCaseEditModel?.length ? securityCaseEditModel.map(getAutocompleteOption) : [];
    };

    const compareOptions = (first: SecurityCase, second: SecurityCase) => {
        return sortByTimestamp(second.opened_at, first.opened_at);
    };

    const getCasesOptions = () => cases?.slice()?.sort(compareOptions)?.map(getAutocompleteOption);

    const getSubInfoMessage = () => {
        const newCases = value.filter(v => defaultValue.some(d => d.case_id !== v.case_id));
        return newCases?.length > 0 && affectedUserCount !== allUsers.length && affectedUserCount > 0
            ? formatMessage(localized.affectedItemsCountMessage, {
                  affectedUserCount,
                  totalUserCount: allUsers?.length,
              })
            : null;
    };

    return (
        <>
            <Autocomplete
                multiple={true}
                clearOnSuggestionSelect
                triggerFilterChangeOnFirstOpen
                allowEmptyValue
                allowFreeInputValue
                suggestAlways
                suggestCreation
                classes={classes}
                loading={isLoading}
                value={getSelectedValue(value)}
                defaultOption={getSelectedOption(value)}
                options={getCasesOptions()}
                onValueChange={handleChange}
                onFilterChange={handleFilterChange}
                suggestPlaceholder={localized.createCaseLabel}
                placeholder={localized.enterCaseID}
                disableOptionSelection={affectedUserCount === 0}
                subInfoMessage={getSubInfoMessage()}
            />
        </>
    );
};
