import {IModuleGridItem} from '@components/data-grid';
import {LegalManagementHistory} from '@models/generated/businessPoolGraphql';

export const domain = 'legal-content-management/history';

export class LegalDocumentHistoryItem extends LegalManagementHistory implements IModuleGridItem {
    id: string;
    serverId: string;

    userEmail: string;
    content: string;

    get isNew() {
        return false;
    }
}
