import React, {PropsWithChildren} from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    snackbarStack: {
        display: 'flex',
        zIndex: theme.zIndex.snackbar,
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
        left: '50%',
        right: 'auto',
        bottom: theme.spacing(3),
        transform: 'translateX(-50%)',

        '& .MuiSnackbar-anchorOriginBottomCenter': {
            transform: 'none',
            position: 'static',
        },
    },
}));

export function SnackbarStack({children}: PropsWithChildren<{}>) {
    const {classes} = useStyles();

    return <Box className={classes.snackbarStack}>{children}</Box>;
}
