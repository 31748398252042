import React from 'react';
import {Box, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

import {ErrorAccordion} from '@components/error';
import {CustomIcon} from '@components/icons';
import {withTraceErrorBoundary} from '@otel';

import {Button, IconButton} from '../button/Buttons';

import {useModalClasses} from './Modal.style';
import {ModalContent as ModalContentType} from './types';

type ModalContentProps = ModalContentType & {
    onCloseClick: () => void;
};

function ModalContentInternal(props: ModalContentProps) {
    const {dialogContentClassName, title, subtitle, body, buttons, description, footer, subInfoMessage, onCloseClick} = props;
    const {classes: modalClasses} = useModalClasses();
    return (
        <>
            <DialogTitle classes={{root: modalClasses.modalTitle}}>
                <Box className={modalClasses.modalTitleText}>
                    {title}
                    <Box className={modalClasses.modalSubtitle}>
                        <Typography variant="body1" color="secondary">
                            {subtitle}
                        </Typography>
                    </Box>
                </Box>

                <IconButton
                    aria-label="close"
                    className={modalClasses.modalCloseButton}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    onClick={onCloseClick}
                >
                    <Box component="span" className={CustomIcon.CancelOutline} fontSize={10} />
                </IconButton>
            </DialogTitle>
            <DialogContent
                className={`${dialogContentClassName} ${modalClasses.modalContent} ${!title ? modalClasses.modalContentNoTitle : ''}`}
            >
                {description}
                {body}
            </DialogContent>
            {buttons?.length > 0 ? (
                <DialogActions className={modalClasses.modalActions}>
                    <Box className={modalClasses.modalSubInfoMessageContainer}>{subInfoMessage}</Box>
                    <Box className={modalClasses.modalActionsButtonsContainer}>
                        {buttons.map((button, index) => {
                            return button ? (
                                <Button
                                    key={button.id ?? index}
                                    color={button.isPrimary ? 'primary' : 'secondary'}
                                    label={button.label}
                                    onClick={button.onClick}
                                    disabled={button.disabled}
                                    data-testid={button.id}
                                />
                            ) : null;
                        })}
                    </Box>
                </DialogActions>
            ) : null}
            {footer ? <DialogActions className={modalClasses.modalActions}>{footer}</DialogActions> : <></>}
        </>
    );
}

export const ModalContent = withTraceErrorBoundary(ModalContentInternal, ErrorAccordion);
