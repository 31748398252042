import produce from 'immer';
import {createReducer, PayloadAction} from 'typesafe-actions';

import {EntityActions, entityActions, EntityFetchStatus} from '@redux/entity';

import {
    ViewActions,
    viewActions,
    ViewCleanActionPayload,
    ViewSaveActionPayload,
    ViewSaveFilterActionPayload,
    ViewUpdateCleanDelayPayload,
    ViewUpdateKeysActionPayload,
} from './actions';
import {ViewData, ViewType} from './types';

export type ViewsState = Partial<Record<ViewType, ViewState>>;

export type ViewState = ViewData;

export const viewsReducer = createReducer<ViewsState, ViewActions | EntityActions>({} as ViewsState)
    .handleAction(viewActions.save, (state: ViewsState, action: PayloadAction<string, ViewSaveActionPayload>) => {
        const updatedState = produce(state, draftState => {
            const viewType = action.payload.view;
            draftState[viewType] = state[viewType];

            if (state[viewType]) {
                draftState[viewType].entities[action.payload.entity.entity] = action.payload.entity;
                if (action.payload.realtime) {
                    draftState[viewType].realtime[action.payload.entity.entity] = action.payload.realtime;
                }
            } else {
                draftState[viewType] = {
                    viewType,
                    displayName: action.payload.displayName,
                    entities: {
                        [action.payload.entity.entity]: action.payload.entity,
                    },
                };
                if (action.payload.realtime) {
                    draftState[viewType].realtime = {
                        [action.payload.entity.entity]: action.payload.realtime,
                    };
                }
            }
        });
        return updatedState;
    })
    .handleAction(viewActions.saveKeys, (state: ViewsState, action: PayloadAction<string, ViewUpdateKeysActionPayload>) => {
        const updatedState = produce(state, draftState => {
            const allViewEntityStates = Object.values(draftState)
                .flatMap(i => i.entities)
                .flatMap(i => Object.values(i));
            const updatedViewEntityStates = allViewEntityStates?.filter(
                i => i.entity === action.payload.entity && i.filter === action.payload.filter
            );
            updatedViewEntityStates?.forEach(viewEntityState => {
                viewEntityState.keys = action.payload.keys;
                viewEntityState.total = action.payload.total;
            });
        });
        return updatedState;
    })
    .handleAction(viewActions.saveFilter, (state: ViewsState, action: PayloadAction<string, ViewSaveFilterActionPayload>) => {
        const updatedState = produce(state, draftState => {
            const allViewEntityStates = Object.values(draftState)
                .filter(i => i.viewType === action.payload.view)
                .flatMap(i => i.entities)
                .flatMap(i => Object.values(i));
            const updatedViewEntityStates = allViewEntityStates?.filter(i => i.entity === action.payload.entity);
            updatedViewEntityStates?.forEach(viewEntityState => {
                viewEntityState.filter = action.payload.filter;
            });
        });
        return updatedState;
    })
    .handleAction(viewActions.clean, (state: ViewsState, action: PayloadAction<string, ViewCleanActionPayload>) => {
        const updatedState = produce(state, draftState => {
            const viewType = action.payload.view as ViewType;
            delete draftState[viewType];
        });
        return updatedState;
    })
    .handleAction(entityActions.fetchStatus, (state: ViewsState, action: PayloadAction<string, EntityFetchStatus>) => {
        const updatedState = produce(state, draftState => {
            const {entityType, filter, requestStatus} = action.payload;
            const viewEntityStates = Object.values(draftState)
                .flatMap(i => i.entities)
                .flatMap(i => Object.values(i))
                .filter(i => i.entity === entityType && i.filter === filter);
            viewEntityStates.forEach(i => (i.status = requestStatus));
        });

        return updatedState;
    })
    .handleAction(viewActions.updateCleanDelay, (state: ViewsState, action: PayloadAction<string, ViewUpdateCleanDelayPayload>) => {
        const updatedState = produce(state, draftState => {
            const {view, cleanDelay} = action.payload;
            const stateCleanDelay = state[view].cleanDelay ?? 0;
            draftState[view].cleanDelay = Math.max(cleanDelay, stateCleanDelay);
        });

        return updatedState;
    });
