import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {ChipListCell} from '@components/data-grid/mui';

import {useActionHistoryFormatter} from '../hooks/useActionHistoryFormatter';
import {EventLogGridItem} from '../types';

type ActionHistoryValueProps = {
    actionType: string;
    actionValue: string;
};

const ActionHistoryValue = ({actionValue, actionType}: ActionHistoryValueProps) => {
    const formattedValues = useActionHistoryFormatter(actionType, actionValue);
    return <ChipListCell values={formattedValues} hideTooltip={!formattedValues?.length} />;
};

export const renderActionHistoryValue = (params: GridCellParams) => {
    const actionType = (params.row as EventLogGridItem).event_name;
    const actionValue = params.value as string;

    return <ActionHistoryValue actionType={actionType} actionValue={actionValue} />;
};

export function getActionHistoryValue(params: GridCellParams, key: keyof EventLogGridItem) {
    const value = (params.row as EventLogGridItem)[key];
    return typeof value === 'string' ? value : JSON.stringify(value);
}
