import {defineMessages} from 'react-intl';
import {injectable} from 'inversify';
import {Observable} from 'rxjs';

import {CsvDocumentBuilder} from '@file/services/CsvDocumentBuilder';
import {CsvDocumentBuilderAdapter} from '@file/services/CsvDocumentBuilderAdapter';
import {IFileCreator} from '@file/services/IFileFactory';
import {DataWorksheet, DataWorksheetColDefs} from '@file/types';
import {AgentPlayerReportingViewModelKeys, AgentReportingDownloadModel} from '@models/agent-player-reporting';

const localized = defineMessages<AgentPlayerReportingViewModelKeys>({
    uid: {
        id: 'AgentReportingDownloadModel_uid',
        defaultMessage: 'Player ID',
    },
    uid_link: {
        id: 'AgentReportingDownloadModel_uidLink',
        defaultMessage: 'Player ID',
    },
    uid_text: {
        id: 'AgentReportingDownloadModel_uidText',
        defaultMessage: 'Player ID',
    },
    username: {
        id: 'AgentReportingDownloadModel_username',
        defaultMessage: 'Username',
    },
    phone: {
        id: 'AgentReportingDownloadModel_phone',
        defaultMessage: 'Phone Number',
    },
    email: {
        id: 'AgentReportingDownloadModel_email',
        defaultMessage: 'Email Address',
    },
    country: {
        id: 'AgentReportingDownloadModel_country',
        defaultMessage: 'Country',
    },
    referrer_agent_id: {
        id: 'AgentReportingDownloadModel_referrerAgentId',
        defaultMessage: 'Referral ID',
    },
    referrer_agent_username: {
        id: 'AgentReportingDownloadModel_referrerAgentUsername',
        defaultMessage: 'Referral Username',
    },
    currency: {
        id: 'AgentReportingDownloadModel_currency',
        defaultMessage: 'Currency',
    },
    id: {
        id: 'AgentReportingDownloadModel_id',
        defaultMessage: 'ID',
    },
    revenue_share_type: {
        id: 'AgentReportingDownloadModel_revenueShareType',
        defaultMessage: 'Revenue Share Type',
    },
    referee_type: {
        id: 'AgentReportingDownloadModel_refereeType',
        defaultMessage: 'Referee Type',
    },
    rakes: {
        id: 'AgentReportingDownloadModel_rakes',
        defaultMessage: 'Rakes',
    },
    insurance_tool: {
        id: 'AgentReportingDownloadModel_insuranceTool',
        defaultMessage: 'Insurance Tool',
    },
    casino_ggr: {
        id: 'AgentReportingDownloadModel_casinoGgr',
        defaultMessage: 'Casino GGR',
    },
    registered_player_number: {
        id: 'AgentReportingDownloadModel_registeredPlayerNumber',
        defaultMessage: 'Registered Player Number',
    },
    active_player_number: {
        id: 'AgentReportingDownloadModel_activePlayerNumber',
        defaultMessage: 'Active Player Number',
    },
    revenue_share: {
        id: 'AgentReportingDownloadModel_revenueShare',
        defaultMessage: 'Revenue Share',
    },
    register_marketing_code: {
        id: 'AgentReportingDownloadModel_registerMarketingCode',
        defaultMessage: 'Marketing Code',
    },
    'ggr_summary.cash_ggr': {
        id: 'AgentReportingDownloadModel_cashGgr',
        defaultMessage: 'Cash GGR',
    },
    'ggr_summary.external_casino_ggr': {
        id: 'AgentReportingDownloadModel_externalCasinoGgr',
        defaultMessage: 'External Casino GGR',
    },
    'ggr_summary.internal_casino_ggr': {
        id: 'AgentReportingDownloadModel_internalCasinoGgr',
        defaultMessage: 'Internal Casino GGR',
    },
    'ggr_summary.external_casino_bet_amount': {
        id: 'AgentReportingDownloadModel_externalCasinoBetAmount',
        defaultMessage: 'External Casino Turn Over',
    },
    'ggr_summary.internal_casino_bet_amount': {
        id: 'AgentReportingDownloadModel_internalCasinoBetAmount',
        defaultMessage: 'Internal Casino Turn Over',
    },
    'ggr_summary.poker_addons': {
        id: 'AgentReportingDownloadModel_pokerAddons',
        defaultMessage: 'Poker Addons',
    },
    'ggr_summary.poker_ggr': {
        id: 'AgentReportingDownloadModel_pokerGgr',
        defaultMessage: 'Poker GGR',
    },
    'ggr_summary.poker_mtt_fees': {
        id: 'AgentReportingDownloadModel_pokerMttFees',
        defaultMessage: 'Poker MTT Fees',
    },
    'ggr_summary.poker_rake': {
        id: 'AgentReportingDownloadModel_pokerRake',
        defaultMessage: 'Poker Rake',
    },
    'ggr_summary.mtt_ggr': {
        id: 'AgentReportingDownloadModel_mttGgr',
        defaultMessage: 'MTT GGR',
    },
    'ggr_summary.casino_ggr': {
        id: 'AgentReportingDownloadModel_ggrSummaryCasinoGgr',
        defaultMessage: 'Casino GGR',
    },
    'ggr_summary.global_spin_ggr': {
        id: 'AgentReportingDownloadModel_ggrGlobalSpin',
        defaultMessage: 'EGGR',
    },
    'ggr_summary.total_share': {
        id: 'AgentReportingDownloadModel_ggrTotalShare',
        defaultMessage: 'Total Share',
    },
    'ggr_summary.casino_share': {
        id: 'AgentReportingDownloadModel_casinoShare',
        defaultMessage: 'Casino Share',
    },
    'ggr_summary.plo_ggr_high_stake': {
        id: 'AgentReportingDownloadModel_ploGgrHighStake',
        defaultMessage: 'PLO GGR High Stake',
    },
    'ggr_summary.plo_ggr_low_stake': {
        id: 'AgentReportingDownloadModel_ploGgrLowStake',
        defaultMessage: 'PLO GGR Low Stake',
    },
    'ggr_summary.plo_ggr_medium_stake': {
        id: 'AgentReportingDownloadModel_ploGgrMediumStake',
        defaultMessage: 'PLO GGR Medium Stake',
    },
    'ggr_summary.plo_ggr_micro_stake': {
        id: 'AgentReportingDownloadModel_ploGgrMicroStake',
        defaultMessage: 'PLO GGR Micro Stake',
    },
    'ggr_summary.plo_ggr_total': {
        id: 'AgentReportingDownloadModel_ploGgrTotal',
        defaultMessage: 'PLO GGR Total',
    },
    'ggr_summary.plo_ggr_unknown_stake': {
        id: 'AgentReportingDownloadModel_ploGgrUnknownStake',
        defaultMessage: 'PLO GGR Unknown Stake',
    },
    'ggr_summary.poker_share': {
        id: 'AgentReportingDownloadModel_pokerShare',
        defaultMessage: 'Poker Share',
    },
    'ggr_summary.total': {
        id: 'AgentReportingDownloadModel_ggrTotal',
        defaultMessage: 'Total GGR',
    },
    'ggr_summary.mtt_total_buyin': {
        id: 'AgentReportingDownloadModel_mttTotalBuyin',
        defaultMessage: 'MTT Total Buy In',
    },
    'ngr_summary.cash_ngr': {
        id: 'AgentReportingDownloadModel_cashNgr',
        defaultMessage: 'Cash NGR',
    },
    'ngr_summary.casino_ngr': {
        id: 'AgentReportingDownloadModel_casinoNgr',
        defaultMessage: 'Casino NGR',
    },
    'ngr_summary.mtt_ngr': {
        id: 'AgentReportingDownloadModel_mttNgr',
        defaultMessage: 'MTT NGR',
    },
    'ngr_summary.poker_ngr': {
        id: 'AgentReportingDownloadModel_ngrPokerNgr',
        defaultMessage: 'Poker NGR',
    },
    'ngr_summary.total': {
        id: 'AgentReportingDownloadModel_ngrTotal',
        defaultMessage: 'Total NGR',
    },
    'bonus_summary.realized_bonus_total': {
        id: 'AgentReportingDownloadModel_bonusRealizedTotal',
        defaultMessage: 'Cash Realized Bonus',
    },
    'bonus_summary.mtt_ticket_bonus': {
        id: 'AgentReportingDownloadModel_mttTicketBonus',
        defaultMessage: 'MTT Ticket Bonus',
    },
    'bonus_summary.poker_bonus': {
        id: 'AgentReportingDownloadModel_pokerBonus',
        defaultMessage: 'Poker Bonus',
    },
    'bonus_summary.casino_bonus': {
        id: 'AgentReportingDownloadModel_casinoBonus',
        defaultMessage: 'Casino Bonus',
    },
    'bonus_summary.unrealized_bonus_total': {
        id: 'AgentReportingDownloadModel_bonusUnrealizedTotal',
        defaultMessage: 'Total Unrealized Bonus',
    },
    'bonus_summary.cost_global_spins_tickets': {
        id: 'AgentReportingDownloadModel_costGlobalSpinsTickets',
        defaultMessage: 'Spins Tournament Tickets',
    },
    'deduction_summary.casino_provider_fee': {
        id: 'AgentReportingDownloadModel_casinoProviderFee',
        defaultMessage: 'Casino Provider Fee',
    },
    'deduction_summary.ngr_cost': {
        id: 'AgentReportingDownloadModel_deductionNGRCost',
        defaultMessage: 'NGR Cost',
    },
    'deduction_summary.psp_fee': {
        id: 'AgentReportingDownloadModel_deductionPspFee',
        defaultMessage: 'PSP Fee',
    },
    'deduction_summary.total': {
        id: 'AgentReportingDownloadModel_deductionTotal',
        defaultMessage: 'Total Deduction',
    },
    'player_games_summary.cash_game_hand_played': {
        id: 'AgentReportingDownloadModel_cashGameHandPlayed',
        defaultMessage: 'Cash game hand played',
    },
    'player_games_summary.mtt_played': {
        id: 'AgentReportingDownloadModel_mttPlayed',
        defaultMessage: 'MTT played',
    },
    'player_games_summary.player_net_deposit': {
        id: 'AgentReportingDownloadModel_playerNetDeposit',
        defaultMessage: 'Player Net Deposit',
    },
    'player_games_summary.player_profit_loss': {
        id: 'AgentReportingDownloadModel_playerProfitLoss',
        defaultMessage: 'Player profit loss',
    },
    'other_summary.cost_tournament_overlay_excl_freeroll': {
        id: 'AgentReportingDownloadModel_costTournamentOverlayExclFreeroll',
        defaultMessage: 'Tournament Overlay',
    },
    highest_blind_level_is_pro_nlhe: {
        id: 'AgentReportingDownloadModel_highestBlindLevelIsProNLHE',
        defaultMessage: 'Highest blind level is pro',
    },
    'pro_summary.is_pro_shortdeck': {
        id: 'AgentReportingDownloadModel_isProShortdeck',
        defaultMessage: 'Pro Shortdeck',
    },
    'pro_summary.cost_spins_tournament_tickets_pro': {
        id: 'AgentReportingDownloadModel_costSpinsTournamentTicketsPro',
        defaultMessage: 'Cost Spins Ticket',
    },
    'pro_summary.cost_ftd_bonus_pro': {
        id: 'AgentReportingDownloadModel_costFtdBonusPro',
        defaultMessage: 'FTD Bonus Cost',
    },
    'pro_summary.cost_psp_fee_pro': {
        id: 'AgentReportingDownloadModel_costPspFeePro',
        defaultMessage: 'PSP Fee Cost',
    },
    'pro_summary.poker_ggr_usd_pro': {
        id: 'AgentReportingDownloadModel_pokerGgrUSDPro',
        defaultMessage: 'Poker GGR USD Pro',
    },
    'pro_summary.nlhe_ggr_usd_pro': {
        id: 'AgentReportingDownloadModel_nlheGgrUSDPro',
        defaultMessage: 'NLHE GGR USD Pro',
    },
    'pro_summary.shortdeck_ggr_usd_pro': {
        id: 'AgentReportingDownloadModel_shortdeckGgrUSDPro',
        defaultMessage: 'ShortDeck GGR USD Pro',
    },
    'pro_summary.nlhe_micro_ggr_usd_pro': {
        id: 'AgentReportingDownloadModel_nlheMicroGgrUSDPro',
        defaultMessage: 'NLHE Micro GGR USD Pro',
    },
    'pro_summary.nlhe_low_ggr_usd_pro': {
        id: 'AgentReportingDownloadModel_nlheLowGgrUSDPro',
        defaultMessage: 'NLHE Low GGR USD Pro',
    },
    'pro_summary.nlhe_medium_ggr_usd_pro': {
        id: 'AgentReportingDownloadModel_nlheMediumGgrUSDPro',
        defaultMessage: 'NLHE Medium GGR USD Pro',
    },
    'pro_summary.nlhe_high_ggr_usd_pro': {
        id: 'AgentReportingDownloadModel_nlheHighGgrUSDPro',
        defaultMessage: 'NLHE High GGR USD Pro',
    },
    total_share: null,
    poker_share: null,
    casino_share: null,
    is_summary: null,
    has_summary: null,
});

type WorkSheetColumnType = DataWorksheetColDefs<keyof AgentReportingDownloadModel>;

/**
 *  @deprecated
 * This approach is outdated. Use {@link CsvDocumentBuilderAdapter}
 * */
@injectable()
export class AgentReportingCsvFileFactory implements IFileCreator<AgentReportingDownloadModel> {
    private readonly builder = new CsvDocumentBuilder();

    public createFile(items: AgentReportingDownloadModel[], keys: AgentPlayerReportingViewModelKeys[]): Observable<ArrayBuffer> {
        const worksheet = this.getWorksheet(items, keys);
        return this.builder.createDocument<AgentPlayerReportingViewModelKeys>(worksheet);
    }

    private getWorksheet(
        data: AgentReportingDownloadModel[],
        fields: AgentPlayerReportingViewModelKeys[]
    ): DataWorksheet<AgentPlayerReportingViewModelKeys> {
        const columns = fields.reduce<WorkSheetColumnType>((previousValue, field, index) => {
            previousValue[field] = {key: field, header: localized[field]?.defaultMessage as string, colIndex: index + 1};
            return previousValue;
        }, {} as WorkSheetColumnType);

        return {
            title: 'Agent Report',
            columns,
            data,
        };
    }
}
