import React, {SyntheticEvent, useEffect, useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Box, Snackbar as MuiSnackbar, SnackbarProps as MuiSnackbarProps, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon} from '@components/icons';
import {LinkText} from '@components/link';
import {CustomTheme} from '@style';

import {IconButton} from '../button/Buttons';

export const useStyles = makeStyles()((theme: CustomTheme) => ({
    snackbarContent: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
    },
    snackbarTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.5),
        flexDirection: 'row',
    },
    snackbarAction: {
        margin: theme.spacing(1),
    },
}));

const localized = defineMessages({
    snackbarCountdown: {
        id: 'snackbarCountdown',
        defaultMessage: ' in {seconds} seconds',
    },
});

type SnackbarProps = Omit<MuiSnackbarProps, 'message'> & {
    message: string | React.ReactNode;
    action?: string | React.ReactNode;
    messageIcon?: React.ReactNode;
    onAction?: () => void;
    onClose?: (event: React.SyntheticEvent<any>, reason: string) => void;
    showCountDown?: boolean;
};

export function Snackbar({
    open,
    message,
    action,
    onAction,
    onClose,
    messageIcon,
    autoHideDuration,
    showCountDown,
    anchorOrigin = {vertical: 'bottom', horizontal: 'center'},
    ...props
}: SnackbarProps) {
    const {classes} = useStyles();
    const {formatMessage} = useIntl();
    const millisecondsInSecond = 1000;
    const autoHideDurationSeconds = autoHideDuration > 0 ? autoHideDuration / millisecondsInSecond : null;
    const [secondsRemaining, setSecondsRemaining] = useState(autoHideDurationSeconds);

    useEffect(() => {
        const interval = setInterval(() => {
            if (secondsRemaining > 0) {
                setSecondsRemaining(secondsRemaining => secondsRemaining - 1);
            } else {
                clearInterval(interval);
            }
        }, millisecondsInSecond);
        return () => clearInterval(interval);
    }, []);

    function getAction() {
        let res: React.ReactNode = null;

        if (action && typeof action === 'string') {
            res = <LinkText underline="none" onClick={handleAction} title={action} className={classes.snackbarAction} />;
        } else if (action) {
            res = <>{action}</>;
        }

        return res;
    }

    function getSecondsRemaining() {
        return showCountDown === true && secondsRemaining >= 0
            ? formatMessage(localized.snackbarCountdown, {seconds: secondsRemaining})
            : null;
    }

    function handleClose(e: SyntheticEvent<HTMLElement, Event>, reason: string) {
        e?.stopPropagation();

        if (onClose) {
            onClose(e, reason);
        }
    }

    function handleAction(e: SyntheticEvent<HTMLElement, Event>) {
        e.stopPropagation();

        if (onAction) {
            onAction();
        }
    }

    return (
        <MuiSnackbar
            open={open}
            autoHideDuration={autoHideDuration}
            anchorOrigin={anchorOrigin}
            message={
                <Box className={classes.snackbarTitle}>
                    {messageIcon ? messageIcon : null}
                    {typeof message === 'string' ? (
                        <Typography variant="body1" component="span">
                            {message}
                            {getSecondsRemaining()}
                        </Typography>
                    ) : (
                        <Box>
                            {message}
                            {getSecondsRemaining()}
                        </Box>
                    )}
                </Box>
            }
            action={
                <>
                    {getAction()}
                    <IconButton iconClassName={CustomIcon.CancelOutline} onClick={e => handleClose(e, 'clickClose')} />
                </>
            }
            ContentProps={{className: classes.snackbarContent}}
            onClose={handleClose}
            {...props}
        />
    );
}
