import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {mergeMap} from '@otel';
import {RootEpic} from '@redux';

import {protectEpics} from '../../app/error-handling/epics';
import {createSingleItemReadEpics} from '../../module-shared/epics';
import {piiActions} from '../../player-actions/pii-actions/actions';
import {PlayerProfileGridItem} from '../../player-actions/types';
import {createItemSubscriberRealtimeEpics} from '../../realtime-updates/epics';

import {localized} from './components/UserRegionRelatedInfo.localize';
import actions from './actions';
import {columnsSelector, filterSelector} from './selectors';
import {dataSelector} from './selectors';
import {domain} from './types';

const editSuccessEpic: RootEpic = (action$, state) =>
    action$.pipe(
        filter(
            isActionOf([
                piiActions.editNationality.success,
                piiActions.editCountryOfBirth.success,
                piiActions.editNationalId.success,
                piiActions.editTaxId.success,
                piiActions.editOccupation.success,
            ])
        ),
        mergeMap(res => {
            const user = dataSelector(state.value);
            return of(actions.contentUpdateItem({...user, ...res.payload} as PlayerProfileGridItem));
        })
    );

export default protectEpics(
    createSingleItemReadEpics(domain, c => c.userProfileService, filterSelector, columnsSelector, false),
    createItemSubscriberRealtimeEpics({realtimeKey: domain, domain, name: localized.header}, dataSelector, u => u.uid),
    editSuccessEpic
);
