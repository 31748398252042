import * as React from 'react';

import {MemoizedDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {EntityType} from '@redux/entity';

import {useLabel} from '../hooks';

export type BulkActionSummaryTotalChipTypes = 'info' | 'infoDark' | 'warning' | 'warningDark' | 'success' | 'successDark';

type BulkActionSummaryTotalProps = {
    processedItems?: number;
    totalItems: number;
    type?: EntityType;
    chipType: BulkActionSummaryTotalChipTypes;
};

export const BulkTotalCountChip = ({processedItems, totalItems, type, chipType}: BulkActionSummaryTotalProps) => {
    const total = processedItems > 0 && totalItems > 0 ? `${processedItems}/${totalItems}` : totalItems ? `${totalItems}` : '0';
    const label = type ? useLabel(type) : '';

    const chipMapping: Record<BulkActionSummaryTotalChipTypes, ChipVariant> = {
        info: ChipVariant.Disabled,
        infoDark: ChipVariant.DarkGrey,
        warning: ChipVariant.Orange,
        warningDark: ChipVariant.OrangeDark,
        success: ChipVariant.Green,
        successDark: ChipVariant.GreenDark,
    };

    return <MemoizedDefaultChip value={`${total} ${label}`} variant={chipMapping[chipType]} type={ChipType.Status} />;
};
