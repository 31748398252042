import {defineMessages} from 'react-intl';
import {GridCellParams, GridValueGetterParams} from '@mui/x-data-grid';

import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    renderChipCell,
    renderCurrencyCell,
    renderDateCell,
    renderTextListWithTooltipCell,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {TicketStatus} from '@models/sportsbook-transaction/type';

import {SportsbookTransactionViewModel, SportsbookTransactionViewModelKeys} from '../types';

const localized = defineMessages({
    ticketId: {
        id: 'DataGridSportsbookTransaction_TicketId',
        defaultMessage: 'Internal Ticket ID',
    },
    compUserId: {
        id: 'DataGridSportsbookTransaction_CompUserId',
        defaultMessage: 'WPTG platform Player ID',
    },
    sabaTicketId: {
        id: 'DataGridSportsbookTransaction_SabaTicketId',
        defaultMessage: 'Saba ticket ID',
    },
    ticketStatus: {
        id: 'DataGridSportsbookTransaction_TicketStatus',
        defaultMessage: 'Ticket Status',
    },
    matchNames: {
        id: 'DataGridSportsbookTransaction_MatchNames',
        defaultMessage: 'Match Name',
    },
    tournamentNames: {
        id: 'DataGridSportsbookTransaction_tournamentNames',
        defaultMessage: 'League Name',
    },
    matchIds: {
        id: 'DataGridSportsbookTransaction_MatchIds',
        defaultMessage: 'Match ID',
    },
    betOptions: {
        id: 'DataGridSportsbookTransaction_BetOptions',
        defaultMessage: 'Bet Option',
    },
    betOdds: {
        id: 'DataGridSportsbookTransaction_BetOdds',
        defaultMessage: 'Betting Odds',
    },
    betAmount: {
        id: 'DataGridSportsbookTransaction_BetAmount',
        defaultMessage: 'Betting Amount',
    },
    settleAmount: {
        id: 'DataGridSportsbookTransaction_SettleAmount',
        defaultMessage: 'Settlement Amount',
    },
    betTime: {
        id: 'DataGridSportsbookTransaction_BetTime',
        defaultMessage: 'Bet Time',
    },
});

class DataGridSportsbookTransactionConfigFactory extends DataGridEntityConfigFactory<
    SportsbookTransactionViewModelKeys,
    SportsbookTransactionViewModel
> {
    getColumnConfig(): Partial<
        Record<SportsbookTransactionViewModelKeys, DataGridEntityColDef<SportsbookTransactionViewModelKeys, SportsbookTransactionViewModel>>
    > {
        return {
            id: {
                field: 'id',
                hide: true,
            },
            ticketId: {
                field: 'ticketId',
                renderHeader: () => renderCellHeader(localized.ticketId),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.3,
            },
            compUserId: {
                field: 'compUserId',
                renderHeader: () => renderCellHeader(localized.compUserId),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.2,
            },
            sabaTicketId: {
                field: 'sabaTicketId',
                renderHeader: () => renderCellHeader(localized.sabaTicketId),
                renderCell: renderCellWithTooltip,
                sortable: false,
                flex: 0.2,
            },
            ticketStatus: {
                field: 'ticketStatus',
                valueGetter: (params: GridValueGetterParams) => ({value: params.row.ticketStatus, type: nameof<TicketStatus>()}),
                renderHeader: () => renderCellHeader(localized.ticketStatus),
                renderCell: renderChipCell,
                sortable: false,
                flex: 0.25,
            },
            matchNames: {
                field: 'matchNames',
                renderHeader: () => renderCellHeader(localized.matchNames),
                renderCell: renderTextListWithTooltipCell,
                sortable: false,
                flex: 0.3,
            },
            tournamentNames: {
                field: 'tournamentNames',
                renderHeader: () => renderCellHeader(localized.tournamentNames),
                renderCell: renderTextListWithTooltipCell,
                sortable: false,
                flex: 0.35,
            },
            matchIds: {
                field: 'matchIds',
                renderHeader: () => renderCellHeader(localized.matchIds),
                renderCell: renderTextListWithTooltipCell,
                sortable: false,
                flex: 0.3,
            },
            betOptions: {
                field: 'betOptions',
                renderHeader: () => renderCellHeader(localized.betOptions),
                renderCell: renderTextListWithTooltipCell,
                sortable: false,
                flex: 0.2,
            },
            betOdds: {
                field: 'betOdds',
                renderHeader: () => renderCellHeader(localized.betOdds),
                renderCell: renderTextListWithTooltipCell,
                sortable: false,
                flex: 0.2,
            },
            betAmount: {
                field: 'betAmount',
                valueGetter: p => this.getBetAmount(p),
                renderHeader: () => renderCellHeader(localized.betAmount),
                renderCell: p => renderCurrencyCell(p, this.getCurrency(p)),
                sortable: true,
                flex: 0.2,
            },
            settleAmount: {
                field: 'settleAmount',
                valueGetter: p => this.getSettleAmount(p),
                renderHeader: () => renderCellHeader(localized.settleAmount),
                renderCell: p => renderCurrencyCell(p, this.getCurrency(p)),
                sortable: true,
                flex: 0.2,
            },
            betTime: {
                field: 'betTime',
                renderHeader: () => renderCellHeader(localized.betTime),
                renderCell: renderDateCell,
                sortable: true,
                flex: 0.3,
            },
        };
    }

    private getCurrency(p: GridCellParams) {
        return this.getRow(p)?.currency;
    }

    private getBetAmount(p: GridValueGetterParams) {
        return this.getRow(p)?.betAmount;
    }

    private getSettleAmount(p: GridValueGetterParams) {
        return this.getRow(p)?.settleAmount;
    }
}

export const DataGridSportsbookTransactionServer = withDataGridEntityServer<
    SportsbookTransactionViewModelKeys,
    SportsbookTransactionViewModel
>(MuiDataGridServer, () => new DataGridSportsbookTransactionConfigFactory().getColumnConfig());

export const DataGridSportsbookTransactionClient = withDataGridEntityClient<
    SportsbookTransactionViewModelKeys,
    SportsbookTransactionViewModel
>(MuiDataGridClient, () => new DataGridSportsbookTransactionConfigFactory().getColumnConfig());
