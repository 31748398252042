import {constructUsing, createMap, forMember, mapFrom, Mapper, mapWith} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {AggregatedFinanceSummaryViewModel, AggregatedFinanceSummaryViewModelKeys} from '@models/aggregated-finance-summary/index';
import {AggregatedFinanceSummary, Ggr, TransactionAttemptsSummary} from '@models/generated/graphql';
import {AggregatedFinanceSummaryQueryFields} from '@redux/entity';
import {formatTimestamp} from '@utils';

import {GgrViewModel, TransactionAttemptsSummaryViewModel} from './types';

export type AggregatedFinanceSummaryMappingArgs = {
    currency: string;
};

export class AggregatedFinanceSummaryMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            TransactionAttemptsSummary,
            TransactionAttemptsSummaryViewModel,
            forMember(
                vm => vm.currency,
                mapFrom(m => m.currency)
            ),
            forMember(
                vm => vm.failed_amount,
                mapFrom(m => m.failed_amount)
            ),
            forMember(
                vm => vm.failed_count,
                mapFrom(m => m.failed_count)
            ),
            forMember(
                vm => vm.pending_amount,
                mapFrom(m => m.pending_amount)
            ),
            forMember(
                vm => vm.pending_count,
                mapFrom(m => m.pending_count)
            ),
            forMember(
                vm => vm.rejected_amount,
                mapFrom(m => m.rejected_amount)
            ),
            forMember(
                vm => vm.rejected_count,
                mapFrom(m => m.rejected_count)
            ),
            forMember(
                vm => vm.successful_count,
                mapFrom(m => m.successful_count)
            ),
            forMember(
                vm => vm.successful_amount,
                mapFrom(m => m.successful_amount)
            )
        );
        createMap(
            mapper,
            Ggr,
            GgrViewModel,
            forMember(
                vm => vm.casino_connector,
                mapFrom(m => m.casino_connector)
            ),
            forMember(
                vm => vm.casino_poker_flips,
                mapFrom(m => m.casino_poker_flips)
            ),
            forMember(
                vm => vm.mtt_service_fee,
                mapFrom(m => m.mtt_service_fee)
            ),
            forMember(
                vm => vm.poker_features_purchase,
                mapFrom(m => m.poker_features_purchase)
            ),
            forMember(
                vm => vm.poker_guess_hand,
                mapFrom(m => m.poker_guess_hand)
            ),
            forMember(
                vm => vm.poker_insurance,
                mapFrom(m => m.poker_insurance)
            ),
            forMember(
                vm => vm.poker_rake,
                mapFrom(m => m.poker_rake)
            )
        );
        createMap(
            mapper,
            AggregatedFinanceSummary,
            AggregatedFinanceSummaryViewModel,
            forMember(
                vm => vm.ggr,
                mapWith(GgrViewModel, Ggr, m => m.ggr)
            ),
            forMember(
                vm => vm.deposit,
                mapWith(TransactionAttemptsSummaryViewModel, TransactionAttemptsSummary, m => m.deposit)
            ),
            forMember(
                vm => vm.withdrawal,
                mapWith(TransactionAttemptsSummaryViewModel, TransactionAttemptsSummary, m => m.withdrawal)
            ),
            forMember(
                vm => vm.bonus_ratio,
                mapFrom(m => m.bonus_ratio)
            ),
            forMember(
                vm => vm.generated_rake,
                mapFrom(m => m.generated_rake)
            ),
            forMember(
                vm => vm.lifetime_bonus,
                mapFrom(m => m.lifetime_bonus)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.created_at,
                mapFrom(m => formatTimestamp(m.created_at, 'date-time'))
            ),
            forMember(
                vm => vm.start_at,
                mapFrom(m => formatTimestamp(m.start_at, 'date-time'))
            ),
            forMember(
                vm => vm.end_at,
                mapFrom(m => formatTimestamp(m.end_at, 'date-time'))
            )
        );
        createMap<AggregatedFinanceSummaryViewModelKeys[], AggregatedFinanceSummaryQueryFields[]>(
            mapper,
            nameof<AggregatedFinanceSummaryViewModelKeys>(),
            nameof<AggregatedFinanceSummaryQueryFields>(),
            constructUsing((m, _) => {
                const mapper: Record<AggregatedFinanceSummaryViewModelKeys, AggregatedFinanceSummaryQueryFields> = {
                    uid: 'uid',
                    bonus_ratio: 'bonus_ratio',
                    created_at: 'created_at.seconds',
                    end_at: 'end_at.seconds',
                    start_at: 'start_at.seconds',
                    generated_rake: 'generated_rake',
                    lifetime_bonus: 'lifetime_bonus',
                    'ggr.casino_connector': 'ggr.casino_connector',
                    'ggr.casino_poker_flips': 'ggr.casino_poker_flips',
                    'ggr.mtt_service_fee': 'ggr.mtt_service_fee',
                    'ggr.poker_features_purchase': 'ggr.poker_features_purchase',
                    'ggr.poker_guess_hand': 'ggr.poker_guess_hand',
                    'ggr.poker_insurance': 'ggr.poker_insurance',
                    'ggr.poker_rake': 'ggr.poker_rake',
                    'deposit.currency': 'deposit.currency',
                    'deposit.failed_amount': 'deposit.failed_amount',
                    'deposit.failed_count': 'deposit.failed_count',
                    'deposit.pending_amount': 'deposit.pending_amount',
                    'deposit.pending_count': 'deposit.pending_count',
                    'deposit.rejected_amount': 'deposit.rejected_amount',
                    'deposit.rejected_count': 'deposit.rejected_count',
                    'deposit.successful_amount': 'deposit.successful_amount',
                    'deposit.successful_count': 'deposit.successful_count',
                    'withdrawal.currency': 'withdrawal.currency',
                    'withdrawal.failed_amount': 'withdrawal.failed_amount',
                    'withdrawal.failed_count': 'withdrawal.failed_count',
                    'withdrawal.pending_amount': 'withdrawal.pending_amount',
                    'withdrawal.pending_count': 'withdrawal.pending_count',
                    'withdrawal.rejected_amount': 'withdrawal.rejected_amount',
                    'withdrawal.rejected_count': 'withdrawal.rejected_count',
                    'withdrawal.successful_amount': 'withdrawal.successful_amount',
                    'withdrawal.successful_count': 'withdrawal.successful_count',
                };

                return [...new Set(m.map(i => mapper[i]))];
            })
        );
    }
}
