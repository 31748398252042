import React from 'react';

import {withQueryFilter} from '@components/filter/QueryFilterHoc';
import {QueryFilterAdapterModel, QueryFilterAdapterProps} from '@components/filter/types';
import {BoUserServerFilterKeys, BoUserServerTextFilterKeys} from '@models/bo-user';
import {getMirrorObject} from '@utils/object';

import {BoUserFilterModel, BoUserFilterName, BoUserTextFilterKeys} from '../types';

import {BoUserFilters} from './BoUserFilters';

type BoUserQueryFilterAdapterProps = QueryFilterAdapterProps<BoUserServerFilterKeys, BoUserFilterName, BoUserServerTextFilterKeys>;

function BoUserQueryFilterAdapter({model, onChange, availableFilters}: BoUserQueryFilterAdapterProps) {
    const clientTextKeyToServer: Record<BoUserTextFilterKeys, BoUserServerTextFilterKeys> = {
        em_fn_ln: 'em_fn_ln',
        email: 'email',
        firstName: 'firstName',
        lastName: 'lastName',
        agentPlayerId: 'playerId',
    };
    const serverTextKeyToClient: Record<BoUserServerTextFilterKeys, BoUserTextFilterKeys> = getMirrorObject(clientTextKeyToServer);

    const filterModel: BoUserFilterModel = {
        text: model?.text ? {...model.text, option: serverTextKeyToClient[model?.text?.option]} : null,
    };

    function handleChange(model: BoUserFilterModel) {
        const result: QueryFilterAdapterModel<BoUserServerFilterKeys, BoUserServerTextFilterKeys> = {
            text: model?.text ? {...model?.text, option: clientTextKeyToServer[model?.text?.option]} : undefined,
        };

        onChange(result);
    }

    return <BoUserFilters model={filterModel} onChange={handleChange} availableFilters={availableFilters} />;
}

export const BoUserQueryFilters = withQueryFilter<BoUserServerFilterKeys, BoUserFilterName, BoUserServerTextFilterKeys>(
    BoUserQueryFilterAdapter,
    {
        em_fn_ln_apId: ['em_fn_ln', 'email', 'firstName', 'lastName', 'playerId'],
    }
);
