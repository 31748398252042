import React from 'react';

import {withQueryFilter} from '@components/filter/QueryFilterHoc';
import {QueryFilterAdapterModel, QueryFilterAdapterProps} from '@components/filter/types';
import {AgentLevelReportServerFilterKeys, AgentLevelReportServerTextFilterKeys} from '@redux/entity';
import {getMirrorObject} from '@utils/object';

import {AgentLevelReportFilterModel, AgentLevelReportFilterName, AgentLevelReportTextFilterKeys} from '../types';

import {AgentLevelReportFilters} from './AgentLevelReportFilters';

type AgentLevelReportQueryFilterAdapterProps = QueryFilterAdapterProps<
    AgentLevelReportServerFilterKeys,
    AgentLevelReportFilterName,
    AgentLevelReportServerTextFilterKeys
>;
function AgentLevelReportQueryFilterAdapter({model, onChange, ...props}: AgentLevelReportQueryFilterAdapterProps) {
    const clientTextKeyToServer: Record<AgentLevelReportTextFilterKeys, AgentLevelReportServerTextFilterKeys> = {
        refIdUname: 'ref_id_uname',
        referrerId: 'referrer_id',
        referrerUsername: 'referrer_username',
    };
    const serverTextKeyToClient: Record<AgentLevelReportServerTextFilterKeys, AgentLevelReportTextFilterKeys> =
        getMirrorObject(clientTextKeyToServer);

    const filterModel: AgentLevelReportFilterModel = {
        text: model?.text ? {...model.text, option: serverTextKeyToClient[model?.text?.option]} : null,
    };

    function handleChange(model: AgentLevelReportFilterModel) {
        const result: QueryFilterAdapterModel<AgentLevelReportServerFilterKeys, AgentLevelReportServerTextFilterKeys> = {
            text: model?.text ? {...model?.text, option: clientTextKeyToServer[model?.text?.option]} : undefined,
        };

        onChange(result);
    }

    return <AgentLevelReportFilters model={filterModel} onChange={handleChange} {...props} />;
}

export const AgentLevelReportQueryFilters = withQueryFilter<
    AgentLevelReportServerFilterKeys,
    AgentLevelReportFilterName,
    AgentLevelReportServerTextFilterKeys
>(AgentLevelReportQueryFilterAdapter, {
    ref_id_uname: ['ref_id_uname', 'referrer_id', 'referrer_username'],
});
