import {useDispatch, useSelector} from 'react-redux';
import {PayloadActionCreator, RootState} from 'typesafe-actions';

import {LocalizedMessageData} from 'src/common/types';
import {KeyValueDataStorageItem} from '../module-shared/types';

import tempStateStorageActions from './actions';

const useTempStorageMessageState = (
    key: string,
    messageSelector: (s: RootState) => LocalizedMessageData[],
    changeAction: PayloadActionCreator<string, KeyValueDataStorageItem<LocalizedMessageData[]>>
) => {
    const dispatch = useDispatch();
    const message = useSelector(messageSelector);
    const hasMessage = message !== null && message !== undefined;

    const clearMessage = () => {
        dispatch(changeAction({key: key, value: null}));
    };

    return {
        message,
        hasMessage,
        clearMessage,
    };
};

export const useTempStorageErrorState = (key: string) => {
    return useTempStorageMessageState(key, (s: RootState) => s.tempStorage.error[key], tempStateStorageActions.changeErrorState);
};

export const useTempStorageInfoState = (key: string) => {
    return useTempStorageMessageState(key, (s: RootState) => s.tempStorage.info[key], tempStateStorageActions.changeInfoState);
};
