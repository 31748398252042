import {AccountVerification} from '@models/generated/graphql';

import {protectEpics} from '../app/error-handling/epics';
import {createEntityRealtimeEpics} from '../realtime-updates/epics';

import {realtimeSubscribersSelector, subscribedTypesSelector} from './selectors';
import {domain} from './types';

export const kycEntityEpics = protectEpics(
    createEntityRealtimeEpics<AccountVerification>(
        domain,
        container => container.kycRealtimeService,
        realtimeSubscribersSelector,
        subscribedTypesSelector
    )
);
