import {injectable} from 'inversify';
import {Observable, of} from 'rxjs';

import {configDev, configWPTO} from '../../../configuration';

import {FeatureName, Jurisdiction, JurisdictionConfiguration} from './types';

const config = {
    [Jurisdiction.NJ]: configDev,
    [Jurisdiction.PKW]: configDev,
    [Jurisdiction.MT]: configDev,
    [Jurisdiction.IM]: configDev,
    [Jurisdiction.WPTO]: configWPTO,
    [Jurisdiction.Dev]: configDev,
};

@injectable()
export class JurisdictionConfigService {
    getConfigurationObservable(jurisdiction: Jurisdiction): Observable<JurisdictionConfiguration> {
        return of(this.getConfiguration(jurisdiction));
    }

    getConfiguration(jurisdiction: Jurisdiction): JurisdictionConfiguration {
        return config[jurisdiction];
    }

    isFeatureDisabled(jurisdiction: Jurisdiction, featureName: FeatureName) {
        const limitedFeatures = this.getConfiguration(jurisdiction).limitedFeatures;
        return limitedFeatures?.find(f => f.featureName === featureName) !== undefined;
    }
}
