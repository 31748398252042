import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box, Typography} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';

import {useEnrichedButtonStyles} from './EnrichedButton.style';

export type EnrichedButtonItem = {
    id: string;
    title: MessageDescriptor;
    description: MessageDescriptor;
    icon: string;
    selected: boolean;
    className?: string;
    onClick?: () => void;
    component: () => JSX.Element;
    dataTestid?: string;
};

export type EnrichedButtonProps = EnrichedButtonItem;

export const EnrichedButton = ({title, description, icon, selected, className, onClick, dataTestid}: EnrichedButtonProps) => {
    const {classes, cx} = useEnrichedButtonStyles();

    return (
        <Box
            data-testid={dataTestid}
            className={cx(classes.enrichedButtonContainer, {[classes.enrichedButtonContainerSelected]: selected}, className)}
            onClick={onClick}
        >
            <Box className={cx(classes.enrichedButtonIconContainer, {[classes.enrichedButtonIconContainerSelected]: selected})}>
                <Box component="span" className={icon} fontSize={30} />
            </Box>
            <Box className={classes.enrichedButtonTitleDescriptionContainer}>
                <Box className={classes.enrichedButtonTitleContainer}>
                    <Typography variant="h6">
                        <LocalizedText label={title} />
                    </Typography>
                </Box>
                <Box className={classes.enrichedButtonDescriptionContainer}>
                    <Typography variant="body1" color="secondary">
                        <LocalizedText label={description} />
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
