import equal from 'fast-deep-equal/es6';
import produce from 'immer';
import {combineReducers, ReducersMapObject} from 'redux';
import {createReducer, PayloadAction, Reducer} from 'typesafe-actions';

import {isObject} from '@utils';

import {EntityType} from './types/base';
import {
    EntityActions,
    entityActions,
    EntityCleanActionPayload,
    EntityReferenceCounterActionPayload,
    EntitySaveActionPayload,
    UpdateItemPayload,
} from './actions';

export type EntityState = Record<string, unknown>;

type EntityReferences = Record<string, number>;

export type EntitiesState = {
    entities: Partial<Record<EntityType, EntityState>>;
    references: EntityReferences;
};

export class EntityReducerCreator {
    createReducer() {
        const entitiesReducer: ReducersMapObject<Partial<Record<EntityType, EntityState>>, EntityActions> = {};
        Object.values<EntityType>(EntityType).forEach(
            (entityType: EntityType) => (entitiesReducer[entityType] = this.createEntityItemsReducer(entityType))
        );

        const referencesReducer = this.createReferencesReducer();

        return combineReducers<EntitiesState, EntityActions>({
            entities: combineReducers(entitiesReducer),
            references: referencesReducer,
        });
    }

    private createEntityItemsReducer(entityType: EntityType): Reducer<EntityState, EntityActions> {
        return createReducer<EntityState, EntityActions>({})
            .handleAction(entityActions.save, (state: Record<string, unknown>, action: PayloadAction<string, EntitySaveActionPayload>) => {
                let newState: EntityState = state;
                if (entityType === action.payload.type && action.payload.items && Object.keys(action.payload.items).length !== 0) {
                    newState = produce(state, draftState => {
                        this.updateItemField(draftState, action.payload.items);
                    });
                }

                return newState;
            })
            .handleAction(entityActions.updateItem, (state: Record<string, unknown>, action: PayloadAction<string, UpdateItemPayload>) => {
                let newState: EntityState = state;
                if (entityType === action.payload.type && action.payload.id && action.payload.updatedItem && state[action.payload.id]) {
                    newState = produce(state, draftState => {
                        this.updateItemField(draftState[action.payload.id], action.payload.updatedItem);
                    });
                }

                return newState;
            })
            .handleAction(
                entityActions.clean,
                (state: Record<string, unknown>, action: PayloadAction<string, EntityCleanActionPayload>) => {
                    let newState: EntityState = state;
                    const {ids, type} = action.payload;
                    if (entityType === type) {
                        newState = produce(state, draftState => {
                            ids.forEach(id => {
                                delete draftState[id];
                            });
                        });
                    }
                    return newState;
                }
            );
    }

    private createReferencesReducer(): Reducer<EntityReferences, EntityActions> {
        return createReducer<EntityReferences, EntityActions>({})
            .handleAction(
                entityActions.increaseReferenceCounter,
                (state: EntityReferences, action: PayloadAction<string, EntityReferenceCounterActionPayload>) => {
                    let newState: EntityReferences = state;
                    newState = produce(state, draftState => {
                        const {references} = action.payload;

                        if (references?.length > 0) {
                            references.forEach(ref => {
                                let count = draftState[ref];
                                count = count > 0 ? count + 1 : 1;
                                draftState[ref] = count;
                            });
                        }

                        draftState;
                    });
                    return newState;
                }
            )
            .handleAction(
                entityActions.decreaseReferenceCounter,
                (state: EntityReferences, action: PayloadAction<string, EntityReferenceCounterActionPayload>) => {
                    let newState: EntityReferences = state;
                    newState = produce(state, draftState => {
                        const {references} = action.payload;

                        if (references?.length > 0) {
                            references.forEach(ref => {
                                let count = draftState[ref];
                                count = count > 0 ? count - 1 : 0;
                                draftState[ref] = count;
                            });
                        }

                        draftState;
                    });
                    return newState;
                }
            )
            .handleAction(
                entityActions.cleanReferenceCounter,
                (state: EntityReferences, action: PayloadAction<string, EntityReferenceCounterActionPayload>) => {
                    let newState: EntityReferences = state;
                    newState = produce(state, draftState => {
                        const {references} = action.payload;

                        if (references?.length > 0) {
                            references.forEach(ref => {
                                delete draftState[ref];
                            });
                        }

                        draftState;
                    });
                    return newState;
                }
            );
    }

    private updateItemField<TModel>(currentItem: TModel, newItem: TModel): void {
        Object.entries(newItem).forEach(value => {
            const [key, newValue] = value as [keyof TModel, TModel[keyof TModel]];
            const isFieldExists = Object.prototype.hasOwnProperty.call(currentItem, key);

            const isFieldEmpty = !isFieldExists || currentItem[key] === null;
            const isFieldChanged = isFieldExists && !equal(newValue, currentItem[key]);
            if (isFieldEmpty || (isFieldChanged && !isObject(newValue))) {
                currentItem[key] = newValue;
            } else if (isFieldChanged && isObject(newValue)) {
                this.updateItemField(currentItem[key], newValue);
            }
        });
    }
}
