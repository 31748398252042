import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {usePolicyAccessCheck} from '@access-control';
import {useService} from '@inversify';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {useJurisdictionFeature} from 'src/features/app/config/hooks';
import {FeatureName} from 'src/features/app/config/types';
import {financeActionsUpdatePermissions, p2pLockUpdateResource} from 'src/features/block-user-profile-actions/permissions';
import {useMultiplePermissions} from '../../app/permission/PermissionHoc';
import {
    bulkActionsActions,
    BulkChooseActionStepLockItem,
    BulkLockItemValue,
    itemsSelector,
    p2pTransferLockResultValueSelector,
} from '../../block-bulk-actions';
import {UserProfileGridItem} from '../../data-grids/types';
import {ApplyP2PLockRequest, ApplyP2PLockStrategy} from '../services/applyStrategy';

const localized = defineMessages({
    p2pTransferStatus: {
        id: 'BulkActionP2PLock_p2pTransferStatus',
        defaultMessage: 'P2P transfer',
    },
});

function ActionP2PLock() {
    const dispatch = useDispatch();
    const strategy = useService<ApplyP2PLockStrategy>('ApplyP2PLockStrategy');
    const players = useSelector((state: RootState) => itemsSelector<UserProfileGridItem>(state));
    const initialValues = players.map(i => i.p2p_transfer_status === FeatureAccessStatus.Locked);
    const resultValue: FeatureAccessStatus = useSelector(p2pTransferLockResultValueSelector);

    function handleSubmit(toggleValue: BulkLockItemValue): void {
        const valueMapper: Record<BulkLockItemValue, FeatureAccessStatus> = {
            [BulkLockItemValue.Checked]: FeatureAccessStatus.Locked,
            [BulkLockItemValue.Unchecked]: FeatureAccessStatus.Unlocked,
            [BulkLockItemValue.NoChanges]: null,
        };
        const request: ApplyP2PLockRequest = {players, value: valueMapper[toggleValue]};
        dispatch(bulkActionsActions.apply({strategy, request}));
    }

    return (
        <BulkChooseActionStepLockItem
            label={localized.p2pTransferStatus}
            initialValues={initialValues}
            resultValue={resultValue ? resultValue === FeatureAccessStatus.Locked : null}
            onSubmit={handleSubmit}
        />
    );
}

export function BulkUserProfileP2PLock() {
    const isP2pLockPolicyAvailiable = useJurisdictionFeature({
        featureName: FeatureName.FinanceActionsNewPolicies,
    });

    const isAvailable = isP2pLockPolicyAvailiable
        ? usePolicyAccessCheck(p2pLockUpdateResource)
        : useMultiplePermissions({allowedPermissions: financeActionsUpdatePermissions});

    return isAvailable ? <ActionP2PLock /> : <></>;
}
