import {createReducer} from 'typesafe-actions';

import {getFilterString, getSortString, toSearchFilter} from '@utils';

import {filterActions} from './actions';

export const getFilterReducer = (domain: string) => {
    const actions = filterActions(domain);

    const filterReducer = createReducer<string>(null)
        .handleAction(actions.setFilter, (_, action) => action.payload)
        .handleAction(actions.updateFilter, (_, action) => action.payload)
        .handleAction(actions.addFilters, (state, action) => getFilterString(state ?? '', false, ...action.payload))
        .handleAction(actions.setDefaultSorting, (state, action) => {
            const stateSorting = toSearchFilter(state)?.sorting;
            return stateSorting?.length ? state : getSortString(state ?? '', action.payload);
        });

    return {
        filter: filterReducer,
    };
};
