import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {Chip, ChipProps, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {StyledTooltip} from '@components/Tooltip';
import {CustomTheme} from '@style';

import {SelectOption} from '../../../features/module-shared/types';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    multiSelectTextCounterWithTooltipInput: {
        position: 'relative',
        height: theme.custom.denseButtonHeight,
    },
    multiSelectTextCounterWithTooltip: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    multiSelectTextCounterWithTooltipBody: {
        maxWidth: '400px',
        maxHeight: '300px',
        overflowY: 'auto',
    },
    multiSelectTextCounterWithTooltipTitle: {
        display: 'flex',
        gap: theme.spacing(0.5),
    },
    multiSelectTextCounterWithTooltipList: {
        marginTop: theme.spacing(1.75),
        display: 'inline-flex',
        flexWrap: 'wrap',
        rowGap: theme.spacing(1.25),
        columnGap: theme.spacing(0.75),
    },
    multiSelectTextCounterWithTooltipLabel: {
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        maxWidth: '100px',
    },
    multiSelectTextCounterWithTooltipFilterLabel: {
        marginRight: theme.spacing(1.25),
        color: theme.palette.secondary.main,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export type MultiSelectTextCounterWithTooltipProps = {
    label?: string | MessageDescriptor;
    filterLabel?: string | MessageDescriptor;
    tooltipTitle: string | MessageDescriptor;
    selectedTotal: number;
    values: string[];
    options: SelectOption[];
    element?: JSX.Element;
} & Pick<ChipProps, 'color'>;

export function MultiSelectTextCounterWithTooltip({
    label,
    tooltipTitle,
    selectedTotal,
    values,
    options,
    element,
    filterLabel,
    color,
}: MultiSelectTextCounterWithTooltipProps) {
    const {classes} = useStyles();
    const {formatMessage} = useIntl();
    const maxValuesCount = 200;

    function getLabel(value: string): string {
        const label = options?.find(o => o.value === value)?.label;
        return label ? (typeof label === 'string' ? label : formatMessage(label)) : '';
    }

    return (
        <StyledTooltip
            placement="bottom-start"
            title={
                <div className={classes.multiSelectTextCounterWithTooltipBody}>
                    <div className={classes.multiSelectTextCounterWithTooltipTitle}>
                        <LocalizedText label={tooltipTitle} />
                        <Chip label={selectedTotal} size="small" />
                    </div>
                    {values?.length ? (
                        <div className={classes.multiSelectTextCounterWithTooltipList}>
                            {values.slice(0, maxValuesCount).map(value => (
                                <Chip
                                    label={<LocalizedText label={options?.length ? options?.find(o => o?.value === value).label : value} />}
                                    size="small"
                                />
                            ))}
                            {selectedTotal > maxValuesCount ? <Chip label="..." size="small" /> : null}
                        </div>
                    ) : null}
                </div>
            }
            element={
                element ? (
                    <div>{element}</div>
                ) : (
                    <div className={classes.multiSelectTextCounterWithTooltipInput}>
                        <div className={classes.multiSelectTextCounterWithTooltip}>
                            <Chip label={selectedTotal} size="small" color={color} />
                            {filterLabel ? (
                                <Typography
                                    variant="body1"
                                    className={classes.multiSelectTextCounterWithTooltipFilterLabel}
                                    component="span"
                                >
                                    <LocalizedText label={filterLabel} />
                                </Typography>
                            ) : (
                                <></>
                            )}

                            <div className={classes.multiSelectTextCounterWithTooltipLabel}>
                                <LocalizedText label={label ?? values?.map(v => getLabel(v)).join(', ')} />
                            </div>
                        </div>
                    </div>
                )
            }
        />
    );
}
