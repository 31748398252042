import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {QueryGetUserActivitySummaryArgs, UserActivitySummary} from '@models/generated/graphql';
import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload, Filter} from '@redux/entity';
import {UserActivitySummaryFilterKeys, UserActivitySummaryQueryFields} from '@redux/entity/types/entityUserActivitySummary';
import {EntityBaseGqlService, IEntityReadService} from '@services/entity';
import {GqlRequestBuilder} from '@services/entity/GqlRequestBuilder';
import {ApolloClientProxy} from '@services/gql-api';
import {getValueFromQueryFilter} from '@utils/query';

export class UserActivitySummaryServiceModel extends UserActivitySummary {
    uid: string;
}

export interface IUserActivitySummaryService extends IEntityReadService {}

@injectable()
export class UserActivitySummaryService
    extends EntityBaseGqlService<QueryGetUserActivitySummaryArgs, UserActivitySummaryQueryFields, UserActivitySummaryFilterKeys>
    implements IUserActivitySummaryService
{
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new UserActivitySummaryRequestBuilder());
    }

    public get(
        requestPayload: EntityFetchRequestPayload<UserActivitySummaryQueryFields>
    ): Observable<EntityFetchServiceResponsePayload<UserActivitySummaryServiceModel>> {
        return super.get(requestPayload).pipe(
            map((response: EntityFetchServiceResponsePayload<UserActivitySummary>) => {
                const uid = getValueFromQueryFilter<UserActivitySummaryFilterKeys, string>(requestPayload?.filter, 'uid');
                const payload = response?.responsePayload;
                return payload?.items?.length
                    ? {
                          ...response,
                          responsePayload: {
                              items: payload?.items?.map<UserActivitySummaryServiceModel>((i: UserActivitySummary) => ({
                                  ...(i ?? {}),
                                  uid,
                              })),
                              total: payload?.total,
                          },
                      }
                    : (response as EntityFetchServiceResponsePayload<UserActivitySummaryServiceModel>);
            })
        );
    }
}

export class UserActivitySummaryRequestBuilder extends GqlRequestBuilder<
    QueryGetUserActivitySummaryArgs,
    UserActivitySummaryQueryFields,
    UserActivitySummaryFilterKeys
> {
    protected getGqlPageArgs(filter: Filter<UserActivitySummaryFilterKeys>): QueryGetUserActivitySummaryArgs {
        return {uid: this.toGQLStringFilter(filter, 'uid')};
    }
    protected buildFilter(_: Filter<UserActivitySummaryFilterKeys>): object {
        return undefined;
    }

    protected buildQuery(fields: UserActivitySummaryQueryFields[]): DocumentNode {
        return gql`
            query GetUserActivitySummary($uid: String) {
                getUserActivitySummary(uid: $uid) {
                    ${this.buildQueryFields(fields)}
                }
            }
        `;
    }
}
