import React, {FC} from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {UserChip} from '@components/chip/UserChip';
import {withEmptyCheck} from '@components/label';

import {renderValueWithTooltip} from './GridCellWithTooltip';

type UserChipCellProps = {
    value: string;
};

export const UserChipCell: FC<UserChipCellProps> = withEmptyCheck(({value}: UserChipCellProps) => {
    return renderValueWithTooltip(<UserChip userName={value} />);
});

export const renderUserChipCell = (params: GridCellParams) => {
    const value = params.value;
    return <UserChipCell value={value as string} />;
};
