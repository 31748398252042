import {createAsyncAction} from 'typesafe-actions';

import {MarketingCode, MutationAddMarketingCodeArgs, MutationDeleteMarketingCodesArgs} from '@models/generated/graphql';

import {IFailureResponse} from '../module-shared/types';
import {createEntityRealtimeActions} from '../realtime-updates/actions';

import {domain} from './types';

const addMarketingCodeAction = '/addMarketingCode';
const addMarketingCodeSucceedAction = '/addMarketingCodeSucceed';
const addMarketingCodeFailedAction = '/addMarketingCodeFailed';

const deleteMarketingCodesAction = '/deleteMarketingCodes';
const deleteMarketingCodesSucceedAction = '/deleteMarketingCodesSucceed';
const deleteMarketingCodesFailedAction = '/deleteMarketingCodesFailed';

export const marketingCodeActions = {
    ...createEntityRealtimeActions(domain),

    addMarketingCode: createAsyncAction(
        `${domain}${addMarketingCodeAction}`,
        `${domain}${addMarketingCodeSucceedAction}`,
        `${domain}${addMarketingCodeFailedAction}`
    )<MutationAddMarketingCodeArgs, MarketingCode, IFailureResponse>(),

    deleteMarketingCodes: createAsyncAction(
        `${domain}${deleteMarketingCodesAction}`,
        `${domain}${deleteMarketingCodesSucceedAction}`,
        `${domain}${deleteMarketingCodesFailedAction}`
    )<MutationDeleteMarketingCodesArgs, MarketingCode, IFailureResponse>(),
};
