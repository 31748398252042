import React from 'react';
import {useSelector} from 'react-redux';

import {GridColDef, MuiDataGridServer} from '@components/data-grid/mui';
import {LayoutDetailsBlock} from '@components/layout/LayoutDetailsBlock';
import {PlayerMessage} from '@models/generated/graphql';

import {Filter} from 'src/common/types';
import {domain as entityDomain} from '../../../player-message/types';
import {useRealtimeUpdates} from '../../../realtime-updates/hooks';
import {SubscriptionType} from '../../../realtime-updates/types';
import {useFilter} from '../../../shared/filter/hooks';
import {filterSelector, itemPageSelector} from '../selectors';
import {domain} from '../types';

import {userNotificationHistoryData} from './NotificationHistory.config';
import {localizedNotificationHistory} from './NotificationHistory.localize';

type NotificationHistoryProps = {
    userId: string;
};

export const NotificationHistory = ({userId}: NotificationHistoryProps) => {
    const notificationHistory = useSelector(itemPageSelector);
    const defaultFilter: Filter[] = [{key: nameof<PlayerMessage>(i => i.uid), value: userId}];
    const filterString = useSelector(filterSelector);
    const {searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useFilter(domain, filterString, defaultFilter);

    useRealtimeUpdates(entityDomain, domain, [SubscriptionType.Added]);

    return (
        <LayoutDetailsBlock
            content={
                <MuiDataGridServer
                    domain={domain}
                    autoHeight={true}
                    rowCount={notificationHistory.total}
                    rows={notificationHistory.items}
                    columns={userNotificationHistoryData.columns as GridColDef[]}
                    emptyMessage={localizedNotificationHistory.emptyList}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    sortModel={searchFilter?.sorting}
                    onSortModelChange={handleSortChange}
                />
            }
        ></LayoutDetailsBlock>
    );
};
