import React from 'react';
import {FormLabel as MuiFormLabel, FormLabelProps as MuiFormLabelProps} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    formLabelCaption: {
        textTransform: 'uppercase',
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        letterSpacing: 1,
        fontWeight: 500,
        padding: theme.spacing(0.5, 0),
    },
    formLabelDefault: {
        color: theme.custom.palette.content.headingText,
    },
}));

export type FormLabelProps = MuiFormLabelProps & {
    label?: string;
    labelVariant?: 'caption' | 'default';
};

export function FormLabel({label, labelVariant}: FormLabelProps) {
    const {classes, cx} = useClasses();

    return (
        <MuiFormLabel
            focused={false}
            className={cx({
                [classes.formLabelCaption]: labelVariant === 'caption',
                [classes.formLabelDefault]: labelVariant === 'default',
            })}
        >
            {label}
        </MuiFormLabel>
    );
}
