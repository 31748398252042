import {createAction, createAsyncAction} from 'typesafe-actions';

import {Module} from './types';

const modulesLoad = 'modules/modulesRequest';
const modulesLoadSuccess = 'modules/modulesSuccess';
const modulesLoadFailure = 'modules/modulesFailure';

const selectModule = 'modules/selectModule';

export const loadModulesAction = createAsyncAction(modulesLoad, modulesLoadSuccess, modulesLoadFailure)<void, Module[], void>();

export const selectModuleAction = createAction(selectModule)<string>();
