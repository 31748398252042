import {defineMessages} from 'react-intl';

export const localizedRegistrationInfo = defineMessages({
    title: {
        id: 'RI_title',
        defaultMessage: 'Registration Information',
    },
    id: {
        id: 'RI_id',
        defaultMessage: 'Unique Id',
    },
    userName: {
        id: 'RI_userName',
        defaultMessage: 'Username',
    },
    firstName: {
        id: 'RI_firstName',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'RI_lastName',
        defaultMessage: 'Last Name',
    },
    ipAddress: {
        id: 'RI_ipAddress',
        defaultMessage: 'IP Address',
    },
    email: {
        id: 'RI_email',
        defaultMessage: 'Email',
    },
    phone: {
        id: 'RI_phone',
        defaultMessage: 'Phone',
    },
    gender: {
        id: 'RI_gender',
        defaultMessage: 'Gender',
    },
    registrationDate: {
        id: 'RI_registrationDate',
        defaultMessage: 'Date of Registration',
    },
    loginStatus: {
        id: 'RI_loginStatus',
        defaultMessage: 'Login Status',
    },
    birthDate: {
        id: 'RI_birthDate',
        defaultMessage: 'Date of Birth',
    },
    country: {
        id: 'RI_country',
        defaultMessage: 'Country',
    },
    postCode: {
        id: 'RI_postCode',
        defaultMessage: 'Post Code',
    },
    address: {
        id: 'RI_address',
        defaultMessage: 'Street Address',
    },
    city: {
        id: 'RI_city',
        defaultMessage: 'City',
    },
    state: {
        id: 'RI_state',
        defaultMessage: 'State',
    },
    ssn: {
        id: 'RI_ssn',
        defaultMessage: 'SSN',
    },
    twoFA: {
        id: 'RI_twoFA',
        defaultMessage: '2FA',
    },
    hasChallengeQuestions: {
        id: 'RI_hasChallengeQuestions',
        defaultMessage: 'Challenge Questions',
    },
    actions: {
        id: 'RI_actions',
        defaultMessage: 'Actions',
    },
    dateOfJoiningFrom: {
        id: 'RI_dateOfJoiningFrom',
        defaultMessage: 'Joined from',
    },
    dateOfJoiningTo: {
        id: 'RI_dateOfJoiningTo',
        defaultMessage: 'to',
    },
    resetPasscodeSuccess: {
        id: 'RI_resetPasscodeSuccess',
        defaultMessage: 'Passcode was successfully reset',
    },
    enabled: {
        id: 'RI_enabled',
        defaultMessage: 'Enabled',
    },
    disabled: {
        id: 'RI_disabled',
        defaultMessage: 'Disabled',
    },
    //filters
    textFullFilter: {
        id: 'RI_textFullFilter',
        defaultMessage: 'All',
    },
    userIdFilter: {
        id: 'RI_userIdFilter',
        defaultMessage: 'Player ID',
    },
    usernameFilter: {
        id: 'RI_usernameFilter',
        defaultMessage: 'Username',
    },
    firstNameFilter: {
        id: 'RI_firstNameFilter',
        defaultMessage: 'First Name',
    },
    lastNameFilter: {
        id: 'RI_lastNameFilter',
        defaultMessage: 'Last Name',
    },
    emailFilter: {
        id: 'RI_emailFilter',
        defaultMessage: 'Email',
    },
    textFullFilterPlaceholder: {
        id: 'RI_textFullFilterPlaceholder',
        defaultMessage: 'Search in Registration Information',
    },
    userIdFilterPlaceholder: {
        id: 'RI_userIdFilterPlaceholder',
        defaultMessage: '123',
    },
    usernameFilterPlaceholder: {
        id: 'RI_usernameFilterPlaceholder',
        defaultMessage: 'For example, Antonio',
    },
    firstNameFilterPlaceholder: {
        id: 'RI_firstNameFilterPlaceholder',
        defaultMessage: 'First Name',
    },
    lastNameFilterPlaceholder: {
        id: 'RI_lastNameFilterPlaceholder',
        defaultMessage: 'Last Name',
    },
    emailFilterPlaceholder: {
        id: 'RI_emailFilterPlaceholder',
        defaultMessage: 'For example, antonio@xyz.com',
    },
    countryFilter: {
        id: 'RI_countryFilter',
        defaultMessage: 'Country',
    },
    countryFilterPlaceholder: {
        id: 'RI_countryFilterPlaceholder',
        defaultMessage: 'For example, Ireland',
    },
});
