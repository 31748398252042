import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {getContentModuleStateReducer, getContentSingleItemReducer} from '../../../features/module-shared/reducers';

import {domain} from './types';

const kycDetailsDocumentsReducer = combineReducers({
    domain: createReducer(domain),
    data: combineReducers(getContentSingleItemReducer(domain)),
    state: combineReducers(getContentModuleStateReducer(domain)),
});

export default kycDetailsDocumentsReducer;
