import {GridCellParams} from '@mui/x-data-grid';

import {
    ColumnConfigurationColDef,
    gridCellDefaultFormatter,
    GridColDef,
    renderCellHeader,
    renderCellWithTooltip,
} from '@components/data-grid/mui';
import {PlayerMessage} from '@models/generated/graphql';
import {formatTimestamp, sortByTimestamp} from '@utils';

import {PlayerMessageColumnsConfiguration, PlayerMessageGridItem} from '../../../player-message/types';

import {localizedNotificationHistory} from './NotificationHistory.localize';
import {renderNotificationHistoryDetailsAction} from './NotificationHistoryDetailsAction';
import {renderNotificationHistorySender} from './NotificationHistorySender';

const getGridColumns = () => {
    const columns: (GridColDef & ColumnConfigurationColDef)[] = [
        {
            field: nameof<PlayerMessageGridItem>(b => b.id),
            hide: true,
            configurationColumns: [
                nameof<PlayerMessageColumnsConfiguration>(c => c.withId),
                nameof<PlayerMessageColumnsConfiguration>(c => c.withUid),
            ],
        },
        {
            field: nameof<PlayerMessageGridItem>(b => b.subject),
            renderHeader: () => renderCellHeader(localizedNotificationHistory.subject),
            configurationColumns: [nameof<PlayerMessageColumnsConfiguration>(c => c.withSubject)],
            flex: 1,
        },
        {
            field: nameof.full<PlayerMessageGridItem>(b => b.sent_at.seconds),
            renderHeader: () => renderCellHeader(localizedNotificationHistory.dateAndTime),
            valueFormatter: gridCellDefaultFormatter<PlayerMessageGridItem>(b => formatTimestamp(b.sent_at, 'date-time')),
            configurationColumns: [nameof<PlayerMessageColumnsConfiguration>(c => c.withSentAt)],
            sortField: nameof.full<PlayerMessageGridItem>(u => u.sent_at.seconds),
            sortDirection: 'desc',
            sortComparator: sortByTimestamp,
            sortable: true,
            flex: 1,
        },
        {
            field: 'sender_email',
            renderHeader: () => renderCellHeader(localizedNotificationHistory.sender),
            valueGetter: (params: GridCellParams) => (params.row as PlayerMessageGridItem).sender_id,
            renderCell: params => renderNotificationHistorySender(params.value as string),
            sortable: false,
            configurationColumns: [nameof<PlayerMessageColumnsConfiguration>(c => c.withSenderId)],
            flex: 1,
        },
        {
            field: 'actions',
            headerName: ' ',
            renderCell: params => renderNotificationHistoryDetailsAction(params.row as PlayerMessage),
            width: 75,
            sortable: false,
            configurationColumns: [
                nameof<PlayerMessageColumnsConfiguration>(c => c.withId),
                nameof<PlayerMessageColumnsConfiguration>(c => c.withUid),
                nameof<PlayerMessageColumnsConfiguration>(c => c.withTitle),
                nameof<PlayerMessageColumnsConfiguration>(c => c.withSubject),
                nameof<PlayerMessageColumnsConfiguration>(c => c.withSentAt),
                nameof<PlayerMessageColumnsConfiguration>(c => c.withSender),
                nameof<PlayerMessageColumnsConfiguration>(c => c.withContent),
                nameof<PlayerMessageColumnsConfiguration>(c => c.withSignature),
            ],
        },
    ];

    const defaultCol: GridColDef = {
        field: '',
        renderCell: renderCellWithTooltip,
        sortable: false,
    };

    return columns.map(c => ({...defaultCol, ...c}));
};

export const userNotificationHistoryData = {
    columns: getGridColumns(),
};
