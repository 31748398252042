import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {MultiSelectWithExcludeFilter, MultiSelectWithExcludeFilterProps} from '@components/filter';

import {SelectOption} from '../../module-shared/types';
import {useCountries} from '../hooks';

const localized = defineMessages({
    label: {
        id: 'CountryFilter_label',
        defaultMessage: 'Country',
    },
    searchPlaceholder: {
        id: 'CountryFilter_searchPlaceholder',
        defaultMessage: 'Search country',
    },
    countries: {
        id: 'CountryFilter_countries',
        defaultMessage: 'countries',
    },
});

export function CountryFilter({
    value,
    onChange,
    inputLabel = localized.label,
}: Pick<MultiSelectWithExcludeFilterProps, 'value' | 'onChange' | 'inputLabel' | 'searchPlaceholder'>) {
    const {formatMessage} = useIntl();
    const {countries} = useCountries({
        viewType: 'CountryFilter',
        validateFilter: () => true,
        isCancelable: false,
    });
    return (
        <div>
            <MultiSelectWithExcludeFilter
                mode="client"
                virtualization
                value={value}
                onChange={onChange}
                options={countries.map<SelectOption>(c => ({value: c.iso2Code, label: c.name}))}
                searchPlaceholder={localized.searchPlaceholder}
                showResetButton
                total={countries?.length}
                itemsName={formatMessage(localized.countries)}
                inputLabel={inputLabel}
            />
        </div>
    );
}
