import React, {ReactChild, useEffect} from 'react';
import {MessageDescriptor} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';

import {IModuleGridItem} from '@components/data-grid';
import {GridColDef, MuiDataGridServer} from '@components/data-grid/mui';
import {LayoutQueue} from '@components/layout';

import {ItemsPage, Sorting} from 'src/common/types';
import {setBreadcrumbAction} from '../../app/breadcrumb/actions';
import {GridToolbar} from '../../data-grids/components/GridToolbar';
import {useModuleAccess} from '../../modules/hooks';
import {ModuleAction, ModuleHeaderAction} from '../../shared/actions/types';
import {useFilter} from '../../shared/filter/hooks';
import {Filter} from '../../shared/filter/types';
import {FormModuleData, IModuleItem, PageModuleAction} from '../types';

import {ModuleProps, withModuleContentLoad} from './ModuleContentLoadHoc';
import ModuleHeader from './ModuleHeader';
import {useModulePageClasses} from './ModulePage.style';

class ModulePageProps extends ModuleProps {
    alignActionsRight?: boolean;
    header?: string | MessageDescriptor;
    subHeader?: string | MessageDescriptor;
    columns: GridColDef[];
    filters?: Filter[];
    moduleActions?: ModuleAction[];
    actions?: PageModuleAction[];
    headerActions?: ModuleHeaderAction[];
    form?: ReactChild;
    data: {
        domain: string;
        data: {
            editForm?: FormModuleData<IModuleItem>;
            itemsPage: ItemsPage<IModuleGridItem>;
            route: string;
            state: {
                filter: string;
            };
        };
    };
    checkboxSelection?: boolean;
    showBackButton?: boolean;
    selectedItems?: IModuleGridItem[];
    defaultSorting: Sorting<string>[];
}

const ModulePage = ({
    header,
    subHeader,
    filters,
    columns,
    actions,
    moduleActions,
    form,
    data,
    domain,
    checkboxSelection,
    selectedItems,
    alignActionsRight = true,
    headerActions,
    showBackButton,
    defaultSorting,
}: ModulePageProps) => {
    const {classes} = useModulePageClasses();
    const dispatch = useDispatch();

    const {
        data: {
            editForm,
            itemsPage,
            state: {filter: filterString},
        },
    } = data;
    const {searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useFilter(
        domain,
        filterString,
        filters?.map(f => f.defaultValue)?.flat(),
        defaultSorting,
        null,
        true
    );

    useEffect(() => {
        dispatch(setBreadcrumbAction([]));
    }, []);

    const accessibleActions = actions?.filter(a => {
        const {moduleName, submoduleName, featureName, permissions} = a;
        const isAvailable = useModuleAccess(permissions, moduleName, submoduleName, featureName);

        return isAvailable;
    });

    const accessibleHeaderActions = headerActions?.filter(a => {
        const {moduleName, submoduleName, featureName, permissions} = a;
        const isAvailable = useModuleAccess(permissions, moduleName, submoduleName, featureName);

        return isAvailable;
    });

    const getHeader = () => {
        return (
            header && (
                <>
                    <ModuleHeader
                        showBackButton={showBackButton}
                        alignActionsRight={alignActionsRight}
                        header={header}
                        subHeader={subHeader}
                        actions={accessibleHeaderActions}
                    >
                        {accessibleActions?.map((action, index) => {
                            const ActionComponent = action.render;
                            return <ActionComponent key={index} />;
                        })}
                    </ModuleHeader>
                </>
            )
        );
    };

    const getToolbar = () => {
        return <GridToolbar filters={filters} actions={moduleActions} domain={domain} filterString={filterString} />;
    };

    const getBody = () => {
        return (
            <>
                <Box className={`${classes.moduleGrid} ${editForm?.item ? classes.moduleGridEditMode : ''}`}>
                    {columns === null ? null : (
                        <MuiDataGridServer
                            checkboxSelection={checkboxSelection}
                            selectedItems={selectedItems}
                            rows={itemsPage.items}
                            rowCount={itemsPage.total}
                            columns={columns}
                            domain={domain}
                            page={searchFilter?.paging?.page}
                            pageSize={searchFilter?.paging?.pageSize}
                            sortModel={searchFilter?.sorting}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onSortModelChange={handleSortChange}
                        />
                    )}
                </Box>
                {form}
            </>
        );
    };

    return <LayoutQueue header={getHeader()} toolbar={getToolbar()} body={getBody()} />;
};

const Page = withModuleContentLoad(ModulePage);

export default Page;
