import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useModulePageClasses = makeStyles()((theme: CustomTheme) => ({
    modulePage: {
        display: 'flex',
        width: '100%',
        flex: 1,
        position: 'relative',
    },
    moduleFilters: {
        margin: theme.spacing(0, 5, 2.5),
    },
    moduleGrid: {
        flex: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        left: 0,
        right: 0,
    },
    moduleGridEditMode: {
        right: '50%',
    },
    loadingProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));
