import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {ReduxLinkText} from '@components/link';

import {getGridEmptyValue} from './utils';

type LinkCellProps = {
    path: string;
    title: string;
};

export function renderMuiGridLinkCell(params: GridCellParams) {
    const {path, title} = params.value as LinkCellProps;
    const emptyValue = getGridEmptyValue(params?.row);

    return path?.length && title?.length ? <ReduxLinkText title={title} path={path} /> : <>{emptyValue}</>;
}
