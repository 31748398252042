import {Mapper} from '@automapper/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload} from '@redux/entity';

import {ResponsePayloadMapExtraArgs} from '../entity/mapper';
import {RestResponse, ServerResponseStatus} from '../types';

import {RestRequestFactory} from './restRequestFactory';
import {RestService} from './restService';

export class BaseFetchApiService {
    protected readonly _httpService: RestService;
    private readonly _mapper: Mapper;
    protected readonly _builder: RestRequestFactory;

    constructor(httpService: RestService, mapper: Mapper, builder: RestRequestFactory) {
        this._httpService = httpService;
        this._mapper = mapper;
        this._builder = builder;
    }

    public get(endpoint: string, requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        const restRequest = this._builder.buildRequest({endpoint, query: requestPayload.filter});

        return restRequest
            ? this._httpService.get(restRequest).pipe(
                  map((resp: RestResponse) =>
                      this._mapper.map(resp, RestResponse, EntityFetchServiceResponsePayload, {
                          extraArgs: () => ({requestPayload} as ResponsePayloadMapExtraArgs),
                      })
                  )
              )
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {items: [], total: 0},
              });
    }

    public post(endpoint: string, requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        const restRequest = this._builder.buildRequest({endpoint, query: requestPayload.filter, body: requestPayload.body});

        return restRequest
            ? this._httpService.post(restRequest).pipe(
                  map((resp: RestResponse) =>
                      this._mapper.map(resp, RestResponse, EntityFetchServiceResponsePayload, {
                          extraArgs: () => ({requestPayload} as ResponsePayloadMapExtraArgs),
                      })
                  )
              )
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {items: [], total: 0},
              });
    }
}
