import produce from 'immer';
import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {bulkActionsActions, BulkActionsType} from './actions';
import {BulkActionItemPayload, ValidationResult} from './types';

type BulkItemsState = unknown[];

export type BulkActionState = Partial<Record<string, BulkActionItemPayload[]>>;

export type BulkValidationState = ValidationResult[];

export type BulkOperationIdsState = Partial<Record<string, string>>;

const getBulkActionsItemsReducer = () => {
    const itemsReducer = createReducer<BulkItemsState, BulkActionsType>([] as unknown[])
        .handleAction(bulkActionsActions.setBulkActionsItems, (_, action) => action.payload)
        .handleAction(bulkActionsActions.clearBulkActions, () => []);

    const actionsDataReducer = createReducer<BulkActionState, BulkActionsType>({} as BulkActionState)
        .handleAction(bulkActionsActions.setBulkActions, (state, action) => {
            return produce(state, draftState => {
                const updatedActionKeys = Object.keys(action.payload);
                updatedActionKeys.forEach(actionKey => {
                    draftState[actionKey] = action.payload[actionKey];
                });
            });
        })
        .handleAction(bulkActionsActions.changeBulkActionStatus, (state, action) => {
            return produce(state, draftState => {
                action.payload.items.forEach(payload => {
                    const updatedItem = draftState[action.payload.actionKey].find(i => payload.itemId === i.itemId);
                    updatedItem.status = payload.status;
                });
            });
        })
        .handleAction(bulkActionsActions.removeBulkActionsWithKey, (state, action) => {
            return produce(state, draftState => {
                draftState[action.payload] = [];
            });
        })
        .handleAction(bulkActionsActions.clearBulkActions, () => ({} as BulkActionState));

    const validationResultsReducer = createReducer<BulkValidationState, BulkActionsType>(null)
        .handleAction(bulkActionsActions.setValidationResults, (_, action) => {
            return action.payload;
        })
        .handleAction(bulkActionsActions.clearBulkActions, () => null);

    const bulkOperationIdsReducer = createReducer<BulkOperationIdsState, BulkActionsType>({})
        .handleAction(bulkActionsActions.saveOperationId, (state, action) => {
            return produce(state, draftState => {
                draftState[action.payload.actionKey] = action.payload.id;
            });
        })
        .handleAction([bulkActionsActions.cleanOperationIds, bulkActionsActions.clearBulkActions], () => ({}));

    return {
        selectedItems: itemsReducer,
        actionsData: actionsDataReducer,
        validationResults: validationResultsReducer,
        bulkOperationIds: bulkOperationIdsReducer,
    };
};

const stateReducer = combineReducers({
    ...getBulkActionsItemsReducer(),
});

export const bulkActionsReducer = combineReducers({
    state: stateReducer,
});
