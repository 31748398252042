import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    workspaceList: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        gap: theme.spacing(1.5),
    },
}));

type WorkspaceListProps = {
    children: React.ReactNode;
};

//TODO: move to layout components
export const WorkspaceList = ({children}: WorkspaceListProps) => {
    const {classes} = useStyles();

    return <Box className={classes.workspaceList}>{children}</Box>;
};
