import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useBonusEngineFormClasses = makeStyles()((theme: CustomTheme) => ({
    bonusEngineFormModalList: {
        paddingTop: 0,
    },
    bonusEngineFormModalListItem: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
    bonusEngineFormModalSecondaryAction: {
        width: theme.custom.autocompleteWidth,
        right: 0,
    },
    bonusEngineFormModalListItemText: {
        minWidth: theme.spacing(25),
        marginBottom: theme.spacing(2),
    },
    bonusEngineFormAddActionFooterButton: {
        height: theme.custom.formActionButtonHeight,
        borderRadius: theme.custom.roundedButtonRadius,
        padding: theme.spacing(1, 2),
        textTransform: 'none',
    },
    bonusEngineFormActionButtons: {
        display: 'flex',
        columnGap: theme.spacing(1.25),
        float: 'right',
    },
    bonusEngineFormMessageWithIconContainer: {
        marginLeft: theme.spacing(25),
        marginBottom: theme.spacing(2),
    },
    bonusEngineFormMessageWithIcon: {
        color: theme.palette.secondary.main,
    },
}));
