import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {push} from '@vs-centaurea/connected-react-router';

import {SquareIconButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons/types';
import StyledTooltip from '@components/Tooltip';
import {appendSelectedItemToQuery} from '@utils';

import {RouteUrl} from 'src/common/routeEnums';

const localized = defineMessages({
    bonusEngineDetailsActionTooltip: {
        id: 'BE_bonusEngineDisplayAndManualTriggerActionTooltip',
        defaultMessage: 'Display and Manual Trigger',
    },
});

const bonusEngineDetailsBaseUrl = `${RouteUrl.MarketingFunction}${RouteUrl.BonusEngineDetails}`;

export type BonusEngineDetailsActionProps = {
    bonusId: string;
};

const BonusEngineDetailsAction = ({bonusId}: BonusEngineDetailsActionProps) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        const path = appendSelectedItemToQuery(bonusEngineDetailsBaseUrl, bonusId);
        dispatch(push(path));
    };

    return (
        <StyledTooltip
            element={
                <Box>
                    <SquareIconButton onClick={handleClick} iconClassName={CustomIcon.ListPlayOutline} iconFontSize={16} />
                </Box>
            }
            title={<LocalizedText label={localized.bonusEngineDetailsActionTooltip} />}
            hideTooltip={false}
        />
    );
};

export function renderBonusEngineDetailsAction(props: GridCellParams) {
    const bonusId = (props?.value as BonusEngineDetailsActionProps)?.bonusId;
    return <BonusEngineDetailsAction bonusId={bonusId} />;
}
