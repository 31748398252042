import {defineMessages} from 'react-intl';

export const localizedBulkModalManualTrigger = defineMessages({
    bonusCodeAmountRequiredLabel: {
        id: 'bonusCodeAmountRequiredLabel',
        defaultMessage: 'Amount should be assigned for each user',
    },
    bonusEmptyList: {
        id: 'bonusEmptyList',
        defaultMessage: 'Player list is empty.',
    },
});
