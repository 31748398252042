import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {MultiStepFilterApplyButton} from '@components/filter/MultiStepFilterApplyButton';
import {MultiStepFilterStepProps, withMultiStepFilterHoc} from '@components/filter/MultiStepFilterHoc';
import {MultiStepFilterInput} from '@components/filter/MultiStepFilterInput';
import {ViewType} from '@redux/view';
import {CustomTheme} from '@style';

import {AgentFilterNew, AgentFilterValue, useCurrentAgentUsername} from './AgentFilterNew';
import {localized} from './localized';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    multiStepFilterAgentFilter: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, 2),
    },
}));

function MultiStepFilterAgentInputStep({
    value,
    defaultState,
    onNextStep,
}: MultiStepFilterStepProps<AgentFilterValue, MultiStepFilterAgentDefaultState>) {
    const {formatMessage} = useIntl();
    const agentUsername = useCurrentAgentUsername({viewType: defaultState?.viewType, agentId: value?.agentId});
    return (
        <MultiStepFilterInput
            label={localized.agentLabel}
            inputPlaceholder={localized.agentPlaceholder}
            inputValue={agentUsername ?? formatMessage(localized.any)}
            onClick={() => onNextStep(MultiStepFilterAgentSteps.MultiStepAgentFilterAutocompleteStep)}
        ></MultiStepFilterInput>
    );
}

function MultiStepAgentFilterAutocompleteStep({
    value,
    onApply,
    defaultState,
    onChange,
}: MultiStepFilterStepProps<AgentFilterValue, MultiStepFilterAgentDefaultState>) {
    const {classes} = useClasses();
    const [draftValue, setDraftValue] = useState<AgentFilterValue>(value);

    function handleApply() {
        onChange(draftValue);
        onApply();
    }
    return (
        <>
            <div className={classes.multiStepFilterAgentFilter}>
                <AgentFilterNew
                    displayMode="mobile"
                    boAgentPlayerId={defaultState?.boAgentPlayerId}
                    value={draftValue}
                    viewType={defaultState?.viewType}
                    onChange={setDraftValue}
                    withDownstream={defaultState?.withDownStream}
                />
            </div>
            <MultiStepFilterApplyButton onApply={handleApply} />
        </>
    );
}

type MultiStepFilterAgentDefaultState = {
    viewType: ViewType;
    boAgentPlayerId?: string;
    withDownStream: boolean;
};

enum MultiStepFilterAgentSteps {
    MultiStepFilterAgentInputStep,
    MultiStepAgentFilterAutocompleteStep,
}

export const MultiStepAgentFilter = withMultiStepFilterHoc<AgentFilterValue, MultiStepFilterAgentDefaultState>(
    [MultiStepFilterAgentInputStep, MultiStepAgentFilterAutocompleteStep],
    localized.agentLabel
);
