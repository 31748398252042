import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, PayloadAction, PayloadMetaAction} from 'typesafe-actions';

import {switchMap} from '@otel';
import {IAsyncAction, RootEpic} from '@redux';
import {BaseEpicsBuilder} from '@redux';
import {AsyncActionMeta} from '@redux/async-action';

import {getErrorByFailureAction} from '../../app/intl/shared-resources/serverResponse';

import {asyncActions} from './actions';
import {RequestStatus} from './types';

export class AsyncActionEpicsBuilder extends BaseEpicsBuilder {
    private _actions: IAsyncAction[];
    private _successActionMapping: {[key: string]: string};
    private _failureActionMapping: {[key: string]: string};

    constructor(actions: unknown) {
        super();
        this._actions = [];
        this._successActionMapping = {};
        this._failureActionMapping = {};

        const asyncActions = this.filterAsyncActions(actions);

        for (const action of asyncActions) {
            if (action !== null) {
                this._actions.push(action);
                this._successActionMapping[action.success().type] = action.request().type;
                this._failureActionMapping[action.failure().type] = action.request().type;
            }
        }
    }

    protected buildEpicList(): RootEpic[] {
        const inProgressEpic: RootEpic = action$ =>
            action$.pipe(
                filter(isActionOf(this._actions.map(a => a.request))),
                switchMap(action => {
                    const actionUniqueId = this.getAsyncActionUniqueId(action, action.type);
                    return of(
                        asyncActions.setRequestStatus({
                            requestAction: actionUniqueId,
                            requestStatus: RequestStatus.Progress,
                        })
                    );
                })
            );
        const successEpic: RootEpic = action$ =>
            action$.pipe(
                filter(isActionOf(this._actions.map(a => a.success))),
                switchMap(action => {
                    const actionType = this._successActionMapping[(action as PayloadAction<string, unknown>).type];
                    const actionUniqueId = this.getAsyncActionUniqueId(action, actionType);
                    return of(asyncActions.setRequestStatus({requestAction: actionUniqueId, requestStatus: RequestStatus.None}));
                })
            );

        const failedEpic: RootEpic = action$ =>
            action$.pipe(
                filter(isActionOf(this._actions.map(a => a.failure))),
                switchMap(action => {
                    const actionType = this._failureActionMapping[(action as PayloadAction<string, unknown>).type];
                    const actionUniqueId = this.getAsyncActionUniqueId(action, actionType);
                    const errorMessage = getErrorByFailureAction(action.type, action.payload?.code ?? action.payload?.errors?.[0]?.code);

                    return of(
                        asyncActions.setRequestStatus({requestAction: actionUniqueId, requestStatus: RequestStatus.Error}),
                        asyncActions.setErrorMessage({
                            requestAction: actionUniqueId,
                            errorMessage,
                        })
                    );
                })
            );

        return [inProgressEpic, successEpic, failedEpic];
    }

    private getAsyncActionUniqueId(action: unknown, actionType: string): string {
        const asyncAction = action as PayloadMetaAction<string, unknown, AsyncActionMeta>;
        return asyncAction.meta?.actionId ? `${actionType}/${asyncAction.meta?.actionId}` : actionType;
    }

    private filterAsyncActions(actions: unknown): IAsyncAction[] {
        const keys = Object.keys(actions);
        return keys
            .map(key => (<any>actions)[key])
            .flatMap(a => {
                const keys = Object.keys(a);
                const isAsyncAction = keys.includes(nameof<IAsyncAction>(t => t.request));
                const isAction = a instanceof Function;
                return isAsyncAction ? <IAsyncAction>(<unknown>a) : isAction ? null : this.filterAsyncActions(a);
            });
    }
}
