import React from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {ListItem, ListItemText} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {RuleType, useValidationFormatter} from '@components/input';
import {FormInputProps} from '@components/input/FormInput';

import {ApprovedStatusValue, RelationalOperator, RuleCondition, RuleViewModel} from '../types';

import {RuleFormRemoveConditionButton} from './RuleFormRemoveConditionButton';
import {StartAdornmentRelationalOperator} from './StartAdornmentRelationalOperator';

export const localizedRuleCondition = defineMessages<RuleCondition>({
    registrationCountry: {
        id: 'RuleCondition_registrationCountry',
        defaultMessage: 'Countries',
    },
    withdrawalAmount: {
        id: 'RuleCondition_withdrawalAmount',
        defaultMessage: 'Withdrawal amount',
    },
    successfulWithdrawalAmount24: {
        id: 'RuleCondition_successfulWithdrawalAmount24',
        defaultMessage: 'Total Withdrawal last 24 hours',
    },
    successfulWithdrawalAmountLifetime: {
        id: 'RuleCondition_successfulWithdrawalAmountLifetime',
        defaultMessage: 'Total Withdrawal lifetime',
    },
    hasApprovedKYCHistory: {
        id: 'RuleCondition_hasKycHistoryApprove',
        defaultMessage: 'Players KYC',
    },
    isFirstWithdrawal: {
        id: 'RuleCondition_isFirstWithdrawal',
        defaultMessage: 'First withdrawal condition',
    },
    hasLocks: {
        id: 'RuleCondition_hasLocks',
        defaultMessage: 'Account lock condition',
    },
    isWithdrawalClosedLoop: {
        id: 'RuleCondition_isWithdrawalClosedLoop',
        defaultMessage: 'Withdrawal close loop check',
    },
    hasSecurityCasesAssigned: {
        id: 'RuleCondition_hasSecurityCasesAssigned',
        defaultMessage: 'Security case condition',
    },
});

const useStyles = makeStyles()(theme => ({
    ruleFormCondition: {
        padding: theme.spacing(0),
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        marginTop: theme.spacing(1.5),
    },
    ruleFormConditionInputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
}));

type BaseRuleConditionInputProps = FormInputProps<unknown> & {
    startAdornment: React.ReactNode;
};

export type RuleFormConditionProps = {
    defaultValue: number | string[] | boolean | ApprovedStatusValue;
    ruleKey: RuleCondition;
    operator: RelationalOperator;
    onRemove?: (key: RuleCondition) => void;
    positiveNumberCheck?: boolean;
    alwaysVisible?: boolean;
    requiredValidation?: boolean;
    disabled?: boolean;
};

export function withRuleFormCondition<TProps extends object>(WrappedComponent: React.ComponentType<BaseRuleConditionInputProps & TProps>) {
    return function RuleConditionHoc({
        defaultValue,
        ruleKey,
        operator,
        onRemove,
        positiveNumberCheck,
        alwaysVisible,
        requiredValidation,
        ...props
    }: Omit<TProps, 'value' | 'onChange' | 'fieldState'> & RuleFormConditionProps) {
        const {classes} = useStyles();
        const {formatMessage} = useIntl();
        const validationMessageFormatter = useValidationFormatter();
        const {control, setValue} = useFormContext<RuleViewModel>();
        const watch = useWatch({name: ruleKey});

        function handleRemove() {
            setValue(ruleKey, undefined);
            if (onRemove) {
                onRemove(ruleKey);
            }
        }

        return alwaysVisible || watch !== undefined ? (
            <ListItem className={classes.ruleFormCondition}>
                <ListItemText primary={formatMessage(localizedRuleCondition[ruleKey])} />
                <Controller
                    render={({field, fieldState}) => (
                        <div className={classes.ruleFormConditionInputContainer}>
                            <WrappedComponent
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                startAdornment={<StartAdornmentRelationalOperator operator={operator} />}
                                {...(props as TProps)}
                            />
                            {onRemove ? <RuleFormRemoveConditionButton onRemove={handleRemove} /> : null}
                        </div>
                    )}
                    control={control}
                    defaultValue={defaultValue}
                    name={ruleKey}
                    rules={{
                        required: requiredValidation
                            ? validationMessageFormatter(RuleType.Required, localizedRuleCondition[ruleKey])
                            : null,
                        validate: positiveNumberCheck
                            ? {
                                  min: value =>
                                      (value as number) >= 0
                                          ? null
                                          : validationMessageFormatter(RuleType.Min, localizedRuleCondition[ruleKey], 0),
                              }
                            : null,
                    }}
                />
            </ListItem>
        ) : null;
    };
}
