import {gql} from '@apollo/client';

export const sendMessageMutation = gql`
    mutation SendMessage($uid: String!, $message: PlayerMessageInput!) {
        sendMessage(uid: $uid, message: $message) {
            id
            uid
            sender
            sender_id
            mail_type
            content
            subject
            title
            signature
            sent_at {
                seconds
            }
            source
        }
    }
`;
