import React from 'react';

import {Buttons} from './components/ButtonsDev';
import {ChipRendererDev} from './components/ChipRendererDev';
import {Chips} from './components/ChipsDev';
import {DateRangePickers} from './components/DateRangePickersDev';
import {DropzoneDev} from './components/DropzoneDev';
import {EditableDev} from './components/EditableDev';
import {EntityDataGridsDev} from './components/EntityDataGridsDev';
import {ModalDev} from './components/ModalDev';
import {MultiSelectWithExcludeDev} from './components/MultiSelectWithExcludeDev';
import {MultiStepFilter} from './components/MultiStepFilterDev';
import {SnackbarsDev} from './components/SnackbarsDev';
import {SportbookDataGridDev} from './components/SportsbookDataGridDev';
import {StyledToggles} from './components/StyledTogglesDev';
import {Typos} from './components/TyposDev';

export const DevPage = () => {
    console.log('test deployment 18.26');
    return (
        <>
            <ChipRendererDev />
            <Typos />
            <MultiSelectWithExcludeDev />
            <Buttons />
            <Chips />
            <StyledToggles />
            <DateRangePickers />
            <MultiStepFilter />
            <EditableDev />
            <EntityDataGridsDev />
            <ModalDev />
            <DropzoneDev />
            <SnackbarsDev />
            <SportbookDataGridDev />
        </>
    );
};
