import React from 'react';

import {DownloadCsvButton} from 'src/features/block-download';
import {useAgentLevelReportDownload} from '../hooks';
import {AgentLevelReportViewModelKeys} from '../types';

type AgentLevelReportDownloadButtonProps = {
    filterString: string;
    keys: AgentLevelReportViewModelKeys[];
    filename: string;
};

export function AgentLevelReportDownloadButton(props: AgentLevelReportDownloadButtonProps) {
    const {handleClick, isProgress} = useAgentLevelReportDownload(props);
    return <DownloadCsvButton onClick={handleClick} loading={isProgress} />;
}
