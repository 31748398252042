import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {UserProfile} from '@models/generated/graphql';
import {EntityType, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {ConfigurationKeyValuePair, Filter} from 'src/common/types';
import {UserProfileColumnsConfiguration} from 'src/features/user-profile/types';

import {WorkspaceKeyValueTable} from './WorkspaceKeyValueTable';

type MarketingInfoProps = {
    userId: string;
};

const domain = 'user-marketing-info';

const useClasses = makeStyles()(() => ({
    marketingInfo: {
        paddingTop: 0,
        boxShadow: 'none',
    },
    marketingInfoTable: {
        gridTemplateColumns: 'minmax(280px, max-content) auto',
    },
}));

const localized = defineMessages({
    title: {
        id: 'userMarketingInfo_title',
        defaultMessage: 'Marketing Info',
    },
    registerMarketingCode: {
        id: 'userMarketingInfo_registerMarketingCode',
        defaultMessage: 'Register Marketing Code',
    },
});

const getTableRows = (user: UserProfile): ConfigurationKeyValuePair[] => [
    {
        field: nameof.full<UserProfile>(u => u.marketing_info.register_marketing_code),
        key: localized.registerMarketingCode,
        value: user?.marketing_info?.register_marketing_code,
        configuration: [nameof<UserProfileColumnsConfiguration>(i => i.withMarketingInfo)],
    },
];

export const MarketingInfo = ({userId}: MarketingInfoProps) => {
    const {classes} = useClasses();

    const viewType = 'MarketingInfo';
    const entityType = EntityType.UserProfile;
    const fields: UserProfileQueryFields[] = ['marketing_info.register_marketing_code'];
    const defaultFilters: Filter<string, UserProfileServerFilterKeys>[] = [{key: 'uid', value: userId}];

    const {
        items,
        viewEntity: {filter: stateFilter},
    } = useViewInit<UserProfile, string, string>({
        viewType,
        displayName: localized.title,
        entity: {
            entity: entityType,
            fields,
        },
        realtime: {
            entity: entityType,
            mode: RealtimeUpdatesMode.Confirm,
            triggers: [{type: RealtimeMessageTrigger.Update}],
        },
        defaultFilters,
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });
    const userProfile = items[0];

    return (
        <WorkspaceKeyValueTable
            itemId={userId}
            domain={domain}
            header={localized.title}
            fields={fields}
            getTableRows={getTableRows}
            dataSelector={() => userProfile}
            filterSelector={() => stateFilter}
            className={classes.marketingInfo}
            styles={{table: classes.marketingInfoTable}}
            hideTitle
            columnsCount={1}
        />
    );
};
