import {defineMessages} from 'react-intl';

import {ManagedGameType} from '@redux/entity';

export const localizedManagedGameType = defineMessages({
    [ManagedGameType.PLO5]: {
        id: 'managedGameType_PLO5',
        defaultMessage: 'PLO-5',
    },
});
