import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {Box, Typography} from '@mui/material';

import {MemoizedDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import LocalizedText from '@components/i18n/LocalizedText';
import {SelectProps} from '@components/select';
import {useFilterSelectClasses, useGeneralSelectClasses} from '@components/select/selectStyle';

type MultiSelectWithTextCounterValueProps<T extends unknown[]> = Pick<
    SelectProps<T>,
    'enumFormatter' | 'label' | 'emptyValue' | 'options'
> & {
    selected: string[];
    maxWidth?: number;
};

export function MultiSelectWithTextCounterValue<T extends unknown[]>({
    selected,
    enumFormatter,
    label,
    emptyValue,
    options,
    maxWidth,
}: MultiSelectWithTextCounterValueProps<T>) {
    let formattedValues: string[] = [];
    const {formatMessage} = useIntl();
    if (selected?.length) {
        formattedValues = enumFormatter
            ? selected.map(s => enumFormatter(s)).map(s => (typeof s === 'string' ? s : formatMessage(s)))
            : selected.map(s => {
                  const label = options.find(o => o.value === s)?.label as MessageDescriptor;
                  return label ? (typeof label === 'string' ? label : formatMessage(label)) : s;
              });
        formattedValues = [...new Set(formattedValues)];
    }
    const {classes} = useFilterSelectClasses();
    const {classes: generalSelectClasses} = useGeneralSelectClasses();

    const valuesLength = formattedValues?.length;

    return (
        <Box className={generalSelectClasses.selectedValueEllipsis} style={{maxWidth}} data-testid="multiselectLabel">
            {valuesLength > 0 ? (
                <MemoizedDefaultChip
                    className={classes.selectTotalChip}
                    value={`${valuesLength}`}
                    variant={ChipVariant.Blue}
                    type={ChipType.Mark}
                    hideTooltip={true}
                />
            ) : null}
            {label ? (
                <Typography variant="body1" className={classes.selectLabel} component="span">
                    <LocalizedText label={label} />
                </Typography>
            ) : (
                <></>
            )}

            <Typography variant="body1" component="span">
                {valuesLength > 0 ? formattedValues.join(', ') : <LocalizedText label={emptyValue} />}
            </Typography>
        </Box>
    );
}
