import React, {useState} from 'react';
import {DateRange} from 'react-day-picker';
import {makeStyles} from 'tss-react/mui';

import {DateRangeFilterValue, useDateRangeFilter} from '@components/filter';
import {DateRangePicker, DateRangePickerProps} from '@components/input';
import {useYearsAndMonthsOptions} from '@components/input/DatePickerYearMonthForm';
import {RadioGroupList} from '@components/input/RadioGroupList';
import {Locale} from '@components/types';
import {CustomTheme} from '@style';
import {formatDateRange, getTodayAsDate, timestampSecondsToDate} from '@utils/date';

import {MultiStepFilterApplyButton} from '../MultiStepFilterApplyButton';
import {MultiStepFilterStepProps, withMultiStepFilterHoc} from '../MultiStepFilterHoc';
import {MultiStepFilterInput} from '../MultiStepFilterInput';

import {localized} from './localized';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    multiStepDatePickerCaption: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: theme.spacing(1),
    },
    multiStepDatePicker: {
        padding: theme.spacing(1.5),
    },
    multiStepDatePickerListContainer: {
        padding: theme.spacing(1.5, 0),
        maxHeight: '400px',
        overflowY: 'auto',
    },
}));

type MultiStepDateRangeFilterDefaultState = Pick<DateRangePickerProps, 'rangeMode'> & {
    defaultDate?: Date;
    locale: Locale;
    availableDateRangeForSelection?: {from?: Date; to?: Date};
};

type MultiStepFilterDatePickerStepProps = MultiStepFilterStepProps<DateRangeFilterValue, MultiStepDateRangeFilterDefaultState>;

function MultiStepFilterDatePickerInitialStep({value, onNextStep}: MultiStepFilterDatePickerStepProps) {
    const {value: dateRangeValue} = useDateRangeFilter({value, onChange: null});
    return (
        <MultiStepFilterInput
            label={localized.label}
            inputPlaceholder={localized.placeholder}
            inputValue={formatDateRange(dateRangeValue.from, dateRangeValue.to)}
            onClick={() => onNextStep(MultiStepFilterDatePickerSteps.MultiStepFilterDatePickerStep)}
        ></MultiStepFilterInput>
    );
}

function getDefaultDate(state: MultiStepDateRangeFilterDefaultState, value: DateRangeFilterValue): Date {
    return state?.defaultDate ?? timestampSecondsToDate(value?.from) ?? getTodayAsDate();
}

function CaptionComponent({defaultState, onNextStep}: Pick<MultiStepFilterStepProps<DateRange, Date>, 'defaultState' | 'onNextStep'>) {
    const {classes} = useClasses();
    const {monthsOptions} = useYearsAndMonthsOptions({
        disableFutureDates: false,
    });

    return (
        <div className={classes.multiStepDatePickerCaption}>
            <MultiStepFilterInput
                inputPlaceholder={localized.month}
                inputValue={monthsOptions[defaultState ? defaultState.getMonth() : getTodayAsDate().getMonth()].label}
                onClick={() => onNextStep(MultiStepFilterDatePickerSteps.MultiStepFilterDatePickerMonthStep)}
                size="small"
                background="paper"
                inline
            />
            <MultiStepFilterInput
                inputPlaceholder={localized.year}
                inputValue={defaultState ? defaultState.getFullYear().toString() : getTodayAsDate().getFullYear().toString()}
                onClick={() => onNextStep(MultiStepFilterDatePickerSteps.MultiStepFilterDatePickerYearStep)}
                size="small"
                background="paper"
                inline
            />
        </div>
    );
}

function MultiStepFilterDatePickerStep({value, defaultState, onChange, onApply, onNextStep}: MultiStepFilterDatePickerStepProps) {
    const {classes} = useClasses();
    const [draftDateRange, setDraftDateRange] = useState<DateRangeFilterValue>(value);
    const {
        value: dateRangeValue,
        handleChange,
        availableRangeForSelection,
    } = useDateRangeFilter({value, onChange: v => setDraftDateRange(v)}, defaultState.availableDateRangeForSelection);
    const {defaultDate, locale, rangeMode} = defaultState;

    function handleApply() {
        onChange(draftDateRange);
        onApply();
    }
    return (
        <>
            <div className={classes.multiStepDatePicker}>
                <DateRangePicker
                    captionComponent={({date}) => <CaptionComponent defaultState={date ?? getTodayAsDate()} onNextStep={onNextStep} />}
                    displayMode="mobile"
                    onSelect={handleChange}
                    onChange={handleApply}
                    selectedMonth={defaultDate ?? dateRangeValue?.from}
                    value={dateRangeValue}
                    type="small"
                    rangeMode={rangeMode}
                    locale={locale}
                    disableFutureDates
                    placeholder={localized.placeholder}
                    availableDateRangeForSelection={availableRangeForSelection}
                />
            </div>
            <MultiStepFilterApplyButton onApply={handleApply} />
        </>
    );
}

function MultiStepFilterDatePickerMonthStep({
    value,
    defaultState,
    isStepActive,
    onStateChange,
    onApply,
}: MultiStepFilterDatePickerStepProps) {
    const {classes} = useClasses();
    const [draftDate, setDraftDate] = useState<Date>(getDefaultDate(defaultState, value));
    const {monthsOptions} = useYearsAndMonthsOptions({
        disableFutureDates: false,
    });

    function handleDateChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newMonth = Number(e.target.value);
        const date = getDefaultDate(defaultState, value);
        const newDate = new Date(date?.getFullYear(), newMonth);
        setDraftDate(newDate);
    }

    function handleApply() {
        onStateChange({...defaultState, defaultDate: draftDate});
        onApply();
    }

    return (
        <>
            <div className={classes.multiStepDatePickerListContainer}>
                <RadioGroupList
                    value={draftDate.getMonth()}
                    options={monthsOptions}
                    scrollToSelected={isStepActive}
                    onChange={handleDateChange}
                />
            </div>
            <MultiStepFilterApplyButton onApply={handleApply} />
        </>
    );
}

function MultiStepFilterDatePickerYearStep({
    value,
    defaultState,
    isStepActive,
    onStateChange,
    onApply,
}: MultiStepFilterDatePickerStepProps) {
    const {classes} = useClasses();
    const [draftDate, setDraftDate] = useState<Date>(getDefaultDate(defaultState, value));
    const {yearsOptions} = useYearsAndMonthsOptions({
        disableFutureDates: false,
    });

    function handleDateChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newYear = Number(e.target.value);
        const date = getDefaultDate(defaultState, value);
        const newDate = new Date(newYear, date.getMonth());
        setDraftDate(newDate);
    }
    function handleApply() {
        onStateChange({...defaultState, defaultDate: draftDate});
        onApply();
    }

    return (
        <>
            <div className={classes.multiStepDatePickerListContainer}>
                <RadioGroupList
                    value={draftDate.getFullYear()}
                    options={yearsOptions}
                    scrollToSelected={isStepActive}
                    onChange={handleDateChange}
                />
            </div>
            <MultiStepFilterApplyButton onApply={handleApply} />
        </>
    );
}

enum MultiStepFilterDatePickerSteps {
    MultiStepFilterDatePickerInitialStep,
    MultiStepFilterDatePickerStep,
    MultiStepFilterDatePickerMonthStep,
    MultiStepFilterDatePickerYearStep,
}

export const MultiStepFilterDatePicker = withMultiStepFilterHoc<DateRangeFilterValue, MultiStepDateRangeFilterDefaultState>(
    [
        MultiStepFilterDatePickerInitialStep,
        MultiStepFilterDatePickerStep,
        MultiStepFilterDatePickerMonthStep,
        MultiStepFilterDatePickerYearStep,
    ],
    localized.label
);
