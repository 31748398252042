import {AjaxResponse} from '@services/rest-api';

export function getTempStorageKey(typeName: string, id: string, field?: string) {
    return field ? `${typeName}.${id}.${field}` : `${typeName}.${id}`;
}

export const getTypedResponses = <TResponse>(...responses: AjaxResponse[]) => {
    const data = (responses.map((r: AjaxResponse) => r?.response) as TResponse[]).reduce(
        (result, current) => (current ? [...result, current] : result),
        [] as TResponse[]
    );

    return data;
};
