import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {DropdownButton, ToggleHeadingMenuItem, useActive} from '@remirror/react';
import {CoreIcon} from 'remirror';

import {useClasses} from './FontSizeSelect';

const levels = [1, 2, 3, 4, 5, 6];

const localized = defineMessages({
    heading: {
        id: 'HeadingSelect_heading',
        defaultMessage: 'Heading',
    },
});

export function HeadingSelect() {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();
    const {heading} = useActive();
    const currentHeading = levels.find(level => heading({level}));
    const icon: CoreIcon = currentHeading ? (`h${currentHeading}` as CoreIcon) : 'heading';
    return (
        <DropdownButton aria-label={formatMessage(localized.heading)} icon={icon}>
            {levels.map(level => (
                <ToggleHeadingMenuItem attrs={{level}} className={classes.fontSizeSelectSelected} key={level} />
            ))}
        </DropdownButton>
    );
}
