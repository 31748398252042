import React from 'react';

import {useService} from '@inversify';
import {EntityType} from '@redux/entity';

import {
    BulkActionKey,
    BulkStepExecutionProps,
    BulkStepSummaryContentChipList,
    BulkStepSummaryContentProps,
    UserProfileActionItem,
    withBulkStepExecutionSummary,
} from '../../block-bulk-actions';
import {PerformAddUserLabelStrategy, PerformRemoveUserLabelStrategy} from '../services/performStrategy';

function BulkStepSummaryChipList(props: BulkStepSummaryContentProps) {
    return <BulkStepSummaryContentChipList {...props} getChipValue={item => (item.value as UserProfileActionItem).uid} />;
}

const BulkStepPlayerSummary = withBulkStepExecutionSummary(BulkStepSummaryChipList);

export function BulkLabelExecutionStepModal({onNext, hasRetry}: BulkStepExecutionProps) {
    const performAddUserLabelStrategy = useService<PerformAddUserLabelStrategy>('PerformAddUserLabelStrategy');
    const performRemoveUserLabelStrategy = useService<PerformRemoveUserLabelStrategy>('PerformRemoveUserLabelStrategy');

    return (
        <BulkStepPlayerSummary
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.UserProfile}
            actionsData={[
                {type: 'operation', actionKey: BulkActionKey.AddUserLabel, strategy: performAddUserLabelStrategy},
                {type: 'operation', actionKey: BulkActionKey.RemoveUserLabel, strategy: performRemoveUserLabelStrategy},
            ]}
        />
    );
}
