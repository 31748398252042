import {defineMessages} from 'react-intl';

import {BoUser, BoUserServerFilterKeys} from '@models/bo-user';
import {Entity, EntityType as EventLogEntityType} from '@models/generated/graphql';
import {EntityType, EventLogFilterKeys, EventLogQueryFields} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit, ViewType} from '@redux/view';
import {isStringNullOrEmpty, toSearchFilter} from '@utils';

import {Filter, SearchFilter, Sorting} from 'src/common/types';

import {EventLogGridItem, labelMap} from './types';

type ActionHistoryData = {
    items: EventLogGridItem[];
    totalCount: number;
    searchFilter: SearchFilter;
    handlePageChange: (newPage: number) => void;
    handlePageSizeChange: (newPageSize: number) => void;
    handleSortChange: (newSorting: Sorting<EventLogQueryFields>[]) => void;
};

const localized = defineMessages({
    title: {
        id: 'AH_title',
        defaultMessage: 'Action History',
    },
});

export function useActionHistoryData(
    viewType: ViewType,
    fields: EventLogQueryFields[],
    filters: Filter<any, EventLogFilterKeys>[],
    sorting: Sorting<EventLogQueryFields>[]
): ActionHistoryData {
    const {
        items,
        totalCount,
        viewEntity: {filter: stateFilter},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
    } = useViewInit<EventLogGridItem, EventLogFilterKeys, EventLogQueryFields>({
        viewType,
        displayName: localized.title,
        entity: {
            entity: EntityType.EventLogRecord,
            fields,
        },
        realtime: {
            entity: EntityType.EventLogRecord,
            mode: RealtimeUpdatesMode.Confirm,
            triggers: [{type: RealtimeMessageTrigger.Add}],
        },
        defaultFilters: filters,
        defaultSorting: sorting,
        validateFilter: filter => {
            const entity = filter?.find(f => f.key === 'entity')?.value as Entity;
            const eventLogEntityType = filter?.find(f => f.key === 'type')?.value as EventLogEntityType;
            return !isStringNullOrEmpty(entity?.id) && !isStringNullOrEmpty(entity?.type) && !isStringNullOrEmpty(eventLogEntityType);
        },
    });

    const {items: boUsers} = useViewInit<BoUser, BoUserServerFilterKeys, string>({
        viewType,
        displayName: localized.title,
        entity: {
            entity: EntityType.BoUser,
            fields: ['id', 'firstName', 'lastName'],
        },
        validateFilter: () => true,
    });
    const searchFilter = toSearchFilter(stateFilter);

    const eventLogs = items?.map(i => ({...i, isNew: i.isNew, actionAgent: getActionAgent(i.trigger_by_id)} as EventLogGridItem));

    function getActionAgent(userId: string) {
        let agentName = null;

        if (userId in labelMap) {
            agentName = labelMap[userId];
        } else {
            const agent = boUsers?.find((u: BoUser) => u.id === userId);
            agentName = agent ? `${agent.firstName} ${agent.lastName}` : userId;
        }

        return agentName;
    }

    return {items: eventLogs, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange};
}
