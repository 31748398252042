import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    header: {
        id: 'KW_header',
        defaultMessage: 'KYC Workspace',
    },
    pendingCases: {
        id: 'KW_pendingCases',
        defaultMessage: 'Pending Cases',
    },
    historyCases: {
        id: 'KW_historyCases',
        defaultMessage: 'History Cases',
    },
    ndrpHeader: {
        id: 'KW_ndrpHeader',
        defaultMessage: 'NDRP Processing',
    },
});
