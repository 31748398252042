import React from 'react';
import {Box, ButtonGroup, Typography} from '@mui/material';

import {CustomIcon} from '@components/icons/types';
import {formatTimestamp} from '@utils';

import {SquareIconButton} from '../button/Buttons';

import {useClasses} from './AttachmentsItem.style';
import {Attachment} from './types';
import {humanFileType} from './utils';

type AttachmentProps = Attachment;

export const AttachmentsItem = (attachment: AttachmentProps) => {
    const {classes} = useClasses();
    const type = attachment?.extension || humanFileType(attachment.filename);

    function handleDownload() {
        return attachment.onDownload();
    }

    function handleRemove() {
        return attachment.onRemove();
    }
    return (
        <Box className={classes.attachmentItem}>
            <Box className={classes.attachmentIcon}>
                <Typography component="span" variant="subtitle1" align="center" className={classes.attachmentType}>
                    {type}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="column" ml={2}>
                <Box component="span" className={classes.attachmentName}>
                    {attachment.filename}
                </Box>
                <Typography component="span" variant="subtitle1">
                    {formatTimestamp(attachment.posted_at, 'date-time')}
                </Typography>
            </Box>
            <Box display="flex" ml="auto">
                <ButtonGroup>
                    {attachment.onDownload && (
                        <SquareIconButton
                            iconFontSize={12}
                            className={classes.attachmentBtn}
                            iconClassName={CustomIcon.Download}
                            onClick={handleDownload}
                        />
                    )}
                    {attachment.onRemove && (
                        <SquareIconButton
                            iconFontSize={12}
                            className={classes.attachmentBtn}
                            iconClassName={CustomIcon.CancelOutline}
                            onClick={handleRemove}
                        />
                    )}
                </ButtonGroup>
            </Box>
        </Box>
    );
};
