import React, {useEffect, useState} from 'react';
import {Accept, useDropzone} from 'react-dropzone';
import {Box, Input} from '@mui/material';
import {InputProps} from '@mui/material/Input/Input';

import {ErrorModal} from '@components/error';
import LocalizedText from '@components/i18n/LocalizedText';
import {withTraceErrorBoundary} from '@otel';

import {localized} from './Dropzone.localize';
import {useDropzoneClasses} from './Dropzone.style';
import {DropzoneContent} from './DropzoneContent';

type SupportedFormats = 'XLS' | 'XLSX';

const MimetypeFileFormats: Record<SupportedFormats, string> = {
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

type DropzoneProps = {
    onFileUpload: (file: File) => void;
    onInvalidFileUpload: () => void;
    availableFormats: SupportedFormats[];
    maxSizeInMB: number;
};

export enum EditMode {
    Default,
    Success,
    Failed,
}

function Dropzone({onFileUpload, onInvalidFileUpload, availableFormats, maxSizeInMB}: DropzoneProps) {
    const {classes, cx} = useDropzoneClasses();
    const bytesInMB = 1048576;
    const acceptedFileFormats: Accept = {};
    availableFormats.forEach(f => (acceptedFileFormats[MimetypeFileFormats[f]] = []));

    const {acceptedFiles, fileRejections, isDragActive, isFileDialogActive, getRootProps, getInputProps, open} = useDropzone({
        noClick: true,
        accept: acceptedFileFormats,
        maxSize: maxSizeInMB * bytesInMB,
        maxFiles: 1,
    });

    const isDragOrDialogActive: boolean = isDragActive || isFileDialogActive;
    const successFile = acceptedFiles[0];
    const rejectFile = fileRejections[0];

    const [editMode, setEditMode] = useState<EditMode>(EditMode.Default);

    useEffect(() => {
        if (!isDragActive) {
            setEditMode(rejectFile ? EditMode.Failed : successFile ? EditMode.Success : EditMode.Default);
        } else {
            setEditMode(EditMode.Default);
        }
    }, [successFile, rejectFile, isDragActive]);

    useEffect(() => {
        if (editMode === EditMode.Success) {
            onFileUpload(successFile);
        } else if (editMode === EditMode.Failed) {
            onInvalidFileUpload();
        }
    }, [editMode]);

    return (
        <Box className={classes.dropzoneContainer}>
            <Box
                {...getRootProps({
                    className: cx(isDragOrDialogActive ? classes.dropzoneActive : '', classes.dropzone),
                })}
            >
                <Input {...(getInputProps() as unknown as InputProps)} />
                <DropzoneContent
                    successFile={successFile}
                    rejectFile={rejectFile}
                    isDragOrDialogActive={isDragOrDialogActive}
                    onOpen={open}
                    editMode={editMode}
                />
            </Box>
            <Box className={classes.dropzoneFooter}>
                <Box>
                    <LocalizedText label={localized.dropzoneSupportedFormats} />
                    {availableFormats.join(', ')}
                </Box>
                <Box>
                    <LocalizedText label={localized.dropzoneMaxFileSize} labelParams={{maxSizeInMB}} />
                </Box>
            </Box>
        </Box>
    );
}

export default withTraceErrorBoundary(Dropzone, ErrorModal);
