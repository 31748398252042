import React from 'react';
import {DialogActions} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {Button} from '../button/Buttons';

import {ModalButton} from './types';

type PatchModalButtonsProps = {
    submitButton: ModalButton;
    cancelButton: ModalButton;
};

const useClasses = makeStyles()(() => ({
    modalActions: {
        justifyContent: 'center',
    },
}));

export const ModalButtons = ({submitButton, cancelButton}: PatchModalButtonsProps) => {
    const {classes} = useClasses();

    return (
        <DialogActions className={classes.modalActions}>
            <Button key="cancel" label={cancelButton.label} onClick={cancelButton.onClick} data-testid="cancelButton" />
            <Button key="submit" color="primary" label={submitButton.label} onClick={submitButton.onClick} data-testid="submitButton" />
        </DialogActions>
    );
};
