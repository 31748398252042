import {gql} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {UserProfile} from '@models/generated/graphql';

import {IRealtimeService} from '../../realtime-updates/services/IRealtimeService';
import {RealtimeSubscriptionService} from '../../realtime-updates/services/RealtimeUpdatesService';

export class UserProfileRealtimeService extends RealtimeSubscriptionService implements IRealtimeService<UserProfile> {
    //TODO: [BO-2494] Refactor query fragments for GQL
    private readonly _userProfileFragment = gql`
        fragment UserProfileFragment on UserProfile {
            uid
            first_name
            last_name
            username
            nickname
            birthday {
                seconds
            }
            date_of_joining {
                seconds
            }
            contact {
                email
                mobile {
                    area
                    mobile
                }
            }
            address {
                address
                city
                state
                country
                post_code
                country_info {
                    name
                    iso_alpha2_code
                }
                street
            }
            region_player_info {
                occupation
                tax_id
                national_id
                nationality
                country_of_birth
                district
            }
            language
            account_status
            security_settings {
                is_2fa_enabled
                is_challenge_questions_enabled
            }
            identity_info {
                last_ssn_digits
            }
            gender
            security_cases {
                type
                case_id
                opened_at {
                    seconds
                }
                closed_at {
                    seconds
                }
            }
            friend_groups {
                group_id
                players
                group_name
            }
            deposit_status
            withdrawal_status
            lobby_access_status
            p2p_transfer_status
            withdrawal_limits {
                type
                durationType
                limit
                currency
                start_at {
                    seconds
                }
                trigger_at {
                    seconds
                }
                end_at {
                    seconds
                }
                limit_id
            }
            ip_addresses
            devices {
                uuid
                name
                operating_system
                model
                mac_address
                agent
            }
            black_list {
                blacklisted
                deposit_threshold
                items {
                    type
                    key
                }
            }
            verification_info {
                is_email_verified
                is_phone_verified
                is_address_ssn_verified
            }
            blocked_stakes_access {
                game_type
                game_mode
                limit
            }
            punishment_status
            club
            distance_score
            avg_binary_mistake_score
            ip_quality_score
            recaptcha_score
            bb_100
            win_total
            most_played_stake
            num_linked_bots_by_devices
            num_linked_bots_by_subnets
            num_linked_bots_by_ips
            num_linked_bots_by_bank_card
            num_linked_bots_by_bank_card_name
            num_linked_members_by_devices
            num_linked_members_by_subnets
            num_linked_members_by_bank_card
            num_linked_members_by_bank_card_name
            remote_logins
            last_region
            countries
            vpn
            players_groups
            latest_login {
                uid
                logged_at_ts {
                    seconds
                }
                ip
                vpn
                username
                current_balance
                device {
                    uuid
                    name
                    operating_system
                    model
                    mac_address
                    agent
                }
                session_id
                login_type
                isp
                ip_quality_score
                recaptcha_score
                recaptcha_reasons
                language
                location {
                    city
                    state
                    country
                    latitude
                    longitude
                }
                correlation_id
                login_status
                connection_type
            }
            latest_kyc {
                security {
                    id
                    kyc_case_id
                    account_verification_status
                }
                payment {
                    id
                    kyc_case_id
                    account_verification_status
                }
            }
            finance {
                deposit {
                    total {
                        category
                        group_name
                        successful_amount
                        failed_amount
                        rejected_amount
                        successful_count
                        failed_count
                        rejected_count
                        currency
                    }
                    per_method {
                        category
                        group_name
                        successful_amount
                        failed_amount
                        rejected_amount
                        successful_count
                        failed_count
                        rejected_count
                        currency
                    }
                }
                withdrawal {
                    total {
                        category
                        group_name
                        successful_amount
                        failed_amount
                        rejected_amount
                        successful_count
                        failed_count
                        rejected_count
                        currency
                    }
                    per_method {
                        category
                        group_name
                        successful_amount
                        failed_amount
                        rejected_amount
                        successful_count
                        failed_count
                        rejected_count
                        currency
                    }
                }
                generated_rake
                balance
                payment_methods
                ggr
                lifetime_bonus
                bonus_ratio
            }
            security {
                player_skill {
                    score
                    label
                    custom_label
                }
                gto_score
            }
            aml_labels
            business_pool
            pool_id
            evidence
            kyc_action
            platform
            affiliate {
                btag
                invite_code
            }
            labels {
                id
                name
                group {
                    id
                    name
                    color
                }
            }
        }
    `;

    subscribeToUpdate(): Observable<UserProfile> {
        return super.createSubscription(this.getItemUpdatedSubscriptionQuery()).pipe(map(res => res.userProfileUpdated));
    }

    subscribeToCreate(): Observable<UserProfile> {
        return super.createSubscription(this.getItemAddedSubscriptionQuery()).pipe(map(res => res.userProfileAdded));
    }

    private getItemUpdatedSubscriptionQuery(): DocumentNode {
        return gql`
            ${this._userProfileFragment}
            subscription OnUserProfileUpdated {
                userProfileUpdated {
                    ...UserProfileFragment
                }
            }
        `;
    }

    private getItemAddedSubscriptionQuery(): DocumentNode {
        return gql`
            ${this._userProfileFragment}
            subscription OnUserProfileAdded {
                userProfileAdded {
                    ...UserProfileFragment
                }
            }
        `;
    }
}
