import {IAccessRule} from './PoliciesAccessRule';

type CompositeAccessRuleOperator = 'and' | 'or';

export class CompositeAccessRule<T> implements IAccessRule<T> {
    private rules: IAccessRule[];
    private operator: CompositeAccessRuleOperator;

    constructor(rules: IAccessRule[], operator: CompositeAccessRuleOperator) {
        this.rules = rules;
        this.operator = operator;
    }

    validate(value: T): boolean {
        const result =
            this.rules.length === 0 ||
            (this.operator === 'and' && this.rules.every(rule => rule.validate(value))) ||
            (this.operator === 'or' && this.rules.some(rule => rule.validate(value)));
        return result;
    }
}
