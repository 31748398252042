import {defineMessages} from 'react-intl';

import {ReferrerType} from '@models/generated/graphql';

export const localizedReferrerType = defineMessages({
    [ReferrerType.P2P]: {
        id: 'referrerType_P2P',
        defaultMessage: 'P2P',
    },
    [ReferrerType.Affiliate]: {
        id: 'referrerType_Affiliate',
        defaultMessage: 'Affiliate',
    },
});
