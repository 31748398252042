import React from 'react';

import {AccountVerificationFilterKeys} from '@redux/entity';

import DurationRangePicker from '../../shared/filter/components/value-range-picker/DurationRangePicker';

import {AccountVerificationsFilterProps} from './AccountVerificationsFilters';

function AccountVerificationsFilterDuration({filter, onFilterChange}: AccountVerificationsFilterProps) {
    const keys: AccountVerificationFilterKeys[] = ['initiatedAt.from', 'initiatedAt.to'];
    return <DurationRangePicker onFilterChange={onFilterChange} domain={null} keys={keys} filterString={filter} />;
}

export default AccountVerificationsFilterDuration;
