import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {Rule} from '@models/rule/types';
import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload, RuleFilterKeys} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {
    BaseRuleRequestPayload,
    BaseRuleResponsePayload,
    ChangeRuleOrderRequestPayload,
    RuleApiService,
    UpdateRuleRequestPayload,
    UpdateRuleResponsePayload,
} from '@services/rest-api/ruleApiService';
import {ServerResponseStatus, ServiceResponsePayload} from '@services/types';
import {getValueFromQueryFilter} from '@utils/query';

@injectable()
export class RuleService implements IEntityReadService {
    private readonly _ruleApiService: RuleApiService;

    constructor(@inject(ServiceTypes.RuleApiService) ruleApiService: RuleApiService) {
        this._ruleApiService = ruleApiService;
    }

    public get(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload<Rule>> {
        const invalid = getValueFromQueryFilter<RuleFilterKeys, boolean>(requestPayload.filter, 'invalid');
        const ruleId: string = getValueFromQueryFilter<RuleFilterKeys, string>(requestPayload.filter, 'id');

        return !(<boolean>invalid)
            ? ruleId
                ? this._ruleApiService.getRule(ruleId, requestPayload)
                : this._ruleApiService.getRules(requestPayload)
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {
                      items: [],
                      total: 0,
                  },
              });
    }

    public createRule(requestPayload: UpdateRuleRequestPayload): Observable<UpdateRuleResponsePayload> {
        return this._ruleApiService.createRule(requestPayload);
    }

    public editRule(requestPayload: UpdateRuleRequestPayload): Observable<UpdateRuleResponsePayload> {
        return this._ruleApiService.editRule(requestPayload);
    }

    public deleteRule(requestPayload: BaseRuleRequestPayload): Observable<BaseRuleResponsePayload> {
        return this._ruleApiService.deleteRule(requestPayload);
    }

    public activateRule(requestPayload: BaseRuleRequestPayload): Observable<BaseRuleResponsePayload> {
        return this._ruleApiService.activateRule(requestPayload);
    }

    public deactivateRule(requestPayload: BaseRuleRequestPayload): Observable<BaseRuleResponsePayload> {
        return this._ruleApiService.deactivateRule(requestPayload);
    }

    public changeRuleOrder(
        requestPayload: ChangeRuleOrderRequestPayload
    ): Observable<ServiceResponsePayload<ChangeRuleOrderRequestPayload, null>> {
        return this._ruleApiService.changeRuleOrder(requestPayload);
    }
}
