import React from 'react';
import {useAuth} from 'react-oidc-context';
import {Route, RouteProps} from 'react-router-dom';

import {useAuthUrl} from '@auth';

//TODO: move to auth
function ProtectedRoute(props: RouteProps) {
    const {isAuthenticated} = useAuth();
    const {hasAuthQueryParams} = useAuthUrl();

    return isAuthenticated === null ? <></> : isAuthenticated === true && !hasAuthQueryParams ? <Route {...props} /> : <></>;
}

export default ProtectedRoute;
