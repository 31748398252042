import {DataGridColumn, DataGridProps, IModuleGridItem} from '../types';

import {GlideGridProps} from './GlideGrid';

export class GlideGridPropsConverter<TModel extends IModuleGridItem, TColumnKey extends string, TColumn extends DataGridColumn<TModel>> {
    public convert(props: DataGridProps<TModel, TColumnKey, TColumn>): Omit<GlideGridProps<TModel>, 'columns'> {
        return {
            rows: props.rows,
            freezeColumns: {
                desktop: props.frozenColumnsCount?.desktop ?? 0,
                mobile: props.frozenColumnsCount?.mobile ?? 0,
            },
            page: props.paging?.page,
            pageSize: props.paging?.pageSize,
            totalRows: props.paging?.rowCount,
            sortModel: props.sortModel?.sortModel,
            pinnedRow: props.pinnedRow,
            emptyMessage: props.emptyMessage,
            onPageChange: props.onPageChange,
            onPageSizeChange: props.onPageSizeChange,
            onSortChange: props.onSortChange,
        };
    }
}
