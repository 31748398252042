import React from 'react';

import {withQueryFilter} from '@components/filter/QueryFilterHoc';
import {QueryFilterAdapterModel, QueryFilterAdapterProps} from '@components/filter/types';
import {AgentPlayerReportingServerFilterKeys, AgentPlayerReportingServerTextFilterKeys} from '@redux/entity';
import {getMirrorObject} from '@utils/object';

import {AgentPlayerReportingFilterModel, AgentPlayerReportingFilterName, AgentPlayerReportingTextFilterKeys} from '../types';

import {AgentPlayerReportingFilters, AgentPlayerReportingFiltersAdditionalProps} from './AgentPlayerReportingFilters';

type AgentPlayerReportingQueryFilterAdapterProps = QueryFilterAdapterProps<
    AgentPlayerReportingServerFilterKeys,
    AgentPlayerReportingFilterName,
    AgentPlayerReportingServerTextFilterKeys
> &
    AgentPlayerReportingFiltersAdditionalProps;

function AgentPlayerReportingQueryFilterAdapter({model, onChange, ...props}: AgentPlayerReportingQueryFilterAdapterProps) {
    const clientTextKeyToServer: Record<AgentPlayerReportingTextFilterKeys, AgentPlayerReportingServerTextFilterKeys> = {
        uid: 'uid',
        email: 'contact.email',
        username: 'username',
        marketing_code: 'register_marketing_code',
        uid_email_uname_rmc: 'uid_em_un_rmc',
        uid_email: 'uid_email',
    };
    const serverTextKeyToClient: Record<AgentPlayerReportingServerTextFilterKeys, AgentPlayerReportingTextFilterKeys> =
        getMirrorObject(clientTextKeyToServer);

    const filterModel: AgentPlayerReportingFilterModel = {
        text: model?.text ? {...model.text, option: serverTextKeyToClient[model?.text?.option]} : null,
        started_at: {
            from: model?.['transactionStartedTs.from'] as number,
            to: model?.['transactionStartedTs.to'] as number,
        },
        agent: {
            agentId: model?.referrerPlayerId as string,
            isDownstream: model?.isDownstream as boolean,
        },
        countries: model?.countries as string[],
    };

    function handleChange(model: AgentPlayerReportingFilterModel) {
        const result: QueryFilterAdapterModel<AgentPlayerReportingServerFilterKeys, AgentPlayerReportingServerTextFilterKeys> = {
            text: model?.text ? {...model?.text, option: clientTextKeyToServer[model?.text?.option]} : undefined,
            'transactionStartedTs.from': model?.started_at?.from,
            'transactionStartedTs.to': model?.started_at?.to,
            referrerPlayerId: model?.agent?.agentId,
            isDownstream: model?.agent?.isDownstream,
            countries: model?.countries,
        };

        onChange(result);
    }

    return <AgentPlayerReportingFilters model={filterModel} onChange={handleChange} {...props} />;
}

export const AgentPlayerReportingQueryFilters = withQueryFilter<
    AgentPlayerReportingServerFilterKeys,
    AgentPlayerReportingFilterName,
    AgentPlayerReportingServerTextFilterKeys,
    AgentPlayerReportingFiltersAdditionalProps
>(AgentPlayerReportingQueryFilterAdapter, {
    uid: ['uid'],
    uid_email_uname_rmc: ['uid_em_un_rmc', 'uid', 'contact.email', 'username', 'register_marketing_code'],
    uid_email: ['uid_email', 'uid', 'contact.email'],
});
