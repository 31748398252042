import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {ModuleName, SubmoduleName} from '@models/modules';
import {BoUser} from '@redux/entity';

import {getContentItemsPageReducer, getGridModuleStateReducer, getModuleNameReducers} from '../../features/module-shared/reducers';
import {FormModuleData} from '../../features/module-shared/types';

import actions from './actions';
import {domain} from './types';

const rolesReducer = createReducer(null).handleAction(actions.getRoles.success, (_, action) => action.payload ?? null);

const createAccessManagementReducer = () => {
    const formInitialState = {item: undefined, validationMessage: null} as FormModuleData<BoUser>;
    const editFormReducer = createReducer(formInitialState)
        .handleAction(actions.openUserForm, (state, action) => ({
            ...state,
            item: action.payload,
            validationMessage: null,
        }))
        .handleAction(actions.closeUserForm, state => ({
            ...state,
            ...formInitialState,
        }))
        .handleAction(actions.saveUser.failure, (state, action) => ({
            ...state,
            validationMessage: action.payload,
        }));

    const combined = combineReducers({
        domain: createReducer(domain),
        data: combineReducers({
            ...getContentItemsPageReducer(domain),
            ...getModuleNameReducers(ModuleName.AccessManagement, SubmoduleName.Users),
            roles: rolesReducer,
            editForm: editFormReducer,
            state: combineReducers(getGridModuleStateReducer(domain)),
        }),
    });

    return combined;
};

export default createAccessManagementReducer();
