import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {Card} from '@components/card/Card';
import {CustomIcon, Icon} from '@components/icons';
import {StyledInput} from '@components/input';
import {CustomTheme} from '@style';

import {useReferralLink} from 'src/features/block-user-profile-details/hooks';

const localized = defineMessages({
    referralLinkTitle: {
        id: 'ReferralLink_referralLinkTitle',
        defaultMessage: 'Referral link',
    },
    buttonLabel: {
        id: 'ReferralLink_buttonLabel',
        defaultMessage: 'Copy',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    referralLinkContainer: {
        width: 570,
    },
    referralLinkInputIcon: {
        fontSize: theme.typography.h6.fontSize,
        alignSelf: 'center',
    },
    referralLinkContentContainer: {
        marginTop: theme.spacing(5),
        display: 'flex',
        justifyContent: 'space-between',
    },
    referralLinkButton: {
        marginLeft: theme.spacing(2),
        height: theme.custom.denseButtonHeight + 2,
        padding: theme.spacing(1.25, 2),
        minWidth: 'auto',
        borderRadius: theme.shape.borderRadius * 2,
    },
    referralLinkButtonIcon: {
        fontSize: '1em !important',
        color: theme.palette.secondary.main,
    },
}));

type ReferralLinkProps = {
    uid: string;
};

function ReferralLink({uid}: ReferralLinkProps) {
    const {classes} = useStyles();
    const {handleCopyClick, link} = useReferralLink(uid);

    return (
        <Card title={localized.referralLinkTitle} className={classes.referralLinkContainer}>
            <div className={classes.referralLinkContentContainer}>
                <StyledInput
                    startAdornment={<Icon icon={CustomIcon.Linked} className={classes.referralLinkInputIcon} />}
                    value={link}
                    onChange={() => {}}
                    disabled
                    hasBottomSpacing={false}
                    fullWidth={false}
                />
                <Button
                    label={localized.buttonLabel}
                    onClick={handleCopyClick}
                    className={classes.referralLinkButton}
                    startIcon={<Icon icon={CustomIcon.Copy} className={classes.referralLinkButtonIcon} />}
                    disabled={!link}
                />
            </div>
        </Card>
    );
}

export default ReferralLink;
