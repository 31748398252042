import {defineMessages} from 'react-intl';

import {withUserProfileTextField} from '../../player-actions/pii-actions/components/UserProfileTextField';
import {personalInfoActions} from '../actions';

const localized = defineMessages({
    piiLastNameFieldName: {
        id: 'piiLastNameFieldName',
        defaultMessage: 'Last Name',
    },
});

export const UserProfileLastName = withUserProfileTextField({
    action: personalInfoActions.editLastName,
    placeholder: localized.piiLastNameFieldName,
    submitModelMapper: m => ({
        fieldValue: m?.user?.last_name,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            last_name: m?.fieldValue as string,
        },
        userId: m?.userId,
    }),
    isRequired: true,
});
