import React from 'react';
import Autosizer from 'react-virtualized-auto-sizer';
import {FixedSizeList} from 'react-window';
import {makeStyles} from 'tss-react/mui';
import {v4 as uuid} from 'uuid';

import {MultiSelectItem, MultiSelectItemProps} from '@components/select/MultiSelectItem';
import {CustomTheme} from '@style';

import {SelectOption} from 'src/features/module-shared/types';

const useStyles = makeStyles<number>()((theme: CustomTheme, params) => ({
    virtualizedMultiSelectOptionListContainer: {
        height: theme.custom.menuItemHeight * params,
    },
}));

type VirtualizedMultiSelectOptionListProps<TValue extends unknown[]> = Pick<MultiSelectItemProps, 'chipType'> & {
    selectedValue: TValue;
    items: SelectOption[];
    multiselectId: string;
    itemSize?: number;
    visibleOptionCount?: number;
    onItemClick: (value: string) => void;
};

export function VirtualizedMultiSelectOptionList<TValue extends unknown[]>({
    chipType,
    multiselectId,
    items,
    selectedValue,
    visibleOptionCount = 6,
    onItemClick,
}: VirtualizedMultiSelectOptionListProps<TValue>) {
    const {classes, theme} = useStyles(visibleOptionCount);

    return (
        <div className={classes.virtualizedMultiSelectOptionListContainer}>
            <Autosizer>
                {({height, width}) => (
                    <FixedSizeList
                        height={height}
                        width={width}
                        itemSize={(theme as CustomTheme).custom.menuItemHeight}
                        itemCount={items?.length}
                    >
                        {({index, style}) => {
                            const item = items[index];
                            return (
                                <MultiSelectItem
                                    key={`multiselect-${multiselectId}-${item.value}-${uuid()}`}
                                    value={item.value as string}
                                    chipValue={item.value as string}
                                    label={item.label}
                                    isSelected={selectedValue?.includes(item.value)}
                                    chipType={chipType}
                                    style={style}
                                    onClick={() => onItemClick(item.value as string)}
                                />
                            );
                        }}
                    </FixedSizeList>
                )}
            </Autosizer>
        </div>
    );
}
