import {defineMessages} from 'react-intl';

export const localizedHeaders = defineMessages({
    gameType: {
        id: 'DataGridGameTemplate_gameType',
        defaultMessage: 'Game',
    },
    id: {
        id: 'DataGridGameTemplate_id',
        defaultMessage: 'Template ID',
    },
    name: {
        id: 'DataGridGameTemplate_name',
        defaultMessage: 'Template Name',
    },
    live: {
        id: 'DataGridGameTemplate_live',
        defaultMessage: 'Template Live',
    },
    labels: {
        id: 'DataGridGameTemplate_labels',
        defaultMessage: 'Labels',
    },
    stakes: {
        id: 'DataGridGameTemplate_stakes',
        defaultMessage: 'Stakes',
    },
    players: {
        id: 'DataGridGameTemplate_players',
        defaultMessage: 'Players',
    },
    tables: {
        id: 'DataGridGameTemplate_tables',
        defaultMessage: 'Current Tables',
    },
    modify: {
        id: 'DataGridGameTemplate_modify',
        defaultMessage: 'Modify Template',
    },
    date: {
        id: 'DataGridGameTemplate_date',
        defaultMessage: 'Last Modified',
    },
});
