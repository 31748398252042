import React from 'react';
import {PropsWithChildren} from 'react';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
    const gridGap = theme.spacing(1.25);
    return {
        filtersContainerSixColumn: {
            display: 'grid',
            gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
            gap: gridGap,
            alignItems: 'center',

            [theme.breakpoints.down('md')]: {
                display: 'flex',
            },
        },
        filtersContainer: {
            display: 'flex',
            flex: '1',
            gap: gridGap,
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                display: 'flex',
            },
        },
        filtersContainerFullWidth: {
            '& :first-child': {
                flexGrow: 'inherit',
            },
        },
        filtersContainerOnSubmit: {
            flex: 'unset',
        },
    };
});

export type FiltersContainerProps = {
    withSubmit: boolean;
    viewMode?: 'flex' | 'six-column-view';
    groupContainerFullWidth?: boolean;
};

export function FiltersContainer({viewMode, withSubmit, groupContainerFullWidth, children}: PropsWithChildren<FiltersContainerProps>) {
    const {classes, cx} = useStyles();
    return (
        <div
            className={
                viewMode === 'six-column-view'
                    ? classes.filtersContainerSixColumn
                    : cx(classes.filtersContainer, {
                          [classes.filtersContainerFullWidth]: groupContainerFullWidth,
                          [classes.filtersContainerOnSubmit]: withSubmit,
                      })
            }
        >
            {children}
        </div>
    );
}
