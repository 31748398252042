import {defineMessages} from 'react-intl';

import {BackpackItemStatus} from '@models/generated/graphql';

export const localizedBackpackStatus = defineMessages({
    [BackpackItemStatus.Active]: {
        id: 'backpackStatus_Active',
        defaultMessage: 'Active',
    },
    [BackpackItemStatus.Expired]: {
        id: 'backpackStatus_Expired',
        defaultMessage: 'Expired',
    },
    [BackpackItemStatus.Used]: {
        id: 'backpackStatus_Used',
        defaultMessage: 'Used',
    },
});
