import {useEffect, useState} from 'react';

import {useAutoMapper} from '@auto-mapper';
import {AutocompleteOptionType} from '@components/autocomplete';
import {AgentFilterViewModel} from '@models/agent-profile';
import {ReferrerFilter, UserProfile} from '@models/generated/graphql';
import {useAuthUser} from '@auth';
import {
    AgentPlayerReportingServerFilterKeys,
    AgentProfileFilterKeys,
    AgentProfileQueryFields,
    AgentReferrerFilterKeys,
    EntityType,
} from '@redux/entity';
import {extendedViewCleanDelay, UseListViewEntityProps, useViewInit, ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {Filter} from '../../common/types';
import {useUserProfiles} from '../block-user-profile-list';

type UseAgentProfilesResult = {
    items: UserProfile[];
    handleFilterChange: (newFilter: Filter[]) => void;
};

export function useAgentProfiles({
    viewType,
    fields,
    displayName,
    defaultFilters,
    validateFilter,
    cleanDelay,
}: UseListViewEntityProps<AgentProfileFilterKeys, AgentProfileQueryFields>): UseAgentProfilesResult {
    const {items, handleFilterChange} = useViewInit<UserProfile, AgentProfileFilterKeys, AgentProfileQueryFields>({
        viewType,
        entity: {
            entity: EntityType.AgentProfile,
            fields: fields,
        },
        displayName,
        defaultFilters,
        validateFilter,
        cleanDelay,
    });

    return {items, handleFilterChange};
}

type BoAgentPlayerProps = {
    viewType: ViewType;
    cleanDelay?: number;
};

export function useBoAgentPlayer({viewType, cleanDelay}: BoAgentPlayerProps) {
    const boUserId = useAuthUser()?.sub;
    const {items} = useUserProfiles({
        viewType,
        fields: ['uid'],
        defaultFilters: [{key: 'boUserId', value: boUserId}],
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'boUserId')?.value),
        cleanDelay,
    });

    return {
        boAgentPlayerId: items?.[0]?.uid,
    };
}

type UseDefaultReferrerFilterProps = {
    viewType: ViewType;
    cleanDelay?: number;
    onFilterChange: (newFilter: Filter<string, AgentPlayerReportingServerFilterKeys>[]) => void;
};

type UseDefaultReferrerFilterResult = {
    boAgentPlayerId: string;
};

export function useDefaultReferrerFilter({
    viewType,
    cleanDelay = extendedViewCleanDelay,
    onFilterChange,
}: UseDefaultReferrerFilterProps): UseDefaultReferrerFilterResult {
    const {boAgentPlayerId} = useBoAgentPlayer({viewType, cleanDelay});

    useEffect(() => {
        const defaultReferrerIdKey: AgentPlayerReportingServerFilterKeys = 'defaultReferrerPlayerId';
        onFilterChange([{key: defaultReferrerIdKey, value: boAgentPlayerId}]);
    }, [boAgentPlayerId]);

    return {boAgentPlayerId};
}

type AgentFilterDataProps = {
    boAgentPlayerId: string;
    value: Filter<string | boolean>[];
};

type AgentFilterResult = {
    filterViewModel: AgentFilterViewModel;
};

export function useAgentFilter({value, boAgentPlayerId}: AgentFilterDataProps): AgentFilterResult {
    const referrerKey: AgentReferrerFilterKeys = 'referrerPlayerId';
    const downlineKey: AgentReferrerFilterKeys = 'isDownstream';

    const mapper = useAutoMapper();

    const filterViewModel: AgentFilterViewModel = getFilterViewModel();

    function getFilterViewModel(): AgentFilterViewModel {
        const referrerFilter: ReferrerFilter = {
            is_downstream: (value?.find(i => i.key === downlineKey)?.value as boolean) ?? false,
            referrer_player_id: (value?.find(i => i.key === referrerKey)?.value as string) ?? '',
            is_agent: true,
        };

        const viewModel = mapper.map(referrerFilter, ReferrerFilter, AgentFilterViewModel);
        return {...viewModel, default_referrer_player_id: boAgentPlayerId};
    }

    return {
        filterViewModel,
    };
}

type AgentAutocompleteResult = {
    defaultFilters: Filter<any, AgentProfileFilterKeys>[];
};

export function useAgentAutocomplete(filterViewModel: AgentFilterViewModel): AgentAutocompleteResult {
    const defaultFilters: Filter<any, AgentProfileFilterKeys>[] = getDefaultFilters();

    function getDefaultFilters(): Filter<any, AgentProfileFilterKeys>[] {
        const filters: Filter<any, AgentProfileFilterKeys>[] = [];
        if (filterViewModel.default_referrer_player_id) {
            filters.push({key: 'referrer_agent_id', value: filterViewModel.default_referrer_player_id});
        }
        if (filterViewModel.referrer_player_id) {
            filters.push({key: 'uid', value: filterViewModel.referrer_player_id});
        }
        return filters;
    }

    return {
        defaultFilters,
    };
}

type UseAgentAutocompleteOptionsProps = {
    agentProfiles: UserProfile[];
    isTreeMode?: boolean;
};

type UseAgentAutocompleteOptionsResult = {
    options: AutocompleteOptionType[];
};

export function useAgentAutocompleteOptions({
    agentProfiles,
    isTreeMode,
}: UseAgentAutocompleteOptionsProps): UseAgentAutocompleteOptionsResult {
    const [options, setOptions] = useState<AutocompleteOptionType[]>([]);

    useEffect(() => {
        setOptions(getOptions());
    }, [agentProfiles?.map(a => a.uid)?.join()]);

    function getOptions(): AutocompleteOptionType[] {
        return isTreeMode ? getAgentTreeOptions(agentProfiles) : agentProfiles?.map(i => ({label: i.username, value: i.uid}));
    }

    function getAgentTreeOptions(agents: UserProfile[]): AutocompleteOptionType[] {
        const result: AutocompleteOptionType[] = [];
        const itemsDict: Record<string, AutocompleteOptionType> = {};
        const agentsUids: Set<string> = new Set(agents?.map(a => a.uid));

        agents?.forEach(agent => {
            let currentNode = itemsDict[agent.uid];

            if (currentNode) {
                currentNode.value = agent.uid;
                currentNode.label = agent.username;
            } else {
                currentNode = {value: agent.uid, label: agent.username};
                itemsDict[agent.uid] = currentNode;
            }

            const parentId = agent.referral?.inviting_user_id;
            const parentNode = itemsDict[parentId];

            if (parentId && parentNode) {
                parentNode.subOptions = [...(parentNode?.subOptions ?? []), currentNode];
            } else {
                if (parentId && agentsUids.has(parentId)) {
                    itemsDict[parentId] = {subOptions: [currentNode]} as AutocompleteOptionType;
                } else {
                    result.push(currentNode);
                }
            }
        });

        return result;
    }

    return {
        options,
    };
}
