import {defineMessages} from 'react-intl';

import {AccountVerificationStatus} from '@models/generated/graphql';

export const localizedInitiateSecurityKYC = defineMessages({
    [AccountVerificationStatus.InitLoginKyc]: {
        id: 'accountVerificationStatusInitLoginKyc',
        defaultMessage: 'Reverify Login',
    },
    [AccountVerificationStatus.InitWithdrawalKyc]: {
        id: 'accountVerificationStatusInitWithdrawalKyc',
        defaultMessage: 'Reverify Withdrawal',
    },
});
