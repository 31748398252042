import {OidcClientSettings, User, UserManager} from 'oidc-client-ts';
import {from, map, Observable} from 'rxjs';

import {Policy} from './policy';
import {getPolicies} from './utils';

export type UserExtended = User & {
    authHeaders?: Record<string, string>;
    basicInfo?: {
        permissions: string[];
        uid: string;
    };
};

export class UserManagerExtended extends UserManager {
    public getUserObservable(): Observable<User> {
        return from(this.getUser());
    }

    public getUserExtended(): Observable<UserExtended> {
        return this.getUserObservable().pipe(
            map((u: User) => {
                const res: UserExtended = u;
                res.authHeaders = this.getAuthHeadersFromUser(u);
                res.basicInfo = this.getUserBasicInfoFromUser(u);
                return res;
            })
        );
    }

    public getSignInUrl(): Observable<string> {
        const settings: OidcClientSettings = {
            authority: this.settings.authority,
            client_id: this.settings.client_id,
            redirect_uri: this.settings.redirect_uri,
        };

        return from(this._client.createSigninRequest(settings)).pipe(
            map(v => {
                const url = new URL(v.url);
                return url.toString().substring(url.origin.length);
            })
        );
    }

    public getPolicies(): Observable<Policy[]> {
        return this.getUserObservable().pipe(map(user => getPolicies(user).map(p => new Policy(p))));
    }

    private getUserBasicInfoFromUser(user: User): {permissions: string[]; uid: string} {
        return {permissions: user?.profile?.policies as string[], uid: user?.profile?.sub};
    }

    private getAuthHeadersFromUser(user: User): Record<string, string> {
        const authorization: string = user ? `${user.token_type} ${user.access_token}` : null;
        const headers: Record<string, string> = {};
        if (authorization !== null) {
            headers.Authorization = authorization;
        }
        return headers;
    }
}
