import {useAutoMapper} from '@auto-mapper';
import {AgentPathStatisticsViewModel, AgentPathStatisticsViewModelKeys} from '@models/agent-path-statistics';
import {AgentPathStatistics} from '@models/generated/graphql';
import {AgentPathStatisticsQueryFields, BaseFilterKeys, EntityType} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

export function useAgentPathStatistics({
    viewType,
    fields,
    displayName,
    cleanDelay = 0,
    defaultPaging,
    validateFilter,
    syncWithUrl = false,
}: UseListViewEntityProps<BaseFilterKeys, AgentPathStatisticsViewModelKeys>): UseListViewEntityResult<
    AgentPathStatisticsViewModel,
    AgentPathStatisticsViewModelKeys
> {
    const mapper = useAutoMapper();

    const queryFields: AgentPathStatisticsQueryFields[] = mapper?.map<AgentPathStatisticsViewModelKeys[], AgentPathStatisticsQueryFields[]>(
        fields,
        nameof<AgentPathStatisticsViewModelKeys>(),
        nameof<AgentPathStatisticsQueryFields>()
    );

    const {
        items: agentStatisticsModel,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<AgentPathStatistics, BaseFilterKeys, AgentPathStatisticsQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.AgentPathStatistics,
            fields: queryFields,
        },
        defaultPaging,
        syncWithUrl,
        cleanDelay,
        validateFilter,
    });

    const items: AgentPathStatisticsViewModel[] = agentStatisticsModel?.map(i =>
        mapper?.map(i, AgentPathStatistics, AgentPathStatisticsViewModel)
    );

    return {
        items,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    };
}
