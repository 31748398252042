import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {IconButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {Icon, IconColor} from '@components/icons/Icon';
import {CustomIcon, CustomIconValues} from '@components/icons/types';
import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    valueButton: {
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.background.paper,
        border: `1px solid ${theme.custom.palette.content.liner}`,
        boxShadow: `0px 1px 1px ${theme.custom.palette.content.boxShadow}`,
        boxSizing: 'border-box',
        borderRadius: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: theme.custom.formActionButtonHeight,
        cursor: 'pointer',
    },
    valueButtonOpened: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    valueButtonIconContainer: {
        marginRight: theme.spacing(1.25),
        display: 'flex',
        alignItems: 'center',
    },
    valueButtonIcon: {
        fontSize: theme.spacing(2),
    },
    valueButtonRightContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    valueButtonRightIconOpened: {
        marginLeft: theme.spacing(1.25),
        color: theme.palette.primary.main,
    },
    valueButtonRightIcon: {
        marginLeft: theme.spacing(1.25),
    },
    valueButtonRenderValueContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    valueButtonDisabled: {
        '& .MuiTypography-root': {
            color: theme.palette.secondary.main,
        },
    },
    valueButtonLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
    },
}));

export type ValueButtonProps = {
    label: MessageDescriptor;
    statusLabel?: React.ReactNode;
    iconClassName: CustomIconValues;
    onClick: (value?: unknown) => void;
    endIconClassName?: string;
    endIconSize?: number;
    open?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
};

export const ValueButton: React.FC<ValueButtonProps> = ({
    label,
    iconClassName,
    onClick,
    endIconClassName = CustomIcon.GreaterThan,
    endIconSize = 10,
    open,
    children,
    disabled,
    statusLabel,
}) => {
    const {classes, cx} = useClasses();

    return (
        <Box
            className={cx(
                {
                    [classes.valueButtonDisabled]: disabled,
                    [classes.valueButtonOpened]: open,
                },
                classes.valueButton
            )}
            onClick={disabled ? () => {} : onClick}
        >
            <Box className={classes.valueButtonIconContainer}>
                <Icon icon={iconClassName} color={open ? IconColor.Primary : IconColor.Secondary} className={classes.valueButtonIcon} />
            </Box>
            <Box className={classes.valueButtonLabel}>
                <Typography variant="body1" color={open ? 'primary' : 'textPrimary'}>
                    <LocalizedText label={label} />
                </Typography>
                {statusLabel}
            </Box>
            <Box className={classes.valueButtonRightContainer}>
                <Box className={classes.valueButtonRenderValueContainer}>{children}</Box>
                <IconButton
                    iconClassName={endIconClassName}
                    className={open ? classes.valueButtonRightIconOpened : classes.valueButtonRightIcon}
                    iconFontSize={endIconSize}
                ></IconButton>
            </Box>
        </Box>
    );
};
