import React, {useEffect} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from 'tss-react/mui';

import {BulkModalContent, BulkModalTitle} from '@components/modal';
import {useService} from '@inversify';
import {SecurityCase} from '@models/generated/graphql';

import {
    bulkActionsActions,
    BulkApplyStepModalHeader,
    BulkStepProps,
    itemsSelector,
    securityCaseAddResultValueSelector,
    securityCaseInitialValueSelector,
    securityCaseRemoveResultValueSelector,
    securityCaseUpdateResultValueSelector,
} from '../../block-bulk-actions';
import {useSecurityCases} from '../../player-actions/cases-actions/hooks';
import {EditSecurityCasesFieldModel, EditSecurityCasesSubmitModel} from '../../player-actions/cases-actions/types';
import {PlayerProfileGridItem} from '../../player-actions/types';
import {
    ApplySecurityCasesAddStrategy,
    ApplySecurityCasesRemoveStrategy,
    ApplySecurityCasesRequest,
    ApplySecurityCasesUpdateStrategy,
} from '../services/applyStrategy';

import {BulkUserProfileSecurityCasesFieldsForm} from './BulkUserProfileSecurityCasesFieldsForm';

const localized = defineMessages({
    title: {
        id: 'BulkStepPlayerSecurityCases_title',
        defaultMessage: 'Add Bulk Action',
    },
    addSecurityCaseID: {
        id: 'BulkStepPlayerSecurityCases_addSecurityCaseID',
        defaultMessage: 'Add Security Case ID',
    },
});

const useClasses = makeStyles()({
    bulkStepPlayerSecurityCaseIdForm: {
        overflow: 'auto',
    },
});

export const BulkUserProfileSecurityCaseModal = ({onPrev, onNext}: BulkStepProps) => {
    const {classes} = useClasses();
    const dispatch = useDispatch();

    const id = nameof<PlayerProfileGridItem>(u => u.security_cases);
    const typeName = nameof<PlayerProfileGridItem>(u => u.security_cases);
    const field = nameof<PlayerProfileGridItem>(u => u.security_cases);

    const affectedUsers = useSelector(itemsSelector) as PlayerProfileGridItem[];
    const initialSecurityCases = useSelector(securityCaseInitialValueSelector);

    const {initialValue, getSubmitValue, getFormValue} = useSecurityCases(initialSecurityCases ?? []);

    const addResultValue: EditSecurityCasesSubmitModel = useSelector(securityCaseAddResultValueSelector);
    const updateResultValue: EditSecurityCasesSubmitModel = useSelector(securityCaseUpdateResultValueSelector);
    const removeResultValue: EditSecurityCasesSubmitModel = useSelector(securityCaseRemoveResultValueSelector);

    const defaultValue = getFormValue(addResultValue, updateResultValue, removeResultValue, affectedUsers?.length);
    const form = useForm<EditSecurityCasesFieldModel>({defaultValues: defaultValue});
    const watched = useWatch({control: form.control});

    useEffect(() => {
        form.reset(defaultValue);
    }, [JSON.stringify(initialSecurityCases)]);

    const addStrategy = useService<ApplySecurityCasesAddStrategy>('ApplySecurityCasesAddStrategy');
    const updateStrategy = useService<ApplySecurityCasesUpdateStrategy>('ApplySecurityCasesUpdateStrategy');
    const removeStrategy = useService<ApplySecurityCasesRemoveStrategy>('ApplySecurityCasesRemoveStrategy');

    const handleApply = () => {
        const allUpdatedCases = watched?.cases?.filter(i => i.case_id || i.isNew) as SecurityCase[];
        if (initialValue && defaultValue && form) {
            dispatch(
                bulkActionsActions.apply({
                    request: {
                        players: affectedUsers,
                        value: getSubmitValue({cases: allUpdatedCases}),
                    } as ApplySecurityCasesRequest,
                    strategy: addStrategy,
                })
            );
            dispatch(
                bulkActionsActions.apply({
                    request: {
                        players: affectedUsers,
                        value: getSubmitValue({cases: allUpdatedCases}),
                    } as ApplySecurityCasesRequest,
                    strategy: updateStrategy,
                })
            );
            dispatch(
                bulkActionsActions.apply({
                    request: {
                        players: affectedUsers,
                        value: getSubmitValue({cases: allUpdatedCases}),
                    } as ApplySecurityCasesRequest,
                    strategy: removeStrategy,
                })
            );
        }

        onNext();
    };

    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent>
                <BulkApplyStepModalHeader back={onPrev} apply={handleApply} label={localized.addSecurityCaseID} />
                <FormProvider {...form}>
                    <form name={`${typeName}.${field}.${id}`} className={classes.bulkStepPlayerSecurityCaseIdForm}>
                        <BulkUserProfileSecurityCasesFieldsForm id={id} value={defaultValue} typeName={typeName} form={form} />
                    </form>
                </FormProvider>
            </BulkModalContent>
        </>
    );
};
