import React from 'react';
import {defineMessages} from 'react-intl';

import {formatDate} from '@utils';

import {ConfigurationKeyValuePair} from 'src/common/types';
import {LegalDocs} from 'src/features/legal-docs/components/LegalDocs';
import {privacyPolicySelector} from 'src/features/legal-docs/selectors';
import actions from '../../legal-docs/actions';
import {domain} from '../../legal-docs/types';
import {LegalDocType, LegalDocumentItem} from '../../legal-docs/types';
import {WorkspaceDetailsComponentProps} from '../types';

import {WorkspaceKeyValueTable} from './WorkspaceKeyValueTable';

const getTableRows = (document: LegalDocumentItem): ConfigurationKeyValuePair[] => [
    {
        field: nameof<LegalDocumentItem>(l => l.content),
        key: localizedUserPrivacyPolicy.userPrivacyPolicyAcceptanceVersion,
        value: <LegalDocs uid={document?.uid} legalDocType={LegalDocType.PrivacyPolicy} document={document} />,
        configuration: [],
    },
    {
        field: nameof<LegalDocumentItem>(l => l.acceptedAt),
        key: localizedUserPrivacyPolicy.userPrivacyPolicyDateAccepted,
        value: formatDate(document?.acceptedAt, 'date-time'),
        configuration: [],
    },
];

const localizedUserPrivacyPolicy = defineMessages({
    userPrivacyPolicyHeader: {
        id: 'UPP_userPrivacyPolicyHeader',
        defaultMessage: 'Privacy Policy',
    },
    userPrivacyPolicyDateAccepted: {
        id: 'UPP_userPrivacyPolicyDateAccepted',
        defaultMessage: 'Date Accepted',
    },
    userPrivacyPolicyAcceptanceVersion: {
        id: 'UPP_userPrivacyPolicyAcceptanceVersion',
        defaultMessage: 'Acceptance Version',
    },
});

export const UserPrivacyPolicy = ({itemId, className, fields, isUnderlined, hideTitle}: WorkspaceDetailsComponentProps) => {
    return (
        <WorkspaceKeyValueTable
            itemId={itemId}
            domain={domain}
            className={className}
            header={localizedUserPrivacyPolicy.userPrivacyPolicyHeader}
            fields={fields}
            getTableRows={getTableRows}
            dataSelector={privacyPolicySelector}
            hideTitle={hideTitle}
            isUnderlined={isUnderlined}
            columnsCount={2}
            requestAction={actions.loadAcceptedPrivacyPolicy.request}
        />
    );
};
