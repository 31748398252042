import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    line: {
        lineHeight: theme.typography.body1.lineHeight,
    },
}));

const VersionAcceptanceGridHeader = (docType: MessageDescriptor, headerName: MessageDescriptor) => {
    const {classes} = useClasses();
    return (
        <Box className={classes.container}>
            <Box className={classes.line}>
                <LocalizedText label={docType} />
            </Box>
            <Box className={classes.line}>
                <LocalizedText label={headerName} />
            </Box>
        </Box>
    );
};

export default VersionAcceptanceGridHeader;
