import {MessageDescriptor} from 'react-intl';
import {injectable} from 'inversify';

import {BaseEpicsBuilder} from '@redux';
import {ServerResponseError, ServiceResponsePayload} from '@services/types';

import {showErrorAction} from '../message-snack-bar/actions';
import {asyncActions} from '../shared/async-action/actions';

@injectable()
export abstract class GameActionsEpicsBuilder extends BaseEpicsBuilder {
    protected showOperationFailureMessage<TRequestPayload, TResponsePayload>(
        response: ServiceResponsePayload<TRequestPayload, TResponsePayload>,
        operationFailure: MessageDescriptor,
        operationFailureWithDetails: MessageDescriptor
    ) {
        const error: ServerResponseError = response?.errors?.[0];
        return error?.message && error?.traceId
            ? showErrorAction({
                  message: operationFailureWithDetails,
                  values: {message: error?.message, traceId: error?.traceId},
              })
            : showErrorAction({message: operationFailure});
    }

    protected setAsyncActionErrorMessage<TRequestPayload, TResponsePayload>(
        actionUniqueId: string,
        response: ServiceResponsePayload<TRequestPayload, TResponsePayload>,
        operationFailure: MessageDescriptor,
        operationFailureWithDetails: MessageDescriptor,
        messageValues?: Record<string, string>
    ) {
        const error: ServerResponseError = response?.errors?.[0];
        return error?.message && error?.traceId
            ? asyncActions.setErrorMessage({
                  requestAction: actionUniqueId,
                  errorMessage: operationFailureWithDetails,
                  errorMessageValues: {...messageValues, message: error?.message, traceId: error?.traceId},
              })
            : asyncActions.setErrorMessage({
                  requestAction: actionUniqueId,
                  errorMessage: operationFailure,
              });
    }
}
