import React from 'react';

import MuiDataGrid, {MuiDataGridProps} from './MuiDataGrid';

export type MuiDataGridClientProps = Omit<MuiDataGridProps, 'sortingMode' | 'paginationMode' | 'rowCount'>;

/**
 * @deprecated
 * <p>Should be removed. Use {@link DataGridClient}</p>
 */
const withDataGridClient = (WrappedDataGrid: (props: MuiDataGridProps) => JSX.Element) => (props: MuiDataGridClientProps) => {
    const {rows} = props;
    return <WrappedDataGrid {...props} rowCount={rows?.length} sortingMode="client" paginationMode="client" />;
};

/**
 * @deprecated
 * <p>Should be removed. Use {@link DataGridClient}</p>
 */
export const MuiDataGridClient = withDataGridClient(MuiDataGrid);
