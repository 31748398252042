import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import actions from './actions';
import {domain, LegalDocumentItem} from './types';

const getLegalDocumentsReducer = () => {
    const privacyPolicyReducer = createReducer(null as LegalDocumentItem).handleAction(
        actions.loadAcceptedPrivacyPolicy.success,
        (_, action) => action.payload
    );

    const termsReducer = createReducer(null as LegalDocumentItem).handleAction(
        actions.loadAcceptedTerms.success,
        (_, action) => action.payload
    );

    return {
        terms: termsReducer,
        privacyPolicy: privacyPolicyReducer,
    };
};

export const legalDocsReducer = combineReducers({
    domain: createReducer(domain),
    data: combineReducers(getLegalDocumentsReducer()),
});
