import {DocumentNode, gql} from '@apollo/client';
import equal from 'fast-deep-equal/es6';
import {ExecutionResult} from 'graphql';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {
    BonusType,
    MarketingCode,
    MarketingCodeFilterInput,
    Mutation,
    MutationAddMarketingCodeArgs,
    MutationDeleteMarketingCodesArgs,
    Query,
} from '@models/generated/graphql';
import {ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlSearchFilter} from '@services/deprecated/types';
import {toGQKMultiselectFilter, toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';
import {IRealtimeGridReadService} from '../../realtime-updates/services/IRealtimeGridReadService';
import {addMarketingCodeMutation, deleteMarketingCodeMutation} from '../mutations';
import {MarketingCodeColumnsConfiguration, MarketingCodeGridItem, MarketingCodeItem} from '../types';

const getMarketingCodesQuery = (configuration: MarketingCodeColumnsConfiguration) => gql`
    query getMarketingCodes($filter: MarketingCodeFilterInput, $sort: Sorting, $start: Int, $end: Int) {
        getMarketingCodes(filter: $filter, sort: $sort, end: $end, start: $start) {
            items {
                marketing_code ${configuration.withMarketingCode ? '' : '@client'}
                type ${configuration.withType ? '' : '@client'}
                added_by_uid ${configuration.withAddedByUid ? '' : '@client'}
                btag ${configuration.withBtag ? '' : '@client'} 
                referrer_id ${configuration.withBtag ? '' : '@client'} 
                created_at ${configuration.withCreatedAt ? '' : '@client'} {
                    seconds
                }
            }
            total_count
        }
    }
`;

export class MarketingCodeService
    extends ReadonlyRealtimeGridServiceBase<MarketingCodeItem, MarketingCodeGridItem, MarketingCode>
    implements IRealtimeGridReadService<string>
{
    getItem(): Observable<MarketingCodeItem> {
        throw new Error('Method not implemented.');
    }

    addItem(item: MutationAddMarketingCodeArgs) {
        return this._client.executeMutation(addMarketingCodeMutation, item).pipe(
            catchError((error: ExecutionResult<Mutation>) => {
                const addCodeValidationErrorType = 'MarketingCodeAlreadyAvailable';
                return error?.errors?.length === 1 && error.errors[0].message?.includes(addCodeValidationErrorType)
                    ? throwError({
                          message: `Marketing code ${item.marketing_code.marketing_code} with bonus type ${item.marketing_code.type} already exists`,
                          response: {
                              ...error,
                              code: 10000,
                              values: {code: item.marketing_code.marketing_code, type: item.marketing_code.type},
                          },
                      })
                    : throwError(error);
            })
        );
    }

    deleteItems(items: MutationDeleteMarketingCodesArgs): Observable<unknown> {
        return this._client.executeMutation(deleteMarketingCodeMutation, items).pipe(
            catchError((error: ExecutionResult<Mutation>) => {
                const deletedCodes = error?.data?.deleteMarketingCodes?.filter(c => c);
                if (deletedCodes?.length > 0) {
                    const failedItems = items.codes.filter(
                        code =>
                            !deletedCodes.some(
                                deletedCode => equal(code.marketing_code, deletedCode.marketing_code) && equal(code.type, deletedCode.type)
                            )
                    );
                    const formattedFailedCodes = failedItems.map(i => `${i.marketing_code} ${i.type}`).join(', ');
                    return throwError({
                        message: `Some marketing codes are not deleted: ${formattedFailedCodes}`,
                        response: {
                            ...error,
                            code: 10000,
                            values: {codes: formattedFailedCodes},
                        },
                    });
                }
                return throwError(error);
            })
        );
    }

    getItemsIds(filter?: SearchFilter): Observable<string[]> {
        const configuration = this.getGQLConfiguration(new MarketingCodeColumnsConfiguration(), [
            nameof<MarketingCodeColumnsConfiguration>(c => c.withMarketingCode),
            nameof<MarketingCodeColumnsConfiguration>(c => c.withType),
        ]);

        return this._client
            .executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter))
            .pipe(map<Query, string[]>(res => res?.getMarketingCodes?.items?.map(i => `${i?.marketing_code}-${i?.type}`) ?? []));
    }

    getItems(): Observable<MarketingCodeGridItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsPage(filter?: SearchFilter, itemFields?: string[]): Observable<ItemsPage<MarketingCodeGridItem>> {
        const configuration = this.getGQLConfiguration(new MarketingCodeColumnsConfiguration(), itemFields);

        return this._client.executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter)).pipe(
            map<Query, ItemsPage<MarketingCodeGridItem>>(res => ({
                items:
                    res?.getMarketingCodes?.items?.map((i, index) =>
                        this.mapModels(
                            {id: `${index}`, serverId: `${i?.marketing_code}-${i?.type}`} as MarketingCodeGridItem,
                            i as MarketingCode
                        )
                    ) ?? [],

                total: res?.getMarketingCodes?.total_count ?? 0,
            }))
        );
    }

    protected getItemsPageQuery(configuration: MarketingCodeColumnsConfiguration): DocumentNode {
        return getMarketingCodesQuery(configuration);
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        return {
            type: toGQKMultiselectFilter<BonusType>(
                filters,
                nameof<MarketingCodeFilterInput>(m => m.type)
            ),
            text: [
                toGQLTextFilter(
                    filters,
                    nameof.toArray<MarketingCode>(m => [m.marketing_code]),
                    nameof<MarketingCode>(m => m.marketing_code)
                ),
            ],
        } as MarketingCodeFilterInput;
    }
}
