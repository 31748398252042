import React from 'react';
import {TreeItem, TreeItemProps} from '@mui/x-tree-view/TreeItem';
import {makeStyles} from 'tss-react/mui';

import {IconButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {ExpandMoreMinusIcon, ExpandMorePlusIcon} from '@components/icons/ExpandMoreIcon';
import {CustomTheme} from '@style';

const useStyles = makeStyles<void, 'treeViewItemExpandableOptionIcon'>()((theme: CustomTheme, _params, classes) => ({
    treeViewItemExpandableOption: {
        '& .MuiTreeItem-root': {
            position: 'relative',
            '&:hover': {
                background: theme.palette.primary.light,
            },
        },
    },
    treeViewItemExpandableOptionIcon: {
        display: 'none',
    },
    treeViewItemExpandableOptionLabel: {
        '& .MuiTreeItem-content': {
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            '& .MuiTreeItem-label': {
                position: 'static',
            },
        },
    },
    treeViewItemExpandableOptionLabelContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    treeViewItemExpandableOptionContentLines: {
        position: 'relative',
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            zIndex: '1',
            left: theme.spacing(1.5),
        },
        '&:after': {
            bottom: '50%',
            height: '100%',
            width: theme.spacing(2),
            borderLeft: `1px solid ${theme.custom.palette.content.border}`,
            borderBottom: `1px solid ${theme.custom.palette.content.border}`,
        },
        '&:before': {
            width: 1,
            height: 0,
            borderLeft: `1px solid ${theme.custom.palette.content.border}`,
        },
    },
    treeViewItemExpandableOptionContentLinesLastChild: {
        '&:before': {
            height: '100%',
        },
    },
    treeViewItemExpandableOptionSelected: {
        '& .MuiTreeItem-root': {
            background: theme.palette.primary.light,
            [`& .${classes.treeViewItemExpandableOptionIcon}`]: {
                display: 'block',
            },
        },
    },
    treeViewItemExpandableOptionDepthLines: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'absolute',
        top: 0,
        height: '100%',
        marginLeft: theme.spacing(1.5),
    },
    treeViewItemExpandableOptionDepthLineMargin: {
        width: theme.spacing(2),
        height: '100%',
    },
    treeViewItemExpandableOptionDepthLine: {
        borderLeft: `1px solid ${theme.custom.palette.content.border}`,
    },
}));

export type TreeOption = {
    value: string;
    label: string;
    subOptions: TreeOption[];
    depth?: number;
    parentId?: string;
    isLastChild?: boolean;
};

type TreeViewItemExpandableOptionProps = Omit<TreeItemProps, 'onClick' | 'label'> & {
    label: string;
    depth: number;
    hasSubOptions: boolean;
    parentsDepthLines: boolean[];
    onClick: (value: string) => void;
    onExpand: (e: React.MouseEvent<Element, MouseEvent>) => void;
    isExpanded: boolean;
    isSelected: boolean;
    isLastChild: boolean;
};

export function TreeViewItemExpandableOption({
    label,
    nodeId,
    onClick,
    onExpand,
    style,
    parentsDepthLines,
    depth = 0,
    isSelected = false,
    hasSubOptions = false,
    isExpanded = false,
    isLastChild = false,
    ...props
}: TreeViewItemExpandableOptionProps) {
    const {classes, cx, theme} = useStyles();

    function handleClick() {
        onClick(label);
    }

    return (
        <div
            className={cx(classes.treeViewItemExpandableOption, classes.treeViewItemExpandableOptionLabel, {
                [classes.treeViewItemExpandableOptionSelected]: isSelected,
            })}
        >
            <TreeItem
                classes={{
                    content: cx({
                        [classes.treeViewItemExpandableOptionContentLines]: !!depth,
                        [classes.treeViewItemExpandableOptionContentLinesLastChild]: !isLastChild,
                    }),
                }}
                icon={
                    hasSubOptions ? (
                        isExpanded ? (
                            <IconButton onClick={onExpand}>
                                <ExpandMoreMinusIcon />
                            </IconButton>
                        ) : (
                            <IconButton onClick={onExpand}>
                                <ExpandMorePlusIcon />
                            </IconButton>
                        )
                    ) : null
                }
                nodeId={nodeId}
                onClick={handleClick}
                label={
                    <>
                        <div
                            className={classes.treeViewItemExpandableOptionDepthLines}
                            style={{
                                left: `-${parseInt(theme.spacing(2), 10) * depth}px`,
                            }}
                        >
                            {parentsDepthLines?.map(i => (
                                <div
                                    className={cx(
                                        {
                                            [classes.treeViewItemExpandableOptionDepthLine]: i,
                                        },
                                        classes.treeViewItemExpandableOptionDepthLineMargin
                                    )}
                                ></div>
                            ))}
                        </div>
                        <div className={classes.treeViewItemExpandableOptionLabelContainer}>
                            <LocalizedText label={label} />
                            {isSelected ? (
                                <Icon
                                    icon={CustomIcon.Confirm}
                                    color={IconColor.Primary}
                                    className={classes.treeViewItemExpandableOptionIcon}
                                />
                            ) : null}
                        </div>
                    </>
                }
                style={{...style, paddingLeft: `${parseInt(theme.spacing(2), 10) * depth}px`}}
                {...props}
            />
        </div>
    );
}
