import React, {Fragment, useContext} from 'react';
import {Controller} from 'react-hook-form';
import {IntlContext} from 'react-intl';
import {Box, Checkbox, FormControlLabel, TextField} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {HTMLEditor} from '@components/rich-text-editor/HTMLEditor';

import {FormStyledInput} from '../../../features/module-shared/components/FormStyledInput';
import withModuleEditForm, {ModuleFormInnerProps} from '../../../features/module-shared/components/ModuleEditForm';
import {LegalDocumentRead} from '../types';

import data from './data';

const useClasses = makeStyles()(() => ({
    formFullscreenInput: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    isRequiredInput: {
        margin: 0,
    },
}));

const LegalDocumentFormContent = ({value, setValue, control, errors}: ModuleFormInnerProps<LegalDocumentRead>) => {
    const {classes} = useClasses();
    const {formatMessage} = useContext(IntlContext);

    return (
        <Fragment>
            <Box display="none">
                <Controller render={_ => <TextField hidden />} name="id" control={control} defaultValue={value?.id ?? ''} />
            </Box>
            <FormStyledInput
                className={classes.formFullscreenInput}
                render={_ => (
                    <HTMLEditor
                        error={errors.document?.message}
                        value={value?.document}
                        onChange={(value: string) =>
                            setValue(
                                nameof<LegalDocumentRead>(u => u.document),
                                value
                            )
                        }
                    />
                )}
                name={nameof<LegalDocumentRead>(u => u.document)}
                control={control}
                label={formatMessage(data.localized.documentContent)}
                defaultValue={value?.document}
                required={true}
            />
            <FormStyledInput
                render={({field}) => (
                    <FormControlLabel
                        control={
                            <Checkbox checked={field.value as boolean} onChange={e => field.onChange(e.target.checked)} color="secondary" />
                        }
                        label={formatMessage(data.localized.isRequired)}
                    />
                )}
                name={nameof<LegalDocumentRead>(u => u.required)}
                control={control}
                className={classes.isRequiredInput}
                defaultValue={value?.required ?? true}
            />
        </Fragment>
    );
};

const LegalDocumentForm = withModuleEditForm<LegalDocumentRead>(LegalDocumentFormContent, LegalDocumentRead, true);

export default LegalDocumentForm;
