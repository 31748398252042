import {IModuleGridItem} from '@components/data-grid';
import {MuiDataGridClient, withDataGridEntityClient} from '@components/data-grid/mui';
import {TransactionViewModel, TransactionViewModelKeys} from '@models/transaction';

import {BulkResultsRecord, DataGridBulkResultsColumns, DataGridBulkResultsConfigFactory} from 'src/features/block-bulk-actions';
import {DataGridTransactionConfigFactory} from 'src/features/block-transaction-list';

export type DataGridBulkTransactionColumns = DataGridBulkResultsColumns | TransactionViewModelKeys;

export const DataGridBulkTransactionsClient = withDataGridEntityClient<
    DataGridBulkTransactionColumns,
    BulkResultsRecord & Partial<TransactionViewModel> & IModuleGridItem
>(MuiDataGridClient, () => ({
    ...new DataGridBulkResultsConfigFactory().getColumnConfig(),
    ...new DataGridTransactionConfigFactory().getColumnConfig(),
}));
