export function getMinValue(a?: number, b?: number): number {
    let maxValue: number = undefined;
    if (!isNaN(a) && !isNaN(b)) {
        maxValue = Math.min(a, b);
    } else if (!isNaN(a)) {
        maxValue = a;
    } else if (!isNaN(b)) {
        maxValue = b;
    }
    return maxValue;
}

export function getMaxValue(a?: number, b?: number): number {
    let minValue: number = undefined;
    if (!isNaN(a) && !isNaN(b)) {
        minValue = Math.max(a, b);
    } else if (!isNaN(a)) {
        minValue = a;
    } else if (!isNaN(b)) {
        minValue = b;
    } else {
        minValue = undefined;
    }
    return minValue;
}
