import {combineEpics, Epic} from 'redux-observable';
import {Observable} from 'rxjs';
import {StateType} from 'typesafe-actions';

import {ServiceResponsePayload} from '@services/types';

import {AsyncActionRequestEpicCreator} from './async-action/asyncActionRequestEpicCreator';
import {ProtectEpicCreator} from './error-handling/protectEpicCreator';
import {IAsyncPayloadAction, IAsyncPayloadMetaAction} from './async-action';

export interface IEpicsBuilder {
    buildEpics(): Epic;
}

type RequestCallback<TRequestActionPayload, TRequest, TResponse> = (
    payload: TRequestActionPayload,
    state: StateType<unknown>
) => Observable<ServiceResponsePayload<TRequest, TResponse>>;

export abstract class BaseEpicsBuilder implements IEpicsBuilder {
    private _asyncEpicCreator: AsyncActionRequestEpicCreator;
    private _protectEpicCreator: ProtectEpicCreator;

    constructor() {
        this._asyncEpicCreator = new AsyncActionRequestEpicCreator();
        this._protectEpicCreator = new ProtectEpicCreator();
    }

    public buildEpics(): Epic {
        const epics = this.buildEpicList();
        return epics.length === 1
            ? this._protectEpicCreator.createEpic(epics[0])
            : combineEpics(...epics.map(epic => this._protectEpicCreator.createEpic(epic)));
    }

    protected abstract buildEpicList(): Epic[];

    protected buildRequestEpic<TRequestActionPayload, TRequest, TResponse>(
        asyncAction:
            | IAsyncPayloadAction<
                  TRequestActionPayload,
                  ServiceResponsePayload<TRequest, TResponse>,
                  ServiceResponsePayload<TRequest, TResponse>
              >
            | IAsyncPayloadMetaAction<
                  TRequestActionPayload,
                  ServiceResponsePayload<TRequest, TResponse>,
                  ServiceResponsePayload<TRequest, TResponse>
              >,
        callback: RequestCallback<TRequestActionPayload, TRequest, TResponse>,
        shouldCancelPreviousAction = true
    ): Epic {
        return this._asyncEpicCreator.createRequestEpic(asyncAction, callback, shouldCancelPreviousAction);
    }
}
