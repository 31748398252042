import {constructUsing, createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {PlayerReferrerSummary} from '@models/generated/graphql';
import {PlayerReferrerSummaryQueryFields} from '@redux/entity';

import {PlayerReferrerSummaryViewModel, PlayerReferrerSummaryViewModelKeys} from './types';

export class PlayerReferrerSummaryMapping implements IMapping {
    createMapping(mapper: Mapper) {
        createMap(
            mapper,
            PlayerReferrerSummary,
            PlayerReferrerSummaryViewModel,
            forMember(
                vm => vm.referrer_uid,
                mapFrom(m => m.referrer?.uid)
            ),
            forMember(
                vm => vm.invite_code,
                mapFrom(m => m.invite_code)
            ),
            forMember(
                vm => vm.redeem_pending_total,
                mapFrom(m => m.redeem_pending_total)
            ),
            forMember(
                vm => vm.revenue_generated_total,
                mapFrom(m => m.revenue_generated_total)
            ),
            forMember(
                vm => vm.revenue_share,
                mapFrom(m => m.revenue_share)
            ),
            forMember(
                vm => vm.revenue_redeemed_total,
                mapFrom(m => m.revenue_redeemed_total)
            )
        );
        createMap<PlayerReferrerSummaryViewModelKeys[], PlayerReferrerSummaryQueryFields[]>(
            mapper,
            nameof<PlayerReferrerSummaryViewModelKeys>(),
            nameof<PlayerReferrerSummaryQueryFields>(),
            constructUsing(m => {
                const mapper: Record<PlayerReferrerSummaryViewModelKeys, PlayerReferrerSummaryQueryFields[]> = {
                    invite_code: ['invite_code'],
                    redeem_pending_total: ['redeem_pending_total'],
                    revenue_generated_total: ['revenue_generated_total'],
                    revenue_redeemed_total: ['revenue_redeemed_total'],
                    revenue_share: ['revenue_share'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
    }
}
