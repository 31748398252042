export enum ActiveStatus {
    Active = 'Active',
    Inactive = 'Inactive',
}

export class Rule {
    id: string;
    name: string;
    expression: string;
    status: ActiveStatus;
    order: number;
}

export class CreateUpdateRuleInput {
    name: string;
    expression: string;
}

export class ChangeOrderInput {
    order: number;
}
