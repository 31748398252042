import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {AgentPlayerReportingViewModelKeys} from '@models/agent-player-reporting';
import {AgentPlayerReportingQueryFields, BaseFilterKeys} from '@redux/entity';
import {maxPageSize} from '@services/types';
import {getFilterString} from '@utils';

import {useAsyncActionState} from '../shared/async-action/hooks';

import {agentReportingActions} from './actions';
import {AgentReportType} from './types';

type UseAgentReportDownloadButtonProps = {
    filterString: string;
    keys: AgentPlayerReportingViewModelKeys[];
    filename: string;
    agentReportType: AgentReportType;
};

type UseAgentReportDownloadButtonResult = {
    handleClick: () => void;
    isProgress: boolean;
};

export function useAgentPlayerReportDownloadButton({
    filterString,
    keys,
    filename,
    agentReportType,
}: UseAgentReportDownloadButtonProps): UseAgentReportDownloadButtonResult {
    const pageFilterKey: BaseFilterKeys = 'page';
    const sizeFilterKey: BaseFilterKeys = 'size';
    const dispatch = useDispatch();
    const mapper = useAutoMapper();
    const {isProgress} = useAsyncActionState(agentReportingActions.download);

    const searchFilter = getFilterString(
        filterString,
        true,
        {key: pageFilterKey, value: '1'},
        {key: sizeFilterKey, value: `${maxPageSize}`}
    );

    const fields: AgentPlayerReportingQueryFields[] = mapper.map<AgentPlayerReportingViewModelKeys[], AgentPlayerReportingQueryFields[]>(
        keys,
        nameof<AgentPlayerReportingViewModelKeys>(),
        nameof<AgentPlayerReportingQueryFields>()
    );

    const handleClick = () => {
        dispatch(agentReportingActions.download.request({filter: searchFilter, fields, filename, worksheetKeys: keys, agentReportType}));
    };

    return {handleClick, isProgress};
}
