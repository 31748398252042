import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {OccupationsApiService} from '@services/rest-api';

import {ServiceTypes} from '../inversify';

@injectable()
export class OccupationsService implements IEntityReadService {
    private readonly _occupationsApiService: OccupationsApiService;

    constructor(@inject(ServiceTypes.OccupationsApiService) occupationsApiService: OccupationsApiService) {
        this._occupationsApiService = occupationsApiService;
    }

    public get(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        return this._occupationsApiService.getOccupations(requestPayload);
    }
}
