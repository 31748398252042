import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, TextField, TextFieldProps} from '@mui/material';

import {EditableProps, withEditButton} from '@components/editable/EditButtonHoc';
import {isStringNullOrEmpty} from '@utils';

import {emptyCellCharacter} from '../../../features/module-shared/utils';

import {useClasses} from './MultiInputEditableText.style';

type MultiInputValueType = {
    [key: string]: string;
};

type MultiInputEditableTextInternalProps<T extends MultiInputValueType> = EditableProps & {
    disabled?: boolean;

    value: T;

    onChange: (value: T) => void;

    inputProps: {
        [key: string]: Omit<TextFieldProps, 'onChange' | 'disabled' | 'value'>;
    };
};

const MultiInputEditableTextInternal = <T extends MultiInputValueType>({
    mode,
    disabled,
    onChange,
    value,
    inputProps,
}: MultiInputEditableTextInternalProps<T>) => {
    const {classes} = useClasses();
    const inputsDisabled = disabled || mode !== 'Edit';
    const inputKeys = Object.keys(inputProps);
    const [inputValues, setInputValues] = useState(value);
    const displayValue = inputKeys.map(key => value[key]).join('');

    useEffect(() => {
        setInputValues(value);
    }, [value]);

    const handleChange = (key: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = {...inputValues, [key]: e.target.value};
        setInputValues(newValue);
        onChange(newValue);
    };
    return value ? (
        <Box className={classes.multiInputEditableText}>
            {!inputsDisabled ? (
                inputKeys.map((key: string) => {
                    const props = inputProps[key];
                    const {placeholder} = props;
                    const inputValue = disabled && !value ? emptyCellCharacter : value;
                    const inputPlaceholder = mode === 'Edit' ? placeholder : (inputValue as string);
                    return (
                        <TextField
                            {...props}
                            className={classes.multiInputEditableTextInput}
                            value={value[key]}
                            disabled={inputsDisabled}
                            variant={mode !== 'Edit' ? 'standard' : 'outlined'}
                            placeholder={inputPlaceholder}
                            onChange={e => handleChange(key, e)}
                        />
                    );
                })
            ) : (
                <Box className={classes.multiInputEditableTextReadonly}>
                    {!isStringNullOrEmpty(displayValue) ? displayValue : emptyCellCharacter}
                </Box>
            )}
        </Box>
    ) : (
        <>{emptyCellCharacter}</>
    );
};

export const MultiInputEditableText = withEditButton(MultiInputEditableTextInternal);
