import * as React from 'react';
import {PropsWithChildren} from 'react';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {CircularProgressWithLabel} from '@components/progress/CircularProgressWithLabel';
import {BulkStatus} from '@models/bulk-operation';
import {EntityType} from '@redux/entity';

import {useLabel} from '../hooks';

type BulkActionSummaryTotalProgressProps = PropsWithChildren<{
    processedItems: number;
    totalItems: number;
    failedItems: number;
    type: EntityType;
}>;
export const BulkExecutionStepTotalProgress = ({
    totalItems,
    processedItems,
    failedItems,
    type,
    children,
}: BulkActionSummaryTotalProgressProps) => {
    const label = useLabel(type);
    const progress = totalItems && processedItems ? (processedItems / totalItems) * 100 : 0;
    const bulkStatus = failedItems > 0 ? BulkStatus.Warning : BulkStatus.Success;

    return !totalItems || processedItems !== totalItems ? (
        <CircularProgressWithLabel
            data-testid="bulkExecutionSummaryStepCircularProgress"
            value={progress}
            totalItemCount={totalItems}
            processedItemLabel={label}
        />
    ) : (
        <>
            <MemoizedChip value={bulkStatus} chipName={nameof<BulkStatus>()} />
            {children}
        </>
    );
};
