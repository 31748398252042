import {Span} from '@opentelemetry/api';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {
    applyHttpRequestAttrs,
    applyHttpResponseAttrs,
    applyLocationAttrs,
    applyUserAttrs,
    ITracingService,
    mergeMap,
    TraceUser,
} from '@otel';

export type FetchFunction = (url: string, request: RequestInit, user: TraceUser, parentSpan?: Span) => Observable<unknown>;

export function fetchWithTracing(tracingService: ITracingService): FetchFunction {
    return function fetchFunction(url: string, request: RequestInit, user: TraceUser, parentSpan?: Span): Observable<unknown> {
        const method = request?.method ?? 'GET';

        const tracer = tracingService.getTracer();
        const ctx = tracingService.setSpanOnContext(parentSpan);

        return tracer.startActiveSpan(`REST HTTP ${method}`, undefined, ctx, span => {
            applyLocationAttrs(span);
            applyUserAttrs(span, user);
            applyHttpRequestAttrs(span, url, method, 'fetch');

            return from(fetch(url, request)).pipe(
                mergeMap(r => {
                    return from(r.json()).pipe(
                        mergeMap(responseBody => {
                            let result: Observable<Response | never>;
                            if (!r.ok) {
                                const error = new FailedFetchError(r.status, responseBody?.traceId, r.statusText);
                                result = throwError(() => error);
                            } else {
                                applyHttpResponseAttrs(span, r.status?.toString());
                                tracingService.endSpanOk(span);
                                result = of(responseBody);
                            }

                            return result;
                        })
                    );
                }),
                catchError((e: FailedFetchError) => {
                    applyHttpResponseAttrs(span, e.status?.toString(), e.traceId, e.message);
                    tracingService.endSpanFailed(span, e.message);
                    return throwError(() => e);
                })
            );
        });
    };
}

class FailedFetchError extends Error {
    private readonly _status: number;
    private readonly _traceId?: string;
    private readonly _message?: string;

    constructor(status: number, traceId: string, message: string) {
        super();
        this._status = status;
        this._traceId = traceId;
        this._message = message;
    }

    get status() {
        return this._status;
    }

    get traceId() {
        return this._traceId;
    }

    get message() {
        return this._message;
    }
}
