import {BonusCodeDetails} from '@models/generated/graphql';
import {RootEpic} from '@redux';

import {requestWithConfirmEpic} from 'src/common/epics';
import {protectEpics} from '../app/error-handling/epics';
import {createSingleItemReadEpics} from '../module-shared/epics';
import {createEntityRealtimeEpics} from '../realtime-updates/epics';

import {beBonusActions} from './actions';
import {columnsSelector, filterSelector, realtimeSubscribersSelector, subscribedTypesSelector} from './selectors';
import {domain} from './types';

const beBonusAddEpic: RootEpic = requestWithConfirmEpic(beBonusActions.addBonusCode, (payload, _, container) => {
    return container.beBonusService.addItem(payload);
});

export const beBonusEpics = protectEpics(
    createSingleItemReadEpics(domain, c => c.beBonusService, filterSelector, columnsSelector, false),
    createEntityRealtimeEpics<BonusCodeDetails>(
        domain,
        container => container.beBonusRealtimeService,
        realtimeSubscribersSelector,
        subscribedTypesSelector
    ),
    beBonusAddEpic
);
