import {defineMessages} from 'react-intl';

import {CustomIcon} from '@components/icons/types';
import {ModuleName, SubmoduleName} from '@models/modules';

import {agentLevelReportResource} from 'src/pages/AgentLevelReport';
import {ndrpProcessingResource} from 'src/pages/kyc-processing/components/NDRPProcessing';
import {sportsbookTransactionResourse} from 'src/pages/SportsbookTransactionHistory';
import {RouteUrl} from '../../common/routeEnums';
import {Route} from '../../common/types';
import {accessManagementResource} from '../../pages/access-management/components/AccessManagement';
import {agentKrDailyReportResource} from '../../pages/AgentKrDailyReport';
import {agentManagementPathStatisticsResource} from '../../pages/AgentManagementPathStatistics';
import {agentManagementTransferReportResource} from '../../pages/AgentManagementTransferReport';
import {agentVnDailyReportResource} from '../../pages/AgentVnDailyReport';
import {agentVnWeeklyReportResource} from '../../pages/AgentVnWeeklyReport';
import {agentManagementAccessControlled, agentManagementPageLocalized} from '../../pages/asia/AgentManagementPage';
import {bonusEngineResource} from '../../pages/BonusEngine';
import {customerSupportWorkspaceResource} from '../../pages/CustomerSupportWorkspace';
import {embeddedReportResource} from '../../pages/EmbeddedReports';
import {gameAdminPLO5Resource} from '../../pages/GameAdminPLO5';
import {kycProcessingResource} from '../../pages/kyc-processing/components/KYCProcessing';
import {privacyPolicyResource} from '../../pages/legal-docs/components/PrivacyPolicy';
import {termsAndConditionsResource} from '../../pages/legal-docs/components/TermsAndConditions';
import {versionAcceptanceResource} from '../../pages/legal-docs/components/VersionAcceptance';
import {loginHistoryPageResource} from '../../pages/LoginHistory';
import {marketingBonusCodeResource} from '../../pages/marketing-bonus-code/components/MarketingBonusCode';
import {p2pReferralOfferingAdminResource} from '../../pages/P2PReferralOfferingAdmin';
import {playerLevelReportingResource} from '../../pages/PlayerLevelReport';
import {registrationInfoResource} from '../../pages/registration-info/components/RegistrationInfo';
import {rolesManagementResource} from '../../pages/role-management/components/RolesManagement';
import {rulesResource} from '../../pages/Rules';
import {securityWorkspaceResource} from '../../pages/SecurityWorkspace';
import {teamWorkspaceResource} from '../../pages/TeamWorkspace';
import {transactionHistoryResource} from '../../pages/transaction-history/components/TransactionHistory';
import {withdrawalProcessingResource} from '../../pages/WithdrawalProcessing';

const localizedRoutes = defineMessages({
    accessManagement: {
        id: 'routes_AccessManagement',
        defaultMessage: 'Login/Access Management',
    },
    playerManagement: {
        id: 'routes_PlayerManagement',
        defaultMessage: 'Player Management',
    },
    legalContentManagement: {
        id: 'routes_LegalContentManagement',
        defaultMessage: 'Legal Content Management',
    },
    withdrawalProcessing: {
        id: 'routes_WithdrawalProcessing',
        defaultMessage: 'Withdrawal Processing',
    },
    kycProcessing: {
        id: 'routes_KycProcessing',
        defaultMessage: 'KYC Processing',
    },
    securityWorkspace: {
        id: 'routes_SecurityWorkspace',
        defaultMessage: 'Security Workspace',
    },
    customerSupport: {
        id: 'routes_CustomerSupport',
        defaultMessage: 'Customer Support',
    },
    marketingFunction: {
        id: 'routes_MarketingFunction',
        defaultMessage: 'Marketing Function',
    },
    pageAgentReport: {
        id: 'routes_AgentReport',
        defaultMessage: 'Agent Report',
    },
    pageGameHistory: {
        id: 'routes_GameHistory',
        defaultMessage: 'Game History',
    },
    users: {
        id: 'routes_Users',
        defaultMessage: 'Users',
    },
    roles: {
        id: 'routes_Roles',
        defaultMessage: 'Roles',
    },
    registrationInformation: {
        id: 'routes_RegistrationInformation',
        defaultMessage: 'Registration Information',
    },
    loginHistory: {
        id: 'routes_LoginHistory',
        defaultMessage: 'Login History',
    },
    responsibleGamingLimits: {
        id: 'routes_ResponsibleGamingLimits',
        defaultMessage: 'Responsible Gaming Limits',
    },
    kyc: {
        id: 'routes_KYC',
        defaultMessage: 'KYC',
    },
    transactionHistory: {
        id: 'routes_TransactionHistory',
        defaultMessage: 'Transaction History',
    },
    privacyPolicy: {
        id: 'routes_PrivacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
    termsAndConditions: {
        id: 'routes_TermsAndConditions',
        defaultMessage: 'Terms & Conditions',
    },
    userAcceptanceVersion: {
        id: 'routes_UserAcceptanceVersion',
        defaultMessage: 'User Acceptance Version',
    },
    bonusEngine: {
        id: 'routes_BonusEngine',
        defaultMessage: 'Bonus Engine',
    },
    marketingBonusCode: {
        id: 'routes_MarketingBonusCode',
        defaultMessage: 'Marketing Bonus Codes',
    },
    p2pReferralOfferingAdmin: {
        id: 'routes_P2PReferralOfferingAdmin',
        defaultMessage: 'P2P Referral Offering Admin',
    },
    ggrReport: {
        id: 'routes_agentReport',
        defaultMessage: 'Player Level Report',
    },
    transferReport: {
        id: 'routes_TransferReport',
        defaultMessage: 'Transfer',
    },
    transactionReport: {
        id: 'routes_TransactionReport',
        defaultMessage: 'Transaction Report',
    },
    pageAgentReportVn: {
        id: 'routes_AgentReportVn',
        defaultMessage: 'VN Agent Report',
    },
    pageAgentReportKr: {
        id: 'routes_AgentReportKr',
        defaultMessage: 'KR Agent Report',
    },
    pageAgentReportKrDaily: {
        id: 'routes_AgentReportKrDaily',
        defaultMessage: 'KR Agent Report Daily',
    },
    agentPlayerReportVnDaily: {
        id: 'routes_AgentPlayerReportVnDaily',
        defaultMessage: 'VN Agent Player Report Daily',
    },
    agentPlayerReportVnWeekly: {
        id: 'routes_AgentPlayerReportVnWeekly',
        defaultMessage: 'VN Agent Player Report Weekly',
    },
    agentPathStatisticsVn: {
        id: 'routes_AgentPathStatisticsVn',
        defaultMessage: 'VN Path Statistics',
    },
    teamWorkspace: {
        id: 'routes_TeamWorkspace',
        defaultMessage: 'Team Workspace',
    },
    gameAdmin: {
        id: 'routes_GameAdmin',
        defaultMessage: 'Game Admin',
    },
    plo5: {
        id: 'routes_Plo5',
        defaultMessage: 'PLO5',
    },
    rules: {
        id: 'routes_Rules',
        defaultMessage: 'Rules',
    },
    reporting: {
        id: 'routes_Reporting',
        defaultMessage: 'Reporting',
    },
    agentLevelReport: {
        id: 'routes_AgentLevelReport',
        defaultMessage: 'Agent Level Report',
    },
    ndrpProcessing: {
        id: 'routes_NDRPProcessing',
        defaultMessage: 'NDRP Processing',
    },
    pageSportsbookOperation: {
        id: 'routes_PageSportsbookOperation',
        defaultMessage: 'Sportsbook Operation',
    },
    sportsbookTransactionHistory: {
        id: 'routes_SportsbookTransactionHistory',
        defaultMessage: 'Sportsbook Transaction History',
    },
    embeddedReport: {
        id: 'routes_embeddedReport',
        defaultMessage: 'Embedded Agent Report',
    },
});

const routes: readonly Route[] = Object.freeze<Route[]>([
    {
        name: ModuleName.AccessManagement,
        url: RouteUrl.AccessManagement,
        title: localizedRoutes.accessManagement,
        menuIcon: CustomIcon.Access,
        subRoutes: [
            {
                name: SubmoduleName.Roles,
                url: RouteUrl.Roles,
                title: localizedRoutes.roles,
                resource: rolesManagementResource,
            },
            {
                name: SubmoduleName.Users,
                url: RouteUrl.Users,
                title: localizedRoutes.users,
                resource: accessManagementResource,
            },
        ],
    },
    {
        name: ModuleName.PlayerManagement,
        url: RouteUrl.PlayerManagement,
        title: localizedRoutes.playerManagement,
        menuIcon: CustomIcon.Profile,
        subRoutes: [
            {
                name: SubmoduleName.RegistrationInformation,
                url: RouteUrl.RegistrationInformation,
                title: localizedRoutes.registrationInformation,
                resource: registrationInfoResource,
            },
            {
                name: SubmoduleName.LoginHistory,
                url: RouteUrl.LoginHistory,
                title: localizedRoutes.loginHistory,
                resource: loginHistoryPageResource,
            },
            {
                name: SubmoduleName.TransactionHistory,
                url: RouteUrl.TransactionHistory,
                title: localizedRoutes.transactionHistory,
                resource: transactionHistoryResource,
            },
        ],
    },
    {
        name: ModuleName.PageAgentManagement,
        url: RouteUrl.AgentManagementPage,
        title: agentManagementPageLocalized.title,
        menuIcon: CustomIcon.Access,
        resource: agentManagementAccessControlled,
    },
    {
        name: ModuleName.LegalContentManagement,
        url: RouteUrl.LegalContentManagement,
        title: localizedRoutes.legalContentManagement,
        menuIcon: CustomIcon.FileBox,
        subRoutes: [
            {
                name: SubmoduleName.PrivacyPolicy,
                url: RouteUrl.PrivacyPolicy,
                title: localizedRoutes.privacyPolicy,
                resource: privacyPolicyResource,
            },
            {
                name: SubmoduleName.TermsAndConditions,
                url: RouteUrl.TermsAndConditions,
                title: localizedRoutes.termsAndConditions,
                resource: termsAndConditionsResource,
            },
            {
                name: SubmoduleName.UserAcceptanceVersion,
                url: RouteUrl.UserAcceptanceVersion,
                title: localizedRoutes.userAcceptanceVersion,
                resource: versionAcceptanceResource,
            },
        ],
    },
    {
        name: ModuleName.KycProcessing,
        url: RouteUrl.KycProcessing,
        title: localizedRoutes.kycProcessing,
        menuIcon: CustomIcon.Database,
        resource: kycProcessingResource,
    },
    {
        name: ModuleName.PageNdrp,
        url: RouteUrl.NDRPProcessing,
        title: localizedRoutes.ndrpProcessing,
        menuIcon: CustomIcon.Database,
        resource: ndrpProcessingResource,
    },
    {
        name: ModuleName.WithdrawalProcessing,
        url: RouteUrl.WithdrawalProcessing,
        title: localizedRoutes.withdrawalProcessing,
        menuIcon: CustomIcon.Withdrawal,
        resource: withdrawalProcessingResource,
    },
    {
        name: ModuleName.SecurityWorkspace,
        url: RouteUrl.SecurityWorkspace,
        title: localizedRoutes.securityWorkspace,
        menuIcon: CustomIcon.Database,
        resource: securityWorkspaceResource,
    },
    {
        name: ModuleName.CustomerSupport,
        url: RouteUrl.CustomerSupport,
        title: localizedRoutes.customerSupport,
        menuIcon: CustomIcon.Headphones,
        resource: customerSupportWorkspaceResource,
    },
    {
        name: ModuleName.MarketingFunction,
        url: RouteUrl.MarketingFunction,
        title: localizedRoutes.marketingFunction,
        menuIcon: CustomIcon.Withdrawal,
        subRoutes: [
            {
                name: SubmoduleName.BonusEngine,
                url: RouteUrl.BonusEngine,
                title: localizedRoutes.bonusEngine,
                resource: bonusEngineResource,
            },
            {
                name: SubmoduleName.MarketingBonusCode,
                url: RouteUrl.MarketingBonusCode,
                title: localizedRoutes.marketingBonusCode,
                resource: marketingBonusCodeResource,
            },
            {
                name: SubmoduleName.P2PReferralOfferingAdmin,
                url: RouteUrl.P2PReferralOfferingAdmin,
                title: localizedRoutes.p2pReferralOfferingAdmin,
                resource: p2pReferralOfferingAdminResource,
            },
        ],
    },
    {
        name: ModuleName.PageEmbeddedReport,
        url: RouteUrl.EmbeddedReport,
        title: localizedRoutes.embeddedReport,
        menuIcon: CustomIcon.ArticleOutline,
        subRoutes: [
            {
                name: 'EmbeddedPlayerLevelReport',
                url: RouteUrl.PlayerLevelReport,
                title: localizedRoutes.ggrReport,
                resource: embeddedReportResource,
            },
            {
                name: 'EmbeddedAgentLevelReport',
                url: RouteUrl.AgentLevelReport,
                title: localizedRoutes.agentLevelReport,
                resource: embeddedReportResource,
            },
            {
                name: 'EmbeddedTransferReport',
                url: RouteUrl.TransferReport,
                title: localizedRoutes.transactionReport,
                resource: embeddedReportResource,
            },
        ],
    },
    {
        name: ModuleName.PageAgentReport,
        url: RouteUrl.AgentManagement,
        title: localizedRoutes.pageAgentReport,
        menuIcon: CustomIcon.ArticleOutline,
        subRoutes: [
            {
                name: SubmoduleName.GGRReport,
                url: RouteUrl.PlayerLevelReport,
                title: localizedRoutes.ggrReport,
                resource: playerLevelReportingResource,
            },
            {
                name: SubmoduleName.AgentLevelReport,
                url: RouteUrl.AgentLevelReport,
                title: localizedRoutes.agentLevelReport,
                resource: agentLevelReportResource,
            },
            {
                name: SubmoduleName.TransferReport,
                url: RouteUrl.TransferReport,
                title: localizedRoutes.transferReport,
                resource: agentManagementTransferReportResource,
            },
        ],
    },
    {
        name: ModuleName.PageAgentReportVn,
        url: RouteUrl.AgentReportVn,
        title: localizedRoutes.pageAgentReportVn,
        menuIcon: CustomIcon.ArticleOutline,
        subRoutes: [
            {
                name: 'AgentPlayerReportVnDaily',
                url: RouteUrl.PlayerReportDaily,
                title: localizedRoutes.agentPlayerReportVnDaily,
                resource: agentVnDailyReportResource,
            },
            {
                name: 'AgentPlayerReportVnWeekly',
                url: RouteUrl.PlayerReportWeekly,
                title: localizedRoutes.agentPlayerReportVnWeekly,
                resource: agentVnWeeklyReportResource,
            },
            {
                name: 'AgentPathStatisticsVn',
                url: RouteUrl.PathStatisticsVn,
                title: localizedRoutes.agentPathStatisticsVn,
                resource: agentManagementPathStatisticsResource,
            },
        ],
    },
    {
        name: ModuleName.PageAgentReportKr,
        url: RouteUrl.AgentReportKr,
        title: localizedRoutes.pageAgentReportKr,
        menuIcon: CustomIcon.ArticleOutline,
        subRoutes: [
            {
                name: 'AgentPlayerReportKrDaily',
                url: RouteUrl.PlayerReportDaily,
                title: localizedRoutes.pageAgentReportKrDaily,
                resource: agentKrDailyReportResource,
            },
        ],
    },
    {
        name: ModuleName.TeamWorkspace,
        url: RouteUrl.TeamWorkspace,
        title: localizedRoutes.teamWorkspace,
        menuIcon: CustomIcon.Withdrawal,
        resource: teamWorkspaceResource,
    },
    {
        name: ModuleName.GameAdmin,
        url: RouteUrl.GameAdmin,
        title: localizedRoutes.gameAdmin,
        menuIcon: CustomIcon.ServicesOutline,
        subRoutes: [
            {
                name: SubmoduleName.PLO5,
                url: RouteUrl.PLO5,
                title: localizedRoutes.plo5,
                resource: gameAdminPLO5Resource,
            },
        ],
    },
    {
        name: ModuleName.PageRules,
        url: RouteUrl.Rules,
        title: localizedRoutes.rules,
        menuIcon: CustomIcon.ArticleOutline,
        resource: rulesResource,
    },
    {
        name: ModuleName.PageSportsbookOperation,
        url: RouteUrl.SportsbookOperation,
        title: localizedRoutes.pageSportsbookOperation,
        menuIcon: CustomIcon.ServicesOutline,
        subRoutes: [
            {
                name: SubmoduleName.SportsbookTransactionHistory,
                url: RouteUrl.TransactionHistory,
                title: localizedRoutes.sportsbookTransactionHistory,
                resource: sportsbookTransactionResourse,
            },
        ],
    },
]);

const getFullRouteUrl = (routes: readonly Route[], routeNames: string[], baseRoute = ''): string => {
    const name = routeNames.shift();
    const route: Route = routes.find(r => r.name === name);
    const routeUrl = `${baseRoute}${route?.url ?? ''}`;
    return route?.subRoutes !== undefined ? getFullRouteUrl(route.subRoutes, routeNames, routeUrl) : routeUrl;
};

export const getRoutesFromPath = (routes: readonly Route[], path: string, routesFound: Route[] = []): Route[] => {
    if (path.charAt(0) === '/') path = path.slice(1);

    const paths = path.split('/');
    const route: Route = routes.find(r => r.url === `/${paths[0]}`) ?? ({} as Route);
    const {subRoutes, ...routeClone} = route;

    routesFound.push(routeClone);

    return route?.subRoutes !== undefined ? getRoutesFromPath(route.subRoutes, paths.slice(1).join('/'), routesFound) : routesFound;
};

export const getRouteUrl = (routes: readonly Route[], ...routeNames: string[]): string => {
    return getFullRouteUrl(routes, routeNames);
};

export const concatUrl = (...routeUrls: RouteUrl[]): string => {
    return routeUrls.join('');
};

export default routes;
