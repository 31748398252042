import React, {useContext} from 'react';
import {defineMessages, IntlContext, MessageDescriptor} from 'react-intl';
import {Typography} from '@mui/material';

const localized = defineMessages({
    formHeaderAdd: {
        id: 'formHeaderAdd',
        defaultMessage: 'Add new {headerItemName}',
    },
    formHeaderEdit: {
        id: 'formHeaderEdit',
        defaultMessage: 'Edit {headerItemName}',
    },
});

type FormHeaderProps = {
    isNewValue: boolean;
    headerItemName: MessageDescriptor;
};

const FormHeader = ({isNewValue, headerItemName}: FormHeaderProps) => {
    const {formatMessage} = useContext(IntlContext);

    const getHeader = () => {
        return formatMessage(isNewValue ? localized.formHeaderAdd : localized.formHeaderEdit, {
            headerItemName: formatMessage(headerItemName),
        });
    };

    return headerItemName ? <Typography variant="h6">{getHeader()}</Typography> : null;
};

export default FormHeader;
