import {inject, injectable} from 'inversify';
import {map, mergeMap, Observable} from 'rxjs';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {SportsbookTransactionPage} from '@models/sportsbook-transaction/type';
import {ServiceResponsePayload} from '@services/types';
import {IBrandService} from '@brand';

import {ApiRoutesService} from './apiRoutesService';
import {GetRecordRequestPayload, ISportsbookApiService} from './ISportsbookApiService';
import {RestService} from './restService';

@injectable()
export class SportsbookApiService implements ISportsbookApiService {
    private readonly _restService: RestService;
    private readonly _apiRoutesService: ApiRoutesService;
    private readonly _brandService: IBrandService;

    constructor(
        @inject(ServiceTypes.RestService) restService: RestService,
        @inject(ServiceTypes.ApiRoutesService) apiRoutesService: ApiRoutesService,
        @inject(ServiceTypes.BrandService) brandService: IBrandService
    ) {
        this._restService = restService;
        this._apiRoutesService = apiRoutesService;
        this._brandService = brandService;
    }

    getRecord(
        requestPayload: GetRecordRequestPayload
    ): Observable<ServiceResponsePayload<GetRecordRequestPayload, SportsbookTransactionPage>> {
        const endpoint = this._apiRoutesService.getSportsbookGetRecordEndpoint();

        return this._brandService.getBrands().pipe<ServiceResponsePayload<GetRecordRequestPayload, SportsbookTransactionPage>>(
            mergeMap(brandConfig => {
                const licenseType =
                    brandConfig.licenseTypes?.map(type => (type === null || type === undefined ? 'null' : type?.toString())) ?? [];
                const payload: GetRecordRequestPayload = {
                    ...requestPayload,
                    licenseType,
                };

                return this._restService.post({endpoint, body: payload}, null, false).pipe(
                    map(response => ({
                        ...response,
                        requestPayload: payload,
                        responsePayload: {
                            items: response?.responsePayload?.response?.records ?? [],
                            total: response?.responsePayload?.response?.total ?? 0,
                        },
                    }))
                );
            })
        );
    }
}
