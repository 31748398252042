import React from 'react';
import {Popper, PopperProps} from '@mui/material';

import {useCustomTheme} from '@style';

export const AutocompletePopper = ({style, ...props}: PopperProps) => {
    const {width, ...otherStyles} = style;
    const theme = useCustomTheme();
    let boxWithBorderWidth: string | number = 0;

    try {
        boxWithBorderWidth = Number(width) + theme.custom.borderWidth * 2;
    } catch (e) {
        boxWithBorderWidth = width;
    }

    return (
        <Popper
            placement="bottom"
            {...props}
            style={{...otherStyles, height: 0, width: boxWithBorderWidth}}
            popperOptions={{
                modifiers: [
                    {
                        offset: {
                            offset: '0,0',
                        },
                    },
                ],
            }}
        />
    );
};
