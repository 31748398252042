import React from 'react';
import {Box, Typography} from '@mui/material';

type WrapperProps = {
    label?: string;
    children?: React.ReactNode;
};

export const Wrapper = ({label, children}: WrapperProps) => {
    return (
        <>
            <Box component="div" marginX={10} marginY={5}>
                <Typography variant="h5" color="primary">
                    {label}
                </Typography>
                <Box component="span" m={5}>
                    {children}
                </Box>
            </Box>
        </>
    );
};

export const WrapperChild = ({label, children}: WrapperProps) => {
    return (
        <>
            {label ? (
                <Typography variant="subtitle2" color="primary">
                    {label}
                </Typography>
            ) : null}
            <Box component="span" m={5}>
                {children}
            </Box>
        </>
    );
};
