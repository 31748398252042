import React from 'react';
import {defineMessages} from 'react-intl';

import {LayoutDetailsBlock} from '@components/layout/LayoutDetailsBlock';
import {isStringNullOrEmpty} from '@utils/string';

import {DataGridPlayerReferralColumns, DataGridPlayerReferralsServer} from 'src/features/block-player-referral-list/index';
import {useUserProfileDetails} from 'src/features/block-user-profile-details';
import {useRefereeRevenueInfoData} from '../../block-player-referral-details/hooks';

type UserRefereeRevenueInfoProps = {
    userId: string;
};

const localized = defineMessages({
    refereeRevenueInfoTitle: {
        id: 'UserRefereeRevenueInfo_userRefereeRevenueInfo',
        defaultMessage: 'Referee Revenue Generation',
    },
    emptyList: {
        id: 'UserRefereeRevenueInfo_emptyList',
        defaultMessage: 'Referee list is empty',
    },
});

export const UserRefereeRevenueInfo = ({userId}: UserRefereeRevenueInfoProps) => {
    const columns: DataGridPlayerReferralColumns[] = [
        'referee.uid',
        'revenue_share',
        'revenue_generated_total',
        'revenue_share_offering_status',
        'expiration_date',
    ];
    const {items, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useRefereeRevenueInfoData(
        'UserRefereeRevenueInfo',
        localized.refereeRevenueInfoTitle,
        userId
    );
    const {item: user} = useUserProfileDetails({
        id: userId,
        viewType: 'UserRefereeRevenueInfo',
        fields: ['uid', 'agent_info.bo_agent_id'],
    });
    const isAgent = !isStringNullOrEmpty(user?.agent_info?.bo_agent_id);

    return !isAgent ? (
        <LayoutDetailsBlock
            title={localized.refereeRevenueInfoTitle}
            content={
                <DataGridPlayerReferralsServer
                    autoHeight
                    columns={columns}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                    emptyMessage={localized.emptyList}
                />
            }
        ></LayoutDetailsBlock>
    ) : null;
};
