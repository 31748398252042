import {MarketingCode} from '@models/generated/graphql';
import {RootEpic} from '@redux';

import {requestWithConfirmEpic} from 'src/common/epics';
import {protectEpics} from '../app/error-handling/epics';
import {createEntityRealtimeEpics} from '../realtime-updates/epics';

import {marketingCodeActions} from './actions';
import {realtimeSubscribersSelector, subscribedTypesSelector} from './selectors';
import {domain} from './types';

const marketingCodeAddEpic: RootEpic = requestWithConfirmEpic(marketingCodeActions.addMarketingCode, (payload, _, container) => {
    return container.marketingCodeService.addItem(payload);
});

const marketingCodesDeleteEpic: RootEpic = requestWithConfirmEpic(marketingCodeActions.deleteMarketingCodes, (payload, _, container) => {
    return container.marketingCodeService.deleteItems(payload);
});

export const marketingCodeEpics = protectEpics(
    createEntityRealtimeEpics<MarketingCode>(
        domain,
        container => container.marketingCodeRealtimeService,
        realtimeSubscribersSelector,
        subscribedTypesSelector
    ),
    marketingCodeAddEpic,
    marketingCodesDeleteEpic
);
