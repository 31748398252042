import {useAutoMapper} from '@auto-mapper';
import {UserProfile} from '@models/generated/graphql';
import {UserProfileColumnSettings, UserProfileColumnSettingsKeys, UserProfileViewModelKeys} from '@models/user-profile';
import {useAuthUser} from '@auth';
import {useColumnsSettings} from '@user-settings';
import {EntityType, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {Filter, Sorting} from '../../common/types';
import {useJurisdictionConfig} from '../app/config/hooks';

import {UserProfileGridItem} from './types';

export function useUserProfiles({
    realtimeMode,
    triggers,
    ids,
    fields,
    viewType,
    displayName,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    syncWithUrl,
    validateFilter,
    cleanDelay,
}: UseListViewEntityProps<UserProfileServerFilterKeys, UserProfileViewModelKeys>): UseListViewEntityResult<
    UserProfileGridItem,
    UserProfileViewModelKeys
> {
    const mapper = useAutoMapper();

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<UserProfile, UserProfileServerFilterKeys, UserProfileQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.UserProfile,
            fields: mapFields(fields),
        },
        realtime: realtimeMode && triggers ? {entity: EntityType.UserProfile, mode: realtimeMode, triggers} : null,
        defaultSorting: mapSorting(defaultSorting),
        defaultFilters: getDefaultFilters(),
        defaultPaging: ids?.length > 0 ? {page: 1, pageSize: getUniqueIds().length} : defaultPaging,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const {currency} = useJurisdictionConfig();

    //TODO: [BO-2915] Refactor id column logic for data grids
    const userItems: UserProfileGridItem[] = items?.map(i => ({...i, id: i.uid, currency}));

    return {
        items: userItems,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };

    function getDefaultFilters(): Filter<string, UserProfileServerFilterKeys>[] {
        const filters: Filter<string, UserProfileServerFilterKeys>[] = defaultFilters ? [...defaultFilters] : [];
        if (ids?.length > 0) {
            const uniqueUids: string[] = getUniqueIds();
            const uidFilter: string = uniqueUids.join('|');
            filters.push({key: 'uid', value: uidFilter});
        }
        return filters;
    }

    function getUniqueIds(): string[] {
        return [...new Set(ids)];
    }

    function mapFields(fields: UserProfileViewModelKeys[]): UserProfileQueryFields[] {
        return mapper.map(fields, nameof<UserProfileViewModelKeys>(), nameof<UserProfileQueryFields>());
    }

    function mapField(field: UserProfileViewModelKeys): UserProfileQueryFields {
        return mapFields([field])[0];
    }

    function mapSorting(sorting: Sorting<UserProfileViewModelKeys>[]): Sorting<UserProfileQueryFields>[] {
        return sorting?.map(s => ({...s, field: mapField(s.field)}));
    }

    function handleVmSortChange(sorting: Sorting<UserProfileViewModelKeys>[]): void {
        handleSortChange(mapSorting(sorting));
    }
}

type UseUserProfileSettingsPorps = {
    settingId: string;
    defaultColumns: UserProfileViewModelKeys[];
    pinnedColumns?: UserProfileViewModelKeys[];
};

type UseUserProfileSettingsResult = {
    visibleColumns?: UserProfileViewModelKeys[];
    onVisibleColumnsChange: (value: UserProfileViewModelKeys[]) => void;
};

export function useUserProfileSettings({
    settingId,
    defaultColumns,
    pinnedColumns = [],
}: UseUserProfileSettingsPorps): UseUserProfileSettingsResult {
    const {sub} = useAuthUser();
    const mapper = useAutoMapper();

    const {visible, onVisibleColumnsChange} = useColumnsSettings<UserProfileColumnSettings>({
        userId: sub,
        settingId,
        defaultColumns: mapViewModelKeysToSettings(defaultColumns),
        pinnedColumns: mapViewModelKeysToSettings(pinnedColumns),
    });

    function mapViewModelKeysToSettings(keys: UserProfileViewModelKeys[]): UserProfileColumnSettingsKeys[] {
        return [
            ...new Set(
                keys
                    ?.map(i =>
                        mapper.map<UserProfileViewModelKeys, UserProfileColumnSettingsKeys>(
                            i,
                            nameof<UserProfileViewModelKeys>(),
                            nameof<UserProfileColumnSettingsKeys>()
                        )
                    )
                    ?.filter(i => i) ?? []
            ),
        ];
    }

    function mapSettingsToViewModelKeys(settings: UserProfileColumnSettingsKeys[]): UserProfileViewModelKeys[] {
        return [
            ...new Set(
                settings?.flatMap(i =>
                    mapper.map<UserProfileColumnSettingsKeys, UserProfileViewModelKeys[]>(
                        i,
                        nameof<UserProfileColumnSettingsKeys>(),
                        nameof<UserProfileViewModelKeys>()
                    )
                ) ?? []
            ),
        ];
    }

    function handleColumnSettingsChange(fields: UserProfileViewModelKeys[]) {
        const columns = mapViewModelKeysToSettings(fields);
        onVisibleColumnsChange(columns);
    }

    return {
        // filter is workaround for storing multiple keys in single setting key
        visibleColumns: mapSettingsToViewModelKeys(visible).filter(f => defaultColumns.includes(f)),
        onVisibleColumnsChange: handleColumnSettingsChange,
    };
}
