import React, {createContext, useContext, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import {IFrame} from '@components/IFrame';
import {EmbeddedDashboardType} from '@models/generated/graphql';

export type EmbeddedIFrames = 'PlayerLevelReport' | 'AgentLevelReport' | 'TransferReport';

type EmbeddedIFramesContext = {
    setActiveFrame: (type: EmbeddedIFrames) => void;
    setFrameSrc: (type: EmbeddedIFrames, src: string) => void;
};

const EmbeddedIFramesContext = createContext<EmbeddedIFramesContext>({setActiveFrame: null, setFrameSrc: null});

const useStyles = makeStyles()(theme => ({
    embeddedIFrames: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: `-${theme.zIndex.tooltip}`,
    },
    embeddedIFramesContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        overflowY: 'hidden',
    },
    embeddedIFramesContainerHide: {
        pointerEvents: 'none',
        opacity: 0,
    },
    embeddedIFramesActive: {
        zIndex: theme.zIndex.fab,
    },
    embeddedIFramesIFrame: {
        width: '100%',
        height: '100%',
    },
    embeddedIFramesDefaultBackground: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
    },
}));

type EmbeddedIFramesProviderProps = {
    children: React.ReactNode;
};

export function EmbeddedIFramesProvider({children}: EmbeddedIFramesProviderProps) {
    const {classes, cx} = useStyles();
    const [activeFrame, setActiveFrameType] = useState<EmbeddedIFrames>(null);
    const [iFramesData, setIFramesData] = useState<Record<EmbeddedIFrames, string>>({
        PlayerLevelReport: null,
        AgentLevelReport: null,
        TransferReport: null,
    });

    function setActiveFrame(type: EmbeddedIFrames) {
        setActiveFrameType(type);
    }

    function setFrameSrc(type: EmbeddedIFrames, src: string) {
        setIFramesData(prevState => ({...prevState, [type]: src}));
    }

    return (
        <EmbeddedIFramesContext.Provider value={{setActiveFrame, setFrameSrc}}>
            <div className={cx(classes.embeddedIFramesContainer, activeFrame === null && classes.embeddedIFramesContainerHide)}>
                <div
                    key={EmbeddedDashboardType.GlobalPlayerLevelReport}
                    className={cx(classes.embeddedIFrames, activeFrame === 'PlayerLevelReport' && classes.embeddedIFramesActive)}
                >
                    {iFramesData.PlayerLevelReport ? (
                        <IFrame
                            key={EmbeddedDashboardType.GlobalPlayerLevelReport}
                            src={iFramesData.PlayerLevelReport}
                            className={classes.embeddedIFramesIFrame}
                        />
                    ) : (
                        <div className={classes.embeddedIFramesDefaultBackground}></div>
                    )}
                </div>
                <div
                    key={EmbeddedDashboardType.GlobalAgentLevelReport}
                    className={cx(classes.embeddedIFrames, activeFrame === 'AgentLevelReport' && classes.embeddedIFramesActive)}
                >
                    {iFramesData.AgentLevelReport ? (
                        <IFrame
                            key={EmbeddedDashboardType.GlobalAgentLevelReport}
                            src={iFramesData.AgentLevelReport}
                            className={classes.embeddedIFramesIFrame}
                        />
                    ) : (
                        <div className={classes.embeddedIFramesDefaultBackground}></div>
                    )}
                </div>
                <div
                    key={EmbeddedDashboardType.GlobalTransferReport}
                    className={cx(classes.embeddedIFrames, activeFrame === 'TransferReport' && classes.embeddedIFramesActive)}
                >
                    {iFramesData.TransferReport ? (
                        <IFrame
                            key={EmbeddedDashboardType.GlobalTransferReport}
                            src={iFramesData.TransferReport}
                            className={classes.embeddedIFramesIFrame}
                        />
                    ) : (
                        <div className={classes.embeddedIFramesDefaultBackground}></div>
                    )}
                </div>
            </div>
            {children}
        </EmbeddedIFramesContext.Provider>
    );
}

export function useEmbeddedIFrames(): EmbeddedIFramesContext {
    return useContext(EmbeddedIFramesContext);
}
