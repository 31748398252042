import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {defineAccessControlledResource, PoliciesAccessRule, withPolicyAccessCheck} from '@access-control';
import {OutlinedButton} from '@components/button/Buttons';
import {EnrichedButtonItem} from '@components/button/EnrichedButton';
import {EnrichedButtonGroup} from '@components/button/EnrichedButtonGroup';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons/types';
import {ModalContext} from '@components/modal';
import {ModuleName} from '@models/modules';
import {Policy} from '@auth';
import {CustomTheme} from '@style';

import {BonusEngineCashAfterRakeForm} from './BonusEngineCashAfterRakeForm';
import {BonusEngineCashBonusForm} from './BonusEngineCashBonusForm';
import {BonusEngineMTTTicketForm} from './BonusEngineMTTTicketForm';

const localizedBonusEngineAddBonusCode = defineMessages({
    addBonusCode: {
        id: 'BEABC_addBonusCode',
        defaultMessage: 'Add Bonus Code',
    },
    addBonusCodeTemplateMTTTicketTitle: {
        id: 'BEABC_addBonusCodeTemplateMTTTicketTitle',
        defaultMessage: 'MTT Ticket',
    },
    addBonusCodeTemplateDepositCashMatchTitle: {
        id: 'BEABC_addBonusCodeTemplateDepositCashMatchTitle',
        defaultMessage: 'Deposit Cash Match',
    },
    addBonusCodeTemplateImmediatelyCashBonusTitle: {
        id: 'BEABC_addBonusCodeTemplateImmediatelyCashBonusTitle',
        defaultMessage: 'Immediately Cash Bonus',
    },
    addBonusCodeTemplateMTTTicketDescription: {
        id: 'BEABC_addBonusCodeTemplateMTTTicketDescription',
        defaultMessage: 'Send MTT ticket immediately when triggered',
    },
    addBonusCodeTemplateDepositCashMatchDescription: {
        id: 'BEABC_addBonusCodeTemplateDepositCashMatchDescription',
        defaultMessage: 'Send Cash after rake is generated when deposit is made',
    },
    addBonusCodeTemplateImmediatelyCashBonusDescription: {
        id: 'BEABC_addBonusCodeTemplateImmediatelyCashBonusDescription',
        defaultMessage: 'Send fix cash bonus immediately when triggered',
    },
    confirm: {
        id: 'BEABC_confirm',
        defaultMessage: 'Confirm',
    },
    close: {
        id: 'BEABC_close',
        defaultMessage: 'Close',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    bonusEngineAddBonusCodeContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    bonusEngineAddBonusCodeTitleContainer: {
        display: 'flex',
    },
    bonusEngineAddBonusCodeTitleSeparator: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    bonusEngineAddBonusCodeDescriptionContainer: {
        marginBottom: theme.spacing(4),
    },
}));

export enum AddBonusCodeTemplates {
    MTTTicket = 'MTTTicket',
    CashAfterRake = 'CashAfterRake',
    CashBonus = 'CashBonus',
}
export const bonusEngineAddBonusCodeTemplates: EnrichedButtonItem[] = [
    {
        id: AddBonusCodeTemplates.MTTTicket,
        title: localizedBonusEngineAddBonusCode.addBonusCodeTemplateMTTTicketTitle,
        description: localizedBonusEngineAddBonusCode.addBonusCodeTemplateMTTTicketDescription,
        icon: CustomIcon.TicketOutline,
        selected: false,
        component: BonusEngineMTTTicketForm,
        dataTestid: 'enrichedButtonItemMTTTicket',
    },
    {
        id: AddBonusCodeTemplates.CashAfterRake,
        title: localizedBonusEngineAddBonusCode.addBonusCodeTemplateDepositCashMatchTitle,
        description: localizedBonusEngineAddBonusCode.addBonusCodeTemplateDepositCashMatchDescription,
        icon: CustomIcon.NarrativeOutline,
        selected: false,
        component: BonusEngineCashAfterRakeForm,
        dataTestid: 'enrichedButtonItemCashAfterRake',
    },
    {
        id: AddBonusCodeTemplates.CashBonus,
        title: localizedBonusEngineAddBonusCode.addBonusCodeTemplateImmediatelyCashBonusTitle,
        description: localizedBonusEngineAddBonusCode.addBonusCodeTemplateImmediatelyCashBonusDescription,
        icon: CustomIcon.Withdrawal,
        selected: false,
        component: BonusEngineCashBonusForm,
        dataTestid: 'enrichedButtonItemCashBonus',
    },
];

const titleSeparatorCharacter = '/';

function BonusEngineAddBonusCode() {
    const {openModal, closeModal} = useContext(ModalContext);
    const {classes} = useStyles();
    let selectedTemplate: EnrichedButtonItem = null;

    const onOpenTemplateForm = () => {
        if (selectedTemplate) {
            const {component: FormComponent, title, description} = selectedTemplate;

            openModal({
                body: <FormComponent />,
                title: (
                    <Box className={classes.bonusEngineAddBonusCodeTitleContainer}>
                        <Typography variant="h6" color="secondary">
                            <LocalizedText label={localizedBonusEngineAddBonusCode.addBonusCode} />
                            <Box className={classes.bonusEngineAddBonusCodeTitleSeparator}>{titleSeparatorCharacter}</Box>
                        </Typography>
                        <Typography variant="h6" color="textPrimary">
                            <LocalizedText label={title} />
                        </Typography>
                    </Box>
                ),
                description: (
                    <Box className={classes.bonusEngineAddBonusCodeDescriptionContainer}>
                        <Typography variant="subtitle1" color="secondary">
                            <LocalizedText label={description} />
                        </Typography>
                    </Box>
                ),
            });
        }
    };

    const onAddBonusCodeClicked = () => {
        openModal({
            body: (
                <Box className={classes.bonusEngineAddBonusCodeContainer}>
                    <EnrichedButtonGroup items={bonusEngineAddBonusCodeTemplates} onClick={item => (selectedTemplate = item)} />
                </Box>
            ),
            title: <LocalizedText label={localizedBonusEngineAddBonusCode.addBonusCode} />,
            buttons: [
                {
                    id: 'confirm',
                    label: localizedBonusEngineAddBonusCode.confirm,
                    isPrimary: true,
                    onClick: () => onOpenTemplateForm(),
                },
                {
                    id: 'close',
                    label: localizedBonusEngineAddBonusCode.close,
                    onClick: () => closeModal(),
                },
            ],
        });
    };

    return (
        <OutlinedButton
            data-testid="addBonusCodeButton"
            label={localizedBonusEngineAddBonusCode.addBonusCode}
            startIcon={<Box component="span" className={CustomIcon.Add} />}
            onClick={onAddBonusCodeClicked}
        />
    );
}

const addBonusCodeButtonResources = defineAccessControlledResource({
    name: 'BonusEngine: Add bonus code button',
    rules: new PoliciesAccessRule([new Policy(ModuleName.MarketingFunction, null, 'create')]),
});

export default withPolicyAccessCheck(BonusEngineAddBonusCode, addBonusCodeButtonResources);

export const renderBonusEngineAddBonusCode = () => {
    return <BonusEngineAddBonusCode />;
};
