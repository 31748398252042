import {RootState} from 'typesafe-actions';

import {ItemsPage} from 'src/common/types';
import {PlayerMessageGridItem} from '../../player-message/types';

export const itemPageSelector = (state: RootState) => state.userNotificationHistory.data.itemsPage as ItemsPage<PlayerMessageGridItem>;

export const filterSelector = (state: RootState) => state.userNotificationHistory.state.filter;

export const columnsSelector = (state: RootState) => state.userNotificationHistory.state.columns;
