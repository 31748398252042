import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {BoUser, BoUserServerFilterKeys, BoUserServerTextFilterKeys, boUserServerTextFilterKeys} from '@models/bo-user';
import {UserProfile} from '@models/generated/graphql';
import {map, mergeMap} from '@otel';
import {
    EntityFetchRequestPayload,
    EntityFetchServiceResponsePayload,
    EntityType,
    UserProfileQueryFields,
    UserProfileServerTextFilterKeys,
} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {
    GetUsersRequestPayload,
    GetUsersTextFilter,
    GetUsersTextFilterKey,
    IUserManagementApiService,
} from '@services/rest-api/IUserManagementApiService';
import {IUserProfileService} from '@services/userProfileService';
import {getValueFromQueryFilter} from '@utils/query';

@injectable()
export class BoUserService implements IEntityReadService {
    private readonly _userManagementApiService: IUserManagementApiService;
    private readonly _userProfileService: IUserProfileService;

    constructor(
        @inject(ServiceTypes.UserManagementApiService) userManagementApiService: IUserManagementApiService,
        @inject(ServiceTypes.UserProfileService) userProfileService: IUserProfileService
    ) {
        this._userManagementApiService = userManagementApiService;
        this._userProfileService = userProfileService;
    }

    public get(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload<BoUser>> {
        return this.hasAgentPlayerIdFilter(requestPayload.filter)
            ? this.getAgentPlayerProfiles(requestPayload.filter).pipe(
                  mergeMap((userProfiles: UserProfile[]) => this.getBoUsers(requestPayload, userProfiles))
              )
            : this.getBoUsers(requestPayload);
    }

    private hasAgentPlayerIdFilter(filter: string): boolean {
        const agentPlayerIdFilterKey: BoUserServerTextFilterKeys = 'playerId';
        const searchParams = new URLSearchParams(filter ?? '');
        return searchParams.has(agentPlayerIdFilterKey);
    }

    private getAgentPlayerProfiles(filter: string): Observable<UserProfile[]> {
        const playerIdFilterKey: UserProfileServerTextFilterKeys = 'uid';
        const playerIdFilterValue = getValueFromQueryFilter<BoUserServerTextFilterKeys, string>(filter, 'playerId');
        const userProfileRequest: EntityFetchRequestPayload<UserProfileQueryFields> = {
            type: EntityType.UserProfile,
            filter: `${playerIdFilterKey}="${playerIdFilterValue}"`,
            fields: ['uid', 'agent_info.bo_agent_id'],
        };

        return this._userProfileService.get(userProfileRequest).pipe(map(res => (res?.responsePayload?.items as UserProfile[]) ?? []));
    }

    private getBoUsers(requestPayload: EntityFetchRequestPayload, userProfiles: UserProfile[] = null) {
        const boUserIds = userProfiles?.map(i => i.agent_info?.bo_agent_id);
        const {page, size, textFilter} = this.getApiRequestPayload(requestPayload.filter);
        return this._userManagementApiService.getUserPage({page, size, textFilter, ids: boUserIds}).pipe(
            map(r => {
                const items: BoUser[] = r.responsePayload?.items?.map(boUser => {
                    const playerId = userProfiles?.find(p => p.agent_info?.bo_agent_id === boUser.id)?.uid;
                    return {...boUser, agentPlayerId: playerId};
                });
                return {...r, requestPayload, responsePayload: {items, total: r.responsePayload?.total}};
            })
        );
    }

    private getApiRequestPayload(baseFilter: string): GetUsersRequestPayload {
        const page = this.getNumberFromFilter(baseFilter, 'page');
        const size = this.getNumberFromFilter(baseFilter, 'size');
        return {
            page,
            size,
            textFilter: this.getUserManagementApiTextFilters(baseFilter),
        };
    }

    private getNumberFromFilter(filter: string, key: BoUserServerFilterKeys): number {
        const filterValue = getValueFromQueryFilter<BoUserServerFilterKeys, string>(filter, key);
        const value = filterValue ? Number(filterValue) : null;
        return isNaN(value) ? null : value;
    }

    private getUserManagementApiTextFilters(filter: string): GetUsersTextFilter[] {
        const mapFilterKey: Record<BoUserServerTextFilterKeys, GetUsersTextFilterKey> = {
            em_fn_ln: 'em_fn_ln',
            email: 'email',
            firstName: 'firstName',
            lastName: 'lastName',
            playerId: null,
        };

        return boUserServerTextFilterKeys.map(key => ({
            key: mapFilterKey[key],
            value: getValueFromQueryFilter<BoUserServerTextFilterKeys, string>(filter, key),
        }));
    }
}
