import {IModuleGridItem} from '@components/data-grid';
import {ReasonCode, Transaction, TransactionStatus} from '@models/generated/graphql';
import {isRecent} from '@utils';

import {ColumnConfiguration} from 'src/common/types';
import {IModuleItem} from '../module-shared/types';

export const domain = 'transactions';

export class TransactionItem extends Transaction implements IModuleItem {
    get id() {
        return this.uid;
    }
}

export class TransactionGridItem extends Transaction implements IModuleGridItem {
    id: string;

    serverId: string;

    get isNew() {
        return this.status_log && this.status_log.length ? isRecent(this.status_log[0].logged_at, 1) : false;
    }
}

export type EditWithdrawalStatusModel = {
    transactionId: string;
    status: TransactionStatus;
    reason?: ReasonCode;
    reasonText?: string;
    //TODO: [IGP-2481] Remove after fix for admin id in event log
    userId?: string;
    adminId?: string;
    adminName?: string;
};

export const transactionStatusOrder = new Map([
    [TransactionStatus.Succeeded, 0],
    [TransactionStatus.Failed, 1],
    [TransactionStatus.Rejected, 2],
    [TransactionStatus.InProgress, 3],
    [TransactionStatus.OnHoldPayment, 4],
    [TransactionStatus.RiskAuthorized, 5],
    [TransactionStatus.OnHoldRisk, 6],
    [TransactionStatus.Pending, 7],
]);

export class TransactionColumnsConfiguration extends ColumnConfiguration {
    withUid: boolean;
    withUsername: boolean;
    withTransactionId: boolean;
    withPaymentSystem: boolean;
    withCurrency: boolean;
    withAmount: boolean;
    withPreviousBalance: boolean;
    withCurrentBalance: boolean;
    withType: boolean;
    withPaymentSystemUid: boolean;
    withPaymentSystemTransactionId: boolean;
    withTransactionStartedTs: boolean;
    withStatusLog: boolean;
    withTransactionStatus: boolean;
    withReasons: boolean;

    withPaymentVendor: boolean;
    withPaymentOption: boolean;
    withPaymentMethodName: boolean;
    withPaymentMethodDescription: boolean;
    withEmail: boolean;
    withAddress: boolean;

    constructor() {
        super();
        this.withUid = false;
        this.withUsername = false;
        this.withTransactionId = false;
        this.withPaymentSystem = false;
        this.withCurrency = false;
        this.withAmount = false;
        this.withPreviousBalance = false;
        this.withCurrentBalance = false;
        this.withType = false;
        this.withPaymentSystemUid = false;
        this.withPaymentSystemTransactionId = false;
        this.withTransactionStartedTs = false;
        this.withStatusLog = false;
        this.withTransactionStatus = false;
        this.withReasons = false;

        this.withPaymentVendor = false;
        this.withPaymentOption = false;
        this.withPaymentMethodName = false;
        this.withPaymentMethodDescription = false;
        this.withEmail = false;
        this.withAddress = false;
    }
}

export const withdrawalProcessingFullSearch = 'withdrawalProcessingFullSearch';

export const defaultTransactionTypesFilterKey = 'defaultTransactionTypes';
