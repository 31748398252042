import React from 'react';
import {GridEditCellValueParams, GridRenderCellParams} from '@mui/x-data-grid';

import {StyledInput} from '@components/input';

export type EditableNumberCellProps = {
    value: number;
    endAdornment?: string;
};

export function renderEditableNumberCell(params: GridRenderCellParams) {
    const {value, endAdornment} = params.value as EditableNumberCellProps;

    function handleChange(amount: number) {
        const editValue: GridEditCellValueParams = {id: params.id, value: {value: amount, endAdornment}, field: params.field};
        if (params.cellMode === 'view') {
            params.api.startCellEditMode({id: params.id, field: params.field});
        }

        setTimeout(() => {
            params.api.setEditCellValue(editValue);
        }, 0);
    }

    return (
        <StyledInput
            value={value ?? 0}
            onChange={e => handleChange(e.target.value as number)}
            endAdornment={endAdornment}
            textAlign="right"
            type="number"
            hasBottomSpacing={false}
            fullWidth={false}
        />
    );
}
