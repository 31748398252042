import {Epic, StateObservable} from 'redux-observable';
import {Observable} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Action, isActionOf, PayloadAction, PayloadMetaAction, StateType} from 'typesafe-actions';

import {map, mergeMap, switchMap} from '@otel';
import {ServerResponseStatus, ServiceResponsePayload} from '@services/types';

import {actions} from './action';
import {AsyncActionMeta, IAsyncAction, IAsyncPayloadAction, IAsyncPayloadMetaAction} from './type';

type RequestCallback<TRequestActionPayload, TRequest, TResponse> = (
    payload: TRequestActionPayload,
    state: StateType<unknown>
) => Observable<ServiceResponsePayload<TRequest, TResponse>>;

export class AsyncActionRequestEpicCreator {
    createRequestEpic<TRequestActionPayload, TRequest, TResponse>(
        asyncAction:
            | IAsyncPayloadAction<
                  TRequestActionPayload,
                  ServiceResponsePayload<TRequest, TResponse>,
                  ServiceResponsePayload<TRequest, TResponse>
              >
            | IAsyncPayloadMetaAction<
                  TRequestActionPayload,
                  ServiceResponsePayload<TRequest, TResponse>,
                  ServiceResponsePayload<TRequest, TResponse>
              >,
        callback: RequestCallback<TRequestActionPayload, TRequest, TResponse>,
        shouldCancelPreviousAction = true
    ):
        | Epic<
              PayloadAction<string, ServiceResponsePayload<TRequest, TResponse> | TRequestActionPayload>,
              PayloadAction<string, ServiceResponsePayload<TRequest, TResponse>>
          >
        | Epic<
              PayloadMetaAction<string, ServiceResponsePayload<TRequest, TResponse> | TRequestActionPayload, AsyncActionMeta>,
              PayloadMetaAction<string, ServiceResponsePayload<TRequest, TResponse>, AsyncActionMeta>
          > {
        return this.createActionEpic(asyncAction, callback, shouldCancelPreviousAction);
    }

    private createActionEpic<
        TAsyncAction extends IAsyncAction,
        TRequestActionPayload,
        TRequest,
        TResponse,
        TResultAction extends Action<string>
    >(
        asyncAction: TAsyncAction,
        callback: RequestCallback<TRequestActionPayload, TRequest, TResponse>,
        shouldCancelPreviousAction: boolean
    ): Epic<Action, TResultAction> {
        const mapRequest = shouldCancelPreviousAction ? switchMap : mergeMap;
        return (action$, state$: StateObservable<StateType<unknown>>) => {
            let actionId: string = null;
            let hasActionIdMeta = false;
            return action$.pipe(
                filter(isActionOf(asyncAction.request)),
                mapRequest(action => {
                    hasActionIdMeta = action?.meta?.actionId !== undefined;
                    if (hasActionIdMeta) {
                        actionId = action.meta?.actionId;
                    }
                    return callback(action.payload, state$?.value).pipe(
                        takeUntil(
                            action$.pipe(
                                filter(isActionOf(actions.cancelRequests)),
                                filter(() => action?.meta?.isCancelable !== false)
                            )
                        )
                    );
                }),
                filter(res => res !== null),
                map<ServiceResponsePayload<TRequest, TResponse>, TResultAction>((res: ServiceResponsePayload<TRequest, TResponse>) => {
                    return res.status === ServerResponseStatus.Success
                        ? hasActionIdMeta
                            ? asyncAction.success(res, {actionId})
                            : asyncAction.success(res)
                        : hasActionIdMeta
                        ? asyncAction.failure(res, {actionId})
                        : asyncAction.failure(res);
                })
            );
        };
    }
}
