import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {ActionConfirmationModal, ModalContext} from '@components/modal';
import {BonusCodeDetails, BonusStatus} from '@models/generated/graphql';
import {CustomTheme} from '@style';

import {useAsyncActionState} from '../../shared/async-action/hooks';
import {bonusCodeActions} from '../actions';

const localized = defineMessages({
    buttonLabel: {
        id: 'BonusDeactivateButtonModalCell_buttonLabel',
        defaultMessage: 'Deactivate',
    },
    modalTitle: {
        id: 'BonusDeactivateButtonModalCell_modalTitle',
        defaultMessage: 'Deactivate Bonus',
    },
    modalActionDescription: {
        id: 'BonusDeactivateButtonModalCell_modalActionDescription',
        defaultMessage: 'Do you want deactivate this bonus?',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    deactivateBonusModalButtonIcon: {
        fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    },
}));

type BonusDeactivateButtonModalCellProps = {
    bonusId: string;
    bonusStatus: BonusStatus;
};

function BonusDeactivateButtonModalCell({bonusId, bonusStatus}: BonusDeactivateButtonModalCellProps) {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const {openModal, closeModal} = useContext(ModalContext);
    const {isProgress} = useAsyncActionState(bonusCodeActions.deactivateBonus);

    const handleConfirm = () => {
        dispatch(bonusCodeActions.deactivateBonus.request({bonus_id: bonusId}));
        closeModal();
    };

    const handleClick = () => {
        openModal({
            body: (
                <ActionConfirmationModal
                    title={localized.modalTitle}
                    contentDescription={localized.modalActionDescription}
                    handleConfirm={handleConfirm}
                    handleCancel={closeModal}
                />
            ),
            styleParams: {maxWidth: 'xs'},
        });
    };

    return (
        <OutlinedButton
            smallPlus
            label={localized.buttonLabel}
            onClick={handleClick}
            startIcon={
                <Icon className={classes.deactivateBonusModalButtonIcon} icon={CustomIcon.CancelOutline} color={IconColor.SuitRed} />
            }
            disabled={isProgress || bonusStatus === BonusStatus.Inactive}
        />
    );
}

export function renderDeactivateBonusModalButtonCell(params: GridCellParams) {
    const bonusCode: BonusCodeDetails = params.row as BonusCodeDetails;
    return <BonusDeactivateButtonModalCell bonusId={bonusCode.bonus_id} bonusStatus={bonusCode.bonus_status} />;
}
