import {IModuleGridItem} from '@components/data-grid';
import {PlayerMessage, PlayerMessageMailType} from '@models/generated/graphql';

import {ColumnConfiguration} from 'src/common/types';
import {IModuleItem} from '../module-shared/types';

export const domain = 'player-message';

export class PlayerMessageItem extends PlayerMessage implements IModuleItem {}

export class PlayerMessageGridItem extends PlayerMessage implements IModuleGridItem {
    isNew: boolean;
    serverId: string;
}

export class PlayerMessageColumnsConfiguration extends ColumnConfiguration {
    withId: boolean;
    withMailType: boolean;
    withContent: boolean;
    withSubject: boolean;
    withTitle: boolean;
    withSignature: boolean;
    withSender: boolean;
    withUid: boolean;
    withSenderId: boolean;
    withSentAt: boolean;
    withSource: boolean;

    constructor() {
        super();
        this.withId = false;
        this.withMailType = false;
        this.withContent = false;
        this.withSubject = false;
        this.withTitle = false;
        this.withSignature = false;
        this.withSender = false;
        this.withUid = false;
        this.withSenderId = false;
        this.withSentAt = false;
        this.withSource = false;
    }
}

export class Message {
    content: string;
    title: string;
    sender: string;
    mailType: MailType;
    subject: string;
    signature: string;
}

export enum MailType {
    Mail = 1,
    RealAnnounce = 2,
    AsyncAnnounce = 3,
}

export const mailTypeMapping = {
    [MailType.Mail]: PlayerMessageMailType.Mail,
    [MailType.AsyncAnnounce]: PlayerMessageMailType.AsyncAnnounce,
    [MailType.RealAnnounce]: PlayerMessageMailType.RealAnnounce,
};

export const defaultMessage: Message = {
    content: '',
    mailType: MailType.Mail,
    title: '',
    subject: '',
    signature: '',
    sender: '',
};
