import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {MessageDescriptor, useIntl} from 'react-intl';

import {RuleType, useValidationFormatter} from '@components/input';
import {customerSupportUpdatePermissions} from '@models/permissions/permissions';
import {AsyncAction} from '@redux';

import {LackOfPermissionIndicator, useMultiplePermissions, withMultiplePermission} from '../../../app/permission/PermissionHoc';
import {useReduxForm, useReduxFormRequestMode} from '../../../shared/form/hooks';
import {EditableOccupationAutocomplete} from '../../../view-autocomplete/components/OccupationAutocomplete';
import {UserProfileModel} from '../types';

type UserProfileOccupationModel = {
    fieldValue: string;
    userId: string;
};

type UserProfileOccupationProps = {
    action: AsyncAction<string, UserProfileModel, string, unknown, string, unknown>;
    placeholder: MessageDescriptor;
    submitModelMapper: (submitModel: UserProfileModel) => UserProfileOccupationModel;
    formModelMapper: (formModel: UserProfileOccupationModel) => UserProfileModel;
    isRequired?: boolean;
};

type UserProfileOccupationInnerProps = UserProfileModel & {
    disabled?: boolean;
};

/**
 * @deprecated
 */
export const withUserProfileOccupation = (props: UserProfileOccupationProps) =>
    withMultiplePermission(
        (model: UserProfileOccupationInnerProps) => {
            const isEditable = useMultiplePermissions({allowedPermissions: customerSupportUpdatePermissions});

            const getFieldValue = () => (model?.userId ? props.submitModelMapper(model) : undefined);
            const [fieldValueModel, setFieldValueModel] = useState<UserProfileOccupationModel>(undefined);
            const {control, state, submit, cancel, handleSubmit} = useReduxForm<UserProfileOccupationModel, UserProfileModel>({
                initialModel: fieldValueModel,
                asyncAction: props.action,
                map: props.formModelMapper,
            });
            const {requestMode, resetRequestMode} = useReduxFormRequestMode<UserProfileModel>(props.action);
            const {formatMessage} = useIntl();
            const validationMessageFormatter = useValidationFormatter();

            useEffect(() => {
                const currentFieldValue = getFieldValue();
                setFieldValueModel(currentFieldValue);
            }, [JSON.stringify(model)]);

            return (
                <form onSubmit={handleSubmit(submit)}>
                    <Controller
                        render={({field, fieldState}) => (
                            <EditableOccupationAutocomplete
                                mode={requestMode}
                                value={field.value}
                                onValueChange={v => {
                                    field.onChange(v);
                                    resetRequestMode();
                                }}
                                onSave={() => handleSubmit(submit)}
                                onCancel={cancel}
                                disabled={model.disabled || !isEditable}
                                placeholder={props.placeholder}
                                errorMessage={
                                    state.errorMessage
                                        ? formatMessage(state.errorMessage)
                                        : fieldState?.error?.message
                                        ? fieldState?.error?.message
                                        : null
                                }
                            />
                        )}
                        control={control}
                        name="fieldValue"
                        defaultValue={fieldValueModel?.fieldValue}
                        rules={{
                            required: props.isRequired ? validationMessageFormatter(RuleType.Required, props.placeholder) : null,
                        }}
                    ></Controller>
                </form>
            );
        },
        {allowedPermissions: customerSupportUpdatePermissions, indicator: LackOfPermissionIndicator.Disabled}
    );
