import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {BulkMutationInput, DeleteUserLabelInput} from '@models/generated/graphql';
import {IUserProfileService} from '@services';

import {failedOperationId, IBulkStrategy, PerformStrategyOperationResponse, PerformStrategyRequest} from '../../block-bulk-actions';
import {BulkActionAddLabelItemPayloadValue} from '../types';

export const bulkPlayerLabelMutationInputTimeout = 10000;
export const bulkPlayerLabelMutationInputRetries = 3;

@injectable()
export class PerformAddUserLabelStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    private _userProfile: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfile: IUserProfileService) {
        this._userProfile = userProfile;
    }

    process({items}: PerformStrategyRequest<BulkActionAddLabelItemPayloadValue>): Observable<PerformStrategyOperationResponse> {
        const payload: BulkMutationInput = {
            items: items.map(i => ({id: i.itemId, data: {user_label_add: {uid: i.value.uid, label_ids: [i.value.label_id]}}})),
            retries: bulkPlayerLabelMutationInputRetries,
            timeout: bulkPlayerLabelMutationInputTimeout,
        };
        const actionKey = items[0].value?.label_id;

        return this._userProfile.assignUserLabels(payload).pipe(
            map(response => ({actionKey, id: response.id})),
            catchError(() => of({actionKey, id: failedOperationId}))
        );
    }
}

@injectable()
export class PerformRemoveUserLabelStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse> {
    private _userProfile: IUserProfileService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfile: IUserProfileService) {
        this._userProfile = userProfile;
    }

    process({items}: PerformStrategyRequest<DeleteUserLabelInput>): Observable<PerformStrategyOperationResponse> {
        const payload: BulkMutationInput = {
            items: items.map(i => ({id: i.itemId, data: {user_label_delete: i.value}})),
            retries: bulkPlayerLabelMutationInputRetries,
            timeout: bulkPlayerLabelMutationInputTimeout,
        };
        const actionKey = items[0].value?.label_id;

        return this._userProfile.unAssignUserLabels(payload).pipe(
            map(response => ({actionKey, id: response.id})),
            catchError(() => of({actionKey, id: failedOperationId}))
        );
    }
}
