import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import {TreeView} from '@components/tree-view/TreeView';
import {TreeViewItemCheckbox} from '@components/tree-view/TreeViewItemCheckbox';
import {TreeViewItemColorGroup} from '@components/tree-view/TreeViewItemColorGroup';
import {CustomTheme} from '@style';

import {LabelGroup, UserLabelsForm} from '../../block-label-group-list/types';

import {BlockPlayerLabelGroupEditAction} from './BlockPlayerLabelGroupEdit';
import {BlockPlayerLabelGroupLabelCheckbox} from './BlockPlayerLabelGroupLabelCheckbox';

const localized = defineMessages({
    blockPlayerLabelAssignSubmit: {
        id: 'blockPlayerLabelAssignSubmit',
        defaultMessage: 'Apply',
    },
    blockPlayerLabelAssignCancel: {
        id: 'blockPlayerLabelAssignCancel',
        defaultMessage: 'Cancel',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    blockPlayerLabelAssignDropdown: {
        position: 'relative',
        width: '400px',
        maxHeight: '360px',
        paddingTop: theme.spacing(1),
        overflow: 'auto',
    },

    blockPlayerLabelAssignDropdownButtons: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(2),
        gap: theme.spacing(1),

        '& > *': {
            flexGrow: 1,
        },
    },
}));

type BlockPlayerLabelAssignProps = {
    value: UserLabelsForm;
    isEditable?: boolean;
    onSubmit: (data: UserLabelsForm) => void;
    labelGroups: LabelGroup[];
    labelIds: string[];
};

export function BlockPlayerLabelAssignForm({value, onSubmit, labelGroups, labelIds, isEditable = true}: BlockPlayerLabelAssignProps) {
    const {classes} = useStyles();
    const [expanded, setExpanded] = useState([] as string[]);

    const {control, handleSubmit, reset, watch} = useForm<UserLabelsForm>({defaultValues: value});
    const groups = watch('groups');
    const newLabelsCount = groups.flatMap(g => g?.labels)?.filter(l => l && l?.selected && !l?.disabled)?.length;

    useEffect(() => {
        setExpanded(labelIds);
        reset(value);
    }, [labelIds.join(), JSON.stringify(value)]);

    function handleExpand(_: React.BaseSyntheticEvent, nodeIds: string[]) {
        setExpanded(nodeIds);
    }

    function handleCancel() {
        reset();
    }

    return (
        <Box>
            <form id="BlockPlayerLabelAssign" onSubmit={handleSubmit(onSubmit)}></form>
            <TreeView expanded={expanded} onNodeToggle={handleExpand} className={classes.blockPlayerLabelAssignDropdown}>
                {labelGroups?.map((group, groupIndex) => (
                    <TreeViewItemColorGroup
                        key={group.id}
                        nodeId={group.id}
                        label={group.name}
                        color={group.color}
                        itemsCount={group.labels?.length ?? 0}
                        actions={isEditable ? <BlockPlayerLabelGroupEditAction labelGroup={group} /> : null}
                    >
                        {group?.labels?.map((label, labelIndex) => (
                            <Controller
                                key={label.id}
                                control={control}
                                render={({field}) => (
                                    <TreeViewItemCheckbox
                                        nodeId={label.id}
                                        label={
                                            <BlockPlayerLabelGroupLabelCheckbox
                                                id={label.id}
                                                name={label.name}
                                                groupId={group.id}
                                                isEditable={isEditable}
                                            />
                                        }
                                        value={field.value}
                                        onChange={field.onChange}
                                        disabled={value.groups[groupIndex].labels[labelIndex].disabled}
                                    ></TreeViewItemCheckbox>
                                )}
                                name={`groups.${groupIndex}.labels.${labelIndex}.selected`}
                                defaultValue={value.groups[groupIndex].labels[labelIndex].selected}
                            ></Controller>
                        ))}
                    </TreeViewItemColorGroup>
                ))}
            </TreeView>
            <Box className={classes.blockPlayerLabelAssignDropdownButtons}>
                <Button key="cancel" label={localized.blockPlayerLabelAssignCancel} onClick={handleCancel} />
                <Button
                    key="submit"
                    color="primary"
                    label={localized.blockPlayerLabelAssignSubmit}
                    onSubmit={handleSubmit(onSubmit)}
                    disabled={newLabelsCount === 0}
                    type="submit"
                    form="BlockPlayerLabelAssign"
                >
                    {newLabelsCount > 0 ? (
                        <>
                            &nbsp;
                            <Chip
                                label={newLabelsCount}
                                size="small"
                                chipType={ChipType.Status}
                                chipVariant={ChipVariant.PrimaryButton}
                                fillWidth={false}
                            />
                        </>
                    ) : null}
                </Button>
            </Box>
        </Box>
    );
}
