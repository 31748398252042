import React from 'react';
import {CircularProgress} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useClasses = makeStyles()(() => ({
    loadingProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));

//TODO: [BO-2603] Progress component should be refactored to be used everywhere and this component should be removed
const CenteredCircularProgress = () => {
    const {classes} = useClasses();

    return <CircularProgress className={classes.loadingProgress} />;
};

export default CenteredCircularProgress;
