import React, {FC, memo} from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {withEmptyCheck} from '@components/label';
import {formatTimestamp} from '@utils';

import {renderValueWithTooltip} from './GridCellWithTooltip';

type Timestamp = {
    nanos?: number;
    seconds?: number;
};

type DateCellProps = {
    value: Timestamp;
};

const DateCellFormatter = memo(({value}: DateCellProps) => {
    return renderValueWithTooltip(formatTimestamp(value, 'date-time'));
});

export const DateCell: FC<DateCellProps> = withEmptyCheck(({value}: DateCellProps) => {
    return <DateCellFormatter value={value} />;
});

export const renderDateCell = (params: GridCellParams) => {
    const value = params.value;
    return <DateCell value={value as Timestamp}></DateCell>;
};
