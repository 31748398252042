import React from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {Snackbar} from '@components/snackbar/Snackbar';
import {SnackbarIconUpdate} from '@components/snackbar/SnackbarIcon';
import {localizedRealtimeNotification} from '@redux/realtime';

import {realtimeNotificationActions} from '../actions';
import {realtimeNotificationSelector} from '../selectors';

export function RealtimeUpdateNotificationObsolete() {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const updatesMeta = useSelector(realtimeNotificationSelector);
    const updatedTitles = updatesMeta?.map(u => formatMessage(u.name)).join(', ');

    const reload = () => {
        dispatch(realtimeNotificationActions.reloadAll());
    };

    const close = () => {
        dispatch(realtimeNotificationActions.clearNotification());
    };

    return (
        <Snackbar
            open={updatedTitles?.length > 0}
            message={formatMessage(localizedRealtimeNotification.realtimeNotificationMessage, {updatedTitles})}
            messageIcon={<SnackbarIconUpdate />}
            onAction={reload}
            action={formatMessage(localizedRealtimeNotification.realtimeNotificationButton)}
            onClose={close}
        />
    );
}
