import React from 'react';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {CustomTheme} from '@style';
import {sharedLocalization} from '@localization';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    multiStepFilterApplyButtonContainer: {
        padding: theme.spacing(1.5),
    },
    multiStepFilterApplyButton: {
        borderRadius: theme.spacing(1),
    },
}));

type MultiStepFilterApplyButtonProps = {
    onApply: () => void;
};

export function MultiStepFilterApplyButton({onApply}: MultiStepFilterApplyButtonProps) {
    const {classes} = useClasses();
    return (
        <div className={classes.multiStepFilterApplyButtonContainer}>
            <Button
                fullWidth
                className={classes.multiStepFilterApplyButton}
                label={sharedLocalization.apply}
                color="primary"
                onClick={onApply}
            ></Button>
        </div>
    );
}
