import React, {useEffect, useState} from 'react';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';

import {withStyledInputLabel} from '@components/input';
import {Select, SelectProps} from '@components/select';
import {arrayDifference, getObjectListWithUniqueKey, removeItemsFromArray} from '@utils/array';

import {SelectOption} from '../../../features/module-shared/types';

import {MultiSelectTextCounterWithTooltip, MultiSelectTextCounterWithTooltipProps} from './MultiSelectTextCounterWithTooltip';

const localized = defineMessages({
    all: {
        id: 'MultiSelectWithExclude_all',
        defaultMessage: 'All',
    },
    allExcept: {
        id: 'MultiSelectWithExclude_allExcept',
        defaultMessage: 'All except {except}',
    },
    allButSome: {
        id: 'MultiSelectWithExclude_allButSome',
        defaultMessage: 'All but some',
    },
    tooltipTitleAll: {
        id: 'MultiSelectWithExclude_tooltipTitleAll',
        defaultMessage: 'All {itemsName} selected',
    },
    tooltipTitleAllExcept: {
        id: 'MultiSelectWithExclude_tooltipTitleAllExcept',
        defaultMessage: 'Selected all {itemsName} except',
    },
    tooltipTitleSelected: {
        id: 'MultiSelectWithExclude_tooltipTitleSelected',
        defaultMessage: 'Selected {itemsName}',
    },
});

export class MultiSelectWithExcludeModel {
    include: string[];
    exclude: string[];
}

export type MultiSelectWithExcludeMode = 'include' | 'exclude';

export type MultiSelectWithExcludeProps = Omit<SelectProps<string>, 'value' | 'onSubmit' | 'multiple' | 'hasSelectAll' | 'hasSearch'> & {
    initialMode?: MultiSelectWithExcludeMode;
    value: MultiSelectWithExcludeModel;
    onValueChange: (options: MultiSelectWithExcludeModel) => void;
    itemsName?: string;
    inputLabel?: string | MessageDescriptor;
    inputChipColor?: 'primary';
} & ({hasSearch: false} | {hasSearch: true; filteredTotal: number});

export function MultiSelectWithExclude({
    value,
    options,
    total,
    onValueChange,
    itemsName,
    inputLabel,
    inputChipColor,
    initialMode = 'exclude',
    ...props
}: MultiSelectWithExcludeProps) {
    const [selectAll, setSelectAll] = useState(initialMode === 'exclude');
    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const {formatMessage} = useIntl();

    useEffect(() => {
        setSelectAll(initialMode === 'exclude');
    }, [initialMode]);

    useEffect(() => {
        if (options) {
            setAllOptions(prevState => getObjectListWithUniqueKey([...prevState, ...options], 'value'));
        }
    }, [options?.map(o => `${o.label}${o.value}`).join()]);

    function handleValueChange(newValues: string[]) {
        if (newValues) {
            const newModel: MultiSelectWithExcludeModel = value ? {...value} : {include: [], exclude: []};
            if (selectAll) {
                const selectedValues = getSelectedValues();
                if (selectedValues?.length > newValues?.length) {
                    newModel.exclude.push(...arrayDifference(selectedValues, newValues));
                } else {
                    newModel.exclude = removeItemsFromArray(newModel.exclude, arrayDifference(newValues, selectedValues)[0]);
                }
            } else {
                newModel.include = newValues;
            }
            onValueChange(newModel);
        } else {
            onValueChange({include: [], exclude: []});
            setSelectAll(false);
        }
    }

    function handleSelectAll(v: boolean) {
        onValueChange({include: [], exclude: []});
        setSelectAll(v);
    }

    function getValueData(): Omit<MultiSelectTextCounterWithTooltipProps, 'options'> {
        const result: Omit<MultiSelectTextCounterWithTooltipProps, 'options'> = {
            tooltipTitle: formatMessage(localized.tooltipTitleAll, {itemsName: itemsName}),
            selectedTotal: total,
            values: null,
            label: formatMessage(localized.all),
        };
        if (value?.include?.length) {
            result.selectedTotal = value?.include?.length;
            result.tooltipTitle = formatMessage(localized.tooltipTitleSelected, {itemsName: itemsName});
            result.values = value.include;
            result.label = null;
        } else if (value?.exclude?.length) {
            result.selectedTotal = value?.exclude?.length;
            result.tooltipTitle = formatMessage(localized.tooltipTitleAllExcept, {itemsName: itemsName});
            result.values = value.exclude;
            result.label =
                value.exclude.length === 1
                    ? formatMessage(localized.allExcept, {except: value?.exclude[0]})
                    : formatMessage(localized.allButSome);
        }
        return result;
    }

    function getSelectedValues(): string[] {
        return allOptions
            ?.map<string>(o => (typeof o.value === 'boolean' ? (o.value ? 'true' : 'false') : o.value.toString()))
            .filter((v: string) => (selectAll ? !value.exclude.includes(v) : value.include.includes(v)));
    }

    const {selectedTotal, tooltipTitle, values, label} = getValueData();

    return (
        <Select
            multiple
            value={getSelectedValues()}
            defaultSelectAll={selectAll}
            hasSelectAll
            options={options}
            onSelectAll={handleSelectAll}
            onSubmit={handleValueChange}
            renderValue={() => (
                <MultiSelectTextCounterWithTooltip
                    label={label}
                    filterLabel={inputLabel}
                    color={inputChipColor}
                    tooltipTitle={tooltipTitle}
                    selectedTotal={selectedTotal}
                    values={values}
                    options={allOptions}
                />
            )}
            total={props.hasSearch ? props.filteredTotal : total}
            {...props}
        />
    );
}

export const MultiSelectWithExcludeFormInput = withStyledInputLabel(MultiSelectWithExclude);
