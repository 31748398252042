import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    infoMessageIcon: {
        fontSize: theme.typography.body2.fontSize,
    },
    infoMessageContainer: {
        display: 'flex',
        columnGap: theme.spacing(1),
        alignItems: 'center',
        overflow: 'hidden',
    },
    infoMessage: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
}));
