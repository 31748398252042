import {defineMessages, MessageDescriptor} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {IModuleGridItem} from '@components/data-grid';
import {EventLogRecord, UserAction} from '@models/generated/graphql';
import {IGQlFilterVariables} from '@services/deprecated/types';
import {TestCase} from '@tests/types';
import {isRecent} from '@utils';

import {SearchFilter} from 'src/common/types';
import {IModuleItem} from '../module-shared/types';

export const domain = 'action-history';

const localized = defineMessages({
    automatedAmlRulesLabel: {
        id: 'AUTOMATED_AML_RULES',
        defaultMessage: 'Automated AML rules',
    },
    automatedRulesLabel: {
        id: 'AUTOMATED_RULES',
        defaultMessage: 'Automated rules',
    },
});

export const labelMap: {[key: string]: MessageDescriptor} = {
    'pbi-airflow': localized.automatedAmlRulesLabel,
    'pbi-evp': localized.automatedRulesLabel,
};
export interface IActionCenterGqlQueryVariables extends IGQlFilterVariables {
    uid: string;
}

export type ActionCenterSearchFilter = SearchFilter & {
    uid: string;
};

export class ActionHistory extends UserAction implements IModuleItem {}

export class ActionHistoryGridItem extends UserAction implements IModuleGridItem {
    actionAgent: string | MessageDescriptor;
    serverId: string;

    get isNew() {
        return isRecent(this.trigger_at, 1);
    }
}

export enum ActionType {
    AdminChangedAmlLabel = 'AdminChangedAmlLabel',
    AdminResentUsersRestoreCode = 'AdminResentUsersRestoreCode',
    AdminDisabledUsersTwoFA = 'AdminDisabledUsersTwoFA',
    AdminModifiedUsersMobile = 'AdminModifiedUsersMobile',
    AdminChangedBotGroup = 'AdminChangedBotGroup',
    AdminSecurityCaseOpened = 'AdminSecurityCaseOpened',
    AdminSecurityCaseClosed = 'AdminSecurityCaseClosed',
    AdminUnlockedUsersLogin = 'AdminUnlockedUsersLogin',
    AdminLockedUsersLogin = 'AdminLockedUsersLogin',
    AdminUnlockedUsersWithdrawal = 'AdminUnlockedUsersWithdrawal',
    AdminLockedUsersWithdrawal = 'AdminLockedUsersWithdrawal',
    AdminUnlockedUsersLobbyAccess = 'AdminUnlockedUsersLobbyAccess',
    AdminLockedUsersLobbyAccess = 'AdminLockedUsersLobbyAccess',
    AdminBlacklistedPlayer = 'AdminBlacklistedPlayer',
    KickedBlacklistedPlayer = 'KickedBlacklistedPlayer',
    AdminBlockedUser = 'AdminBlockedUser',
    AdminModifiedUsersKYCStatus = 'AdminModifiedUsersKYCStatus',
    AdminModifiedUsersEmail = 'AdminModifiedUsersEmail',
    AdminLockedUsersDeposit = 'AdminLockedUsersDeposit',
    AdminUnlockedUsersDeposit = 'AdminUnlockedUsersDeposit',
    AdminPlayerAccountClosed = 'AdminPlayerAccountClosed',
    BlacklistedPlayer = 'BlacklistedPlayer',
    AdminUpdatedFriendsGroupUsers = 'AdminUpdatedFriendsGroupUsers',
    AdminKickedUser = 'AdminKickedUser',
    InitiatedPaymentKYC = 'InitiatedPaymentKYC',
    InitiatedSecurityKYC = 'InitiatedSecurityKYC',
    UpdatedKYCCaseStatus = 'UpdatedKYCCaseStatus',
    UpdatedTransactionStatus = 'UpdatedTransactionStatus',
    UpdatedP2PFeatureStatus = 'UpdatedP2PFeatureStatus',
    AdminSetUserP2PFeature = 'AdminSetUserP2PFeature',
    UserLabel = 'UserLabel',
}

export type FormatterTestCase = TestCase<string, ChipKeyValue[]>;

export class EventLogGridItem extends EventLogRecord implements IModuleGridItem {
    serverId: string;
    actionAgent: string | MessageDescriptor;

    get isNew() {
        return isRecent(this.trigger_at, 1);
    }
}
