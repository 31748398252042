import React, {FC} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {useBulkActionSummaryValue} from './BulkSummaryStepSummaryValue';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    bulkActionSummary: {
        width: '100%',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    bulkActionSummaryHeader: {
        padding: 0,
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        display: 'flex',
    },
    bulkActionSummaryHeaderNoBorder: {
        borderBottom: '0',
    },
    bulkActionSummaryContent: {
        padding: theme.spacing(2, 0),
        display: 'flex',
        width: '100%',
    },
    bulkActionSummaryHeaderActionLabel: {
        display: 'flex',
        width: theme.spacing(20),
        minWidth: theme.spacing(25),
        maxWidth: theme.spacing(25),
        alignItems: 'center',
        columnGap: theme.spacing(1.5),
    },
    bulkActionSummaryHeaderActionValue: {
        display: 'flex',
        overflow: 'auto',
        maxWidth: theme.custom.bulkSummaryDetailsItemValueWidth,
    },
    bulkActionSummaryHeaderActionMeta: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
    },
    bulkActionSummaryDetails: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    bulkActionSummaryDetailsSmall: {
        height: '200px',
        overflowY: 'auto',
    },
}));

type BulkActionSummaryBaseProps = {
    actionKey: string;
    actionValue?: unknown;
    actionLabel: JSX.Element;
    actionTotal: JSX.Element;
    expandable?: boolean;
    size?: 'small' | 'normal';
    children?: React.ReactNode;
};

export const BulkActionSummaryDetailsInfo: FC<BulkActionSummaryBaseProps> = ({
    actionKey,
    actionValue,
    actionLabel,
    children,
    actionTotal,
    expandable,
    size = 'normal',
}) => {
    const {classes} = useStyles();
    const actionSummary = useBulkActionSummaryValue({actionKey, actionValue});

    return (
        <Accordion className={classes.bulkActionSummary} expanded={!expandable ? true : undefined} key={`actionSummary-${actionKey}`}>
            <AccordionSummary className={classes.bulkActionSummaryHeader} expandIcon={expandable ? <ExpandMoreIcon /> : null}>
                <Box className={classes.bulkActionSummaryHeaderActionLabel}>{actionLabel}</Box>
                <Box className={classes.bulkActionSummaryHeaderActionValue}>{actionSummary ? actionSummary : null}</Box>
                <Box className={classes.bulkActionSummaryHeaderActionMeta}>{actionTotal}</Box>
            </AccordionSummary>
            <AccordionDetails
                className={`${classes.bulkActionSummaryDetails} ${size === 'small' ? classes.bulkActionSummaryDetailsSmall : ''}`}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export const BulkActionSummaryDetailsInfoNotExpandable: FC<BulkActionSummaryBaseProps> = ({
    actionKey,
    actionValue,
    actionLabel,
    children,
    actionTotal,
}) => {
    const {classes} = useStyles();
    const actionSummary = useBulkActionSummaryValue({actionKey, actionValue});

    return (
        <Box className={classes.bulkActionSummary}>
            <Box className={classes.bulkActionSummaryHeader}>
                <Box className={classes.bulkActionSummaryContent}>
                    <Box className={classes.bulkActionSummaryHeaderActionLabel}> {actionLabel}</Box>
                    <Box className={classes.bulkActionSummaryHeaderActionValue}>{actionSummary ? actionSummary : null}</Box>
                    <Box className={classes.bulkActionSummaryHeaderActionMeta}>{actionTotal}</Box>
                </Box>
            </Box>
            {children}
        </Box>
    );
};

type BulkActionSummaryContentDataGridProps = Omit<BulkActionSummaryBaseProps, 'actionKey' | 'actionValue'>;

export const BulkActionSummaryDetailsInfoExpanded: FC<BulkActionSummaryContentDataGridProps> = ({
    actionLabel,
    children,
    actionTotal,
    size = 'normal',
}) => {
    const {classes, cx} = useStyles();

    return (
        <Box className={classes.bulkActionSummary}>
            <Box className={`${classes.bulkActionSummaryHeader} ${classes.bulkActionSummaryHeaderNoBorder}`}>
                <Box className={classes.bulkActionSummaryContent}>
                    <Box className={classes.bulkActionSummaryHeaderActionLabel}>{actionLabel}</Box>
                    <Box className={classes.bulkActionSummaryHeaderActionMeta}>{actionTotal}</Box>
                </Box>
            </Box>
            <Box
                className={cx(
                    {
                        [classes.bulkActionSummaryDetailsSmall]: size === 'small',
                    },
                    classes.bulkActionSummaryDetails
                )}
            >
                {children}
            </Box>
        </Box>
    );
};
