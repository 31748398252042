import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {DeleteRuleButton} from './DeleteRuleButton';
import {EditRuleButton} from './EditRuleButton';

const useStyles = makeStyles()(theme => ({
    editDeleteActionsCellContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(),
    },
}));

export type EditDeleteActionsCellProps = {
    id: string;
    name: string;
};

function EditDeleteActionsCell({id, name}: EditDeleteActionsCellProps) {
    const {classes} = useStyles();

    return (
        <div className={classes.editDeleteActionsCellContainer}>
            <EditRuleButton ruleId={id} />
            <DeleteRuleButton ruleId={id} ruleName={name} />
        </div>
    );
}

export function renderEditDeleteActionsCell(params: GridCellParams) {
    const value: EditDeleteActionsCellProps = params.value as EditDeleteActionsCellProps;
    return <EditDeleteActionsCell {...value} />;
}
