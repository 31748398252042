import moment from 'moment-timezone';

import {endDateNow, getNHoursAgoAsSeconds, momentToTimestampSeconds, timestampSecondsToMoment} from '@utils';

//TODO: [BO-2663] Move files to src/common/components/input (use in DatePicker and DateRangePicker)
export function convertInternalFormatToSeconds(isFrom: boolean, dateLocal: Date | typeof endDateNow, timezone: string): number {
    if (dateLocal !== endDateNow) {
        let res: number = null;
        let value = dateLocal ? moment.utc(dateLocal.toDateString()) : null;

        if (value) {
            const formattedValue = value.format('YYYY-MM-DD');
            value = moment.tz(formattedValue, timezone);
            value = isFrom ? value.startOf('day') : value.endOf('day');
            res = momentToTimestampSeconds(value);
        }

        return res;
    } else if (dateLocal === endDateNow) {
        return getNHoursAgoAsSeconds(0);
    } else {
        return null;
    }
}

export function convertSecondsToInternalFormat(seconds: string | number, timezone: string): Date {
    let result = null;
    if (seconds) {
        const value = timestampSecondsToMoment(seconds)?.tz(timezone);
        const formattedValue = value?.format('YYYY-MM-DD');
        const date = moment.utc(formattedValue);
        const dateLocal = date?.utcOffset(0);
        const dateLocal2 = dateLocal?.local(true);
        result = dateLocal2?.toDate();
    }
    return result;
}
