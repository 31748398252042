import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {BonusCodeDetails} from '@models/generated/graphql';
import {gqlQueryArgs} from '@services/query/all';

import {IRealtimeService} from '../../realtime-updates/services/IRealtimeService';
import {RealtimeSubscriptionService} from '../../realtime-updates/services/RealtimeUpdatesService';

//TODO: [BO-2715] Separate be-bonus to workspace blocks (workspace-block-bonus-list/workspace-block-bonus-create)
export class BeBonusRealtimeService extends RealtimeSubscriptionService implements IRealtimeService<BonusCodeDetails> {
    subscribeToCreate(): Observable<BonusCodeDetails> {
        return super.createSubscription(gqlQueryArgs.beBonus.addSubscription).pipe(map(res => res.beBonusAdded));
    }
}
