import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, RootState} from 'typesafe-actions';

import {mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {getFilterString} from '@utils';

import {Filter} from 'src/common/types';
import {protectEpics} from '../../app/error-handling/epics';
import {locationSelector} from '../../app/routing/selectors';
import {contentActions as getContentActions} from '../../module-shared/actions';

import {filterActions, getFilterActions} from './actions';

export const getFilterEpics = (
    domain: string,
    filterStateSelector: (state: RootState) => string,
    updateSearchUrl: boolean,
    isClientFiltering: boolean
) => {
    const {getUpdateSearchFilterActions} = getFilterActions(domain, updateSearchUrl);
    const contentActions = getContentActions(domain);
    const actions = filterActions(domain);

    const setFilterEpic: RootEpic = (action$, _state$) =>
        action$.pipe(
            filter(isActionOf(actions.setFilter)),
            mergeMap(() => (isClientFiltering ? of() : of(contentActions.contentLoad.request())))
        );

    const itemsFilterChangedEpic: RootEpic = (action$, state$) =>
        action$.pipe(
            filter(isActionOf(actions.itemsFilter)),
            mergeMap(res => {
                const filters = Array.isArray(res.payload) ? (res.payload as Filter[]) : [res.payload as Filter];
                const filterQuery = getFilterString(filterStateSelector(state$.value), true, ...filters);
                const location = locationSelector(state$.value);
                const mappedActions = [...getUpdateSearchFilterActions(location, filterQuery)];
                return of(...mappedActions);
            })
        );

    return protectEpics(setFilterEpic, itemsFilterChangedEpic);
};
