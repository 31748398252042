import {useAutoMapper} from '@auto-mapper';
import {BackpackMappingExtraArgs, BackpackViewModel, BackpackViewModelKeys} from '@models/backpack';
import {BackpackItem} from '@models/generated/graphql';
import {BackpackFilterKeys, BackpackQueryFields, EntityType} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {Sorting} from 'src/common/types';
import {useJurisdictionConfig} from '../app/config/hooks';

export function useBackpack({
    viewType,
    fields,
    displayName,
    cleanDelay = 0,
    realtimeMode,
    triggers,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    validateFilter,
    syncWithUrl = false,
}: UseListViewEntityProps<BackpackFilterKeys, BackpackViewModelKeys>): UseListViewEntityResult<BackpackViewModel, BackpackViewModelKeys> {
    const mapper = useAutoMapper();
    const queryFields = mapFields(fields);
    const {
        items: backpacks,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<BackpackItem, BackpackFilterKeys, BackpackQueryFields>({
        viewType,
        entity: {
            entity: EntityType.Backpack,
            fields: queryFields,
        },
        displayName,
        realtime: realtimeMode ? {entity: EntityType.Backpack, mode: realtimeMode, triggers} : null,
        defaultSorting: mapSorting(defaultSorting),
        defaultPaging,
        defaultFilters,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const {currency} = useJurisdictionConfig();
    const items: BackpackViewModel[] = backpacks?.map(i =>
        mapper.map(i, BackpackItem, BackpackViewModel, {
            extraArgs: () => ({currency} as BackpackMappingExtraArgs),
        })
    );

    return {
        items,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    };

    function mapFields(fields: BackpackViewModelKeys[]) {
        return mapper.map<BackpackViewModelKeys[], BackpackQueryFields[]>(
            fields,
            nameof<BackpackViewModelKeys>(),
            nameof<BackpackQueryFields>()
        );
    }

    function mapField(field: BackpackViewModelKeys): BackpackQueryFields {
        return mapFields([field])[0];
    }

    function mapSorting(sorting: Sorting<BackpackViewModelKeys>[]) {
        return sorting?.map(s => ({...s, field: mapField(s.field)}));
    }
}
