import React, {KeyboardEvent} from 'react';
import {ControllerFieldState} from 'react-hook-form';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {Box, Grid} from '@mui/material';

import {FormTextInputDefault} from '@components/input';
import {withLabel} from '@components/label';

import {ValueRange} from './types';

export type RelativeRangeProps = {
    showFrom: boolean;
    showTo: boolean;
    value: ValueRange;
    fieldState: ControllerFieldState;
    label: string | MessageDescriptor;
    onChange: (value: ValueRange) => void;
};

export const localized = defineMessages({
    valueRangeValue: {
        id: 'ValueRangeFilter_valueRangeValue',
        defaultMessage: 'Value',
    },
    firstValueRangeInput: {
        id: 'ValueRangeFilter_firstValueRangeInput',
        defaultMessage: 'From',
    },
    secondValueRangeInput: {
        id: 'ValueRangeFilter_secondValueRangeInput',
        defaultMessage: 'To',
    },
});

export function RelativeRangeInner({value, onChange, showTo, showFrom, fieldState}: RelativeRangeProps) {
    const {formatMessage} = useIntl();
    const showBoth = showTo && showFrom;
    const localizeFirstInput = showTo ? localized.firstValueRangeInput : localized.valueRangeValue;
    const localizeSecondInput = showFrom ? localized.secondValueRangeInput : localized.valueRangeValue;

    function getFromFieldErrorState(): ControllerFieldState {
        const isFieldErrorIncluded = fieldState?.error?.message?.includes(formatMessage(localized.firstValueRangeInput));
        const replacedMessage = isFieldErrorIncluded
            ? fieldState.error.message.replace(formatMessage(localized.firstValueRangeInput), formatMessage(localizeFirstInput))
            : null;
        return showFrom && isFieldErrorIncluded
            ? {
                  ...fieldState,
                  error: {
                      ...fieldState.error,
                      message: replacedMessage,
                  },
              }
            : undefined;
    }

    function getToFieldErrorState(): ControllerFieldState {
        const isFieldErrorIncluded = fieldState?.error?.message?.includes(formatMessage(localized.secondValueRangeInput));
        const replacedMessage = isFieldErrorIncluded
            ? fieldState.error.message.replace(formatMessage(localized.secondValueRangeInput), formatMessage(localizeSecondInput))
            : null;
        return showTo && isFieldErrorIncluded
            ? {
                  ...fieldState,
                  error: {
                      ...fieldState.error,
                      message: replacedMessage,
                  },
              }
            : undefined;
    }

    function handleFromKeyDown(e: KeyboardEvent<HTMLDivElement>) {
        if (e.key === 'Backspace' && value.from === 0) {
            onChange({
                from: undefined,
                to: value.to,
            });
        }
    }

    function handleToKeyDown(e: KeyboardEvent<HTMLDivElement>) {
        if (e.key === 'Backspace' && value.to === 0) {
            onChange({
                from: value.from,
                to: undefined,
            });
        }
    }

    return (
        <Box>
            <Grid container spacing={1}>
                {showFrom ? (
                    <Grid item xs={12} md={showBoth ? 6 : 12}>
                        <FormTextInputDefault
                            key={'from'}
                            value={value.from}
                            onChange={e =>
                                onChange({
                                    from: Number(e?.target?.value),
                                    to: value.to,
                                })
                            }
                            fieldState={getFromFieldErrorState()}
                            hideLabel={true}
                            placeholder={localizeFirstInput}
                            type="number"
                            onKeyDown={handleFromKeyDown}
                        />
                    </Grid>
                ) : null}

                {showTo ? (
                    <Grid item xs={12} md={showBoth ? 6 : 12}>
                        <FormTextInputDefault
                            key={'to'}
                            value={value.to}
                            onChange={e =>
                                onChange({
                                    from: value.from,
                                    to: Number(e?.target?.value),
                                })
                            }
                            fieldState={getToFieldErrorState()}
                            hideLabel={true}
                            placeholder={localizeSecondInput}
                            type="number"
                            onKeyDown={handleToKeyDown}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </Box>
    );
}

export const RelativeRange = withLabel(RelativeRangeInner);
