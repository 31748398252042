import {useEffect, useState} from 'react';
import {GridColDef as MuiGridColDef} from '@mui/x-data-grid';
import produce from 'immer';

import {MuiDataGridProps} from '../MuiDataGrid';
import {GridColDef} from '../types';

import {useColumnHeaderName} from './useColumnHeaderName';
import {useGridCellStyle} from './useGridCellStyle';
import {useGridPermissions} from './useGridPermissions';
import {useJurisdictionConfigForColumns} from './useJurisdictionConfigForColumns';

type UseColumnsReturnType = {
    columns: MuiGridColDef[];
    onColumnsVisibilityUpdate: (columns: string[]) => void;
};

export function useColumns({
    columns: initialColumns,
    pinnedColumns,
    visibleColumns,
    hasColumnSelection,
    isFlexible,
    onColumnsVisibilityUpdate,
}: Pick<
    MuiDataGridProps,
    'columns' | 'pinnedColumns' | 'visibleColumns' | 'onColumnsVisibilityUpdate' | 'hasColumnSelection' | 'isFlexible'
>): UseColumnsReturnType {
    const [visibleColumnNames, setVisibleColumnNames] = useState<string[]>(visibleColumns);

    const allColumns = hasColumnSelection ? [...(pinnedColumns ?? []), ...(visibleColumnNames ?? [])] : null;
    if (allColumns) {
        const sortedColumns = sortColumns(initialColumns, allColumns);
        initialColumns = hideColumns(sortedColumns, allColumns);
    }

    const namedColumns = useColumnHeaderName(initialColumns);
    //TODO: remove from DataGrid
    const accessibleColumns = useJurisdictionConfigForColumns(namedColumns);
    const unstyledColumns = useGridPermissions(accessibleColumns);
    const styledColumns = useGridCellStyle(unstyledColumns, isFlexible);

    useEffect(() => {
        setVisibleColumnNames(visibleColumns);
    }, [visibleColumns?.join()]);

    function hideColumns(columns: GridColDef[], visibleColumnNames: string[]): GridColDef[] {
        return produce(columns, draft => {
            draft.forEach((column: GridColDef) => {
                column.hide = visibleColumnNames.length ? !visibleColumnNames.includes(column.field) : true;
            });
        });
    }

    function sortColumns(columns: GridColDef[], visibleColumnNames: string[]): GridColDef[] {
        const sortedFieldNames: string[] = [...new Set([...visibleColumnNames, ...columns.filter(c => c).map(c => c.field)])];
        return sortedFieldNames.map<GridColDef>((name: string) => columns.find(c => c.field === name))?.filter(c => c);
    }

    function handleVisibilityUpdate(visibleColumns: string[]) {
        setVisibleColumnNames(visibleColumns);
        if (onColumnsVisibilityUpdate) {
            onColumnsVisibilityUpdate(visibleColumns);
        }
    }
    return {
        columns: styledColumns,
        onColumnsVisibilityUpdate: handleVisibilityUpdate,
    };
}
