import React from 'react';

import {ErrorModal} from '@components/error';
import {PermissionAccessibleItem} from '@models/permissions/types';
import {withTraceErrorBoundary} from '@otel';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';

export const withModule = <Props extends object>(
    WrappedComponent: React.ComponentType<Props>,
    modulesPermissions: PermissionAccessibleItem[]
) =>
    withTraceErrorBoundary(
        withMultiplePermission(WrappedComponent, {
            allowedPermissions: modulesPermissions,
            indicator: LackOfPermissionIndicator.InfoMessage,
        }),
        ErrorModal
    );
