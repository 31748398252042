import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    downlineLabel: {
        id: 'AgentPlayerReportingFilterReferrer_downlineLabel',
        defaultMessage: 'Include Downline',
    },
    agentLabel: {
        id: 'AgentPlayerReportingFilterReferrer_agentLabel',
        defaultMessage: 'Agent',
    },
    agentPlaceholder: {
        id: 'AgentPlayerReportingFilterReferrer_agentPlaceholder',
        defaultMessage: 'Search agent',
    },
    agentEmptyPlaceholder: {
        id: 'AgentPlayerReportingFilterReferrer_agentEmptyPlaceholder',
        defaultMessage: 'Enter the agent name and select the downline type',
    },
    cancel: {
        id: 'AgentPlayerReportingFilterReferrer_cancel',
        defaultMessage: 'Cancel',
    },
    any: {
        id: 'AgentPlayerReportingFilterReferrer_any',
        defaultMessage: 'Any',
    },
    agentAutocompletePlaceholder: {
        id: 'AgentAutocomplete_agentAutocompletePlaceholder',
        defaultMessage: 'Search agent',
    },
    agentAutocompleteEmptyPlaceholder: {
        id: 'AgentAutocomplete_agentAutocompleteEmptyPlaceholder',
        defaultMessage: 'Enter the agent name and select the downline type',
    },
});
