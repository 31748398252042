import {createMap, forMember, mapFrom, Mapper} from '@automapper/core';
import {injectable} from 'inversify';

import {IMapping} from '@auto-mapper';
import {AffiliatesInput} from '@models/generated/graphql';
import {UserReferralFormModel} from '@services/playerReferralService';
import {UpdateAffiliateBtagModel} from '@services/userProfileService';

@injectable()
export class PlayerReferrerInfoMapping implements IMapping {
    createMapping(mapper: Mapper) {
        createMap<UserReferralFormModel, UpdateAffiliateBtagModel>(
            mapper,
            UserReferralFormModel,
            nameof<UpdateAffiliateBtagModel>(),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.referee_uid)
            ),
            forMember(
                vm => vm.affiliate,
                mapFrom(
                    m =>
                        ({
                            btag: m.referrer_id,
                        } as AffiliatesInput)
                )
            ),
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            )
        );
    }
}
