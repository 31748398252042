import {NormalizedCacheObject} from '@apollo/client';
import {inject, injectable} from 'inversify';

import {FileDownloadService} from '@file/services/FileDownloadService';
import {ServiceTypes} from '@inversify';
import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {BoUserService, UserProfileService} from '@services';
import {ApolloClientProxy} from '@services/gql-api';
import {StatesService} from '@services/statesService';

import {ActionHistoryService} from '../../features/action-history/services';
import {UserActionRealtimeService} from '../../features/action-history/services/UserActionRealtimeService';
import {JurisdictionConfigService} from '../../features/app/config/services';
import {ErrorHandlingService} from '../../features/app/error-handling/services';
import {SharedDataService} from '../../features/app-shared/services';
import {BeBonusRealtimeService} from '../../features/be-bonus/services/BeBonusRealtimeService';
import {BeBonusService} from '../../features/be-bonus/services/BeBonusService';
import {BeBonusHistoryRealtimeService} from '../../features/be-bonus-history/services/BeBonusHistoryRealtimeService';
import {BeBonusHistoryService} from '../../features/be-bonus-history/services/BeBonusHistoryService';
import {KycService} from '../../features/kyc/services';
import {KycRealtimeService} from '../../features/kyc/services/KycRealtimeService';
import {LegalDocsService} from '../../features/legal-docs/services';
import {MarketingCodeRealtimeService} from '../../features/marketing-code/services/MarketingCodeRealtimeService';
import {MarketingCodeService} from '../../features/marketing-code/services/MarketingCodeService';
import {ModuleService} from '../../features/modules/services';
import {SecurityCasesService} from '../../features/player-actions/cases-actions/services';
import {VerificationActionsUpdateService} from '../../features/player-actions/verification-actions/services';
import {PlayerMessageRealtimeService} from '../../features/player-message/services/PlayerMessageRealtimeService';
import {PlayerMessageService} from '../../features/player-message/services/PlayerMessageService';
import {ReferrerSummaryRealtimeService} from '../../features/referrer-summary/services/ReferrerSummaryRealtimeService';
import {TransactionService} from '../../features/transactions/services';
import {UserTransactionRealtimeService} from '../../features/transactions/services/UserTransactionRealtimeService';
import {UserProfileService as UserProfileServiceDeprecated} from '../../features/user-profile/services';
import {UserProfileRealtimeService} from '../../features/user-profile/services/UserProfileRealtimeService';
import {UserService} from '../../pages/access-management/services';
import {KycDetailsDocumentsService} from '../../pages/kyc-details/kyc-details-documents/services';
import {KycProcessingService} from '../../pages/kyc-processing/services';
import {LegalDocHistoryService} from '../../pages/legal-docs/history/services';
import {LegalDocManagementService, UserAcceptanceVersionService} from '../../pages/legal-docs/services';
import {MarketingBonusCodeService} from '../../pages/marketing-bonus-code/services';
import {RegistrationInfoService} from '../../pages/registration-info/services';
import {RolesService} from '../../pages/role-management/services';

/**
 * @deprecated
 * <p>Temporary registered in inversify container but it should be removed. All services should be registered directly</p>
 */
@injectable()
export class ServiceContainer {
    moduleService: ModuleService;
    userService: UserService;
    rolesService: RolesService;
    registrationInfo: RegistrationInfoService;
    kycService: KycService;
    legalDocumentService: LegalDocManagementService;
    legalDocumentHistoryService: LegalDocHistoryService;
    userAcceptanceVersionService: UserAcceptanceVersionService;
    actionHistoryService: ActionHistoryService;
    securityCasesService: SecurityCasesService;
    sharedDataService: SharedDataService;
    jurisdictionConfigService: JurisdictionConfigService;
    transactionService: TransactionService;
    verificationActionsUpdateService: VerificationActionsUpdateService;
    userProfileService: UserProfileServiceDeprecated;
    kycProcessingService: KycProcessingService;
    kycDetailsDocumentsService: KycDetailsDocumentsService;
    legalDocsService: LegalDocsService;
    marketingCodeService: MarketingCodeService;
    marketingBonusCodeService: MarketingBonusCodeService;
    errorHandlingService: ErrorHandlingService;
    playerMessageService: PlayerMessageService;
    beBonusService: BeBonusService;
    beBonusHistoryService: BeBonusHistoryService;
    fileDownloadService: FileDownloadService;
    userProfileRealtimeService: UserProfileRealtimeService;
    userTransactionRealtimeService: UserTransactionRealtimeService;
    userActionRealtimeService: UserActionRealtimeService;
    kycRealtimeService: KycRealtimeService;
    referrerSummaryRealtimeService: ReferrerSummaryRealtimeService;
    marketingCodeRealtimeService: MarketingCodeRealtimeService;
    playerMessageRealtimeService: PlayerMessageRealtimeService;
    beBonusRealtimeService: BeBonusRealtimeService;
    beBonusHistoryRealtimeService: BeBonusHistoryRealtimeService;

    constructor(
        @inject(ServiceTypes.ApolloClientIGP) apolloClientIgp: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.ApolloClientPE) apolloClientPe: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.TracingService) tracingService: ITracingService,
        @inject(ServiceTypes.StateService) stateService: StatesService,
        @inject(ServiceTypes.UserProfileService) userProfileService: UserProfileService,
        @inject(ServiceTypes.BoUserService) boUserService: BoUserService,
        @inject(ServiceTypes.UserManager) userManager: UserManagerExtended
    ) {
        const userProfileServiceDeprecated: UserProfileServiceDeprecated = new UserProfileServiceDeprecated(
            apolloClientIgp,
            stateService,
            userManager
        );
        const userService: UserService = new UserService(apolloClientIgp, tracingService, userProfileService, userManager);
        const jurisdictionConfigService = new JurisdictionConfigService();

        this.moduleService = new ModuleService(tracingService, userManager);
        this.userService = userService;
        this.rolesService = new RolesService(tracingService, userManager);
        this.registrationInfo = new RegistrationInfoService(apolloClientIgp, userManager, tracingService);
        this.kycService = new KycService(apolloClientIgp, tracingService, userManager);
        this.legalDocumentService = new LegalDocManagementService(tracingService, userManager);
        this.legalDocumentHistoryService = new LegalDocHistoryService(apolloClientPe, tracingService, userManager);
        this.userAcceptanceVersionService = new UserAcceptanceVersionService(tracingService, userManager);
        this.actionHistoryService = new ActionHistoryService(apolloClientIgp, userService);
        this.securityCasesService = new SecurityCasesService(apolloClientIgp, tracingService, userManager);
        this.sharedDataService = new SharedDataService(tracingService, userManager);
        this.jurisdictionConfigService = jurisdictionConfigService;
        this.transactionService = new TransactionService(apolloClientIgp);
        this.verificationActionsUpdateService = new VerificationActionsUpdateService(apolloClientIgp);
        this.userProfileService = userProfileServiceDeprecated;
        this.kycProcessingService = new KycProcessingService(apolloClientIgp);
        this.kycDetailsDocumentsService = new KycDetailsDocumentsService(apolloClientIgp);
        this.legalDocsService = new LegalDocsService(tracingService, userManager);
        this.marketingCodeService = new MarketingCodeService(apolloClientIgp);
        this.marketingBonusCodeService = new MarketingBonusCodeService(apolloClientIgp);
        this.errorHandlingService = new ErrorHandlingService();
        this.playerMessageService = new PlayerMessageService(apolloClientIgp, userManager);
        this.beBonusService = new BeBonusService(apolloClientIgp);
        this.beBonusHistoryService = new BeBonusHistoryService(apolloClientIgp, boUserService);
        this.fileDownloadService = new FileDownloadService();

        // realtime services
        this.userProfileRealtimeService = new UserProfileRealtimeService(apolloClientIgp);
        this.userTransactionRealtimeService = new UserTransactionRealtimeService(apolloClientIgp);
        this.userActionRealtimeService = new UserActionRealtimeService(apolloClientIgp);
        this.kycRealtimeService = new KycRealtimeService(apolloClientIgp);
        this.referrerSummaryRealtimeService = new ReferrerSummaryRealtimeService(apolloClientIgp);
        this.marketingCodeRealtimeService = new MarketingCodeRealtimeService(apolloClientIgp);
        this.playerMessageRealtimeService = new PlayerMessageRealtimeService(apolloClientIgp);
        this.beBonusRealtimeService = new BeBonusRealtimeService(apolloClientIgp);
        this.beBonusHistoryRealtimeService = new BeBonusHistoryRealtimeService(apolloClientIgp);
    }
}
