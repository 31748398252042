import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {baseFilterKeys, EntityFetchRequestPayload, EntityFetchServiceResponsePayload, Filter} from '@redux/entity';

import {globalConfig} from '../../../configuration';

import {BaseFetchApiService} from './BaseFetchApiService';
import {RestRequestFactory} from './restRequestFactory';
import {RestService} from './restService';

export type OccupationsApiFilterKeys = (typeof baseFilterKeys)[number];

@injectable()
export class OccupationsApiService extends BaseFetchApiService {
    private readonly apiUrl: string;
    private readonly apiBaseUrl: string;

    constructor(@inject(ServiceTypes.RestService) httpService: RestService, @inject(ServiceTypes.AutoMapper) mapper: Mapper) {
        super(httpService, mapper, new OccupationsApiBuilder());
        this.apiBaseUrl = globalConfig.getConfig().playerManagementApiUrl;
        this.apiUrl = `${this.apiBaseUrl}/api/v1`;
    }

    public getOccupations(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        const request = {...requestPayload, body: {lookupId: 1}};
        return this.post(`${this.apiUrl}/lookup`, request);
    }
}

export class OccupationsApiBuilder extends RestRequestFactory<OccupationsApiFilterKeys> {
    protected buildFilter(_filter: Filter<OccupationsApiFilterKeys>): URLSearchParams {
        return new URLSearchParams();
    }
}
