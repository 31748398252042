import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useMarketingBonusCodeCreateActionClasses = makeStyles()((theme: CustomTheme) => ({
    marketingBonusCodeAddActionInput: {
        width: '100%',
    },
    marketingBonusCodeAddActionButtons: {
        display: 'flex',
        marginTop: theme.spacing(4),
        marginLeft: 'auto',
        columnGap: theme.spacing(1.25),
    },
    marketingBonusCodeAddNewButton: {
        height: theme.custom.denseButtonHeight,
    },

    marketingBonusCodeModalForm: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        marginTop: theme.spacing(2),
        rowGap: theme.spacing(2),
        alignItems: 'center',
    },
    marketingBonusCodeAddActionFooterButton: {
        height: theme.custom.formActionButtonHeight,
        borderRadius: theme.custom.roundedButtonRadius,
        padding: theme.spacing(1, 2),
        textTransform: 'none',
    },
}));
