import React from 'react';
import {Box} from '@mui/material';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {Message, MessageProps, MessageType} from '@components/alerts/Message';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {CustomTheme} from '@style';

type AutocompleteInfoMessageProps = Omit<MessageProps, 'variant' | 'messageType' | 'className'> & {
    subInfoMessage?: string;
    autocompleteState?: string;
};

const useStyles = makeStyles()((theme: CustomTheme) => ({
    autocompleteInfoMessageSubMessage: {
        color: theme.palette.warning.main,
        margin: theme.spacing(1, 0),
        ...(theme.typography.body2 as CSSObject),
        display: 'block',
    },
    autocompleteInfoMessageSubMessageIcon: {
        fontSize: theme.typography.body2.fontSize,
        marginRight: theme.spacing(1.25),
    },
    autocompleteInfoMessage: {
        ...(theme.typography.caption as CSSObject),
        padding: theme.spacing(0.5),
        whiteSpace: 'normal',
    },
}));

export function AutocompleteInfoMessage({
    containerRef,
    hideMessage,
    dataTestId,
    message,
    messageOrientation,
    subInfoMessage,
    autocompleteState,
}: AutocompleteInfoMessageProps) {
    const {classes: styles} = useStyles();
    return (
        <>
            {subInfoMessage && autocompleteState ? (
                <Box component="span" className={styles.autocompleteInfoMessageSubMessage}>
                    <Icon
                        color={IconColor.InProgress}
                        icon={CustomIcon.WarningOutline}
                        className={styles.autocompleteInfoMessageSubMessageIcon}
                    />
                    {subInfoMessage}
                </Box>
            ) : null}
            <Message
                hideMessage={hideMessage}
                containerRef={containerRef}
                dataTestId={dataTestId}
                className={styles.autocompleteInfoMessage}
                message={message}
                variant="inherit"
                messageType={MessageType.Error}
                messageOrientation={messageOrientation}
            />
        </>
    );
}
