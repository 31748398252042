import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useModalClasses = makeStyles()((theme: CustomTheme) => ({
    modalDialogLimited: {
        height: theme.custom.limitedModalHeight,
        paddingBottom: theme.spacing(2),
    },
    modalTitle: {
        textAlign: 'center',
        '& .MuiTypography-root': {
            display: 'flex',
            alignItems: 'center',
        },
        position: 'relative',
    },
    modalSubtitle: {
        marginTop: theme.spacing(1),
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    modalContentNoTitle: {
        marginTop: '-40px',
    },
    modalTitleText: {
        textAlign: 'start',
        float: 'left',
    },
    modalCloseButton: {
        marginLeft: 'auto',
        position: 'absolute',
        right: '-10px',
        top: '-10px',
        width: '40px',
        height: '40px',
    },
    modalActions: {
        margin: theme.spacing(2, 0, 0, 0),
        justifyContent: 'center',
    },
    modalActionsButtonsContainer: {
        display: 'flex',
        columnGap: theme.spacing(1.25),
        marginLeft: 'auto !important',
    },
    modalSubInfoMessageContainer: {
        color: theme.palette.secondary.main,
    },
}));
