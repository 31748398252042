import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';

import {SquareIconButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {ModalContext} from '@components/modal';

import {useAsyncActionState} from '../../shared/async-action/hooks';
import {ruleActions} from '../actions';

import {RuleModal} from './RuleModal';

const localized = defineMessages({
    modalTitle: {
        id: 'EditRuleButton_modalTitle',
        defaultMessage: 'Edit Rule',
    },
});

type EditRuleActionButtonProps = {
    ruleId: string;
};
export function EditRuleButton({ruleId}: EditRuleActionButtonProps) {
    const {openModal, closeModal} = useContext(ModalContext);
    const {isProgress} = useAsyncActionState(ruleActions.editRule);

    const handleClick = () => {
        openModal({
            title: <LocalizedText label={localized.modalTitle} />,
            body: <RuleModal ruleId={ruleId} mode="edit" onClose={closeModal} />,
            styleParams: {
                isLimitedBody: false,
            },
        });
    };

    return <SquareIconButton onClick={handleClick} iconClassName={CustomIcon.WriteOutline} disabled={isProgress} />;
}
