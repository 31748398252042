import {defineMessages} from 'react-intl';

export const localizedFilters = defineMessages({
    filterAnyValue: {
        id: 'filterAnyValue',
        defaultMessage: 'Any',
    },
    filterRemoveSelection: {
        id: 'filterRemoveSelection',
        defaultMessage: 'Clear All',
    },
    openDurationFilter: {
        id: 'filterOpenDuration',
        defaultMessage: 'Open Duration',
    },
    rangeFilterLabel: {
        id: 'filterRangeLabel',
        defaultMessage: 'Relative Hour(s)',
    },
    statusFilterPlaceholder: {
        id: 'filterStatusPlaceholder',
        defaultMessage: 'Any',
    },
    searchFilterPlaceholder: {
        id: 'searchFilterPlaceholder',
        defaultMessage: 'Search',
    },
});
