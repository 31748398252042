import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {DataGridColumnsPanel} from './DataGridColumnsPanel';
import {GlideGrid, GlideGridColumnsConverter, GlideGridPropsConverter} from './glide-grid';
import MuiDataGrid, {MuiDataGridColumnsConverter, MuiDataGridPropsConverter} from './mui';
import {DataGridColumn, DataGridProps, IModuleGridItem} from './types';

export type DataGridClientProps<TEntity extends IModuleGridItem, TColumns extends string> = Omit<
    DataGridProps<TEntity, TColumns, DataGridColumn<TEntity, TColumns>>,
    'mode' | 'paging' | 'onPageChange' | 'onPageSizeChange' | 'sortModel' | 'onSortChange'
>;

export function DataGridClient<TEntity extends IModuleGridItem, TColumns extends string>(props: DataGridClientProps<TEntity, TColumns>) {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    function handlePageChange(page: number) {
        setPage(page);
    }

    function handlePageSizeChange(page: number) {
        setPageSize(page);
    }

    return (
        <DataGrid
            {...props}
            mode="client"
            paging={{page, pageSize, rowCount: props.rows?.length}}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
        />
    );
}

export type DataGridServerProps<TEntity extends IModuleGridItem, TColumns extends string> = Omit<
    DataGridProps<TEntity, TColumns, DataGridColumn<TEntity>>,
    'mode'
>;

export function DataGridServer<TEntity extends IModuleGridItem, TColumns extends string>(props: DataGridServerProps<TEntity, TColumns>) {
    return <DataGrid {...props} mode="server" />;
}

function DataGrid<TEntity extends IModuleGridItem, TColumns extends string>(
    props: DataGridProps<TEntity, TColumns, DataGridColumn<TEntity>>
) {
    const dispatch = useDispatch();
    const {formatMessage, formatNumber} = useIntl();
    const muiGridPropsConverter = useRef(new MuiDataGridPropsConverter<TEntity, TColumns, DataGridColumn<TEntity>>());
    const muiGridColumnsConverter = useRef(new MuiDataGridColumnsConverter<TEntity>(formatMessage));
    const glideGridPropsConverter = useRef(new GlideGridPropsConverter<TEntity, TColumns, DataGridColumn<TEntity>>());
    const glideGridColumnsConverter = useRef(new GlideGridColumnsConverter<TEntity>(formatMessage, formatNumber, dispatch));

    const [visibleColumnNames, setVisibleColumnNames] = useState<string[]>(props.configurableColumns?.visibleColumns);
    const columnsToRender = props.configurableColumns
        ? [...(props.configurableColumns?.pinnedColumns ?? []), ...(visibleColumnNames ?? [])]
        : props.columns?.map(c => c.field);

    useEffect(() => {
        setVisibleColumnNames(props.configurableColumns?.visibleColumns);
    }, [props.configurableColumns?.visibleColumns?.join()]);

    const sortedColumns = getSortedColumns(props.columns);

    function getSortedColumns(columns: DataGridColumn<TEntity>[]): DataGridColumn<TEntity>[] {
        let result: DataGridColumn<TEntity>[] = columns;
        if (columnsToRender?.length) {
            const sortedFieldNames: string[] = [...new Set([...columnsToRender, ...columns?.map(c => c.field)])];
            result = sortedFieldNames.map(name => columns?.find(c => c.field === name))?.filter(c => c);
        }

        return result;
    }

    function handleVisibilityUpdate(visibleColumns: TColumns[]) {
        setVisibleColumnNames(visibleColumns);
        if (props.configurableColumns?.onVisibleColumnsChange) {
            props.configurableColumns.onVisibleColumnsChange(visibleColumns);
        }
    }

    return (
        <>
            {props.configurableColumns ? (
                <DataGridColumnsPanel
                    allColumns={sortedColumns}
                    visibleColumns={visibleColumnNames}
                    pinnedColumnNames={props.configurableColumns.pinnedColumns}
                    onColumnsVisibilityUpdate={handleVisibilityUpdate}
                />
            ) : null}
            {props.type === 'mui' ? (
                <MuiDataGrid
                    {...muiGridPropsConverter.current.convert(props)}
                    columns={[...muiGridColumnsConverter.current.convert(sortedColumns, columnsToRender)]}
                />
            ) : (
                <GlideGrid
                    {...glideGridPropsConverter.current.convert(props)}
                    columns={[...glideGridColumnsConverter.current.convert(sortedColumns, columnsToRender)]}
                />
            )}
        </>
    );
}
