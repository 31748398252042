import {defineMessages} from 'react-intl';

import {SecurityCaseType} from '@models/generated/graphql';

export const localizedSecurityCaseType = defineMessages({
    [SecurityCaseType.Undefined]: {
        id: 'securityCaseTypeUndefined',
        defaultMessage: 'Undefined',
    },
    [SecurityCaseType.Bot]: {
        id: 'securityCaseTypeBot',
        defaultMessage: 'Bot',
    },
    [SecurityCaseType.Collusion]: {
        id: 'securityCaseTypeCollusion',
        defaultMessage: 'Collusion',
    },
    [SecurityCaseType.Aml]: {
        id: 'securityCaseTypeAml',
        defaultMessage: 'Aml',
    },
    [SecurityCaseType.Kyc]: {
        id: 'securityCaseTypeKyc',
        defaultMessage: 'Kyc',
    },
    [SecurityCaseType.Finance]: {
        id: 'securityCaseTypeFinance',
        defaultMessage: 'Finance',
    },
    [SecurityCaseType.ExternalRequest]: {
        id: 'securityCaseTypeExternalRequest',
        defaultMessage: 'External Request',
    },
    [SecurityCaseType.ChipDumping]: {
        id: 'securityCaseTypeChipDumping',
        defaultMessage: 'Chip Dumping',
    },
    [SecurityCaseType.GeoLocation]: {
        id: 'securityCaseTypeGeoLocation',
        defaultMessage: 'Geo Location',
    },
    [SecurityCaseType.SharedInstance]: {
        id: 'securityCaseTypeSharedInstance',
        defaultMessage: 'Shared Instance',
    },
    [SecurityCaseType.BlacklistProbable]: {
        id: 'securityCaseTypeBlacklistProbable',
        defaultMessage: 'Blacklist Probable',
    },
});
