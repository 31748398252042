import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {OutlinedButton} from '@components/button/Buttons';
import {ChipType, ChipVariant} from '@components/chip/types';
import {OutlinedButtonDropdown} from '@components/dropdown/Dropdown';
import {DropdownChipItem} from '@components/dropdown/DropdownChipItem';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {LayoutDetailsItemGroup} from '@components/layout/LayoutDetailsItemGroup';
import {KycReasonCode} from '@models/generated/graphql';
import {ViewType} from '@redux/view';

import {localizedKYCReasonCodes} from '../../app/intl';
import {useAccountVerificationDetails, useKYCAction} from '../hooks';

const localized = defineMessages({
    reject: {
        id: 'KycStatusActions_reject',
        defaultMessage: 'Reject',
    },
    approve: {
        id: 'KycStatusActions_approve',
        defaultMessage: 'Approve',
    },
    onHold: {
        id: 'KycStatusActions_onHold',
        defaultMessage: 'On Hold',
    },
    kycReason: {
        id: 'KycStatusActions_kycReason',
        defaultMessage: 'KYC REASON',
    },
});

const availableOnHoldReasons: KycReasonCode[] = Object.values(KycReasonCode);

type KycStatusActionsProps = {
    id: string;
    type: 'kyc' | 'ndrp';
};

export function KycStatusActions({id, type}: KycStatusActionsProps) {
    const viewType: ViewType = 'KycActions';
    const {formatMessage} = useIntl();
    const {item} = useAccountVerificationDetails({
        id,
        viewType,
        fields: ['id', 'uid', 'account_verification_status', 'type', 'kyc_case_id'],
    });
    const {approve, onHold, reject, isActionVisible, isActionDisabled} = useKYCAction({
        actionType: type,
        id,
        currentStatus: item?.account_verification_status,
        accountVerificationType: item?.type,
        kycCaseId: item?.kyc_case_id,
        uid: item?.uid,
    });

    return (
        <LayoutDetailsItemGroup orientation="horizontal" id="kycActions">
            {isActionVisible('approve') ? (
                <OutlinedButton
                    key="approve"
                    disabled={isActionDisabled('approve')}
                    label={localized.approve}
                    onClick={approve}
                    startIcon={<Icon icon={CustomIcon.Confirm} color={IconColor.Success} />}
                    fullWidth
                    data-testid="approve"
                />
            ) : null}
            {isActionVisible('onHold') ? (
                <OutlinedButtonDropdown
                    key="on-hold"
                    disabled={isActionDisabled('onHold')}
                    label={localized.onHold}
                    startIcon={<Icon icon={CustomIcon.ClockOutline} color={IconColor.InProgress} />}
                    fullWidth
                    data-testid="onHold"
                    listTitle={localized.kycReason}
                >
                    {availableOnHoldReasons?.map(reason => (
                        <DropdownChipItem
                            key={reason}
                            value={formatMessage(localizedKYCReasonCodes[reason])}
                            type={ChipType.Mark}
                            variant={ChipVariant.DropdownDefault}
                            onClick={() => onHold(reason)}
                        />
                    ))}
                </OutlinedButtonDropdown>
            ) : null}
            {isActionVisible('reject') ? (
                <OutlinedButton
                    key="reject"
                    disabled={isActionDisabled('reject')}
                    label={localized.reject}
                    onClick={reject}
                    startIcon={<Icon icon={CustomIcon.CancelOutline} />}
                    fullWidth
                    data-testid="reject"
                />
            ) : null}
        </LayoutDetailsItemGroup>
    );
}
