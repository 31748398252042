import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {ValueChip} from '@components/chip/SquaredChipList';
import {ChipType, ChipVariant} from '@components/chip/types';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

import {SecurityCaseEditModel} from '../../player-actions/cases-actions/types';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    bulkSecurityCasesList: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
        margin: theme.spacing(2, 0),
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    bulkSecurityCasesListLabel: {
        color: theme.palette.text.secondary,
    },
}));

export type DropdownValueProps = {
    value: SecurityCaseEditModel[];
    label: string | MessageDescriptor;
};

export function BulkUserProfileSecurityCasesDropdownValue({value, label}: DropdownValueProps) {
    const {classes} = useClasses();

    return (
        <>
            <Box className={classes.bulkSecurityCasesListLabel}>
                <LocalizedText label={label} />
            </Box>
            <Box className={classes.bulkSecurityCasesList}>
                {value?.map(i => (
                    <ValueChip key={`${i.type}-${i.case_id}`} type={ChipType.Prefix} variant={ChipVariant.Default} label={i.type}>
                        {i.case_id}
                    </ValueChip>
                ))}
            </Box>
        </>
    );
}
