import React, {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Collapse, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons/types';
import {CustomTheme} from '@style';

import {selectModuleAction} from '../actions';
import {ModuleListItemProps} from '../types';

const useListItemStyles = makeStyles<{nestingLevel: number}, 'selected'>({name: 'ModuleListItem'})(
    (theme: CustomTheme, {nestingLevel}, classes) => ({
        root: {
            paddingLeft: nestingLevel ? theme.spacing(nestingLevel * 2) : null,
            marginLeft: nestingLevel ? theme.spacing(nestingLevel * 2) : null,
            width: nestingLevel ? theme.custom.sideBarItemWidth - parseInt(theme.spacing(2), 10) : null,
            height: theme.custom.sideBarItemHeight,
            minHeight: theme.custom.sideBarItemHeight,
            borderRadius: '6px',
            [`&.${classes.selected}, &.${classes.selected}:hover`]: {
                background: theme.palette.primary.light,
                color: theme.palette.primary.main,
                borderRadius: '6px',
                marginRight: nestingLevel ? theme.spacing(nestingLevel * 2) : null,
                width: nestingLevel ? theme.custom.sideBarItemWidth - parseInt(theme.spacing(2), 10) : null,
            },
            '&:hover': {
                background: theme.palette.primary.light,
                borderRadius: '6px',
                marginRight: nestingLevel ? theme.spacing(nestingLevel * 2) : null,
                width: nestingLevel ? theme.custom.sideBarItemWidth - parseInt(theme.spacing(2), 10) : null,
            },
        },
        selected: {},
    })
);

const useStyles = makeStyles()((theme: CustomTheme) => ({
    collapseList: {
        marginLeft: theme.spacing(3),
        padding: 0,
        borderLeft: '1px solid #cccccc',
    },
    listItemIcon: {
        minWidth: theme.spacing(4),
        fontSize: '18px',
    },
    listItemIconSelected: {
        minWidth: theme.spacing(4),
        color: theme.palette.primary.main,
        fontSize: '18px',
    },
    listItem: {
        minHeight: theme.spacing(6),
    },
    listItemWithSubModule: {
        '&:hover': {
            background: theme.palette.primary.light,
            borderRadius: '6px',
        },
        height: theme.custom.sideBarItemHeight,
        minHeight: theme.custom.sideBarItemHeight,
    },
    listItemAction: {
        display: 'flex',
        alignItems: 'center',
    },
    invisible: {
        visibility: 'hidden',
    },
    listItemArrow: {
        color: theme.palette.text.secondary,
    },
    listItemText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: theme.spacing(1),
    },
}));

const defaultModuleIcon = CustomIcon.Profile;

const ModuleListItem = memo(({url, title, name, nestingLevel, selectedModuleName, menuIcon, showItemText}: ModuleListItemProps) => {
    const {classes: listItemStyles} = useListItemStyles({nestingLevel});
    const {classes: styles} = useStyles();
    const dispatch = useDispatch();
    const selected = name === selectedModuleName;

    const handleClick = useCallback(() => {
        dispatch(selectModuleAction(name));
    }, [name]);

    const isListItemTextVisible = showItemText || nestingLevel !== 0;
    const listItemArrow = 'icon-arrow_up';

    return (
        <ListItem
            className={styles.listItem}
            button
            selected={name === selectedModuleName}
            classes={listItemStyles}
            component={RouterLink}
            to={url}
            key={name}
            onClick={handleClick}
        >
            {!nestingLevel && (
                <ListItemIcon className={selected ? styles.listItemIconSelected : styles.listItemIcon}>
                    <Box component="span" className={menuIcon ? menuIcon : defaultModuleIcon}></Box>
                </ListItemIcon>
            )}
            {isListItemTextVisible && (
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant="body2" className={styles.listItemText}>
                            <LocalizedText label={title} />
                        </Typography>
                    }
                />
            )}
            {!nestingLevel && (
                <Box className={`${styles.listItemAction} ${styles.invisible}`}>
                    <Box component="span" className={`${listItemArrow} ${styles.listItemArrow}`} fontSize={10}></Box>
                </Box>
            )}
        </ListItem>
    );
});

const ModuleListItemWithSubmodules = ({
    selectedModuleName,
    subRoutes,
    title,
    nestingLevel,
    showSubmodules,
    menuIcon,
}: ModuleListItemProps) => {
    const isSubmoduleSelected = subRoutes?.some(s => s.name === selectedModuleName);
    const [open, setOpen] = useState(isSubmoduleSelected);
    const {classes: styles} = useStyles();

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (isSubmoduleSelected !== open) {
            setOpen(isSubmoduleSelected);
        }
    }, [isSubmoduleSelected]);

    const listItemArrow = open ? CustomIcon.ArrowUp : CustomIcon.ArrowDown;

    return (
        <React.Fragment>
            <ListItem className={styles.listItemWithSubModule} button onClick={handleClick}>
                <ListItemIcon className={styles.listItemIcon}>
                    <Box component="span" className={menuIcon ? menuIcon : defaultModuleIcon}></Box>
                </ListItemIcon>
                {showSubmodules && (
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant="body2" className={styles.listItemText}>
                                <LocalizedText label={title} />
                            </Typography>
                        }
                    />
                )}
                {showSubmodules && (
                    <Box className={styles.listItemAction}>
                        <Box component="span" className={`${listItemArrow} ${styles.listItemArrow}`} fontSize={10}></Box>
                    </Box>
                )}
            </ListItem>
            <Collapse in={open} unmountOnExit>
                <List className={styles.collapseList}>
                    {showSubmodules &&
                        subRoutes.map(route => (
                            <ModuleListItem
                                key={route.name}
                                {...route}
                                nestingLevel={nestingLevel}
                                selectedModuleName={selectedModuleName}
                            />
                        ))}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

export {ModuleListItem, ModuleListItemWithSubmodules};
