import React from 'react';
import {Controller} from 'react-hook-form';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';

import {EditableSelect} from '@components/editable/EditableSelect';
import {FormError, RuleType, useValidationFormatter} from '@components/input';
import {Gender} from '@models/generated/graphql';
import {customerSupportUpdatePermissions} from '@models/permissions/permissions';

import {localizedGender} from '../../app/intl/shared-resources/gender';
import {LackOfPermissionIndicator, useMultiplePermissions, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {SelectOption} from '../../module-shared/types';
import {UserProfileModel} from '../../player-actions/pii-actions/types';
import {useReduxForm} from '../../shared/form/hooks';
import {personalInfoActions} from '../actions';

const localized = defineMessages({
    gender: {
        id: 'UserProfileGender_gender',
        defaultMessage: 'Gender',
    },
});

type UserProfileGenderProps = UserProfileModel & {
    disabled?: boolean;
};

const UserProfileGenderInternal = (props: UserProfileGenderProps) => {
    const isEditable = useMultiplePermissions({allowedPermissions: customerSupportUpdatePermissions});
    const validationMessageFormatter = useValidationFormatter();

    const {control, state, submit, cancel, handleSubmit} = useReduxForm<Omit<UserProfileModel, 'userId'>, UserProfileModel>({
        initialModel: props,
        asyncAction: personalInfoActions.editGender,
        map: m => ({
            user: {
                uid: props.userId,
                gender: m?.user?.gender,
            },
            userId: props.userId,
        }),
    });

    const getOptions = () => {
        return Object.values(Gender)
            .filter(i => i !== Gender.Undefined)
            .map(s => ({label: localizedGender[s], value: s} as SelectOption));
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Controller
                render={({field, fieldState}) => (
                    <Box>
                        <EditableSelect
                            mode={state.isProgress ? 'InProgress' : 'Read'}
                            value={field.value}
                            options={getOptions()}
                            onSubmit={field.onChange}
                            onSave={() => handleSubmit(submit)}
                            onCancel={cancel}
                            disabled={!isEditable || props.disabled}
                        ></EditableSelect>
                        {fieldState.invalid ? <FormError>{fieldState.error.message}</FormError> : <></>}
                    </Box>
                )}
                control={control}
                name="user.gender"
                defaultValue={props.user?.gender}
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localized.gender),
                }}
            ></Controller>
        </form>
    );
};

export const UserProfileGender = withMultiplePermission(UserProfileGenderInternal, {
    allowedPermissions: customerSupportUpdatePermissions,
    indicator: LackOfPermissionIndicator.Disabled,
});
