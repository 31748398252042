import {MultiKeyFilterTextValue} from '@components/filter/types';
import {Timestamp} from '@models/generated/graphql';
import {BaseSettingsType} from '@user-settings';

export class AgentLevelReportViewModel {
    id: string;
    week_start_date_ts: Timestamp;
    referrer_id: string;
    referrer_username?: string;
    poker_ngr?: number;
    casino_ggr?: number;
    internal_casino_ggr?: number;
    external_casino_ggr?: number;
    revenue_shared_total?: number;
    referrer_psp_fees?: number;
    carry_over_negative_balance?: number;
    adjusted_revenue_shared_total?: number;
    tier_1_revenue_shared?: number;
    tier_2_revenue_shared?: number;
    tier_3_revenue_shared?: number;
    tier_4_revenue_shared?: number;
    tier_5_revenue_shared?: number;
    tier_6_revenue_shared?: number;
    tier_1_poker_revenue_share?: number;
    tier_2_poker_revenue_share?: number;
    tier_3_poker_revenue_share?: number;
    tier_4_poker_revenue_share?: number;
    tier_5_poker_revenue_share?: number;
    tier_6_poker_revenue_share?: number;
    tier_1_casino_revenue_share?: number;
    tier_2_casino_revenue_share?: number;
    tier_3_casino_revenue_share?: number;
    tier_4_casino_revenue_share?: number;
    tier_5_casino_revenue_share?: number;
    tier_6_casino_revenue_share?: number;
    tier_1_generated_internal_casino_ggr?: number;
    tier_2_generated_internal_casino_ggr?: number;
    tier_3_generated_internal_casino_ggr?: number;
    tier_4_generated_internal_casino_ggr?: number;
    tier_5_generated_internal_casino_ggr?: number;
    tier_6_generated_internal_casino_ggr?: number;
    tier_1_generated_external_casino_ggr?: number;
    tier_2_generated_external_casino_ggr?: number;
    tier_3_generated_external_casino_ggr?: number;
    tier_4_generated_external_casino_ggr?: number;
    tier_5_generated_external_casino_ggr?: number;
    tier_6_generated_external_casino_ggr?: number;
}

export type AgentLevelReportViewModelKeys = keyof Omit<AgentLevelReportViewModel, 'id'>;

export type AgentLevelReportFilterName = 'ref_id_uname';

export type AgentLevelReportFilterKeys = 'text';
export type AgentLevelReportTextFilterKeys = 'refIdUname' | 'referrerId' | 'referrerUsername';

export type AgentLevelReportTextFilterValue = MultiKeyFilterTextValue<AgentLevelReportTextFilterKeys>;
export class AgentLevelReportFilterModel implements Record<AgentLevelReportFilterKeys, unknown> {
    text: AgentLevelReportTextFilterValue;
}

export class AgentLevelReportDownloadViewModel implements Record<AgentLevelReportViewModelKeys, string> {
    week_start_date_ts: string;
    referrer_id: string;
    referrer_username: string;
    poker_ngr: string;
    casino_ggr: string;
    internal_casino_ggr: string;
    external_casino_ggr: string;
    revenue_shared_total: string;
    referrer_psp_fees: string;
    carry_over_negative_balance: string;
    adjusted_revenue_shared_total: string;
    tier_1_revenue_shared: string;
    tier_2_revenue_shared: string;
    tier_3_revenue_shared: string;
    tier_4_revenue_shared: string;
    tier_5_revenue_shared: string;
    tier_6_revenue_shared: string;
    tier_1_poker_revenue_share: string;
    tier_2_poker_revenue_share: string;
    tier_3_poker_revenue_share: string;
    tier_4_poker_revenue_share: string;
    tier_5_poker_revenue_share: string;
    tier_6_poker_revenue_share: string;
    tier_1_casino_revenue_share: string;
    tier_2_casino_revenue_share: string;
    tier_3_casino_revenue_share: string;
    tier_4_casino_revenue_share: string;
    tier_5_casino_revenue_share: string;
    tier_6_casino_revenue_share: string;
    tier_1_generated_internal_casino_ggr: string;
    tier_2_generated_internal_casino_ggr: string;
    tier_3_generated_internal_casino_ggr: string;
    tier_4_generated_internal_casino_ggr: string;
    tier_5_generated_internal_casino_ggr: string;
    tier_6_generated_internal_casino_ggr: string;
    tier_1_generated_external_casino_ggr: string;
    tier_2_generated_external_casino_ggr: string;
    tier_3_generated_external_casino_ggr: string;
    tier_4_generated_external_casino_ggr: string;
    tier_5_generated_external_casino_ggr: string;
    tier_6_generated_external_casino_ggr: string;
}

export interface AgentLevelReportColumnSettings extends BaseSettingsType {
    week_start_date_ts: {};
    referrer_id: {};
    referrer_username: {};
    poker_ngr: {};
    casino_ggr: {};
    internal_casino_ggr: {};
    external_casino_ggr: {};
    revenue_shared_total: {};
    referrer_psp_fees: {};
    carry_over_negative_balance: {};
    adjusted_revenue_shared_total: {};
    tier_1_revenue_shared: {};
    tier_2_revenue_shared: {};
    tier_3_revenue_shared: {};
    tier_4_revenue_shared: {};
    tier_5_revenue_shared: {};
    tier_6_revenue_shared: {};
    tier_1_poker_revenue_share: {};
    tier_2_poker_revenue_share: {};
    tier_3_poker_revenue_share: {};
    tier_4_poker_revenue_share: {};
    tier_5_poker_revenue_share: {};
    tier_6_poker_revenue_share: {};
    tier_1_casino_revenue_share: {};
    tier_2_casino_revenue_share: {};
    tier_3_casino_revenue_share: {};
    tier_4_casino_revenue_share: {};
    tier_5_casino_revenue_share: {};
    tier_6_casino_revenue_share: {};
    tier_1_generated_internal_casino_ggr: {};
    tier_2_generated_internal_casino_ggr: {};
    tier_3_generated_internal_casino_ggr: {};
    tier_4_generated_internal_casino_ggr: {};
    tier_5_generated_internal_casino_ggr: {};
    tier_6_generated_internal_casino_ggr: {};
    tier_1_generated_external_casino_ggr: {};
    tier_2_generated_external_casino_ggr: {};
    tier_3_generated_external_casino_ggr: {};
    tier_4_generated_external_casino_ggr: {};
    tier_5_generated_external_casino_ggr: {};
    tier_6_generated_external_casino_ggr: {};
}

export type AgentLevelReportColumnSettingsKeys = keyof AgentLevelReportColumnSettings;
