import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {IModuleGridItem} from '@components/data-grid';
import {BoUser} from '@redux/entity';

import {ItemsPage} from 'src/common/types';
import {appSharedActions} from 'src/features/app-shared/actions';
import {MarketingCodeGridItem} from '../../features/marketing-code/types';
import {FormModuleData, IModuleItem} from '../../features/module-shared/types';
import {useTempStorageDataLoad} from '../../features/module-temp-storage/components/editable-components/hooks';
import {UserGridItem} from '../access-management/types';

import {gridDataSelector} from './selectors';

type MarketingBonusCodeData = {
    domain: string;
    data: {
        editForm?: FormModuleData<IModuleItem>;
        itemsPage: ItemsPage<IModuleGridItem>;
        route: string;
        state: {
            filter: string;
        };
    };
};

//TODO: [BO-2672] Remove dependency to features (view feature should be used to fetch data)
export function useMarketingBonusCodeData(): MarketingBonusCodeData {
    const dispatch = useDispatch();

    const gridData = useSelector(gridDataSelector);
    const {data: users} = useTempStorageDataLoad<UserGridItem[]>(nameof(BoUser));
    const bonusCodeData = {
        ...gridData,
        data: {
            ...gridData.data,
            itemsPage: {
                ...gridData.data.itemsPage,
                items: gridData.data.itemsPage.items.map((i: MarketingCodeGridItem) => {
                    return {
                        ...i,
                        addedByUsername: users?.find(u => u.id === i.added_by_uid)?.email ?? i.added_by_uid,
                    };
                }),
            },
        },
    };

    useEffect(() => {
        dispatch(appSharedActions.initUsersLoading());
    }, []);

    return bonusCodeData;
}
