import produce from 'immer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {AuthApiService} from '@services/deprecated';
import {RestHttpService} from '@services/deprecated';

import {ItemsPage, SearchFilter} from 'src/common/types';

import {Module} from './types';

export class ModuleService {
    private readonly httpService: RestHttpService;

    constructor(tracingService: ITracingService, userManager: UserManagerExtended) {
        this.httpService = new AuthApiService('modules', tracingService, userManager, 'submodules');
    }

    getItemsPage(): Observable<ItemsPage<Module>> {
        //TODO: do not need to inherit it from IFeatureService
        throw new Error('Method not implemented.');
    }

    getItem(id: string): Observable<Module> {
        return this.httpService.getItem(id);
    }

    getItems(): Observable<Module[]> {
        const modulesFilter = produce({} as SearchFilter, f => {
            f.extend = ['policies'];
        });

        return this.httpService.getItemsPage(modulesFilter).pipe<Module[]>(map<ItemsPage<Module>, Module[]>(response => response.items));
    }
}
