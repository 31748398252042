import React from 'react';
import {useDispatch} from 'react-redux';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {SquareIconButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons';

import {ruleActions} from '../actions';

const useStyles = makeStyles()(theme => ({
    orderCellContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(),
    },
    orderCellButton: {
        width: theme.spacing(3.25),
        height: theme.spacing(3.25),
        minWidth: 'unset',
    },
}));

export type OrderCellProps = {
    id: string;
    order: number;
    total: number;
};
function OrderCell({id, order, total}: OrderCellProps) {
    const {classes} = useStyles();
    const dispatch = useDispatch();

    function handleDownClick() {
        if (order !== total - 1) {
            dispatch(ruleActions.changeRuleOrder.request({ruleId: id, order: order + 1}));
        }
    }
    function handleUpClick() {
        if (order === 0) {
            dispatch(ruleActions.changeRuleOrder.request({ruleId: id, order: order - 1}));
        }
    }
    return (
        <div className={classes.orderCellContainer}>
            <SquareIconButton
                onClick={handleDownClick}
                iconClassName={CustomIcon.ArrowDown}
                className={classes.orderCellButton}
                iconFontSize={8}
                disabled={total - 1 === order}
            />
            <SquareIconButton
                onClick={handleUpClick}
                iconClassName={CustomIcon.ArrowUp}
                className={classes.orderCellButton}
                iconFontSize={8}
                disabled={order === 0}
            />
        </div>
    );
}

export function renderOrderCell(params: GridCellParams) {
    const value: OrderCellProps = params.value as OrderCellProps;
    return <OrderCell {...value} />;
}
