import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {push} from '@vs-centaurea/connected-react-router';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import {DisplayCountries, DisplayCountriesProps} from '@components/data-visualize';
import {CustomIcon} from '@components/icons/types';
import {agentReadPolicies} from '@models/permissions/permissions';
import {EntityType, UserLoginQueryFields, UserLoginServerFilterKeys} from '@redux/entity';
import {useView} from '@redux/view';
import {appendQuery, getFilterString, mapSortingToFilter} from '@utils';

import {RouteUrl} from 'src/common/routeEnums';
import {withMultiplePermission} from 'src/features/app/permission/PermissionHoc';

import {WorkspaceList} from './WorkspaceList';

const localized = defineMessages({
    loginHistoryButton: {
        id: 'LoggedInCountry_loginHistoryLabel',
        defaultMessage: 'Login History',
    },
});

type LoggedInCountryProps = DisplayCountriesProps & {
    uid?: string;
};

export const useClasses = makeStyles()(() => ({
    loggedInCountry: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    loggedInCountryIcon: {
        fontSize: 'inherit',
    },
}));

const LoggedInCountryInner = ({value, uid}: LoggedInCountryProps) => {
    const {classes} = useClasses();
    const dispatch = useDispatch();
    const {forceUpdateFilter} = useView('LoginHistory');

    function handleClick() {
        const routeUrls: RouteUrl[] = [RouteUrl.PlayerManagement, RouteUrl.LoginHistory];
        const sortField: UserLoginQueryFields = 'logged_at_ts.seconds';
        const uidFilterKey: UserLoginServerFilterKeys = 'uid';
        const sortingFilters = mapSortingToFilter({field: sortField, sort: 'desc'});
        const search = getFilterString('', false, {key: uidFilterKey, value: uid}, ...sortingFilters);
        const updatedRoute = appendQuery(routeUrls.join(''), search);

        dispatch(push(updatedRoute));
        forceUpdateFilter(EntityType.UserLogin, search);
    }

    return (
        <WorkspaceList>
            <Box className={classes.loggedInCountry}>
                <DisplayCountries value={value} />
                {uid && (
                    <OutlinedButton
                        smallPlus={true}
                        label={localized.loginHistoryButton}
                        startIcon={<Box component="span" className={CustomIcon.Details} />}
                        onClick={handleClick}
                    />
                )}
            </Box>
        </WorkspaceList>
    );
};

const restrictedPermissions = agentReadPolicies;

export const LoggedInCountry = withMultiplePermission(LoggedInCountryInner, {restrictedPermissions});
