import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {withStyledInputLabel} from '@components/input';
import {MultiSelectWithExclude, MultiSelectWithExcludeModel} from '@components/select';
import {CasinoGameFilterKeys} from '@models/casino-game';

import {Filter} from '../../../common/types';
import {SelectOption} from '../../module-shared/types';
import {useCasinoGames} from '../hooks';

const localized = defineMessages({
    title: {
        id: 'CasinoGameAutocomplete_displayName',
        defaultMessage: 'Casino Game Autocomplete',
    },
    searchPlaceholder: {
        id: 'CasinoGameAutocomplete_searchPlaceholder',
        defaultMessage: 'Search...',
    },
    games: {
        id: 'CasinoGameAutocomplete_games',
        defaultMessage: 'games',
    },
});

type CasinoGameAutocompleteProps = {
    sourceTypes: MultiSelectWithExcludeModel;
    aggregators?: MultiSelectWithExcludeModel;
    suppliers?: MultiSelectWithExcludeModel;
    value: MultiSelectWithExcludeModel;
    onValueChange: (options: MultiSelectWithExcludeModel) => void;
};

export function CasinoGameAutocomplete({sourceTypes, suppliers, aggregators, value, onValueChange}: CasinoGameAutocompleteProps) {
    const {formatMessage} = useIntl();

    const {
        items,
        handleFilterChange,
        handlePageChange,
        totalCount: filteredTotalCount,
        searchFilter,
    } = useCasinoGames({
        viewType: 'DepositBonusCasinoGamesAutocomplete',
        displayName: localized.title,
        sourceTypeFilter: sourceTypes,
        aggregatorFilter: aggregators,
        suppliersFilter: suppliers,
        validateFilter: filter => filter?.find(f => f.key === 'aggregators')?.value && filter?.find(f => f.key === 'supplier_codes')?.value,
    });
    const {totalCount} = useCasinoGames({
        viewType: 'DepositBonusCasinoGamesAutocomplete.Total',
        displayName: localized.title,
        sourceTypeFilter: sourceTypes,
        aggregatorFilter: aggregators,
        suppliersFilter: suppliers,
        validateFilter: filter => filter?.find(f => f.key === 'aggregators')?.value && filter?.find(f => f.key === 'supplier_codes')?.value,
    });

    function handleTitleFilterChange(value: string) {
        const titleFilterKey: CasinoGameFilterKeys = 'title';
        const newTitleFilter: Filter<any, CasinoGameFilterKeys> = {key: titleFilterKey, value};
        const filters = searchFilter?.filter?.filter(f => f.key !== titleFilterKey) ?? [];
        handleFilterChange([...filters, newTitleFilter]);
    }

    const options = items?.map<SelectOption>(i => ({value: i.code, label: i.title}));

    return (
        <MultiSelectWithExclude
            mode="server"
            value={value}
            options={options}
            hasSearch={true}
            searchPlaceholder={localized.searchPlaceholder}
            onValueChange={onValueChange}
            showResetButton
            showPagination
            onFilterChange={handleTitleFilterChange}
            page={searchFilter?.paging?.page}
            pageSize={searchFilter?.paging?.pageSize}
            onPageChange={handlePageChange}
            total={totalCount}
            filteredTotal={filteredTotalCount}
            itemsName={formatMessage(localized.games)}
        />
    );
}

export const CasinoGameAutocompleteFormInput = withStyledInputLabel(CasinoGameAutocomplete);
