import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {CommentFormModel} from '@components/comment';
import {useService} from '@inversify';
import {AccountVerificationViewModel} from '@models/account-verification';
import {EntityType} from '@models/generated/graphql';
import {useAuthUser} from '@auth';

import {
    ApplyNotesRequest,
    ApplyNoteStrategy,
    bulkActionsActions,
    BulkNoteApplyStepModal,
    BulkStepProps,
    itemsSelector,
} from '../../block-bulk-actions';
import type {NoteMappingExtraArgs} from '../../block-note/mapper';
import {EntityViewModel, NoteInputViewModel} from '../../block-note/types';

export function BulkKYCApplyNotesModal({onPrev, onNext}: BulkStepProps) {
    const dispatch = useDispatch();
    const user = useAuthUser();
    const mapper = useAutoMapper();
    const strategy = useService<ApplyNoteStrategy>('ApplyNoteStrategy');
    const accountVerifications: AccountVerificationViewModel[] = useSelector(itemsSelector<AccountVerificationViewModel>);

    function handleApply(commentFormModel: CommentFormModel) {
        const notesRequest: ApplyNotesRequest = {
            notes: accountVerifications?.map(i => {
                const noteInput: NoteInputViewModel = mapper.map(commentFormModel, CommentFormModel, NoteInputViewModel, {
                    extraArgs: (): NoteMappingExtraArgs => ({entity: getKycCaseEntity(i), postedByUid: user.sub}),
                });
                return {
                    ...noteInput,
                    entityId: i.id,
                };
            }),
        };
        dispatch(bulkActionsActions.apply({request: notesRequest, strategy}));

        onNext();
    }

    function getKycCaseEntity(accountVerification: AccountVerificationViewModel) {
        const entityItem: EntityViewModel = {
            parent: {
                id: accountVerification.uid,
                type: EntityType.Player,
            },
            id: accountVerification.id,
            type: EntityType.KycCase,
        };

        return entityItem;
    }

    return <BulkNoteApplyStepModal onPrev={onPrev} onNext={handleApply} />;
}
