import {ChipType, ChipVariant} from '@components/chip/types';
import {CustomIcon} from '@components/icons/types';
import {AccountVerificationStatus} from '@models/generated/graphql';

import {localizedInitiateSecurityKYC} from '../../../features/app/intl/shared-resources/initiateSecurityKYC';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export const getBulkAccountVerificationStatusChipsMapping = (chipType: ChipType) => ({
    [AccountVerificationStatus.InitLoginKyc]: {
        label: localizedInitiateSecurityKYC[AccountVerificationStatus.InitLoginKyc],
        chipType,
        chipVariant: ChipVariant.Disabled,
        startIconClass: CustomIcon.UserOutline,
    },
    [AccountVerificationStatus.InitWithdrawalKyc]: {
        label: localizedInitiateSecurityKYC[AccountVerificationStatus.InitWithdrawalKyc],
        chipType,
        chipVariant: ChipVariant.Disabled,
        startIconClass: CustomIcon.TransferOutline,
    },
});
