import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {EditWithdrawalStatusModel} from '../../../features/transactions/types';
import {ServiceTypes} from '../../inversify';
import {map} from '../../otel';
import {RestRequest, RestResponse, ServiceResponsePayload} from '../types';

import {ApiRoutesService} from './apiRoutesService';
import {RestService} from './restService';

@injectable()
export class PaymentApiService {
    private readonly _apiRouteService: ApiRoutesService;
    protected readonly _restService: RestService;

    constructor(
        @inject(ServiceTypes.RestService) restService: RestService,
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService
    ) {
        this._restService = restService;
        this._apiRouteService = apiRouteService;
    }

    public setWithdrawalRiskStatus(model: EditWithdrawalStatusModel): Observable<RestResponse> {
        const endpoint = this._apiRouteService.getWithdrawalRiskStatusChangeEndpoint();
        return this._restService.post({endpoint, body: model});
    }

    public setWithdrawalPaymentStatus(model: EditWithdrawalStatusModel): Observable<RestResponse> {
        const endpoint = this._apiRouteService.getWithdrawalPaymentStatusChangeEndpoint();
        return this._restService.post({endpoint, body: model});
    }

    public getCashierLink(transactionId: string): Observable<ServiceResponsePayload<RestRequest, string>> {
        const endpoint = this._apiRouteService.getCashierLinkEndpoint(transactionId);
        return this._restService.get({endpoint}).pipe(map(r => ({...r, responsePayload: r.responsePayload.response.uri})));
    }
}
