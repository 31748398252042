import {createSelector} from 'reselect';
import {RootState} from 'typesafe-actions';

import {EntityState, EntityType} from '@redux/entity';

import {ViewsState, ViewState} from './reducers';
import {ViewEntityRequestStatus, ViewType} from './types';

export const viewsSelector = (state: RootState): ViewsState => state.views;

export const createViewSelector = (view: ViewType) => createSelector<RootState, ViewsState, ViewState>(viewsSelector, views => views[view]);

export const viewSelector = createSelector<RootState, ViewType, ViewsState, ViewType, ViewState>(
    viewsSelector,
    (_: RootState, viewType: ViewType): ViewType => viewType,
    (views: ViewsState, viewType: ViewType): ViewState => views[viewType]
);

type ViewEntityKeysSelectorParams = {
    entityType: EntityType;
    viewType: ViewType;
};

export const viewEntityKeysSelector = createSelector<
    RootState,
    ViewEntityKeysSelectorParams,
    ViewsState,
    ViewEntityKeysSelectorParams,
    string[]
>(
    viewsSelector,
    (_: RootState, params: ViewEntityKeysSelectorParams): ViewEntityKeysSelectorParams => params,
    (state: ViewsState, {viewType, entityType}: ViewEntityKeysSelectorParams) => state[viewType]?.entities?.[entityType]?.keys
);

type ViewEntityFilterSelectorParams = {
    entityType: EntityType;
    viewType: ViewType;
};

export const viewEntityFilterSelector = createSelector<
    RootState,
    ViewEntityFilterSelectorParams,
    ViewsState,
    ViewEntityFilterSelectorParams,
    string
>(
    viewsSelector,
    (_: RootState, params: ViewEntityFilterSelectorParams): ViewEntityFilterSelectorParams => params,
    (state: ViewsState, {viewType, entityType}: ViewEntityFilterSelectorParams) => state[viewType]?.entities?.[entityType]?.filter
);

export const viewEntityItemsSelector = createSelector<RootState, ViewEntityFilterSelectorParams, EntityState, string[], unknown[]>(
    (state: RootState, {entityType}: ViewEntityFilterSelectorParams) => state.entities.entities?.[entityType],
    (state: RootState, {entityType, viewType}: ViewEntityFilterSelectorParams) =>
        viewSelector(state, viewType)?.entities?.[entityType]?.keys,
    (items: EntityState, keys: string[]) => (items && keys ? keys?.map(key => items[key]) : [])
);

export const viewEntityTotalSelector = createSelector<
    RootState,
    ViewEntityFilterSelectorParams,
    ViewsState,
    ViewEntityFilterSelectorParams,
    number
>(
    viewsSelector,
    (_: RootState, params: ViewEntityFilterSelectorParams): ViewEntityFilterSelectorParams => params,
    (state: ViewsState, {viewType, entityType}: ViewEntityFilterSelectorParams) => state[viewType]?.entities?.[entityType]?.total ?? 0
);

export const viewEntityRequestStatusSelector = createSelector<
    RootState,
    ViewEntityFilterSelectorParams,
    ViewsState,
    ViewEntityFilterSelectorParams,
    ViewEntityRequestStatus
>(
    viewsSelector,
    (_: RootState, params: ViewEntityFilterSelectorParams): ViewEntityFilterSelectorParams => params,
    (state: ViewsState, {viewType, entityType}: ViewEntityFilterSelectorParams) => state[viewType]?.entities?.[entityType]?.status
);
