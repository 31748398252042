import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {createEntityRealtimeActions} from './actions';
import {SubscriptionType} from './types';

export const useRealtimeUpdates = (entityDomain: string, subscriberDomain: string, types: SubscriptionType[]) => {
    const dispatch = useDispatch();

    const actions = createEntityRealtimeActions(entityDomain);

    useEffect(() => {
        dispatch(actions.enableRealtime({domain: subscriberDomain, types}));
        return () => {
            dispatch(actions.disableRealtime(subscriberDomain));
        };
    }, []);
};
