import {ActionType, createAction, createAsyncAction} from 'typesafe-actions';

import {AsyncActionMeta} from '@redux/async-action';
import {ViewEntityRequestStatus} from '@redux/view';

import {domain, EntityFetchServiceResponsePayload, EntityType} from './types/base';
import {EntityFetchRequestPayload} from './types/base';
import {INormalized} from './types/normalized';

export const entityActions = {
    fetch: createAction(`${domain}/fetch`)<EntityFetchActionPayload>(),
    fetchRequest: createAsyncAction(`${domain}/fetchRequest`, `${domain}/fetchRequestSuccess`, `${domain}/fetchRequestFailure`)<
        [EntityFetchRequestActionPayload, AsyncActionMeta],
        [EntityFetchServiceResponsePayload, AsyncActionMeta],
        [EntityFetchServiceResponsePayload, AsyncActionMeta]
    >(),
    save: createAction(`${domain}/save`)<EntitySaveActionPayload>(),
    clean: createAction(`${domain}/clean`)<EntityCleanActionPayload>(),
    updateItem: createAction(`${domain}/updateItem`)<UpdateItemPayload>(),

    increaseReferenceCounter: createAction(`${domain}/increaseReferenceCounter`)<EntityReferenceCounterActionPayload>(),
    decreaseReferenceCounter: createAction(`${domain}/decreaseReferenceCounter`)<EntityReferenceCounterActionPayload>(),
    cleanReferenceCounter: createAction(`${domain}/cleanReferenceCounter`)<EntityReferenceCounterActionPayload>(),

    collectGarbage: createAction(`${domain}/collectGarbage`)(),
    fetchStatus: createAction(`${domain}/fetchStatus`)<EntityFetchStatus>(),
};

//#region Types

export type EntityActions = ActionType<typeof entityActions>;

export type EntityFetchActionPayload = EntityFetchRequestPayload;

export type EntityFetchRequestActionPayload = EntityFetchRequestPayload;

export type EntitySaveActionPayload = {
    type: EntityType;
    items: Record<string, unknown>;
};

export type EntityCleanActionPayload = {
    type: EntityType;
    ids: string[];
};

export type EntityReferenceCounterActionPayload = {
    references: string[];
};

export type UpdateItemPayload<TModel extends INormalized = INormalized> = {
    type: EntityType;
    id: string;
    updatedItem: TModel;
};

export type EntityFetchStatus = {
    entityType: EntityType;
    filter: string;
    requestStatus: ViewEntityRequestStatus;
};

//#endregion Types
