import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    occupationAutocompleteDisplayName: {
        id: 'occupationAutocompleteDisplayName',
        defaultMessage: 'Occupation',
    },
    countryAutocompleteDisplayName: {
        id: 'countryAutocompleteDisplayName',
        defaultMessage: 'Country',
    },
    stateAutocompleteDisplayName: {
        id: 'stateAutocompleteDisplayName',
        defaultMessage: 'State',
    },
    incorrectValueMessage: {
        id: 'autocompleteIncorrectValueMessage',
        defaultMessage: 'Invalid value. Please use one of suggested options',
    },
});
