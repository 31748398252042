import React, {useState} from 'react';
import {useChainedCommands, useCurrentSelection} from '@remirror/react';
import {FromToProps} from 'remirror';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon, IconColor} from '@components/icons';
import {CustomTheme} from '@style';

import {EditorDropdownButton} from './EditorDropdownButton';
import {useEmoji} from './hooks';
import {ToolbarIcon} from './ToolbarIcon';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    addEmojiButtonGrid: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(0.5),
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    addEmojiButtonEmojiItem: {
        ...(theme.typography.h5 as CSSObject),
        height: theme.spacing(3.5),
        width: theme.spacing(4),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));

export function EmojiButton() {
    const {classes} = useClasses();

    const {state, getItemProps} = useEmoji();
    const chain = useChainedCommands();
    const enabled = !!state;
    const {to} = useCurrentSelection();
    const [isEmojiAdded, setIsEmojiAdded] = useState(false);

    function handleEmojiClick(identifier: string) {
        chain.addEmoji(identifier).run();
        setIsEmojiAdded(true);
    }
    function handleOpen() {
        if (!enabled) {
            chain.insertText(' ', {from: to, to}).suggestEmoji().run();
        }
        setIsEmojiAdded(false);
    }

    function handleClose() {
        // suggest emoji in remirror adds ' :' to the editor that allow us to view all avaliable emojis
        // addEmoji - puts emoji in editor without deleting ' :'
        // after adding or not adding emoji in editor we need to delete ' :' from the editor
        // if we added emoji then position of ' :' is {from: cursorPosition - 4, to: cursorPosition - 2}
        // if we close menu without adding emoji then position of ' :' is {from: cursorPosition - 2, to: cursorPosition}
        // all calculation of selection goes with check for preventing out of boundaries
        function getSuggestSimbolSelection(): FromToProps {
            let fromPosition: number;
            let toPosition: number;
            if (isEmojiAdded) {
                fromPosition = to - 4 < 1 ? 0 : to - 4;
                toPosition = to - 2 < 1 ? 0 : to - 2;
            } else {
                fromPosition = to - 2 < 1 ? 0 : to - 2;
                toPosition = to;
            }

            return {from: fromPosition, to: toPosition};
        }

        chain.delete(getSuggestSimbolSelection()).run();
    }

    return state?.exit ? null : (
        <EditorDropdownButton
            onDropdownClose={handleClose}
            onDropdownOpen={handleOpen}
            buttonContent={<ToolbarIcon color={IconColor.SuitBlack} icon={CustomIcon.Emoji} />}
            closeOnClickInside
        >
            <div className={classes.addEmojiButtonGrid}>
                {enabled &&
                    state?.list.map((emoji, index) => {
                        const shortcode = emoji.shortcodes?.[0] ?? emoji.annotation;
                        const props = getItemProps({item: emoji, index});
                        return (
                            <div
                                key={emoji.emoji}
                                className={classes.addEmojiButtonEmojiItem}
                                {...props}
                                onClick={() => handleEmojiClick(shortcode)}
                            >
                                <span>{emoji.emoji}</span>
                            </div>
                        );
                    })}
            </div>
        </EditorDropdownButton>
    );
}
