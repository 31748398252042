import React, {ReactElement, useEffect, useState} from 'react';
import {FC} from 'react';
import {DateRange} from 'react-day-picker';
import {defineMessages, MessageDescriptor} from 'react-intl';
import 'react-day-picker/dist/style.css';
import {FilledInputProps, InputProps, OutlinedInputProps} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {FilterProps} from '@components/filter/types';
import LocalizedText from '@components/i18n/LocalizedText';
import {FormError} from '@components/input/FormInput';
import {Locale} from '@components/types';
import {CustomTheme} from '@style';
import {formatDateRange} from '@utils';

import {CaptionComponentProps, DatePicker, DatePickerType} from './DatePicker';
import {StyledInputProps} from './StyledFormInputHoc';

export const localized = defineMessages({
    weekValidationMessage: {
        id: 'DateRangePicker_WeekValidationMessage',
        defaultMessage: 'Please select start and end of the week',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    dateRangePicker: {
        marginBottom: 0,
    },
    dateRangePickerInputSpacing: {
        paddingLeft: 0,
    },
    dateRangePickerInput: {
        height: theme.custom.denseButtonHeight,
    },
    dateRangePickerInputOpen: {
        height: theme.custom.denseButtonHeight,
        border: `1px solid ${theme.palette.primary.main} !important`,
        color: theme.palette.primary.main,
    },
}));

export type DateRangePickerProps = StyledInputProps &
    FilterProps<DateRange> & {
        captionComponent?: ({date, onChange}: CaptionComponentProps) => ReactElement;
        selectedMonth?: Date;
        rangeMode?: 'weeks' | 'default';
        displayMode?: 'mobile' | 'desktop';
        disableFutureDates?: Boolean;
        availableDateRangeForSelection?: {from?: Date; to?: Date};
        type?: DatePickerType;
        InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
        locale: Locale;
        pickerClassName?: string;
        onSelect?: (value: DateRange) => void;
        placeholder: string | MessageDescriptor;
    };

export const DateRangePicker: FC<DateRangePickerProps> = ({
    captionComponent,
    value,
    selectedMonth,
    rangeMode = 'default',
    displayMode = 'desktop',
    disableFutureDates,
    type = 'large',
    onChange,
    onSelect,
    locale,
    placeholder,
    pickerClassName,
    availableDateRangeForSelection,
    ...textInputProps
}) => {
    const [open, setOpen] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState(value);
    const {classes, cx} = useStyles();
    const clearValue = {} as DateRange;

    const isDateSelected = (value?.from !== null && value?.from !== undefined) || (value?.to !== null && value?.to !== undefined);
    const isWeekMode = rangeMode === 'weeks';
    const isValid = getValueIsValid(selectedDateRange);

    useEffect(() => {
        setSelectedDateRange(value);
    }, [`${value?.from}${value?.to}`]);

    function handleSelect(dateRange: DateRange) {
        setSelectedDateRange(dateRange ?? {from: null, to: null});
        if (onSelect) {
            onSelect(dateRange);
        }
    }

    function handleApplyClick() {
        if (isValid) {
            onChange(selectedDateRange);
        }
    }

    function handleCancelClick() {
        setSelectedDateRange(clearValue);
        onChange(clearValue);
    }

    function handleOutsideClick() {
        setSelectedDateRange(value);
    }

    function handleDropdownOpen() {
        setOpen(true);
    }

    function handleDropdownClose() {
        setOpen(false);
    }

    function isDateDisabled(date: Date) {
        const isDateDisabledBasedOnModeSelection = isWeekMode ? !isDateInWeekRange(date) : false;
        const isDataRangeSelectionLimited = availableDateRangeForSelection?.from || availableDateRangeForSelection?.to;
        const isDateInSelectionRange = availableDateRangeForSelection
            ? (availableDateRangeForSelection.from ? availableDateRangeForSelection.from <= date : true) &&
              (availableDateRangeForSelection.to ? date <= availableDateRangeForSelection.to : true)
            : true;
        return isDateDisabledBasedOnModeSelection || (isDataRangeSelectionLimited && !isDateInSelectionRange);
    }

    function isMonday(date: Date) {
        return date.getDay() === 1;
    }

    function isSunday(date: Date) {
        return date.getDay() === 0;
    }

    function isDateInWeekRange(date: Date) {
        const isRangeSelected =
            selectedDateRange?.from && selectedDateRange?.to && selectedDateRange.from.getTime() !== selectedDateRange.to.getTime();

        const isMondayDateSelected = selectedDateRange?.from && isMonday(selectedDateRange.from);

        const isSundayDateSelected = selectedDateRange?.to && isSunday(selectedDateRange.to);

        let result = isMonday(date) || isSunday(date);
        if (isRangeSelected) {
            result = date.getTime() === selectedDateRange.from.getTime() || date.getTime() === selectedDateRange.to.getTime();
        } else if (isMondayDateSelected) {
            result = isSunday(date) && date.getTime() > selectedDateRange.from.getTime();
        } else if (isSundayDateSelected) {
            result = isMonday(date) && date.getTime() < selectedDateRange.to.getTime();
        }

        return result;
    }

    function getValueIsValid(value: DateRange): boolean {
        let result = true;
        if (isWeekMode && (!value?.from || !value?.to || value?.from?.getTime() === value?.to?.getTime())) {
            result = false;
        }
        return result;
    }

    return (
        <DatePicker
            captionComponent={captionComponent}
            displayMode={displayMode}
            isOpen={open}
            isRangeMode
            isDateSelected={isDateSelected}
            value={selectedDateRange}
            formattedValue={formatDateRange(value?.from, value?.to)}
            onSelect={handleSelect}
            isDateDisabled={isDateDisabled}
            defaultMonthDate={selectedMonth}
            disableFutureDates={disableFutureDates}
            isApplyDisabled={!isValid || !selectedDateRange?.from}
            onApplyClick={handleApplyClick}
            onCancelClick={handleCancelClick}
            onOutsideClick={handleOutsideClick}
            onDropdownOpen={handleDropdownOpen}
            onDropdownClose={handleDropdownClose}
            placeholder={placeholder}
            type={type}
            className={cx(classes.dateRangePicker, pickerClassName)}
            locale={locale}
            footer={
                isWeekMode && !isValid ? (
                    <FormError>
                        <LocalizedText label={localized.weekValidationMessage} />
                    </FormError>
                ) : null
            }
            InputProps={{
                onChange: v => {
                    console.log(v);
                },
                classes: {
                    root: open ? classes.dateRangePickerInputOpen : classes.dateRangePickerInput,
                    input: classes.dateRangePickerInputSpacing,
                },
            }}
            {...textInputProps}
        />
    );
};
