import {defineMessages} from 'react-intl';

export const localizedNotificationHistory = defineMessages({
    emptyList: {
        id: 'userNotificationHistory_emptyList',
        defaultMessage: 'Notification history list is empty',
    },
    subject: {
        id: 'userNotificationHistory_subject',
        defaultMessage: 'Subject',
    },
    dateAndTime: {
        id: 'userNotificationHistory_dateAndTime',
        defaultMessage: 'Date and Time',
    },
    sender: {
        id: 'userNotificationHistory_sender',
        defaultMessage: 'Sender',
    },
});
