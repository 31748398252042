import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {emptyCellCharacter} from '../../../features/module-shared/utils';
import {StyledTooltip} from '../Tooltip';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    multiLineAutoAdjustedWidthTooltipLabel: {
        lineHeight: theme.typography.h4.lineHeight,
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
    },
    multiLineAutoAdjustedWidthTooltipLabelWhiteSpace: {
        whiteSpace: 'normal',
    },
    multiLineAutoAdjustedWidthTooltipLabelNoWrap: {
        whiteSpace: 'nowrap',
    },
    multiLineAutoAdjustedWidthTooltipOneLineLabel: {
        '-webkit-line-clamp': '1',
    },
    multiLineAutoAdjustedWidthTooltipTwoLineLabel: {
        '-webkit-line-clamp': '2',
    },
    multiLineAutoAdjustedWidthTooltipThreeLineLabel: {
        '-webkit-line-clamp': '3',
    },
    multiLineAutoAdjustedWidthTooltipFourLineLabel: {
        '-webkit-line-clamp': '4',
    },
    multiLineAutoAdjustedWidthTooltipFiveLineLabel: {
        '-webkit-line-clamp': '5',
    },
}));

type MultiLineAutoAdjustedWidthTooltipProps = {
    children: JSX.Element;
    linesCount?: number;
};

const minLinesCount = 1;
const maxLinesCount = 5;

export function MultiLineAutoAdjustedWidthTooltip({children, linesCount = minLinesCount}: MultiLineAutoAdjustedWidthTooltipProps) {
    const [isOverflowed, setIsOverflow] = useState(false);
    const textLinesCount = linesCount > minLinesCount ? (maxLinesCount > linesCount ? linesCount : maxLinesCount) : minLinesCount;
    const {classes, cx} = useClasses();
    const labelClassNameMapper: Record<number, string> = {
        1: classes.multiLineAutoAdjustedWidthTooltipOneLineLabel,
        2: classes.multiLineAutoAdjustedWidthTooltipTwoLineLabel,
        3: classes.multiLineAutoAdjustedWidthTooltipThreeLineLabel,
        4: classes.multiLineAutoAdjustedWidthTooltipFourLineLabel,
        5: classes.multiLineAutoAdjustedWidthTooltipFiveLineLabel,
    };

    const textElementRef = useRef<HTMLDivElement>(null);
    const textElementOneLineWidth = useRef<number>(null);

    useEffect(() => {
        if (textElementRef && textElementRef.current) {
            if (!textElementOneLineWidth.current) {
                textElementOneLineWidth.current = textElementRef.current.scrollWidth;
            }
            setIsOverflow(textElementOneLineWidth.current > textElementRef.current.clientWidth);
        }
    }, [textElementRef?.current?.clientWidth]);

    return children ? (
        <StyledTooltip
            element={
                <div
                    ref={textElementRef}
                    className={cx(
                        isOverflowed
                            ? classes.multiLineAutoAdjustedWidthTooltipLabelWhiteSpace
                            : classes.multiLineAutoAdjustedWidthTooltipLabelNoWrap,
                        labelClassNameMapper[textLinesCount],
                        classes.multiLineAutoAdjustedWidthTooltipLabel
                    )}
                >
                    {children}
                </div>
            }
            title={<div>{children}</div>}
            hideTooltip={!isOverflowed}
        />
    ) : (
        <>{emptyCellCharacter}</>
    );
}
