import React, {ChangeEvent, useState} from 'react';
import {defineMessages} from 'react-intl';
import EmbeddedIcon from '@mui/icons-material/WebAssetOutlined';
import {useCommands} from '@remirror/react';

import {FormTextInput} from '@components/input/FormInput';

import {EditorDropdownButton} from './EditorDropdownButton';

type Rect = {
    height: number;
    width: number;
};

const localized = defineMessages({
    src: {
        id: 'AddIframeButton_src',
        defaultMessage: 'Src',
    },
    height: {
        id: 'AddIframeButton_height',
        defaultMessage: 'Height',
    },
    width: {
        id: 'AddIframeButton_width',
        defaultMessage: 'Width',
    },
});

export function AddIframeButton() {
    const [src, setSrc] = useState('');
    const [size, setSize] = useState<Rect>({height: 250, width: 250});
    const {addIframe} = useCommands();

    function handleSizeChange(newSize: Rect) {
        if (newSize.height > 0 && newSize.width > 0) {
            setSize(newSize);
        }
    }

    function handleOpen() {
        setDefault();
    }

    function handleSubmit() {
        if (src) {
            addIframe({src, ...size});
            setDefault();
        }
    }

    function setDefault() {
        setSrc('');
        setSize({height: 250, width: 250});
    }

    function handleSrcChange(e: ChangeEvent<HTMLInputElement>) {
        setSrc(e.target.value as string);
    }

    function handleWidthChange(e: ChangeEvent<HTMLInputElement>) {
        handleSizeChange({width: Number(e.target.value), height: size.height});
    }

    function handleHeightChange(e: ChangeEvent<HTMLInputElement>) {
        handleSizeChange({height: Number(e.target.value), width: size.width});
    }

    return (
        <EditorDropdownButton
            onDropdownOpen={handleOpen}
            onDropdownClose={() => {}}
            buttonContent={<EmbeddedIcon />}
            applyAction
            onApplyClick={handleSubmit}
        >
            <FormTextInput type="text" value={src} onChange={handleSrcChange} label={localized.src} autoFocus />
            <FormTextInput type="number" value={size.height} onChange={handleHeightChange} label={localized.height} />
            <FormTextInput type="number" value={size.width} onChange={handleWidthChange} label={localized.width} />
        </EditorDropdownButton>
    );
}
