import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {FormTextInputDefault} from '@components/input/FormInput';
import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    multiStepFilterInput: {
        margin: theme.spacing(0, 2),
    },
    multiStepFilterInputLabel: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    multiStepFilterInputBackground: {
        background: theme.custom.palette.content.bulkActionSelectBackground,
        border: 0,
    },
    multiStepFilterInputSize: {
        height: theme.spacing(3.75),
    },
    multiStepFilterInputArrow: {
        fontSize: theme.spacing(1.5),
    },
    multiStepFilterInputInner: {
        height: theme.custom.formActionButtonHeight,
    },
}));

export type MultiStepFilterInputProps = {
    label?: string | MessageDescriptor;
    inputPlaceholder: string | MessageDescriptor;
    inputValue?: string | MessageDescriptor;
    size?: 'medium' | 'small';
    background?: 'default' | 'paper';
    inline?: boolean;
    onClick?: () => void;
};

export function MultiStepFilterInput({
    label,
    inputPlaceholder,
    inputValue,
    size = 'medium',
    background = 'default',
    inline = false,
    onClick,
}: MultiStepFilterInputProps) {
    const {classes, cx} = useClasses();

    return (
        <div className={inline ? null : classes.multiStepFilterInput}>
            {label ? (
                <Typography variant="body1" className={classes.multiStepFilterInputLabel}>
                    <LocalizedText label={label}></LocalizedText>
                </Typography>
            ) : null}
            <FormTextInputDefault
                size={size}
                variant="outlined"
                InputProps={{
                    className: cx(
                        size === 'small' && classes.multiStepFilterInputSize,
                        background === 'default' && classes.multiStepFilterInputBackground
                    ),
                }}
                onChange={null}
                value={inputValue}
                placeholder={inputPlaceholder}
                onClick={onClick}
                inputClassName={classes.multiStepFilterInputInner}
                endAdornment={
                    <Icon icon={CustomIcon.ArrowDown} color={IconColor.Secondary} className={classes.multiStepFilterInputArrow} />
                }
            />
        </div>
    );
}
