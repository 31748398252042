import {defineMessages} from 'react-intl';

import {KycStatus} from '@models/generated/graphql';

export const localizedKycStatus = defineMessages({
    [KycStatus.Successful]: {
        id: 'kycStatusPass',
        defaultMessage: 'Pass',
    },
    [KycStatus.Pending]: {
        id: 'kycStatusPending',
        defaultMessage: 'Pending',
    },
    [KycStatus.Failed]: {
        id: 'kycStatusFailed',
        defaultMessage: 'Failed',
    },
    [KycStatus.None]: {
        id: 'kycStatusPending',
        defaultMessage: 'Pending',
    },
});
