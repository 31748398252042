import React from 'react';
import {render} from 'react-dom';

import {globalConfig} from '../configuration';

import {App} from './components/App';
import {initContainer} from './inversify';
import {initOtel} from './otel';
import {initStore} from './store';

export function renderApp(element: HTMLElement) {
    const config = globalConfig.getConfig();
    initOtel(config);
    const container = initContainer(config);
    const {store, history} = initStore(container, config);
    return render(<App container={container} store={store} history={history} config={config}></App>, element);
}
