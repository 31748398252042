import {defineMessages} from 'react-intl';

import {ChipType, ChipVariant} from '@components/chip/types';
import {TicketStatus} from '@models/sportsbook-transaction/type';

import {ChipMapping} from './chipConfig';

const localized = defineMessages({
    unknown: {
        id: 'tiketStatusChipConfig_Unknown',
        defaultMessage: 'Unknown',
    },
    awaiting: {
        id: 'tiketStatusChipConfig_Awaiting',
        defaultMessage: 'Awaiting',
    },
    rejected: {
        id: 'tiketStatusChipConfig_Rejected',
        defaultMessage: 'Rejected',
    },
    accepted: {
        id: 'tiketStatusChipConfig_Accepted',
        defaultMessage: 'Accepted',
    },
    voided: {
        id: 'tiketStatusChipConfig_Voided',
        defaultMessage: 'Voided',
    },
    settled: {
        id: 'tiketStatusChipConfig_Settled',
        defaultMessage: 'Settled',
    },
    canceled: {
        id: 'tiketStatusChipConfig_Canceled',
        defaultMessage: 'Canceled',
    },
});

export function getTicketStatusChipMapping(): Record<TicketStatus, ChipMapping> {
    return {
        [TicketStatus.Unknown]: {
            label: localized.unknown,
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Default,
        },
        [TicketStatus.Awaiting]: {
            label: localized.awaiting,
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Orange,
        },
        [TicketStatus.Rejected]: {
            label: localized.rejected,
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Red,
        },
        [TicketStatus.Accepted]: {
            label: localized.accepted,
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Green,
        },
        [TicketStatus.Voided]: {
            label: localized.voided,
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },
        [TicketStatus.Settled]: {
            label: localized.settled,
            chipType: ChipType.Status,
            chipVariant: ChipVariant.LightBlue,
        },
        [TicketStatus.Canceled]: {
            label: localized.canceled,
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Disabled,
        },
    };
}
