import React from 'react';
import {defineMessages} from 'react-intl';
import {Typography} from '@mui/material';

import InfoMessage from '@components/alerts/InfoMessage';
import LocalizedText from '@components/i18n/LocalizedText';

const localized = defineMessages({
    notFoundMessage: {
        id: 'notFoundMessage',
        defaultMessage: 'Sorry, no such module was found.',
    },
    noPermissions: {
        id: 'noPermissionsMessage',
        defaultMessage: "Sorry, you don't have enough permissions to view the page.",
    },
    maintenance: {
        id: 'maintenanceMessage',
        defaultMessage: 'This page is currently under maintenance. Sorry for the inconvenience caused.',
    },
});

export const NotFoundPage = () => (
    <InfoMessage>
        <Typography variant="body1">
            <LocalizedText label={localized.notFoundMessage} />
        </Typography>
    </InfoMessage>
);

export const NoPermissionsPage = () => (
    <InfoMessage>
        <Typography variant="body1">
            <LocalizedText label={localized.noPermissions} />
        </Typography>
    </InfoMessage>
);

export const MaintenancePage = () => (
    <InfoMessage>
        <Typography variant="body1">
            <LocalizedText label={localized.maintenance} />
        </Typography>
    </InfoMessage>
);
