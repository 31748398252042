import React, {useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomIcon, Icon} from '@components/icons';
import {CustomTheme} from '@style';

import {SelectOption} from 'src/features/module-shared/types';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    radioGroupList: {
        width: '100%',
    },
    radioGroupListItem: {
        display: 'flex',
        justifyContent: 'space-between',
        height: theme.spacing(6),
        marginLeft: '0',
        marginRight: '0',
        padding: theme.spacing(0, 1.5),
    },
    radioGroupListItemSelected: {
        backgroundColor: theme.palette.primary.light,
    },
    radioGroupListCheck: {
        fontSize: theme.typography.h6.fontSize,
    },
}));

type RadioGroupListProps = {
    value: any;
    options: SelectOption[];
    scrollToSelected?: boolean;
    onChange: (e: React.ChangeEvent) => void;
};

export function RadioGroupList({value, options, scrollToSelected = false, onChange}: RadioGroupListProps) {
    const ref = useRef(null);
    const {formatMessage} = useIntl();
    const {classes} = useClasses();

    useEffect(() => {
        if (ref.current && scrollToSelected) ref.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, [scrollToSelected]);

    return (
        <RadioGroup value={value} onChange={onChange} className={classes.radioGroupList}>
            {options.map((option, index) => (
                <FormControlLabel
                    labelPlacement="start"
                    className={[classes.radioGroupListItem, option.value === value ? classes.radioGroupListItemSelected : null].join(' ')}
                    key={index}
                    ref={option.value === value ? ref : null}
                    value={option.value}
                    control={
                        <Radio
                            key={index}
                            icon={<></>}
                            checkedIcon={<Icon icon={CustomIcon.Confirm} className={classes.radioGroupListCheck} />}
                            color="secondary"
                        />
                    }
                    label={typeof option.label === 'string' ? option.label : formatMessage(option.label)}
                />
            ))}
        </RadioGroup>
    );
}
