import React from 'react';

import {AccountVerificationFilterKeys} from '@redux/entity';

import {Filter} from '../../../common/types';

import AccountVerificationLabelFilter from './AccountVerificationLabelFilter';
import AccountVerificationsFilterCountry from './AccountVerificationsFilterCountry';
import AccountVerificationsFilterDate from './AccountVerificationsFilterDate';
import AccountVerificationsFilterDuration from './AccountVerificationsFilterDuration';
import {
    AccountVerificationsFilterClosedNDRPStatuses,
    AccountVerificationsFilterClosedStatuses,
    AccountVerificationsFilterPendingStatuses,
} from './AccountVerificationsFilterStatuses';
import AccountVerificationsFilterText from './AccountVerificationsFilterText';
import AccountVerificationsFilterType from './AccountVerificationsFilterType';
import InitKYCReasonCodeFilter from './InitKYCReasonCodeFilter';

export type AccountVerificationsFilterType =
    | 'text'
    | 'type'
    | 'date'
    | 'pendingStatuses'
    | 'closedStatuses'
    | 'closedNDRPStatuses'
    | 'duration'
    | 'country'
    | 'reasonCode'
    | 'labels';

export type AccountVerificationsFilterProps = {
    filter: string;
    onFilterChange: (newFilter: Filter[]) => void;
    defaultFilter?: Filter<unknown, AccountVerificationFilterKeys>[];
};

type AccountVerificationsFiltersProps = AccountVerificationsFilterProps & {
    filters: AccountVerificationsFilterType[];
};

export function AccountVerificationsFilters({filters, ...props}: AccountVerificationsFiltersProps): JSX.Element {
    const filterMapping: Record<AccountVerificationsFilterType, React.ComponentType<AccountVerificationsFilterProps>> = {
        text: AccountVerificationsFilterText,
        type: AccountVerificationsFilterType,
        pendingStatuses: AccountVerificationsFilterPendingStatuses,
        closedStatuses: AccountVerificationsFilterClosedStatuses,
        closedNDRPStatuses: AccountVerificationsFilterClosedNDRPStatuses,
        duration: AccountVerificationsFilterDuration,
        country: AccountVerificationsFilterCountry,
        date: AccountVerificationsFilterDate,
        reasonCode: InitKYCReasonCodeFilter,
        labels: AccountVerificationLabelFilter,
    };
    return (
        <>
            {filters.map(type => {
                const FilterComponent = filterMapping[type];
                return <FilterComponent key={type} {...props} />;
            })}
        </>
    );
}
