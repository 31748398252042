import React, {useEffect} from 'react';
import {Controller, useFieldArray, UseFormReturn} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {Box, Checkbox, List, ListItem, ListItemText} from '@mui/material';

import {SecurityCaseType} from '@models/generated/graphql';

import {localizedSecurityCaseType} from '../../app/intl/shared-resources/securityCaseType';
import {getTempStorageKey} from '../../module-temp-storage/utils';
import {useClasses} from '../../player-actions/cases-actions/components/SecurityCasesFieldsForm.style';
import {getSecurityCasesTypes} from '../../player-actions/cases-actions/hooks';
import {EditSecurityCasesFieldModel, SecurityCaseEditModel} from '../../player-actions/cases-actions/types';

import {BulkUserProfileSecurityCasesAutocomplete} from './BulkUserProfileSecurityCasesAutocomplete';

type BulkSecurityCasesFieldsFormProps = {
    id: string;
    value: EditSecurityCasesFieldModel;
    typeName: string;
    form: UseFormReturn<EditSecurityCasesFieldModel>;
};

export const BulkUserProfileSecurityCasesFieldsForm = ({id, value, typeName, form}: BulkSecurityCasesFieldsFormProps) => {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();

    const {control, reset} = form;
    const {fields, replace} = useFieldArray<EditSecurityCasesFieldModel, 'cases', 'id'>({
        control,
        name: 'cases',
    });
    const fieldsCases = fields as unknown as SecurityCaseEditModel[];

    useEffect(() => {
        reset({
            cases: value.cases,
        });
    }, [id]);

    const getLabel = (caseType: SecurityCaseType): string => formatMessage(localizedSecurityCaseType[caseType]);

    const handleBulkAutocompleteChange = (securityCases: SecurityCaseEditModel[], caseType: SecurityCaseType) => {
        const otherCases = fieldsCases?.filter(i => i.type !== caseType);
        replace([...otherCases, ...securityCases]);
    };

    const caseTypes = getSecurityCasesTypes();

    return (
        <List classes={{padding: classes.securityCasesFieldsModalList}} data-testid="securityCasesList">
            {fieldsCases.map((securityCase, index) => (
                <Box key={`${securityCase.type}-${securityCase.case_id ?? securityCase.isNew}`}>
                    <Box display="none">
                        <Controller
                            render={({field}) => <input hidden {...field}></input>}
                            name={`cases.${index}.type`}
                            control={control}
                            defaultValue={securityCase.type}
                        />
                        <Controller
                            render={({field}) => <input hidden {...field}></input>}
                            name={`cases.${index}.case_id`}
                            control={control}
                            defaultValue={securityCase.case_id}
                        />
                        <Controller
                            render={({field}) => <Checkbox checked={field.value} value={field.value} color="secondary" hidden />}
                            name={`cases.${index}.isNew`}
                            control={control}
                            defaultValue={securityCase.isNew}
                        />
                    </Box>
                </Box>
            ))}
            {caseTypes.map((caseType, index) => {
                const typeCases = fieldsCases.filter(f => f.type === caseType);
                const defaultTypeCases = value?.cases?.filter(f => f.type === caseType);
                const fieldKey = getTempStorageKey(typeName, id, `cases.${index}.case_id`);
                return (
                    <ListItem className={classes.securityCasesFieldsModalListItem} key={`${caseType}-${index}`}>
                        <ListItemText primary={getLabel(caseType)} />
                        <Box className={classes.securityCasesFieldsModalSecondaryAction}>
                            <Box className={classes.securityCasesFieldsModalListItemAutocomplete} data-testid={`${caseType}_autocomplete`}>
                                <BulkUserProfileSecurityCasesAutocomplete
                                    classes={{root: classes.securityCasesFieldsModalListItemAutocomplete}}
                                    value={typeCases}
                                    defaultValue={defaultTypeCases}
                                    onChange={securityCases => handleBulkAutocompleteChange(securityCases, caseType)}
                                    fieldKey={fieldKey}
                                    type={caseType}
                                    userId={id}
                                />
                            </Box>
                        </Box>
                    </ListItem>
                );
            })}
        </List>
    );
};
