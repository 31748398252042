import {useEffect, useState} from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';

import {IModuleGridItem} from '@components/data-grid';
import {TransactionViewModel} from '@models/transaction';

import {BulkResultsRecord, ValidationResult} from '../block-bulk-actions';

import {localizedBulkStepValidation} from './components/BulkTransactionValidationStepContent';
import {bulkTransactionsMaxTemplateLine, ValidationCode} from './types';

export type ErrorItem = BulkResultsRecord & Partial<TransactionViewModel> & IModuleGridItem;

export function getValidationErrorItems(invalidItems: ValidationResult<TransactionViewModel>[]): ErrorItem[] {
    const {formatMessage} = useIntl();
    const [errorItems, setErrorItems] = useState<ErrorItem[]>([]);

    useEffect(() => {
        setErrorItems(getErrorItems());
    }, []);

    const getErrorItems = (): ErrorItem[] => {
        return invalidItems.reduce((items: ErrorItem[], result: ValidationResult<TransactionViewModel>) => {
            const model = result.item;
            return [...items, ...result.errorCodes.map((errorCode: ValidationCode) => getErrorItem(result.id, model.uid, errorCode))];
        }, []);
    };

    const getErrorItem = (id: number, uid: string, errorCode: ValidationCode): ErrorItem => {
        const errorMessageMapper: Record<ValidationCode, string | MessageDescriptor> = {
            [ValidationCode.AmountNotInteger]: localizedBulkStepValidation.amountNotInteger,
            [ValidationCode.AmountNotNumber]: localizedBulkStepValidation.amountNotNumber,
            [ValidationCode.AmountNotPositive]: localizedBulkStepValidation.amountNotPositive,
            [ValidationCode.AmountDecimalPartLengthIsNotValid]: localizedBulkStepValidation.amountDecimalPartLengthIsNotValid,
            [ValidationCode.LineIsTooLarge]: formatMessage(localizedBulkStepValidation.lineIsTooLarge, {
                maxTransactionLines: bulkTransactionsMaxTemplateLine,
            }),
            [ValidationCode.ReasonInvalid]: localizedBulkStepValidation.reasonCodeIsNotValid,
            [ValidationCode.TypeInvalid]: localizedBulkStepValidation.typeIsNotValid,
            [ValidationCode.UidNotExists]: localizedBulkStepValidation.uidNotExists,
            [ValidationCode.BalanceIsNotEnough]: localizedBulkStepValidation.balanceIsNotEnough,
        };

        return {
            id: `${id}_${errorCode}`,
            serverId: `${id}_${errorCode}`,
            rowIndex: id,
            uid,
            error: errorMessageMapper[errorCode],
        } as ErrorItem;
    };

    return errorItems;
}
