import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {FilterGroupNew, FilterGroupNewFilter} from '@components/filter/FilterGroupNew';
import {TextInputWithDropdownFilter} from '@components/filter/TextFilterWithDropdown';
import {FilterProps, FiltersProps} from '@components/filter/types';
import {sharedLocalization} from '@localization';

import {
    AgentLevelReportFilterModel,
    AgentLevelReportFilterName,
    AgentLevelReportTextFilterKeys,
    AgentLevelReportTextFilterValue,
} from '../types';

const localized = defineMessages({
    textFullFilterPlaceholder: {
        id: 'AgentLevelReportFilter_textFullFilterPlaceholder',
        defaultMessage: 'Search in Agent Level Report',
    },
    textReferrerIdPlaceholder: {
        id: 'AgentLevelReportFilter_textAgentIdPlaceholder',
        defaultMessage: 'Agent ID',
    },
    textReferrerUsernamePlaceholder: {
        id: 'AgentLevelReportFilter_textAgentUsernamePlaceholder',
        defaultMessage: 'Agent Username',
    },
});

type AgentLevelReportFiltersProps = FiltersProps<AgentLevelReportFilterModel, AgentLevelReportFilterName>;

export function AgentLevelReportFilters({model, onChange, availableFilters}: AgentLevelReportFiltersProps) {
    const placeholderMapper: Record<AgentLevelReportTextFilterKeys, MessageDescriptor> = {
        referrerId: localized.textReferrerIdPlaceholder,
        referrerUsername: localized.textReferrerUsernamePlaceholder,
        refIdUname: sharedLocalization.textFullFilter,
    };

    const filters: FilterGroupNewFilter<AgentLevelReportFilterModel, AgentLevelReportFilterName>[] = [
        {
            filterName: 'ref_id_uname',
            modelField: 'text',
            component: (props: FilterProps<AgentLevelReportTextFilterValue>) => (
                <TextInputWithDropdownFilter
                    options={[
                        {label: placeholderMapper.refIdUname, value: 'refIdUname'},
                        {label: placeholderMapper.referrerId, value: 'referrerId'},
                        {label: placeholderMapper.referrerUsername, value: 'referrerUsername'},
                    ]}
                    inputPlaceholder={localized.textFullFilterPlaceholder}
                    width="full"
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
    ];

    return (
        <FilterGroupNew
            model={model}
            onChange={onChange}
            mode="update-on-change"
            allFilters={filters}
            availableFilters={availableFilters}
        />
    );
}
