import {defineMessages} from 'react-intl';

export const localizedSecurityCases = defineMessages({
    editSecurityCaseModalTitle: {
        id: 'editSecurityCaseModalTitle',
        defaultMessage: 'Security cases for {username}',
    },
    editSecurityCaseIdPlaceholder: {
        id: 'editSecurityCaseIdPlaceholder',
        defaultMessage: 'Case Id',
    },
    activeSecurityCaseMessage: {
        id: 'activeSecurityCaseMessage',
        defaultMessage: 'Active {total} Security Case',
    },
    activeSecurityCasesMessageMultiple: {
        id: 'activeSecurityCasesMessageMultiple',
        defaultMessage: 'Active {total} Security Cases',
    },
    createCaseLabel: {
        id: 'createCaseLabel',
        defaultMessage: 'Create case',
    },
    enterCaseID: {
        id: 'enterCaseID',
        defaultMessage: 'Enter Case ID',
    },
    assignSecurityCaseId: {
        id: 'assignSecurityCaseId',
        defaultMessage: 'Assign Security Case ID',
    },
    securityCasesId: {
        id: 'securityCasesId',
        defaultMessage: 'Security Cases ID',
    },
});
