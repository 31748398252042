import {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment/moment';

import {useAutoMapper} from '@auto-mapper';
import {EmbeddedDashboardType} from '@models/generated/graphql';
import {EmbeddedReportFilterKeys, EmbeddedReportQueryFields, EntityType} from '@redux/entity';
import {UseDetailsViewEntityProps, UseDetailsViewEntityResult, useViewInit, viewActions} from '@redux/view';
import {EmbeddedReportServerModel} from '@services/embeddedReportService';
import {timestampSecondsToMoment} from '@utils';

import {EmbeddedReportViewModel, EmbeddedReportViewModelKeys} from './types';

type UseEmbeddedReportProps = UseDetailsViewEntityProps<EmbeddedReportFilterKeys, EmbeddedReportViewModelKeys> & {
    type?: EmbeddedDashboardType;
};

export function useEmbeddedReports({
    type,
    fields,
    viewType,
    displayName,
    validateFilter,
    defaultFilters = [],
}: UseEmbeddedReportProps): UseDetailsViewEntityResult<EmbeddedReportViewModel, EmbeddedReportViewModelKeys> {
    const extendedCleanDelay = 1800000; // 30 min
    const dispatch = useDispatch();
    const mapper = useAutoMapper();

    const queryFields: EmbeddedReportQueryFields[] = fields?.map(field =>
        mapper.map(field, nameof<EmbeddedReportViewModelKeys>(), nameof<EmbeddedReportQueryFields>())
    );

    const {
        items,
        searchFilter,
        viewEntity: {filter: filterString, status},
        handleFilterChange,
    } = useViewInit<EmbeddedReportServerModel, EmbeddedReportFilterKeys, EmbeddedReportQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.EmbeddedReport,
            fields: queryFields,
        },
        defaultFilters: [...defaultFilters, {key: 'type', value: type}],
        validateFilter,
        cleanDelay: extendedCleanDelay,
        blockFetchWithInvalidFilter: true,
    });

    const item = mapper.map(items[0], EmbeddedReportServerModel, EmbeddedReportViewModel);

    const updateTimer = useRef(null);

    useEffect(() => {
        if (item?.url) {
            if (updateTimer.current) {
                clearTimeout(updateTimer.current);
            }
            const delay = getDelayTime(item?.expire_at?.seconds);
            updateTimer.current = setTimeout(() => {
                dispatch(viewActions.update({views: [viewType], entity: EntityType.EmbeddedReport}));
            }, delay);
        }

        return () => clearTimeout(updateTimer.current);
    }, [item?.url]);

    function getDelayTime(expireTimeInSeconds: number): number {
        const diff = timestampSecondsToMoment(expireTimeInSeconds).subtract(5, 'minutes').diff(moment(), 'milliseconds');
        return diff > 0 ? diff : 0;
    }

    return {
        item,
        searchFilter,
        filterString,
        requestStatus: status,
        handleFilterChange,
    };
}
