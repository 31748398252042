import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {MessageOrientation} from '@components/alerts/Message';
import {EditableAutocompleteProps} from '@components/editable/EditableAutocomplete';
import {ErrorModal} from '@components/error';
import {PentagonSvgIcon} from '@components/icons/PentagonSvgIcon';
import {StyledInputProps} from '@components/input';
import {LabelGroup, LabelGroupSourceType} from '@models/generated/graphql';
import {withTraceErrorBoundary} from '@otel';
import {EntityType, Filter, LabelGroupQueryFields} from '@redux/entity';
import {ViewType} from '@redux/view';
import {CountriesApiFilterKeys} from '@services/rest-api/countriesApiService';
import {CustomTheme} from '@style';

import {StyledViewAutocomplete} from './ViewAutocomplete';

type PlayerLabelGroupAutocompleteBaseProps = Omit<EditableAutocompleteProps<Filter<CountriesApiFilterKeys>>, 'disabled'>;

type PlayerLabelGroupAutocompleteProps = PlayerLabelGroupAutocompleteBaseProps & StyledInputProps;

const localized = defineMessages({
    placeholder: {
        id: 'PlayerLabelGroupAutocompleteInto_placeholder',
        defaultMessage: 'Choose the group',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    playerLabelGroupPentagonSvgIcon: {
        display: 'inline-block',
        marginRight: theme.spacing(1),
    },
}));

function PlayerLabelGroupAutocompleteInner(props: PlayerLabelGroupAutocompleteProps) {
    const viewType: ViewType = 'PlayerLabelGroupAutocomplete';
    const {classes} = useStyles();
    const fields: LabelGroupQueryFields[] = ['id', 'name', 'color'];
    const selectedGroup = props.value ? ({id: props.value} as LabelGroup) : null;

    function optionMapper(value: LabelGroup) {
        return value
            ? {
                  label: value.name ?? '',
                  value: value.id,
                  startIcon: value.color ? (
                      <Box className={classes.playerLabelGroupPentagonSvgIcon}>
                          <PentagonSvgIcon color={value.color} />
                      </Box>
                  ) : null,
              }
            : null;
    }

    return (
        <StyledViewAutocomplete
            {...props}
            value={selectedGroup}
            viewType={viewType}
            entityType={EntityType.LabelGroup}
            displayName={localized.placeholder}
            fields={fields}
            optionMapper={optionMapper}
            valueMapper={(value: LabelGroup) => (value ? value.id : null)}
            hasLargeOptions={true}
            errorMessageOrientation={MessageOrientation.Floating}
            filterKey={'source_type'}
            filter={{source_type: LabelGroupSourceType.Custom}}
            validateFilter={() => true}
            mode="client"
        />
    );
}

export const PlayerLabelGroupAutocomplete = withTraceErrorBoundary(PlayerLabelGroupAutocompleteInner, ErrorModal);
