import ExcelJS from 'exceljs';

import {DataWorksheetColDefs, ExcelColDef, ExcelDataColDef} from '../types';

type ParseResult<TKeys extends string> = Record<TKeys | 'rowIndex', string>;

export class InvalidStructureError extends Error {}

export class BaseExcelDocumentBuilder {
    protected workbook: ExcelJS.Workbook;
    private firstDataRow = 2;

    constructor() {
        this.reset();
    }

    public setWorksheet(title: string, options?: Partial<ExcelJS.AddWorksheetOptions>): void {
        this.workbook?.addWorksheet(title, options);
    }

    public setColumns<TKey extends string>(sheetKey: string, colDefs: Partial<ExcelColDef<TKey>>[]): void {
        const sheet = this.workbook.getWorksheet(sheetKey);
        sheet.columns = colDefs;
    }

    public setHeaderStyles(sheetKey: string): void {
        const sheet = this.workbook.getWorksheet(sheetKey);
        const headerRow: ExcelJS.Row = sheet.getRow(1);
        headerRow.font = {bold: true};
        this.setHeaderCellBorders(headerRow);
    }

    public setValidation<TKey extends string>(sheetKey: string, colDefs: Partial<ExcelDataColDef<TKey>>[], maxRow: number): void {
        const sheet: ExcelJS.Worksheet = this.workbook.getWorksheet(sheetKey);
        const columnsWithValidation = colDefs.filter(c => c.validation);
        columnsWithValidation.forEach(c => this.setColumnValidation(sheet, c, maxRow));
    }

    public setData<TModel>(sheetKey: string, data: TModel[]): void {
        const sheet: ExcelJS.Worksheet = this.workbook.getWorksheet(sheetKey);
        sheet.addRows(data);
    }

    protected parseDataSheet<TKeys extends string>(sheet: ExcelJS.Worksheet, columns: DataWorksheetColDefs<TKeys>) {
        const result: ParseResult<TKeys>[] = [];
        sheet.eachRow((row: ExcelJS.Row) => {
            if (row.number >= this.firstDataRow) {
                result.push(this.parseRow(row, columns));
            } else {
                this.verifyColumnsStructure(row, columns);
            }
        });

        return result;
    }

    private verifyColumnsStructure<TKeys extends string>(row: ExcelJS.Row, columns: DataWorksheetColDefs<TKeys>) {
        const values = row.values as ExcelJS.CellValue[];
        Object.keys(columns).forEach((key: TKeys) => {
            const recievedValue = values[columns[key].colIndex]?.toString();
            const expectedValue = columns[key].header;
            if (recievedValue !== expectedValue) {
                throw new InvalidStructureError('Incorrect structure', {
                    cause: {
                        expectedValue,
                        recievedValue,
                    },
                });
            }
        });
    }

    protected parseRow<TKeys extends string>(row: ExcelJS.Row, columns: DataWorksheetColDefs<TKeys>): ParseResult<TKeys> {
        const values = row.values as ExcelJS.CellValue[];
        const rowDataModel: Partial<Record<TKeys, string>> = {};
        Object.keys(columns).forEach((key: TKeys) => (rowDataModel[key] = values[columns[key].colIndex]?.toString() ?? null));

        return {...rowDataModel, rowIndex: row.number.toString()};
    }

    protected setHeaderCellBorders(headerRow: ExcelJS.Row): void {
        headerRow.eachCell((cell: ExcelJS.Cell) => {
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'},
            };
        });
    }

    protected setColumnValidation<TKey extends string>(
        sheet: ExcelJS.Worksheet,
        colDef: Partial<ExcelDataColDef<TKey>>,
        maxDataRow: number
    ): void {
        const sheetColumn: ExcelJS.Column = sheet.getColumn(colDef.key);
        for (let rowIndex = this.firstDataRow; rowIndex <= maxDataRow; rowIndex++) {
            const cellWithValidation = sheet.getCell(rowIndex, sheetColumn.number);
            cellWithValidation.dataValidation = colDef.validation;
        }
    }

    protected reset() {
        this.workbook = new ExcelJS.Workbook();
    }
}
