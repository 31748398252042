import {defineMessages} from 'react-intl';

import {AccountVerificationType} from '@models/generated/graphql';

export const localizedAccountVerificationType = defineMessages({
    [AccountVerificationType.Payment]: {
        id: 'accountVerificationTypePayment',
        defaultMessage: 'Payment',
    },
    [AccountVerificationType.Security]: {
        id: 'accountVerificationTypeSecurity',
        defaultMessage: 'Security',
    },
    [AccountVerificationType.NdrpVerification]: {
        id: 'accountVerificationTypeNDRP',
        defaultMessage: 'NDRP',
    },
});
