import {RootEpic} from '@redux';

import {protectEpics} from '../../app/error-handling/epics';
import {patchEpic} from '../../module-temp-storage/epics';

import {actions} from './actions';

const setVerificationStatusEpic: RootEpic = patchEpic(actions.setVerificationStatus, (payload, _, container) => {
    return container.verificationActionsUpdateService.setVerificationStatus(payload.id, payload.item);
});

export const verificationActionsEpics = protectEpics(setVerificationStatusEpic);
