import {injectable} from 'inversify';
import {Observable} from 'rxjs';

import {CsvDocumentBuilder} from '@file/services/CsvDocumentBuilder';
import {IEntityFileAdapter} from '@file/services/IFileFactory';
import {DataWorksheetColDefs} from '@file/types';

@injectable()
export class CsvDocumentBuilderAdapter<TKeys extends string> implements IEntityFileAdapter<TKeys> {
    private readonly builder = new CsvDocumentBuilder();

    public createFile(data: Record<TKeys, string>[], keys: TKeys[], headers: Record<TKeys, string>): Observable<ArrayBuffer> {
        const columns = keys.reduce<DataWorksheetColDefs<TKeys>>((previousValue, key, index) => {
            previousValue[key] = {
                key: key,
                header: headers?.[key] ?? key,
                colIndex: index + 1,
            };
            return previousValue;
        }, {} as DataWorksheetColDefs<TKeys>);

        return this.builder.createDocument<TKeys>({
            title: 'CSV File',
            columns,
            data,
        });
    }
}
