import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {RelationalOperator} from '../types';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    startAdornmentRelationalOperator: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        backgroundColor: theme.custom.palette.content.backgroundLight,
        height: theme.custom.denseButtonHeight,
        width: theme.spacing(5),
        color: theme.custom.palette.status.white.light,
        fontSize: theme.spacing(2.25),
        borderRadius: `${theme.shape.borderRadius * 2}px 0 0 ${theme.shape.borderRadius * 2}px`,
    },
}));

type StartAdornmentRelationalOperatorProps = {
    operator: RelationalOperator;
};

export function StartAdornmentRelationalOperator({operator}: StartAdornmentRelationalOperatorProps) {
    const {classes} = useStyles();
    return <div className={classes.startAdornmentRelationalOperator}>{operator?.replace('==', '=')}</div>;
}
