import {defineMessages} from 'react-intl';

export const localizedTransactionMessages = defineMessages({
    transactionType: {
        id: 'T_transactionType',
        defaultMessage: 'Transaction Type',
    },
    transactionStatus: {
        id: 'T_transactionStatus',
        defaultMessage: 'Status',
    },
    transactionStartedDate: {
        id: 'T_transactionStartedDate',
        defaultMessage: 'Started Date',
    },
    transactionAmount: {
        id: 'T_transactionAmount',
        defaultMessage: 'Amount',
    },
    paymentMethod: {
        id: 'T_paymentMethod',
        defaultMessage: 'Method',
    },
    transactionId: {
        id: 'T_transactionId',
        defaultMessage: 'Transaction Id',
    },
    paymentMethodDescription: {
        id: 'T_paymentMethodDescription',
        defaultMessage: 'Method Description',
    },
    previousBalance: {
        id: 'T_previousBalance',
        defaultMessage: 'Previous Balance',
    },
    currentBalance: {
        id: 'T_currentBalance',
        defaultMessage: 'Current Balance',
    },
    paymentVendor: {
        id: 'T_paymentVendor',
        defaultMessage: 'Vendor',
    },
    paymentOption: {
        id: 'T_paymentOption',
        defaultMessage: 'Option',
    },
    currency: {
        id: 'T_currency',
        defaultMessage: 'Currency',
    },
});
