import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {useDateRangeFilter} from '@components/filter';
import {FilterProps} from '@components/filter/types';
import {DateRangePicker} from '@components/input';
import {Locale} from '@components/types';

import {localized} from './localized';

const useStyles = makeStyles()({
    dateRangeFilter: {
        width: 350,
    },
});

export type DateRangeFilterValue = {
    from?: number;
    to?: number | 'now';
};

type DateRangeFilterProps = FilterProps<DateRangeFilterValue> & {
    rangeMode?: 'default' | 'weeks';
    locale: Locale;
    availableDateRangeForSelection?: {from?: Date; to?: Date};
};

export function DateRangeFilter({
    value,
    onChange,
    rangeMode = 'default',
    locale,
    availableDateRangeForSelection,
    width = 'fixed',
}: DateRangeFilterProps) {
    const {classes, cx} = useStyles();
    const {
        value: dateRangeValue,
        handleChange,
        availableRangeForSelection: availableRangeForSelectionInternal,
    } = useDateRangeFilter({value, onChange}, availableDateRangeForSelection);
    return (
        <DateRangePicker
            value={dateRangeValue}
            onChange={handleChange}
            disableFutureDates
            rangeMode={rangeMode}
            locale={locale}
            pickerClassName={cx(width === 'fixed' && classes.dateRangeFilter)}
            hasBottomSpacing={false}
            placeholder={localized.placeholder}
            availableDateRangeForSelection={availableRangeForSelectionInternal}
            selectedMonth={dateRangeValue?.from}
        />
    );
}
