export enum RouteUrl {
    AccessManagement = '/access-management',
    Users = '/users',
    Roles = '/roles',
    PlayerManagement = '/player-management',
    RegistrationInformation = '/registration-information',
    LoginHistory = '/login-history',
    KYC = '/kyc',
    KYCDetails = '/kyc-details',
    NDRPDetails = '/ndrp-details',
    TransactionHistory = '/transaction-history',
    GameHistory = '/game-history',
    Finance = '/finance',
    BusinessMetrics = '/business-metrics',
    LegalContentManagement = '/legal-content-management',
    PrivacyPolicy = '/privacy-policy',
    TermsAndConditions = '/terms-and-conditions',
    UserAcceptanceVersion = '/user-acceptance-version',
    WithdrawalProcessing = '/withdrawal-processing',
    WithdrawalPendingCases = '/pending-cases',
    WithdrawalHistoryCases = '/history-cases',
    WithdrawalDetails = '/withdrawal-details',
    KycProcessing = '/kyc-processing',
    KycPendingCases = '/pending-cases',
    KycHistoryCases = '/history-cases',
    SecurityWorkspace = '/security-workspace',
    SecurityDetails = '/security-details',
    CustomerSupport = '/customer-support',
    CustomerSupportDetails = '/customer-support-details',
    MarketingFunction = '/marketing-function',
    MarketingBonusCode = '/marketing-bonus-code',
    BonusEngine = '/bonus-engine',
    BonusEngineDetails = '/bonus-engine-details',
    P2PReferralOfferingAdmin = '/p2p-referral-offering-admin',
    AgentManagement = '/agent-player-management',
    AgentReportPlayerDetails = '/agent-player-management-details',
    PlayerLevelReport = '/player-level-report',
    AgentLevelReport = '/agent-level-report',
    TransferReport = '/transfer',
    NoPermission = '/no-permission',
    TeamWorkspace = '/team-workspace',
    GameAdmin = '/game-admin',
    PLO5 = '/plo5',
    TemplateManagement = '/template-management',
    TableManagement = '/table-management',
    AgentReportVn = '/agent-player-management-vn',
    AgentReportKr = '/agent-player-management-kr',
    PlayerReportDaily = '/player-report-daily',
    PlayerReportWeekly = '/player-report-weekly',
    PathStatisticsVn = '/path-statistics',
    Rules = '/rules',
    AgentManagementPage = '/agent-management',
    NDRPProcessing = '/ndrp-processing',
    SportsbookOperation = '/sportsbook-operation',
    EmbeddedReport = '/embedded-report',
}
