import React from 'react';
import {defineMessages, FormattedNumber} from 'react-intl';
import {Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {Card} from '@components/card/Card';
import LocalizedText from '@components/i18n/LocalizedText';
import {RealtimeUpdatesMode} from '@redux/realtime';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {usePlayerReferrerSummaryDetails} from '../../block-player-referrer-summary-details';
import {RedeemRevenueShareButton, redeemRevenueSharePermissions} from '../../block-transaction-actions';

const localized = defineMessages({
    title: {
        id: 'Redeem_title',
        defaultMessage: 'Redeem',
    },
    redeemableAmount: {
        id: 'Redeem_redeemableAmount',
        defaultMessage: 'Redeemable amount',
    },
});

const useClasses = makeStyles()(theme => ({
    redeemContent: {
        display: 'flex',
        marginTop: theme.spacing(3),
        gap: theme.spacing(5),
        alignItems: 'center',
    },
    redeemableAmount: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),
    },
}));

type RedeemableAmountProps = {
    uid: string;
};

export function RedeemRevenueShareInner({uid}: RedeemableAmountProps) {
    const {classes} = useClasses();
    const {currency} = useJurisdictionConfig();
    const {item} = usePlayerReferrerSummaryDetails({
        id: uid,
        fields: ['redeem_pending_total'],
        viewType: 'RedeemableAmount',
        realtimeMode: RealtimeUpdatesMode.Silent,
        displayName: localized.title,
    });

    return (
        <Card title={localized.title}>
            <div className={classes.redeemContent}>
                <div className={classes.redeemableAmount}>
                    <Typography variant="h5">
                        <FormattedNumber value={item?.redeem_pending_total ?? 0} style="currency" currency={currency} />
                    </Typography>
                    <Typography variant="subtitle1">
                        <LocalizedText label={localized.redeemableAmount} />
                    </Typography>
                </div>
                <RedeemRevenueShareButton uid={uid} amount={item?.redeem_pending_total} />
            </div>
        </Card>
    );
}

export const RedeemRevenueShare = withMultiplePermission(RedeemRevenueShareInner, {allowedPermissions: redeemRevenueSharePermissions});
