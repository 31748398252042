import {createAsyncAction} from 'typesafe-actions';

import {PlayerReferral, UserProfile} from '@models/generated/graphql';
import {
    UserReferralExpirationDateFormModel,
    UserReferralFormModel,
    UserReferralRevenueShareFormModel,
} from '@services/playerReferralService';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';
import {UpdateAffiliateBtagModel} from '@services/userProfileService';

const domain = 'block-player-referral-actions';

export type UpdateReferrerInfoActionPayload = {
    referral: UserReferralFormModel;
    mode: 'add' | 'update' | 'remove';
};

export const playerReferralActions = {
    changeReferrerInfo: createAsyncAction(
        `${domain}/changeReferrerInfo`,
        `${domain}/changeReferrerInfoSucceed`,
        `${domain}/changeReferrerInfoFailed`
    )<UpdateReferrerInfoActionPayload, void, void>(),

    addReferrerInfo: createAsyncAction(`${domain}/addReferrerInfo`, `${domain}/addReferrerInfoSucceed`, `${domain}/addReferrerInfoFailed`)<
        UserReferralFormModel,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>
    >(),

    updateReferrerInfo: createAsyncAction(
        `${domain}/updateReferrerInfo`,
        `${domain}/updateReferrerInfoSucceed`,
        `${domain}/updateReferrerInfoFailed`
    )<
        UserReferralFormModel,
        ServiceResponsePayload<UserReferralFormModel | UpdateAffiliateBtagModel, UserProfile | PlayerReferral>,
        ServiceResponsePayload<UserReferralFormModel | UpdateAffiliateBtagModel, UserProfile | PlayerReferral>
    >(),

    removeReferrerInfo: createAsyncAction(
        `${domain}/removeReferrerInfo`,
        `${domain}/removeReferrerInfoSucceed`,
        `${domain}/removeReferrerInfoFailed`
    )<
        UserReferralFormModel,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>
    >(),

    editExpirationDate: createAsyncAction(
        `${domain}/editExpirationDateRequest`,
        `${domain}/editExpirationDateSucceed`,
        `${domain}/editExpirationDateFailure`
    )<
        UserReferralExpirationDateFormModel,
        ServiceResponsePayload<GqlMutationRequest, PlayerReferral>,
        ServiceResponsePayload<GqlMutationRequest, PlayerReferral>
    >(),

    editRevenueShare: createAsyncAction(
        `${domain}/editRevenueShareRequest`,
        `${domain}/editRevenueShareSucceed`,
        `${domain}/editRevenueShareFailure`
    )<
        UserReferralRevenueShareFormModel,
        ServiceResponsePayload<GqlMutationRequest, PlayerReferral>,
        ServiceResponsePayload<GqlMutationRequest, PlayerReferral>
    >(),
};
