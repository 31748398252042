import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

//TODO: [BO-2680] Move files to src/common/components/modal
type SideModalProps = {
    modalId: string;
    isOpen: boolean;
    children?: React.ReactNode;
    onClose: () => void;
};

const makeClasses = makeStyles()((theme: CustomTheme) => ({
    sideBarDrawerPaper: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: theme.custom.sideModalWidth,
            width: theme.custom.sideModalWidth,
        },
        background: theme.palette.primary.contrastText,
    },
}));

const SideModal = ({modalId, isOpen, onClose, children}: SideModalProps) => {
    const {classes} = makeClasses();

    const [opened, setOpened] = React.useState(isOpen);

    useEffect(() => {
        setOpened(isOpen);
    }, [isOpen]);

    const onModalClose = () => {
        setOpened(false);
        onClose();
    };

    return (
        <Box>
            <React.Fragment key={modalId}>
                <Drawer classes={{paper: classes.sideBarDrawerPaper}} anchor={'right'} open={opened} onClose={onModalClose}>
                    {children}
                </Drawer>
            </React.Fragment>
        </Box>
    );
};

export default SideModal;
