import {useAutoMapper} from '@auto-mapper';
import {PlayerBonus} from '@models/generated/graphql';
import {PlayerBonusViewModel, PlayerBonusViewModelKeys} from '@models/player-bonus';
import {BonusCodeServerFilterKeys, EntityType, PlayerBonusFilterKeys, PlayerBonusQueryFields} from '@redux/entity';
import {getNonEmptyValueValidator, UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {Sorting} from 'src/common/types';
import {useBonusCodes} from '../block-bonus-code/hooks';
import {BonusCodeDetailsViewModel, BonusCodeDetailsViewModelKeys} from '../block-bonus-code/types';

export function usePlayerBonuses(
    {
        viewType,
        fields,
        displayName,
        cleanDelay = 0,
        realtimeMode,
        triggers,
        defaultFilters,
        defaultPaging,
        defaultSorting,
        validateFilter,
        syncWithUrl = false,
    }: UseListViewEntityProps<PlayerBonusFilterKeys, PlayerBonusViewModelKeys>,
    bonusCodeProps?: Omit<UseListViewEntityProps<BonusCodeServerFilterKeys, BonusCodeDetailsViewModelKeys>, 'validateFilter'>
): UseListViewEntityResult<PlayerBonusViewModel, PlayerBonusViewModelKeys> {
    const mapper = useAutoMapper();

    const {
        items: playerBonuses,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<PlayerBonus, PlayerBonusFilterKeys, PlayerBonusQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.PlayerBonus,
            fields: mapFields(fields),
        },
        realtime: realtimeMode ? {entity: EntityType.PlayerBonus, mode: realtimeMode, triggers} : null,
        defaultSorting: mapSorting(defaultSorting),
        defaultPaging,
        defaultFilters,
        syncWithUrl,
        validateFilter: validateFilter ?? (() => true),
        cleanDelay,
    });

    const {items: bonusCodes} = useBonusCodes({
        ...bonusCodeProps,
        ids: playerBonuses?.map(i => i?.bonus_engine_code),
        validateFilter: filter =>
            bonusCodeProps?.fields?.length > 0 && getNonEmptyValueValidator<BonusCodeServerFilterKeys>('bonusId')(filter),
    });

    const playerBonusesVm: PlayerBonusViewModel[] = playerBonuses?.map(i => mapper.map(i, PlayerBonus, PlayerBonusViewModel));

    const items: PlayerBonusViewModel[] = playerBonusesVm?.map(i => {
        const bonusCode = bonusCodes?.find(b => b.bonus_id === i.bonus_engine_code);
        mapper.mutate(bonusCode, i, BonusCodeDetailsViewModel, PlayerBonusViewModel);
        return i;
    });

    function mapFields(fields: PlayerBonusViewModelKeys[]): PlayerBonusQueryFields[] {
        return mapper?.map<PlayerBonusViewModelKeys[], PlayerBonusQueryFields[]>(
            fields,
            nameof<PlayerBonusViewModelKeys>(),
            nameof<PlayerBonusQueryFields>()
        );
    }

    function mapField(field: PlayerBonusViewModelKeys): PlayerBonusQueryFields {
        return mapFields([field])[0];
    }

    function mapSorting(sorting: Sorting<PlayerBonusViewModelKeys>[]): Sorting<PlayerBonusQueryFields>[] {
        return sorting?.map(s => ({...s, field: mapField(s.field)}));
    }

    return {
        items,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    };
}
