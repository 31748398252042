import produce from 'immer';
import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {EntityType} from '@redux/entity';
import {ViewType} from '@redux/view';

import {RealtimeActions, realtimeActions} from './actions';
import {getSubscriptionKey} from './utils';

type RealtimeTriggerBasedViewSubscriber = Record<string, ViewType[]>;

type RealtimeEntitySubscribers = Partial<Record<EntityType, RealtimeTriggerBasedViewSubscriber>>;

type RealtimeViewUpdates = Partial<Record<ViewType, {entities: EntityType[]}>>;

export type RealtimeState = {
    subscribers: RealtimeEntitySubscribers;
    updates: RealtimeViewUpdates;
};

export class RealtimeReducerCreator {
    createReducer() {
        const subscriberReducers = createReducer<RealtimeEntitySubscribers, RealtimeActions>({})
            .handleAction(realtimeActions.saveSubscriber, (state, action) => {
                const updatedState = produce(state, draftState => {
                    const {entity, triggers, view} = action.payload;

                    draftState[entity] = draftState[entity] ?? {};
                    const subscriptionKeys = triggers?.map(trigger => getSubscriptionKey(entity, trigger.type, trigger.args)) ?? [];
                    subscriptionKeys?.forEach(key => {
                        if (!draftState[entity][key]) {
                            draftState[entity][key] = [];
                        }

                        const subscriptions = draftState[entity][key];

                        if (!subscriptions.includes(view)) {
                            subscriptions.push(view);
                        }
                    });
                });
                return updatedState;
            })
            .handleAction(realtimeActions.removeSubscriber, (state, action) => {
                const updatedState = produce(state, draftState => {
                    const {entity, triggers, view} = action.payload;

                    if (draftState[entity]) {
                        const subscriptionKeys = triggers?.map(trigger => getSubscriptionKey(entity, trigger.type, trigger.args)) ?? [];
                        subscriptionKeys?.forEach(key => {
                            const subscriptions = draftState[entity][key];
                            const index = subscriptions?.indexOf(view);
                            if (index > -1) {
                                subscriptions.splice(index, 1);
                            }
                        });
                    }
                });
                return updatedState;
            });

        const updatesReducer = createReducer<RealtimeViewUpdates, RealtimeActions>({})
            .handleAction(realtimeActions.saveUpdatedViews, (state, action) => {
                const updatedState = produce(state, draftState => {
                    const {entity, views} = action.payload;

                    views.forEach(view => {
                        if (!draftState[view]?.entities?.length) {
                            draftState[view] = {entities: []};
                        }

                        if (!draftState[view].entities.includes(entity)) {
                            draftState[view].entities.push(entity);
                        }
                    });
                });
                return updatedState;
            })
            .handleAction(realtimeActions.cleanUpdatedViews, () => ({}));

        return combineReducers<RealtimeState>({
            subscribers: subscriberReducers,
            updates: updatesReducer,
        });
    }
}
