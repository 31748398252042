import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {AjaxResponse} from '@services/rest-api';

import {IModuleItem} from '../../../features/module-shared/types';
import {ItemsPage, SearchFilter} from '../../types';

import {BaseHttpService} from './baseHttpService';

/**
 * @deprecated should use services from src/common/services
 */
export abstract class RestHttpService extends BaseHttpService {
    protected constructor(endpoint: string, tracingService: ITracingService, userManager: UserManagerExtended) {
        super(endpoint, tracingService, userManager);
    }

    getItems<TModel>(filter?: SearchFilter): Observable<TModel> {
        return this.getJSON<TModel>(`?${this.toSearchQuery(filter)}`);
    }

    getItemsPage<TModel>(filter?: SearchFilter): Observable<ItemsPage<TModel>> {
        return this.get(`?${this.toSearchQuery(filter)}`).pipe<ItemsPage<TModel>>(
            map<AjaxResponse, ItemsPage<TModel>>(response => {
                const total = this.getItemsTotalCount(response);
                return {items: response.response?.items ?? [], total: total ?? 0};
            })
        );
    }

    getItem<TModel>(id: string, filter?: SearchFilter): Observable<TModel> {
        return this.getJSON<TModel>(`/${id}${filter ? `?${this.toSearchQuery(filter)}` : ''}`);
    }

    abstract addItem<TModel extends IModuleItem>(item: TModel): Observable<string>;

    abstract editItem<TModel extends IModuleItem>(item: TModel): Observable<string>;

    abstract deleteItem(id: string): Observable<AjaxResponse>;

    protected abstract toSearchQuery(filter: SearchFilter): string;

    protected abstract getItemsTotalCount(response: AjaxResponse): number;
}
