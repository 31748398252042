import React from 'react';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    layoutQueueToolbarDetailsContainer: {
        display: 'flex',
        justifyContent: 'start',
        gap: theme.spacing(1.25),
        marginBottom: theme.spacing(2.5),
    },
}));

type LayoutQueueToolbarDetailsProps = {
    children?: React.ReactNode;
};

export function LayoutQueueToolbarDetails({children}: LayoutQueueToolbarDetailsProps) {
    const {classes} = useStyles();
    return <div className={classes.layoutQueueToolbarDetailsContainer}>{children}</div>;
}
