import {merge, Observable, of, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import {catchError, filter, takeUntil} from 'rxjs/operators';
import {isActionOf, PayloadAction, RootState} from 'typesafe-actions';

import {map, mergeMap} from '@otel';
import {AsyncAction, RootEpic} from '@redux';

import {cancelRequests} from 'src/common/actions';
import {LocalizedMessageData} from 'src/common/types';
import {ServiceContainer} from '../../app/inversify/serviceContainer';
import {protectEpics} from '../app/error-handling/epics';
import {successMessages} from '../app/intl/shared-resources/serverResponse';
import {getErrorByFailureAction} from '../app/intl/shared-resources/serverResponse';
import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import actions from './actions';
import {LoadPayload, MessageType, PatchPayload, RequestPayload} from './types';

const requestEpic =
    <TPayload extends RequestPayload, TRequestType extends string, TSuccessType extends string, TFailureType extends string>(
        successMappingFunction: (result: any, payload: TPayload) => TPayload,
        requestPayloadObservable: (
            action: PayloadAction<TRequestType, TPayload>
        ) => Observable<PayloadAction<string, any>[]> | Observable<PayloadAction<string, any>>,
        successPayloadObservable: (
            action: PayloadAction<TSuccessType, TPayload>,
            successMessage?: LocalizedMessageData[]
        ) => Observable<PayloadAction<string, any>[]> | Observable<PayloadAction<string, any>>,
        failurePayloadObservable: (
            action: PayloadAction<TFailureType, TPayload>
        ) => Observable<PayloadAction<string, any>[]> | Observable<PayloadAction<string, any>>
    ) =>
    (
        asyncAction: AsyncAction<TRequestType, TPayload, TSuccessType, TPayload, TFailureType, TPayload>,
        requestCallback: (payload: TPayload, state: RootState, dependencies: ServiceContainer) => Observable<any>,
        successMessageMappingFunction?: (response: TPayload) => LocalizedMessageData[]
    ) => {
        const patchRequestEpic: RootEpic = (action$, state$, dependencies) =>
            action$.pipe(
                filter(isActionOf(asyncAction.request)),
                mergeMap(action => {
                    const payloadAction = action as PayloadAction<TRequestType, TPayload>;
                    const requestPayload: TPayload = payloadAction.payload;

                    return merge(
                        requestPayloadObservable(payloadAction),
                        requestCallback(requestPayload, state$.value, dependencies).pipe(
                            map(result => asyncAction.success(successMappingFunction(result, requestPayload))),
                            takeUntil(action$.pipe(filter(isActionOf(cancelRequests)))),
                            catchError((err: AjaxError, source) => {
                                requestPayload.errorCode = err.status;
                                return merge(source, throwError({payload: requestPayload, err}));
                            })
                        )
                    ).pipe(catchError(({payload, err}, source) => merge(source, of(asyncAction.failure(payload)), throwError(err))));
                })
            );

        const patchSuccessEpic: RootEpic = action$ =>
            action$.pipe(
                filter(isActionOf(asyncAction.success)),
                mergeMap(action => {
                    const successAction = action as PayloadAction<TSuccessType, TPayload>;
                    return successPayloadObservable(
                        successAction,
                        successMessageMappingFunction ? successMessageMappingFunction(successAction.payload) : null
                    );
                })
            );

        const patchFailedEpic: RootEpic = action$ =>
            action$.pipe(
                filter(isActionOf(asyncAction.failure)),
                mergeMap(action => failurePayloadObservable(action as PayloadAction<TFailureType, TPayload>))
            );

        return protectEpics(patchRequestEpic, patchSuccessEpic, patchFailedEpic);
    };

const patchEpicCreator = <TRequestType extends string, TSuccessType extends string, TFailureType extends string>() =>
    requestEpic<PatchPayload, TRequestType, TSuccessType, TFailureType>(
        (result: any, payload: PatchPayload) => ({...payload, data: result}),

        (action: PayloadAction<TRequestType, PatchPayload>) =>
            of(actions.changeInProgressState({key: action.payload.modelKey, value: true})),

        (action: PayloadAction<TSuccessType, PatchPayload>, successMessage?: LocalizedMessageData[]) => {
            let infoMessageAction;
            if (action.payload.messageType === MessageType.ModuleMessage) {
                infoMessageAction = showMessageAction({message: successMessages.operationSuccessfullyCompleted});
            }
            if (action.payload.messageType === MessageType.FieldMessage) {
                infoMessageAction = actions.changeInfoState({
                    key: action.payload.fieldKey,
                    value: successMessage ?? [{message: successMessages.operationSuccessfullyCompleted}],
                });
            }

            const changeInProgressStateAction = actions.changeInProgressState({
                key: action.payload.modelKey,
                value: false,
            });
            return infoMessageAction ? of(infoMessageAction, changeInProgressStateAction) : of(changeInProgressStateAction);
        },

        (action: PayloadAction<TFailureType, PatchPayload>) => {
            let errorMessageAction;
            if (action.payload.messageType === MessageType.ModuleMessage) {
                errorMessageAction = showErrorAction({message: getErrorByFailureAction(action.type, action.payload.errorCode)});
            }
            if (action.payload.messageType === MessageType.FieldMessage) {
                errorMessageAction = actions.changeErrorState({
                    key: action.payload.fieldKey,
                    value: [{message: getErrorByFailureAction(action.type, action.payload.errorCode)}],
                });
            }

            const changeInProgressStateAction = actions.changeInProgressState({
                key: action.payload.modelKey,
                value: false,
            });

            return errorMessageAction ? of(errorMessageAction, changeInProgressStateAction) : of(changeInProgressStateAction);
        }
    );

export const patchEpic = patchEpicCreator();

const loadDataEpicCreator = <TRequestType extends string, TSuccessType extends string, TFailureType extends string, TFilter>() =>
    requestEpic<LoadPayload<TFilter>, TRequestType, TSuccessType, TFailureType>(
        (result: any, payload: LoadPayload<TFilter>) => ({...payload, data: result}),

        (action: PayloadAction<TRequestType, LoadPayload<TFilter>>) =>
            of(actions.changeLoadingState({key: action.payload.dataKey, value: true})),

        (action: PayloadAction<TSuccessType, LoadPayload<TFilter>>) =>
            of(
                actions.changeLoadingData({key: action.payload.dataKey, value: action.payload.data}),
                actions.changeLoadingState({key: action.payload.dataKey, value: false})
            ),

        (action: PayloadAction<TFailureType, LoadPayload<TFilter>>) =>
            of(actions.changeLoadingState({key: action.payload.dataKey, value: false}))
    );
export const loadDataEpic = loadDataEpicCreator();
