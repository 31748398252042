import {AccountVerificationStatusLog, CountryInfo, KycDocument, UserLabel} from '@models/generated/graphql';
import {AccountVerificationDocumentsMock, AccountVerificationMock} from '@models/mock/accountVerification';
import {TimestampKeys} from '@models/shared';
import {UserLabelGroupViewModel} from '@models/transaction/types';

import {BaseFilterKeys} from './base';

export type AccountVerificationTextFilterKeys = 'uid' | 'username' | 'uidUsername' | 'id' | 'email' | 'uid_un_em' | 'uidIdEmail' | 'labels';

export const AccountVerificationDateRangeFilterPrefix = 'initiatedAt' as const;

export type AccountVerificationFilterKeys =
    | BaseFilterKeys
    | 'accountVerificationStatus'
    | 'defaultAccountVerificationStatus'
    | 'type'
    | `${typeof AccountVerificationDateRangeFilterPrefix}.from`
    | `${typeof AccountVerificationDateRangeFilterPrefix}.to`
    | 'kycStatus'
    | 'registrationCountry'
    | 'reasonCode'
    | AccountVerificationTextFilterKeys;

type AccountVerificationKeys = keyof AccountVerificationMock;
const initiatedAtKey: AccountVerificationKeys = 'initiated_at';
const statusLogKey: AccountVerificationKeys = 'status_log';
type StatusLogKeys = keyof AccountVerificationStatusLog;
const statusLogLoggedAtKey: StatusLogKeys = 'logged_at';
const documentKey: AccountVerificationKeys = 'documents';
type DocumentKeys = keyof Pick<KycDocument, 'country_info' | 'uploaded_ts' | 'doc_ref_id' | 'status' | 'doc_type' | 'device_id'>;
const documentUploadAtKey: DocumentKeys = 'uploaded_ts';
const documentCountryInfoKey: DocumentKeys = 'country_info';
type DocumentCountryInfoKeys = keyof CountryInfo;
const labels: AccountVerificationKeys = 'user_labels';
const groupKey: keyof UserLabel = 'group';
const documentObjectKey: AccountVerificationKeys = 'documents_object';
type AccountVerificationDocumentsKey = keyof AccountVerificationDocumentsMock;
const idCardKey: AccountVerificationDocumentsKey = 'id_card';

export type AccountVerificationQueryFields =
    | AccountVerificationKeys
    | `${typeof documentObjectKey}.${AccountVerificationDocumentsKey}`
    | `${typeof initiatedAtKey}.${TimestampKeys}`
    | `${typeof statusLogKey}.${StatusLogKeys}`
    | `${typeof statusLogKey}.${typeof statusLogLoggedAtKey}.${TimestampKeys}`
    | `${typeof documentKey}.${DocumentKeys}`
    | `${typeof documentKey}.${typeof documentUploadAtKey}.${TimestampKeys}`
    | `${typeof documentKey}.${typeof documentCountryInfoKey}.${DocumentCountryInfoKeys}`
    | `${typeof labels}.${keyof Omit<UserLabel, 'group'>}`
    | `${typeof labels}.${typeof groupKey}.${keyof UserLabelGroupViewModel}`;

export type AccountVerificationSortingFields =
    | keyof Pick<
          AccountVerificationMock,
          'email' | 'id' | 'username' | 'account_verification_status' | 'iso_alpha2_country_code' | 'type' | 'uid' | 'kyc_case_id'
      >
    | `${typeof initiatedAtKey}.${TimestampKeys}`
    | `${typeof documentObjectKey}.${typeof idCardKey}.${typeof documentUploadAtKey}.${TimestampKeys}`;
