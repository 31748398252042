import {
    ApolloLink,
    concat,
    DefaultOptions,
    DocumentNode,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    Observable as ZenObservable,
    Operation,
    split,
} from '@apollo/client';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import fetch from 'cross-fetch';
import {ClientOptions, CloseCode, createClient} from 'graphql-ws';
import {inject, injectable} from 'inversify';
import moment from 'moment';
import {map, Observable} from 'rxjs';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {ITracingService} from '@otel/ITracingService';
import {UserManagerExtended} from '@auth';

import {ApolloClientProxy} from './ApolloClientProxy';

type AuthenticationType = 'oidc' | 'api-key' | 'none';

type AuthOptions = {
    apiKey?: string;
};

export interface IApolloClientBuilder {
    withHttp(url: string): IApolloClientBuilder;

    withWebsocket(url: string): IApolloClientBuilder;

    withAuth(authType: AuthenticationType, option?: AuthOptions): IApolloClientBuilder;

    withOidcAuth(): IApolloClientBuilder;

    withApiKeyAuth(igpGraphQlApiKey: string): IApolloClientBuilder;

    withClientMocking(cache: InMemoryCache, typeDefs: DocumentNode[]): IApolloClientBuilder;

    buildClient(): ApolloClientProxy<NormalizedCacheObject>;
}

@injectable()
export class ApolloClientGraphQlTransportWsBuilder implements IApolloClientBuilder {
    protected _httpLink: ApolloLink;
    protected _wsLink: ApolloLink;
    protected _authLink: ApolloLink;
    protected _tracingService: ITracingService;
    protected _cache: InMemoryCache;
    protected _typeDefs: DocumentNode[];
    protected _userManager: UserManagerExtended;

    constructor(
        @inject(ServiceTypes.TracingService) tracingService: ITracingService,
        @inject(ServiceTypes.UserManager) userManager: UserManagerExtended
    ) {
        this._tracingService = tracingService;
        this._userManager = userManager;
    }

    withHttp(url: string): IApolloClientBuilder {
        this._httpLink = new HttpLink({
            uri: url,
            fetch,
        });

        return this;
    }

    withWebsocket(url: string): IApolloClientBuilder {
        let tokenExpiryTimeout: NodeJS.Timeout = null;
        let pingPongTimeout: NodeJS.Timeout = null;
        const pongWaitInterval = 5000;
        let activeSocket: WebSocket = null;

        const clientOptions: ClientOptions = {
            url: url,
            connectionParams: async function () {
                const user = await this._userManager.getUser();
                const token = user?.access_token ?? null;
                return {token: token ? `${token}` : ''};
            }.bind(this),
            keepAlive: 60000, // how often ping message will be sent
            lazyCloseTimeout: 3000,
            retryAttempts: 10,
            shouldRetry: () => true,
            on: {
                connected: function (socket: WebSocket) {
                    activeSocket = socket;
                    // clear timeout on every connect for debouncing the expiry
                    if (tokenExpiryTimeout) {
                        clearTimeout(tokenExpiryTimeout);
                    }

                    // set a token expiry timeout for closing the socket
                    // with an `4403: Forbidden` close event indicating
                    // that the token expired. the `closed` event listener below
                    // will set the token refresh flag to true
                    this.getExpiredTimeout().subscribe({
                        next: (expiryTimeout: number) => {
                            if (expiryTimeout > 0) {
                                tokenExpiryTimeout = setTimeout(() => {
                                    closeSocket();
                                }, expiryTimeout);
                            } else {
                                closeSocket();
                            }
                        },
                        error: () => closeSocket(),
                    });

                    function closeSocket() {
                        if (socket.readyState === WebSocket.OPEN) {
                            socket.close(CloseCode.Forbidden, 'Forbidden');
                        }
                    }
                }.bind(this),
                ping: function (received: boolean) {
                    if (!received) {
                        // sent
                        pingPongTimeout = setTimeout(() => {
                            if (activeSocket.readyState === WebSocket.OPEN) {
                                activeSocket.close(CloseCode.ConnectionInitialisationTimeout, 'Request Timeout');
                            }
                        }, pongWaitInterval); // wait for the pong and then close the connection
                    }
                }.bind(this),
                pong: function (received: boolean) {
                    if (received) {
                        clearTimeout(pingPongTimeout); // pong is received, clear connection close timeout
                    }
                }.bind(this),
            },
        };

        const client = createClient(clientOptions);
        this._wsLink = new GraphQLWsLink(client);

        return this;
    }

    withOidcAuth(): IApolloClientBuilder {
        this._authLink = new ApolloLink((operation, forward) => {
            const extendAuthHeader = new ZenObservable<Operation>(observer => {
                this._userManager.getUserExtended().subscribe(u => {
                    operation.setContext({
                        headers: u.authHeaders,
                    });
                    observer.next(operation);
                    observer.complete();
                });
                return () => {};
            });

            return extendAuthHeader.flatMap(op => forward(op));
        });

        return this;
    }

    withApiKeyAuth(apiKey: string): IApolloClientBuilder {
        this._authLink = new ApolloLink((operation, forward) => {
            operation.setContext({
                headers: {
                    'x-api-key': apiKey,
                },
            });
            return forward(operation);
        });

        return this;
    }

    withAuth(authType: AuthenticationType, option?: AuthOptions): IApolloClientBuilder {
        switch (authType) {
            case 'oidc':
                this.withOidcAuth();
                break;
            case 'api-key':
                this.withApiKeyAuth(option?.apiKey);
                break;
            default:
                break;
        }

        return this;
    }

    withClientMocking(cache: InMemoryCache, typeDefs: DocumentNode[]): IApolloClientBuilder {
        this._cache = cache;
        this._typeDefs = typeDefs;
        return this;
    }

    buildClient(): ApolloClientProxy<NormalizedCacheObject> {
        const defaultOptions: DefaultOptions = {
            query: {
                errorPolicy: 'all',
                fetchPolicy: this._cache ? 'network-only' : 'no-cache',
            },
            mutate: {
                errorPolicy: 'all',
            },
        };

        const client = new ApolloClientProxy(
            {
                link: this.getLink(),
                cache:
                    this._cache ??
                    new InMemoryCache({
                        resultCaching: false,
                    }),
                defaultOptions: defaultOptions,
                typeDefs: this._typeDefs,
            },
            this._tracingService,
            this._userManager
        );

        return client;
    }

    protected getLink(): ApolloLink {
        const httpLink = this._authLink ? concat(this._authLink, this._httpLink) : this._httpLink;
        const finalLink = this._wsLink
            ? split(
                  ({query}) => {
                      const definition = getMainDefinition(query);
                      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
                  },
                  this._wsLink,
                  httpLink
              )
            : httpLink;
        return finalLink;
    }

    protected getExpiredTimeout(): Observable<number> {
        return this._userManager.getUserObservable().pipe(
            map(user => {
                const expiryTimeout = moment(new Date(user.expires_at * 1000)).diff(moment(), 'milliseconds');
                return expiryTimeout;
            })
        );
    }
}
