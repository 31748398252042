import React from 'react';
import {defineMessages} from 'react-intl';

import {LayoutDetailsBlock} from '@components/layout/LayoutDetailsBlock';
import {BackpackViewModelKeys} from '@models/backpack';
import {BackpackFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {getNonEmptyValueValidator} from '@redux/view';

import {useBackpack} from '../hooks';

import {DataGridBackpackServer} from './DataGridBackpack';

const localized = defineMessages({
    title: {
        id: 'BackpackInfo_displayName',
        defaultMessage: 'Backpack Info',
    },
    emptyList: {
        id: 'BackpackInfo_emptyList',
        defaultMessage: 'Backpack is empty',
    },
});

type BackpackInfoProps = {
    userId: string;
};

export const BackpackInfo = ({userId}: BackpackInfoProps) => {
    const keys: BackpackViewModelKeys[] = [
        'serial_number',
        'created_at.seconds',
        'bonus_status',
        'marketing_code',
        'bonus_name',
        'tool_label',
        'used_at.seconds',
        'value',
    ].filter(i => i) as BackpackViewModelKeys[];
    const {items, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useBackpack({
        viewType: 'BackpackInfo',
        fields: keys,
        displayName: localized.title,
        realtimeMode: RealtimeUpdatesMode.Confirm,
        triggers: [{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Update}],
        defaultFilters: [{key: 'uid', value: userId}],
        defaultSorting: [{sort: 'desc', field: 'created_at.seconds'}],
        validateFilter: getNonEmptyValueValidator<BackpackFilterKeys>('uid'),
    });

    return (
        <LayoutDetailsBlock
            title={localized.title}
            content={
                <DataGridBackpackServer
                    autoHeight={true}
                    columns={keys}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                />
            }
        />
    );
};
