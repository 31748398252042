import {useState} from 'react';
import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {SortDirection} from '@components/button/SortButton';
import {CommentFormModel, Suggestion} from '@components/comment/types';
import {BoUser, BoUserServerFilterKeys} from '@models/bo-user';
import {Entity, Note, NoteInput, NotesFilterType, Workspace} from '@models/generated/graphql';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {useAuthUser} from '@auth';
import {EntityType, NoteFilterKeys, NoteQueryFields} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit, ViewType} from '@redux/view';
import {isFutureDate} from '@utils/date';

import {Filter, Sorting} from 'src/common/types';
import {localizedWorkspaces} from '../app/intl/shared-resources/workspace';
import {getPolicyFromPermission} from '../app/permission/PermissionHoc';
import {SelectOption} from '../module-shared/types';
import {useAsyncActionState} from '../shared/async-action/hooks';

import {noteActions} from './actions';
import type {NoteMappingExtraArgs} from './mapper';
import {AttachmentViewModel, EntityViewModel, NoteViewModel, NoteViewModelKeys} from './types';

export function useNotes({
    viewType,
    fields,
    displayName,
    cleanDelay = 0,
    realtimeMode,
    triggers,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    validateFilter,
    syncWithUrl = false,
}: UseListViewEntityProps<NoteFilterKeys, NoteViewModelKeys>): UseListViewEntityResult<NoteViewModel, NoteViewModelKeys> {
    const mapper = useAutoMapper();

    const queryFields: NoteQueryFields[] = mapper?.map<NoteViewModelKeys[], NoteQueryFields[]>(
        fields,
        nameof<NoteViewModelKeys>(),
        nameof<NoteQueryFields>()
    );

    const {
        items: notes,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<Note, NoteFilterKeys, NoteQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.Note,
            fields: queryFields,
        },
        realtime: realtimeMode ? {entity: EntityType.Note, mode: realtimeMode, triggers} : null,
        defaultSorting,
        defaultPaging,
        defaultFilters,
        syncWithUrl,
        validateFilter: validateFilter ?? (() => true),
        cleanDelay,
    });

    const items: NoteViewModel[] = notes?.map(i => mapper.map(i, Note, NoteViewModel));

    return {
        items,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    };
}

export type UseNotesFilterProps = {
    entity: EntityViewModel;
    filterType: NotesFilterType;
};

export const useNotesFilter = ({entity, filterType}: UseNotesFilterProps) => {
    const workspaces = useWorkspaceFilterOptions();
    const defaultFilters: Filter<any, NoteFilterKeys>[] = [
        {
            key: 'type',
            value: filterType,
        },
        {
            key: 'workspace',
            value: [Workspace.Global, ...workspaces?.flatMap(w => w.value).filter(w => w)],
        },
    ];

    if (entity?.id && entity?.type) {
        defaultFilters.push(
            {
                key: 'entity.id',
                value: entity?.id,
            },
            {
                key: 'entity.type',
                value: entity?.type,
            }
        );
    }

    // NOTE: this condition check was added to avoid issues with filtration for all player records (the same id and type)
    if (entity?.parent?.type && entity?.id !== entity?.parent?.id && entity?.type !== entity?.parent?.type) {
        defaultFilters.push(
            {
                key: 'entity.parent.id',
                value: entity?.parent?.id,
            },
            {
                key: 'entity.parent.type',
                value: entity?.parent?.type,
            }
        );
    }

    const sortingField: NoteQueryFields = 'posted_at.seconds';
    const defaultSorting: Sorting<NoteQueryFields>[] = [{field: sortingField, sort: 'desc'}];

    const getNotesSorting = (sortDirection: SortDirection): Sorting<NoteQueryFields>[] => [
        {
            field: sortingField,
            sort: sortDirection === SortDirection.New ? 'desc' : 'asc',
        },
    ];

    return {
        defaultFilters,
        defaultSorting,
        getNotesSorting,
    };
};

export const useNotesUserSuggestions = (viewType: ViewType, filterLockout = true): Suggestion[] => {
    const {items: boUsers} = useViewInit<BoUser, BoUserServerFilterKeys, string>({
        viewType,
        displayName: null,
        entity: {
            entity: EntityType.BoUser,
            fields: ['id', 'firstName', 'lastName', 'lockoutEnd'],
        },
        validateFilter: () => true,
    });

    const suggestions = boUsers
        ?.filter(i => (filterLockout ? !isFutureDate(i.lockoutEnd) : true))
        ?.map(u => {
            const suggestion: Suggestion = {
                text: `${u.firstName} ${u.lastName}`,
                value: `${u.firstName} ${u.lastName}`,
                url: `${u.id}`,
            };
            return suggestion;
        });

    return suggestions;
};

export const useWorkspaceFilterOptions = () => {
    const {policies} = useAuthUser();
    const selectOptions = Object.values(Workspace)
        .map(value => ({label: localizedWorkspaces[value], value} as SelectOption))
        .filter(i => i.value !== Workspace.Global);
    const workspaceSubmodulesMapping = {
        [Workspace.Global]: SubmoduleName.None,
        [Workspace.Payment]: SubmoduleName.Payment,
        [Workspace.Security]: SubmoduleName.NotesSecurity,
        [Workspace.Kyc]: SubmoduleName.KYC,
        [Workspace.CustomerSupport]: SubmoduleName.NotesCustomerSupport,
    };

    const allCommentsPolicy = getPolicyFromPermission(PermissionEnum.Read, ModuleName.Comments, SubmoduleName.NotesPlayer)?.modulePolicy;
    return policies.includes(allCommentsPolicy)
        ? selectOptions
        : selectOptions.filter(i =>
              policies.includes(
                  getPolicyFromPermission(PermissionEnum.Read, ModuleName.Comments, workspaceSubmodulesMapping[i.value as Workspace])
                      ?.submodulePolicy
              )
          );
};

type UseNotePinButtonResult = {
    isProgress: boolean;
    handlePin: (id: string, vm: NoteViewModel) => void;
};

export function useNotePin(): UseNotePinButtonResult {
    const dispatch = useDispatch();
    const mapper = useAutoMapper();

    const {isProgress} = useAsyncActionState(noteActions.pinNote);

    const handlePin = (id: string, vm: NoteViewModel) => {
        const note = mapper.map(vm, NoteViewModel, NoteInput);
        dispatch(noteActions.pinNote.request({id, note: {...note, is_pinned: !note.is_pinned}}));
    };

    return {
        isProgress,
        handlePin,
    };
}

type UseNoteCreateResult = {
    isProgress: boolean;
    isNone: boolean;
    addNote: (viewModel: CommentFormModel) => void;
};

export function useNoteCreate(entity: EntityViewModel): UseNoteCreateResult {
    const dispatch = useDispatch();
    const mapper = useAutoMapper();
    const user = useAuthUser();

    const {isProgress, isNone} = useAsyncActionState(noteActions.addNote);

    function addNote(viewModel: CommentFormModel) {
        const note: NoteInput = mapper.map(viewModel, CommentFormModel, NoteInput, {
            extraArgs: (): NoteMappingExtraArgs => ({entity: entity, postedByUid: user.sub}),
        });
        note.entity = mapper.map(entity, EntityViewModel, Entity);
        dispatch(noteActions.addNote.request({note, files: viewModel.attachmentsFinal}));
    }

    return {addNote, isProgress, isNone};
}

type UseAttachmentProps = {
    viewType: ViewType;
    entity: EntityViewModel;
    filterType: NotesFilterType;
};

type UseAttachmentsResult = {
    attachments: AttachmentViewModel[];
    totalCount: number;
    page: number;
    pageSize: number;
    sortDirection: SortDirection;
    handleSortDirectionChange: (s: SortDirection) => void;
    handlePageChange: (page: number) => void;
    handlePageSizeChange: (pageSize: number) => void;
};

export function useAttachments({viewType, entity, filterType}: UseAttachmentProps): UseAttachmentsResult {
    const {defaultFilters, defaultSorting} = useNotesFilter({entity, filterType});

    const {items} = useNotes({
        viewType,
        fields: [
            'posted_at.seconds',
            'attachments.extension',
            'attachments.filename',
            'attachments.id',
            'attachments.url',
            'attachments.size',
        ],
        realtimeMode: RealtimeUpdatesMode.Silent,
        triggers: [{type: RealtimeMessageTrigger.Add}],
        defaultFilters: [...defaultFilters, {key: 'attachments.filename', value: '*'}],
        defaultSorting,
        defaultPaging: {page: 1, pageSize: 10000},
        validateFilter: () => true,
        syncWithUrl: false,
    });

    const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.New);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(50);
    const pageStart = page * pageSize;
    const pageEnd = (page + 1) * pageSize;

    const attachments: AttachmentViewModel[] = items
        .flatMap(i => i.attachments.map(a => ({...a, posted_at: i.posted_at})))
        .sort((a, b) =>
            sortDirection === SortDirection.New ? b.posted_at?.seconds - a.posted_at?.seconds : a.posted_at?.seconds - b.posted_at?.seconds
        );
    const attachmentsPage = attachments.slice(pageStart, pageEnd);

    function handleSortDirectionChange(s: SortDirection) {
        setSortDirection(s);
    }

    function handlePageChange(page: number) {
        setPage(page);
    }

    function handlePageSizeChange(pageSize: number) {
        setPageSize(pageSize);
    }

    return {
        handleSortDirectionChange,
        handlePageChange,
        handlePageSizeChange,
        attachments: attachmentsPage,
        totalCount: attachments?.length,
        sortDirection,
        page,
        pageSize,
    };
}

type UseAttachmentsActionsResult = {
    download: (attachment: AttachmentViewModel) => void;
};

export function useAttachmentsActions(): UseAttachmentsActionsResult {
    const dispatch = useDispatch();

    function download(attachment: AttachmentViewModel) {
        dispatch(noteActions.downloadAttachment.request({fileId: attachment?.id, fileName: attachment?.filename}));
    }

    return {download};
}
