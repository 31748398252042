import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {ModuleName, SubmoduleName} from '@models/modules';
import {Policy} from '@auth';

export const entityTransactionCreateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'EntityTransactionCreateResource',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.EntityTransactions, null, 'create'),
        new Policy(ModuleName.EntityTransactions, SubmoduleName.P2PTransfer, 'create'),
    ]),
});

export const p2pTransactionCreateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'P2pTransactionCreateResource',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.ActionCenter),
        new Policy(ModuleName.ActionCenter, null, 'create'),
        new Policy(ModuleName.ActionCenter, SubmoduleName.P2PTransaction),
        new Policy(ModuleName.ActionCenter, SubmoduleName.P2PTransaction, 'create'),
    ]),
});
