import produce from 'immer';
import {createSelector} from 'reselect';
import {RootState} from 'typesafe-actions';

import {
    BulkItem,
    BulkOperationResult,
    BulkOperationStatus,
    FeatureAccessLockInput,
    FeatureAccessStatus,
    SecurityCase,
    UserAccountStatus,
} from '@models/generated/graphql';

import {getErrorCodeFromMessage} from '../app/error-handling/utils';
import {EditSecurityCasesSubmitModel, SecurityCaseEditModel} from '../player-actions/cases-actions/types';
import {PlayerProfileGridItem} from '../player-actions/types';

import {ApplyNoteStrategyResponseValue} from './services/applyStrategy';
import {BulkActionState, BulkOperationIdsState} from './reducers';
import {
    AccountLockActionItem,
    BulkActionItemPayload,
    BulkActionKey,
    bulkItemFailedStatues,
    BulkItemStatus,
    BulkSecurityCasesResultValue,
    DepositLockActionItem,
    failedOperationId,
    LobbyLockActionItem,
    SendMessageActionItem,
    ValidationResult,
    WithdrawalLockActionItem,
} from './types';

export const itemsSelector = <TModel = unknown>(state: RootState) => state.bulkActions.state.selectedItems as TModel[];

export const validationResultsSelector = <TModel>(state: RootState) =>
    state.bulkActions.state.validationResults as ValidationResult<TModel>[];

export const actionsDataSelector = (state: RootState) => state.bulkActions.state.actionsData as BulkActionState;

export const actionItemsSelector = createSelector<RootState, BulkActionKey, BulkActionState, BulkActionKey, BulkActionItemPayload[]>(
    state => actionsDataSelector(state),
    (_, bulkActionKey) => bulkActionKey,
    (allActions, actionKey) => {
        return Object.values(allActions)
            ?.flatMap(i => i)
            ?.filter(i => i.actionKey === actionKey);
    }
);

export const simpleResultValueSelector = (state: RootState, actionKey: BulkActionKey) => {
    const allActions = actionsDataSelector(state);
    const actionsWithKey = allActions[actionKey];
    return (actionsWithKey ?? [])[0]?.value;
};

export const accountLockInitialValueSelector = (state: RootState): boolean[] => {
    const selectedItems = itemsSelector(state) as PlayerProfileGridItem[];
    return selectedItems?.map(i => i.account_status === UserAccountStatus.Locked);
};

export const accountLockResultValueSelector = (state: RootState): boolean => {
    return (simpleResultValueSelector(state, BulkActionKey.AccountLock) as AccountLockActionItem)?.isLocked;
};

export const withdrawalLockInitialValueSelector = (state: RootState): boolean[] => {
    const selectedItems = itemsSelector(state) as PlayerProfileGridItem[];
    return selectedItems?.map(i => i.withdrawal_status === FeatureAccessStatus.Locked);
};

export const withdrawalLockResultValueSelector = (state: RootState): boolean => {
    return (simpleResultValueSelector(state, BulkActionKey.WithdrawalLock) as WithdrawalLockActionItem)?.isLocked;
};

export const depositLockInitialValueSelector = (state: RootState): boolean[] => {
    const selectedItems = itemsSelector(state) as PlayerProfileGridItem[];
    return selectedItems?.map(i => i.deposit_status === FeatureAccessStatus.Locked);
};

export const depositLockResultValueSelector = (state: RootState): boolean => {
    return (simpleResultValueSelector(state, BulkActionKey.DepositLock) as DepositLockActionItem)?.isLocked;
};

export const lobbyLockInitialValueSelector = (state: RootState): boolean[] => {
    const selectedItems = itemsSelector(state) as PlayerProfileGridItem[];
    return selectedItems?.map(i => i.lobby_access_status === FeatureAccessStatus.Locked);
};

export const lobbyLockResultValueSelector = (state: RootState): boolean => {
    return (simpleResultValueSelector(state, BulkActionKey.LobbyLock) as LobbyLockActionItem)?.isLocked;
};

export const casinoLockResultValueSelector = (state: RootState): FeatureAccessStatus => {
    return (simpleResultValueSelector(state, BulkActionKey.CasinoLock) as FeatureAccessLockInput)?.feature_status;
};

export const sportsbookLockResultValueSelector = (state: RootState): FeatureAccessStatus => {
    return (simpleResultValueSelector(state, BulkActionKey.SportsbookLock) as FeatureAccessLockInput)?.feature_status;
};

export const p2pTransferLockResultValueSelector = (state: RootState): FeatureAccessStatus => {
    return (simpleResultValueSelector(state, BulkActionKey.P2PTransferLock) as FeatureAccessLockInput)?.feature_status;
};

export const messageResultValueSelector = (state: RootState) => {
    return (simpleResultValueSelector(state, BulkActionKey.Message) as SendMessageActionItem)?.message;
};

export const notesAttachmentsResultValueSelector = (state: RootState) => {
    return (simpleResultValueSelector(state, BulkActionKey.NotesAndAttachments) as ApplyNoteStrategyResponseValue)?.noteInput;
};

export const securityCaseInitialValueSelector = (state: RootState) => {
    type BulkSecurityCaseItem = {
        case: SecurityCase;
        occurrences: number;
    };

    const selectedItems = itemsSelector(state) as PlayerProfileGridItem[];
    const allSecurityCases = selectedItems?.reduce((result, item) => {
        return item.security_cases ? [...result, ...item.security_cases] : result;
    }, [] as SecurityCase[]);

    const caseOccurrenceMap: {[key: string]: BulkSecurityCaseItem} = {};
    allSecurityCases?.forEach(securityCase => {
        const securityCaseKey = `${securityCase.type}-${securityCase.case_id}`;

        if (!caseOccurrenceMap[securityCaseKey]) {
            caseOccurrenceMap[securityCaseKey] = {case: securityCase, occurrences: 1};
        } else {
            caseOccurrenceMap[securityCaseKey].occurrences++;
        }
    });

    const initialSecurityCases = Object.values(caseOccurrenceMap)?.map(item => ({
        ...item.case,
        totalCount: item.occurrences,
    })) as SecurityCaseEditModel[];

    return selectedItems?.length ? initialSecurityCases : [];
};

const securityCaseResultValueSelector = (state: RootState, actionKey: BulkActionKey) => {
    const allActions = actionsDataSelector(state);
    const actionsWithKey = allActions[actionKey];

    const submitModel: EditSecurityCasesSubmitModel = {initial: [], add: [], remove: []};

    const addCaseIfNotAlreadyExist = (submitCases: SecurityCaseEditModel[], securityCase: SecurityCaseEditModel) => {
        const isCaseAlreadyExist = submitCases?.find(c => c.case_id === securityCase.case_id && c.type === securityCase.type);
        if (submitCases && !isCaseAlreadyExist) {
            submitCases.push(securityCase);
        }
    };

    actionsWithKey?.forEach(action => {
        const actionResultValue = action?.value as EditSecurityCasesSubmitModel;
        actionResultValue?.add?.forEach(securityCase => {
            addCaseIfNotAlreadyExist(submitModel.add, securityCase);
        });

        actionResultValue?.remove?.forEach(securityCase => {
            addCaseIfNotAlreadyExist(submitModel.remove, securityCase);
        });
    });

    const hasChanges = submitModel?.add?.length || submitModel?.remove?.length;
    return hasChanges ? submitModel : undefined;
};

export const securityCaseAddResultValueSelector = (state: RootState) => {
    return securityCaseResultValueSelector(state, BulkActionKey.SecurityCasesAdd);
};

export const securityCaseUpdateResultValueSelector = (state: RootState) => {
    return securityCaseResultValueSelector(state, BulkActionKey.SecurityCasesUpdate);
};

export const securityCaseRemoveResultValueSelector = (state: RootState) => {
    return securityCaseResultValueSelector(state, BulkActionKey.SecurityCasesRemove);
};

export const securityCaseAllResultValueSelector = (state: RootState): BulkSecurityCasesResultValue => {
    const addItems = securityCaseAddResultValueSelector(state);
    const updateItems = securityCaseUpdateResultValueSelector(state);
    const removeItems = securityCaseRemoveResultValueSelector(state);

    return {
        addedCases: addItems,
        updatedCases: updateItems,
        removedCases: removeItems,
    };
};

const manualTriggerResultValueSelector = (state: RootState, actionKey: BulkActionKey) => {
    const allActions = actionsDataSelector(state);
    const actionsWithKey = allActions[actionKey];

    const resultValue = actionsWithKey?.map(i => i.value);

    const hasChanges = resultValue?.length;
    return hasChanges ? {bonuses: resultValue} : undefined;
};

export const manualTriggerDepositCashMatchResultValueSelector = (state: RootState) => {
    return manualTriggerResultValueSelector(state, BulkActionKey.ManualTriggerDepositCashMatch);
};

export const manualTriggerImmediateCashBonusResultValueSelector = (state: RootState) => {
    return manualTriggerResultValueSelector(state, BulkActionKey.ManualTriggerImmediateCashBonus);
};

export const manualTriggerMTTTicketResultValueSelector = (state: RootState) => {
    return manualTriggerResultValueSelector(state, BulkActionKey.ManualTriggerMTTTicket);
};

const userLabelResultValueSelector = (state: RootState, actionKey: BulkActionKey) => {
    const allActions = actionItemsSelector(state, actionKey);
    const resultValue = allActions?.map(i => i.value);

    return resultValue?.[0];
};

export const addUserLabelResultValueSelector = (state: RootState) => {
    return userLabelResultValueSelector(state, BulkActionKey.AddUserLabel);
};

export const removeUserLabelResultValueSelector = (state: RootState) => {
    return userLabelResultValueSelector(state, BulkActionKey.RemoveUserLabel);
};

export const manualTransactionResultValueSelector = (state: RootState) => {
    const allActions = actionsDataSelector(state);
    const actionsWithKey = allActions[BulkActionKey.ManualTransactions];

    return actionsWithKey?.map(i => i.value);
};

export const p2pTransferResultValueSelector = (state: RootState) => {
    const allActions = actionsDataSelector(state);
    const actionsWithKey = allActions[BulkActionKey.P2PTransfer];

    return actionsWithKey?.map(i => i.value);
};

const bulkOperationsIdsSelector = (state: RootState): BulkOperationIdsState => state.bulkActions.state.bulkOperationIds;

export const bulkOperationsNotFailedIdsSelector = createSelector<RootState, BulkOperationIdsState, BulkOperationIdsState>(
    bulkOperationsIdsSelector,
    state => Object.fromEntries(Object.entries(state).filter(([_, value]) => value && value !== failedOperationId))
);

export const bulkFailedOperationActionKeysSelector = createSelector<RootState, BulkOperationIdsState, string[]>(
    bulkOperationsIdsSelector,
    state => (Object.keys(state) as string[]).filter((key: string) => state[key] === failedOperationId)
);

export const bulkOperationNotCreatedActionKeys = createSelector<RootState, BulkOperationIdsState, string[]>(
    bulkOperationsIdsSelector,
    state => (Object.keys(state) as string[])?.filter((key: string) => state[key] === failedOperationId) ?? []
);

export const bulkOperationsFilterSelector = createSelector<RootState, BulkOperationIdsState, string>(
    bulkOperationsNotFailedIdsSelector,
    state => Object.values(state).join(',')
);

type ExecutionSelectorParams = {
    operations: BulkOperationResult[];
};

export const isExecutionInProgressSelector = createSelector<
    RootState,
    ExecutionSelectorParams,
    BulkOperationResult[],
    BulkOperationIdsState,
    string[],
    BulkActionState,
    boolean
>(
    (_, {operations}: ExecutionSelectorParams) => operations,
    bulkOperationsNotFailedIdsSelector,
    bulkOperationNotCreatedActionKeys,
    actionsDataSelector,
    getExecutionStatusResultSelector(checkOperationIsProcessing, checkActionStateHasProcessingItems)
);

export const hasExecutionFailedItemsSelector = createSelector<
    RootState,
    ExecutionSelectorParams,
    BulkOperationResult[],
    BulkOperationIdsState,
    string[],
    BulkActionState,
    boolean
>(
    (_, {operations}: ExecutionSelectorParams) => operations,
    bulkOperationsNotFailedIdsSelector,
    bulkOperationNotCreatedActionKeys,
    actionsDataSelector,
    getExecutionStatusResultSelector(checkOperationHasFailedItems, checkActionStateHasFailedItems)
);

export const executionItemsSelector = createSelector<
    RootState,
    ExecutionSelectorParams,
    BulkOperationResult[],
    BulkOperationIdsState,
    BulkActionState,
    BulkActionState
>(
    (_, {operations}: ExecutionSelectorParams) => operations,
    bulkOperationsNotFailedIdsSelector,
    actionsDataSelector,
    (operations, createdOperationIdsState, bulkActionItems) => {
        const joinedActionItems: BulkActionState = {...bulkActionItems};
        Object.keys(bulkActionItems).map((actionKey: string) => {
            const operationId = createdOperationIdsState[actionKey];
            if (operationId) {
                const operation = operations?.find(o => o.id === operationId);
                joinedActionItems[actionKey] = produce(bulkActionItems[actionKey], draftState => {
                    draftState.forEach((i: BulkActionItemPayload) => {
                        const operationItem = operation?.items?.find((o: BulkItem) => o.id === i?.itemId);
                        i.status = operationItem?.status ?? i.status;
                        i.errorCode = getErrorCodeFromMessage(operationItem?.message) ?? i.errorCode;
                        i.message = operationItem?.message;
                    });
                });
            }
        });

        return joinedActionItems;
    }
);

function getExecutionStatusResultSelector(
    checkOperation: (operation: BulkOperationResult) => boolean,
    checkActionItems: (bulkActionItems: BulkActionState, actionKey: string) => boolean
) {
    function executionStatusResultSelector(
        operations: BulkOperationResult[],
        createdOperationIdsState: BulkOperationIdsState,
        failedOperationKeys: string[],
        bulkActionItems: BulkActionState
    ): boolean {
        let hasItems = false;
        Object.keys(bulkActionItems).map((actionKey: string) => {
            const operationId = createdOperationIdsState[actionKey];
            if (operationId) {
                const operation: BulkOperationResult = operations?.find(o => o.id === operationId);
                hasItems = hasItems || checkOperation(operation);
            } else {
                hasItems = hasItems || (!failedOperationKeys.includes(actionKey) && checkActionItems(bulkActionItems, actionKey));
            }
        });

        return hasItems;
    }

    return executionStatusResultSelector;
}

function checkOperationHasFailedItems(operation: BulkOperationResult): boolean {
    return operation?.items?.some((i: BulkItem) => bulkItemFailedStatues.includes(i.status));
}

function checkActionStateHasFailedItems(bulkActionItems: BulkActionState, actionKey: string): boolean {
    return bulkActionItems[actionKey]?.some(value => bulkItemFailedStatues.includes(value.status));
}

function checkOperationIsProcessing(operation: BulkOperationResult): boolean {
    return operation?.status !== BulkOperationStatus.Completed;
}

function checkActionStateHasProcessingItems(bulkActionItems: BulkActionState, actionKey: string): boolean {
    const itemProcessingStatuses: BulkItemStatus[] = [BulkItemStatus.Pending, BulkItemStatus.InProgress];
    return bulkActionItems[actionKey]?.some(value => itemProcessingStatuses.includes(value.status));
}
