import {MuiDataGridClient, withDataGridEntityClient} from '@components/data-grid/mui';
import {UserProfile} from '@models/generated/graphql';
import {TransactionEditableViewModel, TransactionViewModelEditableKeys} from '@models/transaction/types';
import {UserProfileViewModelKeys} from '@models/user-profile';

import {DataGridUserProfileConfigFactory} from 'src/features/block-user-profile-list';

import {DataGridTransactionConfigFactory} from './DataGridTransaction';
import {DataGridTransactionEditableConfigFactory} from './DataGridTransactionEditable';

type DataGridTransactionWithUserProfileEditableColumns = UserProfileViewModelKeys | TransactionViewModelEditableKeys;

type TransactionWithUserProfileEditableGridItem = TransactionEditableViewModel &
    Partial<Omit<UserProfile, '__typename'>> & {
        id: string;
    };

export const DataGridTransactionWithUserProfileEditableClient = withDataGridEntityClient<
    DataGridTransactionWithUserProfileEditableColumns,
    TransactionWithUserProfileEditableGridItem
>(MuiDataGridClient, () => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridTransactionConfigFactory().getColumnConfig(),
    ...new DataGridTransactionEditableConfigFactory().getColumnConfig(),
}));
