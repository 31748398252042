import React, {Fragment} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {useDataGridSelectedIds} from '@components/data-grid/mui';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {ModuleName} from '@models/modules';
import {customerSupportReadPermissions} from '@models/permissions/permissions';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {Policy} from '@auth';
import {extendedViewCleanDelay, ViewType} from '@redux/view';
import {removeItemsFromArray} from '@utils/array';

import {Sorting} from 'src/common/types';
import {useUserProfileSettings} from 'src/features/block-user-profile-list/hooks';
import {UserProfileDownloadCsvButton} from '../features/block-user-profile-actions';
import {
    DataGridUserProfileServer,
    UserProfileFiltersObsolete,
    UserProfileFilterType,
    useUserProfiles,
} from '../features/block-user-profile-list';
import {CustomerSupportBulkManualTransactionButton} from '../features/block-workspace-cs-header';
import {CustomerSupportBulkPlayerButton, CustomerSupportManageLabelButton} from '../features/block-workspace-cs-toolbar';
import {withModule} from '../features/module-shared/components/ModuleHoc';

const localized = defineMessages({
    title: {
        id: 'CustomerSupportWorkspace_title',
        defaultMessage: 'Customer Support Workspace',
    },
});

function CustomerSupportWorkspace() {
    const viewType: ViewType = 'CustomerSupportWorkspace';
    const keys: UserProfileViewModelKeys[] = [
        'uid_link_cs',
        'username',
        'nickname',
        'email',
        'phone',
        'register_marketing_code',
        'referrer',
        'finance.balance',
        'labels',
        'platform_cs',
        'brand',
        'license_type',
        'national_id',
        'latest_kyc.security.account_verification_status',
        'latest_kyc.payment.account_verification_status_cs',
        'account_status',
        'country',
        'poker_ggr_lifetime',
        'casino_ggr_lifetime',
    ].filter(k => k) as UserProfileViewModelKeys[];
    const filterKeys: UserProfileFilterType[] = [
        'uid',
        'username',
        'firstName',
        'lastName',
        'labels',
        'email',
        'nationalId',
        'marketingCode',
        'phone',
        'referrerId',
        'registrationCountry',
    ].filter(k => k) as UserProfileFilterType[];
    const pinnedColumns: UserProfileViewModelKeys[] = ['uid_link_cs', 'username', 'email'];
    const {visibleColumns, onVisibleColumnsChange} = useUserProfileSettings({
        settingId: 'CustomerSupportWorkspaceGrid',
        defaultColumns: keys,
    });

    const defaultSorting: Sorting<UserProfileViewModelKeys>[] = [
        {
            field: 'date_of_joining.seconds',
            sort: 'desc',
        },
    ];
    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useUserProfiles({
            viewType,
            displayName: localized.title,
            fields: keys,
            defaultSorting,
            validateFilter: () => true,
            syncWithUrl: true,
            cleanDelay: extendedViewCleanDelay,
        });
    const {selectedIds, handleSelect} = useDataGridSelectedIds();
    const {formatMessage} = useIntl();

    return (
        <Fragment>
            <LayoutQueue
                header={
                    <PageHeader
                        header={localized.title}
                        actions={
                            <>
                                <CustomerSupportManageLabelButton selectedIds={selectedIds} />
                                <CustomerSupportBulkPlayerButton selectedIds={selectedIds} />
                                <UserProfileDownloadCsvButton
                                    filterString={filterString}
                                    filename={formatMessage(localized.title)}
                                    keys={removeItemsFromArray(visibleColumns, 'finance.balance', 'email', 'phone')}
                                />
                                <CustomerSupportBulkManualTransactionButton />
                            </>
                        }
                    />
                }
                toolbar={
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems streched>
                                <UserProfileFiltersObsolete
                                    mode="multi"
                                    filter={filterString}
                                    onFilterChange={handleFilterChange}
                                    filters={filterKeys}
                                />
                            </ToolbarItems>
                        </ToolbarItemsContainer>
                    </Toolbar>
                }
                body={
                    <DataGridUserProfileServer
                        hasColumnSelection
                        columns={keys}
                        pinnedColumns={pinnedColumns}
                        rows={items}
                        rowCount={totalCount}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortModelChange={handleSortChange}
                        page={searchFilter?.paging?.page}
                        pageSize={searchFilter?.paging?.pageSize}
                        sortModel={searchFilter?.sorting}
                        checkboxSelection
                        selectedIds={selectedIds}
                        onSelect={handleSelect}
                        visibleColumns={visibleColumns}
                        onColumnsVisibilityUpdate={onVisibleColumnsChange}
                    />
                }
            />
        </Fragment>
    );
}

export const customerSupportWorkspaceResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'CustomerSupportWorkspace',
    rules: new PoliciesAccessRule([new Policy(ModuleName.CustomerSupport, null, 'read')]),
});

export default withModule(CustomerSupportWorkspace, customerSupportReadPermissions);
