import {ApolloError} from '@apollo/client';
import {AjaxError} from 'rxjs/ajax';

import packageJSON from '../../../../package.json';
import {globalConfig} from '../../../configuration';

import {CustomAttributes, ErrorCategory, ErrorSeverity, ReduxError} from './types';
export class CustomAttributesBuilder {
    constructor() {}

    public getCustomAttr(error: Error, category: ErrorCategory, func?: Function): CustomAttributes {
        let attrs: CustomAttributes = this.getDefaults(category, func);

        switch (category) {
            case ErrorCategory.GraphQL:
                attrs = this.updateGraphqlAttrs(attrs, error);
                break;
            case ErrorCategory.Rest:
                attrs = this.updateRestAttrs(attrs, error as AjaxError);
                break;
            case ErrorCategory.WebSocket:
                attrs = this.updateGraphqlAttrs(attrs, error);
                break;
            case ErrorCategory.ReactComponent:
                attrs = this.updateReactComponentAttrs(attrs);
                break;
            case ErrorCategory.ReduxMiddleware:
                attrs = this.updateReduxMiddlewareAttrs(attrs, error as AjaxError);
                break;
            default:
                break;
        }

        return attrs;
    }

    private updateRestAttrs(attrs: CustomAttributes, error: AjaxError): CustomAttributes {
        return {
            ...attrs,
            traceId: error?.response?.traceId,
            actionMethod: error?.request?.method,
            actionRequestUrl: error?.request?.url,
            severity: error?.status === 401 || error?.status === 403 ? ErrorSeverity.Warning : ErrorSeverity.Error,
        };
    }

    private updateGraphqlAttrs(attrs: CustomAttributes, error: Error): CustomAttributes {
        const isNetworkError = error instanceof ApolloError && error.networkError && !error.graphQLErrors.length;
        return {
            ...attrs,
            severity: isNetworkError ? ErrorSeverity.Warning : ErrorSeverity.Error,
        };
    }

    private updateReactComponentAttrs(attrs: CustomAttributes): CustomAttributes {
        return {
            ...attrs,
            severity: ErrorSeverity.Fatal,
        };
    }

    private updateReduxMiddlewareAttrs(attrs: CustomAttributes, error: Error): CustomAttributes {
        if (error instanceof AjaxError) {
            this.updateRestAttrs(attrs, error);
        }
        if (error instanceof ReduxError) {
            const reduxErr: ReduxError = error;
            attrs = {
                ...attrs,
                reduxActionType: reduxErr.actionType,
                reduxActionPayload: reduxErr.actionPayload ? JSON.stringify(reduxErr.actionPayload) : undefined,
            };
        }
        return attrs;
    }

    private getDefaults = (category: ErrorCategory, func?: Function): CustomAttributes => ({
        pageQuery: window.location?.search ?? null,
        time: Date.now(),
        severity: ErrorSeverity.Error,
        category,
        jurisdiction: globalConfig.getConfig().jurisdiction,
        appVersion: packageJSON.version,
        instanceName: func?.name,
    });
}
