import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';

export type LocalizedTextProps = {
    label: string | MessageDescriptor;
    labelParams?: Record<string, any>;
};

const LocalizedText = ({label, labelParams}: LocalizedTextProps) => {
    return label ? <>{typeof label === 'string' ? label : <FormattedMessage {...label} values={labelParams} />}</> : <></>;
};

export default LocalizedText;
