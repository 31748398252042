import {withUserProfileTextField} from '../../player-actions/pii-actions/components/UserProfileTextField';
import {addressInfoActions} from '../actions';

import {localized} from './UserProfilePostCode.localize';

export const UserProfilePostCode = withUserProfileTextField({
    action: addressInfoActions.editAddressPostCode,
    placeholder: localized.piiAddressPostCodeFieldName,
    submitModelMapper: m => ({
        fieldValue: m?.user?.address?.post_code,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            address: {
                post_code: m?.fieldValue as string,
            },
        },
        userId: m?.userId,
    }),
    isRequired: true,
});
