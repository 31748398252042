import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {CommandMenuItem, DropdownButton, useActive, useCommands} from '@remirror/react';

import {useClasses} from './FontSizeSelect';

const fontFamilies: Array<[React.CSSProperties['fontFamily'], string]> = [
    ['arial', 'Arial'],
    ['georgia', 'Georgia'],
    ['impact', 'Impact'],
    ['tahoma', 'Tahoma'],
    ['times new roman', 'Times New Roman'],
    ['verdana', 'Verdana'],
];

const localized = defineMessages({
    fontFamily: {
        id: 'FontFamilySelect_fontFamily',
        defaultMessage: 'Font Family',
    },
});

export function FontFamilySelect() {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();
    const {setFontFamily} = useCommands();
    const active = useActive();
    return (
        <DropdownButton aria-label={formatMessage(localized.fontFamily)} icon="text">
            {fontFamilies.map(([fontFamily, label]) => (
                <CommandMenuItem
                    key={fontFamily}
                    className={classes.fontSizeSelectSelected}
                    commandName="setFontFamily"
                    onSelect={() => setFontFamily(fontFamily as string)}
                    enabled={setFontFamily.enabled(fontFamily as string)}
                    active={active.fontFamily({fontFamily})}
                    label={<span style={{fontFamily}}>{label}</span>}
                />
            ))}
        </DropdownButton>
    );
}
