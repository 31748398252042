import {gql} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {PlayerReferrerSummary} from '@models/generated/graphql';

import {IRealtimeService} from '../../realtime-updates/services/IRealtimeService';
import {RealtimeSubscriptionService} from '../../realtime-updates/services/RealtimeUpdatesService';

export class ReferrerSummaryRealtimeService extends RealtimeSubscriptionService implements IRealtimeService<PlayerReferrerSummary> {
    subscribeToCreate(): Observable<PlayerReferrerSummary> {
        return super.createSubscription(this.getItemAddedSubscriptionQuery()).pipe(map(res => res.playerReferrerAdded));
    }

    subscribeToUpdate(): Observable<PlayerReferrerSummary> {
        return super.createSubscription(this.getItemUpdatedSubscriptionQuery()).pipe(map(res => res.playerReferrerUpdated));
    }

    private getItemAddedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnPlayerReferrerSummaryAdded {
                playerReferrerAdded {
                    invite_code
                    referrer {
                        uid
                    }
                    revenue_share
                    revenue_generated_total
                    revenue_redeemed_total
                    redeem_pending_total
                }
            }
        `;
    }

    private getItemUpdatedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnPlayerReferrerSummaryUpdated {
                playerReferrerUpdated {
                    invite_code
                    referrer {
                        uid
                    }
                    revenue_share
                    revenue_generated_total
                    revenue_redeemed_total
                    redeem_pending_total
                }
            }
        `;
    }
}
