import React, {useContext} from 'react';
import {IntlContext} from 'react-intl';
import {useSelector} from 'react-redux';
import {Box, Typography} from '@mui/material';
import {StyledComponentProps} from '@mui/material/styles';
import {RootState} from 'typesafe-actions';

import {MessageOrientation} from '@components/alerts/Message';
import {EditableAutocompleteProps, withEditableAutocomplete} from '@components/editable/EditableAutocomplete';
import {EntityType, Filter, Occupation} from '@redux/entity';
import {viewEntityItemsSelector} from '@redux/view';
import {OccupationsApiFilterKeys} from '@services/rest-api/occupationsApiService';

import {localizedOccupations} from '../../app/intl/shared-resources/occupations';

import {useOccupationAutocompleteClasses} from './OccupationAutocomplete.style';
import {ViewAutocompleteWithInfoMessage} from './ViewAutocomplete';
import {localized} from './ViewAutocomplete.localize';

export const OccupationAutocomplete = (props: EditableAutocompleteProps<Filter<OccupationsApiFilterKeys>> & StyledComponentProps) => {
    const {classes} = useOccupationAutocompleteClasses();
    const {formatMessage} = useContext(IntlContext);

    const viewType = 'OccupationAutocomplete';
    const items = useSelector((state: RootState) =>
        viewEntityItemsSelector(state, {entityType: EntityType.Occupation, viewType: 'OccupationAutocomplete'})
    ) as Occupation[];
    const selectedItem = items?.find(i => i?.code === props.value);
    const infoMessage = getInfoMessage();

    const autocompleteMapper = (value: Occupation) => {
        if (value && value.code) {
            return {
                label: [value.code, getLocalizedOccupationTitle(value)].join(' - '),
                subLabel: getLocalizedOccupationSubtitle(value),
                value: value.code,
            };
        }

        return null;
    };

    function getLocalizedOccupationString(key: string): string {
        const descriptor = (localizedOccupations as any)[key];
        return descriptor ? formatMessage(descriptor) : null;
    }

    function getLocalizedOccupationTitle(occupation: Occupation): string {
        return getLocalizedOccupationString(`${occupation?.code}_title`) ?? occupation?.title;
    }

    function getLocalizedOccupationSubtitle(occupation: Occupation): string {
        return getLocalizedOccupationString(`${occupation?.code}_subtitle`) ?? occupation?.subtitle;
    }

    function getInfoMessage() {
        if (selectedItem?.code) {
            return (
                <Box>
                    <Typography variant="body1">{getLocalizedOccupationTitle(selectedItem)}</Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        {getLocalizedOccupationSubtitle(selectedItem)}
                    </Typography>
                    <Typography className={classes.occupationAutocompleteInfoMessageCode} variant="body1">
                        {selectedItem.code}
                    </Typography>
                </Box>
            );
        }

        return null;
    }

    return (
        <ViewAutocompleteWithInfoMessage
            {...props}
            value={selectedItem}
            viewType={viewType}
            entityType={EntityType.Occupation}
            displayName={localized.occupationAutocompleteDisplayName}
            fields={[nameof<Occupation>(i => i.code)]}
            optionMapper={autocompleteMapper}
            valueMapper={(value: Occupation) => (value ? value.code : null)}
            infoMessage={infoMessage}
            showInfoMessage={props.mode !== 'Edit'}
            hasLargeOptions={true}
            errorMessageOrientation={MessageOrientation.Floating}
            validateFilter={() => true}
            mode="client"
        />
    );
};

export const EditableOccupationAutocomplete = withEditableAutocomplete(OccupationAutocomplete);
