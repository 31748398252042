import {ZoneContextManager} from '@opentelemetry/context-zone';
import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http';
import {registerInstrumentations} from '@opentelemetry/instrumentation';
import {DocumentLoadInstrumentation} from '@opentelemetry/instrumentation-document-load';
import {XMLHttpRequestInstrumentation} from '@opentelemetry/instrumentation-xml-http-request';
import {Resource} from '@opentelemetry/resources';
import {BatchSpanProcessor, WebTracerProvider} from '@opentelemetry/sdk-trace-web';
import {SemanticResourceAttributes} from '@opentelemetry/semantic-conventions';

import {applyLocationAttrs} from './OtelSpan';

export type OtelConfigOptions = {
    resources: {
        serviceName: string;
        serviceVersion: string;
        jurisdiction: string;
        environment: string;
    };

    exporter: {
        url: string;
    };
};

export class OtelConfigurator {
    _options: OtelConfigOptions;
    constructor(options: OtelConfigOptions) {
        this._options = options;
    }
    registerProvider() {
        const provider = new WebTracerProvider({resource: this.getResource()});
        provider.addSpanProcessor(
            new BatchSpanProcessor(this.getExporter(), {
                maxQueueSize: 200,
                maxExportBatchSize: 20,
                scheduledDelayMillis: 500,
                exportTimeoutMillis: 30000,
            })
        );
        provider.register({contextManager: new ZoneContextManager()});
    }
    registerInstrumentation(apiUrls?: string[]) {
        registerInstrumentations({
            instrumentations: [
                new XMLHttpRequestInstrumentation({
                    applyCustomAttributesOnSpan: applyLocationAttrs,
                    ignoreUrls: [/nr-data/],
                    propagateTraceHeaderCorsUrls: apiUrls?.length
                        ? new RegExp(`(${apiUrls?.map(url => url?.replace(/\//g, '\\/')).join('|')})`)
                        : '',
                }),
                new DocumentLoadInstrumentation(),
            ],
        });
    }
    private getResource() {
        return new Resource({
            [SemanticResourceAttributes.SERVICE_NAME]: this._options.resources.serviceName,
            [SemanticResourceAttributes.SERVICE_VERSION]: this._options.resources.serviceVersion,
            [SemanticResourceAttributes.SERVICE_INSTANCE_ID]: this._options.resources.jurisdiction,
            [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: this._options.resources.environment,
        });
    }
    private getExporter() {
        return new OTLPTraceExporter({
            ...this._options.exporter,
            concurrencyLimit: 10,
        });
    }
}
