import {createAsyncAction} from 'typesafe-actions';

import {
    AddUserLabelInput,
    DeleteUserLabelInput,
    LabelGroup,
    LabelGroupInput,
    LabelInput,
    MutationAddLabelArgs,
    MutationAddLabelGroupArgs,
    MutationDeleteUserLabelArgs,
    MutationUpdateLabelArgs,
    MutationUpdateLabelGroupArgs,
    UserProfile,
} from '@models/generated/graphql';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

const domain = 'user-labels';

export class AddLabelGroupRequestPayload {
    group: LabelGroupInput;
}

export class UpdateLabelGroupRequestPayload {
    id: string;
    group: LabelGroupInput;
}

export type AddLabelRequestPayload = {
    label: LabelInput;
};

export class UpdateLabelRequestPayload {
    id: string;
    label: LabelInput;
}

export const blockUserLabelActions = {
    addUserLabels: createAsyncAction(`${domain}/addUserLabelRequest`, `${domain}/addUserLabelSuccess`, `${domain}/addUserLabelFailure`)<
        AddUserLabelInput,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>
    >(),

    deleteUserLabel: createAsyncAction(
        `${domain}/deleteUserLabelRequest`,
        `${domain}/deleteUserLabelSuccess`,
        `${domain}/deleteUserLabelFailure`
    )<
        DeleteUserLabelInput,
        ServiceResponsePayload<GqlMutationRequest<MutationDeleteUserLabelArgs>, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest<MutationDeleteUserLabelArgs>, UserProfile>
    >(),

    addLabelGroup: createAsyncAction(`${domain}/addLabelGroupRequest`, `${domain}/addLabelGroupSuccess`, `${domain}/addLabelGroupFailure`)<
        AddLabelGroupRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationAddLabelGroupArgs>, LabelGroup>,
        ServiceResponsePayload<GqlMutationRequest<MutationAddLabelGroupArgs>, LabelGroup>
    >(),

    updateLabelGroup: createAsyncAction(
        `${domain}/updateLabelGroupRequest`,
        `${domain}/updateLabelGroupSuccess`,
        `${domain}/updateLabelGroupFailure`
    )<
        UpdateLabelGroupRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateLabelGroupArgs>, LabelGroup>,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateLabelGroupArgs>, LabelGroup>
    >(),

    addLabel: createAsyncAction(`${domain}/addLabelRequest`, `${domain}/addLabelSuccess`, `${domain}/addLabelFailure`)<
        AddLabelRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationAddLabelArgs>, LabelGroup>,
        ServiceResponsePayload<GqlMutationRequest<MutationAddLabelArgs>, LabelGroup>
    >(),

    updateLabel: createAsyncAction(`${domain}/updateLabelRequest`, `${domain}/updateLabelSuccess`, `${domain}/updateLabelFailure`)<
        UpdateLabelRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateLabelArgs>, LabelGroup>,
        ServiceResponsePayload<GqlMutationRequest<MutationUpdateLabelArgs>, LabelGroup>
    >(),
};
