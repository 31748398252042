import {Observable} from 'rxjs';
import {AjaxRequest} from 'rxjs/ajax';
import {map} from 'rxjs/operators';

import {ITracingService, mergeMap} from '@otel';
import {UserManagerExtended} from '@auth';
import {AjaxResponse} from '@services/rest-api';
import {HttpMethod} from '@services/types';

import {ajax, AjaxFunction} from '../rest-api/ajaxProxy';

/**
 * @deprecated
 * <p>For http requests use {@link RestService}.</p>
 */
export class BaseHttpService {
    private _endpoint: string;
    private _ajax: AjaxFunction;
    private readonly _userManager: UserManagerExtended;

    constructor(endpoint: string, tracingService: ITracingService, userManager: UserManagerExtended) {
        this._endpoint = endpoint;
        this._ajax = ajax(tracingService);
        this._userManager = userManager;
    }

    get(path: string, options?: object): Observable<AjaxResponse> {
        return this.getUser().pipe(
            mergeMap(({authHeaders}) =>
                this.request(`${this._endpoint}${path}`, HttpMethod.Get, {headers: authHeaders, ...options} as AjaxRequest)
            )
        );
    }

    getJSON<TResponse>(path: string): Observable<TResponse> {
        return this.getUser().pipe(
            mergeMap(({authHeaders}) =>
                this.request(`${this._endpoint}${path}`, HttpMethod.Get, {headers: authHeaders} as AjaxRequest).pipe(
                    map(r => r.response as TResponse)
                )
            )
        );
    }

    post<TModel>(path: string, item: TModel): Observable<AjaxResponse> {
        return this.getJsonRequest(item).pipe(mergeMap(request => this.request(`${this._endpoint}${path}`, HttpMethod.Post, request)));
    }

    put<TModel>(path: string, item: TModel): Observable<AjaxResponse> {
        return this.getJsonRequest(item).pipe(mergeMap(request => this.request(`${this._endpoint}${path}`, HttpMethod.Put, request)));
    }

    delete<TModel>(path: string, item: TModel): Observable<AjaxResponse> {
        return this.getJsonRequest(item).pipe(mergeMap(request => this.request(`${this._endpoint}${path}`, HttpMethod.Delete, request)));
    }

    patch<TModel>(path: string, item: TModel): Observable<AjaxResponse> {
        return this.getJsonRequest(item).pipe(mergeMap(request => this.request(`${this._endpoint}${path}`, HttpMethod.Patch, request)));
    }

    postFileAsFormData(path: string, file: File) {
        return this.getUser().pipe(
            mergeMap(user => {
                const body = new FormData();
                body.append('File', file);
                const request: AjaxRequest = {
                    url: `${this._endpoint}${path}`,
                    method: 'POST',
                    body,
                    headers: user.authHeaders,
                } as AjaxRequest;
                return this._ajax(request, user.basicInfo);
            })
        );
    }

    private request(url: string, method: HttpMethod, base: AjaxRequest = {} as AjaxRequest): Observable<AjaxResponse> {
        const request = {...base, method, url, withCredentials: true};
        return this.getUser().pipe(mergeMap(user => this._ajax(request, user.basicInfo)));
    }

    private getJsonRequest(body: any): Observable<AjaxRequest> {
        return this.getJsonHeaders().pipe(map(headers => ({headers, body: JSON.stringify(body)} as AjaxRequest)));
    }

    private getJsonHeaders(): Observable<Object> {
        return this.getUser().pipe(
            map(({authHeaders}) => ({
                'Content-Type': 'application/json',
                ...authHeaders,
            }))
        );
    }

    private getUser() {
        return this._userManager.getUserExtended();
    }
}
