import {defineMessages} from 'react-intl';

import {RefereeType} from '@models/generated/graphql';

export const localizedRefereeType = defineMessages({
    [RefereeType.Player]: {
        id: 'localizedRefereeType_Player',
        defaultMessage: 'Player',
    },
    [RefereeType.SubAgent]: {
        id: 'localizedRefereeType_SubAgent',
        defaultMessage: 'Subagent',
    },
    [RefereeType.None]: {
        id: 'localizedRefereeType_None',
        defaultMessage: 'None',
    },
});
