import React from 'react';
import {defineMessages} from 'react-intl';

import {EmptyPlaceholder} from '@components/select';
import {AccountVerificationStatus} from '@models/generated/graphql';
import {AccountVerificationFilterKeys} from '@redux/entity';

import {SelectOption} from 'src/features/module-shared/types';
import {localizedAccountVerificationStatus} from '../../app/intl/shared-resources/accountVerificationStatus';
import FilterMultiSelect from '../../shared/filter/components/FilterMultiSelect';
import {PageModuleChipSelectFilter} from '../../shared/filter/types';

import {AccountVerificationsFilterProps} from './AccountVerificationsFilters';

const localized = defineMessages({
    kycStatusFilter: {
        id: 'AccountVerificationsFilterStatus_kycStatusFilter',
        defaultMessage: 'KYC Status',
    },
});

const pendingKYCStatuses: SelectOption[] = [
    {
        label: localizedAccountVerificationStatus.pendingLabel,
        value: [
            AccountVerificationStatus.InitDepositKyc,
            AccountVerificationStatus.InitLoginKyc,
            AccountVerificationStatus.InitWithdrawalKyc,
            AccountVerificationStatus.InitPaymentKyc,
        ],
    },
    {
        label: localizedAccountVerificationStatus.onHoldLabel,
        value: [
            AccountVerificationStatus.OnHoldDepositKyc,
            AccountVerificationStatus.OnHoldLoginKyc,
            AccountVerificationStatus.OnHoldWithdrawalKyc,
            AccountVerificationStatus.OnHoldPaymentKyc,
        ],
    },
];

const closedKYCStatuses: SelectOption[] = [
    {
        label: localizedAccountVerificationStatus.approvedLabel,
        value: [AccountVerificationStatus.Approved],
    },
    {
        label: localizedAccountVerificationStatus.rejectLabel,
        value: [
            AccountVerificationStatus.DeniedDepositKyc,
            AccountVerificationStatus.DeniedLoginKyc,
            AccountVerificationStatus.DeniedWithdrawalKyc,
            AccountVerificationStatus.DeniedPaymentKyc,
        ],
    },
];

const closedNDRPStatuses: SelectOption[] = [
    {
        label: localizedAccountVerificationStatus.approvedLabel,
        value: [AccountVerificationStatus.Approved],
    },
    {
        label: localizedAccountVerificationStatus.rejectLabel,
        value: [AccountVerificationStatus.DeniedNdrpKyc],
    },
];

function withStatusFilter(WrappedComponent: typeof FilterMultiSelect, availableOptions: SelectOption[] = []) {
    const filterKey: AccountVerificationFilterKeys = 'accountVerificationStatus';

    return function AccountVerificationsFilterStatusHOC(props: AccountVerificationsFilterProps) {
        const {filter, onFilterChange, defaultFilter} = props;
        return (
            <WrappedComponent
                onFilterChange={onFilterChange}
                domain={null}
                filter={
                    {
                        key: filterKey,
                        name: localized.kycStatusFilter,
                        label: localized.kycStatusFilter,
                        defaultValue: defaultFilter,
                        chipType: nameof<AccountVerificationStatus>(),
                        options: availableOptions,
                        emptyPlaceholder: EmptyPlaceholder.Any,
                        enumFormatter: (value: AccountVerificationStatus) => localizedAccountVerificationStatus[value],
                    } as PageModuleChipSelectFilter
                }
                filterString={filter}
            ></WrappedComponent>
        );
    };
}

export const AccountVerificationsFilterPendingStatuses = withStatusFilter(FilterMultiSelect, pendingKYCStatuses);
export const AccountVerificationsFilterClosedStatuses = withStatusFilter(FilterMultiSelect, closedKYCStatuses);
export const AccountVerificationsFilterClosedNDRPStatuses = withStatusFilter(FilterMultiSelect, closedNDRPStatuses);
