import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {FeatureAccessStatus} from '@models/generated/graphql';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {userProfileActions} from '../actions';
import {gamesActionsReadPermissions, gamesActionsUpdatePermissions, gamesActionsUpdateRestrictPermissions} from '../permissions';

import {LockActionProps} from './UserProfileActions';
import {UserProfileLock} from './UserProfileLock';

const localized = defineMessages({
    label: {
        id: 'LobbyLock_label',
        defaultMessage: 'Lobby Lock',
    },
});

type LobbyLockProps = LockActionProps<FeatureAccessStatus>;

function LobbyLockInner({uid, status, disabled}: LobbyLockProps) {
    const {formatMessage} = useIntl();

    return (
        <UserProfileLock
            action={userProfileActions.changeLobbyAccessStatus}
            label={formatMessage(localized.label)}
            uid={uid}
            isLocked={status === FeatureAccessStatus.Locked}
            disabled={disabled}
            dataTestId="lobby-status-lock"
        />
    );
}

export const LobbyLock = withMultiplePermission(
    withMultiplePermission(LobbyLockInner, {
        allowedPermissions: gamesActionsUpdatePermissions,
        restrictedPermissions: gamesActionsUpdateRestrictPermissions,
        indicator: LackOfPermissionIndicator.Disabled,
    }),
    {allowedPermissions: gamesActionsReadPermissions, indicator: LackOfPermissionIndicator.Hidden}
);
