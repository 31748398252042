import {defineMessages} from 'react-intl';

import {BonusType} from '@models/generated/graphql';

export const localizedBonusType = defineMessages({
    [BonusType.Deposit]: {
        id: 'bonusType_Deposit',
        defaultMessage: 'Deposit',
    },
    [BonusType.Register]: {
        id: 'bonusType_Register',
        defaultMessage: 'Register',
    },
    [BonusType.Team]: {
        id: 'bonusType_Team',
        defaultMessage: 'Team',
    },
});
