import React, {useEffect} from 'react';
import {AuthProvider} from 'react-oidc-context';
import {Provider, useDispatch} from 'react-redux';
import {ConnectedRouter} from '@vs-centaurea/connected-react-router';
import {History} from 'history';
import {Container} from 'inversify';
import {UserManagerSettings} from 'oidc-client-ts';
import {Store} from 'redux';

import {AutoMapperProvider} from '@auto-mapper';
import {ErrorUnstyled} from '@components/error';
import {InversifyProvider, ServiceTypes} from '@inversify';
import {ITracingService, TraceProvider, withTraceErrorBoundary} from '@otel';
import {useAuthUrl, useAutoLoginLogout} from '@auth';
import {UserSettingsProvider} from '@user-settings';
import {useRealtimePauseResume} from '@redux/view/hooks';
import {CustomThemeProvider} from '@style';
import {BrandProvider, IBrandService} from '@brand';

import type {DynamicConfig} from '../../configuration';
import {ConfigurationProvider} from '../../configuration';
import {loadJurisdictionConfigAction} from '../../features/app/config/actions';
import {useJurisdictionConfig} from '../../features/app/config/hooks';
import tempStateStorageActions from '../../features/module-temp-storage/actions';
import {initDatabase} from '../dbContext';

import {AuthorizedApp} from './AuthorizedApp';
import {UnauthorizedApp} from './UnauthorizedApp';

declare module '@vs-centaurea/connected-react-router' {
    interface ConnectedRouterProps {
        children?: React.ReactNode;
    }
}

type AppProps = {
    container: Container;
    store: Store;
    history: History<unknown>;
    config: DynamicConfig;
};

function ReduxApp({container, history}: AppProps) {
    //TODO: cleanup logic below
    const dispatch = useDispatch();
    const {pause, resume} = useRealtimePauseResume();

    const {isAuthenticated, isLoading} = useAutoLoginLogout(history);
    const {locale} = useJurisdictionConfig();

    useEffect(() => {
        dispatch(tempStateStorageActions.subscribeToGQLUpdates());
    }, []);

    useEffect(() => {
        dispatch(loadJurisdictionConfigAction.request());
    }, []);

    function handleVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            pause();
        } else {
            resume();
        }
    }
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            removeEventListener('visibilityChange', handleVisibilityChange);
        };
    }, []);

    return (
        <InversifyProvider container={container}>
            <ConnectedRouter history={history}>
                <CustomThemeProvider locale={locale}>
                    <AutoMapperProvider>{!isLoading && isAuthenticated ? <AuthorizedApp /> : <UnauthorizedApp />}</AutoMapperProvider>
                </CustomThemeProvider>
            </ConnectedRouter>
        </InversifyProvider>
    );
}

const ProtectedReduxApp = withTraceErrorBoundary(ReduxApp, ErrorUnstyled);

export function App({container, store, history, config}: AppProps) {
    const tracingService = container.get<ITracingService>(ServiceTypes.TracingService);
    const brandService = container.get<IBrandService>(ServiceTypes.BrandService);
    const settings = container.get<UserManagerSettings>(ServiceTypes.UserManagerSettings);
    const {relativeRedirectUrl, absoluteRedirectUrl} = useAuthUrl(settings?.redirect_uri);
    const storage = initDatabase();

    function handleSignIn() {
        //remove oidc query string params
        history.replace(relativeRedirectUrl);
    }

    return (
        <React.StrictMode>
            <TraceProvider tracingService={tracingService}>
                <ConfigurationProvider config={config}>
                    <Provider store={store}>
                        <AuthProvider {...settings} redirect_uri={absoluteRedirectUrl} onSigninCallback={handleSignIn}>
                            <UserSettingsProvider storage={storage}>
                                <BrandProvider brandService={brandService}>
                                    <ProtectedReduxApp
                                        container={container}
                                        store={store}
                                        history={history}
                                        config={config}
                                    ></ProtectedReduxApp>
                                </BrandProvider>
                            </UserSettingsProvider>
                        </AuthProvider>
                    </Provider>
                </ConfigurationProvider>
            </TraceProvider>
        </React.StrictMode>
    );
}
