import {useAutoMapper} from '@auto-mapper';
import {PlayersRelation} from '@models/generated/graphql';
import {EntityType, PlayersRelationFilterKeys, PlayersRelationQueryFields} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {useUserProfiles} from '../block-user-profile-list';

import {PlayersRelationViewModel, PlayersRelationViewModelKeys} from './types';

type UsePlayersRelationsResult = Pick<UseListViewEntityResult<PlayersRelationViewModel, PlayersRelationViewModelKeys>, 'items'>;

export function usePlayersRelations({
    viewType,
    fields,
    displayName,
    cleanDelay = 0,
    realtimeMode,
    triggers,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    validateFilter,
    syncWithUrl = false,
}: UseListViewEntityProps<PlayersRelationFilterKeys, PlayersRelationViewModelKeys>): UsePlayersRelationsResult {
    const mapper = useAutoMapper();

    const queryFields: PlayersRelationQueryFields[] = mapper?.map<PlayersRelationViewModelKeys[], PlayersRelationQueryFields[]>(
        fields,
        nameof<PlayersRelationViewModelKeys>(),
        nameof<PlayersRelationQueryFields>()
    );

    const {items: playerRelations} = useViewInit<PlayersRelation, PlayersRelationFilterKeys, PlayersRelationQueryFields>({
        viewType,
        displayName,
        entity: {entity: EntityType.PlayersRelation, fields: queryFields},
        realtime: realtimeMode ? {entity: EntityType.PlayersRelation, mode: realtimeMode, triggers} : null,
        defaultSorting,
        defaultPaging,
        defaultFilters,
        syncWithUrl,
        validateFilter: validateFilter ?? (() => true),
        cleanDelay,
    });

    useUserProfiles({
        viewType,
        displayName,
        fields: ['uid', 'username', 'security_cases'],
        defaultSorting: [{field: 'uid', sort: 'desc'}],
        realtimeMode: RealtimeUpdatesMode.Silent,
        triggers: [{type: RealtimeMessageTrigger.Update}],
        ids: playerRelations?.map(r => r.related_player?.uid),
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });

    const items: PlayersRelationViewModel[] = playerRelations?.map(i => mapper.map(i, PlayersRelation, PlayersRelationViewModel));

    return {items};
}
