import {ChipType, ChipVariant} from '@components/chip/types';
import {GeoCheckEventSource} from '@models/generated/graphql';

import {localizedGeoCheckEventSource} from '../../../features/app/intl/shared-resources/geoCheckEventSource';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export const getGeoCheckEventSourceChipsMapping = () => ({
    [GeoCheckEventSource.Login]: {
        label: localizedGeoCheckEventSource[GeoCheckEventSource.Login],
        chipType: ChipType.Status,
        chipVariant: ChipVariant.DropdownDefault,
    },

    [GeoCheckEventSource.Geocomply]: {
        label: localizedGeoCheckEventSource[GeoCheckEventSource.Geocomply],
        chipType: ChipType.Status,
        chipVariant: ChipVariant.DropdownDefault,
    },
});
