import React, {useRef} from 'react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Box} from '@mui/material';

import {OutlinedButtonDropdown} from '@components/dropdown/Dropdown';
import {Icon} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {FormTextInputDefault, RuleType, useValidationFormatter} from '@components/input';
import {PlayerBonusTriggerMetaInput} from '@models/generated/graphql';

import {useCurrencySymbol} from '../../app/intl/hooks';

import {localizedBulkAddBonusCodeButton} from './BulkBonusActionButton.localize';
import {useBulkAddBonusCodeButtonStyles} from './BulkBonusActionButton.style';

type BulkAddBonusAmountButtonProps = {
    onSubmit: (value: number) => void;
    disabled?: boolean;
};

export const BulkBonusAddAmountButton = ({onSubmit, disabled}: BulkAddBonusAmountButtonProps) => {
    const validationFormatter = useValidationFormatter();
    const currencySymbol = useCurrencySymbol();
    const {classes} = useBulkAddBonusCodeButtonStyles();
    const containerRef = useRef<HTMLDivElement>(null);

    const form = useForm<PlayerBonusTriggerMetaInput>({defaultValues: undefined});
    const {control, handleSubmit} = form;

    const callOnSubmit = (value: PlayerBonusTriggerMetaInput, _: React.BaseSyntheticEvent) => {
        onSubmit(Number(value.amount));
    };

    const onApplyClick = () => {
        form.handleSubmit(callOnSubmit)();
    };

    return (
        <OutlinedButtonDropdown
            startIcon={<Icon icon={CustomIcon.TransferOutline} />}
            label={localizedBulkAddBonusCodeButton.addBulkDepositAmountLabel}
            closeOnClickInside={false}
            disabled={disabled}
            cancelAction={true}
            applyAction={localizedBulkAddBonusCodeButton.confirmLabel}
            onApplyClick={onApplyClick}
            styles={{dropdownActions: classes.bulkActionBonusFormActions}}
            container={containerRef.current}
        >
            <FormProvider {...form}>
                <form onSubmit={handleSubmit(callOnSubmit)}>
                    <Box className={classes.bulkActionBonusFormContainer}>
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    type="number"
                                    inputProps={{min: 0}}
                                    className={classes.bulkActionBonusFormInput}
                                    value={field.value}
                                    onChange={field.onChange}
                                    placeholder={localizedBulkAddBonusCodeButton.fieldAmountPlaceholder}
                                    fieldState={fieldState}
                                    label={localizedBulkAddBonusCodeButton.fieldAmountLabel}
                                    helperText={localizedBulkAddBonusCodeButton.fieldAmountDescription}
                                    endAdornment={currencySymbol}
                                />
                            )}
                            defaultValue={null}
                            name="amount"
                            control={control}
                            rules={{
                                required: validationFormatter(RuleType.Required, localizedBulkAddBonusCodeButton.fieldAmountLabel),
                            }}
                        />
                    </Box>
                </form>
            </FormProvider>
        </OutlinedButtonDropdown>
    );
};

export default BulkBonusAddAmountButton;
