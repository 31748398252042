import {defineMessages} from 'react-intl';

import {BulkStatus} from '@models/bulk-operation/types';

export const localizedBulkStatus = defineMessages({
    [BulkStatus.Success]: {
        id: 'bulkStatusSuccess',
        defaultMessage: 'Success',
    },
    [BulkStatus.Warning]: {
        id: 'bulkStatusWarning',
        defaultMessage: 'Warning',
    },
});
