import {RootState} from 'typesafe-actions';

import {UserProfile} from '@models/generated/graphql';
import {RootEpic} from '@redux';

import {requestWithConfirmEpic} from 'src/common/epics';
import {protectEpics} from '../../app/error-handling/epics';

import {piiActions} from './actions';
import {UserProfileUpdateModel} from './types';

const getUpdatePiiFieldPayload = (user: UserProfile, _: RootState): UserProfileUpdateModel => {
    return {...user, pii_manual: true} as UserProfileUpdateModel;
};

const editNationalityEpic: RootEpic = requestWithConfirmEpic(piiActions.editNationality, (payload, state, {userProfileService}) => {
    return userProfileService.updateUserProfile(getUpdatePiiFieldPayload(payload.user, state));
});

const editCountryOfBirthEpic: RootEpic = requestWithConfirmEpic(piiActions.editCountryOfBirth, (payload, state, {userProfileService}) => {
    return userProfileService.updateUserProfile(getUpdatePiiFieldPayload(payload.user, state));
});

const editNationalIdEpic: RootEpic = requestWithConfirmEpic(piiActions.editNationalId, (payload, state, {userProfileService}) => {
    return userProfileService.updateUserProfile(getUpdatePiiFieldPayload(payload.user, state));
});

const editTaxIdEpic: RootEpic = requestWithConfirmEpic(piiActions.editTaxId, (payload, state, {userProfileService}) => {
    return userProfileService.updateUserProfile(getUpdatePiiFieldPayload(payload.user, state));
});

const editOccupationEpic: RootEpic = requestWithConfirmEpic(piiActions.editOccupation, (payload, state, {userProfileService}) => {
    return userProfileService.updateUserProfile(getUpdatePiiFieldPayload(payload.user, state));
});

export const piiActionsEpics = protectEpics(
    editNationalityEpic,
    editCountryOfBirthEpic,
    editNationalIdEpic,
    editTaxIdEpic,
    editOccupationEpic
);
