import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {userProfileActions} from '../../block-user-profile-actions/actions';
import {EditableAgentPlayerId} from '../../block-user-profile-actions/components/EditableAgentPlayerId';

export type EditablePlayerIdValue = {
    playerId: string;
    boUserId: string;
};

export function renderEditablePlayerIdCell(params: GridCellParams) {
    const value = params.value as EditablePlayerIdValue;
    return (
        <EditableAgentPlayerId
            playerId={value.playerId}
            boUserId={value.boUserId}
            updatePlayerIdAction={userProfileActions.updatePlayerId}
        />
    );
}
