import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import tempStateStorageActions from '../../actions';
import {getTempStorageEntityKeys} from '../editable-components/hooks';

type EditableContextProps = {
    isEditing: (id: string, field: string, typeName: string) => boolean;
    startEdit: (id: string, field: string, typeName: string) => void;
    stopEdit: () => void;
};
type EditableProviderProps = {
    children: JSX.Element;
};

const EditableContext = React.createContext<EditableContextProps>(undefined);

const EditableProvider = ({children}: EditableProviderProps) => {
    const dispatch = useDispatch();
    const [editState, setState] = useState({} as {id: string; field: string; typeName: string});

    const startEdit = (id: string, field: string, typeName: string) => {
        const {fieldKey} = getTempStorageEntityKeys(typeName, id, field);

        setState({id, field, typeName});
        dispatch(tempStateStorageActions.changeEditState({key: fieldKey, value: true}));
    };

    const stopEdit = () => {
        const {fieldKey} = getTempStorageEntityKeys(editState.typeName, editState.id, editState.field);

        setState({id: null, field: null, typeName: null});
        dispatch(tempStateStorageActions.changeEditState({key: fieldKey, value: false}));
    };

    const isEditing = (id: string, field: string, typeName: string) =>
        editState.id === id && editState.field === field && editState.typeName === typeName;

    return <EditableContext.Provider value={{startEdit, stopEdit, isEditing}}>{children}</EditableContext.Provider>;
};
export {EditableContext, EditableProvider};
