import {createAsyncAction} from 'typesafe-actions';

import {PatchPayload} from '../module-temp-storage/types';
import {createEntityRealtimeActions} from '../realtime-updates/actions';

import {domain} from './types';

const sendMessageAction = '/sendMessage';
const sendMessageSucceedAction = '/sendMessageSucceed';
const sendMessageFailedAction = '/sendMessageFailed';

export const playerMessageActions = {
    ...createEntityRealtimeActions(domain),

    sendMessage: createAsyncAction(
        `${domain}${sendMessageAction}`,
        `${domain}${sendMessageSucceedAction}`,
        `${domain}${sendMessageFailedAction}`
    )<PatchPayload, PatchPayload, PatchPayload>(),
};
