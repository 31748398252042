import {Transaction} from '@models/generated/graphql';

import {protectEpics} from '../app/error-handling/epics';
import {createEntityRealtimeEpics} from '../realtime-updates/epics';

import {realtimeSubscribersSelector, subscribedTypesSelector} from './selectors';
import {domain} from './types';

export default protectEpics(
    createEntityRealtimeEpics<Transaction>(
        domain,
        container => container.userTransactionRealtimeService,
        realtimeSubscribersSelector,
        subscribedTypesSelector
    )
);
