import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {UserSecuritySettings} from '@models/generated/graphql';

import {localizedRegistrationInfo} from '../../../pages/registration-info/components/RegistrationInfo.localize';

export type UserTwoFAHistoryValue = {
    security_settings: UserSecuritySettings;
};

export const useUserTwoFAFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: UserTwoFAHistoryValue = JSON.parse(json);

    const formatSecuritySettings = (settings: UserSecuritySettings): string =>
        formatMessage(settings.is_2fa_enabled ? localizedRegistrationInfo.enabled : localizedRegistrationInfo.disabled);

    return value?.security_settings ? [{key: formatSecuritySettings(value.security_settings)}] : null;
};
