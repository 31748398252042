import React from 'react';
import {defineMessages} from 'react-intl';

import LocalizedText from '@components/i18n/LocalizedText';
import {BulkModalContent, BulkModalTitle} from '@components/modal';
import {PlayerViewModel} from '@models/game-room/types';
import {GameRoomFilterKeys, ManagedGameType} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {getNonEmptyValueValidator} from '@redux/view';

import {useGameRooms} from '../hooks';

import {DataGridPlayerClient} from './DataGridPlayer';

const localized = defineMessages({
    title: {
        id: 'RemovePlayerModal_title',
        defaultMessage: 'Players',
    },
});

type RemovePlayerModalProps = {
    roomId: string;
    roomName: string;
    gameType: ManagedGameType;
};

export function GamePlayersModal({roomId, roomName, gameType}: RemovePlayerModalProps) {
    const {items} = useGameRooms({
        viewType: 'GameTablePlayersGrid',
        fields: ['players'],
        // realtime settings are used to update by event action after action was fetched successfully. See epics.ts
        realtimeMode: RealtimeUpdatesMode.Silent,
        triggers: [{type: RealtimeMessageTrigger.Update}],
        defaultFilters: [
            {key: 'gameType', value: gameType},
            {key: 'roomName', value: roomName},
        ],
        validateFilter: getNonEmptyValueValidator<GameRoomFilterKeys>('roomName', 'gameType'),
    });

    const rows: PlayerViewModel[] = items[0]?.players?.players?.map(p => ({...p, gameType, roomId})) ?? [];

    return (
        <>
            <BulkModalTitle>
                <LocalizedText label={localized.title} />
            </BulkModalTitle>
            <BulkModalContent>
                <DataGridPlayerClient autoHeight={true} rows={rows} columns={['name', 'playerId', 'remove']} hidePagination />
            </BulkModalContent>
        </>
    );
}
