import {createAction} from 'typesafe-actions';

import {domain, FileType} from './types';

const downloadAction = '/download';

export type FileDownloadPayload = {
    file: Blob;
    type?: FileType;
    title: string;
};

export const fileActions = {
    download: createAction(`${domain}${downloadAction}`)<FileDownloadPayload>(),
};
