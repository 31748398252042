import React from 'react';
import {defineMessages} from 'react-intl';

import {OutlinedButton} from '@components/button/Buttons';
import {Icon} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {PaymentVendor, TransactionStatus} from '@models/generated/graphql';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {useCashierLinkHandler} from '../hooks';
import {withdrawalActionsPermissions} from '../permissions';

const localized = defineMessages({
    processCashierLabel: {
        id: 'BETL_processCashierLabel',
        defaultMessage: 'Process in Cashier',
    },
});

type BlockExternalTransactionLinkProps = {
    transactionId: string;
    transactionStatus: TransactionStatus;
    paymentVendor: PaymentVendor;
    disabled?: boolean;
};

function WithdrawalExternalCashierLinkInner({
    transactionId,
    transactionStatus,
    paymentVendor,
    disabled,
}: BlockExternalTransactionLinkProps) {
    const {handleOpenCashier} = useCashierLinkHandler();
    const pendingStatuses = [
        TransactionStatus.Pending,
        TransactionStatus.OnHoldRisk,
        TransactionStatus.OnHoldPayment,
        TransactionStatus.RiskAuthorized,
        TransactionStatus.InProgress,
    ];
    const isLinkToProviderDisabled = !pendingStatuses.includes(transactionStatus);
    const isLinkToProviderAvailable = [PaymentVendor.PremierCashier].includes(paymentVendor);

    return isLinkToProviderAvailable ? (
        <OutlinedButton
            label={localized.processCashierLabel}
            startIcon={<Icon icon={CustomIcon.Linked} />}
            onClick={() => handleOpenCashier(transactionId)}
            disabled={disabled || isLinkToProviderDisabled}
        />
    ) : null;
}

export const WithdrawalExternalCashierLink = withMultiplePermission(WithdrawalExternalCashierLinkInner, {
    allowedPermissions: withdrawalActionsPermissions,
});
