import {BaseFilterKeys} from '@redux/entity';

import {DateRange, NumRange, Sorting as GqlSorting, SortOrder, TextFilter, Timestamp} from '../models/generated/graphql';
import {Filter, FilterKey, Paging, Sorting} from '../types';

import {endDateNow, getNHoursAgoAsSeconds} from './date';

//filterBy is the key of filter that will be placed in location

export function toGQLTextFilter(
    filters: Filter[],
    fieldNames: string[],
    filterBy: string = null,
    transformText?: (value: string) => string
): TextFilter {
    const filterKey = filterBy ?? FilterKey.FullTextSearch;
    const textFilter = filters?.find(f => f.key === filterKey);
    return textFilter
        ? {
              text: transformText ? transformText(`${textFilter?.value}`) : `${textFilter?.value}`,
              search_in: fieldNames,
          }
        : null;
}

export function ignoreCase(value: string): string {
    const isSymbol = (str: string): boolean => {
        return !!str.match(/[a-z!@#$%^&()_+=[\]{};':"\\|,.<>/?]/i);
    };

    const getCaseInsensitiveWord = (str: string): string => {
        return str
            .split('')
            .map(c => (isSymbol(c) ? `[${c.toUpperCase()}${c.toLowerCase()}]` : c))
            .join('');
    };

    return value
        .split(' ')
        .map(i => `/${getCaseInsensitiveWord(i)}/`)
        .join(' ')
        .replaceAll('*', '.*');
}

export function toGQLStringFilter(filters: Filter[], filterName: string): string {
    const textFilter = filters?.find(f => f.key === filterName);
    return textFilter?.value?.toString();
}

export function toEnumFilter<TEnum>(filters: Filter[], filterName: string): TEnum {
    const textFilter = filters?.find(f => f.key === filterName);
    return textFilter?.value as TEnum;
}

export function toGQKMultiselectFilter<TResult>(filters: Filter<TResult[]>[], filterName: string): TResult[] {
    const multiselectFilter = filters.filter(f => f.key.includes(filterName));

    return multiselectFilter.length > 0 ? (multiselectFilter[0].value?.flatMap(v => v) as TResult[]) : undefined;
}

export function toGQLDateFilter(filters: Filter[], filterName: string): DateRange {
    const dateFilter = filters.filter(f => f.key.includes(filterName));
    let res: DateRange = null;

    if (dateFilter?.length) {
        const getDateFilterPart = (partName: string): Timestamp => {
            const datePart = dateFilter.find(f => f.key.includes(partName));

            if (!datePart) return null;

            return datePart.value === endDateNow ? {seconds: getNHoursAgoAsSeconds(0)} : {seconds: datePart.value};
        };

        res = {
            from: getDateFilterPart(nameof<DateRange>(d => d.from)),
            to: getDateFilterPart(nameof<DateRange>(d => d.to)),
        };
    }

    return res;
}

export function toGQLNumberRangeFilter(filters: Filter[], filterName: string): NumRange {
    const numberRangeFilter = filters.filter(f => f.key.includes(filterName));
    let res: NumRange = null;

    if (numberRangeFilter?.length) {
        const getNumberRangeFilterPart = (partName: string): number => {
            const numberPart = numberRangeFilter.find(f => f.key.includes(partName));
            return numberPart ? numberPart.value : null;
        };

        res = {
            min: getNumberRangeFilterPart(nameof<NumRange>(d => d.min)),
            max: getNumberRangeFilterPart(nameof<NumRange>(d => d.max)),
        };
    }

    return res;
}

export function toGQLContainsFilter<TArrayValue>(
    filters: Filter[],
    filterName: string,
    converter: (s: string) => TArrayValue
): TArrayValue[] {
    const containsFilter = filters.find(f => f.key.includes(filterName));
    let res: TArrayValue[] = null;

    if (containsFilter) {
        const values = (containsFilter.value as string).split(',');
        res = values.map(v => converter(v));
    }

    return res;
}

export function toGQLSorting(sorting: Sorting<string>[]): GqlSorting {
    return sorting?.length
        ? {
              sort: sorting[0].field,
              order: sorting[0].sort === 'asc' ? SortOrder.Asc : SortOrder.Desc,
          }
        : null;
}

export function toGQLMultipleSorting(sorting: Sorting<string>[]): GqlSorting[] {
    return sorting?.length
        ? sorting.map<GqlSorting>(s => ({
              sort: s.field,
              order: s.sort === 'asc' ? SortOrder.Asc : SortOrder.Desc,
          }))
        : null;
}

export function toGQLPaging(paging: Paging): {offset: Number; limit: Number} {
    return paging
        ? {
              offset: (paging.page - 1) * paging.pageSize,
              limit: paging.page * paging.pageSize,
          }
        : null;
}

export function mapSortingToFilter<TSortFields extends string = string>(sorting: Sorting<TSortFields>): Filter<any, BaseFilterKeys>[] {
    return [
        {key: 'sortField', value: sorting?.field},
        {key: 'sortOrder', value: sorting?.sort},
    ];
}
