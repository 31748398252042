import React from 'react';

import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {notesCreatePermissions, securityWorkspaceUpdatePermissions} from '@models/permissions/permissions';

import {useJurisdictionFeature} from 'src/features/app/config/hooks';
import {FeatureName} from 'src/features/app/config/types';
import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {BulkActionKey} from '../../block-bulk-actions';
import {BulkUserProfileChooseActionsStepButton} from '../../block-user-profile-bulk-actions';

type SecurityWorkspaceBulkPlayerButtonInnerProps = {
    selectedIds: string[];
};

function SecurityWorkspaceBulkPlayerButtonInner({selectedIds}: SecurityWorkspaceBulkPlayerButtonInnerProps) {
    const isSportsbookLockAvailable = useJurisdictionFeature({featureName: FeatureName.SportsbookLockOnSecurityWorkspace});
    return (
        <BulkUserProfileChooseActionsStepButton
            uids={selectedIds}
            actionKeys={[
                BulkActionKey.AccountLock,
                BulkActionKey.WithdrawalLock,
                BulkActionKey.DepositLock,
                BulkActionKey.LobbyLock,
                BulkActionKey.CasinoLock,
                BulkActionKey.P2PTransferLock,
                isSportsbookLockAvailable ? BulkActionKey.SportsbookLock : null,
                BulkActionKey.SecurityCases,
                BulkActionKey.Message,
                BulkActionKey.NotesAndAttachments,
            ].filter(key => key)}
        />
    );
}

export const SecurityWorkspaceBulkPlayerButton = withMultiplePermission(
    withMultiplePermission(SecurityWorkspaceBulkPlayerButtonInner, {
        allowedPermissions: [
            {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Create, PermissionEnum.Delete, PermissionEnum.Update]},
            {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.AccountActions, permissions: [PermissionEnum.Update]},
            {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.FinanceActions, permissions: [PermissionEnum.Update]},
            {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.Verification, permissions: [PermissionEnum.Update]},
            {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.GamesActions, permissions: [PermissionEnum.Update]},
            {
                moduleName: ModuleName.ActionCenter,
                submoduleName: SubmoduleName.Cases,
                permissions: [PermissionEnum.Create, PermissionEnum.Delete],
            },
            {
                moduleName: ModuleName.ActionCenter,
                submoduleName: SubmoduleName.CommunicationActions,
                permissions: [PermissionEnum.Create, PermissionEnum.Delete],
            },
            ...notesCreatePermissions,
        ],
    }),
    {allowedPermissions: securityWorkspaceUpdatePermissions}
);
