import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {Box} from '@mui/material';
import equal from 'fast-deep-equal/es6';
import {makeStyles} from 'tss-react/mui';

import {MessageOrientation} from '@components/alerts/Message';
import {EditableText} from '@components/editable/EditableText';
import {FormError, numberRegex, RuleType, useValidationFormatter} from '@components/input';
import {agentReadPolicies, customerSupportUpdatePermissions} from '@models/permissions/permissions';
import {UserReferralRevenueShareFormModel} from '@services/playerReferralService';
import {CustomTheme} from '@style';

import {useMessageFormatter, usePercentage} from '../../app/intl/hooks';
import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {playerReferralActions} from '../../block-player-referral-actions/actions';
import {useReduxForm, useReduxFormRequestMode} from '../../shared/form/hooks';

const localized = defineMessages({
    enterNumber: {
        id: 'ReferrerInfo_enterNumber',
        defaultMessage: 'Enter a number',
    },
    revenueShare: {
        id: 'ReferrerInfo_revenueShare',
        defaultMessage: 'Revenue Share',
    },
});

const useUserReferralRevenueShareClasses = makeStyles()((_theme: CustomTheme) => ({
    userReferralRevenueShareContainer: {
        width: '100%',
    },
}));

type UserReferralRevenueShareProps = UserReferralRevenueShareFormModel & {
    disabled?: boolean;
};

const minRevenueShare = 0;
const maxRevenueShare = 100;

const UserReferralRevenueShareInternal = (props: UserReferralRevenueShareProps) => {
    const {classes} = useUserReferralRevenueShareClasses();
    const validationMessageFormatter = useValidationFormatter();
    const formatter = useMessageFormatter();
    const {formatMessage} = useIntl();
    const percentageFormatter = usePercentage();

    const [fieldValueModel, setFieldValueModel] = useState<UserReferralRevenueShareFormModel>(props);

    const {control, state, submit, cancel, handleSubmit} = useReduxForm<
        UserReferralRevenueShareFormModel,
        UserReferralRevenueShareFormModel
    >({
        initialModel: fieldValueModel,
        asyncAction: playerReferralActions.editRevenueShare,
        map: m => ({
            referee_uid: props.referee_uid,
            revenue_share: m?.revenue_share / 100,
        }),
    });

    const {requestMode, resetRequestMode} = useReduxFormRequestMode<UserReferralRevenueShareFormModel>(
        playerReferralActions.editRevenueShare
    );

    useEffect(() => {
        if (!equal(fieldValueModel, props)) {
            setFieldValueModel(props);
        }
    }, [props]);

    return (
        <Box className={classes.userReferralRevenueShareContainer}>
            <form onSubmit={handleSubmit(submit)}>
                <Controller
                    render={({field, fieldState}) => (
                        <Box>
                            <EditableText
                                type="number"
                                mode={requestMode}
                                value={field.value}
                                onChange={e => {
                                    field.onChange(e);
                                    resetRequestMode();
                                }}
                                formatter={percentageFormatter}
                                onSave={() => handleSubmit(submit)}
                                onCancel={cancel}
                                placeholder={formatter(localized.enterNumber)}
                                disabled={props.disabled}
                                errorMessage={state.errorMessage ? formatMessage(state.errorMessage) : null}
                                errorMessageOrientation={MessageOrientation.Floating}
                            ></EditableText>
                            {fieldState.invalid ? <FormError>{fieldState.error.message}</FormError> : <></>}
                        </Box>
                    )}
                    control={control}
                    name="revenue_share"
                    defaultValue={props.revenue_share}
                    rules={{
                        required: validationMessageFormatter(RuleType.Required, localized.revenueShare),
                        pattern: {
                            value: numberRegex,
                            message: validationMessageFormatter(RuleType.Number, localized.revenueShare),
                        },
                        min: {
                            value: minRevenueShare,
                            message: validationMessageFormatter(RuleType.Min, localized.revenueShare, minRevenueShare),
                        },
                        max: {
                            value: maxRevenueShare,
                            message: validationMessageFormatter(RuleType.Max, localized.revenueShare, maxRevenueShare),
                        },
                    }}
                ></Controller>
            </form>
        </Box>
    );
};

export const UserReferralRevenueShare = withMultiplePermission(UserReferralRevenueShareInternal, {
    allowedPermissions: customerSupportUpdatePermissions,
    restrictedPermissions: agentReadPolicies,
    indicator: LackOfPermissionIndicator.Disabled,
});
