import React, {createRef, useContext, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Box} from '@mui/material';
import equal from 'fast-deep-equal/es6';
import {makeStyles} from 'tss-react/mui';

import {EditButton} from '@components/button/EditButton';
import {CustomTheme} from '@style';

import {MessageType, PatchAsyncAction} from '../../types';
import {ErrorPopover, InfoPopover} from '../error/MessagePopover';

import {EditableContext} from './EditableProvider';
import {useTempStorageUpdateState} from './hooks';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    editCellContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        margin: theme.spacing(0, -2),
        padding: theme.spacing(0, 2),
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    editCellContainerWithoutSpacing: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    editCellContainer_EditMode: {
        background: theme.palette.background.paper,
    },
    editCellControl: {
        display: 'flex',
        flexGrow: 1,
        // width: '100%',
    },
    editCellControlRight: {
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
    },
}));

export type EditableProps<T, TOptions, TSubmitValue = {}> = {
    id: string;
    field: string;
    typeName: string;
    value: T;
    disabled: boolean;
    action: PatchAsyncAction;
    messageType: MessageType;
    onChange?: (value: T) => void;
    options?: TOptions;
    alignRight?: boolean;
    model?: unknown;
    getSubmitValue?: (updatedValue: T) => TSubmitValue;
    editStateTrigger?: EditStateTrigger;
};

type SingleInputModel = {
    inputValue: unknown;
};

export enum EditStateTrigger {
    WrappedComponent = 'WrappedComponent',
    PencilButton = 'PencilButton',
}

export const withEditable =
    <T, TOptions, TSubmitValue = T>(WrappedComponent: React.ComponentType<EditableProps<T, TOptions, TSubmitValue>>) =>
    ({
        id,
        typeName,
        field,
        value,
        model,
        action,
        messageType,
        disabled,
        alignRight,
        getSubmitValue,
        editStateTrigger = EditStateTrigger.PencilButton,
        ...otherParams
    }: EditableProps<T, TOptions>) => {
        const {classes} = useClasses();

        const boxRef = createRef<HTMLFormElement>();
        const {isEditing, startEdit, stopEdit} = useContext(EditableContext);
        const isEditingMode = isEditing(id, field, typeName);
        const {isUpdateInProgress, storageKey, update} = useTempStorageUpdateState(action, field, id, typeName, messageType);
        const {control, reset, handleSubmit} = useForm<SingleInputModel>({
            defaultValues: {inputValue: value},
        });
        const currentFormValue = control._defaultValues;
        const defaultValue = {inputValue: value} as SingleInputModel;
        const isValueChanged = JSON.stringify(currentFormValue) !== JSON.stringify(defaultValue);

        const submit = (data: SingleInputModel, _: React.BaseSyntheticEvent<object, object, any>) => {
            stopEdit();
            const isValueUpdated = !equal(data.inputValue, value);
            if (isValueUpdated) {
                const submitValue = getSubmitValue ? getSubmitValue(data.inputValue as T) : (data.inputValue as T);
                update(submitValue, model);
            }
        };

        useEffect(() => {
            if (!isEditingMode || isValueChanged) {
                reset(defaultValue);
            }
        }, [isValueChanged, isEditingMode]);

        const handleEdittingState = () => {
            if (!disabled)
                if (editStateTrigger === EditStateTrigger.WrappedComponent && !isEditingMode) {
                    startEdit(id, field, typeName);
                }
        };

        const isWrappedComponentDisabled = () => {
            if (editStateTrigger === EditStateTrigger.WrappedComponent) return disabled;
            return disabled || !isEditingMode;
        };

        return (
            <>
                <form
                    ref={boxRef}
                    onSubmit={handleSubmit(submit)}
                    data-testid={field}
                    name={`${typeName}.${field}.${id}`}
                    className={`${classes.editCellContainer} ${isEditingMode ? classes.editCellContainer_EditMode : ''}`}
                >
                    <Box className={`${classes.editCellControl} ${alignRight ? classes.editCellControlRight : ''}`}>
                        <Controller
                            render={props => (
                                <WrappedComponent
                                    value={props.field.value as T}
                                    disabled={isWrappedComponentDisabled()}
                                    onChange={v => {
                                        props.field.onChange(v);
                                        handleEdittingState();
                                    }}
                                    field={field}
                                    id={id}
                                    typeName={typeName}
                                    action={action}
                                    messageType={messageType}
                                    {...otherParams}
                                />
                            )}
                            name="inputValue"
                            control={control}
                            defaultValue={value ?? (null as T)}
                        />
                    </Box>
                    {!disabled &&
                    (editStateTrigger === EditStateTrigger.PencilButton ||
                        (editStateTrigger === EditStateTrigger.WrappedComponent && isEditingMode)) ? (
                        <EditButton
                            mode={isUpdateInProgress ? 'InProgress' : isEditingMode ? 'Edit' : 'Read'}
                            onEdit={() => startEdit(id, field, typeName)}
                            onCancel={stopEdit}
                            onSave={() => handleSubmit(submit)}
                        ></EditButton>
                    ) : (
                        <></>
                    )}
                </form>
                <ErrorPopover ref={boxRef} messageKey={storageKey}></ErrorPopover>
                <InfoPopover ref={boxRef} messageKey={storageKey}></InfoPopover>
            </>
        );
    };
