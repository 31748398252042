import React, {CSSProperties} from 'react';
import {MessageDescriptor} from 'react-intl';
import {ListItemText, MenuItem, MenuItemProps, Typography} from '@mui/material';

import {StyledCheckbox} from '@components/checkbox/StyledCheckbox';
import {MemoizedChip} from '@components/chip/ChipRenderer';
import LocalizedText from '@components/i18n/LocalizedText';
import {useGeneralSelectClasses} from '@components/select/selectStyle';

export type MultiSelectItemProps = Pick<MenuItemProps, 'onClick'> & {
    label: string | MessageDescriptor;
    value: string;
    isSelected?: boolean;
    chipType?: string;
    chipValue?: string;
    style?: CSSProperties;
};

export const MultiSelectItem = React.forwardRef<HTMLLIElement, MultiSelectItemProps>(
    ({chipValue, label, isSelected, chipType, ...rest}: MultiSelectItemProps, ref) => {
        const {classes: generalSelectClasses} = useGeneralSelectClasses();
        return (
            <MenuItem className={generalSelectClasses.menuItem} data-testid={`${chipValue}_menuItem`} ref={ref} {...rest}>
                <StyledCheckbox checked={isSelected} data-testid="checkbox" />
                <ListItemText
                    className={generalSelectClasses.listItemText}
                    disableTypography
                    primary={
                        chipType ? (
                            <MemoizedChip
                                chipName={chipType}
                                value={(Array.isArray(chipValue) ? chipValue[0] : chipValue) as string}
                                cursorPointer
                            />
                        ) : (
                            <Typography variant="button" className={generalSelectClasses.listItemTextTypography}>
                                <LocalizedText label={label} />
                            </Typography>
                        )
                    }
                />
            </MenuItem>
        );
    }
);
