export class PlayerReferrerSummaryViewModel {
    invite_code?: string;
    redeem_pending_total?: number;
    referrer_uid: string;
    revenue_generated_total?: number;
    revenue_redeemed_total?: number;
    revenue_share?: number;
}

export type PlayerReferrerSummaryViewModelKeys = keyof Omit<PlayerReferrerSummaryViewModel, 'referrer_uid'>;
