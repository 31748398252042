import {IBreadcrumb, Route} from 'src/common/types';
import {getRoutesFromPath} from '../../../app/router/routes';

export const prepareBreadcrumb = (routes: readonly Route[], path: string) => {
    const paths = getRoutesFromPath(routes, path);
    const breadcrumbs: Array<IBreadcrumb> = [];

    paths.forEach((p, index) => {
        if (index === 0) {
            breadcrumbs.push({url: p.url, title: p.title});
        } else {
            breadcrumbs.push({url: `${breadcrumbs[index - 1].url}${p.url}`, title: p.title});
        }
    });

    return breadcrumbs;
};
