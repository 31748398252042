import React from 'react';

import {UserProfileViewModelKeys} from '@models/user-profile';

import {DownloadCsvButton} from 'src/features/block-download';
import {useUserProfileDownloadButton} from '../hooks';

type UserProfileDownloadCsvButtonProps = {
    filterString: string;
    keys: UserProfileViewModelKeys[];
    filename: string;
};

export function UserProfileDownloadCsvButton(props: UserProfileDownloadCsvButtonProps) {
    const {handleClick, isProgress} = useUserProfileDownloadButton(props);
    return <DownloadCsvButton onClick={handleClick} loading={isProgress} />;
}
