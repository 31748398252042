import {defineMessages} from 'react-intl';

export const localizedAccessManagement = defineMessages({
    title: {
        id: 'AccessManagement_title',
        defaultMessage: 'Users',
    },
    firstName: {
        id: 'AccessManagement_firstName',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'AccessManagement_lastName',
        defaultMessage: 'Last Name',
    },
    email: {
        id: 'AccessManagement_email',
        defaultMessage: 'Email',
    },
    searchByFirstName: {
        id: 'AccessManagement_searchByFirstName',
        defaultMessage: 'Search by first name',
    },
    searchByLastName: {
        id: 'AccessManagement_searchByLastName',
        defaultMessage: 'Search by last name',
    },
    searchByEmail: {
        id: 'AccessManagement_searchByEmail',
        defaultMessage: 'Search by email',
    },
    searchByPlayerId: {
        id: 'AccessManagement_searchByPlayerId',
        defaultMessage: 'Search by Agent Player ID',
    },
    roles: {
        id: 'AccessManagement_roles',
        defaultMessage: 'Roles',
    },
    editAction: {
        id: 'AccessManagement_editAction',
        defaultMessage: 'Edit',
    },
    reset2FAAction: {
        id: 'AccessManagement_reset2FAAction',
        defaultMessage: 'Reset 2FA',
    },
    resetPasswordAction: {
        id: 'AccessManagement_resetPasswordAction',
        defaultMessage: 'Reset Password',
    },
    loginAccess: {
        id: 'AccessManagement_loginAccess',
        defaultMessage: 'Login Access',
    },
    item: {
        id: 'AccessManagement_item',
        defaultMessage: 'user',
    },
    userSearch: {
        id: 'AccessManagement_userSearch',
        defaultMessage: 'User Search',
    },
    loginAccessEnable: {
        id: 'AccessManagement_loginAccessEnable',
        defaultMessage: 'Enable',
    },
    loginAccessDisable: {
        id: 'AccessManagement_loginAccessDisable',
        defaultMessage: 'Disable',
    },
    addUser: {
        id: 'AccessManagement_addUser',
        defaultMessage: 'Add User',
    },
    playerId: {
        id: 'AccessManagement_playerId',
        defaultMessage: 'Agent Player ID',
    },
});
