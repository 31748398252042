import {GridCellParams} from '@mui/x-data-grid';

import {IModuleGridItem} from '@components/data-grid';

import {getTempStorageKey} from '../../utils';

export const getTempStorageEntityKeys = (typeName: string, id: string, field: string) => {
    const fieldKey = getTempStorageKey(typeName, id, field);
    const rowKey = getTempStorageKey(typeName, id);

    return {
        rowKey,
        fieldKey,
    };
};

export const getGridRowData = <T extends IModuleGridItem>(params: GridCellParams) => {
    const model = params.row as T;
    const id = model.serverId ?? model.id;
    const typeName = model.__typename;
    const field = params.field;
    const value: any = params.value;

    return {
        model,
        id,
        typeName,
        field,
        defaultValue: value,
    };
};

export const getEditDisabledState = <T extends IModuleGridItem>(model: T, isEditable: boolean, isReadonly?: (model: T) => boolean) => {
    return !(model.serverId ?? model.id) || (isReadonly ? isReadonly(model) : false) || isEditable === false;
};
