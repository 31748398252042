import {useEffect, useState} from 'react';

import {IBrandService} from './IBrandService';
import {BrandConfig} from './types';

type UseBrandResult = BrandConfig;

export function useBrand(brandService: IBrandService): UseBrandResult {
    const [brandConfig, setBrandConfig] = useState<BrandConfig>();

    useEffect(() => {
        brandService.getBrands().subscribe(config => setBrandConfig(config));
    }, []);

    return brandConfig;
}
