import {constructUsing, createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {BoUser, BoUserFields} from '@models/bo-user';
import {UserProfile} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {isFutureDate} from '@utils';

import {BoUserViewModel, BoUserViewModelKeys} from './types';

export class BoUserMapping implements IMapping {
    createMapping(mapper: Mapper) {
        createMap(
            mapper,
            BoUser,
            BoUserViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.email,
                mapFrom(m => m.email)
            ),
            forMember(
                vm => vm.firstName,
                mapFrom(m => m.firstName)
            ),
            forMember(
                vm => vm.lastName,
                mapFrom(m => m.lastName)
            ),
            forMember(
                vm => vm.roles,
                mapFrom(m => m.roles?.map(r => r.name))
            ),
            forMember(
                vm => vm.loginEnabled,
                mapFrom(m => !isFutureDate(m.lockoutEnd))
            ),
            forMember(
                vm => vm.boAgentPlayerId,
                mapFrom(m => m.playerId)
            )
        );
        createMap<BoUserViewModelKeys[], BoUserFields[]>(
            mapper,
            nameof<BoUserViewModelKeys>(),
            nameof<BoUserFields>(),
            constructUsing((m, _) => {
                const mapper: Record<BoUserViewModelKeys, BoUserFields[]> = {
                    id: ['id'],
                    email: ['email'],
                    firstName: ['firstName'],
                    lastName: ['lastName'],
                    loginEnabled: ['lockoutEnd'],
                    roles: [],
                    boAgentPlayerId: [],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
        createMap<BoUserViewModelKeys[], UserProfileViewModelKeys[]>(
            mapper,
            nameof<BoUserViewModelKeys>(),
            nameof<UserProfileViewModelKeys>(),
            constructUsing((m, _) => {
                const mapper: Record<BoUserViewModelKeys, UserProfileViewModelKeys[]> = {
                    id: [],
                    email: [],
                    firstName: [],
                    lastName: [],
                    loginEnabled: [],
                    roles: [],
                    boAgentPlayerId: ['agent_info.bo_agent_id'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
        createMap(
            mapper,
            UserProfile,
            BoUserViewModel,
            forMember(
                vm => vm.boAgentPlayerId,
                mapFrom(m => m.uid)
            )
        );
    }
}
