import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import LocalizedText from '@components/i18n/LocalizedText';
import {EmptyLabel, isEmptyCellValue} from '@components/label/EmptyLabel';
import StyledTooltip from '@components/Tooltip';
import {AutoAdjustedWidthTooltip} from '@components/tooltip/AutoAdjustedWidthTooltip';
import {ReasonCode, TransactionType} from '@models/generated/graphql';
import {TransactionViewModel} from '@models/transaction';
import {formatSnakeCaseEnumValue} from '@utils';

import {localizedReasonCode} from '../../app/intl/shared-resources/transactionReasonCode';
import {emptyCellCharacter} from '../../module-shared/utils';

export type TransactionMethodProps = Pick<TransactionViewModel, 'payment_method_name' | 'transaction_type'>;

function TransactionMethod({transaction_type, payment_method_name}: TransactionMethodProps) {
    let value: string | MessageDescriptor;

    switch (transaction_type) {
        case TransactionType.Credit:
        case TransactionType.Debit:
            value = getReasonCode(payment_method_name as ReasonCode);
            break;
        case TransactionType.PokerAddonCredit:
        case TransactionType.PokerAddonDebit:
        case TransactionType.PokerFlipsPayout:
        case TransactionType.PokerFlipsWager:
            value = formatSnakeCaseMethodWithPrefix(payment_method_name, 'GAME_TRANSACTION_');
            break;
        case TransactionType.MttCredit:
        case TransactionType.MttDebit:
            value = formatSnakeCaseMethodWithPrefix(payment_method_name, 'MTT_SOURCE_');
            break;
        default:
            value = payment_method_name ?? emptyCellCharacter;
            break;
    }

    function getReasonCode(reasonCode: ReasonCode): string | MessageDescriptor {
        return reasonCode && Object.values(ReasonCode).some(o => o === reasonCode)
            ? localizedReasonCode[reasonCode]
            : reasonCode ?? emptyCellCharacter;
    }

    function formatSnakeCaseMethodWithPrefix(methodName: string, prefix: string) {
        const valueWithoutPrefix = methodName?.replace(prefix, '');
        return formatSnakeCaseEnumValue(valueWithoutPrefix);
    }

    return (
        <StyledTooltip
            element={<AutoAdjustedWidthTooltip value={<LocalizedText label={value} />} />}
            title={<LocalizedText label={value} />}
        />
    );
}

export const renderTransactionMethod = (params: GridCellParams) => {
    const {transaction_type, payment_method_name}: TransactionMethodProps = params.value as TransactionMethodProps;
    const isEmpty = isEmptyCellValue(payment_method_name);
    return isEmpty ? (
        <EmptyLabel defaultSymbol="dash" />
    ) : (
        <TransactionMethod transaction_type={transaction_type} payment_method_name={payment_method_name} />
    );
};
