import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';

import {OutlinedButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons/types';
import {FormTextInputCleanable} from '@components/input';

import {Filter as FilterKeyValue} from 'src/common/types';
import {getFilterValueFromKey} from '../utils';

import {useClasses} from './FilterText.styles';
import {localizedFilters} from './FilterTextWithButton.localize';
import withModuleFilter, {FilterInnerProps} from './ModuleFilterHoc';

export const TextInputWithSubmitButton = ({filter, value, onSubmit}: FilterInnerProps<string>) => {
    const {classes, cx} = useClasses();

    const key = filter.key as string;
    const currentValue = getFilterValueFromKey(key, value);

    const defaultValue = (filter?.defaultValue ?? '') as string;
    const [fieldValue, setFieldValue] = useState<string>(currentValue ?? defaultValue);

    useEffect(() => {
        if (currentValue !== fieldValue) {
            setFieldValue(currentValue ?? defaultValue);
        }
    }, [currentValue]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(e.target?.value);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            submit(fieldValue);
        }
    };

    const submit = (value: string) => onSubmit([{key, value}] as FilterKeyValue[]);

    const clear = () => {
        setFieldValue('');
    };

    return (
        <Box className={classes.filterWithButton}>
            <FormTextInputCleanable
                className={cx(filter.limitMaxWidth && classes.filterTextCleanableLimitMaxWidth, classes.filterText)}
                value={fieldValue}
                placeholder={filter.label}
                startAdornment={<Box component="span" className={`${CustomIcon.Search} ${classes.filterTextStartInputAdornment}`} />}
                style={{marginBottom: 0}}
                onChange={onChange}
                onKeyDown={onKeyDown}
                clear={clear}
            />
            <OutlinedButton
                className={classes.submitButton}
                color="primary"
                label={localizedFilters.submitButtonLabel}
                onClick={() => submit(fieldValue)}
                data-testid="textInputSubmitButton"
            />
        </Box>
    );
};

const FilterButtonTextField = withModuleFilter(TextInputWithSubmitButton);

export default FilterButtonTextField;
