import React, {useCallback, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {
    DataGrid,
    DataGridProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridCellEditStartParams,
    GridCellEditStartReasons,
    GridColumnVisibilityModel,
    GridSortModel,
    MuiEvent,
} from '@mui/x-data-grid';
import type {} from '@mui/x-data-grid/themeAugmentation';
import {v4 as uuid} from 'uuid';

import {InfoAlert} from '@components/alerts/InfoAlert';
import {StyledCheckbox} from '@components/checkbox/StyledCheckbox';
import StyledPagination from '@components/StyledPagination';

import {IModuleGridItem} from '../types';

import {GridColumnsButton, GridColumnsButtonProps} from './GridColumnsButton';
import {GridColumnsPanel, GridColumnsPanelProps} from './GridColumnsPanel';
import {
    useColumns,
    useGridColumnConfiguration,
    useGridItemsSelected,
    useGridLocalization,
    useGridPaging,
    useGridSorting,
    useGridStyle,
    useRows,
} from './hooks';
import {ExtendedMuiGridColDef, GridColDef, SortableColDef} from './types';

export type GridPageChangeParams = {
    page: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
    paginationMode: 'client' | 'server';
};

export type GridSortModelParams = {
    sortModel: GridSortModel;
    columns: SortableColDef[];
    api: any;
};

export type GridSelectionModelChangeParams = {
    selectionModel: (string | number)[];
};

const defaultRowHeight = 48;

export type MuiDataGridProps = Omit<
    DataGridProps,
    'columns' | 'pageSizeOptions' | 'paginationModel' | 'onPaginationModelChange' | 'onSortModelChange'
> & {
    rows: IModuleGridItem[];
    rowsPerPageOptions?: number[];
    page?: number;
    pageSize?: number;
    pinnedRow?: IModuleGridItem;
    columns: GridColDef[];
    domain?: string;
    style?: React.CSSProperties;
    emptyMessage?: MessageDescriptor;
    selectedItems?: IModuleGridItem[];
    defaultSortModel?: GridSortModel;
    hidePagination?: boolean;
    onSelect?: (ids: string[]) => void;
    onEdit?: (index: number, field: string, value: unknown) => void;
    hideHeader?: boolean;
    hideRows?: boolean;
    hasColumnSelection?: boolean;
    pinnedColumns?: string[];
    visibleColumns?: string[];
    onPageChange?: (params: GridPageChangeParams) => void;
    onPageSizeChange?: (params: GridPageChangeParams) => void;
    onColumnsVisibilityUpdate?: (visibleColumns: string[]) => void;
    onSortModelChange?: (params: GridSortModelParams) => void;
    onSelectionModelChange?: (params: GridSelectionModelChangeParams) => void;
    isFlexible?: boolean;
    noToolbarMargin?: boolean;
};

const MuiDataGrid = (props: MuiDataGridProps) => {
    const {
        pinnedColumns,
        pinnedRow,
        visibleColumns,
        hasColumnSelection,
        isFlexible,
        hideRows,
        hideHeader,
        domain,
        emptyMessage,
        style,
        className,
        noToolbarMargin,
        hidePagination,
        slots,
    } = props;
    const [gridId] = useState<string>(uuid());

    const {classes, cx} = useGridStyle({autoHeight: props.autoHeight});
    const {localeText, components: localizationComponents} = useGridLocalization();

    const {rows, rowsPerPageOptions} = useRows({
        rows: props.rows,
        pinnedRow,
        rowsPerPageOptions: props.rowsPerPageOptions,
    });

    const {columns, onColumnsVisibilityUpdate} = useColumns({
        columns: props.columns,
        pinnedColumns,
        visibleColumns,
        hasColumnSelection,
        isFlexible,
        onColumnsVisibilityUpdate: props.onColumnsVisibilityUpdate,
    });
    const {page, pageSize, paginationMode, rowCount, onPageChange, onPageSizeChange} = useGridPaging(props);
    const {sortModel, sortingMode, onSortModelChange} = useGridSorting({...props, columns});
    const gridSelectionProps = useGridItemsSelected(props);

    //TODO: remove from DataGrid
    useGridColumnConfiguration(columns, domain);

    const handleCellEditStart = useCallback((params: GridCellEditStartParams, event: MuiEvent<React.KeyboardEvent | React.MouseEvent>) => {
        if (params.reason === GridCellEditStartReasons.printableKeyDown || params.reason === GridCellEditStartReasons.deleteKeyDown) {
            event.defaultMuiPrevented = true;
        }
    }, []);

    function getColumnVisibilityModel() {
        const columnVisibilityModel: GridColumnVisibilityModel = props?.columnVisibilityModel ?? {};
        columns.forEach((column: ExtendedMuiGridColDef) => (columnVisibilityModel[column.field] = !column.hide));
        return columnVisibilityModel;
    }

    return rows.length || !emptyMessage ? (
        <>
            <Box id={gridId} className={classes.dataGridWrapper} style={style}>
                <DataGrid
                    {...props}
                    {...gridSelectionProps}
                    localeText={localeText}
                    className={cx(
                        classes.dataGridRoot,
                        {
                            [classes.dataGridHideHeader]: hideHeader,
                            [classes.dataGridHideRows]: hideRows,
                            [classes.dataGridWithPinnedRow]: !!pinnedRow,
                        },
                        className
                    )}
                    rows={rows}
                    getRowId={r => r?.id ?? uuid()}
                    columns={columns}
                    columnBuffer={columns.length}
                    //sorting
                    sortModel={sortModel}
                    sortingMode={sortingMode}
                    sortingOrder={['desc', 'asc']}
                    columnVisibilityModel={getColumnVisibilityModel()}
                    onSortModelChange={onSortModelChange}
                    //paging
                    paginationModel={{
                        page: page,
                        pageSize: pinnedRow ? pageSize + 1 : pageSize,
                    }}
                    paginationMode={paginationMode}
                    rowCount={rows?.length ?? 0}
                    rowHeight={defaultRowHeight}
                    //config
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector={!hasColumnSelection}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    hideFooter
                    disableColumnMenu
                    onCellEditStart={handleCellEditStart}
                    slots={{
                        ...slots,
                        ...localizationComponents,
                        baseCheckbox: StyledCheckbox,
                        toolbar: hasColumnSelection ? GridColumnsButton : null,
                        columnsPanel: hasColumnSelection ? GridColumnsPanel : null,
                    }}
                    slotProps={{
                        columnsPanel: {
                            allColumns: columns?.filter(c => c.field !== GRID_CHECKBOX_SELECTION_COL_DEF?.field),
                            pinnedColumnNames: pinnedColumns,
                            onColumnsVisibilityUpdate,
                        } as GridColumnsPanelProps,
                        toolbar: {noToolbarMargin} as GridColumnsButtonProps,
                    }}
                />
            </Box>
            {!hidePagination && !hideRows ? (
                <StyledPagination
                    count={rowCount ?? 0}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={rowsPerPageOptions}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                />
            ) : null}
        </>
    ) : (
        <InfoAlert title={emptyMessage} className={classes.dataGridEmptyMessage} />
    );
};

export default MuiDataGrid;
