import React, {FC, memo, useContext} from 'react';
import {MessageDescriptor} from 'react-intl';
import {defineMessages, IntlContext, useIntl} from 'react-intl';
import {Badge, Box, Chip as MuiChip} from '@mui/material';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {chipsMapping, ChipsTypes} from '../../config/chip';
import {withEmptyCheck} from '../label/EmptyLabel';
import StyledTooltip from '../Tooltip';
import {withAutoAdjustedComplexTooltip} from '../tooltip/AutoAdjustedComplexTooltip';
import {AutoAdjustedWidthTooltip} from '../tooltip/AutoAdjustedWidthTooltip';

import {Chip} from './Chips';
import {withChipTooltip, withLabelChipTooltip} from './ChipTooltipHoc';
import {useChipValue} from './ChipValue.hooks';
import {ChipType, ChipVariant} from './types';

const localized = defineMessages({
    chipIdLabel: {
        id: 'chipIdLabel',
        defaultMessage: 'ID',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    chipContainer: {
        columnGap: theme.spacing(2),
        display: 'flex',
        overflow: 'hidden',
        width: 'fit-content',
    },
    chipBadge: {
        marginRight: theme.spacing(2),
        '& .MuiBadge-anchorOriginTopRightRectangular': {
            top: `${theme.spacing(1.5)} !important`,
        },
    },
    idChip: {
        ...(theme.typography.subtitle2 as CSSObject),
        height: theme.custom.chipHeight,
        background: theme.palette.background.paper,
        border: `1px solid ${theme.custom.palette.content.liner}`,
        borderRadius: 100,
        '& .MuiChip-avatar': {
            color: theme.palette.secondary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& .MuiChip-label': {
            paddingLeft: theme.spacing(0.5),
            fontWeight: 500,
        },
    },
}));

export type MemoizedDefaultChipProps = {
    tooltipLabel?: string;
    value: string;
    variant: ChipVariant;
    type: ChipType;
    className?: string;
    startIconClass?: string;
    hideTooltip?: boolean;
};

export type MemoizedChipProps = {
    tooltipLabel?: string;
    value: string;
    chipName: string;
    cursorPointer?: boolean;
    label?: string | MessageDescriptor;
    onDelete?: (event: any) => void;
    hideTooltip?: boolean;
};

export type MemoizedChipListProps = {
    value: string[];
    chipName: string;
};

export type MemoizedLabelChipProps = {
    tooltipLabel?: string;
    value: string;
    hideTooltip?: boolean;
};

export const MemoizedLabelChip: FC<MemoizedLabelChipProps> = withEmptyCheck(
    withLabelChipTooltip(
        withAutoAdjustedComplexTooltip(
            memo(({value}: MemoizedLabelChipProps) => {
                const {classes} = useStyles();
                const {formatMessage} = useContext(IntlContext);

                return (
                    <Box className={classes.chipContainer}>
                        <MuiChip className={classes.idChip} avatar={<span>{formatMessage(localized.chipIdLabel)}</span>} label={value} />
                    </Box>
                );
            })
        )
    )
);

export const MemoizedChip: FC<MemoizedChipProps> = withEmptyCheck(
    withChipTooltip(
        withAutoAdjustedComplexTooltip(
            memo(({value, label, chipName, cursorPointer, onDelete}: MemoizedChipProps) => {
                const {classes} = useStyles();
                const {chip, chipLabel} = useChipValue(value, chipName, label);

                return chip ? (
                    <Box className={classes.chipContainer}>
                        <Chip
                            cursorPointer={cursorPointer}
                            startIconClass={chip.startIconClass}
                            chipType={chip.chipType}
                            chipVariant={chip.chipVariant}
                            label={chipLabel}
                            onDelete={onDelete}
                        />
                    </Box>
                ) : (
                    <AutoAdjustedWidthTooltip value={chipLabel}></AutoAdjustedWidthTooltip>
                );
            })
        )
    )
);

export const MemoizedChipList: FC<MemoizedChipListProps> = withEmptyCheck(
    memo(({value, chipName}: MemoizedChipListProps) => {
        const {classes} = useStyles();
        const {formatMessage} = useIntl();
        const chips = chipsMapping[chipName];

        return (
            <Box className={classes.chipContainer}>
                <StyledTooltip
                    element={
                        <Box display="flex">
                            <Badge className={classes.chipBadge} badgeContent={value?.length} color="default" />
                            <MemoizedChip value={value[0]} chipName={chipName} />
                        </Box>
                    }
                    title={value?.map(i => (
                        <Box>
                            {chips[i].labelTypeString ? (chips[i].label as string) : formatMessage(chips[i].label as MessageDescriptor)}
                        </Box>
                    ))}
                />
            </Box>
        );
    })
);

export const MemoizedDefaultChip: FC<MemoizedDefaultChipProps> = withEmptyCheck(
    withLabelChipTooltip(
        withAutoAdjustedComplexTooltip(
            memo(({value, variant, type, className, startIconClass}: MemoizedDefaultChipProps) => {
                const {classes} = useStyles();

                return (
                    <Box className={`${classes.chipContainer} ${className ?? ''}`}>
                        <Chip chipType={type} chipVariant={variant} label={value} startIconClass={startIconClass} />
                    </Box>
                );
            })
        )
    )
);

export const renderDefaultChip = (value: string, variant: ChipVariant, type: ChipType) => {
    return <MemoizedDefaultChip value={value} variant={variant} type={type} />;
};

export const renderChip = <T extends ChipsTypes>(value: T, type: string) => {
    return <MemoizedChip value={value} chipName={type} />;
};

export const renderChipList = <T extends ChipsTypes>(value: T[], type: string) => {
    return <MemoizedChipList value={value} chipName={type} />;
};
