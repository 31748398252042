import {Observable, of} from 'rxjs';

import {IModuleGridItem} from '@components/data-grid';

import {IModuleItem} from '../../../features/module-shared/types';

import {ReadOrUpdateRealtimeGridServiceBase} from './readOrUpdateRealtimeGridServiceBase';

/**
 * @deprecated should use services from src/common/services
 */
export abstract class ReadonlyRealtimeGridServiceBase<
    TItemModel extends IModuleItem,
    TGridItemModel extends IModuleGridItem,
    TServerModel
> extends ReadOrUpdateRealtimeGridServiceBase<TItemModel, TGridItemModel, TServerModel> {
    editItem(_item: IModuleItem): Observable<string> {
        return of();
    }
}
