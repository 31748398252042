import {useIntl} from 'react-intl';

import {ExtendedMuiGridColDef, GridColDef} from '../types';

export function useColumnHeaderName(
    columns: GridColDef[]
): (Omit<GridColDef, 'renderHeader'> & Pick<ExtendedMuiGridColDef, 'renderHeader'>)[] {
    const {formatMessage} = useIntl();
    return columns?.map(column => {
        const renderHeader = column?.renderHeader
            ? column?.localizedHeaderName
                ? () => column?.renderHeader(column?.localizedHeaderName)
                : () => column?.renderHeader('')
            : undefined;
        return {
            ...column,
            headerName: column?.headerName ?? (column?.localizedHeaderName ? formatMessage(column.localizedHeaderName) : ' '),
            renderHeader,
        };
    });
}
