import {createAsyncAction} from 'typesafe-actions';

import {
    CloseGameRoomRequestPayload,
    CloseRoomServiceResponsePayload,
    KickoffPlayerRequestPayload,
    KickoffPlayerServiceResponsePayload,
} from '@services/rest-api/plo5GameManagementApiService';

const domain = 'block-game-room-actions';

export const gameRoomActions = {
    closeRoom: createAsyncAction(`${domain}/closeRoomRequest`, `${domain}/closeRoomSuccess`, `${domain}/closeRoomFailure`)<
        CloseGameRoomRequestPayload,
        CloseRoomServiceResponsePayload,
        CloseRoomServiceResponsePayload
    >(),

    kickoffPlayer: createAsyncAction(`${domain}/kickoffPlayerRequest`, `${domain}/kickoffPlayerSuccess`, `${domain}/kickoffPlayerFailure`)<
        KickoffPlayerRequestPayload,
        KickoffPlayerServiceResponsePayload,
        KickoffPlayerServiceResponsePayload
    >(),
};
