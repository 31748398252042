import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useClasses = makeStyles()(theme => ({
    layoutQueueHeader: {},
    layoutQueueToolbar: {
        margin: theme.spacing(0, 5, 2.5),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 2, 2),
        },
    },
    layoutQueueContent: {
        display: 'contents',
    },
    layoutQueueBody: {
        display: 'flex',
        width: '100%',
        flex: 1,
        position: 'relative',
        flexDirection: 'column',
        overflowY: 'hidden',
    },
    layoutQueueFooter: {},
}));

type LayoutQueueProps = {
    header?: React.ReactNode;
    toolbar?: React.ReactNode;
    body?: React.ReactNode;
    footer?: React.ReactNode;
    dataTestId?: string;
};

export const LayoutQueue = ({header, toolbar, body, footer, dataTestId}: LayoutQueueProps) => {
    const {classes} = useClasses();

    return (
        <>
            {header ? <Box className={classes.layoutQueueHeader}>{header}</Box> : null}
            {toolbar || body ? (
                <Box className={classes.layoutQueueContent} data-testid={dataTestId}>
                    {toolbar ? <Box className={classes.layoutQueueToolbar}>{toolbar}</Box> : null}
                    {body ? <Box className={classes.layoutQueueBody}>{body}</Box> : null}
                </Box>
            ) : null}
            {footer ? <Box className={classes.layoutQueueFooter}>{footer}</Box> : null}
        </>
    );
};
