import React from 'react';

import {TransactionFilterKeys} from '@redux/entity';
import {ViewType} from '@redux/view';

import {Filter} from 'src/common/types';
import AgentFilterObsolete from '../../block-agent-profile/components/AgentFilter';

import TransactionFilterAmount from './TransactionFilterAmount';
import TransactionFilterCountry from './TransactionFilterCountry';
import TransactionFilterDate from './TransactionFilterDate';
import TransactionFilterDuration from './TransactionFilterDuration';
import TransactionFilterPaymentMethod from './TransactionFilterPaymentMethod';
import {TransactionFilterAllStatuses, TransactionFilterClosedStatuses, TransactionFilterPendingStatuses} from './TransactionFilterStatuses';
import {UidEmailUsernameMarketingCodeFilterText, UidUsernameTransactionIdEmailFilterText} from './TransactionFilterText';
import {default as TransactionFilterTypeComponent} from './TransactionFilterType';
import TransactionLabelFilter from './TransactionLabelFilter';

type TransactionFilterType =
    | 'transactionHistoryTypes'
    | 'paymentMethod'
    | 'amount'
    | 'status'
    | 'uidEmailUsernameMarketingCode'
    | 'uidUsernameTransactionIdEmailText'
    | 'pendingStatuses'
    | 'closedStatuses'
    | 'date'
    | 'duration'
    | 'agent'
    | 'country'
    | 'labels';

export type TransactionFilterProps = {
    viewType?: ViewType;
    filter: string;
    defaultFilter?: Filter<unknown, TransactionFilterKeys>[];
    onFilterChange: (newFilter: Filter[]) => void;
};

type TransactionFiltersProps = TransactionFilterProps & {
    filters: TransactionFilterType[];
};

export function TransactionFilters({filters, ...props}: TransactionFiltersProps): JSX.Element {
    const filterMapping: Record<TransactionFilterType, React.ComponentType<TransactionFilterProps>> = {
        uidEmailUsernameMarketingCode: UidEmailUsernameMarketingCodeFilterText,
        transactionHistoryTypes: TransactionFilterTypeComponent,
        paymentMethod: TransactionFilterPaymentMethod,
        amount: TransactionFilterAmount,
        status: TransactionFilterAllStatuses,
        uidUsernameTransactionIdEmailText: UidUsernameTransactionIdEmailFilterText,
        date: TransactionFilterDate,
        pendingStatuses: TransactionFilterPendingStatuses,
        closedStatuses: TransactionFilterClosedStatuses,
        duration: TransactionFilterDuration,
        country: TransactionFilterCountry,
        agent: AgentFilterObsolete,
        labels: TransactionLabelFilter,
    };

    return (
        <>
            {filters.map(type => {
                const FilterComponent = filterMapping[type];
                return <FilterComponent key={type} {...props} />;
            })}
        </>
    );
}
