import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {Chip} from '@components/chip/Chips';
import {ChipType} from '@components/chip/types';
import {withStyledInputLabel} from '@components/input';

import {Label} from '../types';

const useClasses = makeStyles()(theme => ({
    blockPlayerLabelGroupIncludedLabels: {
        display: 'flex',
        columnGap: theme.spacing(1),
        marginTop: theme.spacing(0.5),
    },
}));

type BlockPlayerLabelGroupIncludedLabelsProps = {
    color: string;
    labels: Label[];
};

function LabelGroupIncludedLabels({color, labels}: BlockPlayerLabelGroupIncludedLabelsProps) {
    const {classes} = useClasses();

    return (
        <Box className={classes.blockPlayerLabelGroupIncludedLabels}>
            {labels.map(label => (
                <Chip key={label.id} chipType={ChipType.Status} chipVariant={color} label={label.name} fillWidth={false} size="medium" />
            ))}
        </Box>
    );
}

export const BlockPlayerLabelGroupIncludedLabels = withStyledInputLabel(LabelGroupIncludedLabels);
