import React from 'react';

import {TransactionDateRangeFilterPrefix, TransactionFilterKeys} from '@redux/entity';

import {ModuleFilterDateRangeObsolete} from '../../shared/filter/components/date-range-picker/FilterDateRangePickerObsolete';

import {TransactionFilterProps} from './TransactionFilters';

const keys: TransactionFilterKeys[] = ['startedTs.from', 'startedTs.to'];

function TransactionFilterDate({filter, defaultFilter, onFilterChange}: TransactionFilterProps) {
    return (
        <ModuleFilterDateRangeObsolete
            onFilterChange={onFilterChange}
            domain={null}
            filter={{
                key: keys,
                options: TransactionDateRangeFilterPrefix,
                defaultValue: defaultFilter,
            }}
            filterString={filter}
        />
    );
}

export default TransactionFilterDate;
