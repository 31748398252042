import {createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {GameBlindViewModel} from '@models/game-blind';
import {GameBlind} from '@redux/entity';

export class GameBlindMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            GameBlind,
            GameBlindViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m?.id)
            ),
            forMember(
                vm => vm.sb,
                mapFrom(m => (m?.sb ? m?.sb / 100 : null))
            ),
            forMember(
                vm => vm.bb,
                mapFrom(m => (m?.bb ? m?.bb / 100 : null))
            )
        );
    }
}
