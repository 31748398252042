import React from 'react';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {TransactionType} from '@models/generated/graphql';
import {ModuleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {TransactionViewModelKeys} from '@models/transaction';
import {Policy} from '@auth';
import {extendedViewCleanDelay, ViewType} from '@redux/view';

import {withModule} from 'src/features/module-shared/components/ModuleHoc';
import {TransactionDownloadCsvButton} from '../features/block-transaction-actions';
import {
    DataGridTransactionsServer,
    getUrlWithoutOutdatedParams,
    TransactionFilters,
    useTransactions,
} from '../features/block-transaction-list';

const localized = defineMessages<string, MessageDescriptor>({
    title: {
        id: 'AgentManagementTransferReport_title',
        defaultMessage: 'Transfer Report',
    },
});

function AgentManagementTransferReport() {
    const viewType: ViewType = 'AgentManagementTransferReport';
    const keys: TransactionViewModelKeys[] = [
        'uid',
        'email',
        'username',
        'referrer_player_id',
        'register_marketing_code',
        'player_country',
        'transaction_started_ts',
        'transaction_type',
        'transaction_status',
        'amount',
        'counterpart_player_id',
    ];
    const {items, handlePageChange, handlePageSizeChange, handleSortChange, searchFilter, totalCount, filterString, handleFilterChange} =
        useTransactions({
            viewType,
            displayName: localized.title,
            fields: keys,
            defaultFilters: [{key: 'transactionTypes', value: [TransactionType.P2PTransferDebit, TransactionType.P2PTransferCredit]}],
            defaultSorting: 'started',
            validateFilter: () => true,
            syncWithUrl: true,
            cleanDelay: extendedViewCleanDelay,
        });
    const {formatMessage} = useIntl();
    const redirectUrl = getUrlWithoutOutdatedParams(location);

    return (
        <>
            {redirectUrl ? <Redirect to={redirectUrl} /> : null}
            <LayoutQueue
                header={
                    <PageHeader
                        header={localized.title}
                        actions={
                            <TransactionDownloadCsvButton
                                keys={keys}
                                filterString={filterString}
                                filename={formatMessage(localized.title)}
                            />
                        }
                    />
                }
                toolbar={
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems streched>
                                <TransactionFilters
                                    viewType="AgentManagementTransferReportFilters"
                                    filter={filterString}
                                    onFilterChange={handleFilterChange}
                                    filters={['uidEmailUsernameMarketingCode', 'agent', 'date']}
                                />
                            </ToolbarItems>
                        </ToolbarItemsContainer>
                    </Toolbar>
                }
                body={
                    <DataGridTransactionsServer
                        columns={keys}
                        rows={items}
                        rowCount={totalCount}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortModelChange={handleSortChange}
                        page={searchFilter?.paging?.page}
                        pageSize={searchFilter?.paging?.pageSize}
                        sortModel={searchFilter?.sorting}
                    />
                }
            />
        </>
    );
}

export const agentManagementTransferReportResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'AgentManagementTransferReport',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageAgentReport, null, 'read')]),
});

export default withModule(AgentManagementTransferReport, [{moduleName: ModuleName.PageAgentReport, permissions: [PermissionEnum.Read]}]);
