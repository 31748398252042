import {createAction} from 'typesafe-actions';

import {PageIdDescriptor} from '@redux/entity';
import {ViewType} from '@redux/view';

const domain = 'pageView';

export type StartPollingPayload = {
    viewType: ViewType;
    interval: number;
};

export type StartPingingPayload = {
    viewType: ViewType;
    pageIdDescriptor: PageIdDescriptor;
    interval: number;
};

export const pageViewActions = {
    startPolling: createAction(`${domain}/startPolling`)<StartPollingPayload>(),
    stopPolling: createAction(`${domain}/stopPolling`)(),
    startPinging: createAction(`${domain}/startPinging`)<StartPingingPayload>(),
    stopPinging: createAction(`${domain}/stopPinging`)(),
};
