import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {CommentFormModel} from '@components/comment';
import {useService} from '@inversify';
import {EntityType} from '@models/generated/graphql';
import {useAuthUser} from '@auth';

import {
    ApplyNotesRequest,
    ApplyNoteStrategy,
    bulkActionsActions,
    BulkNoteApplyStepModal,
    BulkStepProps,
    itemsSelector,
} from '../../block-bulk-actions';
import type {NoteMappingExtraArgs} from '../../block-note/mapper';
import {EntityViewModel, NoteInputViewModel} from '../../block-note/types';
import {UserProfileGridItem} from '../../block-user-profile-list';

export function BulkUserProfileApplyNotesModal({onPrev, onNext}: BulkStepProps) {
    const dispatch = useDispatch();
    const user = useAuthUser();
    const strategy = useService<ApplyNoteStrategy>('ApplyNoteStrategy');
    const players: UserProfileGridItem[] = useSelector(itemsSelector) as UserProfileGridItem[];
    const mapper = useAutoMapper();

    function handleApply(commentFormModel: CommentFormModel) {
        const notesRequest: ApplyNotesRequest = {
            notes: players?.map(i => {
                const noteInput: NoteInputViewModel = mapper.map(commentFormModel, CommentFormModel, NoteInputViewModel, {
                    extraArgs: (): NoteMappingExtraArgs => ({entity: getPlayerEntity(i.uid), postedByUid: user.sub, userId: i.uid}),
                });
                return {
                    ...noteInput,
                    entityId: i.serverId,
                };
            }),
        };
        dispatch(bulkActionsActions.apply({request: notesRequest, strategy}));

        onNext();
    }

    function getPlayerEntity(id: string): EntityViewModel {
        const entityItem = {id, type: EntityType.Player};
        return {parent: entityItem, ...entityItem};
    }

    return <BulkNoteApplyStepModal onPrev={onPrev} onNext={handleApply} />;
}
