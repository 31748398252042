import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {CasinoGame, CasinoGameFields, CasinoGameFilterKeys, CasinoSourceType} from '@models/casino-game';
import {map, mergeMap} from '@otel';
import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload} from '@redux/entity';
import {ICasinoCmsApiService} from '@services/rest-api';
import {CasinoArrayFilterWithMode, GetGamesPayload} from '@services/rest-api/casinoCmsApiService';
import {ServerResponseStatus} from '@services/types';
import {getValueFromQueryFilter} from '@utils/query';

@injectable()
export class CasinoGamesService {
    private readonly _casinoCmsService: ICasinoCmsApiService;

    constructor(@inject(ServiceTypes.CasinoCmsApiService) casinoCmsService: ICasinoCmsApiService) {
        this._casinoCmsService = casinoCmsService;
    }

    public get(requestPayload: EntityFetchRequestPayload<CasinoGameFields>): Observable<EntityFetchServiceResponsePayload<CasinoGame>> {
        let result: Observable<EntityFetchServiceResponsePayload<CasinoGame>> = this.getDefaultCasinoGamesResponse(requestPayload);
        let sourceTypes: string[];
        let isInvalid: boolean;
        try {
            sourceTypes = this.getJsonFilter<string[]>(requestPayload.filter, 'source_type');
            isInvalid = this.getJsonFilter<boolean>(requestPayload.filter, 'invalid');
        } catch (e) {
            result = this.getErrorCasinoGamesResponse(requestPayload, e);
            isInvalid = true;
        }

        if (!isInvalid) {
            try {
                result = this.getGamesPage(requestPayload, sourceTypes);
            } catch (e) {
                result = this.getErrorCasinoGamesResponse(requestPayload, e);
            }
        }

        return result;
    }

    private getGamesPage(requestPayload: EntityFetchRequestPayload<CasinoGameFields>, sourceTypes: string[]) {
        return this.getInternalGames(requestPayload, sourceTypes).pipe(
            mergeMap(internalGamesResponse => {
                let result = of(internalGamesResponse);
                if (internalGamesResponse.status === ServerResponseStatus.Success) {
                    const internalGamesPayload = internalGamesResponse?.responsePayload;
                    result = this.getExternalGames(
                        requestPayload,
                        sourceTypes,
                        internalGamesPayload?.total,
                        internalGamesPayload?.items?.length
                    ).pipe(
                        mergeMap(externalGamesResponse => {
                            let result: Observable<EntityFetchServiceResponsePayload<CasinoGame>> = of(externalGamesResponse);
                            if (externalGamesResponse.status === ServerResponseStatus.Success) {
                                const externalGamesPayload = externalGamesResponse?.responsePayload;
                                result = of({
                                    status: ServerResponseStatus.Success,
                                    requestPayload,
                                    responsePayload: {
                                        items: [...internalGamesPayload?.items, ...externalGamesPayload?.items],
                                        total: internalGamesPayload?.total + externalGamesPayload?.total,
                                    },
                                });
                            }
                            return result;
                        })
                    );
                }
                return result;
            })
        );
    }

    private getInternalGames(
        requestPayload: EntityFetchRequestPayload<CasinoGameFields>,
        sourceTypes: string[]
    ): Observable<EntityFetchServiceResponsePayload<CasinoGame>> {
        const isInternalGameFilter = sourceTypes?.includes(CasinoSourceType.Internal) || !sourceTypes?.length;
        const gamesPayload = this.getGamesFetchPayload(requestPayload);
        return isInternalGameFilter
            ? this._casinoCmsService.getInternalGames(gamesPayload).pipe(
                  map(result => ({
                      ...result,
                      requestPayload,
                  }))
              )
            : this.getDefaultCasinoGamesResponse(requestPayload);
    }

    private getExternalGames(
        requestPayload: EntityFetchRequestPayload<CasinoGameFields>,
        sourceTypes: string[],
        totalInternalGames: number,
        internalGamesOnPage: number
    ): Observable<EntityFetchServiceResponsePayload<CasinoGame>> {
        let externalGamesObservable: Observable<EntityFetchServiceResponsePayload<CasinoGame>> =
            this.getDefaultCasinoGamesResponse(requestPayload);
        if (sourceTypes?.includes(CasinoSourceType.External) || !sourceTypes?.length) {
            const gamesPayload = this.getGamesFetchPayload(requestPayload, totalInternalGames, internalGamesOnPage);
            externalGamesObservable = this._casinoCmsService.getExternalGames(gamesPayload).pipe(
                map(r => ({
                    ...r,
                    requestPayload,
                    responsePayload: r.responsePayload
                        ? {
                              items: r.responsePayload.data?.map(i => i?.attributes),
                              total: r.responsePayload.meta?.pagination?.total,
                          }
                        : null,
                }))
            );
        }

        return externalGamesObservable;
    }

    private getJsonFilter<TValue>(filter: string, key: CasinoGameFilterKeys): TValue {
        const value = getValueFromQueryFilter<CasinoGameFilterKeys, string>(filter, key);
        let result = null;
        if (value) {
            try {
                result = JSON.parse(value);
            } catch {
                throw new Error(`Casino Games filter for ${key} has invalid value: ${value}`);
            }
        }

        return result;
    }

    private getNumberFromFilter(filter: string, key: CasinoGameFilterKeys): number {
        const filterValue = getValueFromQueryFilter<CasinoGameFilterKeys, string>(filter, key);
        const value = Number(filterValue);
        return isNaN(value) ? null : value;
    }

    private getGamesFetchPayload(
        entityPayload: EntityFetchRequestPayload<CasinoGameFields>,
        totalOffset = 0,
        currentPageOffset = 0
    ): GetGamesPayload {
        const page = this.getNumberFromFilter(entityPayload.filter, 'page');
        const size = this.getNumberFromFilter(entityPayload.filter, 'size');
        return {
            title: getValueFromQueryFilter<CasinoGameFilterKeys, string>(entityPayload.filter, 'title'),
            suppliers: this.getJsonFilter<CasinoArrayFilterWithMode>(entityPayload.filter, 'supplier_codes'),
            aggregators: this.getJsonFilter<CasinoArrayFilterWithMode>(entityPayload.filter, 'aggregators'),
            start: (page - 1) * size - totalOffset + currentPageOffset,
            limit: size - currentPageOffset,
        };
    }

    private getDefaultCasinoGamesResponse(
        requestPayload: EntityFetchRequestPayload<CasinoGameFields>
    ): Observable<EntityFetchServiceResponsePayload<CasinoGame>> {
        return of({
            status: ServerResponseStatus.Success,
            requestPayload,
            responsePayload: {items: [], total: 0},
        });
    }

    private getErrorCasinoGamesResponse(
        requestPayload: EntityFetchRequestPayload<CasinoGameFields>,
        error: Error
    ): Observable<EntityFetchServiceResponsePayload<CasinoGame>> {
        return of({
            status: ServerResponseStatus.Failed,
            requestPayload,
            responsePayload: null,
            message: error.message,
            errors: null,
        });
    }
}
