import ExcelJS from 'exceljs';
import {from, Observable} from 'rxjs';

import {DataWorksheet} from '../types';

import {BaseExcelDocumentBuilder} from './BaseExcelDocumentBuilder';

export class CsvDocumentBuilder extends BaseExcelDocumentBuilder {
    public createDocument<TKeys extends string>(dataWorksheet: DataWorksheet<TKeys>): Observable<ArrayBuffer> {
        const worksheetKey = dataWorksheet.title;
        const colDefs = Object.values(dataWorksheet.columns);

        this.setWorksheet(worksheetKey, {});
        this.setColumns(worksheetKey, colDefs);
        this.setData(worksheetKey, dataWorksheet.data);
        const document = this.getDocument();

        return from(document.writeBuffer());
    }

    private getDocument(): ExcelJS.Csv {
        const result = this.workbook.csv;
        this.reset();

        return result;
    }
}
