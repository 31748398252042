import React from 'react';
import {defineMessages} from 'react-intl';

import {EmptyPlaceholder, localizedEmptyPlaceholders} from '@components/select';
import {Workspace} from '@models/generated/graphql';
import {NoteFilterKeys} from '@redux/entity';

import {SelectOption} from 'src/features/module-shared/types';
import FilterSingleSelect from 'src/features/shared/filter/components/FilterSingleSelect';
import {PageModuleChipSelectFilter} from 'src/features/shared/filter/types';
import {useWorkspaceFilterOptions} from '../hooks';

import {NoteFilterProps} from './NotesFilters';

const localized = defineMessages({
    filterType: {
        id: 'NoteFilterType_filterType',
        defaultMessage: 'Workspace',
    },
});

function NoteFilterWorkspace({filter, onFilterChange}: NoteFilterProps) {
    const filterKey: NoteFilterKeys = 'workspace';
    const workspaces = useWorkspaceFilterOptions();
    const options = [
        {label: localizedEmptyPlaceholders.all, value: [Workspace.Global, ...workspaces.map(w => w.value)]} as SelectOption,
        ...workspaces,
    ];

    return (
        <FilterSingleSelect
            onFilterChange={onFilterChange}
            filter={
                {
                    key: filterKey,
                    label: localized.filterType,
                    chipType: nameof<Workspace>(),
                    options,
                    defaultValue: [{key: filterKey, value: options[0].value}],
                    placeholder: localized.filterType,
                    isSelectedValueChip: true,
                    emptyPlaceholder: EmptyPlaceholder.All,
                    showResetButton: false,
                } as PageModuleChipSelectFilter
            }
            filterString={filter}
        />
    );
}
export default NoteFilterWorkspace;
