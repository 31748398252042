import {defineMessages, MessageDescriptor} from 'react-intl';

import {CustomErrorCodes, HttpStatusCode} from '@services/types';

export const errorMessages = defineMessages({
    defaultError: {
        id: 'Operation failed.',
        defaultMessage: 'Operation failed.',
    },
    gqlExecutionTimeout: {
        id: 'Execution timed out.',
        defaultMessage: 'Execution timed out.',
    },

    //Http code errors
    unhandledServerError: {
        id: 'error/500',
        defaultMessage: 'Unexpected error occurred.',
    },
    notAvailable: {
        id: 'error/502',
        defaultMessage: 'Service is not available.',
    },
    notAuthorized: {
        id: 'error/401',
        defaultMessage: 'User is not authorized.',
    },
    notEnoughPermissions: {
        id: 'error/403',
        defaultMessage: 'User does not have the required permissions to perform an action.',
    },
    notFound: {
        id: 'error/404',
        defaultMessage: 'Record not found.',
    },
    conflict: {
        id: 'error/409',
        defaultMessage: 'Conflict during update.',
    },
    userSetInvalidWithdrawalLimits: {
        id: 'action-center/saveWithdrawalLimitsFailed/400',
        defaultMessage: 'Withdrawal limits specified are invalid (0 <= daily <= weekly <= monthly).',
    },
    userBlockStakesFailed: {
        id: 'action-center/updateStakeLimitsFailed/400',
        defaultMessage: 'Update stakes access failed. One or more stakes limit was not set.',
    },
    userSecurityCasesConflict: {
        id: 'action-center/updateSecurityCaseFailed/409',
        defaultMessage: 'Security case of the selected type has already been created',
    },
    botCasesConflict: {
        id: 'bot-detection/bot-player-view/updateBotCaseFailed/409',
        defaultMessage: 'Security case of the selected type has already been created',
    },

    //GraphQL errors
    gqlUnauthorized: {
        id: 'Not Authorized',
        defaultMessage: 'Not Authorized',
    },
    fieldUndefinedValidationError: {
        id: 'error/GraphQL/fieldUndefinedValidationError',
        defaultMessage: 'Validation error of type FieldUndefined',
    },

    //Custom errors
    fileUploadFailed: {
        id: 'error/20000001',
        defaultMessage: 'File upload failed. Please try again.',
    },

    marketingCodeDeletingPartiallyFailed: {
        id: 'marketing-code/deleteMarketingCodesFailed/10000',
        defaultMessage: 'Failed to delete Marketing Codes: {codes}',
    },
    marketingCodeAlreadyAvailable: {
        id: 'marketing-code/addMarketingCodeFailed/10000',
        defaultMessage: 'Marketing code {code} with bonus type {type} already exists',
    },

    entityFetchFailed: {
        id: 'entity/fetchRequestFailure/20000002',
        defaultMessage: 'Failed to fetch {type}',
    },

    inputValidationIncorrectFormat: {
        id: 'error/10000006',
        defaultMessage: 'Incorrect format',
    },
    inputValidationValueNotExist: {
        id: 'error/10000027',
        defaultMessage: 'Value does not exist',
    },
    inputValidationNullValue: {
        id: 'error/10000028',
        defaultMessage: 'This field is required',
    },
    invalidNotificationContractType: {
        id: 'error/10000048',
        defaultMessage: 'Invalid notification contract type',
    },
    unexpectedTransactionStatus: {
        id: 'error/10000050',
        defaultMessage: 'Unexpected notification transaction status from payment system for existing transaction',
    },
    transactionWithSameIdAlreadyExists: {
        id: 'error/10000056',
        defaultMessage: 'Transaction with same id already exist',
    },
    handledExceptionOccurred: {
        id: 'error/10000058',
        defaultMessage: 'Handled exception occurred',
    },
    notEnoughFunds: {
        id: 'error/10000062',
        defaultMessage: 'No enough funds',
    },
    invalidSubagentRevenueShare: {
        id: 'user-profile-actions/updatePlayerIdFailure/20000063',
        defaultMessage: 'You cannot set a revenue share % higher than parent agent',
    },
    noParentAgentRevenueShare: {
        id: 'user-profile-actions/updatePlayerIdFailure/20000064',
        defaultMessage: "The parent agent doesn't have revenue share %",
    },
});

export const successMessages = defineMessages({
    operationSuccessfullyCompleted: {
        id: 'OPERATION_SUCCEED',
        defaultMessage: 'Operation successfully completed.',
    },

    dataSaved: {
        id: 'DATA_SAVED',
        defaultMessage: 'Data successfully updated.',
    },
});

function getMessages(messages: Object): MessageDescriptor[] {
    return Object.values(messages);
}

function getMessage(messages: MessageDescriptor[], selector: (m: MessageDescriptor) => boolean, defaultMessage: MessageDescriptor) {
    return messages.find(selector) ?? defaultMessage;
}

function getErrorKeyByAction(actionType: string, errorCode: string | number | HttpStatusCode | CustomErrorCodes) {
    return `${actionType}/${errorCode}`;
}

function getErrorKeyByCode(errorCode: string | number | HttpStatusCode | CustomErrorCodes) {
    return `error/${errorCode}`;
}

export function getErrorByCode(errorCode: string | number | HttpStatusCode | CustomErrorCodes): MessageDescriptor {
    const messages = getMessages(errorMessages);
    return getMessage(messages, m => m.id === getErrorKeyByCode(errorCode), errorMessages.defaultError);
}

export function getErrorById(description: string, errorCode: string, defaultMessage?: MessageDescriptor): MessageDescriptor {
    const messages = getMessages(errorMessages);
    return getMessage(
        messages,
        m => m.id === `${description}`,
        getMessage(messages, m => m.id === `${errorCode}`, defaultMessage ?? errorMessages.defaultError)
    );
}

export function getErrorByFailureAction(
    actionType: string,
    errorCode: string | number | HttpStatusCode | CustomErrorCodes
): MessageDescriptor {
    const messages = getMessages(errorMessages);
    return getMessage(messages, m => m.id === getErrorKeyByAction(actionType, errorCode), getErrorByCode(errorCode));
}
