import {createBrowserHistory, History} from 'history';
import {combineReducers} from 'redux';

import {EntityReducerCreator} from '@redux/entity';
import {RealtimeReducerCreator} from '@redux/realtime';
import {RouterReducerCreator} from '@redux/routing';
import {viewsReducer} from '@redux/view';

import {actionHistoryReducer} from '../features/action-history/reducers';
import {breadcrumbReducer} from '../features/app/breadcrumb/reducers';
import {appConfigReducer} from '../features/app/config/reducers';
import {localizationReducer} from '../features/app/intl/reducers';
import {beBonusReducer} from '../features/be-bonus/reducers';
import {beBonusHistoryReducer} from '../features/be-bonus-history/reducers';
import {bulkActionsReducer} from '../features/block-bulk-actions';
import {NotificationReducerCreator} from '../features/block-notifications/reducers';
import {kycEntityReducer} from '../features/kyc/reducers';
import {legalDocsReducer} from '../features/legal-docs/reducers';
import {marketingCodeReducer} from '../features/marketing-code/reducers';
import {messageSnackBarReducer} from '../features/message-snack-bar/reducer';
import tempStorageReducers from '../features/module-temp-storage/reducers';
import modulesReducer from '../features/modules/reducer';
import {playerMessageReducer} from '../features/player-message/reducers';
import {globalRealtimeNotificationReducer} from '../features/realtime-updates/reducers';
import {referrerSummaryReducer} from '../features/referrer-summary/reducers';
import {asyncActionsReducer} from '../features/shared/async-action/reducers';
import {transactionReducer} from '../features/transactions/reducers';
import {userProfileReducer} from '../features/user-profile/reducers';
import {userNotificationHistoryReducer} from '../features/workspace-details/user-notification-history/reducer';
import userRegionRelatedInfoReducer from '../features/workspace-details/user-region-related-info/reducer';
import userAccessReducer from '../pages/access-management/reducer';
import bonusEngineDetailsReducer from '../pages/bonus-engine-details/reducers';
import kycDetailsReducer from '../pages/kyc-details/reducers';
import {legalHistoryReducer} from '../pages/legal-docs/history/reducers';
import {privacyPolicyReducers, termsReducers, userAcceptanceReducers} from '../pages/legal-docs/reducers';
import marketingBonusCodeReducer from '../pages/marketing-bonus-code/reducers';
import registrationInfoReducer from '../pages/registration-info/reducer';
import rolesReducer from '../pages/role-management/reducer';
import transactionHistoryReducer from '../pages/transaction-history/reducer';

export function createRootReducer(history: History) {
    return combineReducers({
        router: new RouterReducerCreator().createReducer(history),
        modules: modulesReducer,
        users: userAccessReducer,
        roles: rolesReducer,
        registrationInfo: registrationInfoReducer,
        transactionHistory: transactionHistoryReducer,
        privacyPolicy: privacyPolicyReducers,
        termsAndConditions: termsReducers,
        userAcceptance: userAcceptanceReducers,
        actionHistory: actionHistoryReducer,
        messageSnackBar: messageSnackBarReducer,
        localization: localizationReducer,
        tempStorage: tempStorageReducers,
        jurisdictionConfig: appConfigReducer,
        breadcrumb: breadcrumbReducer,
        legalHistory: legalHistoryReducer,
        userRegionRelatedInfo: userRegionRelatedInfoReducer,
        kycDetails: kycDetailsReducer,
        transactions: transactionReducer,
        userProfile: userProfileReducer,
        kycEntity: kycEntityReducer,
        realtimeNotification: globalRealtimeNotificationReducer,
        legalDocs: legalDocsReducer,
        referrerSummary: referrerSummaryReducer,
        marketingCode: marketingCodeReducer,
        marketingBonusCode: marketingBonusCodeReducer,
        asyncActions: asyncActionsReducer,
        bulkActions: bulkActionsReducer,
        playerMessage: playerMessageReducer,
        userNotificationHistory: userNotificationHistoryReducer,
        beBonus: beBonusReducer,
        beBonusHistory: beBonusHistoryReducer,
        bonusEngineDetails: bonusEngineDetailsReducer,
        entities: new EntityReducerCreator().createReducer(),
        views: viewsReducer,
        realtime: new RealtimeReducerCreator().createReducer(),
        notifications: new NotificationReducerCreator().createReducer(),
    });
}

const reducer = createRootReducer(createBrowserHistory());
export type RootReducer = typeof reducer;
