import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {AppBar, Box} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {makeStyles} from 'tss-react/mui';
import {withStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const useStyles = makeStyles()((theme: CustomTheme) => ({
    tabs: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },

    tabItem: {
        padding: theme.spacing(2.5),
        marginLeft: 0,
    },

    tabsAppBar: {
        position: 'static',
        height: '100%',
        display: 'flex',
    },

    tabsPanel: {
        height: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },

    tabsPanelContent: {
        height: '100%',
    },
}));

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    const {classes} = useStyles();

    return (
        <div
            className={classes.tabsPanel}
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tablabelledby-${index}`}
            {...other}
        >
            {value === index && <Box className={classes.tabsPanelContent}>{children}</Box>}
        </div>
    );
}

const TabRoot = withStyles(Tabs, (theme: CustomTheme) => ({
    indicator: {
        backgroundColor: theme.palette.primary.light,
    },
}));

interface TabsProps {
    tabs: Array<ITabArea>;
}

interface ITabArea {
    label: string | MessageDescriptor;
    children: JSX.Element;
}

const CustomTabs = ({tabs}: TabsProps) => {
    const {classes} = useStyles();
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <div className={classes.tabs}>
            <AppBar className={classes.tabsAppBar} color="default" elevation={0}>
                <TabRoot value={selectedTab} onChange={handleChange} aria-label="Custom Tabs">
                    {tabs.map((tab, index) => (
                        <Tab
                            disableFocusRipple
                            disableRipple
                            key={`tab-${index}`}
                            value={index}
                            label={tab.label ? <LocalizedText label={tab.label} /> : null}
                            className={classes.tabItem}
                        />
                    ))}
                </TabRoot>
                {tabs.map(
                    (tab, index) =>
                        tab.children && (
                            <TabPanel key={`tabPanel-${index}`} value={selectedTab} index={index}>
                                {tab.children}
                            </TabPanel>
                        )
                )}
            </AppBar>
        </div>
    );
};

export default CustomTabs;
