import React from 'react';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import {useActive, useCommands} from '@remirror/react';

import {RadioInputColor, StyledRadioInputColorGroup} from '@components/input';
import {SystemColorsEnum} from '@style';

import {EditorDropdownButton} from './EditorDropdownButton';

const colors: SystemColorsEnum[] = [
    SystemColorsEnum.AliceBlue,
    SystemColorsEnum.Beige,
    SystemColorsEnum.Bronze,
    SystemColorsEnum.Burgundy,
    SystemColorsEnum.Coffee,
    SystemColorsEnum.DarkGrey,
    SystemColorsEnum.Green,
    SystemColorsEnum.DarkPink,
    SystemColorsEnum.DarkTeal,
    SystemColorsEnum.DarkViolet,
    SystemColorsEnum.Emerald,
    SystemColorsEnum.LightBlue,
    SystemColorsEnum.LightGreen,
    SystemColorsEnum.LightGrey,
    SystemColorsEnum.Mint,
    SystemColorsEnum.NavyBlue,
    SystemColorsEnum.Orange,
    SystemColorsEnum.Pink,
    SystemColorsEnum.Red,
    SystemColorsEnum.SkyBlue,
    SystemColorsEnum.Violet,
    SystemColorsEnum.WarmYellow,
    SystemColorsEnum.White,
    SystemColorsEnum.YellowNeon,
];

export function TextColorButton() {
    const {setTextColor} = useCommands();
    const active = useActive();
    function handleChange(_e: React.ChangeEvent<HTMLInputElement>, value: string) {
        setTextColor(value);
    }
    const defaultColor = colors.find(c => active.textColor({color: c}));

    return (
        <EditorDropdownButton buttonContent={<FormatColorTextIcon />}>
            <StyledRadioInputColorGroup defaultValue={defaultColor} name="textColor" onChange={handleChange}>
                {colors.map(color => (
                    <RadioInputColor key={color} color={color} />
                ))}
            </StyledRadioInputColorGroup>
        </EditorDropdownButton>
    );
}
