import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {EmbeddedDashboardType} from '@models/generated/graphql';
import {EntityType} from '@redux/entity';
import {EmbeddedReportFilterKeys} from '@redux/entity/types/entityEmbeddedReport';
import {getNonEmptyValueValidator, viewActions, ViewType} from '@redux/view';

import {useEmbeddedReports} from '../hooks';

import {EmbeddedIFrames, useEmbeddedIFrames} from './EmbeddedIFramesProvider';

type EmbeddedReportProps = {
    module: EmbeddedIFrames;
};

const viewTypeMapper: Record<EmbeddedIFrames, {viewType: ViewType; type: EmbeddedDashboardType}> = {
    PlayerLevelReport: {viewType: 'PlayerLevelEmbeddedReport', type: EmbeddedDashboardType.GlobalPlayerLevelReport},
    AgentLevelReport: {viewType: 'AgentLevelEmbeddedReport', type: EmbeddedDashboardType.GlobalAgentLevelReport},
    TransferReport: {viewType: 'TransferEmbeddedReport', type: EmbeddedDashboardType.GlobalTransferReport},
};

export function EmbeddedReport({module}: EmbeddedReportProps) {
    const dispatch = useDispatch();
    const {viewType, type} = viewTypeMapper[module];
    const {setFrameSrc, setActiveFrame} = useEmbeddedIFrames();

    const {item} = useEmbeddedReports({
        viewType,
        fields: ['url', 'expire_at.seconds'],
        defaultFilters: [{key: 'invalid', value: true}],
        validateFilter: getNonEmptyValueValidator<EmbeddedReportFilterKeys>('type'),
    });

    const typeFilterKey: EmbeddedReportFilterKeys = 'type';
    useEffect(() => {
        setActiveFrame(module);
        if (!item?.url) {
            dispatch(viewActions.updateFilter({view: viewType, filter: `${typeFilterKey}=${type}`, entity: EntityType.EmbeddedReport}));
        }
        return () => {
            setActiveFrame(null);
            dispatch(viewActions.saveFilter({view: viewType, filter: 'invalid=true', entity: EntityType.EmbeddedReport}));
        };
    }, []);

    useEffect(() => {
        if (item?.url) {
            setFrameSrc(module, item?.url);
        }
    }, [item?.url]);

    return <></>;
}
