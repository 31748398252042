import React, {useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {Controller, DeepPartial, Path, UnpackNestedValue, useForm} from 'react-hook-form';
import {useMediaQuery} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {MultiStepFilterDrawer} from '@components/filter/MultiStepFilterDrawer';
import {FilterProps, FilterUpdateMode, FilterWithPlacement} from '@components/filter/types';

import {FilterGroupButtons} from './FilterGroupButtons';
import {FiltersContainer, FiltersContainerProps} from './FiltersContainer';

const useStyles = makeStyles()(theme => {
    return {
        filterGroupRootContainer: {
            width: '100%',
        },
        filterGroupContainerFilter: {
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
            },
        },
    };
});

export type FilterGroupNewFilter<TModel, TFilterName extends string> = {
    modelField: Path<TModel>;
    component: React.ComponentType<FilterProps<unknown>>;
    componentMobile?: React.ComponentType<FilterProps<unknown>>;
    collapseOnMobile?: boolean;
    filterName: TFilterName;
};

export type FilterGroupNewProps<TModel, TFilterName extends string> = Omit<FiltersContainerProps, 'withSubmit'> & {
    model: TModel;
    onChange: (model: TModel) => void;
    mode: FilterUpdateMode;
    allFilters: FilterGroupNewFilter<TModel, TFilterName>[];
    availableFilters: TFilterName[] | FilterWithPlacement<TFilterName>[];
    submitButton?: React.ReactNode;
};

export function FilterGroupNew<TModel extends object, TFilterName extends string>({
    availableFilters,
    allFilters,
    model,
    mode,
    onChange,
    submitButton,
    groupContainerFullWidth = true,
    viewMode = 'flex',
}: FilterGroupNewProps<TModel, TFilterName>) {
    const {classes, cx, theme} = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const maxVisibleItemsCountDesktop = 12;

    const [expandedDesktop, setExpandedDesktop] = useState(availableFilters?.length < maxVisibleItemsCountDesktop);
    const [expandedMobile, setExpandedMobile] = useState(false);
    const visibleFilters = getVisibleFilters();
    const collapsedFiltersMobile = getCollapsedFiltersMobile();
    const hasCollapsedFilters = collapsedFiltersMobile.length > 0;
    const hasMoreAvailableFiltersDesktop = !isMobile && availableFilters.length > maxVisibleItemsCountDesktop;
    const form = useForm<TModel>({defaultValues: model as UnpackNestedValue<DeepPartial<TModel>>});
    const formElement = useRef<HTMLFormElement>(null);
    const visibleFilterNames = visibleFilters.map(getFilterName);
    const visibleFilterComponents = useMemo(() => visibleFilters.map(getFilterComponent), [visibleFilterNames.join()]);
    const collapsedFiltersMobileComponents = useMemo(
        () => collapsedFiltersMobile?.map(getFilterComponent),
        [collapsedFiltersMobile?.join()]
    );

    useEffect(() => {
        //NOTE: This is a workaround for the case when input value changes by clearing it with the "x" button
        //      In this case the React synthetic event is not triggered.
        //      As a workaround we trigger the change event for the form manually when the input value changes.
        function handleFormChange() {
            if (mode === 'update-on-change') {
                setTimeout(() => {
                    const values = form.getValues();
                    handleSubmit(values);
                }, 0);
            }
        }

        formElement.current?.addEventListener('change', handleFormChange);

        return () => {
            formElement.current?.removeEventListener('change', handleFormChange);
        };
    }, []);

    useEffect(() => {
        // Reset form if auto-rendered and model prop changed without user participation
        // For example: new data was uploaded from server.
        if (isModelUpdated() && !form.formState.isDirty) {
            form.reset(model as UnpackNestedValue<DeepPartial<TModel>>, {keepDefaultValues: true});
        }
    }, [isModelUpdated(), form.formState.isDirty]);

    function isRenderedInPortal(
        filters: TFilterName[] | FilterWithPlacement<TFilterName>[]
    ): filters is FilterWithPlacement<TFilterName>[] {
        return typeof filters[0] !== 'string';
    }

    function getFilterName(filter: TFilterName | FilterWithPlacement<TFilterName>): TFilterName {
        return typeof filter === 'string' ? filter : filter.filterName;
    }

    function handleExpandDesktop() {
        setExpandedDesktop(true);
    }

    function handleCollapseDesktop() {
        setExpandedDesktop(false);
    }

    function handleExpandMobile() {
        setExpandedMobile(true);
    }

    function handleCollapseMobile() {
        setExpandedMobile(false);
    }

    function handleSubmit(model: UnpackNestedValue<TModel>) {
        if (onChange) {
            onChange(model as TModel);
        }
    }

    function getVisibleFilters(): TFilterName[] | FilterWithPlacement<TFilterName>[] {
        return isMobile ? getVisibleFiltersMobile() : getVisibleFiltersDesktop(expandedDesktop);
    }

    function getVisibleFiltersMobile(): TFilterName[] | FilterWithPlacement<TFilterName>[] {
        let res = [];
        if (isRenderedInPortal(availableFilters)) {
            res = availableFilters.filter(f => !allFilters.find(af => af.filterName === f.filterName)?.collapseOnMobile);
        } else {
            res = availableFilters.filter(f => !allFilters.find(af => af.filterName === f)?.collapseOnMobile);
        }
        return res;
    }

    function getCollapsedFiltersMobile(): TFilterName[] {
        return isMobile
            ? availableFilters.map(getFilterName).filter(f => allFilters.find(af => af.filterName === f)?.collapseOnMobile)
            : [];
    }

    function getVisibleFiltersDesktop(expanded: boolean): TFilterName[] | FilterWithPlacement<TFilterName>[] {
        const res = expanded ? availableFilters : availableFilters.slice(0, maxVisibleItemsCountDesktop);
        return res;
    }

    function getFilterComponent(filter: TFilterName | FilterWithPlacement<TFilterName>) {
        const filterName = getFilterName(filter);
        const {component, modelField, componentMobile} = allFilters.find(f => f.filterName === filterName);
        const FilterComponent = isMobile && componentMobile ? componentMobile : component;
        return (
            <div className={cx(classes.filterGroupContainerFilter)} key={filterName}>
                <Controller
                    render={({field}) => (
                        <FilterComponent
                            key={field.name}
                            value={field.value}
                            onChange={e => {
                                formElement.current?.dispatchEvent(new Event('change'));
                                return field.onChange(e);
                            }}
                            mode={mode}
                        />
                    )}
                    name={modelField}
                    control={form.control}
                />
            </div>
        );
    }

    function isModelUpdated() {
        const values = form.getValues();
        return JSON.stringify(model) !== JSON.stringify(values);
    }

    const buttons = (
        <FilterGroupButtons
            hasExpandButton={hasMoreAvailableFiltersDesktop}
            hasExpandButtonMobile={hasCollapsedFilters}
            hasSubmitButton={mode === 'update-on-submit'}
            isExpanded={expandedDesktop}
            isExpandedMobile={expandedMobile}
            submitButton={submitButton}
            onCollapse={handleCollapseDesktop}
            onExpand={handleExpandDesktop}
            onCollapseMobile={handleCollapseMobile}
            onExpandMobile={handleExpandMobile}
        />
    );

    const isPortals = isRenderedInPortal(visibleFilters);
    const isRefsLoaded = isPortals ? visibleFilters.every(f => f.node) : true;

    const portals = useMemo(() => {
        const mapper: Record<string, React.ReactNode[]> = {};
        if (isPortals && isRefsLoaded) {
            visibleFilters.forEach((f, i) => {
                const key = f.nodeId;
                mapper[key] = mapper[key] ? [...mapper[key], visibleFilterComponents[i]] : [visibleFilterComponents[i]];
                if (i === visibleFilters.length - 1) {
                    mapper[key].push(buttons);
                }
            });
        }
        return mapper;
    }, [visibleFilterNames.join(','), isRefsLoaded]);

    return (
        <form ref={formElement} className={cx(!isPortals && classes.filterGroupRootContainer)} onSubmit={form.handleSubmit(handleSubmit)}>
            {isPortals ? (
                Object.keys(portals).map(key =>
                    createPortal(
                        <FiltersContainer
                            viewMode={viewMode}
                            withSubmit={mode === 'update-on-submit'}
                            groupContainerFullWidth={groupContainerFullWidth}
                        >
                            {portals[key]}
                        </FiltersContainer>,
                        visibleFilters.find(vf => vf.nodeId === key).node
                    )
                )
            ) : (
                <FiltersContainer
                    viewMode={viewMode}
                    withSubmit={mode === 'update-on-submit'}
                    groupContainerFullWidth={groupContainerFullWidth}
                >
                    {visibleFilterComponents}
                    {buttons}
                </FiltersContainer>
            )}
            {expandedMobile ? (
                <MultiStepFilterDrawer isOpen={expandedMobile} onClose={handleCollapseMobile} onOpen={handleExpandMobile}>
                    {collapsedFiltersMobileComponents}
                </MultiStepFilterDrawer>
            ) : null}
        </form>
    );
}
