import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {
    CasinoGameExternalResponse,
    CasinoGameFields,
    CasinoGameInternalResponse,
    CasinoSupplierFields,
    CasinoSupplierResponse,
} from '@models/casino-game';
import {map} from '@otel';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {RestService} from '@services/rest-api/restService';
import {ServerResponseStatus, ServiceResponsePayload} from '@services/types';

export type GetSuppliersPayload = {
    code?: string;
    start?: number;
    limit?: number;
};

export type CasinoArrayFilterMode = 'included' | 'excluded';

export class CasinoArrayFilterWithMode {
    items: string[];
    mode: CasinoArrayFilterMode;
}

export type GetGamesPayload = {
    aggregators?: CasinoArrayFilterWithMode;
    suppliers?: CasinoArrayFilterWithMode;
    title?: string;
    start?: number;
    limit?: number;
};

export interface ICasinoCmsApiService {
    getSuppliers(requestPayload: GetSuppliersPayload): Observable<ServiceResponsePayload<GetSuppliersPayload, CasinoSupplierResponse>>;

    getExternalGames(requestPayload: GetGamesPayload): Observable<ServiceResponsePayload<GetGamesPayload, CasinoGameExternalResponse>>;

    getInternalGames(requestPayload: GetGamesPayload): Observable<ServiceResponsePayload<GetGamesPayload, CasinoGameInternalResponse>>;
}

@injectable()
export class CasinoCmsApiService implements ICasinoCmsApiService {
    private readonly _apiRouteService: ApiRoutesService;
    private readonly _restService: RestService;
    private readonly _urlQueryBuilder: CasinoCmsUrlBuilder;

    constructor(
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService,
        @inject(ServiceTypes.RestService) restService: RestService
    ) {
        this._restService = restService;
        this._apiRouteService = apiRouteService;
        this._urlQueryBuilder = new CasinoCmsUrlBuilder();
    }

    public getSuppliers(
        requestPayload: GetSuppliersPayload
    ): Observable<ServiceResponsePayload<GetSuppliersPayload, CasinoSupplierResponse>> {
        const {code, start, limit} = requestPayload;
        const suppliersEndpoint = this._apiRouteService.getCmsSuppliersEndpoint();
        this._urlQueryBuilder.reset();
        this._urlQueryBuilder.buildFields<CasinoSupplierFields>(['code', 'name']);
        this._urlQueryBuilder.buildPagination(start, limit);
        this._urlQueryBuilder.buildStringFilter('[name]', code);
        const query = this._urlQueryBuilder.getQuery();

        return this._restService.get({endpoint: suppliersEndpoint, query, withCredentials: false}, null, false).pipe(
            map(response => {
                return {
                    ...response,
                    requestPayload,
                    responsePayload: response.responsePayload.response,
                };
            })
        );
    }

    public getExternalGames(
        requestPayload: GetGamesPayload
    ): Observable<ServiceResponsePayload<GetGamesPayload, CasinoGameExternalResponse>> {
        const {title, suppliers, aggregators, start, limit} = requestPayload;
        const gamesEndpoint = this._apiRouteService.getCmsGamesEndpoint();
        this._urlQueryBuilder.reset();
        this._urlQueryBuilder.buildFields<CasinoGameFields>(['title', 'code', 'aggregator']);
        this._urlQueryBuilder.buildPagination(start, limit);
        this._urlQueryBuilder.buildPopulating<CasinoGameFields>(['supplier']);
        this._urlQueryBuilder.buildArrayFilter('[aggregator]', aggregators?.items, aggregators?.mode);
        this._urlQueryBuilder.buildArrayFilter('[supplier][code]', suppliers?.items, suppliers?.mode);
        this._urlQueryBuilder.buildStringFilter('[title]', title);
        const query = this._urlQueryBuilder.getQuery();

        return this._restService.get({endpoint: gamesEndpoint, query, withCredentials: false}, null, false).pipe(
            map(response => {
                return {
                    ...response,
                    requestPayload,
                    responsePayload: response.responsePayload.response,
                };
            })
        );
    }

    public getInternalGames(
        requestPayload: GetGamesPayload
    ): Observable<ServiceResponsePayload<GetGamesPayload, CasinoGameInternalResponse>> {
        const internalGames = [
            {
                code: 'POKERFLIP',
                title: 'Poker Flip',
            },
            {
                code: 'POKERMASTER',
                title: 'Poker Master',
            },
        ];
        const filteredGames = requestPayload?.title
            ? internalGames?.filter(g => g.title?.toUpperCase()?.startsWith(requestPayload?.title?.toUpperCase()))
            : internalGames;
        const gamesWithPaginationApplied = filteredGames.slice(requestPayload?.start, requestPayload?.start + requestPayload?.limit);
        const shouldIncludeInternalGames = !requestPayload?.aggregators?.items?.length && !requestPayload?.suppliers?.items?.length;

        return of({
            status: ServerResponseStatus.Success,
            requestPayload: null,
            responsePayload: shouldIncludeInternalGames
                ? {
                      items: gamesWithPaginationApplied ?? [],
                      total: filteredGames?.length ?? 0,
                  }
                : {
                      items: [],
                      total: 0,
                  },
        });
    }
}

class CasinoCmsUrlBuilder {
    private urlQuery: URLSearchParams;

    public getQuery(): string {
        return this.urlQuery.toString();
    }

    public reset() {
        this.urlQuery = new URLSearchParams();
    }

    buildFields<TField extends string>(fields: TField[]) {
        fields.forEach((field, index) => this.urlQuery.set(`fields[${index}]`, field));
    }

    buildPopulating<TField extends string>(fields: TField[]) {
        fields.forEach((field, index) => this.urlQuery.set(`populate[${index}]`, field));
    }

    buildPagination(start: number, size: number) {
        const defaultStart = 0;
        const defaultSize = 10;
        this.urlQuery.set('pagination[start]', (start ?? defaultStart).toString());
        this.urlQuery.set('pagination[limit]', (size ?? defaultSize).toString());
    }

    buildArrayFilter(key: string, values: string[], mode: CasinoArrayFilterMode) {
        if (values?.length > 0) {
            const operator = mode === 'included' ? '[$in]' : '[$notIn]';
            values.forEach((item, index) => this.urlQuery.set(`filters${key}${operator}[${index}]`, item));
        }
    }

    buildStringFilter(key: string, value: string) {
        if (value) {
            this.urlQuery.set(`filters${key}[$containsi]`, value);
        }
    }
}
