import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';

import {OutlinedButtonDropdown} from '@components/dropdown/Dropdown';
import {DropdownStyles} from '@components/dropdown/DropdownHoc';
import {CustomIcon} from '@components/icons/types';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';

const localized = defineMessages({
    blockPlayerLabelAssignButtonTitle: {
        id: 'blockPlayerLabelAssignButtonTitle',
        defaultMessage: 'Assign label',
    },
});

type BlockPlayerLabelAssignButtonProps = React.PropsWithChildren<{}> & {
    className?: string;
    hideIcon?: boolean;
    container?: Element | (() => Element);
    styles?: DropdownStyles;
};

function BlockPlayerLabelAssignButtonInternal({hideIcon, children, ...props}: BlockPlayerLabelAssignButtonProps) {
    return (
        <OutlinedButtonDropdown
            startIcon={!hideIcon ? <Box component="span" className={CustomIcon.AddCircleOutline} fontSize={8} /> : null}
            smallPlus={true}
            label={localized.blockPlayerLabelAssignButtonTitle}
            fitDropdownToContent={true}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            {...props}
        >
            {children}
        </OutlinedButtonDropdown>
    );
}

export const blockPlayerLabelAssignButtonPermissions = [
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesSecurity, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.Payment, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.KYC, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesCustomerSupport, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesPlayer, permissions: [PermissionEnum.Create]},
    {moduleName: ModuleName.Comments, submoduleName: SubmoduleName.NotesBotDetection, permissions: [PermissionEnum.Create]},
];

export const BlockPlayerLabelAssignButton = withMultiplePermission<BlockPlayerLabelAssignButtonProps>(
    BlockPlayerLabelAssignButtonInternal,
    {
        allowedPermissions: blockPlayerLabelAssignButtonPermissions,
    }
);
