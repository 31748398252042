import {defineMessages} from 'react-intl';

import {BlackListItemType} from '@models/generated/graphql';

export const localizedBlackListItemType = defineMessages({
    [BlackListItemType.Ip]: {
        id: 'blackListItemType_IP',
        defaultMessage: 'IP',
    },
    [BlackListItemType.Device]: {
        id: 'blackListItemType_Device',
        defaultMessage: 'Device',
    },
});
