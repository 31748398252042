import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {columnConfigurationActions} from './actions';

export const useColumnConfiguration = (domain: string, columns: string[]) => {
    const dispatch = useDispatch();

    const actions = columnConfigurationActions(domain);

    useEffect(() => {
        if (domain) {
            dispatch(actions.setColumns(columns));
        }
    }, []);
};
