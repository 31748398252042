import React from 'react';
import {StyledComponentProps} from '@mui/material/styles';

import {EditableAutocompleteProps, withEditableAutocomplete} from '@components/editable/EditableAutocomplete';
import {Filter} from '@redux/entity';
import {CountriesApiFilterKeys} from '@services/rest-api/countriesApiService';

import {CountryAutocompleteBase} from './CountryAutocompleteBase';

const NationalityAutocomplete = (props: EditableAutocompleteProps<Filter<CountriesApiFilterKeys>> & StyledComponentProps) => {
    return <CountryAutocompleteBase {...props} viewType="NationalityAutocomplete" />;
};

export const EditableNationalityAutocomplete = withEditableAutocomplete(NationalityAutocomplete);
