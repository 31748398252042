import {MessageDescriptor} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {renderChip, renderDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    labelCellValueGetter,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    renderDateCell,
    renderLabelsCell,
    renderLocalizedTextArray,
    renderTextLink,
    TextLinkCell,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {AccountVerificationViewModel, AccountVerificationViewModelKeys} from '@models/account-verification';
import {AccountVerificationStatus, AccountVerificationType, JumioDocumentType} from '@models/generated/graphql';

import {RouteUrl} from 'src/common/routeEnums';
import {localizedKYCReasonCodes} from '../../app/intl';

import {renderAccountVerificationDocumentsStatus} from './AccountVerificationDocumentsStatus';
import {AccountVerificationOpenDurationProps, renderAccountVerificationOpenDuration} from './AccountVerificationOpenDuration';
import {localizedHeaders} from './DataGridAccountVerification.localize';

type KYCType = 'KYC' | 'NDRP';

export class DataGridAccountVerificationConfigFactory extends DataGridEntityConfigFactory<
    AccountVerificationViewModelKeys,
    AccountVerificationViewModel
> {
    private kycType: KYCType;
    constructor(kycType: KYCType = 'KYC') {
        super();
        this.kycType = kycType;
    }
    getColumnConfig(): Partial<
        Record<AccountVerificationViewModelKeys, DataGridEntityColDef<AccountVerificationViewModelKeys, AccountVerificationViewModel>>
    > {
        const columnsMapping: Partial<
            Record<AccountVerificationViewModelKeys, DataGridEntityColDef<AccountVerificationViewModelKeys, AccountVerificationViewModel>>
        > = {
            id: {
                field: 'id',
                valueGetter: p =>
                    this.kycType === 'NDRP' ? this.getAccountVerificationIdLink(p).id : this.getAccountVerificationIdLink(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: this.kycType === 'NDRP' ? localizedHeaders.ndrpId : localizedHeaders.id,
                renderCell: this.kycType === 'NDRP' ? renderCellWithTooltip : renderTextLink,
                flex: 0.2,
                sortable: false,
            },
            id_ndrp: {
                field: 'id_ndrp',
                valueGetter: p => this.getNdrpDetailsLink(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.id,
                renderCell: renderTextLink,
                flex: 0.2,
                sortable: false,
            },
            uid: {
                field: 'uid',
                valueGetter: p => this.getUid(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.uid,
                renderCell: params => renderDefaultChip(params.value as string, ChipVariant.Grey, ChipType.Status),
                flex: 0.2,
            },
            'initiated_at.seconds': {
                field: 'initiated_at.seconds',
                valueGetter: p => this.getInitiatedAt(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.requestDate,
                renderCell: renderDateCell,
                flex: 0.2,
            },
            duration: {
                field: 'duration',
                valueGetter: p => this.getDuration(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.duration,
                renderCell: renderAccountVerificationOpenDuration,
                flex: 0.2,
                sortable: false,
            },
            email: {
                field: 'email',
                valueGetter: p => this.getEmail(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.email,
                renderCell: renderCellWithTooltip,
                flex: 0.2,
                sortable: false,
            },
            account_verification_status: {
                field: 'account_verification_status',
                valueGetter: p => this.getKycStatus(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: this.kycType === 'NDRP' ? localizedHeaders.ndrpStatus : localizedHeaders.kycStatus,
                renderCell: params =>
                    renderChip<AccountVerificationStatus>(params.value as AccountVerificationStatus, nameof<AccountVerificationStatus>()),
                flex: 0.15,
                sortable: false,
            },
            type: {
                field: 'type',
                valueGetter: p => this.getType(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.type,
                renderCell: params =>
                    renderChip<AccountVerificationType>(params.value as AccountVerificationType, nameof<AccountVerificationType>()),
                flex: 0.15,
                sortable: false,
            },
            documents: {
                field: 'documents',
                valueGetter: p => this.getJumioStatus(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.jumioStatus,
                renderCell: params => renderAccountVerificationDocumentsStatus(params, false),
                flex: 0.3,
                sortable: false,
            },
            'documents_object.id_card.uploaded_ts': {
                field: 'documents_object.id_card.uploaded_ts',
                valueGetter: p => this.getIdUploadedTs(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.idUpload,
                renderCell: renderDateCell,
                flex: 0.2,
            },
            documents_with_link: {
                field: 'documents_with_link',
                valueGetter: p => this.getJumioStatus(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.docsJumioStatus,
                renderCell: params => renderAccountVerificationDocumentsStatus(params, true),
                flex: 0.3,
                sortable: false,
            },
            username: {
                field: 'username',
                valueGetter: p => this.getUsername(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.username,
                flex: 0.2,
                sortable: false,
            },
            reason_codes: {
                field: 'reason_codes',
                valueGetter: p => this.getReasons(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.reasonCodes,
                renderCell: params => renderLocalizedTextArray(params.value as MessageDescriptor[]),
                flex: 0.2,
                sortable: false,
            },
            labels: {
                field: 'labels',
                valueGetter: p => this.getLabels(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.labels,
                renderCell: renderLabelsCell,
                flex: 0.25,
                sortable: false,
            },
            iso_alpha2_country_code: {
                field: 'iso_alpha2_country_code',
                valueGetter: p => this.getPlayerCountry(p),
                renderHeader: renderCellHeader,
                localizedHeaderName: localizedHeaders.playerCountry,
                renderCell: renderCellWithTooltip,
                flex: 0.1,
                sortable: false,
            },
        };

        return columnsMapping;
    }

    private getUid(p: GridValueGetterParams) {
        return this.getRow(p)?.uid;
    }

    private getUsername(p: GridValueGetterParams) {
        return this.getRow(p)?.username;
    }

    private getAccountVerificationIdLink(p: GridValueGetterParams) {
        return {id: this.getRow(p)?.id, path: RouteUrl.KYCDetails} as TextLinkCell;
    }

    private getNdrpDetailsLink(p: GridValueGetterParams) {
        return {id: this.getRow(p)?.id, path: RouteUrl.NDRPDetails} as TextLinkCell;
    }

    private getInitiatedAt(p: GridValueGetterParams) {
        return this.getRow(p)?.initiated_at;
    }

    private getIdUploadedTs(p: GridValueGetterParams) {
        return this.getRow(p)?.documents?.find(i => i.doc_type === JumioDocumentType.IdCard && i.uploaded_ts?.seconds)?.uploaded_ts;
    }

    private getDuration(p: GridValueGetterParams): AccountVerificationOpenDurationProps {
        return {history: this.getRow(p)?.status_log, initiatedAt: this.getRow(p)?.initiated_at};
    }

    private getEmail(p: GridValueGetterParams) {
        return this.getRow(p)?.email;
    }

    private getType(p: GridValueGetterParams) {
        return this.getRow(p)?.type;
    }

    private getJumioStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.documents ?? [];
    }

    private getKycStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.account_verification_status;
    }

    private getReasons(p: GridValueGetterParams): MessageDescriptor[] {
        return this.getRow(p)?.reason_codes?.map(r => localizedKYCReasonCodes[r]);
    }

    private getLabels(p: GridValueGetterParams) {
        return labelCellValueGetter(this.getRow(p)?.labels);
    }

    private getPlayerCountry(p: GridValueGetterParams) {
        return this.getRow(p)?.iso_alpha2_country_code;
    }
}

export const DataGridAccountVerificationsClient = withDataGridEntityClient<AccountVerificationViewModelKeys, AccountVerificationViewModel>(
    MuiDataGridClient,
    () => new DataGridAccountVerificationConfigFactory().getColumnConfig()
);

export const DataGridAccountVerificationsServer = withDataGridEntityServer<AccountVerificationViewModelKeys, AccountVerificationViewModel>(
    MuiDataGridServer,
    () => new DataGridAccountVerificationConfigFactory().getColumnConfig()
);
