import {GameCurrency} from '@models/game-template';

import {baseFilterKeys} from './base';
import {ManagedGameType} from './entityGame';

export class GameTemplate {
    id: number;
    name: string;
    is_enabled: boolean;
    auto_buyin_val: number;
    auto_buyout_val: number;
    auto_start_num: number;
    auto_withdraw: boolean;
    player_count_max: number;
    rule_ante_amount: number;
    labels: number[];
    small_blind: number;
    big_blind: number;
    straddle: number;
    players: number[];
    tables: number[];
    updated_at: number;
    gameType: ManagedGameType;
    currencyType: GameCurrency;
    force_showcard: boolean;
    is_ban_vpn: boolean;
    openRoomLimit: boolean;
    limitnums: [
        {
            CurNum: number;
            max: number;
            min: number;
            num: number;
        }
    ];
    rule_blind_enum: number;
    rule_buyin_fold: number;
    rule_buyin_min_enum: number;
    rule_switch_anti_cheat: number;
    rule_switch_force_straddle: number;
}

export const gameTemplateFilterKeys = [...baseFilterKeys, 'gameType', 'id'] as const;
export type GameTemplateFilterKeys = (typeof gameTemplateFilterKeys)[number];

export type GameTemplateFields = keyof GameTemplate;
