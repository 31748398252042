import {defineMessages} from 'react-intl';

import {GeoCheckEventSource} from '@models/generated/graphql';

export const localizedGeoCheckEventSource = defineMessages({
    [GeoCheckEventSource.Login]: {
        id: 'geoCheckEventSourceMockLogin',
        defaultMessage: 'Login',
    },
    [GeoCheckEventSource.Geocomply]: {
        id: 'geoCheckEventSourceMockGeocomply',
        defaultMessage: 'Geocomply',
    },
});
