import React, {PropsWithChildren} from 'react';

import {useService} from '@inversify';

import {IMapper} from './types';

type AutoMapperContextProps = {
    mapper: IMapper;
};

export const AutoMapperContext = React.createContext<AutoMapperContextProps>({mapper: null});

type AutoMapperProviderProps = PropsWithChildren<{}>;

export function AutoMapperProvider({children}: AutoMapperProviderProps) {
    const mapper = useService<IMapper>('AutoMapper');
    return <AutoMapperContext.Provider value={{mapper}}>{children}</AutoMapperContext.Provider>;
}
