import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';

type MessageWithIconProps = {
    label: string | MessageDescriptor;
    icon: string;
    iconSize?: number;
    className?: string;
};

export const MessageWithIcon = ({icon, label, iconSize = 16, className}: MessageWithIconProps) => {
    return (
        <Box className={className} display="flex">
            <Box marginRight={1} component="span" className={icon} fontSize={iconSize} />
            <LocalizedText label={label} />
        </Box>
    );
};
