import React from 'react';

import {securityWorkspaceUpdatePermissions} from '@models/permissions/permissions';

import {withMultiplePermission} from 'src/features/app/permission/PermissionHoc';
import {BulkManageButton} from '../../block-label-group-bulk-actions';

type SecurityWorkspaceManageLabelButtonInnerProps = {
    selectedIds: string[];
};

function SecurityWorkspaceManageLabelButtonInner({selectedIds}: SecurityWorkspaceManageLabelButtonInnerProps) {
    return <BulkManageButton uids={selectedIds} />;
}

export const SecurityWorkspaceManageLabelButton = withMultiplePermission(SecurityWorkspaceManageLabelButtonInner, {
    allowedPermissions: securityWorkspaceUpdatePermissions,
});
