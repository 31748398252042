import {createMap, forMember, fromValue, mapFrom, Mapper, mapWith, preCondition} from '@automapper/core';
import moment from 'moment';

import {IMapping} from '@auto-mapper';
import {
    EntityInput,
    EntityType,
    ManualTransactionInput,
    NoteInput,
    NoteType,
    ReasonInput,
    TimestampInput,
    Workspace,
} from '@models/generated/graphql';
import {momentToTimestampSeconds} from '@utils';

import {ManualTransactionAddViewModel} from './types';

export class TransactionActionsMapping implements IMapping {
    createMapping(mapper: Mapper) {
        createMap(
            mapper,
            ManualTransactionAddViewModel,
            TimestampInput,
            forMember(
                vm => vm.seconds,
                mapFrom(() => momentToTimestampSeconds(moment()))
            )
        );
        createMap(
            mapper,
            ManualTransactionAddViewModel,
            EntityInput,
            forMember(vm => vm.type, fromValue(EntityType.Transaction)),
            forMember(
                vm => vm.parent,
                mapFrom(m => ({id: m.uid, type: EntityType.Player} as EntityInput))
            )
        );
        createMap(
            mapper,
            ManualTransactionAddViewModel,
            NoteInput,
            forMember(
                vm => vm.body,
                mapFrom(m => m.comment)
            ),
            forMember(
                vm => vm.entity,
                mapWith(EntityInput, ManualTransactionAddViewModel, m => m)
            ),
            forMember(vm => vm.note_type, fromValue(NoteType.Request)),
            forMember(vm => vm.workspace, fromValue(Workspace.Global)),
            forMember(
                vm => vm.posted_at,
                mapWith(TimestampInput, ManualTransactionAddViewModel, m => m)
            ),
            forMember(
                vm => vm.posted_by_uid,
                mapFrom(m => m.created_by_uid)
            )
        );
        createMap(
            mapper,
            ManualTransactionAddViewModel,
            ManualTransactionInput,
            forMember(
                m => m.uid,
                mapFrom(vm => vm.uid)
            ),
            forMember(
                m => m.amount,
                mapFrom(vm => Number(vm.amount))
            ),
            forMember(
                m => m.payment_method_description,
                mapFrom(vm => vm.reference)
            ),
            forMember(
                m => m.created_by_uid,
                mapFrom(vm => vm.created_by_uid)
            ),
            forMember(
                m => m.type,
                mapFrom(vm => vm.transaction_type)
            ),
            forMember(
                m => m.reasons,
                mapFrom(vm => (vm?.reason ? [{reason_code: vm.reason} as ReasonInput] : null))
            ),
            forMember(
                m => m.note,
                preCondition(vm => vm.comment !== null && vm.comment !== undefined),
                mapWith(NoteInput, ManualTransactionAddViewModel, vm => vm)
            )
        );
    }
}
