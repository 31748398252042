import {defineMessages} from 'react-intl';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';

import {fileActions} from '@file/actions';
import {CsvDocumentBuilderAdapter} from '@file/services/CsvDocumentBuilderAdapter';
import {ServiceTypes} from '@inversify';
import {AgentRevenueShareWeeklyReport} from '@models/generated/graphql';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {AgentLevelReportQueryFields, EntityFetchRequestPayload, EntityType} from '@redux/entity';
import {AgentLevelReportService} from '@services/agentLevelReportService';
import {maxPageSize, ServerResponseStatus} from '@services/types';

import {showErrorAction} from '../message-snack-bar/actions';
import {Message} from '../message-snack-bar/types';

import {agentLevelReportActions} from './actions';
import {AgentLevelReportDownloadViewModel, AgentLevelReportViewModelKeys} from './types';

export const localized = defineMessages({
    downloadFailure: {
        id: 'AgentLevelReportEpicsBuilder_downloadFailure',
        defaultMessage: 'Failed to download Agent Report',
    },
    downloadMaxPageSizeErrorMessage: {
        id: 'AgentLevelReportEpicsBuilder_downloadMaxPageSizeErrorMessage',
        defaultMessage: 'Total count of downloaded items cannot be more than {maxPageSize}',
    },
});

@injectable()
export class AgentLevelReportEpicsBuilder extends BaseEpicsBuilder {
    private _mapper: Mapper;
    private _service: AgentLevelReportService;
    private _fileAdapter: CsvDocumentBuilderAdapter<AgentLevelReportViewModelKeys>;

    constructor(
        @inject(ServiceTypes.AgentLevelReportService) service: AgentLevelReportService,
        @inject(ServiceTypes.CsvFileAdapter) fileAdapter: CsvDocumentBuilderAdapter<AgentLevelReportViewModelKeys>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super();
        this._service = service;
        this._fileAdapter = fileAdapter;
        this._mapper = mapper;
    }

    protected buildEpicList(): Epic[] {
        return [this.buildDownloadEpic(), this.buildDownloadSuccessEpic(), this.buildDownloadFailureEpic()];
    }

    private buildDownloadEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(agentLevelReportActions.download.request)),
                mergeMap(action => {
                    const {filter, fields, filename, worksheetKeys, headers} = action.payload;
                    const fetchPayload: EntityFetchRequestPayload<AgentLevelReportQueryFields> = {
                        type: EntityType.AgentLevelReport,
                        filter,
                        fields,
                    };
                    const failureObservable = of(agentLevelReportActions.download.failure({message: localized.downloadFailure}));

                    return this._service.get(fetchPayload).pipe(
                        mergeMap(response => {
                            let result: Observable<unknown>;

                            if (response?.status === ServerResponseStatus.Success) {
                                if (response?.responsePayload?.total <= maxPageSize) {
                                    const worksheetData = response.responsePayload?.items?.map(i =>
                                        this._mapper.map(i, AgentRevenueShareWeeklyReport, AgentLevelReportDownloadViewModel)
                                    );
                                    result = this._fileAdapter.createFile(worksheetData, worksheetKeys, headers).pipe(
                                        map(arrayBuffer => agentLevelReportActions.download.success({arrayBuffer, filename})),
                                        catchError(() => failureObservable)
                                    );
                                } else {
                                    result = of(
                                        agentLevelReportActions.download.failure({
                                            message: localized.downloadMaxPageSizeErrorMessage,
                                            values: {maxPageSize: maxPageSize.toString()},
                                        })
                                    );
                                }
                            } else {
                                result = failureObservable;
                            }

                            return result;
                        }),
                        catchError(() => failureObservable)
                    );
                })
            );
    }

    private buildDownloadSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(agentLevelReportActions.download.success)),
                map(action => {
                    const {filename, arrayBuffer} = action.payload;
                    return fileActions.download({
                        file: new Blob([arrayBuffer]),
                        title: filename,
                        type: 'csv',
                    });
                })
            );
    }

    private buildDownloadFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(agentLevelReportActions.download.failure)),
                map((action: PayloadAction<string, Message>) => showErrorAction(action.payload))
            );
    }
}
