import React, {useEffect} from 'react';
import {MessageDescriptor} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {PayloadActionCreator, RootState} from 'typesafe-actions';

import KeyValueTable from '@components/KeyValueTable';
import {getSelectedItemString} from '@utils';

import {ConfigurationKeyValuePair, emptyField} from 'src/common/types';
import {useColumnConfiguration} from '../../module-shared/hooks';
import {filterActions} from '../../shared/filter/actions';
import {WorkspaceDetailsComponentProps} from '../types';

const emptyRow: ConfigurationKeyValuePair = {
    field: emptyField,
    key: '',
    value: <></>,
    configuration: [],
};

type WorkspaceKeyValueTableProps<TData> = WorkspaceDetailsComponentProps & {
    domain: string;
    header: MessageDescriptor;
    getTableRows: (user: TData) => ConfigurationKeyValuePair[];
    dataSelector: (state: RootState) => TData;
    filterSelector?: (state: RootState) => string;
    columnsCount?: number;
    requestAction?: PayloadActionCreator<string, string>; //when need multiple actions on initialization
    styles?: {
        tableRow?: string;
        table?: string;
    };
};

/**
 * @deprecated
 * <p>Should be removed. Use KeyValueTable instead</p>
 */
export const WorkspaceKeyValueTable = <TData extends object>({
    itemId,
    domain,
    header,
    fields,
    dataSelector,
    filterSelector,
    hideTitle,
    isUnderlined,
    className,
    columnsCount,
    getTableRows,
    requestAction,
    styles,
}: WorkspaceKeyValueTableProps<TData>) => {
    const dispatch = useDispatch();

    const data: TData = useSelector(dataSelector) as TData;

    const allRows = getTableRows(data);
    const rows = fields.reduce((result, field) => {
        const row = allRows.find(r => r.field === field);
        return row ? [...result, {...row, isUnderlined: isUnderlined}] : emptyField === field ? [...result, emptyRow] : result;
    }, [] as ConfigurationKeyValuePair[]);
    const rowsConfiguration = rows.reduce(
        (result, row) => (row.configuration ? [...result, ...row.configuration] : result),
        [] as string[]
    );
    useColumnConfiguration(domain, rowsConfiguration);

    const initialFilter: string = filterSelector ? useSelector(filterSelector) : '';
    useEffect(() => {
        if (itemId) {
            const filter = getSelectedItemString(initialFilter, itemId);
            dispatch(filterActions(domain).setFilter(filter));

            if (requestAction) {
                dispatch(requestAction(itemId));
            }
        }
    }, [itemId]);

    return (
        <KeyValueTable
            className={className}
            rows={rows}
            tableLabel={header}
            title={!hideTitle ? header : null}
            columnsCount={columnsCount}
            styles={styles}
        />
    );
};
