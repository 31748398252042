import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {ActionConfirmationModal, ModalContext} from '@components/modal';
import {ManagedGameType} from '@redux/entity';
import {CustomTheme} from '@style';

import {useAsyncActionState} from '../../shared/async-action/hooks';
import {gameRoomActions} from '../actions';

const localized = defineMessages({
    buttonLabel: {
        id: 'CloseGameRoomModalButtonCell_buttonLabel',
        defaultMessage: 'Close',
    },
    modalTitle: {
        id: 'CloseGameRoomModalButtonCell_modalTitle',
        defaultMessage: 'Close Table',
    },
    modalActionDescription: {
        id: 'CloseGameRoomModalButtonCell_modalActionDescription',
        defaultMessage: 'Do you want close this table?',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    closeGameRoomModalButtonIcon: {
        fontSize: `${theme.typography.subtitle2.fontSize} !important`,
    },
}));

export type CloseRoomModalButtonCellProps = {
    roomId: string;
    ownerId: string;
    gameType: ManagedGameType;
};

function CloseRoomModalButtonCell({roomId, ownerId, gameType}: CloseRoomModalButtonCellProps) {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const {openModal, closeModal} = useContext(ModalContext);
    const {isProgress} = useAsyncActionState(gameRoomActions.closeRoom);

    const handleConfirm = () => {
        dispatch(gameRoomActions.closeRoom.request({roomId, ownerId: ownerId, gameType}));
        closeModal();
    };

    const handleClick = () => {
        openModal({
            body: (
                <ActionConfirmationModal
                    title={localized.modalTitle}
                    contentDescription={localized.modalActionDescription}
                    handleConfirm={handleConfirm}
                    handleCancel={closeModal}
                />
            ),
            styleParams: {maxWidth: 'xs'},
        });
    };

    return (
        <OutlinedButton
            smallPlus
            label={localized.buttonLabel}
            onClick={handleClick}
            startIcon={<Icon className={classes.closeGameRoomModalButtonIcon} icon={CustomIcon.CancelOutline} color={IconColor.SuitRed} />}
            disabled={isProgress}
        />
    );
}

export function renderCloseRoomModalButtonCell(params: GridCellParams) {
    const value: CloseRoomModalButtonCellProps = params.value as CloseRoomModalButtonCellProps;
    return <CloseRoomModalButtonCell {...value} />;
}
