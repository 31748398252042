import createKycDetailsEpics from '../epicsCreator';

import {localized} from './localized';
import {filterSelector, itemSelector} from './selectors';
import {domain} from './types';

export default createKycDetailsEpics(domain, c => c.kycDetailsDocumentsService, itemSelector, filterSelector, {
    realtimeKey: domain,
    domain,
    name: localized.KycUploadedDocuments,
});
