import {defineMessages} from 'react-intl';

import {RevenueShareType} from '@models/generated/graphql';

export const localizedRevenueShareType = defineMessages({
    [RevenueShareType.Direct]: {
        id: 'localizedRevenueShareType_Direct',
        defaultMessage: 'Direct',
    },
    [RevenueShareType.SubAgent]: {
        id: 'localizedRevenueShareType_SubAgent',
        defaultMessage: 'Subagent',
    },
    [RevenueShareType.None]: {
        id: 'localizedRevenueShareType_None',
        defaultMessage: 'None',
    },
});
