import React from 'react';
import {Box} from '@mui/material';

//TODO: [BO-2675] Move files to src/common/components/html
type HtmlContentViewerProps = {
    content: string;
};

export const HtmlContentViewer = ({content, className}: HtmlContentViewerProps & React.HTMLAttributes<HTMLElement>) => {
    const createMarkup = () => {
        return {__html: content};
    };

    return content ? <Box className={className} dangerouslySetInnerHTML={createMarkup()}></Box> : null;
};
