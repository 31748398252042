import {MessageDescriptor} from 'react-intl';

export const domain = 'async-action';

export enum RequestStatus {
    None,
    Progress,
    Error,
}

export type AsyncActionState = {
    requestStatus: RequestStatus;
    errorMessage: MessageDescriptor;
    errorMessageValues?: Record<string, string>;
};

export type AsyncActionsState = {
    [key: string]: AsyncActionState;
};

export type AsyncActionStatusPayload = {
    requestAction: string;
    requestStatus: RequestStatus;
};

export type AsyncActionErrorPayload = {
    requestAction: string;
    errorMessage: MessageDescriptor;
    errorMessageValues?: Record<string, string>;
};

export type AsyncActionStateExtended = AsyncActionState & {
    actionId: string;
    isNone: boolean;
    isError: boolean;
    isProgress: boolean;
    resetRequestStatus: () => void;
    resetErrorMessage: () => void;
    resetAsyncActionState: () => void;
};
