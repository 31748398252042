import React from 'react';
import {defineMessages} from 'react-intl';

import {EmptyPlaceholder} from '@components/select';
import {UserAccountStatus} from '@models/generated/graphql';

import {formatSnakeCaseEnumValue} from 'src/common/utils/enum';
import {localizedUserAccountStatus} from 'src/features/app/intl/shared-resources/userAccountStatus';
import {SelectOption} from 'src/features/module-shared/types';
import FilterMultiSelect from 'src/features/shared/filter/components/FilterMultiSelect';
import {localizedFilters} from 'src/features/shared/filter/localize';
import {FilterPlacement, PageModuleChipSelectFilter} from 'src/features/shared/filter/types';

import {UserProfileFilterProps} from './UserProfileFiltersObsolete';

const localized = defineMessages({
    accountStatus: {
        id: 'UserProfileFilterTextAccountStatus_accountStatus',
        defaultMessage: 'Account Status',
    },
});

function UserProfileFilterAccountStatus({filter, onFilterChange, domain}: UserProfileFilterProps) {
    return (
        <FilterMultiSelect
            onFilterChange={onFilterChange}
            domain={domain}
            filter={
                {
                    key: 'accountStatus',
                    label: localized.accountStatus,
                    component: FilterMultiSelect,
                    chipType: nameof<UserAccountStatus>(),
                    options: Object.values(UserAccountStatus).map(s => ({label: localizedUserAccountStatus[s], value: s} as SelectOption)),
                    placement: FilterPlacement.Primary,
                    enumFormatter: formatSnakeCaseEnumValue,
                    emptyPlaceholder: EmptyPlaceholder.Any,
                    placeholder: localizedFilters.statusFilterPlaceholder,
                } as PageModuleChipSelectFilter
            }
            filterString={filter}
        />
    );
}
export default UserProfileFilterAccountStatus;
