import {createAsyncAction} from 'typesafe-actions';

import {DownloadRequestActionPayload, DownloadSuccessActionPayload} from '@file/types';
import {UserLoginQueryFields} from '@redux/entity';

import {Message} from '../message-snack-bar/types';

import {UserLoginViewModelKeys} from './types';

const domain = 'userLoginActions';

type UserLoginDownloadRequestPayload = DownloadRequestActionPayload<UserLoginViewModelKeys, UserLoginQueryFields>;

export const userLoginActions = {
    download: createAsyncAction(`${domain}/downloadRequest`, `${domain}/downloadSuccess`, `${domain}/downloadFailed`)<
        UserLoginDownloadRequestPayload,
        DownloadSuccessActionPayload,
        Message
    >(),
};
