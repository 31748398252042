import {gql} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {MarketingCode} from '@models/generated/graphql';

import {IRealtimeService} from '../../realtime-updates/services/IRealtimeService';
import {RealtimeSubscriptionService} from '../../realtime-updates/services/RealtimeUpdatesService';

export class MarketingCodeRealtimeService extends RealtimeSubscriptionService implements IRealtimeService<MarketingCode> {
    subscribeToCreate(): Observable<MarketingCode> {
        return super.createSubscription(this.getItemAddedSubscriptionQuery()).pipe(map(res => res.marketingCodeAdded));
    }

    subscribeToDelete(): Observable<MarketingCode[]> {
        return super.createSubscription(this.getItemsDeletedSubscriptionQuery()).pipe(map(res => res.marketingCodesDeleted));
    }

    private getItemAddedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnMarketingCodeAdded {
                marketingCodeAdded {
                    marketing_code
                    type
                    added_by_uid
                    created_at {
                        seconds
                    }
                }
            }
        `;
    }

    private getItemsDeletedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnMarketingCodesDeleted {
                marketingCodesDeleted {
                    marketing_code
                    type
                }
            }
        `;
    }
}
