import React, {FC} from 'react';
import {Box, Divider} from '@mui/material';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {withEmptyCheck} from '@components/label/EmptyLabel';
import {AccountVerification, KycDocument} from '@models/generated/graphql';
import {CustomTheme} from '@style';

import {AccountVerificationDocumentStatus} from './AccountVerificationDocumentStatus';

const useAccountVerificationStatusClasses = makeStyles()((theme: CustomTheme) => ({
    accountVerificationStatusContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    accountVerificationStatusLinkContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    accountVerificationStatusItemsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    accountVerificationStatus: {
        padding: theme.spacing(1),
        flexBasis: '100%',
    },
    accountVerificationStatusDivider: {
        margin: theme.spacing(3, 0),
        height: '1.25em',
    },
}));

type AccountVerificationDocumentsStatusProps = {
    value: KycDocument[];
    isLink: boolean;
};

function AccountVerificationDocumentsStatusInner({value, isLink}: AccountVerificationDocumentsStatusProps) {
    const {classes} = useAccountVerificationStatusClasses();

    return (
        <Box className={isLink ? classes.accountVerificationStatusLinkContainer : classes.accountVerificationStatusContainer}>
            {value.map((document, index) => (
                <Box className={classes.accountVerificationStatusItemsContainer} key={`${document.doc_ref_id}-${document.status}`}>
                    <Box className={classes.accountVerificationStatus}>
                        <AccountVerificationDocumentStatus
                            status={document.status}
                            docType={document.doc_type}
                            docRefId={document.doc_ref_id}
                            isLink={isLink}
                        />
                    </Box>
                    {index !== value.length - 1 && !isLink ? (
                        <Divider className={classes.accountVerificationStatusDivider} orientation="vertical" variant="middle" flexItem />
                    ) : null}
                </Box>
            ))}
        </Box>
    );
}

export const AccountVerificationDocumentsStatus: FC<AccountVerificationDocumentsStatusProps> = withEmptyCheck(
    AccountVerificationDocumentsStatusInner
);

export function renderAccountVerificationDocumentsStatus(params: GridCellParams, isLink?: boolean) {
    const documents: KycDocument[] = (params.row as AccountVerification)?.documents ?? [];
    return <AccountVerificationDocumentsStatus value={documents} isLink={isLink} />;
}
