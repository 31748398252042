import {IModuleGridItem} from '@components/data-grid';
import {UserProfile, UserProfileFilter} from '@models/generated/graphql';
import {IGQlSearchFilter} from '@services/deprecated/types';
import {isRecent} from '@utils';

import {IModuleItem} from '../../features/module-shared/types';

export const domain = 'player-management/registration-information';

export class PlayerProfile extends UserProfile implements IModuleItem {
    get id() {
        return this.uid;
    }
}

export class PlayerProfileGridItem extends UserProfile implements IModuleGridItem {
    id: string;

    serverId: string;

    get isNew() {
        return isRecent(this.date_of_joining, 1);
    }
}

export class GQLPlayerProfileSearchFilter extends UserProfileFilter implements IGQlSearchFilter {}
