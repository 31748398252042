import React, {useState} from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {MultiSelectWithExclude, MultiSelectWithExcludeModel} from '@components/select';

import {useUserProfiles} from '../../../features/block-user-profile-list';
import {SelectOption} from '../../../features/module-shared/types';

import {Wrapper, WrapperChild} from './WrapperDev';

const localized = defineMessages({
    searchPlaceholder: {
        id: 'devMultiSelect_searchPlaceholder',
        defaultMessage: 'Search...',
    },
});

const useStyles = makeStyles()(() => ({
    devMultiSelectContainer: {
        maxWidth: 330,
        width: 'fit-content',
    },
    devMultiSelectSelected: {
        marginTop: 16,
    },
    devMultiSelect: {
        height: 'unset',
    },
}));

export const MultiSelectWithExcludeDev = () => {
    const {classes} = useStyles();

    const {
        items,
        totalCount: filteredTotalCount,
        searchFilter,
        handleFilterChange,
        handlePageChange,
    } = useUserProfiles({
        viewType: 'View1',
        fields: ['uid'],
        validateFilter: () => true,
        defaultSorting: [{sort: 'desc', field: 'finance.balance'}],
        defaultPaging: {page: 1, pageSize: 25},
    });
    const {totalCount} = useUserProfiles({
        viewType: 'View2',
        fields: ['uid'],
        validateFilter: () => true,
        defaultSorting: [{sort: 'desc', field: 'finance.balance'}],
        defaultPaging: {page: 1, pageSize: 25},
    });

    const options = items?.map<SelectOption>(i => ({value: i.uid, label: i.uid}));

    const [selectedValues, setSelectedValues] = useState<MultiSelectWithExcludeModel>({include: [], exclude: []});

    function handleFilterChangeAdapter(uid: string) {
        handleFilterChange([{key: 'uid', value: uid}]);
    }

    return (
        <Wrapper label="MultiSelectWithExclude (UserProfile)">
            <WrapperChild>
                <div className={classes.devMultiSelectContainer}>
                    <MultiSelectWithExclude
                        itemsName="games"
                        mode="server"
                        horizontalPosition="left"
                        value={selectedValues}
                        options={options}
                        hasSearch={true}
                        searchPlaceholder={localized.searchPlaceholder}
                        onValueChange={setSelectedValues}
                        showResetButton
                        showPagination
                        className={classes.devMultiSelect}
                        onFilterChange={handleFilterChangeAdapter}
                        page={searchFilter?.paging?.page}
                        pageSize={searchFilter?.paging?.pageSize}
                        onPageChange={handlePageChange}
                        total={totalCount}
                        filteredTotal={filteredTotalCount}
                    />
                </div>
                <div className={classes.devMultiSelectSelected}>Include: {JSON.stringify(selectedValues.include)}</div>
                <div className={classes.devMultiSelectSelected}>Exclude: {JSON.stringify(selectedValues.exclude)}</div>
            </WrapperChild>
        </Wrapper>
    );
};
