import React from 'react';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {ModuleName} from '@models/modules';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';

import {KycDetails} from '../features/block-account-verification';

export const ndrpProcessingDetailsPageResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'NdrpProcessingDetailsPage',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageNdrp), new Policy(ModuleName.PageNdrp, null, 'read')]),
});

function NdrpProcessingDetailsPage() {
    return <KycDetails type="ndrp" />;
}

export default withTraceErrorBoundary(withPolicyAccessCheck(NdrpProcessingDetailsPage, ndrpProcessingDetailsPageResource), ErrorModal);
