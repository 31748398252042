import {defineMessages, useIntl} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {UserLabel} from '@models/generated/graphql';

const localized = defineMessages({
    label: {
        id: 'useUserLabelFormatter_label',
        defaultMessage: 'Label {label} of {group}',
    },
});

export type UserLabelValue = {
    labels: UserLabel[];
};

export const useUserLabelFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useIntl();
    const value: UserLabelValue = JSON.parse(json);
    const formatUserLabel = (label: UserLabel): ChipKeyValue => {
        if (label) {
            const formattedValue = formatMessage(localized.label, {label: label.name, group: label.group?.name});

            return {key: formattedValue};
        }

        return null;
    };

    return value?.labels?.map(formatUserLabel) ?? [];
};
