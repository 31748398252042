import {InMemoryCache, makeVar} from '@apollo/client';
import {TypePolicies} from '@apollo/client/cache/inmemory/policies';
import {injectable} from 'inversify';

import {PlayerSecuritySummary, PlayerSkillCustomLabel, PlayerSkillLabel} from '@models/generated/graphql';

@injectable()
export class ApolloClientInMemoryCache {
    public init(): InMemoryCache {
        return new InMemoryCache({
            typePolicies: {
                ...this.getUserProfileTypePolicy(),
            },
            resultCaching: false,
        });
    }

    private getUserProfileTypePolicy(): TypePolicies {
        const playerSecuritySummaryVar = makeVar<PlayerSecuritySummary>({
            player_skill: {
                custom_label: PlayerSkillCustomLabel.Vip,
                score: 0.2,
                label: PlayerSkillLabel.Pro,
            },
            gto_score: 75,
        } as PlayerSecuritySummary);

        return {
            UserProfile: {
                fields: {
                    security: {
                        read() {
                            return playerSecuritySummaryVar();
                        },
                    },
                },
            },
        };
    }
}
