import React from 'react';

import {AgentPlayerReportingViewModelKeys} from '@models/agent-player-reporting';

import {DownloadCsvButton} from 'src/features/block-download';
import {useAgentPlayerReportDownloadButton} from '../hooks';
import {AgentReportType} from '../types';

type AgentPlayerReportDownloadCsvButtonProps = {
    filterString: string;
    filename: string;
    keys: AgentPlayerReportingViewModelKeys[];
    agentReportType: AgentReportType;
};

export function AgentPlayerReportDownloadCsvButton(props: AgentPlayerReportDownloadCsvButtonProps) {
    const {handleClick, isProgress} = useAgentPlayerReportDownloadButton(props);
    return <DownloadCsvButton loading={isProgress} onClick={handleClick} />;
}
