import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {LayoutDetails} from '@components/layout';
import {EntityType, NotesFilterType} from '@models/generated/graphql';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {ViewType} from '@redux/view';
import {getSelectedItemId} from '@utils';

import {locationSearchSelector} from '../features/app/routing/selectors';
import {useTransactionDetails} from '../features/block-transaction-details/hooks';
import {
    NotesAndAttachments,
    UserDetailsFinancialInfo,
    UserDetailsKYCInfo,
    UserDetailsMarketingInfo,
    UserDetailsNotificationHistoryInfo,
    UserDetailsPersonalInfo,
    UserDetailsPrivacyPolicyInfo,
    UserDetailsRegionRelatedInfo,
    UserDetailsSecurityInfo,
    UserDetailsTermsAndConditionsInfo,
    WithdrawalActions,
    WithdrawalDetailsHeader,
} from '../features/block-workspace-details';
import {withModule} from '../features/module-shared/components/ModuleHoc';

const WithdrawalDetails = () => {
    const viewType: ViewType = 'WithdrawalDetails';
    const queryString = useSelector(locationSearchSelector);
    const transactionId = getSelectedItemId(queryString);
    const {item: transaction} = useTransactionDetails({
        id: transactionId,
        viewType,
        fields: ['transaction_id', 'player_country'],
    });
    const userId = transaction?.uid;

    return (
        <LayoutDetails
            header={<WithdrawalDetailsHeader transactionId={transactionId} />}
            primaryInfo={
                <>
                    <UserDetailsPersonalInfo userId={userId} />
                    <UserDetailsRegionRelatedInfo country={transaction?.player_country} userId={userId} />
                    <UserDetailsFinancialInfo userId={userId} />
                    <UserDetailsKYCInfo userId={userId} />
                    <UserDetailsSecurityInfo userId={userId} />
                    <UserDetailsMarketingInfo userId={userId} />
                    <UserDetailsPrivacyPolicyInfo userId={userId} />
                    <UserDetailsTermsAndConditionsInfo userId={userId} />
                    <UserDetailsNotificationHistoryInfo userId={userId} />
                </>
            }
            secondaryInfoTop={<WithdrawalActions transactionId={transactionId} />}
            secondaryInfoBottom={
                userId ? (
                    <NotesAndAttachments
                        createEntity={{id: transactionId, type: EntityType.Transaction, parent: {id: userId, type: EntityType.Player}}}
                        readEntity={{id: userId, type: EntityType.Player, parent: {id: userId, type: EntityType.Player}}}
                        filterType={NotesFilterType.AllRecords}
                    />
                ) : (
                    <></>
                )
            }
        />
    );
};

export default withModule(memo(WithdrawalDetails), [
    {moduleName: ModuleName.WithdrawalProcessing, permissions: [PermissionEnum.Read]},
    {
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.Payment,
        permissions: [PermissionEnum.Read],
    },
    {
        moduleName: ModuleName.WithdrawalProcessing,
        submoduleName: SubmoduleName.WithdrawalProcessingRisk,
        permissions: [PermissionEnum.Read],
    },
]);
