import {endDateNow, getNHoursAgoAsSeconds, isDiffNHours, totalHoursPassedFromNow} from '@utils';

import {Filter as FilterKeyValue} from '../../../../../common/types';
import {getFilterValueFromKey} from '../../utils';

import {Operators, ValueRangePickerModel} from './valueRangePickerTypes';

type DecodeValueRangeFilterProps = {
    fromKey: string;
    toKey: string;
    value: FilterKeyValue<string>[];
};

export function convertToDateRangePickerModel({fromKey, toKey, value}: DecodeValueRangeFilterProps): ValueRangePickerModel {
    const from = getFilterValueFromKey(fromKey, value) ?? '';
    const to = getFilterValueFromKey(toKey, value) ?? '';
    const result: ValueRangePickerModel = {
        operator: Operators.isLessThan,
        from: totalHoursPassedFromNow(to),
        to: totalHoursPassedFromNow(from),
    };

    if (from && to) {
        const hoursDiff = 0;
        if (isDiffNHours(Number(from), Number(to), hoursDiff)) {
            result.operator = Operators.equals;
            result.from = totalHoursPassedFromNow(from);
        } else {
            result.operator = Operators.between;
        }
    } else if (from && !to) {
        result.operator = Operators.isLessThan;
    } else if (!from && to) {
        result.operator = Operators.isGreaterThan;
    }

    return result;
}

export function convertToNumberRangePickerModel({fromKey, toKey, value}: DecodeValueRangeFilterProps): ValueRangePickerModel {
    const fromFilter = getFilterValueFromKey(fromKey, value);
    const toFilter = getFilterValueFromKey(toKey, value);
    const from = fromFilter ? `${fromFilter}` : '';
    const to = toFilter ? `${toFilter}` : '';
    const result: ValueRangePickerModel = {
        operator: Operators.isLessThan,
        from,
        to,
    };

    if (from && to) {
        if (Number(from) === Number(to)) {
            result.operator = Operators.equals;
            result.from = from;
            result.to = '';
        } else {
            result.operator = Operators.between;
        }
    } else if (from && !to) {
        result.operator = Operators.isGreaterThan;
    } else if (!from && to) {
        result.operator = Operators.isLessThan;
    }

    return result;
}

type TimestampRange = {from: number; to: number | string};

export function convertToDateRangeInSeconds({operator, from, to}: ValueRangePickerModel): TimestampRange {
    let fromInSeconds: number = !to || Number.isNaN(Number(to)) || Number(to) < 0 ? null : getNHoursAgoAsSeconds(to);
    let toInSeconds: number | string = !from || Number.isNaN(Number(from)) || Number(from) < 0 ? null : getNHoursAgoAsSeconds(from);

    if (operator === Operators.between) {
        toInSeconds = from === '0' ? endDateNow : toInSeconds;
    } else if (operator === Operators.equals) {
        toInSeconds = fromInSeconds;
    } else if (operator === Operators.isGreaterThan) {
        fromInSeconds = null;
    } else if (operator === Operators.isLessThan) {
        toInSeconds = null;
    }

    return {from: fromInSeconds, to: toInSeconds};
}

type NumberRange = {from: number; to: number};

export function convertToNumberRange({operator, from: fromString, to: toString}: ValueRangePickerModel): NumberRange {
    let from: number = Number.isNaN(Number(fromString)) ? null : Number(fromString);
    let to: number = Number.isNaN(Number(toString)) ? null : Number(toString);

    if (operator === Operators.equals) {
        to = from;
    } else if (operator === Operators.isLessThan) {
        from = null;
    } else if (operator === Operators.isGreaterThan) {
        to = null;
    }

    return {from: from, to: to};
}
