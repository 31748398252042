import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {useDispatch} from 'react-redux';
import {PayloadActionCreator} from 'typesafe-actions';

import {Button} from '@components/button/Buttons';

type FormCloseActionProps = {
    action: PayloadActionCreator<string, void>;
    component?: React.ElementType;
    label?: string | MessageDescriptor;
    className?: string;
};

const localized = defineMessages({
    formCloseLabel: {
        id: 'FORM_CLOSE_BUTTON_LABEL',
        defaultMessage: 'Cancel',
    },
});

const FormCloseAction = ({className, label, action, component}: FormCloseActionProps) => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(action());
    };

    const ComponentType = component;

    return ComponentType ? (
        <ComponentType label={label ?? localized.formCloseLabel} onClick={() => onClick()} />
    ) : (
        <Button className={className} label={label ?? localized.formCloseLabel} onClick={() => onClick()} />
    );
};

export default FormCloseAction;
