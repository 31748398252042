import {createAction} from 'typesafe-actions';

import {LogErrorActionPayload} from './types';

//TODO: [BO-2709] Remove error-handling once integrate with open telemetry
const domain = 'error-handling';

export const logErrorAction = createAction(`${domain}/logError`)<LogErrorActionPayload>();

export const initDefaultErrorHandlerAction = createAction(`${domain}/setDefaultErrorHandler`)<void>();
