import React from 'react';

import {CodeErrorMessage, CodeErrorMessageProps, ErrorCodeMessage} from '@components/error';

import {errorMessages} from '../../intl';

type SupportedErrorCodes =
    | 401
    | 403
    | 404
    | 409
    | 500
    | 502
    | 10000006
    | 10000027
    | 10000028
    | 10000048
    | 10000050
    | 10000056
    | 10000058
    | 10000062
    | 20000001;

const errorCodeMapping: Record<SupportedErrorCodes, ErrorCodeMessage> = {
    401: {
        message: errorMessages.notAuthorized,
    },
    403: {
        message: errorMessages.notEnoughPermissions,
    },
    404: {
        message: errorMessages.notFound,
    },
    409: {
        message: errorMessages.conflict,
    },
    500: {
        message: errorMessages.unhandledServerError,
    },
    502: {
        message: errorMessages.notAvailable,
    },
    10000006: {
        message: errorMessages.inputValidationIncorrectFormat,
    },
    10000027: {
        message: errorMessages.inputValidationValueNotExist,
        rank: 1,
    },
    10000028: {
        message: errorMessages.inputValidationNullValue,
        rank: 1,
    },
    10000048: {
        message: errorMessages.invalidNotificationContractType,
        rank: 1,
    },
    10000050: {
        message: errorMessages.unexpectedTransactionStatus,
        rank: 1,
    },
    10000056: {
        message: errorMessages.transactionWithSameIdAlreadyExists,
        rank: 1,
    },
    10000058: {
        message: errorMessages.handledExceptionOccurred,
        rank: 1,
    },
    10000062: {
        message: errorMessages.notEnoughFunds,
        rank: 1,
    },
    20000001: {
        message: errorMessages.fieldUndefinedValidationError,
        rank: 1,
    },
};

type GlobalServerErrorMessageProps = Pick<CodeErrorMessageProps<number>, 'errorsToShow' | 'hasError'> & {
    error: string;
};

export function GlobalServerErrorMessage({error, errorsToShow = 'allHighestRanked', hasError}: GlobalServerErrorMessageProps) {
    return (
        <CodeErrorMessage
            error={error}
            errorCodeMessages={errorCodeMapping}
            defaultErrorMessage={errorMessages.defaultError}
            errorsToShow={errorsToShow}
            hasError={hasError}
        ></CodeErrorMessage>
    );
}
