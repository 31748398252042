import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {TransactionStatus} from '@models/generated/graphql';

import {localizedTransactionStatus} from '../../app/intl/shared-resources/transactionStatus';

export type TransactionStatusValue = {
    transaction_status: TransactionStatus;
};

export const useTransactionStatusFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: TransactionStatusValue = JSON.parse(json);
    return value?.transaction_status ? [{key: formatMessage(localizedTransactionStatus[value?.transaction_status])}] : null;
};
