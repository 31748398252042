import {defineMessages} from 'react-intl';

import {AmlLabel} from '@models/generated/graphql';

export const localizedAmlLabel = defineMessages({
    [AmlLabel.AccountReview]: {
        id: 'amlLabelAccountReview',
        defaultMessage: 'Account Review',
    },
    [AmlLabel.ActivityReview]: {
        id: 'amlLabelActivityReview',
        defaultMessage: 'Activity Review',
    },
    [AmlLabel.GameplayReview]: {
        id: 'amlLabelGameplayReview',
        defaultMessage: 'Gameplay Review',
    },
    [AmlLabel.EddGameplay]: {
        id: 'amlLabelEddGameplay',
        defaultMessage: 'Edd Gameplay',
    },
    [AmlLabel.EddDeposit]: {
        id: 'amlLabelEddDeposit',
        defaultMessage: 'Edd Deposit',
    },
    [AmlLabel.EddWithdrawal]: {
        id: 'amlLabelEddWithdrawal',
        defaultMessage: 'Edd Withdrawal',
    },
    [AmlLabel.EddPep]: {
        id: 'amlLabelEddPep',
        defaultMessage: 'Edd Pep',
    },
    [AmlLabel.VerifyPaymentMethod]: {
        id: 'amlLabelVerifyPaymentMethod',
        defaultMessage: 'Verify Payment Method',
    },
});
