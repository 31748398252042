import React, {Fragment} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {useDataGridSelectedIds} from '@components/data-grid/mui';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {ModuleName} from '@models/modules';
import {securityWorkspaceReadPermissions} from '@models/permissions/permissions';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {Policy} from '@auth';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay, ViewType} from '@redux/view';
import {removeItemsFromArray} from '@utils/array';

import {Sorting} from 'src/common/types';
import {UserProfileDownloadCsvButton} from 'src/features/block-user-profile-actions';
import {DataGridUserProfileServer, UserProfileQueryFilters, useUserProfiles} from 'src/features/block-user-profile-list';
import {useUserProfileSettings} from 'src/features/block-user-profile-list/hooks';
import {SecurityWorkspaceBulkPlayerButton, SecurityWorkspaceManageLabelButton} from 'src/features/block-workspace-security-toolbar';
import {withModule} from '../features/module-shared/components/ModuleHoc';

const localized = defineMessages({
    title: {
        id: 'SecurityWorkspace_title',
        defaultMessage: 'Security Workspace',
    },
});

function SecurityWorkspace() {
    const {formatMessage} = useIntl();
    const viewType: ViewType = 'SecurityWorkspace';
    const triggers = [{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Update}];
    const realtimeMode = RealtimeUpdatesMode.Confirm;
    const pinnedColumns: UserProfileViewModelKeys[] = ['uid_link_security', 'username'];
    const columns: UserProfileViewModelKeys[] = [
        'uid_link_security',
        'networks_PKW',
        'networks_BL',
        'country',
        'username',
        'first_name',
        'last_name',
        'email',
        'phone',
        'referrer',
        'register_marketing_code',
        'finance.balance',
        'deposit_total',
        'withdrawal_total',
        'poker_ggr_lifetime',
        'casino_ggr_lifetime',
        'sports_ggr_lifetime',
        'account_status',
        'labels',
        'security_cases',
        'latest_login.logged_at_ts.seconds',
        'date_of_joining.seconds',
        'login_countries',
    ];

    const defaultSorting: Sorting<UserProfileViewModelKeys>[] = [
        {
            field: 'date_of_joining.seconds',
            sort: 'desc',
        },
    ];
    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useUserProfiles({
            viewType,
            displayName: localized.title,
            fields: columns,
            realtimeMode,
            triggers,
            defaultSorting,
            validateFilter: () => true,
            syncWithUrl: true,
            cleanDelay: extendedViewCleanDelay,
        });
    const {selectedIds, handleSelect} = useDataGridSelectedIds();
    const {visibleColumns, onVisibleColumnsChange} = useUserProfileSettings({settingId: 'SecurityWorkspaceGrid', defaultColumns: columns});

    return (
        <Fragment>
            <LayoutQueue
                header={
                    <PageHeader
                        header={localized.title}
                        actions={
                            <>
                                <SecurityWorkspaceManageLabelButton selectedIds={selectedIds} />
                                <SecurityWorkspaceBulkPlayerButton selectedIds={selectedIds} />
                                <UserProfileDownloadCsvButton
                                    filterString={filterString}
                                    filename={formatMessage(localized.title)}
                                    keys={removeItemsFromArray(visibleColumns, 'finance.balance', 'email', 'phone')}
                                />
                            </>
                        }
                    />
                }
                toolbar={
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems streched>
                                <UserProfileQueryFilters
                                    model={filterString}
                                    onChange={handleFilterChange}
                                    availableFilters={[
                                        'uidWithPlaceholder',
                                        'username',
                                        'nickname',
                                        'firstName',
                                        'lastName',
                                        'labels',
                                        'email',
                                        'phone',
                                        'security_cases',
                                        'networks_PKW',
                                        'networks_BL',
                                        'referrerId',
                                        'accountStatus',
                                        'registrationCountry',
                                        'loginCountry',
                                        'registrationDate',
                                        'casinoGgr',
                                        'pokerGgr',
                                        'sportsGgr',
                                        'marketingCode',
                                        'depositTotal',
                                        'withdrawalTotal',
                                    ]}
                                />
                            </ToolbarItems>
                        </ToolbarItemsContainer>
                    </Toolbar>
                }
                body={
                    <DataGridUserProfileServer
                        columns={columns}
                        rows={items}
                        rowCount={totalCount}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortModelChange={handleSortChange}
                        page={searchFilter?.paging?.page}
                        pageSize={searchFilter?.paging?.pageSize}
                        sortModel={searchFilter?.sorting}
                        checkboxSelection={true}
                        selectedIds={selectedIds}
                        onSelect={handleSelect}
                        hasColumnSelection
                        pinnedColumns={pinnedColumns}
                        isFlexible={false}
                        visibleColumns={visibleColumns}
                        onColumnsVisibilityUpdate={onVisibleColumnsChange}
                    />
                }
            />
        </Fragment>
    );
}

export const securityWorkspaceResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'SecurityWorkspace',
    rules: new PoliciesAccessRule([new Policy(ModuleName.SecurityWorkspace, null, 'read')]),
});

export default withModule(SecurityWorkspace, securityWorkspaceReadPermissions);
