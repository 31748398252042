import {useAutoMapper} from '@auto-mapper';
import {SportsbookTransactionRecord, SportsbookTransactionSortKeys} from '@models/sportsbook-transaction/type';
import {EntityType} from '@redux/entity';
import {extendedViewCleanDelay, UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {Sorting} from 'src/common/types';

import {SportsbookTransactionFilterKeys, SportsbookTransactionViewModel, SportsbookTransactionViewModelKeys} from './types';

type UseSportsbookTransactionProps = Omit<
    UseListViewEntityProps<SportsbookTransactionFilterKeys, SportsbookTransactionViewModelKeys>,
    'fields'
>;

export function useSportsbookTransaction({
    viewType,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    validateFilter,
    syncWithUrl,
    cleanDelay = extendedViewCleanDelay,
}: UseSportsbookTransactionProps): UseListViewEntityResult<SportsbookTransactionViewModel, SportsbookTransactionViewModelKeys> {
    const mapper = useAutoMapper();

    const {items, totalCount, viewEntity, handleFilterChange, handlePageChange, handlePageSizeChange, handleSortChange, getSearchFilter} =
        useViewInit<
            SportsbookTransactionRecord,
            SportsbookTransactionFilterKeys,
            SportsbookTransactionViewModelKeys,
            SportsbookTransactionSortKeys
        >({
            viewType,
            entity: {
                entity: EntityType.SportsbookTransaction,
                fields: [
                    'compUserId',
                    'ticketId',
                    'sabaTicketId',
                    'ticketStatus',
                    'matchIds',
                    'matchNames',
                    'tournamentNames',
                    'betOptions',
                    'betOdds',
                    'betAmount',
                    'settleAmount',
                    'betTime',
                ],
            },
            defaultFilters,
            defaultPaging,
            defaultSorting: mapSorting(defaultSorting),
            validateFilter,
            syncWithUrl,
            cleanDelay,
        });

    function mapSorting(sorting: Sorting<SportsbookTransactionViewModelKeys>[]): Sorting<SportsbookTransactionSortKeys>[] {
        return sorting?.map(s => ({
            ...s,
            field: mapper.map<SportsbookTransactionViewModelKeys, SportsbookTransactionSortKeys>(
                s.field,
                nameof<SportsbookTransactionViewModelKeys>(),
                nameof<SportsbookTransactionSortKeys>()
            ),
        }));
    }

    function handleSortChangeInternal(newSorting: Sorting<SportsbookTransactionViewModelKeys>[]) {
        handleSortChange(mapSorting(newSorting));
    }

    function getMappedItems() {
        return items.map(item =>
            mapper.map<SportsbookTransactionRecord, SportsbookTransactionViewModel>(
                item,
                SportsbookTransactionRecord,
                SportsbookTransactionViewModel
            )
        );
    }

    function getMappedSearchFilter() {
        return getSearchFilter(field =>
            mapper.map<SportsbookTransactionSortKeys, SportsbookTransactionViewModelKeys>(
                field,
                nameof<SportsbookTransactionSortKeys>(),
                nameof<SportsbookTransactionViewModelKeys>()
            )
        );
    }

    return {
        items: getMappedItems(),
        totalCount,
        searchFilter: getMappedSearchFilter(),
        filterString: viewEntity.filter,
        handleFilterChange,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleSortChangeInternal,
    };
}
