import React, {createContext, PropsWithChildren} from 'react';

import {useBrand} from './hooks';
import {IBrandService} from './IBrandService';
import {BrandConfig} from './types';

type BrandContextProps = BrandConfig;

export const BrandContext = createContext<BrandContextProps>(null);

type BrandProviderProps = PropsWithChildren<{
    brandService: IBrandService;
}>;

export function BrandProvider({children, brandService}: BrandProviderProps) {
    const brandConfig = useBrand(brandService);

    return <BrandContext.Provider value={brandConfig}>{children}</BrandContext.Provider>;
}
