import React from 'react';
import {defineMessages} from 'react-intl';

import {MessageOrientation} from '@components/alerts/Message';
import {UserProfile} from '@models/generated/graphql';
import {AgentProfileFilterKeys, AgentProfileQueryFields, EntityType, Filter} from '@redux/entity';
import {isStringNullOrEmpty} from '@utils';

import {ViewAutocomplete, ViewAutocompleteProps} from 'src/features/view-autocomplete/components/ViewAutocomplete';

type AgentIdAutocompleteProps = Pick<
    ViewAutocompleteProps<Filter<AgentProfileFilterKeys>, UserProfile>,
    'errorMessage' | 'placeholder' | 'viewType'
> & {
    value: string;
    onValueChange?: (value: string) => void;
};

const localized = defineMessages({
    autocompleteDisplayName: {
        id: 'AgentIdAutocomplete_autocompleteDisplayName',
        defaultMessage: 'Referrer ID',
    },
});

export const AgentIdAutocomplete = ({value, ...props}: AgentIdAutocompleteProps) => {
    const selectedPlayer = value ? ({uid: value} as UserProfile) : null;
    const filter: Filter<AgentProfileFilterKeys> = value ? {uid: value} : null;
    const fields: AgentProfileQueryFields[] = ['uid'];

    return (
        <ViewAutocomplete
            {...props}
            value={selectedPlayer}
            entityType={EntityType.AgentProfile}
            displayName={localized.autocompleteDisplayName}
            fields={fields}
            optionMapper={(item: UserProfile) => ({label: item?.uid, value: item?.uid})}
            valueMapper={(value: UserProfile) => value?.uid}
            errorMessageOrientation={MessageOrientation.Floating}
            filterKey={'uid'}
            filter={filter}
            validateFilter={filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value)}
            mode="server"
        />
    );
};
