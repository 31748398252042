import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {DatePickerType} from './DatePicker';
export const useDatePickerStyles = (datePickerType: DatePickerType, isRangeMode: Boolean) => {
    const {classes} = useClasses();

    const containerModeClassName = !isRangeMode ? `${classes.datePickerCalendarContainerSingleMode}` : '';
    switch (datePickerType) {
        case 'small': {
            return {
                dropdownContainer: classes.datePickerContainerSmall,
                root: `${classes.datePickerCalendarContainerSmall} ${containerModeClassName}`,
                caption: classes.datePickerCaption,
                caption_label: classes.datePickerCaptionLabelSmall,
                nav_button: classes.datePickerCaptionButton,
                nav: classes.datePickerCaptionNav,
                head_cell: classes.datePickerHeadCellSmall,
                day: classes.datePickerDay,
                day_outside: classes.datePickerDayOutside,
                cell: classes.datePickerCell,
                row: classes.datePickerRow,
            };
        }
        case 'large': {
            return {
                dropdownContainer: classes.datePickerContainer,
                root: `${classes.datePickerCalendarContainer} ${containerModeClassName}`,
                caption: classes.datePickerCaption,
                caption_label: classes.datePickerCaptionLabel,
                nav_button: classes.datePickerCaptionButton,
                nav: classes.datePickerCaptionNav,
                head_cell: classes.datePickerHeadCell,
                day: classes.datePickerDay,
                day_outside: classes.datePickerDayOutside,
                cell: classes.datePickerCell,
                row: classes.datePickerRow,
            };
        }
        default: {
            return null;
        }
    }
};

export const useClasses = makeStyles<void, 'datePickerDay'>()((theme: CustomTheme, _params, classes) => {
    const cellPadding = parseInt(theme.spacing(0.75), 10);
    const cellSize = parseInt(theme.spacing(4), 10);

    return {
        datePickerInputFillAvailableWidth: {
            width: '100%',
        },
        calendarIcon: {
            color: theme.palette.secondary.main,
            fontSize: 15,
        },

        calendarIconOpen: {
            color: theme.palette.primary.main,
        },

        calendarIconStart: {
            paddingRight: theme.spacing(2),
        },

        calendarIconEnd: {
            paddingLeft: theme.spacing(2),
        },

        datePickerContainer: {
            padding: theme.spacing(2),
        },

        datePickerContainerSmall: {
            padding: theme.spacing(2),
            margin: 0,
        },

        datePickerPickerContainer: {
            marginBottom: theme.spacing(2),
        },

        datePickerClearButton: {
            marginRight: -theme.spacing(1.5),
            color: theme.palette.text.primary,
        },

        datePickerCalendarContainer: {
            margin: theme.spacing(0, 0, 2),
            fontSize: theme.typography.body1.fontSize,
            '& .rdp-table': {
                maxWidth: '280px', // Style copied from react-day-picker before their styles was overwritten by our classes, in later version our classes replaced their classes
            },
        },

        datePickerCalendarContainerSmall: {
            margin: theme.spacing(0),
            fontSize: theme.typography.body1.fontSize,

            '& .rdp-table': {
                margin: theme.spacing(0, -1.5),
                [theme.breakpoints.down('sm')]: {
                    margin: theme.spacing(0.5),
                    maxWidth: 'unset',
                },
            },
        },

        datePickerCaption: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            paddingRight: '70px', // for navigation buttons with absolute positioning
            [theme.breakpoints.down('sm')]: {
                paddingRight: '90px', // for navigation buttons with absolute positioning on mobile
            },
        },

        datePickerCaptionLabel: {
            ...(theme.typography.h6 as CSSObject),
            color: theme.palette.text.primary,
        },

        datePickerCaptionLabelSmall: {
            ...(theme.typography.h6 as CSSObject),
            color: theme.palette.text.primary,
            margin: theme.spacing(0.5),
        },

        datePickerCaptionButton: {
            '&.rdp-button': {
                position: 'absolute',
                top: '50%',
                transform: 'translatey(-50%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: theme.spacing(1),
                width: 26,
                height: 26,
                border: '1px solid #E1E7EB',
                borderRadius: 6,

                '&:active:not([disabled]), &:focus:not([disabled]), &:hover:not([disabled]):not(.rdp-day_selected)': {
                    border: 'none',
                    backgroundColor: theme.palette.primary.main,
                    '& .rdp-nav_icon': {
                        width: 8,
                        height: 8,
                        color: theme.palette.primary.contrastText,
                    },
                },
                '& .rdp-nav_icon': {
                    width: 8,
                    height: 8,
                    color: theme.palette.text.primary,
                },
            },
        },

        datePickerCaptionNav: {
            '& > button:first-child': {
                right: theme.spacing(5),
            },

            '& > button:last-child': {
                right: '0',
            },

            '& > button:last-child:hover': {
                right: '0',
            },
        },

        datePickerHeadCell: {
            padding: theme.spacing(2.5, 0, 1),
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.caption.fontWeight,
            color: theme.palette.text.secondary,
        },

        datePickerHeadCellSmall: {
            padding: theme.spacing(2, 0, 1),
            fontSize: theme.typography.subtitle2.fontSize,
            fontWeight: theme.typography.caption.fontWeight,
            color: theme.palette.text.secondary,
        },

        datePickerCell: {
            borderSpacing: 0,
            padding: cellPadding,
            zIndex: 0,
            position: 'relative',
            '& > .rdp-button': {
                '&.rdp-day_range_middle, &.rdp-day_range_middle:hover, &.rdp-day_range_middle:active, &.rdp-day_range_middle:focus': {
                    //middle cell in range
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                },

                '&.rdp-day_range_middle:before, &.rdp-day_range_middle:after': {
                    //to fill the gaps between middle cell in range
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    width: cellPadding * 2,
                    height: cellSize,
                    backgroundColor: theme.palette.primary.light,
                },

                '&.rdp-day_range_middle:before': {
                    left: -cellPadding,
                },

                '&.rdp-day_range_middle:after': {
                    right: -cellPadding,
                },

                '&.rdp-day_range_start, &.rdp-day_range_end, &.rdp-day_range_start:hover, &.rdp-day_range_end:hover, &.rdp-day_range_start:active, &.rdp-day_range_end:active, &.rdp-day_range_start:focus, &.rdp-day_range_end:focus':
                    {
                        //start/end cell in range
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        borderRadius: '100%',
                        width: cellSize,
                        margin: 0,
                        position: 'relative',
                        border: 0,
                    },

                '&.rdp-day_range_start:not(.rdp-day_range_end):after, &.rdp-day_range_end:not(.rdp-day_range_start):before': {
                    //to fill the gaps between start/end cell in range
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    width: cellSize / 2 + cellPadding,
                    height: cellSize,
                    backgroundColor: theme.palette.primary.light,
                    zIndex: -1,
                },

                '&.rdp-day_range_start:not(.rdp-day_range_end):after': {
                    right: -cellPadding,
                },

                '&.rdp-day_range_end:not(.rdp-day_range_start):before': {
                    left: -cellPadding,
                },
                [theme.breakpoints.down('sm')]: {
                    '&.rdp-day_range_middle:before': {
                        left: -cellPadding * 2,
                    },

                    '&.rdp-day_range_middle:after': {
                        right: -cellPadding * 2,
                    },

                    '&.rdp-day_range_start:not(.rdp-day_range_end):after': {
                        right: -cellPadding * 2,
                    },

                    '&.rdp-day_range_end:not(.rdp-day_range_start):before': {
                        left: -cellPadding * 2,
                    },
                },
            },
        },

        datePickerRow: {
            '& > td:last-child > .rdp-day_range_middle': {
                //round last cell in row
                borderTopRightRadius: '100%',
                borderBottomRightRadius: '100%',
            },

            '& > td:first-child > .rdp-day_range_middle': {
                //round first cell in row
                borderTopLeftRadius: '100%',
                borderBottomLeftRadius: '100%',
            },

            '& > td:first-child > .rdp-day_range_middle:before, & > td:last-child > .rdp-day_range_middle:after': {
                //no need to fill the gaps between for edge cells
                display: 'none',
            },
        },

        datePickerDay: {
            '&.rdp-button': {
                height: cellSize,
                width: cellSize,
                border: 0,

                '&:active:not([disabled]), &:focus:not([disabled]), &:hover:not([disabled])': {
                    border: 0,
                },
                '&:not([disabled]):not(.rdp-day_selected):hover, &:not([disabled]):not(.rdp-day_selected):active, &:not([disabled]):not(.rdp-day_selected):focus':
                    {
                        borderRadius: '100%',
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.main,
                    },
                '.rdp-day_selected': {
                    color: theme.palette.primary.main,
                },
            },
        },

        datePickerCalendarContainerSingleMode: {
            [`& .${classes.datePickerDay}.rdp-day_selected`]: {
                borderRadius: '100%',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            },
        },

        datePickerDayOutside: {
            '&.rdp-button': {
                color: theme.palette.text.secondary,
                '&:hover': {
                    color: theme.palette.primary.main,
                },
            },
        },
    };
});
