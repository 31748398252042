import {BackpackItemLabel, BackpackItemStatus} from '@models/generated/graphql';
import {TimestampKeys} from '@models/shared';

export class BackpackViewModel {
    id: string;
    bonus_id: string;
    bonus_name: string;
    bonus_status: BackpackItemStatus;
    created_at: string;
    marketing_code: string;
    serial_number?: string;
    uid: string;
    used_at?: string;
    value?: number;
    currency?: string;
    tool_label?: BackpackItemLabel;
}

type ViewModelKeys = keyof BackpackViewModel;
const createdKey: ViewModelKeys = 'created_at';
const usedKey: ViewModelKeys = 'used_at';

const secondsKey: TimestampKeys = 'seconds';

export type BackpackViewModelKeys =
    | keyof Omit<BackpackViewModel, 'created_at' | 'used_at' | 'currency' | 'id'>
    | `${typeof createdKey}.${typeof secondsKey}`
    | `${typeof usedKey}.${typeof secondsKey}`;
