import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, RootAction, RootState} from 'typesafe-actions';

import {map, mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {BoUser} from '@redux/entity';

import {requestEpic, requestWithConfirmEpic} from 'src/common/epics';
import {userProfileActions} from 'src/features/block-user-profile-actions/actions';
import {protectEpics} from '../../features/app/error-handling/epics';
import {successMessages} from '../../features/app/intl/shared-resources/serverResponse';
import {handleErrorResponseAction, showMessageAction} from '../../features/message-snack-bar/actions';
import {createGridEpics} from '../../features/module-shared/epics';
import {patchEpic} from '../../features/module-temp-storage/epics';
import {loadModulesAction} from '../../features/modules/actions';

import actions from './actions';
import {filterSelector} from './selectors';
import {domain, LoginAccessStatus, UserGridItem} from './types';

const usersEpics = createGridEpics<BoUser, UserGridItem>(domain, c => c.userService, filterSelector);

const itemAddEpic: RootEpic = requestEpic(actions.saveUser, (payload, _, container) => {
    const service = container.userService;
    return payload.isNew ? service.addItem(payload.item) : service.editItem(payload.item);
});

const reset2FAEpic: RootEpic = requestWithConfirmEpic(actions.reset2FA, (payload, _, container) => {
    return container.userService.reset2FA(payload);
});

const resetPasswordEpic: RootEpic = requestWithConfirmEpic(actions.resetPassword, (payload, _, container) => {
    return container.userService.resetPassword(payload);
});

const itemAddedEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(actions.saveUser.success)),
        mergeMap(() =>
            of(
                showMessageAction({message: successMessages.operationSuccessfullyCompleted}),
                actions.closeUserForm(),
                actions.contentUpdateRequired()
            )
        )
    );

const itemEditLoadedEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(actions.itemLoad.success)),
        map(action => actions.openUserForm(action.payload as BoUser))
    );

const getRolesEpic: RootEpic = requestEpic(actions.getRoles, (_, _state, container) => container.userService.getRoles());

const usersChangedEpic: Epic<RootAction, any, RootState> = action$ =>
    action$.pipe(
        filter(isActionOf(actions.contentUpdateRequired)),
        mergeMap(() => of(loadModulesAction.request()))
    );

const requestFailureEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(actions.saveUser.failure)),
        map(action => handleErrorResponseAction(action.payload))
    );

const setLoginAccessStatusEpic: RootEpic = patchEpic(actions.setLoginAccessStatus, (payload, _, container) => {
    const loginAccessStatus: LoginAccessStatus = (payload.item as boolean) ? LoginAccessStatus.Enable : LoginAccessStatus.Disable;
    return container.userService.setLoginAccessStatus(payload.id, loginAccessStatus);
});

const setLoginAccessStatusSuccessEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(actions.setLoginAccessStatus.success)),
        mergeMap(() => of(showMessageAction({message: successMessages.operationSuccessfullyCompleted}), actions.contentUpdateRequired()))
    );

//TODO: [BO-2903] Remove old actions usage after moving users management page to view feature
const updatePlayerIdSuccessEpic: RootEpic = action$ =>
    action$.pipe(
        filter(
            isActionOf([
                userProfileActions.updateAccessManagementPlayerId.success,
                userProfileActions.updateAccessManagementPlayerId.failure,
            ])
        ),
        map(() => actions.contentUpdateRequired())
    );

export default protectEpics(
    usersEpics,
    usersChangedEpic,
    itemAddEpic,
    itemAddedEpic,
    itemEditLoadedEpic,
    getRolesEpic,
    requestFailureEpic,
    reset2FAEpic,
    resetPasswordEpic,
    setLoginAccessStatusEpic,
    setLoginAccessStatusSuccessEpic,
    updatePlayerIdSuccessEpic
);
