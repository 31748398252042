import {createAsyncAction} from 'typesafe-actions';

import {MutationAddNoteArgs, Note, NoteInput} from '@models/generated/graphql';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

const domain = 'notes';

export type AddNoteRequestPayload = {
    note: NoteInput;
    files: FileList;
};

export type PinNoteRequestPayload = {
    id: string;
    note: NoteInput;
};

export type DownloadAttachmentRequestPayload = {
    fileId: string;
    fileName: string;
};

export const noteActions = {
    addNote: createAsyncAction(`${domain}/addNoteRequest`, `${domain}/addNoteSuccess`, `${domain}/addNoteFailure`)<
        AddNoteRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationAddNoteArgs>, Note>,
        ServiceResponsePayload<GqlMutationRequest<MutationAddNoteArgs>, Note>
    >(),
    pinNote: createAsyncAction(`${domain}/pinNoteRequest`, `${domain}/pinNoteSuccess`, `${domain}/pinNoteFailure`)<
        PinNoteRequestPayload,
        ServiceResponsePayload<GqlMutationRequest, Note>,
        ServiceResponsePayload<GqlMutationRequest, Note>
    >(),
    downloadAttachment: createAsyncAction(
        `${domain}/downloadAttachmentRequest`,
        `${domain}/downloadAttachmentSuccess`,
        `${domain}/downloadAttachmentFailure`
    )<
        DownloadAttachmentRequestPayload,
        ServiceResponsePayload<DownloadAttachmentRequestPayload, Blob>,
        ServiceResponsePayload<DownloadAttachmentRequestPayload, Blob>
    >(),
};
