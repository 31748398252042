import {GridColDef} from '@mui/x-data-grid';

import {PermissionEnum} from '@models/permissions';

import {useJurisdictionFeaturePermissions} from '../../../../../features/app/config/hooks';
import {PermissionEditableColDef} from '../../../../../features/module-temp-storage/components/editable-components/types';
import {FeatureColDef} from '../types';

//TODO: [BO-2721] Move useGridColumnConfiguration/useGridPermissions/useJurisdictionConfigForColumns to DataGridEntity
/**
 * @deprecated
 * <p>Should be removed</p>
 */
export const useJurisdictionConfigForColumns = (columns: GridColDef[]): PermissionEditableColDef[] => {
    return columns
        .map(c => {
            const {moduleName, submoduleName, featureName, permissions} = c as FeatureColDef;
            const limitedPermissions = useJurisdictionFeaturePermissions({moduleName, submoduleName, featureName, permissions});
            const updatedColDef = limitedPermissions?.length
                ? ({
                      ...c,
                      permissions: limitedPermissions,
                      isEditable: (c as PermissionEditableColDef).isEditable && limitedPermissions.some(p => p !== PermissionEnum.Read),
                  } as PermissionEditableColDef)
                : null;
            return updatedColDef;
        })
        .filter(c => c);
};
