import React from 'react';

import {ViewType} from '@redux/view';

import withModuleFilter, {FilterInnerProps} from 'src/features/shared/filter/components/ModuleFilterHoc';
import {useAgentFilter, useDefaultReferrerFilter} from '../hooks';

import {AgentFilterNew, AgentFilterValue} from './AgentFilterNew';

type AgentFilterInnerProps = FilterInnerProps<string | boolean, {withDownline?: boolean}>;

function AgentFilterInner({filter, value, onSubmit, options = {withDownline: true}}: AgentFilterInnerProps) {
    const viewType: ViewType = filter.options.viewType;

    const {boAgentPlayerId} = useDefaultReferrerFilter({viewType, onFilterChange: onSubmit});
    const {filterViewModel} = useAgentFilter({
        value,
        boAgentPlayerId,
    });

    function handleChange(value: AgentFilterValue) {
        onSubmit([
            {key: 'defaultReferrerPlayerId', value: boAgentPlayerId},
            {key: 'referrerPlayerId', value: value.agentId},
            {key: 'isDownstream', value: value.isDownstream},
        ]);
    }

    return (
        <AgentFilterNew
            value={{agentId: filterViewModel?.referrer_player_id, isDownstream: filterViewModel?.is_downstream}}
            onChange={handleChange}
            width="full"
            boAgentPlayerId={boAgentPlayerId}
            withDownstream={options.withDownline}
            viewType={viewType}
        />
    );
}

export const AgentFilterInternal = withModuleFilter(AgentFilterInner);
