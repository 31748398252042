import {EntityType} from '@redux/entity';

import {withBulkSummaryStep} from '../../block-bulk-actions';
import {DataGridUserProfileClient} from '../../block-user-profile-list';

export const BulkLabelSummaryStepModal = withBulkSummaryStep(
    DataGridUserProfileClient,
    ['uid', 'full_name', 'nickname'],
    EntityType.UserProfile
);
