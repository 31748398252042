import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Box} from '@mui/material';

import {GridColDef} from '@components/data-grid/mui';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {getNonEmptyValueValidator} from '@redux/view/utils';

import {Sorting} from 'src/common/types';
import {useBonusCodes} from 'src/features/block-bonus-code/hooks';
import {BulkBonusChooseActionStepButton} from 'src/features/block-player-bonus-bulk-actions/components/BulkBonusChooseActionStepButton';
import {useJurisdictionConfig} from '../../../features/app/config/hooks';
import {locationSearchSelector} from '../../../features/app/routing/selectors';
import {BeBonusColumnsConfiguration, domain as beBonusDomain} from '../../../features/be-bonus/types';
import {domain as entityDomain} from '../../../features/be-bonus-history/types';
import {withModule} from '../../../features/module-shared/components/ModuleHoc';
import ModulePage from '../../../features/module-shared/components/ModulePage';
import {useColumnConfiguration} from '../../../features/module-shared/hooks';
import {useRealtimeUpdates} from '../../../features/realtime-updates/hooks';
import {SubscriptionType} from '../../../features/realtime-updates/types';
import {useFilter} from '../../../features/shared/filter/hooks';
import {filterSelector, gridDataSelector} from '../selectors';
import {domain} from '../types';

import data from './BonusEngineDetails.config';
import {localizedBonusEngineDetails} from './BonusEngineDetails.localize';
import {useBonusEngineDetailsClasses} from './BonusEngineDetails.styles';

function useBonus(bonusId: string) {
    const {items} = useBonusCodes({
        fields: ['bonus_id', 'bonus_name', 'credit_type', 'release_metric', 'bonus_status'],
        validateFilter: getNonEmptyValueValidator('bonusId'),
        viewType: 'BonusEngineDetails',
        defaultFilters: [{key: 'bonusId', value: bonusId}],
        defaultPaging: {page: 1, pageSize: 1},
    });
    const bonusInfo = items?.[0];

    return {
        bonusInfo,
    };
}

function useBonusDetails(defaultSorting: Sorting<string>[]) {
    const urlSearch = useSelector(locationSearchSelector);
    const filterString = useSelector(filterSelector);
    const {searchFilter, resetFilter, handleFilterChange, handleSortChange} = useFilter(
        domain,
        filterString ?? urlSearch,
        null,
        defaultSorting,
        null,
        true
    );
    const bonusId = searchFilter.selectedItemId;
    const {bonusInfo} = useBonus(bonusId);
    const gridData = useSelector(gridDataSelector);

    useColumnConfiguration(beBonusDomain, [
        nameof<BeBonusColumnsConfiguration>(i => i.withBonusId),
        nameof<BeBonusColumnsConfiguration>(i => i.withBonusName),
        nameof<BeBonusColumnsConfiguration>(i => i.withCreditType),
        nameof<BeBonusColumnsConfiguration>(i => i.withReleaseMetric),
        nameof<BeBonusColumnsConfiguration>(i => i.withBonusStatus),
    ]);
    useRealtimeUpdates(entityDomain, domain, [SubscriptionType.Added]);

    useEffect(() => {
        if (bonusId && bonusInfo?.bonus_id !== bonusId) {
            handleFilterChange([{key: 'selected_item_id', value: bonusId}]);
            handleSortChange(defaultSorting);
        }
        return () => resetFilter();
    }, [bonusId]);

    return {
        bonusInfo,
        gridData,
    };
}

const BonusEngineDetails = () => {
    const {filters, getGridColumns, defaultSorting} = data;
    const {classes} = useBonusEngineDetailsClasses();
    const {currency} = useJurisdictionConfig();
    const {bonusInfo, gridData} = useBonusDetails(defaultSorting);

    const bonusName = bonusInfo?.bonus_name;
    const bonusId = bonusInfo?.bonus_id;
    const subHeader = bonusId && bonusName ? `${bonusId}: ${bonusName}` : '';

    return (
        <Box className={classes.bonusEngineDetailsContainer} data-testid="bonusEngineDetails">
            <ModulePage
                header={localizedBonusEngineDetails.bonusEngine}
                subHeader={subHeader}
                showBackButton={true}
                domain={domain}
                data={gridData}
                filters={filters}
                columns={getGridColumns(currency) as GridColDef[]}
                headerActions={[
                    {
                        component: BulkBonusChooseActionStepButton,
                    },
                ]}
                alignActionsRight={false}
                defaultSorting={defaultSorting}
            />
        </Box>
    );
};

export default withModule(BonusEngineDetails, [
    {
        moduleName: ModuleName.MarketingFunction,
        submoduleName: SubmoduleName.BonusEngine,
        permissions: [PermissionEnum.Read],
    },
]);
