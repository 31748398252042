import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {CasinoSupplier, CasinoSupplierFields, CasinoSupplierFilterKeys} from '@models/casino-game';
import {map} from '@otel';
import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {ICasinoCmsApiService} from '@services/rest-api';
import {ServerResponseStatus} from '@services/types';
import {getValueFromQueryFilter} from '@utils/query';

@injectable()
export class CasinoSupplierService implements IEntityReadService {
    private readonly _casinoCmsService: ICasinoCmsApiService;

    constructor(@inject(ServiceTypes.CasinoCmsApiService) casinoCmsService: ICasinoCmsApiService) {
        this._casinoCmsService = casinoCmsService;
    }

    public get(
        requestPayload: EntityFetchRequestPayload<CasinoSupplierFields>
    ): Observable<EntityFetchServiceResponsePayload<CasinoSupplier>> {
        let result: Observable<EntityFetchServiceResponsePayload<CasinoSupplier>>;
        try {
            const page = this.getNumberFromFilter(requestPayload.filter, 'page');
            const size = this.getNumberFromFilter(requestPayload.filter, 'size');
            result = this._casinoCmsService
                .getSuppliers({
                    code: getValueFromQueryFilter<CasinoSupplierFilterKeys, string>(requestPayload.filter, 'name'),
                    start: (page - 1) * size,
                    limit: size,
                })
                .pipe(
                    map(r => ({
                        ...r,
                        requestPayload,
                        responsePayload: r.responsePayload
                            ? {
                                  items: r.responsePayload.data?.map(i => i?.attributes),
                                  total: r.responsePayload.meta?.pagination?.total,
                              }
                            : null,
                    }))
                );
        } catch (e) {
            result = of({
                status: ServerResponseStatus.Failed,
                requestPayload,
                responsePayload: null,
                message: e.message,
                errors: null,
            });
        }

        return result;
    }

    private getNumberFromFilter(filter: string, key: CasinoSupplierFilterKeys): number {
        const filterValue = getValueFromQueryFilter<CasinoSupplierFilterKeys, string>(filter, key);
        const value = Number(filterValue);
        return isNaN(value) ? null : value;
    }
}
