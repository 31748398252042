import {MessageDescriptor} from 'react-intl';

export type EntityRealtimeState = {
    realtimeSubscribers: string[];
    enabledSubscriptionTypes: SubscriptionType[];
};

export type RealtimeSubscriber = {
    domain: string;
    types: SubscriptionType[];
};

export type RealtimeUpdatesMeta = {
    domain: string;
    realtimeKey: string;
    forceReload?: boolean;
    name?: MessageDescriptor;
};

export type GlobalRealtimeState = {
    pendingUpdates: RealtimeUpdatesMeta[];
};

export enum SubscriptionType {
    Added,
    Updated,
    Deleted,
}

export const RealtimeBufferTimeSpan = 2000;

export type SubscriptionError = {
    message: string;
};

export type SubscriptionErrorList = {
    errors?: SubscriptionError[];
    graphQLErrors?: SubscriptionError[];
};
