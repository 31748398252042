import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {localizedFeatureAccessStatus} from '../../app/intl/shared-resources/featureAccessStatus';

export type P2PFeatureStatusValue = {
    p2p_transfer_status: FeatureAccessStatus;
};

export const useP2PFeatureStatusFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: P2PFeatureStatusValue = JSON.parse(json);
    return value?.p2p_transfer_status ? [{key: formatMessage(localizedFeatureAccessStatus[value?.p2p_transfer_status])}] : null;
};
