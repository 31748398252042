import {injectable} from 'inversify';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';

import {IFileCreator, IFileFactory} from '@file/services/IFileFactory';
import {XlsxDocumentBuilder} from '@file/services/XlsxDocumentBuilder';
import {DataWorksheet, defaultExcelColWidth, ValidationWorksheet} from '@file/types';
import {ReasonCode, TransactionType} from '@models/generated/graphql';

import {creditReasonCodes, debitReasonCodes} from 'src/features/block-transaction-actions';
import {localizedReasonCode} from '../../app/intl/shared-resources/transactionReasonCode';
import {localizedTransactionType} from '../../app/intl/shared-resources/transactionType';
import {
    BulkDebitCreditAddModel,
    bulkTransactionsMaxTemplateLine,
    ManualTransactionParsedModel,
    ParseManualTransactionFileData,
} from '../types';

type ManualTransactionTemplateModel = {
    uid: string;
    transaction_type: TransactionType;
    amount: number | string;
    reason_code: ReasonCode;
    reference: string;
    comments: string;
};

export type ManualTransactionTemplateModelKeys = keyof ManualTransactionTemplateModel;

@injectable()
export class ManualTransactionExcelTemplateFactory
    implements IFileFactory<ParseManualTransactionFileData, BulkDebitCreditAddModel[]>, IFileCreator<null, TransactionType>
{
    private readonly builder = new XlsxDocumentBuilder();

    public parseFile({file, uid, type}: ParseManualTransactionFileData): Observable<BulkDebitCreditAddModel[]> {
        return this.builder.parseFile<ManualTransactionTemplateModelKeys>(file, this.getDataWorksheet(type).columns).pipe(
            map((parsedModels: ManualTransactionParsedModel[]) => {
                this.verifyType(parsedModels, type);
                return parsedModels.map<BulkDebitCreditAddModel>(parsedModel => this.mapParsedItemToInputModel(parsedModel, uid));
            })
        );
    }

    public createFile(_items: null, _keys: null, opts?: TransactionType): Observable<ArrayBuffer> {
        this.setValidationWorksheets(opts);
        this.setDataWorksheet(opts);
        const document = this.builder.getDocument();

        return from(document.writeBuffer());
    }

    private verifyType(parsedModels: ManualTransactionParsedModel[], type: TransactionType) {
        parsedModels.forEach(parsedModel => {
            if (this.getTransactionTypeByMessage(parsedModel.transaction_type) !== type) {
                throw new TypeError('Incorrect type');
            }
        });
    }

    private mapParsedItemToInputModel(parsedModel: ManualTransactionParsedModel, userId: string): BulkDebitCreditAddModel {
        const id = uuid();
        return {
            id: id,
            serverId: id,
            uid: parsedModel.uid,
            transaction_type: this.getTransactionTypeByMessage(parsedModel.transaction_type),
            amount: parsedModel.amount !== '' ? Number(parsedModel.amount) : null,
            reason: this.getReasonCodeByMessage(parsedModel.reason_code),
            reference: parsedModel.reference,
            comment: parsedModel.comments,
            rowIndex: Number(parsedModel.rowIndex),
            created_by_uid: userId,
        };
    }

    private getTransactionTypeByMessage(defaultMessage: string): TransactionType {
        return Object.values(TransactionType).find(
            (type: TransactionType) => localizedTransactionType[type].defaultMessage === defaultMessage
        );
    }

    private getReasonCodeByMessage(defaultMessage: string): ReasonCode {
        return Object.values(ReasonCode).find((code: ReasonCode) => localizedReasonCode[code].defaultMessage === defaultMessage);
    }

    private setValidationWorksheets(type: TransactionType) {
        const validationWorksheets = this.getValidationWorksheets(type);
        validationWorksheets.forEach(worksheet => this.setValidationWorksheet(worksheet));
    }

    private setValidationWorksheet(validationWorksheet: ValidationWorksheet) {
        const worksheetKey: string = validationWorksheet.title;
        const worksheetColumns = Object.values(validationWorksheet.columns);
        this.builder.setWorksheet(worksheetKey, {state: 'veryHidden'});
        this.builder.setColumns(worksheetKey, worksheetColumns);
        this.builder.setData(worksheetKey, validationWorksheet.data);
    }

    private setDataWorksheet(type: TransactionType) {
        const dataWorksheet: DataWorksheet<ManualTransactionTemplateModelKeys> = this.getDataWorksheet(type);
        const worksheetKey: string = dataWorksheet.title;
        const worksheetColumns = Object.values(dataWorksheet.columns);
        this.builder.setWorksheet(worksheetKey);
        this.builder.setColumns(worksheetKey, worksheetColumns);
        this.builder.setHeaderStyles(worksheetKey);
        this.builder.setValidation(worksheetKey, worksheetColumns, bulkTransactionsMaxTemplateLine);
    }

    private getValidationWorksheets(type: TransactionType): ValidationWorksheet[] {
        return [this.getReasonCodeValidationWorksheet(type), this.getTransactionTypeValidationWorksheet(type)];
    }

    private getReasonCodeValidationWorksheet(type: TransactionType): ValidationWorksheet {
        const mapper: Partial<Record<TransactionType, ReasonCode[]>> = {
            [TransactionType.Credit]: creditReasonCodes,
            [TransactionType.Debit]: debitReasonCodes,
        };
        return {
            title: nameof(ReasonCode),
            columns: {
                validationField: {key: 'validationField'},
            },
            data: mapper[type].map(c => ({validationField: localizedReasonCode[c].defaultMessage})),
        };
    }

    private getTransactionTypeValidationWorksheet(type: TransactionType): ValidationWorksheet {
        return {
            title: nameof(TransactionType),
            columns: {
                validationField: {key: 'validationField'},
            },
            data: [{validationField: localizedTransactionType[type].defaultMessage as string}],
        };
    }

    private getDataWorksheet(type: TransactionType): DataWorksheet<ManualTransactionTemplateModelKeys> {
        let direction: string;
        let reasonCodesLength: number;
        if (type === TransactionType.Credit) {
            direction = 'Credit';
            reasonCodesLength = creditReasonCodes.length;
        } else {
            direction = 'Debit';
            reasonCodesLength = debitReasonCodes.length;
        }
        return {
            title: 'Manual Transactions',
            columns: {
                uid: {key: 'uid', header: 'UID', colIndex: 1},
                transaction_type: {
                    key: 'transaction_type',
                    header: 'Direction',
                    validation: {
                        type: 'list',
                        formulae: [`'${nameof(TransactionType)}'!$A$1:$A$1`],
                        showErrorMessage: true,
                        error: `Only ${direction} type is supported`,
                        errorStyle: 'error',
                        errorTitle: 'Transaction type in invalid',
                    },
                    width: defaultExcelColWidth * 4,
                    colIndex: 2,
                },
                amount: {
                    key: 'amount',
                    header: 'Amount',
                    validation: {
                        type: 'decimal',
                        operator: 'greaterThan',
                        formulae: [0],
                        showErrorMessage: true,
                        error: 'Amount should be a number greater than 0',
                        errorStyle: 'error',
                        errorTitle: 'Amount is invalid',
                    },
                    colIndex: 3,
                },
                reason_code: {
                    key: 'reason_code',
                    header: 'Reason Codes',
                    validation: {
                        type: 'list',
                        formulae: [`'${nameof(ReasonCode)}'!$A$1:$A$${reasonCodesLength}`],
                        showErrorMessage: true,
                        error: 'Select valid Reason Code from the list',
                        errorStyle: 'error',
                        errorTitle: 'Reason Code in invalid',
                    },
                    width: defaultExcelColWidth * 4,
                    colIndex: 4,
                },
                reference: {key: 'reference', header: 'Reference', width: defaultExcelColWidth * 5, colIndex: 5},
                comments: {key: 'comments', header: 'Comments', width: defaultExcelColWidth * 5, colIndex: 6},
            },
        };
    }
}
