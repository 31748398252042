import React, {ComponentType, useContext, useEffect, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Switch} from 'react-router';
import {Redirect, useLocation} from 'react-router-dom';
import {Tab, Tabs as MaterialUiTabs} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {ReduxLinkText} from '@components/link';
import {CustomTheme} from '@style';

import ProtectedRoute from 'src/features/protected-route/components/ProtectedRoute';

import {RoutingTabsContext} from './RoutingTabsProvider';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    tabListTab: {
        minWidth: 'auto',

        '& .MuiTab-wrapper': {
            justifyContent: 'stretch',
            height: '100%',
        },
    },
    tabListTabLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textDecoration: 'none',
        padding: theme.spacing(0, 2),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
    },
    tabListTabMargin: {
        marginBottom: theme.spacing(2.5),
    },
}));

export type Tab = {
    title: MessageDescriptor;
    component: ComponentType;
    path: string;
    id: string;
};

type TabsProps = {
    tabs: Tab[];
};

export type RoutingTabsContextProps = {
    saveTab: (tab: Tab) => void;
    findTab: (paths: string[]) => Tab;
    removeTab: (tab: Tab) => void;
};

export function RoutingTabs({tabs}: TabsProps) {
    const {classes} = useClasses();
    const location = useLocation();
    const tabsContext = useContext(RoutingTabsContext);
    const [selectedTab, setSelectedTab] = useState<Tab>(getSelectedTab());

    useEffect(() => {
        setSelectedTab(getSelectedTab());
    }, [location.pathname]);

    useEffect(() => {
        if (selectedTab) {
            tabsContext.saveTab(selectedTab);
        }
    }, [selectedTab.id]);

    function handleChange(_: React.ChangeEvent<{}>, newValue: string) {
        const tab = tabs?.find(t => t.id === newValue);
        if (!tab) {
            return;
        }

        setSelectedTab(tab);
        tabsContext.removeTab(selectedTab);
    }

    function getSelectedTab() {
        let selectedTab = tabs.find(tab => tab.path === location.pathname);

        if (!selectedTab) {
            selectedTab = tabsContext.findTab(tabs.map(t => t.path));
        }

        if (!selectedTab) {
            selectedTab = tabs[0];
        }

        return selectedTab;
    }

    return (
        <>
            <MaterialUiTabs value={selectedTab?.id} onChange={handleChange} className={classes.tabListTabMargin}>
                {tabs?.map(tab => {
                    return (
                        <Tab
                            data-testid={`tab-${tab.id}`}
                            disableFocusRipple
                            disableRipple
                            className={classes.tabListTab}
                            style={{maxWidth: `${100 / tabs.length}%`}}
                            key={`${tab.path}`}
                            value={tab?.id}
                            label={
                                <ReduxLinkText
                                    path={tab.path}
                                    title={tab.title}
                                    className={classes.tabListTabLabel}
                                    onClick={e => e.stopPropagation()}
                                ></ReduxLinkText>
                            }
                        />
                    );
                })}
            </MaterialUiTabs>
            {selectedTab?.path && selectedTab?.path !== location.pathname ? <Redirect from="*" to={selectedTab?.path} /> : null}
            <Switch>
                {tabs.map((tabComponent, index) => (
                    <ProtectedRoute key={index} exact path={`${tabComponent.path}`} component={tabComponent.component} />
                ))}
            </Switch>
        </>
    );
}
