import {defineMessages} from 'react-intl';

export const localizedBonusEngineForm = defineMessages({
    submit: {
        id: 'BEF_submit',
        defaultMessage: 'Submit',
    },
    close: {
        id: 'BEF_close',
        defaultMessage: 'Close',
    },
});
