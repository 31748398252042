import {combineReducers} from 'redux';

import {getContentItemsPageReducer, getContentLoadingReducer, getGridModuleItemReducer} from '../../../features/module-shared/reducers';
import {getFilterReducer} from '../../../features/shared/filter/reducers';

import {domain} from './types';

const createLegalHistoryReducerReducer = () => {
    const combined = combineReducers({
        data: combineReducers({
            ...getContentItemsPageReducer(domain),
            ...getGridModuleItemReducer(domain),
        }),
        state: combineReducers({
            ...getContentLoadingReducer(domain),
            ...getFilterReducer(domain),
        }),
    });

    return combined;
};

export const legalHistoryReducer = createLegalHistoryReducerReducer();
