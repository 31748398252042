import {gql} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {UserAction} from '@models/generated/graphql';

import {IRealtimeService} from '../../realtime-updates/services/IRealtimeService';
import {RealtimeSubscriptionService} from '../../realtime-updates/services/RealtimeUpdatesService';

export class UserActionRealtimeService extends RealtimeSubscriptionService implements IRealtimeService<UserAction> {
    subscribeToCreate(): Observable<UserAction> {
        return super.createSubscription(this.getItemAddedSubscriptionQuery()).pipe(map(res => res.userActionAdded));
    }

    private getItemAddedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnUserActionAdded {
                userActionAdded {
                    id
                    action_type
                    action_by_uid
                    player_uid
                    trigger_at {
                        seconds
                    }
                    prev_value
                    new_value
                }
            }
        `;
    }
}
