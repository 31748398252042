import {constructUsing, createMap, forMember, mapFrom, Mapper, mapWith} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {PlayersRelation, UserProfile} from '@models/generated/graphql';
import {UserProfileViewModel} from '@models/user-profile/types';
import {PlayersRelationQueryFields} from '@redux/entity';

import {PlayersRelationViewModel, PlayersRelationViewModelKeys} from './types';

export class PlayersRelationMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            PlayersRelation,
            PlayersRelationViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.created_at,
                mapFrom(m => m.created_at)
            ),
            forMember(
                vm => vm.player,
                mapWith(UserProfileViewModel, UserProfile, m => m.player)
            ),
            forMember(
                vm => vm.related_player,
                mapWith(UserProfileViewModel, UserProfile, m => m.related_player)
            ),
            forMember(
                vm => vm.relation_type,
                mapFrom(m => m.relation_type)
            ),
            forMember(
                vm => vm.relevance,
                mapFrom(m => m.relevance)
            ),
            forMember(
                vm => vm.security_case_types,
                mapFrom(m => m.security_case_types)
            )
        );
        createMap<PlayersRelationViewModelKeys[], PlayersRelationQueryFields[]>(
            mapper,
            nameof<PlayersRelationViewModelKeys>(),
            nameof<PlayersRelationQueryFields>(),
            constructUsing(m => {
                const mapper: Record<PlayersRelationViewModelKeys, PlayersRelationQueryFields[]> = {
                    id: ['id'],
                    created_at: ['created_at'],
                    player: ['player'],
                    related_player: ['related_player'],
                    relation_type: ['relation_type'],
                    relevance: ['relevance'],
                    security_case_types: ['security_case_types'],
                    'related_player.uid': ['related_player.uid'],
                    'related_player.username': ['related_player.username'],
                    'related_player.security_cases': ['related_player.security_cases'],
                };

                return [...new Set(m.flatMap(field => mapper[field]))];
            })
        );
    }
}
