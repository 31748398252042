import React from 'react';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {EntityType} from '@redux/entity';

import {usePerformKYCActions} from 'src/features/block-account-verification-bulk-actions';
import {PerformActionData} from 'src/features/block-bulk-actions/types';
import {
    BulkActionKey,
    BulkStepExecutionProps,
    BulkStepSummaryContentChipList,
    BulkStepSummaryContentProps,
    PerformNoteStrategy,
    usePerformBulkOperation,
    usePerformMultipleRequests,
    UserProfileActionItem,
    withBulkStepExecutionSummary,
} from '../../block-bulk-actions';
import {
    PerformAccountLockStrategy,
    PerformCasinoLockStrategy,
    PerformDepositLockStrategy,
    PerformLobbyLockStrategy,
    PerformP2PLockStrategy,
    PerformSecurityCasesStrategy,
    PerformSendMessageStrategy,
    PerformSportsbookLockStrategy,
    PerformWithdrawalLockStrategy,
} from '../services/performStrategy';

function BulkStepSummaryChipList(props: BulkStepSummaryContentProps) {
    return <BulkStepSummaryContentChipList {...props} getChipValue={item => (item.value as UserProfileActionItem).uid} />;
}

const BulkStepPlayerSummary = withBulkStepExecutionSummary(BulkStepSummaryChipList);

export function usePerformUserProfileActions(): Partial<Record<BulkActionKey, PerformActionData>> {
    return {
        P2PTransferLock: usePerformBulkOperation<PerformP2PLockStrategy>(
            ServiceTypes.PerformP2PLockStrategy,
            BulkActionKey.P2PTransferLock
        ),
        security_cases_add: usePerformMultipleRequests<PerformSecurityCasesStrategy>(
            ServiceTypes.PerformSecurityCasesStrategy,
            BulkActionKey.SecurityCasesAdd
        ),
        security_cases_update: usePerformMultipleRequests<PerformSecurityCasesStrategy>(
            ServiceTypes.PerformSecurityCasesStrategy,
            BulkActionKey.SecurityCasesUpdate
        ),
        security_cases_remove: usePerformMultipleRequests<PerformSecurityCasesStrategy>(
            ServiceTypes.PerformSecurityCasesStrategy,
            BulkActionKey.SecurityCasesRemove
        ),
        message: usePerformMultipleRequests<PerformSendMessageStrategy>(ServiceTypes.PerformSendMessageStrategy, BulkActionKey.Message),
        notes_attachments: usePerformMultipleRequests<PerformNoteStrategy>(
            ServiceTypes.PerformNoteStrategy,
            BulkActionKey.NotesAndAttachments
        ),
        account_lock: usePerformMultipleRequests<PerformAccountLockStrategy>(
            ServiceTypes.PerformAccountLockStrategy,
            BulkActionKey.AccountLock
        ),
        withdrawal_lock: usePerformMultipleRequests<PerformWithdrawalLockStrategy>(
            ServiceTypes.PerformWithdrawalLockStrategy,
            BulkActionKey.WithdrawalLock
        ),
        deposit_lock: usePerformMultipleRequests<PerformDepositLockStrategy>(
            ServiceTypes.PerformDepositLockStrategy,
            BulkActionKey.DepositLock
        ),
        lobby_lock: usePerformMultipleRequests<PerformLobbyLockStrategy>(ServiceTypes.PerformLobbyLockStrategy, BulkActionKey.LobbyLock),
        CasinoLock: usePerformBulkOperation<PerformCasinoLockStrategy>(ServiceTypes.PerformCasinoLockStrategy, BulkActionKey.CasinoLock),
        SportsbookLock: usePerformBulkOperation<PerformSportsbookLockStrategy>(
            ServiceTypes.PerformSportsbookLockStrategy,
            BulkActionKey.SportsbookLock
        ),
    };
}

export function BulkUserProfileExecutionStepModal({onNext, hasRetry}: BulkStepExecutionProps) {
    const performUserProfileActions = usePerformUserProfileActions();
    const performKYCActions = usePerformKYCActions();

    return (
        <BulkStepPlayerSummary
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.UserProfile}
            actionsData={[...Object.values(performUserProfileActions), performKYCActions.initPaymentKYC]}
        />
    );
}
