import React, {memo} from 'react';
import {MessageDescriptor} from 'react-intl';
import {FormControlLabel} from '@mui/material';
import Switch from '@mui/material/Switch';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {IModuleGridItem} from '@components/data-grid';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

import {MessageType, PatchAsyncAction} from '../../types';
import {EditableColDef} from '../editable-components/types';

import {EditableProps, EditStateTrigger, withEditable} from './EditableHoc';
import {getEditDisabledState, getGridRowData} from './utils';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    editableToggleSwitch: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1.5),
    },
}));

type EditableToggleOptions = {
    enabledLabel: string | MessageDescriptor;
    disabledLabel: string | MessageDescriptor;
};

export const EditableToggle = memo(
    withEditable(({value, onChange, disabled, options}: EditableProps<boolean, EditableToggleOptions>) => {
        const changeValue = (e: React.BaseSyntheticEvent) => {
            onChange(e.target.checked as boolean);
        };
        const label = value ? options.enabledLabel : options.disabledLabel;
        const {classes} = useClasses();

        return (
            <FormControlLabel
                control={
                    <Switch
                        data-testid="editableToggle"
                        checked={value}
                        disabled={disabled}
                        onChange={changeValue}
                        value={value}
                        className={classes.editableToggleSwitch}
                        color="secondary"
                    />
                }
                label={<LocalizedText label={label}></LocalizedText>}
            />
        );
    })
);

export const renderEditableToggleCell =
    <T extends IModuleGridItem>(
        saveAction: PatchAsyncAction,
        enabledLabel: string | MessageDescriptor,
        disabledLabel: string | MessageDescriptor,
        isReadonly?: (model: T) => boolean
    ) =>
    (params: GridCellParams) => {
        const {model, defaultValue, field, id, typeName} = getGridRowData<T>(params);
        const isEditable = (params.colDef as EditableColDef)?.isEditable;
        const isEditDisabled = getEditDisabledState(model, isEditable, isReadonly);

        return (
            <EditableToggle
                model={model}
                value={defaultValue}
                action={saveAction}
                messageType={MessageType.FieldMessage}
                disabled={isEditDisabled}
                field={field}
                typeName={typeName}
                id={id}
                editStateTrigger={EditStateTrigger.WrappedComponent}
                options={{enabledLabel, disabledLabel}}
            />
        );
    };
