import React from 'react';
import {defineMessages} from 'react-intl';

import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {BulkActionKey, BulkChooseActionStepLockGroup} from '../../block-bulk-actions';

import {BulkUserProfileAccountLock} from './BulkUserProfileAccountLock';
import {BulkUserProfileCasinoLock} from './BulkUserProfileCasinoLock';
import {BulkUserProfileDepositLock} from './BulkUserProfileDepositLock';
import {BulkUserProfileLobbyLock} from './BulkUserProfileLobbyLock';
import {BulkUserProfileSportsbookLock} from './BulkUserProfileSportsbookLock';
import {BulkUserProfileWithdrawalLock} from './BulkUserProfileWithdrawalLock';

const localized = defineMessages({
    bulkAccountLock: {
        id: 'BulkStepChooseActions_AccountLock',
        defaultMessage: 'Account / Financial Lock',
    },
});

type AccountLocksProps = {actionKeys: BulkActionKey[]};

function AccountLocks({actionKeys}: AccountLocksProps) {
    const groupActionKeys = [
        BulkActionKey.AccountLock,
        BulkActionKey.WithdrawalLock,
        BulkActionKey.DepositLock,
        BulkActionKey.LobbyLock,
        BulkActionKey.CasinoLock,
        BulkActionKey.SportsbookLock,
    ];
    return groupActionKeys.some(key => actionKeys.includes(key)) ? (
        <BulkChooseActionStepLockGroup title={localized.bulkAccountLock}>
            {actionKeys.includes(BulkActionKey.AccountLock) ? <BulkUserProfileAccountLock /> : null}
            {actionKeys.includes(BulkActionKey.WithdrawalLock) ? <BulkUserProfileWithdrawalLock /> : null}
            {actionKeys.includes(BulkActionKey.DepositLock) ? <BulkUserProfileDepositLock /> : null}
            {actionKeys.includes(BulkActionKey.LobbyLock) ? <BulkUserProfileLobbyLock /> : null}
            {actionKeys.includes(BulkActionKey.CasinoLock) ? <BulkUserProfileCasinoLock /> : null}
            {actionKeys.includes(BulkActionKey.SportsbookLock) ? <BulkUserProfileSportsbookLock /> : null}
        </BulkChooseActionStepLockGroup>
    ) : null;
}

export const BulkUserProfileAccountLockGroup = withMultiplePermission(AccountLocks, {
    allowedPermissions: [
        {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.AccountActions, permissions: [PermissionEnum.Update]},
        {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.FinanceActions, permissions: [PermissionEnum.Update]},
        {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.GamesActions, permissions: [PermissionEnum.Update]},
    ],
});
