import {ActionType, createAction} from 'typesafe-actions';

import {ErrorPayload} from './type';

const domain = 'error-handling';

export const errorActions = {
    error: createAction(`${domain}/error`)<ErrorPayload>(),
};

export type ErrorHandlingActions = ActionType<typeof errorActions>;
