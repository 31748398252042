import React from 'react';

import {CountryFilterAdapter} from '../../block-country';

import {TransactionFilterProps} from './TransactionFilters';

function TransactionFilterCountry({filter, onFilterChange}: TransactionFilterProps) {
    return <CountryFilterAdapter onFilterChange={onFilterChange} domain={null} filter={null} filterString={filter} />;
}

export default TransactionFilterCountry;
