import {MessageDescriptor} from 'react-intl';
import {GridCellParams, GridValueGetterParams} from '@mui/x-data-grid';

import {renderChip} from '@components/chip/ChipRenderer';
import {gridCellDefaultFormatter, PermissionColDef, renderCellHeader, renderCellWithTooltip} from '@components/data-grid/mui';
import {EnablementMode} from '@config/enablementMode';
import {DateRange, Gender, UserAccountStatus} from '@models/generated/graphql';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {formatTimestamp} from '@utils';

import {Sorting} from 'src/common/types';
import {JurisdictionSelectOption} from '../../../features/module-shared/types';
import {renderEditableTextCell} from '../../../features/module-temp-storage/components/editable/EditableInput';
import {renderEditablePhoneCell} from '../../../features/module-temp-storage/components/editable/EditablePhone';
import {renderEditableToggleCell} from '../../../features/module-temp-storage/components/editable/EditableToggle';
import {EditableColDef, PermissionEditableColDef} from '../../../features/module-temp-storage/components/editable-components/types';
import {ModuleFilterDateRangeObsolete} from '../../../features/shared/filter/components/date-range-picker/FilterDateRangePickerObsolete';
import FilterTextWithDropdown from '../../../features/shared/filter/components/FilterTextWithDropdown';
import {Filter, FilterPlacement, MultipleKeysFilter} from '../../../features/shared/filter/types';
import {getDateRangeFilterKey} from '../../../features/shared/filter/utils';
import {registrationInfoFullSearch} from '../../../features/user-profile/types';
import actions from '../actions';
import {domain, PlayerProfileGridItem} from '../types';

import {localizedRegistrationInfo} from './RegistrationInfo.localize';
import {RegistrationInfoActions} from './RegistrationInfoActions';

const getGridColumns = () => {
    const columns: EditableColDef[] = [
        {
            field: nameof<PlayerProfileGridItem>(u => u.id),
            hide: true,
        },
        {
            field: nameof<PlayerProfileGridItem>(u => u.uid),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.id),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(i => i.uid),
        },
        {
            field: nameof<PlayerProfileGridItem>(u => u.username),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.userName),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(i => i.username),
        },
        {
            field: nameof<PlayerProfileGridItem>(u => u.first_name),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.firstName),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(u => u.first_name),
        },
        {
            field: nameof<PlayerProfileGridItem>(u => u.last_name),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.lastName),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(u => u.last_name),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.contact.email),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.email),
            valueGetter: (params: GridCellParams) => {
                const contactInfo = (params.row as PlayerProfileGridItem).contact;
                return contactInfo?.email ?? '';
            },
            renderCell: renderEditableTextCell(actions.editEmail),
            isEditable: true,
            domain: domain,
            action: actions.editEmail,
            submitAction: actions.editEmail,
            idField: nameof<PlayerProfileGridItem>(u => u.uid),
            width: 350,
            permissions: [PermissionEnum.Update],
            moduleName: ModuleName.PlayerManagement,
            submoduleName: SubmoduleName.RegistrationInformation,
        } as PermissionEditableColDef,
        {
            field: nameof<PlayerProfileGridItem>(u => u.ip_addresses),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.ipAddress),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(u => u?.ip_addresses?.[0]),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.contact.mobile),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.phone),
            valueGetter: (params: GridCellParams) => {
                const contactInfo = (params.row as PlayerProfileGridItem).contact;
                return {area: contactInfo?.mobile?.area ?? '', mobile: contactInfo?.mobile?.mobile ?? ''};
            },
            renderCell: renderEditablePhoneCell(actions.editPhone),
            width: 350,
            permissions: [PermissionEnum.Update],
            moduleName: ModuleName.PlayerManagement,
            submoduleName: SubmoduleName.RegistrationInformation,
        } as PermissionEditableColDef,
        {
            field: nameof<PlayerProfileGridItem>(u => u.gender),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.gender),
            renderCell: params => renderChip<Gender>(params.value as Gender, nameof<Gender>()),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.date_of_joining.seconds),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.registrationDate),
            type: 'dateTime',
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(i => formatTimestamp(i.date_of_joining, 'date-time')),
            sortField: nameof.full<PlayerProfileGridItem>(g => g.date_of_joining.seconds),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.address.country_info.name),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.country),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(u => u.address?.country_info?.name),
        },
        {
            field: nameof<PlayerProfileGridItem>(u => u.account_status),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.loginStatus),
            renderCell: params => renderChip<UserAccountStatus>(params.value as UserAccountStatus, nameof<UserAccountStatus>()),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.birthday.seconds),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.birthDate),
            type: 'dateTime',
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(i => formatTimestamp(i.birthday, 'date-time')),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.address.post_code),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.postCode),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(u => (u.address?.post_code ? u.address.post_code : null)),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.address.address),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.address),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(u => (u.address?.address ? u.address.address : null)),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.address.city),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.city),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(u => (u.address?.city ? u.address.city : null)),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.address.state),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.state),
            valueFormatter: gridCellDefaultFormatter<PlayerProfileGridItem>(u => (u.address?.state ? u.address.state : null)),
        },
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.security_settings.is_2fa_enabled),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.twoFA),
            valueGetter: (params: GridValueGetterParams) => (params.row as PlayerProfileGridItem).security_settings?.is_2fa_enabled,

            renderCell: renderEditableToggleCell(
                actions.disableTwoFA,
                localizedRegistrationInfo.enabled,
                localizedRegistrationInfo.disabled,
                (r: PlayerProfileGridItem) => !r.security_settings?.is_2fa_enabled
            ),
            sortable: true,
            width: 300,
            permissions: [PermissionEnum.Update],
            moduleName: ModuleName.PlayerManagement,
            submoduleName: SubmoduleName.RegistrationInformation,
        } as PermissionEditableColDef,
        {
            field: nameof.full<PlayerProfileGridItem>(u => u.security_settings.is_challenge_questions_enabled),
            renderHeader: () => renderCellHeader(localizedRegistrationInfo.hasChallengeQuestions),
            valueGetter: (params: GridCellParams) => {
                return (params.row as PlayerProfileGridItem).security_settings?.is_challenge_questions_enabled;
            },
            renderCell: params => {
                const isEnabled = params.value;
                const type = isEnabled === null ? EnablementMode.Null : isEnabled ? EnablementMode.Enabled : EnablementMode.Disabled;

                return renderChip<EnablementMode>(type as EnablementMode, nameof<EnablementMode>());
            },
        },
        {
            field: 'actions',
            headerName: ' ',
            renderCell: RegistrationInfoActions,
            width: 75,
            sortable: false,
        },
    ];

    const defaultCol: PermissionColDef = {
        field: '',
        width: 200,
        permissions: [PermissionEnum.Read],
        moduleName: ModuleName.PlayerManagement,
        submoduleName: SubmoduleName.RegistrationInformation,
        renderCell: renderCellWithTooltip,
    };

    return columns.map(c => ({
        ...defaultCol,
        ...c,
    }));
};

const textSearchOptions: JurisdictionSelectOption[] = [
    {label: localizedRegistrationInfo.textFullFilter, value: registrationInfoFullSearch},
    {label: localizedRegistrationInfo.userIdFilter, value: nameof<PlayerProfileGridItem>(i => i.uid)},
    {label: localizedRegistrationInfo.usernameFilter, value: nameof<PlayerProfileGridItem>(i => i.username)},
    {label: localizedRegistrationInfo.firstNameFilter, value: nameof<PlayerProfileGridItem>(w => w.first_name)},
    {label: localizedRegistrationInfo.lastNameFilter, value: nameof<PlayerProfileGridItem>(w => w.last_name)},
    {
        label: localizedRegistrationInfo.emailFilter,
        value: nameof.full<PlayerProfileGridItem>(w => w.contact.email),
    },
    {label: localizedRegistrationInfo.countryFilter, value: nameof.full<PlayerProfileGridItem>(i => i.address.country_info.name)},
];

const getFilterLabel = (key: string): MessageDescriptor => {
    return {
        [registrationInfoFullSearch]: localizedRegistrationInfo.textFullFilterPlaceholder,
        [nameof<PlayerProfileGridItem>(i => i.uid)]: localizedRegistrationInfo.userIdFilterPlaceholder,
        [nameof<PlayerProfileGridItem>(i => i.username)]: localizedRegistrationInfo.usernameFilterPlaceholder,
        [nameof<PlayerProfileGridItem>(w => w.first_name)]: localizedRegistrationInfo.firstNameFilterPlaceholder,
        [nameof<PlayerProfileGridItem>(w => w.last_name)]: localizedRegistrationInfo.lastNameFilterPlaceholder,
        [nameof.full<PlayerProfileGridItem>(w => w.contact.email)]: localizedRegistrationInfo.emailFilterPlaceholder,
        [nameof.full<PlayerProfileGridItem>(i => i.address.country_info.name)]: localizedRegistrationInfo.countryFilterPlaceholder,
    }[key];
};

const getFilters = () =>
    [
        {
            keys: [
                registrationInfoFullSearch,
                nameof<PlayerProfileGridItem>(i => i.uid),
                nameof<PlayerProfileGridItem>(i => i.username),
                nameof<PlayerProfileGridItem>(w => w.first_name),
                nameof<PlayerProfileGridItem>(w => w.last_name),
                nameof.full<PlayerProfileGridItem>(w => w.contact.email),
                nameof.full<PlayerProfileGridItem>(w => w.address.country_info.name),
            ],
            component: FilterTextWithDropdown,
            options: textSearchOptions,
            placement: FilterPlacement.Primary,
            getLabel: getFilterLabel,
        } as MultipleKeysFilter,
        {
            key: [
                getDateRangeFilterKey(
                    nameof<PlayerProfileGridItem>(u => u.date_of_joining),
                    nameof<DateRange>(d => d.from)
                ),
                getDateRangeFilterKey(
                    nameof<PlayerProfileGridItem>(u => u.date_of_joining),
                    nameof<DateRange>(d => d.to)
                ),
            ],
            component: ModuleFilterDateRangeObsolete,
            placement: FilterPlacement.Secondary,
            options: nameof<PlayerProfileGridItem>(u => u.date_of_joining),
        },
    ] as Filter[];

const defaultSorting: Sorting<string>[] = [{field: nameof.full<PlayerProfileGridItem>(g => g.date_of_joining.seconds), sort: 'desc'}];

export default {
    filters: getFilters(),
    columns: getGridColumns(),
    localized: localizedRegistrationInfo,
    defaultSorting,
};
