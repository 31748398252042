import React from 'react';
import {Box} from '@mui/material';

import {useAutocompleteClasses} from '@components/autocomplete';
import {CustomIcon} from '@components/icons';
import {FormTextInputCleanable} from '@components/input';
import {FormTextInputCleanableProps} from '@components/input/FormInput';

type FormAutocompleteInputProps = Pick<FormTextInputCleanableProps<string>, 'value' | 'onBlur' | 'clear' | 'placeholder' | 'onKeyDown'> & {
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
};

export function FormAutocompleteInput({value, onInputChange, onBlur, clear, placeholder, onKeyDown}: FormAutocompleteInputProps) {
    const {classes: styles} = useAutocompleteClasses();

    return (
        <FormTextInputCleanable
            startAdornment={<Box component="span" className={`${CustomIcon.Search} ${styles.autocompleteInputAdornment}`} />}
            className={styles.autocompleteInput}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e, e.target.value)}
            onBlur={onBlur}
            clear={clear}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
        />
    );
}
