import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {AccountVerificationViewModel} from '@models/account-verification';
import {AccountVerification} from '@models/generated/graphql';
import {AccountVerificationFilterKeys, AccountVerificationQueryFields, EntityFetchRequestPayload, EntityType, Filter} from '@redux/entity';
import {mapViewFilterToString} from '@redux/view';
import {IEntityReadService} from '@services/entity';

import {IBulkStrategy} from '../../block-bulk-actions';

export type LoadAccountVerificationItemsStrategyRequest = {
    ids: string[];
    fields: AccountVerificationQueryFields[];
};

export type LoadAccountVerificationItemsStrategyResponse = AccountVerificationViewModel[];

@injectable()
export class LoadAccountVerificationItemsStrategy
    implements IBulkStrategy<LoadAccountVerificationItemsStrategyRequest, LoadAccountVerificationItemsStrategyResponse>
{
    private _accountVerificationService: IEntityReadService;
    private _mapper: Mapper;

    constructor(
        @inject(ServiceTypes.AccountVerificationService) accountVerificationService: IEntityReadService,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        this._accountVerificationService = accountVerificationService;
        this._mapper = mapper;
    }

    process({ids, fields}: LoadAccountVerificationItemsStrategyRequest): Observable<LoadAccountVerificationItemsStrategyResponse> {
        return this._accountVerificationService.get(this.getParsedAccountVerificationsPayload(ids, fields)).pipe(
            map(value => {
                const accountVerifications = value.responsePayload.items as AccountVerification[];
                return accountVerifications.map(accountVerification => {
                    return this._mapper.map(accountVerification, AccountVerification, AccountVerificationViewModel);
                });
            })
        );
    }

    private getParsedAccountVerificationsPayload(ids: string[], fields: AccountVerificationQueryFields[]): EntityFetchRequestPayload {
        const filter: Filter<AccountVerificationFilterKeys> = {
            id: ids
                .filter(u => u)
                .map(i => `"${i}"`)
                .join(' '),
            size: ids.length,
            page: 1,
        };

        return {
            type: EntityType.AccountVerification,
            fields,
            filter: mapViewFilterToString<AccountVerificationFilterKeys>(filter, ['id', 'size', 'page']),
        };
    }
}
