import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useClasses = makeStyles()((theme: CustomTheme) => {
    const customContent = theme.custom.palette.content;
    return {
        attachmentItem: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            minHeight: 60,
            marginBottom: theme.spacing(1.25),
            padding: theme.spacing(1.25, 2),
            backgroundColor: customContent.backgroundLight,
            borderRadius: theme.shape.borderRadius * 1.5,
        },
        attachmentIcon: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: 32,
            height: 40,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${customContent.border}`,
            '&::before, &::after': {
                content: '""',
                zIndex: 2,
                position: 'absolute',
                top: '-1px',
                right: '-1px',
                borderStyle: 'solid',
                borderWidth: theme.spacing(0, 1, 1, 0),
                borderColor: `transparent ${customContent.backgroundLight} transparent transparent`,
            },
            '&::after': {
                zIndex: 0,
                top: 0,
                right: 0,
                borderRightColor: customContent.border,
            },
        },
        attachmentType: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: 32,
            fontSize: theme.typography.caption.fontSize,
        },
        attachmentName: {
            maxWidth: 150,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginBottom: 6,
            ...(theme.typography.body1 as CSSObject),
            color: theme.palette.text.primary,
        },
        attachmentBtn: {
            width: 28,
            height: 28,
            minWidth: 'auto',
            borderRadius: theme.custom.roundedButtonRadius,
        },
    };
});
