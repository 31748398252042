import React from 'react';

import {UserProfilePhoneNumber} from '../../../features/block-user-profile-actions-pii/components/UserProfilePhoneNumber';

import {Wrapper} from './WrapperDev';

export const EditableDev = () => {
    return (
        <Wrapper label="Editable text">
            <UserProfilePhoneNumber
                user={{
                    uid: '544',
                    contact: {
                        mobile: {area: '+33', mobile: '789879'},
                    },
                }}
                userId={'544'}
            ></UserProfilePhoneNumber>
        </Wrapper>
    );
};
