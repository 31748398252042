import React from 'react';
import {defineMessages} from 'react-intl';

import {TransactionFilterKeys} from '@redux/entity';

import FilterValueRangePicker from 'src/features/shared/filter/components/value-range-picker/FilterValueRangePicker';
import {
    ValueRangePickerOptions,
    ValueRangePickerType,
} from 'src/features/shared/filter/components/value-range-picker/valueRangePickerTypes';

import {TransactionFilterProps} from './TransactionFilters';

const localized = defineMessages({
    filterAmount: {
        id: 'TransactionFilterAmount_filterAmount',
        defaultMessage: 'Amount',
    },
});

function TransactionFilterAmount({filter, onFilterChange}: TransactionFilterProps) {
    const filterKeys: TransactionFilterKeys[] = ['amountMin', 'amountMax'];
    return (
        <FilterValueRangePicker
            onFilterChange={onFilterChange}
            filter={{
                label: localized.filterAmount,
                key: filterKeys,
                options: {type: ValueRangePickerType.Number, rangeLabel: localized.filterAmount} as ValueRangePickerOptions,
            }}
            filterString={filter}
        />
    );
}
export default TransactionFilterAmount;
