import {PlayerMessage} from '@models/generated/graphql';

import {gridModuleActions as getGridModuleActions} from '../../module-shared/actions';
import {createSubscriberRealtimeActions} from '../../realtime-updates/actions';

import {domain} from './types';

export const userNotificationHistoryActions = {
    ...getGridModuleActions(domain),
    ...createSubscriberRealtimeActions<PlayerMessage>(domain),
};
