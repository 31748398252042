import React from 'react';
import {defineMessages} from 'react-intl';

import {LayoutDetailsBlock} from '@components/layout/LayoutDetailsBlock';
import {isStringNullOrEmpty} from '@utils/string';

import {
    DataGridPlayerReferralColumns,
    DataGridPlayerReferralsClient,
    PlayerReferralGridItem,
    useReferralInfoData,
} from 'src/features/block-player-referral-list';
import {useUserProfileDetails} from 'src/features/block-user-profile-details';

import {UserReferralKeyValueTable} from './UserReferralKeyValueTable';

type UserReferralInfoProps = {
    userId: string;
};

const localized = defineMessages({
    title: {
        id: 'UserReferralInfo_title',
        defaultMessage: 'Referrer Info',
    },
    emptyList: {
        id: 'UserReferrerInfo_emptyList',
        defaultMessage: 'Referrer is not connected',
    },
});

export const UserReferralInfo = ({userId}: UserReferralInfoProps) => {
    const columns: DataGridPlayerReferralColumns[] = [
        'referrer_id',
        'revenue_share',
        'revenue_generated_total',
        'revenue_share_offering_status',
        'expiration_date',
    ];
    const {referral, affiliate} = useReferralInfoData('UserReferralKeyValueTable', localized.title, userId);
    const items = (referral?.referrer_id ? [referral] : []) as PlayerReferralGridItem[];

    const viewType = 'UserReferralInfo';
    const {item: user} = useUserProfileDetails({
        id: referral?.referrer_id,
        viewType,
        fields: ['uid', 'agent_info.bo_agent_id'],
    });
    const isAgentReferrer = !isStringNullOrEmpty(user?.agent_info?.bo_agent_id);

    return (
        <LayoutDetailsBlock
            title={localized.title}
            content={
                <>
                    <UserReferralKeyValueTable
                        userId={userId}
                        referral={referral}
                        affiliate={affiliate}
                        isAgentReferrer={isAgentReferrer}
                    />
                    {(!isAgentReferrer && user) || !referral?.referrer_id ? (
                        <DataGridPlayerReferralsClient
                            autoHeight
                            columns={columns}
                            rows={items}
                            hidePagination
                            emptyMessage={localized.emptyList}
                        />
                    ) : null}
                </>
            }
        ></LayoutDetailsBlock>
    );
};
