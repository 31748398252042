import React from 'react';
import {defineMessages} from 'react-intl';
import {useSelector} from 'react-redux';

import Button from '@components/button/Buttons';
import {IModuleGridItem} from '@components/data-grid';
import {EntityDataGridProps, MuiDataGridClientProps} from '@components/data-grid/mui';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {EntityType} from '@redux/entity';

import {useAffectedRecords, useBulkActionResultValue} from '../hooks';
import {actionsDataSelector} from '../selectors';
import {BulkStepProps} from '../types';

import {BulkActionSummaryDetailsInfo} from './BulkSummaryStepActionItem';
import {BulkSummaryStepActionLabel} from './BulkSummaryStepActionLabel';
import {BulkTotalCountChip} from './BulkTotalCountChip';

const localized = defineMessages({
    bulkStepChangesSummaryModalHeader: {
        id: 'bulkStepChangesSummaryModalHeader',
        defaultMessage: 'Applying Changes',
    },
    bulkStepChangesSummaryModalConfirm: {
        id: 'bulkStepChangesSummaryModalConfirm',
        defaultMessage: 'Confirm',
    },
    bulkStepChangesSummaryModalCancel: {
        id: 'bulkStepChangesSummaryModalCancel',
        defaultMessage: 'Cancel',
    },
});

type BulkStepChangesSummaryProps = BulkStepProps & {withFlexibleGrid?: boolean};

/**
 * used to show the details for all actions set by user
 * @param WrappedDataGrid component to render action summary with data grid
 * @param columns columns that are shown in summary grid
 * @param entityType entity type that used in bulk action
 */
export const withBulkSummaryStep =
    <TEntity extends IModuleGridItem, TColumn extends string>(
        WrappedDataGrid: (props: EntityDataGridProps<MuiDataGridClientProps, TColumn, TEntity>) => JSX.Element,
        columns: TColumn[],
        entityType: EntityType
    ) =>
    ({onNext, onPrev, withFlexibleGrid}: BulkStepChangesSummaryProps) => {
        const actionItems = useSelector(actionsDataSelector);
        const resultValues = useBulkActionResultValue();
        const affectedRecordsForAllActions = useAffectedRecords<TEntity>();
        const maxRows = 100;

        const handlePrevClick = () => {
            onPrev();
        };

        const handleNextClick = () => {
            onNext();
        };

        return (
            <>
                <BulkModalTitle title={localized.bulkStepChangesSummaryModalHeader} />
                <BulkModalContent>
                    {Object.keys(actionItems).map((actionKey: string) => {
                        const affectedRecords = affectedRecordsForAllActions[actionKey] ?? [];
                        return affectedRecords.length ? (
                            <BulkActionSummaryDetailsInfo
                                key={`summaryDetails-${actionKey}`}
                                expandable={true}
                                actionKey={actionKey}
                                actionLabel={
                                    <BulkSummaryStepActionLabel
                                        actionKey={actionItems?.[actionKey]?.[0]?.actionKey}
                                        labelValue={actionItems?.[actionKey]?.[0]?.labelValue}
                                    ></BulkSummaryStepActionLabel>
                                }
                                actionValue={resultValues[actionKey]}
                                actionTotal={
                                    <BulkTotalCountChip
                                        totalItems={affectedRecords.length}
                                        type={entityType}
                                        chipType="info"
                                    ></BulkTotalCountChip>
                                }
                            >
                                <WrappedDataGrid
                                    rows={affectedRecords}
                                    columns={columns}
                                    rowsPerPageOptions={[maxRows]}
                                    hidePagination={affectedRecords.length < maxRows}
                                    autoHeight
                                    isFlexible={withFlexibleGrid}
                                />
                            </BulkActionSummaryDetailsInfo>
                        ) : null;
                    })}
                </BulkModalContent>
                <ModalFooter>
                    <Button label={localized.bulkStepChangesSummaryModalCancel} onClick={handlePrevClick} />
                    <Button color="primary" label={localized.bulkStepChangesSummaryModalConfirm} onClick={handleNextClick} />
                </ModalFooter>
            </>
        );
    };
