import React, {RefObject} from 'react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import {Box, ToggleButton} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {toolbarButtonClassName} from '@components/rich-text-editor/EditorToolbar';

type AttachmentOptionProps = {
    refFileInput: RefObject<HTMLInputElement>;
};

const useClasses = makeStyles()(() => ({
    icon: {
        fontSize: 20,
        transform: 'rotate(-45deg)',
    },
}));
export const attachmentOptionClassName = 'attachmentButton';

export const AttachmentOption = ({refFileInput}: AttachmentOptionProps) => {
    const {classes, cx} = useClasses();
    function handleClick() {
        refFileInput.current.click();
    }

    return (
        <Box className={cx(attachmentOptionClassName, toolbarButtonClassName)}>
            <ToggleButton onClick={handleClick} value="attachment">
                <AttachmentIcon className={classes.icon} />
            </ToggleButton>
        </Box>
    );
};
