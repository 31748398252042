import {defineMessages} from 'react-intl';
import {GridValueFormatterParams} from '@mui/x-data-grid';

import {gridCellDefaultFormatter, GridColDef, renderCellHeader, renderCellWithTooltip} from '@components/data-grid/mui';
import {formatDate} from '@utils';

import {LegalDocumentHistoryItem} from '../types';

import {renderLegalVersionViewDocument} from './LegalVersionViewDocumentAction';

const localized = defineMessages({
    version: {
        id: 'LegalDocs_version',
        defaultMessage: 'Version',
    },
    date: {
        id: 'LegalDocs_date',
        defaultMessage: 'Date',
    },
    text: {
        id: 'LegalDocs_text',
        defaultMessage: 'Text',
    },
    userId: {
        id: 'LegalDocs_userId',
        defaultMessage: 'User Id',
    },
    ip: {
        id: 'LegalDocs_ip',
        defaultMessage: 'IP',
    },
    userEmail: {
        id: 'LegalDocs_userEmail',
        defaultMessage: 'User Email',
    },
});

const getGridColumns = (gridDomain: string) => {
    const columns: GridColDef[] = [
        {
            field: nameof<LegalDocumentHistoryItem>(u => u.id),
            hide: true,
        },
        {
            field: nameof<LegalDocumentHistoryItem>(u => u.version),
            renderHeader: () => renderCellHeader(localized.version),
            sortField: nameof<LegalDocumentHistoryItem>(g => g.version),
            sortDirection: 'desc',
            flex: 0.2,
        },
        {
            field: nameof<LegalDocumentHistoryItem>(u => u.created_at),
            renderHeader: () => renderCellHeader(localized.date),
            valueFormatter: (params: GridValueFormatterParams) =>
                formatDate((params.api.getRow(params.id) as LegalDocumentHistoryItem).created_at, 'date-time'),
            flex: 0.25,
        },
        {
            field: nameof<LegalDocumentHistoryItem>(u => u.user_id),
            renderHeader: () => renderCellHeader(localized.userId),
            flex: 0.3,
        },
        {
            field: nameof<LegalDocumentHistoryItem>(u => u.userEmail),
            renderHeader: () => renderCellHeader(localized.userEmail),
            valueFormatter: gridCellDefaultFormatter<LegalDocumentHistoryItem>(u => u.userEmail),
            sortable: false,
            flex: 0.3,
        },
        {
            field: nameof<LegalDocumentHistoryItem>(u => u.ip),
            renderHeader: () => renderCellHeader(localized.ip),
            flex: 0.2,
        },
        {
            field: nameof<LegalDocumentHistoryItem>(u => u.document),
            renderHeader: () => renderCellHeader(localized.text),
            renderCell: params => renderLegalVersionViewDocument(gridDomain, params.row as LegalDocumentHistoryItem),
            sortable: false,
            flex: 0.2,
        },
    ];

    const defaultCol: GridColDef = {
        field: '',
        flex: 1,
        renderCell: renderCellWithTooltip,
    };

    return columns.map(c => ({...defaultCol, ...c}));
};

export default {
    localized,
    getColumns: getGridColumns,
};
