import {IModuleGridItem} from '@components/data-grid';
import {BoRole} from '@redux/entity';

import {Module} from '../../features/modules/types';

export const domain = 'access-management/roles';

export class RoleFormItem extends BoRole {
    roleId: string;
}

export class RoleGridItem extends BoRole implements IModuleGridItem {
    userCount: number;

    serverId: string;

    get isNew() {
        return false;
    }

    __typename = 'BoRole';
}

export class RolePolicy implements IModuleGridItem {
    id: string;

    policy: number;

    serverId: string;

    get gridId() {
        return this.id;
    }

    get isNew() {
        return false;
    }

    __typename = 'RolePolicy';
}

export type AvailableModules = {
    modules: Module[];
    submodules: Module[];
};
