import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {ErrorModal} from '@components/error';
import {BulkModalContent, BulkModalTitle} from '@components/modal';
import {useService} from '@inversify';
import {withTraceErrorBoundary} from '@otel';

import {
    bulkActionsActions,
    BulkApplyStepModalHeader,
    BulkStepProps,
    itemsSelector,
    messageResultValueSelector,
} from '../../block-bulk-actions';
import {SendMessageForm} from '../../player-actions/communication-actions/components/SendMessageForm';
import {defaultMessage, Message} from '../../player-message/types';
import {ApplySendMessageRequest, ApplySendMessageStrategy} from '../services/applyStrategy';

const localized = defineMessages({
    title: {
        id: 'BulkStepSendMessage_title',
        defaultMessage: 'Add Bulk Action',
    },
    sendMessage: {
        id: 'BulkStepSendMessage_sendMessage',
        defaultMessage: 'Send a Message',
    },
});

function BulkStepSendMessageInternal({onPrev, onNext}: BulkStepProps) {
    const dispatch = useDispatch();

    const messageResultValue: Message = useSelector(messageResultValueSelector);
    const form = useForm<Message>({defaultValues: messageResultValue ?? defaultMessage});

    const strategy = useService<ApplySendMessageStrategy>('ApplySendMessageStrategy');
    const players = useSelector(itemsSelector);

    const onFormSubmit = () => {
        const message = form.getValues();
        dispatch(bulkActionsActions.apply({request: {players, value: {...message}} as ApplySendMessageRequest, strategy}));

        onNext();
    };

    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent>
                <BulkApplyStepModalHeader back={onPrev} apply={form.handleSubmit(onFormSubmit)} label={localized.sendMessage} />
                <FormProvider {...form}>
                    <form name="bulkSendMessageForm">
                        <SendMessageForm />
                    </form>
                </FormProvider>
            </BulkModalContent>
        </>
    );
}

export const BulkUserProfileSendMessageModal = withTraceErrorBoundary(BulkStepSendMessageInternal, ErrorModal);
