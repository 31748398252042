import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    textWithDropdownFilter: {
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius * 2,
        height: theme.custom.denseButtonHeight,
        '& .Mui-focused': {
            border: '0 !important',
            boxShadow: 'none !important',
        },
        border: '1px solid #E1E7EB',
        boxSizing: 'border-box',
        boxShadow: `0px 1px 1px ${theme.custom.palette.content.boxShadow}`,
    },
    textWithDropdownFilterWidthNoLimit: {
        display: 'flex',
        flex: 1,
    },
    textWithDropdownFilterSelect: {
        paddingLeft: theme.spacing(2),
        paddingRight: `${theme.spacing(1.25)} !important`,
        '&:focus': {
            background: 'transparent',
        },
    },
    textWithDropdownFilterTextField: {
        '& .MuiOutlinedInput-root': {
            border: '0 !important',
            boxShadow: 'none !important',
        },
    },
    textWithDropdownFilterInput: {
        paddingLeft: theme.spacing(1),
    },
    dropdownIcon: {
        color: theme.palette.secondary.main,
        paddingRight: theme.spacing(2),
    },

    filterTextStartInputAdornment: {
        color: theme.palette.secondary.main,
        fontSize: 12,
    },

    filterText: {
        '& .MuiInputBase-input': {
            height: theme.custom.denseButtonHeight - 2,
            textOverflow: 'ellipsis',
        },
    },

    filterTextCleanableLimitMaxWidth: {
        maxWidth: theme.custom.filterTextInputMaxWidth,
        width: theme.custom.filterTextInputMaxWidth,
    },

    filterWithButton: {
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(0, 0, 'auto', 0),
        alignItems: 'center',
    },

    submitButton: {
        margin: theme.spacing(1),
        minWidth: theme.spacing(4),
        maxHeight: theme.custom.denseButtonHeight,
    },
}));
