import {BaseFilterKeys, Filter} from '@redux/entity';
import {isStringNullOrEmpty} from '@utils';

import {Filter as KeyValueFilter} from 'src/common/types';

export function mapViewFilterToString<T extends string = BaseFilterKeys>(filter: Filter<T>, possibleKeys: ReadonlyArray<T>): string {
    const searchParams = new URLSearchParams();

    possibleKeys?.forEach((key: T) => {
        const value = filter?.[key];

        if (value) {
            searchParams.set(key, JSON.stringify(value));
        }
    });

    return searchParams.toString();
}

export function getNonEmptyValueValidator<TFilterKeys>(...keys: TFilterKeys[]): (filter: KeyValueFilter<any, TFilterKeys>[]) => boolean {
    return filter => keys?.map(key => !isStringNullOrEmpty(filter?.find(f => f.key === key)?.value))?.every(v => v);
}
