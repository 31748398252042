import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import {DateRangeFilterValue, MultiStepFilterDatePicker, MultiStepFilterDrawer} from '@components/filter';
import {AgentFilterViewModel} from '@models/agent-profile';
import {getTodayAsDate} from '@utils/date';

import {AgentFilterValue} from 'src/features/block-agent-profile/components/AgentFilterNew';
import {MultiStepAgentFilter} from 'src/features/block-agent-profile/components/MultiStepAgentFilter';
import {useJurisdictionConfig} from '../../../features/app/config/hooks';

import {Wrapper} from './WrapperDev';

const initialValue: DateRangeFilterValue = {
    from: null,
    to: null,
};

export function MultiStepFilter() {
    const [stringValue, setStringValue] = useState<AgentFilterValue>({isDownstream: false, agentId: null});
    const [value, setValue] = useState<DateRangeFilterValue>(initialValue);
    const [isOpen, setIsOpen] = useState(false);
    const {locale} = useJurisdictionConfig();

    const methods = useForm<AgentFilterViewModel>({
        defaultValues: null,
    });

    function handleDrawerToggle() {
        setIsOpen(!isOpen);
    }

    return (
        <Wrapper label={'MultiStepFilter'}>
            <button onClick={handleDrawerToggle}>Open</button>
            <FormProvider {...methods}>
                <MultiStepFilterDrawer isOpen={isOpen} onClose={handleDrawerToggle} onOpen={handleDrawerToggle}>
                    <MultiStepAgentFilter
                        value={stringValue}
                        onChange={setStringValue}
                        defaultState={{boAgentPlayerId: '1', viewType: 'View1', withDownStream: true}}
                    />
                    <MultiStepAgentFilter
                        value={stringValue}
                        onChange={setStringValue}
                        defaultState={{viewType: 'View1', withDownStream: false}}
                    />
                    <MultiStepFilterDatePicker value={value} onChange={setValue} defaultState={{defaultDate: getTodayAsDate(), locale}} />
                </MultiStepFilterDrawer>
            </FormProvider>
        </Wrapper>
    );
}
