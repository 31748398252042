import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box, Chip} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

type ActionModalTitleProps = {
    label: MessageDescriptor;
    username?: string;
    total?: number;
    showTotal?: boolean;
};

const useClasses = makeStyles()((theme: CustomTheme) => {
    return {
        actionModalTitle: {
            display: 'flex',
            columnGap: theme.spacing(2),
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
});

export const ActionModalTitle = ({label, username, total, showTotal = true}: ActionModalTitleProps) => {
    const {classes} = useClasses();
    return (
        <Box className={classes.actionModalTitle}>
            <LocalizedText label={label} labelParams={{username}} />
            {total !== undefined && showTotal ? <Chip label={total} size="small" /> : null}
        </Box>
    );
};

type UserInfo = {
    uid?: string;
    username?: string;
};

type ActionCenterModalTitleProps = {
    label: MessageDescriptor;
    model?: UserInfo;
    total?: number;
};

export const ActionCenterModalTitle = ({label, model, total}: ActionCenterModalTitleProps) => {
    return model ? <ActionModalTitle label={label} username={model?.username ?? model?.uid} total={total} /> : <></>;
};
