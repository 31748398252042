import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import InfoMessage from '@components/alerts/InfoMessage';
import LocalizedText from '@components/i18n/LocalizedText';
import {LinkText} from '@components/link';

import {ErrorDetails} from './ErrorDetails';
import {localized} from './localize';
import {ErrorProps} from './type';

export const useClasses = makeStyles()(() => ({
    errorAccordion: {
        background: 'transparent',
        boxShadow: 'none',
    },

    errorAccordionSummary: {
        '& .MuiAccordionSummary-content': {
            justifyContent: 'center',
        },
    },
}));

export function ErrorAccordion(props: ErrorProps) {
    const {classes} = useClasses();
    return (
        <InfoMessage>
            <Typography variant="body1">
                <LocalizedText label={localized.infoMessage} />
            </Typography>
            <Accordion className={classes.errorAccordion}>
                <AccordionSummary className={classes.errorAccordionSummary}>
                    <LinkText title={localized.showMoreLabel} onClick={() => {}} />
                </AccordionSummary>
                <AccordionDetails>
                    <ErrorDetails {...props}></ErrorDetails>
                </AccordionDetails>
            </Accordion>
        </InfoMessage>
    );
}
