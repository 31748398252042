import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AccountVerification} from '@models/generated/graphql';

import {SearchFilter} from 'src/common/types';
import {KycProcessingService} from '../kyc-processing/services';
import {AccountVerificationItem} from '../kyc-processing/types';

export abstract class KycDetailsBaseService extends KycProcessingService {
    getItem(id: string, itemFields?: string[]): Observable<AccountVerificationItem> {
        const filter = {
            filter: [{key: nameof<AccountVerification>(t => t.id), value: id}],
            paging: {page: 1, pageSize: 1},
        } as SearchFilter;
        return super
            .getItemsPage(filter, itemFields)
            .pipe(map(res => (res?.items?.length ? (res.items[0] as AccountVerificationItem) : null)));
    }
}
