import {Observable} from 'rxjs';

import {IModuleGridItem} from '@components/data-grid';

import {IFormItem, IModuleItem} from '../../../features/module-shared/types';
import {Filter, ItemsPage, SearchFilter} from '../../types';
import {toGQLPaging, toGQLSorting} from '../../utils/gqlFilter';

import {GQLBasedService} from './gqlBasedService';
import {IGridService} from './IGridService';
import {IItemDetailsReadService} from './itemDetailsReadService';
import {IGQlFilterVariables, IGQlSearchFilter} from './types';

/**
 * @deprecated should use services from src/common/services
 */
export abstract class RealtimeGridServiceBase<TItemModel extends IModuleItem, TGridItemModel extends IModuleGridItem, TServerModel>
    extends GQLBasedService<TServerModel>
    implements IGridService<TItemModel, TGridItemModel>, IItemDetailsReadService<TItemModel>
{
    abstract getItems(filter?: SearchFilter): Observable<TGridItemModel[]>;

    abstract getItemsPage(filter?: SearchFilter, itemFields?: string[]): Observable<ItemsPage<TGridItemModel>>;

    abstract getItem(id: string, itemFields?: string[]): Observable<TItemModel>;

    abstract addItem(item: IFormItem): Observable<IFormItem>;

    abstract editItem(item: IModuleItem): Observable<string>;

    protected getGQLVariables(filter: SearchFilter, filterMappingFunction?: (filters: Filter[]) => IGQlSearchFilter): IGQlFilterVariables {
        const res: IGQlFilterVariables = {end: 10, start: 0};

        if (filter) {
            const gqlFilter = filterMappingFunction ? filterMappingFunction(filter.filter) : this.toGQLSearchFilter(filter.filter);
            if (gqlFilter?.text?.length) {
                gqlFilter.text = gqlFilter.text.filter(f => f);
                gqlFilter.text = gqlFilter.text.length ? gqlFilter.text : undefined;
            }
            if (gqlFilter) {
                res.filter = gqlFilter;
            }

            const sort = toGQLSorting(filter.sorting);
            if (sort) {
                res.sort = sort;
            }

            const paging = toGQLPaging(filter.paging);
            if (paging) {
                res.end = paging.limit;
                res.start = paging.offset;
            }
        }

        return res;
    }

    protected abstract toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter;
}
