import {GridCellParams, GridColDef} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {IModuleGridItem} from '@components/data-grid';
import {CustomTheme} from '@style';

//TODO: [BO-2704] Move files to src/common/components/data-grid (renamed grid folder)
const useClasses = makeStyles()((theme: CustomTheme) => ({
    dataGridNewCell: {
        background: theme.custom.palette.grid.new,
    },
}));

export const useGridCellStyle = (columns: GridColDef[], isFlexible: boolean) => {
    const {classes} = useClasses();

    function setColumnsWidth(column: GridColDef): GridColDef {
        return column.width && column.flex
            ? {
                  ...column,
                  flex: isFlexible ? column.flex : undefined,
                  width: isFlexible ? undefined : column.width,
              }
            : column;
    }

    const styledColumns: GridColDef[] = columns?.map(c => ({
        ...setColumnsWidth(c),
        cellClassName: (params: GridCellParams) => {
            const gridCellClassName = c.cellClassName
                ? typeof c.cellClassName === 'string'
                    ? c.cellClassName
                    : c.cellClassName(params)
                : '';
            const commonCellClassName = (params.row as IModuleGridItem)?.isNew ? classes.dataGridNewCell : '';
            return `${gridCellClassName} ${commonCellClassName}`;
        },
    }));

    return styledColumns;
};
