import React from 'react';
import {defineMessages} from 'react-intl';

import {AccountVerificationViewModelKeys} from '@models/account-verification';
import {AccountVerificationFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {getNonEmptyValueValidator} from '@redux/view';

import {DataGridAccountVerificationsServer, useAccountVerifications} from '../index';

const localized = defineMessages({
    emptyList: {
        id: 'KYC_emptyList',
        defaultMessage: 'KYC list is empty',
    },
});

type UserKYCInfoProps = {
    userId: string;
};

export function UserKYCInfo({userId}: UserKYCInfoProps) {
    const keys: AccountVerificationViewModelKeys[] = ['account_verification_status', 'type', 'initiated_at.seconds', 'documents_with_link'];

    const {items, totalCount, searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useAccountVerifications({
        viewType: 'UserKYCInfo',
        fields: keys,
        defaultFilters: [{key: 'uid', value: userId}],
        validateFilter: getNonEmptyValueValidator<AccountVerificationFilterKeys>('uid'),
        realtimeMode: RealtimeUpdatesMode.Confirm,
        triggers: [{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Update}],
    });

    return (
        <DataGridAccountVerificationsServer
            autoHeight
            rows={items}
            rowCount={totalCount}
            columns={keys}
            page={searchFilter?.paging?.page}
            pageSize={searchFilter?.paging?.pageSize}
            sortModel={searchFilter?.sorting}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSortModelChange={handleSortChange}
            emptyMessage={localized.emptyList}
        />
    );
}
