import {useAutoMapper} from '@auto-mapper';
import {GameRoom, GameRoomViewModel, GameRoomViewModelKeys} from '@models/game-room';
import {GameRoomViewModelMappingArgs} from '@models/game-room/mapper';
import {EntityType, GameRoomFields, GameRoomFilterKeys} from '@redux/entity';
import {getNonEmptyValueValidator, UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {useJurisdictionConfig} from '../app/config/hooks';

export function useGameRooms({
    viewType,
    displayName,
    defaultFilters,
    realtimeMode,
    triggers,
    validateFilter = () => true,
    fields,
    cleanDelay,
}: UseListViewEntityProps<GameRoomFilterKeys, GameRoomViewModelKeys>): UseListViewEntityResult<GameRoomViewModel, GameRoomViewModelKeys> {
    const mapper = useAutoMapper();

    const serverModelFields: GameRoomFields[] = mapper.map(fields, nameof<GameRoomViewModelKeys>(), nameof<GameRoomFields>());

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
        handlePageChange,
        handlePageSizeChange,
    } = useViewInit<GameRoom, GameRoomFilterKeys, GameRoomFields>({
        viewType,
        entity: {
            entity: EntityType.GameRoom,
            fields: serverModelFields,
        },
        realtime: realtimeMode && triggers ? {entity: EntityType.GameRoom, mode: realtimeMode, triggers} : null,
        displayName,
        defaultFilters,
        validateFilter: filter => {
            const gameTypeValidator = getNonEmptyValueValidator<GameRoomFilterKeys>('gameType');
            return gameTypeValidator(filter) && validateFilter ? validateFilter(filter) : true;
        },
        cleanDelay,
    });

    const {currency} = useJurisdictionConfig();
    const rooms = items
        ?.map(i => mapper.map(i, GameRoom, GameRoomViewModel, {extraArgs: () => ({currency} as GameRoomViewModelMappingArgs)}))
        ?.sort((a, b) => b.roomId - a.roomId);

    const handleSortChange = () => {};

    return {
        items: rooms,
        totalCount,
        filterString,
        searchFilter,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    };
}
