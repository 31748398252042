import React from 'react';
import {AutocompleteGetTagProps} from '@mui/base/useAutocomplete';
import {Badge, ChipProps} from '@mui/material';

import {Chip} from '../chip/Chips';
import {ChipType, ChipVariant} from '../chip/types';

import {useAutocompleteChipClasses} from './AutocompleteChip.styles';
import {AutocompleteOptionType} from './types';

type AutocompleteChipProps = ChipProps & {
    badgeContent?: React.ReactNode;
};

export const AutocompleteChip = ({badgeContent, ...otherProps}: AutocompleteChipProps) => {
    const {classes} = useAutocompleteChipClasses();
    const chipProps = {
        icon: badgeContent ? (
            <Badge className={classes.autocompleteChipPreviouslyAssignedItemBadge} badgeContent={badgeContent} color="default" />
        ) : null,
    };

    return (
        <Chip
            className={classes.autocompleteChipPreviouslyAssignedItemChip}
            fillWidth={false}
            chipType={ChipType.TagMarkIcon}
            chipVariant={ChipVariant.DefaultReversed}
            {...chipProps}
            {...otherProps}
        />
    );
};

export const renderTags = (
    value: AutocompleteOptionType[],
    getTagProps: AutocompleteGetTagProps,
    getOptionLabel: (option: string | AutocompleteOptionType) => string
) => {
    return value
        ?.map((option, index) => {
            const optionLabel = getOptionLabel(option);

            return optionLabel ? (
                <AutocompleteChip
                    label={optionLabel}
                    badgeContent={(option as any)?.badgeContent}
                    {...getTagProps({index})}
                    key={option.value}
                />
            ) : null;
        })
        .filter(v => v);
};
