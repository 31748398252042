import React, {memo, useEffect} from 'react';
import {MessageDescriptor} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from 'tss-react/mui';
import {RootState} from 'typesafe-actions';

import {Button} from '@components/button/Buttons';
import {GridColDef, MuiDataGridServer} from '@components/data-grid/mui';
import LocalizedText from '@components/i18n/LocalizedText';
import {BoUser} from '@redux/entity';
import {CustomTheme} from '@style';

import {Filter, Sorting} from 'src/common/types';
import {appSharedActions} from 'src/features/app-shared/actions';
import {GridModalActionProps, GridModalProps, withGridModal} from '../../../../features/data-grids/components/GridModalHoc';
import {useTempStorageDataLoad} from '../../../../features/module-temp-storage/components/editable-components/hooks';
import {useFilter} from '../../../../features/shared/filter/hooks';
import {UserGridItem} from '../../../access-management/types';
import data from '../../components/data';
import {domain as legalDomain, LegalDocType, LegalDocumentWriteKey} from '../../types';
import {filterSelector} from '../selectors';
import {domain, LegalDocumentHistoryItem} from '../types';

import gridData from './data';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    legalVersionHistoryGrid: {
        border: 'none',
    },
    legalVersionHistoryContainer: {
        flex: 1,
        width: '100%',
        minHeight: theme.custom.modalContainerMinHeight,
    },
}));

const LegalVersionHistoryAction = ({options, onClick}: GridModalActionProps): JSX.Element => {
    return <Button color="primary" label={options.actionLabel} onClick={onClick} />;
};

const LegalVersionHistoryModal = ({
    columns,
    options,
}: GridModalProps<LegalDocumentHistoryItem[], GridColDef[], LegalVersionHistoryOptions>): JSX.Element => {
    const {classes} = useClasses();
    const dispatch = useDispatch();
    const {documentType} = options;

    const stateData = useSelector((s: RootState) => s.legalHistory);
    const {
        data: {itemsPage},
    } = stateData;
    const filterString = useSelector(filterSelector);
    const {data: users} = useTempStorageDataLoad<UserGridItem[]>(nameof(BoUser));
    const versionHistoryItems = itemsPage?.items?.map(
        (i: LegalDocumentHistoryItem) =>
            ({
                ...i,
                userEmail: users?.find(u => u.id === i.user_id)?.email,
            } as LegalDocumentHistoryItem)
    );

    const defaultFilter: Filter[] = [{key: nameof<LegalDocumentWriteKey>(m => m.name), value: documentType}];
    const defaultSorting: Sorting<string>[] = [
        {
            field: nameof<LegalDocumentHistoryItem>(g => g.version),
            sort: 'desc',
        },
    ];
    const {searchFilter, handlePageChange, handlePageSizeChange, handleSortChange} = useFilter(
        domain,
        filterString,
        defaultFilter,
        defaultSorting
    );

    useEffect(() => {
        dispatch(appSharedActions.initUsersLoading());
    }, []);

    return (
        <MuiDataGridServer
            autoHeight={true}
            className={classes.legalVersionHistoryContainer}
            rows={versionHistoryItems}
            rowCount={itemsPage?.total ?? 0}
            columns={columns}
            domain={domain}
            page={searchFilter?.paging?.page}
            pageSize={searchFilter?.paging?.pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            sortModel={searchFilter?.sorting}
            onSortModelChange={handleSortChange}
        />
    );
};

type LegalVersionHistoryOptions = {
    domain: string;
    documentType: LegalDocType;
    actionLabel: MessageDescriptor;
};

const LegalVersionHistory = memo(
    withGridModal<LegalDocumentHistoryItem[], GridColDef[], LegalVersionHistoryOptions>(LegalVersionHistoryAction, LegalVersionHistoryModal)
);

export const renderLegalVersionHistoryAction = (domain: string, actionLabel: MessageDescriptor, centered = false) => {
    const columns = gridData.getColumns(domain);
    const documentType = legalDomain.privacyPolicy === domain ? LegalDocType.PrivacyPolicy : LegalDocType.TermsAndConditions;

    return (
        <LegalVersionHistory
            modalStyleParams={{maxWidth: 'md'}}
            title={<LocalizedText label={data.localized.versionHistoryLabel} />}
            columns={columns}
            options={{domain, documentType, actionLabel}}
            centered={centered}
        />
    );
};
