import {makeStyles} from 'tss-react/mui';

export const useClasses = makeStyles()(theme => ({
    editableDate: {
        marginBottom: 0,
        '& .MuiOutlinedInput-input': {
            height: '30px',
            paddingLeft: theme.spacing(1.5),
        },
    },

    editableDateReadonly: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
