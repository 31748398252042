import {merge, Observable, of, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import {catchError, map} from 'rxjs/operators';

import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {BaseHttpService} from '@services/deprecated';
import {PlayerManagementApiService} from '@services/deprecated';
import {HttpStatusCode} from '@services/types';

import {LegalDocType, LegalDocumentItem} from './types';

export class LegalDocsService {
    protected readonly playerHttpService: BaseHttpService;

    constructor(tracingService: ITracingService, userManager: UserManagerExtended) {
        this.playerHttpService = new PlayerManagementApiService(tracingService, userManager);
    }

    loadLegalDocument(userId: string, type: LegalDocType): Observable<LegalDocumentItem> {
        return this.getLegalDocument(type, userId).pipe(
            map(res => ({...res, uid: userId})),
            catchError<LegalDocumentItem, Observable<LegalDocumentItem>>((err: AjaxError) =>
                err.status !== HttpStatusCode.NotFound ? merge(of(null), throwError(err)) : of(null)
            )
        );
    }

    private getLegalDocument(type: LegalDocType, userId: string): Observable<LegalDocumentItem> {
        return this.playerHttpService.getJSON<LegalDocumentItem>(`/legal/${type}/accepted?userId=${userId}`);
    }
}
