import React from 'react';

import {UserProfile} from '@models/generated/graphql';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionAccessibleItem, PermissionEnum} from '@models/permissions';
import {useAuthUser, usePolicies} from '@auth';
import {EntityType, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {useViewInit, ViewType} from '@redux/view';
import {isStringNullOrEmpty, toEnumString} from '@utils';

import {NoAccessInfoMessage} from '../../../common/access-control/NoAccessInfoMessage';
import {NotFoundPage} from '../../../pages/SystemPages';
import {allPermissions} from '../../module-shared/types';
import {useJurisdictionFeaturePermissions} from '../config/hooks';
import {JurisdictionFeature} from '../config/types';

//TODO: [BO-2711] Split PermissionHOC to multiple files

export const getPolicyFromPermission = (permission: PermissionEnum, moduleName: ModuleName, submoduleName: SubmoduleName) => {
    const permissionName = toEnumString(PermissionEnum, permission).toLowerCase();

    const modulePolicy = `${moduleName}:${permissionName}`;
    const submodulePolicy = `${moduleName}:${submoduleName}:${permissionName}`;

    return {modulePolicy, submodulePolicy};
};

const getPoliciesFromPermissions = (permissions: PermissionEnum[], moduleName: ModuleName, submoduleName: SubmoduleName) => {
    const policies = [] as string[];
    if (permissions?.length && (moduleName || submoduleName)) {
        permissions?.map(permission => {
            const {modulePolicy, submodulePolicy} = getPolicyFromPermission(permission, moduleName, submoduleName);

            policies.push(modulePolicy);
            if (submoduleName) {
                policies.push(submodulePolicy);
            }
        });
    }
    return policies;
};

export const useAvailablePermissions = ({permissions, moduleName, submoduleName}: PermissionAccessibleItem): PermissionEnum[] => {
    const availablePermissions = [] as PermissionEnum[];
    const {policies} = useAuthUser();

    if (permissions?.length && (moduleName || submoduleName)) {
        permissions?.map(permission => {
            const {modulePolicy, submodulePolicy} = getPolicyFromPermission(permission, moduleName, submoduleName);

            if (policies.includes(modulePolicy) || (submoduleName && policies.includes(submodulePolicy))) {
                availablePermissions.push(permission);
            }
        });
    } else {
        availablePermissions.push(...allPermissions);
    }

    return availablePermissions;
};

export const usePermission = ({permissions, moduleName, submoduleName}: PermissionAccessibleItem): boolean => {
    const policiesToCheck = getPoliciesFromPermissions(permissions, moduleName, submoduleName);
    const policies = usePolicies();
    return policiesToCheck.length === 0 || policies.some(p => policiesToCheck.includes(p?.toString()));
};

type UseMultiplePermissionsProps = {
    allowedPermissions?: PermissionAccessibleItem[];
    restrictedPermissions?: PermissionAccessibleItem[];
};

export const useMultiplePermissions = ({allowedPermissions, restrictedPermissions}: UseMultiplePermissionsProps): boolean => {
    const allowedPolicies: string[] = [];
    const restrictedPolicies: string[] = [];

    allowedPermissions?.map(({permissions, moduleName, submoduleName}) => {
        allowedPolicies.push(...getPoliciesFromPermissions(permissions, moduleName, submoduleName));
    });
    restrictedPermissions?.map(({permissions, moduleName, submoduleName}) => {
        restrictedPolicies.push(...getPoliciesFromPermissions(permissions, moduleName, submoduleName));
    });

    const {policies} = useAuthUser();
    const hasAllowedPolices = allowedPolicies.length === 0 || policies.some(p => allowedPolicies.includes(p));
    const hasRestrictedPolices = policies.some(p => restrictedPolicies.includes(p));

    return hasAllowedPolices && !hasRestrictedPolices;
};

export function withPermission<P extends object>(
    WrappedComponent: React.ComponentType<P>,
    permissions: PermissionEnum[],
    moduleName: ModuleName,
    submoduleName?: SubmoduleName,
    showInfoMessage = false
) {
    return (props: P) => {
        return usePermission({permissions, moduleName: moduleName, submoduleName: submoduleName}) ? (
            <WrappedComponent {...(props as P)} />
        ) : showInfoMessage ? (
            <NoAccessInfoMessage />
        ) : null;
    };
}

export enum LackOfPermissionIndicator {
    Hidden,
    Disabled,
    InfoMessage,
    NotFound,
}

type MultiplePermissionHOCParams = {
    allowedPermissions?: PermissionAccessibleItem[];
    restrictedPermissions?: PermissionAccessibleItem[];
    indicator?: LackOfPermissionIndicator;
};

export function withMultiplePermission<P extends object>(
    WrappedComponent: React.ComponentType<P>,
    {allowedPermissions, restrictedPermissions, indicator = LackOfPermissionIndicator.Hidden}: MultiplePermissionHOCParams
) {
    function MultiplePermissionHOC(props?: P) {
        const hasPermissions = useMultiplePermissions({allowedPermissions, restrictedPermissions});

        const renderNoPermissionsResult = (indicator: LackOfPermissionIndicator) => {
            switch (indicator) {
                case LackOfPermissionIndicator.InfoMessage:
                    return <NoAccessInfoMessage />;
                case LackOfPermissionIndicator.Disabled:
                    return <WrappedComponent {...(props as P)} disabled></WrappedComponent>;
                default:
                    return <></>;
            }
        };

        return hasPermissions ? <WrappedComponent {...(props as P)}></WrappedComponent> : renderNoPermissionsResult(indicator);
    }
    MultiplePermissionHOC.displayName = WrappedComponent.name;
    return MultiplePermissionHOC;
}

export const withPermissionAndJurisdictionConfig =
    <P extends object>(
        WrappedComponent: React.ComponentType<P>,
        {permissions, moduleName, submoduleName, featureName}: JurisdictionFeature,
        indicator: LackOfPermissionIndicator = LackOfPermissionIndicator.Hidden
    ) =>
    (props?: P) => {
        const availableRolePermissions = useAvailablePermissions({
            permissions: permissions,
            moduleName: moduleName,
            submoduleName: submoduleName,
        });
        const availableConfigPermissions = useJurisdictionFeaturePermissions({
            permissions: availableRolePermissions,
            moduleName,
            submoduleName,
            featureName,
        });

        const renderNoPermissionsResult = (indicator: LackOfPermissionIndicator) => {
            switch (indicator) {
                case LackOfPermissionIndicator.InfoMessage:
                    return <NoAccessInfoMessage />;
                case LackOfPermissionIndicator.Disabled:
                    return <WrappedComponent {...(props as P)} disabled></WrappedComponent>;
                case LackOfPermissionIndicator.NotFound:
                    return <NotFoundPage />;
                default:
                    return <></>;
            }
        };

        return availableConfigPermissions?.length ? (
            <WrappedComponent {...{...props, permissions: availableConfigPermissions}}></WrappedComponent>
        ) : (
            renderNoPermissionsResult(indicator)
        );
    };

export function useAgentPolicy(viewType: ViewType, uid: string, agentPolicy: PermissionAccessibleItem): boolean {
    const hasAgentPolicy = usePermission(agentPolicy);
    const boUserId = useAuthUser()?.sub;
    const {items} = useViewInit<UserProfile, UserProfileServerFilterKeys, UserProfileQueryFields>({
        viewType,
        entity: {
            entity: EntityType.UserProfile,
            fields: ['uid', 'agent_info.bo_agent_id', 'referral.inviting_user_id'],
        },
        defaultPaging: {page: 1, pageSize: 2},
        defaultFilters: [{key: 'boUserId_uid', value: boUserId && uid ? `"${boUserId}" ${uid}` : null}],
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'boUserId_uid')?.value),
    });

    const boPlayerId = items?.find(i => i.agent_info?.bo_agent_id === boUserId)?.uid;
    const refererId = items?.find(i => i.uid === uid)?.referral?.inviting_user_id;

    return hasAgentPolicy ? boPlayerId && (refererId === boPlayerId || boPlayerId === uid) : true;
}
