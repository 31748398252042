import {BaseFilterKeys, SortOrder, TransactionQueryFields} from '@redux/entity';

export function getUrlWithoutOutdatedParams(location: Pick<Location, 'search' | 'pathname'>): string {
    const initialSearch = location?.search;

    let resultSearch = replaceOutdatedSortField(initialSearch);
    resultSearch = replaceOutdatedSortOrder(resultSearch);

    return resultSearch !== initialSearch ? `${location.pathname}?${resultSearch}` : null;
}

function replaceOutdatedSortField(search: string): string {
    const sortFieldKey: BaseFilterKeys = 'sortField';
    const outdatedSortFieldStartedAt: TransactionQueryFields = 'transaction_started_ts.seconds';
    const outdatedSortFieldUpdatedAt: TransactionQueryFields = 'transaction_updated_ts.seconds';
    const sortFieldStartedAt: TransactionQueryFields = 'transaction_started_ts';
    const sortFieldUpdatedAt: TransactionQueryFields = 'transaction_updated_ts';

    const queryParams = new URLSearchParams(search);
    const sortField = queryParams.get(sortFieldKey);
    if (sortField?.includes(outdatedSortFieldStartedAt)) {
        queryParams.set(sortFieldKey, sortFieldStartedAt);
    } else if (sortField?.includes(outdatedSortFieldUpdatedAt)) {
        queryParams.set(sortFieldKey, sortFieldUpdatedAt);
    }

    return queryParams.toString();
}

function replaceOutdatedSortOrder(search: string): string {
    const sortOrderKey: BaseFilterKeys = 'sortOrder';
    const sortOrderDesc: SortOrder = SortOrder.Desc;
    const sortOrderAsc: SortOrder = SortOrder.Asc;

    const queryParams = new URLSearchParams(search);
    const sortOrder = queryParams.get(sortOrderKey);
    if (sortOrder?.includes(sortOrderDesc) && sortOrder !== sortOrderDesc) {
        queryParams.set(sortOrderKey, sortOrderDesc);
    } else if (sortOrder?.includes(sortOrderAsc) && sortOrder !== sortOrderAsc) {
        queryParams.set(sortOrderKey, sortOrderAsc);
    }

    return queryParams.toString();
}
