import React from 'react';
import {defineMessages} from 'react-intl';

import {
    DateRangeFilter,
    DateRangeFilterValue,
    FilterGroupNew,
    FilterGroupNewFilter,
    FilterProps,
    FiltersProps,
    SelectFilter,
    TextFilterFixedWidth,
    TextFilterFullWidth,
    TextFilterProps,
    ValueRange,
    ValueRangeFilter,
} from '@components/filter';
import {UserAccountStatus} from '@models/generated/graphql';

import {useJurisdictionConfig} from 'src/features/app/config/hooks';
import {localizedUserAccountStatus} from 'src/features/app/intl/shared-resources/userAccountStatus';
import {SelectOption} from 'src/features/module-shared/types';
import {CountryFilter} from '../../block-country/components/CountryFilter';
import {UserProfileFilterModel, UserProfileFilterName} from '../types';

type UserProfileFiltersProps = FiltersProps<UserProfileFilterModel, UserProfileFilterName>;

const localized = defineMessages({
    uid: {
        id: 'UserProfileFilters_userId',
        defaultMessage: 'Player ID',
    },
    username: {
        id: 'UserProfileFilters_username',
        defaultMessage: 'Username',
    },
    referrerId: {
        id: 'UserProfileFilters_referrer',
        defaultMessage: 'Referrer',
    },
    firstName: {
        id: 'UserProfileFilters_firstNameFilterPlaceholder',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'UserProfileFilters_lastNameFilterPlaceholder',
        defaultMessage: 'Last Name',
    },
    phoneNumber: {
        id: 'UserProfileFilters_phoneNumber',
        defaultMessage: 'Phone number',
    },
    securityCaseId: {
        id: 'UserProfileFilters_securityCaseId',
        defaultMessage: 'Security Case ID',
    },
    tournamentsId: {
        id: 'UserProfileFilters_tournamentsId',
        defaultMessage: 'Tournaments ID',
    },
    nickname: {
        id: 'UserProfileFilters_nickname',
        defaultMessage: 'Nickname',
    },
    labels: {
        id: 'UserProfileFilters_labels',
        defaultMessage: 'Label',
    },
    email: {
        id: 'UserProfileFilters_email',
        defaultMessage: 'Email',
    },
    cashGamesId: {
        id: 'UserProfileFilters_cashGamesId',
        defaultMessage: 'Cash Game ID',
    },
    accountStatus: {
        id: 'UserProfileFilters_accountStatus',
        defaultMessage: 'Account Status',
    },
    registrationCountry: {
        id: 'UserProfileFilters_registrationCountry',
        defaultMessage: 'Registration Country',
    },
    loginCountry: {
        id: 'UserProfileFilters_loginCountry',
        defaultMessage: 'Login Country',
    },
    casinoGgr: {
        id: 'UserProfileFilters_casinoGgr',
        defaultMessage: 'Casino GGR',
    },
    pokerGgr: {
        id: 'UserProfileFilters_pokerGgr',
        defaultMessage: 'Poker GGR',
    },
    sportsGgr: {
        id: 'UserProfileFilters_sportsGgr',
        defaultMessage: 'Sportsbook GGR',
    },
    depositTotal: {
        id: 'UserProfileFilters_depositTotal',
        defaultMessage: 'Total Deposits',
    },
    withdrawalTotal: {
        id: 'UserProfileFilters_withdrawalTotal',
        defaultMessage: 'Total Withdrawals',
    },
    marketingCode: {
        id: 'UserProfileFilters_marketingCode',
        defaultMessage: 'Marketing Code',
    },
});

function UserProfileFilterTextFullWidth(props: Omit<TextFilterProps, 'disallowSpecialCharacters'>) {
    return <TextFilterFullWidth {...props} disallowSpecialCharacters />;
}

function UserProfileFilterTextFixedWidth(props: Omit<TextFilterProps, 'disallowSpecialCharacters'>) {
    return <TextFilterFixedWidth {...props} disallowSpecialCharacters />;
}

export function UserProfileFilters({viewMode = 'six-column-view', ...props}: UserProfileFiltersProps) {
    const {locale} = useJurisdictionConfig();

    const filters: FilterGroupNewFilter<UserProfileFilterModel, UserProfileFilterName>[] = [
        {
            filterName: 'uid',
            modelField: 'uid',
            component: (props: FilterProps<string>) => <UserProfileFilterTextFixedWidth {...props} inputPlaceholder={localized.uid} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'uidWithPlaceholder',
            modelField: 'uid',
            component: (props: FilterProps<string>) => <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.uid} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'username',
            modelField: 'username',
            component: (props: FilterProps<string>) => <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.username} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'nickname',
            modelField: 'nickname',
            component: (props: FilterProps<string>) => <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.nickname} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'labels',
            modelField: 'labels',
            component: (props: FilterProps<string>) => <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.labels} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'email',
            modelField: 'email',
            component: (props: FilterProps<string>) => <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.email} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'phone',
            modelField: 'phone',
            component: (props: FilterProps<string>) => (
                <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.phoneNumber} />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'security_cases',
            modelField: 'security_cases',
            component: (props: FilterProps<string>) => (
                <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.securityCaseId} />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'networks_PKW',
            modelField: 'networks_PKW',
            component: (props: FilterProps<string>) => (
                <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.cashGamesId} />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'networks_BL',
            modelField: 'networks_BL',
            component: (props: FilterProps<string>) => (
                <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.tournamentsId} />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'referrerId',
            modelField: 'referrerId',
            component: (props: FilterProps<string>) => (
                <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.referrerId} />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'accountStatus',
            modelField: 'accountStatus',
            component: (props: FilterProps<string[]>) => (
                <SelectFilter
                    label={localized.accountStatus}
                    options={Object.values(UserAccountStatus).map(s => ({label: localizedUserAccountStatus[s], value: s} as SelectOption))}
                    chipType={nameof<UserAccountStatus>()}
                    multiple
                    {...props}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'firstName',
            modelField: 'firstName',
            component: (props: FilterProps<string>) => <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.firstName} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'lastName',
            modelField: 'lastName',
            component: (props: FilterProps<string>) => <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.lastName} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'registrationCountry',
            modelField: 'registrationCountry',
            component: (props: FilterProps<string[]>) => <CountryFilter inputLabel={localized.registrationCountry} {...props} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'loginCountry',
            modelField: 'loginCountry',
            component: (props: FilterProps<string[]>) => <CountryFilter inputLabel={localized.loginCountry} {...props} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'registrationDate',
            modelField: 'registrationDate',
            component: (props: FilterProps<DateRangeFilterValue>) => <DateRangeFilter {...props} width="full" locale={locale} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'casinoGgr',
            modelField: 'casinoGgr',
            component: (props: FilterProps<ValueRange>) => {
                return <ValueRangeFilter {...props} label={localized.casinoGgr} min={0} hideEqualOperator />;
            },
        },
        {
            filterName: 'pokerGgr',
            modelField: 'pokerGgr',
            component: (props: FilterProps<ValueRange>) => {
                return <ValueRangeFilter {...props} label={localized.pokerGgr} min={0} hideEqualOperator />;
            },
        },
        {
            filterName: 'sportsGgr',
            modelField: 'sportsGgr',
            component: (props: FilterProps<ValueRange>) => {
                return <ValueRangeFilter {...props} label={localized.sportsGgr} min={0} hideEqualOperator />;
            },
        },
        {
            filterName: 'depositTotal',
            modelField: 'depositTotal',
            component: (props: FilterProps<ValueRange>) => {
                return <ValueRangeFilter {...props} label={localized.depositTotal} min={0} hideEqualOperator />;
            },
        },
        {
            filterName: 'withdrawalTotal',
            modelField: 'withdrawalTotal',
            component: (props: FilterProps<ValueRange>) => {
                return <ValueRangeFilter {...props} label={localized.withdrawalTotal} min={0} hideEqualOperator />;
            },
        },
        {
            filterName: 'marketingCode',
            modelField: 'marketingCode',
            component: (props: FilterProps<string>) => (
                <UserProfileFilterTextFullWidth {...props} inputPlaceholder={localized.marketingCode} />
            ),
            collapseOnMobile: false,
        },
    ];

    return <FilterGroupNew mode="update-on-submit" viewMode={viewMode} allFilters={filters} {...props} />;
}
