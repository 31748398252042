import * as React from 'react';
import {Box, CircularProgress, CircularProgressProps} from '@mui/material';

import {MemoizedDefaultChip} from '../chip/ChipRenderer';
import {ChipType, ChipVariant} from '../chip/types';

import {useCircularProgressWithLabelStyles} from './CircularProgressWithLabel.style';

type CircularProgressWithLabelProps = CircularProgressProps & {
    totalItemCount: number;
    'data-testid'?: string;
    processedItemLabel?: string;
};

export const CircularProgressWithLabel = (props: CircularProgressWithLabelProps) => {
    const {classes} = useCircularProgressWithLabelStyles();
    const {totalItemCount, 'data-testid': dataTestId, processedItemLabel, ...otherProps} = props;
    const processedItemCount = Math.round((otherProps.value * totalItemCount) / 100); //value is percentage of processed

    return (
        <Box data-testid={dataTestId} className={classes.circularProgressWithLabelContainer}>
            <Box className={classes.circularProgressWithLabelLabel}>
                {totalItemCount ? (
                    <MemoizedDefaultChip
                        value={`${processedItemCount} / ${totalItemCount} ${processedItemLabel}`}
                        variant={ChipVariant.Disabled}
                        type={ChipType.Status}
                    />
                ) : null}
            </Box>
            <Box className={classes.circularProgressWithLabelRoot}>
                <CircularProgress
                    variant="determinate"
                    className={classes.circularProgressWithLabelBottom}
                    thickness={2}
                    {...otherProps}
                    value={100}
                />
                <CircularProgress
                    className={classes.circularProgressWithLabelTop}
                    classes={{
                        circle: classes.circularProgressWithLabelCircle,
                    }}
                    variant="determinate"
                    thickness={2}
                    {...otherProps}
                />
            </Box>
        </Box>
    );
};
