import {Mapper} from '@automapper/core';
import {Span} from '@opentelemetry/api';
import {inject, injectable} from 'inversify';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {GameRoomMappingArgs} from '@models/game-room';
import {GameRoomFilterInput, GameRoomItemPage, GameRoomResponse, GameRoomResponseListItem} from '@models/game-room/types';
import {
    GameTemplateEnabledIdsResponse,
    GameTemplateIdsResponse,
    GameTemplateMappingArgs,
    GameTemplatePlayersResponse,
    GameTemplateResponse,
    GameTemplateSettings,
} from '@models/game-template';
import {
    DisableGameTemplateRequestBody,
    DisableGameTemplateResponse,
    EnableGameTemplateRequestBody,
    GameBlindConfig,
    GameBlindConfigResponse,
} from '@models/game-template/types';
import {applyLocationAttrs, applyUserAttrs, ITracingService, map, mergeMap, TraceUser} from '@otel';
import {UserManagerExtended} from '@auth';
import {GameTemplate, ManagedGameType} from '@redux/entity';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {
    IServiceErrorResponsePayload,
    RestRequest,
    ServerResponseError,
    ServerResponseStatus,
    ServiceResponsePayload,
} from '@services/types';

import {ItemsPage} from 'src/common/types';

import {RestService} from './restService';

export type RoomServiceResponsePayload = ServiceResponsePayload<GameRoomFilterInput, GameRoomItemPage>;
export type GameTemplatesServiceResponsePayload = ServiceResponsePayload<GetGameTemplatesPayload, ItemsPage<GameTemplate>>;
export type BlindConfigServiceResponsePayload = ServiceResponsePayload<GetGameBlindsPayload, GameBlindConfigResponse>;
export type TemplateServiceResponsePayload = ServiceResponsePayload<GetGameTemplatePayload, GameTemplateResponse>;
export type CloseRoomServiceResponsePayload = ServiceResponsePayload<CloseGameRoomRequestPayload, null>;
export type KickoffPlayerServiceResponsePayload = ServiceResponsePayload<KickoffPlayerRequestPayload, null>;
export type EnableTemplateServiceResponsePayload = ServiceResponsePayload<EnableGameTemplatePayload, null>;
export type CreateTemplateServiceResponsePayload = ServiceResponsePayload<CreateGameTemplatePayload, null>;
export type EditTemplateServiceResponsePayload = ServiceResponsePayload<EditGameTemplatePayload, EditGameTemplateResponsePayload>;
export type DisableTemplateServiceResponsePayload = ServiceResponsePayload<DisableGameTemplatePayload, CloseTemplateRoomsResponsePayload>;
export type CloseTemplateRoomsServiceResponsePayload = ServiceResponsePayload<CloseTemplateRoomsPayload, CloseTemplateRoomsResponsePayload>;

export interface IGameManagementApiService {
    getExtendedRooms(filterInput: GameRoomFilterInput, gameType: ManagedGameType): Observable<RoomServiceResponsePayload>;

    getGameTemplates(requestPayload: GetGameTemplatesPayload): Observable<GameTemplatesServiceResponsePayload>;

    getGameTemplateExtended(
        requestPayload: GetGameTemplatePayload
    ): Observable<ServiceResponsePayload<GetGameTemplatePayload, GameTemplate>>;

    createGameTemplate(requestPayload: CreateGameTemplatePayload): Observable<CreateTemplateServiceResponsePayload>;

    editGameTemplateWithCloseRooms(requestPayload: EditGameTemplatePayload): Observable<EditTemplateServiceResponsePayload>;

    getBlindConfig(): Observable<BlindConfigServiceResponsePayload>;
    closeGameRoom(requestPayload: CloseGameRoomRequestPayload): Observable<CloseRoomServiceResponsePayload>;
    kickoffPlayerFromRoom(requestPayload: KickoffPlayerRequestPayload): Observable<KickoffPlayerServiceResponsePayload>;
    enableGameTemplate(requestPayload: EnableGameTemplatePayload): Observable<EnableTemplateServiceResponsePayload>;
    disableGameTemplatesWithCloseRooms(requestPayload: DisableGameTemplatePayload): Observable<DisableTemplateServiceResponsePayload>;
}

export type GameRoomsAdditionalData = Record<number, TemplateServiceResponsePayload> & {
    blinds: BlindConfigServiceResponsePayload;
};

export type GameBasePayload = {
    gameType?: ManagedGameType;
};

export type GetGameBlindsPayload = GameBasePayload;

export type GetGameTemplatesPayload = GameBasePayload & {
    page: number;
    size: number;
};

export type GetGameTemplatePayload = GameBasePayload & {
    id: number;
};

export type GetGameTemplatePlayersPayload = GameBasePayload & {
    id: number;
};

export type CreateGameTemplatePayload = GameBasePayload & {
    template: GameTemplateSettings;
};

export type EditGameTemplatePayload = GameBasePayload & {
    id: string;
    template: GameTemplateSettings;
};

export type EditGameTemplateResponsePayload = {
    failedRoomIds?: number[];
};

export type CloseGameRoomRequestPayload = GameBasePayload & {
    roomId: string;
    ownerId: string;
};

export type KickoffPlayerRequestPayload = GameBasePayload & {
    roomId: string;
    userId: string;
};

export type EnableGameTemplatePayload = GameBasePayload & {
    enableTemplateIds: number[];
};

export type DisableGameTemplatePayload = GameBasePayload & {
    disableTemplateIds: number[];
};

export type CloseTemplateRoomsPayload = GameBasePayload & {
    templateId: number;
};

export type CloseTemplateRoomsResponsePayload = GameBasePayload & {
    failedRoomIds: number[];
};

const plo5AttributesPrefix = 'plo5';
const Plo5OperationAttributes = {
    PLO5_RECORD_ID: `${plo5AttributesPrefix}.recordId`,
};

const plo5AttributesPrefixUpper = plo5AttributesPrefix.toLocaleUpperCase();
const plo5Operations = {
    PLO5_GET_TEMPLATE_EXTENDED: `${plo5AttributesPrefixUpper} GET TEMPLATE EXTENDED`,
    PLO5_GET_TEMPLATES_EXTENDED: `${plo5AttributesPrefixUpper} GET TEMPLATES EXTENDED`,
    PLO5_EDIT_TEMPLATE_WITH_CLOSE_ROOMS: `${plo5AttributesPrefixUpper} EDIT TEMPLATE WITH CLOSE ROOMS`,
    PLO5_CREATE_TEMPLATE: `${plo5AttributesPrefixUpper} CREATE TEMPLATE`,
    PLO5_ENABLE_TEMPLATE: `${plo5AttributesPrefixUpper} ENABLE TEMPLATE`,
    PLO5_DISABLE_TEMPLATE_WITH_CLOSE_ROOMS: `${plo5AttributesPrefixUpper} DISABLE TEMPLATE WITH CLOSE ROOMS`,
    PLO5_GET_ROOMS_EXTENDED: `${plo5AttributesPrefixUpper} GET ROOMS EXTENDED`,
    PLO5_GET_CONFIG: `${plo5AttributesPrefixUpper} GET CONFIG`,
    PLO5_CLOSE_ROOM: `${plo5AttributesPrefixUpper} CLOSE ROOM`,
    PLO5_KICK_OFF_PLAYER: `${plo5AttributesPrefixUpper} KICK OFF PLAYER`,
};

function applyPlo5Attributes(span: Span, id?: string) {
    span?.setAttributes({
        [Plo5OperationAttributes.PLO5_RECORD_ID]: id,
    });
}
export const closeRoomsFetchError: ServerResponseError = {message: 'Failed to fetch Rooms', code: null, values: null};

@injectable()
export class Plo5GameManagementApiService implements IGameManagementApiService {
    private readonly _mapper: Mapper;
    private readonly _apiRouteService: ApiRoutesService;
    private readonly _tracingService: ITracingService;
    protected readonly _restService: RestService;
    protected readonly _userManager: UserManagerExtended;

    constructor(
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService,
        @inject(ServiceTypes.RestService) restService: RestService,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.TracingService) tracingService: ITracingService,
        @inject(ServiceTypes.UserManager) userManager: UserManagerExtended
    ) {
        this._restService = restService;
        this._apiRouteService = apiRouteService;
        this._mapper = mapper;
        this._tracingService = tracingService;
        this._userManager = userManager;
    }

    public getGameTemplates(requestPayload: GetGameTemplatesPayload): Observable<GameTemplatesServiceResponsePayload> {
        const tracer = this._tracingService.getTracer();

        return this.getUser().pipe(
            mergeMap(u =>
                tracer.startActiveSpan(plo5Operations.PLO5_GET_TEMPLATES_EXTENDED, span => {
                    this.applyPlo5AttributesToSpan(span, u.basicInfo);

                    const templateIds$ = this.getGameTemplateIds(span);
                    const enabledGameTemplateIds$ = this.getGameTemplateEnabledIds(span);
                    const blindResponse$: Observable<BlindConfigServiceResponsePayload> = this.getBlindConfig(span);
                    const gameType: ManagedGameType = requestPayload.gameType;

                    return forkJoin([templateIds$, enabledGameTemplateIds$, blindResponse$]).pipe(
                        mergeMap(([templateIdsResponse, enabledGameTemplateIdsResponse, blindResponse]) => {
                            if (
                                templateIdsResponse.status === ServerResponseStatus.Success &&
                                enabledGameTemplateIdsResponse.status === ServerResponseStatus.Success &&
                                blindResponse.status === ServerResponseStatus.Success
                            ) {
                                const allTemplateIds: number[] = templateIdsResponse?.responsePayload?.available_template_id_list ?? [];
                                const templateIds: number[] =
                                    allTemplateIds
                                        ?.sort((a, b) => b - a)
                                        ?.slice(
                                            (requestPayload.page - 1) * requestPayload.size,
                                            requestPayload.page * requestPayload.size
                                        ) ?? [];
                                const enabledGameTemplateIds: number[] =
                                    enabledGameTemplateIdsResponse?.responsePayload?.enabled_templates_ids;
                                const blinds: GameBlindConfig[] = blindResponse?.responsePayload?.data?.blind_list;
                                const templates$ = templateIds?.map(id =>
                                    this.getGameTemplateWithRoomsAndPlayers(
                                        {id},
                                        enabledGameTemplateIds?.includes(id),
                                        gameType,
                                        blinds,
                                        span
                                    )
                                );
                                return templates$?.length
                                    ? forkJoin(templates$).pipe(
                                          mergeMap(templateResponses => {
                                              const failedResponse = this.getFailedResponse(templateResponses);
                                              if (!failedResponse) {
                                                  const response = {
                                                      status: ServerResponseStatus.Success,
                                                      requestPayload,
                                                      responsePayload: {
                                                          items: templateResponses?.map(r => r.responsePayload),
                                                          total: allTemplateIds?.length,
                                                      },
                                                  };
                                                  this.endSpanForHandledServiceRequest(span, response);
                                                  return of(response);
                                              } else {
                                                  this.endSpanForHandledServiceRequest(span, failedResponse);
                                                  return this.getFailedServiceResponse(requestPayload, failedResponse.errors?.[0]);
                                              }
                                          })
                                      )
                                    : of(null);
                            } else {
                                const failedResponse = this.getFailedResponse([
                                    templateIdsResponse,
                                    enabledGameTemplateIdsResponse,
                                    blindResponse,
                                ]);
                                this.endSpanForHandledServiceRequest(span, failedResponse);
                                return this.getFailedServiceResponse(requestPayload, null);
                            }
                        }),
                        catchError(error => {
                            this.endSpanForUnhandledException(span, error);
                            return this.getFailedServiceResponse(requestPayload, error);
                        })
                    );
                })
            )
        );
    }

    public getGameTemplateExtended(
        requestPayload: GetGameTemplatePayload
    ): Observable<ServiceResponsePayload<GetGameTemplatePayload, GameTemplate>> {
        const tracer = this._tracingService.getTracer();

        return this.getUser().pipe(
            mergeMap(u =>
                tracer.startActiveSpan(plo5Operations.PLO5_GET_TEMPLATE_EXTENDED, span => {
                    this.applyPlo5AttributesToSpan(span, u.basicInfo, requestPayload.id);

                    const enabledGameTemplateIds$ = this.getGameTemplateEnabledIds(span);
                    const blindResponse$: Observable<BlindConfigServiceResponsePayload> = this.getBlindConfig(span);
                    const gameType: ManagedGameType = requestPayload.gameType;

                    return forkJoin([enabledGameTemplateIds$, blindResponse$]).pipe(
                        mergeMap(([enabledGameTemplateIdsResponse, blindResponse]) => {
                            const failedResponse = this.getFailedResponse([enabledGameTemplateIdsResponse, blindResponse]);

                            if (!failedResponse) {
                                const enabledGameTemplateIds: number[] =
                                    enabledGameTemplateIdsResponse?.responsePayload?.enabled_templates_ids;
                                const blinds: GameBlindConfig[] = blindResponse?.responsePayload?.data?.blind_list;
                                const templateId = requestPayload.id;
                                return this.getGameTemplateWithRoomsAndPlayers(
                                    requestPayload,
                                    enabledGameTemplateIds?.includes(templateId),
                                    gameType,
                                    blinds,
                                    span
                                );
                            } else {
                                this.endSpanForHandledServiceRequest(span, failedResponse);
                                return this.getFailedServiceResponse(requestPayload, null);
                            }
                        }),
                        catchError(error => {
                            this.endSpanForUnhandledException(span, error);
                            return this.getFailedServiceResponse(requestPayload, error);
                        })
                    );
                })
            )
        );
    }

    public createGameTemplate(requestPayload: CreateGameTemplatePayload): Observable<CreateTemplateServiceResponsePayload> {
        const createGameTemplateEndpoint = this._apiRouteService.getPlo5GameTemplateCreateEndpoint();
        const tracer = this._tracingService.getTracer();

        return this.getUser().pipe(
            mergeMap(u =>
                tracer.startActiveSpan(plo5Operations.PLO5_CREATE_TEMPLATE, span => {
                    this.applyPlo5AttributesToSpan(span, u.basicInfo);
                    return this._restService
                        .post(
                            this.buildRestRequest({
                                endpoint: createGameTemplateEndpoint,
                                body: requestPayload.template,
                            }),
                            span
                        )
                        .pipe(
                            map(response => {
                                this.endSpanForHandledServiceRequest(span, response);
                                return {
                                    ...response,
                                    requestPayload: null,
                                    responsePayload: null,
                                };
                            }),
                            catchError(error => {
                                this.endSpanForUnhandledException(span, error);
                                return this.getFailedServiceResponse(null, error);
                            })
                        );
                })
            )
        );
    }

    public editGameTemplateWithCloseRooms(requestPayload: EditGameTemplatePayload): Observable<EditTemplateServiceResponsePayload> {
        const tracer = this._tracingService.getTracer();

        return this.getUser().pipe(
            mergeMap(u =>
                tracer.startActiveSpan(plo5Operations.PLO5_EDIT_TEMPLATE_WITH_CLOSE_ROOMS, span => {
                    this.applyPlo5AttributesToSpan(span, u.basicInfo, requestPayload.id);
                    return this.editGameTemplate(requestPayload, span).pipe(
                        mergeMap(response => {
                            if (response.status === ServerResponseStatus.Success) {
                                return this.closeGameTemplateRooms(
                                    {
                                        templateId: Number(requestPayload.id),
                                    },
                                    span
                                ).pipe(
                                    mergeMap(closeResponse => {
                                        const res = {
                                            ...response,
                                            requestPayload,
                                            responsePayload: {failedRoomIds: closeResponse?.responsePayload?.failedRoomIds},
                                        };

                                        this.endSpanForHandledServiceRequest(span, res);
                                        return of(res);
                                    })
                                );
                            } else {
                                this.endSpanForHandledServiceRequest(span, response);
                                return of(response);
                            }
                        }),
                        catchError(error => {
                            this.endSpanForUnhandledException(span, error);
                            return this.getFailedServiceResponse(requestPayload, error);
                        })
                    );
                })
            )
        );
    }

    public getExtendedRooms(filterInput: GameRoomFilterInput, gameType: ManagedGameType): Observable<RoomServiceResponsePayload> {
        const tracer = this._tracingService.getTracer();

        return this.getUser().pipe(
            mergeMap(u =>
                tracer.startActiveSpan(plo5Operations.PLO5_GET_ROOMS_EXTENDED, span => {
                    this.applyPlo5AttributesToSpan(span, u.basicInfo);
                    return this.getRooms(filterInput, span).pipe(
                        mergeMap(roomsResponse => {
                            const serverGameRooms: GameRoomResponseListItem[] = roomsResponse?.responsePayload?.data?.RoomList ?? [];

                            let result: Observable<RoomServiceResponsePayload>;
                            if (roomsResponse?.status === ServerResponseStatus.Success) {
                                const templates: Record<number, Observable<TemplateServiceResponsePayload>> = this.getTemplatesForRooms(
                                    serverGameRooms,
                                    span
                                );
                                result = forkJoin({
                                    ...templates,
                                    blinds: this.getBlindConfig(span),
                                }).pipe<RoomServiceResponsePayload>(
                                    mergeMap((dataResponses: GameRoomsAdditionalData) => {
                                        let responsePayload: RoomServiceResponsePayload;
                                        const failedResponse = this.getFailedResponse(Object.values(dataResponses));
                                        if (!failedResponse) {
                                            const gameRooms: GameRoomItemPage = this.combineRoomsWithTemplatesAndBlind(
                                                roomsResponse?.requestPayload,
                                                roomsResponse?.responsePayload,
                                                dataResponses,
                                                gameType
                                            );
                                            responsePayload = {
                                                status: ServerResponseStatus.Success,
                                                requestPayload: filterInput,
                                                responsePayload: gameRooms,
                                            } as RoomServiceResponsePayload;
                                        } else {
                                            responsePayload = {...failedResponse, requestPayload: filterInput, responsePayload: null};
                                        }
                                        this.endSpanForHandledServiceRequest(span, responsePayload);
                                        return of(responsePayload);
                                    })
                                );
                            } else {
                                result = of({
                                    ...roomsResponse,
                                    requestPayload: filterInput,
                                    responsePayload: null,
                                });
                                this.endSpanForHandledServiceRequest(span, roomsResponse);
                            }

                            return result;
                        }),
                        catchError(err => {
                            this.endSpanForUnhandledException(span, err);
                            return this.getFailedServiceResponse<GameRoomFilterInput>(filterInput, err);
                        })
                    );
                })
            )
        );
    }

    public getBlindConfig(parentSpan?: Span): Observable<BlindConfigServiceResponsePayload> {
        const endpoint = this._apiRouteService.getPlo5GameBlindConfigEndpoint();

        const tracer = this._tracingService.getTracer();
        const ctx = this._tracingService.setSpanOnContext(parentSpan);

        const getBlindConfig = (span: Span) =>
            this._restService.get(this.buildRestRequest({endpoint}), span).pipe(
                map(response => {
                    const res: BlindConfigServiceResponsePayload = {
                        ...response,
                        requestPayload: null,
                        responsePayload:
                            response.status === ServerResponseStatus.Success
                                ? (response.responsePayload.response as GameBlindConfigResponse)
                                : null,
                    };

                    this.endSpanForHandledServiceRequest(span, response);
                    return res;
                }),
                catchError(err => {
                    this.endSpanForUnhandledException(span, err);
                    return this.getFailedServiceResponse(null, err);
                })
            );

        return parentSpan
            ? getBlindConfig(parentSpan)
            : this.getUser().pipe(
                  mergeMap(u =>
                      tracer.startActiveSpan(plo5Operations.PLO5_GET_CONFIG, undefined, ctx, span => {
                          this.applyPlo5AttributesToSpan(span, u.basicInfo);
                          return getBlindConfig(span);
                      })
                  )
              );
    }

    public closeGameRoom(requestPayload: CloseGameRoomRequestPayload, parentSpan?: Span): Observable<CloseRoomServiceResponsePayload> {
        const endpoint = this._apiRouteService.getPlo5GameRoomCloseEndpoint(requestPayload.roomId, requestPayload.ownerId);

        const tracer = this._tracingService.getTracer();
        const ctx = this._tracingService.setSpanOnContext(parentSpan);

        const closeGameRoom = (span: Span) =>
            this._restService.delete(this.buildRestRequest({endpoint}), span).pipe(
                map(r => {
                    this.endSpanForHandledServiceRequest(span, r);
                    return {...r, requestPayload, responsePayload: null};
                }),
                catchError(err => {
                    this.endSpanForUnhandledException(span, err);
                    return this.getFailedServiceResponse<CloseGameRoomRequestPayload>(requestPayload, err);
                })
            );

        return parentSpan
            ? closeGameRoom(parentSpan)
            : this.getUser().pipe(
                  mergeMap(u =>
                      tracer.startActiveSpan(plo5Operations.PLO5_CLOSE_ROOM, undefined, ctx, span => {
                          this.applyPlo5AttributesToSpan(span, u.basicInfo, requestPayload.roomId);

                          return closeGameRoom(span);
                      })
                  )
              );
    }

    public kickoffPlayerFromRoom(requestPayload: KickoffPlayerRequestPayload): Observable<KickoffPlayerServiceResponsePayload> {
        const endpoint = this._apiRouteService.getPlo5KickoffPlayerEndpoint(requestPayload.userId);

        const tracer = this._tracingService.getTracer();

        return this.getUser().pipe(
            mergeMap(u =>
                tracer.startActiveSpan(plo5Operations.PLO5_KICK_OFF_PLAYER, span => {
                    this.applyPlo5AttributesToSpan(span, u.basicInfo, `room: ${requestPayload.roomId} player: ${requestPayload.userId}`);
                    return this._restService.post({endpoint, body: {room_id: requestPayload.roomId}, withCredentials: false}, span).pipe(
                        map(r => {
                            this.endSpanForHandledServiceRequest(span, r);
                            return {...r, requestPayload, responsePayload: null};
                        }),
                        catchError(err => {
                            this.endSpanForUnhandledException(span, err);
                            return this.getFailedServiceResponse<KickoffPlayerRequestPayload>(requestPayload, err);
                        })
                    );
                })
            )
        );
    }

    public enableGameTemplate(requestPayload: EnableGameTemplatePayload): Observable<EnableTemplateServiceResponsePayload> {
        const endpoint = this._apiRouteService.getPlo5GameTemplateEnableEndpoint();

        const tracer = this._tracingService.getTracer();

        return this.getUser().pipe(
            mergeMap(u =>
                tracer.startActiveSpan(plo5Operations.PLO5_ENABLE_TEMPLATE, span => {
                    this.applyPlo5AttributesToSpan(span, u.basicInfo, requestPayload.enableTemplateIds?.join());
                    const body: EnableGameTemplateRequestBody = {enable_template_id_list: requestPayload.enableTemplateIds};
                    return this._restService
                        .post(
                            this.buildRestRequest({
                                endpoint,
                                body,
                            })
                        )
                        .pipe(
                            map(r => {
                                this.endSpanForHandledServiceRequest(span, r);
                                return {...r, requestPayload, responsePayload: null};
                            }),
                            catchError(err => {
                                this.endSpanForUnhandledException(span, err);
                                return this.getFailedServiceResponse<EnableGameTemplatePayload>(requestPayload, err);
                            })
                        );
                })
            )
        );
    }

    public disableGameTemplatesWithCloseRooms(
        requestPayload: DisableGameTemplatePayload
    ): Observable<DisableTemplateServiceResponsePayload> {
        const tracer = this._tracingService.getTracer();

        return this.getUser().pipe(
            mergeMap(u =>
                tracer.startActiveSpan(plo5Operations.PLO5_DISABLE_TEMPLATE_WITH_CLOSE_ROOMS, span => {
                    this.applyPlo5AttributesToSpan(span, u.basicInfo, requestPayload.disableTemplateIds?.join());
                    return this.disableGameTemplate(requestPayload, span).pipe(
                        mergeMap(response => {
                            let result: Observable<DisableTemplateServiceResponsePayload>;
                            if (response?.status === ServerResponseStatus.Success) {
                                const closeGameTemplatesRooms = requestPayload?.disableTemplateIds?.map(templateId =>
                                    this.closeGameTemplateRooms({templateId}, span)
                                );
                                result = forkJoin(closeGameTemplatesRooms).pipe(
                                    mergeMap(responses => {
                                        const res = this.combineCloseRoomsResponsesForTemplateDisable(response, responses);
                                        const failedResponse = this.getFailedResponse(responses);
                                        this.endSpanForHandledServiceRequest(span, failedResponse ?? responses?.[0]);
                                        return res;
                                    })
                                );
                            } else {
                                this.endSpanForHandledServiceRequest(span, response);
                                result = of(response);
                            }
                            return result;
                        }),
                        catchError(err => {
                            this.endSpanForUnhandledException(span, err);
                            return this.getFailedServiceResponse(requestPayload, err);
                        })
                    );
                })
            )
        );
    }

    private getGameTemplate(
        requestPayload: GetGameTemplatePayload,
        parentSpan: Span
    ): Observable<ServiceResponsePayload<GetGameTemplatePayload, GameTemplateResponse>> {
        const templateEndpoint = this._apiRouteService.getPlo5GameTemplateEndpoint(requestPayload.id);

        return this._restService.get(this.buildRestRequest({endpoint: templateEndpoint}), parentSpan).pipe(
            map(response => {
                this.endSpanForHandledServiceRequest(parentSpan, response);
                return {
                    ...response,
                    requestPayload,
                    responsePayload: response.responsePayload.response as GameTemplateResponse,
                };
            }),
            catchError(error => {
                this.endSpanForUnhandledException(parentSpan, error);
                return this.getFailedServiceResponse(null, error);
            })
        );
    }

    private editGameTemplate(
        requestPayload: EditGameTemplatePayload,
        parentSpan: Span
    ): Observable<ServiceResponsePayload<EditGameTemplatePayload, EditGameTemplateResponsePayload>> {
        const editGameTemplateEndpoint = this._apiRouteService.getPlo5GameTemplateEditEndpoint(requestPayload.id);

        return this._restService
            .put(
                this.buildRestRequest({
                    endpoint: editGameTemplateEndpoint,
                    body: requestPayload.template,
                }),
                parentSpan
            )
            .pipe(
                map(response => {
                    return {
                        ...response,
                        requestPayload: null,
                        responsePayload: null,
                    };
                }),
                catchError(error => {
                    return this.getFailedServiceResponse(null, error);
                })
            );
    }

    private combineCloseRoomsResponsesForTemplateDisable(
        templateDisableResponse: ServiceResponsePayload<DisableGameTemplatePayload, null>,
        closeRoomsResponses: CloseTemplateRoomsServiceResponsePayload[]
    ): Observable<DisableTemplateServiceResponsePayload> {
        const serviceResponsePayload: DisableTemplateServiceResponsePayload = closeRoomsResponses?.reduce(
            (previousValue, currentValue) => {
                if (currentValue?.errors?.length) {
                    previousValue.errors.push(...currentValue?.errors);
                }
                if (currentValue.responsePayload?.failedRoomIds?.length) {
                    previousValue.responsePayload.failedRoomIds.push(...currentValue.responsePayload.failedRoomIds);
                }
                return previousValue;
            },
            {...templateDisableResponse, errors: [], responsePayload: {failedRoomIds: []}}
        );
        return of(serviceResponsePayload);
    }

    private closeGameTemplateRooms(
        requestPayload: CloseTemplateRoomsPayload,
        parentSpan: Span
    ): Observable<CloseTemplateRoomsServiceResponsePayload> {
        const maxPageSize = 10_000;
        return this.getRooms({template_id: requestPayload.templateId, per_page_count: maxPageSize}, parentSpan).pipe(
            mergeMap(response => {
                let result: Observable<CloseTemplateRoomsServiceResponsePayload>;
                const rooms: GameRoomResponseListItem[] = response.responsePayload?.data?.RoomList;
                if (response.status === ServerResponseStatus.Success && rooms?.length) {
                    result = this.closeGameRooms(rooms, parentSpan).pipe(
                        mergeMap(failedRoomIds => {
                            const res = failedRoomIds?.length
                                ? {status: ServerResponseStatus.Failed, requestPayload, responsePayload: {failedRoomIds}}
                                : {status: ServerResponseStatus.Success, requestPayload, responsePayload: {failedRoomIds: []}};
                            return of(res);
                        })
                    );
                } else {
                    result = this.getFailedServiceResponse(requestPayload, closeRoomsFetchError);
                }
                return result;
            })
        );
    }

    private closeGameRooms(rooms: GameRoomResponseListItem[], parentSpan: Span): Observable<number[]> {
        const failedRoomIds: number[] = [];
        const closeRooms$ = rooms?.map(room =>
            this.closeGameRoom({roomId: room.RoomId.toString(), ownerId: room.CreatePlayerId.toString()}, parentSpan).pipe(
                map(r => {
                    if (r.status !== ServerResponseStatus.Success) {
                        failedRoomIds.push(room?.RoomId);
                    }
                    return r;
                })
            )
        );
        const res = closeRooms$?.length ? forkJoin(closeRooms$).pipe(mergeMap(() => of(failedRoomIds))) : of(null);
        return res;
    }

    private getGameTemplateWithRoomsAndPlayers(
        requestPayload: GetGameTemplatePayload,
        isEnabled: boolean,
        gameType: ManagedGameType,
        blinds: GameBlindConfig[],
        parentSpan: Span
    ): Observable<ServiceResponsePayload<GetGameTemplatePayload, GameTemplate>> {
        const template$ = this.getGameTemplate({id: requestPayload.id}, parentSpan);
        let templateRooms$: Observable<ServiceResponsePayload<GameRoomFilterInput, GameRoomResponse>> = of(null);
        if (isEnabled) {
            templateRooms$ = this.getRooms({template_id: requestPayload.id}, parentSpan);
        }
        let templatePlayers$: Observable<ServiceResponsePayload<void, GameTemplatePlayersResponse>> = of(null);
        if (isEnabled) {
            templatePlayers$ = this.getGameTemplatePlayers({id: requestPayload.id}, parentSpan);
        }
        return forkJoin([template$, templateRooms$, templatePlayers$]).pipe(
            mergeMap(([templateResponse, roomsResponse, playersResponse]) => {
                if (
                    templateResponse.status === ServerResponseStatus.Success &&
                    (!isEnabled ||
                        (playersResponse?.status === ServerResponseStatus.Success &&
                            roomsResponse?.status === ServerResponseStatus.Success))
                ) {
                    const rooms: GameRoomResponseListItem[] = roomsResponse?.responsePayload?.data?.RoomList;
                    const template = this._mapper.map(templateResponse.responsePayload, GameTemplateResponse, GameTemplate, {
                        extraArgs: () =>
                            ({
                                rooms: rooms?.map((i: GameRoomResponseListItem) => i?.RoomId),
                                players: playersResponse?.responsePayload?.data?.uids,
                                blinds,
                                isEnabled,
                                gameType,
                            } as GameTemplateMappingArgs),
                    });

                    const response = {
                        status: ServerResponseStatus.Success,
                        requestPayload: requestPayload,
                        responsePayload: template,
                    };

                    return of(response);
                } else {
                    const failedResponse = this.getFailedResponse([templateResponse, roomsResponse, playersResponse]);
                    return this.getFailedServiceResponse(requestPayload, failedResponse.errors?.[0]);
                }
            }),
            catchError(error => {
                return this.getFailedServiceResponse(requestPayload, error);
            })
        );
    }

    private disableGameTemplate(
        requestPayload: DisableGameTemplatePayload,
        parentSpan: Span
    ): Observable<ServiceResponsePayload<DisableGameTemplatePayload, null>> {
        const endpoint = this._apiRouteService.getPlo5GameTemplateEnableEndpoint();
        const body: DisableGameTemplateRequestBody = {disable_template_id_list: requestPayload.disableTemplateIds};

        return this._restService
            .delete(
                this.buildRestRequest({
                    endpoint,
                    body,
                }),
                parentSpan
            )
            .pipe(
                map(r => {
                    let result: ServiceResponsePayload<DisableGameTemplatePayload, null>;
                    const response: DisableGameTemplateResponse = r?.responsePayload?.response as DisableGameTemplateResponse;
                    if (
                        r.status === ServerResponseStatus.Success &&
                        !requestPayload.disableTemplateIds.every(i => response.disabled_templates_ids.includes(i))
                    ) {
                        result = {
                            status: ServerResponseStatus.Failed,
                            message: 'Not full templates disable',
                            requestPayload,
                            responsePayload: null,
                        };
                    } else {
                        result = {...r, requestPayload, responsePayload: null};
                    }

                    return result;
                }),
                catchError(err => {
                    return this.getFailedServiceResponse<DisableGameTemplatePayload>(requestPayload, err);
                })
            );
    }

    private getRooms(
        filterInput: GameRoomFilterInput,
        parentSpan: Span
    ): Observable<ServiceResponsePayload<GameRoomFilterInput, GameRoomResponse>> {
        const endpoint = this._apiRouteService.getPlo5GameRoomsEndpoint();

        return this._restService.post(this.buildRestRequest({endpoint, body: filterInput}), parentSpan).pipe(
            map(response => {
                const res = {
                    ...response,
                    requestPayload: filterInput,
                    responsePayload:
                        response.status === ServerResponseStatus.Success ? (response?.responsePayload?.response as GameRoomResponse) : null,
                };
                return res;
            }),
            catchError(error => {
                return this.getFailedServiceResponse(filterInput, error);
            })
        );
    }

    private getTemplatesForRooms(serverGameRooms: GameRoomResponseListItem[], parentSpan: Span) {
        return serverGameRooms?.reduce((previousValue, currentValue) => {
            const templateId = currentValue.TemplateId;
            if (!previousValue[templateId]) {
                previousValue[templateId] = this.getGameTemplate({id: templateId}, parentSpan);
            }
            return previousValue;
        }, {} as Record<number, Observable<TemplateServiceResponsePayload>>);
    }

    private combineRoomsWithTemplatesAndBlind(
        filterInput: GameRoomFilterInput,
        gameRoomResponse: GameRoomResponse,
        additionalData: GameRoomsAdditionalData,
        gameType: ManagedGameType
    ): GameRoomItemPage {
        return this._mapper.map(gameRoomResponse, GameRoomResponse, GameRoomItemPage, {
            extraArgs: (): GameRoomMappingArgs => ({
                additionalData,
                gameType,
                filterInput,
            }),
        });
    }

    private getGameTemplateIds(parentSpan: Span): Observable<ServiceResponsePayload<void, GameTemplateIdsResponse>> {
        const templateListEndpoint = this._apiRouteService.getPlo5GameTemplateListEndpoint();

        return this._restService.get(this.buildRestRequest({endpoint: templateListEndpoint}), parentSpan).pipe(
            map(response => {
                const res: ServiceResponsePayload<void, GameTemplateIdsResponse> = {
                    ...response,
                    requestPayload: null,
                    responsePayload: response.responsePayload.response as GameTemplateIdsResponse,
                };

                return res;
            }),
            catchError(error => {
                return this.getFailedServiceResponse(null, error);
            })
        );
    }

    private getGameTemplateEnabledIds(parentSpan: Span): Observable<ServiceResponsePayload<void, GameTemplateEnabledIdsResponse>> {
        const enabledGameTemplateListEndpoint = this._apiRouteService.getPlo5GameTemplateEnabledListEndpoint();

        return this._restService.get(this.buildRestRequest({endpoint: enabledGameTemplateListEndpoint}), parentSpan).pipe(
            map(response => {
                const res: ServiceResponsePayload<void, GameTemplateEnabledIdsResponse> = {
                    ...response,
                    requestPayload: null,
                    responsePayload: response.responsePayload.response as GameTemplateEnabledIdsResponse,
                };

                return res;
            }),
            catchError(error => {
                return this.getFailedServiceResponse(null, error);
            })
        );
    }

    private getGameTemplatePlayers(
        requestPayload: GetGameTemplatePlayersPayload,
        parentSpan: Span
    ): Observable<ServiceResponsePayload<void, GameTemplatePlayersResponse>> {
        const templatePlayersEndpoint = this._apiRouteService.getPlo5GameTemplatePlayerListEndpoint(requestPayload.id);
        return this._restService.get(this.buildRestRequest({endpoint: templatePlayersEndpoint}), parentSpan).pipe(
            map(response => {
                const res: ServiceResponsePayload<void, GameTemplatePlayersResponse> = {
                    ...response,
                    requestPayload: null,
                    responsePayload: response.responsePayload.response as GameTemplatePlayersResponse,
                };

                return res;
            }),
            catchError(error => {
                return this.getFailedServiceResponse(null, error);
            })
        );
    }

    private getFailedResponse(responses: IServiceErrorResponsePayload[]): IServiceErrorResponsePayload {
        return responses?.find(r => r.status !== ServerResponseStatus.Success);
    }

    private getFailedServiceResponse<TRequest>(
        requestPayload: TRequest,
        error?: ServerResponseError
    ): Observable<ServiceResponsePayload<TRequest, null>> {
        return of({
            status: ServerResponseStatus.Failed,
            message: error?.message,
            errors: error ? [error] : undefined,
            requestPayload,
            responsePayload: null,
        });
    }

    private buildRestRequest(request: RestRequest): RestRequest {
        return {
            ...request,
            withCredentials: false,
        };
    }

    private endSpanForHandledServiceRequest(span: Span, response: IServiceErrorResponsePayload) {
        if (response.status === ServerResponseStatus.Success) {
            this._tracingService.endSpanOk(span);
        } else {
            this._tracingService.endSpanFailed(span, response?.message);
        }
    }

    private endSpanForUnhandledException(span: Span, error: any) {
        this._tracingService.endSpanFailed(span, error?.message);
    }

    private getUser() {
        return this._userManager.getUserExtended();
    }

    private applyPlo5AttributesToSpan(span: Span, user: TraceUser, id?: number | string) {
        applyLocationAttrs(span);
        applyUserAttrs(span, user);
        if (id) {
            applyPlo5Attributes(span, id?.toString());
        }
    }
}
