import {ModuleName, SubmoduleName} from '@models/modules';

export enum PermissionEnum {
    Create = 0,
    Read = 1,
    Update = 2,
    Delete = 3,
    All = 4, // this is client only permission (no support on auth server side, int value can be changed in case of conflicts)
}

export interface PermissionAccessibleItem {
    permissions: PermissionEnum[];
    moduleName: ModuleName;
    submoduleName?: SubmoduleName;
}
