import {defineMessages} from 'react-intl';

import {KycReasonCode} from '@models/generated/graphql';

export const localizedKYCReasonCodes = defineMessages<KycReasonCode>({
    [KycReasonCode.Payment]: {
        id: 'KYCInitReasonCodes_Payment',
        defaultMessage: 'KYC Payment',
    },
    [KycReasonCode.Security]: {
        id: 'KYCInitReasonCodes_Security',
        defaultMessage: 'KYC Security',
    },
    [KycReasonCode.PePs]: {
        id: 'KYCInitReasonCodes_PEPs',
        defaultMessage: 'KYC PEPs',
    },
    [KycReasonCode.CcDep]: {
        id: 'KYCInitReasonCodes_CCDep',
        defaultMessage: 'KYC CC Dep',
    },
    [KycReasonCode.Proac]: {
        id: 'KYCInitReasonCodes_Proac',
        defaultMessage: 'KYC Proac',
    },
    [KycReasonCode.Others]: {
        id: 'KYCInitReasonCodes_Others',
        defaultMessage: 'KYC Others',
    },
    [KycReasonCode.Hrj]: {
        id: 'KYCReasonCodes_Hrj',
        defaultMessage: 'KYC HRJ',
    },
    [KycReasonCode.NoEmail]: {
        id: 'KYCReasonCodes_NoEmail',
        defaultMessage: 'KYC - No Email',
    },
    [KycReasonCode.Referral]: {
        id: 'KYCReasonCodes_Referral',
        defaultMessage: 'Referral Customer',
    },
});
