import React, {useEffect} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {ErrorAlert} from '@components/alerts/InfoAlert';
import Button from '@components/button/Buttons';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {useService} from '@inversify';
import {TransactionType} from '@models/generated/graphql';
import {TransactionViewModel} from '@models/transaction';

import {
    bulkActionsActions,
    BulkStepProps,
    IBulkApplyStrategy,
    IBulkStrategy,
    itemsSelector,
    ValidationResult,
    validationResultsSelector,
    ValidationStrategyResponse,
} from '../../block-bulk-actions';
import {ApplyManualTransactionsRequest} from '../services/applyStrategy';
import {ValidationManualTransactionRequest} from '../services/validationStrategy';
import {BulkDebitCreditAddModel} from '../types';

import {BulkStepValidationContentFailed, BulkStepValidationContentSuccessful} from './BulkTransactionValidationStepContent';

const localized = defineMessages({
    title: {
        id: 'BulkStepValidationManualTransaction_title',
        defaultMessage: 'Validation Result',
    },
    cancel: {
        id: 'BulkStepValidationManualTransaction_cancel',
        defaultMessage: 'Cancel',
    },
    apply: {
        id: 'BulkStepValidationManualTransaction_apply',
        defaultMessage: 'Apply',
    },
    fileEmpty: {
        id: 'BulkStepValidationManualTransaction_fileEmpty',
        defaultMessage: "File doesn't have any transactions",
    },
    fileValidationFailed: {
        id: 'BulkStepValidationManualTransaction_fileValidationFailed',
        defaultMessage: "File doesn't pass validation",
    },
    fileValidationSuccess: {
        id: 'BulkStepValidationManualTransaction_fileValidationSuccess',
        defaultMessage: 'File has been validated',
    },
});

export const BulkTransactionManualValidationStepModal = ({onNext, onPrev, type}: BulkStepProps & {type: TransactionType}) => {
    const validationStrategy = useService<IBulkStrategy<ValidationManualTransactionRequest, ValidationStrategyResponse>>(
        'ValidationManualTransactionsStrategy'
    );
    const applyStrategy = useService<IBulkApplyStrategy<ApplyManualTransactionsRequest>>('ApplyManualTransactionsStrategy');
    const dispatch = useDispatch();
    const validationResults: ValidationResult<TransactionViewModel>[] = useSelector((state: RootState) =>
        validationResultsSelector<TransactionViewModel>(state)
    );
    const transactions: BulkDebitCreditAddModel[] = useSelector(itemsSelector) as BulkDebitCreditAddModel[];

    useEffect(() => {
        const request: ValidationManualTransactionRequest = {transactions: transactions, type};
        dispatch(bulkActionsActions.validate({strategy: validationStrategy, request}));
    }, []);

    const handleCancelClick = () => {
        onPrev();
    };

    const handleNextClick = () => {
        const request: ApplyManualTransactionsRequest = {
            transactions: validTransactions.map((r: ValidationResult) => r.item as BulkDebitCreditAddModel),
        };
        dispatch(bulkActionsActions.apply({strategy: applyStrategy, request}));
        onNext();
    };

    const invalidTransactions = validationResults?.filter(r => r.errorCodes.length > 0);
    const validTransactions = validationResults?.filter(r => r.errorCodes.length === 0);
    const isNextDisabled = !validationResults?.length || invalidTransactions?.length > 0;

    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent>
                {validationResults?.length > 0 ? (
                    invalidTransactions?.length > 0 ? (
                        <BulkStepValidationContentFailed
                            validTransactions={validTransactions}
                            invalidTransactions={invalidTransactions}
                            gridColumns={['rowIndex', 'uid', 'error']}
                            alertTitle={localized.fileValidationFailed}
                        />
                    ) : (
                        <BulkStepValidationContentSuccessful
                            validTransactions={validTransactions}
                            alertTitle={localized.fileValidationSuccess}
                        />
                    )
                ) : validationResults?.length === 0 ? (
                    <ErrorAlert title={localized.fileEmpty} />
                ) : (
                    <></>
                )}
            </BulkModalContent>
            <ModalFooter>
                <Button label={localized.cancel} onClick={handleCancelClick} />
                <Button color="primary" label={localized.apply} onClick={handleNextClick} disabled={isNextDisabled} />
            </ModalFooter>
        </>
    );
};
