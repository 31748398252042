import {baseFilterKeys} from '@redux/entity';

export enum CasinoSourceType {
    Internal = 'Internal',
    External = 'External',
}

export enum CasinoAggregator {
    Bragg = 'bragg',
    VisionLink = 'visionlink',
}

export class CasinoSupplier {
    name?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
    code?: string;
}

export class CasinoSupplierData {
    id: number;
    attributes: CasinoSupplier;
}

export class CasinoGameSupplier {
    data: CasinoSupplierData;
}

export class CasinoSupplierResponse {
    data: CasinoSupplierData[];
    meta?: CasinoCmsMeta;
}

export class CasinoCmsPagination {
    page?: number;
    pageSize?: number;
    pageCount?: number;
    total?: number;
}

export class CasinoCmsMeta {
    pagination?: CasinoCmsPagination;
}

export class CasinoGame {
    code?: string;
    title?: string;
    isActive?: boolean;
    createdAt?: string;
    updatedAt?: string;
    aggregator?: CasinoAggregator;
    supplier?: CasinoGameSupplier;
}

export class CasinoGameData {
    id: number;
    attributes: CasinoGame;
}

export class CasinoGameExternalResponse {
    data?: CasinoGameData[];
    meta?: CasinoCmsMeta;
}

export type CasinoGameInternalResponse = {
    items: CasinoGame[];
    total: number;
};

const casinoGameFilterKeys = [...baseFilterKeys, 'source_type', 'title', 'supplier_codes', 'aggregators'] as const;

export type CasinoGameFilterKeys = (typeof casinoGameFilterKeys)[number];

export type CasinoGameFields = keyof CasinoGame;

const casinoSupplierFilterKeys = [...baseFilterKeys, 'name'] as const;

export type CasinoSupplierFilterKeys = (typeof casinoSupplierFilterKeys)[number];

export type CasinoSupplierFields = keyof CasinoSupplier;
