import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme, SystemColorsEnum} from '@style';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    circle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: '50%',
        color: theme.palette.warning.contrastText,
    },
    circleLarge: {
        width: theme.spacing(6),
        minWidth: theme.spacing(6),
        height: theme.spacing(6),
    },
    circleMedium: {
        width: theme.spacing(4),
        minWidth: theme.spacing(4),
        height: theme.spacing(4),
    },
    circleSmall: {
        width: theme.spacing(3),
        minWidth: theme.spacing(3),
        height: theme.spacing(3),
    },
    circleLabelSmall: {
        fontSize: '12px',
        lineHeight: '23px',
    },
    circleLabel: {
        top: theme.spacing(2),
        fontSize: '20px',
        lineHeight: 0,
    },
    circleLabelDefault: {
        color: theme.palette.primary.contrastText,
    },
    circleLabelInverted: {
        color: theme.palette.text.primary,
    },
}));

export type CircleSizes = 'large' | 'medium' | 'small';

export type CircleBoxProps = {
    letter?: React.ReactNode;
    color: SystemColorsEnum;
    size?: CircleSizes;
    className?: string;
};

export function CircleBox({letter, color, size = 'large', className}: CircleBoxProps) {
    const {classes: styles, cx} = useStyles();

    function getSizeClass() {
        const circleSizeMapping: Record<CircleSizes, string> = {
            large: styles.circleLarge,
            medium: styles.circleMedium,
            small: styles.circleSmall,
        };

        return circleSizeMapping[size];
    }

    function getLetterColorClass() {
        switch (color) {
            case SystemColorsEnum.Beige:
            case SystemColorsEnum.WarmYellow:
            case SystemColorsEnum.YellowNeon:
            case SystemColorsEnum.Mint:
            case SystemColorsEnum.SystemGrey:
                return styles.circleLabelInverted;
            default:
                return styles.circleLabelDefault;
        }
    }

    return (
        <div className={cx(styles.circle, getSizeClass(), className)} style={{backgroundColor: color}}>
            <span
                className={cx(getLetterColorClass(), {
                    [styles.circleLabel]: size === 'large',
                    [styles.circleLabelSmall]: size !== 'large',
                })}
            >
                {letter}
            </span>
        </div>
    );
}
