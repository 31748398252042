import React, {useEffect} from 'react';
import {Controller, useForm, useWatch} from 'react-hook-form';
import {Box, Grid} from '@mui/material';
import {format, isValid, parse} from 'date-fns';

import {Locale} from '@components/types';

import {DateInputMask} from './DatePicker.config';
import {localizedDatePickerFooterSingleMode} from './DatePickerFooterSingleMode.localize';
import {useDatePickerFooterSingleModeClasses} from './DatePickerFooterSingleMode.style';
import {FormTextInputCleanable} from './FormInput';

type DatePickerFooterSingleModeModel = {
    date: string;
};

type DatePickerFooterSingleModeProps = {
    value?: Date;
    locale?: Locale;
    onChange?: (date: Date) => void;
};

export const DatePickerFooterSingleMode = ({value, locale, onChange}: DatePickerFooterSingleModeProps) => {
    const {classes} = useDatePickerFooterSingleModeClasses();
    const inputMask = DateInputMask[locale];
    const {control, setValue} = useForm<DatePickerFooterSingleModeModel>({defaultValues: {date: ''}});
    const watched = useWatch({control});
    const parsedDate = parse(watched.date, inputMask, new Date());

    useEffect(() => {
        if (value?.getTime() !== parsedDate?.getTime()) {
            setValue('date', isValid(value) ? format(value, inputMask) : '');
        }
    }, [value]);

    useEffect(() => {
        if (isValid(parsedDate)) {
            onChange(parsedDate);
        }
    }, [watched.date]);

    return (
        <Box mt={1} mr={1.25} ml={1.25}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputCleanable
                                className={classes.datePickerFooterSingleModeInput}
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                clear={() => onChange(null)}
                                fieldState={fieldState}
                                placeholder={inputMask}
                                label={localizedDatePickerFooterSingleMode.datePickerFooterSingleModeDateLabel}
                            />
                        )}
                        name="date"
                        control={control}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
