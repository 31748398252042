import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Typography} from '@mui/material';

import {useAutocompleteClasses} from '@components/autocomplete';
import LocalizedText from '@components/i18n/LocalizedText';

type AutocompletePlaceholderProps = {
    emptyPlaceholder?: MessageDescriptor;
};

export function AutocompletePlaceholder({emptyPlaceholder}: AutocompletePlaceholderProps) {
    const {classes: styles} = useAutocompleteClasses();

    return (
        <div className={styles.autocompleteEmptyPlaceholderContainer}>
            <Typography variant="body1" color="secondary" className={styles.autocompleteEmptyPlaceholder}>
                <LocalizedText label={emptyPlaceholder} />
            </Typography>
        </div>
    );
}
