import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {GridCellParams} from '@mui/x-data-grid';

import {ToggleSwitch} from '@components/toggle';
import {ruleUpdatePermissions} from '@models/permissions/permissions';

import {useMultiplePermissions} from '../../app/permission/PermissionHoc';
import {useAsyncActionState} from '../../shared/async-action/hooks';
import {ruleActions} from '../actions';

const localized = defineMessages({
    enableLabel: {
        id: 'RuleActiveToggleCell_enableLabel',
        defaultMessage: 'Active',
    },
    disableLabel: {
        id: 'RuleActiveToggleCell_disableLabel',
        defaultMessage: 'Inactive',
    },
});

export type RuleActiveToggleCellProps = {
    value: boolean;
    ruleId: string;
};

export function RuleActiveToggleCell({value, ruleId}: RuleActiveToggleCellProps) {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {isProgress: enableActionInProgress} = useAsyncActionState(ruleActions.activateRule);
    const {isProgress: disableActionInProgress} = useAsyncActionState(ruleActions.deactivateRule);
    const hasPermissions = useMultiplePermissions({allowedPermissions: ruleUpdatePermissions});

    const handleClick = (value: boolean) => {
        const action = value ? ruleActions.activateRule.request({ruleId}) : ruleActions.deactivateRule.request({ruleId});

        dispatch(action);
    };

    return (
        <ToggleSwitch
            value={value}
            onChange={handleClick}
            label={formatMessage(value ? localized.enableLabel : localized.disableLabel)}
            disabled={!hasPermissions || enableActionInProgress || disableActionInProgress}
            labelAlignRight
        />
    );
}

export function renderRuleActiveToggleCell(params: GridCellParams) {
    const props: RuleActiveToggleCellProps = params.value as RuleActiveToggleCellProps;
    return <RuleActiveToggleCell {...props} />;
}
