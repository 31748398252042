import {constructUsing, createMap, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {PlayerBonusViewModelKeys} from '@models/player-bonus';

import {BonusCodeDetailsViewModelKeys} from '../block-bonus-code/types';

import {PlayerBonusColumn} from './components/DataGridPlayerBonus';

export class PlayerBonusVmMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap<PlayerBonusColumn[], PlayerBonusViewModelKeys[]>(
            mapper,
            `${nameof<PlayerBonusColumn>()}[]`,
            `${nameof<PlayerBonusViewModelKeys>()}[]`,
            constructUsing((m, _) => {
                const mapper: Record<PlayerBonusColumn, PlayerBonusViewModelKeys[]> = {
                    bonus_name: ['bonus_name'],
                    bonus_status: ['bonus_status'],
                    'created_at.seconds': ['created_at.seconds'],
                    desc: ['desc'],
                    'expire_at.seconds': ['expire_at.seconds'],
                    marketing_code: ['marketing_code'],
                    realized_amount: ['realized_amount'],
                    total_amount: ['total_amount'],
                    remaining_amount: ['remaining_amount'],
                    expireButton: [],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
        createMap<PlayerBonusColumn[], BonusCodeDetailsViewModelKeys[]>(
            mapper,
            `${nameof<PlayerBonusColumn>()}[]`,
            `${nameof<BonusCodeDetailsViewModelKeys>()}[]`,
            constructUsing((m, _) => {
                const mapper: Record<PlayerBonusColumn, BonusCodeDetailsViewModelKeys[]> = {
                    bonus_name: [],
                    bonus_status: [],
                    'created_at.seconds': [],
                    desc: [],
                    'expire_at.seconds': [],
                    marketing_code: [],
                    realized_amount: [],
                    total_amount: [],
                    remaining_amount: [],
                    expireButton: ['release_mechanism', 'credit_type'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
    }
}
