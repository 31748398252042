import React from 'react';
import {MessageDescriptor} from 'react-intl';
import Autosizer from 'react-virtualized-auto-sizer';

import {MemoizedDefaultChipProps} from './ChipRenderer';
import {VirtualizedGroupedChipList} from './VirtualizedGroupedChipList';

export type ChipGroup = {
    groupTitle: MessageDescriptor;
    items: MemoizedDefaultChipProps[];
};

export type CharType = 'number' | 'letter';

export type RowSize = 'small' | 'medium' | 'large';

export type GroupedChipListProps = {
    rowSize: RowSize;
    groups: ChipGroup[];
    charType: CharType;
};

export function GroupedChipList({groups, rowSize, charType}: GroupedChipListProps) {
    return (
        <Autosizer>
            {({height, width}) => (
                <VirtualizedGroupedChipList groups={groups} rowSize={rowSize} charType={charType} listHeight={height} listWidth={width} />
            )}
        </Autosizer>
    );
}
