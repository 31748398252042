import React, {useContext, useEffect} from 'react';
import {Controller, useWatch} from 'react-hook-form';
import {defineMessages, IntlContext} from 'react-intl';
import {Box, InputAdornment, List, ListItem, ListItemText} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {useAutoMapper} from '@auto-mapper';
import Button from '@components/button/Buttons';
import {FormError, FormSelect, FormTextInputDefault, RuleType, useValidationFormatter} from '@components/input';
import {ModalContext, ModalFooter} from '@components/modal';
import {ToggleSwitch} from '@components/toggle';
import {GameCurrency, GameTemplateSettings, GameTemplateSettingsViewModel, GameTemplateViewModelKeys} from '@models/game-template';
import {GameTemplate, ManagedGameType} from '@redux/entity';
import {extendedViewCleanDelay} from '@redux/view';

import {useCurrencyFormatter, useCurrencySymbol} from 'src/features/app/intl/hooks';
import {localizedGameCurrency} from 'src/features/app/intl/shared-resources/gameCurrency';
import {useGameBlinds} from 'src/features/block-game-blind-list';
import {useGameTemplate} from 'src/features/block-game-template-details';
import {SelectOption} from 'src/features/module-shared/types';
import {useReduxForm} from 'src/features/shared/form/hooks';
import {gameTemplateActions, GameTemplateUpdateRequestPayload} from '../actions';

const localized = defineMessages({
    cancelButtonLabel: {
        id: 'GameTemplateModal_cancelButtonLabel',
        defaultMessage: 'Cancel',
    },
    createButtonLabel: {
        id: 'GameTemplateModal_createButtonLabel',
        defaultMessage: 'Create Template',
    },
    saveButtonLabel: {
        id: 'GameTemplateModal_saveButtonLabel',
        defaultMessage: 'Save Template',
    },
    name: {
        id: 'GameTemplateModal_name',
        defaultMessage: 'Name',
    },
    newMemberRestriction: {
        id: 'GameTemplateModal_newMemberRestriction',
        defaultMessage: 'New member restriction',
    },
    newPlayerCountSitTogether: {
        id: 'GameTemplateModal_newPlayerCountSitTogether',
        defaultMessage: 'How many new players can sit at table together',
    },
    playerNumber: {
        id: 'GameTemplateModal_playerNumber',
        defaultMessage: 'No. of players',
    },
    minPlayers: {
        id: 'GameTemplateModal_minPlayers',
        defaultMessage: 'Min players for game to start',
    },
    currency: {
        id: 'GameTemplateModal_currency',
        defaultMessage: 'Currency of table',
    },
    blinds: {
        id: 'GameTemplateModal_blinds',
        defaultMessage: 'Small blind / Big blind',
    },
    forceStraddle: {
        id: 'GameTemplateModal_forceStraddle',
        defaultMessage: 'Force straddle',
    },
    straddle: {
        id: 'GameTemplateModal_straddle',
        defaultMessage: 'Straddle: {straddle}',
    },
    ante: {
        id: 'GameTemplateModal_ante',
        defaultMessage: 'Ante',
    },
    autoBuyout: {
        id: 'GameTemplateModal_autoBuyout',
        defaultMessage: 'Auto buyout',
    },
    minBuyIn: {
        id: 'GameTemplateModal_minBuyIn',
        defaultMessage: 'Minimum table buy in',
    },
    maxBuyIn: {
        id: 'GameTemplateModal_maxBuyIn',
        defaultMessage: 'Maximum table buy in',
    },
    autoTakeOff: {
        id: 'GameTemplateModal_autoTakeOff',
        defaultMessage: 'Auto - take off table',
    },
    showHand: {
        id: 'GameTemplateModal_showHand',
        defaultMessage: 'Force to show hand',
    },
    gpsRestriction: {
        id: 'GameTemplateModal_gpsRestriction',
        defaultMessage: 'GPS restriction',
    },
    vpnRestriction: {
        id: 'GameTemplateModal_vpnRestriction',
        defaultMessage: 'VPN restriction',
    },
});

const useClasses = makeStyles()(theme => ({
    gameTemplateModalList: {
        maxHeight: 660,
        overflow: 'auto',
    },
    gameTemplateModalListItem: {
        padding: theme.spacing(0),
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    gameTemplateModalListItemContent: {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(2),
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    gameTemplateModalListItemDivided: {
        columnGap: theme.spacing(2),
        flexFlow: 'row',
        '& > *': {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: '50%',
        },
    },
    gameTemplateModalListItemText: {
        minWidth: theme.spacing(25),
        marginBottom: theme.spacing(1),
    },
    gameTemplateModalListItemInput: {
        width: '100%',
    },
    gameTemplateModalSwitch: {
        width: '50%',
    },
    gameTemplateModalSwitchCentered: {
        alignSelf: 'center',
        justifyContent: 'flex-start',
    },
    gameTemplateModalSelect: {
        minWidth: '150px !important',
        marginLeft: 0,
    },
}));

type GameTemplateModalProps = {
    id?: string;
    gameType: ManagedGameType;
    mode: 'create' | 'edit';
    onClose: () => void;
};

export function GameTemplateModal({id, gameType, onClose, mode}: GameTemplateModalProps) {
    const {classes, cx} = useClasses();
    const mapper = useAutoMapper();
    const {formatMessage} = useContext(IntlContext);
    const formatCurrency = useCurrencyFormatter();
    const currencySymbol = useCurrencySymbol();
    const validationMessageFormatter = useValidationFormatter();
    const {closeModal} = useContext(ModalContext);

    const keys: GameTemplateViewModelKeys[] = ['gameType', 'id'];
    const {item: template} = useGameTemplate({
        id,
        fields: keys,
        viewType: 'GameTemplateModal',
        defaultFilters: [{key: 'gameType', value: gameType}],
    });

    const {items: blinds} = useGameBlinds({
        viewType: 'GameTemplateModal',
        fields: null,
        defaultFilters: [{key: 'gameType', value: gameType}],
        syncWithUrl: true,
        validateFilter: () => true,
        cleanDelay: extendedViewCleanDelay,
    });

    const nameMaxLength = 50;
    const defaultTemplateVm: GameTemplateSettingsViewModel = {
        minPlayerCount: 2,
        autoWithdraw: false,
        forceShowCard: false,
        vpnRestriction: true,
        name: null,
        newMemberRestriction: true,
        playerCountMax: 8,
        ante: null,
        autoBuyout: null,
        blindId: 1,
        maxBuyIn: null,
        minBuyIn: 50,
        gpsRestriction: true,
        forceStraddle: true,
        currencyType: GameCurrency.USD,
        newPlayerCountSitTogether: 2,
    };

    const defaultValue = getTemplateValue();
    const submitAction = mode === 'edit' ? gameTemplateActions.editTemplate : gameTemplateActions.createTemplate;
    const {control, state, submit, reset, handleSubmit} = useReduxForm<GameTemplateSettingsViewModel, GameTemplateUpdateRequestPayload>({
        initialModel: defaultValue,
        asyncAction: submitAction,
        map: templateVm => ({id, template: mapper.map(templateVm, GameTemplateSettingsViewModel, GameTemplateSettings), gameType}),
        onSuccess: handleSuccess,
        shouldResetOnError: false,
    });
    const watched = useWatch({control});
    const error = state.errorMessage ? formatMessage(state.errorMessage, state.errorMessageValues) : null;

    const straddle = getStraddle();
    const playerOptions = getPlayerOptions();
    const bigBlindAdornment = <InputAdornment position="end">BB</InputAdornment>;
    const currencyAdornment = (
        <InputAdornment position="end">
            {watched.currencyType === GameCurrency.USD ? currencySymbol : formatMessage(localizedGameCurrency[watched.currencyType])}
        </InputAdornment>
    );

    useEffect(() => {
        reset(getTemplateValue());
    }, [template?.id]);

    function getTemplateValue() {
        return mapper.map(template, GameTemplate, GameTemplateSettingsViewModel) ?? defaultTemplateVm;
    }

    function getPlayerOptions(): SelectOption[] {
        const availablePlayersNumber = [2, 3, 4, 5, 6, 7, 8];
        const playerOptions = Array.from(availablePlayersNumber).map(s => ({label: s.toString(), value: s} as SelectOption));
        return playerOptions;
    }

    function getCurrencyOptions(): SelectOption[] {
        const values = Object.values(GameCurrency).filter(v => !isNaN(Number(v)));
        return values.map((s: GameCurrency) => ({label: localizedGameCurrency[s], value: s} as SelectOption));
    }

    function getBlindsOptions(): SelectOption[] {
        return blinds?.map(s => ({label: `${formatCurrency(s.sb)} / ${formatCurrency(s.bb)}`, value: s.id} as SelectOption));
    }

    function getBigBlind() {
        return blinds?.find(b => b.id === watched.blindId)?.bb;
    }

    function getStraddle() {
        const bigBlind = getBigBlind();
        return bigBlind ? formatCurrency(bigBlind * 2) : null;
    }

    function getMinBuyIn() {
        const bigBlind = getBigBlind();
        return bigBlind ? formatCurrency(bigBlind * watched?.minBuyIn) : null;
    }

    function getMaxBuyIn() {
        const bigBlind = getBigBlind();
        return bigBlind ? formatCurrency(bigBlind * watched?.maxBuyIn) : null;
    }

    function getAutoBuyout() {
        const bigBlind = getBigBlind();
        return bigBlind && watched.autoWithdraw ? formatCurrency(bigBlind * watched?.autoBuyout) : null;
    }

    function handleSuccess() {
        closeModal();
    }

    return (
        <Box>
            <form>
                <List classes={{padding: classes.gameTemplateModalList}}>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <ListItemText className={classes.gameTemplateModalListItemText} primary={formatMessage(localized.name)} />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                    textAlign="right"
                                    placeholder={localized.name}
                                ></FormTextInputDefault>
                            )}
                            control={control}
                            defaultValue={defaultValue?.name}
                            name="name"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.name),
                                maxLength: {
                                    value: nameMaxLength,
                                    message: validationMessageFormatter(RuleType.MaxLength, localized.name, nameMaxLength),
                                },
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <Controller
                            render={({field}) => (
                                <ToggleSwitch
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldName={formatMessage(localized.newMemberRestriction)}
                                    alignLeft
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.newMemberRestriction}
                            name="newMemberRestriction"
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <ListItemText
                            className={classes.gameTemplateModalListItemText}
                            primary={formatMessage(localized.newPlayerCountSitTogether)}
                        />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormSelect
                                    key={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={playerOptions}
                                    fieldState={fieldState}
                                    label={localized.newPlayerCountSitTogether}
                                    disabled={!watched.newMemberRestriction}
                                    dropdownClassName={classes.gameTemplateModalSelect}
                                    alignLeft
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.newPlayerCountSitTogether}
                            name="newPlayerCountSitTogether"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.newPlayerCountSitTogether),
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <ListItemText className={classes.gameTemplateModalListItemText} primary={formatMessage(localized.playerNumber)} />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormSelect
                                    key={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={playerOptions}
                                    fieldState={fieldState}
                                    label={localized.playerNumber}
                                    dropdownClassName={classes.gameTemplateModalSelect}
                                    alignLeft
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.playerCountMax}
                            name="playerCountMax"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.playerNumber),
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <ListItemText className={classes.gameTemplateModalListItemText} primary={formatMessage(localized.minPlayers)} />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormSelect
                                    key={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={playerOptions}
                                    fieldState={fieldState}
                                    label={localized.minPlayers}
                                    dropdownClassName={classes.gameTemplateModalSelect}
                                    alignLeft
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.minPlayerCount}
                            name="minPlayerCount"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.minPlayers),
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <ListItemText className={classes.gameTemplateModalListItemText} primary={formatMessage(localized.currency)} />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormSelect
                                    key={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={getCurrencyOptions()}
                                    fieldState={fieldState}
                                    label={localized.currency}
                                    dropdownClassName={classes.gameTemplateModalSelect}
                                    alignLeft
                                    disabled
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.currencyType}
                            name="currencyType"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.currency),
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <ListItemText className={classes.gameTemplateModalListItemText} primary={formatMessage(localized.blinds)} />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormSelect
                                    key={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    options={getBlindsOptions()}
                                    fieldState={fieldState}
                                    label={localized.blinds}
                                    dropdownClassName={classes.gameTemplateModalSelect}
                                    alignLeft
                                    isCompareOptionsAvailable={false}
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.blindId}
                            name="blindId"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.currency),
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <ListItemText className={classes.gameTemplateModalListItemText} primary={formatMessage(localized.ante)} />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormTextInputDefault
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                    textAlign="right"
                                    type="number"
                                    endAdornment={currencyAdornment}
                                    placeholder={localized.ante}
                                ></FormTextInputDefault>
                            )}
                            control={control}
                            defaultValue={defaultValue?.ante}
                            name="ante"
                            rules={{
                                required: validationMessageFormatter(RuleType.Required, localized.ante),
                                validate: {
                                    min: value => (value >= 0 ? null : validationMessageFormatter(RuleType.Min, localized.ante, 0)),
                                },
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <Box className={classes.gameTemplateModalListItemContent}>
                            <Controller
                                render={({field}) => (
                                    <ToggleSwitch
                                        value={Boolean(field.value)}
                                        onChange={field.onChange}
                                        fieldName={formatMessage(localized.forceStraddle)}
                                        alignLeft
                                    />
                                )}
                                control={control}
                                defaultValue={defaultValue?.forceStraddle}
                                name="forceStraddle"
                            />
                            {watched?.forceStraddle && straddle ? <Box>{formatMessage(localized.straddle, {straddle})}</Box> : null}
                        </Box>
                    </ListItem>
                    <ListItem className={cx(classes.gameTemplateModalListItem, classes.gameTemplateModalListItemDivided)}>
                        <Box>
                            <ListItemText className={classes.gameTemplateModalListItemText} primary={formatMessage(localized.minBuyIn)} />
                            <Controller
                                render={({field, fieldState}) => (
                                    <FormTextInputDefault
                                        value={field.value}
                                        onChange={field.onChange}
                                        fieldState={fieldState}
                                        textAlign="right"
                                        type="number"
                                        endAdornment={bigBlindAdornment}
                                        helperText={getMinBuyIn()}
                                        placeholder={localized.minBuyIn}
                                        hasBottomSpacing={false}
                                        fullWidth={false}
                                        className={classes.gameTemplateModalListItemInput}
                                    ></FormTextInputDefault>
                                )}
                                control={control}
                                defaultValue={defaultValue?.minBuyIn}
                                name="minBuyIn"
                                rules={{
                                    required: validationMessageFormatter(RuleType.Required, localized.minBuyIn),
                                    validate: {
                                        min: value =>
                                            value > 0 ? null : validationMessageFormatter(RuleType.GreaterThan, localized.minBuyIn, 0),
                                    },
                                }}
                            />
                        </Box>
                        <Box>
                            <ListItemText className={classes.gameTemplateModalListItemText} primary={formatMessage(localized.maxBuyIn)} />
                            <Controller
                                render={({field, fieldState}) => (
                                    <FormTextInputDefault
                                        value={field.value}
                                        onChange={field.onChange}
                                        fieldState={fieldState}
                                        textAlign="right"
                                        type="number"
                                        endAdornment={bigBlindAdornment}
                                        helperText={getMaxBuyIn()}
                                        placeholder={localized.maxBuyIn}
                                        hasBottomSpacing={false}
                                        fullWidth={false}
                                        className={classes.gameTemplateModalListItemInput}
                                    ></FormTextInputDefault>
                                )}
                                control={control}
                                defaultValue={defaultValue?.maxBuyIn}
                                name="maxBuyIn"
                                rules={{
                                    required: validationMessageFormatter(RuleType.Required, localized.maxBuyIn),
                                    validate: {
                                        min: value =>
                                            value > 0 ? null : validationMessageFormatter(RuleType.GreaterThan, localized.maxBuyIn, 0),
                                    },
                                }}
                            />
                        </Box>
                    </ListItem>
                    <ListItem
                        className={cx(classes.gameTemplateModalListItem, watched.autoWithdraw && classes.gameTemplateModalListItemDivided)}
                    >
                        <Controller
                            render={({field}) => (
                                <ToggleSwitch
                                    value={Boolean(field.value)}
                                    onChange={field.onChange}
                                    fieldName={formatMessage(localized.autoTakeOff)}
                                    alignLeft
                                    className={cx(watched.autoWithdraw ? classes.gameTemplateModalSwitchCentered : null)}
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.autoWithdraw}
                            name="autoWithdraw"
                        />
                        {watched.autoWithdraw ? (
                            <Box>
                                <ListItemText
                                    className={classes.gameTemplateModalListItemText}
                                    primary={formatMessage(localized.autoBuyout)}
                                />
                                <Controller
                                    render={({field, fieldState}) => (
                                        <FormTextInputDefault
                                            value={watched.autoWithdraw ? field.value : null}
                                            onChange={field.onChange}
                                            fieldState={fieldState}
                                            textAlign="right"
                                            type="number"
                                            endAdornment={bigBlindAdornment}
                                            helperText={getAutoBuyout()}
                                            placeholder={localized.autoBuyout}
                                            disabled={!watched.autoWithdraw}
                                            hasBottomSpacing={false}
                                            fullWidth={false}
                                            className={classes.gameTemplateModalListItemInput}
                                        ></FormTextInputDefault>
                                    )}
                                    control={control}
                                    defaultValue={defaultValue?.autoBuyout}
                                    name="autoBuyout"
                                    rules={{
                                        required: watched.autoWithdraw
                                            ? validationMessageFormatter(RuleType.Required, localized.autoBuyout)
                                            : null,
                                        validate: {
                                            min: value =>
                                                !watched.autoWithdraw || value > 0
                                                    ? null
                                                    : validationMessageFormatter(RuleType.GreaterThan, localized.autoBuyout, 0),
                                        },
                                    }}
                                />
                            </Box>
                        ) : null}
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <Controller
                            render={({field}) => (
                                <ToggleSwitch
                                    value={Boolean(field.value)}
                                    onChange={field.onChange}
                                    fieldName={formatMessage(localized.showHand)}
                                    alignLeft
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.forceShowCard}
                            name="forceShowCard"
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <Controller
                            render={({field}) => (
                                <ToggleSwitch
                                    value={Boolean(field.value)}
                                    onChange={field.onChange}
                                    fieldName={formatMessage(localized.gpsRestriction)}
                                    alignLeft
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.gpsRestriction}
                            name="gpsRestriction"
                        />
                    </ListItem>
                    <ListItem className={classes.gameTemplateModalListItem}>
                        <Controller
                            render={({field}) => (
                                <ToggleSwitch
                                    value={Boolean(field.value)}
                                    onChange={field.onChange}
                                    fieldName={formatMessage(localized.vpnRestriction)}
                                    alignLeft
                                />
                            )}
                            control={control}
                            defaultValue={defaultValue?.vpnRestriction}
                            name="vpnRestriction"
                        />
                    </ListItem>
                </List>
                {error ? <FormError>{error}</FormError> : null}
            </form>

            <ModalFooter>
                <Button label={localized.cancelButtonLabel} onClick={onClose} />
                <Button
                    color="primary"
                    label={mode === 'create' ? localized.createButtonLabel : localized.saveButtonLabel}
                    onClick={handleSubmit(submit)}
                />
            </ModalFooter>
        </Box>
    );
}
