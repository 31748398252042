import React from 'react';

import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {notesCreatePermissions} from '@models/permissions/permissions';

import {withMultiplePermission} from 'src/features/app/permission/PermissionHoc';
import {BulkActionKey} from '../../block-bulk-actions';
import {BulkUserProfileChooseActionsStepButton} from '../../block-user-profile-bulk-actions';

type CustomerSupportBulkPlayerButtonInnerProps = {
    selectedIds: string[];
};

function CustomerSupportBulkPlayerButtonInner({selectedIds}: CustomerSupportBulkPlayerButtonInnerProps) {
    return (
        <BulkUserProfileChooseActionsStepButton
            uids={selectedIds}
            actionKeys={[
                BulkActionKey.CasinoLock,
                BulkActionKey.SportsbookLock,
                BulkActionKey.P2PTransferLock,
                BulkActionKey.InitiatePaymentKYC,
                BulkActionKey.NotesAndAttachments,
            ]}
        />
    );
}

export const CustomerSupportBulkPlayerButton = withMultiplePermission(CustomerSupportBulkPlayerButtonInner, {
    allowedPermissions: [
        {moduleName: ModuleName.ActionCenter, permissions: [PermissionEnum.Create, PermissionEnum.Delete, PermissionEnum.Update]},
        {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.FinanceActions, permissions: [PermissionEnum.Update]},
        {moduleName: ModuleName.ActionCenter, submoduleName: SubmoduleName.GamesActions, permissions: [PermissionEnum.Update]},
        ...notesCreatePermissions,
    ],
});
