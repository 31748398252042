import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {useService} from '@inversify';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {
    bulkActionsActions,
    BulkChooseActionStepLockItem,
    BulkLockItemValue,
    casinoLockResultValueSelector,
    itemsSelector,
} from '../../block-bulk-actions';
import {gamesActionsUpdatePermissions} from '../../block-user-profile-actions/permissions';
import {UserProfileGridItem} from '../../data-grids/types';
import {ApplyCasinoLockRequest, ApplyCasinoLockStrategy} from '../services/applyStrategy';

const localized = defineMessages({
    casinoLock: {
        id: 'BulkActionCasinoLock_casinoLock',
        defaultMessage: 'Casino Lock',
    },
});

function ActionCasinoLockInner() {
    const dispatch = useDispatch();
    const strategy = useService<ApplyCasinoLockStrategy>('ApplyCasinoLockStrategy');
    const players = useSelector(itemsSelector) as UserProfileGridItem[];
    const initialValues: boolean[] = players.map(i => i.casino_status === FeatureAccessStatus.Locked);
    const resultValue: FeatureAccessStatus = useSelector(casinoLockResultValueSelector);

    function handleSubmit(toggleValue: BulkLockItemValue): void {
        const valueMapper: Record<BulkLockItemValue, FeatureAccessStatus> = {
            [BulkLockItemValue.Checked]: FeatureAccessStatus.Locked,
            [BulkLockItemValue.Unchecked]: FeatureAccessStatus.Unlocked,
            [BulkLockItemValue.NoChanges]: null,
        };
        const request: ApplyCasinoLockRequest = {players, value: valueMapper[toggleValue]};
        dispatch(bulkActionsActions.apply({strategy, request}));
    }

    return (
        <BulkChooseActionStepLockItem
            label={localized.casinoLock}
            initialValues={initialValues}
            resultValue={resultValue ? resultValue === FeatureAccessStatus.Locked : null}
            onSubmit={handleSubmit}
        />
    );
}

export const BulkUserProfileCasinoLock = withMultiplePermission(ActionCasinoLockInner, {allowedPermissions: gamesActionsUpdatePermissions});
