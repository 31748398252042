import {Span} from '@opentelemetry/api';
import {ObservableInput, ObservedValueOf, OperatorFunction} from 'rxjs';
import {map as mapRx, mergeMap as mergeMapRx, switchMap as switchMapRx} from 'rxjs/operators';

import {applyLocationAttrs} from './OtelSpan';
import {TracingService} from './TracingService';

function hasType(obj: unknown): obj is {type: string} {
    return typeof obj === 'object' && obj !== null && 'type' in obj;
}

const ReduxMiddlewareAttributes = {
    REDUX_ACTION: 'redux.action',
    REDUX_ERROR: 'redux.error',
};

function applyReduxAttrs(span: Span, action: string) {
    span?.setAttributes({
        [ReduxMiddlewareAttributes.REDUX_ACTION]: action,
    });
}

const spanName = 'REDUX ACTION';

export function switchMap<T, O extends ObservableInput<any>>(
    project: (value: T, index: number) => O
): OperatorFunction<T, ObservedValueOf<O>> {
    return switchMapRx((val: T, index: number) => {
        let result: O = undefined;
        const action = hasType(val) ? val.type : null;
        const tracingService = new TracingService();
        const tracer = tracingService.getTracer();
        if (action && tracer) {
            try {
                const result = project(val, index);

                return result;
            } catch (e) {
                const message = e.toString();
                const span = tracer.startSpan(spanName);
                applyLocationAttrs(span);
                applyReduxAttrs(span, action);
                tracingService.endSpanFailed(span, message);
                throw new Error(e);
            }
        } else {
            result = project(val, index);
        }
        return result;
    });
}

export function mergeMap<T, O extends ObservableInput<any>>(
    project: (value: T, index: number) => O
): OperatorFunction<T, ObservedValueOf<O>> {
    return mergeMapRx((val: T, index: number) => {
        let result: O = undefined;
        const action = hasType(val) ? val.type : null;
        const tracingService = new TracingService();
        const tracer = tracingService.getTracer();
        if (action && tracer) {
            try {
                const result = project(val, index);

                return result;
            } catch (e) {
                const message = e.toString();
                const span = tracer.startSpan(spanName);
                applyLocationAttrs(span);
                applyReduxAttrs(span, action);
                tracingService.endSpanFailed(span, message);
                throw new Error(e);
            }
        } else {
            result = project(val, index);
        }
        return result;
    });
}

export function map<T, R>(project: (value: T, index: number) => R, _thisArg?: any): OperatorFunction<T, R> {
    return mapRx((val: T, index: number) => {
        let result: R = undefined;
        const action = hasType(val) ? val.type : null;
        const tracingService = new TracingService();
        const tracer = tracingService.getTracer();
        if (action && tracer) {
            try {
                const result = project(val, index);

                return result;
            } catch (e) {
                const message = e.toString();
                const span = tracer.startSpan(spanName);
                applyLocationAttrs(span);
                applyReduxAttrs(span, action);
                tracingService.endSpanFailed(span, message);
                throw new Error(e);
            }
        } else {
            result = project(val, index);
        }
        return result;
    });
}
