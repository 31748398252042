import {useCallback, useMemo, useState} from 'react';
import {
    EmojiState,
    FlatEmojiWithUrl,
    useAttrs,
    useChainedCommands,
    useCurrentSelection,
    UseEmojiProps,
    UseEmojiReturn,
    useExtensionEvent,
    useHelpers,
    useMenuNavigation,
    useSelectedText,
} from '@remirror/react';
import {isEmptyArray} from 'remirror';
import {EmojiExtension, EmojiSuggestHandler, LinkExtension} from 'remirror/extensions';

import {markedParse, turndownService} from './utils';

function htmlToMarkdown(html: string): string {
    return turndownService.turndown(html);
}

function markdownToHtml(markdown: string, sanitizer?: (html: string) => string): string {
    const html = markedParse(markdown);
    let result = html;

    if (sanitizer) {
        result = sanitizer(html);
    }

    return result;
}

export function useConverters() {
    return {
        htmlToMarkdown,
        markdownToHtml,
    };
}

type LinkState = {
    href: string;
    title: string;
    setHref: (href: string) => void;
    setTitle: (title: string) => void;
    removeLink: () => void;
    submit: () => void;
    getLinkFromSelection: () => void;
};

export function useLinkState(): LinkState {
    const [title, setTitle] = useState('');
    const [href, setHref] = useState('');
    const chain = useChainedCommands();
    const text = useSelectedText();
    const {from, to} = useCurrentSelection();
    const {link} = useAttrs<LinkExtension>();

    function getLinkFromSelection() {
        const url = link()?.href as string;

        setHref(url);
        setTitle(text);
    }

    function submit() {
        if (href && title) {
            const titleEnd = from + title.length;
            chain.delete({from, to}).insertText(title).updateLink({href, auto: false}, {from, to: titleEnd}).focus(titleEnd).run();
        }
    }

    function removeLink() {
        chain.removeLink().focus().run();
    }

    return {
        title,
        href,
        setTitle,
        setHref,
        removeLink,
        submit,
        getLinkFromSelection,
    };
}

export function useEmoji(props: UseEmojiProps = {}): UseEmojiReturn {
    const {direction, dismissKeys, focusOnClick, submitKeys} = props;
    const [state, setState] = useState<EmojiState | null>(null);
    const helpers = useHelpers();
    const items = state?.list ?? [];
    const isOpen = !!state;

    const onDismiss = useCallback(() => {
        let result = false;
        if (state) {
            // Ignore the current mention so that it doesn't show again for this
            // matching area
            helpers.getSuggestMethods().addIgnored({from: state.range.from, name: 'emoji', specific: true});

            setState({...state, exit: true});
            result = true;
        }
        return result;
    }, [helpers, state]);

    const onSubmit = useCallback(
        (emoji: FlatEmojiWithUrl) => {
            let result = false;
            if (state || !isEmptyArray(state.list)) {
                state.apply(emoji.emoji);
                result = true;
            }

            return result;
        },
        [state]
    );

    const menu = useMenuNavigation<FlatEmojiWithUrl>({
        items,
        isOpen,
        onDismiss,
        onSubmit,
        direction,
        dismissKeys,
        focusOnClick,
        submitKeys,
    });
    const {setIndex} = menu;

    const onChange: EmojiSuggestHandler = useCallback(
        props => {
            const {change, exit, moji, apply, range} = props;

            if (change) {
                setIndex(0);
                setState({
                    list: moji.data.map(emoji => ({...emoji, url: moji.url(emoji)})),
                    apply: (code: string) => {
                        setState(null);
                        return apply(code);
                    },
                    range,
                    query: '',
                    exit,
                });
            }

            if (exit) {
                setState(null);
            }
        },
        [setIndex]
    );

    // Add the change handler to the emoji state.
    useExtensionEvent(EmojiExtension, 'suggestEmoji', onChange);

    return useMemo(() => ({...menu, state}), [menu, state]);
}
