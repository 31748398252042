import {RootState} from 'typesafe-actions';

import {ServiceContainer} from '../../app/inversify/serviceContainer';
import {protectEpics} from '../../features/app/error-handling/epics';
import {createSingleItemReadEpics} from '../../features/module-shared/epics';
import {createItemSubscriberRealtimeEpics} from '../../features/realtime-updates/epics';
import {RealtimeUpdatesMeta} from '../../features/realtime-updates/types';
import {AccountVerificationGridItem} from '../kyc-processing/types';

import {KycDetailsBaseService} from './services';

const createKycDetailsEpics = (
    domain: string,
    serviceResolver: (container: ServiceContainer) => KycDetailsBaseService,
    itemSelector: (state: RootState) => AccountVerificationGridItem,
    filterSelector: (state: RootState) => string,
    realtimeUpdatesMeta: RealtimeUpdatesMeta
) => {
    return protectEpics(
        createSingleItemReadEpics(domain, serviceResolver, filterSelector, null, false),

        createItemSubscriberRealtimeEpics(realtimeUpdatesMeta, itemSelector, k => k.id)
    );
};

export default createKycDetailsEpics;
