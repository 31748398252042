import React, {useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueue, LayoutQueueToolbarDetails} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {ModuleName} from '@models/modules';
import {teamWorkspaceReadPermissions} from '@models/permissions/permissions';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';
import {UserProfileServerFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay, getNonEmptyValueValidator} from '@redux/view';
import {appendSelectedItemToQuery} from '@utils';

import {RouteUrl} from 'src/common/routeEnums';
import {BulkP2PTransferButton} from '../features/block-transaction-bulk-actions/components/BulkP2PTransferButton';
import {UserProfileDownloadCsvButton} from '../features/block-user-profile-actions';
import {DataGridUserProfileServer, UserProfileFiltersObsolete, useUserProfiles} from '../features/block-user-profile-list';
import {CurrentBalance, RedeemRevenueShare, ReferralLink} from '../features/block-workspace-team-toolbar-details';
import {withModule} from '../features/module-shared/components/ModuleHoc';
import {getFilterValueFromKey} from '../features/shared/filter/utils';

const localized = defineMessages({
    title: {
        id: 'TeamWorkspace_displayName',
        defaultMessage: 'Team Workspace',
    },
});

function TeamWorkspace() {
    const keys: UserProfileViewModelKeys[] = [
        'uid_link_agent',
        'email',
        'username',
        'referrer_player_id',
        'referrer_username',
        'register_marketing_code',
        'deposit_status',
        'withdrawal_status',
        'p2p_transfer_status',
        'finance.balance',
        'referred_date',
    ];
    const defaultSortingField: UserProfileViewModelKeys = 'finance.balance';
    const {items, totalCount, searchFilter, filterString, handleFilterChange, handlePageSizeChange, handleSortChange, handlePageChange} =
        useUserProfiles({
            viewType: 'TeamWorkspaceReferee',
            fields: keys,
            realtimeMode: RealtimeUpdatesMode.Confirm,
            triggers: [{type: RealtimeMessageTrigger.Update}],
            displayName: localized.title,
            validateFilter:
                getNonEmptyValueValidator<UserProfileServerFilterKeys>('defaultReferrerPlayerId') ||
                getNonEmptyValueValidator<UserProfileServerFilterKeys>('referrerPlayerId'),
            syncWithUrl: true,
            defaultSorting: [{sort: 'desc', field: defaultSortingField}],
            cleanDelay: extendedViewCleanDelay,
        });
    const [selectedIds, setSelectedIds] = useState([]);
    const {formatMessage} = useIntl();

    function handleRowSelected(ids: string[]) {
        setSelectedIds(ids);
    }

    const boAgentPlayerId = getFilterValueFromKey<UserProfileServerFilterKeys>('defaultReferrerPlayerId', searchFilter?.filter)?.toString();
    const boAgentPlayerLink: string = appendSelectedItemToQuery(RouteUrl.AgentReportPlayerDetails, boAgentPlayerId);

    return (
        <LayoutQueue
            header={
                <PageHeader
                    header={localized.title}
                    subHeader={boAgentPlayerId}
                    subHeaderLink={boAgentPlayerLink}
                    actions={
                        <UserProfileDownloadCsvButton filterString={filterString} filename={formatMessage(localized.title)} keys={keys} />
                    }
                />
            }
            toolbar={
                <>
                    <LayoutQueueToolbarDetails>
                        <ReferralLink uid={boAgentPlayerId} />
                        <RedeemRevenueShare uid={boAgentPlayerId} />
                        <CurrentBalance uid={boAgentPlayerId} />
                    </LayoutQueueToolbarDetails>
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems streched>
                                <UserProfileFiltersObsolete
                                    filter={filterString}
                                    onFilterChange={handleFilterChange}
                                    filters={['uidEmailUsernameMarketingCode', 'agent']}
                                    viewType={'TeamWorkspaceFilters'}
                                />
                            </ToolbarItems>
                            <ToolbarItems secondary>
                                <BulkP2PTransferButton agentUid={boAgentPlayerId} uids={selectedIds} />
                            </ToolbarItems>
                        </ToolbarItemsContainer>
                    </Toolbar>
                </>
            }
            body={
                <DataGridUserProfileServer
                    columns={keys}
                    rows={items}
                    rowCount={totalCount}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={handleSortChange}
                    page={searchFilter?.paging?.page}
                    pageSize={searchFilter?.paging?.pageSize}
                    sortModel={searchFilter?.sorting}
                    checkboxSelection
                    selectedIds={selectedIds}
                    onSelect={handleRowSelected}
                />
            }
        />
    );
}

export const teamWorkspaceResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'TeamWorkspace',
    rules: new PoliciesAccessRule([new Policy(ModuleName.TeamWorkspace, null, 'read')]),
});

export default withModule(withTraceErrorBoundary(TeamWorkspace, ErrorModal), teamWorkspaceReadPermissions);
