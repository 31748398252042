import {IModuleGridItem} from '@components/data-grid';
import {BoUser} from '@redux/entity';

export const domain = 'access-management/users';

export enum LoginAccessStatus {
    Enable = 'enable',
    Disable = 'disable',
}

export class UserGridItem extends BoUser implements IModuleGridItem {
    serverId: string;

    get isNew() {
        return false;
    }

    __typename = 'User';
}
