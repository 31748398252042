import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CurrencyCell} from '@components/data-grid/mui';
import {GgrViewModel} from '@models/aggregated-finance-summary';

import {GGRDetailsButton} from './GGRDetailsButton';

const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

type GGRProps = {
    ggr: GgrViewModel;
    total: number;
    dateTimeString: string;
    currency: string;
};

export function GGR({ggr, total, dateTimeString, currency}: GGRProps) {
    const {classes} = useStyles();
    return (
        <Box className={classes.container}>
            <CurrencyCell value={total} currency={currency} />
            <GGRDetailsButton ggr={ggr} currency={currency} dateTimeString={dateTimeString} />
        </Box>
    );
}
