import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {QueryGetPlayerReferrerSummaryArgs} from '@models/generated/graphql';
import {Filter, PlayerReferrerSummaryFilterKeys, PlayerReferrerSummaryQueryFields} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {GqlRequestBuilder} from '@services/entity/GqlRequestBuilder';
import {ApolloClientProxy} from '@services/gql-api';

@injectable()
export class PlayerReferrerSummaryService extends EntityBaseGqlService<
    QueryGetPlayerReferrerSummaryArgs,
    PlayerReferrerSummaryQueryFields,
    PlayerReferrerSummaryFilterKeys
> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new PlayerReferrerSummaryRequestBuilder());
    }
}

export class PlayerReferrerSummaryRequestBuilder extends GqlRequestBuilder<
    QueryGetPlayerReferrerSummaryArgs,
    PlayerReferrerSummaryQueryFields,
    PlayerReferrerSummaryFilterKeys
> {
    protected buildFilter(filter: Filter<PlayerReferrerSummaryFilterKeys>): QueryGetPlayerReferrerSummaryArgs {
        return {uid: filter?.['id']?.toString()};
    }

    protected buildQuery(fields: PlayerReferrerSummaryQueryFields[]): DocumentNode {
        return gql`
            query GetPlayerReferrerSummary($uid: String!) {
                getPlayerReferrerSummary(uid: $uid) {
                    referrer {
                        uid
                    }
                    invite_code @include(if: ${this.hasField(fields, 'invite_code')})
                    redeem_pending_total @include(if: ${this.hasField(fields, 'redeem_pending_total')})
                    revenue_generated_total @include(if: ${this.hasField(fields, 'revenue_generated_total')})
                    revenue_redeemed_total @include(if: ${this.hasField(fields, 'revenue_redeemed_total')})
                    revenue_share @include(if: ${this.hasField(fields, 'revenue_share')})
                }
            }
        `;
    }
}
