import {constructUsing, createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {EmbeddedReportQueryFields} from '@redux/entity';
import {EmbeddedReportServerModel} from '@services/embeddedReportService';

import {EmbeddedReportViewModel, EmbeddedReportViewModelKeys} from './types';

export class EmbeddedReportMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            EmbeddedReportServerModel,
            EmbeddedReportViewModel,
            forMember(
                vm => vm.type,
                mapFrom(m => m?.type)
            ),
            forMember(
                vm => vm.url,
                mapFrom(m => m?.url)
            ),
            forMember(
                vm => vm.expire_at,
                mapFrom(m => m?.expire_at)
            )
        );
        createMap<EmbeddedReportViewModelKeys, EmbeddedReportQueryFields>(
            mapper,
            nameof<EmbeddedReportViewModelKeys>(),
            nameof<EmbeddedReportQueryFields>(),
            constructUsing(m => {
                const mapper: Record<EmbeddedReportViewModelKeys, EmbeddedReportQueryFields> = {
                    type: undefined,
                    url: 'url',
                    'expire_at.seconds': 'expire_at.seconds',
                };

                return mapper[m];
            })
        );
    }
}
