import React from 'react';

import {notesCreatePermissions} from '@models/permissions/permissions';

import {withMultiplePermission} from 'src/features/app/permission/PermissionHoc';
import {BulkKYCChooseActionStepButton} from '../../block-account-verification-bulk-actions';
import {BulkActionKey} from '../../block-bulk-actions';

type KYCWorkspaceBulkPlayerButtonInnerProps = {
    selectedIds: string[];
    actionKeys: BulkActionKey[];
};

function KYCWorkspaceBulkPlayerButtonInner({selectedIds, actionKeys}: KYCWorkspaceBulkPlayerButtonInnerProps) {
    return <BulkKYCChooseActionStepButton ids={selectedIds} actionKeys={actionKeys} />;
}

export const KYCWorkspaceBulkPlayerButton = withMultiplePermission(KYCWorkspaceBulkPlayerButtonInner, {
    allowedPermissions: [...notesCreatePermissions],
});
