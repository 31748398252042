import {createAction, createAsyncAction} from 'typesafe-actions';

import {BoRole} from '@redux/entity';

import {JurisdictionFeature} from '../../features/app/config/types';
import {gridModuleActions} from '../../features/module-shared/actions';
import {FormModuleData, IFailureResponse, IFormItem} from '../../features/module-shared/types';

import {AvailableModules, domain} from './types';

const loadModules = 'loadModules';
const loadModulesSucceed = 'loadModulesSucceed';
const loadModulesFailed = 'loadModulesFailed';

const saveRoleAction = '/saveRole';
const saveRoleSucceedAction = '/saveRoleSucceed';
const saveRoleFailedAction = '/saveRoleFailed';

const openRoleForm = '/openRoleForm';
const closeRoleForm = '/closeRoleForm';

export const loadModulesWithDetailsAction = createAsyncAction(
    `${domain}${loadModules}`,
    `${domain}${loadModulesSucceed}`,
    `${domain}${loadModulesFailed}`
)<JurisdictionFeature[], AvailableModules, IFailureResponse>();

export default {
    ...gridModuleActions(domain),

    saveRole: createAsyncAction(`${domain}${saveRoleAction}`, `${domain}${saveRoleSucceedAction}`, `${domain}${saveRoleFailedAction}`)<
        FormModuleData<BoRole>,
        IFormItem,
        IFailureResponse
    >(),

    openRoleForm: createAction(`${domain}${openRoleForm}`)<BoRole>(),

    closeRoleForm: createAction(`${domain}${closeRoleForm}`)<void>(),
};
