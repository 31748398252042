import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {MessageType, PatchAsyncAction} from '../../types';

import {getTempStorageEntityKeys} from './utils';

export const useTempStorageUpdateState = <T>(
    action: PatchAsyncAction,
    field: string,
    id: string,
    typeName: string,
    messageType: MessageType
) => {
    const dispatch = useDispatch();
    const {fieldKey, rowKey} = getTempStorageEntityKeys(typeName, id, field);

    const isUpdateInProgress = useSelector(
        (s: RootState) => s.tempStorage.inProgress[rowKey] === true || s.tempStorage.inProgress[fieldKey] === true
    );

    const update = (value: T, model?: unknown) => {
        dispatch(action.request({item: value, id, field, fieldKey, modelKey: rowKey, model, messageType}));
    };

    return {isUpdateInProgress, update, storageKey: fieldKey};
};
