import {createAction} from 'typesafe-actions';

import {IBreadcrumb} from 'src/common/types';

//TODO: [BO-2708] Move files files from src/features/app/breadcrumbs to src/common/redux/routing (rename)
const setBreadcrumb = 'breadcrumb/setBreadcrumb';
const setBreadcrumbSuccess = 'breadcrumb/setBreadcrumbSuccess';

export const setBreadcrumbAction = createAction(setBreadcrumb)<Array<IBreadcrumb>>();
export const setBreadcrumbSuccessAction = createAction(setBreadcrumbSuccess)<Array<IBreadcrumb>>();
