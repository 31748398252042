import React, {FC, useRef, useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import {ValueButtonProps} from '@components/button/ValueButton';
import {ValueButtonDropdown} from '@components/dropdown/Dropdown';
import {DropdownType} from '@components/dropdown/DropdownHoc';
import {SelectMenuItemCreator} from '@components/select';

import {SelectOption} from '../../module-shared/types';

const useClasses = makeStyles()(() => ({
    bulkSelectActionButtonContainer: {
        position: 'relative',
        overflow: 'visible !important',
        padding: '0 !important',
    },
}));

export type BulkApplyStepDropdownButtonProps = ValueButtonProps & {
    options: SelectOption[];
    children?: React.ReactNode;
};

export const BulkChooseActionStepDropdownButton: FC<BulkApplyStepDropdownButtonProps> = ({options, onClick, children, ...otherProps}) => {
    const {classes} = useClasses();
    const containerRef = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState(false);
    const handleDropdownOpen = () => {
        setOpen(true);
    };

    const handleDropdownClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.bulkSelectActionButtonContainer} ref={containerRef}>
            <ValueButtonDropdown
                {...otherProps}
                dropdownType={DropdownType.Attached}
                container={containerRef.current}
                onDropdownOpen={handleDropdownOpen}
                onDropdownClose={handleDropdownClose}
                open={open}
                buttonContent={children as JSX.Element}
            >
                <SelectMenuItemCreator options={options} onSubmit={onClick} />
            </ValueButtonDropdown>
        </div>
    );
};
