import React from 'react';
import {ErrorCode, FileRejection} from 'react-dropzone';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';

import {useDropzoneContentClasses} from './DropzoneContent.style';
import {localized} from './DropzoneContentFailed.lozalize';

const DropzoneContentMessageFailed = ({fileRejection}: {fileRejection: FileRejection}): JSX.Element => {
    const errorCodeToFailedMessageMapper: Record<ErrorCode, MessageDescriptor> = {
        [ErrorCode.FileTooLarge]: localized.dropzoneContentFailedTooLargeMessage,
        [ErrorCode.FileInvalidType]: localized.dropzoneContentFailedInvalidTypeMessage,
        [ErrorCode.FileTooSmall]: localized.dropzoneContentFailedTooSmallMessage,
        [ErrorCode.TooManyFiles]: localized.dropzoneContentFailedTooManyFilesMessage,
    };

    return (
        <Box>
            <LocalizedText
                label={errorCodeToFailedMessageMapper[fileRejection.errors[0].code as ErrorCode]}
                labelParams={{
                    filename: <b>{fileRejection?.file?.name ?? ''}</b>,
                }}
            />
        </Box>
    );
};

type FailedDropzoneContentProps = {
    rejectFile: FileRejection;
};

const DropzoneContentFailed = ({rejectFile}: FailedDropzoneContentProps) => {
    const {classes, cx} = useDropzoneContentClasses();

    return (
        <>
            <Icon
                icon={CustomIcon.ExclamationPoint}
                color={IconColor.PrimaryContrastText}
                className={cx(classes.dropzoneContentRejectIcon, classes.dropzoneContentResultIcon, classes.dropzoneContentIcon)}
            />
            {rejectFile ? <DropzoneContentMessageFailed fileRejection={rejectFile} /> : null}
        </>
    );
};

export default DropzoneContentFailed;
