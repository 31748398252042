export function partialMatch(value: string): string {
    return value?.toString()?.split(' ').join('').concat('*');
}

export function partialMatchWithSpecialSymbols(value: string): string {
    function hasSpecialSymbol(text: string) {
        return text?.match(/[#@&<>~.?+\-_*|{}[\]()"\\]/i);
    }
    function formatSpecialSymbols(text: string) {
        return text
            ?.toString()
            ?.split(/(?=[#@&<>~.?+\-_*|{}[\]()"\\])/)
            .join('\\');
    }
    return hasSpecialSymbol(value) ? formatSpecialSymbols(value) : partialMatch(value);
}
