import React, {FC, memo} from 'react';
import {FormattedNumber} from 'react-intl';

import {withEmptyCheck} from '@components/label';

type PercentCellProps = {
    value: number;
};

export const PercentCellFormatter = memo(({value = 0}: PercentCellProps) => {
    return <FormattedNumber value={value / 100} style="percent" />;
});

export const PercentCell: FC<PercentCellProps> = withEmptyCheck((props: PercentCellProps) => {
    return <PercentCellFormatter {...props} />;
});
