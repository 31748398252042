import React from 'react';
import {defineMessages} from 'react-intl';
import moment from 'moment-timezone';

import {LocalizedText} from '@components/i18n';
import {EmptyLabel} from '@components/label';
import {Timestamp} from '@models/shared';
import {timestampSecondsToMoment} from '@utils';

const localized = defineMessages({
    day: {
        id: 'DurationCell_day',
        defaultMessage: '{value} day',
    },
    days: {
        id: 'DurationCell_days',
        defaultMessage: '{value} days',
    },
});

type DurationCellProps = {
    timestamp?: Timestamp;
    count?: number;
    mode: 'days';
};

export function DurationCell({timestamp, count}: DurationCellProps) {
    const value = Math.abs(timestampSecondsToMoment(timestamp?.seconds)?.diff(moment.utc().endOf('day'), 'days') ?? count);
    const localizedLabel = value === 1 ? localized.day : localized.days;
    return value ? <LocalizedText label={localizedLabel} labelParams={{value}} /> : <EmptyLabel defaultSymbol="dash" />;
}
