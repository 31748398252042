import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';

import {RuleType, useValidationFormatter} from '@components/input';

import {localized as relativeRangeLocalized} from './RelativeRange';
import {Operator, ValueRange, ValueRangeModel} from './types';
import {getMaxValue, getMinValue} from './utils';

export const localized = defineMessages({
    anyValue: {
        id: 'ValueRangeFilter_anyValue',
        defaultMessage: 'Any',
    },
    selectBoxBetween: {
        id: 'ValueRangeFilter_selectBoxBetween',
        defaultMessage: 'between: {from}-{to}',
    },
    selectBoxEquals: {
        id: 'ValueRangeFilter_selectBoxEquals',
        defaultMessage: 'equals: {from}',
    },
    selectBoxIsGreaterThan: {
        id: 'ValueRangeFilter_selectBoxIsGreaterThan',
        defaultMessage: 'is greater than: {from}',
    },
    selectBoxIsLessThan: {
        id: 'ValueRangeFilter_selectBoxIsLessThan',
        defaultMessage: 'is less than: {to}',
    },
});

export const useSelectBoxValue = (model: ValueRangeModel): string => {
    const {formatMessage} = useIntl();

    if (isNaN(model?.value?.from) && isNaN(model?.value?.to)) return formatMessage(localized.anyValue);

    const {
        operator,
        value: {from, to},
    } = model;
    const messageMapper: Record<Operator, string> = {
        between: formatMessage(localized.selectBoxBetween, {from, to}),
        equals: formatMessage(localized.selectBoxEquals, {from}),
        less: formatMessage(localized.selectBoxIsLessThan, {to}),
        greater: formatMessage(localized.selectBoxIsGreaterThan, {from}),
    };

    const formatted = messageMapper[operator];

    return formatted ?? formatMessage(localized.anyValue);
};

type UseValidationProps = {
    min?: number;
    max?: number;
    validateFromValue: boolean;
    validateToValue: boolean;
};

type UseValidationResult = {
    fromRequired: (v: ValueRange) => true | string;
    fromMinValue: (v: ValueRange) => true | string;
    fromMaxValue: (v: ValueRange) => true | string;
    toRequired: (v: ValueRange) => true | string;
    toMinValue: (v: ValueRange) => true | string;
    toMaxValue: (v: ValueRange) => true | string;
    isInteger: (v: ValueRange) => true | string;
};

export function useValidation({min, max, validateFromValue, validateToValue}: UseValidationProps): UseValidationResult {
    const validationFormatter = useValidationFormatter();

    function validateRequired(value: ValueRange, fieldKey: keyof ValueRange, validate: boolean, label: MessageDescriptor): true | string {
        const validationResult = validate ? !isNaN(value[fieldKey]) : true;
        return validationResult ? validationResult : validationFormatter(RuleType.Required, label);
    }

    function validateMinValue(
        value: ValueRange,
        fieldToValidate: keyof ValueRange,
        validate: boolean,
        label: MessageDescriptor
    ): true | string {
        const minValue = getMaxValue(min, value.from);
        let validationResult = false;
        if (!validate || isNaN(minValue) || minValue > max) {
            validationResult = true;
        } else {
            validationResult = value[fieldToValidate] >= minValue;
        }
        return validationResult ? validationResult : validationFormatter(RuleType.Min, label, minValue);
    }

    function validateMaxValue(
        value: ValueRange,
        fieldToValidate: keyof ValueRange,
        validate: boolean,
        label: MessageDescriptor
    ): true | string {
        const maxValue = getMinValue(max, value.to);
        let validationResult = false;
        if (!validate || isNaN(maxValue) || maxValue < min) {
            validationResult = true;
        } else {
            validationResult = value[fieldToValidate] <= maxValue;
        }
        return validationResult ? validationResult : validationFormatter(RuleType.Max, label, maxValue);
    }

    function validateInteger(value: ValueRange) {
        let validationResult: string | boolean = true;
        if (typeof value.from === 'number' && !Number.isInteger(value.from)) {
            validationResult = validationFormatter(RuleType.Integer, relativeRangeLocalized.firstValueRangeInput);
        } else if (typeof value.to === 'number' && !Number.isInteger(value.to)) {
            validationResult = validationFormatter(RuleType.Integer, relativeRangeLocalized.secondValueRangeInput);
        }
        return validationResult;
    }

    return {
        fromRequired: (value: ValueRange) =>
            validateRequired(value, 'from', validateFromValue, relativeRangeLocalized.firstValueRangeInput),
        fromMinValue: (value: ValueRange) =>
            validateMinValue(value, 'from', validateFromValue, relativeRangeLocalized.firstValueRangeInput),
        fromMaxValue: (value: ValueRange) =>
            validateMaxValue(value, 'from', validateFromValue, relativeRangeLocalized.firstValueRangeInput),
        toRequired: (value: ValueRange) => validateRequired(value, 'to', validateToValue, relativeRangeLocalized.secondValueRangeInput),
        toMinValue: (value: ValueRange) => validateMinValue(value, 'to', validateToValue, relativeRangeLocalized.secondValueRangeInput),
        toMaxValue: (value: ValueRange) => validateMaxValue(value, 'to', validateToValue, relativeRangeLocalized.secondValueRangeInput),
        isInteger: validateInteger,
    };
}
