import React, {memo, useState} from 'react';
import {defineMessages} from 'react-intl';
import {Button, FormControlLabel, Switch} from '@mui/material';
import {
    GridColumnsPanelProps as MuiGridColumnsPanelProps,
    GridPanelContent,
    GridPanelFooter,
    GridPanelHeader,
    GridPanelWrapper,
} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon} from '@components/icons';
import {FormTextInputCleanable} from '@components/input';

import {GridColDef} from './types';

const localized = defineMessages({
    searchInputPlaceholder: {
        id: 'GridColumnPanel_searchInputPlaceholder',
        defaultMessage: 'Find column',
    },
    hideAllButtonLabel: {
        id: 'GridColumnPanel_hideAllButtonLabel',
        defaultMessage: 'Hide all',
    },
    showAllButtonLabel: {
        id: 'GridColumnPanel_showAllButtonLabel',
        defaultMessage: 'Show all',
    },
});

const useStyles = makeStyles()(theme => ({
    gridColumnsPanelWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: 'max-content',
        maxHeight: '550px',
        backgroundColor: '#ffffff',
        borderRadius: theme.shape.borderRadius * 2,
    },
    gridColumnsPanelHeader: {
        padding: theme.spacing(1.5),
        '& .MuiFormControl-root': {
            margin: 0,
        },
    },
    gridColumnsPanelSearchInputIcon: {
        fontSize: 12,
        color: theme.palette.secondary.main,
    },
    gridColumnsPanelContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        overflow: 'auto',
        maxHeight: 'unset',
        padding: `0 ${theme.spacing(1.5)} ${theme.spacing()}`,
    },
    gridColumnsPanelColumn: {
        marginLeft: theme.spacing(1.5),
        '& .MuiSwitch-root': {
            marginRight: theme.spacing(),
        },
    },
    gridColumnsPanelFooter: {
        padding: `${theme.spacing(1.75)} ${theme.spacing(1.5)}`,
        borderTop: `1px solid ${theme.palette.secondary.light}`,
        '& .MuiButton-text': {
            padding: 0,
            minWidth: 'unset',
        },
    },
}));

export type GridColumnsPanelProps = MuiGridColumnsPanelProps & {
    pinnedColumnNames: string[];
    onColumnsVisibilityUpdate: (updatedColumns: string[]) => void;
    allColumns: GridColDef[];
};

function GridColumnsPanelInner({pinnedColumnNames, onColumnsVisibilityUpdate, allColumns}: GridColumnsPanelProps) {
    const {classes} = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const columns = allColumns?.filter(c => !pinnedColumnNames?.includes(c.field));

    const searchedColumns = React.useMemo(() => {
        let result: GridColDef[] = columns;
        if (searchValue) {
            result = columns.filter(column => column?.headerName?.toLowerCase()?.includes(searchValue.toLowerCase()));
        }
        return result;
    }, [allColumns, searchValue]);

    function handleSearchValueChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value);
    }

    function handleColumnClick(event: React.MouseEvent<HTMLButtonElement>) {
        const name = (event.target as HTMLInputElement).name;
        const column = allColumns.find(c => c.field === name);
        const newColumns = column.hide ? showColumns([name]) : hideColumns([name]);
        onColumnsVisibilityUpdate(newColumns);
    }

    function handleToggleAllColumns(value: boolean) {
        const searchedColumnNames = searchedColumns?.map(c => c.field);
        const newColumns = value ? hideColumns(searchedColumnNames) : showColumns([...searchedColumnNames]);
        onColumnsVisibilityUpdate(newColumns);
    }

    function hideColumns(names: string[]): string[] {
        return allColumns.filter(c => !c.hide && !names.includes(c.field)).map(c => c.field);
    }

    function showColumns(names: string[]): string[] {
        return [...new Set([...allColumns.filter(c => !c.hide).map(c => c.field), ...names])];
    }

    function handleClearSearch() {
        setSearchValue('');
    }

    return (
        <GridPanelWrapper className={classes.gridColumnsPanelWrapper}>
            <GridPanelHeader className={classes.gridColumnsPanelHeader}>
                <FormTextInputCleanable
                    startAdornment={<Icon icon={CustomIcon.Search} className={classes.gridColumnsPanelSearchInputIcon} />}
                    placeholder={localized.searchInputPlaceholder}
                    value={searchValue}
                    onChange={handleSearchValueChange}
                    clear={handleClearSearch}
                />
            </GridPanelHeader>
            <GridPanelContent className={classes.gridColumnsPanelContent}>
                {searchedColumns.map(column => (
                    <div key={column.field} className={classes.gridColumnsPanelColumn}>
                        <FormControlLabel
                            control={<Switch checked={!column.hide} onClick={handleColumnClick} name={column.field} color="secondary" />}
                            label={column.headerName || column.field}
                        />
                    </div>
                ))}
            </GridPanelContent>
            <GridPanelFooter className={classes.gridColumnsPanelFooter}>
                <Button onClick={() => handleToggleAllColumns(true)} color="primary">
                    <LocalizedText label={localized.hideAllButtonLabel} />
                </Button>
                <Button onClick={() => handleToggleAllColumns(false)} color="primary">
                    <LocalizedText label={localized.showAllButtonLabel} />
                </Button>
            </GridPanelFooter>
        </GridPanelWrapper>
    );
}

export const GridColumnsPanel = memo(GridColumnsPanelInner);
