import React, {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {List} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {RootState} from 'typesafe-actions';

import {RouteUrl} from 'src/common/routeEnums';
import {Route} from 'src/common/types';
import {RoutesContext} from '../../../common/routing/RoutesProvider';
import {locationPathnameSelector} from '../../app/routing/selectors';
import {selectModuleAction} from '../actions';

import {ModuleListItem, ModuleListItemWithSubmodules} from './ModuleListItem';

const useClasses = makeStyles()(theme => ({
    moduleList: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        paddingTop: 0,
    },
    moduleListMinimized: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1.5),
        paddingTop: 0,
    },
}));

const mapRouteUrl = (route: Route, baseRouteUrl = ''): Route => {
    const filteredRoute: Route = {
        name: route.name,
        title: route.title,
        menuIcon: route.menuIcon,
        url: `${baseRouteUrl}${route.url}` as RouteUrl,
    };

    if (route.subRoutes?.length) {
        filteredRoute.subRoutes = route.subRoutes.map<Route>(s => mapRouteUrl(s, filteredRoute.url));
    }

    return filteredRoute;
};

type ModuleListProps = {
    showSubmodules?: boolean;
};

function ModuleList({showSubmodules}: ModuleListProps) {
    const {classes} = useClasses();
    const dispatch = useDispatch();
    const currentModuleName = useSelector((state: RootState) => state.modules.currentModuleName);
    const path = useSelector(locationPathnameSelector) || '';
    const {routes} = useContext(RoutesContext);

    const moduleRoutes = routes.map<Route>(r => mapRouteUrl(r));

    const allRoutes = moduleRoutes?.reduce(
        (acc: Route[], r: Route) => (r.subRoutes && r.subRoutes.length > 0 ? acc.concat(r.subRoutes) : acc.concat(r)),
        [] as Route[]
    );

    const pathModuleName = allRoutes.find(r => path === r.url)?.name || allRoutes.find(r => path.startsWith(r.url))?.name;

    useEffect(() => {
        if (pathModuleName && currentModuleName !== pathModuleName) {
            dispatch(selectModuleAction(pathModuleName));
        }
    }, [pathModuleName, currentModuleName]);

    return (
        <List className={showSubmodules ? classes.moduleList : classes.moduleListMinimized}>
            {moduleRoutes.map(route => {
                if (route.subRoutes) {
                    return (
                        <ModuleListItemWithSubmodules
                            key={route.url}
                            {...route}
                            nestingLevel={1}
                            selectedModuleName={currentModuleName}
                            showSubmodules={showSubmodules}
                        />
                    );
                } else {
                    return (
                        <ModuleListItem
                            key={route.url}
                            {...route}
                            nestingLevel={0}
                            selectedModuleName={currentModuleName}
                            showItemText={showSubmodules}
                        />
                    );
                }
            })}
        </List>
    );
}

export default ModuleList;
