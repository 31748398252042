import React, {useEffect, useState} from 'react';
import {defineMessages} from 'react-intl';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {CustomIcon} from '@components/icons/types';
import {MultiSelectDropdownButton} from '@components/select/MultiSelectDropdownButton';
import {AccountVerificationStatus, KycReasonCode} from '@models/generated/graphql';
import {agentReadPolicies} from '@models/permissions/permissions';

import {localizedKYCReasonCodes} from '../../app/intl';
import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {SelectOption} from '../../module-shared/types';
import {actionDisabledStatuses, usePaymentKycAction} from '../hooks';
import {initiatePaymentKycPermissions} from '../permissions';

const localized = defineMessages({
    initiatePaymentLabel: {
        id: 'WD_initiatePaymentLabel',
        defaultMessage: 'Initiate Payment KYC',
    },
    initiatePaymentOptionsLabel: {
        id: 'WD_initiatePaymentOptionsLabel',
        defaultMessage: 'KYC REASON',
    },
    tooltipTitle: {
        id: 'InitiatePaymentKyc_tooltipTitle',
        defaultMessage: 'Selected KYC Reason',
    },
});

type InitiatePaymentKycProps = {
    disabled?: boolean;
    uid: string;
    reasons: KycReasonCode[];
    status: AccountVerificationStatus;
};

function InitiatePaymentKycInner({disabled, uid, reasons = [], status}: InitiatePaymentKycProps) {
    const [reasonsValue, setReasonValue] = useState<KycReasonCode[]>(getReasonsValue(status, reasons));
    const {initiateKyc, isActionDisabled} = usePaymentKycAction();

    useEffect(() => {
        setReasonValue(getReasonsValue(status, reasons));
    }, [reasons?.join(), status]);

    function handleApplyClick(reasons: KycReasonCode[]) {
        initiateKyc(uid, reasons);
    }

    function getReasonsValue(status: AccountVerificationStatus, reasons: KycReasonCode[]): KycReasonCode[] {
        return actionDisabledStatuses.includes(status) ? reasons : [];
    }

    return (
        <MultiSelectDropdownButton
            label={localized.initiatePaymentLabel}
            statusLabel={
                actionDisabledStatuses.includes(status) ? (
                    <MemoizedChip value={status} chipName={nameof<AccountVerificationStatus>()} />
                ) : null
            }
            iconClassName={CustomIcon.AddSquareOutline}
            optionsHeader={localized.initiatePaymentOptionsLabel}
            options={Object.values(KycReasonCode).map<SelectOption>((v: KycReasonCode) => ({
                label: localizedKYCReasonCodes[v],
                value: v,
            }))}
            onApply={handleApplyClick}
            value={reasonsValue}
            disabled={disabled || isActionDisabled(status)}
            tooltipTitle={localized.tooltipTitle}
            dataTestId="initiate-payment-kyc-action"
        />
    );
}

export const InitiatePaymentKyc = withMultiplePermission(
    withMultiplePermission(InitiatePaymentKycInner, {
        allowedPermissions: initiatePaymentKycPermissions,
        indicator: LackOfPermissionIndicator.Disabled,
    }),
    {restrictedPermissions: agentReadPolicies}
);
