import React from 'react';

import {CountryFilterAdapter} from '../../block-country';

import {AccountVerificationsFilterProps} from './AccountVerificationsFilters';

function AccountVerificationsFilterCountry({filter, onFilterChange}: AccountVerificationsFilterProps) {
    return <CountryFilterAdapter onFilterChange={onFilterChange} domain={null} filter={null} filterString={filter} />;
}

export default AccountVerificationsFilterCountry;
