import React, {useContext} from 'react';
import {Switch} from 'react-router';
import {Redirect} from 'react-router-dom';

import {RouteUrl} from 'src/common/routeEnums';
import ProtectedRoute from 'src/features/protected-route/components/ProtectedRoute';
import AccessManagement from 'src/pages/access-management/components/AccessManagement';
import AgentKrDailyReport from 'src/pages/AgentKrDailyReport';
import AgentLevelReport from 'src/pages/AgentLevelReport';
import AgentManagementTransferReport from 'src/pages/AgentManagementTransferReport';
import AgentVnDailyReport from 'src/pages/AgentVnDailyReport';
import AgentVnWeeklyReport from 'src/pages/AgentVnWeeklyReport';
import BonusEngineDetails from 'src/pages/bonus-engine-details/components/BonusEngineDetails';
import CustomerSupportDetails from 'src/pages/CustomerSupportDetails';
import CustomerSupportWorkspace from 'src/pages/CustomerSupportWorkspace';
import {DevPage} from 'src/pages/dev/DevPage';
import GameAdminPLO5 from 'src/pages/GameAdminPLO5';
import KYCProcessing from 'src/pages/kyc-processing/components/KYCProcessing';
import NDRPProcessing from 'src/pages/kyc-processing/components/NDRPProcessing';
import PrivacyPolicy from 'src/pages/legal-docs/components/PrivacyPolicy';
import TermsAndConditions from 'src/pages/legal-docs/components/TermsAndConditions';
import VersionAcceptance from 'src/pages/legal-docs/components/VersionAcceptance';
import LoginHistory from 'src/pages/LoginHistory';
import MarketingBonusCode from 'src/pages/marketing-bonus-code/components/MarketingBonusCode';
import P2PReferralOfferingAdmin from 'src/pages/P2PReferralOfferingAdmin';
import PlayerLevelReport from 'src/pages/PlayerLevelReport';
import RegistrationInfo from 'src/pages/registration-info/components/RegistrationInfo';
import RolesManagement from 'src/pages/role-management/components/RolesManagement';
import SecurityDetails from 'src/pages/SecurityDetails';
import SecurityWorkspace from 'src/pages/SecurityWorkspace';
import {SportsbookTransactionHistory} from 'src/pages/SportsbookTransactionHistory';
import {NoPermissionsPage, NotFoundPage} from 'src/pages/SystemPages';
import TeamWorkspace from 'src/pages/TeamWorkspace';
import TransactionHistory from 'src/pages/transaction-history/components/TransactionHistory';
import WithdrawalDetails from 'src/pages/WithdrawalDetails';
import {RoutesContext} from '../../common/routing/RoutesProvider';
import {useConfig} from '../../configuration';
import AgentManagementPathStatistics from '../../pages/AgentManagementPathStatistics';
import AgentManagementPage from '../../pages/asia/AgentManagementPage';
import {BonusEnginePage} from '../../pages/BonusEngine';
import {AgentLevelEmbeddedReport, PlayerLevelEmbeddedReport, TransferEmbeddedReport} from '../../pages/EmbeddedReports';
import KycProcessingDetailsPage from '../../pages/KycProcessingDetails';
import NdrpProcessingDetailsPage from '../../pages/NdrpProcessingDetails';
import Rules from '../../pages/Rules';
import WithdrawalProcessing from '../../pages/WithdrawalProcessing';

import {concatUrl} from './routes';

function useDefaultRoute(): string {
    const {routes} = useContext(RoutesContext);
    const firstAccessibleRoute = routes?.length ? routes[0] : null;
    const defaultRoute = firstAccessibleRoute
        ? concatUrl(firstAccessibleRoute.url, firstAccessibleRoute.subRoutes ? firstAccessibleRoute.subRoutes[0].url : undefined)
        : RouteUrl.NoPermission;
    return defaultRoute;
}

function GlobalRoutes() {
    const defaultRoute = useDefaultRoute();
    return (
        <Switch>
            <Redirect exact from="/" to={defaultRoute} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AccessManagement, RouteUrl.Users)} component={AccessManagement} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AccessManagement, RouteUrl.Roles)} component={RolesManagement} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.PlayerManagement, RouteUrl.LoginHistory)} component={LoginHistory} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.PlayerManagement, RouteUrl.TransactionHistory)} component={TransactionHistory} />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.PlayerManagement, RouteUrl.RegistrationInformation)}
                component={RegistrationInfo}
            />
            <ProtectedRoute exact path={concatUrl(RouteUrl.LegalContentManagement, RouteUrl.PrivacyPolicy)} component={PrivacyPolicy} />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.LegalContentManagement, RouteUrl.TermsAndConditions)}
                component={TermsAndConditions}
            />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.LegalContentManagement, RouteUrl.UserAcceptanceVersion)}
                component={VersionAcceptance}
            />
            <ProtectedRoute path={RouteUrl.WithdrawalProcessing} component={WithdrawalProcessing} />
            <ProtectedRoute path={RouteUrl.KycProcessing} component={KYCProcessing} />
            <ProtectedRoute path={RouteUrl.NDRPProcessing} component={NDRPProcessing} />
            <ProtectedRoute path={RouteUrl.SecurityWorkspace} component={SecurityWorkspace} />
            <ProtectedRoute exact path={RouteUrl.SecurityDetails} component={SecurityDetails} />
            <ProtectedRoute exact path={RouteUrl.WithdrawalDetails} component={WithdrawalDetails} />
            <ProtectedRoute exact path={RouteUrl.KYCDetails} component={KycProcessingDetailsPage} />
            <ProtectedRoute exact path={RouteUrl.NDRPDetails} component={NdrpProcessingDetailsPage} />
            <ProtectedRoute path={RouteUrl.CustomerSupport} component={CustomerSupportWorkspace} />
            <ProtectedRoute exact path={RouteUrl.CustomerSupportDetails} component={CustomerSupportDetails} />
            <ProtectedRoute path={concatUrl(RouteUrl.MarketingFunction, RouteUrl.MarketingBonusCode)} component={MarketingBonusCode} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.MarketingFunction, RouteUrl.BonusEngine)} component={BonusEnginePage} />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.MarketingFunction, RouteUrl.BonusEngineDetails)}
                component={BonusEngineDetails}
            />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.MarketingFunction, RouteUrl.P2PReferralOfferingAdmin)}
                component={P2PReferralOfferingAdmin}
            />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AgentManagement, RouteUrl.PlayerLevelReport)} component={PlayerLevelReport} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AgentManagement, RouteUrl.AgentLevelReport)} component={AgentLevelReport} />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.AgentManagement, RouteUrl.TransferReport)}
                component={AgentManagementTransferReport}
            />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AgentReportVn, RouteUrl.PlayerReportDaily)} component={AgentVnDailyReport} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AgentReportVn, RouteUrl.PlayerReportWeekly)} component={AgentVnWeeklyReport} />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.AgentReportVn, RouteUrl.PathStatisticsVn)}
                component={AgentManagementPathStatistics}
            />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AgentReportKr, RouteUrl.PlayerReportDaily)} component={AgentKrDailyReport} />
            <ProtectedRoute path={concatUrl(RouteUrl.GameAdmin, RouteUrl.PLO5)} component={GameAdminPLO5} />
            <ProtectedRoute path={RouteUrl.Rules} component={Rules} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AgentReportPlayerDetails)} component={CustomerSupportDetails} />
            <ProtectedRoute exact path={RouteUrl.TeamWorkspace} component={TeamWorkspace} />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.SportsbookOperation, RouteUrl.TransactionHistory)}
                component={SportsbookTransactionHistory}
            />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.EmbeddedReport, RouteUrl.PlayerLevelReport)}
                component={PlayerLevelEmbeddedReport}
            />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.EmbeddedReport, RouteUrl.AgentLevelReport)}
                component={AgentLevelEmbeddedReport}
            />
            <ProtectedRoute exact path={concatUrl(RouteUrl.EmbeddedReport, RouteUrl.TransferReport)} component={TransferEmbeddedReport} />
            <ProtectedRoute exact path={'/dev'} component={DevPage} />
            <ProtectedRoute exact path={RouteUrl.NoPermission} component={NoPermissionsPage} />
            <ProtectedRoute exact path="*" component={NotFoundPage} />
        </Switch>
    );
}

function AsiaRoutes() {
    const defaultRoute = useDefaultRoute();
    return (
        <Switch>
            <Redirect exact from="/" to={defaultRoute} />
            <ProtectedRoute exact path={RouteUrl.AgentManagementPage} component={AgentManagementPage} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AgentReportVn, RouteUrl.PlayerReportDaily)} component={AgentVnDailyReport} />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AgentReportVn, RouteUrl.PlayerReportWeekly)} component={AgentVnWeeklyReport} />
            <ProtectedRoute
                exact
                path={concatUrl(RouteUrl.AgentReportVn, RouteUrl.PathStatisticsVn)}
                component={AgentManagementPathStatistics}
            />
            <ProtectedRoute exact path={concatUrl(RouteUrl.AgentReportKr, RouteUrl.PlayerReportDaily)} component={AgentKrDailyReport} />
            <ProtectedRoute exact path={'/dev'} component={DevPage} />
            <ProtectedRoute exact path={RouteUrl.NoPermission} component={NoPermissionsPage} />
            <ProtectedRoute exact path="*" component={NotFoundPage} />
        </Switch>
    );
}

export function AppRoutes() {
    const {application} = useConfig();
    return application === 'operations' ? <GlobalRoutes /> : <AsiaRoutes />;
}
