import React, {useState} from 'react';
import {Box} from '@mui/material';

import {EnrichedButton, EnrichedButtonItem} from './EnrichedButton';
import {useEnrichedButtonGroupStyles} from './EnrichedButtonGroup.style';

export type EnrichedButtonGroupProps = {
    items: EnrichedButtonItem[];
    onClick?: (item: EnrichedButtonItem) => void;
};

export const EnrichedButtonGroup = ({items, onClick}: EnrichedButtonGroupProps) => {
    const {classes} = useEnrichedButtonGroupStyles();
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const mappedItems = items.map(i => ({...i, selected: i.id === selectedTemplate}));

    const handleSelect = (option: EnrichedButtonItem) => {
        onClick(option);
        setSelectedTemplate(option.id);
    };

    return (
        <Box>
            {mappedItems.map(option => {
                return (
                    <EnrichedButton
                        key={option.id}
                        className={classes.enrichedButtonGroupItem}
                        onClick={() => handleSelect(option)}
                        {...option}
                    />
                );
            })}
        </Box>
    );
};
