import {defineMessages} from 'react-intl';

import {GeoCheckStatus} from '@models/generated/graphql';

export const localizedGeoCheckStatus = defineMessages({
    [GeoCheckStatus.Success]: {
        id: 'geoCheckStatusSuccess',
        defaultMessage: 'Success',
    },
    [GeoCheckStatus.Failed]: {
        id: 'loginStatusFailed',
        defaultMessage: 'Failed',
    },
});
