import React, {ReactNode} from 'react';
import {RadioGroup} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {withStyledInputLabel} from './StyledFormInputHoc';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    radioInputColorGroup: {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, ${theme.spacing(3.75)})`,
        columnGap: theme.spacing(0.75),
        marginBottom: theme.spacing(2),
    },
}));

type RadioInputColorGroupProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    name: string;
    defaultValue: string;
    children?: ReactNode;
};

function RadioInputColorGroup({children, onChange, name, defaultValue}: RadioInputColorGroupProps) {
    const {classes} = useClasses();

    return (
        <RadioGroup className={classes.radioInputColorGroup} onChange={onChange} name={name} defaultValue={defaultValue} row>
            {children}
        </RadioGroup>
    );
}

export const StyledRadioInputColorGroup = withStyledInputLabel(RadioInputColorGroup);
