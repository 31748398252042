import React, {useRef} from 'react';

import {
    CurrencyColumn,
    DataGridColumn,
    DataGridServer,
    DataGridServerProps,
    GridModelWithSummary,
    LinkColumn,
    LocalizedTextColumn,
    TextColumn,
} from '@components/data-grid';
import {AgentPlayerReportingViewModel, AgentPlayerReportingViewModelKeys} from '@models/agent-player-reporting';
import {appendSelectedItemToQuery} from '@utils';

import {RouteUrl} from 'src/common/routeEnums';
import {localizedRefereeType} from '../../app/intl/shared-resources/refereeType';
import {localizedRevenueShareType} from '../../app/intl/shared-resources/revenueShareType';

import {localizedHeaders} from './DataGridAgentPlayerReporting.localize';

type AgentPlayerReportingGridViewModel = AgentPlayerReportingViewModel & GridModelWithSummary;

class DataGridAgentPlayerReportingConfigFactory {
    private readonly _config: Partial<Record<AgentPlayerReportingViewModelKeys, DataGridColumn<AgentPlayerReportingGridViewModel>>>;

    constructor() {
        const uid_link: LinkColumn<AgentPlayerReportingGridViewModel> = {
            field: 'uid_link',
            valueGetter: model => ({path: appendSelectedItemToQuery(RouteUrl.AgentReportPlayerDetails, model?.uid), title: model?.uid}),
            title: localizedHeaders.uid,
            sortable: false,
            width: 100,
            type: 'link',
        };
        const uid_text: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'uid_text',
            valueGetter: model => model?.uid,
            title: localizedHeaders.uid,
            sortable: false,
            width: 100,
            type: 'text',
        };
        const username: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'username',
            valueGetter: model => model?.username,
            title: localizedHeaders.username,
            sortable: false,
            width: 200,
            type: 'text',
        };
        const phone: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'phone',
            valueGetter: model => model?.phone,
            title: localizedHeaders.phone,
            sortable: false,
            width: 200,
            type: 'text',
        };
        const email: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'email',
            valueGetter: model => model?.email,
            title: localizedHeaders.email,
            sortable: false,
            width: 200,
            type: 'text',
        };
        const country: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'country',
            valueGetter: model => model?.country,
            title: localizedHeaders.country,
            sortable: false,
            width: 100,
            type: 'text',
        };
        const referrer_agent_id: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'referrer_agent_id',
            valueGetter: model => model?.referrer_agent_id,
            title: localizedHeaders.referrer_agent_id,
            sortable: false,
            width: 150,
            type: 'text',
        };
        const referrer_agent_username: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'referrer_agent_username',
            valueGetter: model => model?.referrer_agent_username,
            title: localizedHeaders.referrer_agent_username,
            sortable: false,
            width: 200,
            type: 'text',
        };
        const register_marketing_code: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'register_marketing_code',
            valueGetter: model => model?.register_marketing_code,
            title: localizedHeaders.marketingCode,
            sortable: false,
            width: 200,
            type: 'text',
        };
        const ggr_summary_cash_ggr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.cash_ggr',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.cash_ggr, model?.currency),
            title: localizedHeaders.cash_ggr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_mtt_ggr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.mtt_ggr',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.mtt_ggr, model?.currency),
            title: localizedHeaders.mtt_ggr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_casino_ggr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.casino_ggr',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.casino_ggr, model?.currency),
            title: localizedHeaders.casino_ggr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_poker_share: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.poker_share',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.poker_share, model?.currency),
            title: localizedHeaders.poker_share,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_casino_share: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.casino_share',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.casino_share, model?.currency),
            title: localizedHeaders.casino_share,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_total_share: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.total_share',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.total_share, model?.currency),
            title: localizedHeaders.total_share,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_global_spin_ggr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.global_spin_ggr',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.global_spin_ggr, model?.currency),
            title: localizedHeaders.global_spin_ggr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_external_casino_ggr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.external_casino_ggr',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.external_casino_ggr, model?.currency),
            title: localizedHeaders.external_casino_ggr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_internal_casino_ggr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.internal_casino_ggr',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.internal_casino_ggr, model?.currency),
            title: localizedHeaders.internal_casino_ggr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_external_casino_bet_amount: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.external_casino_bet_amount',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.external_casino_bet_amount, model?.currency),
            title: localizedHeaders.external_casino_bet_amount,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_internal_casino_bet_amount: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.internal_casino_bet_amount',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.internal_casino_bet_amount, model?.currency),
            title: localizedHeaders.internal_casino_bet_amount,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_poker_addons: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.poker_addons',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.poker_addons, model?.currency),
            title: localizedHeaders.poker_addons,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_plo_ggr_high_stake: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.plo_ggr_high_stake',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.plo_ggr_high_stake, model?.currency),
            title: localizedHeaders.plo_ggr_high_stake,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_plo_ggr_low_stake: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.plo_ggr_low_stake',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.plo_ggr_low_stake, model?.currency),
            title: localizedHeaders.plo_ggr_low_stake,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_plo_ggr_medium_stake: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.plo_ggr_medium_stake',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.plo_ggr_medium_stake, model?.currency),
            title: localizedHeaders.plo_ggr_medium_stake,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_plo_ggr_micro_stake: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.plo_ggr_micro_stake',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.plo_ggr_micro_stake, model?.currency),
            title: localizedHeaders.plo_ggr_micro_stake,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_plo_ggr_total: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.plo_ggr_total',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.plo_ggr_total, model?.currency),
            title: localizedHeaders.plo_ggr_total,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_plo_ggr_unknown_stake: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.plo_ggr_unknown_stake',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.plo_ggr_unknown_stake, model?.currency),
            title: localizedHeaders.plo_ggr_unknown_stake,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_poker_ggr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.poker_ggr',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.poker_ggr, model?.currency),
            title: localizedHeaders.poker_ggr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_poker_mtt_fees: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.poker_mtt_fees',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.poker_mtt_fees, model?.currency),
            title: localizedHeaders.poker_mtt_fees,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_poker_rake: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.poker_rake',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.poker_rake, model?.currency),
            title: localizedHeaders.poker_rake,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_total: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.total',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.total, model?.currency),
            title: localizedHeaders.total_ggr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ngr_summary_cash_ngr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ngr_summary.cash_ngr',
            valueGetter: model => this.getCurrencyValue(model?.ngr_summary?.cash_ngr, model?.currency),
            title: localizedHeaders.cash_ngr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ngr_summary_casino_ngr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ngr_summary.casino_ngr',
            valueGetter: model => this.getCurrencyValue(model?.ngr_summary?.casino_ngr, model?.currency),
            title: localizedHeaders.casino_ngr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ngr_summary_mtt_ngr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ngr_summary.mtt_ngr',
            valueGetter: model => this.getCurrencyValue(model?.ngr_summary?.mtt_ngr, model?.currency),
            title: localizedHeaders.mtt_ngr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ngr_summary_poker_ngr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ngr_summary.poker_ngr',
            valueGetter: model => this.getCurrencyValue(model?.ngr_summary?.poker_ngr, model?.currency),
            title: localizedHeaders.poker_ngr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ngr_summary_total: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ngr_summary.total',
            valueGetter: model => this.getCurrencyValue(model?.ngr_summary?.total, model?.currency),
            title: localizedHeaders.total_ngr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const bonus_summary_realized_bonus_total: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'bonus_summary.realized_bonus_total',
            valueGetter: model => this.getCurrencyValue(model?.bonus_summary?.realized_bonus_total, model?.currency),
            title: localizedHeaders.realized_bonus_total,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const bonus_summary_mtt_ticket_bonus: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'bonus_summary.mtt_ticket_bonus',
            valueGetter: model => this.getCurrencyValue(model?.bonus_summary?.mtt_ticket_bonus, model?.currency),
            title: localizedHeaders.mtt_ticket_bonus,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const bonus_summary_poker_bonus: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'bonus_summary.poker_bonus',
            valueGetter: model => this.getCurrencyValue(model?.bonus_summary?.poker_bonus, model?.currency),
            title: localizedHeaders.poker_bonus,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const bonus_summary_casino_bonus: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'bonus_summary.casino_bonus',
            valueGetter: model => this.getCurrencyValue(model?.bonus_summary?.casino_bonus, model?.currency),
            title: localizedHeaders.casino_bonus,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const bonus_summary_unrealized_bonus_total: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'bonus_summary.unrealized_bonus_total',
            valueGetter: model => this.getCurrencyValue(model?.bonus_summary?.unrealized_bonus_total, model?.currency),
            title: localizedHeaders.unrealized_bonus_total,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const bonus_summary_cost_global_spins_tickets: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'bonus_summary.cost_global_spins_tickets',
            valueGetter: model => this.getCurrencyValue(model?.bonus_summary?.cost_global_spins_tickets, model?.currency),
            title: localizedHeaders.cost_global_spins_tickets,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const deduction_summary_casino_provider_fee: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'deduction_summary.casino_provider_fee',
            valueGetter: model => this.getCurrencyValue(model?.deduction_summary?.casino_provider_fee, model?.currency),
            title: localizedHeaders.casino_provider_fee,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const deduction_summary_ngr_cost: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'deduction_summary.ngr_cost',
            valueGetter: model => this.getCurrencyValue(model?.deduction_summary?.ngr_cost, model?.currency),
            title: localizedHeaders.ngr_cost,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const deduction_summary_psp_fee: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'deduction_summary.psp_fee',
            valueGetter: model => this.getCurrencyValue(model?.deduction_summary?.psp_fee, model?.currency),
            title: localizedHeaders.psp_fee,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const deduction_summary_total: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'deduction_summary.total',
            valueGetter: model => this.getCurrencyValue(model?.deduction_summary?.total, model?.currency),
            title: localizedHeaders.total_deduction,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const player_games_summary_player_net_deposit: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'player_games_summary.player_net_deposit',
            valueGetter: model => this.getCurrencyValue(model?.player_games_summary?.player_net_deposit, model?.currency),
            title: localizedHeaders.player_net_deposit,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const player_games_summary_cash_game_hand_played: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'player_games_summary.cash_game_hand_played',
            valueGetter: model => this.getNumberValue(model?.player_games_summary?.cash_game_hand_played),
            title: localizedHeaders.cash_game_hand_played,
            sortable: true,
            width: 150,
            type: 'text',
        };
        const player_games_summary_mtt_played: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'player_games_summary.mtt_played',
            valueGetter: model => this.getNumberValue(model?.player_games_summary?.mtt_played),
            title: localizedHeaders.mtt_played,
            sortable: true,
            width: 150,
            type: 'text',
        };
        const player_games_summary_player_profit_loss: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'player_games_summary.player_profit_loss',
            valueGetter: model => this.getCurrencyValue(model?.player_games_summary?.player_profit_loss, model?.currency),
            title: localizedHeaders.player_profit_loss,
            sortable: true,
            width: 170,
            type: 'currency',
        };
        const revenue_share_type: LocalizedTextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'revenue_share_type',
            valueGetter: model => (model?.revenue_share_type ? localizedRevenueShareType[model.revenue_share_type] : null),
            title: localizedHeaders.revenue_share_type,
            sortable: true,
            width: 150,
            type: 'localized_text',
        };
        const referee_type: LocalizedTextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'referee_type',
            valueGetter: model => (model?.referee_type ? localizedRefereeType[model.referee_type] : null),
            title: localizedHeaders.referee_type,
            sortable: true,
            width: 150,
            type: 'localized_text',
        };
        const rakes: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'rakes',
            valueGetter: model => this.getCurrencyValue(model?.rakes, model?.currency),
            title: localizedHeaders.rakes,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const insurance_tool: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'insurance_tool',
            valueGetter: model => this.getCurrencyValue(model?.insurance_tool, model?.currency),
            title: localizedHeaders.insurance_tool,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const casino_ggr: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'casino_ggr',
            valueGetter: model => this.getCurrencyValue(model?.casino_ggr, model?.currency),
            title: localizedHeaders.casino_ggr,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const registered_player_number: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'registered_player_number',
            valueGetter: model => this.getNumberValue(model?.registered_player_number),
            title: localizedHeaders.registered_player_number,
            sortable: false,
            width: 150,
            type: 'text',
        };
        const active_player_number: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'active_player_number',
            valueGetter: model => this.getNumberValue(model?.active_player_number),
            title: localizedHeaders.active_player_number,
            sortable: false,
            width: 150,
            type: 'text',
        };
        const revenue_share: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'revenue_share',
            valueGetter: model => this.getCurrencyValue(model?.revenue_share, model?.currency),
            title: localizedHeaders.revenue_share,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const total_share: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'total_share',
            valueGetter: model => this.getCurrencyValue(model?.total_share, model?.currency),
            title: localizedHeaders.total_share,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const poker_share: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'poker_share',
            valueGetter: model => this.getCurrencyValue(model?.poker_share, model?.currency),
            title: localizedHeaders.poker_share,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const casino_share: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'casino_share',
            valueGetter: model => this.getCurrencyValue(model?.casino_share, model?.currency),
            title: localizedHeaders.casino_share,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const cost_tournament_overlay_excl_freeroll: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'other_summary.cost_tournament_overlay_excl_freeroll',
            valueGetter: model => this.getCurrencyValue(model?.other_summary?.cost_tournament_overlay_excl_freeroll, model?.currency),
            title: localizedHeaders.cost_tournament_overlay_excl_freeroll,
            sortable: true,
            width: 150,
            type: 'currency',
        };
        const ggr_summary_mtt_total_buyin: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'ggr_summary.mtt_total_buyin',
            valueGetter: model => this.getCurrencyValue(model?.ggr_summary?.mtt_total_buyin, model?.currency),
            title: localizedHeaders.mtt_total_buyin,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const pro_summary_cost_ftd_bonus_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.cost_ftd_bonus_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.cost_ftd_bonus_pro, model?.currency),
            title: localizedHeaders.cost_ftd_bonus_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const pro_summary_cost_psp_fee_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.cost_psp_fee_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.cost_psp_fee_pro, model?.currency),
            title: localizedHeaders.cost_psp_fee_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const pro_summary_cost_spins_tournament_tickets_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.cost_spins_tournament_tickets_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.cost_spins_tournament_tickets_pro, model?.currency),
            title: localizedHeaders.cost_spins_tournament_tickets_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const highest_blind_level_is_pro_nlhe: TextColumn<AgentPlayerReportingGridViewModel> = {
            field: 'highest_blind_level_is_pro_nlhe',
            valueGetter: model => model?.highest_blind_level_is_pro_nlhe,
            title: localizedHeaders.highest_blind_level_is_pro_nlhe,
            sortable: false,
            width: 150,
            type: 'text',
        };

        const is_pro_shortdeck: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.is_pro_shortdeck',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.is_pro_shortdeck, model?.currency),
            title: localizedHeaders.is_pro_shortdeck,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const nlhe_ggr_usd_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.nlhe_ggr_usd_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.nlhe_ggr_usd_pro, model?.currency),
            title: localizedHeaders.nlhe_ggr_usd_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const nlhe_high_ggr_usd_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.nlhe_high_ggr_usd_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.nlhe_high_ggr_usd_pro, model?.currency),
            title: localizedHeaders.nlhe_high_ggr_usd_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const nlhe_low_ggr_usd_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.nlhe_low_ggr_usd_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.nlhe_low_ggr_usd_pro, model?.currency),
            title: localizedHeaders.nlhe_low_ggr_usd_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const nlhe_medium_ggr_usd_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.nlhe_medium_ggr_usd_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.nlhe_medium_ggr_usd_pro, model?.currency),
            title: localizedHeaders.nlhe_medium_ggr_usd_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const nlhe_micro_ggr_usd_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.nlhe_micro_ggr_usd_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.nlhe_micro_ggr_usd_pro, model?.currency),
            title: localizedHeaders.nlhe_micro_ggr_usd_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const poker_ggr_usd_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.poker_ggr_usd_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.poker_ggr_usd_pro, model?.currency),
            title: localizedHeaders.poker_ggr_usd_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        const shortdeck_ggr_usd_pro: CurrencyColumn<AgentPlayerReportingGridViewModel> = {
            field: 'pro_summary.shortdeck_ggr_usd_pro',
            valueGetter: model => this.getCurrencyValue(model?.pro_summary?.shortdeck_ggr_usd_pro, model?.currency),
            title: localizedHeaders.shortdeck_ggr_usd_pro,
            sortable: true,
            width: 150,
            type: 'currency',
        };

        this._config = {
            uid_link: uid_link,
            uid_text: uid_text,
            username: username,
            phone: phone,
            email: email,
            country: country,
            referrer_agent_id: referrer_agent_id,
            referrer_agent_username: referrer_agent_username,
            register_marketing_code: register_marketing_code,
            'ggr_summary.cash_ggr': ggr_summary_cash_ggr,
            'ggr_summary.mtt_ggr': ggr_summary_mtt_ggr,
            'ggr_summary.casino_ggr': ggr_summary_casino_ggr,
            'ggr_summary.poker_share': ggr_summary_poker_share,
            'ggr_summary.casino_share': ggr_summary_casino_share,
            'ggr_summary.total_share': ggr_summary_total_share,
            'ggr_summary.global_spin_ggr': ggr_summary_global_spin_ggr,
            'ggr_summary.external_casino_ggr': ggr_summary_external_casino_ggr,
            'ggr_summary.internal_casino_ggr': ggr_summary_internal_casino_ggr,
            'ggr_summary.external_casino_bet_amount': ggr_summary_external_casino_bet_amount,
            'ggr_summary.internal_casino_bet_amount': ggr_summary_internal_casino_bet_amount,
            'ggr_summary.plo_ggr_high_stake': ggr_summary_plo_ggr_high_stake,
            'ggr_summary.plo_ggr_low_stake': ggr_summary_plo_ggr_low_stake,
            'ggr_summary.plo_ggr_medium_stake': ggr_summary_plo_ggr_medium_stake,
            'ggr_summary.plo_ggr_micro_stake': ggr_summary_plo_ggr_micro_stake,
            'ggr_summary.plo_ggr_total': ggr_summary_plo_ggr_total,
            'ggr_summary.plo_ggr_unknown_stake': ggr_summary_plo_ggr_unknown_stake,
            'ggr_summary.poker_addons': ggr_summary_poker_addons,
            'ggr_summary.poker_ggr': ggr_summary_poker_ggr,
            'ggr_summary.poker_mtt_fees': ggr_summary_poker_mtt_fees,
            'ggr_summary.poker_rake': ggr_summary_poker_rake,
            'ggr_summary.total': ggr_summary_total,
            'ggr_summary.mtt_total_buyin': ggr_summary_mtt_total_buyin,
            'ngr_summary.cash_ngr': ngr_summary_cash_ngr,
            'ngr_summary.casino_ngr': ngr_summary_casino_ngr,
            'ngr_summary.mtt_ngr': ngr_summary_mtt_ngr,
            'ngr_summary.poker_ngr': ngr_summary_poker_ngr,
            'ngr_summary.total': ngr_summary_total,
            'bonus_summary.realized_bonus_total': bonus_summary_realized_bonus_total,
            'bonus_summary.mtt_ticket_bonus': bonus_summary_mtt_ticket_bonus,
            'bonus_summary.poker_bonus': bonus_summary_poker_bonus,
            'bonus_summary.casino_bonus': bonus_summary_casino_bonus,
            'bonus_summary.unrealized_bonus_total': bonus_summary_unrealized_bonus_total,
            'bonus_summary.cost_global_spins_tickets': bonus_summary_cost_global_spins_tickets,
            'deduction_summary.casino_provider_fee': deduction_summary_casino_provider_fee,
            'deduction_summary.ngr_cost': deduction_summary_ngr_cost,
            'deduction_summary.psp_fee': deduction_summary_psp_fee,
            'deduction_summary.total': deduction_summary_total,
            'player_games_summary.player_net_deposit': player_games_summary_player_net_deposit,
            'player_games_summary.cash_game_hand_played': player_games_summary_cash_game_hand_played,
            'player_games_summary.mtt_played': player_games_summary_mtt_played,
            'player_games_summary.player_profit_loss': player_games_summary_player_profit_loss,
            'other_summary.cost_tournament_overlay_excl_freeroll': cost_tournament_overlay_excl_freeroll,
            revenue_share_type: revenue_share_type,
            referee_type: referee_type,
            rakes: rakes,
            insurance_tool: insurance_tool,
            casino_ggr: casino_ggr,
            registered_player_number: registered_player_number,
            active_player_number: active_player_number,
            revenue_share: revenue_share,
            total_share: total_share,
            poker_share: poker_share,
            casino_share: casino_share,
            highest_blind_level_is_pro_nlhe: highest_blind_level_is_pro_nlhe,
            'pro_summary.is_pro_shortdeck': is_pro_shortdeck,
            'pro_summary.cost_spins_tournament_tickets_pro': pro_summary_cost_spins_tournament_tickets_pro,
            'pro_summary.cost_ftd_bonus_pro': pro_summary_cost_ftd_bonus_pro,
            'pro_summary.cost_psp_fee_pro': pro_summary_cost_psp_fee_pro,
            'pro_summary.poker_ggr_usd_pro': poker_ggr_usd_pro,
            'pro_summary.nlhe_ggr_usd_pro': nlhe_ggr_usd_pro,
            'pro_summary.shortdeck_ggr_usd_pro': shortdeck_ggr_usd_pro,
            'pro_summary.nlhe_micro_ggr_usd_pro': nlhe_micro_ggr_usd_pro,
            'pro_summary.nlhe_low_ggr_usd_pro': nlhe_low_ggr_usd_pro,
            'pro_summary.nlhe_medium_ggr_usd_pro': nlhe_medium_ggr_usd_pro,
            'pro_summary.nlhe_high_ggr_usd_pro': nlhe_high_ggr_usd_pro,
        };
    }

    public get config(): Partial<Record<AgentPlayerReportingViewModelKeys, DataGridColumn<AgentPlayerReportingGridViewModel>>> {
        return this._config;
    }

    private getNumberValue(value: number): string {
        return value?.toString();
    }

    private getCurrencyValue(amount: number, currency: string) {
        return {amount: amount ?? 0, currency};
    }
}

type DataGridAgentPlayerReportingProps = Omit<
    DataGridServerProps<AgentPlayerReportingGridViewModel, AgentPlayerReportingViewModelKeys>,
    'type' | 'columns'
> & {
    columns: AgentPlayerReportingViewModelKeys[];
};

export function DataGridAgentPlayerReporting(props: DataGridAgentPlayerReportingProps) {
    const columns = useRef(getColumns(new DataGridAgentPlayerReportingConfigFactory().config));

    function getColumns(
        config: Partial<Record<AgentPlayerReportingViewModelKeys, DataGridColumn<AgentPlayerReportingGridViewModel>>>
    ): DataGridColumn<AgentPlayerReportingGridViewModel>[] {
        return props?.columns?.map((column: AgentPlayerReportingViewModelKeys) => config[column]);
    }

    return <DataGridServer {...props} type="glide" columns={columns.current} />;
}
