import React, {useContext} from 'react';
import {Controller, ControllerProps} from 'react-hook-form';
import {defineMessages, IntlContext} from 'react-intl';
import {Box, FormHelperText} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    formControl: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    formField: {
        width: '100%',
    },
    formFieldError: {
        position: 'absolute',
        top: '100%',
    },
}));

const localized = defineMessages({
    fieldRequiredLabel: {
        id: 'fieldRequiredLabel',
        defaultMessage: '{fieldName} is required',
    },
});

type FormStyledInputProps = ControllerProps<any> & {
    label?: string;
    className?: string;
    required?: boolean;
};

export const withStyledFormField =
    <P extends object>(WrappedComponent: React.ComponentType<P & {className: string}>) =>
    (props: P) => {
        const {classes} = useClasses();
        return <WrappedComponent {...props} className={classes.formField} />;
    };

export const FormStyledInput = (props: FormStyledInputProps) => {
    const {classes} = useClasses();
    const {formatMessage} = useContext(IntlContext);
    return (
        <Box className={`${classes.formControl} ${props.className}`}>
            <Controller
                {...props}
                defaultValue={props.defaultValue ?? ''}
                rules={props.required ? {required: formatMessage(localized.fieldRequiredLabel, {fieldName: props.label})} : {}}
            />
        </Box>
    );
};

export type FormInputErrorProps = {
    error: string;
};

export const FormInputError = ({error}: FormInputErrorProps) => {
    const {classes} = useClasses();
    return error ? <FormHelperText className={classes.formFieldError}>{error}</FormHelperText> : <></>;
};
