import {SystemColorsEnum} from '@style';

export class Label {
    id: string;
    name: string;
    group?: LabelGroup;
}

export enum LabelGroupSourceType {
    Custom = 'Custom',
    System = 'System',
}

export class LabelGroup {
    id: string;
    name: string;
    color: string;
    labels?: Label[];
    source_type: LabelGroupSourceType;
}

export type UserLabelFormGroup = {
    id: string;
    labels: {
        id: string;
        name?: string;
        selected: boolean;
        disabled: boolean;
    }[];
};

export type UserLabelsForm = {
    groups: UserLabelFormGroup[];
};

export class LabelGroupForm {
    id: string;
    name: string;
    color: string;
}

export class LabelUpdateForm {
    id: string;
    groupId: string;
    name: string;
}

export const labelGroupColors: SystemColorsEnum[] = [
    SystemColorsEnum.LightBlue,
    SystemColorsEnum.Bronze,
    SystemColorsEnum.LightGreen,
    SystemColorsEnum.Orange,
    SystemColorsEnum.LightGrey,
    SystemColorsEnum.DarkGrey,
    SystemColorsEnum.Violet,
    SystemColorsEnum.Coffee,
    SystemColorsEnum.Beige,
    SystemColorsEnum.Mint,
    SystemColorsEnum.YellowNeon,
    SystemColorsEnum.Burgundy,
    SystemColorsEnum.SkyBlue,
    SystemColorsEnum.Pink,
    SystemColorsEnum.WarmYellow,
];
