import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {fileActions} from '@file/actions';
import {ServiceTypes} from '@inversify';
import {Note} from '@models/generated/graphql';
import {map} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {entityActions, EntityType, NoteNormalized} from '@redux/entity';
import type {INoteService} from '@services/noteService';
import type {IAttachmentsApiService} from '@services/rest-api/attachmentsApiService';
import {GqlMutationRequest, ServerResponseStatus} from '@services/types';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {AddNoteRequestPayload, DownloadAttachmentRequestPayload, noteActions, PinNoteRequestPayload} from './actions';

const localized = defineMessages({
    pinNoteSuccess: {
        id: 'BlockNoteEpicsBuilder_pinNoteSuccess',
        defaultMessage: 'Note pinned',
    },
    unpinNoteSuccess: {
        id: 'BlockNoteEpicsBuilder_unpinNoteSuccess',
        defaultMessage: 'Note unpinned',
    },
    pinNoteFailed: {
        id: 'BlockNoteEpicsBuilder_pinNoteFailed',
        defaultMessage: 'Failed to pin a note',
    },
    addNoteSuccess: {
        id: 'BlockNoteEpicsBuilder_addNoteSuccess',
        defaultMessage: 'Note created',
    },
    addNoteFailed: {
        id: 'BlockNoteEpicsBuilder_addNoteFailed',
        defaultMessage: 'Failed to create a note',
    },
    downloadAttachmentFailed: {
        id: 'BlockNoteEpicsBuilder_downloadAttachmentFailed',
        defaultMessage: 'Failed to download attachment',
    },
});

@injectable()
export class NoteActionsEpicsBuilder extends BaseEpicsBuilder {
    private readonly _noteService: INoteService;
    private readonly _attachmentsService: IAttachmentsApiService;

    constructor(
        @inject(ServiceTypes.NoteService) noteService: INoteService,
        @inject(ServiceTypes.AttachmentsApiService) attachmentsService: IAttachmentsApiService
    ) {
        super();
        this._noteService = noteService;
        this._attachmentsService = attachmentsService;
    }

    protected buildEpicList(): Epic[] {
        return [
            this.buildPinNoteRequestEpic(),
            this.buildPinNoteSuccessEpics(),
            this.buildPinNoteFailureEpics(),
            this.buildAddNoteRequestEpic(),
            this.buildAddNoteSuccessEpics(),
            this.buildAddNoteFailureEpics(),
            this.buildDownloadAttachmentRequestEpic(),
            this.buildDownloadAttachmentSuccessEpic(),
            this.buildDownloadAttachmentFailureEpic(),
        ];
    }

    private buildPinNoteRequestEpic(): Epic {
        return this.buildRequestEpic<PinNoteRequestPayload, GqlMutationRequest, Note>(noteActions.pinNote, payload =>
            this._noteService.updateNote(payload.id, payload.note)
        );
    }

    private buildPinNoteSuccessEpics(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(noteActions.pinNote.success)),
                mergeMap(action => {
                    const updatedItem: Partial<NoteNormalized> = action?.payload?.responsePayload;
                    return of(
                        showMessageAction({
                            message: updatedItem?.is_pinned ? localized.pinNoteSuccess : localized.unpinNoteSuccess,
                        }),
                        entityActions.updateItem({type: EntityType.Note, id: updatedItem?.id, updatedItem})
                    );
                })
            );
    }

    private buildPinNoteFailureEpics(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(noteActions.pinNote.failure)),
                map(() => showErrorAction({message: localized.pinNoteFailed}))
            );
    }

    private buildAddNoteRequestEpic(): Epic {
        return this.buildRequestEpic<AddNoteRequestPayload, GqlMutationRequest, Note>(noteActions.addNote, payload => {
            return this._attachmentsService
                .upload(payload.files, payload.note.workspace)
                .pipe(
                    mergeMap(attachmentsResponse =>
                        attachmentsResponse?.status === ServerResponseStatus.Success
                            ? this._noteService.addNote(payload.note, attachmentsResponse.responsePayload)
                            : of({...attachmentsResponse, requestPayload: null, responsePayload: null})
                    )
                );
        });
    }

    private buildAddNoteSuccessEpics(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(noteActions.addNote.success)),
                map(() => showMessageAction({message: localized.addNoteSuccess}))
            );
    }

    private buildAddNoteFailureEpics(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(noteActions.addNote.failure)),
                map(() => showErrorAction({message: localized.addNoteFailed}))
            );
    }

    private buildDownloadAttachmentRequestEpic(): Epic {
        return this.buildRequestEpic<DownloadAttachmentRequestPayload, DownloadAttachmentRequestPayload, Blob>(
            noteActions.downloadAttachment,
            payload => this._attachmentsService.download(payload)
        );
    }

    private buildDownloadAttachmentSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(noteActions.downloadAttachment.success)),
                map(res => fileActions.download({file: res.payload.responsePayload, title: res.payload.requestPayload.fileName}))
            );
    }

    private buildDownloadAttachmentFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(noteActions.downloadAttachment.failure)),
                map(() => showErrorAction({message: localized.downloadAttachmentFailed}))
            );
    }
}
