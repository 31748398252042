import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {DropdownStyles, DropdownType} from './DropdownHoc';

//TODO: [BO-2669] Move dropdown components to input folder (?)
export const useDropdownStyle = (dropdownType: DropdownType) => {
    const {classes} = useDropdownClasses();

    switch (dropdownType) {
        case DropdownType.Attached: {
            return {
                dropdownContainer: classes.attachedDropdownContainer,
                dropdownRoot: classes.attachedDropdownRoot,
                dropdownPaper: classes.attachedDropdownPaper,
                dropdownButton: classes.attachedDropdownButton,
                dropdownButtonOpen: classes.attachedDropdownButtonOpen,
            } as DropdownStyles;
        }
        case DropdownType.Separated: {
            return {
                dropdownContainer: classes.separatedDropdownContainer,
                dropdownRoot: classes.separatedDropdownRoot,
                dropdownPaper: classes.separatedDropdownPaper,
                dropdownButton: classes.separatedDropdownButton,
                dropdownButtonOpen: classes.separatedDropdownButtonOpen,
            } as DropdownStyles;
        }
        default: {
            return null;
        }
    }
};

export const useDropdownClasses = makeStyles()((theme: CustomTheme) => ({
    dropdownList: {
        minWidth: theme.custom.actionsMenuWidth,
        outline: 'none',
        padding: theme.spacing(0.75),
    },
    dropdownListNoMinWidth: {
        minWidth: 'unset',
    },
    dropdownListNoPadding: {
        padding: 0,
    },
    dropdownListTitle: {
        ...(theme.typography.caption as CSSObject),
        textTransform: 'uppercase',
        padding: theme.spacing(1, 2),
    },
    dropdownContent: {
        height: '100%',
    },
    dropdownActions: {
        display: 'flex',
        columnGap: theme.spacing(2),
        justifyContent: 'space-between',
    },
    dropdownAction: {
        width: '100%',
        borderRadius: 8,
        ...(theme.typography.h4 as CSSObject),
    },
    dropdownActionUnsetWidth: {
        width: 'unset',
    },

    //attached dropdown style
    attachedDropdownButton: {
        width: '100%',
    },
    attachedDropdownButtonOpen: {
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    attachedDropdownContainer: {
        padding: 0,
    },
    attachedDropdownRoot: {
        position: 'absolute !important' as any,
    },
    attachedDropdownPaper: {
        position: 'absolute',
        width: '100%',
        maxWidth: 'unset',
        height: 'auto',
        maxHeight: 'unset',
        top: '100% !important',
        left: '0 !important',
        margin: 0,
        border: `1px solid ${theme.custom.palette.content.border}`,
        borderTop: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
    },

    //separated dropdown style
    separatedDropdownButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    separatedDropdownButtonOpen: {},
    separatedDropdownContainer: {},
    separatedDropdownRoot: {},
    separatedDropdownPaper: {
        '&.MuiPaper-elevation8': {
            marginLeft: 0,
        },
    },
}));
