import React from 'react';
import {Box} from '@mui/material';
import {Remirror, useRemirror} from '@remirror/react';
import {
    BoldExtension,
    BulletListExtension,
    HardBreakExtension,
    ItalicExtension,
    LinkExtension,
    MarkdownExtension,
    MentionAtomExtension,
} from 'remirror/extensions';

import {useClasses} from '@components/comment/CommentEditor';

import {useConverters} from './hooks';

export type MarkdownViewerProps = {
    markdown?: string;
};

export const MarkdownViewer = ({markdown}: MarkdownViewerProps) => {
    const {classes} = useClasses();

    function getExtensions() {
        return [
            new MentionAtomExtension({
                matchers: [{name: 'at', char: '@'}],
            }),
            new BoldExtension({}),
            new ItalicExtension(),
            new LinkExtension({autoLink: false, defaultTarget: '_blank'}),
            new MarkdownExtension({}),
            new BulletListExtension({}),
            new HardBreakExtension({}),
        ];
    }

    const {manager} = useRemirror({
        extensions: getExtensions,
        extraAttributes: [
            {
                identifiers: ['mention'],
                attributes: {role: {default: 'presentation'}},
            },
            {identifiers: ['mention'], attributes: {href: {default: null}}},
        ],
        stringHandler: 'html',
    });

    const {markdownToHtml} = useConverters();

    const html = markdownToHtml(markdown);

    return (
        <Box className={classes.editorContent}>
            <Remirror initialContent={html} manager={manager} editable={false} />
        </Box>
    );
};
