import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {MessageDescriptor} from 'react-intl';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {KeyValueCell, KeyValueColumn, KeyValueTable} from '@components/key-value-table';
import {TextWithEmptyCheck} from '@components/label';
import {AccountVerificationStatus, Platform, UserAccountStatus} from '@models/generated/graphql';
import {GeoCheckSummary, UserProfile} from '@models/generated/graphql';
import {EntityType, GeoCheckSummaryFilterKeys, GeoCheckSummaryQueryFields} from '@redux/entity';
import {RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit, ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {Filter} from 'src/common/types';
import {useUserProfileDetails} from 'src/features/block-user-profile-details';
import {WorkspaceDetailsComponentProps} from '../types';

import {AmlLabelList} from './AmlLabelList';
import {DisplayVpn} from './DisplayVpn';
import {LoggedInCountry} from './LoggedInCountry';
import {SecurityCasesList} from './SecurityCasesList';
import {UserGeoCheckSummary} from './UserGeoCheckSummary';

const localized = defineMessages({
    header: {
        id: 'SI_header',
        defaultMessage: 'Security Info',
    },
    amlLabels: {
        id: 'SI_amlLabels',
        defaultMessage: 'AML Labels',
    },
    accountStatus: {
        id: 'SI_accountStatus',
        defaultMessage: 'Account Status',
    },
    paymentKycStatus: {
        id: 'SI_kycStatus',
        defaultMessage: 'Latest Payment KYC Status',
    },
    securityKycStatus: {
        id: 'SI_kycStatus',
        defaultMessage: 'Latest Security KYC Status',
    },
    securityCases: {
        id: 'SI_securityCases',
        defaultMessage: 'Security Cases',
    },
    loggedInCountry: {
        id: 'SI_loggedInCountry',
        defaultMessage: 'Logged in Country',
    },
    vpnDetected: {
        id: 'SI_vpnDetected',
        defaultMessage: 'VPN Detected',
    },
    cashGamesId: {
        id: 'SI_cashGamesId',
        defaultMessage: 'Cash Game ID',
    },
    tournamentsId: {
        id: 'SI_tournamentsId',
        defaultMessage: 'Tournaments ID',
    },
    geoCheckSummary: {
        id: 'SI_geoCheckSummary',
        defaultMessage: 'Geocomply Location Check',
    },
});

type SecurityInfoData = {
    userData: UserSecurityInfoData;
};

export const domain = 'user-security-info';

export type UserSecurityInfoData = Omit<UserProfile, '__typename'> & Omit<GeoCheckSummary, 'uid' | '__typename'>;

export function useSecurityInfoData(viewType: ViewType, itemId: string, displayName: MessageDescriptor): SecurityInfoData {
    const {item: userProfile} = useUserProfileDetails({
        id: itemId,
        fields: [
            'security_cases.type',
            'security_cases.case_id',
            'networks.uid',
            'networks.platform',
            'aml_labels',
            'account_status',
            'latest_kyc.security.id',
            'latest_kyc.security.account_verification_status',
            'latest_kyc.payment.id',
            'latest_kyc.payment.account_verification_status',
            'login_countries',
            'vpn',
            'country',
        ],
        viewType,
        displayName,
        realtimeMode: RealtimeUpdatesMode.Confirm,
    });

    const geoSummaryFilters: Filter<string, GeoCheckSummaryFilterKeys>[] = [{key: 'uid', value: itemId}];
    const {items: geoSummaryItems} = useViewInit<GeoCheckSummary, GeoCheckSummaryFilterKeys, GeoCheckSummaryQueryFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.GeoCheckSummary,
            fields: ['uid', 'status', 'fail_count', 'reason_categories'],
        },
        defaultFilters: geoSummaryFilters,
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'uid')?.value),
    });
    const geoSummary = geoSummaryItems[0];

    return {
        userData: {...userProfile, ...geoSummary},
    };
}

export const UserSecurityInfo = ({itemId}: WorkspaceDetailsComponentProps) => {
    const viewType: ViewType = 'SecurityInfo';
    const {formatMessage} = useIntl();
    const {userData} = useSecurityInfoData(viewType, itemId, localized.header);

    return (
        <KeyValueTable>
            <KeyValueColumn>
                <KeyValueCell title={formatMessage(localized.securityCases)}>
                    <SecurityCasesList value={userData?.security_cases} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.cashGamesId)}>
                    <TextWithEmptyCheck value={userData?.networks?.find(i => i.platform === Platform.Pkw)?.uid} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.tournamentsId)}>
                    <TextWithEmptyCheck value={userData?.networks?.find(i => i.platform === Platform.Bl)?.uid} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.amlLabels)}>
                    <AmlLabelList value={userData?.aml_labels} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.accountStatus)}>
                    <MemoizedChip value={userData?.account_status} chipName={nameof<UserAccountStatus>()} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.securityKycStatus)}>
                    <MemoizedChip
                        value={userData?.latest_kyc?.security?.account_verification_status}
                        chipName={nameof<AccountVerificationStatus>()}
                    />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.paymentKycStatus)}>
                    <MemoizedChip
                        value={userData?.latest_kyc?.payment?.account_verification_status}
                        chipName={nameof<AccountVerificationStatus>()}
                    />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.loggedInCountry)}>
                    <LoggedInCountry value={userData?.countries} uid={userData?.uid} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.vpnDetected)}>
                    <DisplayVpn value={userData?.vpn} />
                </KeyValueCell>
                <KeyValueCell title={formatMessage(localized.geoCheckSummary)}>
                    <UserGeoCheckSummary value={userData} />
                </KeyValueCell>
            </KeyValueColumn>
        </KeyValueTable>
    );
};
