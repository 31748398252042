import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {fileActions} from '@file/actions';
import {CsvDocumentBuilderAdapter} from '@file/services/CsvDocumentBuilderAdapter';
import {ServiceTypes} from '@inversify';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {BaseFilterKeys} from '@redux/entity';
import {maxPageSize} from '@services/types';
import {getFilterString} from '@utils';

import {showErrorAction} from '../message-snack-bar/actions';

import {downloadActions} from './actions';
import {downloadRequestFailedMessage} from './types';

export const downloadEpicsLocalized = defineMessages({
    failedDownload: {
        id: 'DownloadEpicsBuilder_failedDownload',
        defaultMessage: 'Failed to download {filename}',
    },
    totalCountErrorMessage: {
        id: 'DownloadEpicsBuilder_totalCountErrorMessage',
        defaultMessage: 'Download limit exceeded ({maxPageSize} items). Please adjust your filter and try again.',
    },
});

@injectable()
export class DownloadEpicsBuilder extends BaseEpicsBuilder {
    private readonly _fileFactory: CsvDocumentBuilderAdapter<string>;

    constructor(@inject(ServiceTypes.CsvFileAdapter) fileFactory: CsvDocumentBuilderAdapter<string>) {
        super();
        this._fileFactory = fileFactory;
    }

    protected buildEpicList(): Epic[] {
        return [this.buildDownloadRequestEpic(), this.buildDownloadSuccessEpic(), this.buildDownloadFailureEpic()];
    }

    private buildDownloadRequestEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(downloadActions.download.request)),
                mergeMap(({payload}) => {
                    const {total, strategy, keys, worksheetKeys, headers, filename} = payload;
                    const pageFilterKey: BaseFilterKeys = 'page';
                    const sizeFilterKey: BaseFilterKeys = 'size';
                    const filter = getFilterString(
                        payload?.filter,
                        true,
                        {key: pageFilterKey, value: '1'},
                        {key: sizeFilterKey, value: `${maxPageSize}`}
                    );
                    const failedAction = downloadActions.download.failure({
                        message: downloadEpicsLocalized.failedDownload,
                        values: {filename},
                    });
                    return total <= maxPageSize
                        ? strategy.process({keys, filter}).pipe(
                              mergeMap(downloadModels => {
                                  let result: Observable<unknown> = of(failedAction);
                                  if (downloadModels !== downloadRequestFailedMessage) {
                                      result = this._fileFactory.createFile(downloadModels, worksheetKeys, headers).pipe(
                                          map(arrayBuffer => downloadActions.download.success({arrayBuffer, filename})),
                                          catchError(() => of(failedAction))
                                      );
                                  }
                                  return result;
                              }),
                              catchError(() => of(failedAction))
                          )
                        : of(
                              downloadActions.download.failure({
                                  message: downloadEpicsLocalized.totalCountErrorMessage,
                                  values: {maxPageSize: `${maxPageSize}`},
                              })
                          );
                })
            );
    }

    private buildDownloadSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(downloadActions.download.success)),
                map(action => {
                    const {arrayBuffer, filename} = action.payload;
                    return fileActions.download({
                        file: new Blob([arrayBuffer]),
                        title: filename,
                        type: 'csv',
                    });
                })
            );
    }

    private buildDownloadFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(downloadActions.download.failure)),
                map(action => showErrorAction(action.payload))
            );
    }
}
