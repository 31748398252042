import {ChipType, ChipVariant} from '@components/chip/types';
import {CustomIcon} from '@components/icons/types';
import {TransactionStatus} from '@models/generated/graphql';

import {localizedTransactionStatus} from '../../../features/app/intl/shared-resources/transactionStatus';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export const getTransactionStatusChipsMapping = (chipType: ChipType) => ({
    [TransactionStatus.Cancelled]: {
        label: localizedTransactionStatus[TransactionStatus.Cancelled],
        chipType,
        chipVariant: ChipVariant.Yellow,
    },
    [TransactionStatus.Pending]: {
        label: localizedTransactionStatus[TransactionStatus.Pending],
        chipType,
        chipVariant: ChipVariant.Disabled,
        startIconClass: CustomIcon.ClockOutline,
    },
    [TransactionStatus.OnHoldRisk]: {
        label: localizedTransactionStatus[TransactionStatus.OnHoldRisk],
        chipType,
        chipVariant: ChipVariant.Orange,
        startIconClass: CustomIcon.WarningOutline,
    },
    [TransactionStatus.OnHoldPayment]: {
        label: localizedTransactionStatus[TransactionStatus.OnHoldPayment],
        chipType,
        chipVariant: ChipVariant.Orange,
        startIconClass: CustomIcon.WarningOutline,
    },
    [TransactionStatus.Failed]: {
        label: localizedTransactionStatus[TransactionStatus.Failed],
        chipType,
        chipVariant: ChipVariant.Yellow,
    },
    [TransactionStatus.InProgress]: {
        label: localizedTransactionStatus[TransactionStatus.InProgress],
        chipType,
        chipVariant: ChipVariant.LightBlue,
        startIconClass: CustomIcon.Union,
    },
    [TransactionStatus.Rejected]: {
        label: localizedTransactionStatus[TransactionStatus.Rejected],
        chipType,
        chipVariant: ChipVariant.Yellow,
    },
    [TransactionStatus.Succeeded]: {
        label: localizedTransactionStatus[TransactionStatus.Succeeded],
        chipType,
        chipVariant: ChipVariant.Green,
        startIconClass: CustomIcon.Confirm,
    },
    [TransactionStatus.RiskAuthorized]: {
        label: localizedTransactionStatus[TransactionStatus.RiskAuthorized],
        chipType,
        chipVariant: ChipVariant.Disabled,
        startIconClass: CustomIcon.ClockOutline,
    },
});
