import {inject, injectable} from 'inversify';
import {merge, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {BulkItemStatus} from '@models/generated/graphql';
import {mergeMap} from '@otel';
import {INoteService} from '@services/noteService';
import {IAttachmentsApiService} from '@services/rest-api/attachmentsApiService';
import {ServerResponseStatus} from '@services/types';

import {
    BulkActionKey,
    ChangeBulkActionStatusItem,
    IBulkStrategy,
    PerformStrategyActionItemsResponse,
    PerformStrategyRequest,
} from '../types';

import {ApplyNoteStrategyResponseValue} from './applyStrategy';

@injectable()
export class PerformNoteStrategy implements IBulkStrategy<PerformStrategyRequest, PerformStrategyActionItemsResponse> {
    private readonly _notesService: INoteService;
    private readonly _attachmentService: IAttachmentsApiService;

    constructor(
        @inject(ServiceTypes.NoteService) notesService: INoteService,
        @inject(ServiceTypes.AttachmentsApiService) attachmentService: IAttachmentsApiService
    ) {
        this._notesService = notesService;
        this._attachmentService = attachmentService;
    }
    process({items}: PerformStrategyRequest): Observable<PerformStrategyActionItemsResponse> {
        const {files, workspace} = (items[0].value as ApplyNoteStrategyResponseValue)?.noteInput ?? {};
        return this._attachmentService.upload(files, workspace).pipe(
            mergeMap(requestResult => {
                const usersObservables: Observable<ChangeBulkActionStatusItem>[] = items.map(item => {
                    const failedResult = of({
                        actionKey: BulkActionKey.NotesAndAttachments,
                        itemId: item.itemId,
                        status: BulkItemStatus.Failed,
                    });
                    let result: Observable<ChangeBulkActionStatusItem> = failedResult;
                    if (requestResult?.status === ServerResponseStatus.Success) {
                        const {files: _, ...note} = (item.value as ApplyNoteStrategyResponseValue).noteInput;
                        result = this._notesService.addNote(note, requestResult.responsePayload).pipe(
                            map(
                                (response): ChangeBulkActionStatusItem => ({
                                    actionKey: BulkActionKey.NotesAndAttachments,
                                    itemId: item.itemId,
                                    status:
                                        response.status === ServerResponseStatus.Success
                                            ? BulkItemStatus.Successful
                                            : BulkItemStatus.Failed,
                                })
                            ),
                            catchError((): Observable<ChangeBulkActionStatusItem> => failedResult)
                        );
                    }

                    return result;
                });
                return merge(...usersObservables).pipe(map(result => ({items: [result], actionKey: result.actionKey})));
            }),
            catchError(() => {
                const usersObservables: Observable<ChangeBulkActionStatusItem>[] = items.map(item =>
                    of({actionKey: BulkActionKey.NotesAndAttachments, itemId: item.itemId, status: BulkItemStatus.Failed})
                );

                return merge(...usersObservables).pipe(map(result => ({items: [result], actionKey: result.actionKey})));
            })
        );
    }
}
