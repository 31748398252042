import {makeStyles} from 'tss-react/mui';

export const useAutoAdjustedComplexTooltipClasses = makeStyles()(() => ({
    autoAdjustedComplexTooltipHiddenElement: {
        visibility: 'hidden',
        position: 'absolute',
        overflow: 'visible',
    },
    autoAdjustedComplexTooltipElement: {
        width: 'fit-content',
        display: 'flex',
        overflow: 'hidden',
    },
}));
