import React from 'react';
import {Controller} from 'react-hook-form';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {Box} from '@mui/material';

import {useAutoMapper} from '@auto-mapper';
import {MessageOrientation} from '@components/alerts/Message';
import {EditableText} from '@components/editable/EditableText';
import {FormError, RuleType, useValidationFormatter} from '@components/input';

import {LabelUpdateForm} from '../../block-label-group-list/types';
import {useReduxForm, useReduxFormRequestMode} from '../../shared/form/hooks';
import {blockUserLabelActions, UpdateLabelRequestPayload} from '../actions';

const localized = defineMessages<string, MessageDescriptor>({
    placeholder: {
        id: 'BlockPlayerLabelGroupLabelCheckbox_placeholder',
        defaultMessage: 'Label Name',
    },
});

type BlockPlayerLabelGroupLabelCheckboxProps = LabelUpdateForm & {
    isEditable?: boolean;
};

export function BlockPlayerLabelGroupLabelCheckbox({id, groupId, name, isEditable}: BlockPlayerLabelGroupLabelCheckboxProps) {
    const validationMessageFormatter = useValidationFormatter();
    const {formatMessage} = useIntl();
    const mapper = useAutoMapper();

    const {control, state, submit, cancel, handleSubmit} = useReduxForm<LabelUpdateForm, UpdateLabelRequestPayload>({
        initialModel: {id, groupId, name},
        asyncAction: blockUserLabelActions.updateLabel,
        map: (formData: LabelUpdateForm) => mapper.map(formData, LabelUpdateForm, UpdateLabelRequestPayload),
    });

    const {requestMode, resetRequestMode} = useReduxFormRequestMode<UpdateLabelRequestPayload>(blockUserLabelActions.updateLabel, id);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Controller
                render={({field, fieldState}) => (
                    <Box>
                        <EditableText
                            mode={requestMode}
                            value={field.value}
                            onChange={e => {
                                field.onChange(e);
                                resetRequestMode();
                            }}
                            onSave={() => handleSubmit(submit)}
                            onCancel={cancel}
                            placeholder={formatMessage(localized.placeholder)}
                            errorMessage={state.errorMessage ? formatMessage(state.errorMessage) : null}
                            errorMessageOrientation={MessageOrientation.Floating}
                            disabled={!isEditable}
                        />
                        {fieldState.error ? <FormError>{fieldState.error.message}</FormError> : null}
                    </Box>
                )}
                control={control}
                name="name"
                defaultValue={name}
                rules={{required: validationMessageFormatter(RuleType.Required, localized.placeholder)}}
            />
        </form>
    );
}
