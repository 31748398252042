import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box, Typography} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';

import {ModuleHeaderAction} from '../../shared/actions/types';

import BackButton from './BackButton';
import {useModuleHeaderClasses} from './ModuleHeader.style';

type ModuleHeaderProps = {
    header: string | MessageDescriptor;
    subHeader?: string | MessageDescriptor;
    children?: React.ReactNode;
    removeBottomSpacing?: boolean;
    alignActionsRight?: boolean;
    actions?: ModuleHeaderAction[];
    showBackButton?: boolean;
};

const TitleSeparatorCharacter = '/';

const ModuleHeader = ({
    header,
    subHeader,
    children,
    removeBottomSpacing,
    alignActionsRight,
    actions,
    showBackButton,
}: ModuleHeaderProps) => {
    const {classes, cx} = useModuleHeaderClasses();
    const moduleActionClassName = alignActionsRight ? classes.moduleActionsRightAligned : classes.moduleActions;

    return (
        <Box>
            <Box className={`${classes.moduleHeader} ${!removeBottomSpacing ? classes.moduleHeaderSpacing : ''}`}>
                {showBackButton ? <BackButton /> : null}
                <Box
                    className={cx(classes.moduleHeaderTitle, {
                        [classes.moduleHeaderTitlePadding]: showBackButton,
                    })}
                >
                    {header ? (
                        <Typography
                            className={classes.moduleHeaderTitleSeparatorContainer}
                            variant={subHeader ? 'h6' : 'h5'}
                            color={subHeader ? 'secondary' : 'textPrimary'}
                        >
                            <LocalizedText label={header} />
                            {subHeader ? <Box className={classes.moduleHeaderTitleSeparator}>{TitleSeparatorCharacter}</Box> : null}
                        </Typography>
                    ) : null}
                    {subHeader ? (
                        <Typography variant="h5" color="textPrimary" data-testid="moduleSubHeader">
                            <LocalizedText label={subHeader} />
                        </Typography>
                    ) : null}
                </Box>
                <Box className={classes.moduleHeaderActions}>
                    {actions?.map((action, index) => {
                        const ActionComponent = action.component;
                        return <ActionComponent key={index} />;
                    })}
                </Box>
            </Box>
            <Box className={children ? moduleActionClassName : classes.hidden}>{children}</Box>
        </Box>
    );
};

export default ModuleHeader;
