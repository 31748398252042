import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme, SystemColorsEnum} from '@style';

import {ChipWithTooltip} from './Chips';
import {ChipType, ChipVariant} from './types';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    visibleElementContainer: {
        marginRight: theme.spacing(0.5),
    },
    dropdownContent: {
        display: 'grid',
        gridGap: theme.spacing(1),
        justifyItems: 'baseline',
    },
}));

type RoundedChipListProps = {
    visibleElement: JSX.Element;
    hiddenElements: React.ReactNode[];
    chipType?: ChipType;
    chipVariant?: ChipVariant | SystemColorsEnum | string;
};

export function ListWithChipTooltip({
    visibleElement,
    hiddenElements,
    chipType = ChipType.Status,
    chipVariant = ChipVariant.Grey,
}: RoundedChipListProps) {
    const {classes} = useStyles();

    return (
        <>
            <Box className={classes.visibleElementContainer}>{visibleElement}</Box>
            {hiddenElements?.length ? (
                <ChipWithTooltip
                    chipType={chipType}
                    chipVariant={chipVariant}
                    label={`+${hiddenElements?.length}`}
                    fillWidth={false}
                    title={<Box className={classes.dropdownContent}>{hiddenElements}</Box>}
                    hideTooltip={false}
                />
            ) : null}
        </>
    );
}
