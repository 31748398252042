import React from 'react';
import {ControllerFieldState} from 'react-hook-form';
import {FormControl, FormHelperText} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useStyle = makeStyles()({
    formTextError: {
        textAlign: 'right',
    },
});

type FormControlHocProps = {
    formControlClassName?: string;
    fieldState: ControllerFieldState;
};

export function withFormControl<TProps>(WrappedComponent: React.FC<TProps>) {
    return function FormErrorControl({formControlClassName, fieldState, ...props}: TProps & FormControlHocProps) {
        const {classes} = useStyle();
        const hasError = fieldState?.error?.message?.length > 0;
        return (
            <FormControl className={formControlClassName} error={hasError}>
                <WrappedComponent {...(props as TProps)} />
                {hasError ? <FormHelperText className={classes.formTextError}>{fieldState?.error?.message}</FormHelperText> : null}
            </FormControl>
        );
    };
}
