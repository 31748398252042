import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    financeSummary: {
        id: 'RealtimeMessage_financeSummary',
        defaultMessage: 'Finance Summary',
    },
    title: {
        id: 'userFinanceSummary_title',
        defaultMessage: 'Deposit / Withdrawal Summary',
    },
    type: {
        id: 'userFinanceSummary_type',
        defaultMessage: 'Type',
    },
    method: {
        id: 'userFinanceSummary_method',
        defaultMessage: 'Method',
    },
    reference: {
        id: 'userFinanceSummary_reference',
        defaultMessage: 'Reference',
    },
    countsSuccessful: {
        id: 'userFinanceSummary_countsSuccessful',
        defaultMessage: 'Counts Successful',
    },
    amountSuccessful: {
        id: 'userFinanceSummary_amountSuccessful',
        defaultMessage: 'Amount Successful',
    },
    countFailed: {
        id: 'userFinanceSummary_countFailed',
        defaultMessage: 'Count Failed',
    },
    amountFailed: {
        id: 'userFinanceSummary_amountFailed',
        defaultMessage: 'Amount Failed',
    },
    countRejected: {
        id: 'userFinanceSummary_countRejected',
        defaultMessage: 'Count Rejected',
    },
    amountRejected: {
        id: 'userFinanceSummary_amountRejected',
        defaultMessage: 'Amount Rejected',
    },
    filterType: {
        id: 'userFinanceSummary_filterType',
        defaultMessage: 'Type',
    },
    filterMethod: {
        id: 'userFinanceSummary_filterMethod',
        defaultMessage: 'Method',
    },
    emptyList: {
        id: 'userFinanceSummary_emptyList',
        defaultMessage: 'Finance summary is empty',
    },
});
