import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {EditableColDef} from 'src/features/module-temp-storage/components/editable-components/types';
import GridViewEditAction from '../../../features/module-shared/components/GridViewEditAction';
import {domain, RoleGridItem} from '../types';

export const renderEditAction = (params: GridCellParams) => {
    const role: RoleGridItem = params.row as RoleGridItem;
    const isEditable = (params.colDef as EditableColDef)?.isEditable;
    return <GridViewEditAction domain={domain} id={role.id} isEditable={isEditable} mode="view" />;
};
