import {RootEpic} from '@redux';

import {protectEpics} from '../../app/error-handling/epics';
import {loadDataEpic, patchEpic} from '../../module-temp-storage/epics';

import {actions} from './actions';
import {EditSecurityCasesSubmitModel, SecurityCaseFilter} from './types';

const botCasesLoadEpic: RootEpic = loadDataEpic(actions.loadSecurityCases, (payload, _, container) => {
    return container.securityCasesService.loadSecurityCases(payload.filter as SecurityCaseFilter);
});

const updateSecurityCaseEpic: RootEpic = patchEpic(actions.updateSecurityCase, (payload, _, container) => {
    return container.securityCasesService.updateSecurityCases(payload.id, payload.item as EditSecurityCasesSubmitModel);
});

export default protectEpics(botCasesLoadEpic, updateSecurityCaseEpic);
