import React, {memo} from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import LocalizedText, {LocalizedTextProps} from '@components/i18n/LocalizedText';
import {MultiLineAutoAdjustedWidthTooltip} from '@components/tooltip/MultiLineAutoAdjustedWidthTooltip';

import {getGridEmptyValue} from './utils';

export const GridTextFormatter = memo(({label}: LocalizedTextProps) => {
    return <LocalizedText label={label} />;
});

// TODO: Move to HeaderCell.tsx
export const renderCellHeader = (label: string | MessageDescriptor) => {
    return (
        <MultiLineAutoAdjustedWidthTooltip linesCount={2}>
            <GridTextFormatter label={label} />
        </MultiLineAutoAdjustedWidthTooltip>
    );
};

type MuiTextCellProps = {
    value: string;
    emptyPlaceholder: string;
};

function MuiTextCell({value, emptyPlaceholder}: MuiTextCellProps) {
    return value ? (
        <MultiLineAutoAdjustedWidthTooltip linesCount={1}>
            <LocalizedText label={value} />
        </MultiLineAutoAdjustedWidthTooltip>
    ) : (
        <>{emptyPlaceholder}</>
    );
}

export function renderMuiGridStringTextCell(params: GridCellParams) {
    const value = params.value as string;
    const emptyPlaceholder = getGridEmptyValue(params.row);

    return <MuiTextCell value={value} emptyPlaceholder={emptyPlaceholder} />;
}

export function renderMuiGridLocalizedTextCell(params: GridCellParams) {
    const {formatMessage} = useIntl();
    const message = params.value as MessageDescriptor;
    const value = message ? formatMessage(message) : null;
    const emptyPlaceholder = getGridEmptyValue(params.row);

    return <MuiTextCell value={value} emptyPlaceholder={emptyPlaceholder} />;
}
