import React, {useState} from 'react';

type EditableFormProviderProps = {
    children: JSX.Element | React.ReactNode;
};

type EditableFormContextProps = {
    editableFormId: string;
    setEditableFormId: (id: string) => void;
};

const EditableFormContext = React.createContext<EditableFormContextProps>(undefined);

const EditableFormProvider = ({children}: EditableFormProviderProps) => {
    const [editableFormId, setEditableFormIdInternal] = useState<string>(null);

    function setEditableFormId(id: string) {
        setEditableFormIdInternal(null);
        setTimeout(() => {
            setEditableFormIdInternal(id);
        }, 100);
    }

    return <EditableFormContext.Provider value={{editableFormId, setEditableFormId}}>{children}</EditableFormContext.Provider>;
};

export {EditableFormContext, EditableFormProvider};
