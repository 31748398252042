import {RouterLocation, RouterState} from '@vs-centaurea/connected-react-router';
import {createSelector} from 'reselect';
import {RootState} from 'typesafe-actions';

export const locationSelector = createSelector<RootState, RouterState, RouterLocation<unknown>>(
    s => s.router,
    (state: RouterState) => state?.location
);

export const locationSearchSelector = createSelector<RootState, RouterState, string>(
    s => s.router,
    (state: RouterState) => state?.location?.search
);

export const locationPathnameSelector = createSelector<RootState, RouterState, string>(
    s => s.router,
    (state: RouterState) => state?.location?.pathname
);
