import {defineMessages} from 'react-intl';

export const localizedOccupations = defineMessages({
    '1000000_title': {
        id: '1000000_title',
        defaultMessage: 'NO APLICA',
    },
    '1110100_title': {
        id: '1110100_title',
        defaultMessage: 'AGRICULTURA Y SILVICULTURA',
    },
    '1110100_subtitle': {
        id: '1110100_subtitle',
        defaultMessage: 'ADMINISTRADORES O TRABAJADORES AGRICOLAS',
    },
    '1110400_title': {
        id: '1110400_title',
        defaultMessage: 'AGRICULTURA Y SILVICULTURA',
    },
    '1110400_subtitle': {
        id: '1110400_subtitle',
        defaultMessage: 'ADMINISTRADORES O TRABAJADORES SILVICOLAS Y FORESTALES',
    },
    '1220100_title': {
        id: '1220100_title',
        defaultMessage: 'GANADERIA',
    },
    '1220100_subtitle': {
        id: '1220100_subtitle',
        defaultMessage: 'APICULTORES',
    },
    '1220200_title': {
        id: '1220200_title',
        defaultMessage: 'GANADERIA',
    },
    '1220200_subtitle': {
        id: '1220200_subtitle',
        defaultMessage: 'ADMINISTRADORES, CRIADORES O SUPERVISORES AVICOLAS Y GANADEROS',
    },
    '1330200_title': {
        id: '1330200_title',
        defaultMessage: 'PESCA Y ACUACULTURA',
    },
    '1330200_subtitle': {
        id: '1330200_subtitle',
        defaultMessage: 'PESCADORES Y TRABAJADORES EN LA CRIA Y CULTIVO DE ESPECIES MARINAS',
    },
    '2130100_title': {
        id: '2130100_title',
        defaultMessage: 'MINERIA, EXTRACCION Y SUMINISTRO',
    },
    '2130100_subtitle': {
        id: '2130100_subtitle',
        defaultMessage: 'TECNICOS GEOLOGICOS Y DE MINERALES',
    },
    '2140100_title': {
        id: '2140100_title',
        defaultMessage: 'MINERIA, EXTRACCION Y SUMINISTRO',
    },
    '2140100_subtitle': {
        id: '2140100_subtitle',
        defaultMessage: 'INGENIEROS, OPERADORES O AYUDANTES EN LA EXTRACCION Y REFINACION MINERA',
    },
    '2210100_title': {
        id: '2210100_title',
        defaultMessage: 'MINERIA, EXTRACCION Y SUMINISTRO',
    },
    '2210100_subtitle': {
        id: '2210100_subtitle',
        defaultMessage: 'AYUDANTES EN LA PERFORACION DE POZOS DE PETROLEO Y GAS NATURAL',
    },
    '2240200_title': {
        id: '2240200_title',
        defaultMessage: 'MINERIA, EXTRACCION Y SUMINISTRO',
    },
    '2240200_subtitle': {
        id: '2240200_subtitle',
        defaultMessage: 'INGENIEROS PETROLEROS',
    },
    '2420100_title': {
        id: '2420100_title',
        defaultMessage: 'MINERIA, EXTRACCION Y SUMINISTRO',
    },
    '2420100_subtitle': {
        id: '2420100_subtitle',
        defaultMessage: 'OPERADORES DE CENTRALES O SISTEMAS  DE ENERGIA ELECTRICAS',
    },
    '2420200_title': {
        id: '2420200_title',
        defaultMessage: 'MINERIA, EXTRACCION Y SUMINISTRO',
    },
    '2420200_subtitle': {
        id: '2420200_subtitle',
        defaultMessage: 'OPERADORES DE MAQUINAS DE VAPOR',
    },
    '2530100_title': {
        id: '2530100_title',
        defaultMessage: 'MINERIA, EXTRACCION Y SUMINISTRO',
    },
    '2530100_subtitle': {
        id: '2530100_subtitle',
        defaultMessage: 'GERENTE, SUPERVISOR U OPERADORES DE  TRATAMIENTO Y POTABILIZACION, ABASTECIMIENTO Y RECOLECCION DE AGUA',
    },
    '3130100_title': {
        id: '3130100_title',
        defaultMessage: 'CONSTRUCCION',
    },
    '3130100_subtitle': {
        id: '3130100_subtitle',
        defaultMessage: 'DECORADORES DE INTERIORES',
    },
    '3130300_title': {
        id: '3130300_title',
        defaultMessage: 'CONSTRUCCION',
    },
    '3130300_subtitle': {
        id: '3130300_subtitle',
        defaultMessage: 'INGENIEROS, TECNICOS Y OPERADORES DE LA CONSTRUCCION',
    },
    '3310300_title': {
        id: '3310300_title',
        defaultMessage: 'CONSTRUCCION',
    },
    '3310300_subtitle': {
        id: '3310300_subtitle',
        defaultMessage: 'COLOCADORES DE PRODUCTOS PREFABRICADOS EN INMUEBLES',
    },
    '3310400_title': {
        id: '3310400_title',
        defaultMessage: 'CONSTRUCCION',
    },
    '3310400_subtitle': {
        id: '3310400_subtitle',
        defaultMessage: 'PINTORES',
    },
    '3310600_title': {
        id: '3310600_title',
        defaultMessage: 'CONSTRUCCION',
    },
    '3310600_subtitle': {
        id: '3310600_subtitle',
        defaultMessage: 'VIDRIEROS',
    },
    '3410200_title': {
        id: '3410200_title',
        defaultMessage: 'CONSTRUCCION',
    },
    '3410200_subtitle': {
        id: '3410200_subtitle',
        defaultMessage: 'REPARADORES DE VIAS DE COMUNICACION',
    },
    '3420100_title': {
        id: '3420100_title',
        defaultMessage: 'CONSTRUCCION',
    },
    '3420100_subtitle': {
        id: '3420100_subtitle',
        defaultMessage: 'PLOMEROS E INSTALADORES DE TUBERIA',
    },
    '3420200_title': {
        id: '3420200_title',
        defaultMessage: 'CONSTRUCCION',
    },
    '3420200_subtitle': {
        id: '3420200_subtitle',
        defaultMessage: 'ARQUITECTOS',
    },
    '4130300_title': {
        id: '4130300_title',
        defaultMessage: 'MECANICA',
    },
    '4130300_subtitle': {
        id: '4130300_subtitle',
        defaultMessage: 'MECANICOS DE EQUIPO PESADO',
    },
    '4130500_title': {
        id: '4130500_title',
        defaultMessage: 'MECANICA',
    },
    '4130500_subtitle': {
        id: '4130500_subtitle',
        defaultMessage: 'INGENIERO O MECANICOS INSTALADORES DE MAQUINARIA INDUSTRIAL',
    },
    '4131000_title': {
        id: '4131000_title',
        defaultMessage: 'MECANICA',
    },
    '4131000_subtitle': {
        id: '4131000_subtitle',
        defaultMessage: 'INGENIERO O TECNICO EN MECANICA DE VEHICULOS TERRESTRES, AEREOS Y ACUATICOS',
    },
    '4230200_title': {
        id: '4230200_title',
        defaultMessage: 'ELECTRICIDAD',
    },
    '4230200_subtitle': {
        id: '4230200_subtitle',
        defaultMessage: 'INGENIEROS O TECNICOS ELECTRICISTAS',
    },
    '4230500_title': {
        id: '4230500_title',
        defaultMessage: 'ELECTRICIDAD',
    },
    '4230500_subtitle': {
        id: '4230500_subtitle',
        defaultMessage: 'TECNICOS EN REFRIGERACION, AIRE ACONDICIONADO Y CALEFACCION',
    },
    '4330100_title': {
        id: '4330100_title',
        defaultMessage: 'ELECTRONICA',
    },
    '4330100_subtitle': {
        id: '4330100_subtitle',
        defaultMessage: 'MECANICOS DE INSTRUMENTOS INDUSTRIALES',
    },
    '4330200_title': {
        id: '4330200_title',
        defaultMessage: 'ELECTRONICA',
    },
    '4330200_subtitle': {
        id: '4330200_subtitle',
        defaultMessage: 'INGENIERO O TECNICOS EN  ELECTRONICA',
    },
    '4430200_title': {
        id: '4430200_title',
        defaultMessage: 'INFORMATICA',
    },
    '4430200_subtitle': {
        id: '4430200_subtitle',
        defaultMessage: 'INGENIERO O TECNICOS PROGRAMADORES EN INFORMATICA',
    },
    '4440100_title': {
        id: '4440100_title',
        defaultMessage: 'INFORMATICA',
    },
    '4440100_subtitle': {
        id: '4440100_subtitle',
        defaultMessage: 'PROFESIONISTAS O TECNICOS DE SISTEMAS DE INFORMACION Y PROCESAMIENTO DE DATOS',
    },
    '4530200_title': {
        id: '4530200_title',
        defaultMessage: 'TELECOMUNICACIONES',
    },
    '4530200_subtitle': {
        id: '4530200_subtitle',
        defaultMessage: 'INGENIERO, INSTALADORES Y REPARADORES DE EQUIPOS Y ACCESORIOS DE TELECOMUNICACIONES',
    },
    '4530500_title': {
        id: '4530500_title',
        defaultMessage: 'TELECOMUNICACIONES',
    },
    '4530500_subtitle': {
        id: '4530500_subtitle',
        defaultMessage: 'TELEGRAFISTAS Y RADIO-OPERADORES',
    },
    '4630100_title': {
        id: '4630100_title',
        defaultMessage: 'PROCESOS INDUSTRIALES',
    },
    '4630100_subtitle': {
        id: '4630100_subtitle',
        defaultMessage: 'INGENIERO O TECNICOS INDUSTRIAL Y DE PRODUCCION',
    },
    '4640300_title': {
        id: '4640300_title',
        defaultMessage: 'PROCESOS INDUSTRIALES',
    },
    '4640300_subtitle': {
        id: '4640300_subtitle',
        defaultMessage: 'INGENIEROS METALURGICOS Y DE MATERIALES',
    },
    '5110100_title': {
        id: '5110100_title',
        defaultMessage: 'MINERALES NO METALICOS',
    },
    '5110100_subtitle': {
        id: '5110100_subtitle',
        defaultMessage: 'OPERADORES O TRABAJADORES DE VIDRIO Y CONCRETO',
    },
    '5121300_title': {
        id: '5121300_title',
        defaultMessage: 'MINERALES NO METALICOS',
    },
    '5121300_subtitle': {
        id: '5121300_subtitle',
        defaultMessage: 'OPERADORES DE MAQUINAS PROCESADORAS DE MINERALES NO METALICOS',
    },
    '5210100_title': {
        id: '5210100_title',
        defaultMessage: 'METALES',
    },
    '5210100_subtitle': {
        id: '5210100_subtitle',
        defaultMessage: 'SUPERVISORES U OPERADORES DE PROCESAMIENTO Y FUNDICION DE METALES',
    },
    '5310700_title': {
        id: '5310700_title',
        defaultMessage: 'ALIMENTOS Y BEBIDAS',
    },
    '5310700_subtitle': {
        id: '5310700_subtitle',
        defaultMessage: 'TRABAJADORES EN LA ELABORACION  Y PROCESAMIENTO DE ALIMENTOS, BEBIDAS Y TABACO',
    },
    '5410100_title': {
        id: '5410100_title',
        defaultMessage: 'TEXTILES Y PRENDAS DE VESTIR',
    },
    '5410100_subtitle': {
        id: '5410100_subtitle',
        defaultMessage: 'TRABAJADORES EN LA PRODUCCION DE TEXTILES, PRENDAS DE VESTIR Y CALZADO',
    },
    '5420700_title': {
        id: '5420700_title',
        defaultMessage: 'TEXTILES Y PRENDAS DE VESTIR',
    },
    '5420700_subtitle': {
        id: '5420700_subtitle',
        defaultMessage: 'TRABAJADORES DE REPARACION DE PRENDAS DE VESTIR Y CALZADO',
    },
    '5510100_title': {
        id: '5510100_title',
        defaultMessage: 'MADERA, PAPEL, Y PIEL',
    },
    '5510100_subtitle': {
        id: '5510100_subtitle',
        defaultMessage: 'TRABAJADORES EN LA FABRICACION DE MUEBLES O PRODUCTOS DE MADERA Y/O PIEL',
    },
    '5610200_title': {
        id: '5610200_title',
        defaultMessage: 'PRODUCTOS QUIMICOS',
    },
    '5610200_subtitle': {
        id: '5610200_subtitle',
        defaultMessage: 'TRABAJADORES EN EL PROCESAMIENTO Y FABRICACION DE PRODUCTOS QUIMICOS Y FARMACOQUIMICAS',
    },
    '5710200_title': {
        id: '5710200_title',
        defaultMessage: 'PRODUCTOS METALICOS Y DE HULE Y PLASTICO',
    },
    '5710200_subtitle': {
        id: '5710200_subtitle',
        defaultMessage: 'TRABAJADORES  EN LA FABRICACION DE PRODUCTOS METALICOS, DE HULE Y PLASTICOS',
    },
    '5720800_title': {
        id: '5720800_title',
        defaultMessage: 'PRODUCTOS METALICOS Y DE HULE Y PLASTICO',
    },
    '5720800_subtitle': {
        id: '5720800_subtitle',
        defaultMessage: 'ENSAMBLADORES Y ACABADORES DE PRODUCTOS DE PLASTICO',
    },
    '5720900_title': {
        id: '5720900_title',
        defaultMessage: 'PRODUCTOS METALICOS Y DE HULE Y PLASTICO',
    },
    '5720900_subtitle': {
        id: '5720900_subtitle',
        defaultMessage: 'FABRICANTES DE HERRAMIENTAS Y TROQUELES',
    },
    '5721000_title': {
        id: '5721000_title',
        defaultMessage: 'PRODUCTOS METALICOS Y DE HULE Y PLASTICO',
    },
    '5721000_subtitle': {
        id: '5721000_subtitle',
        defaultMessage: 'HERREROS Y FORJADORES',
    },
    '5721100_title': {
        id: '5721100_title',
        defaultMessage: 'PRODUCTOS METALICOS Y DE HULE Y PLASTICO',
    },
    '5721100_subtitle': {
        id: '5721100_subtitle',
        defaultMessage: 'JOYEROS Y ORFEBRES',
    },
    '5722900_title': {
        id: '5722900_title',
        defaultMessage: 'PRODUCTOS METALICOS Y DE HULE Y PLASTICO',
    },
    '5722900_subtitle': {
        id: '5722900_subtitle',
        defaultMessage: 'SOLDADORES Y OXICORTADORES',
    },
    '5730600_title': {
        id: '5730600_title',
        defaultMessage: 'PRODUCTOS METALICOS Y DE HULE Y PLASTICO',
    },
    '5730600_subtitle': {
        id: '5730600_subtitle',
        defaultMessage: 'TRABAJADORES EN EL ENSAMBLADO DE VEHICULOS, MOLDEADO, LAMINADO Y MONTAJE DE PIEZAS METALICAS, HULE O PLASTICO',
    },
    '5731100_title': {
        id: '5731100_title',
        defaultMessage: 'PRODUCTOS METALICOS Y DE HULE Y PLASTICO',
    },
    '5731100_subtitle': {
        id: '5731100_subtitle',
        defaultMessage: 'SUPERVISORES EN LA FABRICACION Y MONTAJE DE ARTICULOS DEPORTIVOS, DE JUGUETES Y SIMILARES',
    },
    '5810200_title': {
        id: '5810200_title',
        defaultMessage: 'PRODUCTOS ELECTRICOS Y ELECTRONICOS',
    },
    '5810200_subtitle': {
        id: '5810200_subtitle',
        defaultMessage: 'TRABAJADORES EN LA FABRICACION DE PRODUCTOS ELECTRICOS Y ELECTRONICOS',
    },
    '5910400_title': {
        id: '5910400_title',
        defaultMessage: 'PRODUCTOS IMPRESOS',
    },
    '5910400_subtitle': {
        id: '5910400_subtitle',
        defaultMessage: 'TRABAJADORES EN LA ELABORACION DE PRODUCTOS IMPRESOS',
    },
    '6120100_title': {
        id: '6120100_title',
        defaultMessage: 'TRANSPORTE FERROVIARIO',
    },
    '6120100_subtitle': {
        id: '6120100_subtitle',
        defaultMessage: 'CONDUCTORES Y OPERADORES DE TREN SUBTERRANEO Y DE TREN LIGERO',
    },
    '6120200_title': {
        id: '6120200_title',
        defaultMessage: 'TRANSPORTE FERROVIARIO',
    },
    '6120200_subtitle': {
        id: '6120200_subtitle',
        defaultMessage: 'TRABAJADORES DE FERROCARRILES',
    },
    '6220100_title': {
        id: '6220100_title',
        defaultMessage: 'TRANSPORTE TERRESTRE',
    },
    '6220100_subtitle': {
        id: '6220100_subtitle',
        defaultMessage: 'CONDUCTORES DE VEHICULOS DE TRANSPORTE, SERVICIOS DE CARGA Y/O REPARTO',
    },
    '6330100_title': {
        id: '6330100_title',
        defaultMessage: 'TRANSPORTE AEREO',
    },
    '6330100_subtitle': {
        id: '6330100_subtitle',
        defaultMessage: 'COORDINADORES Y SUPERVISORES EN SERVICIOS DE TRANSPORTE AEREO',
    },
    '6330200_title': {
        id: '6330200_title',
        defaultMessage: 'TRANSPORTE AEREO',
    },
    '6330200_subtitle': {
        id: '6330200_subtitle',
        defaultMessage: 'DESPACHADORES DE VUELO Y ESPECIALISTAS EN SERVICIOS AEREOS',
    },
    '6330400_title': {
        id: '6330400_title',
        defaultMessage: 'TRANSPORTE AEREO',
    },
    '6330400_subtitle': {
        id: '6330400_subtitle',
        defaultMessage: 'SUPERVISORES DE SISTEMAS DE COMUNICACION PARA LA AERONAVEGACION',
    },
    '6330500_title': {
        id: '6330500_title',
        defaultMessage: 'TRANSPORTE AEREO',
    },
    '6330500_subtitle': {
        id: '6330500_subtitle',
        defaultMessage: 'PILOTOS DE AVIACION E INSTRUCTORES DE VUELO',
    },
    '6330600_title': {
        id: '6330600_title',
        defaultMessage: 'TRANSPORTE AEREO',
    },
    '6330600_subtitle': {
        id: '6330600_subtitle',
        defaultMessage: 'SOBRECARGOS',
    },
    '6420100_title': {
        id: '6420100_title',
        defaultMessage: 'TRANSPORTE MARITIMO Y FLUVIAL',
    },
    '6420100_subtitle': {
        id: '6420100_subtitle',
        defaultMessage: 'CONDUCTORES DE EMBARCACIONES',
    },
    '6430100_title': {
        id: '6430100_title',
        defaultMessage: 'TRANSPORTE MARITIMO Y FLUVIAL',
    },
    '6430100_subtitle': {
        id: '6430100_subtitle',
        defaultMessage: 'JEFES Y CONTROLADORES DE TRAFICO MARITIMO',
    },
    '6430300_title': {
        id: '6430300_title',
        defaultMessage: 'TRANSPORTE MARITIMO Y FLUVIAL',
    },
    '6430300_subtitle': {
        id: '6430300_subtitle',
        defaultMessage: 'PILOTOS, CAPITANES DE PUERTOS Y OFICIALES DE CUBIERTA',
    },
    '7110200_title': {
        id: '7110200_title',
        defaultMessage: 'COMERCIO',
    },
    '7110200_subtitle': {
        id: '7110200_subtitle',
        defaultMessage: 'DESPACHADORES DE GASOLINERA',
    },
    '7110300_title': {
        id: '7110300_title',
        defaultMessage: 'COMERCIO',
    },
    '7110300_subtitle': {
        id: '7110300_subtitle',
        defaultMessage: 'EMPACADORES DE MERCANCIAS',
    },
    '7110500_title': {
        id: '7110500_title',
        defaultMessage: 'COMERCIO',
    },
    '7110500_subtitle': {
        id: '7110500_subtitle',
        defaultMessage: 'TAQUILLEROS',
    },
    '7110600_title': {
        id: '7110600_title',
        defaultMessage: 'COMERCIO',
    },
    '7110600_subtitle': {
        id: '7110600_subtitle',
        defaultMessage: 'VENDEDORES AMBULANTES',
    },
    '7120100_title': {
        id: '7120100_title',
        defaultMessage: 'COMERCIO',
    },
    '7120100_subtitle': {
        id: '7120100_subtitle',
        defaultMessage: 'CAJEROS REGISTRADORES',
    },
    '7130200_title': {
        id: '7130200_title',
        defaultMessage: 'COMERCIO',
    },
    '7130200_subtitle': {
        id: '7130200_subtitle',
        defaultMessage: 'REPRESENTANTES DE VENTAS POR TELEFONO O POR TELEVISION',
    },
    '7130400_title': {
        id: '7130400_title',
        defaultMessage: 'COMERCIO',
    },
    '7130400_subtitle': {
        id: '7130400_subtitle',
        defaultMessage: 'VENDEDORES ESPECIALIZADOS',
    },
    '7140100_title': {
        id: '7140100_title',
        defaultMessage: 'COMERCIO',
    },
    '7140100_subtitle': {
        id: '7140100_subtitle',
        defaultMessage: 'GERENTES O SUPERVISOR DE ESTABLECIMIENTO COMERCIAL',
    },
    '7140200_title': {
        id: '7140200_title',
        defaultMessage: 'COMERCIO',
    },
    '7140200_subtitle': {
        id: '7140200_subtitle',
        defaultMessage: 'GERENTES O EMPLEADOS DE VENTAS',
    },
    '7210100_title': {
        id: '7210100_title',
        defaultMessage: 'ALIMENTACION Y HOSPEDAJE',
    },
    '7210100_subtitle': {
        id: '7210100_subtitle',
        defaultMessage: 'TRABAJADORES DE SERVICIO DE ALIMENTOS Y BEBIDAS',
    },
    '7230200_title': {
        id: '7230200_title',
        defaultMessage: 'ALIMENTACION Y HOSPEDAJE',
    },
    '7230200_subtitle': {
        id: '7230200_subtitle',
        defaultMessage: 'JEFES DE COCINA, RESTAURANTE Y/O BAR',
    },
    '7240200_title': {
        id: '7240200_title',
        defaultMessage: 'ALIMENTACION Y HOSPEDAJE',
    },
    '7240200_subtitle': {
        id: '7240200_subtitle',
        defaultMessage: 'TRABAJADORES DE SERVICIOS DE ALOJAMIENTO',
    },
    '7330200_title': {
        id: '7330200_title',
        defaultMessage: 'TURISMO',
    },
    '7330200_subtitle': {
        id: '7330200_subtitle',
        defaultMessage: 'COORDINADORES DE OPERACIONES EN AGENCIAS DE VIAJES',
    },
    '7330300_title': {
        id: '7330300_title',
        defaultMessage: 'TURISMO',
    },
    '7330300_subtitle': {
        id: '7330300_subtitle',
        defaultMessage: 'GUIAS DE EXCURSIONES O ECOTURISTICO',
    },
    '7430100_title': {
        id: '7430100_title',
        defaultMessage: 'DEPORTE Y ESPARCIMIENTO',
    },
    '7430100_subtitle': {
        id: '7430100_subtitle',
        defaultMessage: 'ANIMADORES RECREATIVOS',
    },
    '7430200_title': {
        id: '7430200_title',
        defaultMessage: 'DEPORTE Y ESPARCIMIENTO',
    },
    '7430200_subtitle': {
        id: '7430200_subtitle',
        defaultMessage: 'ATLETAS, ENTRENADORES O INSTRUCTORES EN DEPORTE Y RECREACION',
    },
    '7430400_title': {
        id: '7430400_title',
        defaultMessage: 'DEPORTE Y ESPARCIMIENTO',
    },
    '7430400_subtitle': {
        id: '7430400_subtitle',
        defaultMessage: 'OFICIALES, JUECES Y ARBITROS DEPORTIVOS',
    },
    '7530100_title': {
        id: '7530100_title',
        defaultMessage: 'SERVICIOS PERSONALES',
    },
    '7530100_subtitle': {
        id: '7530100_subtitle',
        defaultMessage: 'ESTILISTAS, ESTETICISTAS Y MASAJISTAS',
    },
    '7540100_title': {
        id: '7540100_title',
        defaultMessage: 'SERVICIOS PERSONALES',
    },
    '7540100_subtitle': {
        id: '7540100_subtitle',
        defaultMessage: 'TRBAJADORES DE SERVICIOS FUNERARIOS O CEMENTERIOS',
    },
    '7620100_title': {
        id: '7620100_title',
        defaultMessage: 'REPARACION DE ARTICULOS DE USO DOMESTICO Y PERSONAL',
    },
    '7620100_subtitle': {
        id: '7620100_subtitle',
        defaultMessage: 'CERRAJEROS',
    },
    '7620200_title': {
        id: '7620200_title',
        defaultMessage: 'REPARACION DE ARTICULOS DE USO DOMESTICO Y PERSONAL',
    },
    '7620200_subtitle': {
        id: '7620200_subtitle',
        defaultMessage: 'REPARADORES DE ARTICULOS DE HULE',
    },
    '7630100_title': {
        id: '7630100_title',
        defaultMessage: 'REPARACION DE ARTICULOS DE USO DOMESTICO Y PERSONAL',
    },
    '7630100_subtitle': {
        id: '7630100_subtitle',
        defaultMessage: 'RELOJEROS Y REPARADORES DE RELOJES',
    },
    '7630200_title': {
        id: '7630200_title',
        defaultMessage: 'REPARACION DE ARTICULOS DE USO DOMESTICO Y PERSONAL',
    },
    '7630200_subtitle': {
        id: '7630200_subtitle',
        defaultMessage: 'REPARADORES DE APARATOS ELECTRICOS',
    },
    '7720100_title': {
        id: '7720100_title',
        defaultMessage: 'LIMPIEZA',
    },
    '7720100_subtitle': {
        id: '7720100_subtitle',
        defaultMessage: 'SERVICIOS DE CAMARISTAS Y ASEADORES',
    },
    '7720200_title': {
        id: '7720200_title',
        defaultMessage: 'LIMPIEZA',
    },
    '7720200_subtitle': {
        id: '7720200_subtitle',
        defaultMessage: 'TRABAJADORES DE TINTORERIA Y LAVANDERIA',
    },
    '7720300_title': {
        id: '7720300_title',
        defaultMessage: 'LIMPIEZA',
    },
    '7720300_subtitle': {
        id: '7720300_subtitle',
        defaultMessage: 'FUMIGADORES DE PLAGAS',
    },
    '7810200_title': {
        id: '7810200_title',
        defaultMessage: 'SERVICIO POSTAL Y MENSAJERIA',
    },
    '7810200_subtitle': {
        id: '7810200_subtitle',
        defaultMessage: 'EMPLEADOS DE SERVICIOS DE MENSAJERIA',
    },
    '8120100_title': {
        id: '8120100_title',
        defaultMessage: 'BOLSA, BANCA Y SEGUROS',
    },
    '8120100_subtitle': {
        id: '8120100_subtitle',
        defaultMessage: 'GERENTES O TRABAJADORES DE SERVICIOS Y PRODUCTOS FINANCIEROS',
    },
    '8130500_title': {
        id: '8130500_title',
        defaultMessage: 'BOLSA, BANCA Y SEGUROS',
    },
    '8130500_subtitle': {
        id: '8130500_subtitle',
        defaultMessage: 'VALUADORES',
    },
    '8140100_title': {
        id: '8140100_title',
        defaultMessage: 'BOLSA, BANCA Y SEGUROS',
    },
    '8140100_subtitle': {
        id: '8140100_subtitle',
        defaultMessage: 'AGENTES DE VALORES, PROMOTORES Y CORREDORES DE INVERSION',
    },
    '8210100_title': {
        id: '8210100_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8210100_subtitle': {
        id: '8210100_subtitle',
        defaultMessage: 'TRABAJADORES DE ARCHIVO, ALMACEN DE INVENTARIOS',
    },
    '8220400_title': {
        id: '8220400_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8220400_subtitle': {
        id: '8220400_subtitle',
        defaultMessage: 'CAPTURISTA Y OPERADORES DE TELEFONO',
    },
    '8220600_title': {
        id: '8220600_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8220600_subtitle': {
        id: '8220600_subtitle',
        defaultMessage: 'PAGADORES Y COBRADORES',
    },
    '8230300_title': {
        id: '8230300_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8230300_subtitle': {
        id: '8230300_subtitle',
        defaultMessage: 'DIRECTORES, GERENTES  Y EMPLEADOS DE COMPRAS, FINANZAS, RECURSOS HUMANOS Y SERVICIOS ADMINISTRATIVOS',
    },
    '8240100_title': {
        id: '8240100_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8240100_subtitle': {
        id: '8240100_subtitle',
        defaultMessage: 'ASISTENTES ADMINISTRATIVOS',
    },
    '8240200_title': {
        id: '8240200_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8240200_subtitle': {
        id: '8240200_subtitle',
        defaultMessage: 'CONTADORES Y AUDITORES',
    },
    '8240600_title': {
        id: '8240600_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8240600_subtitle': {
        id: '8240600_subtitle',
        defaultMessage: 'DIRECTORES, GERENTES Y EMPLEADOS DE PRODUCCION',
    },
    '8240700_title': {
        id: '8240700_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8240700_subtitle': {
        id: '8240700_subtitle',
        defaultMessage: 'DIRECTORES, GERENTES Y EMPLEADOS DE SERVICIOS DE TRANSPORTE',
    },
    '8250100_title': {
        id: '8250100_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8250100_subtitle': {
        id: '8250100_subtitle',
        defaultMessage: 'CONSULTORES',
    },
    '8250200_title': {
        id: '8250200_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8250200_subtitle': {
        id: '8250200_subtitle',
        defaultMessage: 'DIRECTORES, GERENTES Y EMPLEADOS DE COMERCIALIZACION',
    },
    '8250800_title': {
        id: '8250800_title',
        defaultMessage: 'ADMINISTRACION',
    },
    '8250800_subtitle': {
        id: '8250800_subtitle',
        defaultMessage: 'DIRECTORES, GERENTES Y EMPELADOS ADMINISTRATIVOS',
    },
    '8340100_title': {
        id: '8340100_title',
        defaultMessage: 'SERVICIOS LEGALES',
    },
    '8340100_subtitle': {
        id: '8340100_subtitle',
        defaultMessage: 'ABOGADOS Y ASESORES LEGALES',
    },
    '8340300_title': {
        id: '8340300_title',
        defaultMessage: 'SERVICIOS LEGALES',
    },
    '8340300_subtitle': {
        id: '8340300_subtitle',
        defaultMessage: 'NOTARIOS Y CORREDORES PUBLICOS',
    },
    '9120700_title': {
        id: '9120700_title',
        defaultMessage: 'SERVICIOS MEDICOS',
    },
    '9120700_subtitle': {
        id: '9120700_subtitle',
        defaultMessage: 'ENFERMERAS Y/O PARAMEDICOS',
    },
    '9130300_title': {
        id: '9130300_title',
        defaultMessage: 'SERVICIOS MEDICOS',
    },
    '9130300_subtitle': {
        id: '9130300_subtitle',
        defaultMessage: 'DIETISTAS Y NUTRIOLOGOS',
    },
    '9131200_title': {
        id: '9131200_title',
        defaultMessage: 'SERVICIOS MEDICOS',
    },
    '9131200_subtitle': {
        id: '9131200_subtitle',
        defaultMessage: 'TECNICOS DE LABORATORIO MEDICO',
    },
    '9140200_title': {
        id: '9140200_title',
        defaultMessage: 'SERVICIOS MEDICOS',
    },
    '9140200_subtitle': {
        id: '9140200_subtitle',
        defaultMessage: 'DIRECTORES DE INSTITUCIONES EN EL CUIDADO DE LA SALUD',
    },
    '9140400_title': {
        id: '9140400_title',
        defaultMessage: 'SERVICIOS MEDICOS',
    },
    '9140400_subtitle': {
        id: '9140400_subtitle',
        defaultMessage: 'FARMACEUTICOS',
    },
    '9140500_title': {
        id: '9140500_title',
        defaultMessage: 'SERVICIOS MEDICOS',
    },
    '9140500_subtitle': {
        id: '9140500_subtitle',
        defaultMessage: 'FISIOTERAPEUTAS Y QUIROPRACTICOS',
    },
    '9140700_title': {
        id: '9140700_title',
        defaultMessage: 'SERVICIOS MEDICOS',
    },
    '9140700_subtitle': {
        id: '9140700_subtitle',
        defaultMessage: 'MEDICOS ESPECIALISTAS',
    },
    '9140800_title': {
        id: '9140800_title',
        defaultMessage: 'SERVICIOS MEDICOS',
    },
    '9140800_subtitle': {
        id: '9140800_subtitle',
        defaultMessage: 'MEDICOS GENERALES Y FAMILIARES',
    },
    '9230100_title': {
        id: '9230100_title',
        defaultMessage: 'INSPECCION',
    },
    '9230100_subtitle': {
        id: '9230100_subtitle',
        defaultMessage: 'INSPECTORES DE SALUD AMBIENTAL, SANIDAD Y DEL TRABAJO',
    },
    '9230200_title': {
        id: '9230200_title',
        defaultMessage: 'INSPECCION',
    },
    '9230200_subtitle': {
        id: '9230200_subtitle',
        defaultMessage: 'INSPECTORES DE TRANSPORTE DE CARGA Y DE PASAJEROS',
    },
    '9230300_title': {
        id: '9230300_title',
        defaultMessage: 'INSPECCION',
    },
    '9230300_subtitle': {
        id: '9230300_subtitle',
        defaultMessage: 'INSPECTORES FISCALES Y DE PRECIOS',
    },
    '9230400_title': {
        id: '9230400_title',
        defaultMessage: 'INSPECCION',
    },
    '9230400_subtitle': {
        id: '9230400_subtitle',
        defaultMessage: 'INSPECTORES SANITARIOS Y DE CONTROL DE CALIDAD DE PRODUCTOS CARNICOS, PESQUEROS Y AGRICOLAS',
    },
    '9330100_title': {
        id: '9330100_title',
        defaultMessage: 'SEGURIDAD SOCIAL',
    },
    '9330100_subtitle': {
        id: '9330100_subtitle',
        defaultMessage: 'CONSEJEROS DE EMPLEO',
    },
    '9330300_title': {
        id: '9330300_title',
        defaultMessage: 'SEGURIDAD SOCIAL',
    },
    '9330300_subtitle': {
        id: '9330300_subtitle',
        defaultMessage: 'TRABAJADORES DE SERVICIO SOCIAL Y DE LA COMUNIDAD',
    },
    '9420100_title': {
        id: '9420100_title',
        defaultMessage: 'PROTECCION DE BIENES Y/O PERSONAS',
    },
    '9420100_subtitle': {
        id: '9420100_subtitle',
        defaultMessage: 'BOMBEROS',
    },
    '9420300_title': {
        id: '9420300_title',
        defaultMessage: 'PROTECCION DE BIENES Y/O PERSONAS',
    },
    '9420300_subtitle': {
        id: '9420300_subtitle',
        defaultMessage: 'GUARDIAS DE SEGURIDAD',
    },
    '9430100_title': {
        id: '9430100_title',
        defaultMessage: 'PROTECCION DE BIENES Y/O PERSONAS',
    },
    '9430100_subtitle': {
        id: '9430100_subtitle',
        defaultMessage: 'DETECTIVES PRIVADOS',
    },
    '1014010_title': {
        id: '1014010_title',
        defaultMessage: 'RADIO, CINE, TELEVISION Y TEATRO',
    },
    '1014010_subtitle': {
        id: '1014010_subtitle',
        defaultMessage: 'DISEÑADORES GRAFICOS',
    },
    '1014030_title': {
        id: '1014030_title',
        defaultMessage: 'RADIO, CINE, TELEVISION Y TEATRO',
    },
    '1014030_subtitle': {
        id: '1014030_subtitle',
        defaultMessage: 'EDITORES, PERIODISTAS, REPORTEROS Y REDACTORES',
    },
    '1023010_title': {
        id: '1023010_title',
        defaultMessage: 'RADIO, CINE, TELEVISION Y TEATRO',
    },
    '1023010_subtitle': {
        id: '1023010_subtitle',
        defaultMessage: 'ASISTENTES Y/U OPERADORES DE PRODUCCION DE CINE, RADIO Y TELEVISION',
    },
    '1023070_title': {
        id: '1023070_title',
        defaultMessage: 'RADIO, CINE, TELEVISION Y TEATRO',
    },
    '1023070_subtitle': {
        id: '1023070_subtitle',
        defaultMessage: 'LOCUTORES, COMENTARISTAS Y CRONISTAS DE RADIO Y TELEVISION',
    },
    '1024020_title': {
        id: '1024020_title',
        defaultMessage: 'RADIO, CINE, TELEVISION Y TEATRO',
    },
    '1024020_subtitle': {
        id: '1024020_subtitle',
        defaultMessage: 'PRODUCTORES Y DIRECTORES DE CINE, TELEVISION Y TEATRO',
    },
    '1033010_title': {
        id: '1033010_title',
        defaultMessage: 'INTERPRETACION ARTISTICA',
    },
    '1033010_subtitle': {
        id: '1033010_subtitle',
        defaultMessage: 'ACTORES, BAILARINES, MUSICOS, ESCRITORES',
    },
    '1033020_title': {
        id: '1033020_title',
        defaultMessage: 'INTERPRETACION ARTISTICA',
    },
    '1033020_subtitle': {
        id: '1033020_subtitle',
        defaultMessage: 'ARTESANOS',
    },
    '1033040_title': {
        id: '1033040_title',
        defaultMessage: 'INTERPRETACION ARTISTICA',
    },
    '1033040_subtitle': {
        id: '1033040_subtitle',
        defaultMessage: 'FOTOGRAFOS',
    },
    '1034010_title': {
        id: '1034010_title',
        defaultMessage: 'INTERPRETACION ARTISTICA',
    },
    '1034010_subtitle': {
        id: '1034010_subtitle',
        defaultMessage: 'ARTISTAS PLASTICOS',
    },
    '1044010_title': {
        id: '1044010_title',
        defaultMessage: 'TRADUCCION E INTERPRETACION LINGüISTICA',
    },
    '1044010_subtitle': {
        id: '1044010_subtitle',
        defaultMessage: 'INTERPRETES Y/O TRADUCTORES',
    },
    '1052010_title': {
        id: '1052010_title',
        defaultMessage: 'PUBLICIDAD, PROPAGANDA Y RELACIONES PUBLICAS',
    },
    '1052010_subtitle': {
        id: '1052010_subtitle',
        defaultMessage: 'MODELOS Y EDECANES',
    },
    '1054010_title': {
        id: '1054010_title',
        defaultMessage: 'PUBLICIDAD, PROPAGANDA Y RELACIONES PUBLICAS',
    },
    '1054010_subtitle': {
        id: '1054010_subtitle',
        defaultMessage: 'DIRECTORES, GERENTES Y EMPLEADOS DE PUBLICIDAD',
    },
    '1112010_title': {
        id: '1112010_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1112010_subtitle': {
        id: '1112010_subtitle',
        defaultMessage: 'ENCUESTADORES Y CODIFICADORES',
    },
    '1113010_title': {
        id: '1113010_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1113010_subtitle': {
        id: '1113010_subtitle',
        defaultMessage: 'ASISTENTES DE INVESTIGADORES',
    },
    '1113020_title': {
        id: '1113020_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1113020_subtitle': {
        id: '1113020_subtitle',
        defaultMessage: 'QUIMICOS Y/O TECNICOS EN QUIMICA',
    },
    '1114010_title': {
        id: '1114010_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1114010_subtitle': {
        id: '1114010_subtitle',
        defaultMessage: 'BIOLOGOS Y CIENTIFICOS RELACIONADOS',
    },
    '1114020_title': {
        id: '1114020_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1114020_subtitle': {
        id: '1114020_subtitle',
        defaultMessage: 'ECONOMISTAS Y POLITOLOGOS (NO FUNCIONARIOS PUBLICOS)',
    },
    '1114030_title': {
        id: '1114030_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1114030_subtitle': {
        id: '1114030_subtitle',
        defaultMessage: 'FISICOS ASTRONOMOS',
    },
    '1114040_title': {
        id: '1114040_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1114040_subtitle': {
        id: '1114040_subtitle',
        defaultMessage: 'GEOLOGOS, GEOQUIMICOS, GEOFISICOS Y GEOGRAFOS',
    },
    '1114050_title': {
        id: '1114050_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1114050_subtitle': {
        id: '1114050_subtitle',
        defaultMessage: 'INVESTIGADORES Y CONSULTORES EN MERCADOTECNIA',
    },
    '1114060_title': {
        id: '1114060_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1114060_subtitle': {
        id: '1114060_subtitle',
        defaultMessage: 'MATEMATICOS, ESTADISTICOS Y ACTUARIOS',
    },
    '1114070_title': {
        id: '1114070_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1114070_subtitle': {
        id: '1114070_subtitle',
        defaultMessage: 'METEOROLOGOS',
    },
    '1114090_title': {
        id: '1114090_title',
        defaultMessage: 'INVESTIGACION',
    },
    '1114090_subtitle': {
        id: '1114090_subtitle',
        defaultMessage: 'SOCIOLOGOS, ANTROPOLOGOS E HISTORIADORES',
    },
    '1123020_title': {
        id: '1123020_title',
        defaultMessage: 'ENSEÑANZA',
    },
    '1123020_subtitle': {
        id: '1123020_subtitle',
        defaultMessage: 'CAPACITADORES E INSTRUCTORES',
    },
    '1124070_title': {
        id: '1124070_title',
        defaultMessage: 'ENSEÑANZA',
    },
    '1124070_subtitle': {
        id: '1124070_subtitle',
        defaultMessage: 'PROFESORES O DOCENTES',
    },
    '1125010_title': {
        id: '1125010_title',
        defaultMessage: 'ENSEÑANZA',
    },
    '1125010_subtitle': {
        id: '1125010_subtitle',
        defaultMessage: 'DIRECTORES GENERALES DE EDUCACION',
    },
    '1133010_title': {
        id: '1133010_title',
        defaultMessage: 'DIFUSION CULTURAL',
    },
    '1133010_subtitle': {
        id: '1133010_subtitle',
        defaultMessage: 'PROMOTORES DE DIFUSION CULTURAL',
    },
    '1134010_title': {
        id: '1134010_title',
        defaultMessage: 'DIFUSION CULTURAL',
    },
    '1134010_subtitle': {
        id: '1134010_subtitle',
        defaultMessage: 'TRABAJADORES DE BIBLIOTECA, ARCHIVO, MUSEO Y GALERIA DE ARTE',
    },
    '1135010_title': {
        id: '1135010_title',
        defaultMessage: 'OTRAS OCUPACIONES',
    },
    '1135010_subtitle': {
        id: '1135010_subtitle',
        defaultMessage: 'ESTUDIANTE O MENOR DE EDAD SIN OCUPACION',
    },
    '1135080_title': {
        id: '1135080_title',
        defaultMessage: 'OTRAS OCUPACIONES',
    },
    '1135080_subtitle': {
        id: '1135080_subtitle',
        defaultMessage: 'PROPIETARIO, ACCIONISTA O SOCIO',
    },
    '1135020_title': {
        id: '1135020_title',
        defaultMessage: 'OTRAS OCUPACIONES',
    },
    '1135020_subtitle': {
        id: '1135020_subtitle',
        defaultMessage: 'DESEMPLEADO',
    },
    '1135030_title': {
        id: '1135030_title',
        defaultMessage: 'OTRAS OCUPACIONES',
    },
    '1135030_subtitle': {
        id: '1135030_subtitle',
        defaultMessage: 'JUBILADO O PENSIONADO',
    },
    '1135050_title': {
        id: '1135050_title',
        defaultMessage: 'OTRAS OCUPACIONES',
    },
    '1135050_subtitle': {
        id: '1135050_subtitle',
        defaultMessage: 'AMA DE CASA O QUEHACERES DEL HOGAR',
    },
    '1135060_title': {
        id: '1135060_title',
        defaultMessage: 'OTRAS OCUPACIONES',
    },
    '1135060_subtitle': {
        id: '1135060_subtitle',
        defaultMessage: 'MINISTROS DE CULTO RELIGIOSO (SACERDOTE, PASTOR, MONJA, ETC)',
    },
    '1135070_title': {
        id: '1135070_title',
        defaultMessage: 'OTRAS OCUPACIONES',
    },
    '1135070_subtitle': {
        id: '1135070_subtitle',
        defaultMessage: 'AGENTE ADUANAL',
    },
    '1136010_title': {
        id: '1136010_title',
        defaultMessage: 'SECTOR PUBLICO',
    },
    '1136010_subtitle': {
        id: '1136010_subtitle',
        defaultMessage: 'EMPLEADO DEL PODER EJECUTIVO FEDERAL',
    },
    '1136020_title': {
        id: '1136020_title',
        defaultMessage: 'SECTOR PUBLICO',
    },
    '1136020_subtitle': {
        id: '1136020_subtitle',
        defaultMessage: 'EMPLEADO PODER EJECUTIVO ESTATAL O DEL DISTRITO FEDERAL',
    },
    '1136030_title': {
        id: '1136030_title',
        defaultMessage: 'SECTOR PUBLICO',
    },
    '1136030_subtitle': {
        id: '1136030_subtitle',
        defaultMessage: 'EMPLEADO DEL PODER EJECUTIVO MUNICIPAL O DELEGACIONAL',
    },
    '1136040_title': {
        id: '1136040_title',
        defaultMessage: 'SECTOR PUBLICO',
    },
    '1136040_subtitle': {
        id: '1136040_subtitle',
        defaultMessage: 'EMPLEADO DEL PODER JUDICIAL FEDERAL',
    },
    '1136070_title': {
        id: '1136070_title',
        defaultMessage: 'SECTOR PUBLICO',
    },
    '1136070_subtitle': {
        id: '1136070_subtitle',
        defaultMessage: 'EMPLEADO DEL PODER JUDICIAL ESTATAL O DEL DISTRITO FEDERAL',
    },
    '1136050_title': {
        id: '1136050_title',
        defaultMessage: 'SECTOR PUBLICO',
    },
    '1136050_subtitle': {
        id: '1136050_subtitle',
        defaultMessage: 'EMPLEADO DEL PODER LEGISLATIVO FEDERAL',
    },
    '1136060_title': {
        id: '1136060_title',
        defaultMessage: 'SECTOR PUBLICO',
    },
    '1136060_subtitle': {
        id: '1136060_subtitle',
        defaultMessage: 'EMPLEADO DEL PODER LEGISLATIVO ESTATAL O DEL DISTRITO FEDERAL',
    },
    '1136090_title': {
        id: '1136090_title',
        defaultMessage: 'SECTOR PUBLICO',
    },
    '1136090_subtitle': {
        id: '1136090_subtitle',
        defaultMessage: 'EJERCITO, ARMADA Y FUERZA AEREA',
    },
    '1136080_title': {
        id: '1136080_title',
        defaultMessage: 'ORGANISMOS INTERNACIONALES Y EXTRATERRITORIALES',
    },
    '1136080_subtitle': {
        id: '1136080_subtitle',
        defaultMessage: 'EMPLEADOS DE ORGANISMOS INTERNACIONALES Y EXTRATERRITORIALES',
    },
});
