import {OtelConfigOptions, registerOtel} from '@otel/config';
import {isStringNullOrEmpty} from '@utils';

import type {DynamicConfig} from '../configuration';

export function initOtel(config: DynamicConfig) {
    const {
        otelUrl,
        env,
        jurisdiction,
        name,
        version,
        authApiUrl,
        countriesApiUrl,
        plo5GameManagementApiUrl,
        igpGraphQlHttpUrl,
        attachmentsUrl,
        playerManagementApiUrl,
        configApiUrl,
    } = config;

    if (!isStringNullOrEmpty(otelUrl)) {
        const otelConfig: OtelConfigOptions = {
            resources: {
                environment: env,
                jurisdiction: jurisdiction,
                serviceName: name,
                serviceVersion: version,
            },
            exporter: {
                url: `${otelUrl}/traces`,
            },
        };
        registerOtel(otelConfig, [
            authApiUrl,
            countriesApiUrl,
            plo5GameManagementApiUrl,
            igpGraphQlHttpUrl,
            attachmentsUrl,
            playerManagementApiUrl,
            configApiUrl,
        ]);
    }
}
