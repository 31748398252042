import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';

import {Snackbar} from '@components/snackbar/Snackbar';
import {SnackbarIconUpdate} from '@components/snackbar/SnackbarIcon';

import {realtimeActions} from '../actions';
import {realtimeSelector} from '../selectors';

export const localizedRealtimeNotification = defineMessages({
    realtimeNotificationTitle: {
        id: 'realtimeNotificationTitle',
        defaultMessage: 'Updated modules',
    },
    realtimeNotificationButton: {
        id: 'realtimeNotificationButton',
        defaultMessage: 'Update',
    },
    realtimeNotificationMessage: {
        id: 'realtimeNotificationMessage',
        defaultMessage: '{updatedTitles} have been updated. Please refresh to see the update info.',
    },
});

export function RealtimeNotification() {
    const dispatch = useDispatch();
    const realtimeState = useSelector(realtimeSelector);
    const {formatMessage} = useIntl();
    const updatedViews = realtimeState?.updates ? Object.keys(realtimeState.updates) : [];
    const updatedTitles = updatedViews?.join(', ');

    const reload = () => {
        dispatch(realtimeActions.updateViews());
    };

    const close = () => {
        dispatch(realtimeActions.cleanUpdatedViews());
    };

    return (
        <Snackbar
            open={updatedTitles?.length > 0}
            message={formatMessage(localizedRealtimeNotification.realtimeNotificationMessage, {updatedTitles})}
            messageIcon={<SnackbarIconUpdate />}
            onAction={reload}
            action={formatMessage(localizedRealtimeNotification.realtimeNotificationButton)}
            onClose={close}
        />
    );
}
