import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import CountryFlag from '@components/data-visualize/CountryFlag';
import {withEmptyCheck} from '@components/label/EmptyLabel';

export type DisplayCountriesProps = {
    value: string[];
};

export type CountryProps = {
    code: string;
    className: string;
};

export const useClasses = makeStyles()(theme => ({
    displayCountries: {
        display: 'flex',
        flexFlow: 'wrap',
    },
    displayCountry: {
        minWidth: theme.spacing(7),
    },
}));

const DisplayCountry = ({code, className}: CountryProps) => {
    return (
        <Box className={className}>
            <CountryFlag value={{iso_alpha2_code: code, name: code}} />
        </Box>
    );
};

export const DisplayCountries = withEmptyCheck(({value}: DisplayCountriesProps) => {
    const {classes} = useClasses();
    return (
        <Box className={classes.displayCountries}>
            {value.map((val, index) => (
                <DisplayCountry key={index} code={val} className={classes.displayCountry} />
            ))}
        </Box>
    );
});
