import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {Filter as FilterKeyValue} from 'src/common/types';
import {useJurisdictionFeature} from '../../app/config/hooks';
import {ModuleAction} from '../../shared/actions/types';
import {Filter, FilterPlacement, ModuleTool} from '../../shared/filter/types';

const useClasses = makeStyles()(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    toolbarActions: {
        marginBottom: 'auto',
    },
    toolbarItemsContainer: {
        display: 'flex',
        columnGap: theme.spacing(1.25),
    },
    toolbarItemsContainerPrimary: {
        width: '100%',
    },
    toolbarItems: {
        display: 'flex',
        columnGap: theme.spacing(1.25),
    },
    toolbarItemsSecondary: {
        marginLeft: 'auto',
    },
    toolbarItemsStretched: {
        flex: 1,
    },
}));

const filterTools = <T extends ModuleTool>(actions: T[], placement: FilterPlacement) =>
    actions?.filter(
        f =>
            f !== null &&
            f.placement === placement &&
            useJurisdictionFeature({
                moduleName: f.moduleName,
                submoduleName: f.submoduleName,
                featureName: f.featureName,
                permissions: f.permissions,
            })
    ) ?? [];

const prepareActions = (actions: ModuleAction[], placement: FilterPlacement) => {
    const {classes} = useClasses();
    const filteredTools = filterTools(actions, placement);
    return filteredTools.map((action, index) => {
        const Component = action.component;
        return (
            <Box key={`action-${index}`} className={classes.toolbarActions}>
                <Component />
            </Box>
        );
    });
};

const prepareFilter = (
    domain: string,
    filters: Filter[],
    filterString: string,
    onFilterChange: (value: FilterKeyValue[]) => void,
    placement: FilterPlacement
) => {
    const filteredTools = filterTools(filters, placement);
    return filteredTools.map((filter, index) => {
        const FilterComponent = filter.component;
        return (
            <FilterComponent
                onFilterChange={onFilterChange}
                filterString={filterString}
                filter={filter}
                domain={domain}
                key={`${filter.key}${index}`}
            />
        );
    });
};

type ToolbarProps = {
    filters?: Filter[];
    actions?: ModuleAction[];
    filterString?: string;
    domain?: string;
    onFilterChange?: (value: FilterKeyValue[]) => void;
};

export const GridToolbar = ({filters, actions, filterString, onFilterChange, domain}: ToolbarProps) => {
    const {classes, cx} = useClasses();
    const toolsList: Partial<Record<FilterPlacement, JSX.Element[]>> = {};

    Object.keys(FilterPlacement).forEach(fp => {
        const key = fp as keyof typeof FilterPlacement;

        toolsList[key] = prepareFilter(domain, filters, filterString, onFilterChange, FilterPlacement[key]).concat(
            prepareActions(actions, FilterPlacement[key])
        );
    });

    return !filters && !actions ? null : (
        <Box className={classes.toolbar}>
            <Box className={cx(classes.toolbarItemsContainerPrimary, classes.toolbarItemsContainer)}>
                <Box className={cx(classes.toolbarItemsStretched, classes.toolbarItems)}>{toolsList[FilterPlacement.Primary]}</Box>
                <Box className={cx(classes.toolbarItemsSecondary, classes.toolbarItems)}>{toolsList[FilterPlacement.Secondary]}</Box>
            </Box>

            {toolsList[FilterPlacement.Advanced]?.length || toolsList[FilterPlacement.AdvancedSecondary]?.length ? (
                <Box className={classes.toolbarItemsContainer}>
                    <Box className={classes.toolbarItems}>{toolsList[FilterPlacement.Advanced]}</Box>
                    <Box className={cx(classes.toolbarItemsSecondary, classes.toolbarItems)}>
                        {toolsList[FilterPlacement.AdvancedSecondary]}
                    </Box>
                </Box>
            ) : null}
            {toolsList[FilterPlacement.Tertiary]?.length || toolsList[FilterPlacement.TertiarySecondary]?.length ? (
                <Box className={classes.toolbarItemsContainer}>
                    <Box className={classes.toolbarItems}>{toolsList[FilterPlacement.Tertiary]}</Box>
                    <Box className={cx(classes.toolbarItemsSecondary, classes.toolbarItems)}>
                        {toolsList[FilterPlacement.TertiarySecondary]}
                    </Box>
                </Box>
            ) : null}
        </Box>
    );
};
