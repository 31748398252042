import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {ErrorAlert} from '@components/alerts/InfoAlert';
import {gridMaxSize} from '@components/data-grid/mui';
import LocalizedText from '@components/i18n/LocalizedText';
import {EntityType} from '@redux/entity';

import {
    BulkActionKey,
    BulkActionSummaryDetailsInfo,
    BulkDetailsInfoExpanded,
    BulkExecutionStepTotalProgress,
    BulkStepExecutionProps,
    BulkStepSummaryContentProps,
    useExecutionStepResult,
    usePerformStrategy,
    withBulkStepExecutionSummary,
} from '../../block-bulk-actions';
import {useMttBonusGroupedByTicket} from '../hooks';
import {BonusTriggerViewModel, BonusTriggerViewModelKeys} from '../types';

import {DataGridBonusTriggerClient} from './DataGridBonusTrigger';

const localized = defineMessages({
    header: {
        id: 'bulkBonusExecutionStep_header',
        defaultMessage: 'Applying Changes',
    },
    confirm: {
        id: 'bulkBonusExecutionStep_confirm',
        defaultMessage: 'Confirm',
    },
    cancel: {
        id: 'bulkBonusExecutionStep_cancel',
        defaultMessage: 'Cancel',
    },
    triggerMttBonus: {
        id: 'bulkBonusExecutionStep_triggerMttBonus',
        defaultMessage: 'Trigger Mtt bonus: {bonus}',
    },
    triggerImmediateCashBonus: {
        id: 'bulkBonusExecutionStep_triggerImmediateCashBonus',
        defaultMessage: 'Trigger Immediate Cash bonus: {bonus}',
    },
    triggerDepositCashMatchBonus: {
        id: 'bulkBonusExecutionStep_triggerDepositCashMatchBonus',
        defaultMessage: 'Trigger Deposit Cash Match bonus: {bonus}',
    },
    operationFailed: {
        id: 'bulkBonusExecutionStep_OperationFailed',
        defaultMessage: 'Operation is failed',
    },
});

type BulkBonusesExecutionDetailsProps = BulkStepSummaryContentProps<BonusTriggerViewModel> & {
    label?: MessageDescriptor;
    columns?: BonusTriggerViewModelKeys[];
};

function BulkBonusesExecutionDetails({
    actionKey,
    isProcessingFinished,
    isOperationFailed,
    actionItems,
    entityType,
    label,
    columns,
}: BulkBonusesExecutionDetailsProps) {
    const {successfulItems, partiallySuccessfulItems, failedItems} = useExecutionStepResult<BonusTriggerViewModel>({
        actionItems,
        isOperationFailed,
    });

    return actionItems?.length > 0 ? (
        <BulkActionSummaryDetailsInfo
            key={`summaryDetails-${actionKey}`}
            expandable={true}
            actionKey={actionKey}
            actionLabel={<LocalizedText label={label} labelParams={{bonus: actionItems[0].value.bonus_name}} />}
            actionTotal={
                <BulkExecutionStepTotalProgress
                    totalItems={actionItems?.length}
                    processedItems={
                        isOperationFailed ? actionItems?.length : [...successfulItems, ...failedItems, ...partiallySuccessfulItems]?.length
                    }
                    failedItems={isOperationFailed ? actionItems?.length : [...failedItems, ...partiallySuccessfulItems].length}
                    type={entityType}
                />
            }
        >
            {isOperationFailed ? <ErrorAlert title={localized.operationFailed} /> : null}
            {isProcessingFinished && !isOperationFailed ? (
                <>
                    {failedItems?.length ? (
                        <BulkDetailsInfoExpanded status="failed" items={failedItems} size="small">
                            <DataGridBonusTriggerClient
                                rows={failedItems}
                                columns={columns}
                                rowsPerPageOptions={[gridMaxSize]}
                                hidePagination={failedItems.length < gridMaxSize}
                                autoHeight
                            />
                        </BulkDetailsInfoExpanded>
                    ) : null}
                    {partiallySuccessfulItems?.length ? (
                        <BulkDetailsInfoExpanded status="partialSuccess" items={partiallySuccessfulItems} size="small">
                            <DataGridBonusTriggerClient
                                rows={partiallySuccessfulItems}
                                columns={columns}
                                rowsPerPageOptions={[gridMaxSize]}
                                hidePagination={partiallySuccessfulItems.length < gridMaxSize}
                                autoHeight
                            />
                        </BulkDetailsInfoExpanded>
                    ) : null}
                    {successfulItems?.length ? (
                        <BulkDetailsInfoExpanded status="success" items={successfulItems} size="small">
                            <DataGridBonusTriggerClient
                                rows={successfulItems}
                                columns={columns}
                                rowsPerPageOptions={[gridMaxSize]}
                                hidePagination={successfulItems.length < gridMaxSize}
                                autoHeight
                            />
                        </BulkDetailsInfoExpanded>
                    ) : null}
                </>
            ) : null}
        </BulkActionSummaryDetailsInfo>
    ) : null;
}

function BulkMttBonusesExecutionDetails({
    actionKey,
    isProcessingFinished,
    isOperationFailed,
    actionItems,
    entityType,
}: BulkBonusesExecutionDetailsProps) {
    const {groupByTicket} = useMttBonusGroupedByTicket();
    const itemsGroupedByTicket = groupByTicket(actionItems);

    return itemsGroupedByTicket?.length > 0 ? (
        <BulkBonusesExecutionDetails
            actionItems={itemsGroupedByTicket}
            actionKey={actionKey}
            entityType={entityType}
            isOperationFailed={isOperationFailed}
            isProcessingFinished={isProcessingFinished}
            label={localized.triggerMttBonus}
            columns={['uid', 'marketing_code', 'ticket_count', 'triggered_tickets']}
        />
    ) : null;
}

function BulkImmediateCashBonusesExecutionDetails({
    actionKey,
    isProcessingFinished,
    isOperationFailed,
    actionItems,
    entityType,
}: BulkBonusesExecutionDetailsProps) {
    return actionItems?.length > 0 ? (
        <BulkBonusesExecutionDetails
            actionItems={actionItems}
            actionKey={actionKey}
            entityType={entityType}
            isOperationFailed={isOperationFailed}
            isProcessingFinished={isProcessingFinished}
            label={localized.triggerImmediateCashBonus}
            columns={['uid', 'marketing_code']}
        />
    ) : null;
}

function BulkDepositCashMatchBonusesExecutionDetails({
    actionKey,
    isProcessingFinished,
    isOperationFailed,
    actionItems,
    entityType,
}: BulkBonusesExecutionDetailsProps) {
    return actionItems?.length > 0 ? (
        <BulkBonusesExecutionDetails
            actionItems={actionItems}
            actionKey={actionKey}
            entityType={entityType}
            isOperationFailed={isOperationFailed}
            isProcessingFinished={isProcessingFinished}
            label={localized.triggerDepositCashMatchBonus}
            columns={['uid', 'marketing_code', 'total_amount']}
        />
    ) : null;
}

const BulkMttBonusesExecutionStep = withBulkStepExecutionSummary(BulkMttBonusesExecutionDetails);

const BulkImmediateCashBonusesExecutionStep = withBulkStepExecutionSummary(BulkImmediateCashBonusesExecutionDetails);

const BulkDepositCashMatchBonusesExecutionStep = withBulkStepExecutionSummary(BulkDepositCashMatchBonusesExecutionDetails);

export function BulkBonusMttExecutionStep({onNext, hasRetry}: BulkStepExecutionProps) {
    const strategy = usePerformStrategy('PerformTriggerMttBonusStrategy');

    return (
        <BulkMttBonusesExecutionStep
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.UserProfile}
            actionsData={[{type: 'operation', actionKey: BulkActionKey.ManualTriggerMTTTicket, strategy}]}
        />
    );
}

export function BulkBonusImmediateCashExecutionStep({onNext, hasRetry}: BulkStepExecutionProps) {
    const strategy = usePerformStrategy('PerformTriggerImmediateCashBonusStrategy');

    return (
        <BulkImmediateCashBonusesExecutionStep
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.UserProfile}
            actionsData={[{type: 'operation', actionKey: BulkActionKey.ManualTriggerImmediateCashBonus, strategy}]}
        />
    );
}

export function BulkBonusDepositCashMatchExecutionStep({onNext, hasRetry}: BulkStepExecutionProps) {
    const strategy = usePerformStrategy('PerformTriggerDepositCashMatchBonusStrategy');

    return (
        <BulkDepositCashMatchBonusesExecutionStep
            onNext={onNext}
            hasRetry={hasRetry}
            entityType={EntityType.UserProfile}
            actionsData={[{type: 'operation', actionKey: BulkActionKey.ManualTriggerDepositCashMatch, strategy}]}
        />
    );
}
