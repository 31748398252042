import React, {createRef, useContext} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import equal from 'fast-deep-equal/es6';

import {ModalButton, ModalContent, ModalContext, ModalStyleParams} from '@components/modal';

import {ErrorPopover, InfoPopover} from '../error/MessagePopover';

import {EditableProps, useClasses} from './EditableHoc';
import {useTempStorageUpdateState} from './hooks';

export type EditableModalProps<TModel, TValue, TSubmitValue, TOptions = unknown> = EditableProps<TValue, TOptions, TSubmitValue> & {
    model: TModel;
    title: JSX.Element;
    subInfoMessage?: string;
    submitLabel?: MessageDescriptor | string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    modalStyleParams?: ModalStyleParams;
    getSubmitValue: (updatedValue: TValue) => TSubmitValue;
};

const localized = defineMessages({
    modalDefaultSendButtonLabel: {
        id: 'modalDefaultSendButtonLabel',
        defaultMessage: 'Send',
    },
    modalDefaultCloseButtonLabel: {
        id: 'modalDefaultCloseButtonLabel',
        defaultMessage: 'Close',
    },
});

export const withEditableModal =
    <TModel, TValue, TSubmitValue, TOptions>(
        WrappedComponent: React.ComponentType<EditableModalProps<TModel, TValue, TSubmitValue, TOptions>>,
        WrappedComponentEditModal: React.ComponentType<EditableModalProps<TModel, TValue, TSubmitValue, TOptions>>,
        removeWrappedComponentSpacing?: boolean
    ) =>
    ({
        id,
        value,
        typeName,
        field,
        action,
        messageType,
        disabled,
        onChange,
        title,
        submitLabel,
        getSubmitValue,
        modalStyleParams,
        subInfoMessage,
        ...otherParams
    }: EditableModalProps<TModel, TValue, TSubmitValue, TOptions>) => {
        const {classes} = useClasses();

        const boxRef = createRef<HTMLButtonElement>();
        const {closeModal, openModal} = useContext(ModalContext);
        const {storageKey, update} = useTempStorageUpdateState(action, field, id, typeName, messageType);
        const form = useForm<unknown>({defaultValues: value});

        const open = (content: ModalContent) => {
            form.reset(value);
            openModal(content);
        };

        const save = (data: any) => {
            const isValueUpdated = !equal(data, value);
            if (isValueUpdated) {
                update(getSubmitValue(data) as TSubmitValue);
            }
            closeModal();
            form.reset(value);
        };

        const cancel = () => {
            closeModal();
            form.reset(value);
        };

        const modalActions: ModalButton[] = [
            {
                id: 'submitButton',
                label: submitLabel ?? localized.modalDefaultSendButtonLabel,
                onClick: form.handleSubmit(save),
                isPrimary: true,
            },
            {
                id: 'cancelButton',
                label: localized.modalDefaultCloseButtonLabel,
                onClick: () => cancel(),
                isPrimary: false,
            },
        ];

        const modalContent = (
            <FormProvider {...form}>
                <form name={`${typeName}.${field}.${id}`}>
                    <WrappedComponentEditModal
                        {...otherParams}
                        value={value}
                        disabled={disabled}
                        onChange={onChange}
                        field={field}
                        id={id}
                        typeName={typeName}
                        action={action}
                        messageType={messageType}
                        title={title}
                        getSubmitValue={getSubmitValue}
                    />
                </form>
            </FormProvider>
        );

        return (
            <>
                <Box
                    ref={boxRef}
                    className={removeWrappedComponentSpacing ? classes.editCellContainerWithoutSpacing : classes.editCellContainer}
                >
                    <Box className={classes.editCellControl}>
                        {
                            <WrappedComponent
                                data-testid={field}
                                value={value}
                                disabled={disabled}
                                onChange={onChange}
                                field={field}
                                id={id}
                                typeName={typeName}
                                action={action}
                                messageType={messageType}
                                title={title}
                                onClick={() =>
                                    open({
                                        body: modalContent,
                                        title,
                                        buttons: modalActions,
                                        subInfoMessage,
                                        styleParams: modalStyleParams,
                                    })
                                }
                                getSubmitValue={getSubmitValue}
                                {...otherParams}
                            />
                        }
                    </Box>
                </Box>
                <ErrorPopover ref={boxRef} messageKey={storageKey}></ErrorPopover>
                <InfoPopover ref={boxRef} messageKey={storageKey}></InfoPopover>
            </>
        );
    };
