import {RootState} from 'typesafe-actions';

import {IModuleGridItem} from '@components/data-grid';
import {getSortString, toSearchFilter} from '@utils';

import {Filter, SearchFilter} from 'src/common/types';
import {Filter as FilterKeyValue} from 'src/common/types';
import {locationSearchSelector} from '../../app/routing/selectors';

import {ClientFilter, ClientFilterType} from './types';

export const getConcatFilter = (prefix: string, rangePostfix: string) => {
    return `${prefix}.${rangePostfix}`;
};

export const getFilterValueFromKey = <TKey extends string>(key: TKey, value: FilterKeyValue<string>[]) => {
    return value?.find(v => v.key === key)?.value;
};

export const getFilterUtils = (updateSearchUrl: boolean, filterSelector: (state: RootState) => string) => {
    const selectSearchFilterString = (state: RootState) => filterSelector(state);

    const selectSearchFilter = (state: RootState, defaultFilterString = ''): SearchFilter => {
        const searchFilter = toSearchFilter(selectSearchFilterString(state));
        const defaultFilter = toSearchFilter(defaultFilterString);
        const updateDefaultFilter = defaultFilter.filter.filter(i => !searchFilter.filter.map(f => f.key).includes(i.key));
        searchFilter.filter = [...updateDefaultFilter, ...searchFilter.filter];

        return searchFilter;
    };

    const selectInitialSearchFilterString = (state: RootState): string => {
        const stateFilter = selectSearchFilterString(state);
        const urlFilter = locationSearchSelector(state);

        const updatedFilter =
            updateSearchUrl && urlFilter?.length ? getSortString(urlFilter, toSearchFilter(stateFilter).sorting) : stateFilter;

        return updatedFilter;
    };

    return {selectSearchFilterString, selectSearchFilter, selectInitialSearchFilterString};
};

export const getDateRangeFilterKey = (prefix: string, rangePostfix: string) => {
    return `${prefix}.${rangePostfix}`;
};

const filterMultiselectClient = <TModel extends IModuleGridItem>(
    data: TModel[],
    filter: Filter,
    fieldSelector: (item: TModel) => unknown
) => {
    return data.filter(m => !filter?.value?.length || filter.value.includes(fieldSelector(m)));
};

const filterClientMethodMap = {[ClientFilterType.Multiselect]: filterMultiselectClient};

const filterFieldClient = <TModel extends IModuleGridItem>(data: TModel[], filterString: string, clientFilter: ClientFilter<TModel>) => {
    const searchFilter = toSearchFilter(filterString);
    const filter = searchFilter.filter.find(f => f.key === clientFilter.filterKey);

    return filterClientMethodMap[clientFilter.filterType](data, filter, clientFilter.fieldSelector);
};

export const filterClient = <TModel extends IModuleGridItem>(
    data: TModel[],
    filterString: string,
    clientFilters: ClientFilter<TModel>[]
) => {
    let filteredData = data;
    clientFilters.forEach(clientFilter => (filteredData = filterFieldClient(filteredData, filterString, clientFilter)));

    return filteredData;
};
