import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useAutocompleteClasses = makeStyles()((theme: CustomTheme) => ({
    autocompleteCircularProgress: {
        paddingRight: theme.spacing(1),
    },
    autocompleteOptionSuggestedItem: {
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        borderTop: `1px solid ${theme.palette.secondary.light}`,
        height: 'inherit',
    },
    autocompleteOptionItem: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    autocompletePaper: {
        padding: 0,
        margin: 0,
        borderTop: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        marginTop: '1px',
        boxSizing: 'border-box',
    },
    autocompleteSelected: {
        color: theme.palette.primary.main,
    },
    autocompleteContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    autocompleteOptionLarge: {
        height: 'auto !important',
    },
    autocompletePopperLarge: {
        '& .MuiPaper-root': {
            minWidth: `${theme.spacing(40)} !important`,
        },
    },
    autocompleteStartAdornment: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(0.3),
        maxWidth: theme.spacing(1.5),
    },
    autocompleteOptionContent: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    autocompleteViewList: {
        overflow: 'auto',
        margin: `${theme.spacing(0, -1.5)} !important`,
        maxHeight: '300px',
    },
    autocompleteTreeViewList: {
        height: '230px',
    },
    autocompleteInput: {
        '& .MuiInputBase-root': {
            paddingRight: theme.spacing(0),
        },
        '& .MuiInputBase-input': {
            paddingLeft: theme.spacing(1.25),
        },
    },
    autocompleteInputAdornment: {
        color: theme.palette.secondary.main,
        fontSize: 12,
        marginLeft: theme.spacing(-0.25),
    },
    autocompleteEmptyPlaceholderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(3.75),
        },
    },
    autocompleteEmptyPlaceholder: {
        maxWidth: '220px',
        textAlign: 'center',
    },
}));
