import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload, GameBlindFilterKeys, ManagedGameType} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {Plo5GameManagementApiService} from '@services/rest-api';
import {getValueFromQueryFilter} from '@utils/query';

import {ServiceTypes} from '../inversify';

import {BlindConfigServiceResponsePayload, IGameManagementApiService} from './rest-api/plo5GameManagementApiService';
import {ServerResponseStatus} from './types';

@injectable()
export class GameBlindService implements IEntityReadService {
    private readonly _apiServiceMapper: Record<ManagedGameType, IGameManagementApiService>;

    constructor(@inject(ServiceTypes.Plo5GameManagementApiService) plo5GameManagementApiService: Plo5GameManagementApiService) {
        this._apiServiceMapper = {
            [ManagedGameType.PLO5]: plo5GameManagementApiService,
        };
    }

    public get(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        const gameType: ManagedGameType = getValueFromQueryFilter<GameBlindFilterKeys, ManagedGameType>(requestPayload.filter, 'gameType');
        const apiService: IGameManagementApiService = this.getGameManagementApiService(gameType);
        return apiService
            ? apiService.getBlindConfig().pipe(
                  map((r: BlindConfigServiceResponsePayload) => ({
                      ...r,
                      requestPayload,
                      responsePayload: {items: r.responsePayload?.data?.blind_list, total: r.responsePayload?.data?.blind_list?.length},
                  }))
              )
            : of({
                  status: ServerResponseStatus.Success,
                  requestPayload,
                  responsePayload: {items: [], total: 0},
              });
    }

    private getGameManagementApiService(gameType: ManagedGameType): IGameManagementApiService {
        return this._apiServiceMapper[gameType];
    }
}
