import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {LocalizedMessageData} from 'src/common/types';
import {updateKeyValueState, updateKeyValueStateBatch} from '../module-shared/reducers';
import {KeyValueStorageData} from '../module-shared/types';

import actions from './actions';

const inProgressReducer = createReducer({} as KeyValueStorageData<boolean>)
    .handleAction(actions.changeInProgressState, (state, action) => updateKeyValueState(state, action.payload.key, action.payload.value))
    .handleAction(actions.changeInProgressStateBatch, (state, action) => updateKeyValueStateBatch(state, action.payload));

const errorReducer = createReducer({} as KeyValueStorageData<LocalizedMessageData[]>).handleAction(
    actions.changeErrorState,
    (state, action) => updateKeyValueState(state, action.payload.key, action.payload.value)
);

const infoReducer = createReducer({} as KeyValueStorageData<LocalizedMessageData[]>).handleAction(
    actions.changeInfoState,
    (state, action) => updateKeyValueState(state, action.payload.key, action.payload.value)
);

const editReducer = createReducer({} as KeyValueStorageData<boolean>).handleAction(actions.changeEditState, (state, action) =>
    updateKeyValueState(state, action.payload.key, action.payload.value)
);

const loadingReducer = createReducer({} as KeyValueStorageData<boolean>).handleAction(actions.changeLoadingState, (state, action) =>
    updateKeyValueState(state, action.payload.key, action.payload.value)
);

const dataReducer = createReducer({} as KeyValueStorageData<any>).handleAction(actions.changeLoadingData, (state, action) =>
    updateKeyValueState(state, action.payload.key, action.payload.value)
);

const combined = combineReducers({
    inProgress: inProgressReducer,
    error: errorReducer,
    info: infoReducer,
    edit: editReducer,
    loading: loadingReducer,
    data: dataReducer,
});

export default combined;
