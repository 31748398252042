export function humanFileType(name: string): string {
    return name?.split('.').pop().toLocaleUpperCase();
}

export function getMarkdownMentions(markdown: string, type: 'text' | 'value' = 'value'): string[] {
    const regexMarkdownMentionLink = /\[(@[^[]+)\]\((.*)\)/;
    const regexMarkdownMentionLinks = /\[(@[^[]+)\](\(.*?\))/g;

    const matches = markdown.match(regexMarkdownMentionLinks);
    const len = matches?.length || 0;

    const mentions = [];

    for (let i = 0; i < len; i++) {
        const matched = regexMarkdownMentionLink.exec(matches[i]);
        const val = type === 'text' ? matched[1] : matched[2];
        mentions.push(val);
    }

    return mentions;
}
