import {RootEpic} from '@redux';

import {requestEpic} from 'src/common/epics';
import {protectEpics} from '../app/error-handling/epics';

import {loadModulesAction} from './actions';

const loadModulesEpic: RootEpic = requestEpic(loadModulesAction, (_payload, _state, {moduleService}) => moduleService.getItems());

const modulesEpics = protectEpics(loadModulesEpic);

export default modulesEpics;
