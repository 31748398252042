import React, {HTMLAttributes} from 'react';
import {Box, Typography} from '@mui/material';

import {useAutocompleteClasses} from './Autocomplete.styles';
import {AutocompleteOptionType} from './types';

type AutocompleteOptionProps = {
    option: string | AutocompleteOptionType;
    inputValue: string;
};

export const AutocompleteOption = ({option, inputValue}: AutocompleteOptionProps) => {
    const {classes: styles} = useAutocompleteClasses();
    const optionLabel = (option as AutocompleteOptionType)?.label;
    const optionSubLabel = (option as AutocompleteOptionType)?.subLabel;
    const isExactMatch = typeof option === 'string' ? inputValue === option : inputValue === optionLabel;

    if ((option as AutocompleteOptionType)?.isSuggested) {
        return (
            <Box className={styles.autocompleteOptionSuggestedItem} data-testid={`${optionLabel}_autocompleteOption`}>
                {optionLabel}
            </Box>
        );
    }
    return (
        <Box className={styles.autocompleteOptionItem}>
            <Box className={isExactMatch ? styles.autocompleteSelected : ''}>
                {typeof option === 'string' ? (
                    <Box data-testid={`${option}_autocompleteOption`}>{option}</Box>
                ) : (
                    <Box className={styles.autocompleteOptionContent}>
                        {option.startIcon ?? null}
                        <Box data-testid={`${optionLabel}_autocompleteOption`}>
                            <Typography variant="body1">{optionLabel}</Typography>
                            {optionSubLabel ? (
                                <Typography variant="subtitle2" color="textSecondary">
                                    {optionSubLabel}
                                </Typography>
                            ) : null}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: string | AutocompleteOptionType, inputValue: string) => {
    return (
        <li {...props}>
            <AutocompleteOption option={option} inputValue={inputValue} />
        </li>
    );
};
