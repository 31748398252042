import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {Icon, IconProps} from '@components/icons/Icon';

const useStyles = makeStyles()(theme => ({
    toolbarIcon: {
        fontSize: theme.spacing(2.5),
    },
}));

export function ToolbarIcon({className, ...otherProps}: IconProps) {
    const {classes, cx} = useStyles();
    return <Icon className={cx(classes.toolbarIcon, className)} {...otherProps} />;
}
