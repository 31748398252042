import {MessageDescriptor} from 'react-intl';
import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {errorMessages, getErrorById} from '../app/intl/shared-resources/serverResponse';

import {clearMessageAction, handleErrorResponseAction, showErrorAction, showMessageAction} from './actions';

const messageReducer = createReducer(null as MessageDescriptor)
    .handleAction([showMessageAction, showErrorAction], (state, action) => action.payload?.message ?? state)
    .handleAction(handleErrorResponseAction, (_, action) => getErrorById(null, action.payload?.code, errorMessages.defaultError))
    .handleAction(clearMessageAction, () => null);

const messageValuesReducer = createReducer(null as Record<string, string>)
    .handleAction([showMessageAction, showErrorAction], (state, action) => action.payload?.values ?? state)
    .handleAction(clearMessageAction, () => null);

const isErrorReducer = createReducer(false)
    .handleAction([handleErrorResponseAction, showErrorAction], () => true)
    .handleAction(showMessageAction, () => false);

export const messageSnackBarReducer = combineReducers({
    message: messageReducer,
    values: messageValuesReducer,
    isError: isErrorReducer,
});
