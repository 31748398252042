import React, {useState} from 'react';
import {makeStyles} from 'tss-react/mui';

import {SelectButtonWithChipProps} from '@components/button/Buttons';
import {CustomTheme} from '@style';

import {SelectButtonWithChipDropdown} from '../dropdown/Dropdown';
import {DropdownProps, DropdownStyles} from '../dropdown/DropdownHoc';

//TODO: [BO-2668] Move to src/common/components/dropdown (rename or merge?)
//TODO: [BO-2669] Move dropdown components to input folder (?)
const useClasses = makeStyles()((theme: CustomTheme) => ({
    selectButton: {
        height: theme.custom.denseButtonHeight,
    },
    selectButtonMobileFullWidth: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

type SelectButtonProps = Omit<SelectButtonWithChipProps, 'open'> & {
    styles?: DropdownStyles;
    startIcon?: React.ReactNode;
    isMobileFullWidth?: boolean;
};

export const SelectButton = ({
    label,
    selectedText,
    styles,
    closeOnClickInside,
    children,
    isMobileFullWidth = true,
    ...props
}: SelectButtonProps & DropdownProps) => {
    const [open, setOpen] = useState(false);
    const {classes} = useClasses();
    const handleDropdownOpen = () => {
        setOpen(true);
    };

    const handleDropdownClose = () => {
        setOpen(false);
    };

    return (
        <SelectButtonWithChipDropdown
            {...props}
            label={label}
            selectedText={selectedText}
            open={open}
            onDropdownOpen={handleDropdownOpen}
            onDropdownClose={handleDropdownClose}
            closeOnClickInside={closeOnClickInside ?? false}
            styles={{
                dropdownButton: `${classes.selectButton} ${isMobileFullWidth ? classes.selectButtonMobileFullWidth : ''} ${
                    styles?.dropdownButton
                }`,
                ...styles,
            }}
        >
            {children}
        </SelectButtonWithChipDropdown>
    );
};
