import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {Icon, IconColor} from '@components/icons';
import {CustomIcon} from '@components/icons';
import {CustomTheme} from '@style';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    snackbarIconList: {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        width: '2em',
        height: '2em',
        fontSize: '0.75em',
        lineHeight: '2em !important',
        textAlign: 'center',
        borderRadius: theme.shape.borderRadius * 1.5,
    },
}));

export function SnackbarIconUpdate() {
    return <Icon icon={CustomIcon.Union} color={IconColor.Primary} fontSize="large" />;
}

export function SnackbarIconWarning() {
    return <Icon icon={CustomIcon.WarningOutline} color={IconColor.Primary} fontSize="large" />;
}

export function SnackbarIconList() {
    const {classes} = useStyles();
    return <Icon icon={CustomIcon.CheckList} color={IconColor.PrimaryContrastText} fontSize="large" className={classes.snackbarIconList} />;
}

export function SnackbarIconSuccess() {
    return <Icon icon={CustomIcon.CheckDouble} color={IconColor.Primary} fontSize="large" />;
}
