import {RootEpic} from '@redux';

import {requestEpic} from 'src/common/epics';
import {protectEpics} from '../app/error-handling/epics';

import actions from './actions';
import {LegalDocType} from './types';

const loadAcceptedPrivacyPolicyEpic: RootEpic = requestEpic(actions.loadAcceptedPrivacyPolicy, (payload, _, container) => {
    return container.legalDocsService.loadLegalDocument(payload, LegalDocType.PrivacyPolicy);
});

const loadAcceptedTermsEpic: RootEpic = requestEpic(actions.loadAcceptedTerms, (payload, _, container) => {
    return container.legalDocsService.loadLegalDocument(payload, LegalDocType.TermsAndConditions);
});

export default protectEpics(loadAcceptedPrivacyPolicyEpic, loadAcceptedTermsEpic);
