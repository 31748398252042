import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import StyledAccordion from '@components/StyledAccordion';
import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    layoutDetailsActionsContainer: {
        minWidth: 'auto',
    },
    layoutDetailsActions: {
        display: 'flex',
        minWidth: 'auto',
        flexDirection: 'column',
        paddingBottom: theme.spacing(2.5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        rowGap: theme.spacing(1.25),
        backgroundColor: theme.palette.background.paper,
    },
    layoutDetailsActionsCollapsibleHeader: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(3),
        marginRight: theme.spacing(-4),
    },
    layoutDetailsActionsTitle: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(2),
    },
}));

type LayoutDetailsActionsCollapsibleProps = {
    children: React.ReactNode;
    header: React.ReactNode;
};

export function LayoutDetailsActionsCollapsible({children, header}: LayoutDetailsActionsCollapsibleProps) {
    const {classes} = useClasses();

    return (
        <StyledAccordion
            className={classes.layoutDetailsActionsContainer}
            header={<div className={classes.layoutDetailsActionsCollapsibleHeader}>{header}</div>}
            componentId="layoutDetailsActions"
            unmountOnCollapse
        >
            <Box className={classes.layoutDetailsActions}>{children}</Box>
        </StyledAccordion>
    );
}

type LayoutDetailsActionsProps = {
    title: string | MessageDescriptor;
    children: React.ReactNode;
};

export function LayoutDetailsActions({title, children}: LayoutDetailsActionsProps) {
    const {classes} = useClasses();

    return (
        <div className={classes.layoutDetailsActions}>
            <Typography variant="h6" className={classes.layoutDetailsActionsTitle}>
                <LocalizedText label={title} />
            </Typography>
            {children}
        </div>
    );
}
