import {constructUsing, createMap, forMember, mapFrom, Mapper, mapWith, mapWithArguments} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {GameTemplate, GameTemplateFields} from '@redux/entity';

import {
    defaultGameMode,
    defaultGamePlatform,
    defaultPlayersMaxLimit,
    defaultTimeLimit,
    GameCurrency,
    GameTemplateMappingArgs,
    GameTemplateResponse,
    GameTemplateSettings,
    GameTemplateSettingsViewModel,
    GameTemplateViewModel,
    GameTemplateViewModelKeys,
    GameTemplateViewModelMappingArgs,
    StakesViewModel,
} from './types';

export class GameTemplateMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            GameTemplateResponse,
            GameTemplate,
            forMember(
                vm => vm.id,
                mapFrom(m => m?.new_template_Id)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m?.template?.param?.name)
            ),
            forMember(
                vm => vm.auto_buyin_val,
                mapFrom(m => m?.template?.param?.auto_buyin_val ?? 0)
            ),
            forMember(
                vm => vm.auto_start_num,
                mapFrom(m => m?.template?.param?.auto_start_num ?? 0)
            ),
            forMember(
                vm => vm.auto_withdraw,
                mapFrom(m => m?.template?.param?.auto_withdraw ?? false)
            ),
            forMember(
                vm => vm.currencyType,
                mapFrom(m => m?.template?.param?.currencyType ?? GameCurrency.USD)
            ),
            forMember(
                vm => vm.player_count_max,
                mapFrom(m => m?.template?.param?.player_count_max ?? 0)
            ),
            forMember(
                vm => vm.rule_ante_amount,
                mapFrom(m => m?.template?.param?.rule_ante_amount ?? 0)
            ),
            forMember(
                vm => vm.labels,
                mapFrom(m => m?.template?.param?.plats)
            ),
            forMember(
                vm => vm.auto_buyout_val,
                mapFrom(m => m?.template?.param?.auto_buyout_val)
            ),
            forMember(
                vm => vm.updated_at,
                mapFrom(m => m?.template?.updated_at)
            ),
            forMember(
                vm => vm.force_showcard,
                mapFrom(m => m?.template?.param?.force_showcard ?? false)
            ),
            forMember(
                vm => vm.is_ban_vpn,
                mapFrom(m => m?.template?.param?.is_ban_vpn ?? false)
            ),
            forMember(
                vm => vm.openRoomLimit,
                mapFrom(m => m?.template?.param?.openRoomLimit ?? false)
            ),
            forMember(
                vm => vm.limitnums,
                mapFrom(m => m?.template?.param?.limitnums)
            ),
            forMember(
                vm => vm.rule_blind_enum,
                mapFrom(m => m?.template?.param?.rule_blind_enum ?? 0)
            ),
            forMember(
                vm => vm.rule_buyin_fold,
                mapFrom(m => m?.template?.param?.rule_buyin_fold ?? 0)
            ),
            forMember(
                vm => vm.rule_buyin_min_enum,
                mapFrom(m => m?.template?.param?.rule_buyin_min_enum ?? 0)
            ),
            forMember(
                vm => vm.rule_switch_anti_cheat,
                mapFrom(m => m?.template?.param?.rule_switch_anti_cheat ?? 0)
            ),
            forMember(
                vm => vm.big_blind,
                mapWithArguments((m, {blinds}: GameTemplateMappingArgs) => {
                    const blindId = m?.template?.param?.rule_blind_enum ?? 0;
                    return blinds?.find(b => b?.id === blindId)?.bb ?? null;
                })
            ),
            forMember(
                vm => vm.small_blind,
                mapWithArguments((m, {blinds}: GameTemplateMappingArgs) => {
                    const blindId = m?.template?.param?.rule_blind_enum ?? 0;
                    return blinds?.find(b => b?.id === blindId)?.sb ?? null;
                })
            ),
            forMember(
                vm => vm.straddle,
                mapWithArguments((m, {blinds}: GameTemplateMappingArgs) => {
                    const blindId = m?.template?.param?.rule_blind_enum ?? 0;
                    const bb = blinds?.find(b => b.id === blindId)?.bb;
                    return bb ? bb * 2 : null;
                })
            ),
            forMember(
                vm => vm.rule_switch_force_straddle,
                mapFrom(m => m?.template?.param?.rule_switch_force_straddle ?? 0)
            ),
            forMember(
                vm => vm.is_enabled,
                mapWithArguments((_, {isEnabled}: GameTemplateMappingArgs) => isEnabled)
            ),
            forMember(
                vm => vm.players,
                mapWithArguments((_, {players}: GameTemplateMappingArgs) => players)
            ),
            forMember(
                vm => vm.tables,
                mapWithArguments((_, {rooms}: GameTemplateMappingArgs) => rooms)
            ),
            forMember(
                d => d.gameType,
                mapWithArguments((_, {gameType}: GameTemplateMappingArgs) => gameType)
            )
        );
        createMap(
            mapper,
            GameTemplate,
            GameTemplateViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id?.toString())
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.is_enabled,
                mapFrom(m => m.is_enabled)
            ),
            forMember(
                vm => vm.labels,
                mapFrom(m => m.labels)
            ),
            forMember(
                vm => vm.stakes,
                mapWith(StakesViewModel, GameTemplate, m => m)
            ),
            forMember(
                vm => vm.players,
                mapFrom(m => `${m?.players?.length ?? 0}/${m?.player_count_max * (m.tables?.length ?? 0)}`)
            ),
            forMember(
                vm => vm.tables,
                mapFrom(m => m.tables)
            ),
            forMember(
                vm => vm.updated_at,
                mapFrom(m => m.updated_at)
            ),
            forMember(
                d => d.gameType,
                mapFrom(s => s.gameType)
            )
        );
        createMap(
            mapper,
            GameTemplate,
            StakesViewModel,
            forMember(
                d => d.sb,
                mapFrom(s => (s.small_blind ? s.small_blind / 100 : null))
            ),
            forMember(
                d => d.bb,
                mapFrom(s => (s.big_blind ? s.big_blind / 100 : null))
            ),
            forMember(
                d => d.hasStraddle,
                mapFrom(s => !!s.rule_switch_force_straddle)
            ),
            forMember(
                d => d.ante,
                mapFrom(s => (s.rule_ante_amount ? s.rule_ante_amount / 100 : null))
            ),
            forMember(
                d => d.currency,
                mapWithArguments((_, {currency}: GameTemplateViewModelMappingArgs) => currency)
            )
        );
        createMap(
            mapper,
            GameTemplate,
            GameTemplateSettingsViewModel,
            forMember(
                d => d.minPlayerCount,
                mapFrom(s => s.auto_start_num)
            ),
            forMember(
                d => d.autoWithdraw,
                mapFrom(s => s.auto_withdraw)
            ),
            forMember(
                d => d.currencyType,
                mapFrom(s => s.currencyType)
            ),
            forMember(
                d => d.forceShowCard,
                mapFrom(s => s.force_showcard)
            ),
            forMember(
                d => d.vpnRestriction,
                mapFrom(s => s.is_ban_vpn)
            ),
            forMember(
                d => d.name,
                mapFrom(s => s.name)
            ),
            forMember(
                d => d.newMemberRestriction,
                mapFrom(s => s.openRoomLimit)
            ),
            forMember(
                d => d.newPlayerCountSitTogether,
                mapFrom(s => s.limitnums?.[0]?.num)
            ),
            forMember(
                d => d.playerCountMax,
                mapFrom(s => s.player_count_max)
            ),
            forMember(
                d => d.ante,
                mapFrom(s => (s.rule_ante_amount ? s.rule_ante_amount / 100 : null))
            ),
            forMember(
                d => d.blindId,
                mapFrom(s => s.rule_blind_enum)
            ),
            forMember(
                d => d.maxBuyIn,
                mapFrom(s => s.rule_buyin_fold)
            ),
            forMember(
                d => d.minBuyIn,
                mapFrom(s => s.rule_buyin_min_enum)
            ),
            forMember(
                d => d.gpsRestriction,
                mapFrom(s => Boolean(s.rule_switch_anti_cheat))
            ),
            forMember(
                d => d.forceStraddle,
                mapFrom(s => Boolean(s.rule_switch_force_straddle))
            ),
            forMember(
                d => d.autoBuyout,
                mapFrom(s => s.auto_buyout_val)
            )
        );
        createMap(
            mapper,
            GameTemplateSettingsViewModel,
            GameTemplateSettings,
            forMember(
                d => d.auto_buyout_val,
                mapFrom(s => (s.autoWithdraw && s.autoBuyout ? Number(s.autoBuyout) : null))
            ),
            forMember(
                d => d.auto_start_num,
                mapFrom(s => s.minPlayerCount)
            ),
            forMember(
                d => d.auto_withdraw,
                mapFrom(s => s.autoWithdraw)
            ),
            forMember(
                d => d.currencyType,
                mapFrom(s => s.currencyType)
            ),
            forMember(
                d => d.game_mode,
                mapFrom(() => defaultGameMode)
            ),
            forMember(
                d => d.force_showcard,
                mapFrom(s => s.forceShowCard)
            ),
            forMember(
                d => d.is_ban_vpn,
                mapFrom(s => s.vpnRestriction)
            ),
            forMember(
                d => d.name,
                mapFrom(s => s.name)
            ),
            forMember(
                d => d.openRoomLimit,
                mapFrom(s => s.newMemberRestriction)
            ),
            forMember(
                d => d.plats,
                mapFrom(() => [defaultGamePlatform])
            ),
            forMember(
                d => d.limitnums,
                mapFrom(s =>
                    s.newMemberRestriction
                        ? [
                              {
                                  max: defaultPlayersMaxLimit,
                                  num: s.newPlayerCountSitTogether,
                              },
                          ]
                        : null
                )
            ),
            forMember(
                d => d.player_count_max,
                mapFrom(s => s.playerCountMax)
            ),
            forMember(
                d => d.rule_ante_amount,
                mapFrom(s => (s.ante ? s.ante * 100 : null))
            ),
            forMember(
                d => d.rule_blind_enum,
                mapFrom(s => Number(s.blindId))
            ),
            forMember(
                d => d.rule_buyin_fold,
                mapFrom(s => Number(s.maxBuyIn))
            ),
            forMember(
                d => d.rule_buyin_min_enum,
                mapFrom(s => Number(s.minBuyIn))
            ),
            forMember(
                d => d.rule_switch_anti_cheat,
                mapFrom(s => (s.gpsRestriction ? 1 : 0))
            ),
            forMember(
                d => d.rule_switch_force_straddle,
                mapFrom(s => (s.forceStraddle ? 1 : 0))
            ),
            forMember(
                d => d.rule_time_limit,
                mapFrom(() => defaultTimeLimit)
            )
        );
        createMap<GameTemplateViewModelKeys[], GameTemplateFields[]>(
            mapper,
            nameof<GameTemplateViewModelKeys>(),
            nameof<GameTemplateFields>(),
            constructUsing((m, _) => {
                const mapper: Record<GameTemplateViewModelKeys, GameTemplateFields[]> = {
                    id: ['id'],
                    name: ['name'],
                    is_enabled: ['is_enabled'],
                    labels: ['labels'],
                    players: ['players'],
                    tables: ['tables'],
                    updated_at: ['updated_at'],
                    gameType: ['gameType'],
                    stakes: ['rule_blind_enum'],
                    modify: ['id'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
    }
}
