import React from 'react';

import {BaseFiltersProps, KeyValueFilter, QueryFilterAdapterModel, QueryFilterAdapterProps} from '@components/filter/types';

type QueryFilterProps<TFilterKey extends string, TFilterName extends string> = BaseFiltersProps<
    string,
    TFilterName,
    KeyValueFilter<TFilterKey>[]
>;

export function withQueryFilter<TFilterKey extends string, TFilterName extends string, TTextFilterKey extends TFilterKey, TProps = {}>(
    WrappedComponent: React.FC<QueryFilterAdapterProps<TFilterKey, TFilterName, TTextFilterKey> & TProps>,
    textFilterKeysConfig?: Partial<Record<TFilterName, TTextFilterKey[]>>
) {
    return function QueryFilterHoc({
        model,
        onChange,
        availableFilters,
        viewMode,
        ...props
    }: QueryFilterProps<TFilterKey, TFilterName> & TProps) {
        const searchObject = new URLSearchParams(model);
        const filterModel: QueryFilterAdapterModel<string, TTextFilterKey> = {};
        searchObject?.forEach((value, key) => {
            let parsedValue: unknown;
            try {
                parsedValue = JSON.parse(value);
            } catch (err) {
                parsedValue = value;
            }
            filterModel[key] = parsedValue;
        });

        const textFilterKey = Object.keys(textFilterKeysConfig).find((i: TFilterName) =>
            availableFilters?.map(f => (typeof f === 'string' ? f : f.filterName)).includes(i)
        ) as TFilterName;
        const textFilterKeys = textFilterKey ? textFilterKeysConfig?.[textFilterKey] : [];
        // If array of text keys has been passed, add text filter field
        const option = textFilterKeys?.find(k => filterModel?.[k]);
        if (option) {
            filterModel.text = option && filterModel?.[option] ? {option, text: filterModel?.[option] as string} : undefined;
        }

        function handleChange(newValue: QueryFilterAdapterModel<TFilterKey, TTextFilterKey>) {
            const keyValueFilter: KeyValueFilter<TFilterKey>[] = Object.entries(newValue)?.flatMap(([key, value]) => {
                let result: KeyValueFilter<TFilterKey>[];
                if (key === 'text') {
                    result = textFilterKeys?.map(k =>
                        value?.option === k ? {key: value?.option, value: value?.text} : {key: k, value: ''}
                    );
                } else {
                    result = [{key: key as TFilterKey, value: value}];
                }
                return result;
            });
            onChange(keyValueFilter);
        }

        return (
            <WrappedComponent
                model={filterModel}
                onChange={handleChange}
                availableFilters={availableFilters}
                viewMode={viewMode}
                {...(props as TProps)}
            />
        );
    };
}
