import {gql} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AccountVerification} from '@models/generated/graphql';

import {IRealtimeService} from '../../realtime-updates/services/IRealtimeService';
import {RealtimeSubscriptionService} from '../../realtime-updates/services/RealtimeUpdatesService';

export class KycRealtimeService extends RealtimeSubscriptionService implements IRealtimeService<AccountVerification> {
    subscribeToCreate(): Observable<AccountVerification> {
        return super.createSubscription(this.getItemAddedSubscriptionQuery()).pipe(map(res => res.accountVerificationAdded));
    }

    subscribeToUpdate(): Observable<AccountVerification> {
        return super.createSubscription(this.getItemUpdatedSubscriptionQuery()).pipe(map(res => res.accountVerificationUpdated));
    }

    private getItemAddedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnAccountVerificationAdded {
                accountVerificationAdded {
                    id
                    kyc_case_id
                    uid
                    username
                    type
                    account_verification_status
                    initiated_at {
                        seconds
                    }
                    status_log {
                        kyc_status
                        account_verification_status
                        logged_at {
                            seconds
                        }
                    }
                    documents {
                        did
                        doc_ref_id
                        doc_type
                        status
                        uploaded_ts {
                            seconds
                        }
                        doc_id_sub_type
                        location {
                            line1
                            line2
                            country
                            postal_code
                            city
                            subdivision
                            formatted
                        }
                        client_ip
                        number
                        first_name
                        last_name
                        gender
                        dob
                        ip_verified
                        device_verified
                        geolocation_verified
                        country_info {
                            name
                            iso_alpha2_code
                        }
                        device_id
                    }
                }
            }
        `;
    }

    private getItemUpdatedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnAccountVerificationUpdated {
                accountVerificationUpdated {
                    id
                    kyc_case_id
                    uid
                    username
                    type
                    account_verification_status
                    initiated_at {
                        seconds
                    }
                    status_log {
                        kyc_status
                        account_verification_status
                        logged_at {
                            seconds
                        }
                    }
                    documents {
                        did
                        doc_ref_id
                        doc_type
                        status
                        uploaded_ts {
                            seconds
                        }
                        doc_id_sub_type
                        location {
                            line1
                            line2
                            country
                            postal_code
                            city
                            subdivision
                            formatted
                        }
                        client_ip
                        number
                        first_name
                        last_name
                        gender
                        dob
                        ip_verified
                        device_verified
                        geolocation_verified
                        country_info {
                            name
                            iso_alpha2_code
                        }
                        device_id
                    }
                }
            }
        `;
    }
}
