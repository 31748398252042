import React, {memo} from 'react';
import {FormattedNumber} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import {renderValueWithTooltip} from './GridCellWithTooltip';

export type GridNumberFormatterProps = {
    value: number;
};

export const GridNumberFormatter = memo(({value}: GridNumberFormatterProps) => {
    return <FormattedNumber value={value ?? 0} />;
});

export const GridPercentageFormatter = memo(({value}: GridNumberFormatterProps) => {
    return <FormattedNumber value={value ?? 0} style="percent" />;
});

export const renderGridNumber = (params: GridCellParams) => {
    const defaultValue = params.value as number;
    return renderValueWithTooltip(
        defaultValue !== null && defaultValue !== undefined ? <GridNumberFormatter value={defaultValue} /> : defaultValue
    );
};
