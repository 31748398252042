import {BaseFilterKeys, Filter, SortOrder} from '@redux/entity';

import {RestRequest} from '../types';

export abstract class RestRequestFactory<TKeys extends string = BaseFilterKeys> {
    public buildRequest(restRequest: RestRequest): RestRequest {
        const filter: Filter<TKeys> = this.mapToFilterObject(restRequest.query);

        return !this.isFilterInvalid(filter)
            ? {
                  endpoint: this.buildEndpoint(restRequest),
                  query: this.buildQueryFilter(filter),
                  body: restRequest.body,
              }
            : null;
    }

    private isFilterInvalid({invalid}: Filter): boolean {
        return <boolean>invalid;
    }

    private buildEndpoint(restRequest: RestRequest): string {
        return restRequest.endpoint;
    }

    protected buildQueryFilter(filter: Filter<TKeys>): string {
        return filter ? this.combineFilterParts(this.buildFilter(filter), this.buildPaging(filter), this.buildSort(filter)) : '';
    }

    protected abstract buildFilter(filter: Filter<TKeys>): URLSearchParams;

    private combineFilterParts(...args: URLSearchParams[]): string {
        const result = new URLSearchParams();
        const parseURLSearchParams = (searchParams: URLSearchParams) => {
            searchParams.forEach((value, key) => result.append(key, value));
        };

        args.filter(a => a).forEach(searchParams => parseURLSearchParams(searchParams));

        return result.toString();
    }

    private mapToFilterObject(filter: string): Filter<TKeys> {
        const result: Filter<TKeys> = {};
        const searchParams = new URLSearchParams(filter);

        searchParams.forEach((value, key: TKeys) => {
            result[key] = value;
        });

        return result;
    }

    private buildSort(filter: Filter): URLSearchParams {
        const result = new URLSearchParams('');
        const defaultSortOrder = SortOrder.Desc;

        if (filter.sortField) {
            result.append('sort', filter.sortField.toString());
            result.append('order', (filter.sortOrder ?? defaultSortOrder).toString());
        }

        return result;
    }

    private buildPaging(filter: Filter): URLSearchParams {
        const result = new URLSearchParams('');

        if (filter.size) {
            result.append('page', (((filter.page ?? 1) as number) - 1).toString());
            result.append('size', filter.size.toString());
        }

        return result;
    }
}
