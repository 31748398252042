import React from 'react';
import {Controller, ControllerFieldState} from 'react-hook-form';
import {useIntl} from 'react-intl';

import {AutocompleteOptionType} from '@components/autocomplete';
import {MultiAutocompleteValueType, withMultipleEditableAutocomplete} from '@components/editable/MultipleEditableAutocomplete';
import {RuleType, useValidationFormatter} from '@components/input';
import {customerSupportUpdatePermissions} from '@models/permissions/permissions';
import {Filter} from '@redux/entity';
import {CountriesApiFilterKeys} from '@services/rest-api/countriesApiService';

import {LackOfPermissionIndicator, useMultiplePermissions, withMultiplePermission} from 'src/features/app/permission/PermissionHoc';
import {UserProfileModel} from 'src/features/player-actions/pii-actions/types';
import {useReduxForm, useReduxFormRequestMode} from 'src/features/shared/form/hooks';
import {CountryAutocomplete} from 'src/features/view-autocomplete/components/CountryAutocomplete';
import {StateAutocomplete} from 'src/features/view-autocomplete/components/StateAutocomplete';
import {addressInfoActions} from '../actions';

import {localized} from './UserProfileCountryState.localize';

type UserProfileCountryStateProps = UserProfileModel & {
    disabled?: boolean;
};

type AddressStateModel = {
    country: Omit<AutocompleteOptionType, 'subOptions'>;
    state: Omit<AutocompleteOptionType, 'subOptions'>;
};

type CountryStateModel = {
    address: AddressStateModel;
};

const countriesWithRequiredState = [
    'AR', // Argentina
    'BR', // Brazil
    'CA', // Canada
];

const CountryStateEdit = withMultipleEditableAutocomplete(CountryAutocomplete, StateAutocomplete);

const UserProfileCountryStateInternal = (props: UserProfileCountryStateProps) => {
    const isEditable = useMultiplePermissions({allowedPermissions: customerSupportUpdatePermissions});
    const validationFormatter = useValidationFormatter();
    const {formatMessage} = useIntl();

    const defaultValue = {
        address: {
            country: {
                label: props.user?.address?.country_info?.name,
                value: props.user?.address?.country_info?.iso_alpha2_code,
            },
            state: {
                label: props.user?.address?.stateName,
                value: props.user?.address?.state,
            },
        },
    };
    const {control, state, submit, cancel, handleSubmit} = useReduxForm<CountryStateModel, UserProfileModel>({
        initialModel: defaultValue,
        asyncAction: addressInfoActions.editAddressCountry,
        map: m => ({
            user: {
                uid: props.userId,
                address: {
                    country_info: {
                        iso_alpha2_code: m?.address?.country?.value ?? '',
                        name: m?.address?.country?.label ?? '',
                    },
                    state: m?.address?.state?.value ?? '',
                    stateName: m?.address?.state?.label,
                },
            },
            userId: props.userId,
        }),
    });
    const {requestMode, resetRequestMode} = useReduxFormRequestMode<UserProfileModel>(addressInfoActions.editAddressCountry);

    //TODO: [BO-2600] Refactor error message handling
    const getCountryErrorMessage = (fieldState: ControllerFieldState) => {
        return state.errorMessage
            ? formatMessage(state.errorMessage)
            : fieldState?.error?.type === 'requiredCountry'
            ? fieldState?.error?.message
            : null;
    };

    const getStateErrorMessage = (fieldState: ControllerFieldState) => {
        return fieldState?.error?.type === 'requiredState' ? fieldState?.error?.message : null;
    };

    const getUpdatedValue = (newValue: MultiAutocompleteValueType, oldValue: MultiAutocompleteValueType) => {
        const isCountryChanged = oldValue?.country?.value !== newValue?.country?.value;
        const updatedValue = !isCountryChanged
            ? newValue
            : {
                  country: newValue?.country,
                  state: null,
              };
        return updatedValue;
    };

    function stateRequiredValidation(model: AddressStateModel): string {
        return countriesWithRequiredState.includes(model?.country?.value) && !model?.state
            ? validationFormatter(RuleType.Required, localized.piiAddressStateFieldName)
            : null;
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Controller
                render={({field, fieldState}) => {
                    const stateFilter: Filter<CountriesApiFilterKeys> = {country: field.value?.country?.value};

                    return (
                        <CountryStateEdit
                            disabled={!isEditable}
                            mode={requestMode}
                            value={field.value}
                            onChange={value => {
                                const updatedValue = getUpdatedValue(value, field.value);
                                field.onChange(updatedValue);
                                resetRequestMode();
                            }}
                            onSave={() => handleSubmit(submit)}
                            onCancel={cancel}
                            autocompletes={[
                                {
                                    key: 'country',
                                    filter: null,
                                    errorMessage: getCountryErrorMessage(fieldState),
                                },
                                {
                                    key: 'state',
                                    filter: stateFilter,
                                    errorMessage: getStateErrorMessage(fieldState),
                                },
                            ]}
                        />
                    );
                }}
                control={control}
                name="address"
                defaultValue={defaultValue?.address}
                rules={{
                    validate: {
                        requiredCountry: v =>
                            !v?.country?.value || !v?.country?.label
                                ? validationFormatter(RuleType.Required, localized.piiAddressCountryFieldName)
                                : null,
                        requiredState: stateRequiredValidation,
                    },
                }}
            ></Controller>
        </form>
    );
};

export const UserProfileCountryState = withMultiplePermission(UserProfileCountryStateInternal, {
    allowedPermissions: customerSupportUpdatePermissions,
    indicator: LackOfPermissionIndicator.Disabled,
});
