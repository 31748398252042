import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    label: {
        id: 'multiStepFilterDatePicker_label',
        defaultMessage: 'Date Range',
    },
    placeholder: {
        id: 'DateRangePicker_Placeholder',
        defaultMessage: 'Select Date Range',
    },
    month: {
        id: 'multiStepFilterDatePicker_month',
        defaultMessage: 'Month',
    },
    year: {
        id: 'multiStepFilterDatePicker_year',
        defaultMessage: 'Year',
    },
});
