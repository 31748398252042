import React, {useEffect, useRef, useState} from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {AutocompleteGetTagProps} from '@mui/base/useAutocomplete';
import MuiAutocomplete, {AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import {createFilterOptions} from '@mui/material/Autocomplete';
import {StyledComponentProps} from '@mui/material/styles';
import {FilterOptionsState} from '@mui/material/useAutocomplete';

import {AutocompleteInfoMessage} from '@components/autocomplete/AutocompleteInfoMessage';

import {localizedAutocomplete} from './Autocomplete.localize';
import {useAutocompleteClasses} from './Autocomplete.styles';
import {renderTags} from './AutocompleteChip';
import {CloseIcon, PopupIcon} from './AutocompleteIcons';
import {renderInput} from './AutocompleteInput';
import {renderOption} from './AutocompleteOption';
import {AutocompletePaper} from './AutocompletePaper';
import {AutocompletePopper} from './AutocompletePopper';
import {useAutocompleteState} from './hooks';
import {AutocompleteBaseProps, AutocompleteOptionType} from './types';

type AsyncAutocompleteProps<TValue> = AutocompleteBaseProps<TValue> & {
    multiple?: boolean;
    loading?: boolean;
    suggestCreation?: boolean;
    suggestAlways?: boolean;
    suggestPlaceholder?: MessageDescriptor;
    clearOnSuggestionSelect?: boolean;
    allowFreeInputValue?: boolean;
    disablePortal?: boolean;
    clientFilter?: boolean;
} & StyledComponentProps;

const filter = createFilterOptions();

export const Autocomplete = <TValue,>({
    multiple,
    value,
    defaultOption,
    options,
    loading,
    triggerFilterChangeOnFirstOpen,
    suggestCreation,
    suggestPlaceholder,
    suggestAlways,
    clearOnSuggestionSelect,
    placeholder,
    classes,
    disabled,
    disablePortal,
    subInfoMessage,
    errorMessage,
    disableOptionSelection,
    allowEmptyValue,
    allowFreeInputValue,
    onValueChange,
    onFilterChange,
    optionsLimit,
    hasLargeOptions,
    errorMessageOrientation,
    dataTestId,
    clientFilter = true,
    useValueAsDefaultInputValue,
}: AsyncAutocompleteProps<TValue>) => {
    const {classes: styles} = useAutocompleteClasses();
    const {formatMessage} = useIntl();
    const [isFilterChangeOnFirstOpenTriggered, setFilterChangeOnFirstOpenTriggered] = useState(false);

    const {
        open,
        showInputIcon,
        inputValue,
        selectedOption,
        optionsInternal,
        autocompleteState,
        handleFilterChange,
        handleInputChange,
        handleBlur,
        handleClear,
        handleClose,
        handleOpen,
        handleAutocompleteChange,
    } = useAutocompleteState({
        useValueAsDefaultInputValue,
        value,
        defaultOption,
        options,
        onValueChange,
        onFilterChange,
        allowFreeInputValue,
        allowEmptyValue,
        multiple,
    });

    useEffect(() => {
        if (open && triggerFilterChangeOnFirstOpen && !isFilterChangeOnFirstOpenTriggered) {
            handleFilterChange(inputValue);
            setFilterChangeOnFirstOpenTriggered(true);
        }
    }, [open]);

    const getNoOptionsText = () => {
        return <>{formatMessage(inputValue === '' && placeholder ? placeholder : localizedAutocomplete.autocompleteNoOptionsLabel)}</>;
    };

    const getSuggestedOptionLabel = (inputValue: string) => {
        return formatMessage(suggestPlaceholder ?? localizedAutocomplete.autocompleteAddNewItemLabel, {
            value: inputValue,
        });
    };

    const getOptionLabel = (option: string | AutocompleteOptionType) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }
        if (option.isSuggested) {
            return getSuggestedOptionLabel(option.inputValue);
        }

        return option.label ?? '';
    };

    const getOptionValue = (option: string | AutocompleteOptionType) => {
        return typeof option === 'string' ? option : option.value;
    };

    const getOptionSelected = (option: string | AutocompleteOptionType, value: string | AutocompleteOptionType) => {
        return getOptionLabel(option) === getOptionLabel(value) && getOptionValue(option) === getOptionValue(value);
    };

    const getOptions = (options: AutocompleteOptionType[], params: FilterOptionsState<AutocompleteOptionType>) => {
        const filterParams = {...params, inputValue: inputValue};
        let filteredOptions = clientFilter ? filter(options, filterParams) : options;
        filteredOptions = optionsLimit ? filteredOptions.slice(0, optionsLimit) : filteredOptions;

        // Suggest the creation of a new value
        if (suggestCreation && (suggestAlways || (!filteredOptions?.length && filterParams.inputValue !== ''))) {
            const inputValue = clearOnSuggestionSelect ? '' : filterParams.inputValue;
            filteredOptions.push({
                inputValue: inputValue,
                value: inputValue,
                label: getSuggestedOptionLabel(inputValue),
                isSuggested: true,
            });
        }

        return filteredOptions as AutocompleteOptionType[];
    };

    const autocompleteProps = multiple
        ? {
              multiple: true,
              limitTags: 1,
              renderTags: (value: AutocompleteOptionType[], getTagProps: AutocompleteGetTagProps) =>
                  renderTags(value, getTagProps, getOptionLabel),
              popupIcon: PopupIcon,
              closeIcon: CloseIcon,
          }
        : {
              freeSolo: true,
          };

    const largeOptionsClasses = hasLargeOptions
        ? {
              option: styles.autocompleteOptionLarge,
              popper: styles.autocompletePopperLarge,
          }
        : {};

    const autocompleteRef = useRef<HTMLDivElement>(null);

    return (
        <div className={styles.autocompleteContainer} ref={autocompleteRef}>
            <MuiAutocomplete
                {...autocompleteProps}
                data-testid={dataTestId ?? 'autocomplete'}
                classes={{...classes, ...largeOptionsClasses}}
                disablePortal={disablePortal}
                loading={loading}
                disabledItemsFocusable
                autoComplete
                disableClearable
                selectOnFocus
                openOnFocus
                handleHomeEndKeys
                disabled={disabled}
                value={selectedOption}
                open={open}
                onChange={handleAutocompleteChange}
                onInputChange={handleInputChange}
                onOpen={handleOpen}
                onClose={handleClose}
                onBlur={handleBlur}
                PopperComponent={AutocompletePopper}
                PaperComponent={AutocompletePaper}
                options={optionsInternal}
                noOptionsText={getNoOptionsText()}
                filterOptions={getOptions}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={getOptionSelected}
                getOptionDisabled={() => disableOptionSelection}
                renderOption={(props, option, {inputValue}) => renderOption(props, option as string | AutocompleteOptionType, inputValue)}
                renderInput={(params: AutocompleteRenderInputParams) =>
                    renderInput(
                        multiple,
                        params,
                        placeholder,
                        loading,
                        inputValue,
                        handleClear,
                        showInputIcon ? options?.find(o => o.label === inputValue)?.startIcon : null
                    )
                }
            />
            <AutocompleteInfoMessage
                subInfoMessage={subInfoMessage}
                autocompleteState={autocompleteState}
                hideMessage={open}
                containerRef={autocompleteRef}
                dataTestId="autocompleteErrorMessage"
                message={errorMessage}
                messageOrientation={errorMessageOrientation}
            />
        </div>
    );
};
