import React from 'react';

import {Select} from '@components/select';

import {SelectOption} from '../../../module-shared/types';
import {PageModuleChipSelectFilter} from '../types';

import {FilterInnerProps} from './ModuleFilterHoc';
import withModuleFilter from './ModuleFilterHoc';

const SingleSelectFilter = ({filter, value, onSubmit}: FilterInnerProps<string>) => {
    const onChangeSelect = (selectedValue: string): void => {
        onSubmit({key: filter.key, value: selectedValue});
    };

    const {
        options,
        key,
        enumFormatter,
        label,
        chipType,
        isSelectedValueChip,
        emptyPlaceholder,
        showResetButton = true,
        horizontalPosition,
    } = filter as PageModuleChipSelectFilter;

    return (
        <Select
            options={options as SelectOption[]}
            value={value.find(i => i.key === key)?.value}
            onSubmit={onChangeSelect}
            enumFormatter={enumFormatter}
            label={label}
            chipType={chipType}
            isSelectedValueChip={isSelectedValueChip}
            emptyPlaceholder={emptyPlaceholder}
            showResetButton={showResetButton}
            horizontalPosition={horizontalPosition}
        />
    );
};

const FilterSingleSelect = withModuleFilter(SingleSelectFilter, 'auto', false);

export default FilterSingleSelect;
