import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import produce from 'immer';

import Button from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {EntityType} from '@redux/entity';

import {
    BulkActionItemPayload,
    BulkActionKey,
    BulkActionSummaryDetailsInfo,
    BulkStepProps,
    BulkTotalCountChip,
} from '../../block-bulk-actions';
import {useMttBonusGroupedByTicket, useTriggerBonusesSummaryGroups} from '../hooks';
import {BonusTriggerViewModel, BonusTriggerViewModelKeys} from '../types';

import {DataGridBonusTriggerClient} from './DataGridBonusTrigger';

const localized = defineMessages({
    header: {
        id: 'bulkBonusChangesSummaryStep_header',
        defaultMessage: 'Applying Changes',
    },
    confirm: {
        id: 'bulkBonusChangesSummaryStep_confirm',
        defaultMessage: 'Confirm',
    },
    cancel: {
        id: 'bulkBonusChangesSummaryStep_cancel',
        defaultMessage: 'Cancel',
    },
    triggerMttBonus: {
        id: 'bulkBonusChangesSummaryStep_triggerMttBonus',
        defaultMessage: 'Trigger Mtt bonus: {bonus}',
    },
    triggerImmediateCashBonus: {
        id: 'bulkBonusChangesSummaryStep_triggerImmediateCashBonus',
        defaultMessage: 'Trigger Immediate Cash bonus: {bonus}',
    },
    triggerDepositCashMatchBonus: {
        id: 'bulkBonusChangesSummaryStep_triggerDepositCashMatchBonus',
        defaultMessage: 'Trigger Deposit Cash Match bonus: {bonus}',
    },
});

type BulkBonusChangesSummaryStep = BulkStepProps & {
    items: Record<string, BulkActionItemPayload<BonusTriggerViewModel>[]>;
    label: MessageDescriptor;
    columns: BonusTriggerViewModelKeys[];
};

function BulkBonusChangesSummaryStep({onNext, onPrev, items, label, columns}: BulkBonusChangesSummaryStep) {
    function handlePrevClick() {
        onPrev();
    }

    function handleNextClick() {
        onNext();
    }

    const maxRows = 100;

    return (
        <>
            <BulkModalTitle title={localized.header} />
            <BulkModalContent>
                {Object.entries(items).map(([bonusId, actionItems]) => {
                    return actionItems.length > 0 ? (
                        <BulkActionSummaryDetailsInfo
                            key={bonusId}
                            expandable={true}
                            actionKey={actionItems[0].actionKey}
                            actionLabel={
                                <LocalizedText label={label} labelParams={{bonus: actionItems[0].value?.bonus_name}}></LocalizedText>
                            }
                            actionTotal={
                                <BulkTotalCountChip totalItems={actionItems.length} type={EntityType.UserProfile} chipType="info" />
                            }
                        >
                            <DataGridBonusTriggerClient
                                rows={actionItems.map(i => i.value)}
                                columns={columns}
                                rowsPerPageOptions={[maxRows]}
                                hidePagination={actionItems.length < maxRows}
                                autoHeight
                            />
                        </BulkActionSummaryDetailsInfo>
                    ) : null;
                })}
            </BulkModalContent>
            <ModalFooter>
                <Button label={localized.cancel} onClick={handlePrevClick} />
                <Button color="primary" label={localized.confirm} onClick={handleNextClick} />
            </ModalFooter>
        </>
    );
}

export function BulkBonusMttChangesSummaryStep({onNext, onPrev}: BulkStepProps) {
    const {groupedBonuses} = useTriggerBonusesSummaryGroups(BulkActionKey.ManualTriggerMTTTicket);
    const {groupByTicket} = useMttBonusGroupedByTicket();
    const bonusesGroupedByTickets = produce(groupedBonuses, draft => {
        Object.keys(draft).forEach(i => (draft[i] = groupByTicket(draft[i])));
        return draft;
    });

    return (
        <BulkBonusChangesSummaryStep
            onNext={onNext}
            onPrev={onPrev}
            items={bonusesGroupedByTickets}
            label={localized.triggerMttBonus}
            columns={['uid', 'account_status', 'deposit_status', 'withdrawal_status', 'security_cases', 'marketing_code', 'ticket_count']}
        />
    );
}

export function BulkBonusImmediateCashChangesSummaryStep({onNext, onPrev}: BulkStepProps) {
    const {groupedBonuses} = useTriggerBonusesSummaryGroups(BulkActionKey.ManualTriggerImmediateCashBonus);

    return (
        <BulkBonusChangesSummaryStep
            onNext={onNext}
            onPrev={onPrev}
            items={groupedBonuses}
            label={localized.triggerImmediateCashBonus}
            columns={['uid', 'account_status', 'deposit_status', 'withdrawal_status', 'security_cases', 'marketing_code']}
        />
    );
}

export function BulkBonusDepositCashMatchChangesSummaryStep({onNext, onPrev}: BulkStepProps) {
    const {groupedBonuses} = useTriggerBonusesSummaryGroups(BulkActionKey.ManualTriggerDepositCashMatch);

    return (
        <BulkBonusChangesSummaryStep
            onNext={onNext}
            onPrev={onPrev}
            items={groupedBonuses}
            label={localized.triggerDepositCashMatchBonus}
            columns={['uid', 'total_amount', 'account_status', 'deposit_status', 'withdrawal_status', 'security_cases', 'marketing_code']}
        />
    );
}
