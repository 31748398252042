import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';

import {ServiceTypes} from '@inversify';
import {UserProfile} from '@models/generated/graphql';
import {EntityFetchRequestPayload, EntityType, Filter, UserProfileQueryFields, UserProfileServerFilterKeys} from '@redux/entity';
import {mapViewFilterToString} from '@redux/view';
import {IEntityReadService} from '@services/entity';

import {IBulkStrategy} from '../../block-bulk-actions';
import {UserProfileGridItem} from '../../data-grids/types';

export type LoadPlayerItemsStrategyRequest = {
    uids: string[];
    fields: UserProfileQueryFields[];
};

export type LoadPlayerItemsStrategyResponse = UserProfileGridItem[];

@injectable()
export class LoadPlayerItemsStrategy implements IBulkStrategy<LoadPlayerItemsStrategyRequest, LoadPlayerItemsStrategyResponse> {
    private _userProfileService: IEntityReadService;

    constructor(@inject(ServiceTypes.UserProfileService) userProfileService: IEntityReadService) {
        this._userProfileService = userProfileService;
    }

    process({uids, fields}: LoadPlayerItemsStrategyRequest): Observable<LoadPlayerItemsStrategyResponse> {
        return this._userProfileService.get(this.getParsedUsersPayload(uids, fields)).pipe(
            map(value => {
                const users = value.responsePayload.items as UserProfile[];
                return users.map(user => {
                    const id = uuid();
                    return {...user, id, serverId: id};
                });
            })
        );
    }

    private getParsedUsersPayload(uids: string[], fields: UserProfileQueryFields[]): EntityFetchRequestPayload {
        const filter: Filter<UserProfileServerFilterKeys> = {
            uid: uids.filter(u => u).join(' '),
            size: uids.length,
            page: 1,
        };

        return {
            type: EntityType.UserProfile,
            fields,
            filter: mapViewFilterToString<UserProfileServerFilterKeys>(filter, ['uid', 'size', 'page']),
        };
    }
}
