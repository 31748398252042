import {AccountVerification} from '@models/generated/graphql';

import {contentModuleActions} from '../../features/module-shared/actions';
import {createSubscriberRealtimeActions} from '../../features/realtime-updates/actions';

export const createKycDetailsActions = (domain: string) => ({
    ...contentModuleActions(domain),

    ...createSubscriberRealtimeActions<AccountVerification>(domain),
});
