import {MessageDescriptor} from 'react-intl';
import {PopoverOrigin} from '@mui/material';

import {IModuleGridItem} from '@components/data-grid';
import {EmptyPlaceholder} from '@components/select';

import {Filter as FilterKeyValue} from 'src/common/types';
import {JurisdictionFeature} from '../../app/config/types';

export type ModuleTool = JurisdictionFeature & {
    placement?: FilterPlacement;
};

export type FilterProps<TOptions = unknown> = {
    domain?: string;
    filter: Filter;
    filterString: string;
    onFilterChange?: (value: FilterKeyValue[]) => void;
    options?: TOptions;
};

export type Filter = ModuleTool & {
    name?: MessageDescriptor;
    key?: string | string[];
    label?: string | MessageDescriptor;
    getLabel?: (key: string) => MessageDescriptor;
    options?: any;
    component?: (props: FilterProps) => JSX.Element;
    defaultValue?: FilterKeyValue[];
    limitMaxWidth?: boolean;
};

export type PageModuleChipSelectFilter = PageModuleSelectFilter & {
    chipType?: string;
    isSelectedValueChip?: boolean;
};

export type PageModuleSelectFilter = Filter & {
    enumFormatter?: (value: string) => string | MessageDescriptor;
    emptyPlaceholder?: EmptyPlaceholder;
    placeholder?: MessageDescriptor;
    showResetButton?: boolean;
    hasSearch?: boolean;
    horizontalPosition?: PopoverOrigin['horizontal'];
};

export type MultipleKeysFilter = Filter & {
    keys: string[];
};

//TODO: [BO-1844] Replace Tertiary to Advanced After Adding Filter Side Panel
export enum FilterPlacement {
    Primary = 'Primary', // for left aligned filter
    Secondary = 'Secondary', // for right aligned filter
    Advanced = 'Advanced', // for second row (or side panel in the future)
    Tertiary = 'Tertiary', // for third row (or side panel in the future)
    AdvancedSecondary = 'AdvancedSecondary', // right aligned advanced filters
    TertiarySecondary = 'TertiarySecondary', // right aligned tertiary filters
}

export type ClientFilter<TModel extends IModuleGridItem> = {
    fieldSelector: (item: TModel) => unknown;
    filterKey: string;
    filterType: ClientFilterType;
};

export enum ClientFilterType {
    Multiselect,
}
