import React, {forwardRef, PropsWithChildren} from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useClasses = makeStyles()(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: theme.spacing(1.25),
    },
    toolbarItemsContainer: {
        display: 'flex',
        columnGap: theme.spacing(1.25),
        [theme.breakpoints.down('sm')]: {
            columnGap: 0,
        },
    },
    toolbarItemsContainerPrimary: {
        width: '100%',
    },
    toolbarItems: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1.25),
    },
    toolbarItemsSecondary: {
        marginLeft: 'auto',
    },
    toolbarItemsStretched: {
        flex: 1,
    },
}));

function Toolbar({children}: PropsWithChildren) {
    const {classes} = useClasses();

    return children ? <Box className={classes.toolbar}>{children}</Box> : null;
}

type ToolbarItemsContainerProps = {primary?: boolean};

export function ToolbarItemsContainer({primary = false, children}: PropsWithChildren<ToolbarItemsContainerProps>) {
    const {classes, cx} = useClasses();
    return <Box className={cx(classes.toolbarItemsContainer, primary && classes.toolbarItemsContainerPrimary)}>{children}</Box>;
}

type ToolbarItemsProps = {
    secondary?: boolean;
    streched?: boolean;
};

export const ToolbarItems = forwardRef<HTMLDivElement, PropsWithChildren<ToolbarItemsProps>>(
    ({secondary = false, streched = false, children}, ref) => {
        const {classes, cx} = useClasses();
        return (
            <Box
                className={cx(classes.toolbarItems, streched && classes.toolbarItemsStretched, secondary && classes.toolbarItemsSecondary)}
                ref={ref}
            >
                {children}
            </Box>
        );
    }
);

export default Toolbar;
