import {createAsyncAction} from 'typesafe-actions';

import {MutationForceExpireCashBonusArgs, PlayerBonus} from '@models/generated/graphql';
import {AsyncActionMeta} from '@redux/async-action';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

const domain = 'player-bonus';

export type ForceExpireRequestPayload = {
    bonusId: string;
};

export const playerBonusActions = {
    forceExpire: createAsyncAction(`${domain}/forceExpireRequest`, `${domain}/forceExpireSuccess`, `${domain}/forceExpireFailure`)<
        [ForceExpireRequestPayload, AsyncActionMeta],
        [ServiceResponsePayload<GqlMutationRequest<MutationForceExpireCashBonusArgs>, PlayerBonus>, AsyncActionMeta],
        [ServiceResponsePayload<GqlMutationRequest<MutationForceExpireCashBonusArgs>, PlayerBonus>, AsyncActionMeta]
    >(),
};
