import {defineMessages} from 'react-intl';

import {withUserProfileTextField} from '../../player-actions/pii-actions/components/UserProfileTextField';
import {personalInfoActions} from '../actions';

const localized = defineMessages({
    piiNicknameFieldName: {
        id: 'piiNicknameFieldName',
        defaultMessage: 'Nickname',
    },
});

export const UserProfileNickname = withUserProfileTextField({
    action: personalInfoActions.editNickname,
    placeholder: localized.piiNicknameFieldName,
    submitModelMapper: m => ({
        fieldValue: m?.user?.nickname,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            nickname: m?.fieldValue as string,
        },
        userId: m?.userId,
    }),
    isRequired: true,
});
