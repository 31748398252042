import React, {useContext, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {v4 as uuid} from 'uuid';

import {EditButton, EditButtonProps, EditMode} from '@components/button/EditButton';

import {EditableFormContext} from './EditProvider';

type WithEditButtonProps = Omit<EditButtonProps, 'onEdit' | 'onCancel'> & {
    disabled?: boolean;
    onEdit?: () => void;
    onCancel?: () => void;
    onEditModeChange?: (mode: EditMode) => void;
    isMultiStep?: boolean;
    loading?: boolean;
};

const useClasses = makeStyles()(theme => ({
    withEditButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexGrow: 1,
        justifyContent: 'space-between',
        columnGap: theme.spacing(1),
    },
}));

export type EditableProps = Omit<WithEditButtonProps, 'onSave' | 'onCancel'>;

export const withEditButton =
    <TProps extends object & EditableProps>(WrappedComponent: React.ComponentType<TProps>) =>
    ({
        mode,
        onEdit,
        onSave,
        onCancel,
        disabled = false,
        isMultiStep = false,
        onNextStep,
        onEditModeChange,
        disabledSubmitButton,
        ...props
    }: TProps & WithEditButtonProps) => {
        const {classes} = useClasses();

        const [currentMode, setCurrentMode] = useState(mode);
        const [currentId] = useState(uuid());

        const {editableFormId, setEditableFormId} = useContext(EditableFormContext);

        useEffect(() => {
            if (currentMode !== mode) {
                setEditMode(mode);

                if (isEditable(mode)) {
                    setEditableFormId(currentId);
                }
            }
        }, [mode]);

        useEffect(() => {
            if (currentId !== editableFormId) {
                setEditMode('Read');
            } else {
                setEditMode(isMultiStep ? 'EditMultiStep' : 'Edit');
            }
        }, [editableFormId]);

        function isEditable(mode: EditMode) {
            return mode === 'EditMultiStep' || mode === 'Edit';
        }

        function handleCancel() {
            setEditMode('Read');

            if (onCancel) {
                onCancel();
            }
        }

        function handleSave() {
            setEditMode('InProgress');
            onSave();
        }

        function handleEdit() {
            setEditableFormId(currentId);

            if (onEdit) {
                onEdit();
            }
        }

        function handleNextStep() {
            if (onNextStep) {
                onNextStep();
            }
        }

        function setEditMode(mode: EditMode) {
            setCurrentMode(mode);

            if (onEditModeChange) {
                onEditModeChange(mode);
            }
        }

        return (
            <Box data-testid="editContainer" className={classes.withEditButtonContainer}>
                <WrappedComponent {...(props as TProps)} mode={currentMode}></WrappedComponent>
                {!disabled ? (
                    <EditButton
                        mode={currentMode}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        onEdit={handleEdit}
                        onNextStep={handleNextStep}
                        disabled={props.loading}
                        disabledSubmitButton={disabledSubmitButton}
                    ></EditButton>
                ) : (
                    <></>
                )}
            </Box>
        );
    };
