import React from 'react';

import {withFormControl} from '@components/input/FormControlHoc';

import {CountryAutocomplete} from '../../view-autocomplete/components/CountryAutocomplete';
import {CountryAutocompleteProps} from '../../view-autocomplete/components/CountryAutocompleteBase';

import {withRuleFormCondition} from './RuleFormConditionHoc';

type RuleConditionCountriesInternalProps = Omit<CountryAutocompleteProps, 'viewType'> & {
    onChange: (...props: any[]) => void;
};

function RuleConditionCountriesInternal({onChange, ...props}: RuleConditionCountriesInternalProps) {
    return <CountryAutocomplete onValueChange={onChange} {...props} />;
}

export const RuleConditionCountries = withRuleFormCondition(withFormControl(RuleConditionCountriesInternal));
