import React, {ReactElement, useEffect, useState} from 'react';
import {FC} from 'react';
import {CaptionLabelProps, DateRange, DayPicker, Matcher} from 'react-day-picker';
import {MessageDescriptor} from 'react-intl';
import 'react-day-picker/dist/style.css';
import {Box, FilledInputProps, InputProps, OutlinedInputProps} from '@mui/material';
import en from 'date-fns/locale/en-US';
import kr from 'date-fns/locale/ko';
import cn from 'date-fns/locale/zh-CN';

import {Locale as ComponentLocale} from '@components/types';
import {formatDayOfWeek, getTodayAsDate} from '@utils';

import {StyledInputDropdown} from '../dropdown/Dropdown';
import {CustomIcon} from '../icons/types';

import {localized} from './DatePicker.localize';
import {useClasses, useDatePickerStyles} from './DatePicker.style';
import {DatePickerFooterRangeMode} from './DatePickerFooterRangeMode';
import {DatePickerFooterSingleMode} from './DatePickerFooterSingleMode';
import {DatePickerYearMonthForm} from './DatePickerYearMonthForm';
import {StyledInputProps} from './StyledFormInputHoc';

const localeMapping: Record<ComponentLocale, Locale> = {en: en, 'zh-CN': cn, 'ko-KR': kr};

type IconPlacement = 'start' | 'end' | 'none';
export type DatePickerType = 'large' | 'small';

export type CaptionComponentProps = {
    date: Date;
    onChange: (date: Date) => void;
};

type DatePickerProps = StyledInputProps & {
    captionComponent?: ({date, onChange}: CaptionComponentProps) => ReactElement;
    displayMode?: 'mobile' | 'desktop';
    isOpen?: Boolean;
    value: Matcher;
    formattedValue: string;
    defaultMonthDate: Date;
    locale: ComponentLocale;
    isDateSelected?: Boolean;
    isDateDisabled?: Matcher | Matcher[];
    disableFutureDates?: Boolean;
    isRangeMode?: Boolean;
    footer?: React.ReactElement;
    iconPlacement?: IconPlacement;
    type?: DatePickerType;
    placeholder?: string | MessageDescriptor;
    isApplyDisabled?: boolean;
    applyAction?: boolean | MessageDescriptor;
    cancelAction?: boolean | MessageDescriptor;
    onSelect?: (value: Matcher) => void;
    onApplyClick?: () => void;
    onCancelClick?: () => void;
    onOutsideClick?: () => void;
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
    renderInsideInputConteiner?: boolean;
};

//TODO: [BO-2664] Remove dependency to features (pass data through props)

/**
 * DatePicker - component to let users pick dates from a calendar.
 *
 * @param {'large' | 'small'} type Visual size type - 'large' | 'small'.
 * @param {'start' | 'end' | 'none'} iconPlacement Icon placement - 'start' | 'end' | 'none'.
 * @param {() => void} onApplyClick Apply Click handler.
 * @param {() => void} onCancelClick Cancel Click handler.
 * @param {() => void} onDropdownClose Dropdown Close handler.
 * @param {() => void} onDropdownOpen Dropdown Open handler.
 * @param {() => void} onOutsideClick Outside Click handler.
 * @param {(value: Matcher) => void} onSelect Select handler.
 * @param {Boolean} disableFutureDates Param that activate mode when all dates in future is disabled.
 * @param {Boolean} isDateSelected value/values is enabled (not null and not undefined).
 * @param {Boolean} isApplyDisabled Is Apply Button Disabled.
 * @param {Matcher | Matcher[]} isDateDisabled Param that defines if date is disabled for selection
 * @param {Boolean} isOpen Current open/close mode.
 * @param {Boolean} isRangeMode Is Range or Single picker mode.
 * @param {Boolean} footer Footer
 * @param {Date} defaultMonthDate The date the month of which will be displayed by default after opening the picker.
 * @param {Matcher} value Current selected value. It can be a Date or a DateRange, depending on the selected picker mode.
 * @param {Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>} textInputProps Input props.
 * @param {boolean | MessageDescriptor} applyAction Apply action button label or enabler.
 * @param {boolean | MessageDescriptor} cancelAction Cancel action button label or enabler.
 * @param {string | MessageDescriptor} placeholder Input placeholder.
 * @param {string} className Input class name.
 * @param {string} formattedValue Formatted value.
 */
export const DatePicker: FC<DatePickerProps> = ({
    captionComponent,
    displayMode = 'desktop',
    isOpen,
    value,
    formattedValue,
    locale,
    defaultMonthDate,
    isDateSelected,
    isDateDisabled,
    disableFutureDates = false,
    onDropdownOpen,
    onDropdownClose,
    onSelect,
    isApplyDisabled,
    onApplyClick,
    onCancelClick,
    onOutsideClick,
    isRangeMode,
    placeholder,
    className,
    applyAction = true,
    cancelAction = true,
    footer,
    iconPlacement = 'start',
    type = 'large',
    ...textInputProps
}) => {
    const {classes} = useClasses();
    const datePickerStyles = useDatePickerStyles(type, isRangeMode);
    const isMobile = displayMode === 'mobile';
    const calendarIconPlacementClass = iconPlacement === 'start' ? classes.calendarIconStart : classes.calendarIconEnd;
    const calendarIcon = isOpen ? `${classes.calendarIcon} ${classes.calendarIconOpen}` : classes.calendarIcon;
    const calendarIconComponent = (
        <Box component="span" className={`${CustomIcon.CalendarOutline} ${calendarIcon}  ${calendarIconPlacementClass}`} />
    );

    const selectHandleProps = {
        onSelect,
    };

    const [currentDate, setCurrentDate] = useState<Date>(defaultMonthDate ? defaultMonthDate : getTodayAsDate());
    const handleYearMonthChange = (date: Date) => {
        setCurrentDate(date);
    };

    const toDate = disableFutureDates ? getTodayAsDate() : null;

    const defaultFooter = isRangeMode ? (
        <DatePickerFooterRangeMode value={value as DateRange} locale={locale} onChange={onSelect} />
    ) : (
        <DatePickerFooterSingleMode value={value as Date} locale={locale} onChange={onSelect} />
    );

    const Caption = captionComponent;
    const rangeDayPicker = (
        <DayPicker
            {...selectHandleProps}
            mode={'range'}
            locale={localeMapping[locale]}
            defaultMonth={defaultMonthDate}
            showOutsideDays
            month={currentDate}
            onMonthChange={handleYearMonthChange}
            selected={value as DateRange}
            disabled={isDateDisabled}
            formatters={{
                formatWeekdayName: (date: Date) => formatDayOfWeek(date, 'ddd'),
            }}
            toDate={toDate}
            classNames={datePickerStyles}
            today={getTodayAsDate()}
            components={{
                CaptionLabel: (_props: CaptionLabelProps) =>
                    captionComponent ? (
                        <Caption date={currentDate} onChange={handleYearMonthChange} />
                    ) : (
                        <DatePickerYearMonthForm
                            date={currentDate}
                            disableFutureDates={disableFutureDates}
                            onChange={handleYearMonthChange}
                        />
                    ),
            }}
            footer={footer ?? defaultFooter}
        ></DayPicker>
    );
    const singleDayPicker = (
        <DayPicker
            {...selectHandleProps}
            mode={'single'}
            locale={localeMapping[locale]}
            defaultMonth={defaultMonthDate}
            showOutsideDays
            month={currentDate}
            onMonthChange={handleYearMonthChange}
            selected={value as Date}
            disabled={isDateDisabled}
            formatters={{
                formatWeekdayName: (date: Date) => formatDayOfWeek(date, 'ddd'),
            }}
            toDate={toDate}
            classNames={datePickerStyles}
            components={{
                CaptionLabel: (_props: CaptionLabelProps) =>
                    captionComponent ? (
                        <Caption date={currentDate} onChange={handleYearMonthChange} />
                    ) : (
                        <DatePickerYearMonthForm
                            date={currentDate}
                            disableFutureDates={disableFutureDates}
                            onChange={handleYearMonthChange}
                        />
                    ),
            }}
            footer={footer ?? defaultFooter}
        ></DayPicker>
    );

    const dayPicker = isRangeMode ? rangeDayPicker : singleDayPicker;

    useEffect(() => {
        if (!isRangeMode && currentDate) {
            const newDate = getDateForMonthAndYear(value as Date, currentDate, disableFutureDates.valueOf());
            onSelect(newDate);
            if (newDate.getMonth() !== currentDate.getMonth() || newDate.getFullYear() !== currentDate.getFullYear()) {
                setCurrentDate(newDate);
            }
        }
    }, [currentDate]);

    useEffect(() => {
        handleYearMonthChange(defaultMonthDate ? defaultMonthDate : getTodayAsDate());
    }, [defaultMonthDate?.getMonth(), defaultMonthDate?.getFullYear()]);

    function getDateForMonthAndYear(date: Date, month: Date, disableFuture: boolean): Date {
        let result: Date;
        const today = getTodayAsDate();

        if (date) {
            result = date as Date;
            result.setMonth(month.getMonth());
            result.setFullYear(month.getFullYear());
        } else {
            result = month;
        }

        return disableFuture && result > today ? today : result;
    }

    return isMobile ? (
        dayPicker
    ) : (
        <StyledInputDropdown
            className={`${classes.datePickerInputFillAvailableWidth} ${className}`}
            data-testid="dateRangePicker"
            value={formattedValue}
            onChange={() => {}}
            startAdornment={iconPlacement === 'start' ? calendarIconComponent : null}
            endAdornment={iconPlacement === 'end' ? calendarIconComponent : null}
            buttonContent={''}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            closeOnClickInside={false}
            closeOnClickOutside={true}
            applyAction={applyAction}
            cancelAction={isDateSelected && cancelAction ? localized.datePickerClearLabel : cancelAction}
            onApplyClick={onApplyClick}
            isApplyDisabled={isApplyDisabled}
            onCancelClick={onCancelClick}
            onOutsideClick={onOutsideClick}
            onDropdownOpen={onDropdownOpen}
            onDropdownClose={onDropdownClose}
            styles={{
                dropdownContainer: datePickerStyles.dropdownContainer,
            }}
            placeholder={placeholder}
            {...textInputProps}
        >
            {dayPicker}
        </StyledInputDropdown>
    );
};
