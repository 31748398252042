import React from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {AccessControlIndicator, withPolicyAccessCheck} from '@access-control';
import {FeatureAccessStatus} from '@models/generated/graphql';

import {userProfileActions} from '../actions';
import {gamesActionsReadResources, gamesActionsUpdateResources} from '../permissions';

import {LockActionProps} from './UserProfileActions';
import {UserProfileLock} from './UserProfileLock';

const localized = defineMessages({
    label: {
        id: 'SportsbookLock_label',
        defaultMessage: 'Sportsbook Lock',
    },
});

type SportsbookLockProps = LockActionProps<FeatureAccessStatus>;

function SportsbookLockInner({uid, status, disabled}: SportsbookLockProps) {
    const {formatMessage} = useIntl();
    return (
        <UserProfileLock
            action={userProfileActions.changeSportsbookStatus}
            label={formatMessage(localized.label)}
            uid={uid}
            isLocked={status === FeatureAccessStatus.Locked}
            disabled={disabled}
            dataTestId="sportsbook-status-lock"
        />
    );
}

export const SportsbookLock = withPolicyAccessCheck(
    withPolicyAccessCheck(SportsbookLockInner, gamesActionsUpdateResources, AccessControlIndicator.Disabled),
    gamesActionsReadResources,
    AccessControlIndicator.Hidden
);
