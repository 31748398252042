export const CustomIcon = {
    Confirm: 'icon-confirm',
    More: 'icon-more',
    Access: 'icon-access',
    Profile: 'icon-profile',
    Bag: 'icon-bag',
    FileBox: 'icon-file_box',
    Database: 'icon-database',
    Logout: 'icon-logout',
    Earth: 'icon-earth',
    Bell: 'icon-bell',
    ArrowDown: 'icon-arrow_down',
    ArrowUp: 'icon-arrow_up',
    PinOutline: 'icon-pin_outline',
    UnpinOutline: 'icon-unpin_outline',
    ActionOutline: 'icon-action_outline',
    AddSquareOutline: 'icon-add_square_outline',
    CalendarOutline: 'icon-calendar_outline',
    CancelOutline: 'icon-cancel_outline',
    MoreVerticalOutline: 'icon-more_vertical_outline',
    MoveOutline: 'icon-move_outline',
    PowerOutline: 'icon-power_outline',
    SmsOutline: 'icon-sms_outline',
    TransferOutline: 'icon-transfer_outline',
    ViewOutline: 'icon-view_outline',
    WarningOutline: 'icon-warning_outline',
    ClockOutline: 'icon-clock_outline',
    NotesOutline: 'icon-notes_outline',
    WriteOutline: 'icon-write_outline',
    Search: 'icon-search',
    Drag: 'icon-drag',
    Mail: 'icon-mail_outline',
    Gear: 'icon-gear_outline',
    Finance: 'icon-finance_outline',
    Withdrawal: 'icon-withdrawal_outline',
    Block: 'icon-block',
    CheckDouble: 'icon-check_double_outline',
    LessThan: 'icon-less_than',
    GreaterThan: 'icon-greater_than',
    Equals: 'icon-equals',
    Between: 'icon-between',
    SortOldestFirst: 'icon-sort_oldest_first',
    SortNewestFirst: 'icon-sort_newest_first',
    Download: 'icon-download_outline',
    Linked: 'icon-linked',
    Union: 'icon-union',
    Details: 'icon-details_outline',
    UserOutline: 'icon-user_outline',
    Headphones: 'icon-headphones',
    ErrorCircleOutline: 'icon-error_circle_outline_20',
    ArrowRightSquareOutline: 'icon-arrow_right_square_outline',
    Target: 'icon-target',
    AddCircleOutline: 'icon-add_circle_outline',
    DeleteOutlineAndroid: 'icon-delete_outline_android',
    ListPlayOutline: 'icon-list_play_outline',
    Add: 'icon-add',
    NarrativeOutline: 'icon-narrative_outline',
    TicketOutline: 'icon-ticket_outline',
    CodeBranch: 'icon-code_branch',
    ErrorCircle: 'icon-error_circle',
    ExclamationPoint: 'icon-exclamation_point',
    File: 'icon-file',
    CheckList: 'icon-checklist',
    ConfirmCircle: 'icon-confirm_circle',
    ArticleOutline: 'icon-article_outline',
    Copy: 'icon-copy',
    ServicesOutline: 'icon-services_outline',
    Eye: 'icon-eye',
    Filter: 'icon-filter',
    Emoji: 'icon-emoji',
    Bold: 'icon-bold',
    Italic: 'icon-italic',
    Link: 'icon-link',
} as const;

export type CustomIconKeys = keyof typeof CustomIcon;

export type CustomIconValues = (typeof CustomIcon)[CustomIconKeys];
