import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {StyledCheckbox} from '@components/checkbox/StyledCheckbox';
import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import {TreeViewFilterOption} from '@components/filter';
import LocalizedText from '@components/i18n/LocalizedText';

const useStyles = makeStyles()(theme => ({
    treeViewFilterItemLabel: {
        display: 'flex',
        gap: theme.spacing(),
        alignItems: 'center',
    },
}));

type TreeViewFilterItemLabelProps = {
    option: TreeViewFilterOption;
    isSelected: boolean;
    showTotalCount: boolean;
};

export function TreeViewFilterItemLabel({option, isSelected, showTotalCount}: TreeViewFilterItemLabelProps) {
    const {classes} = useStyles();
    return (
        <div className={classes.treeViewFilterItemLabel}>
            {option?.startIcon === 'checkbox' ? <StyledCheckbox checkedIconType="checkmark" checked={isSelected} /> : option?.startIcon}
            <LocalizedText label={option?.label} />
            {showTotalCount && option?.subOptions?.length ? (
                <Chip
                    chipType={ChipType.Status}
                    chipVariant={ChipVariant.LightGrey}
                    label={option?.subOptions?.length}
                    size="small"
                    fillWidth={false}
                />
            ) : null}
        </div>
    );
}
