import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import {ManagedGameType} from '@redux/entity';

import {GamePlayersModalButton} from './GamePlayersModalButton';

const useStyles = makeStyles()({
    playersCountCellContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
});

export type PlayersCellProps = {
    playerCount: number;
    playerMaxCount: number;
    roomId: string;
    roomName: string;
    gameType: ManagedGameType;
};

export function PlayersCell({playerCount, playerMaxCount, roomId, roomName, gameType}: PlayersCellProps) {
    const {classes} = useStyles();
    const chipLabel = `${playerCount}/${playerMaxCount}`;
    return (
        <div className={classes.playersCountCellContainer}>
            <Chip fillWidth={false} chipType={ChipType.Status} chipVariant={ChipVariant.Grey} label={chipLabel} />
            <GamePlayersModalButton roomId={roomId} roomName={roomName} gameType={gameType} />
        </div>
    );
}

export function renderPlayersCell(params: GridCellParams) {
    const value: PlayersCellProps = params.value as PlayersCellProps;
    return <PlayersCell {...value} />;
}
