import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Box, Switch as MuiSwitch, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledTooltip from '@components/Tooltip';
import {CustomTheme} from '@style';

import {isStringNullOrEmpty} from '../../utils/string';

const localized = defineMessages({
    disabled: {
        id: 'ToggleSwitch_disabled',
        defaultMessage: 'Toggle is disabled',
    },
});

const useClasses = makeStyles<void, 'toggleSwitch'>()((theme: CustomTheme, _params, classes) => ({
    toggleSwitch: {
        display: 'flex',
        columnGap: theme.spacing(1),
        alignItems: 'center',
    },
    toggleSwitchAligned: {
        justifyContent: 'flex-start',
    },
    toggleSwitchWithBorder: {
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    toggleSwitchWithFieldLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 0),
        [`& .${classes.toggleSwitch}`]: {
            marginLeft: theme.spacing(1.5),
        },
    },
    toggleSwitchLabelAlignRight: {
        flexDirection: 'row-reverse',
    },
}));

export type ToggleSwitchProps = {
    label?: string;
    value: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    fieldName?: string;
    className?: string;
    withBorder?: boolean;
    alignLeft?: boolean;
    labelAlignRight?: boolean;
};

export function ToggleSwitch({
    fieldName,
    value,
    label,
    onChange,
    disabled,
    className,
    withBorder,
    alignLeft,
    labelAlignRight,
}: ToggleSwitchProps) {
    const {classes, cx} = useClasses();
    const {formatMessage} = useIntl();

    function renderSwitch() {
        return <MuiSwitch checked={value} onChange={event => onChange(event?.target?.checked)} disabled={disabled} color="secondary" />;
    }

    return (
        <Box
            className={cx(
                {
                    [classes.toggleSwitchAligned]: alignLeft,
                    [classes.toggleSwitchWithBorder]: withBorder,
                    [classes.toggleSwitchWithFieldLabel]: !isStringNullOrEmpty(fieldName),
                },
                className
            )}
        >
            <Typography>{fieldName}</Typography>
            <Box
                className={cx(classes.toggleSwitch, {
                    [classes.toggleSwitchLabelAlignRight]: labelAlignRight,
                })}
            >
                {label ? (
                    <Typography variant="subtitle2" color={disabled ? 'textSecondary' : undefined}>
                        {label}
                    </Typography>
                ) : null}
                {disabled ? (
                    <StyledTooltip title={formatMessage(localized.disabled)} element={<div>{renderSwitch()}</div>}></StyledTooltip>
                ) : (
                    renderSwitch()
                )}
            </Box>
        </Box>
    );
}
