import {defineMessages} from 'react-intl';

import {Gender} from '@models/generated/graphql';

export const localizedGender = defineMessages({
    [Gender.Female]: {
        id: 'genderFemale',
        defaultMessage: 'Female',
    },
    [Gender.Male]: {
        id: 'genderMale',
        defaultMessage: 'Male',
    },
    [Gender.Undefined]: {
        id: 'genderUndefined',
        defaultMessage: 'Undefined',
    },
});
