export class UserActivitySummaryViewModel {
    uid: string;
    average_bet?: number;
    average_poker_buy_in?: number;
    casino_favorite_games?: string[];
    days_from_last_active?: number;
    last_played_game?: string;
    poker_favorite_games?: string[];
    skill_flag?: string;
    slot_favorite_games?: string[];
}

export type UserActivitySummaryViewModelKeys = keyof UserActivitySummaryViewModel;
