import {parse} from 'acorn';

import {
    BooleanExpression,
    BooleanOperator,
    NodeBinaryExpression,
    NodeBooleanOperator,
    NodeIdentifier,
    NodeLiteral,
    NodeLogicalExpression,
    NodeMemberExpression,
    NodeProgram,
    RelationalExpression,
} from './types';

export class ExpressionBuilder {
    build(exp: BooleanExpression): string {
        return this.buildBooleanExpression(exp) ?? '';
    }

    parse(programExp: string): BooleanExpression | RelationalExpression {
        let isLogicalOrBinaryExp;
        let logicalExp: NodeLogicalExpression | NodeBinaryExpression = undefined;

        try {
            const exp = (
                parse(programExp?.replaceAll('AND', '&&').replaceAll('OR', '||'), {
                    ecmaVersion: 'latest',
                }) as NodeProgram
            ).body?.[0]?.expression;
            logicalExp = exp as NodeLogicalExpression | NodeBinaryExpression;
            isLogicalOrBinaryExp = logicalExp.operator !== undefined;
        } catch (error) {
            isLogicalOrBinaryExp = false;
        }

        return isLogicalOrBinaryExp ? this.convertLogicalOrBinaryExpression(logicalExp) : null;
    }

    private buildRelationalExpression(exp: RelationalExpression) {
        let res = null;

        if (exp) {
            const {left: key, operator, right: value} = exp;
            const valueStr = typeof value === 'string' ? `"${value}"` : value;
            res = `${key}${operator}${valueStr}`;
        }

        return res;
    }

    private buildBooleanExpression(exp: BooleanExpression): string {
        const res = exp?.conditions
            .map(i => {
                const booleanExp = i as BooleanExpression;
                const relExpression = i as RelationalExpression;
                let res = null;

                if (relExpression?.left) {
                    res = this.buildRelationalExpression(relExpression);
                } else {
                    res = this.buildBooleanExpression(booleanExp);
                    res = booleanExp?.conditions.length > 1 && booleanExp?.operator === 'OR' ? `(${res})` : res;
                }

                return res;
            })
            .filter(i => i)
            .join(` ${exp.operator} `);
        return res;
    }

    private convertLogicalOrBinaryExpression(exp: NodeBinaryExpression | NodeLogicalExpression) {
        const isLogical = exp?.operator === '&&' || exp.operator === '||';
        return isLogical
            ? this.convertLogicalExpression(exp as NodeLogicalExpression)
            : this.convertBinaryExpression(exp as NodeBinaryExpression);
    }

    private convertLogicalExpression(exp: NodeLogicalExpression): BooleanExpression {
        const {left, right, operator} = exp;
        const operatorMapping: Record<NodeBooleanOperator, BooleanOperator> = {
            '&&': 'AND',
            '||': 'OR',
        };
        let res: BooleanExpression;

        if (exp) {
            res = {
                conditions: [this.convertLogicalOrBinaryExpression(left), this.convertLogicalOrBinaryExpression(right)]?.filter(e => e),
                operator: operatorMapping[operator],
            };
        }

        return res;
    }

    private convertBinaryExpression(exp: NodeBinaryExpression): RelationalExpression {
        let res: RelationalExpression;

        if (exp) {
            const {left, right, operator} = exp;
            const key = this.getBinaryExpressionKey(left);
            const value = (right as NodeLiteral)?.value;
            res = {left: key, right: value, operator};
        }

        return res;
    }

    private getBinaryExpressionKey(exp: NodeMemberExpression | NodeIdentifier): string {
        let key = undefined;

        if (exp) {
            key = '';
            const node = exp as NodeMemberExpression;
            const isIdentifier = node.type === 'Identifier';

            if (!isIdentifier) {
                key = `${this.getBinaryExpressionKey(node.object)}.${this.getBinaryExpressionKey(node.property)}`;
            } else {
                key = node.name;
            }
        }

        return key;
    }
}
