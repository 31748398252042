import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {MultiLineAutoAdjustedWidthTooltip} from '@components/tooltip/MultiLineAutoAdjustedWidthTooltip';

import {CurrencyCell} from './CurrencyCell';

type StakesCellLayoutProps = {
    sb: React.ReactNode;
    bb: React.ReactNode;
    straddle: React.ReactNode;
    ante: React.ReactNode;
};

function StakesCellLayout({sb, bb, straddle, ante}: StakesCellLayoutProps) {
    return (
        <>
            {sb}/{bb}
            {straddle ? <>/{straddle}</> : null} ({ante})
        </>
    );
}

export type StakesCellProps = {
    sb: number;
    bb: number;
    hasStraddle: boolean;
    ante: number;
    currency: string;
};

function StakesCell({sb, bb, hasStraddle, ante, currency}: StakesCellProps) {
    return (
        <StakesCellLayout
            sb={<CurrencyCell currency={currency} value={sb} />}
            bb={<CurrencyCell currency={currency} value={bb} />}
            straddle={hasStraddle ? <CurrencyCell currency={currency} value={bb * 2} /> : null}
            ante={<CurrencyCell currency={currency} value={ante} />}
        />
    );
}

export function renderStakesCell(params: GridCellParams) {
    const value: StakesCellProps = params.value as StakesCellProps;
    return (
        <MultiLineAutoAdjustedWidthTooltip>
            <StakesCell {...value} />
        </MultiLineAutoAdjustedWidthTooltip>
    );
}
