import {defineMessages} from 'react-intl';

export const localizedValueRangePicker = defineMessages({
    anyValue: {
        id: 'FilterValueRangePicker_anyValue',
        defaultMessage: 'Any',
    },
    isLessThan: {
        id: 'FilterValueRangePicker_isLessThan',
        defaultMessage: 'is less than',
    },
    isGreaterThan: {
        id: 'FilterValueRangePicker_isGreaterThan',
        defaultMessage: 'is greater than',
    },
    equals: {
        id: 'FilterValueRangePicker_equals',
        defaultMessage: 'equals',
    },
    between: {
        id: 'FilterValueRangePicker_between',
        defaultMessage: 'between',
    },
    operator: {
        id: 'FilterValueRangePicker_operator',
        defaultMessage: 'Operator',
    },
    RelativeRange: {
        id: 'FilterValueRangePicker_RelativeRange',
        defaultMessage: 'Relative Date',
    },
    valueRangeValue: {
        id: 'FilterValueRangePicker_valueRangeValue',
        defaultMessage: 'Value',
    },
    firstValueRangeInput: {
        id: 'FilterValueRangePicker_firstValueRangeInput',
        defaultMessage: 'From',
    },
    secondValueRangeInput: {
        id: 'FilterValueRangePicker_secondValueRangeInput',
        defaultMessage: 'To',
    },
    singleValueRangeInput: {
        id: 'FilterValueRangePicker_singleValueRangeInput',
        defaultMessage: 'Value',
    },
    openDuration: {
        id: 'FilterValueRangePicker_openDuration',
        defaultMessage: 'Open Duration',
    },
    clearAll: {
        id: 'FilterValueRangePicker_clearAll',
        defaultMessage: 'Clear All',
    },
    apply: {
        id: 'FilterValueRangePicker_apply',
        defaultMessage: 'Apply',
    },
    any: {
        id: 'FilterValueRangePicker_any',
        defaultMessage: 'Any',
    },

    selectBoxBetween: {
        id: 'FilterValueRangePicker_selectBoxBetween',
        defaultMessage: 'between: {from}-{to}',
    },
    selectBoxEquals: {
        id: 'FilterValueRangePicker_selectBoxEquals',
        defaultMessage: 'equals: {from}',
    },
    selectBoxIsGreaterThan: {
        id: 'FilterValueRangePicker_selectBoxIsGreaterThan',
        defaultMessage: 'is greater than: {from}',
    },
    selectBoxIsLessThan: {
        id: 'FilterValueRangePicker_selectBoxIsLessThan',
        defaultMessage: 'is less than: {to}',
    },
});
