import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {useDispatch} from 'react-redux';
import {PayloadActionCreator} from 'typesafe-actions';

import {Button} from '@components/button/Buttons';

import {IModuleItem} from '../types';

import {useModuleCreateActionClasses} from './ModuleCreateAction.style';

type ModuleCreateActionProps = {
    openFormAction: PayloadActionCreator<string, IModuleItem>;
    label?: MessageDescriptor;
};

const localized = defineMessages({
    addLabel: {
        id: 'addLabel',
        defaultMessage: 'Add',
    },
});

const ModuleCreateAction = ({openFormAction, label = localized.addLabel}: ModuleCreateActionProps) => {
    const dispatch = useDispatch();
    const {classes} = useModuleCreateActionClasses();

    const onClick = () => {
        dispatch(openFormAction({} as IModuleItem));
    };

    return <Button className={classes.moduleCreateActionButton} color="primary" label={label} onClick={onClick} />;
};

export default ModuleCreateAction;
