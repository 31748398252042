import React from 'react';
import {defineMessages} from 'react-intl';

import {
    DateRangeFilter,
    DateRangeFilterValue,
    FilterGroupNew,
    FilterGroupNewFilter,
    FilterProps,
    FiltersProps,
    MultiStepFilterDatePicker,
    SelectFilter,
    TextFilter,
} from '@components/filter';
import {TicketStatus} from '@models/sportsbook-transaction/type';

import {SelectOption} from 'src/features/module-shared/types';
import {useJurisdictionConfig} from '../../app/config/hooks';
import {SportsbookTransactionFilterModel, SportsbookTransactionFilterName} from '../types';

type SportsbookTransactionFiltersProps = FiltersProps<SportsbookTransactionFilterModel, SportsbookTransactionFilterName>;

const placeholders = defineMessages({
    ticketId: {
        id: 'SportsbookTransactionFilters_ticketId',
        defaultMessage: 'Ticket ID',
    },
    compUserId: {
        id: 'SportsbookTransactionFilters_compUserId',
        defaultMessage: 'WPTG platform Player ID',
    },
    matchId: {
        id: 'SportsbookTransactionFilters_matchId',
        defaultMessage: 'Match ID',
    },
    tournamentName: {
        id: 'SportsbookTransactionFilters_tournamentName',
        defaultMessage: 'League Name',
    },
    ticketStatus: {
        id: 'SportsbookTransactionFilters_ticketStatus',
        defaultMessage: 'Ticket Status',
    },
});

export function SportsbookTransactionFilters({model, onChange, availableFilters}: SportsbookTransactionFiltersProps) {
    const {locale} = useJurisdictionConfig();

    function getSelectOptionForTicketStatus(): SelectOption[] {
        const statuses = [
            TicketStatus.Unknown,
            TicketStatus.Awaiting,
            TicketStatus.Rejected,
            TicketStatus.Accepted,
            TicketStatus.Voided,
            TicketStatus.Settled,
            TicketStatus.Canceled,
        ];
        return statuses.map(status => ({value: status, label: status}));
    }

    const filters: FilterGroupNewFilter<SportsbookTransactionFilterModel, SportsbookTransactionFilterName>[] = [
        {
            filterName: 'ticketStatus',
            modelField: 'ticketStatus',
            component: ({onChange, ...props}: FilterProps<string>) => (
                <SelectFilter
                    label={placeholders.ticketStatus}
                    {...props}
                    onChange={onChange}
                    chipType={nameof<TicketStatus>()}
                    options={getSelectOptionForTicketStatus()}
                />
            ),
            collapseOnMobile: false,
        },
        {
            filterName: 'ticketId',
            modelField: 'ticketId',
            component: (props: FilterProps<string>) => <TextFilter inputPlaceholder={placeholders.ticketId} {...props} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'compUserId',
            modelField: 'compUserId',
            component: (props: FilterProps<string>) => <TextFilter inputPlaceholder={placeholders.compUserId} {...props} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'matchId',
            modelField: 'matchId',
            component: (props: FilterProps<string>) => <TextFilter inputPlaceholder={placeholders.matchId} {...props} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'tournamentName',
            modelField: 'tournamentName',
            component: (props: FilterProps<string>) => <TextFilter inputPlaceholder={placeholders.tournamentName} {...props} />,
            collapseOnMobile: false,
        },
        {
            filterName: 'betTime',
            modelField: 'betTime',
            component: props => <DateRangeFilter {...props} locale={locale} />,
            componentMobile: (props: FilterProps<DateRangeFilterValue>) => <MultiStepFilterDatePicker defaultState={{locale}} {...props} />,
            collapseOnMobile: true,
        },
    ];

    return (
        <FilterGroupNew
            model={model}
            onChange={onChange}
            mode="update-on-change"
            allFilters={filters}
            availableFilters={availableFilters}
        />
    );
}
