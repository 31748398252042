import React from 'react';
import {FormGroup as MuiFormGroup, FormGroupProps as MuiFormGroupProps} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    formGroup: {
        rowGap: theme.spacing(3),
    },
    formGroupHighlighted: {
        padding: theme.spacing(3),
        backgroundColor: theme.custom.palette.content.backgroundLight,
    },
}));

type FormGroupProps = Omit<MuiFormGroupProps, 'style'> & {
    style?: 'default' | 'highlighted';
};

export function FormGroup({style = 'default', children, ...props}: FormGroupProps) {
    const {classes, cx} = useClasses();

    return (
        <MuiFormGroup {...props} className={cx({[classes.formGroupHighlighted]: style === 'highlighted'}, classes.formGroup)}>
            {children}
        </MuiFormGroup>
    );
}
