import React from 'react';
import {defineMessages} from 'react-intl';

import {FilterProps, TreeViewFilter, TreeViewFilterOption} from '@components/filter';
import {PentagonSvgIcon} from '@components/icons';

import {usePlayerLabelGroups} from '../../block-label-group-actions/hooks';

const localized = defineMessages({
    filterLabel: {
        id: 'LabelFilter_label',
        defaultMessage: 'Labels',
    },
});

type LabelFilterProps = FilterProps<string[]>;

export function LabelFilter({value, onChange}: LabelFilterProps) {
    const {labelGroups} = usePlayerLabelGroups();

    function getTreeViewFilterOptions(): TreeViewFilterOption[] {
        return labelGroups?.map<TreeViewFilterOption>(group => ({
            value: group?.id,
            label: group?.name,
            startIcon: <PentagonSvgIcon color={group?.color} />,
            subOptions: group?.labels?.map(label => ({
                value: label?.id,
                label: label?.name,
                startIcon: 'checkbox',
                subOptions: [],
            })),
        }));
    }

    return <TreeViewFilter label={localized.filterLabel} value={value} onChange={onChange} options={getTreeViewFilterOptions()} />;
}
