import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    dropzoneSupportedFormats: {
        id: 'DZ_supportedFormats',
        defaultMessage: 'Supported formats: ',
    },
    dropzoneMaxFileSize: {
        id: 'DZ_maxFileSize',
        defaultMessage: 'Maximum size: {maxSizeInMB}MB',
    },
});
