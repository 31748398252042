import React, {useEffect} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {MessageDescriptor} from 'react-intl';
import {Box, Grid} from '@mui/material';
import equal from 'fast-deep-equal/es6';

import Button, {OutlinedButton} from '@components/button/Buttons';
import {SelectButton} from '@components/select';

import withModuleFilter, {FilterInnerProps} from './../ModuleFilterHoc';
import {localizedValueRangePicker} from './localizeValueRangePicker';
import {OperatorSelect} from './OperatorSelect';
import {RelativeRange} from './RelativeRange';
import {useSelectBoxValue} from './valueRangePickerHooks';
import {useValueRangePickerClasses} from './valueRangePickerStyle';
import {defaultValueRangeModel, ValueRangePickerModel, ValueRangePickerOptions, ValueRangePickerType} from './valueRangePickerTypes';
import {
    convertToDateRangeInSeconds,
    convertToDateRangePickerModel,
    convertToNumberRange,
    convertToNumberRangePickerModel,
} from './valueRangePickerUtils';

export const ValueRangePicker = ({filter, value, onSubmit}: FilterInnerProps<string>) => {
    const {classes} = useValueRangePickerClasses();
    const [fromKey, toKey] = filter.key;
    const methods = useForm<ValueRangePickerModel>({defaultValues: defaultValueRangeModel});
    const {reset, control, handleSubmit} = methods;
    const watched = useWatch({control});
    const {type, rangeLabel} = filter.options as ValueRangePickerOptions;
    const valueRangeFilterParsed =
        type === ValueRangePickerType.Hours
            ? convertToDateRangePickerModel({fromKey, toKey, value})
            : convertToNumberRangePickerModel({fromKey, toKey, value});
    const selectedText = useSelectBoxValue(valueRangeFilterParsed, type);

    useEffect(() => {
        if (!equal(watched, valueRangeFilterParsed)) {
            reset(valueRangeFilterParsed);
        }
    }, [value.map(v => `${v.key}${v.value}`).join()]);

    useEffect(() => {
        if (valueRangeFilterParsed.operator !== watched.operator) {
            const {from, to} = defaultValueRangeModel;

            reset({
                operator: watched.operator,
                from,
                to,
            });
        }
    }, [watched.operator]);

    const callOnSubmit = (data: ValueRangePickerModel, _: React.BaseSyntheticEvent) => {
        const {from, to} = type === ValueRangePickerType.Hours ? convertToDateRangeInSeconds(data) : convertToNumberRange(data);

        onSubmit([
            {key: fromKey, value: from},
            {key: toKey, value: to},
        ]);
    };

    const handleClear = () => {
        const {from, to} = defaultValueRangeModel;

        onSubmit([
            {key: fromKey, value: from},
            {key: toKey, value: to},
        ]);
    };

    return (
        <SelectButton label={filter.label as MessageDescriptor} selectedText={selectedText}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(callOnSubmit)}>
                    <Box className={classes.valueRangePickerContainer} data-testid="valueRangePickerContainer">
                        <OperatorSelect label={localizedValueRangePicker.operator} />
                        <RelativeRange operator={watched.operator} label={rangeLabel} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <OutlinedButton
                                    className={classes.valueRangePickerClearAll}
                                    label={localizedValueRangePicker.clearAll}
                                    onClick={handleClear}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    type="submit"
                                    className={classes.valueRangePickerApply}
                                    color="primary"
                                    label={localizedValueRangePicker.apply}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </FormProvider>
        </SelectButton>
    );
};

const FilterValueRangePicker = withModuleFilter(ValueRangePicker);

export default FilterValueRangePicker;
