import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {mergeMap} from '@otel';
import {RootEpic} from '@redux';

// TODO: [BO-3037] rework breadcrumb when ModulePage is removed, to avoid app dependency
import routes from '../../../app/router/routes';
import {protectEpics} from '../error-handling/epics';
import {locationPathnameSelector} from '../routing/selectors';

import {setBreadcrumbAction, setBreadcrumbSuccessAction} from './actions';
import {prepareBreadcrumb} from './utils';

const setBreadcrumbEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(setBreadcrumbAction)),
        mergeMap(action => {
            let breadcrumb = [];

            if (action.payload.length) {
                breadcrumb = action.payload;
            } else {
                const pathname = locationPathnameSelector(state$.value);
                breadcrumb = prepareBreadcrumb(routes, pathname);
            }

            return of(setBreadcrumbSuccessAction(breadcrumb));
        })
    );

export default protectEpics(setBreadcrumbEpic);
