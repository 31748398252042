import {constructUsing, createMap, extend, forMember, mapFrom, Mapper, mapWith, mapWithArguments} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {
    AgentPlayerReportingViewModel,
    AgentPlayerReportingViewModelKeys,
    AgentReportingDownloadModel,
    BonusSummaryViewModel,
    DeductionSummaryViewModel,
    GgrSummaryViewModel,
    NgrSummaryViewModel,
    OtherSummaryViewModel,
    PlayerGamesSummaryViewModel,
} from '@models/agent-player-reporting';
import {
    AgentPlayerReporting,
    AgentPlayerReportingKr,
    AgentPlayerReportingVn,
    BonusSummary,
    DeductionSummary,
    GgrSummary,
    NgrSummary,
    OtherSummary,
    PlayerGamesSummary,
    ProSummary,
} from '@models/generated/graphql';
import {
    AgentPlayerReportingExtended,
    AgentPlayerReportingKrExtended,
    AgentPlayerReportingQueryFields,
    AgentPlayerReportingSortingFields,
    AgentPlayerReportingVnExtended,
} from '@redux/entity';
import {IsNever} from '@utils/type';

import {PlayerLevelReportColumnSettingsKeys, ProSummaryViewModel} from './types';

export type AgentPlayerReportingMappingArgs = {
    currency: string;
};

export class AgentPlayerReportingMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            BonusSummary,
            BonusSummaryViewModel,
            forMember(
                vm => vm.mtt_ticket_bonus,
                mapFrom(m => m.mtt_ticket_bonus)
            ),
            forMember(
                vm => vm.poker_bonus,
                mapFrom(m => m.poker_bonus)
            ),
            forMember(
                vm => vm.casino_bonus,
                mapFrom(m => m.casino_bonus)
            ),
            forMember(
                vm => vm.realized_bonus_total,
                mapFrom(m => m.realized_bonus_total)
            ),
            forMember(
                vm => vm.unrealized_bonus_total,
                mapFrom(m => m.unrealized_bonus_total)
            ),
            forMember(
                vm => vm.cost_global_spins_tickets,
                mapFrom(m => m.cost_global_spins_tickets)
            )
        );
        createMap(
            mapper,
            DeductionSummary,
            DeductionSummaryViewModel,
            forMember(
                vm => vm.casino_provider_fee,
                mapFrom(m => m.casino_provider_fee)
            ),
            forMember(
                vm => vm.ngr_cost,
                mapFrom(m => m.ngr_cost)
            ),
            forMember(
                vm => vm.psp_fee,
                mapFrom(m => m.psp_fee)
            ),
            forMember(
                vm => vm.total,
                mapFrom(m => m.total)
            )
        );
        createMap(
            mapper,
            GgrSummary,
            GgrSummaryViewModel,
            forMember(
                vm => vm.cash_ggr,
                mapFrom(m => m.cash_ggr)
            ),
            forMember(
                vm => vm.casino_ggr,
                mapFrom(m => m.casino_ggr)
            ),
            forMember(
                vm => vm.casino_share,
                mapFrom(m => m.casino_share)
            ),
            forMember(
                vm => vm.total_share,
                mapFrom(m => m.total_share)
            ),
            forMember(
                vm => vm.global_spin_ggr,
                mapFrom(m => m.global_spin_ggr)
            ),
            forMember(
                vm => vm.mtt_ggr,
                mapFrom(m => m.mtt_ggr)
            ),
            forMember(
                vm => vm.poker_share,
                mapFrom(m => m.poker_share)
            ),
            forMember(
                vm => vm.external_casino_ggr,
                mapFrom(m => m.external_casino_ggr)
            ),
            forMember(
                vm => vm.internal_casino_ggr,
                mapFrom(m => m.internal_casino_ggr)
            ),
            forMember(
                vm => vm.external_casino_bet_amount,
                mapFrom(m => m.external_casino_bet_amount)
            ),
            forMember(
                vm => vm.internal_casino_bet_amount,
                mapFrom(m => m.internal_casino_bet_amount)
            ),
            forMember(
                vm => vm.plo_ggr_high_stake,
                mapFrom(m => m?.plo_ggr_high_stake)
            ),
            forMember(
                vm => vm.plo_ggr_low_stake,
                mapFrom(m => m?.plo_ggr_low_stake)
            ),
            forMember(
                vm => vm.plo_ggr_medium_stake,
                mapFrom(m => m?.plo_ggr_medium_stake)
            ),
            forMember(
                vm => vm.plo_ggr_micro_stake,
                mapFrom(m => m?.plo_ggr_micro_stake)
            ),
            forMember(
                vm => vm.plo_ggr_total,
                mapFrom(m => m?.plo_ggr_total)
            ),
            forMember(
                vm => vm.plo_ggr_unknown_stake,
                mapFrom(m => m?.plo_ggr_unknown_stake)
            ),
            forMember(
                vm => vm.poker_addons,
                mapFrom(m => m.poker_addons)
            ),
            forMember(
                vm => vm.poker_ggr,
                mapFrom(m => m.poker_ggr)
            ),
            forMember(
                vm => vm.poker_mtt_fees,
                mapFrom(m => m.poker_mtt_fees)
            ),
            forMember(
                vm => vm.poker_rake,
                mapFrom(m => m.poker_rake)
            ),
            forMember(
                vm => vm.total,
                mapFrom(m => m.total)
            ),
            forMember(
                vm => vm.mtt_total_buyin,
                mapFrom(m => m.mtt_total_buyin)
            )
        );
        createMap(
            mapper,
            NgrSummary,
            NgrSummaryViewModel,
            forMember(
                vm => vm.cash_ngr,
                mapFrom(m => m.cash_ngr)
            ),
            forMember(
                vm => vm.casino_ngr,
                mapFrom(m => m.casino_ngr)
            ),
            forMember(
                vm => vm.mtt_ngr,
                mapFrom(m => m.mtt_ngr)
            ),
            forMember(
                vm => vm.poker_ngr,
                mapFrom(m => m.poker_ngr)
            ),
            forMember(
                vm => vm.total,
                mapFrom(m => m.total)
            )
        );
        createMap(
            mapper,
            OtherSummary,
            OtherSummaryViewModel,
            forMember(
                vm => vm.cost_tournament_overlay_excl_freeroll,
                mapFrom(m => m.cost_tournament_overlay_excl_freeroll)
            )
        );
        createMap(
            mapper,
            PlayerGamesSummary,
            PlayerGamesSummaryViewModel,
            forMember(
                vm => vm.cash_game_hand_played,
                mapFrom(m => m.cash_game_hand_played)
            ),
            forMember(
                vm => vm.mtt_played,
                mapFrom(m => m.mtt_played)
            ),
            forMember(
                vm => vm.player_net_deposit,
                mapFrom(m => m.player_net_deposit)
            ),
            forMember(
                vm => vm.player_profit_loss,
                mapFrom(m => m.player_profit_loss)
            )
        );
        createMap(
            mapper,
            ProSummary,
            ProSummaryViewModel,
            forMember(
                vm => vm.is_pro_shortdeck,
                mapFrom(m => m.is_pro_shortdeck)
            ),
            forMember(
                vm => vm.cost_spins_tournament_tickets_pro,
                mapFrom(m => m.cost_spins_tournament_tickets_pro)
            ),
            forMember(
                vm => vm.cost_ftd_bonus_pro,
                mapFrom(m => m.cost_ftd_bonus_pro)
            ),
            forMember(
                vm => vm.cost_psp_fee_pro,
                mapFrom(m => m.cost_psp_fee_pro)
            ),
            forMember(
                vm => vm.poker_ggr_usd_pro,
                mapFrom(m => m.poker_ggr_usd_pro)
            ),
            forMember(
                vm => vm.nlhe_ggr_usd_pro,
                mapFrom(m => m.nlhe_ggr_usd_pro)
            ),
            forMember(
                vm => vm.shortdeck_ggr_usd_pro,
                mapFrom(m => m.shortdeck_ggr_usd_pro)
            ),
            forMember(
                vm => vm.nlhe_micro_ggr_usd_pro,
                mapFrom(m => m.nlhe_micro_ggr_usd_pro)
            ),
            forMember(
                vm => vm.nlhe_low_ggr_usd_pro,
                mapFrom(m => m.nlhe_low_ggr_usd_pro)
            ),
            forMember(
                vm => vm.nlhe_medium_ggr_usd_pro,
                mapFrom(m => m.nlhe_medium_ggr_usd_pro)
            ),
            forMember(
                vm => vm.nlhe_high_ggr_usd_pro,
                mapFrom(m => m.nlhe_high_ggr_usd_pro)
            )
        );
        createMap(
            mapper,
            AgentPlayerReporting,
            AgentPlayerReportingViewModel,
            forMember(
                vm => vm.bonus_summary,
                mapWith(BonusSummaryViewModel, BonusSummary, m => m.bonus_summary)
            ),
            forMember(
                vm => vm.deduction_summary,
                mapWith(DeductionSummaryViewModel, DeductionSummary, m => m.deduction_summary)
            ),
            forMember(
                vm => vm.ggr_summary,
                mapWith(GgrSummaryViewModel, GgrSummary, m => m.ggr_summary)
            ),
            forMember(
                vm => vm.ngr_summary,
                mapWith(NgrSummaryViewModel, NgrSummary, m => m.ngr_summary)
            ),
            forMember(
                vm => vm.player_games_summary,
                mapWith(PlayerGamesSummaryViewModel, PlayerGamesSummary, m => m.player_games_summary)
            ),
            forMember(
                vm => vm.pro_summary,
                mapWith(ProSummaryViewModel, ProSummary, m => m.pro_summary)
            ),
            forMember(
                vm => vm.highest_blind_level_is_pro_nlhe,
                mapFrom(m => m.highest_blind_level_is_pro_nlhe)
            ),
            forMember(
                vm => vm.country,
                mapFrom(m => m.iso_alpha2_country_code)
            ),
            forMember(
                vm => vm.currency,
                mapWithArguments((_, {currency}: AgentPlayerReportingMappingArgs) => currency)
            ),
            forMember(
                vm => vm.email,
                mapFrom(m => m.contact?.email)
            ),
            forMember(
                vm => vm.id,
                mapFrom(m => `${m?.referrer_agent_id}_${m?.uid}`)
            ),
            forMember(
                vm => vm.phone,
                mapFrom(m => m.contact?.mobile?.full_number)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.referrer_agent_id,
                mapFrom(m => m.referrer_agent_id)
            ),
            forMember(
                vm => vm.referrer_agent_username,
                mapFrom(m => m.referrer_agent_username)
            ),
            forMember(
                vm => vm.username,
                mapFrom(m => m.username)
            ),
            forMember(
                vm => vm.register_marketing_code,
                mapFrom(m => m.register_marketing_code)
            ),
            forMember(
                vm => vm.other_summary,
                mapWith(OtherSummaryViewModel, OtherSummary, m => m.other_summary)
            )
        );
        createMap(
            mapper,
            AgentPlayerReportingExtended,
            AgentPlayerReportingViewModel,
            extend(AgentPlayerReporting, AgentPlayerReportingViewModel),
            forMember(
                vm => vm.is_summary,
                mapFrom(m => m.is_summary)
            )
        );
        createMap(
            mapper,
            AgentPlayerReportingVn,
            AgentPlayerReportingViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => `${m.referrer_agent_id}_${m?.uid}_${m?.revenue_share_type}_${m?.referee_type}`)
            ),
            forMember(
                vm => vm.email,
                mapFrom(m => m.contact?.email)
            ),
            forMember(
                vm => vm.phone,
                mapFrom(m => m.contact?.mobile?.full_number)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.uid_link,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.uid_text,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.referrer_agent_id,
                mapFrom(m => m.referrer_agent_id)
            ),
            forMember(
                vm => vm.referrer_agent_username,
                mapFrom(m => m.referrer_agent_username)
            ),
            forMember(
                vm => vm.revenue_share_type,
                mapFrom(m => m.revenue_share_type)
            ),
            forMember(
                vm => vm.referee_type,
                mapFrom(m => m.referee_type)
            ),
            forMember(
                vm => vm.currency,
                mapWithArguments((_, {currency}: AgentPlayerReportingMappingArgs) => currency)
            ),
            forMember(
                vm => vm.rakes,
                mapFrom(m => m.rakes ?? 0)
            ),
            forMember(
                vm => vm.insurance_tool,
                mapFrom(m => m.insurance_tool ?? 0)
            ),
            forMember(
                vm => vm.casino_ggr,
                mapFrom(m => m.casino_ggr ?? 0)
            ),
            forMember(
                vm => vm.registered_player_number,
                mapFrom(m => m.registered_player_number ?? 0)
            ),
            forMember(
                vm => vm.active_player_number,
                mapFrom(m => m.active_player_number ?? 0)
            ),
            forMember(
                vm => vm.revenue_share,
                mapFrom(m => m.revenue_share ?? 0)
            )
        );
        createMap(
            mapper,
            AgentPlayerReportingVnExtended,
            AgentPlayerReportingViewModel,
            extend(AgentPlayerReportingVn, AgentPlayerReportingViewModel),
            forMember(
                vm => vm.is_summary,
                mapFrom(m => m.is_summary)
            )
        );
        createMap(
            mapper,
            AgentPlayerReportingKr,
            AgentPlayerReportingViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => `${m.referrer_agent_id}_${m?.uid}_${m?.revenue_share_type}_${m?.referee_type}`)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.referrer_agent_id,
                mapFrom(m => m.referrer_agent_id)
            ),
            forMember(
                vm => vm.referrer_agent_username,
                mapFrom(m => m.referrer_agent_username)
            ),
            forMember(
                vm => vm.revenue_share_type,
                mapFrom(m => m.revenue_share_type)
            ),
            forMember(
                vm => vm.referee_type,
                mapFrom(m => m.referee_type)
            ),
            forMember(
                vm => vm.total_share,
                mapFrom(m => m.total_share)
            ),
            forMember(
                vm => vm.poker_share,
                mapFrom(m => m.poker_share)
            ),
            forMember(
                vm => vm.casino_share,
                mapFrom(m => m.casino_share)
            )
        );
        createMap(
            mapper,
            AgentPlayerReportingKrExtended,
            AgentPlayerReportingViewModel,
            extend(AgentPlayerReportingKr, AgentPlayerReportingViewModel),
            forMember(
                vm => vm.is_summary,
                mapFrom(m => m.is_summary)
            )
        );
        createMap(
            mapper,
            AgentPlayerReportingVn,
            AgentReportingDownloadModel,
            extend(AgentPlayerReportingVn, AgentPlayerReportingViewModel),
            forMember(
                vm => vm.uid_text,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.uid_link,
                mapFrom(m => m.uid)
            )
        );
        createMap(
            mapper,
            AgentPlayerReporting,
            AgentReportingDownloadModel,
            extend(AgentPlayerReporting, AgentPlayerReportingViewModel),
            forMember(
                vm => vm.country,
                mapFrom(m => m.iso_alpha2_country_code)
            ),
            forMember(
                vm => vm.currency,
                mapWithArguments((_, {currency}: AgentPlayerReportingMappingArgs) => currency)
            ),
            forMember(
                vm => vm.email,
                mapFrom(m => m.contact?.email)
            ),
            forMember(
                vm => vm.id,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.phone,
                mapFrom(m => m.contact?.mobile?.full_number)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.uid_link,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.referrer_agent_id,
                mapFrom(m => m.referrer_agent_id)
            ),
            forMember(
                vm => vm.referrer_agent_username,
                mapFrom(m => m.referrer_agent_username)
            ),
            forMember(
                vm => vm.username,
                mapFrom(m => m.username)
            ),
            forMember(
                vm => vm['bonus_summary.mtt_ticket_bonus'],
                mapFrom(m => m.bonus_summary?.mtt_ticket_bonus?.toString())
            ),
            forMember(
                vm => vm['bonus_summary.poker_bonus'],
                mapFrom(m => m.bonus_summary?.poker_bonus?.toString())
            ),
            forMember(
                vm => vm['bonus_summary.casino_bonus'],
                mapFrom(m => m.bonus_summary?.casino_bonus?.toString())
            ),
            forMember(
                vm => vm['bonus_summary.realized_bonus_total'],
                mapFrom(m => m.bonus_summary?.realized_bonus_total?.toString())
            ),
            forMember(
                vm => vm['bonus_summary.unrealized_bonus_total'],
                mapFrom(m => m.bonus_summary?.unrealized_bonus_total?.toString())
            ),
            forMember(
                vm => vm['bonus_summary.cost_global_spins_tickets'],
                mapFrom(m => m.bonus_summary?.cost_global_spins_tickets?.toString())
            ),

            forMember(
                vm => vm['deduction_summary.casino_provider_fee'],
                mapFrom(m => m.deduction_summary?.casino_provider_fee?.toString())
            ),
            forMember(
                vm => vm['deduction_summary.ngr_cost'],
                mapFrom(m => m.deduction_summary?.ngr_cost?.toString())
            ),
            forMember(
                vm => vm['deduction_summary.psp_fee'],
                mapFrom(m => m.deduction_summary?.psp_fee?.toString())
            ),
            forMember(
                vm => vm['deduction_summary.total'],
                mapFrom(m => m.deduction_summary?.total?.toString())
            ),

            forMember(
                vm => vm['ggr_summary.cash_ggr'],
                mapFrom(m => m.ggr_summary?.cash_ggr?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.casino_ggr'],
                mapFrom(m => m.ggr_summary?.casino_ggr?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.casino_share'],
                mapFrom(m => m.ggr_summary?.casino_share?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.total_share'],
                mapFrom(m => m.ggr_summary?.total_share?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.global_spin_ggr'],
                mapFrom(m => m.ggr_summary?.global_spin_ggr?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.external_casino_ggr'],
                mapFrom(m => m.ggr_summary?.external_casino_ggr?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.internal_casino_ggr'],
                mapFrom(m => m.ggr_summary?.internal_casino_ggr?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.external_casino_bet_amount'],
                mapFrom(m => m.ggr_summary?.external_casino_bet_amount?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.internal_casino_bet_amount'],
                mapFrom(m => m.ggr_summary?.internal_casino_bet_amount?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.plo_ggr_high_stake'],
                mapFrom(m => m.ggr_summary?.plo_ggr_high_stake?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.plo_ggr_low_stake'],
                mapFrom(m => m.ggr_summary?.plo_ggr_low_stake?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.plo_ggr_medium_stake'],
                mapFrom(m => m.ggr_summary?.plo_ggr_medium_stake?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.plo_ggr_micro_stake'],
                mapFrom(m => m.ggr_summary?.plo_ggr_micro_stake?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.plo_ggr_total'],
                mapFrom(m => m.ggr_summary?.plo_ggr_total?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.plo_ggr_unknown_stake'],
                mapFrom(m => m.ggr_summary?.plo_ggr_unknown_stake?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.poker_addons'],
                mapFrom(m => m.ggr_summary?.poker_addons?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.poker_ggr'],
                mapFrom(m => m.ggr_summary?.poker_ggr?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.poker_mtt_fees'],
                mapFrom(m => m.ggr_summary?.poker_mtt_fees?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.poker_rake'],
                mapFrom(m => m.ggr_summary?.poker_rake?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.mtt_ggr'],
                mapFrom(m => m.ggr_summary?.mtt_ggr?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.poker_share'],
                mapFrom(m => m.ggr_summary?.poker_share?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.total'],
                mapFrom(m => m.ggr_summary?.total?.toString())
            ),
            forMember(
                vm => vm['ggr_summary.mtt_total_buyin'],
                mapFrom(m => m.ggr_summary?.mtt_total_buyin?.toString())
            ),

            forMember(
                vm => vm['ngr_summary.cash_ngr'],
                mapFrom(m => m.ngr_summary?.cash_ngr?.toString())
            ),
            forMember(
                vm => vm['ngr_summary.casino_ngr'],
                mapFrom(m => m.ngr_summary?.casino_ngr?.toString())
            ),
            forMember(
                vm => vm['ngr_summary.mtt_ngr'],
                mapFrom(m => m.ngr_summary?.mtt_ngr?.toString())
            ),
            forMember(
                vm => vm['ngr_summary.poker_ngr'],
                mapFrom(m => m.ngr_summary?.poker_ngr?.toString())
            ),
            forMember(
                vm => vm['ngr_summary.total'],
                mapFrom(m => m.ngr_summary?.total?.toString())
            ),

            forMember(
                vm => vm['player_games_summary.cash_game_hand_played'],
                mapFrom(m => m.player_games_summary?.cash_game_hand_played?.toString())
            ),
            forMember(
                vm => vm['player_games_summary.mtt_played'],
                mapFrom(m => m.player_games_summary?.mtt_played?.toString())
            ),
            forMember(
                vm => vm['player_games_summary.player_net_deposit'],
                mapFrom(m => m.player_games_summary?.player_net_deposit?.toString())
            ),
            forMember(
                vm => vm['player_games_summary.player_profit_loss'],
                mapFrom(m => m.player_games_summary?.player_profit_loss?.toString())
            ),
            forMember(
                vm => vm['other_summary.cost_tournament_overlay_excl_freeroll'],
                mapFrom(m => m.other_summary?.cost_tournament_overlay_excl_freeroll?.toString())
            ),
            forMember(
                vm => vm['highest_blind_level_is_pro_nlhe'],
                mapFrom(m => m.highest_blind_level_is_pro_nlhe)
            ),
            forMember(
                vm => vm['pro_summary.cost_ftd_bonus_pro'],
                mapFrom(m => m.pro_summary?.cost_ftd_bonus_pro?.toString())
            ),
            forMember(
                vm => vm['pro_summary.cost_psp_fee_pro'],
                mapFrom(m => m.pro_summary?.cost_psp_fee_pro?.toString())
            ),
            forMember(
                vm => vm['pro_summary.cost_spins_tournament_tickets_pro'],
                mapFrom(m => m.pro_summary?.cost_spins_tournament_tickets_pro?.toString())
            ),
            forMember(
                vm => vm['pro_summary.is_pro_shortdeck'],
                mapFrom(m => m.pro_summary?.is_pro_shortdeck?.toString())
            ),
            forMember(
                vm => vm['pro_summary.nlhe_ggr_usd_pro'],
                mapFrom(m => m.pro_summary?.nlhe_ggr_usd_pro?.toString())
            ),
            forMember(
                vm => vm['pro_summary.nlhe_high_ggr_usd_pro'],
                mapFrom(m => m.pro_summary?.nlhe_high_ggr_usd_pro?.toString())
            ),
            forMember(
                vm => vm['pro_summary.nlhe_low_ggr_usd_pro'],
                mapFrom(m => m.pro_summary?.nlhe_low_ggr_usd_pro?.toString())
            ),
            forMember(
                vm => vm['pro_summary.nlhe_medium_ggr_usd_pro'],
                mapFrom(m => m.pro_summary?.nlhe_medium_ggr_usd_pro?.toString())
            ),
            forMember(
                vm => vm['pro_summary.nlhe_micro_ggr_usd_pro'],
                mapFrom(m => m.pro_summary?.nlhe_micro_ggr_usd_pro?.toString())
            ),
            forMember(
                vm => vm['pro_summary.poker_ggr_usd_pro'],
                mapFrom(m => m.pro_summary?.poker_ggr_usd_pro?.toString())
            ),
            forMember(
                vm => vm['pro_summary.shortdeck_ggr_usd_pro'],
                mapFrom(m => m.pro_summary?.shortdeck_ggr_usd_pro?.toString())
            )
        );
        createMap<AgentPlayerReportingViewModelKeys, AgentPlayerReportingSortingFields>(
            mapper,
            nameof<AgentPlayerReportingViewModelKeys>(),
            nameof<AgentPlayerReportingSortingFields>(),
            constructUsing((m, _) => {
                const mapper: Record<AgentPlayerReportingViewModelKeys, AgentPlayerReportingSortingFields> = {
                    id: 'uid',
                    currency: null,
                    uid: 'uid',
                    uid_link: 'uid',
                    uid_text: 'uid',
                    username: 'username',
                    phone: 'contact.mobile.full_number',
                    email: 'contact.email',
                    country: 'iso_alpha2_country_code',
                    referrer_agent_id: 'referrer_agent_id',
                    referrer_agent_username: 'referrer_agent_username',
                    'ggr_summary.cash_ggr': 'ggr_summary.cash_ggr',
                    'ggr_summary.mtt_ggr': 'ggr_summary.mtt_ggr',
                    'ggr_summary.casino_ggr': 'ggr_summary.casino_ggr',
                    'ggr_summary.casino_share': 'ggr_summary.casino_share',
                    'ggr_summary.total_share': 'ggr_summary.total_share',
                    'ggr_summary.global_spin_ggr': 'ggr_summary.global_spin_ggr',
                    'ggr_summary.external_casino_ggr': 'ggr_summary.external_casino_ggr',
                    'ggr_summary.internal_casino_ggr': 'ggr_summary.internal_casino_ggr',
                    'ggr_summary.external_casino_bet_amount': 'ggr_summary.external_casino_bet_amount',
                    'ggr_summary.internal_casino_bet_amount': 'ggr_summary.internal_casino_bet_amount',
                    'ggr_summary.plo_ggr_high_stake': 'ggr_summary.plo_ggr_high_stake',
                    'ggr_summary.plo_ggr_low_stake': 'ggr_summary.plo_ggr_low_stake',
                    'ggr_summary.plo_ggr_medium_stake': 'ggr_summary.plo_ggr_medium_stake',
                    'ggr_summary.plo_ggr_micro_stake': 'ggr_summary.plo_ggr_micro_stake',
                    'ggr_summary.plo_ggr_total': 'ggr_summary.plo_ggr_total',
                    'ggr_summary.plo_ggr_unknown_stake': 'ggr_summary.plo_ggr_unknown_stake',
                    'ggr_summary.poker_addons': 'ggr_summary.poker_addons',
                    'ggr_summary.poker_ggr': 'ggr_summary.poker_ggr',
                    'ggr_summary.poker_mtt_fees': 'ggr_summary.poker_mtt_fees',
                    'ggr_summary.poker_rake': 'ggr_summary.poker_rake',
                    'ggr_summary.poker_share': 'ggr_summary.poker_share',
                    'ggr_summary.total': 'ggr_summary.total',
                    'ggr_summary.mtt_total_buyin': 'ggr_summary.mtt_total_buyin',
                    'ngr_summary.cash_ngr': 'ngr_summary.cash_ngr',
                    'ngr_summary.casino_ngr': 'ngr_summary.casino_ngr',
                    'ngr_summary.mtt_ngr': 'ngr_summary.mtt_ngr',
                    'ngr_summary.poker_ngr': 'ngr_summary.poker_ngr',
                    'ngr_summary.total': 'ngr_summary.total',
                    'bonus_summary.realized_bonus_total': 'bonus_summary.realized_bonus_total',
                    'bonus_summary.mtt_ticket_bonus': 'bonus_summary.mtt_ticket_bonus',
                    'bonus_summary.poker_bonus': 'bonus_summary.poker_bonus',
                    'bonus_summary.casino_bonus': 'bonus_summary.casino_bonus',
                    'bonus_summary.unrealized_bonus_total': 'bonus_summary.unrealized_bonus_total',
                    'bonus_summary.cost_global_spins_tickets': 'bonus_summary.cost_global_spins_tickets',
                    'deduction_summary.casino_provider_fee': 'deduction_summary.casino_provider_fee',
                    'deduction_summary.ngr_cost': 'deduction_summary.ngr_cost',
                    'deduction_summary.psp_fee': 'deduction_summary.psp_fee',
                    'deduction_summary.total': 'deduction_summary.total',
                    'player_games_summary.cash_game_hand_played': 'player_games_summary.cash_game_hand_played',
                    'player_games_summary.mtt_played': 'player_games_summary.mtt_played',
                    'player_games_summary.player_net_deposit': 'player_games_summary.player_net_deposit',
                    'player_games_summary.player_profit_loss': 'player_games_summary.player_profit_loss',
                    'other_summary.cost_tournament_overlay_excl_freeroll': 'other_summary.cost_tournament_overlay_excl_freeroll',
                    revenue_share_type: 'revenue_share_type',
                    referee_type: 'referee_type',
                    rakes: 'rakes',
                    insurance_tool: 'insurance_tool',
                    casino_ggr: 'casino_ggr',
                    registered_player_number: 'registered_player_number',
                    active_player_number: 'active_player_number',
                    revenue_share: 'revenue_share',
                    register_marketing_code: 'register_marketing_code',
                    is_summary: null,
                    total_share: 'total_share',
                    poker_share: 'poker_share',
                    casino_share: 'casino_share',
                    has_summary: 'summary',
                    highest_blind_level_is_pro_nlhe: 'highest_blind_level_is_pro_nlhe',
                    'pro_summary.cost_ftd_bonus_pro': 'pro_summary.cost_ftd_bonus_pro',
                    'pro_summary.cost_psp_fee_pro': 'pro_summary.cost_psp_fee_pro',
                    'pro_summary.cost_spins_tournament_tickets_pro': 'pro_summary.cost_spins_tournament_tickets_pro',
                    'pro_summary.is_pro_shortdeck': 'pro_summary.is_pro_shortdeck',
                    'pro_summary.nlhe_ggr_usd_pro': 'pro_summary.nlhe_ggr_usd_pro',
                    'pro_summary.nlhe_high_ggr_usd_pro': 'pro_summary.nlhe_high_ggr_usd_pro',
                    'pro_summary.nlhe_low_ggr_usd_pro': 'pro_summary.nlhe_low_ggr_usd_pro',
                    'pro_summary.nlhe_medium_ggr_usd_pro': 'pro_summary.nlhe_medium_ggr_usd_pro',
                    'pro_summary.nlhe_micro_ggr_usd_pro': 'pro_summary.nlhe_micro_ggr_usd_pro',
                    'pro_summary.poker_ggr_usd_pro': 'pro_summary.poker_ggr_usd_pro',
                    'pro_summary.shortdeck_ggr_usd_pro': 'pro_summary.shortdeck_ggr_usd_pro',
                };

                return mapper[m];
            })
        );
        createMap<AgentPlayerReportingViewModelKeys[], AgentPlayerReportingQueryFields[]>(
            mapper,
            nameof<AgentPlayerReportingViewModelKeys>(),
            nameof<AgentPlayerReportingQueryFields>(),
            constructUsing((m, _) => {
                const itemsMapper: Record<AgentPlayerReportingViewModelKeys, AgentPlayerReportingQueryFields> = {
                    id: 'items.uid',
                    currency: null,
                    uid: 'items.uid',
                    uid_link: 'items.uid',
                    uid_text: 'items.uid',
                    username: 'items.username',
                    phone: 'items.contact.mobile.full_number',
                    email: 'items.contact.email',
                    country: 'items.iso_alpha2_country_code',
                    referrer_agent_id: 'items.referrer_agent_id',
                    referrer_agent_username: 'items.referrer_agent_username',
                    'ggr_summary.cash_ggr': 'items.ggr_summary.cash_ggr',
                    'ggr_summary.mtt_ggr': 'items.ggr_summary.mtt_ggr',
                    'ggr_summary.casino_ggr': 'items.ggr_summary.casino_ggr',
                    'ggr_summary.casino_share': 'items.ggr_summary.casino_share',
                    'ggr_summary.total_share': 'items.ggr_summary.total_share',
                    'ggr_summary.global_spin_ggr': 'items.ggr_summary.global_spin_ggr',
                    'ggr_summary.external_casino_ggr': 'items.ggr_summary.external_casino_ggr',
                    'ggr_summary.internal_casino_ggr': 'items.ggr_summary.internal_casino_ggr',
                    'ggr_summary.external_casino_bet_amount': 'items.ggr_summary.external_casino_bet_amount',
                    'ggr_summary.internal_casino_bet_amount': 'items.ggr_summary.internal_casino_bet_amount',
                    'ggr_summary.plo_ggr_high_stake': 'items.ggr_summary.plo_ggr_high_stake',
                    'ggr_summary.plo_ggr_low_stake': 'items.ggr_summary.plo_ggr_low_stake',
                    'ggr_summary.plo_ggr_medium_stake': 'items.ggr_summary.plo_ggr_medium_stake',
                    'ggr_summary.plo_ggr_micro_stake': 'items.ggr_summary.plo_ggr_micro_stake',
                    'ggr_summary.plo_ggr_total': 'items.ggr_summary.plo_ggr_total',
                    'ggr_summary.plo_ggr_unknown_stake': 'items.ggr_summary.plo_ggr_unknown_stake',
                    'ggr_summary.poker_addons': 'items.ggr_summary.poker_addons',
                    'ggr_summary.poker_ggr': 'items.ggr_summary.poker_ggr',
                    'ggr_summary.poker_mtt_fees': 'items.ggr_summary.poker_mtt_fees',
                    'ggr_summary.poker_rake': 'items.ggr_summary.poker_rake',
                    'ggr_summary.poker_share': 'items.ggr_summary.poker_share',
                    'ggr_summary.total': 'items.ggr_summary.total',
                    'ggr_summary.mtt_total_buyin': 'items.ggr_summary.mtt_total_buyin',
                    'ngr_summary.cash_ngr': 'items.ngr_summary.cash_ngr',
                    'ngr_summary.casino_ngr': 'items.ngr_summary.casino_ngr',
                    'ngr_summary.mtt_ngr': 'items.ngr_summary.mtt_ngr',
                    'ngr_summary.poker_ngr': 'items.ngr_summary.poker_ngr',
                    'ngr_summary.total': 'items.ngr_summary.total',
                    'bonus_summary.realized_bonus_total': 'items.bonus_summary.realized_bonus_total',
                    'bonus_summary.mtt_ticket_bonus': 'items.bonus_summary.mtt_ticket_bonus',
                    'bonus_summary.poker_bonus': 'items.bonus_summary.poker_bonus',
                    'bonus_summary.casino_bonus': 'items.bonus_summary.casino_bonus',
                    'bonus_summary.unrealized_bonus_total': 'items.bonus_summary.unrealized_bonus_total',
                    'bonus_summary.cost_global_spins_tickets': 'items.bonus_summary.cost_global_spins_tickets',
                    'deduction_summary.casino_provider_fee': 'items.deduction_summary.casino_provider_fee',
                    'deduction_summary.ngr_cost': 'items.deduction_summary.ngr_cost',
                    'deduction_summary.psp_fee': 'items.deduction_summary.psp_fee',
                    'deduction_summary.total': 'items.deduction_summary.total',
                    'player_games_summary.cash_game_hand_played': 'items.player_games_summary.cash_game_hand_played',
                    'player_games_summary.mtt_played': 'items.player_games_summary.mtt_played',
                    'player_games_summary.player_net_deposit': 'items.player_games_summary.player_net_deposit',
                    'player_games_summary.player_profit_loss': 'items.player_games_summary.player_profit_loss',
                    'other_summary.cost_tournament_overlay_excl_freeroll': 'items.other_summary.cost_tournament_overlay_excl_freeroll',
                    revenue_share_type: 'items.revenue_share_type',
                    referee_type: 'items.referee_type',
                    rakes: 'items.rakes',
                    insurance_tool: 'items.insurance_tool',
                    casino_ggr: 'items.casino_ggr',
                    registered_player_number: 'items.registered_player_number',
                    active_player_number: 'items.active_player_number',
                    revenue_share: 'items.revenue_share',
                    register_marketing_code: 'items.register_marketing_code',
                    is_summary: null,
                    total_share: 'items.total_share',
                    poker_share: 'items.poker_share',
                    casino_share: 'items.casino_share',
                    has_summary: null,
                    highest_blind_level_is_pro_nlhe: 'items.highest_blind_level_is_pro_nlhe',
                    'pro_summary.cost_ftd_bonus_pro': 'items.pro_summary.cost_ftd_bonus_pro',
                    'pro_summary.cost_psp_fee_pro': 'items.pro_summary.cost_psp_fee_pro',
                    'pro_summary.cost_spins_tournament_tickets_pro': 'items.pro_summary.cost_spins_tournament_tickets_pro',
                    'pro_summary.is_pro_shortdeck': 'items.pro_summary.is_pro_shortdeck',
                    'pro_summary.nlhe_ggr_usd_pro': 'items.pro_summary.nlhe_ggr_usd_pro',
                    'pro_summary.nlhe_high_ggr_usd_pro': 'items.pro_summary.nlhe_high_ggr_usd_pro',
                    'pro_summary.nlhe_low_ggr_usd_pro': 'items.pro_summary.nlhe_low_ggr_usd_pro',
                    'pro_summary.nlhe_medium_ggr_usd_pro': 'items.pro_summary.nlhe_medium_ggr_usd_pro',
                    'pro_summary.nlhe_micro_ggr_usd_pro': 'items.pro_summary.nlhe_micro_ggr_usd_pro',
                    'pro_summary.poker_ggr_usd_pro': 'items.pro_summary.poker_ggr_usd_pro',
                    'pro_summary.shortdeck_ggr_usd_pro': 'items.pro_summary.shortdeck_ggr_usd_pro',
                };
                const summaryMapper: Record<AgentPlayerReportingViewModelKeys, AgentPlayerReportingQueryFields> = {
                    id: null,
                    currency: null,
                    uid: null,
                    uid_link: null,
                    uid_text: null,
                    username: null,
                    phone: null,
                    email: null,
                    country: null,
                    referrer_agent_id: null,
                    referrer_agent_username: null,
                    'ggr_summary.cash_ggr': 'summary.ggr_summary.cash_ggr',
                    'ggr_summary.mtt_ggr': 'summary.ggr_summary.mtt_ggr',
                    'ggr_summary.casino_ggr': 'summary.ggr_summary.casino_ggr',
                    'ggr_summary.casino_share': 'summary.ggr_summary.casino_share',
                    'ggr_summary.total_share': 'summary.ggr_summary.total_share',
                    'ggr_summary.global_spin_ggr': 'summary.ggr_summary.global_spin_ggr',
                    'ggr_summary.external_casino_ggr': 'summary.ggr_summary.external_casino_ggr',
                    'ggr_summary.internal_casino_ggr': 'summary.ggr_summary.internal_casino_ggr',
                    'ggr_summary.external_casino_bet_amount': 'summary.ggr_summary.external_casino_bet_amount',
                    'ggr_summary.internal_casino_bet_amount': 'summary.ggr_summary.internal_casino_bet_amount',
                    'ggr_summary.plo_ggr_high_stake': 'summary.ggr_summary.plo_ggr_high_stake',
                    'ggr_summary.plo_ggr_low_stake': 'summary.ggr_summary.plo_ggr_low_stake',
                    'ggr_summary.plo_ggr_medium_stake': 'summary.ggr_summary.plo_ggr_medium_stake',
                    'ggr_summary.plo_ggr_micro_stake': 'summary.ggr_summary.plo_ggr_micro_stake',
                    'ggr_summary.plo_ggr_total': 'summary.ggr_summary.plo_ggr_total',
                    'ggr_summary.plo_ggr_unknown_stake': 'summary.ggr_summary.plo_ggr_unknown_stake',
                    'ggr_summary.poker_addons': 'summary.ggr_summary.poker_addons',
                    'ggr_summary.poker_ggr': 'summary.ggr_summary.poker_ggr',
                    'ggr_summary.poker_mtt_fees': 'summary.ggr_summary.poker_mtt_fees',
                    'ggr_summary.poker_rake': 'summary.ggr_summary.poker_rake',
                    'ggr_summary.poker_share': 'summary.ggr_summary.poker_share',
                    'ggr_summary.total': 'summary.ggr_summary.total',
                    'ggr_summary.mtt_total_buyin': 'summary.ggr_summary.mtt_total_buyin',
                    'ngr_summary.cash_ngr': 'summary.ngr_summary.cash_ngr',
                    'ngr_summary.casino_ngr': 'summary.ngr_summary.casino_ngr',
                    'ngr_summary.mtt_ngr': 'summary.ngr_summary.mtt_ngr',
                    'ngr_summary.poker_ngr': 'summary.ngr_summary.poker_ngr',
                    'ngr_summary.total': 'summary.ngr_summary.total',
                    'bonus_summary.realized_bonus_total': 'summary.bonus_summary.realized_bonus_total',
                    'bonus_summary.mtt_ticket_bonus': 'summary.bonus_summary.mtt_ticket_bonus',
                    'bonus_summary.poker_bonus': 'summary.bonus_summary.poker_bonus',
                    'bonus_summary.casino_bonus': 'summary.bonus_summary.casino_bonus',
                    'bonus_summary.unrealized_bonus_total': 'summary.bonus_summary.unrealized_bonus_total',
                    'bonus_summary.cost_global_spins_tickets': 'summary.bonus_summary.cost_global_spins_tickets',
                    'deduction_summary.casino_provider_fee': 'summary.deduction_summary.casino_provider_fee',
                    'deduction_summary.ngr_cost': 'summary.deduction_summary.ngr_cost',
                    'deduction_summary.psp_fee': 'summary.deduction_summary.psp_fee',
                    'deduction_summary.total': 'summary.deduction_summary.total',
                    'player_games_summary.cash_game_hand_played': 'summary.player_games_summary.cash_game_hand_played',
                    'player_games_summary.mtt_played': 'summary.player_games_summary.mtt_played',
                    'player_games_summary.player_net_deposit': 'summary.player_games_summary.player_net_deposit',
                    'player_games_summary.player_profit_loss': 'summary.player_games_summary.player_profit_loss',
                    'other_summary.cost_tournament_overlay_excl_freeroll': 'summary.other_summary.cost_tournament_overlay_excl_freeroll',
                    revenue_share_type: null,
                    referee_type: null,
                    rakes: 'summary.rakes',
                    insurance_tool: 'summary.insurance_tool',
                    casino_ggr: 'summary.casino_ggr',
                    registered_player_number: null,
                    active_player_number: null,
                    revenue_share: 'summary.revenue_share',
                    register_marketing_code: null,
                    is_summary: null,
                    total_share: 'summary.total_share',
                    poker_share: 'summary.poker_share',
                    casino_share: 'summary.casino_share',
                    has_summary: null,
                    highest_blind_level_is_pro_nlhe: null,
                    'pro_summary.cost_ftd_bonus_pro': 'summary.pro_summary.cost_ftd_bonus_pro',
                    'pro_summary.cost_psp_fee_pro': 'summary.pro_summary.cost_psp_fee_pro',
                    'pro_summary.cost_spins_tournament_tickets_pro': 'summary.pro_summary.cost_spins_tournament_tickets_pro',
                    'pro_summary.is_pro_shortdeck': 'summary.pro_summary.is_pro_shortdeck',
                    'pro_summary.nlhe_ggr_usd_pro': 'summary.pro_summary.nlhe_ggr_usd_pro',
                    'pro_summary.nlhe_high_ggr_usd_pro': 'summary.pro_summary.nlhe_high_ggr_usd_pro',
                    'pro_summary.nlhe_low_ggr_usd_pro': 'summary.pro_summary.nlhe_low_ggr_usd_pro',
                    'pro_summary.nlhe_medium_ggr_usd_pro': 'summary.pro_summary.nlhe_medium_ggr_usd_pro',
                    'pro_summary.nlhe_micro_ggr_usd_pro': 'summary.pro_summary.nlhe_micro_ggr_usd_pro',
                    'pro_summary.poker_ggr_usd_pro': 'summary.pro_summary.poker_ggr_usd_pro',
                    'pro_summary.shortdeck_ggr_usd_pro': 'summary.pro_summary.shortdeck_ggr_usd_pro',
                };

                const itemsQueryFields = m.map(i => itemsMapper[i]);
                const summaryQueryFields = m.includes('has_summary') ? m.map(i => summaryMapper[i]) : [];
                const requiredQueryFields: AgentPlayerReportingQueryFields[] = ['total_count', 'items.uid'];

                return [...new Set([...requiredQueryFields, ...itemsQueryFields, ...summaryQueryFields])].filter(f => f);
            })
        );
        createMap<AgentPlayerReportingViewModelKeys, PlayerLevelReportColumnSettingsKeys>(
            mapper,
            nameof<AgentPlayerReportingViewModelKeys>(),
            nameof<PlayerLevelReportColumnSettingsKeys>(),
            constructUsing(m => {
                type FieldsToMap = Exclude<AgentPlayerReportingViewModelKeys, PlayerLevelReportColumnSettingsKeys>;
                const isNever: IsNever<FieldsToMap> = true;
                if (!isNever) {
                    throw new Error('Mapping should be defined for all values');
                }
                return m;
            })
        );
        createMap<PlayerLevelReportColumnSettingsKeys, AgentPlayerReportingViewModelKeys>(
            mapper,
            nameof<PlayerLevelReportColumnSettingsKeys>(),
            nameof<AgentPlayerReportingViewModelKeys>(),
            constructUsing(m => {
                type FieldsToMap = Exclude<PlayerLevelReportColumnSettingsKeys, AgentPlayerReportingViewModelKeys | '__type'>;
                const isNever: IsNever<FieldsToMap> = true;
                if (!isNever) {
                    throw new Error('Mapping should be defined for all values');
                }
                return m === '__type' ? undefined : m;
            })
        );
    }
}
