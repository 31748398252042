import {defineMessages} from 'react-intl';

export const appMenuLocalized = defineMessages({
    overview: {
        id: 'SideBar_overview',
        defaultMessage: 'OVERVIEW',
    },
    teamName: {
        id: 'SideBar_teamName',
        defaultMessage: 'Back Office',
    },
});
