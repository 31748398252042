import React, {useEffect} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import Button from '@components/button/Buttons';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {BulkItemStatus, BulkOperationResult} from '@models/generated/graphql';
import {BulkOperationFilterKeys, BulkOperationQueryFields, EntityType} from '@redux/entity';
import {useViewInit, viewActions, ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {bulkActionsActions, PerformPayload, PollingState} from '../actions';
import {BulkActionState} from '../reducers';
import {
    bulkFailedOperationActionKeysSelector,
    bulkOperationNotCreatedActionKeys,
    bulkOperationsFilterSelector,
    executionItemsSelector,
    hasExecutionFailedItemsSelector,
    isExecutionInProgressSelector,
} from '../selectors';
import {BulkActionItemPayload, BulkStepProps, PerformActionData} from '../types';

import {BulkStepSummaryContentProps} from './BulkExecutionStepDetailsContent';

const localized = defineMessages({
    bulkStepExecutionSummaryModalHeader: {
        id: 'BulkStepExecutionSummary_modalHeader',
        defaultMessage: 'Summary',
    },
    bulkStepExecutionSummaryModalConfirm: {
        id: 'BulkStepExecutionSummary_modalConfirm',
        defaultMessage: 'Confirm',
    },
    bulkStepExecutionSummaryModalRetry: {
        id: 'BulkStepExecutionSummary_modalRetry',
        defaultMessage: 'Retry',
    },
    bulkStepExecutionSummaryDisplayName: {
        id: 'BulkStepExecutionSummary_displayName',
        defaultMessage: 'Execution Summary',
    },
});

type BulkStepExecutionSummaryData = {
    isExecutionInProgress: boolean;
    isExecutionFailed: boolean;
    actionItems: BulkActionState;
    failedOperationKeys: string[];
    operationIdFilter: string;
};

function useBulkStepExecutionSummaryData(operations: BulkOperationResult[]): BulkStepExecutionSummaryData {
    const failedOperationKeys = useSelector(bulkOperationNotCreatedActionKeys);
    const isExecutionInProgress = useSelector<RootState, boolean>(state => isExecutionInProgressSelector(state, {operations}));
    const hasFailedItems = useSelector<RootState, boolean>(state => hasExecutionFailedItemsSelector(state, {operations}));
    const actionItems = useSelector<RootState, BulkActionState>(state => executionItemsSelector(state, {operations}));
    const operationIdFilter: string = useSelector(bulkOperationsFilterSelector);

    return {
        isExecutionInProgress,
        isExecutionFailed: failedOperationKeys?.length > 0 || hasFailedItems,
        actionItems,
        failedOperationKeys,
        operationIdFilter,
    };
}

type ExecutionActions = {
    performExecution: () => void;
    retryExecution: () => void;
};

function useExecutionActions(actionItems: BulkActionState, actionData: PerformActionData[]): ExecutionActions {
    const dispatch = useDispatch();
    const failedOperationKeys = useSelector(bulkFailedOperationActionKeysSelector);

    const performPayload: PerformPayload = Object.entries(actionItems)
        .map(([actionKey, payloads]) => {
            const items: BulkActionItemPayload[] = failedOperationKeys.includes(actionKey)
                ? payloads.map(i => ({...i, status: BulkItemStatus.Failed}))
                : payloads;
            const peformData = actionData?.find(i => i.actionKey === items?.[0]?.actionKey);
            return {
                strategy: peformData?.strategy,
                postProcessingAction:
                    peformData?.type === 'operation' ? bulkActionsActions.saveOperationId : bulkActionsActions.changeBulkActionStatus,
                request: items?.length ? {items, actionKey} : null,
            };
        })
        .filter(s => s.request !== null);

    function performExecution() {
        dispatch(bulkActionsActions.perform(performPayload));
    }

    function retryExecution() {
        dispatch(bulkActionsActions.retry(performPayload));
    }

    return {performExecution, retryExecution};
}

function useBatchOperationsPolling(isExecutionInProgress: boolean) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            bulkActionsActions.fetchOperations({
                viewType: 'BulkExecutionSummary',
                pollingState: isExecutionInProgress ? PollingState.Start : PollingState.Finished,
            })
        );
    }, [isExecutionInProgress]);
}

type BulkStepExecutionSummaryProps = Omit<BulkStepProps, 'onPrev'> & {
    hasRetry: boolean;
    actionsData: PerformActionData[];
    entityType: EntityType;
    withFlexibleGrid?: boolean;
};

export function withBulkStepExecutionSummary(WrappedComponent: (props: BulkStepSummaryContentProps) => JSX.Element) {
    return function BulkStepExecutionSummary({
        onNext,
        hasRetry = false,
        actionsData,
        entityType,
        withFlexibleGrid,
    }: BulkStepExecutionSummaryProps) {
        const dispatch = useDispatch();

        const viewType: ViewType = 'BulkExecutionSummary';
        const fields: BulkOperationQueryFields[] = ['id', 'status', 'items.id', 'items.status', 'items.message', 'entity_type'];
        const {
            items: operations,
            viewEntity: {filter: viewFilterString},
        } = useViewInit<BulkOperationResult, BulkOperationFilterKeys, BulkOperationQueryFields>({
            viewType,
            displayName: localized.bulkStepExecutionSummaryDisplayName,
            entity: {
                entity: EntityType.BulkOperation,
                fields,
            },
            validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'id')?.value),
        });
        const {isExecutionInProgress, isExecutionFailed, actionItems, failedOperationKeys, operationIdFilter} =
            useBulkStepExecutionSummaryData(operations);

        const {performExecution, retryExecution} = useExecutionActions(actionItems, actionsData);

        useBatchOperationsPolling(isExecutionInProgress);

        useEffect(() => {
            if (operationIdFilter && operationIdFilter !== viewFilterString) {
                dispatch(
                    viewActions.updateFilter({
                        view: viewType,
                        entity: EntityType.BulkOperation,
                        filter: `id=${operationIdFilter}`,
                    })
                );
            }
        }, [operationIdFilter]);

        useEffect(() => {
            performExecution();
        }, []);

        function handlePrevClick() {
            retryExecution();
        }

        function handleNextClick() {
            onNext();
        }

        return (
            <>
                <BulkModalTitle title={localized.bulkStepExecutionSummaryModalHeader} />
                <BulkModalContent>
                    {Object.keys(actionItems).map(actionKey => (
                        <WrappedComponent
                            key={`summaryDetails-${actionKey}`}
                            actionItems={actionItems[actionKey]}
                            actionKey={actionItems[actionKey]?.[0]?.actionKey}
                            entityType={entityType}
                            isProcessingFinished={!isExecutionInProgress}
                            isOperationFailed={failedOperationKeys.includes(actionKey)}
                            withFlexibleGrid={withFlexibleGrid}
                        />
                    ))}
                </BulkModalContent>
                <ModalFooter>
                    {hasRetry && !isExecutionInProgress && isExecutionFailed ? (
                        <Button label={localized.bulkStepExecutionSummaryModalRetry} onClick={handlePrevClick} />
                    ) : (
                        <></>
                    )}
                    <Button
                        color="primary"
                        label={localized.bulkStepExecutionSummaryModalConfirm}
                        onClick={handleNextClick}
                        disabled={isExecutionInProgress}
                    />
                </ModalFooter>
            </>
        );
    };
}
