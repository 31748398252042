import React from 'react';
import {defineMessages} from 'react-intl';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {LayoutDetailsHeaderActions} from '@components/layout/LayoutDetailsHeaderActions';
import {LayoutDetailsHeaderItem} from '@components/layout/LayoutDetailsHeaderItem';
import {EntityFilterType, EntityType, UserAccountStatus} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {RealtimeUpdatesMode} from '@redux/realtime';

import {RouteUrl} from 'src/common/routeEnums';
import {ActionHistoryModalAction} from '../../action-history/components/ActionHistoryModalAction';
import {useBreadcrumbInitializer} from '../../app/breadcrumb/hooks';
import {useUserProfileDetails} from '../../block-user-profile-details/hooks';

import {WorkspaceHeader} from './WorkspaceHeader';

const localized = defineMessages({
    accountStatus: {
        id: 'SecurityDetailsHeader_accountStatus',
        defaultMessage: 'Account Status',
    },
    securityWorkspaceRoute: {
        id: 'SecurityDetailsHeader_route',
        defaultMessage: 'Security Workspace',
    },
});

type UserProfileDetailsHeaderProps = {
    uid: string;
};

export const SecurityDetailsHeader = ({uid}: UserProfileDetailsHeaderProps) => {
    const fields: UserProfileViewModelKeys[] = ['uid', 'account_status'];
    const {item} = useUserProfileDetails({id: uid, viewType: 'SecurityDetailsHeader', fields, realtimeMode: RealtimeUpdatesMode.Silent});
    useBreadcrumbInitializer(uid, RouteUrl.SecurityWorkspace, RouteUrl.SecurityDetails, localized.securityWorkspaceRoute);

    return (
        <WorkspaceHeader>
            <LayoutDetailsHeaderItem label={localized.accountStatus}>
                <MemoizedChip chipName={nameof<UserAccountStatus>()} value={item?.account_status} />
            </LayoutDetailsHeaderItem>
            <LayoutDetailsHeaderActions>
                <ActionHistoryModalAction
                    uid={uid}
                    options={{
                        id: uid,
                        type: EntityType.Player,
                        filterType: EntityFilterType.AllRecords,
                    }}
                />
            </LayoutDetailsHeaderActions>
        </WorkspaceHeader>
    );
};
