import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {
    BonusCodeDetails,
    BonusCodeFilterInput,
    BonusCreditType,
    BonusStatus,
    MutationAddBonusCodeArgs,
    Query,
} from '@models/generated/graphql';
import {ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlSearchFilter} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';
import {gqlQueryArgs} from '@services/query/all';
import {toGQKMultiselectFilter, toGQLDateFilter, toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';
import {IRealtimeGridReadService} from '../../realtime-updates/services/IRealtimeGridReadService';
import {BeBonusColumnsConfiguration, beBonusFullSearch, BeBonusGridItem, BeBonusItem} from '../types';

const getBeBonusesQuery = (configuration: BeBonusColumnsConfiguration) => gql`
    query getBonusCodes($filter: BonusCodeFilterInput, $sort: Sorting, $start: Int, $end: Int) {
        getBonusCodes(filter: $filter, sort: $sort, end: $end, start: $start)
        {
            items {
                bonus_id ${configuration.withBonusId ? '' : '@client'}
                bonus_name ${configuration.withBonusName ? '' : '@client'}
                platform ${configuration.withPlatform ? '' : '@client'}
                bonus_status ${configuration.withBonusStatus ? '' : '@client'}
                threshold ${configuration.withThreshold ? '' : '@client'}
                credit_type ${configuration.withCreditType ? '' : '@client'}
                bonus_to_credit ${configuration.withBonusToCredit ? '' : '@client'}
                release_metric ${configuration.withReleaseMetric ? '' : '@client'}
                release_mechanism ${configuration.withReleaseMechanism ? '' : '@client'}
                meta ${configuration.withMeta ? '' : '@client'} {
                    bonus_value_param
                    expire_period
                    max_bonus_get
                    min_deposit
                    overall_max_bonus_get
                    rake_amount
                    rake_qualify
                    show_max_bonus_in_total
                    ticket_serial
                    time_range_by_date
                    total_max_bonus_get
                    unrealized_expire_period
                }
                description ${configuration.withDescription ? '' : '@client'}
                marketing_codes ${configuration.withMarketingCodes ? '' : '@client'}
                create_time ${configuration.withCreateTime ? '' : '@client'}  {
                    seconds
                }
                last_update ${configuration.withLastUpdate ? '' : '@client'} {
                    seconds
                }
                start_time ${configuration.withStartTime ? '' : '@client'} {
                    seconds
                }
                end_time ${configuration.withEndTime ? '' : '@client'} {
                    seconds
                }
            }
            total_count
        }
    }
`;

@injectable()
export class BeBonusService
    extends ReadonlyRealtimeGridServiceBase<BeBonusItem, BeBonusGridItem, BonusCodeDetails>
    implements IRealtimeGridReadService<string>
{
    constructor(@inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>) {
        super(client);
    }

    getItem(id: string, itemFields?: string[]): Observable<BeBonusItem> {
        const configuration = this.getGQLConfiguration(new BeBonusColumnsConfiguration(), itemFields);

        return this._client
            .executeQuery(
                this.getItemQuery(configuration),
                this.getGQLVariables({filter: [{key: nameof<BonusCodeDetails>(u => u.bonus_id), value: id}]} as SearchFilter)
            )
            .pipe(
                map<Query, BeBonusGridItem>(res => {
                    const beBonusItem = res?.getBonusCodes?.items?.[0];

                    return this.mapModels(
                        {id: `${beBonusItem?.bonus_id}`, serverId: `${beBonusItem?.bonus_id}`} as BeBonusGridItem,
                        beBonusItem as BonusCodeDetails
                    );
                })
            );
    }

    addItem(item: MutationAddBonusCodeArgs) {
        return this._client.executeMutation(gqlQueryArgs.beBonus.addMutation, item);
    }

    getItemsIds(filter?: SearchFilter): Observable<string[]> {
        const configuration = this.getGQLConfiguration(new BeBonusColumnsConfiguration(), [
            nameof<BeBonusColumnsConfiguration>(c => c.withBonusId),
        ]);

        return this._client
            .executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter))
            .pipe(map<Query, string[]>(res => res?.getBonusCodes?.items?.map(i => `${i?.bonus_id}`) ?? []));
    }

    getItems(): Observable<BeBonusGridItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsPage(filter?: SearchFilter, itemFields?: string[]): Observable<ItemsPage<BeBonusGridItem>> {
        const configuration = this.getGQLConfiguration(new BeBonusColumnsConfiguration(), itemFields);

        return this._client.executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter)).pipe(
            map<Query, ItemsPage<BeBonusGridItem>>(res => ({
                items:
                    res?.getBonusCodes?.items?.map(i =>
                        this.mapModels({id: `${i?.bonus_id}`, serverId: `${i?.bonus_id}`} as BeBonusGridItem, i)
                    ) ?? [],

                total: res?.getBonusCodes?.total_count ?? 0,
            }))
        );
    }

    protected getItemsPageQuery(configuration: BeBonusColumnsConfiguration): DocumentNode {
        return getBeBonusesQuery(configuration);
    }

    protected getItemQuery(configuration: BeBonusColumnsConfiguration) {
        return getBeBonusesQuery(configuration);
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        const filter = {
            credit_type: toGQKMultiselectFilter<BonusCreditType>(
                filters,
                nameof<BonusCodeFilterInput>(m => m.credit_type)
            ),
            bonus_status: toGQKMultiselectFilter<BonusStatus>(
                filters,
                nameof<BonusCodeFilterInput>(m => m.bonus_status)
            ),
            text: [
                toGQLTextFilter(
                    filters,
                    nameof.toArray<BonusCodeDetails>(b => [b.bonus_id]),
                    nameof<BonusCodeDetails>(b => b.bonus_id)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<BonusCodeDetails>(b => [b.bonus_name]),
                    nameof<BonusCodeDetails>(b => b.bonus_name)
                ),
                toGQLTextFilter(
                    filters,
                    [nameof<BonusCodeDetails>(w => w.bonus_id), nameof<BonusCodeDetails>(w => w.bonus_name)],
                    beBonusFullSearch
                ),
            ],
        } as BonusCodeFilterInput;

        const dateFilter = toGQLDateFilter(
            filters,
            nameof<BonusCodeFilterInput>(m => m.create_time)
        );

        if (dateFilter) {
            filter.create_time = dateFilter;
        }

        return filter;
    }
}
