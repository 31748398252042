import React, {ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import {ControllerFieldState} from 'react-hook-form';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {CSSObject} from '@emotion/react';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import {
    Box,
    Fade,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    SelectChangeEvent,
    SelectProps,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {Message, MessageOrientation, MessageType} from '@components/alerts/Message';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon} from '@components/icons';
import {CustomTheme} from '@style';

import {useMessageFormatter} from '../../../features/app/intl/hooks';
import {SelectOption} from '../../../features/module-shared/types';
import {isMessageDescriptor} from '../../../features/module-shared/utils';
import {IconButton} from '../button/Buttons';
import StyledSelect, {StyledSelectProps} from '../StyledSelect';

import {StyledInputProps, withStyledInputLabel} from './StyledFormInputHoc';
import {validateSpecialCharacters} from './validation';

//TODO: [BO-2664] Remove dependency to features (pass data through props)
//TODO: [BO-2665] Move inputs to separate files
const useClasses = makeStyles()((theme: CustomTheme) => ({
    formInputFullWidth: {
        width: '100%',
    },

    formInputSpacingBottom: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },

    formControlLabeled: {
        padding: 0,
        margin: 0,
        justifyContent: 'space-between',
        '& > .MuiFormControlLabel-label': {
            marginRight: 'auto',
        },
    },

    formControlLabel: {
        ...(theme.typography.caption as CSSObject),
        marginBottom: theme.spacing(1),
        marginRight: 'auto',
    },

    formControlHelperIcon: {
        marginRight: theme.spacing(0.5),
    },

    formControlErrorWrapper: {
        display: 'block',
    },

    formControlHelperText: {
        marginTop: theme.spacing(1),
        textAlign: 'right',
    },

    formControlHelperTextLeft: {
        textAlign: 'left',
    },

    formControlError: {
        textAlign: 'right',
    },

    formControlErrorLeft: {
        textAlign: 'left',
    },

    formTextInput: {
        flexGrow: 1,
        width: '100%',
        marginRight: 'auto',
    },

    formControlSelect: {
        '& > .MuiInputBase-root:not(.Mui-disabled):before, & > .MuiInputBase-root:hover:not(.Mui-disabled):before, & > .MuiInputBase-root:after':
            {
                border: 'none !important',
            },
        '& > .MuiInputBase-root': {
            height: theme.custom.denseButtonHeight,
            border: `1px solid ${theme.custom.palette.content.liner}`,
            borderRadius: '8px',
            padding: theme.spacing(0.5, 2),
            '& .MuiSelect-select': {
                paddingLeft: theme.spacing(0),
            },
        },
        '& > .MuiInputBase-root.Mui-focused': {
            border: `1px solid ${theme.palette.primary.main} !important`,
        },
        '& .MuiSelect-icon': {
            right: '8px',
        },
    },

    textFieldInputProps: {
        ...(theme.typography.body2 as CSSObject),
        paddingLeft: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
        height: theme.custom.denseButtonHeight,
    },
    multilineTextFieldInputProps: {
        ...(theme.typography.body2 as CSSObject),
        padding: theme.spacing(1.5, 2),
    },
    formTextInputCleanableFadeHidden: {
        position: 'absolute',
        right: theme.spacing(1.5),
    },
    floatingError: {
        ...(theme.typography.caption as CSSObject),
        padding: theme.spacing(0.5),
        whiteSpace: 'normal',
    },
}));

const localized = defineMessages({
    selectPlaceholder: {
        id: 'selectPlaceholder',
        defaultMessage: 'Select {field}',
    },
    unsupportedSpecialCharacters: {
        id: 'FormInput_unsupportedSpecialCharacters',
        defaultMessage: 'Unsupported special characters \'"\\|/()[]{}',
    },
});

export class FormInputProps<T> extends StyledInputProps {
    value?: T;
    fieldState?: ControllerFieldState;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>, value?: T) => void;
    disabled?: boolean;
    variant?: 'filled' | 'outlined' | 'standard';
    hideError?: boolean;
    helperText?: string | MessageDescriptor;
}

type FormTextInputProps<T> = FormInputProps<T> &
    Omit<TextFieldProps, 'placeholder' | 'ref' | 'label' | 'helperText'> & {
        endAdornment?: JSX.Element;
        placeholder?: string | MessageDescriptor;
        textAlign?: 'left' | 'right';
        hideLabel?: boolean;
        startAdornment?: JSX.Element;
        inputClassName?: string;
        floatingError?: boolean;
    };

export type FormTextInputDefaultProps<T> = Omit<FormTextInputProps<T>, 'endAdornment'> & {
    endAdornment?: string | MessageDescriptor | JSX.Element;
};

export type FormTextInputCleanableProps<T> = Omit<FormTextInputDefaultProps<T>, 'endAdornment'> & {
    clear?: React.MouseEventHandler<HTMLButtonElement>;
    /**
     * @deprecated
     * <p>Validation done inside this component because it was not possible to use react-hook-form rules.</p>
     * <p>{@link UserProfileFiltersObsolete}, {@link AccountVerificationFilters} should be updated to use {@link FilterGroupNew}.</p>
     * <p>Validation rules should be described in {@link FilterGroupNewFilter} type and passed to {@link FilterGroupNew} controller.</p>
     */
    disallowSpecialCharacters?: boolean;
};

export const FormTextInput = forwardRef(
    (
        {
            label,
            value,
            onChange,
            startAdornment,
            endAdornment,
            placeholder,
            fieldState,
            disabled,
            type,
            textAlign = 'left',
            hideLabel,
            style,
            variant = 'outlined',
            className,
            onKeyDown,
            InputProps,
            helperText,
            hasBottomSpacing = true,
            fullWidth = true,
            inputClassName,
            floatingError,
            ...props
        }: FormTextInputProps<unknown>,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        const {classes, cx} = useClasses();
        const formatMessage = useMessageFormatter();
        const hasError = fieldState?.error?.message?.length > 0;
        const placeholderLocalized = formatMessage(placeholder);
        const {inputProps, ...otherProps} = props;
        const containerRef = useRef(null);
        return (
            <FormControl
                error={hasError}
                className={cx(
                    {
                        [classes.formInputFullWidth]: fullWidth,
                        [classes.formInputSpacingBottom]: hasBottomSpacing,
                    },
                    className
                )}
                style={style}
                ref={containerRef}
            >
                <FormControlLabel
                    control={
                        <TextField
                            inputRef={ref}
                            value={value ?? ''}
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            placeholder={placeholderLocalized}
                            error={hasError}
                            className={classes.formTextInput}
                            disabled={disabled}
                            type={type}
                            variant={variant}
                            InputProps={{
                                classes: {
                                    input: cx(
                                        classes.textFieldInputProps,
                                        props?.multiline && classes.multilineTextFieldInputProps,
                                        inputClassName
                                    ),
                                },
                                style: {textAlign},
                                startAdornment,
                                endAdornment,
                                inputProps: {'data-testid': 'textInput', ...inputProps},
                                ...InputProps,
                            }}
                            size="small"
                            {...otherProps}
                        />
                    }
                    label={
                        !hideLabel && label ? (
                            <Box className={classes.formControlLabel}>
                                <LocalizedText label={label} />
                            </Box>
                        ) : null
                    }
                    labelPlacement="top"
                    className={classes.formControlLabeled}
                />
                {helperText ? (
                    <FormHelperText
                        className={`${classes.formControlHelperText} ${textAlign === 'left' ? classes.formControlHelperTextLeft : ''}`}
                    >
                        <Icon className={classes.formControlHelperIcon} icon={CustomIcon.WarningOutline} />
                        <LocalizedText label={helperText} />
                    </FormHelperText>
                ) : null}
                {floatingError ? (
                    <Message
                        hideMessage={!hasError}
                        containerRef={containerRef}
                        className={classes.floatingError}
                        message={fieldState?.error?.message}
                        variant="inherit"
                        messageType={MessageType.Error}
                        messageOrientation={MessageOrientation.Floating}
                    />
                ) : hasError ? (
                    <FormHelperText className={`${classes.formControlError} ${textAlign === 'left' ? classes.formControlErrorLeft : ''}`}>
                        {fieldState?.error?.message}
                    </FormHelperText>
                ) : null}
            </FormControl>
        );
    }
);

export const FormTextInputDefault = ({endAdornment, ...otherParams}: FormTextInputDefaultProps<unknown>) => {
    const isText = isMessageDescriptor(endAdornment) || endAdornment instanceof String;
    return (
        <FormTextInput
            ref={null}
            {...otherParams}
            endAdornment={
                isText ? (
                    <InputAdornment position="end">
                        <Typography color="secondary" variant="body2">
                            <LocalizedText label={endAdornment as string | MessageDescriptor} />
                        </Typography>
                    </InputAdornment>
                ) : (
                    (endAdornment as JSX.Element)
                )
            }
        />
    );
};

export const FormTextInputCleanable = forwardRef(
    (
        {clear, value, disallowSpecialCharacters, fieldState, floatingError, ...otherParams}: FormTextInputCleanableProps<string>,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        const {classes} = useClasses();
        const {formatMessage} = useIntl();
        const {onChange} = otherParams;
        const [currentValue, setCurrentValue] = useState(value);
        const [isSpecialCharactersError, setIsSpecialCharactersError] = useState(false);
        const specialCharactersError: ControllerFieldState = {
            invalid: true,
            isDirty: false,
            isTouched: false,
            error: {
                type: 'pattern',
                message: formatMessage(localized.unsupportedSpecialCharacters),
            },
        };
        const isEmpty = !value;

        useEffect(() => {
            setCurrentValue(value);
        }, [value]);

        const clearIcon = useMemo(() => {
            return (
                <Fade in={!isEmpty} appear={false} className={isEmpty ? classes.formTextInputCleanableFadeHidden : ''}>
                    <InputAdornment position="end">
                        <IconButton data-testid="textInputClearButton" size="small" onClick={handleClear}>
                            <HighlightOffRoundedIcon />
                        </IconButton>
                    </InputAdornment>
                </Fade>
            );
        }, [isEmpty]);

        function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
            const value = e.target.value;
            setCurrentValue(value);
            const isValid = validateSpecialCharacters(value);
            setIsSpecialCharactersError(!isValid);
            if (isValid) {
                onChange(e);
            }
        }

        function handleClear(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
            onChange?.({target: {value: ''}} as any);
            clear?.(e);
            setIsSpecialCharactersError(false);
            setCurrentValue('');
        }

        return (
            <FormTextInput
                ref={ref}
                {...otherParams}
                onChange={handleChange}
                value={currentValue}
                endAdornment={clearIcon}
                fieldState={isSpecialCharactersError ? specialCharactersError : fieldState}
                floatingError={floatingError ?? disallowSpecialCharacters}
            />
        );
    }
);

export type FormSelectProps = FormInputProps<unknown> &
    StyledInputProps &
    StyledSelectProps &
    Pick<SelectProps, 'startAdornment'> & {
        displayEmpty?: boolean;
        compareByValue?: boolean;
        isCompareOptionsAvailable?: boolean;
    };

export const FormSelect = ({
    value,
    options,
    onChange,
    fieldState,
    disabled,
    displayEmpty,
    compareByValue,
    label,
    className,
    dropdownClassName,
    isCompareOptionsAvailable = true,
    hideError,
    alignLeft,
    fullWidth = true,
    hasBottomSpacing = true,
    startAdornment,
}: FormSelectProps) => {
    const {classes, cx} = useClasses();
    const formatMessage = useMessageFormatter();
    const hasError = fieldState?.error?.message?.length > 0;

    const getLabel = (option: SelectOption) =>
        typeof option.label === 'string' ? option.label : formatMessage(option.label as MessageDescriptor);

    const compareOptions = (first: SelectOption, second: SelectOption) => {
        return compareByValue
            ? !isNaN(Number(first.value))
                ? Number(first.value) - Number(second.value)
                : first.value.toString().localeCompare(second.value.toString())
            : getLabel(first).localeCompare(getLabel(second));
    };

    const sortedOptions = isCompareOptionsAvailable ? options.sort(compareOptions) : options;
    const emptyOption = {value: '', disabled: true, label: formatMessage(localized.selectPlaceholder, {field: formatMessage(label)})};
    const allOptions = displayEmpty ? [emptyOption, ...sortedOptions] : sortedOptions;

    return (
        <FormControl
            error={hasError}
            className={cx(
                {
                    [classes.formInputFullWidth]: fullWidth,
                    [classes.formInputSpacingBottom]: hasBottomSpacing,
                },
                classes.formControlSelect,
                className
            )}
        >
            <StyledSelect
                displayEmpty={displayEmpty}
                disabled={disabled}
                value={value !== undefined && value !== null ? value : ''}
                onChange={e => onChange(e, e.target.value as SelectOption)}
                options={allOptions}
                dropdownClassName={dropdownClassName}
                alignLeft={alignLeft}
                startAdornment={startAdornment}
            />
            {hasError && !hideError ? (
                <FormHelperText className={classes.formControlError}>{fieldState?.error?.message}</FormHelperText>
            ) : null}
        </FormControl>
    );
};

export const FormSelectWithLabel = withStyledInputLabel(FormSelect);

type FormMultilineTextProps = FormInputProps<string> &
    StyledInputProps & {
        placeholder?: string | MessageDescriptor;
    };

export const FormMultilineText = ({value, onChange, placeholder, fieldState}: FormMultilineTextProps) => {
    const {classes} = useClasses();
    const formatMessage = useMessageFormatter();
    const hasError = fieldState?.error?.message?.length > 0;
    const placeholderLocalized = formatMessage(placeholder);

    return (
        <FormControl error={hasError} className={classes.formInputFullWidth}>
            <TextField
                fullWidth
                multiline
                margin="dense"
                variant="outlined"
                placeholder={placeholderLocalized}
                minRows={2}
                maxRows={6}
                error={hasError}
                value={value}
                onChange={onChange}
                InputProps={{
                    classes: {
                        input: classes.multilineTextFieldInputProps,
                    },
                }}
            />
            {hasError ? (
                <FormHelperText className={`${classes.formControlError} ${classes.formControlErrorLeft}`}>
                    {fieldState?.error?.message}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
};

type FormErrorProps = {
    children: React.ReactNode;
    'data-testid'?: string;
};

export function FormError({children, ...props}: FormErrorProps) {
    const {classes} = useClasses();
    return (
        <FormControl data-testid={props['data-testid']} error={true} className={classes.formControlErrorWrapper}>
            <FormHelperText className={`${classes.formControlError} ${classes.formControlErrorLeft}`}>{children}</FormHelperText>
        </FormControl>
    );
}
