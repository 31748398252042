import React from 'react';
import {Tooltip, TooltipProps} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

//TODO: [BO-2684] Move files to src/common/components/tooltip
export const useClasses = makeStyles()((theme: CustomTheme) => ({
    arrow: {
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
        fontSize: '1em',
        minWidth: '100%',
        maxWidth: 'none',
        padding: theme.spacing(2),
        display: 'block',
    },
    tooltipNarrow: {
        padding: theme.spacing(1),
    },
}));

export type StyledTooltipProps = Omit<TooltipProps, 'children'> & {
    element: JSX.Element;
    title: NonNullable<React.ReactNode>;
    hideTooltip?: boolean;
    narrow?: boolean;
};

export const StyledTooltip = ({element, title, hideTooltip, narrow, ...otherProps}: StyledTooltipProps) => {
    const {classes, cx} = useClasses();
    return !hideTooltip ? (
        <Tooltip
            arrow
            title={title}
            classes={{...classes, tooltip: cx(classes.tooltip, {[classes.tooltipNarrow]: narrow})}}
            {...otherProps}
        >
            {element}
        </Tooltip>
    ) : (
        element
    );
};

export default StyledTooltip;
