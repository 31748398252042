import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {BulkModalContent} from '@components/modal/BulkModalContent';
import {BulkModalTitle} from '@components/modal/BulkModalTitle';
import {ModalFooter} from '@components/modal/ModalFooter';

const localized = defineMessages({
    confirmButtonLabel: {
        id: 'ActionConfirmationModal_confirmButtonLabel',
        defaultMessage: 'Yes',
    },
    cancelButtonLabel: {
        id: 'ActionConfirmationModal_cancelButtonLabel',
        defaultMessage: 'No',
    },
});

const useStyles = makeStyles()({
    actionConfirmationModalContent: {
        height: 'unset',
    },
});

type ActionConfirmationModalProps = {
    title: string | MessageDescriptor;
    contentDescription: string | MessageDescriptor;
    handleCancel: () => void;
    handleConfirm: () => void;
    confirmButtonLabel?: string | MessageDescriptor;
    cancelButtonLabel?: string | MessageDescriptor;
};

export function ActionConfirmationModal({
    title,
    contentDescription,
    handleConfirm,
    handleCancel,
    confirmButtonLabel,
    cancelButtonLabel,
}: ActionConfirmationModalProps) {
    const {classes} = useStyles();
    return (
        <>
            <BulkModalTitle>
                <LocalizedText label={title} />
            </BulkModalTitle>
            <BulkModalContent className={classes.actionConfirmationModalContent}>
                <LocalizedText label={contentDescription} />
            </BulkModalContent>
            <ModalFooter>
                <Button label={cancelButtonLabel ?? localized.cancelButtonLabel} onClick={handleCancel} />
                <Button color="primary" label={confirmButtonLabel ?? localized.confirmButtonLabel} onClick={handleConfirm} />
            </ModalFooter>
        </>
    );
}
