import React from 'react';
import {Box} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {LinkText} from '@components/link';

import {useDropzoneContentClasses} from './DropzoneContent.style';
import {localized} from './DropzoneContentDefault.localize';

type DropzoneContentDefaultProps = {
    isDragOrDialogActive: boolean;
    onOpen: () => void;
};

const DropzoneContentDefault = ({isDragOrDialogActive, onOpen}: DropzoneContentDefaultProps) => {
    const {classes, cx} = useDropzoneContentClasses();

    return (
        <>
            <Icon
                icon={CustomIcon.File}
                color={isDragOrDialogActive ? IconColor.Primary : IconColor.Secondary}
                className={cx(classes.dropzoneContentDefaultIcon, classes.dropzoneContentIcon)}
            />
            <Box>
                <LocalizedText
                    label={localized.dropzoneContentDefaultPlaceholder}
                    labelParams={{
                        linkToDialogWindow: (
                            <LinkText
                                title={localized.dropzoneContentDefaultLinkToChooseFile}
                                onClick={onOpen}
                                underline={isDragOrDialogActive ? 'always' : 'none'}
                            />
                        ),
                    }}
                />
            </Box>
        </>
    );
};

export default DropzoneContentDefault;
