import React from 'react';
import {Paper} from '@mui/material';

import {useAutocompleteClasses} from './Autocomplete.styles';

export const AutocompletePaper = ({...props}: React.HTMLAttributes<HTMLElement>) => {
    const {classes: styles} = useAutocompleteClasses();

    return <Paper variant="outlined" {...props} className={styles.autocompletePaper} />;
};
