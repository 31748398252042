import React, {useContext} from 'react';
import {IntlContext} from 'react-intl';
import {Box, Drawer, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {appMenuLocalized} from '@components/appMenuLocalized';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {CustomTheme} from '@style';

import ModuleList from '../../../features/modules/components/ModuleList';
import {IconButton} from '../button/Buttons';

import {Account} from './Account';

const makeClasses = makeStyles()((theme: CustomTheme) => ({
    sideBarPinIcon: {
        padding: theme.spacing(1.5),
    },
    sideBarHeaderMinimized: {
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        maxHeight: '120px',
    },
    sideBarHeaderMaximized: {
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        maxHeight: '80px',
    },
    sideBarHeaderInnerMaximized: {
        display: 'flex',
        minHeight: theme.custom.topMenuHeight,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    sideBarHeaderInnerMinimized: {
        display: 'block',
        minHeight: theme.custom.topMenuHeight,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    sideBarTitle: {
        marginTop: theme.spacing(2),
    },
    overviewSectionTitle: {
        marginLeft: theme.spacing(3),
    },
    overviewSection: {
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: theme.spacing(2),
    },
    accountSection: {
        marginLeft: theme.spacing(1),
        marginTop: 'auto',
        marginRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    userInformationContent: {
        marginBottom: theme.spacing(2),
        display: 'flex',
    },
    drawerStickButton: {
        marginLeft: 'auto',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: theme.custom.sideBarWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: theme.custom.sideBarWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(10),
    },
}));

type SidebarProps = {
    stick: boolean;
    handleDrawerStick: (isStick: boolean) => void;
};

function SideBar({stick, handleDrawerStick}: SidebarProps) {
    const {classes, cx} = makeClasses();
    const {formatMessage} = useContext(IntlContext);
    const [hovered, setHovered] = React.useState(false);

    //handling the state when sidebar minimized
    const onMouseOver = () => {
        if (!stick) {
            setHovered(true);
        }
    };

    const onMouseLeave = () => {
        if (!stick) {
            setHovered(false);
        }
    };

    const isStickOrHovered = stick || hovered;

    return (
        <nav>
            <Drawer
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block',
                    },
                }}
                data-testid="sidebarDrawer"
                variant="permanent"
                className={cx(classes.drawer, {
                    [classes.drawerOpen]: isStickOrHovered,
                    [classes.drawerClose]: !isStickOrHovered,
                })}
                classes={{
                    paper: cx({
                        [classes.drawerOpen]: isStickOrHovered,
                        [classes.drawerClose]: !isStickOrHovered,
                    }),
                }}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
            >
                <Box
                    className={isStickOrHovered ? classes.sideBarHeaderMaximized : classes.sideBarHeaderMinimized}
                    data-testid="sidebarDrawerHeader"
                >
                    <Box className={isStickOrHovered ? classes.sideBarHeaderInnerMaximized : classes.sideBarHeaderInnerMinimized}>
                        <Box className={isStickOrHovered ? classes.sideBarTitle : classes.hide}>
                            <Typography variant="h6">{formatMessage(appMenuLocalized.teamName)}</Typography>
                        </Box>
                        <Box className={classes.drawerStickButton}>
                            <IconButton
                                data-testid="sidebarDrawerUnpin"
                                size="medium"
                                className={stick ? classes.sideBarPinIcon : classes.hide}
                                onClick={() => handleDrawerStick(false)}
                            >
                                <Box component="span" className={CustomIcon.UnpinOutline}></Box>
                            </IconButton>
                            <IconButton
                                data-testid="sidebarDrawerPin"
                                size="medium"
                                className={!stick ? classes.sideBarPinIcon : classes.hide}
                                onClick={() => handleDrawerStick(true)}
                            >
                                <Box component="span" className={CustomIcon.PinOutline}></Box>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.overviewSection} height="100%">
                    <Typography className={isStickOrHovered ? classes.overviewSectionTitle : classes.hide} variant="caption">
                        <LocalizedText label={appMenuLocalized.overview} />
                    </Typography>
                    <ModuleList showSubmodules={isStickOrHovered} />
                </Box>
                <Box className={classes.accountSection}>
                    <Account onlyIcons={!isStickOrHovered} />
                </Box>
            </Drawer>
        </nav>
    );
}

export default SideBar;
