import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {filterNotNulls, getFilterString, getPagingString, getSortString, toSearchFilter} from '@utils';

import {Filter, Paging, SearchFilter, Sorting} from 'src/common/types';
import {locationSearchSelector} from '../../app/routing/selectors';
import {pagingActions, serverSortingActions} from '../../module-shared/actions';

import {filterActions} from './actions';

type UseFilterChangeResult = (newFilter: Filter[]) => void;

const useFilterChange = (domain: string, defaultFilter?: Filter[]): UseFilterChangeResult => {
    const dispatch = useDispatch();
    const actions = filterActions(domain);
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (defaultFilter && defaultFilter?.length) {
            handleFilterChange(filterNotNulls(defaultFilter));
        }
    }, [defaultFilter?.map(f => `${f?.key}${f?.value}`).join()]);

    const handleFilterChange = (newFilter: Filter[]) => {
        dispatch(actions.itemsFilter(newFilter));
    };

    return handleFilterChange;
};

type UseSortChangeResult = (newSorting: Sorting<string>[]) => void;

const useSortChange = (domain: string, defaultSorting?: Sorting<string>[]): UseSortChangeResult => {
    const dispatch = useDispatch();
    const actions = serverSortingActions(domain);
    const isFirstRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (defaultSorting && defaultSorting?.length) {
            handleSortChange(filterNotNulls(defaultSorting));
        }
    }, [defaultSorting?.map(s => `${s?.field}${s?.sort}`).join()]);

    const handleSortChange: UseSortChangeResult = (newSorting: Sorting<string>[]) => {
        if (isFirstRun.current) {
            return;
        }

        dispatch(actions.itemsSorted(newSorting));
    };

    return handleSortChange;
};

type UsePagingChangeResult = {
    handlePageChange: (newPage: number) => void;
    handlePageSizeChange: (newPageSize: number) => void;
};

const usePagingChange = (domain: string, {page, pageSize}: Paging): UsePagingChangeResult => {
    const dispatch = useDispatch();
    const actions = pagingActions(domain);

    const handlePageChange = (newPage: number) => {
        if (newPage !== page) {
            dispatch(actions.itemsPagingChanged({page: newPage, pageSize: pageSize}));
        }
    };

    const handlePageSizeChange = (newPageSize: number) => {
        const getNewPageNumber = (rowsPerPage: number) => {
            const firstPageItemIndex = pageSize * (page - 1) + 1;
            const newPage = Math.ceil(firstPageItemIndex / rowsPerPage);
            return newPage;
        };

        if (newPageSize !== pageSize) {
            //when page size changed we need to calculate page to keep the first item of the page on the same page
            const newPage = getNewPageNumber(newPageSize);
            dispatch(actions.itemsPagingChanged({page: newPage, pageSize: newPageSize}));
        }
    };

    return {handlePageChange, handlePageSizeChange};
};

const useInitialFilterString = (
    filterString: string,
    defaultFilter?: Filter[],
    defaultSorting?: Sorting<string>[],
    defaultPaging?: Paging,
    syncWithUrl = false
): string => {
    const urlSearch = useSelector<RootState, string>(locationSearchSelector);

    let initialFilterString = filterString;
    if (!initialFilterString && syncWithUrl) {
        initialFilterString = urlSearch;
    }
    if (!initialFilterString) {
        if (defaultFilter && defaultFilter?.length) {
            initialFilterString = getFilterString(initialFilterString, false, ...filterNotNulls(defaultFilter));
        }
        if (defaultSorting && defaultSorting?.length) {
            initialFilterString = getSortString(initialFilterString, filterNotNulls(defaultSorting));
        }
        if (defaultPaging && defaultPaging?.page > 0 && defaultPaging?.pageSize > 0) {
            initialFilterString = getPagingString(initialFilterString, defaultPaging);
        }
    }

    return initialFilterString;
};

type UseFilterResult = {
    searchFilter: SearchFilter;
    handlePageChange: (newPage: number) => void;
    handlePageSizeChange: (newPageSize: number) => void;
    handleFilterChange: (newFilter: Filter[]) => void;
    handleSortChange: (newSorting: Sorting<string>[]) => void;
    resetFilter: () => void;
};

export const useFilter = (
    domain: string,
    filterString: string,
    defaultFilter?: Filter[],
    defaultSorting?: Sorting<string>[],
    defaultPaging?: Paging,
    syncWithUrl = false,
    doNotReset = false
): UseFilterResult => {
    const dispatch = useDispatch();
    const actions = filterActions(domain);
    const initialFilterString = useInitialFilterString(filterString, defaultFilter, defaultSorting, defaultPaging, syncWithUrl);

    const searchFilter = toSearchFilter(filterString ?? initialFilterString);

    useEffect(() => {
        if (initialFilterString !== filterString) {
            dispatch(actions.setFilter(initialFilterString));
        }

        //clear the filter if it's not page filter and reset is not disable
        if (!syncWithUrl && !doNotReset) {
            return () => resetFilter();
        } else {
            return () => {};
        }
    }, []);

    const handleFilterChange = useFilterChange(domain, defaultFilter);
    const handleSortChange = useSortChange(domain, defaultSorting);
    const {handlePageChange, handlePageSizeChange} = usePagingChange(domain, searchFilter.paging);

    const resetFilter = (): void => {
        dispatch(actions.setFilter(null));
    };

    return {
        searchFilter,
        handlePageChange,
        handlePageSizeChange,
        handleFilterChange,
        handleSortChange,
        resetFilter,
    };
};
