import React from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import Dropzone from '@components/dropzone/Dropzone';
import LocalizedText from '@components/i18n/LocalizedText';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {useService} from '@inversify';
import {TransactionType} from '@models/generated/graphql';
import {useAuthUser} from '@auth';

import {bulkActionsActions, BulkStepProps, BulkStrategyPayload, IBulkStrategy, useSelectedItems} from '../../block-bulk-actions';
import {LoadManualTransactionsStrategyRequest, LoadManualTransactionsStrategyResponse} from '../services/loadItemsStrategy';

import BulkTransactionDownloadTemplateButton from './BulkTransactionDownloadTemplateButton';

export const localized = defineMessages({
    bulkStepUploadFileFooterCancelAction: {
        id: 'uploadFileStepFooterCancel',
        defaultMessage: 'Cancel',
    },
    bulkStepUploadFileFooterConfirmAction: {
        id: 'uploadFileStepFooterConfirm',
        defaultMessage: 'Next',
    },
    bulkStepUploadFileHeaderLabel: {
        id: 'uploadFileHeaderLabel',
        defaultMessage: 'Upload File',
    },
    debit: {
        id: 'BulkTransactionManualUploadStepModal_debit',
        defaultMessage: 'Batch Debit',
    },
    credit: {
        id: 'BulkTransactionManualUploadStepModal_credit',
        defaultMessage: 'Batch Credit',
    },
});

const useClasses = makeStyles()(theme => ({
    bulkStepUploadFileTextActionBox: {
        display: 'flex',
        marginTop: theme.spacing(3.75),
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));

export const BulkTransactionManualUploadStepModal = ({onNext, onPrev, type}: BulkStepProps & {type: TransactionType}) => {
    const strategy =
        useService<IBulkStrategy<LoadManualTransactionsStrategyRequest, LoadManualTransactionsStrategyResponse>>(
            'LoadManualTransactionsStrategy'
        );
    const {classes} = useClasses();
    const dispatch = useDispatch();
    const uid = useAuthUser()?.sub;

    const handleCancelClick = () => {
        onPrev();
    };

    const handleNextClick = () => {
        onNext();
    };

    const handleFileUpload = (file: File) => {
        const loadStrategyProcessPayload: BulkStrategyPayload<
            LoadManualTransactionsStrategyRequest,
            LoadManualTransactionsStrategyResponse
        > = {
            strategy,
            request: {file, uid, type},
        };
        dispatch(bulkActionsActions.loadItems(loadStrategyProcessPayload));
    };

    const handleInvalidFileUpload = () => {
        dispatch(bulkActionsActions.clearBulkActions());
    };

    const isNextEnabled = !!useSelectedItems().length;

    return (
        <>
            <BulkModalTitle>
                <>
                    <LocalizedText label={localized.bulkStepUploadFileHeaderLabel} />
                    <span> </span>
                    <LocalizedText label={type === TransactionType.Debit ? localized.debit : localized.credit} />
                </>
            </BulkModalTitle>
            <BulkModalContent>
                <Dropzone
                    availableFormats={['XLS', 'XLSX']}
                    maxSizeInMB={5}
                    onFileUpload={handleFileUpload}
                    onInvalidFileUpload={handleInvalidFileUpload}
                />
                <Box className={classes.bulkStepUploadFileTextActionBox}>
                    {type === TransactionType.Debit ? (
                        <BulkTransactionDownloadTemplateButton title={localized.debit} type={TransactionType.Debit} />
                    ) : (
                        <BulkTransactionDownloadTemplateButton title={localized.credit} type={TransactionType.Credit} />
                    )}
                </Box>
            </BulkModalContent>
            <ModalFooter>
                <Button label={localized.bulkStepUploadFileFooterCancelAction} onClick={handleCancelClick} />
                <Button
                    color="primary"
                    label={localized.bulkStepUploadFileFooterConfirmAction}
                    onClick={handleNextClick}
                    disabled={!isNextEnabled}
                />
            </ModalFooter>
        </>
    );
};
