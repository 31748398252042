import {constructUsing, createMap, forMember, mapFrom, Mapper, mapWith} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {Timestamp} from '@models/shared';
import {SportsbookTransactionRecord, SportsbookTransactionSortKeys, TicketStatus} from '@models/sportsbook-transaction/type';

import {SportsbookTransactionViewModel, SportsbookTransactionViewModelKeys} from './types';

export class SportsbookTransactionMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap<Number, TicketStatus>(
            mapper,
            nameof<Number>(),
            nameof<TicketStatus>(),
            constructUsing(m => {
                let result: TicketStatus;
                switch (m) {
                    case 0:
                        result = TicketStatus.Awaiting;
                        break;
                    case 1:
                        result = TicketStatus.Rejected;
                        break;
                    case 2:
                        result = TicketStatus.Accepted;
                        break;
                    case 3:
                        result = TicketStatus.Voided;
                        break;
                    case 4:
                        result = TicketStatus.Settled;
                        break;
                    case 5:
                        result = TicketStatus.Canceled;
                        break;
                    default:
                        result = TicketStatus.Unknown;
                        break;
                }
                return result;
            })
        );
        createMap<TicketStatus, Number>(
            mapper,
            nameof<TicketStatus>(),
            nameof<Number>(),
            constructUsing(m => {
                let result: number;
                switch (m) {
                    case TicketStatus.Unknown:
                        result = -1;
                        break;
                    case TicketStatus.Awaiting:
                        result = 0;
                        break;
                    case TicketStatus.Rejected:
                        result = 1;
                        break;
                    case TicketStatus.Accepted:
                        result = 2;
                        break;
                    case TicketStatus.Voided:
                        result = 3;
                        break;
                    case TicketStatus.Settled:
                        result = 4;
                        break;
                    case TicketStatus.Canceled:
                        result = 5;
                        break;
                }
                return result;
            })
        );
        createMap<SportsbookTransactionRecord, SportsbookTransactionViewModel>(
            mapper,
            SportsbookTransactionRecord,
            SportsbookTransactionViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id.toString())
            ),
            forMember(
                vm => vm.ticketId,
                mapFrom(m => m.ticketId)
            ),
            forMember(
                vm => vm.compUserId,
                mapFrom(m => m.compUserId)
            ),
            forMember(
                vm => vm.sabaTicketId,
                mapFrom(m => m.sabaTicketId)
            ),
            forMember(
                vm => vm.ticketStatus,
                mapWith(nameof<TicketStatus>(), nameof<Number>(), m => m.ticketStatus)
            ),
            forMember(
                vm => vm.matchNames,
                mapFrom(m => m.options.map(o => o.matchNameEn))
            ),
            forMember(
                vm => vm.tournamentNames,
                mapFrom(m => m.options.map(o => o.tournamentNameEn))
            ),
            forMember(
                vm => vm.matchIds,
                mapFrom(m => m.options.map(o => o.matchId))
            ),
            forMember(
                vm => vm.betOptions,
                mapFrom(m => m.options.map(o => o.betOption))
            ),
            forMember(
                vm => vm.betOdds,
                mapFrom(m => m.options.map(o => o.odds))
            ),
            forMember(
                vm => vm.betAmount,
                mapFrom(m => m.betAmount)
            ),
            forMember(
                vm => vm.settleAmount,
                mapFrom(m => m.settleAmount)
            ),
            forMember(
                vm => vm.betTime,
                mapFrom(m => ({seconds: m.betTime} as Timestamp))
            ),
            forMember(
                vm => vm.currency,
                mapFrom(m => m.currency)
            )
        );
        createMap<SportsbookTransactionViewModelKeys, SportsbookTransactionSortKeys>(
            mapper,
            nameof<SportsbookTransactionViewModelKeys>(),
            nameof<SportsbookTransactionSortKeys>(),
            constructUsing(m => {
                const mapper: Record<SportsbookTransactionViewModelKeys, SportsbookTransactionSortKeys> = {
                    id: null,
                    ticketId: null,
                    compUserId: null,
                    sabaTicketId: null,
                    ticketStatus: null,
                    matchNames: null,
                    tournamentNames: null,
                    matchIds: null,
                    betOptions: null,
                    betOdds: null,
                    betAmount: 'bet_amount',
                    settleAmount: 'settle_amount',
                    betTime: 'bet_time',
                    currency: null,
                };
                return mapper[m];
            })
        );
        createMap<SportsbookTransactionSortKeys, SportsbookTransactionViewModelKeys>(
            mapper,
            nameof<SportsbookTransactionSortKeys>(),
            nameof<SportsbookTransactionViewModelKeys>(),
            constructUsing(m => {
                const mapper: Record<SportsbookTransactionSortKeys, SportsbookTransactionViewModelKeys> = {
                    bet_amount: 'betAmount',
                    settle_amount: 'settleAmount',
                    bet_time: 'betTime',
                };
                return mapper[m];
            })
        );
    }
}
