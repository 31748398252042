import {defineMessages} from 'react-intl';

import {UserAccountStatus} from '@models/generated/graphql';

export const localizedUserAccountStatus = defineMessages({
    [UserAccountStatus.Active]: {
        id: 'userAccountStatusActive',
        defaultMessage: 'Active',
    },
    [UserAccountStatus.Locked]: {
        id: 'userAccountStatusLocked',
        defaultMessage: 'Locked',
    },
    [UserAccountStatus.Dormant]: {
        id: 'userAccountStatusDormant',
        defaultMessage: 'Dormant',
    },
    [UserAccountStatus.Blocked]: {
        id: 'userAccountStatusBlocked',
        defaultMessage: 'Blocked',
    },
    [UserAccountStatus.Deregistered]: {
        id: 'userAccountStatusDeregistered',
        defaultMessage: 'Deregistered',
    },
    [UserAccountStatus.Pending]: {
        id: 'userAccountStatusPending',
        defaultMessage: 'Pending',
    },
    [UserAccountStatus.Closed]: {
        id: 'userAccountStatusClosed',
        defaultMessage: 'Closed',
    },
});
