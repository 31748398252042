import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useMultipleEditableAutocompleteClasses = makeStyles()((theme: CustomTheme) => ({
    multipleEditableAutocompleteContainer: {
        display: 'flex',
        width: '100%',
        gap: theme.spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexWrap: 'wrap',
    },

    multipleEditableAutocompleteContainerDisabled: {
        display: 'block',
    },
}));
