import acorn from 'acorn';

import {IModuleItem} from '../module-shared/types';

export type BooleanOperator = 'AND' | 'OR';

export type RelationalOperator = '>' | '<' | '>=' | '<=' | '==';

export type RelationalExpression = {
    left: string;
    right: string | number | boolean;
    operator: RelationalOperator;
};

export type BooleanExpression = {
    operator: BooleanOperator;
    conditions: (BooleanExpression | RelationalExpression)[];
};

export type NodeProgram = acorn.Node & {
    body: NodeExpressionStatement[];
};

export type NodeExpressionStatement = acorn.Node & {
    expression: NodeLogicalExpression | NodeBinaryExpression | NodeIdentifier;
};

export type NodeBooleanOperator = '&&' | '||';

export type NodeLogicalExpression = acorn.Node & {
    left: NodeBinaryExpression | NodeLogicalExpression;
    right: NodeBinaryExpression | NodeLogicalExpression;
    operator: NodeBooleanOperator;
};

export type NodeBinaryExpression = acorn.Node & {
    left: NodeMemberExpression;
    right: NodeMemberExpression | NodeLiteral;
    operator: RelationalOperator;
};

export type NodeMemberExpression = acorn.Node & {
    object: NodeMemberExpression | NodeIdentifier;
    property: NodeIdentifier;
    name: string;
};

export type NodeIdentifier = acorn.Node & {
    name: string;
};

export type NodeLiteral = acorn.Node & {
    value: string;
    raw: string;
};

export class RuleViewModel implements IModuleItem {
    id: string;
    name: string;
    status: boolean;
    registrationCountry?: string[];
    withdrawalAmount?: number;
    successfulWithdrawalAmount24?: number;
    successfulWithdrawalAmountLifetime?: number;
    hasApprovedKYCHistory?: ApprovedStatusValue;
    isFirstWithdrawal?: boolean;
    hasLocks?: boolean;
    isWithdrawalClosedLoop?: boolean;
    hasSecurityCasesAssigned?: boolean;
    order: number;
}

type DataGridActionsFields = 'actions';

export type RuleViewModelKeys = keyof RuleViewModel | DataGridActionsFields;

export type RuleCondition = keyof Omit<RuleViewModel, 'name' | 'status' | 'id' | 'order'>;
export const allRuleConditions: RuleCondition[] = [
    'registrationCountry',
    'withdrawalAmount',
    'successfulWithdrawalAmount24',
    'successfulWithdrawalAmountLifetime',
    'hasApprovedKYCHistory',
    'isFirstWithdrawal',
    'hasLocks',
    'isWithdrawalClosedLoop',
    'hasSecurityCasesAssigned',
];

export const relatedConditionMapper: Partial<Record<RuleCondition, RuleCondition>> = {
    successfulWithdrawalAmount24: 'hasApprovedKYCHistory',
    successfulWithdrawalAmountLifetime: 'hasApprovedKYCHistory',
};

export enum ApprovedStatusValue {
    Approved = 'Approved',
    NotApproved = 'NotApproved',
}
