import React, {useContext} from 'react';

import Button from '@components/button/Buttons';
import {ModalContext} from '@components/modal';

import {Wrapper, WrapperChild} from './WrapperDev';

export const ModalDev = () => {
    const {openModal} = useContext(ModalContext);

    const handleModalWithoutHeaderClick = () => {
        openModal({
            body: <>Modal body without header</>,
        });
    };

    const handleModalWithHeaderClick = () => {
        openModal({
            body: <>Modal body</>,
            title: <>Header</>,
        });
    };

    return (
        <Wrapper label="Modals">
            <WrapperChild>
                <Button onClick={handleModalWithoutHeaderClick} type="button" color="primary">
                    Open modal without header
                </Button>
            </WrapperChild>

            <WrapperChild>
                <Button onClick={handleModalWithHeaderClick} type="button" color="primary">
                    Open modal with header
                </Button>
            </WrapperChild>
        </Wrapper>
    );
};
