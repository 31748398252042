import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {PageView} from '@models/generated/graphql';
import {EntityType, PageIdDescriptor, PageViewFilterKeys, PageViewQueryFields} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit, ViewType} from '@redux/view';
import {PageIdDescriptorConverter} from '@services/pageViewService';
import {getUTCTimestamp} from '@utils/date';

import {useBoUsers} from '../block-bo-user';

import {pageViewActions, StartPingingPayload, StartPollingPayload} from './actions';
import {activeUsersIntervalInSeconds, PageViewViewModel} from './types';

type UsePollingProps = StartPollingPayload;

export function usePolling(props: UsePollingProps) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageViewActions.startPolling(props));
        return () => {
            dispatch(pageViewActions.stopPolling());
        };
    }, []);
}

type UsePingingProps = Pick<StartPingingPayload, 'pageIdDescriptor' | 'viewType'>;

export function usePageViewPing(props: UsePingingProps) {
    const pingIntervalInMs = (activeUsersIntervalInSeconds / 3) * 1000;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageViewActions.startPinging({...props, interval: pingIntervalInMs}));
        return () => {
            dispatch(pageViewActions.stopPinging());
        };
    }, []);
}

type UsePageViewsProps = {
    pageIdDescriptors: PageIdDescriptor[];
    viewType: ViewType;
};

export function usePageViews({pageIdDescriptors, viewType}: UsePageViewsProps): {
    all: PageViewViewModel[];
    groupedByRecordId: Record<string, PageViewViewModel[]>;
} {
    const visibleUserTimeIntervalInSeconds = 300;
    const pollingIntervalInMs = (activeUsersIntervalInSeconds / 3) * 1000;

    const mapper = useAutoMapper();
    const [fromFilter, setFromFilter] = useState(getFromFilter());
    const {items} = useViewInit<PageView, PageViewFilterKeys, PageViewQueryFields>({
        viewType,
        entity: {
            entity: EntityType.PageView,
            fields: ['user_id', 'page_id', 'last_opened_at'],
        },
        defaultFilters: [
            {
                key: 'lastOpenedAtFrom',
                value: fromFilter,
            },
            {
                key: 'lastOpenedAtTo',
                value: 'now',
            },
            {
                key: 'pageIds',
                value: pageIdDescriptors,
            },
        ],
        validateFilter: () => pageIdDescriptors.length > 0,
        realtime: {
            entity: EntityType.PageView,
            mode: RealtimeUpdatesMode.Silent,
            triggers: [
                {
                    type: RealtimeMessageTrigger.Add,
                    args: {ids: pageIdDescriptors?.map(p => new PageIdDescriptorConverter().convert(p)) ?? []},
                },
            ],
        },
    });

    const boUsers = useBoUsers({
        viewType,
        ids: items.map(i => i.user_id),
        fields: ['id', 'firstName', 'lastName', 'email'],
        validateFilter: () => items.length > 0,
    });

    usePolling({
        viewType,
        interval: pollingIntervalInMs,
    });

    useEffect(() => {
        const interval = setInterval(() => setFromFilter(getFromFilter()), visibleUserTimeIntervalInSeconds * 1000);
        return () => clearInterval(interval);
    }, []);

    function getFromFilter(): number {
        return getUTCTimestamp().seconds - visibleUserTimeIntervalInSeconds;
    }

    function mapItems(items: PageView[]): PageViewViewModel[] {
        return (
            items?.map(i => {
                const pageView = mapper.map<PageView, PageViewViewModel>(i, PageView, PageViewViewModel);
                const user = boUsers?.items?.find(u => u.id === i.user_id);
                return {...pageView, ...user};
            }) ?? []
        );
    }

    const pageViews = mapItems(items);
    return {
        all: pageViews,
        groupedByRecordId: pageViews?.reduce((grouped, pageView) => {
            grouped[pageView.recordId] = grouped[pageView.recordId] ?? [];
            grouped[pageView.recordId].push(pageView);
            return grouped;
        }, {} as Record<string, PageViewViewModel[]>),
    };
}
