import {Span} from '@opentelemetry/api';
import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';
import {AjaxError, AjaxRequest} from 'rxjs/ajax';
import {catchError, map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {ITracingService, mergeMap} from '@otel';
import {UserManagerExtended} from '@auth';
import {AjaxResponse} from '@services/rest-api';
import {HttpMethod, ServerResponseError, ServerResponseStatus} from '@services/types';

import {RestRequest, RestResponse} from '../types';

import {ajax, AjaxFunction} from './ajaxProxy';
import {FetchFunction, fetchWithTracing} from './fetchProxy';
import {Plo5ServerResponseError} from './types';

type RestErrorResponse = Omit<RestResponse, 'requestPayload' | 'responsePayload'>;

type ContentType = 'application/json' | 'auto';

type ResponseType = 'json' | 'blob';

export abstract class RestService {
    private readonly _userManager: UserManagerExtended;

    protected constructor(userManager: UserManagerExtended) {
        this._userManager = userManager;
    }

    public get(restRequest: RestRequest, parentSpan?: Span, withAuth = true, responseType?: ResponseType): Observable<RestResponse> {
        return this.sendRequest(HttpMethod.Get, restRequest, parentSpan, withAuth, null, responseType);
    }

    public post(
        restRequest: RestRequest,
        parentSpan?: Span,
        withAuth = true,
        contentType: ContentType = 'application/json'
    ): Observable<RestResponse> {
        return this.sendRequest(HttpMethod.Post, restRequest, parentSpan, withAuth, contentType);
    }

    public patch(
        restRequest: RestRequest,
        parentSpan?: Span,
        withAuth = true,
        contentType: ContentType = 'application/json'
    ): Observable<RestResponse> {
        return this.sendRequest(HttpMethod.Patch, restRequest, parentSpan, withAuth, contentType);
    }

    public delete(
        restRequest: RestRequest,
        parentSpan?: Span,
        withAuth = true,
        contentType: ContentType = 'application/json'
    ): Observable<RestResponse> {
        return this.sendRequest(HttpMethod.Delete, restRequest, parentSpan, withAuth, contentType);
    }

    public put(
        restRequest: RestRequest,
        parentSpan?: Span,
        withAuth = true,
        contentType: ContentType = 'application/json'
    ): Observable<RestResponse> {
        return this.sendRequest(HttpMethod.Put, restRequest, parentSpan, withAuth, contentType);
    }

    protected abstract sendRequest(
        method: HttpMethod,
        restRequest: RestRequest,
        parentSpan?: Span,
        withAuth?: boolean,
        contentType?: ContentType,
        responseType?: ResponseType
    ): Observable<RestResponse>;

    protected getBody(method: HttpMethod, restRequest: RestRequest, contentType?: ContentType) {
        return method !== HttpMethod.Get
            ? contentType === 'application/json'
                ? JSON.stringify(restRequest.body)
                : restRequest.body
            : undefined;
    }

    protected getUser() {
        return this._userManager.getUserExtended();
    }

    protected parseError(error: AjaxError): RestErrorResponse {
        return error instanceof AjaxError ? this.parseAjaxError(error) : this.parseUnknownError(error);
    }

    private parseAjaxError(error: AjaxError): RestErrorResponse {
        return {
            status: ServerResponseStatus.Failed,
            message: error.message,
            errors: error.response
                ? this.parseServerErrorResponse(error.response)
                : [
                      {
                          code: error.status?.toString() ?? null,
                          message: error.message,
                          values: [JSON.stringify(error)],
                      },
                  ],
        };
    }

    private parseServerErrorResponse(error: Plo5ServerResponseError): ServerResponseError[] {
        return [
            {
                code: error.error_code?.toString() ?? null,
                message: error.msg,
                traceId: error.traceid,
                values: [JSON.stringify(error)],
            },
        ];
    }

    private parseUnknownError(error: Error): RestErrorResponse {
        return {
            status: ServerResponseStatus.Failed,
            message: error.message,
            errors: [
                {
                    code: error.name,
                    message: error.message,
                    values: [JSON.stringify(error)],
                },
            ],
        };
    }
}

@injectable()
export class RestXhrService extends RestService {
    private _ajax: AjaxFunction;

    constructor(
        @inject(ServiceTypes.TracingService) tracingService: ITracingService,
        @inject(ServiceTypes.UserManager) userManager: UserManagerExtended
    ) {
        super(userManager);
        this._ajax = ajax(tracingService);
    }

    protected sendRequest(
        method: HttpMethod,
        restRequest: RestRequest,
        parentSpan?: Span,
        withAuth = true,
        contentType?: ContentType,
        responseType: ResponseType = 'json'
    ): Observable<RestResponse> {
        const contentTypeHeader = contentType && contentType !== 'auto' ? {['Content-Type']: contentType} : {};
        return this.getUser().pipe(
            mergeMap(user => {
                const authHeader = withAuth ? {...user.authHeaders} : {};
                const request: Partial<AjaxRequest> = {
                    method,
                    headers: {...authHeader, ...contentTypeHeader},
                    body: this.getBody(method, restRequest, contentType),
                    url: `${restRequest.endpoint}?${restRequest.query ?? ''}`,
                    withCredentials: restRequest?.withCredentials ?? true,
                    responseType,
                };
                return this._ajax(request, user.basicInfo, parentSpan).pipe(
                    map(resp => ({
                        status: ServerResponseStatus.Success,
                        requestPayload: restRequest,
                        responsePayload: resp,
                    })),
                    catchError(err => {
                        return of({
                            requestPayload: restRequest,
                            responsePayload: null as AjaxResponse,
                            ...this.parseError(err),
                        });
                    })
                );
            })
        );
    }
}

@injectable()
export class RestFetchApiService extends RestService {
    private _fetch: FetchFunction;

    constructor(
        @inject(ServiceTypes.TracingService) tracingService: ITracingService,
        @inject(ServiceTypes.UserManager) userManager: UserManagerExtended
    ) {
        super(userManager);
        this._fetch = fetchWithTracing(tracingService);
    }

    protected sendRequest(
        method: HttpMethod,
        restRequest: RestRequest,
        parentSpan?: Span,
        withAuth = true,
        contentType?: ContentType
    ): Observable<RestResponse> {
        const contentTypeHeader = contentType ? {['Content-Type']: contentType} : {};
        return this.getUser().pipe(
            mergeMap(user => {
                const authHeader = withAuth ? {...user.authHeaders} : {};
                const request: RequestInit = {
                    method,
                    headers: {...authHeader, ...contentTypeHeader},
                    body: this.getBody(method, restRequest, contentType),
                };

                return this._fetch(`${restRequest.endpoint}?${restRequest.query ?? ''}`, request, user.basicInfo, parentSpan).pipe(
                    map(resp => ({
                        status: ServerResponseStatus.Success,
                        requestPayload: restRequest,
                        responsePayload: resp,
                    })),
                    catchError(err => {
                        return of({
                            requestPayload: restRequest,
                            responsePayload: null,
                            ...this.parseError(err),
                        });
                    })
                );
            })
        );
    }
}
