import {createMap, forMember, ignore, mapFrom, Mapper, mapWith} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {
    LabelGroup as GqlLabelGroup,
    LabelGroupInput,
    LabelGroupSourceType,
    LabelInput,
    UserLabel,
    UserLabelGroup,
} from '@models/generated/graphql';

import {AddLabelGroupRequestPayload, UpdateLabelGroupRequestPayload, UpdateLabelRequestPayload} from '../block-label-group-actions/actions';

import {Label, LabelGroup, LabelGroupForm, LabelUpdateForm} from './types';

export class PlayerLabelMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            UserLabel,
            Label,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.group,
                mapWith(LabelGroup, UserLabelGroup, m => m.group)
            )
        );
        createMap(
            mapper,
            UserLabelGroup,
            LabelGroup,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.color,
                mapFrom(m => m.color)
            ),
            forMember(vm => vm.source_type, ignore())
        );
        createMap(
            mapper,
            GqlLabelGroup,
            LabelGroup,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.color,
                mapFrom(m => m.color)
            ),
            forMember(
                vm => vm.source_type,
                mapFrom(m => m.source_type)
            ),
            forMember(
                vm => vm.labels,
                mapFrom(m => m.labels)
            )
        );
        createMap(
            mapper,
            LabelGroupForm,
            LabelGroupInput,
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.color,
                mapFrom(m => m.color)
            ),
            forMember(
                vm => vm.source_type,
                mapFrom(() => LabelGroupSourceType.Custom)
            )
        );
        createMap(
            mapper,
            LabelGroupForm,
            AddLabelGroupRequestPayload,
            forMember(
                vm => vm.group,
                mapWith(LabelGroupInput, LabelGroupForm, m => m)
            )
        );
        createMap(
            mapper,
            LabelGroupForm,
            UpdateLabelGroupRequestPayload,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.group,
                mapWith(LabelGroupInput, LabelGroupForm, m => m)
            )
        );
        createMap(
            mapper,
            LabelUpdateForm,
            LabelInput,
            forMember(
                vm => vm.group_id,
                mapFrom(m => m.groupId)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            )
        );
        createMap(
            mapper,
            LabelUpdateForm,
            UpdateLabelRequestPayload,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.label,
                mapWith(LabelInput, LabelUpdateForm, m => m)
            )
        );
    }
}
