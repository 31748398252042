import {defineMessages} from 'react-intl';

export const localizedHeaders = defineMessages({
    uid: {
        id: 'DataGridAgentPlayerReporting_uid',
        defaultMessage: 'Player ID',
    },
    username: {
        id: 'DataGridAgentPlayerReporting_username',
        defaultMessage: 'Username',
    },
    phone: {
        id: 'DataGridAgentPlayerReporting_phone',
        defaultMessage: 'Phone Number',
    },
    email: {
        id: 'DataGridAgentPlayerReporting_email',
        defaultMessage: 'Email Address',
    },
    country: {
        id: 'DataGridAgentPlayerReporting_country',
        defaultMessage: 'Country',
    },
    referrer_agent_id: {
        id: 'DataGridAgentPlayerReporting_referrerAgentId',
        defaultMessage: 'Referral ID',
    },
    referrer_agent_username: {
        id: 'DataGridAgentPlayerReporting_referrerAgentUsername',
        defaultMessage: 'Referral Username',
    },
    cash_ggr: {
        id: 'DataGridAgentPlayerReporting_cashGgr',
        defaultMessage: 'Cash GGR',
    },
    external_casino_ggr: {
        id: 'DataGridAgentPlayerReporting_externalCasinoGgr',
        defaultMessage: 'External Casino GGR',
    },
    internal_casino_ggr: {
        id: 'DataGridAgentPlayerReporting_internalCasinoGgr',
        defaultMessage: 'Internal Casino GGR',
    },
    external_casino_bet_amount: {
        id: 'DataGridAgentPlayerReporting_externalCasinoBetAmount',
        defaultMessage: 'External Casino Turn Over',
    },
    internal_casino_bet_amount: {
        id: 'DataGridAgentPlayerReporting_internalCasinoBetAmount',
        defaultMessage: 'Internal Casino Turn Over',
    },
    poker_addons: {
        id: 'DataGridAgentPlayerReporting_pokerAddons',
        defaultMessage: 'Poker Addons',
    },
    poker_ggr: {
        id: 'DataGridAgentPlayerReporting_pokerGgr',
        defaultMessage: 'Poker GGR',
    },
    poker_ngr: {
        id: 'DataGridAgentPlayerReporting_pokerNgr',
        defaultMessage: 'Poker NGR',
    },
    poker_mtt_fees: {
        id: 'DataGridAgentPlayerReporting_pokerMttFees',
        defaultMessage: 'Poker MTT Fees',
    },
    poker_rake: {
        id: 'DataGridAgentPlayerReporting_pokerRake',
        defaultMessage: 'Poker Rake',
    },
    mtt_ggr: {
        id: 'DataGridAgentPlayerReporting_mttGgr',
        defaultMessage: 'MTT GGR',
    },
    casino_ggr: {
        id: 'DataGridAgentPlayerReporting_casinoGgr',
        defaultMessage: 'Casino GGR',
    },
    poker_share: {
        id: 'DataGridAgentPlayerReporting_pokerShare',
        defaultMessage: 'Poker Share',
    },
    casino_share: {
        id: 'DataGridAgentPlayerReporting_casinoShare',
        defaultMessage: 'Casino Share',
    },
    total_share: {
        id: 'DataGridAgentPlayerReporting_totalShare',
        defaultMessage: 'Total Share',
    },
    global_spin_ggr: {
        id: 'DataGridAgentPlayerReporting_globalSpinGgr',
        defaultMessage: 'EGGR',
    },
    total_ggr: {
        id: 'DataGridAgentPlayerReporting_totalGgr',
        defaultMessage: 'Total GGR',
    },
    cash_ngr: {
        id: 'DataGridAgentPlayerReporting_cashNgr',
        defaultMessage: 'Cash NGR',
    },
    mtt_ngr: {
        id: 'DataGridAgentPlayerReporting_mttNgr',
        defaultMessage: 'MTT NGR',
    },
    casino_ngr: {
        id: 'DataGridAgentPlayerReporting_casinoNgr',
        defaultMessage: 'Casino NGR',
    },
    total_ngr: {
        id: 'DataGridAgentPlayerReporting_totalNgr',
        defaultMessage: 'Total NGR',
    },
    // TODO: [IGP-4457] Remove and use cash_realized_bonus instead of it
    realized_bonus_total: {
        id: 'DataGridAgentPlayerReporting_realizedBonusTotal',
        defaultMessage: 'Total Realized Bonus',
    },
    cash_realized_bonus: {
        id: 'DataGridAgentPlayerReporting_cashRealizedBonus',
        defaultMessage: 'Cash Realized Bonus',
    },
    mtt_ticket_bonus: {
        id: 'DataGridAgentPlayerReporting_mttTicketBonus',
        defaultMessage: 'MTT Ticket Bonus',
    },
    poker_bonus: {
        id: 'DataGridAgentPlayerReporting_pokerBonus',
        defaultMessage: 'Poker Bonus',
    },
    casino_bonus: {
        id: 'DataGridAgentPlayerReporting_casinoBonus',
        defaultMessage: 'Casino Bonus',
    },
    unrealized_bonus_total: {
        id: 'DataGridAgentPlayerReporting_unrealizedBonusTotal',
        defaultMessage: 'Total Unrealized Bonus',
    },
    cost_global_spins_tickets: {
        id: 'DataGridAgentPlayerReporting_costGlobalSpinsTickets',
        defaultMessage: 'Spins Tournament Tickets',
    },
    ngr_cost: {
        id: 'DataGridAgentPlayerReporting_ngrCost',
        defaultMessage: 'NGR Cost',
    },
    psp_fee: {
        id: 'DataGridAgentPlayerReporting_pspFee',
        defaultMessage: 'PSP Fee',
    },
    casino_provider_fee: {
        id: 'DataGridAgentPlayerReporting_casinoProviderFee',
        defaultMessage: 'Casino Provider Fee',
    },
    total_deduction: {
        id: 'DataGridAgentPlayerReporting_totalDeduction',
        defaultMessage: 'Total Deduction',
    },
    player_net_deposit: {
        id: 'DataGridAgentPlayerReporting_playerNetDeposit',
        defaultMessage: 'Player Net Deposit',
    },
    cash_game_hand_played: {
        id: 'DataGridAgentPlayerReporting_cashGameHandPlayed',
        defaultMessage: 'Cash game hand played',
    },
    mtt_played: {
        id: 'DataGridAgentPlayerReporting_mttPlayed',
        defaultMessage: 'MTT played',
    },
    player_profit_loss: {
        id: 'DataGridAgentPlayerReporting_playerProfitLoss',
        defaultMessage: 'Player profit loss',
    },
    revenue_share_type: {
        id: 'DataGridAgentPlayerReporting_revenue_share_type',
        defaultMessage: 'Revenue Share Type',
    },
    referee_type: {
        id: 'DataGridAgentPlayerReporting_referee_type',
        defaultMessage: 'Referee Type',
    },
    rakes: {
        id: 'DataGridAgentPlayerReporting_rakes',
        defaultMessage: 'Rakes',
    },
    insurance_tool: {
        id: 'DataGridAgentPlayerReporting_insurance_tool',
        defaultMessage: 'Insurance Tool',
    },
    registered_player_number: {
        id: 'DataGridAgentPlayerReporting_registered_player_number',
        defaultMessage: 'Registered Player Number',
    },
    active_player_number: {
        id: 'DataGridAgentPlayerReporting_active_player_number',
        defaultMessage: 'Active Player Number',
    },
    revenue_share: {
        id: 'DataGridAgentPlayerReporting_revenue_share',
        defaultMessage: 'Revenue Share',
    },
    marketingCode: {
        id: 'DataGridAgentPlayerReporting_marketingCode',
        defaultMessage: 'Marketing Code',
    },
    plo_ggr_high_stake: {
        id: 'DataGridAgentPlayerReporting_plo_ggr_high_stake',
        defaultMessage: 'PLO GGR High Stake',
    },
    plo_ggr_low_stake: {
        id: 'DataGridAgentPlayerReporting_plo_ggr_low_stake',
        defaultMessage: 'PLO GGR Low Stake',
    },
    plo_ggr_medium_stake: {
        id: 'DataGridAgentPlayerReporting_plo_ggr_medium_stake',
        defaultMessage: 'PLO GGR Medium Stake',
    },
    plo_ggr_micro_stake: {
        id: 'DataGridAgentPlayerReporting_plo_ggr_micro_stake',
        defaultMessage: 'PLO GGR Micro Stake',
    },
    plo_ggr_total: {
        id: 'DataGridAgentPlayerReporting_plo_ggr_total',
        defaultMessage: 'PLO GGR Total',
    },
    plo_ggr_unknown_stake: {
        id: 'DataGridAgentPlayerReporting_plo_ggr_unknown_stake',
        defaultMessage: 'PLO GGR Unknown Stake',
    },
    mtt_total_buyin: {
        id: 'DataGridAgentPlayerReporting_mtt_total_buyin',
        defaultMessage: 'MTT Total Buy In',
    },
    cost_tournament_overlay_excl_freeroll: {
        id: 'DataGridAgentPlayerReporting_cost_tournament_overlay_excl_freeroll',
        defaultMessage: 'Tournament Overlay',
    },
    cost_ftd_bonus_pro: {
        id: 'DataGridAgentPlayerReporting_cost_ftd_bonus_pro',
        defaultMessage: 'FTD Bonus Cost',
    },
    cost_psp_fee_pro: {
        id: 'DataGridAgentPlayerReporting_cost_psp_fee_pro',
        defaultMessage: 'Psp Fee Cost',
    },
    cost_spins_tournament_tickets_pro: {
        id: 'DataGridAgentPlayerReporting_cost_spins_tournament_tickets_pro',
        defaultMessage: 'Cost Spins Ticket',
    },
    highest_blind_level_is_pro_nlhe: {
        id: 'DataGridAgentPlayerReporting_highest_blind_level_is_pro_nlhe',
        defaultMessage: 'Highest blind level is pro',
    },
    is_pro_shortdeck: {
        id: 'DataGridAgentPlayerReporting_is_pro_shortdeck',
        defaultMessage: 'Pro Shortdeck',
    },
    nlhe_ggr_usd_pro: {
        id: 'DataGridAgentPlayerReporting_nlhe_ggr_usd_pro',
        defaultMessage: 'NLHE GGR USD Pro',
    },
    nlhe_high_ggr_usd_pro: {
        id: 'DataGridAgentPlayerReporting_nlhe_high_ggr_usd_pro',
        defaultMessage: 'NLHE High GGR USD Pro',
    },
    nlhe_low_ggr_usd_pro: {
        id: 'DataGridAgentPlayerReporting_nlhe_low_ggr_usd_pro',
        defaultMessage: 'NLHE Low GGR USD Pro',
    },
    nlhe_medium_ggr_usd_pro: {
        id: 'DataGridAgentPlayerReporting_nlhe_medium_ggr_usd_pro',
        defaultMessage: 'NLHE Medium GGR USD Pro',
    },
    nlhe_micro_ggr_usd_pro: {
        id: 'DataGridAgentPlayerReporting_nlhe_micro_ggr_usd_pro',
        defaultMessage: 'NLHE Micro GGR USD Pro',
    },
    poker_ggr_usd_pro: {
        id: 'DataGridAgentPlayerReporting_poker_ggr_usd_pro',
        defaultMessage: 'Poker GGR USD Pro',
    },
    shortdeck_ggr_usd_pro: {
        id: 'DataGridAgentPlayerReporting_shortdeck_ggr_usd_pro',
        defaultMessage: 'ShortDeck GGR USD Pro',
    },
});
