import {createAction, createAsyncAction} from 'typesafe-actions';

import {IFailureResponse} from '../../module-shared/types';

import {JurisdictionConfiguration, JurisdictionLocales as JurisdictionLocale, JurisdictionTimezone} from './types';

const loadJurisdictionConfig = 'app-config/loadJurisdictionConfig';
const loadJurisdictionConfigSuccess = 'app-config/loadJurisdictionConfigSuccess';
const loadJurisdictionConfigFailed = 'app-config/loadJurisdictionConfigFailed';
const setJurisdictionTimezone = 'app-config/setJurisdictionTimezone';
const setJurisdictionLocale = 'app-config/setJurisdictionLocale';

export const loadJurisdictionConfigAction = createAsyncAction(
    loadJurisdictionConfig,
    loadJurisdictionConfigSuccess,
    loadJurisdictionConfigFailed
)<void, JurisdictionConfiguration, IFailureResponse>();
export const setJurisdictionTimezoneAction = createAction(setJurisdictionTimezone)<JurisdictionTimezone>();
export const setJurisdictionLocaleAction = createAction(setJurisdictionLocale)<JurisdictionLocale>();
