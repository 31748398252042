import React from 'react';
import {Dialog} from '@mui/material';

import {useModalClasses} from './Modal.style';
import {ModalContent} from './ModalContent';
import {ModalContext} from './types';

export const Modal = () => {
    const {classes: modalClasses} = useModalClasses();

    const {isModalOpened, modalContent, closeModal} = React.useContext(ModalContext);

    const dialogClasses = {
        paper: `${modalContent?.styleParams?.isLimitedBody ? modalClasses.modalDialogLimited : ''}`,
    };

    function handleClose() {
        if (modalContent?.onClose) {
            modalContent.onClose();
        }
        closeModal();
    }

    return (
        <Dialog
            classes={dialogClasses}
            open={isModalOpened}
            onClose={handleClose}
            scroll="paper"
            maxWidth={modalContent?.styleParams?.maxWidth ?? 'sm'}
            fullWidth={true}
            data-testid="modal"
        >
            <ModalContent {...{...modalContent, onCloseClick: handleClose}}></ModalContent>
        </Dialog>
    );
};
