import {useContext} from 'react';
import {defineMessages, IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {UserBlackList} from '@models/generated/graphql';

import {useCurrency} from '../../app/intl/hooks';

export type BlacklistedPlayerValue = {
    black_list: UserBlackList;
};

export const localized = defineMessages({
    enabled: {
        id: 'enabled',
        defaultMessage: 'Enabled',
    },
    disabled: {
        id: 'disabled',
        defaultMessage: 'Disabled',
    },
});

export const useAdminBlacklistedPlayerFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: BlacklistedPlayerValue = JSON.parse(json);

    const formatBlacklist = (blacklist: UserBlackList) =>
        blacklist?.blacklisted
            ? `${formatMessage(localized.enabled)} - ${useCurrency(value.black_list.deposit_threshold)}`
            : formatMessage(localized.disabled);

    return value?.black_list ? [{key: formatBlacklist(value.black_list)}] : null;
};
