import React, {useEffect, useState} from 'react';
import {Divider, SelectChangeEvent} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledSelect from '@components/StyledSelect';
import {CustomTheme} from '@style';

import {SelectOption} from 'src/features/module-shared/types';

import {TextFilter} from './TextFilter';
import {TextInputBasedFilterProps} from './types';

export type FilterWithDropdownValue = {
    option: string;
    text: string;
};

type TextFilterWithDropdownProps = TextInputBasedFilterProps<FilterWithDropdownValue> & {
    options: SelectOption[];
};

const useClasses = makeStyles()((theme: CustomTheme) => ({
    textWithDropdownFilter: {
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius * 2,
        height: theme.custom.denseButtonHeight,
        '& .Mui-focused': {
            border: '0 !important',
            boxShadow: 'none !important',
        },
        '& .MuiOutlinedInput-root': {
            border: '0 !important',
            boxShadow: 'none !important',
        },
        border: '1px solid #E1E7EB',
        boxSizing: 'border-box',
        boxShadow: `0px 1px 1px ${theme.custom.palette.content.boxShadow}`,
    },
    textWithDropdownFilterWidthNoLimit: {
        display: 'flex',
        flexGrow: 1,
    },
    textWithDropdownFilterSelect: {
        paddingLeft: theme.spacing(2),
        paddingRight: `${theme.spacing(1.25)} !important`,
        '&:focus': {
            background: 'transparent',
        },
    },
    textWithDropdownFilterSelectIcon: {
        paddingRight: theme.spacing(2),
    },
}));

export function TextInputWithDropdownFilter({
    options,
    value,
    onChange,
    width,
    inputPlaceholder,
    disallowSpecialCharacters,
}: TextFilterWithDropdownProps) {
    const {classes, cx} = useClasses();
    const [option, setOption] = useState(getOption());
    // Need to prevent option state repeated change after user update
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        if (!isDirty) {
            setOption(getOption());
        }
    }, [value?.option, isDirty, options?.map(v => v.value).join()]);

    function handleSelectChange(event: SelectChangeEvent<{name?: string; value: unknown}>): void {
        onChange({text: value?.text, option: event.target.value as string});
        setOption(event.target.value as string);
        setIsDirty(true);
    }

    function handleInputChange(newValue: string): void {
        setIsDirty(true);
        onChange({text: newValue, option});
    }

    function getOption() {
        return value?.option ?? (options[0]?.value as string);
    }

    return (
        <div
            className={cx(
                {
                    [classes.textWithDropdownFilterWidthNoLimit]: width === 'full',
                },
                classes.textWithDropdownFilter
            )}
            data-testid="dropdownTextFilter"
        >
            <StyledSelect
                disableUnderline
                value={option}
                onChange={handleSelectChange}
                options={options}
                classes={{select: classes.textWithDropdownFilterSelect}}
                iconClassName={classes.textWithDropdownFilterSelectIcon}
                alignLeft
            />
            <Divider orientation="vertical" />
            <TextFilter
                value={value?.text}
                width={width}
                inputPlaceholder={inputPlaceholder}
                onChange={handleInputChange}
                onEnter={handleInputChange}
                disallowSpecialCharacters={disallowSpecialCharacters}
            />
        </div>
    );
}
