import {defineMessages} from 'react-intl';

import {BonusStatus} from '@models/generated/graphql';

export const localizedBonusStatus = defineMessages({
    [BonusStatus.Active]: {
        id: 'localizedPlayerBonusStatusActive',
        defaultMessage: 'Active',
    },
    [BonusStatus.Inactive]: {
        id: 'localizedPlayerBonusStatusInactive',
        defaultMessage: 'Inactive',
    },
});
