export enum RealtimeMessageTrigger {
    Add = 'Add',
    Update = 'Update',
    Delete = 'Delete',
}

export enum RealtimeUpdatesMode {
    Silent = 'Silent',
    Confirm = 'Confirm',
}

export const domain = 'realtime';
