import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {AccountVerification, InitKycInput} from '@models/generated/graphql';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {AccountVerificationNormalized, entityActions, EntityType} from '@redux/entity';
import {IAccountVerificationService, UpdateKycArgs} from '@services/accountVerificationService';
import {GqlMutationRequest} from '@services/types';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {accountVerificationActions, UpdateKycStatusRequestPayload} from './actions';

const localized = defineMessages({
    initiatePaymentKycSuccess: {
        id: 'BlockAccountVerificationActionsEpicsBuilder_initiatePaymentKycSuccess',
        defaultMessage: 'Payment KYC is successfully initiated',
    },
    initiatePaymentKycFailed: {
        id: 'BlockAccountVerificationActionsEpicsBuilder_initiatePaymentKycFailed',
        defaultMessage: 'Failed to initiate payment KYC',
    },
    updateKycStatusSuccess: {
        id: 'BlockAccountVerificationActionsEpicsBuilder_updateKycStatusSuccess',
        defaultMessage: 'KYC status is successfully updated',
    },
    updateKycStatusFailed: {
        id: 'BlockAccountVerificationActionsEpicsBuilder_updateKycStatusFailed',
        defaultMessage: 'Failed to update KYC status',
    },
});

@injectable()
export class AccountVerificationActionsEpicsBuilder extends BaseEpicsBuilder {
    private readonly _service: IAccountVerificationService;

    constructor(@inject(ServiceTypes.AccountVerificationService) service: IAccountVerificationService) {
        super();
        this._service = service;
    }

    protected buildEpicList(): Epic[] {
        return [
            this.buildInitiatePaymentKycRequestEpic(),
            this.buildInitiatePaymentKycSuccessEpic(),
            this.buildInitiatePaymentKycFailureEpic(),
            this.buildUpdateKycStatusRequestEpic(),
            this.buildUpdateKycStatusSuccessEpic(),
            this.buildUpdateKycStatusFailureEpic(),
        ];
    }

    private buildInitiatePaymentKycRequestEpic(): Epic {
        return this.buildRequestEpic<InitKycInput, GqlMutationRequest, AccountVerification>(
            accountVerificationActions.initiatePaymentKyc,
            payload => this._service.initiatePaymentKyc(payload)
        );
    }

    private buildInitiatePaymentKycSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(accountVerificationActions.initiatePaymentKyc.success)),
                mergeMap(() => {
                    return of(showMessageAction({message: localized.initiatePaymentKycSuccess}));
                })
            );
    }

    private buildInitiatePaymentKycFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(accountVerificationActions.initiatePaymentKyc.failure)),
                map(() => showErrorAction({message: localized.initiatePaymentKycFailed}))
            );
    }

    private buildUpdateKycStatusRequestEpic(): Epic {
        return this.buildRequestEpic<UpdateKycStatusRequestPayload, GqlMutationRequest<UpdateKycArgs>, AccountVerification>(
            accountVerificationActions.updateKycStatus,
            payload => this._service.updateKycStatus(payload)
        );
    }

    private buildUpdateKycStatusSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(accountVerificationActions.updateKycStatus.success)),
                mergeMap(response => {
                    const variables = response.payload.requestPayload.variables;
                    const id = response.payload.responsePayload.id;
                    const {status} = variables;
                    const updatedItem = {account_verification_status: status} as AccountVerificationNormalized;

                    return of(
                        entityActions.updateItem({type: EntityType.AccountVerification, id, updatedItem}),
                        showMessageAction({message: localized.updateKycStatusSuccess})
                    );
                })
            );
    }

    private buildUpdateKycStatusFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(accountVerificationActions.updateKycStatus.failure)),
                map(() => showErrorAction({message: localized.updateKycStatusFailed}))
            );
    }
}
