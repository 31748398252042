import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

//TODO: [BO-2669] Move dropdown components to input folder (?)
export const useDropdownItemClasses = makeStyles<void, 'dropdownItemConfirmIcon'>()((theme: CustomTheme, _params, classes) => ({
    dropdownItem: {
        width: '100%',
        [`&.Mui-selected .${classes.dropdownItemConfirmIcon}`]: {
            display: 'block',
        },
    },
    dropdownItemIcon: {
        marginRight: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        width: theme.custom.dropdownIconWidth,
    },
    dropdownItemConfirmIcon: {
        display: 'none',
        marginLeft: 'auto',
    },
}));
