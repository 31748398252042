import {defineMessages} from 'react-intl';

import {PaymentOption} from '@models/generated/graphql';

export const localizedPaymentOption = defineMessages({
    [PaymentOption.PaymentOptionCreditcard]: {
        id: 'paymentOption_Creditcard',
        defaultMessage: 'Credit Card',
    },
    [PaymentOption.PaymentOptionDebitcard]: {
        id: 'paymentOption_Debitcard',
        defaultMessage: 'Debit Card',
    },
    [PaymentOption.PaymentOptionFastbankttransfer]: {
        id: 'paymentOption_Fastbankttransfer',
        defaultMessage: 'Fast Bank Transfer',
    },
    [PaymentOption.PaymentOptionNeteller]: {
        id: 'paymentOption_Neteller',
        defaultMessage: 'Neteller',
    },
    [PaymentOption.PaymentOptionNone]: {
        id: 'paymentOption_None',
        defaultMessage: 'None',
    },
    [PaymentOption.PaymentOptionPaypal]: {
        id: 'paymentOption_Paypal',
        defaultMessage: 'Paypal',
    },
    [PaymentOption.PaymentOptionQiwi]: {
        id: 'paymentOption_Qiwi',
        defaultMessage: 'Qiwi',
    },
    [PaymentOption.PaymentOptionYandex]: {
        id: 'paymentOption_Yandex',
        defaultMessage: 'Yandex',
    },
    [PaymentOption.PaymentOptionSkrill]: {
        id: 'paymentOption_Skrill',
        defaultMessage: 'Skrill',
    },
    [PaymentOption.PaymentOptionCryptocurrency]: {
        id: 'paymentOption_Cryptocurrency',
        defaultMessage: 'Cryptocurrency',
    },
    [PaymentOption.PaymentOptionAstropaycard]: {
        id: 'paymentOption_Astropaycard',
        defaultMessage: 'Astropay Card',
    },
    [PaymentOption.PaymentOptionBanklocal]: {
        id: 'paymentOption_Banklocal',
        defaultMessage: 'Bank Local',
    },
    [PaymentOption.PaymentOptionBankintl]: {
        id: 'paymentOption_Bankintl',
        defaultMessage: 'Bank Intl',
    },
});
