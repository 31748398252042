import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {createEntityRealtimeActions, realtimeNotificationActions} from './actions';
import {RealtimeUpdatesMeta, SubscriptionType} from './types';

export const createRealtimeStateReducer = (domain: string) => {
    const actions = createEntityRealtimeActions(domain);

    const realtimeSubscribersReducer = createReducer([] as string[])
        .handleAction(actions.enableRealtime, (state, action) => [...state, action.payload.domain])
        .handleAction(actions.disableRealtime, (state, action) => [...state].filter(d => d !== action.payload));

    const enabledSubscriptionTypesReducer = createReducer([] as SubscriptionType[])
        .handleAction(actions.enableRealtime, (state, action) => [...state, ...action.payload.types])
        .handleAction(actions.stopRealtime, () => []);

    const realtimeStateReducer = combineReducers({
        realtimeSubscribers: realtimeSubscribersReducer,
        enabledSubscriptionTypes: enabledSubscriptionTypesReducer,
    });

    return {realtime: realtimeStateReducer};
};

export const globalRealtimeNotificationReducer = createReducer([] as RealtimeUpdatesMeta[])
    .handleAction(realtimeNotificationActions.notifyUpdates, (state, action) =>
        state.find(m => m.domain === action.payload.domain) ? state : [...state, action.payload]
    )
    .handleAction(realtimeNotificationActions.clearNotificationsForDomain, (state, action) =>
        [...state].filter(m => m.domain !== action.payload)
    )
    .handleAction(realtimeNotificationActions.clearNotification, () => []);
