import React from 'react';

import {DisplayBoolean, DisplayBooleanProps} from '@components/data-visualize';

import {WorkspaceList} from './WorkspaceList';

export const DisplayVpn = ({value}: DisplayBooleanProps) => {
    return (
        <WorkspaceList>
            <DisplayBoolean value={value ?? false} />
        </WorkspaceList>
    );
};
