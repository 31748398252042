import {constructUsing, createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {PlayerBonus} from '@models/generated/graphql';
import {PlayerBonusViewModel, PlayerBonusViewModelKeys} from '@models/player-bonus/types';
import {PlayerBonusQueryFields} from '@redux/entity';
import {formatTimestamp} from '@utils/date';

import {BonusCodeDetailsViewModel} from '../../../features/block-bonus-code/types';

export class PlayerBonusMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            PlayerBonus,
            PlayerBonusViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_engine_code,
                mapFrom(m => m.bonus_engine_code)
            ),
            forMember(
                vm => vm.bonus_id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_name,
                mapFrom(m => m.bonus_name)
            ),
            forMember(
                vm => vm.bonus_status,
                mapFrom(m => m.bonus_status)
            ),
            forMember(
                vm => vm.created_at,
                mapFrom(m => formatTimestamp(m.created_at, 'date-time'))
            ),
            forMember(
                vm => vm.desc,
                mapFrom(m => m.desc)
            ),
            forMember(
                vm => vm.expire_at,
                mapFrom(m => formatTimestamp(m.expire_at, 'date'))
            ),
            forMember(
                vm => vm.marketing_code,
                mapFrom(m => m.marketing_code)
            ),
            forMember(
                vm => vm.realized_amount,
                mapFrom(m => m.realized_amount)
            ),
            forMember(
                vm => vm.total_amount,
                mapFrom(m => m.total_amount)
            ),
            forMember(
                vm => vm.type,
                mapFrom(m => m.type)
            ),
            forMember(
                vm => vm.uid,
                mapFrom(m => m.uid)
            ),
            forMember(
                vm => vm.remaining_amount,
                mapFrom(m => (m?.total_amount && m?.realized_amount ? m.total_amount - m.realized_amount : null))
            )
        );
        createMap(
            mapper,
            BonusCodeDetailsViewModel,
            PlayerBonusViewModel,
            forMember(
                vm => vm.release_mechanism,
                mapFrom(m => m?.release_mechanism)
            ),
            forMember(
                vm => vm.credit_type,
                mapFrom(m => m?.credit_type)
            )
        );
        createMap<PlayerBonusViewModelKeys[], PlayerBonusQueryFields[]>(
            mapper,
            nameof<PlayerBonusViewModelKeys>(),
            nameof<PlayerBonusQueryFields>(),
            constructUsing((m, _) => {
                const mapper: Record<PlayerBonusViewModelKeys, PlayerBonusQueryFields[]> = {
                    id: ['bonus_id'],
                    bonus_engine_code: ['bonus_engine_code'],
                    bonus_id: ['bonus_id'],
                    bonus_name: ['bonus_name'],
                    bonus_status: ['bonus_status'],
                    'created_at.seconds': ['created_at.seconds'],
                    desc: ['desc'],
                    'expire_at.seconds': ['expire_at.seconds'],
                    marketing_code: ['marketing_code'],
                    realized_amount: ['realized_amount'],
                    total_amount: ['total_amount'],
                    type: ['type'],
                    uid: ['uid'],
                    remaining_amount: ['total_amount', 'realized_amount'],
                    release_mechanism: [],
                    credit_type: [],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
    }
}
