import {MuiDataGridProps} from '../MuiDataGrid';

type UseRowsReturnType = {
    rows: any[];
    rowsPerPageOptions: number[];
};

export function useRows({
    rows,
    pinnedRow,
    rowsPerPageOptions,
}: Pick<MuiDataGridProps, 'rows' | 'pinnedRow' | 'rowsPerPageOptions'>): UseRowsReturnType {
    return {
        rows: pinnedRow ? [pinnedRow, ...(rows ?? [])] : rows,
        rowsPerPageOptions: rowsPerPageOptions ?? pinnedRow ? [10, 25, 50, 99] : [10, 25, 50, 100],
    };
}
