import {MarketingCode} from '@models/generated/graphql';

import {gridModuleActions} from '../../features/module-shared/actions';
import {createSubscriberRealtimeActions} from '../../features/realtime-updates/actions';

import {domain} from './types';

export default {
    ...gridModuleActions(domain),
    ...createSubscriberRealtimeActions<MarketingCode>(domain),
};
