type SportsbookTransactionOption = {
    matchId: number;
    sportId: number;
    matchName?: string;
    matchNameEn?: string;
    kickOffTime?: number;
    tournamentId: number;
    tournamentName?: string;
    tournamentNameEn?: string;
    homeScore?: number;
    awayScore?: number;
    marketType?: number;
    sabaMarketId?: string;
    odds?: number;
    betOption?: string;
    settleTime?: number;
    settleScore?: string;
    settleResult?: number;
};

type SportsbookTransactionBet = {
    betM?: number;
    betN?: number;
    betCount?: number;
    betAmount?: number;
};

export class SportsbookTransactionRecord {
    id: number;
    ticketId: number;
    betAmount?: number;
    betCoupon?: number;
    ticketStatus?: number;
    sabaTicketId: number;
    compUserId: string;
    betType?: number;
    currency?: string;
    betTime?: number;
    ip?: string;
    settleAmount?: number;
    winloseCoupon?: number;
    settleResult?: number;
    settleTime?: number;
    options?: SportsbookTransactionOption[];
    bets?: SportsbookTransactionBet[];
    created_at?: number;
    updated_at?: number;
}

export type SportsbookTransactionPage = {
    items: SportsbookTransactionRecord[];
    total: number;
};

export enum TicketStatus {
    Unknown = 'Unknown',
    Awaiting = 'Awaiting',
    Rejected = 'Rejected',
    Accepted = 'Accepted',
    Voided = 'Voided',
    Settled = 'Settled',
    Canceled = 'Canceled',
}

export type SportsbookTransactionSortKeys = 'settle_amount' | 'bet_amount' | 'bet_time';
