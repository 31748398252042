import React from 'react';
import {PropsWithChildren} from 'react';

import {ITracingService} from './ITracingService';

type TraceContextProps = {
    tracingService: ITracingService;
};

export const TraceContext = React.createContext<TraceContextProps>({tracingService: null});

type TraceProvideProps = PropsWithChildren<TraceContextProps>;

export const TraceProvider: React.FC<TraceProvideProps> = ({children, tracingService}: TraceProvideProps) => {
    return <TraceContext.Provider value={{tracingService}}>{children}</TraceContext.Provider>;
};
