import React, {useCallback, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import '@glideapps/glide-data-grid/dist/index.css';
import {
    type Rectangle,
    CustomRenderer,
    DataEditor,
    DrawHeaderCallback,
    GridCell,
    GridCellKind,
    GridMouseEventArgs,
    Item,
    Theme,
} from '@glideapps/glide-data-grid';
import {useMediaQuery, useTheme} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {InfoAlert} from '@components/alerts/InfoAlert';
import StyledPagination from '@components/StyledPagination';
import {CustomTheme} from '@style';

import {IModuleGridItem, SortDirection} from '../types';

import {renderHeaderCell} from './cells';
import {GlideGridTooltip} from './GlideGridTooltip';
import {GlideCellData, GlideGridColumn, rowHeight, SortField} from './types';

export const useClasses = makeStyles()(() => {
    return {
        glideGrid: {
            flex: 1,

            '& .gdg-wmyidgi': {
                height: '100%',
            },
        },
        glideGridEmptyMessage: {
            marginRight: 0,
            marginLeft: 0,
        },
    };
});

type GlideFreezeColumns = {
    mobile?: number;
    desktop?: number;
};

export type GlideGridProps<TModel extends IModuleGridItem> = {
    rows: TModel[];
    columns: GlideGridColumn[];
    freezeColumns?: GlideFreezeColumns;
    emptyMessage?: MessageDescriptor;
    pinnedRow?: TModel;

    sortModel?: SortField[];
    page?: number;
    pageSize?: number;
    totalRows?: number;

    onSortChange?: (sortModel: SortField[]) => void;
    onPageChange?: (page: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
};

type TooltipProps = {
    bounds: Rectangle;
    value: React.ReactNode;
};

export function GlideGrid({
    rows,
    columns,
    sortModel,
    page,
    pageSize,
    totalRows,
    freezeColumns,
    pinnedRow,
    emptyMessage,
    onPageSizeChange,
    onPageChange,
    onSortChange,
}: GlideGridProps<IModuleGridItem>) {
    const muiTheme = useTheme<CustomTheme>();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
    const {classes} = useClasses();

    const finalRows = pinnedRow ? [pinnedRow, ...rows] : rows;
    const getCellContent = useCallback(
        (cell: Item): GridCell => {
            const [col, row] = cell;
            return columns[col].cell.getCellContent(finalRows[row], !!pinnedRow && row === 0);
        },
        [JSON.stringify(finalRows), columns?.map(c => c.id)?.join()]
    );

    const drawHeader: DrawHeaderCallback = React.useCallback(
        (args, draw) => {
            const {ctx, rect, columnIndex, theme} = args;
            renderHeaderCell({ctx, rect, column: columns[columnIndex], glideTheme: theme, muiTheme, sortModel});
            draw();
        },
        [sortModel[0]?.field, sortModel[0]?.sort, columns?.map(c => c.id)?.join()]
    );

    const handleRowThemeOverride = useCallback(
        (row: number): Partial<Theme> | undefined => {
            let result: Partial<Theme> | undefined = undefined;
            if (pinnedRow && row === 1) {
                result = {
                    horizontalBorderColor: muiTheme.custom.palette.content.borderDark,
                };
            }
            return result;
        },
        [!pinnedRow]
    );

    const [tooltipContent, setTooltipContent] = useState<TooltipProps>(null);

    function handlePageChange(page: number) {
        onPageChange(page + 1);
    }

    function handleSortChange(colIndex: number) {
        const clickedColumn = columns[colIndex];
        if (clickedColumn?.sortable) {
            const columnId: string = clickedColumn?.id;
            const direction: SortDirection = sortModel?.[0]?.field === columnId && sortModel?.[0]?.sort === 'desc' ? 'asc' : 'desc';
            onSortChange([{field: columnId, sort: direction}]);
        }
    }

    function handleItemHovered(args: GridMouseEventArgs) {
        switch (args?.kind) {
            case 'cell': {
                const [col, row] = args?.location;
                const cell: GridCell = columns[col].cell.getCellContent(finalRows[row]);
                if (cell?.kind === GridCellKind.Custom && cell?.data) {
                    const data = cell.data as GlideCellData;
                    const TooltipContent = data.tooltip;
                    setTooltipContent({
                        value: <TooltipContent value={data.value}></TooltipContent>,
                        bounds: args?.bounds,
                    });
                } else {
                    closeTooltip();
                }
                break;
            }
            case 'header': {
                const columnIndex = args?.location?.[0];
                setTooltipContent({bounds: args?.bounds, value: columns[columnIndex].columnName});
                break;
            }
            default: {
                closeTooltip();
                break;
            }
        }
    }

    function closeTooltip() {
        setTooltipContent(null);
    }

    return rows.length || !emptyMessage ? (
        <>
            <div className={classes.glideGrid}>
                <DataEditor
                    theme={{
                        bgHeader: muiTheme.palette.background.paper,
                        bgHeaderHovered: muiTheme.palette.background.paper,
                        bgHeaderHasFocus: muiTheme.palette.background.paper,
                        cellHorizontalPadding: parseInt(muiTheme.spacing(2)),
                        borderColor: muiTheme.palette.secondary.light,
                    }}
                    customRenderers={columns.map<CustomRenderer>(c => c.cell.getRenderer(muiTheme))}
                    columns={columns}
                    rows={finalRows.length}
                    getCellContent={getCellContent}
                    freezeColumns={isMobile ? freezeColumns?.mobile : freezeColumns?.desktop}
                    minColumnWidth={50}
                    rowHeight={rowHeight}
                    headerHeight={rowHeight}
                    width="100%"
                    onHeaderClicked={handleSortChange}
                    onItemHovered={handleItemHovered}
                    columnSelect="none"
                    getCellsForSelection={true}
                    getRowThemeOverride={handleRowThemeOverride}
                    drawHeader={drawHeader}
                />
                <GlideGridTooltip value={tooltipContent?.value} bounds={tooltipContent?.bounds} />
            </div>
            <StyledPagination
                count={totalRows}
                page={page - 1}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onPageChange={handlePageChange}
                onPageSizeChange={onPageSizeChange}
            />
        </>
    ) : (
        <InfoAlert title={emptyMessage} className={classes.glideGridEmptyMessage} />
    );
}
