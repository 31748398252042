import {ChipType, ChipVariant} from '@components/chip/types';
import {ChipMapping} from '@config/chip/chipConfig';
import {getTransactionTypeChipsMapping} from '@config/chip/transactionTypeChipConfig';
import {TransactionFilterType} from '@models/transaction/types';

import {localizedTransactionFilterType} from 'src/features/app/intl/shared-resources/transactionType';

export const getTransactionFilterTypeChipsMapping = (chipType: ChipType): Record<TransactionFilterType, ChipMapping> => ({
    ...getTransactionTypeChipsMapping(chipType),
    FEES: {
        label: localizedTransactionFilterType.FEES,
        chipType,
        chipVariant: ChipVariant.Default,
    },
});
