import {constructUsing, createMap, forMember, mapFrom, Mapper, mapWith, mapWithArguments} from '@automapper/core';
import moment from 'moment';

import {IMapping} from '@auto-mapper';
import {CommentFormModel, defaultCommentFormModel, NoteType, Workspace} from '@components/comment';
import {Attachment, Entity, EntityInput, Note, NoteInput, Timestamp as GqlTimestamp, TimestampInput} from '@models/generated/graphql';
import {Timestamp} from '@models/shared';
import {NoteQueryFields} from '@redux/entity';
import {momentToTimestampSeconds} from '@utils';

import {NoteActionItem} from '../block-notes-bulk-actions/types';

import {AttachmentViewModel, EntityViewModel, NoteInputViewModel, NoteViewModel, NoteViewModelKeys} from './types';

export type NoteMappingExtraArgs = {
    postedByUid: string;
    entity: EntityViewModel;
    userId?: string;
};

export class NoteMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            Entity,
            EntityViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.type,
                mapFrom(m => m.type)
            ),
            forMember(
                vm => vm.parent,
                mapWith(EntityViewModel, Entity, m => m.parent)
            )
        );
        createMap(
            mapper,
            EntityViewModel,
            Entity,
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.type,
                mapFrom(m => m.type)
            ),
            forMember(
                vm => vm.parent,
                mapWith(Entity, EntityViewModel, m => m.parent)
            )
        );
        createMap(
            mapper,
            Attachment,
            AttachmentViewModel,
            forMember(
                vm => vm.extension,
                mapFrom(m => m.extension)
            ),
            forMember(
                vm => vm.filename,
                mapFrom(m => m.filename)
            ),
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.size,
                mapFrom(m => m.size)
            ),
            forMember(
                vm => vm.type,
                mapFrom(m => m.type)
            ),
            forMember(
                vm => vm.url,
                mapFrom(m => m.url)
            )
        );
        createMap(
            mapper,
            AttachmentViewModel,
            Attachment,
            forMember(
                vm => vm.extension,
                mapFrom(m => m.extension)
            ),
            forMember(
                vm => vm.filename,
                mapFrom(m => m.filename)
            ),
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.size,
                mapFrom(m => m.size)
            ),
            forMember(
                vm => vm.type,
                mapFrom(m => m.type)
            ),
            forMember(
                vm => vm.url,
                mapFrom(m => m.url)
            )
        );
        createMap<CommentFormModel, NoteInput>(
            mapper,
            CommentFormModel,
            NoteInput,
            forMember(
                vm => vm.entity,
                mapWithArguments((m, {entity}: NoteMappingExtraArgs) => {
                    const {parent, ...recordEntity} = entity;

                    function isEntityEqualToParent(entity: EntityInput) {
                        return entity?.id === entity?.parent?.id && entity?.type === entity?.parent?.type;
                    }

                    return m.note_type === NoteType.Global ? parent : isEntityEqualToParent(entity) ? recordEntity : entity;
                })
            ),
            forMember(
                vm => vm.body,
                mapFrom(m => m.body)
            ),
            forMember(
                vm => vm.note_type,
                mapFrom(m => m.note_type)
            ),
            forMember(
                vm => vm.posted_by_uid,
                mapWithArguments((_, {postedByUid}: NoteMappingExtraArgs) => postedByUid)
            ),
            forMember(
                vm => vm.users_tagged,
                mapFrom(m => m.users_tagged)
            ),
            forMember(
                vm => vm.workspace,
                mapFrom(m => m.workspace ?? Workspace.Global)
            ),
            forMember<CommentFormModel, NoteInput, TimestampInput>(
                vm => vm.posted_at,
                mapFrom(_ => ({seconds: momentToTimestampSeconds(moment())}))
            ),
            forMember(
                vm => vm.is_pinned,
                mapFrom(_ => false)
            )
        );
        createMap(
            mapper,
            Note,
            NoteViewModel,
            forMember(
                vm => vm.entity,
                mapWith(EntityViewModel, Entity, m => m.entity)
            ),
            forMember(
                vm => vm.attachments,
                mapWith(AttachmentViewModel, Attachment, m => m.attachments)
            ),
            forMember(
                vm => vm.body,
                mapFrom(m => m.body)
            ),
            forMember(
                vm => vm.id,
                mapFrom(m => m.id)
            ),
            forMember(
                vm => vm.note_type,
                mapFrom(m => m.note_type)
            ),
            forMember(
                vm => vm.posted_by_uid,
                mapFrom(m => m.posted_by_uid)
            ),
            forMember(
                vm => vm.users_tagged,
                mapFrom(m => m.users_tagged)
            ),
            forMember(
                vm => vm.workspace,
                mapFrom(m => m.workspace)
            ),
            forMember(
                vm => vm.is_pinned,
                mapFrom(m => m.is_pinned)
            ),
            forMember(
                vm => vm.posted_at,
                mapWith(Timestamp, GqlTimestamp, m => m.posted_at)
            )
        );
        createMap(
            mapper,
            NoteViewModel,
            NoteInput,
            forMember(
                vm => vm.entity,
                mapWith(Entity, EntityViewModel, m => m.entity)
            ),
            forMember(
                vm => vm.attachments,
                mapWith(Attachment, AttachmentViewModel, m => m.attachments)
            ),
            forMember(
                vm => vm.body,
                mapFrom(m => m.body)
            ),
            forMember(
                vm => vm.note_type,
                mapFrom(m => m.note_type)
            ),
            forMember(
                vm => vm.posted_by_uid,
                mapFrom(m => m.posted_by_uid)
            ),
            forMember(
                vm => vm.users_tagged,
                mapFrom(m => m.users_tagged)
            ),
            forMember(
                vm => vm.workspace,
                mapFrom(m => m.workspace)
            ),
            forMember(
                vm => vm.is_pinned,
                mapFrom(m => m.is_pinned)
            ),
            forMember(
                vm => vm.posted_at,
                mapWith(TimestampInput, Timestamp, m => m.posted_at)
            )
        );
        createMap<NoteViewModelKeys[], NoteQueryFields[]>(
            mapper,
            nameof<NoteViewModelKeys>(),
            nameof<NoteQueryFields>(),
            constructUsing((m, _) => {
                const mapper: Record<NoteViewModelKeys, NoteQueryFields[]> = {
                    id: ['id'],
                    body: ['body'],
                    note_type: ['note_type'],
                    'posted_at.seconds': ['posted_at.seconds'],
                    posted_by_uid: ['posted_by_uid'],
                    users_tagged: ['users_tagged'],
                    workspace: ['workspace'],
                    is_pinned: ['is_pinned'],
                    'entity.id': ['entity.id'],
                    'entity.type': ['entity.type'],
                    'entity.parent.id': ['entity.parent.id'],
                    'entity.parent.type': ['entity.parent.type'],
                    'attachments.extension': ['attachments.extension'],
                    'attachments.filename': ['attachments.filename'],
                    'attachments.id': ['attachments.id'],
                    'attachments.type': ['attachments.type'],
                    'attachments.url': ['attachments.url'],
                    'attachments.size': ['attachments.size'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
        createMap<CommentFormModel, NoteInputViewModel>(
            mapper,
            CommentFormModel,
            NoteInputViewModel,
            forMember(
                vm => vm.body,
                mapFrom(m => m.body)
            ),
            forMember(
                vm => vm.files,
                mapFrom(m => m.attachmentsFinal)
            ),
            forMember(
                vm => vm.entity,
                mapWithArguments((m, {entity}: NoteMappingExtraArgs) => {
                    const {parent, ...recordEntity} = entity;

                    function isEntityEqualToParent(entity: EntityInput) {
                        return entity?.id === entity?.parent?.id && entity?.type === entity?.parent?.type;
                    }

                    return m.note_type === NoteType.Global ? parent : isEntityEqualToParent(entity) ? recordEntity : entity;
                })
            ),
            forMember(
                vm => vm.note_type,
                mapFrom(m => m.note_type)
            ),
            forMember(
                vm => vm.users_tagged,
                mapFrom(m => m.users_tagged)
            ),
            forMember(
                vm => vm.workspace,
                mapFrom(m => m.workspace ?? Workspace.Global)
            ),
            forMember(
                vm => vm.posted_by_uid,
                mapWithArguments((_, {postedByUid}: NoteMappingExtraArgs) => postedByUid)
            ),
            forMember(
                vm => vm.uid,
                mapWithArguments((_, {userId}: NoteMappingExtraArgs) => userId)
            ),
            forMember(
                vm => vm.posted_at,
                mapFrom((_): Timestamp => ({seconds: momentToTimestampSeconds(moment())}))
            ),
            forMember(
                vm => vm.is_pinned,
                mapFrom(_ => false)
            )
        );
        createMap<NoteInputViewModel, CommentFormModel>(
            mapper,
            NoteInputViewModel,
            CommentFormModel,
            forMember(
                vm => vm.body,
                mapFrom(m => m.body ?? defaultCommentFormModel.body)
            ),
            forMember(
                vm => vm.note_type,
                mapFrom(m => m.note_type ?? defaultCommentFormModel.note_type)
            ),
            forMember(
                vm => vm.users_tagged,
                mapFrom(m => m.users_tagged ?? defaultCommentFormModel.users_tagged)
            ),
            forMember(
                vm => vm.workspace,
                mapFrom(m => m.workspace ?? defaultCommentFormModel.workspace)
            ),
            forMember(
                vm => vm.attachments,
                mapFrom(_ => null)
            ),
            forMember(
                vm => vm.attachmentsFinal,
                mapFrom(m => m.files ?? defaultCommentFormModel.fileMeta)
            )
        );
        createMap<NoteInputViewModel, NoteActionItem>(
            mapper,
            NoteInputViewModel,
            NoteActionItem,
            forMember(
                vm => vm.files,
                mapFrom(m => m.files)
            ),
            forMember(
                vm => vm.attachments,
                mapFrom(m => m.attachments)
            ),
            forMember(
                vm => vm.body,
                mapFrom(m => m.body)
            ),
            forMember(
                vm => vm.entity,
                mapFrom(m => m.entity)
            ),
            forMember(
                vm => vm.is_pinned,
                mapFrom(m => m.is_pinned)
            ),
            forMember(
                vm => vm.note_type,
                mapFrom(m => m.note_type)
            ),
            forMember(
                vm => vm.posted_at,
                mapFrom(m => m.posted_at)
            ),
            forMember(
                vm => vm.posted_by_uid,
                mapFrom(m => m.posted_by_uid)
            ),
            forMember(
                vm => vm.users_tagged,
                mapFrom(m => m.users_tagged)
            ),
            forMember(
                vm => vm.workspace,
                mapFrom(m => m.workspace)
            )
        );
    }
}
