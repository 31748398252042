import React from 'react';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {makeStyles} from 'tss-react/mui';

import {TreeViewFilterItemLabel} from '@components/filter';
import {TreeOption} from '@components/tree-view/TreeViewItemExpandableOption';

const useStyles = makeStyles()(theme => ({
    treeViewFilterItemGroup: {
        paddingLeft: theme.spacing(1.75),
    },
    treeViewFilterItemContent: {
        '&:hover': {
            background: theme.palette.primary.light,
        },
    },
    treeViewFilterSubItemContent: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
}));

export type TreeViewFilterOption = Pick<TreeOption, 'subOptions' | 'value' | 'label'> & {
    subOptions: TreeViewFilterOption[];
    startIcon?: 'checkbox' | React.ReactNode;
};

type TreeViewFilterItemProps = {
    option: TreeViewFilterOption;
    selectedValues: string[];
    onClick: (value: string) => void;
    showTotalCount?: boolean;
    contentClassName?: string;
};

export function TreeViewFilterItem({option, contentClassName, ...props}: TreeViewFilterItemProps) {
    const {selectedValues, onClick, showTotalCount} = props;
    const {classes, cx} = useStyles();

    function handleClick() {
        if (option?.startIcon === 'checkbox') {
            onClick(option?.value);
        }
    }

    return (
        <TreeItem
            nodeId={option?.value}
            label={
                <TreeViewFilterItemLabel
                    option={option}
                    isSelected={selectedValues?.includes(option?.value)}
                    showTotalCount={showTotalCount}
                />
            }
            onClick={handleClick}
            classes={{
                group: classes.treeViewFilterItemGroup,
                content: cx(classes.treeViewFilterItemContent, contentClassName),
            }}
        >
            {option?.subOptions?.map(o => (
                <TreeViewFilterItem option={o} contentClassName={classes.treeViewFilterSubItemContent} {...props} />
            ))}
        </TreeItem>
    );
}
