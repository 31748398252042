import {useAutoMapper} from '@auto-mapper';
import {GameTemplateViewModel, GameTemplateViewModelKeys} from '@models/game-template';
import {GameTemplateViewModelMappingArgs} from '@models/game-template/types';
import {EntityType, GameTemplate, GameTemplateFields, GameTemplateFilterKeys} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

import {Sorting} from 'src/common/types';
import {useJurisdictionConfig} from '../app/config/hooks';

export function useGameTemplates({
    viewType,
    fields,
    displayName,
    cleanDelay = 0,
    realtimeMode,
    triggers,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    validateFilter,
    syncWithUrl = false,
}: UseListViewEntityProps<GameTemplateFilterKeys, GameTemplateViewModelKeys>): UseListViewEntityResult<
    GameTemplateViewModel,
    GameTemplateViewModelKeys
> {
    const mapper = useAutoMapper();
    const {currency} = useJurisdictionConfig();

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<GameTemplate, GameTemplateFilterKeys, GameTemplateFields>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.GameTemplate,
            fields: mapFields(fields),
        },
        realtime: realtimeMode ? {entity: EntityType.GameTemplate, mode: realtimeMode, triggers} : null,
        defaultFilters,
        defaultSorting: mapSorting(defaultSorting),
        defaultPaging,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const templates = items
        ?.map(i => mapper.map(i, GameTemplate, GameTemplateViewModel, {extraArgs: () => ({currency} as GameTemplateViewModelMappingArgs)}))
        ?.sort((a, b) => Number(b.id) - Number(a.id));

    return {
        items: templates,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange: handleVmSortChange,
        handleFilterChange,
    };

    function mapFields(fields: GameTemplateViewModelKeys[]): GameTemplateFields[] {
        return mapper.map(fields, nameof<GameTemplateViewModelKeys>(), nameof<GameTemplateFields>());
    }

    function mapField(field: GameTemplateViewModelKeys): GameTemplateFields {
        return mapFields([field])[0];
    }

    function mapSorting(sorting: Sorting<GameTemplateViewModelKeys>[]): Sorting<GameTemplateFields>[] {
        return sorting?.map(s => ({...s, field: mapField(s.field)}));
    }

    function handleVmSortChange(sorting: Sorting<GameTemplateViewModelKeys>[]) {
        handleSortChange(mapSorting(sorting));
    }
}
