import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {Icon, IconColor} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {TransactionType} from '@models/generated/graphql';
import {CustomTheme} from '@style';

import {transactionsBulkActions} from '../actions';

const localized = defineMessages({
    downloadTemplateActionTitle: {
        id: 'downloadTemplateActionTitle',
        defaultMessage: 'Excel Example',
    },
    downloadTemplateActionDescription: {
        id: 'downloadTemplateActionDescription',
        defaultMessage: 'You can download the attached example and use them as a starting point for your own file.',
    },
    downloadTemplateActionButtonLabel: {
        id: 'downloadTemplateActionButtonLabel',
        defaultMessage: 'Download',
    },
});

const useClasses = makeStyles()((theme: CustomTheme) => ({
    downloadTemplateActionContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        padding: theme.spacing(2.5),
        background: theme.palette.secondary.light,
        borderRadius: theme.shape.borderRadius * 5,
    },
    downloadTemplateActionTextBox: {
        color: theme.palette.secondary.main,
    },
    downloadTemplateActionButtonBox: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
    },
    downloadTemplateActionTitle: {
        fontWeight: 500,
        paddingBottom: theme.spacing(1.25),
    },
    downloadTemplateActionTitleIcon: {
        marginRight: theme.spacing(1.25),
        padding: theme.spacing(0.625),
        borderRadius: theme.shape.borderRadius * 1.25,
        backgroundColor: theme.custom.palette.status.lime.main,
    },
    downloadTemplateActionButton: {
        fontWeight: 500,
    },
}));

type BulkTransactionDownloadTemplateButtonProps = {
    type: TransactionType;
    title: MessageDescriptor;
};

function BulkTransactionDownloadTemplateButton({type, title}: BulkTransactionDownloadTemplateButtonProps) {
    const dispatch = useDispatch();
    const {classes} = useClasses();

    const onClick = () => {
        dispatch(transactionsBulkActions.downloadManualTransactionTemplate({fileType: 'xlsx', transactionType: type}));
    };

    return (
        <Box className={classes.downloadTemplateActionContainer}>
            <Box>
                <Box className={classes.downloadTemplateActionTitle}>
                    <Icon
                        icon={CustomIcon.CheckList}
                        color={IconColor.PrimaryContrastText}
                        className={classes.downloadTemplateActionTitleIcon}
                    />
                    <LocalizedText label={localized.downloadTemplateActionTitle} />
                    <span> </span>
                    <LocalizedText label={title} />
                </Box>
                <Box className={classes.downloadTemplateActionTextBox}>
                    <LocalizedText label={localized.downloadTemplateActionDescription} />
                </Box>
            </Box>
            <Box className={classes.downloadTemplateActionButtonBox}>
                <OutlinedButton onClick={onClick} className={classes.downloadTemplateActionButton}>
                    <LocalizedText label={localized.downloadTemplateActionButtonLabel} />
                </OutlinedButton>
            </Box>
        </Box>
    );
}

export default BulkTransactionDownloadTemplateButton;
