import React, {FC, memo} from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import SquaredChipList from '@components/chip/SquaredChipList';
import {withEmptyCheck} from '@components/label';
import {SecurityCase} from '@models/generated/graphql';

export type SecurityCasesCellProps = {
    value: SecurityCase[];
};

export const SecurityCasesList: FC<SecurityCasesCellProps> = memo(({value}) => {
    return <SquaredChipList list={value?.map(i => ({key: i?.type, value: i?.case_id}))} label={value[0]?.case_id?.split('_')[0]} />;
});

export const SecurityCasesCell: FC<SecurityCasesCellProps> = withEmptyCheck(SecurityCasesList);

export const renderSecurityCasesCell = (params: GridCellParams) => {
    const cases = params.value as SecurityCase[];
    return <SecurityCasesCell value={cases}></SecurityCasesCell>;
};
