import {defineMessages} from 'react-intl';

export const localizedReferralSettingsExpirationPeriod = defineMessages({
    numberOfDays: {
        id: 'ReferralSettingsExpirationPeriod_numberOfDays',
        defaultMessage: 'Number of days',
    },
    periodForRevenueShare: {
        id: 'ReferralSettingsExpirationPeriod_periodForRevenueShare',
        defaultMessage: 'Period for Revenue share',
    },
    daysFormat: {
        id: 'ReferralSettingsExpirationPeriod_daysFormat',
        defaultMessage: '{value} days',
    },
});
