import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useCircularProgressWithLabelStyles = makeStyles()((theme: CustomTheme) => ({
    circularProgressWithLabelContainer: {
        position: 'relative',
        display: 'inline-flex',
    },
    circularProgressWithLabelLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(2),
    },
    circularProgressWithLabelRoot: {
        position: 'relative',
    },
    circularProgressWithLabelBottom: {
        color: theme.palette.secondary.light,
    },
    circularProgressWithLabelTop: {
        color: theme.palette.primary.main,
        position: 'absolute',
        left: 0,
    },
    circularProgressWithLabelCircle: {
        strokeLinecap: 'round',
    },
}));
