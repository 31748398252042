import {DateRange} from 'react-day-picker';
import moment from 'moment-timezone';

import {DateRangeFilterValue} from '@components/filter';
import {FilterProps} from '@components/filter/types';
import {endDateNow, getNHoursAgoAsSeconds, isCurrentDate, momentToTimestampSeconds, timestampSecondsToMoment} from '@utils';
import {getLocalDateWithoutTimezone, getUTCDateWithLocalTimezone} from '@utils/date';

type UseDateRangeFilterResult = Pick<FilterProps<DateRange>, 'value'> & {
    handleChange: (value: DateRange) => void;
    availableRangeForSelection: {from?: Date; to?: Date};
};

//TODO: The code with converting to local date should be moved from DateRangeFilter to DateRangePicker
export function useDateRangeFilter(
    {value, onChange}: FilterProps<DateRangeFilterValue>,
    availableRangeForSelection?: {from?: Date; to?: Date}
): UseDateRangeFilterResult {
    const dateRangeVal: DateRange = {
        from: convertSecondsToInternalFormat(value?.from),
        to: convertSecondsToInternalFormat(value?.to),
    };

    const availableRangeForSelectionLocal = availableRangeForSelection
        ? {
              from: convertSecondsToInternalFormat(momentToTimestampSeconds(moment(availableRangeForSelection?.from))),
              to: convertSecondsToInternalFormat(momentToTimestampSeconds(moment(availableRangeForSelection?.to))),
          }
        : null;

    function handleChange(newValue: DateRange) {
        const result: DateRangeFilterValue = {
            from: convertInternalFormatToSeconds(newValue?.from, 'start'),
            to: newValue?.to && isCurrentDate(newValue.to) ? endDateNow : convertInternalFormatToSeconds(newValue?.to, 'end'),
        };

        onChange(result);
    }

    /**
     * Components for date filters work with local dates. We need to create UTC date with the same year, month and date numbers as local to send in request
     */
    function convertInternalFormatToSeconds(dateLocal: Date, dayBoundary: 'start' | 'end'): number {
        let res: number = null;
        let value = dateLocal ? moment.utc(getLocalDateWithoutTimezone(moment(dateLocal))) : null;

        if (value) {
            value = dayBoundary === 'start' ? value.startOf('day') : value.endOf('day');
            res = momentToTimestampSeconds(value);
        }

        return res;
    }

    /**
     * Components for date filters work with local dates. As we need to show UTC time, we need to create local date with the same year, month and date numbers as UTC
     */
    function convertSecondsToInternalFormat(seconds: number | typeof endDateNow): Date {
        let result = null;
        if (seconds) {
            const value = timestampSecondsToMoment(seconds === endDateNow ? getNHoursAgoAsSeconds(0) : seconds);
            const date = moment.utc(value);
            result = getUTCDateWithLocalTimezone(date);
        }
        return result;
    }

    return {value: dateRangeVal, handleChange, availableRangeForSelection: availableRangeForSelectionLocal};
}
