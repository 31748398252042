import {merge, Observable, of, throwError} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import {catchError, filter, takeUntil} from 'rxjs/operators';
import {isActionOf, PayloadAction, RootState} from 'typesafe-actions';

import {ServiceContainer} from '../app/inversify/serviceContainer';
import {protectEpics} from '../features/app/error-handling/epics';
import {getErrorByFailureAction, successMessages} from '../features/app/intl/shared-resources/serverResponse';
import {showErrorAction, showMessageAction} from '../features/message-snack-bar/actions';
import {IFailureResponse} from '../features/module-shared/types';

import {cancelRequests} from './actions';
import {map, mergeMap, switchMap} from './otel';
import {AsyncAction, RootEpic} from './redux';

//TODO: [BO-2695] Move files to src/common/redux
/**
 * @deprecated
 * <p>Should be removed. Use{@link BaseEpicsBuilder}</p>
 */
export const requestEpic = <
    TRequestType extends string,
    TRequestPayload,
    TSuccessType extends string,
    TSuccessPayload,
    TFailureType extends string,
    TFailurePayload
>(
    asyncAction: AsyncAction<TRequestType, TRequestPayload, TSuccessType, TSuccessPayload, TFailureType, TFailurePayload>,
    requestCallback: (payload: TRequestPayload, state: RootState, dependencies: ServiceContainer) => Observable<unknown>
) => {
    const epic: RootEpic = (action$, state$, dependencies) =>
        action$.pipe(
            filter(isActionOf(asyncAction.request)),
            switchMap(action => {
                return requestCallback((action as PayloadAction<TRequestType, TRequestPayload>).payload, state$.value, dependencies).pipe(
                    map(res => asyncAction.success(res as TSuccessPayload)),
                    takeUntil(action$.pipe(filter(isActionOf(cancelRequests)))),
                    catchError((err: AjaxError) => merge(of(asyncAction.failure(err.response as TFailurePayload)), throwError(err)))
                );
            })
        );

    return epic;
};

const requestWithConfirmEpicCreator =
    () =>
    <TRequestType extends string, TRequestPayload, TSuccessType extends string, TSuccessPayload, TFailureType extends string>(
        asyncAction: AsyncAction<TRequestType, TRequestPayload, TSuccessType, TSuccessPayload, TFailureType, IFailureResponse>,
        requestCallback: (payload: TRequestPayload, state: RootState, dependencies: ServiceContainer) => Observable<unknown>
    ) => {
        const moduleActionEpic: RootEpic = requestEpic(asyncAction, requestCallback);

        const moduleActionSuccessEpic: RootEpic = action$ =>
            action$.pipe(
                filter(isActionOf(asyncAction.success)),
                mergeMap(() => {
                    return of(showMessageAction({message: successMessages.operationSuccessfullyCompleted}));
                })
            );

        const moduleActionFailedEpic: RootEpic = action$ =>
            action$.pipe(
                filter(isActionOf(asyncAction.failure)),
                mergeMap(action => {
                    return of(
                        showErrorAction({
                            message: getErrorByFailureAction(action.type, action.payload?.code),
                            values: action.payload?.values,
                        })
                    );
                })
            );

        return protectEpics(moduleActionEpic, moduleActionSuccessEpic, moduleActionFailedEpic);
    };

export const requestWithConfirmEpic = requestWithConfirmEpicCreator();
