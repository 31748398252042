import {defineMessages} from 'react-intl';

import {PlayerBonusStatus} from '@models/generated/graphql';

export const localizedPlayerBonusStatus = defineMessages({
    [PlayerBonusStatus.Active]: {
        id: 'bonusStatus_Active',
        defaultMessage: 'Active',
    },
    [PlayerBonusStatus.Complete]: {
        id: 'bonusStatus_Complete',
        defaultMessage: 'Complete',
    },
    [PlayerBonusStatus.Expired]: {
        id: 'bonusStatus_Expired',
        defaultMessage: 'Expired',
    },
    [PlayerBonusStatus.Pending]: {
        id: 'bonusStatus_Pending',
        defaultMessage: 'Pending',
    },
});
