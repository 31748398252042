import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {GridValueFormatterParams} from '@mui/x-data-grid';

import LocalizedText from '@components/i18n/LocalizedText';

import {emptyCellCharacter, IModuleGridItem} from '../types';

const defaultValueGetter = (value: string | MessageDescriptor) => {
    return value && (typeof value !== 'string' || (value as string).length) ? value : emptyCellCharacter;
};

const defaultValueFormatter = <T extends IModuleGridItem>(value: T, formatter: (item: T) => string | MessageDescriptor) => {
    const formattedValue = value ? formatter(value) : null;
    return defaultValueGetter(formattedValue);
};

export const gridCellDefaultFormatter =
    <T extends IModuleGridItem>(fieldFormatter: (item: T) => string | MessageDescriptor) =>
    (params: GridValueFormatterParams) => {
        const formattedValue = defaultValueFormatter<T>(params.api.getRow(params.id) as T, fieldFormatter);
        return <LocalizedText label={formattedValue} />;
    };
