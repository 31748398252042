import React from 'react';
import {StyledComponentProps} from '@mui/material/styles';

import {EditableAutocompleteProps, withEditableAutocomplete} from '@components/editable/EditableAutocomplete';
import {Filter} from '@redux/entity';
import {CountriesApiFilterKeys} from '@services/rest-api/countriesApiService';

import {CountryAutocompleteBase} from './CountryAutocompleteBase';

type CountryOfBirthAutocompleteProps = EditableAutocompleteProps<Filter<CountriesApiFilterKeys>> & StyledComponentProps;

const CountryOfBirthAutocomplete = (props: CountryOfBirthAutocompleteProps) => {
    return <CountryAutocompleteBase {...props} viewType="CountryOfBirthAutocomplete" />;
};

export const EditableCountryOfBirthAutocomplete = withEditableAutocomplete(CountryOfBirthAutocomplete);
