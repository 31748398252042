import {createAsyncAction} from 'typesafe-actions';

import {DownloadRequestActionPayload, DownloadSuccessActionPayload} from '@file/types';
import {AgentPlayerReportingViewModelKeys} from '@models/agent-player-reporting';
import {AgentPlayerReportingQueryFields} from '@redux/entity';

import {Message} from '../message-snack-bar/types';

import {AgentReportType} from './types';

export type AgentReportingDownloadRequestPayload = DownloadRequestActionPayload<
    AgentPlayerReportingViewModelKeys,
    AgentPlayerReportingQueryFields
> & {
    agentReportType: AgentReportType;
};

export const domain = 'block-agent-reporting-actions';

export const agentReportingActions = {
    download: createAsyncAction(`${domain}/downloadRequest`, `${domain}/downloadSuccess`, `${domain}/downloadFailed`)<
        AgentReportingDownloadRequestPayload,
        DownloadSuccessActionPayload,
        Message
    >(),
};
