import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';

import Breadcrumb from '../../module-shared/components/BreadCrumb';

const localized = defineMessages({
    bulkBonusEngineLabel: {
        id: 'bulkBonusEngineLabel',
        defaultMessage: 'Bonus Engine',
    },
    bulkBonusManualTriggerLabel: {
        id: 'bulkBonusManualTriggerLabel',
        defaultMessage: 'Manual Trigger',
    },
});

export const BulkBonusModalTitleBreadcrumb = () => {
    return (
        <Box>
            <Breadcrumb
                breadcrumbs={[
                    {
                        url: null,
                        title: localized.bulkBonusEngineLabel,
                        clickable: false,
                    },
                    {
                        url: null,
                        title: localized.bulkBonusManualTriggerLabel,
                        clickable: false,
                    },
                ]}
                highlightCurrent={false}
            />
        </Box>
    );
};
