import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {Icon, IconProps, useIconColorClasses} from '@components/icons/Icon';
import {CustomTheme} from '@style';

import {LinkProps, withLink, withReduxLink} from './LinkHOC';

export const useStyles = makeStyles()((theme: CustomTheme) => ({
    iconLinkContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        height: theme.custom.defaultChipHeight,
        background: theme.palette.background.paper,
        border: `1px solid ${theme.custom.palette.content.border}`,
        boxShadow: theme.custom.defaultChipBoxShadow,
        borderRadius: theme.shape.borderRadius * 4,
        gap: theme.spacing(1),
        textDecoration: 'none',
        color: theme.custom.palette.status.lightGrey.main,
        lineHeight: `${theme.custom.defaultChipHeight}px`,
        boxSizing: 'content-box',
        fontSize: theme.typography.body1.fontSize,
        justifyContent: 'center',

        '& > *': {
            borderRight: `1px solid ${theme.custom.palette.content.border}`,
            display: 'flex',
            gap: theme.spacing(1),
            paddingRight: theme.spacing(1),
            alignItems: 'center',
        },

        '& > *:last-child': {
            borderRight: 'none',
            paddingRight: 0,
        },
    },
    linkIconChip: {
        '& .icon-linked:last-child': {
            display: 'none',
        },
    },
    linkIconCircle: {
        '& .icon-linked:last-child': {
            display: 'none',
        },
        borderRadius: theme.shape.borderRadius * 6,
        height: '40px',
        width: '40px',
    },
    linkIconChipWithLinkIcon: {},
}));

type LinkStyle = 'circle' | 'chip' | 'chipWithLinkIcon';

type IconLinkInternalProps = LinkProps &
    Omit<IconProps, 'title'> & {
        linkStyle: LinkStyle;
    };

function IconLinkInternal({linkStyle, ...props}: IconLinkInternalProps) {
    const {classes} = useStyles();
    const {title, icon, color} = props;
    const iconColorClass = useIconColorClasses()[color];
    const {formatMessage} = useIntl();
    const formattedTitle = title ? (typeof title === 'string' ? title : formatMessage(title as MessageDescriptor)) : '';
    const linkIconStyleClass = getLinkStyle();
    const hasLinkIcon = linkStyle === 'chipWithLinkIcon';

    function getLinkStyle() {
        const styles: Record<LinkStyle, string> = {
            circle: classes.linkIconCircle,
            chip: classes.linkIconChip,
            chipWithLinkIcon: classes.linkIconChipWithLinkIcon,
        };

        return styles[linkStyle];
    }

    return (
        <div className={`${classes.iconLinkContainer} ${linkIconStyleClass}`}>
            <span>
                <Icon className={icon} {...props} title={formattedTitle}></Icon>
                {formattedTitle ? <span className={iconColorClass ?? ''}>{formattedTitle}</span> : null}
            </span>
            {hasLinkIcon ? <Icon icon="icon-linked" /> : null}
        </div>
    );
}

export const LinkButton = withLink(IconLinkInternal, false);

export const ReduxLinkButton = withReduxLink(LinkButton);
