import {routerMiddleware} from '@vs-centaurea/connected-react-router';
import {createBrowserHistory, History} from 'history';
import {Container} from 'inversify';
import {applyMiddleware, compose, createStore, Store} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import {RootAction, RootState} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {loadState} from '@storage/localStorage';

import type {DynamicConfig} from '../configuration';

import {ServiceContainer} from './inversify/serviceContainer';
import {initEpics} from './rootEpic';
import {createRootReducer} from './rootReducer';

export function initStore(container: Container, config: DynamicConfig): {store: Store<RootState, RootAction>; history: History<unknown>} {
    const history = createBrowserHistory();

    const {rootEpic} = initEpics(container);
    const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, ServiceContainer>({
        dependencies: container.get<ServiceContainer>(ServiceTypes.ServiceContainer),
    });
    const middlewares = [epicMiddleware, routerMiddleware(history)];
    const composeEnhancers = (config.env === 'DEV' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    const enhancer = composeEnhancers(applyMiddleware(...middlewares));

    const initialState = loadState();
    const rootReducer = createRootReducer(history);

    const store = createStore(rootReducer, initialState, enhancer);

    epicMiddleware.run((action$, store, dependencies) => rootEpic(action$, store, dependencies));

    return {store, history};
}
