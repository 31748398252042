import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {ServiceTypes} from '@inversify';
import {QueryGetAgentPathStatisticsArgs} from '@models/generated/graphql';
import {AgentPathStatisticsQueryFields} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {GqlRequestBuilder} from '@services/entity/GqlRequestBuilder';
import {ApolloClientProxy} from '@services/gql-api';

@injectable()
export class AgentPathStatisticsService extends EntityBaseGqlService<QueryGetAgentPathStatisticsArgs, AgentPathStatisticsQueryFields> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new AgentPathStatisticsRequestBuilder());
    }
}

export class AgentPathStatisticsRequestBuilder extends GqlRequestBuilder<QueryGetAgentPathStatisticsArgs, AgentPathStatisticsQueryFields> {
    protected buildFilter(): object {
        return {};
    }

    protected buildQuery(fields: AgentPathStatisticsQueryFields[]): DocumentNode {
        return gql`
            query GetAgentPathStatistics($sort: Sorting, $start: Int, $end: Int) {
                getAgentPathStatistics(sort: $sort, start: $start, end: $end) {
                    items {
                        agent_uid @include(if: ${this.hasField(fields, 'agent_uid')})
                        path_to_subagent @include(if: ${this.hasField(fields, 'path_to_subagent')})
                        registered_player_number @include(if: ${this.hasField(fields, 'registered_player_number')})
                        active_player_number @include(if: ${this.hasField(fields, 'active_player_number')})
                    }
                    total_count
                }
            }
        `;
    }
}
