import {UserProfile} from '@models/generated/graphql';

import {columnConfigurationActions, contentModuleActions} from '../../module-shared/actions';
import {createSubscriberRealtimeActions} from '../../realtime-updates/actions';

import {domain} from './types';

export default {
    ...contentModuleActions(domain),
    ...columnConfigurationActions(domain),
    ...createSubscriberRealtimeActions<UserProfile>(domain),
};
