import React from 'react';
import {Typography} from '@mui/material';

import InfoMessage from '@components/alerts/InfoMessage';
import LocalizedText from '@components/i18n/LocalizedText';

import {accessControlLocalized} from './localized';

export function NoAccessInfoMessage() {
    return (
        <InfoMessage dataTestId="notAccessibleMessage">
            <Typography variant="body1">
                <LocalizedText label={accessControlLocalized.notAccessibleMessage} />
            </Typography>
        </InfoMessage>
    );
}
