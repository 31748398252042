import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf, RootState} from 'typesafe-actions';

import {IModuleGridItem} from '@components/data-grid';
import {map, mergeMap, switchMap} from '@otel';
import {RootEpic} from '@redux';
import {IGridService} from '@services/deprecated';
import {IItemDetailsReadService} from '@services/deprecated';
import {getPagingString, getSelectedItemId, getSortString} from '@utils';

import {requestEpic} from 'src/common/epics';
import {ServiceContainer} from '../../app/inversify/serviceContainer';
import {protectEpics} from '../app/error-handling/epics';
import {locationSelector} from '../app/routing/selectors';
import {handleErrorResponseAction} from '../message-snack-bar/actions';
import {realtimeNotificationActions} from '../realtime-updates/actions';
import {getFilterActions} from '../shared/filter/actions';
import {getFilterEpics} from '../shared/filter/epics';
import {getFilterUtils} from '../shared/filter/utils';

import {contentModuleActions, gridModuleActions, initActions} from './actions';
import {IModuleItem} from './types';

export const getInitEpic = (domain: string, filterSelector: (state: RootState) => string, updateSearchUrl: boolean): RootEpic => {
    const actions = initActions(domain);
    const {selectInitialSearchFilterString} = getFilterUtils(updateSearchUrl, filterSelector);
    const {getUpdateSearchFilterActions} = getFilterActions(domain, updateSearchUrl);

    const initEpic: RootEpic = (action$, state$) =>
        action$.pipe(
            filter(isActionOf(actions.init)),
            mergeMap(() => {
                const filter = selectInitialSearchFilterString(state$.value);
                const location = locationSelector(state$.value);
                const resultActions = [
                    realtimeNotificationActions.clearNotification(),
                    ...getUpdateSearchFilterActions(location, filter, true),
                ];
                return of(...resultActions);
            })
        );

    return initEpic;
};

export const createGridEpics = <TItem extends IModuleItem, TGridItem extends IModuleGridItem>(
    domain: string,
    serviceResolver: (container: ServiceContainer) => IGridService<TItem, TGridItem>,
    filterStateSelector: (state: RootState) => string,
    columnsSelector?: (state: RootState) => string[],
    updateSearchUrl = true,
    defaultFilterString?: string,
    isClientFiltering = false,
    itemsSelectedByDefault = false
) => {
    const actions = gridModuleActions(domain);

    const searchFilterUtils = getFilterUtils(updateSearchUrl, filterStateSelector);
    const {getUpdateSearchFilterActions} = getFilterActions(domain, updateSearchUrl);

    const contentLoadEpic: RootEpic = requestEpic(actions.contentLoad, (_, state, container) => {
        const searchFilter = searchFilterUtils.selectSearchFilter(state, defaultFilterString);
        const columns = columnsSelector ? columnsSelector(state) : null;
        return serviceResolver(container).getItemsPage(searchFilter, columns);
    });

    //TODO: fixed generic typing (any)
    const itemsPagingChangedEpic: RootEpic = (action$, state$) =>
        action$.pipe(
            filter(isActionOf(actions.itemsPagingChanged)),
            mergeMap(res => {
                const pagingQuery = getPagingString(searchFilterUtils.selectSearchFilterString(state$.value), res.payload);
                const location = locationSelector(state$.value);
                return of(...getUpdateSearchFilterActions(location, pagingQuery));
            })
        );

    //TODO: fixed generic typing (any)
    const itemsSortedEpic: RootEpic = (action$, state$) =>
        action$.pipe(
            filter(isActionOf(actions.itemsSorted)),
            mergeMap(res => {
                const sortQuery = getSortString(searchFilterUtils.selectSearchFilterString(state$.value), res.payload);
                const location = locationSelector(state$.value);
                return of(...getUpdateSearchFilterActions(location, sortQuery));
            })
        );

    const clearSelectedItemsEpic: RootEpic = (action$, _state$) =>
        action$.pipe(
            filter(isActionOf([actions.itemsPagingChanged, actions.itemsSorted, actions.itemsFilter])),
            mergeMap(() => of(actions.itemsSelected([])))
        );

    const contentChangedEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(actions.contentUpdateRequired)),
            switchMap(() => {
                return of(actions.contentLoad.request());
            })
        );

    const contentLoadRequestEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(actions.contentLoad.request)),
            switchMap(() => of(realtimeNotificationActions.clearNotificationsForDomain(domain)))
        );

    const itemLoadEpic: RootEpic = requestEpic(actions.itemLoad, (payload, _, container) => {
        const service = serviceResolver(container);
        return service.getItem(payload);
    });

    const requestFailureEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf([actions.itemLoad.failure, actions.contentLoad.failure])),
            map(action => handleErrorResponseAction(action.payload))
        );

    const selectLoadedItemsEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(actions.contentLoad.success)),
            switchMap(action => of(actions.itemsSelected(action.payload.items)))
        );

    const epics: RootEpic[] = [
        getInitEpic(domain, filterStateSelector, updateSearchUrl),
        getFilterEpics(domain, filterStateSelector, updateSearchUrl, isClientFiltering),
        contentLoadEpic,
        itemLoadEpic,
        itemsPagingChangedEpic,
        itemsSortedEpic,
        contentChangedEpic,
        contentLoadRequestEpic,
        requestFailureEpic,
    ];

    if (itemsSelectedByDefault) {
        epics.push(selectLoadedItemsEpic);
    } else {
        epics.push(clearSelectedItemsEpic);
    }

    return protectEpics(...epics);
};

export const createSingleItemReadEpics = <TItem extends IModuleItem>(
    domain: string,
    serviceResolver: (container: ServiceContainer) => IItemDetailsReadService<TItem>,
    filterSelector: (state: RootState) => string,
    columnsSelector?: (state: RootState) => string[],
    updateSearchUrl = true,
    isClientFiltering = false
) => {
    const actions = contentModuleActions(domain);

    const contentLoadEpic: RootEpic = requestEpic(actions.contentLoad, (_, state, container) => {
        const service = serviceResolver(container);
        const searchFilter = filterSelector(state);
        const columns = columnsSelector ? columnsSelector(state) : null;
        return service.getItem(getSelectedItemId(searchFilter), columns);
    });

    const contentChangedEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(actions.contentUpdateRequired)),
            switchMap(() => of(actions.contentLoad.request()))
        );

    const contentLoadRequestEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf(actions.contentLoad.request)),
            switchMap(() => of(realtimeNotificationActions.clearNotificationsForDomain(domain)))
        );

    const requestFailureEpic: RootEpic = action$ =>
        action$.pipe(
            filter(isActionOf([actions.contentLoad.failure])),
            map(action => handleErrorResponseAction(action.payload))
        );

    return protectEpics(
        getInitEpic(domain, filterSelector, updateSearchUrl),
        getFilterEpics(domain, filterSelector, updateSearchUrl, isClientFiltering),
        contentLoadEpic,
        requestFailureEpic,
        contentLoadRequestEpic,
        contentChangedEpic
    );
};
