import React, {memo} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

import {emptyCellCharacter} from '../../../features/module-shared/utils';
import StyledTooltip from '../Tooltip';

import {Chip} from './Chips';
import {ChipType, ChipVariant} from './types';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    flexArrayContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        alignItems: 'center',
        '& :nth-child(1)': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        '& span:nth-child(n+2)': {
            '&::before': {
                content: '", "',
            },
        },
    },
    valueContainer: {
        display: 'block',
        marginLeft: theme.spacing(1),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    chipListContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    chipListLabel: {
        lineHeight: theme.typography.body1.lineHeight,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
    },
    chipListTooltip: {
        whiteSpace: 'pre',
    },
    chipListMore: {
        marginLeft: 'auto',
    },
}));

export type ChipKeyValue = {
    key: string;
    value?: string;
};

type SquaredChipListProps = {
    list: ChipKeyValue[];
    hideTooltip?: boolean;
    value?: string | MessageDescriptor;
    label?: string;
};

type ValueChipProps = {
    label: React.ReactNode;
    value?: string | number;
    variant: ChipVariant;
    type: ChipType;
    children: React.ReactNode;
};

export const ValueChip = ({label, value, variant, type, children}: ValueChipProps) => {
    const {classes} = useClasses();

    return (
        <Box className={classes.flexArrayContainer} data-testid="chipListLabel">
            <Chip fillWidth={false} chipType={type} chipVariant={variant} label={label} value={value} />
            <Box display="flex" className={classes.valueContainer}>
                {children}
            </Box>
        </Box>
    );
};

const SquaredChipList = ({list, hideTooltip, label}: SquaredChipListProps & React.HTMLAttributes<HTMLElement>) => {
    const {classes} = useClasses();

    const totalItems = list?.length;
    const chipKey = totalItems ? <LocalizedText label={label ?? list[0].key} /> : emptyCellCharacter;
    const chipValue = totalItems ? <LocalizedText label={list[0].value} /> : emptyCellCharacter;
    const getTooltipValue = (val: string) => {
        return val ? `: ${val}` : '';
    };
    const tooltip = list?.map(l => `${l.key} ${getTooltipValue(l.value)}`).join('\r\n');

    return (
        <StyledTooltip
            element={
                <Box className={classes.chipListContainer}>
                    {!totalItems ? (
                        <Box className={classes.chipListLabel} data-testid="chipListLabel">
                            {emptyCellCharacter}
                        </Box>
                    ) : (
                        <>
                            <ValueChip type={ChipType.Prefix} variant={ChipVariant.Default} label={chipKey} value={totalItems}>
                                {chipValue}
                            </ValueChip>
                        </>
                    )}
                </Box>
            }
            title={<Box className={classes.chipListTooltip}>{tooltip}</Box>}
            hideTooltip={hideTooltip}
        />
    );
};

export default memo(SquaredChipList);
