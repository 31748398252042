import {defineMessages} from 'react-intl';

export const localizedMarketingBonusCodeCreateAction = defineMessages({
    marketingCode: {
        id: 'MBCAA_marketingCode',
        defaultMessage: 'Marketing Code',
    },
    type: {
        id: 'MBCAA_type',
        defaultMessage: 'Type',
    },
    btag: {
        id: 'MBCAA_btag',
        defaultMessage: 'Btag',
    },
    referrerId: {
        id: 'MarketingBonusCodeCreateActionForm_ReferrerId',
        defaultMessage: 'Referrer ID',
    },
    placeholderOptional: {
        id: 'MBCAA_placeholderOptional',
        defaultMessage: 'Optional',
    },
    placeholderEnterNameCode: {
        id: 'MBCAA_placeholderEnterNameCode',
        defaultMessage: 'Enter name code',
    },
    addNew: {
        id: 'MBCAA_addNew',
        defaultMessage: 'Add New',
    },
    addMarketingBonusCode: {
        id: 'MBCAA_addMarketingBonusCode',
        defaultMessage: 'Add Marketing Bonus Code',
    },
    cancel: {
        id: 'MBCAA_cancel',
        defaultMessage: 'Cancel',
    },
    confirm: {
        id: 'MBCAA_confirm',
        defaultMessage: 'Confirm',
    },
    invalidBtagFormatMessage: {
        id: 'MBCAA_invalidBtagFormatMessage',
        defaultMessage: 'Invalid Btag',
    },
});
