import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {map, mergeMap} from '@otel';
import {entityActions, EntityType, GameTemplateNormalized, UpdateItemPayload} from '@redux/entity';
import {realtimeActions, RealtimeMessageTrigger} from '@redux/realtime';
import {GameTemplateService} from '@services';
import {
    CloseTemplateRoomsResponsePayload,
    CreateGameTemplatePayload,
    DisableGameTemplatePayload,
    DisableTemplateServiceResponsePayload,
    EditGameTemplatePayload,
    EditTemplateServiceResponsePayload,
    EnableGameTemplatePayload,
} from '@services/rest-api/plo5GameManagementApiService';
import {ServerResponseStatus} from '@services/types';

import {GameActionsEpicsBuilder} from '../block-game-management/epics';
import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';
import {Message} from '../message-snack-bar/types';

import {gameTemplateActions} from './actions';

export const localized = defineMessages({
    createTemplateFailure: {
        id: 'GameTemplateActions_createFailureMessage',
        defaultMessage: 'Failed to create game template. Try again later',
    },
    createTemplateFailureWithErrorInfo: {
        id: 'GameTemplateActions_createTemplateFailureWithErrorInfo',
        defaultMessage: 'Failed to create game template: {message}{br}Trace id: {traceId}',
    },
    createTemplateSuccess: {
        id: 'GameTemplateActions_createSuccessMessage',
        defaultMessage: 'Game template was successfully created',
    },
    editTemplateFailure: {
        id: 'GameTemplateActions_editFailureMessage',
        defaultMessage: 'Failed to modify game template. Try again later',
    },
    editTemplateFailureWithErrorInfo: {
        id: 'GameTemplateActions_editTemplateFailureWithErrorInfo',
        defaultMessage: 'Failed to modify game template: {message}{br}Trace id: {traceId}',
    },
    editTemplateCloseRoomsWithoutIdsFailure: {
        id: 'GameTemplateActions_editTemplateCloseRoomsFailureMessage',
        defaultMessage: 'Game template was modified. But failed to close game tables. Try again later',
    },
    editTemplateCloseRoomsFailure: {
        id: 'GameTemplateActions_editTemplateCloseRoomsFailureMessage',
        defaultMessage: 'Game template was modified. But failed to close game tables. Failed tables ids: {failedRoomIds}. Try again later',
    },
    editTemplateCloseRoomsFailureWithErrorInfo: {
        id: 'GameTemplateActions_editTemplateCloseRoomsFailureMessageWithErrorInfo',
        defaultMessage:
            'Game template was modified. But failed to close game tables: {message}.{br}Failed tables ids: {failedRoomIds}.{br}Trace id: {traceId}',
    },
    editTemplateSuccess: {
        id: 'GameTemplateActions_editSuccessMessage',
        defaultMessage: 'Game template was successfully modified',
    },
    enableSuccessMessage: {
        id: 'GameTemplateActionsEpicsBuilder_enableSuccessMessage',
        defaultMessage: 'Template successfully enable',
    },
    enableFailureMessage: {
        id: 'GameTemplateActionsEpicsBuilder_enableFailureMessage',
        defaultMessage: 'Template enable was failed',
    },
    enableFailureMessageWithErrorInfo: {
        id: 'GameTemplateActions_enableFailureMessageWithErrorInfo',
        defaultMessage: 'Template enable was failed: {message}{br}Trace id: {traceId}',
    },
    disableSuccessMessage: {
        id: 'GameTemplateActionsEpicsBuilder_disableSuccessMessage',
        defaultMessage: 'Template successfully disabled',
    },
    disableFailureMessage: {
        id: 'GameTemplateActionsEpicsBuilder_disableFailureMessage',
        defaultMessage: 'Template disable was failed',
    },
    disableFailureMessageWithErrorInfo: {
        id: 'GameTemplateActions_disableFailureMessageWithErrorInfo',
        defaultMessage: 'Template disable was failed: {message}{br}Trace id: {traceId}',
    },
    disableFailureNotAllRoomsClosedMessage: {
        id: 'GameTemplateActionsEpicsBuilder_disableFailureNotAllRoomsClosedMessage',
        defaultMessage: 'Template was disabled. But rooms with ids: {failedRoomIds} - were not closed',
    },
    disableFailureNotClosedRoomsMessage: {
        id: 'GameTemplateActionsEpicsBuilder_disableFailureNotClosedRoomsMessage',
        defaultMessage: 'Template was disabled. But close rooms was failed',
    },
});

@injectable()
export class GameTemplateActionsEpicsBuilder extends GameActionsEpicsBuilder {
    private _gameTemplateService: GameTemplateService;

    constructor(@inject(ServiceTypes.GameTemplateService) gameTemplateService: GameTemplateService) {
        super();
        this._gameTemplateService = gameTemplateService;
    }

    protected buildEpicList(): Epic[] {
        return [
            this.buildCreateTemplateEpic(),
            this.buildCreateTemplateSuccessEpic(),
            this.buildCreateTemplateFailureEpic(),
            this.buildEditTemplateEpic(),
            this.buildEditTemplateSuccessEpic(),
            this.buildEditTemplateFailureEpic(),
            this.buildGameTemplateEnableRequestEpic(),
            this.buildGameTemplateEnableSuccessEpic(),
            this.buildGameTemplateEnableFailureEpic(),
            this.buildGameTemplateDisableRequestEpic(),
            this.buildGameTemplateDisableSuccessEpic(),
            this.buildGameTemplateDisableFailureEpic(),
        ];
    }

    private buildCreateTemplateEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(gameTemplateActions.createTemplate.request)),
                mergeMap(action => {
                    const {template, gameType} = action.payload;
                    const payload: CreateGameTemplatePayload = {
                        template,
                        gameType,
                    };

                    return this._gameTemplateService.createGameTemplate(payload).pipe(
                        mergeMap(response => {
                            if (response?.status !== ServerResponseStatus.Success) {
                                return of(gameTemplateActions.createTemplate.failure(response));
                            }

                            return of(
                                realtimeActions.event({
                                    entity: EntityType.GameTemplate,
                                    items: [],
                                    trigger: {type: RealtimeMessageTrigger.Update},
                                }),
                                gameTemplateActions.createTemplate.success()
                            );
                        }),
                        catchError(() => of(gameTemplateActions.createTemplate.failure(null)))
                    );
                })
            );
    }

    private buildEditTemplateEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(gameTemplateActions.editTemplate.request)),
                mergeMap(action => {
                    const {id, template, gameType} = action.payload;
                    const payload: EditGameTemplatePayload = {
                        id,
                        template,
                        gameType,
                    };

                    return this._gameTemplateService.editGameTemplate(payload).pipe(
                        mergeMap(response => {
                            if (response?.status !== ServerResponseStatus.Success) {
                                return of(gameTemplateActions.editTemplate.failure(response));
                            }

                            return of(
                                realtimeActions.event({
                                    entity: EntityType.GameTemplate,
                                    items: [],
                                    trigger: {type: RealtimeMessageTrigger.Update},
                                }),
                                gameTemplateActions.editTemplate.success(response)
                            );
                        }),
                        catchError(() => of(gameTemplateActions.editTemplate.failure(null)))
                    );
                })
            );
    }

    private buildCreateTemplateSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(gameTemplateActions.createTemplate.success)),
                map(() => showMessageAction({message: localized.createTemplateSuccess}))
            );
    }

    private buildEditTemplateSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(gameTemplateActions.editTemplate.success)),
                mergeMap(action => {
                    const response: EditTemplateServiceResponsePayload = action?.payload;
                    const failedRoomIds: number[] = response?.responsePayload?.failedRoomIds;

                    let messageAction: PayloadAction<string, Message>;
                    if (!response?.errors?.length) {
                        messageAction = failedRoomIds?.length
                            ? showErrorAction({
                                  message: localized.editTemplateCloseRoomsFailure,
                                  values: {failedRoomIds: failedRoomIds?.join(', ')},
                              })
                            : showMessageAction({message: localized.editTemplateSuccess});
                    } else {
                        messageAction = showErrorAction({message: localized.editTemplateCloseRoomsWithoutIdsFailure});
                    }

                    return of(messageAction);
                })
            );
    }

    private buildCreateTemplateFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameTemplateActions.createTemplate.failure)),
                map(action =>
                    this.setAsyncActionErrorMessage(
                        gameTemplateActions?.createTemplate?.request(null)?.type,
                        action?.payload,
                        localized.createTemplateFailure,
                        localized.createTemplateFailureWithErrorInfo
                    )
                )
            );
    }

    private buildEditTemplateFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameTemplateActions.editTemplate.failure)),
                map(action => {
                    const failedRoomIds = action.payload?.responsePayload?.failedRoomIds?.join(', ');
                    const actionType = gameTemplateActions?.editTemplate?.request(null)?.type;
                    return failedRoomIds
                        ? this.setAsyncActionErrorMessage(
                              actionType,
                              action?.payload,
                              localized.editTemplateCloseRoomsFailure,
                              localized.editTemplateCloseRoomsFailureWithErrorInfo,
                              {failedRoomIds}
                          )
                        : this.setAsyncActionErrorMessage(
                              actionType,
                              action?.payload,
                              localized.editTemplateFailure,
                              localized.editTemplateFailureWithErrorInfo
                          );
                })
            );
    }

    private buildGameTemplateEnableRequestEpic(): Epic {
        return this.buildRequestEpic<EnableGameTemplatePayload, EnableGameTemplatePayload, null>(
            gameTemplateActions.enableTemplate,
            payload => this._gameTemplateService.enableGameTemplates(payload)
        );
    }

    private buildGameTemplateEnableSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameTemplateActions.enableTemplate.success)),
                mergeMap(action => {
                    const updatedActions: PayloadAction<string, UpdateItemPayload>[] = this.getUpdateItemActions(
                        action?.payload?.requestPayload?.enableTemplateIds,
                        {
                            is_enabled: true,
                        }
                    );

                    return of(
                        ...[
                            ...updatedActions,
                            realtimeActions.event({
                                entity: EntityType.GameTemplate,
                                items: [],
                                trigger: {type: RealtimeMessageTrigger.Update},
                            }),
                            showMessageAction({message: localized.enableSuccessMessage}),
                        ]
                    );
                })
            );
    }

    private buildGameTemplateEnableFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameTemplateActions.enableTemplate.failure)),
                map(action =>
                    this.showOperationFailureMessage(
                        action?.payload,
                        localized.enableFailureMessage,
                        localized.enableFailureMessageWithErrorInfo
                    )
                )
            );
    }

    private buildGameTemplateDisableRequestEpic(): Epic {
        return this.buildRequestEpic<DisableGameTemplatePayload, DisableGameTemplatePayload, CloseTemplateRoomsResponsePayload>(
            gameTemplateActions.disableTemplate,
            payload => this._gameTemplateService.disableGameTemplates(payload)
        );
    }

    private buildGameTemplateDisableSuccessEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameTemplateActions.disableTemplate.success)),
                mergeMap(action => {
                    const response: DisableTemplateServiceResponsePayload = action?.payload;
                    const failedRoomIds: number[] = response?.responsePayload?.failedRoomIds;

                    const updatedItem: Partial<GameTemplateNormalized> = {is_enabled: false};
                    let message: PayloadAction<string, Message>;
                    if (!response?.errors?.length) {
                        updatedItem.tables = failedRoomIds;

                        message = failedRoomIds?.length
                            ? showErrorAction({
                                  message: localized.disableFailureNotAllRoomsClosedMessage,
                                  values: {failedRoomIds: failedRoomIds?.join(', ')},
                              })
                            : showMessageAction({message: localized.disableSuccessMessage});
                    } else {
                        message = showErrorAction({message: localized.disableFailureNotClosedRoomsMessage});
                    }

                    const updatedActions: PayloadAction<string, UpdateItemPayload>[] = this.getUpdateItemActions(
                        response?.requestPayload?.disableTemplateIds,
                        updatedItem
                    );

                    return of(...[...updatedActions, message]);
                })
            );
    }

    private getUpdateItemActions(ids: number[] | string[], updatedItem: Partial<GameTemplateNormalized>) {
        return ids?.map(id =>
            entityActions.updateItem({
                type: EntityType.GameTemplate,
                id: id.toString(),
                updatedItem,
            })
        );
    }

    private buildGameTemplateDisableFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameTemplateActions.disableTemplate.failure)),
                map(action =>
                    this.showOperationFailureMessage(
                        action?.payload,
                        localized.disableFailureMessage,
                        localized.disableFailureMessageWithErrorInfo
                    )
                )
            );
    }
}
