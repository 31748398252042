import {BaseFilterKeys} from '@redux/entity';

type BoUserRole = {
    id: string;
    name: string;
};

export class BoUser {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    lockoutEnd?: Date;
    roles?: BoUserRole[];
    playerId?: string;
}

export type BoUserPage = {
    items: BoUser[];
    total: number;
};

export const boUserServerTextFilterKeys = ['em_fn_ln', 'email', 'firstName', 'lastName', 'playerId'] as const;
export type BoUserServerTextFilterKeys = (typeof boUserServerTextFilterKeys)[number];

export type BoUserServerFilterKeys = BaseFilterKeys | BoUserServerTextFilterKeys;

export type BoUserFields = keyof BoUser;
