import {createAsyncAction} from 'typesafe-actions';

import {DownloadRequestActionPayload, DownloadSuccessActionPayload} from '@file/types';
import {AgentLevelReportQueryFields} from '@redux/entity';

import {Message} from '../message-snack-bar/types';

import {AgentLevelReportViewModelKeys} from './types';

export const domain = 'block-agent-level-report-actions';

export const agentLevelReportActions = {
    download: createAsyncAction(`${domain}/downloadRequest`, `${domain}/downloadSuccess`, `${domain}/downloadFailed`)<
        DownloadRequestActionPayload<AgentLevelReportViewModelKeys, AgentLevelReportQueryFields>,
        DownloadSuccessActionPayload,
        Message
    >(),
};
