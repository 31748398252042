import {useEffect, useState} from 'react';
import {DeepPartial, Mode, UnpackNestedValue, useForm, UseFormReturn} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import equal from 'fast-deep-equal';

import {AsyncAction, AsyncActionMeta} from '@redux/async-action';

import {useAsyncActionState} from '../async-action/hooks';
import {AsyncActionStateExtended} from '../async-action/types';

export type UseReduxFormReturn<T> = UseFormReturn<T> & {
    state: AsyncActionStateExtended;
    submit: (newModel: T) => void;
    cancel: () => void;
    formattedErrorMessage: string;
};

type UseReduxFormProps<TFormModel extends object, TSubmitModel extends object> = {
    initialModel: UnpackNestedValue<DeepPartial<TFormModel>>;
    asyncAction: AsyncAction<string, TSubmitModel, string, unknown, string, unknown>;
    map: (formModel: TFormModel) => TSubmitModel;
    shouldHaveActionId?: boolean;
    shouldResetOnError?: boolean;
    onSuccess?: () => void;
    mode?: Mode;
};

export const useReduxForm = <TFormModel extends object, TSubmitModel extends object>({
    initialModel,
    asyncAction,
    map,
    onSuccess,
    shouldHaveActionId = false,
    shouldResetOnError = true,
    mode,
}: UseReduxFormProps<TFormModel, TSubmitModel>): UseReduxFormReturn<TFormModel> => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const state = useAsyncActionState(asyncAction, shouldHaveActionId);
    const form = useForm<TFormModel>({
        defaultValues: initialModel,
        mode,
    });

    useEffect(() => {
        form.reset(initialModel);
    }, [JSON.stringify(initialModel)]);

    useEffect(() => {
        if (state.isError && shouldResetOnError) {
            form.reset(initialModel);
        }
    }, [state.isError]);

    useEffect(() => {
        return () => {
            state.resetAsyncActionState();
        };
    }, []);

    useEffect(() => {
        if (!state.isProgress && !state.isError && form.formState.isSubmitted) {
            if (onSuccess) {
                onSuccess();
            }
            state.resetAsyncActionState();
        }
    }, [state.isProgress]);

    const submit = (newModel: TFormModel) => {
        const initialValue = map(initialModel as TFormModel);
        const newValue = map(newModel);
        if (!equal(initialValue, newValue)) {
            const meta: AsyncActionMeta = {
                actionId: shouldHaveActionId ? state.actionId : undefined,
            };
            dispatch(asyncAction.request(newValue, meta));
        } else {
            cancel();
        }
    };

    const cancel = () => {
        form.reset(initialModel);
        state.resetAsyncActionState();
    };

    const formattedErrorMessage = state.errorMessage ? formatMessage(state.errorMessage, state.errorMessageValues) : null;

    return {...form, state, submit, cancel, formattedErrorMessage};
};

export type FormMode = 'Read' | 'Edit' | 'EditMultiStep' | 'InProgress';

export const useReduxFormRequestMode = <TSubmitModel extends object>(
    asyncAction: AsyncAction<string, TSubmitModel, string, unknown, string, unknown>,
    actionId?: string,
    isMultiStep?: boolean
) => {
    const [requestMode, setRequestMode] = useState<FormMode>('Read');
    const state = useAsyncActionState(asyncAction, actionId !== undefined, actionId);

    useEffect(() => {
        state.isProgress ? setRequestMode('InProgress') : setRequestMode('Read');
    }, [state.isProgress]);

    useEffect(() => {
        if (state.errorMessage) {
            setRequestMode(isMultiStep ? 'EditMultiStep' : 'Edit');
        }
    }, [state.errorMessage]);

    useEffect(() => {
        return () => {
            state.resetAsyncActionState();
        };
    }, []);

    return {requestMode, resetRequestMode: state.resetAsyncActionState};
};
