import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useMarketingBonusCodeDeleteActionClasses = makeStyles()((theme: CustomTheme) => ({
    marketingBonusCodeDeleteActionContainer: {
        display: 'flex',
        columnGap: theme.spacing(1),
        marginLeft: 'auto',
    },
    marketingBonusCodeDeleteButton: {
        display: 'flex',
        alignItems: 'center',
        height: theme.custom.denseButtonHeight,
        borderRadius: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    marketingBonusCodeDeleteButtonIcon: {
        fontSize: `${theme.typography.h6.fontSize} !important`,
    },
    marketingBonusCodeDeleteButtonChip: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(0.75),
        height: theme.spacing(2.5),
        backgroundColor: theme.palette.primary.contrastText,
        ...(theme.typography.subtitle2 as CSSObject),
    },
}));
