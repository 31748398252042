import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    dropzoneContentFailedTooLargeMessage: {
        id: 'FDZC_tooLargeMessage',
        defaultMessage: 'File {filename} is too large',
    },
    dropzoneContentFailedInvalidTypeMessage: {
        id: 'FDZC_invalidTypeMessage',
        defaultMessage: "File {filename} isn't in the correct format",
    },
    dropzoneContentFailedTooSmallMessage: {
        id: 'FDZC_tooSmallMessage',
        defaultMessage: 'File {filename} is too small',
    },
    dropzoneContentFailedTooManyFilesMessage: {
        id: 'FDZC_tooSmallMessage',
        defaultMessage: 'You can drop only one file here',
    },
});
