import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {getColumnConfigurationReducer, getContentModuleStateReducer, getContentSingleItemReducer} from '../../module-shared/reducers';

import {domain} from './types';

const userRegionRelatedInfoReducer = combineReducers({
    domain: createReducer(domain),
    data: combineReducers(getContentSingleItemReducer(domain)),
    state: combineReducers({
        ...getColumnConfigurationReducer(domain),
        ...getContentModuleStateReducer(domain),
    }),
});

export default userRegionRelatedInfoReducer;
