import React, {useMemo} from 'react';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    iFrameContainer: {
        height: '100%',
        border: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.mixins.toolbar.minHeight,
        },
    },
}));

type IFrameProps = {
    src: string;
    className?: string;
};

export function IFrame({src, className}: IFrameProps) {
    const {classes, cx} = useStyles();

    const frame = useMemo(() => {
        return <iframe src={src} className={cx(classes.iFrameContainer, className)}></iframe>;
    }, [src]);

    return frame;
}
