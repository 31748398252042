import {Container} from 'inversify';

export abstract class ContainerFacade {
    protected _container: Container;

    constructor(container: Container) {
        this._container = container;
    }

    abstract bindAll(): void;
}
