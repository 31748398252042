import React, {FC, useContext, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {FilterProps} from '@components/filter/types';

import {DrawerContext} from './MultiStepFilterDrawer';
import {MultiStepFilterHeader} from './MultiStepFilterHeader';

const useClasses = makeStyles()(() => ({
    multiStepFilterHocComponentContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    multiStepFilterHocHiddenStep: {
        display: 'none',
    },
}));

export type MultiStepFilterStepProps<TValue, TDefaultState> = Omit<FilterProps<TValue>, 'width' | 'mode'> & {
    defaultState: TDefaultState;
    isStepActive?: boolean;
    onStateChange: (state: TDefaultState) => void;
    onApply: () => void;
    onNextStep: (index: number) => void;
};

export function withMultiStepFilterHoc<TValue, TDefaultState>(
    WrappedComponents: FC<MultiStepFilterStepProps<TValue, TDefaultState>>[],
    filterLabel: string | MessageDescriptor
) {
    function StepContainer({
        value,
        defaultState,
        onChange,
    }: Omit<MultiStepFilterStepProps<TValue, TDefaultState>, 'onNextStep' | 'onApply' | 'onStateChange'>) {
        const [stepHistory, setStepHistory] = useState<Number[]>([0]);
        const [currentState, setCurrentState] = useState<TDefaultState>(defaultState);
        const {classes, cx} = useClasses();
        const context = useContext(DrawerContext);
        function handleApplyClick() {
            handlePrevStep();
        }

        function handleNextStep(index: Number) {
            if (stepHistory.length === 1) {
                context.onActivate();
            }
            if (stepHistory[stepHistory.length - 1] !== index) {
                setStepHistory([...stepHistory, index]);
            }
        }

        function handlePrevStep() {
            if (stepHistory.length === 2) {
                context.onDeactivate();
            }
            if (stepHistory.length > 1) {
                setStepHistory([...stepHistory.slice(0, -1)]);
            }
        }

        function handleStateChange(newState: TDefaultState) {
            setCurrentState(newState);
        }

        return (
            <>
                {stepHistory.length > 1 ? (
                    <MultiStepFilterHeader onBack={handlePrevStep} label={filterLabel} onClose={context.onClose} />
                ) : null}
                {WrappedComponents.map((Comp, index) => (
                    <div
                        className={cx(
                            classes.multiStepFilterHocComponentContainer,
                            index !== stepHistory[stepHistory.length - 1] && classes.multiStepFilterHocHiddenStep
                        )}
                    >
                        <Comp
                            isStepActive={index === stepHistory[stepHistory.length - 1]}
                            value={value}
                            onChange={onChange}
                            defaultState={currentState}
                            onStateChange={handleStateChange}
                            onApply={handleApplyClick}
                            onNextStep={handleNextStep}
                        />
                    </div>
                ))}
            </>
        );
    }
    return StepContainer;
}
