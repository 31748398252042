import {IModuleGridItem} from '@components/data-grid';
import {PlayerReferrerSummary} from '@models/generated/graphql';
import {IGQlFilterVariables} from '@services/deprecated/types';

import {ColumnConfiguration} from 'src/common/types';
import {IModuleItem} from '../module-shared/types';

export const domain = 'referrer-summary';

export class PlayerReferrerSummaryItem extends PlayerReferrerSummary implements IModuleItem {
    id: string;
}

export class PlayerReferrerSummaryGridItem extends PlayerReferrerSummary implements IModuleGridItem {
    id: string;
    serverId: string;

    get isNew() {
        return false;
    }
}

export interface IPlayerReferrerSummaryGqlQueryVariables extends IGQlFilterVariables {
    uid: string;
}

export class PlayerReferrerSummaryColumnsConfiguration extends ColumnConfiguration {
    withReferrer: boolean;
    withInviteCode: boolean;
    withRevenueShare: boolean;
    withRevenueGeneratedTotal: boolean;
    withRedeemedTotal: boolean;
    withRedeemPendingTotal: boolean;

    constructor() {
        super();
        this.withReferrer = false;
        this.withInviteCode = false;
        this.withRevenueShare = false;
        this.withRevenueGeneratedTotal = false;
        this.withRedeemedTotal = false;
        this.withRedeemPendingTotal = false;
    }
}
