import {MessageDescriptor} from 'react-intl';

import {PaymentOption} from '@models/generated/graphql';

import {localizedPaymentOption} from '../../app/intl/shared-resources/paymentOption';

export const formatMethodLabel = (method: string): string | MessageDescriptor => {
    const paymentOption = Object.values(PaymentOption).find(o => o === method);
    return method && paymentOption ? localizedPaymentOption[paymentOption] : method;
};
