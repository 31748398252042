import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import KeyValueTable, {KeyValuePair} from '@components/KeyValueTable';
import {Affiliates, PlayerReferral} from '@models/generated/graphql';
import {ViewModel} from '@redux/view';

import {UserReferralInfoForm} from 'src/features/block-player-referral-actions';

const localized = defineMessages({
    title: {
        id: 'ReferrerInfo_tableTitle',
        defaultMessage: 'Referrer Info',
    },
    referrerTypeAndTrackingId: {
        id: 'ReferrerInfo_referrerTypeAndTrackingId',
        defaultMessage: 'Referrer Type and Tracking ID',
    },
});

const useClasses = makeStyles()(() => ({
    referrerInfoFilled: {
        padding: 0,
        boxShadow: 'none',
    },
    referrerInfoTable: {
        gridTemplateColumns: 'minmax(280px, max-content) auto',
    },
    referrerInfoTableRow: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

type UserReferralKeyValueTableProps = {
    userId: string;
    referral: ViewModel<PlayerReferral>;
    affiliate: Affiliates;
    isAgentReferrer: boolean;
};

export const UserReferralKeyValueTable = ({userId, referral, affiliate, isAgentReferrer}: UserReferralKeyValueTableProps) => {
    const {classes} = useClasses();

    const rows: KeyValuePair[] = [
        {
            key: localized.referrerTypeAndTrackingId,
            value: <UserReferralInfoForm userId={userId} referral={referral} affiliate={affiliate} isAgentReferrer={isAgentReferrer} />,
        },
    ];

    return (
        <KeyValueTable
            className={classes.referrerInfoFilled}
            styles={{table: classes.referrerInfoTable, tableRow: classes.referrerInfoTableRow}}
            rows={rows}
            tableLabel={localized.title}
            columnsCount={1}
        />
    );
};
