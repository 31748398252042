import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {Query, QueryGetUserActionHistoryArgs, UserAction} from '@models/generated/graphql';
import {BoUser} from '@redux/entity';
import {ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlSearchFilter} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';

import {Filter, ItemsPage} from 'src/common/types';
import {UserService} from '../../pages/access-management/services';

import {ActionCenterSearchFilter, ActionHistory, ActionHistoryGridItem, IActionCenterGqlQueryVariables, labelMap} from './types';

export class ActionHistoryService extends ReadonlyRealtimeGridServiceBase<ActionHistory, ActionHistoryGridItem, UserAction> {
    private readonly userService: UserService;

    constructor(client: ApolloClientProxy<NormalizedCacheObject>, userService: UserService) {
        super(client);
        this.userService = userService;
    }

    getItems(_filter?: ActionCenterSearchFilter): Observable<ActionHistoryGridItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsPage(filter?: ActionCenterSearchFilter): Observable<ItemsPage<ActionHistoryGridItem>> {
        return this.userService.getItems().pipe(
            mergeMap((users: any) =>
                this._client.executeQuery(this.getItemsPageQuery(), this.getGQLVariables(filter)).pipe(
                    map<Query, ItemsPage<ActionHistoryGridItem>>(res => {
                        return {
                            items: res?.getUserActionHistory?.items.map((item: ActionHistoryGridItem) => {
                                return {
                                    actionAgent: this.getActionAgent(item.action_by_uid, users),
                                    ...item,
                                } as ActionHistoryGridItem;
                            }),
                            total: res?.getUserActionHistory?.total_count ?? 0,
                        };
                    })
                )
            )
        );
    }

    getActionAgent(userId: string, users: any) {
        let agentName = null;

        if (userId in labelMap) {
            agentName = labelMap[userId];
        } else {
            const agent = users?.items.find((u: BoUser) => u.id === userId);
            agentName = agent ? `${agent.firstName} ${agent.lastName}` : userId;
        }

        return agentName;
    }

    getItem(_id: string): Observable<ActionHistory> {
        throw new Error('Method not implemented.');
    }

    protected getGQLVariables(filter: ActionCenterSearchFilter): IActionCenterGqlQueryVariables {
        return {
            ...super.getGQLVariables(filter),
            uid: filter?.filter?.find(f => f.key === nameof<QueryGetUserActionHistoryArgs>(i => i.uid))?.value,
        };
    }

    private getItemsPageQuery(): DocumentNode {
        return gql`
            query GetUserActionHistory($uid: String!, $sort: Sorting, $start: Int, $end: Int) {
                getUserActionHistory(uid: $uid, sort: $sort, end: $end, start: $start) {
                    items {
                        id
                        action_type
                        action_by_uid
                        player_uid
                        trigger_at {
                            seconds
                        }
                        prev_value
                        new_value
                    }
                    total_count
                }
            }
        `;
    }

    protected toGQLSearchFilter(_filters: Filter[]): IGQlSearchFilter {
        return null;
    }
}
