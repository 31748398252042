import {PlayerMessage} from '@models/generated/graphql';

import {protectEpics} from '../../app/error-handling/epics';
import {createGridEpics} from '../../module-shared/epics';
import {PlayerMessageGridItem, PlayerMessageItem} from '../../player-message/types';
import {createGridSubscriberRealtimeEpics} from '../../realtime-updates/epics';
import {SubscriptionType} from '../../realtime-updates/types';

import {localized} from './localize';
import {columnsSelector, filterSelector, itemPageSelector} from './selectors';
import {domain} from './types';

export const userNotificationHistoryEpics = protectEpics(
    createGridEpics<PlayerMessageItem, PlayerMessageGridItem>(domain, c => c.playerMessageService, filterSelector, columnsSelector, false),
    createGridSubscriberRealtimeEpics<PlayerMessage, string>(
        {realtimeKey: domain, domain, name: localized.realtimeUpdateTitle},
        itemPageSelector,
        pm => pm.id,
        [SubscriptionType.Added],
        c => c.playerMessageService,
        filterSelector
    )
);
