import {gql} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Transaction} from '@models/generated/graphql';

import {IRealtimeService} from '../../realtime-updates/services/IRealtimeService';
import {RealtimeSubscriptionService} from '../../realtime-updates/services/RealtimeUpdatesService';

export class UserTransactionRealtimeService extends RealtimeSubscriptionService implements IRealtimeService<Transaction> {
    subscribeToCreate(): Observable<Transaction> {
        return super.createSubscription(this.getItemAddedSubscriptionQuery()).pipe(map(res => res.userTransactionAdded));
    }

    subscribeToUpdate(): Observable<Transaction> {
        return super.createSubscription(this.getItemUpdatedSubscriptionQuery()).pipe(map(res => res.userTransactionUpdated));
    }

    private getItemAddedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnTransactionAdded {
                userTransactionAdded {
                    uid
                    username
                    transaction_id
                    transaction_status
                    currency
                    amount
                    previous_balance
                    current_balance
                    type
                    payment_system_uid
                    payment_system_transaction_id
                    transaction_started_ts {
                        seconds
                    }
                    status_log {
                        status
                        logged_at {
                            seconds
                        }
                    }
                    payment_vendor
                    payment_option
                    payment_method_description
                    payment_method_name
                    created_by_uid
                    reasons {
                        reason_type
                        reason_code
                        reason_text
                        created_at_ts {
                            seconds
                        }
                    }
                }
            }
        `;
    }

    private getItemUpdatedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnTransactionUpdated {
                userTransactionUpdated {
                    uid
                    username
                    transaction_id
                    transaction_status
                    currency
                    amount
                    previous_balance
                    current_balance
                    type
                    payment_system_uid
                    payment_system_transaction_id
                    transaction_started_ts {
                        seconds
                    }
                    status_log {
                        status
                        logged_at {
                            seconds
                        }
                    }
                    payment_vendor
                    payment_option
                    payment_method_description
                    payment_method_name
                    created_by_uid
                    reasons {
                        reason_type
                        reason_code
                        reason_text
                        created_at_ts {
                            seconds
                        }
                    }
                }
            }
        `;
    }
}
