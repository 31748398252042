import React from 'react';
import {type CustomCell, type CustomRenderer, type Rectangle, GridCellKind} from '@glideapps/glide-data-grid';

import {CustomTheme} from '@style';

import {CanvasRenderingContext2DExtended} from '../CanvasRenderingContext2DExtended';
import {GlideCellData, rowHeight, TooltipContentProps} from '../types';

import {GlideCell} from './GlideCell';

export class GlideTextCell<TModel, TValue = string> extends GlideCell<TModel, TValue, string> {
    private readonly _cellKind = 'text';

    constructor(valueGetter: (model: TModel) => TValue) {
        super(valueGetter);
    }

    getRenderer(muiTheme: CustomTheme): CustomRenderer<CustomCell<GlideCellData<string>>> {
        return {
            kind: GridCellKind.Custom,
            isMatch: (cell: CustomCell<GlideCellData<string>>): cell is CustomCell<GlideCellData<string>> =>
                cell.data.kind === this._cellKind,
            needsHover: false,
            draw: (args, cell) => {
                const value = cell.data.value;

                const {ctx, theme, rect} = args;
                const fontSizeProperty = muiTheme.typography.body1.fontSize;
                const fontSize: number = typeof fontSizeProperty === 'string' ? Number.parseInt(fontSizeProperty) : fontSizeProperty;
                ctx.font = `normal ${cell.data.isSummary ? 'bold' : 'normal'} ${fontSize}px ${theme.fontFamily}`;
                ctx.fillStyle = theme.textDark;

                const drawArea: Rectangle = this.getDrawArea(rect, theme);
                const textCtx = new CanvasRenderingContext2DExtended(args.ctx);
                textCtx.fillTextWithEllipsis(value, drawArea.width, drawArea.x, drawArea.y + rowHeight / 2);
            },
        };
    }

    protected getCustomContent(value: string, isSummary: boolean): CustomCell<GlideCellData<string>> {
        return {
            kind: GridCellKind.Custom,
            copyData: value,
            data: {
                kind: this._cellKind,
                value: value,
                isSummary,
                tooltip: value ? TextCellTooltipContent : null,
            },
            allowOverlay: false,
        };
    }

    protected isCellEmpty(value: string): boolean {
        return !value;
    }

    protected formatValue(value: TValue): string {
        return value?.toString();
    }
}

function TextCellTooltipContent({value}: TooltipContentProps<string>) {
    return <div>{value}</div>;
}
