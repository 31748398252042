import {gql} from '@apollo/client';
import {DocumentNode} from 'graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {PlayerMessage} from '@models/generated/graphql';

import {IRealtimeService} from '../../realtime-updates/services/IRealtimeService';
import {RealtimeSubscriptionService} from '../../realtime-updates/services/RealtimeUpdatesService';

export class PlayerMessageRealtimeService extends RealtimeSubscriptionService implements IRealtimeService<PlayerMessage> {
    subscribeToCreate(): Observable<PlayerMessage> {
        return super.createSubscription(this.getItemAddedSubscriptionQuery()).pipe(map(res => res.playerMessageAdded));
    }

    private getItemAddedSubscriptionQuery(): DocumentNode {
        return gql`
            subscription OnPlayerMessageAdded {
                playerMessageAdded {
                    id
                    uid
                    sender
                    sender_id
                    mail_type
                    content
                    subject
                    title
                    signature
                    sent_at {
                        seconds
                    }
                    source
                }
            }
        `;
    }
}
