import React, {useEffect, useRef, useState} from 'react';

import {AutocompleteInfoMessage, AutocompleteOptionType, AutocompletePlaceholder, FormAutocompleteInput} from '@components/autocomplete';
import {VirtualizedExpandableTreeView} from '@components/tree-view/VirtualizedExpandableTreeView';

import {useAutocompleteClasses} from './Autocomplete.styles';
import {useAutocompleteClientFilter, useAutocompleteState} from './hooks';
import {AutocompleteProps} from './types';

export function TreeAutocomplete<TValue>({
    value,
    defaultOption,
    options,
    placeholder,
    emptyPlaceholder,
    subInfoMessage,
    errorMessage,
    onValueChange,
    onFilterChange,
    errorMessageOrientation,
    dataTestId,
    allowEmptyValue,
    mode = 'client',
    listContainerClassName,
}: AutocompleteProps<TValue>) {
    const {classes: styles, cx} = useAutocompleteClasses();
    const autocompleteRef = useRef<HTMLDivElement>(null);
    const [treeOptions, setTreeOptions] = useState([]);

    const flatOptions = getFlatOptions(options);
    const {filter} = useAutocompleteClientFilter();
    const {open, inputValue, autocompleteState, handleInputChange, handleBlur, handleKeyDown, handleClear, handleAutocompleteChange} =
        useAutocompleteState({
            value,
            defaultOption,
            options: flatOptions,
            onValueChange,
            onFilterChange: mode === 'client' ? handleClientFilterChange : onFilterChange,
            allowFreeInputValue: false,
            allowEmptyValue,
            multiple: false,
            isAlwaysOpen: true,
            mode,
        });

    useEffect(() => {
        handleClientFilterChange(inputValue);
    }, [flatOptions?.map(o => o.value)?.join()]);

    function getFlatOptions(options: AutocompleteOptionType[]): AutocompleteOptionType[] {
        return options?.length ? [options, ...options?.map(o => getFlatOptions(o.subOptions))]?.flat() : [];
    }

    function handleClientFilterChange(filterString: string) {
        const selected: AutocompleteOptionType = flatOptions?.find(o => o.value === value);
        setTreeOptions(
            selected?.value && selected?.label && inputValue === selected.label
                ? [{value: selected.value, label: selected.label}]
                : filter(options, filterString)
        );
    }

    return (
        <div className={styles.autocompleteContainer} data-testid={dataTestId} ref={autocompleteRef}>
            <FormAutocompleteInput
                value={inputValue}
                onInputChange={handleInputChange}
                onBlur={handleBlur}
                clear={handleClear}
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
            />
            {treeOptions?.length ? (
                <div className={cx(listContainerClassName, styles.autocompleteTreeViewList, styles.autocompleteViewList)}>
                    <VirtualizedExpandableTreeView
                        options={treeOptions}
                        onItemClick={value => handleAutocompleteChange(null, value)}
                        selectedValue={value as string}
                    />
                </div>
            ) : (
                <AutocompletePlaceholder emptyPlaceholder={emptyPlaceholder} />
            )}

            <AutocompleteInfoMessage
                subInfoMessage={subInfoMessage}
                autocompleteState={autocompleteState}
                hideMessage={open}
                containerRef={autocompleteRef}
                dataTestId="autocompleteTreeErrorMessage"
                message={errorMessage}
                messageOrientation={errorMessageOrientation}
            />
        </div>
    );
}
