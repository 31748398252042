import {globalConfig} from '../../../configuration';

import {CustomAttributesBuilder} from './customAttributesBuilder';
import {CustomAttributes, ErrorCategory, LoggedError} from './types';

export class ErrorHandlingService {
    private readonly _customAttributesBuilder: CustomAttributesBuilder;

    constructor() {
        this._customAttributesBuilder = new CustomAttributesBuilder();
    }

    public logError(error: LoggedError, category: ErrorCategory, func?: Function) {
        const loggedError: LoggedError = error;

        if (!loggedError.isLogged) {
            this.markHandled(error);

            const attrs = this._customAttributesBuilder.getCustomAttr(error, category, func);
            this.logToConsole(error, attrs);
            this.sendToNewRelic(error, attrs);
        }
    }

    public initDefaultErrorHandler() {
        window.newrelic?.setErrorHandler((e: any) => {
            const isLoggingRequired = !e.isLogged;

            if (isLoggingRequired) {
                this.logError(e, ErrorCategory.Unhandled);
            }

            return isLoggingRequired;
        });
    }

    private markHandled(error: Error): LoggedError {
        const handledError: LoggedError = error;
        handledError.isLogged = true;
        return handledError;
    }

    private sendToNewRelic(error: Error, customAttributes: CustomAttributes) {
        window.newrelic?.noticeError(error, customAttributes);
    }

    private logToConsole(error: Error, attrs: CustomAttributes) {
        if (globalConfig?.config?.env === 'DEV') {
            console.error({error, attrs});
        }
    }
}
