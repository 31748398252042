import React from 'react';

import {AccountVerificationDateRangeFilterPrefix, AccountVerificationFilterKeys} from '@redux/entity';

import {ModuleFilterDateRangeObsolete} from '../../shared/filter/components/date-range-picker/FilterDateRangePickerObsolete';

import {AccountVerificationsFilterProps} from './AccountVerificationsFilters';

function AccountVerificationsFilterDate({filter, defaultFilter, onFilterChange}: AccountVerificationsFilterProps) {
    const keys: AccountVerificationFilterKeys[] = ['initiatedAt.from', 'initiatedAt.to'];
    return (
        <ModuleFilterDateRangeObsolete
            onFilterChange={onFilterChange}
            domain={null}
            filter={{
                key: keys,
                options: AccountVerificationDateRangeFilterPrefix,
                defaultValue: defaultFilter,
            }}
            filterString={filter}
        />
    );
}

export default AccountVerificationsFilterDate;
