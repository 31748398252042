import {createAction, createAsyncAction} from 'typesafe-actions';

import {Jurisdiction} from '../../features/app/config/types';
import {contentModuleActions, gridModuleActions} from '../../features/module-shared/actions';
import {IFailureResponse} from '../../features/module-shared/types';

import {domain, LegalDocType, LegalDocumentRead, VersionAcceptanceDocument} from './types';

const initLegalDocsModule = '/initLegalDocsModule';

const loadDocumentAction = '/loadDocument';
const loadDocumentSucceedAction = '/loadDocumentSucceed';
const loadDocumentFailedAction = '/loadDocumentFailed';

export const legalModuleActions = (domain: string) => ({
    ...contentModuleActions(domain),

    initLegalDocsModule: createAction(`${domain}${initLegalDocsModule}`)<LegalDocType, Jurisdiction>(),
});

export const privacyPolicyActions = legalModuleActions(domain.privacyPolicy);
export const termsActions = legalModuleActions(domain.termsAndConditions);
export const userAcceptanceVersionActions = {
    ...gridModuleActions(domain.userAcceptanceVersion),

    loadDocument: createAsyncAction(
        `${domain.userAcceptanceVersion}${loadDocumentAction}`,
        `${domain.userAcceptanceVersion}${loadDocumentSucceedAction}`,
        `${domain.userAcceptanceVersion}${loadDocumentFailedAction}`
    )<VersionAcceptanceDocument, LegalDocumentRead, IFailureResponse>(),
};
