import {IModuleGridItem} from '@components/data-grid';
import {AccountVerification, Platform} from '@models/generated/graphql';
import {isRecent} from '@utils';

import {ColumnConfiguration} from 'src/common/types';
import {IModuleItem} from '../../features/module-shared/types';

export const domain = 'kyc-processing';

export const kycPendingCasesDomain = 'kyc-processing/pending-cases';
export const kycHistoryCasesDomain = 'kyc-processing/history-cases';

export class AccountVerificationColumnsConfiguration extends ColumnConfiguration {
    withUid: boolean;
    withKycCaseId: boolean;
    withUsername: boolean;
    withStatus: boolean;
    withAccountVerificationStatus: boolean;
    withStatusLog: boolean;
    withInitiatedAt: boolean;
    withPlatform: boolean;
    withVipLevel: boolean;
    withDocuments: boolean;
    withType: boolean;
    withEmail: boolean;

    constructor() {
        super();
        this.withUid = false;
        this.withKycCaseId = false;
        this.withUsername = false;
        this.withStatus = false;
        this.withAccountVerificationStatus = false;
        this.withInitiatedAt = false;
        this.withStatusLog = false;
        this.withPlatform = false;
        this.withVipLevel = false;
        this.withDocuments = false;
        this.withType = false;
        this.withEmail = false;
    }
}

export class AccountVerificationItem extends AccountVerification implements IModuleItem {
    country?: string;
}

export class AccountVerificationGridItem extends AccountVerification implements IModuleGridItem {
    serverId: string;
    platform: Platform;
    vipLevel: number;
    country?: string;

    get isNew() {
        return isRecent(this.initiated_at, 1);
    }
}

export const kycWorkspaceFullSearch = 'kycWorkspaceFullSearch';
export const kycWorkspaceHeaderActionContainerId = 'kycProcessingHeaderActions';
