import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {Box, Button} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {CustomTheme} from '@style';

const useSortButtonClasses = makeStyles()((theme: CustomTheme) => ({
    sortButtonIconSizeMedium: {
        '& > *:first-child': {
            fontSize: theme.typography.body2.fontSize,
        },
    },
}));

const localized = defineMessages({
    sortNewFirst: {
        id: 'sortNewFirst',
        defaultMessage: 'New First',
    },
    sortOldFirst: {
        id: 'sortOldFirst',
        defaultMessage: 'Old First',
    },
});

type SortButtonOption = {
    iconClassName: string;
    label: MessageDescriptor;
    direction: SortDirection;
};

export enum SortDirection {
    New,
    Old,
}

type SortButtonProps = {
    direction: SortDirection;
    onSortChanged: (newDirection: SortDirection) => void;
};

export const SortButton = React.memo(({direction, onSortChanged}: SortButtonProps) => {
    const {classes} = useSortButtonClasses();
    const options = [
        {iconClassName: CustomIcon.SortNewestFirst, label: localized.sortNewFirst, direction: SortDirection.New},
        {iconClassName: CustomIcon.SortOldestFirst, label: localized.sortOldFirst, direction: SortDirection.Old},
    ] as SortButtonOption[];
    const selectedOption = options.find(o => o.direction === direction);

    const onClick = () => {
        const newDirection = selectedOption.direction === SortDirection.New ? SortDirection.Old : SortDirection.New;

        onSortChanged(newDirection);
    };

    return (
        <Button
            classes={{iconSizeMedium: classes.sortButtonIconSizeMedium}}
            color="secondary"
            endIcon={<Box component="span" className={selectedOption.iconClassName} />}
            onClick={onClick}
        >
            <LocalizedText label={selectedOption.label} />
        </Button>
    );
});
