import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {IModuleGridItem} from '@components/data-grid';
import {Query, Transaction, TransactionType, UserTransactionFilter} from '@models/generated/graphql';
import {ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlFilterVariables, IGQlSearchFilter} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';
import {ignoreCase, toGQKMultiselectFilter, toGQLDateFilter, toGQLMultipleSorting, toGQLNumberRangeFilter, toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';
import {IModuleItem} from '../module-shared/types';
import {IRealtimeGridReadService} from '../realtime-updates/services/IRealtimeGridReadService';

import {
    defaultTransactionTypesFilterKey,
    TransactionColumnsConfiguration,
    TransactionGridItem,
    TransactionItem,
    withdrawalProcessingFullSearch,
} from './types';

const getTransactionsQuery = (configuration: TransactionColumnsConfiguration) => gql`
    query GetUserTransactions($filter: UserTransactionFilter, $sort: [Sorting], $start: Int, $end: Int) {
        getUsersTransactions(filter: $filter, sort: $sort, end: $end, start: $start) {
            items {
                uid ${configuration.withUid ? '' : '@client'}
                username ${configuration.withUsername ? '' : '@client'}
                transaction_id ${configuration.withTransactionId ? '' : '@client'}
                type ${configuration.withType ? '' : '@client'}
                currency ${configuration.withCurrency ? '' : '@client'}
                previous_balance ${configuration.withPreviousBalance ? '' : '@client'}
                current_balance ${configuration.withCurrentBalance ? '' : '@client'}
                amount ${configuration.withAmount ? '' : '@client'}
                payment_system_uid ${configuration.withPaymentSystemUid ? '' : '@client'}
                payment_system_transaction_id ${configuration.withPaymentSystemTransactionId ? '' : '@client'}
                status_log ${configuration.withStatusLog ? '' : '@client'} {
                    status
                    logged_at {
                        seconds
                    }
                }
                transaction_started_ts ${configuration.withTransactionStartedTs ? '' : '@client'} {
                    seconds
                }
                payment_vendor ${configuration.withPaymentVendor ? '' : '@client'}
                payment_option ${configuration.withPaymentOption ? '' : '@client'}
                payment_method_name ${configuration.withPaymentMethodName ? '' : '@client'}
                payment_method_description ${configuration.withPaymentMethodDescription ? '' : '@client'}
                transaction_status ${configuration.withTransactionStatus ? '' : '@client'}
                reasons ${configuration.withReasons ? '' : '@client'} {
                    id
                    reason_type
                    reason_code
                    reason_text
                    created_at_ts {
                        seconds
                    }
                }
                email ${configuration.withEmail ? '' : '@client'}
            }
            total_count
        }
    }
`;

export class TransactionBaseService<TTransactionItemModel extends IModuleItem, TTransactionGridItemModel extends IModuleGridItem>
    extends ReadonlyRealtimeGridServiceBase<TTransactionItemModel, TTransactionGridItemModel, Transaction>
    implements IRealtimeGridReadService<string>
{
    private readonly types: TransactionType[];

    constructor(client: ApolloClientProxy<NormalizedCacheObject>, types?: TransactionType[]) {
        super(client);
        this.types = types ?? [TransactionType.Deposit, TransactionType.Withdrawal];
    }

    getItemsIds(filter?: SearchFilter): Observable<string[]> {
        return this._client
            .executeQuery(
                this.getItemsPageQuery([nameof<TransactionColumnsConfiguration>(c => c.withTransactionId)]),
                this.getGQLVariables(filter)
            )
            .pipe(
                map<Query, string[]>(res => {
                    return res?.getUsersTransactions?.items.map(i => i.transaction_id) ?? [];
                })
            );
    }

    getItems(_filter?: SearchFilter): Observable<TTransactionGridItemModel[]> {
        throw new Error('Method not implemented.');
    }

    getItemsPage(filter?: SearchFilter, itemFields?: string[]): Observable<ItemsPage<TTransactionGridItemModel>> {
        return this._client.executeQuery(this.getItemsPageQuery(itemFields), this.getGQLVariables(filter)).pipe(
            map<Query, ItemsPage<TTransactionGridItemModel>>(res => {
                return {
                    items:
                        res?.getUsersTransactions?.items.map((i, index) =>
                            this.mapModels({id: `${index}`, serverId: i?.transaction_id} as TTransactionGridItemModel, i)
                        ) ?? [],
                    total: res?.getUsersTransactions?.total_count ?? 0,
                };
            })
        );
    }

    getItem(_id: string): Observable<TTransactionItemModel> {
        throw new Error('Method not implemented.');
    }

    protected getItemsPageQuery(itemFields?: string[]): DocumentNode {
        const configuration = this.getGQLConfiguration(new TransactionColumnsConfiguration(), itemFields);

        return getTransactionsQuery(configuration);
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        const defaultTypes = filters?.find(f => f.key === defaultTransactionTypesFilterKey)?.value;
        const filter = {
            text: [
                toGQLTextFilter(
                    filters,
                    nameof.toArray<Transaction>(m => [m.uid, m.username, m.transaction_id])
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<Transaction>(m => [m.uid]),
                    nameof<Transaction>(m => m.uid)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<Transaction>(m => [m.payment_system_transaction_id]),
                    nameof<Transaction>(m => m.payment_system_transaction_id)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<Transaction>(m => [m.username]),
                    nameof<Transaction>(m => m.username)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<Transaction>(m => [m.transaction_id]),
                    nameof<Transaction>(m => m.transaction_id)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<Transaction>(m => [m.type]),
                    nameof<Transaction>(m => m.type)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<Transaction>(m => [m.email]),
                    nameof<Transaction>(m => m.email),
                    ignoreCase
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<Transaction>(m => [m.payment_method_name]),
                    nameof<Transaction>(m => m.payment_method_name),
                    text => text?.split(' ').join('').concat('*')
                ),
                toGQLTextFilter(
                    filters,
                    [
                        nameof<Transaction>(m => m.uid),
                        nameof<Transaction>(m => m.username),
                        nameof<Transaction>(m => m.transaction_id),
                        nameof<Transaction>(m => m.email),
                    ],
                    withdrawalProcessingFullSearch
                ),
            ],
            transaction_status: toGQKMultiselectFilter(
                filters,
                nameof<UserTransactionFilter>(m => m.transaction_status)
            ),
            account_status: toGQKMultiselectFilter(
                filters,
                nameof<UserTransactionFilter>(m => m.account_status)
            ),
            payment_option: toGQKMultiselectFilter(
                filters,
                nameof<UserTransactionFilter>(m => m.payment_option)
            ),
            transaction_types:
                toGQKMultiselectFilter(
                    filters,
                    nameof<UserTransactionFilter>(m => m.transaction_types)
                ) ??
                defaultTypes ??
                this.types,
            iso_alpha2_country_code: toGQKMultiselectFilter(filters, 'registrationCountry'),
        } as UserTransactionFilter;

        const withdrawalAmountFilter = toGQLNumberRangeFilter(
            filters,
            nameof<UserTransactionFilter>(m => m.amount)
        );
        if (withdrawalAmountFilter) {
            filter.amount = withdrawalAmountFilter;
        }

        const dateFilter = toGQLDateFilter(
            filters,
            nameof<UserTransactionFilter>(m => m.transaction_started_ts)
        );
        if (dateFilter) {
            filter.transaction_started_ts = dateFilter;
        }
        return filter;
    }

    protected getGQLVariables(filter: SearchFilter, filterMappingFunction?: (filters: Filter[]) => IGQlSearchFilter): IGQlFilterVariables {
        const variables = super.getGQLVariables(filter, filterMappingFunction);
        const sort = toGQLMultipleSorting(filter.sorting);
        if (sort) {
            variables.sort = sort;
        }

        return variables;
    }
}

export class TransactionService extends TransactionBaseService<TransactionItem, TransactionGridItem> {}
