import {Timestamp} from '@models/shared';
import {PageType} from '@redux/entity';

import {BoUserViewModel} from '../block-bo-user';

export class PageViewViewModel extends BoUserViewModel {
    userId: string;
    pageId: string;
    lastOpenedAt: Timestamp;
    recordId: string;
    pageType: PageType;
}

export type PageViewViewModelKeys = keyof PageViewViewModel;

export const activeUsersIntervalInSeconds = 120;

export const inactiveUsersIntervalInSeconds = 300;

export type UserInfo = {
    id: string;
    name: string;
    email: string;
    username: string;
    lastOpenedAtSeconds: number;
};
