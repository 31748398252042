import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {GraphQLError} from 'graphql';
import {UserManager} from 'oidc-client-ts';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';

import {
    Mutation,
    Platform,
    Query,
    SecurityCase,
    SortOrder,
    TextFilter,
    UserAccountStatus,
    UserLabel,
    UserLogin,
    UserNetworkProfile,
    UserProfile,
    UserProfileFilter,
    UserProfileInput,
} from '@models/generated/graphql';
import {EntityFetchServiceResponsePayload, EntityType, State} from '@redux/entity';
import {ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlFilterVariables, IGQlSearchFilter} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';
import {MutationError} from '@services/gql-api/gqlService';
import {StatesService} from '@services/statesService';
import {CustomErrorCodes, HttpStatusCode} from '@services/types';
import {ignoreCase, toGQKMultiselectFilter, toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';
import {UserProfileUpdateModel} from '../player-actions/pii-actions/types';
import {PlayerProfile, PlayerProfileGridItem} from '../player-actions/types';
import {IRealtimeGridReadService} from '../realtime-updates/services/IRealtimeGridReadService';

import {
    customerSupportFullSearch,
    customerSupportReferrerSearch,
    securityWorkspaceFullSearch,
    securityWorkspaceReferrerSearch,
    UserProfileColumnsConfiguration,
} from './types';

const getUserProfileQuery = (configuration: UserProfileColumnsConfiguration) => gql`
    query GetUserItems($filter: UserProfileFilter, $sort: Sorting, $start: Int, $end: Int) {        
        getUserProfiles(filter: $filter, sort: $sort, start: $start, end: $end) {
            items {
                uid
                networks ${configuration.withNetworks ? '' : '@client'} {
                    uid
                    platform
                }
                username ${configuration.withUsername ? '' : '@client'}
                nickname ${configuration.withNickName ? '' : '@client'}
                first_name ${configuration.withFirstName ? '' : '@client'}
                last_name ${configuration.withLastName ? '' : '@client'}
                contact {
                    email ${configuration.withEmail ? '' : '@client'} 
                    mobile  ${configuration.withMobile ? '' : '@client'} {
                        area
                        mobile
                        full_number
                    }
                }
                region_player_info {
                    occupation ${configuration.withOccupation ? '' : '@client'}
                    tax_id ${configuration.withTaxID ? '' : '@client'}
                    national_id ${configuration.withNationalID ? '' : '@client'}
                    nationality ${configuration.withNationality ? '' : '@client'}
                    country_of_birth ${configuration.withCountryOfBirth ? '' : '@client'}
                    district ${configuration.withDistrict ? '' : '@client'}
                }
                birthday ${configuration.withBirthday ? '' : '@client'} {
                    seconds
                }
                gender ${configuration.withGender ? '' : '@client'}
                address {
                    address ${configuration.withAddress ? '' : '@client'}
                    city ${configuration.withCity ? '' : '@client'}
                    state ${configuration.withState ? '' : '@client'}
                    country ${configuration.withCountry ? '' : '@client'}
                    post_code ${configuration.withPostCode ? '' : '@client'}
                    country_info {
                      iso_alpha2_code ${configuration.withCountry ? '' : '@client'}
                      name ${configuration.withCountry ? '' : '@client'}
                    }
                }
                date_of_joining ${configuration.withDateOfJoining ? '' : '@client'} {
                    seconds
                }
                account_status ${configuration.withAccountStatus ? '' : '@client'}
                security_settings {
                    is_2fa_enabled ${configuration.withTwoFAEnabled ? '' : '@client'}
                    is_challenge_questions_enabled ${configuration.withChallengeQuestions ? '' : '@client'}
                }
                black_list ${configuration.withBlackList ? '' : '@client'} {
                    items {
                        type
                        key
                    }
                }
                ip_addresses ${configuration.withIpAddresses ? '' : '@client'}
                devices ${configuration.withDevices ? '' : '@client'} {
                    uuid
                    model
                    name
                    operating_system
                }
                security_cases ${configuration.withSecurityCases ? '' : '@client'} {
                    type
                    case_id
                }
                latest_login ${configuration.withLatestLogin ? '' : '@client'} {
                    login_type
                    login_status
                    logged_at_ts {
                        seconds
                    }
                    location {
                        country
                        state
                        city
                        latitude
                        longitude
                    }
                    ip
                    isp
                    device {
                        mac_address
                        model
                        uuid
                        name
                        operating_system
                    }
                    session_id
                    recaptcha_score
                    recaptcha_reasons
                    ip_quality_score
                }                
                finance {
                    deposit ${configuration.withDepositTotalInfo || configuration.withDepositPerMethodInfo ? '' : '@client'} {
                        total ${configuration.withDepositTotalInfo ? '' : '@client'} {
                            successful_amount
                        }
                        per_method ${configuration.withDepositPerMethodInfo ? '' : '@client'} {
                            category
                            group_name
                            successful_amount
                            failed_amount
                            rejected_amount
                            successful_count
                            failed_count
                            rejected_count
                            currency
                        }
                    }
                    withdrawal ${configuration.withWithdrawalTotalInfo || configuration.withWithdrawalPerMethodInfo ? '' : '@client'} {
                        total ${configuration.withWithdrawalTotalInfo ? '' : '@client'} {
                            successful_amount
                        }
                        per_method ${configuration.withWithdrawalPerMethodInfo ? '' : '@client'} {
                            category
                            group_name
                            successful_amount
                            failed_amount
                            rejected_amount
                            successful_count
                            failed_count
                            rejected_count
                            currency
                        }
                    }
                    generated_rake ${configuration.withBalance ? '' : '@client'}
                    balance ${configuration.withBalance ? '' : '@client'}
                    ggr ${configuration.withGGR ? '' : '@client'}
                    lifetime_bonus ${configuration.withLifetimeBonus ? '' : '@client'}
                    bonus_ratio ${configuration.withBonusRatio ? '' : '@client'}
                }
                security ${configuration.withSecurity ? '' : '@client'} @client
                latest_kyc ${configuration.withLatestVerification ? '' : '@client'} {
                    security {
                        id
                        kyc_case_id
                        account_verification_status
                        documents {
                            first_name
                            last_name
                            gender
                            dob
                            doc_type
                            doc_id_sub_type
                            number
                            device_verified
                            ip_verified
                            geolocation_verified
                        }
                    }
                    payment {
                        account_verification_status
                        documents {
                            first_name
                            last_name
                            gender
                            dob
                            doc_type
                            doc_id_sub_type
                            number
                            device_verified
                            ip_verified
                            geolocation_verified
                        }
                    }
                }
                aml_labels ${configuration.withAmlLAbels ? '' : '@client'}
                punishment_status ${configuration.withPunishmentStatus ? '' : '@client'}
                withdrawal_status ${configuration.withWithdrawalStatus ? '' : '@client'}
                deposit_status ${configuration.withDepositStatus ? '' : '@client'}
                lobby_access_status ${configuration.withLobbyAccessStatus ? '' : '@client'}
                p2p_transfer_status ${configuration.withP2pTransferStatus ? '' : '@client'}
                business_pool  ${configuration.withBusinessPool ? '' : '@client'}
                platform  ${configuration.withPlatform ? '' : '@client'}
                pool_id  ${configuration.withPoolId ? '' : '@client'}
                countries  ${configuration.withCountries ? '' : '@client'}
                vpn  ${configuration.withVpn ? '' : '@client'}
                affiliate  ${configuration.withAffiliate ? '' : '@client'} {
                    btag
                }
                # security {
                #     gto_score
                #     player_skill {
                #         score
                #         label
                #         custom_label
                #     }
                # }
                marketing_info ${configuration.withMarketingInfo ? '' : '@client'} {
                    register_marketing_code
                }
                referral ${configuration.withReferrerId ? '' : '@client'} {
                    inviting_user_id
                }
                labels ${configuration.withLabels ? '' : '@client'} {
                    id
                    name
                    group {
                        id
                        name
                        color
                    }
                }
            }
            total_count
        }
    }
`;

//TODO: [BO-2494] Refactor query fragments for GQL
const userProfileFragment = gql`
    fragment UserProfileFragment on UserProfile {
        uid
        first_name
        last_name
        username
        nickname
        birthday {
            seconds
        }
        date_of_joining {
            seconds
        }
        contact {
            email
            mobile {
                area
                mobile
            }
        }
        address {
            address
            city
            state
            country
            post_code
            country_info {
                name
                iso_alpha2_code
            }
            street
        }
        region_player_info {
            occupation
            tax_id
            national_id
            nationality
            country_of_birth
            district
        }
        language
        account_status
        p2p_transfer_status
        security_settings {
            is_2fa_enabled
            is_challenge_questions_enabled
        }
        identity_info {
            last_ssn_digits
        }
        gender
        security_cases {
            type
            case_id
            opened_at {
                seconds
            }
            closed_at {
                seconds
            }
        }
        friend_groups {
            group_id
            players
            group_name
        }
        deposit_status
        withdrawal_status
        lobby_access_status
        p2p_transfer_status
        withdrawal_limits {
            type
            durationType
            limit
            currency
            start_at {
                seconds
            }
            trigger_at {
                seconds
            }
            end_at {
                seconds
            }
            limit_id
        }
        ip_addresses
        devices {
            uuid
            name
            operating_system
            model
            mac_address
            agent
        }
        black_list {
            blacklisted
            deposit_threshold
            items {
                type
                key
            }
        }
        verification_info {
            is_email_verified
            is_phone_verified
            is_address_ssn_verified
        }
        blocked_stakes_access {
            game_type
            game_mode
            limit
        }
        punishment_status
        club
        distance_score
        avg_binary_mistake_score
        ip_quality_score
        recaptcha_score
        bb_100
        win_total
        most_played_stake
        num_linked_bots_by_devices
        num_linked_bots_by_subnets
        num_linked_bots_by_ips
        num_linked_bots_by_bank_card
        num_linked_bots_by_bank_card_name
        num_linked_members_by_devices
        num_linked_members_by_subnets
        num_linked_members_by_bank_card
        num_linked_members_by_bank_card_name
        remote_logins
        last_region
        countries
        vpn
        players_groups
        latest_login {
            uid
            logged_at_ts {
                seconds
            }
            ip
            vpn
            username
            current_balance
            device {
                uuid
                name
                operating_system
                model
                mac_address
                agent
            }
            session_id
            login_type
            isp
            ip_quality_score
            recaptcha_score
            recaptcha_reasons
            language
            location {
                city
                state
                country
                latitude
                longitude
            }
            correlation_id
            login_status
            connection_type
        }
        latest_kyc {
            security {
                id
                kyc_case_id
                account_verification_status
            }
            payment {
                id
                kyc_case_id
                account_verification_status
            }
        }
        finance {
            deposit {
                total {
                    category
                    group_name
                    successful_amount
                    failed_amount
                    rejected_amount
                    successful_count
                    failed_count
                    rejected_count
                    currency
                }
                per_method {
                    category
                    group_name
                    successful_amount
                    failed_amount
                    rejected_amount
                    successful_count
                    failed_count
                    rejected_count
                    currency
                }
            }
            withdrawal {
                total {
                    category
                    group_name
                    successful_amount
                    failed_amount
                    rejected_amount
                    successful_count
                    failed_count
                    rejected_count
                    currency
                }
                per_method {
                    category
                    group_name
                    successful_amount
                    failed_amount
                    rejected_amount
                    successful_count
                    failed_count
                    rejected_count
                    currency
                }
            }
            generated_rake
            balance
            payment_methods
            ggr
            lifetime_bonus
            bonus_ratio
        }
        security {
            player_skill {
                score
                label
                custom_label
            }
            gto_score
        }
        aml_labels
        business_pool
        pool_id
        evidence
        kyc_action
        platform
        affiliate {
            btag
            invite_code
        }
        labels {
            id
            name
            group {
                id
                name
                color
            }
        }
    }
`;

const updateUserProfileMutation = gql`
    ${userProfileFragment}

    mutation UpdateUser($uid: String!, $user: UserProfileInput!) {
        updateUser(uid: $uid, user: $user) {
            ...UserProfileFragment
        }
    }
`;

const loginHistoryQuery = gql`
    query GetLoginHistory($filter: UserLoginFilter, $sort: Sorting, $start: Int, $end: Int) {
        getUsersLoginHistory(filter: $filter, sort: $sort, end: $end, start: $start) {
            items {
                uid
                logged_at_ts {
                    seconds
                }
                device {
                    uuid
                }
            }
            total_count
        }
    }
`;

export class UserProfileService
    extends ReadonlyRealtimeGridServiceBase<PlayerProfile, PlayerProfileGridItem, UserProfile>
    implements IRealtimeGridReadService<string>
{
    private readonly _stateService: StatesService;
    private readonly _userManager: UserManager;

    constructor(client: ApolloClientProxy<NormalizedCacheObject>, stateService: StatesService, userManager: UserManager) {
        super(client);
        this._stateService = stateService;
        this._userManager = userManager;
    }

    getItem(id: string, itemFields?: string[]): Observable<PlayerProfile> {
        const configuration = this.getGQLConfiguration(new UserProfileColumnsConfiguration(), itemFields);

        let profile$ = this._client
            .executeQuery(
                this.getItemQuery(configuration),
                this.getGQLVariables({filter: [{key: nameof<UserProfile>(u => u.uid), value: id}]} as SearchFilter)
            )
            .pipe(
                map<Query, PlayerProfile>(res =>
                    this.mapModels({} as PlayerProfile, res?.getUserProfiles?.items?.length ? res?.getUserProfiles?.items[0] : null)
                )
            );

        if (configuration.withFirstLogin) {
            const loginHistoryFilter = {
                start: 0,
                end: 1,
                filter: {
                    text: [
                        {
                            search_in: [nameof<UserLogin>(u => u.uid)],
                            text: id?.toString(),
                        },
                    ],
                },
                sort: {sort: nameof.full<UserLogin>(u => u.logged_at_ts.seconds), order: SortOrder.Asc},
            } as IGQlFilterVariables;

            profile$ = profile$.pipe(
                mergeMap(user => {
                    return this._client
                        .executeQuery(this.getFirstUserLoginQuery(), loginHistoryFilter)
                        .pipe(
                            map<Query, PlayerProfile>(res =>
                                this.mapModels(user, {firstLogin: res?.getUsersLoginHistory?.items[0], serverId: user.uid} as PlayerProfile)
                            )
                        );
                })
            );
        }

        if (configuration.withState) {
            profile$ = profile$.pipe(
                mergeMap(user => {
                    if (!user?.address?.country_info?.iso_alpha2_code) {
                        return of(user);
                    }

                    return this._stateService
                        .get({
                            type: EntityType.State,
                            filter: `country=${user?.address?.country_info?.iso_alpha2_code}`,
                            fields: [nameof<State>(i => i.code)],
                        })
                        .pipe(
                            map<EntityFetchServiceResponsePayload, PlayerProfile>(res => {
                                const states: State[] = res?.responsePayload?.items as State[];
                                return this.mapModels(user, {
                                    id: user.id,
                                    uid: user.uid,
                                    address: {
                                        ...user.address,
                                        stateName: states?.find(i => i.code === user?.address?.state)?.name,
                                    },
                                    serverId: user.uid,
                                } as PlayerProfile);
                            })
                        );
                })
            );
        }

        return profile$;
    }

    getItems(_filter?: SearchFilter): Observable<PlayerProfileGridItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsIds(filter?: SearchFilter): Observable<string[]> {
        return this._client.executeQuery(this.getItemsPageQuery({} as UserProfileColumnsConfiguration), this.getGQLVariables(filter)).pipe(
            map<Query, string[]>(res => {
                return res?.getUserProfiles?.items?.map(i => i.uid);
            })
        );
    }

    getItemsPage(filter?: SearchFilter, itemFields?: string[]): Observable<ItemsPage<PlayerProfileGridItem>> {
        const configuration = this.getGQLConfiguration(new UserProfileColumnsConfiguration(), itemFields);

        return this._client.executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter)).pipe(
            map<Query, ItemsPage<PlayerProfileGridItem>>(res => {
                let items =
                    res?.getUserProfiles?.items?.map((i, index) =>
                        this.mapModels({id: `${index}`, serverId: i?.uid} as PlayerProfileGridItem, i as UserProfile)
                    ) ?? [];
                const labelsFilter = this.getLabelFilter(filter);
                if (labelsFilter) {
                    items = this.sortPlayerLabelsByFilter(items, labelsFilter);
                }

                return {
                    items,
                    total: res?.getUserProfiles?.total_count ?? 0,
                };
            })
        );
    }

    updateUserProfile(user: UserProfileUpdateModel) {
        return from(this._userManager.getUser()).pipe(
            map(boUser => {
                return this._client
                    .executeMutation(updateUserProfileMutation, {
                        uid: user?.uid,
                        user: {...user, action_by_uid: boUser?.profile?.sub} as UserProfileInput,
                    })
                    .pipe(
                        map<Mutation, UserProfileUpdateModel>(res => res.updateUser),
                        catchError((error: Error) => {
                            const errorCode = error ? this.getUpdateUserProfileErrorCode(error) : null;
                            return errorCode
                                ? throwError({
                                      response: {
                                          ...error,
                                          code: errorCode,
                                      },
                                  })
                                : throwError(error);
                        })
                    );
            })
        );
    }

    private getUpdateUserProfileErrorCode(error: Error): string {
        return (
            this.getGraphQLErrorCode(error as GraphQLError) ||
            (this.getMutationErrorCode(error as MutationError) === String(HttpStatusCode.BadRequest)
                ? CustomErrorCodes.InputValidationIncorrectFormat.toString()
                : null)
        );
    }

    protected getItemsPageQuery(configuration: UserProfileColumnsConfiguration): DocumentNode {
        return getUserProfileQuery(configuration);
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        const filter = {
            account_status: toGQKMultiselectFilter<UserAccountStatus>(
                filters,
                nameof<UserProfileFilter>(m => m.account_status)
            ),
            text: [
                toGQLTextFilter(
                    filters,
                    nameof.toArray<UserProfile>(m => [m.uid]),
                    nameof<UserProfile>(m => m.uid)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<UserProfile>(m => [m.first_name]),
                    nameof<UserProfile>(m => m.first_name)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<UserProfile>(m => [m.last_name]),
                    nameof<UserProfile>(m => m.last_name)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<UserProfile>(m => [m.username]),
                    nameof<UserProfile>(m => m.username)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<UserProfile>(m => [m.nickname]),
                    nameof<UserProfile>(m => m.nickname)
                ),
                toGQLTextFilter(
                    filters,
                    [`${nameof<UserProfile>(w => w.labels)}.${nameof<UserLabel>(l => l.name)}`],
                    `${nameof<UserProfile>(w => w.labels)}.${nameof<UserLabel>(l => l.name)}`,
                    value => `"${value}"`
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<UserProfile>(m => [nameof.full(m.contact.email, 1)]),
                    nameof<UserProfile>(m => m.contact.email),
                    ignoreCase
                ),
                toGQLTextFilter(
                    filters,
                    [nameof.full<UserProfile>(m => m.region_player_info.national_id)],
                    nameof.full<UserProfile>(m => m.region_player_info.national_id),
                    ignoreCase
                ),
                toGQLTextFilter(
                    filters,
                    [`${nameof.full<UserProfile>(u => u.security_cases)}.${nameof<SecurityCase>(u => u.case_id)}`],
                    nameof<SecurityCase>(u => u.case_id)
                ),
                toGQLTextFilter(
                    filters,
                    [nameof.full<UserProfile>(u => u.marketing_info.register_marketing_code)],
                    nameof.full<UserProfile>(u => u.marketing_info.register_marketing_code)
                ),
                toGQLTextFilter(
                    filters,
                    [`${nameof.full<UserProfile>(u => u.security_cases)}.${nameof<SecurityCase>(u => u.case_id)}`],
                    nameof<SecurityCase>(u => u.case_id)
                ),
                toGQLTextFilter(filters, [`${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserProfile>(p => p.uid)}`], Platform.Pkw),
                toGQLTextFilter(filters, [`${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserProfile>(p => p.uid)}`], Platform.Bl),
                (() => {
                    const networkFilter = filters.find(i => i.key === Platform.Bl || i.key === Platform.Pkw);
                    const textFilter: TextFilter = networkFilter
                        ? {
                              text: networkFilter.key?.toString(),
                              search_in: [`${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserProfile>(p => p.platform)}`],
                          }
                        : null;
                    return textFilter;
                })(),
                toGQLTextFilter(
                    filters,
                    [nameof.full<UserProfile>(m => m.contact.mobile.full_number)],
                    nameof.full<UserProfile>(m => m.contact.mobile.full_number)
                ),
                toGQLTextFilter(
                    filters,
                    [nameof.full<UserProfile>(w => w.affiliate.btag), nameof.full<UserProfile>(w => w.referral.inviting_user_id)],
                    customerSupportReferrerSearch
                ),
                toGQLTextFilter(
                    filters,
                    [
                        nameof<UserProfile>(w => w.uid),
                        nameof<UserProfile>(w => w.username),
                        nameof<UserProfile>(w => w.first_name),
                        nameof<UserProfile>(w => w.last_name),
                        nameof.full<UserProfile>(w => w.contact.email),
                        nameof.full<UserProfile>(w => w.region_player_info.national_id),
                        `${nameof<UserProfile>(w => w.labels)}.${nameof<UserLabel>(l => l.name)}`,
                        nameof.full<UserProfile>(w => w.contact.mobile.full_number),
                        nameof.full<UserProfile>(w => w.marketing_info.register_marketing_code),
                        nameof.full<UserProfile>(w => w.affiliate.btag),
                        nameof.full<UserProfile>(w => w.referral.inviting_user_id),
                    ],
                    customerSupportFullSearch
                ),
                toGQLTextFilter(
                    filters,
                    [
                        nameof<UserProfile>(w => w.uid),
                        nameof<UserProfile>(w => w.username),
                        nameof<UserProfile>(w => w.nickname),
                        nameof.full<UserProfile>(w => w.contact.email),
                        `${nameof<UserProfile>(w => w.labels)}.${nameof<UserLabel>(l => l.name)}`,
                        `${nameof.full<UserProfile>(u => u.security_cases)}.${nameof<SecurityCase>(u => u.case_id)}`,
                        `${nameof.full<UserProfile>(u => u.networks)}.${nameof<UserNetworkProfile>(p => p.uid)}`,
                        nameof.full<UserProfile>(w => w.affiliate.btag),
                        nameof.full<UserProfile>(w => w.referral.inviting_user_id),
                    ],
                    securityWorkspaceFullSearch
                ),
                toGQLTextFilter(
                    filters,
                    [nameof.full<UserProfile>(w => w.affiliate.btag), nameof.full<UserProfile>(w => w.referral.inviting_user_id)],
                    securityWorkspaceReferrerSearch
                ),
                toGQLTextFilter(
                    filters,
                    [nameof.full<UserProfile>(m => m.agent_info.bo_agent_id)],
                    nameof.full<UserProfile>(m => m.agent_info.bo_agent_id)
                ),
            ],
        } as UserProfileFilter;
        return filter;
    }

    protected getItemQuery(configuration: UserProfileColumnsConfiguration) {
        return getUserProfileQuery(configuration);
    }

    protected getFirstUserLoginQuery() {
        return loginHistoryQuery;
    }

    private sortPlayerLabelsByFilter(users: PlayerProfileGridItem[], labelsFilter: string): PlayerProfileGridItem[] {
        users?.forEach(i => {
            i.labels = i?.labels?.sort((a, b) => b.name.toUpperCase().indexOf(labelsFilter) - a.name.toUpperCase().indexOf(labelsFilter));
        });

        return users;
    }

    private getLabelFilter(searchFilter: SearchFilter) {
        const labelFilterKey = `${nameof.full<UserProfile>(w => w.labels)}.${nameof.full<UserLabel>(l => l.name)}`;
        return searchFilter?.filter
            ?.find(f => f.key === labelFilterKey || customerSupportFullSearch)
            ?.value?.toString()
            ?.toUpperCase();
    }
}
