import {customerSupportUpdatePermissions} from '@models/permissions/permissions';

import {LackOfPermissionIndicator, withMultiplePermission} from '../../../app/permission/PermissionHoc';
import {EditableCountryOfBirthAutocomplete} from '../../../view-autocomplete/components/CountryOfBirthAutocomplete';
import {piiActions} from '../actions';

import {withUserProfileCountry} from './UserProfileCountryHoc';
import {localized} from './UserProfileCountryOfBirth.localize';

export const UserProfileCountryOfBirth = withMultiplePermission(
    withUserProfileCountry(EditableCountryOfBirthAutocomplete, {
        action: piiActions.editCountryOfBirth,
        submitModelMapper: m => ({
            fieldValue: m?.user?.region_player_info?.country_of_birth,
            userId: m?.userId,
        }),
        formModelMapper: m => ({
            user: {
                uid: m?.userId,
                region_player_info: {
                    country_of_birth: m?.fieldValue,
                },
            },
            userId: m?.userId,
        }),
        placeholder: localized.piiCountryOfBirthFieldName,
        //TODO: [IGP-2179] Added because of the backend partial update logic. Rework after backend change
        isRequired: true,
    }),
    {allowedPermissions: customerSupportUpdatePermissions, indicator: LackOfPermissionIndicator.Disabled}
);
