import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    piiAddressCountryFieldName: {
        id: 'piiAddressCountryFieldName',
        defaultMessage: 'Country',
    },
    piiAddressStateFieldName: {
        id: 'piiAddressStateFieldName',
        defaultMessage: 'State',
    },
});
