import {constructUsing, createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {AgentPathStatistics} from '@models/generated/graphql';
import {AgentPathStatisticsQueryFields} from '@redux/entity';

import {AgentPathStatisticsViewModel, AgentPathStatisticsViewModelKeys} from './types';

export class AgentPathStatisticsMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            AgentPathStatistics,
            AgentPathStatisticsViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.path_to_subagent)
            ),
            forMember(
                vm => vm.agent_uid,
                mapFrom(m => m.agent_uid)
            ),
            forMember(
                vm => vm.path_to_subagent,
                mapFrom(m => m.path_to_subagent)
            ),
            forMember(
                vm => vm.registered_player_number,
                mapFrom(m => m.registered_player_number)
            ),
            forMember(
                vm => vm.active_player_number,
                mapFrom(m => m.active_player_number)
            )
        );
        createMap<AgentPathStatisticsViewModelKeys[], AgentPathStatisticsQueryFields[]>(
            mapper,
            nameof<AgentPathStatisticsViewModelKeys>(),
            nameof<AgentPathStatisticsQueryFields>(),
            constructUsing(m => {
                const mapper: Record<AgentPathStatisticsViewModelKeys, AgentPathStatisticsQueryFields> = {
                    id: 'path_to_subagent',
                    agent_uid: 'agent_uid',
                    path_to_subagent: 'path_to_subagent',
                    active_player_number: 'active_player_number',
                    registered_player_number: 'registered_player_number',
                };

                return [...new Set(m.map(i => mapper[i]))];
            })
        );
    }
}
