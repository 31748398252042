import {GridCellParams, GridValueGetterParams} from '@mui/x-data-grid';

import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    renderCurrencyCell,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {formatTimestamp} from '@utils';

import {useJurisdictionConfig} from 'src/features/app/config/hooks';
import {AgentLevelReportViewModel, AgentLevelReportViewModelKeys} from '../types';

import {localizedHeaders} from './DataGridAgentLevelReport.localize';

class DataGridAgentLevelReportConfigFactory extends DataGridEntityConfigFactory<AgentLevelReportViewModelKeys, AgentLevelReportViewModel> {
    getColumnConfig(): Partial<
        Record<AgentLevelReportViewModelKeys, DataGridEntityColDef<AgentLevelReportViewModelKeys, AgentLevelReportViewModel>>
    > {
        const {currency} = useJurisdictionConfig();
        const columnsMapping: Partial<
            Record<AgentLevelReportViewModelKeys, DataGridEntityColDef<AgentLevelReportViewModelKeys, AgentLevelReportViewModel>>
        > = {
            adjusted_revenue_shared_total: {
                field: 'adjusted_revenue_shared_total',
                valueGetter: (p: GridValueGetterParams) => this.getAdjustedRevenueSharedTotal(p),
                localizedHeaderName: localizedHeaders.adjusted_revenue_shared_total,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            week_start_date_ts: {
                field: 'week_start_date_ts',
                valueGetter: (p: GridValueGetterParams) => this.getWeekStartDate(p),
                localizedHeaderName: localizedHeaders.week_start_date_ts,
                renderHeader: renderCellHeader,
                renderCell: renderCellWithTooltip,
                width: 150,
                sortField: 'week_start_date_ts',
                sortDirection: 'desc',
            },
            referrer_id: {
                field: 'referrer_id',
                valueGetter: (p: GridValueGetterParams) => this.getReferrerId(p),
                localizedHeaderName: localizedHeaders.referrer_id,
                renderHeader: renderCellHeader,
                renderCell: renderCellWithTooltip,
            },
            referrer_username: {
                field: 'referrer_username',
                valueGetter: (p: GridValueGetterParams) => this.getReferrerUsername(p),
                localizedHeaderName: localizedHeaders.referrer_username,
                renderHeader: renderCellHeader,
                renderCell: renderCellWithTooltip,
                width: 150,
            },
            poker_ngr: {
                field: 'poker_ngr',
                valueGetter: (p: GridValueGetterParams) => this.getPokerNgr(p),
                localizedHeaderName: localizedHeaders.poker_ngr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            casino_ggr: {
                field: 'casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getCasinoGgr(p),
                localizedHeaderName: localizedHeaders.casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            internal_casino_ggr: {
                field: 'internal_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getInternalCasinoGgr(p),
                localizedHeaderName: localizedHeaders.internal_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            external_casino_ggr: {
                field: 'external_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getExternalCasinoGgr(p),
                localizedHeaderName: localizedHeaders.external_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            revenue_shared_total: {
                field: 'revenue_shared_total',
                valueGetter: (p: GridValueGetterParams) => this.getRevenueSharedTotal(p),
                localizedHeaderName: localizedHeaders.revenue_shared_total,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            referrer_psp_fees: {
                field: 'referrer_psp_fees',
                valueGetter: (p: GridValueGetterParams) => this.getReferrerPspFees(p),
                localizedHeaderName: localizedHeaders.referrer_psp_fees,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            carry_over_negative_balance: {
                field: 'carry_over_negative_balance',
                valueGetter: (p: GridValueGetterParams) => this.getCarryOverNegativeBalance(p),
                localizedHeaderName: localizedHeaders.carry_over_negative_balance,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_1_revenue_shared: {
                field: 'tier_1_revenue_shared',
                valueGetter: (p: GridValueGetterParams) => this.getTierRevenueShared(p, 1),
                localizedHeaderName: localizedHeaders.tier_1_revenue_shared,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_2_revenue_shared: {
                field: 'tier_2_revenue_shared',
                valueGetter: (p: GridValueGetterParams) => this.getTierRevenueShared(p, 2),
                localizedHeaderName: localizedHeaders.tier_2_revenue_shared,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_3_revenue_shared: {
                field: 'tier_3_revenue_shared',
                valueGetter: (p: GridValueGetterParams) => this.getTierRevenueShared(p, 3),
                localizedHeaderName: localizedHeaders.tier_3_revenue_shared,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_4_revenue_shared: {
                field: 'tier_4_revenue_shared',
                valueGetter: (p: GridValueGetterParams) => this.getTierRevenueShared(p, 4),
                localizedHeaderName: localizedHeaders.tier_4_revenue_shared,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_5_revenue_shared: {
                field: 'tier_5_revenue_shared',
                valueGetter: (p: GridValueGetterParams) => this.getTierRevenueShared(p, 5),
                localizedHeaderName: localizedHeaders.tier_5_revenue_shared,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_6_revenue_shared: {
                field: 'tier_6_revenue_shared',
                valueGetter: (p: GridValueGetterParams) => this.getTierRevenueShared(p, 6),
                localizedHeaderName: localizedHeaders.tier_6_revenue_shared,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_1_poker_revenue_share: {
                field: 'tier_1_poker_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierPokerRevenueShare(p, 1),
                localizedHeaderName: localizedHeaders.tier_1_poker_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_2_poker_revenue_share: {
                field: 'tier_2_poker_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierPokerRevenueShare(p, 2),
                localizedHeaderName: localizedHeaders.tier_2_poker_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_3_poker_revenue_share: {
                field: 'tier_3_poker_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierPokerRevenueShare(p, 3),
                localizedHeaderName: localizedHeaders.tier_3_poker_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_4_poker_revenue_share: {
                field: 'tier_4_poker_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierPokerRevenueShare(p, 4),
                localizedHeaderName: localizedHeaders.tier_4_poker_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_5_poker_revenue_share: {
                field: 'tier_5_poker_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierPokerRevenueShare(p, 5),
                localizedHeaderName: localizedHeaders.tier_5_poker_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_6_poker_revenue_share: {
                field: 'tier_6_poker_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierPokerRevenueShare(p, 6),
                localizedHeaderName: localizedHeaders.tier_6_poker_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_1_casino_revenue_share: {
                field: 'tier_1_casino_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierCasinoRevenueShare(p, 1),
                localizedHeaderName: localizedHeaders.tier_1_casino_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_2_casino_revenue_share: {
                field: 'tier_2_casino_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierCasinoRevenueShare(p, 2),
                localizedHeaderName: localizedHeaders.tier_2_casino_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_3_casino_revenue_share: {
                field: 'tier_3_casino_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierCasinoRevenueShare(p, 3),
                localizedHeaderName: localizedHeaders.tier_3_casino_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_4_casino_revenue_share: {
                field: 'tier_4_casino_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierCasinoRevenueShare(p, 4),
                localizedHeaderName: localizedHeaders.tier_4_casino_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_5_casino_revenue_share: {
                field: 'tier_5_casino_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierCasinoRevenueShare(p, 5),
                localizedHeaderName: localizedHeaders.tier_5_casino_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_6_casino_revenue_share: {
                field: 'tier_6_casino_revenue_share',
                valueGetter: (p: GridValueGetterParams) => this.getTierCasinoRevenueShare(p, 6),
                localizedHeaderName: localizedHeaders.tier_6_casino_revenue_share,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_1_generated_internal_casino_ggr: {
                field: 'tier_1_generated_internal_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedInternalCasinoGGR(p, 1),
                localizedHeaderName: localizedHeaders.tier_1_generated_internal_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_2_generated_internal_casino_ggr: {
                field: 'tier_2_generated_internal_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedInternalCasinoGGR(p, 2),
                localizedHeaderName: localizedHeaders.tier_2_generated_internal_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_3_generated_internal_casino_ggr: {
                field: 'tier_3_generated_internal_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedInternalCasinoGGR(p, 3),
                localizedHeaderName: localizedHeaders.tier_3_generated_internal_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_4_generated_internal_casino_ggr: {
                field: 'tier_4_generated_internal_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedInternalCasinoGGR(p, 4),
                localizedHeaderName: localizedHeaders.tier_4_generated_internal_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_5_generated_internal_casino_ggr: {
                field: 'tier_5_generated_internal_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedInternalCasinoGGR(p, 5),
                localizedHeaderName: localizedHeaders.tier_5_generated_internal_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_6_generated_internal_casino_ggr: {
                field: 'tier_6_generated_internal_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedInternalCasinoGGR(p, 6),
                localizedHeaderName: localizedHeaders.tier_6_generated_internal_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_1_generated_external_casino_ggr: {
                field: 'tier_1_generated_external_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedExternalCasinoGGR(p, 1),
                localizedHeaderName: localizedHeaders.tier_1_generated_external_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_2_generated_external_casino_ggr: {
                field: 'tier_2_generated_external_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedExternalCasinoGGR(p, 2),
                localizedHeaderName: localizedHeaders.tier_2_generated_external_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_3_generated_external_casino_ggr: {
                field: 'tier_3_generated_external_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedExternalCasinoGGR(p, 3),
                localizedHeaderName: localizedHeaders.tier_3_generated_external_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_4_generated_external_casino_ggr: {
                field: 'tier_4_generated_external_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedExternalCasinoGGR(p, 4),
                localizedHeaderName: localizedHeaders.tier_4_generated_external_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_5_generated_external_casino_ggr: {
                field: 'tier_5_generated_external_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedExternalCasinoGGR(p, 5),
                localizedHeaderName: localizedHeaders.tier_5_generated_external_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
            tier_6_generated_external_casino_ggr: {
                field: 'tier_6_generated_external_casino_ggr',
                valueGetter: (p: GridValueGetterParams) => this.getTierGeneratedExternalCasinoGGR(p, 6),
                localizedHeaderName: localizedHeaders.tier_6_generated_external_casino_ggr,
                renderHeader: renderCellHeader,
                renderCell: (p: GridCellParams) => renderCurrencyCell(p, currency),
            },
        };
        return columnsMapping;
    }

    private getTierGeneratedExternalCasinoGGR(p: GridValueGetterParams, tier: number) {
        const field = `tier_${tier}_generated_external_casino_ggr` as keyof AgentLevelReportViewModel;
        return this.getRow(p)?.[field];
    }

    private getTierGeneratedInternalCasinoGGR(p: GridValueGetterParams, tier: number) {
        const field = `tier_${tier}_generated_internal_casino_ggr` as keyof AgentLevelReportViewModel;
        return this.getRow(p)?.[field];
    }

    private getTierCasinoRevenueShare(p: GridValueGetterParams, tier: number) {
        const field = `tier_${tier}_casino_revenue_share` as keyof AgentLevelReportViewModel;
        return this.getRow(p)?.[field];
    }

    private getTierPokerRevenueShare(p: GridValueGetterParams, tier: number) {
        const field = `tier_${tier}_poker_revenue_share` as keyof AgentLevelReportViewModel;
        return this.getRow(p)?.[field];
    }

    private getTierRevenueShared(p: GridValueGetterParams, tier: number) {
        const field = `tier_${tier}_revenue_shared` as keyof AgentLevelReportViewModel;
        return this.getRow(p)?.[field];
    }

    private getCarryOverNegativeBalance(p: GridValueGetterParams) {
        return this.getRow(p)?.carry_over_negative_balance;
    }

    private getReferrerPspFees(p: GridValueGetterParams) {
        return this.getRow(p)?.referrer_psp_fees;
    }

    private getRevenueSharedTotal(p: GridValueGetterParams) {
        return this.getRow(p)?.revenue_shared_total;
    }

    private getExternalCasinoGgr(p: GridValueGetterParams) {
        return this.getRow(p)?.external_casino_ggr;
    }

    private getInternalCasinoGgr(p: GridValueGetterParams) {
        return this.getRow(p)?.internal_casino_ggr;
    }

    private getCasinoGgr(p: GridValueGetterParams) {
        return this.getRow(p)?.casino_ggr;
    }

    private getPokerNgr(p: GridValueGetterParams) {
        return this.getRow(p)?.poker_ngr;
    }

    private getReferrerUsername(p: GridValueGetterParams) {
        return this.getRow(p)?.referrer_username;
    }

    private getReferrerId(p: GridValueGetterParams) {
        return this.getRow(p)?.referrer_id;
    }

    private getWeekStartDate(p: GridValueGetterParams) {
        return formatTimestamp(this.getRow(p)?.week_start_date_ts, 'date');
    }

    private getAdjustedRevenueSharedTotal(p: GridValueGetterParams) {
        return this.getRow(p)?.adjusted_revenue_shared_total;
    }
}

export const DataGridAgentLevelReportingClient = withDataGridEntityClient<AgentLevelReportViewModelKeys, AgentLevelReportViewModel>(
    MuiDataGridClient,
    () => new DataGridAgentLevelReportConfigFactory().getColumnConfig()
);

export const DataGridAgentLevelReportServer = withDataGridEntityServer<AgentLevelReportViewModelKeys, AgentLevelReportViewModel>(
    MuiDataGridServer,
    () => new DataGridAgentLevelReportConfigFactory().getColumnConfig()
);
