import {defineMessages} from 'react-intl';

export const localizedReferralSettingsRevenueShare = defineMessages({
    enteraNumber: {
        id: 'ReferralSettingsRevenueShare_enteraNumber',
        defaultMessage: 'Enter a number',
    },
    revenueShare: {
        id: 'ReferralSettingsRevenueShare_revenueShare',
        defaultMessage: 'Revenue Share',
    },
});
