export const isObject = (item: unknown): boolean => item !== null && typeof item === 'object' && !Array.isArray(item);

export const setObjectFieldByPath = (object: any, fieldPath: string, value: unknown) => {
    const parts = fieldPath.split('.');
    parts.forEach((part, index) => {
        if (index !== parts.length - 1) {
            if (!object[part]) {
                object[part] = {};
            }
            object = object[part];
        } else {
            object[part] = value;
        }
    });
};

export function getMirrorObject<TKey extends string | number | symbol, TValue extends string | number | symbol>(
    object: Record<TKey, TValue>
): Record<TValue, TKey> {
    return (Object.keys(object) as TKey[])?.reduce((result, key) => {
        const value: TValue = object[key];
        if (value) {
            result[value] = key;
        }
        return result;
    }, {} as Record<TValue, TKey>);
}
