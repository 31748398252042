import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import Dropzone from '@components/dropzone/Dropzone';

import {Wrapper} from './WrapperDev';

const useClasses = makeStyles()({
    container: {
        margin: '50px',
        width: '600px',
    },
});

export const DropzoneDev = () => {
    const {classes} = useClasses();
    return (
        <Wrapper label={'Drag&Drop component'}>
            <Box className={classes.container}>
                <Dropzone availableFormats={['XLS', 'XLSX']} maxSizeInMB={5} onFileUpload={() => {}} onInvalidFileUpload={() => {}} />
            </Box>
        </Wrapper>
    );
};
