import {defineMessages} from 'react-intl';

export const localizedHeaders = defineMessages({
    playerBonusGridAmount: {
        id: 'playerBonusGridAmount',
        defaultMessage: 'Amount',
    },
    playerBonusGridMarketingCode: {
        id: 'playerBonusGridMarketingCode',
        defaultMessage: 'Marketing code',
    },
});
