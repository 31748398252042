import {Observable} from 'rxjs';

import {AccountVerificationColumnsConfiguration, AccountVerificationItem} from '../../kyc-processing/types';
import {KycDetailsBaseService} from '../services';

export class KycDetailsDocumentsService extends KycDetailsBaseService {
    getItem(id: string): Observable<AccountVerificationItem> {
        return super.getItem(id, [
            nameof<AccountVerificationColumnsConfiguration>(c => c.withDocuments),
            nameof<AccountVerificationColumnsConfiguration>(c => c.withType),
            nameof<AccountVerificationColumnsConfiguration>(c => c.withUid),
        ]);
    }
}
