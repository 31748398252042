import {createSelector} from 'reselect';
import {RootState} from 'typesafe-actions';

import {EntityType} from './types/base';
import {EntitiesState, EntityState} from './reducers';

export const entitiesSelector = (state: RootState): EntitiesState => state.entities;

export const entitiesSelectorWithoutReferences = (state: RootState): Partial<Record<EntityType, EntityState>> => state.entities.entities;

export const selectAllItems = createSelector<RootState, EntityType, EntityState, unknown[]>(
    (state: RootState, entityType: EntityType) => state.entities.entities?.[entityType],
    (items: EntityState) => Object.values(items)
);

type SelectItemByIdParams = {
    entityType: EntityType;
    id: string;
};

export const selectItemById = createSelector<RootState, SelectItemByIdParams, unknown, unknown>(
    (state: RootState, {entityType, id}: SelectItemByIdParams) => state.entities.entities?.[entityType]?.[id],
    (item: unknown) => item
);

type SelectItemListByIdsParams = {
    entityType: EntityType;
    ids: string[];
};

export const selectItemListByIds = createSelector<RootState, SelectItemListByIdsParams, EntityState, string[], unknown[]>(
    (state: RootState, {entityType}: SelectItemListByIdsParams) => state.entities.entities?.[entityType],
    (_: RootState, {ids}: SelectItemListByIdsParams) => ids,
    (items: EntityState, ids: string[]) =>
        Object.keys(items)
            .filter(id => ids.includes(id))
            .map(id => items[id])
);
