import React from 'react';
import {Box, TablePagination, Typography, useMediaQuery} from '@mui/material';
import {Pagination} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme, useCustomTheme} from '@style';

//TODO: [BO-2682] Move files to src/common/components/pagination
type StyledPaginationProps = {
    count: number;
    page: number;
    rowsPerPage: number;
    rowsPerPageOptions?: number[];
    showPaginationNumbers?: boolean;
    labelRowsPerPage?: string | null;
    onPageChange: (page: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
};

export const useStyledPaginationClasses = makeStyles()((theme: CustomTheme) => ({
    tablePagination: {
        minHeight: 60,
        overflow: 'hidden',
        marginTop: theme.spacing(1),
        marginRight: 'auto',
        '& .MuiTablePagination-displayedRows': {
            display: 'none',
        },
        '& .MuiTablePagination-select': {
            color: theme.palette.secondary.main,
            paddingRight: `${theme.spacing(4)} !important`,
            [theme.breakpoints.down('sm')]: {
                paddingRight: `${theme.spacing(3)} !important`,
                paddingLeft: 0,
            },
        },
        '& .MuiTablePagination-selectLabel': {
            color: theme.palette.secondary.main,
        },
        '& .MuiTablePagination-caption:first-of-type': {
            //marginLeft: theme.spacing(2),
            color: theme.palette.secondary.main,
        },
        '& .MuiTablePagination-caption:last-of-type': {
            display: 'none',
            marginRight: theme.spacing(4),
            color: theme.palette.secondary.main,
        },
        '& .MuiTablePagination-actions': {
            display: 'none',
            marginRight: theme.spacing(4),
        },
        '& .MuiTablePagination-selectIcon': {
            right: theme.spacing(-1),
            color: theme.palette.secondary.main,
        },
        '& .MuiTablePagination-select.Mui-focused': {
            '& .MuiTablePagination-select': {
                color: theme.palette.primary.main,
            },
            border: '0 !important',
            boxShadow: 'none',
        },
        '& .MuiTablePagination-selectRoot': {
            marginLeft: theme.spacing(0.5),
        },
    },
    tablePaginationWithActions: {
        minHeight: 60,
        overflow: 'hidden',
        marginTop: theme.spacing(1),
        marginRight: 'auto',
        width: '100%',
        '& .MuiTablePagination-displayedRows': {
            display: 'none',
        },
        '& .MuiIconButton-root': {
            backgroundColor: theme.palette.background.paper,
            height: '26px',
            width: '26px',
            borderRadius: theme.spacing(0.5),
            border: '1px solid rgb(225 231 235)',
            margin: theme.spacing(1, 0.5),
        },
        '& .MuiIconButton-root:hover': {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiIconButton-root .MuiSvgIcon-root path': {
            stroke: theme.palette.background.paper,
            strokeWidth: '0.75px',
        },
        '& .MuiIconButton-root:hover .MuiSvgIcon-root path': {
            stroke: theme.palette.primary.main,
            strokeWidth: '0.75px',
        },
        '& .MuiTablePagination-actions': {
            marginRight: theme.spacing(4),
        },
        '& .MuiTablePagination-select': {
            color: theme.palette.secondary.main,
            paddingRight: `${theme.spacing(4)} !important`,
        },
        '& .MuiTablePagination-selectLabel': {
            color: theme.palette.secondary.main,
        },
        '& .MuiTablePagination-caption:first-of-type': {
            color: theme.palette.secondary.main,
        },
        '& .MuiTablePagination-caption:last-of-type': {
            marginRight: theme.spacing(0.75),
            color: theme.palette.secondary.main,
        },
        '& .MuiTablePagination-selectIcon': {
            color: theme.palette.secondary.main,
        },
        '& .MuiTablePagination-select.Mui-focused .MuiTablePagination-select': {
            color: theme.palette.primary.main,
        },
    },
    paginationCaption: {
        alignItems: 'center',
        marginRight: theme.spacing(4),
    },
    pagination: {
        display: 'flex',
        marginRight: theme.spacing(3),
        '& .MuiPaginationItem-root': {
            height: '26px',
            minWidth: '26px',
            border: 'none',
            margin: 0,
            padding: 0,
            color: theme.palette.secondary.main,
        },
        '& .MuiPaginationItem-root:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        '& .MuiPagination-ul li:first-of-type .MuiPaginationItem-root, & .MuiPagination-ul li:last-of-type .MuiPaginationItem-root': {
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.spacing(0.5),
            border: '1px solid rgb(225 231 235)',
            margin: theme.spacing(0, 0.5),
        },
        '& .MuiPagination-ul li:first-of-type .MuiPaginationItem-root:hover, & .MuiPagination-ul li:last-of-type .MuiPaginationItem-root:hover':
            {
                color: theme.palette.background.paper,
                backgroundColor: theme.palette.primary.main,
            },
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(2),
            minWidth: 220,
            justifyContent: 'end',
        },
    },
}));

const StyledPagination = ({
    count,
    rowsPerPage,
    rowsPerPageOptions,
    page,
    labelRowsPerPage,
    onPageChange,
    onPageSizeChange,
    showPaginationNumbers = true,
}: StyledPaginationProps) => {
    const {classes} = useStyledPaginationClasses();
    const theme = useCustomTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const itemCount = Math.ceil(count / rowsPerPage);
    const pageNumber = page + 1;
    const paginateStart = pageNumber * rowsPerPage + 1 - rowsPerPage;
    const emptyCheckedPaginateStart = count === 0 ? 0 : paginateStart;
    const paginateEnd = paginateStart + rowsPerPage - 1;
    const tablePaginationClass = showPaginationNumbers ? classes.tablePagination : classes.tablePaginationWithActions;

    const handlePageChange = (_: React.MouseEvent<HTMLButtonElement>, page: number) => {
        if (onPageChange) {
            onPageChange(page - 1);
        }
    };

    const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onPageSizeChange) {
            const newPageSize = parseInt(event.target.value);
            if (rowsPerPage !== newPageSize) {
                onPageSizeChange(newPageSize);
            }
        }
    };

    return (
        <Box display="flex">
            <TablePagination
                className={tablePaginationClass}
                component={'div'}
                count={count}
                page={page}
                labelRowsPerPage={labelRowsPerPage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                nextIconButtonProps={{disableFocusRipple: true, disableRipple: true}}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handlePageSizeChange}
            />
            {showPaginationNumbers && (
                <Box display="flex">
                    <Box
                        sx={{
                            display: {
                                xs: 'none',
                                sm: 'flex',
                            },
                        }}
                        className={classes.paginationCaption}
                    >
                        <Typography variant="body2" color="textSecondary">
                            {emptyCheckedPaginateStart}-{paginateEnd > count ? count : paginateEnd} of {count}
                        </Typography>
                    </Box>
                    <Pagination
                        className={classes.pagination}
                        count={itemCount}
                        variant="outlined"
                        page={pageNumber}
                        shape="rounded"
                        onChange={handlePageChange}
                        siblingCount={isMobile ? 0 : 1}
                    />
                </Box>
            )}
        </Box>
    );
};

export default StyledPagination;
