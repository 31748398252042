import React from 'react';
import {Box} from '@mui/material';
import {TreeItem, TreeItemProps} from '@mui/x-tree-view/TreeItem';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {Chip} from '../chip/Chips';
import {ChipType, ChipVariant} from '../chip/types';
import {PentagonSvgIcon} from '../icons/PentagonSvgIcon';

type TreeViewItemColorGroupProps = TreeItemProps & {
    color: string;
    itemsCount: number;
    actions?: React.ReactNode;
};

const useStyles = makeStyles()((theme: CustomTheme) => ({
    treeViewItemColorGroupGroup: {
        paddingLeft: theme.spacing(7.25),
        paddingRight: theme.spacing(1.5),
    },
    treeViewItem: {
        display: 'flex',
        flexDirection: 'row',
    },
    treeViewItemColorLabel: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    treeViewItemColorLabelActions: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));

export function TreeViewItemColorGroup({classes, label, color, itemsCount, actions, ...otherProps}: TreeViewItemColorGroupProps) {
    const {classes: colorGroupClasses} = useStyles();
    return (
        <TreeItem
            {...otherProps}
            classes={{...classes, group: `${classes?.group} ${colorGroupClasses.treeViewItemColorGroupGroup}`}}
            label={
                <Box className={colorGroupClasses.treeViewItem}>
                    <Box className={colorGroupClasses.treeViewItemColorLabel}>
                        <PentagonSvgIcon color={color}></PentagonSvgIcon>
                        {label}
                        <Chip
                            chipType={ChipType.Status}
                            chipVariant={ChipVariant.LightGrey}
                            label={itemsCount}
                            size="small"
                            fillWidth={false}
                        ></Chip>
                    </Box>
                    <Box className={colorGroupClasses.treeViewItemColorLabelActions}>{actions}</Box>
                </Box>
            }
        ></TreeItem>
    );
}
