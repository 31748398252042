import {LOCATION_CHANGE, LocationChangePayload} from '@vs-centaurea/connected-react-router';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {PayloadAction} from 'typesafe-actions';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {applyLocationAttrs, mergeMap, TracingService} from '@otel';

import {BaseEpicsBuilder} from '..';

const routing = 'route';
const routingAttributes = {ROUTE_PREV_PATHNAME: `${routing}.prev_pathname`, ROUTE_CURRENT_PATHNAME: `${routing}.current_pathname`};
const routingSpanName = 'ROUTING CLIENT';

@injectable()
class RoutingEpicsBuilder extends BaseEpicsBuilder {
    private _tracingService: TracingService;

    constructor(@inject(ServiceTypes.TracingService) tracingService: TracingService) {
        super();
        this._tracingService = tracingService;
    }

    protected buildEpicList(): Epic[] {
        return [this.buildLocationChangeEpic()];
    }

    private buildLocationChangeEpic(): Epic {
        let prevPathname: string;
        return action$ =>
            action$.pipe(
                filter(action => action.type === LOCATION_CHANGE),
                mergeMap((a: PayloadAction<string, LocationChangePayload>) => {
                    const currentPathName = a?.payload?.location?.pathname;

                    if (prevPathname !== currentPathName) {
                        const tracer = this._tracingService.getTracer();

                        const span = tracer.startSpan(routingSpanName, {
                            attributes: {
                                [routingAttributes.ROUTE_PREV_PATHNAME]: prevPathname,
                                [routingAttributes.ROUTE_CURRENT_PATHNAME]: currentPathName,
                            },
                        });
                        applyLocationAttrs(span);
                        this._tracingService.endSpanOk(span);

                        prevPathname = currentPathName;
                    }

                    return of();
                })
            );
    }
}

export {RoutingEpicsBuilder};
