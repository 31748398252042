import {Observable, of} from 'rxjs';
import {defaultIfEmpty} from 'rxjs/operators';

/**
 * @deprecated should use services from src/common/services
 */
export abstract class BaseService {
    protected defaultObservable(): Observable<unknown> {
        return of().pipe(defaultIfEmpty(null));
    }
}
