import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {ReferralSettings, ReferralSettingsInput} from '@models/generated/graphql';
import {mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {BaseEpicsBuilder} from '@redux';
import {entityActions, entityReservedId, EntityType, ReferralSettingsNormalized} from '@redux/entity';
import {IReferralSettingsUpdateService} from '@services/referralSettingsService';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

import {successMessages} from '../app/intl/shared-resources/serverResponse';
import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {referralSettingsActions} from './actions';

const localized = defineMessages({
    updateSettingsFailure: {
        id: 'ReferralSettingsEpicsBuilder_updateSettingsFailure',
        defaultMessage: 'Failed to update referral settings',
    },
});

@injectable()
export class ReferralSettingsEpicsBuilder extends BaseEpicsBuilder {
    private readonly _referralSettingsService: IReferralSettingsUpdateService;

    constructor(@inject(ServiceTypes.ReferralSettingsService) referralSettingsService: IReferralSettingsUpdateService) {
        super();
        this._referralSettingsService = referralSettingsService;
    }

    protected buildEpicList(): RootEpic[] {
        return [
            this.buildEditReferralSettingsRevenueShareRequestEpic(),
            this.buildEditReferralSettingsExpirationPeriodRequestEpic(),
            this.buildEditReferralSettingsSuccessEpic(),
            this.buildEditReferralSettingsFailureEpic(),
        ];
    }

    private buildEditReferralSettingsRevenueShareRequestEpic(): RootEpic {
        return this.buildRequestEpic<ReferralSettingsInput, GqlMutationRequest, ReferralSettings>(
            referralSettingsActions.editReferralSettingsRevenueShare,
            payload => {
                return this._referralSettingsService.editRevenueShare(payload);
            }
        );
    }

    private buildEditReferralSettingsExpirationPeriodRequestEpic(): RootEpic {
        return this.buildRequestEpic<ReferralSettingsInput, GqlMutationRequest, ReferralSettings>(
            referralSettingsActions.editReferralSettingsExpirationPeriod,
            payload => {
                return this._referralSettingsService.editExpirationPeriod(payload);
            }
        );
    }

    private buildEditReferralSettingsSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(
                    isActionOf([
                        referralSettingsActions.editReferralSettingsRevenueShare.success,
                        referralSettingsActions.editReferralSettingsExpirationPeriod.success,
                    ])
                ),
                mergeMap((res: PayloadAction<string, ServiceResponsePayload<GqlMutationRequest, ReferralSettings>>) => {
                    const updatedItem: ReferralSettingsNormalized = {
                        expiration_period: res?.payload?.responsePayload?.expiration_period,
                        revenue_share: res?.payload?.responsePayload?.revenue_share,
                    };
                    return of(
                        showMessageAction({message: successMessages.operationSuccessfullyCompleted}),
                        entityActions.updateItem({
                            type: EntityType.ReferralSettings,
                            id: entityReservedId,
                            updatedItem,
                        })
                    );
                })
            );
    }

    private buildEditReferralSettingsFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(
                    isActionOf([
                        referralSettingsActions.editReferralSettingsRevenueShare.failure,
                        referralSettingsActions.editReferralSettingsExpirationPeriod.failure,
                    ])
                ),
                map(() => showErrorAction({message: localized.updateSettingsFailure}))
            );
    }
}
