import React from 'react';
import {Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useClasses = makeStyles()(theme => ({
    toggleGroup: {
        paddingBottom: theme.spacing(1),
    },
    title: {
        color: theme.palette.secondary.main,
    },
}));

type ToggleGroupProps = {
    title: string;
    children: React.ReactNode | React.ReactNodeArray;
};

export function ToggleGroup({title, children}: ToggleGroupProps) {
    const {classes} = useClasses();

    return title ? (
        <div className={classes.toggleGroup}>
            <div className={classes.title}>
                <Typography variant="subtitle2">{title}</Typography>
            </div>
            <div>{children}</div>
        </div>
    ) : (
        <div>{children}</div>
    );
}
