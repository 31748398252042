import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';

import {withEmptyCheck} from '@components/label';
import {MultiLineAutoAdjustedWidthTooltip} from '@components/tooltip/MultiLineAutoAdjustedWidthTooltip';

type LocalizedTextArrayCellProps = {
    value: MessageDescriptor[];
};

function LocalizedTextArrayCellInner({value}: LocalizedTextArrayCellProps) {
    const {formatMessage} = useIntl();
    const localizedMessages = value?.map(m => formatMessage(m));
    return (
        <MultiLineAutoAdjustedWidthTooltip>
            <>{localizedMessages?.join(', ')}</>
        </MultiLineAutoAdjustedWidthTooltip>
    );
}

const LocalizedTextArrayCell = withEmptyCheck(LocalizedTextArrayCellInner);

export function renderLocalizedTextArray(messages: MessageDescriptor[]) {
    return <LocalizedTextArrayCell value={messages} />;
}
