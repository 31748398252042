import React from 'react';
import {MessageDescriptor} from 'react-intl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

//TODO: [BO-2681] Move files to src/common/components/accordion
interface StyledAccordionProps {
    header: string | MessageDescriptor | React.ReactNode;
    componentId: string;
    children?: React.ReactNode;
    isCollapsed?: boolean;
    unmountOnCollapse?: boolean;
}

const useStyles = makeStyles()((theme: CustomTheme) => ({
    tableContainer: {
        boxShadow: 'none',
    },
    tableCellBottom: {
        border: 'none',
    },
    accordionDetails: {
        padding: 0,
    },
    accordionHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));

const StyledAccordion = ({
    header,
    componentId,
    children,
    unmountOnCollapse,
    className,
    isCollapsed = false,
}: StyledAccordionProps & React.HTMLAttributes<HTMLElement>) => {
    const {classes} = useStyles();
    const [expanded, setExpanded] = React.useState(!isCollapsed);

    const handleExpand = (_event: React.ChangeEvent<{}>, expanded: boolean) => {
        setExpanded(expanded);
    };

    return (
        <>
            <Accordion
                className={className}
                TransitionProps={{unmountOnExit: unmountOnCollapse}}
                expanded={expanded}
                onChange={handleExpand}
            >
                <AccordionSummary id={componentId} expandIcon={<ExpandMoreIcon />} aria-controls={`${componentId}-content`}>
                    <Typography variant="h6" className={classes.accordionHeader}>
                        {header ? (
                            React.isValidElement(header) ? (
                                header
                            ) : (
                                <LocalizedText label={header as string | MessageDescriptor} />
                            )
                        ) : null}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
            </Accordion>
        </>
    );
};

export default StyledAccordion;
