import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledAccordion from '@components/StyledAccordion';

import {UserReferralInfo} from 'src/features/block-player-referral-details';
import {BackpackInfo} from '../../block-backpack';
import {UserRefereeRevenueInfo} from '../../block-player-referral-list/components/UserRefereeRevenueInfo';

import {CashBonuses} from './CashBonuses';
import {MarketingInfo} from './MarketingInfo';

const localized = defineMessages({
    cashBonuses: {
        id: 'UD_marketingInfo',
        defaultMessage: 'Marketing Info',
    },
});

const useClasses = makeStyles()(() => ({
    userDetailsMarketingInfoContainer: {
        width: '100%',
    },
}));

type UserDetailsMarketingInfoProps = {
    userId: string;
};

export const UserDetailsMarketingInfo = ({userId}: UserDetailsMarketingInfoProps) => {
    const {classes} = useClasses();

    return (
        <StyledAccordion
            header={localized.cashBonuses}
            componentId="marketingInfo"
            isCollapsed
            unmountOnCollapse
            children={
                <Box className={classes.userDetailsMarketingInfoContainer}>
                    <MarketingInfo userId={userId} />
                    <CashBonuses userId={userId} />
                    <BackpackInfo userId={userId} />
                    <UserReferralInfo userId={userId} />
                    <UserRefereeRevenueInfo userId={userId} />
                </Box>
            }
        />
    );
};
