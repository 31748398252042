import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {interval, merge, of} from 'rxjs';
import {delay, filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {BaseEpicsBuilder} from '@redux';
import {EntityType} from '@redux/entity';
import {viewActions} from '@redux/view';
import {IPageViewService} from '@services/pageViewService';
import {getUTCTimestamp} from '@utils';

import {pageViewActions} from './actions';

@injectable()
export class PageViewEpicsBuilder extends BaseEpicsBuilder {
    private readonly _pageViewService: IPageViewService;
    constructor(@inject(ServiceTypes.PageViewService) pageViewService: IPageViewService) {
        super();
        this._pageViewService = pageViewService;
    }
    protected buildEpicList(): Epic[] {
        return [this.buildPollingEpic(), this.buildPingingEpic()];
    }

    private buildPollingEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(pageViewActions.startPolling)),
                mergeMap(action => {
                    const {interval: period, viewType} = action.payload;
                    const updateAction = viewActions.update({views: [viewType], entity: EntityType.PageView});
                    return interval(period).pipe(
                        map(() => updateAction),
                        takeUntil(action$.pipe(filter(isActionOf(pageViewActions.stopPolling))))
                    );
                })
            );
    }

    private buildPingingEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(pageViewActions.startPinging)),
                mergeMap(action => {
                    const {interval: period, pageIdDescriptor, viewType} = action.payload;
                    const update = () =>
                        this._pageViewService
                            .upsertPageView({
                                pageIdDescriptor,
                                lastOpenedAtSeconds: getUTCTimestamp().seconds,
                            })
                            .pipe(
                                //delay was added to make sure that page view already exists
                                //(in some cases it was not available after the upsert request)
                                delay(300),
                                mergeMap(() => of(viewActions.update({views: [viewType], entity: EntityType.PageView})))
                            );
                    return merge(
                        update(),
                        interval(period).pipe(mergeMap(update), takeUntil(action$.pipe(filter(isActionOf(pageViewActions.stopPinging)))))
                    );
                })
            );
    }
}
