import {Policy} from '@auth';

export interface IAccessRule<T = unknown> {
    validate(value: T): boolean;
}

export class PoliciesAccessRule implements IAccessRule<Policy[]> {
    private policies: string[];

    constructor(policies: Policy[]) {
        this.policies = policies?.map(policy => policy.toString()) ?? [];
    }

    validate(value: Policy[]): boolean {
        return this.policies.length === 0 || value?.some(policy => this.policies.includes(policy.toString()));
    }
}

export class RestrictedPoliciesAccessRule implements IAccessRule<Policy[]> {
    private policies: string[];

    constructor(policies: Policy[]) {
        this.policies = policies?.map(policy => policy.toString()) ?? [];
    }

    validate(value: Policy[]): boolean {
        return this.policies.length === 0 || !value?.some(policy => this.policies.includes(policy.toString()));
    }
}
