import {useAutoMapper} from '@auto-mapper';
import {GameBlindViewModel} from '@models/game-blind';
import {EntityType, GameBlind, GameBlindFilterKeys} from '@redux/entity';
import {UseListViewEntityProps, UseListViewEntityResult, useViewInit} from '@redux/view';

export function useGameBlinds({
    viewType,
    displayName,
    cleanDelay = 0,
    realtimeMode,
    triggers,
    defaultFilters,
    defaultPaging,
    defaultSorting,
    validateFilter,
    syncWithUrl = false,
}: UseListViewEntityProps<GameBlindFilterKeys, string>): UseListViewEntityResult<GameBlindViewModel, string> {
    const mapper = useAutoMapper();

    const {
        items,
        totalCount,
        searchFilter,
        viewEntity: {filter: filterString},
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useViewInit<GameBlind, GameBlindFilterKeys, string>({
        viewType,
        displayName,
        entity: {
            entity: EntityType.GameBlind,
            fields: null,
        },
        realtime: realtimeMode ? {entity: EntityType.GameBlind, mode: realtimeMode, triggers} : null,
        defaultFilters,
        defaultSorting,
        defaultPaging,
        syncWithUrl,
        validateFilter,
        cleanDelay,
    });

    const blinds = items?.map(i => mapper.map(i, GameBlind, GameBlindViewModel))?.sort((a, b) => a.sb - b.sb);

    return {
        items: blinds,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    };
}
