import {produce} from 'immer';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {AjaxResponse} from '@services/rest-api';

import {globalConfig} from '../../../configuration';
import {IModuleItem} from '../../../features/module-shared/types';
import {ItemsPage, SearchFilter} from '../../types';

import {ApiService} from './apiService';

/**
 * @deprecated should use services from src/common/services
 */
export class AuthApiService extends ApiService {
    constructor(
        endpoint: string,
        tracingService: ITracingService,
        userManager: UserManagerExtended,
        additionalEndpoint?: string,
        additionalEndpointParam?: string
    ) {
        super(
            `${globalConfig.getConfig().authApiUrl}/api`,
            endpoint,
            tracingService,
            userManager,
            additionalEndpoint,
            additionalEndpointParam
        );
    }

    addItem<TModel extends IModuleItem>(item: TModel): Observable<string> {
        return this.post('', item).pipe<string>(map<AjaxResponse, string>(response => response.response?.id));
    }

    editItem<TModel extends IModuleItem>(item: TModel): Observable<string> {
        return this.put(`/${item.id}`, item).pipe<string>(map<AjaxResponse, string>(response => response.response?.id));
    }

    deleteItem(id: string): Observable<AjaxResponse> {
        return this.delete(`/${id}`, {});
    }

    getUpdatedFilter(filter?: SearchFilter): SearchFilter {
        let updatedFilter = filter ?? ({} as SearchFilter);
        if (this.additionalEndpoint) {
            updatedFilter = produce(updatedFilter, f => {
                f.extend = [...(f.extend ?? []), this.additionalEndpoint];
            });
        }
        return updatedFilter;
    }

    getItem<TModel>(id: string, filter?: SearchFilter): Observable<TModel> {
        return super.getItem(id, this.getUpdatedFilter(filter));
    }

    getItemsPage<TModel>(filter?: SearchFilter): Observable<ItemsPage<TModel>> {
        return super.getItemsPage(this.getUpdatedFilter(filter));
    }

    assignItems<TModel extends IModuleItem>(id: string, items: TModel[]): Observable<AjaxResponse> {
        return items === undefined || items.length === 0
            ? of(undefined)
            : items.length === 1
            ? this.put(`/${id}/${this.additionalEndpoint}/${items[0].id}`, {})
            : this.put(`/${id}/${this.additionalEndpoint}`, {[this.additionalEndpointParam]: items.map(i => i.id)});
    }

    unAssignItems<TModel extends IModuleItem>(id: string, items: TModel[]): Observable<AjaxResponse> {
        return items === undefined || items.length === 0
            ? of(undefined)
            : items.length === 1
            ? this.delete(`/${id}/${this.additionalEndpoint}/${items[0].id}`, {})
            : this.delete(`/${id}/${this.additionalEndpoint}`, {[this.additionalEndpointParam]: items.map(i => i.id)});
    }

    protected toSearchQuery(filter: SearchFilter): string {
        let result = '';

        if (filter) {
            const filters =
                filter.filter && filter.filter.length !== 0
                    ? `filter=${encodeURIComponent(filter.filter.map(f => `${f.key}=${f.value}`).join())}`
                    : '';

            const sort =
                filter.sorting && filter.sorting.length !== 0
                    ? `sort=${filter.sorting.map(s => s.field).join()}&order=${filter.sorting.map(s => s.sort).join()}`
                    : '';

            let paging = '';
            if (filter.paging) {
                const page = filter.paging?.page - 1;
                const size = filter.paging?.pageSize;
                paging = size ? `page=${page}&size=${size}` : '';
            }

            let extend = '';
            if (filter.extend?.length) {
                extend = filter.extend.map(f => `extend=${f}`).join('&');
            }

            result = [filters, paging, sort, extend].filter(i => i).join('&');
        }

        return result;
    }

    protected getItemsTotalCount(response: AjaxResponse): number {
        return response.response?.count;
    }
}
