import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

//TODO: [BO-2668] Move to src/common/components/dropdown (rename or merge?)
//TODO: [BO-2669] Move dropdown components to input folder (?)
export const useSelectMenuItemCreatorClasses = makeStyles()((theme: CustomTheme) => ({
    selectItemStartIcon: {
        justifyContent: 'center',
        minWidth: theme.spacing(3),
    },
}));
