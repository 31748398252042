import React from 'react';
import {defineMessages} from 'react-intl';

import Button from '@components/button/Buttons';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {useRedeemRevenueShare} from '../hooks';
import {redeemRevenueSharePermissions} from '../permissions';

const localized = defineMessages({
    label: {
        id: 'RedeemRevenueShareButton_label',
        defaultMessage: 'Redeem',
    },
});

type RedeemRevenueShareButtonProps = {
    uid: string;
    amount: number;
};

function RedeemRevenueShareButtonInner({uid, amount}: RedeemRevenueShareButtonProps) {
    const {isProgress, handleRedeem} = useRedeemRevenueShare({uid, amount});

    return <Button label={localized.label} onClick={handleRedeem} disabled={isProgress || amount <= 0} />;
}

export const RedeemRevenueShareButton = withMultiplePermission(RedeemRevenueShareButtonInner, {
    allowedPermissions: redeemRevenueSharePermissions,
});
