import React from 'react';
import {defineMessages} from 'react-intl';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons/types';
import {CustomTheme} from '@style';

export const localized = defineMessages({
    displayBooleanTruthy: {
        id: 'displayBooleanTruthy',
        defaultMessage: 'Yes',
    },
    displayBooleanFalsy: {
        id: 'displayBooleanFalsy',
        defaultMessage: 'No',
    },
});

export type DisplayBooleanProps = {
    value: any;
};

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    displayBoolean: {
        display: 'flex',
        alignItems: 'center',
    },
    displayBooleanIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.spacing(2),
        height: theme.spacing(2),
        borderRadius: '50%',
        '&.truthy': {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText,
        },
        '&.falsy': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,
        },
    },
    displayBooleanLabel: {
        marginLeft: theme.spacing(1.25),
        '&.truthy': {
            color: theme.palette.success.main,
        },
        '&.falsy': {
            color: theme.palette.error.main,
        },
    },
}));

export const DisplayBoolean = ({value}: DisplayBooleanProps) => {
    const {classes} = useClasses();
    const icon = value ? CustomIcon.Confirm : CustomIcon.CancelOutline;
    const label = value ? localized.displayBooleanTruthy : localized.displayBooleanFalsy;
    const type = value ? 'truthy' : 'falsy';

    return (
        <Box className={classes.displayBoolean}>
            <Box className={`${classes.displayBooleanIcon} ${type}`}>
                <Box component="span" className={icon} fontSize={8} />
            </Box>
            <Typography variant="body1" component="span" className={`${classes.displayBooleanLabel} ${type}`}>
                <LocalizedText label={label} />
            </Typography>
        </Box>
    );
};
