import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {mergeMap} from '@otel';
import {baseFilterKeys, Country, State} from '@redux/entity';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {HttpStatusCode, ServerResponseStatus, ServiceResponsePayload} from '@services/types';
import type {IBrandService} from '@brand';

import {RestService} from './restService';

export const countryFilterKey = 'country' as const;

export const countriesStateFilterKeys = [...baseFilterKeys, countryFilterKey];
export type CountriesApiFilterKeys = (typeof countriesStateFilterKeys)[number];

@injectable()
export class CountriesApiService {
    private readonly _httpService: RestService;
    private readonly _apiRouteService: ApiRoutesService;
    private readonly _brandService: IBrandService;

    constructor(
        @inject(ServiceTypes.RestFetchApiService) httpService: RestService,
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService,
        @inject(ServiceTypes.BrandService) brandService: IBrandService
    ) {
        this._httpService = httpService;
        this._apiRouteService = apiRouteService;
        this._brandService = brandService;
    }

    public getCountries(): Observable<ServiceResponsePayload<unknown, Country[]>> {
        const endpoint = this._apiRouteService?.getCountriesEndpoint();
        return this._httpService.get({endpoint}).pipe(
            mergeMap(response => {
                let res: Observable<ServiceResponsePayload<unknown, Country[]>> = of(response);
                if (response?.status === ServerResponseStatus.Success) {
                    const allCountries: Country[] = response.responsePayload as Country[];
                    res = this._brandService.getBrands().pipe(
                        map(brandConfig => {
                            const countries: Country[] =
                                brandConfig.countries.mode === 'include'
                                    ? allCountries.filter(c => brandConfig.countries.iso2codeList.includes(c.iso2Code))
                                    : allCountries.filter(c => !brandConfig.countries.iso2codeList.includes(c.iso2Code));

                            return {
                                status: ServerResponseStatus.Success,
                                requestPayload: response.requestPayload,
                                responsePayload: countries,
                            };
                        })
                    );
                }

                return res;
            })
        );
    }

    public getStates(countryIsoCode: string): Observable<ServiceResponsePayload<unknown, State[]>> {
        if (countryIsoCode && countryIsoCode !== 'undefined') {
            const endpoint = this._apiRouteService?.getStatesEndpoint(countryIsoCode);
            return this._httpService.get({endpoint}).pipe(
                map(response => {
                    const responseStatus =
                        response?.errors?.length === 1 && response.errors[0].code === HttpStatusCode.NotFound.toString()
                            ? ServerResponseStatus.Success
                            : response.status;

                    return {
                        ...response,
                        status: responseStatus,
                        responsePayload: response?.responsePayload?.subdivisions,
                    };
                })
            );
        }

        return of();
    }
}
