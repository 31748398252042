import FileSaver from 'file-saver';
import {injectable} from 'inversify';

import {FileDownloadPayload} from '../actions';

@injectable()
export class FileDownloadService {
    public download({file, title, type}: FileDownloadPayload): void {
        FileSaver.saveAs(file, type ? `${title}.${type}` : title);
    }
}
