import React, {memo, useRef} from 'react';
import {MessageDescriptor} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';
import {RootState} from 'typesafe-actions';

import {Button} from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';

import {useJurisdictionConfig} from '../../../../features/app/config/hooks';
import {GridModalActionProps, GridModalProps, withGridModal} from '../../../../features/data-grids/components/GridModalHoc';
import data from '../../components/data';
import LegalDocumentView from '../../components/LegalDocumentView';
import {domain as legalDomain, LegalDocType, LegalDocumentRead} from '../../types';
import {getLegalDocId} from '../../utils';
import actions from '../actions';
import {LegalDocumentHistoryItem} from '../types';

import {renderLegalVersionHistoryAction} from './LegalVersionHistoryAction';

const LegalVersionViewDocumentAction = ({options, onClick}: GridModalActionProps): JSX.Element => {
    const {document, documentType} = options;

    const dispatch = useDispatch();
    const {jurisdiction} = useJurisdictionConfig();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const contentId = getLegalDocId(jurisdiction, documentType, document.version);

        dispatch(actions.itemLoad.request(contentId));
        onClick(e);
    };

    return !document ? null : <Button label={data.localized.viewLabel} onClick={handleClick} />;
};

const LegalVersionViewDocumentModal = ({options}: GridModalProps<any, any, LegalVersionViewDocumentOptions>): JSX.Element => {
    const {documentName, document} = options;
    const modalId = useRef('versionHistoryViewModal');

    const legalDocument: LegalDocumentRead = useSelector((s: RootState) => s.legalHistory.data.item) as LegalDocumentRead;

    const documentWithContent = {
        id: document.id,
        version: document.version,
        actionTime: document.created_at,
        author: document.user_id,
        authorEmail: document.userEmail,
        document: legalDocument?.document,
        required: legalDocument?.required,
    } as LegalDocumentRead;

    return (
        <Box id={modalId.current}>
            {documentWithContent?.document && (
                <LegalDocumentView fullContent={true} document={documentWithContent} documentName={documentName} />
            )}
        </Box>
    );
};

type LegalVersionViewDocumentOptions = {
    domain: string;
    document: LegalDocumentHistoryItem;
    documentName: MessageDescriptor;
    documentType: string;
};

const LegalVersionViewDocument = memo(
    withGridModal<any, any, LegalVersionViewDocumentOptions>(LegalVersionViewDocumentAction, LegalVersionViewDocumentModal)
);

export const renderLegalVersionViewDocument = (domain: string, document: LegalDocumentHistoryItem) => {
    const documentType = legalDomain.privacyPolicy === domain ? LegalDocType.PrivacyPolicy : LegalDocType.TermsAndConditions;
    const documentName: MessageDescriptor =
        documentType === LegalDocType.PrivacyPolicy ? data.localized.privacyPolicy : data.localized.termsAndConditions;

    return (
        <LegalVersionViewDocument
            modalStyleParams={{maxWidth: 'lg'}}
            title={<LocalizedText label={data.localized.versionHistoryLabel} />}
            options={{domain, document, documentName, documentType}}
            footer={renderLegalVersionHistoryAction(domain, data.localized.backLabel, true)}
        />
    );
};
