import {BoRole} from './entityBoRole';

/**
 * @deprecated
 * <p>Should be removed. Use /src/common/models/bo-user</p>
 */
export class BoUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    lockoutEnd?: string;
    roles: BoRole[];
    playerId?: string;

    constructor() {
        this.roles = [];
    }
}
