import {constructUsing, createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {UserActivitySummaryQueryFields} from '@redux/entity/types/entityUserActivitySummary';
import {UserActivitySummaryServiceModel} from '@services/userActivitySummaryService';
import {IsNever} from '@utils';

import {UserActivitySummaryViewModel, UserActivitySummaryViewModelKeys} from './types';

export class UserActivitySummaryMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            UserActivitySummaryServiceModel,
            UserActivitySummaryViewModel,
            forMember(
                vm => vm.uid,
                mapFrom(m => m?.uid)
            ),
            forMember(
                vm => vm.average_bet,
                mapFrom(m => m?.average_bet)
            ),
            forMember(
                vm => vm.average_poker_buy_in,
                mapFrom(m => m?.average_poker_buy_in)
            ),
            forMember(
                vm => vm.casino_favorite_games,
                mapFrom(m => m?.casino_favorite_games)
            ),
            forMember(
                vm => vm.days_from_last_active,
                mapFrom(m => m?.days_from_last_active)
            ),
            forMember(
                vm => vm.last_played_game,
                mapFrom(m => m?.last_played_game)
            ),
            forMember(
                vm => vm.poker_favorite_games,
                mapFrom(m => m?.poker_favorite_games)
            ),
            forMember(
                vm => vm.skill_flag,
                mapFrom(m => m?.skill_flag)
            ),
            forMember(
                vm => vm.slot_favorite_games,
                mapFrom(m => m?.slot_favorite_games)
            )
        );
        createMap<UserActivitySummaryViewModelKeys, UserActivitySummaryQueryFields>(
            mapper,
            nameof<UserActivitySummaryViewModelKeys>(),
            nameof<UserActivitySummaryQueryFields>(),
            constructUsing(m => {
                type FieldsToMap = Exclude<UserActivitySummaryViewModelKeys, UserActivitySummaryQueryFields>;
                const mapper: Record<FieldsToMap, UserActivitySummaryQueryFields> = {
                    uid: null,
                };

                type MappedFields = Exclude<UserActivitySummaryViewModelKeys, UserActivitySummaryQueryFields | keyof typeof mapper>;
                const isNever: IsNever<MappedFields> = true;
                if (!isNever) {
                    throw new Error('Mapping should be defined for all values');
                }
                return mapper[m as FieldsToMap] === undefined ? m : mapper[m as FieldsToMap];
            })
        );
    }
}
