import React from 'react';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource, withPolicyAccessCheck} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueueTabs} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import {RoutingTabs, Tab} from '@components/tab/RoutingTabs';
import {AccountVerificationViewModelKeys} from '@models/account-verification';
import {AccountVerificationStatus} from '@models/generated/graphql';
import {ModuleName} from '@models/modules';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';

import {RouteUrl} from 'src/common/routeEnums';
import {KYCQueue} from 'src/features/block-account-verification';
import {BulkActionKey} from 'src/features/block-bulk-actions';
import {localized} from '../localized';
import {kycWorkspaceHeaderActionContainerId} from '../types';

function useColumns() {
    const kycFields: AccountVerificationViewModelKeys[] = [
        'id',
        'initiated_at.seconds',
        'duration',
        'uid',
        'email',
        'type',
        'account_verification_status',
        'reason_codes',
        'documents',
        'documents_object.id_card.uploaded_ts',
        'labels',
        'iso_alpha2_country_code',
    ];

    const playerFields: UserProfileViewModelKeys[] = ['platform'];

    return {
        kycFields: kycFields,
        playerFields,
    };
}

function PendingCases() {
    const {kycFields, playerFields} = useColumns();
    const actions: BulkActionKey[] = [
        BulkActionKey.AccountLock,
        BulkActionKey.WithdrawalLock,
        BulkActionKey.DepositLock,
        BulkActionKey.LobbyLock,
        BulkActionKey.CasinoLock,
        BulkActionKey.SportsbookLock,
        BulkActionKey.P2PTransferLock,
        BulkActionKey.KYCReject,
        BulkActionKey.NotesAndAttachments,
    ];
    return (
        <KYCQueue
            statuses={[
                AccountVerificationStatus.InitDepositKyc,
                AccountVerificationStatus.InitWithdrawalKyc,
                AccountVerificationStatus.InitLoginKyc,
                AccountVerificationStatus.InitPaymentKyc,
                AccountVerificationStatus.OnHoldDepositKyc,
                AccountVerificationStatus.OnHoldLoginKyc,
                AccountVerificationStatus.OnHoldWithdrawalKyc,
                AccountVerificationStatus.OnHoldPaymentKyc,
            ]}
            fields={kycFields}
            playerFields={playerFields}
            actions={actions}
            title={localized.pendingCases}
            filters={['text', 'date', 'pendingStatuses', 'duration', 'type', 'country', 'reasonCode', 'labels']}
            viewType={'KYCPendingCases'}
            realtimeMode={RealtimeUpdatesMode.Confirm}
            triggers={[{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Update}]}
            syncWithUrl
            displayPageViews
        />
    );
}

function HistoryCases() {
    const {kycFields, playerFields} = useColumns();
    const actions: BulkActionKey[] = [
        BulkActionKey.AccountLock,
        BulkActionKey.WithdrawalLock,
        BulkActionKey.DepositLock,
        BulkActionKey.LobbyLock,
        BulkActionKey.CasinoLock,
        BulkActionKey.SportsbookLock,
        BulkActionKey.P2PTransferLock,
        BulkActionKey.NotesAndAttachments,
    ];
    return (
        <KYCQueue
            statuses={[
                AccountVerificationStatus.Approved,
                AccountVerificationStatus.DeniedDepositKyc,
                AccountVerificationStatus.DeniedLoginKyc,
                AccountVerificationStatus.DeniedWithdrawalKyc,
                AccountVerificationStatus.DeniedPaymentKyc,
            ]}
            fields={kycFields}
            playerFields={playerFields}
            filters={['text', 'date', 'closedStatuses', 'type', 'country', 'reasonCode', 'labels']}
            actions={actions}
            title={localized.historyCases}
            viewType={'KYCHistoryCases'}
            realtimeMode={RealtimeUpdatesMode.Confirm}
            triggers={[{type: RealtimeMessageTrigger.Update}]}
            syncWithUrl
            displayPageViews
        />
    );
}

function KYCProcessing() {
    const tabs: Tab[] = [
        {
            component: PendingCases,
            title: localized.pendingCases,
            path: `${RouteUrl.KycProcessing}${RouteUrl.KycPendingCases}`,
            id: 'pendingCases',
        },
        {
            component: HistoryCases,
            title: localized.historyCases,
            path: `${RouteUrl.KycProcessing}${RouteUrl.KycHistoryCases}`,
            id: 'historyCases',
        },
    ];
    return (
        <>
            <LayoutQueueTabs
                header={
                    <PageHeader
                        header={localized.header}
                        actions={<div id={kycWorkspaceHeaderActionContainerId}></div>}
                        removeBottomSpacing
                    />
                }
                body={<RoutingTabs tabs={tabs} />}
            />
        </>
    );
}

export const kycProcessingResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'KYCProcessing',
    rules: new PoliciesAccessRule([new Policy(ModuleName.KycProcessing, null, 'read')]),
});

export default withTraceErrorBoundary(withPolicyAccessCheck(KYCProcessing, kycProcessingResource), ErrorModal);
