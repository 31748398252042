import {useAutoMapper} from '@auto-mapper';
import {EntityType} from '@redux/entity';
import {UserActivitySummaryFilterKeys, UserActivitySummaryQueryFields} from '@redux/entity/types/entityUserActivitySummary';
import {getNonEmptyValueValidator, UseDetailsViewEntityProps, UseDetailsViewEntityResult, useViewInit} from '@redux/view';
import {UserActivitySummaryServiceModel} from '@services/userActivitySummaryService';

import {Filter} from '../../common/types';

import {UserActivitySummaryViewModel, UserActivitySummaryViewModelKeys} from './types';

export function useUserActivitySummary({
    id,
    fields,
    viewType,
    displayName,
    defaultFilters,
    validateFilter,
    cleanDelay,
}: UseDetailsViewEntityProps<UserActivitySummaryFilterKeys, UserActivitySummaryViewModelKeys>): UseDetailsViewEntityResult<
    UserActivitySummaryViewModel,
    UserActivitySummaryViewModelKeys
> {
    const mapper = useAutoMapper();

    const {
        items,
        searchFilter,
        viewEntity: {filter: filterString},
        handleFilterChange,
    } = useViewInit<UserActivitySummaryServiceModel, UserActivitySummaryFilterKeys, UserActivitySummaryQueryFields>({
        viewType,
        entity: {
            entity: EntityType.UserActivitySummary,
            fields: mapFields(fields),
        },
        defaultFilters: getDefaultFilters(),
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: validateFilter ?? getNonEmptyValueValidator<UserActivitySummaryFilterKeys>('uid'),
        displayName,
        cleanDelay,
    });

    const item = mapper.map(items[0], UserActivitySummaryServiceModel, UserActivitySummaryViewModel);

    return {
        item,
        searchFilter,
        filterString,
        handleFilterChange,
    };

    function getDefaultFilters(): Filter<string, UserActivitySummaryFilterKeys>[] {
        const filters: Filter<string, UserActivitySummaryFilterKeys>[] = [...(defaultFilters ?? [])];
        if (id) {
            filters.push({key: 'uid', value: id});
        }
        return filters;
    }

    function mapField(field: UserActivitySummaryViewModelKeys): UserActivitySummaryQueryFields {
        return mapper.map<UserActivitySummaryViewModelKeys, UserActivitySummaryQueryFields>(
            field,
            nameof<UserActivitySummaryViewModelKeys>(),
            nameof<UserActivitySummaryQueryFields>()
        );
    }

    function mapFields(fields: UserActivitySummaryViewModelKeys[]): UserActivitySummaryQueryFields[] {
        return fields?.map(mapField);
    }
}
