import {createAsyncAction} from 'typesafe-actions';

import {BonusCodeDetails, MutationAddBonusCodeArgs} from '@models/generated/graphql';

import {columnConfigurationActions, contentModuleActions} from '../module-shared/actions';
import {IFailureResponse} from '../module-shared/types';
import {createEntityRealtimeActions} from '../realtime-updates/actions';

import {domain} from './types';

const addBeBonusAction = '/addBonusCode';
const addBeBonusSucceedAction = '/addBeBonusSucceed';
const addBeBonusFailedAction = '/addBeBonusFailed';

export const beBonusActions = {
    ...createEntityRealtimeActions(domain),
    ...contentModuleActions(domain),
    ...columnConfigurationActions(domain),

    addBonusCode: createAsyncAction(
        `${domain}${addBeBonusAction}`,
        `${domain}${addBeBonusSucceedAction}`,
        `${domain}${addBeBonusFailedAction}`
    )<MutationAddBonusCodeArgs, BonusCodeDetails, IFailureResponse>(),
};
