import {injectable} from 'inversify';

import {BulkItemStatus} from '@models/generated/graphql';

import {ApplyStrategyResponse, BulkActionKey, IBulkApplyStrategy} from '../../block-bulk-actions';
import {UserProfileGridItem} from '../../data-grids/types';
import {BulkActionAddLabelItemPayload, BulkActionRemoveLabelItemPayload} from '../types';

export type ApplyAddUserLabelRequest = {
    players: UserProfileGridItem[];
    labels: {
        id: string;
        name?: string;
    }[];
};

@injectable()
export class ApplyAddUserLabelStrategy implements IBulkApplyStrategy<ApplyAddUserLabelRequest> {
    process({players, labels}: ApplyAddUserLabelRequest): ApplyStrategyResponse {
        const items = players
            ?.map<BulkActionAddLabelItemPayload[]>(player => {
                const playerLabelIds = player?.labels?.map(i => i.id);
                const availableLabelIds = labels?.filter(label => label?.id?.length && !playerLabelIds?.includes(label.id));
                return availableLabelIds?.map(label => ({
                    actionKey: label?.id,
                    labelValue: label?.name,
                    value: label.id?.length ? {uid: player.uid, label_id: label?.id} : null,
                    itemId: player.serverId,
                    status: BulkItemStatus.Pending,
                }));
            })
            .flat()
            .filter(i => i.value);

        return {actionKey: BulkActionKey.AddUserLabel, items};
    }
}

export type ApplyRemoveUserLabelRequest = {
    players: UserProfileGridItem[];
    label: {
        id: string;
        name?: string;
    };
};

@injectable()
export class ApplyRemoveUserLabelStrategy implements IBulkApplyStrategy<ApplyRemoveUserLabelRequest> {
    process({players, label}: ApplyRemoveUserLabelRequest): ApplyStrategyResponse {
        const items = players
            .map<BulkActionRemoveLabelItemPayload>(player => {
                const isAvailableLabel = label?.id && player?.labels?.map(i => i.id).includes(label?.id);
                return {
                    actionKey: label?.id,
                    labelValue: label?.name,
                    value: isAvailableLabel ? {uid: player.uid, label_id: label?.id} : null,
                    itemId: player.serverId,
                    status: BulkItemStatus.Pending,
                };
            })
            .filter(i => i.value);

        return {actionKey: items?.length ? BulkActionKey.RemoveUserLabel : label?.id, items};
    }
}
