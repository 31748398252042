import React from 'react';
import {defineMessages} from 'react-intl';

import {InlineAutocomplete, TreeAutocomplete} from '@components/autocomplete';
import {AutocompleteOptionType, AutocompleteProps} from '@components/autocomplete/types';
import {AgentProfileFilterKeys} from '@redux/entity';
import {extendedViewCleanDelay, getNonEmptyValueValidator, ViewType} from '@redux/view';
import {maxPageSize} from '@services/types';

import {Filter} from 'src/common/types';
import {useAgentAutocompleteOptions, useAgentProfiles} from '../hooks';

const localized = defineMessages({
    agentAutocompletePlaceholder: {
        id: 'AgentAutocomplete_agentAutocompletePlaceholder',
        defaultMessage: 'Search agent',
    },
    agentAutocompleteEmptyPlaceholder: {
        id: 'AgentAutocomplete_agentAutocompleteEmptyPlaceholder',
        defaultMessage: 'Enter the agent name and select the downline type',
    },
});

type AgentAutocompleteProps = {
    viewType: ViewType;
    boAgentPlayerId: string;
    value: string;
    onChange: (value: string) => void;
    isTreeMode?: boolean;
    listContainerClassName?: string;
};

export function AgentAutocomplete({
    viewType: containerViewType,
    boAgentPlayerId,
    value,
    onChange,
    isTreeMode,
    listContainerClassName,
}: AgentAutocompleteProps) {
    const viewTypeInner: ViewType = 'AgentAutocomplete';
    const viewType: ViewType = `${containerViewType}.${viewTypeInner}` as ViewType;

    const {items: agentProfiles, handleFilterChange} = useAgentProfiles({
        viewType,
        fields: isTreeMode ? ['uid', 'username', 'referral.inviting_user_id'] : ['uid', 'username'],
        defaultFilters: isTreeMode
            ? [
                  {key: 'page', value: 0},
                  {key: 'size', value: maxPageSize},
              ]
            : getDefaultFilters(),
        validateFilter: isTreeMode ? treeModeValidateFilter : defaultValidateFilter,
        cleanDelay: extendedViewCleanDelay,
    });

    const {options} = useAgentAutocompleteOptions({
        agentProfiles,
        isTreeMode,
    });

    function getDefaultFilters(): Filter<string, AgentProfileFilterKeys>[] {
        const filters: Filter<string, AgentProfileFilterKeys>[] = [];
        if (boAgentPlayerId) {
            filters.push({key: 'referrer_agent_id', value: boAgentPlayerId});
        }
        if (value) {
            filters.push({key: 'uid', value: value});
        }
        return filters;
    }

    function treeModeValidateFilter(filter: Filter<any, AgentProfileFilterKeys>[]): boolean {
        return filter?.find(f => f.key === 'page')?.value === 0 && filter?.find(f => f.key === 'size')?.value === maxPageSize;
    }
    function defaultValidateFilter(filter: Filter<any, AgentProfileFilterKeys>[]): boolean {
        return (
            getNonEmptyValueValidator<AgentProfileFilterKeys>('uid')(filter) ||
            getNonEmptyValueValidator<AgentProfileFilterKeys>('username')(filter) ||
            getNonEmptyValueValidator<AgentProfileFilterKeys>('referrer_agent_id')(filter)
        );
    }

    function getSelectedValue() {
        const optionValue = value?.toString();
        return optionValue;
    }
    function handleAutocompleteFilterChange(value: string) {
        handleFilterChange([
            {key: 'uid', value: null},
            {key: 'username', value},
        ]);
    }
    const AutocompleteComponent: React.FC<AutocompleteProps<unknown>> = isTreeMode ? TreeAutocomplete : InlineAutocomplete;
    const selectedValue = getSelectedValue();

    return (
        <AutocompleteComponent
            value={selectedValue}
            options={options}
            onValueChange={(option: AutocompleteOptionType) => {
                onChange(option?.value ?? '');
            }}
            onFilterChange={handleAutocompleteFilterChange}
            placeholder={localized.agentAutocompletePlaceholder}
            emptyPlaceholder={localized.agentAutocompleteEmptyPlaceholder}
            allowEmptyValue
            listContainerClassName={listContainerClassName}
        />
    );
}
