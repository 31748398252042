import {combineReducers} from 'redux';

import {createRealtimeStateReducer} from '../realtime-updates/reducers';

import {domain} from './types';

const stateReducer = combineReducers(createRealtimeStateReducer(domain));

export const beBonusHistoryReducer = combineReducers({
    state: stateReducer,
});
