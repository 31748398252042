import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useEnrichedButtonStyles = makeStyles()((theme: CustomTheme) => ({
    enrichedButtonContainer: {
        height: theme.custom.enrichedButtonHeight,
        display: 'flex',
        padding: theme.spacing(2.5),
        border: `1px solid ${theme.custom.palette.content.liner}`,
        borderRadius: 8,
        alignItems: 'center',
        cursor: 'pointer',
    },
    enrichedButtonContainerSelected: {
        border: `1.5px solid ${theme.palette.primary.main}`,
    },
    enrichedButtonIconContainer: {
        marginRight: theme.spacing(2.5),
        color: theme.palette.secondary.main,
    },
    enrichedButtonIconContainerSelected: {
        color: theme.palette.primary.main,
    },
    enrichedButtonTitleDescriptionContainer: {},
    enrichedButtonTitleContainer: {
        marginBottom: theme.spacing(1),
    },
    enrichedButtonDescriptionContainer: {},
}));
