import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useModuleCreateActionClasses = makeStyles()((theme: CustomTheme) => ({
    moduleCreateActionButton: {
        borderRadius: 8,
        height: theme.custom.customButton.medium.height,
    },
}));
