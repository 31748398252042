import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useIntl} from 'react-intl';

import {TextFilter} from '@components/filter/TextFilter';

import {FilterInnerProps, SingleInputModel} from './FilterHoc';
import withFilter from './FilterHoc';

const TextInput = ({filter, value}: FilterInnerProps<string>) => {
    const {formatMessage} = useIntl();
    const {control, reset} = useFormContext<SingleInputModel>();
    const defaultValue: SingleInputModel = {inputValue: value};

    useEffect(() => {
        reset(defaultValue);
    }, [value]);

    return (
        <Controller
            render={({field}) => {
                return (
                    <TextFilter
                        value={value}
                        onChange={field.onChange}
                        onEnter={field.onChange}
                        width="full"
                        inputPlaceholder={
                            filter?.label ? (typeof filter.label === 'string' ? filter.label : formatMessage(filter.label)) : null
                        }
                    />
                );
            }}
            name="inputValue"
            defaultValue={defaultValue?.inputValue}
            control={control}
        />
    );
};

const FilterTextField = withFilter(TextInput);

export default FilterTextField;
