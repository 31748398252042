import {injectable} from 'inversify';

import {BulkItemStatus, KycStatusInput} from '@models/generated/graphql';

import {AccountVerificationGridItem} from 'src/pages/kyc-processing/types';
import {ApplyStrategyResponse, BulkActionItemPayload, BulkActionKey, IBulkApplyStrategy} from '../../block-bulk-actions';

export type ApplyKYCRejectRequest = {items: AccountVerificationGridItem[]};

@injectable()
export class ApplyKYCRejectStrategy implements IBulkApplyStrategy<ApplyKYCRejectRequest> {
    process({items}: ApplyKYCRejectRequest): ApplyStrategyResponse {
        const actionItems = items?.map<BulkActionItemPayload<KycStatusInput>>(item => ({
            actionKey: BulkActionKey.KYCReject,
            value: {id: item.id, status: item.account_verification_status},
            itemId: item.serverId,
            status: BulkItemStatus.Pending,
        }));

        return {actionKey: BulkActionKey.KYCReject, items: actionItems};
    }
}
