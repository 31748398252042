import React from 'react';
import {defineMessages} from 'react-intl';

import {defineAccessControlledResource, Resource, usePolicyAccessCheck} from '@access-control';
import Tabs from '@components/tab/Tabs';
import {Policy} from '@auth';

import {commentReadPolicyRule, Notes, NotesAttachments, NotesProps} from '../../block-note';

const localized = defineMessages({
    allNotes: {
        id: 'NotesAndAttachments_allNotes',
        defaultMessage: 'All Notes',
    },
    attachments: {
        id: 'NotesAndAttachments_attachments',
        defaultMessage: 'Attachments',
    },
    pinnedNotes: {
        id: 'NotesAndAttachments_pinnedNotes',
        defaultMessage: 'Pinned Notes',
    },
});

const resource: Resource<Policy[]> = defineAccessControlledResource({
    name: 'NotesAndAttachments',
    rules: commentReadPolicyRule,
});

type NotesAndAttachmentsProps = Omit<NotesProps, 'isPinned'>;

export function NotesAndAttachments(props: NotesAndAttachmentsProps) {
    const hasAccess = usePolicyAccessCheck(resource);

    const tabs = [
        {label: localized.allNotes, children: <Notes {...props} />},
        {label: localized.pinnedNotes, children: <Notes isPinned={true} {...props} />},
        {label: localized.attachments, children: <NotesAttachments {...props} />},
    ]?.filter(i => i !== null);

    return hasAccess ? <Tabs tabs={tabs} /> : null;
}
