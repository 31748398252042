import {AccountVerification, KycDocument} from '@models/generated/graphql';

export type AccountVerificationDocumentsMock = {
    id_card: KycDocument;
    proof_of_address: KycDocument;
    proof_of_payment: KycDocument;
};

export class AccountVerificationMock extends AccountVerification {
    documents_object?: AccountVerificationDocumentsMock;
}
