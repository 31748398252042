import {Policy} from '@auth';

import {IAccessRule} from './PoliciesAccessRule';

export type RestrictionMode = 'hide' | 'disable' | 'info-message';

export type Resource<T = unknown> = {
    name: string;
    rules: IAccessRule<T>;
};

export type PolicyRestrictedResource = Resource<Policy[]>;

export enum AccessControlIndicator {
    Hidden,
    Disabled,
    InfoMessage,
}
