import {createAsyncAction} from 'typesafe-actions';

import {ReferralSettings, ReferralSettingsInput} from '@models/generated/graphql';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

import {domain} from './types';

const referralSettingsRevenueShareEditAction = '/referralSettingsRevenueShareEditAction';
const referralSettingsRevenueShareEditSucceedAction = '/referralSettingsRevenueShareEditSucceedAction';
const referralSettingsRevenueShareEditFailedAction = '/referralSettingsRevenueShareEditFailedAction';

const referralSettingsExpirationPeriodEditAction = '/referralSettingsExpirationPeriodEditAction';
const referralSettingsExpirationPeriodEditSucceedAction = '/referralSettingsExpirationPeriodEditSucceedAction';
const referralSettingsExpirationPeriodEditFailedAction = '/referralSettingsExpirationPeriodEditFailedAction';

export const referralSettingsActions = {
    editReferralSettingsRevenueShare: createAsyncAction(
        `${domain}${referralSettingsRevenueShareEditAction}`,
        `${domain}${referralSettingsRevenueShareEditSucceedAction}`,
        `${domain}${referralSettingsRevenueShareEditFailedAction}`
    )<
        ReferralSettingsInput,
        ServiceResponsePayload<GqlMutationRequest, ReferralSettings>,
        ServiceResponsePayload<GqlMutationRequest, ReferralSettings>
    >(),

    editReferralSettingsExpirationPeriod: createAsyncAction(
        `${domain}${referralSettingsExpirationPeriodEditAction}`,
        `${domain}${referralSettingsExpirationPeriodEditSucceedAction}`,
        `${domain}${referralSettingsExpirationPeriodEditFailedAction}`
    )<
        ReferralSettingsInput,
        ServiceResponsePayload<GqlMutationRequest, ReferralSettings>,
        ServiceResponsePayload<GqlMutationRequest, ReferralSettings>
    >(),
};
