import {createAction} from 'typesafe-actions';

//TODO: [BO-2694] Move global actions to the specific features
const cancelRequestsAction = 'global/cancelRequests';

const terminateAllEpicsAction = '/terminateAllEpics';

/**
 * @deprecated
 * <p>Should be removed. Use buildRequestEpic method from {@link BaseEpicsBuilder}</p>
 */
export const cancelRequests = createAction(`${cancelRequestsAction}`)();

export const terminateAllEpics = createAction(terminateAllEpicsAction)<void>();
