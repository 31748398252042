import {constructUsing, createMap, forMember, mapFrom, Mapper, mapWith, mapWithArguments} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {GameRoom, GameRoomResponseListItem, GameRoomViewModelKeys} from '@models/game-room';
import {
    GameFeaturesViewModel,
    GameRoomFilterInput,
    GameRoomItemPage,
    GameRoomResponse,
    GameRoomViewModel,
    Player,
    PlayersInfoViewModel,
    PlayerViewModel,
    StakesViewModel,
} from '@models/game-room/types';
import {GameTemplateSettings} from '@models/game-template';
import {GameRoomFields, ManagedGameType} from '@redux/entity';
import {GameRoomsAdditionalData} from '@services/rest-api/plo5GameManagementApiService';

export type GameRoomMappingArgs = {
    additionalData: GameRoomsAdditionalData;
    gameType: ManagedGameType;
    filterInput: GameRoomFilterInput;
};

export type GameRoomViewModelMappingArgs = {
    currency: string;
};

export class GameRoomMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            GameRoomResponseListItem,
            GameRoom,
            forMember(
                d => d.roomId,
                mapFrom(s => s.RoomId)
            ),
            forMember(
                d => d.ownerId,
                mapFrom(s => s.CreatePlayerId)
            ),
            forMember(
                d => d.roomName,
                mapFrom(s => s.RoomName)
            ),
            forMember(
                d => d.playerCount,
                mapFrom(s => s.PlayerCount)
            ),
            forMember(
                d => d.players,
                mapFrom(s => s.Players ?? [])
            ),
            forMember(
                d => d.templateId,
                mapFrom(s => s.TemplateId)
            ),
            forMember(
                d => d.templateSettings,
                mapWithArguments(
                    (s, {additionalData}: GameRoomMappingArgs) => additionalData?.[s.TemplateId]?.responsePayload?.template?.param ?? null
                )
            ),
            forMember(
                d => d.blind,
                mapWithArguments((s, {additionalData}: GameRoomMappingArgs) => {
                    const templateSettings = additionalData?.[s.TemplateId]?.responsePayload?.template?.param;
                    const blinds = additionalData?.blinds?.responsePayload?.data?.blind_list;
                    return blinds?.find(b => b.id === templateSettings?.rule_blind_enum) ?? null;
                })
            ),
            forMember(
                d => d.gameType,
                mapWithArguments((_, {gameType}: GameRoomMappingArgs) => gameType)
            )
        );
        createMap(
            mapper,
            GameRoomResponse,
            GameRoomItemPage,
            forMember(
                d => d.items,
                mapWith(GameRoom, GameRoomResponseListItem, s => s?.data?.RoomList)
            ),
            forMember(
                d => d.total,
                mapWithArguments(
                    (s, {filterInput}: GameRoomMappingArgs) => filterInput.page_no * filterInput.per_page_count + (s?.data?.MatchCount ?? 0)
                )
            )
        );
        createMap(
            mapper,
            Player,
            PlayerViewModel,
            forMember(
                d => d.id,
                mapFrom(s => s.PlayerId?.toString())
            ),
            forMember(
                d => d.playerId,
                mapFrom(s => s.PlayerId?.toString())
            ),
            forMember(
                d => d.name,
                mapFrom(s => s.Name)
            )
        );
        createMap(
            mapper,
            GameRoom,
            PlayersInfoViewModel,
            forMember(
                d => d.playerCount,
                mapFrom(s => s.playerCount)
            ),
            forMember(
                d => d.playerMaxCount,
                mapFrom(s => s.templateSettings.player_count_max)
            ),
            forMember(
                d => d.players,
                mapWith(PlayerViewModel, Player, s => s.players)
            )
        );
        createMap(
            mapper,
            GameRoom,
            StakesViewModel,
            forMember(
                d => d.sb,
                mapFrom(s => s.blind?.sb / 100)
            ),
            forMember(
                d => d.bb,
                mapFrom(s => s.blind?.bb / 100)
            ),
            forMember(
                d => d.hasStraddle,
                mapFrom(s => !!s.templateSettings?.rule_switch_force_straddle)
            ),
            forMember(
                d => d.ante,
                mapFrom(s => s.templateSettings?.rule_ante_amount / 100)
            ),
            forMember(
                d => d.currency,
                mapWithArguments((_, {currency}: GameRoomViewModelMappingArgs) => currency)
            )
        );
        createMap(
            mapper,
            GameTemplateSettings,
            GameFeaturesViewModel,
            forMember(
                d => d.geo,
                mapFrom(s => s.is_ban_vpn)
            ),
            forMember(
                d => d.ip,
                mapFrom(s => !!s.rule_switch_anti_cheat)
            ),
            forMember(
                d => d.tot,
                mapFrom(s => s.auto_withdraw)
            ),
            forMember(
                d => d.sho,
                mapFrom(s => s.force_showcard)
            )
        );
        createMap(
            mapper,
            GameRoom,
            GameRoomViewModel,
            forMember(
                d => d.id,
                mapFrom(s => s.roomId?.toString())
            ),
            forMember(
                d => d.gameType,
                mapFrom(s => s.gameType)
            ),
            forMember(
                d => d.roomId,
                mapFrom(s => s.roomId)
            ),
            forMember(
                d => d.ownerId,
                mapFrom(s => s.ownerId)
            ),
            forMember(
                d => d.roomName,
                mapFrom(s => s.roomName)
            ),
            forMember(
                d => d.templateId,
                mapFrom(s => s.templateId)
            ),
            forMember(
                d => d.templateId,
                mapFrom(s => s.templateId)
            ),
            forMember(
                d => d.platforms,
                mapFrom(s => s?.templateSettings?.plats)
            ),
            forMember(
                d => d.players,
                mapWith(PlayersInfoViewModel, GameRoom, s => s)
            ),
            forMember(
                d => d.stakes,
                mapWith(StakesViewModel, GameRoom, s => s)
            ),
            forMember(
                d => d.features,
                mapWith(GameFeaturesViewModel, GameTemplateSettings, s => s.templateSettings)
            )
        );
        createMap<GameRoomViewModelKeys[], GameRoomFields[]>(
            mapper,
            nameof<GameRoomViewModelKeys>(),
            nameof<GameRoomFields>(),
            constructUsing((m, _) => {
                const mapper: Record<GameRoomViewModelKeys, GameRoomFields[]> = {
                    id: ['roomId'],
                    gameType: ['gameType'],
                    roomId: ['roomId'],
                    ownerId: ['ownerId'],
                    roomName: ['roomName'],
                    templateId: ['templateId'],
                    platforms: ['templateSettings'],
                    stakes: ['blind', 'templateSettings'],
                    players: ['players'],
                    features: ['templateSettings'],
                    close: ['roomId'],
                };

                return [...new Set(m.flatMap(i => mapper[i]))];
            })
        );
    }
}
