import {defineMessages} from 'react-intl';

export const localizedBulkAddBonusCodeButton = defineMessages({
    addBonusCodeLabel: {
        id: 'addBonusCodeLabel',
        defaultMessage: 'Associate Marketing Bonus Code',
    },
    addBulkDepositAmountLabel: {
        id: 'addBulkDepositAmountLabel',
        defaultMessage: 'Add Bulk Deposit Amount',
    },
    fieldBonusCodeLabel: {
        id: 'fieldBonusCodeLabel',
        defaultMessage: 'Marketing Bonus Code',
    },
    fieldBonusCodePlaceholder: {
        id: 'fieldBonusCodePlaceholder',
        defaultMessage: 'Optional',
    },
    fieldAmountLabel: {
        id: 'fieldAmountLabel',
        defaultMessage: 'Deposit Amount',
    },
    fieldAmountPlaceholder: {
        id: 'fieldAmountPlaceholder',
        defaultMessage: '0',
    },
    fieldAmountDescription: {
        id: 'fieldAmountDescription',
        defaultMessage: 'Deposit amount you want to simulate in order to trigger the bonus',
    },
    fieldBonusCodeDescription: {
        id: 'fieldBonusCodeDescription',
        defaultMessage: 'You can associate a marketing bonus code.',
    },
    cancelLabel: {
        id: 'cancelLabel',
        defaultMessage: 'Cancel',
    },
    confirmLabel: {
        id: 'confirmLabel',
        defaultMessage: 'Confirm',
    },
});
