import {gql, NormalizedCacheObject} from '@apollo/client';

import {AccountVerificationStatus} from '@models/generated/graphql';
import {BaseService} from '@services/deprecated';
import {GraphQLServiceBaseObsolete} from '@services/deprecated';
import {ApolloClientProxy} from '@services/gql-api';

const initiateSecurityKYCMutation = gql`
    mutation InitSecurityKYC($uid: String!, $status: AccountVerificationStatus!) {
        initSecurityKYC(uid: $uid, status: $status) {
            id
            uid
            username
            initiated_at {
                seconds
            }
            type
            account_verification_status
            status_log {
                kyc_status
                account_verification_status
                logged_at {
                    seconds
                }
            }
            documents {
                did
                doc_type
                status
                uploaded_ts {
                    seconds
                }
                location {
                    country
                }
                number
            }
        }
    }
`;

export class VerificationActionsUpdateService extends BaseService {
    protected _client: GraphQLServiceBaseObsolete;

    constructor(client: ApolloClientProxy<NormalizedCacheObject>) {
        super();
        this._client = new GraphQLServiceBaseObsolete(client);
    }

    setVerificationStatus(uid: string, status: AccountVerificationStatus) {
        return this._client.executeMutation(initiateSecurityKYCMutation, {uid, status});
    }
}
