import {DateRangeFilterValue} from '@components/filter';
import {MultiKeyFilterTextValue} from '@components/filter/types';
import {CasinoAggregator, CasinoSourceType} from '@models/casino-game';
import {
    BonusCasinoSourceType,
    BonusCodeDetails,
    BonusCreditType,
    BonusMeta,
    BonusReleaseMechanism,
    BonusReleaseMetric,
    BonusReleaseRatioType,
    BonusStatus,
    BonusToCredit,
    Platform,
} from '@models/generated/graphql';
import {Timestamp, TimestampKeys} from '@models/shared';
import {UserProfileViewModel} from '@models/user-profile/types';
import {INormalized} from '@redux/entity';

export const domain = 'bonus-engine';
export class BonusReleaseRatioViewModel {
    category: number;
    layer_metric_amount: number;
    layer_metric_qualify: number;
    level: number;
}

export class BonusCasinoViewModel {
    aggregators?: string[];
    game_codes?: string[];
    source_types?: BonusCasinoSourceType[];
    supplier_codes?: string[];
}

export class BonusMetaViewModel {
    bonus_value_param?: number;
    casino_excluded?: BonusCasinoViewModel;
    casino_included?: BonusCasinoViewModel;
    expire_period?: number;
    max_bonus_get?: number;
    metric_amount?: number;
    metric_qualify?: number;
    min_deposit?: number;
    overall_max_bonus_get?: number;
    rake_amount?: number;
    rake_qualify?: number;
    release_ratios?: BonusReleaseRatioViewModel[];
    show_max_bonus_in_total?: number;
    ticket_serial?: string;
    time_range_by_date?: number;
    total_max_bonus_get?: number;
    unrealized_expire_period?: number;
}

export class BonusCodeDetailsViewModel {
    id: string;
    bonus_id: string;
    bonus_name: string;
    bonus_status: BonusStatus;
    bonus_to_credit: BonusToCredit;
    create_time: Timestamp;
    credit_type: BonusCreditType;
    description?: string;
    end_time: Timestamp;
    last_update: Timestamp;
    marketing_codes?: string[];
    meta: BonusMetaViewModel;
    platform: Platform;
    release_mechanism: BonusReleaseMechanism;
    release_metric: BonusReleaseMetric;
    release_ratio_type: BonusReleaseRatioType;
    start_time: Timestamp;
    threshold?: number;
    total_paid_out_amount?: number;
}

const metaKey: keyof BonusCodeDetailsViewModel = 'meta';
const createTimeKey: keyof BonusCodeDetailsViewModel = 'create_time';
const secondsKey: TimestampKeys = 'seconds';
export type BonusCodeDetailsViewModelKeys =
    | keyof Omit<BonusCodeDetailsViewModel, 'meta' | 'create_time'>
    | `${typeof metaKey}.${keyof Omit<BonusMeta, '__typename'>}`
    | `${typeof createTimeKey}.${typeof secondsKey}`;

export class CasinoSupplierViewModel {
    name?: string;
    isActive?: boolean;
    createdAt?: Timestamp;
    updatedAt?: Timestamp;
    code?: string;
}

export class CasinoGameViewModel {
    code?: string;
    title?: string;
    isActive?: boolean;
    createdAt?: Timestamp;
    updatedAt?: Timestamp;
    aggregator?: CasinoAggregator;
    supplier?: string;
}

class CasinoItemsViewModel<TValue = string> {
    include: TValue[];
    exclude: TValue[];
}

export class DepositCashBonusFormViewModel {
    bonus_name: string;
    description: string;
    release_metric: BonusReleaseMetric;
    min_deposit: string;
    bonus_value_param: string;
    maximum_bonus: string;
    metric_qualify: string;
    metric_amount: string;
    expire_period: string;
    source_types?: CasinoItemsViewModel<CasinoSourceType>;
    aggregators?: CasinoItemsViewModel;
    suppliers?: CasinoItemsViewModel;
    games?: CasinoItemsViewModel;
}

export type BonusCodeFilterName = 'id_name' | 'credit_type' | 'bonus_status' | 'create_time';
export type BonusCodeFilterKeys = 'text' | 'credit_type' | 'bonus_status' | 'create_time';
export type BonusCodeTextFilterKeys = 'id_name' | 'id' | 'name';
export type BonusCodeTextFilterValue = MultiKeyFilterTextValue<BonusCodeTextFilterKeys>;

export class BonusCodeFilterModel implements Record<BonusCodeFilterKeys, unknown> {
    text: BonusCodeTextFilterValue;
    credit_type: BonusCreditType[];
    bonus_status: BonusStatus[];
    create_time: DateRangeFilterValue;
}

export type BonusTriggerViewModel = UserProfileViewModel & {
    id: string;
    bonus_id: string;
    bonus_name?: string;
    total_amount?: number;
    marketing_code?: string;
    ticket_index?: number;
    ticket_count?: number;
    triggered_tickets?: number;
    currency?: string;
};

export type BonusTriggerViewModelKeys = keyof BonusTriggerViewModel;

export type BonusTriggerSelectedItems = UserProfileViewModel & {
    bonuses: {
        id: string;
        name: string;
    }[];
};

export type BonusCodeNormalized = INormalized & BonusCodeDetails;

export class BonusCodeDownloadModel implements Record<BonusCodeDetailsViewModelKeys, string> {
    id: string;
    bonus_id: string;
    bonus_name: string;
    bonus_status: string;
    bonus_to_credit: string;
    'create_time.seconds': string;
    credit_type: string;
    description: string;
    end_time: string;
    last_update: string;
    marketing_codes: string;
    'meta.bonus_value_param': string;
    'meta.casino_excluded': string;
    'meta.casino_included': string;
    'meta.expire_period': string;
    'meta.max_bonus_get': string;
    'meta.metric_amount': string;
    'meta.metric_qualify': string;
    'meta.min_deposit': string;
    'meta.overall_max_bonus_get': string;
    'meta.rake_amount': string;
    'meta.rake_qualify': string;
    'meta.release_ratios': string;
    'meta.show_max_bonus_in_total': string;
    'meta.ticket_serial': string;
    'meta.time_range_by_date': string;
    'meta.total_max_bonus_get': string;
    'meta.unrealized_expire_period': string;
    platform: string;
    release_mechanism: string;
    release_metric: string;
    release_ratio_type: string;
    start_time: string;
    threshold: string;
    total_paid_out_amount: string;
}
