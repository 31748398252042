import {gql, NormalizedCacheObject} from '@apollo/client';
import {Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {BotGroup, BotGroupFilter, Query, SecurityCase, SecurityCaseType, TextFilter} from '@models/generated/graphql';
import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {BaseHttpService} from '@services/deprecated';
import {GQLBasedService} from '@services/deprecated';
import {PlayerManagementApiService} from '@services/deprecated';
import {IGQlFilterVariables} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';
import {AjaxResponse} from '@services/rest-api';
import {isStringNullOrEmpty} from '@utils';

import {SearchFilter} from 'src/common/types';

import {EditSecurityCasesSubmitModel, ISecurityCaseGqlQueryVariables, SecurityCaseFilter} from './types';

export const getSecurityCasesQuery = gql`
    query GetSecurityCases($type: SecurityCaseType!, $case_id: String, $limit: Int) {
        getSecurityCases(type: $type, case_id: $case_id, limit: $limit) {
            type
            case_id
            opened_at {
                seconds
            }
            closed_at {
                seconds
            }
        }
    }
`;

export const getBotGroupsQuery = gql`
    query GetBotGroups($filter: BotGroupFilter, $sort: Sorting, $start: Int, $end: Int) {
        getBotGroups(filter: $filter, sort: $sort, end: $end, start: $start) {
            items {
                group_id
                uids
            }
        }
    }
`;

export class SecurityCasesService extends GQLBasedService<SecurityCase> {
    private readonly playerHttpService: BaseHttpService;

    constructor(client: ApolloClientProxy<NormalizedCacheObject>, tracingService: ITracingService, userManager: UserManagerExtended) {
        super(client);
        this.playerHttpService = new PlayerManagementApiService(tracingService, userManager);
    }

    updateSecurityCases(userId: string, securityCases: EditSecurityCasesSubmitModel): Observable<AjaxResponse> {
        const addCases = securityCases?.add;
        const removeCases = securityCases?.remove;
        const addCases$ = this.addSecurityCases(userId, addCases);
        const deleteCases$ = this.deleteSecurityCases(userId, removeCases);

        if (removeCases?.length && addCases?.length) {
            return deleteCases$.pipe(mergeMap(() => addCases$));
        } else if (removeCases?.length) {
            return deleteCases$;
        } else if (addCases?.length) {
            return addCases$;
        } else return of(null);
    }

    loadSecurityCases(filter?: SecurityCaseFilter): Observable<SecurityCase[]> {
        const gqlFilter = this.getSecurityCasesVariables(filter);

        return filter?.type === SecurityCaseType.Bot
            ? this.loadBotCases(filter)
            : this._client.executeQuery(this.getSecurityCasesQuery(), gqlFilter).pipe(
                  map<Query, SecurityCase[]>(res => {
                      return res?.getSecurityCases ?? [];
                  })
              );
    }

    private loadBotCases(filter?: SecurityCaseFilter): Observable<SecurityCase[]> {
        const isFilterEmpty = isStringNullOrEmpty(filter?.id);

        const botGroupFilter = this.getBotGroupsQueryVariables(filter);
        const securityCasesFilter = this.getSecurityCasesVariables(filter);

        const cases$ = this._client.executeQuery(this.getBotGroupsQuery(), botGroupFilter).pipe(
            map<Query, SecurityCase[]>(res => {
                return res?.getBotGroups?.items?.map(i => ({type: filter?.type, case_id: i?.group_id} as SecurityCase)) ?? [];
            })
        );

        return !isFilterEmpty
            ? cases$.pipe(
                  mergeMap(cases => {
                      return this._client.executeQuery(this.getSecurityCasesQuery(), securityCasesFilter).pipe(
                          map<Query, SecurityCase[]>(res => {
                              const securityCases = res?.getSecurityCases ?? [];

                              const allCases = [...cases, ...securityCases];
                              const uniqueCases = [...new Map(allCases?.map(i => [i.case_id, i])).values()];

                              return uniqueCases;
                          })
                      );
                  })
              )
            : cases$;
    }

    private addSecurityCases(userId: string, securityCases: SecurityCase[]): Observable<AjaxResponse> {
        return this.playerHttpService.post('/security-cases', {
            userId,
            targetTypes: securityCases?.map(c => ({
                type: c.type,
                caseId: !isStringNullOrEmpty(c.case_id) ? c.case_id : null,
            })),
        });
    }

    private deleteSecurityCases(userId: string, securityCases: SecurityCase[]): Observable<AjaxResponse> {
        return this.playerHttpService.delete(
            `/security-cases?userId=${userId}&${securityCases?.map(c => `Ids=${c.case_id}`).join('&')}`,
            {}
        );
    }

    private getSecurityCasesQuery() {
        return getSecurityCasesQuery;
    }

    private getBotGroupsQuery() {
        return getBotGroupsQuery;
    }

    private getSecurityCasesVariables(filter?: SecurityCaseFilter) {
        const isFilterEmpty = isStringNullOrEmpty(filter?.id);
        const gqlFilter = {
            type: filter?.type,
            case_id: !isFilterEmpty ? filter?.id : null,
            limit: 10,
        } as ISecurityCaseGqlQueryVariables;

        return gqlFilter;
    }

    private getBotGroupsQueryVariables(filter?: SecurityCaseFilter) {
        const isFilterEmpty = isStringNullOrEmpty(filter?.id);

        const botGroupIdFilter: TextFilter = {
            text: filter?.id,
            search_in: nameof.toArray<BotGroup>(m => [m.group_id]),
        };
        const botGroupUidsFilter: TextFilter = {
            text: filter?.userId,
            search_in: nameof.toArray<BotGroup>(m => [m.uids]),
        };

        const botGroupTextFilters = [!isFilterEmpty ? botGroupIdFilter : botGroupUidsFilter];
        const botGroupFilter: BotGroupFilter = {text: botGroupTextFilters} as BotGroupFilter;

        return {filter: botGroupFilter} as IGQlFilterVariables;
    }

    protected getGQLVariables(_filter: SearchFilter): ISecurityCaseGqlQueryVariables {
        return null;
    }
}
