import {defineMessages} from 'react-intl';

import {LoginStatus} from '@models/generated/graphql';

export const localizedLoginStatus = defineMessages({
    [LoginStatus.Success]: {
        id: 'logintatusSuccess',
        defaultMessage: 'Success',
    },
    [LoginStatus.Failed]: {
        id: 'loginStatusFailed',
        defaultMessage: 'Failed',
    },
});
