import {PoliciesAccessRule} from '@access-control';
import {ModuleName, SubmoduleName} from '@models/modules';
import {Policy} from '@auth';

export const commentReadPolicyRule = new PoliciesAccessRule([
    new Policy(ModuleName.Comments),
    new Policy(ModuleName.Comments, null, 'read'),
    new Policy(ModuleName.Comments, SubmoduleName.NotesSecurity),
    new Policy(ModuleName.Comments, SubmoduleName.NotesSecurity, 'read'),
    new Policy(ModuleName.Comments, SubmoduleName.Payment),
    new Policy(ModuleName.Comments, SubmoduleName.Payment, 'read'),
    new Policy(ModuleName.Comments, SubmoduleName.KYC),
    new Policy(ModuleName.Comments, SubmoduleName.KYC, 'read'),
    new Policy(ModuleName.Comments, SubmoduleName.NotesCustomerSupport),
    new Policy(ModuleName.Comments, SubmoduleName.NotesCustomerSupport, 'read'),
    new Policy(ModuleName.Comments, SubmoduleName.NotesPlayer),
    new Policy(ModuleName.Comments, SubmoduleName.NotesPlayer, 'read'),
    new Policy(ModuleName.Comments, SubmoduleName.NotesBotDetection),
    new Policy(ModuleName.Comments, SubmoduleName.NotesBotDetection, 'read'),
]);

export const commentCreatePolicyRule = new PoliciesAccessRule([
    new Policy(ModuleName.Comments),
    new Policy(ModuleName.Comments, null, 'create'),
    new Policy(ModuleName.Comments, SubmoduleName.NotesSecurity),
    new Policy(ModuleName.Comments, SubmoduleName.NotesSecurity, 'create'),
    new Policy(ModuleName.Comments, SubmoduleName.Payment),
    new Policy(ModuleName.Comments, SubmoduleName.Payment, 'create'),
    new Policy(ModuleName.Comments, SubmoduleName.KYC),
    new Policy(ModuleName.Comments, SubmoduleName.KYC, 'create'),
    new Policy(ModuleName.Comments, SubmoduleName.NotesCustomerSupport),
    new Policy(ModuleName.Comments, SubmoduleName.NotesCustomerSupport, 'create'),
    new Policy(ModuleName.Comments, SubmoduleName.NotesPlayer),
    new Policy(ModuleName.Comments, SubmoduleName.NotesPlayer, 'create'),
    new Policy(ModuleName.Comments, SubmoduleName.NotesBotDetection),
    new Policy(ModuleName.Comments, SubmoduleName.NotesBotDetection, 'create'),
]);
