import {defineMessages} from 'react-intl';

import {GamePlatform} from '@models/game-template';

export const localizedGamePlatform = defineMessages({
    [GamePlatform.Me]: {
        id: 'gamePlatform_Me',
        defaultMessage: 'Me',
    },
    [GamePlatform.WPK]: {
        id: 'gamePlatform_WPK',
        defaultMessage: 'WPK',
    },
    [GamePlatform.BL]: {
        id: 'gamePlatform_BL',
        defaultMessage: 'BL',
    },
    [GamePlatform.MemePoker]: {
        id: 'gamePlatform_MemePoker',
        defaultMessage: 'MemePoker',
    },
    [GamePlatform.WPTG]: {
        id: 'gamePlatform_WPTG',
        defaultMessage: 'WPTG',
    },
});
