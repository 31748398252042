import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useLayoutDetailsClasses = makeStyles()((theme: CustomTheme) => ({
    layoutDetails: {
        overflowY: 'hidden',
        height: '100%',
    },
    layoutDetailsBody: {
        width: '100%',
        display: 'flex',
        margin: 0,
        padding: theme.spacing(2.5, 5),
        columnGap: theme.spacing(2.5),

        height: `calc(100% - ${theme.custom.pageHeaderHeight}px)`,
        [theme.breakpoints.down('md')]: {
            height: `calc(100% - ${theme.custom.pageHeaderHeight + theme.custom.smallSizesNavbarHeight}px)`,
        },
    },
    layoutDetailsPrimaryInfo: {
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        '& > :nth-child(n)': {
            marginBottom: theme.spacing(2),
            '&.Mui-expanded': {
                marginBottom: theme.spacing(2),
            },
        },
    },
    layoutDetailsSecondaryInfo: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1.25),
        marginLeft: 'auto',
        flexShrink: 0,
        position: 'relative',
        top: 0,
        bottom: 0,
        maxWidth: '33.333%',
        width: '33.333%',
    },
}));

type LayoutDetailsProps = {
    header: React.ReactNode;
    primaryInfo: React.ReactNode;
    secondaryInfoTop: React.ReactNode;
    secondaryInfoBottom: React.ReactNode;
};

export const LayoutDetails = ({header, primaryInfo, secondaryInfoTop, secondaryInfoBottom}: LayoutDetailsProps) => {
    const {classes} = useLayoutDetailsClasses();

    return (
        <Box className={classes.layoutDetails}>
            {header}
            <Box className={classes.layoutDetailsBody}>
                <Box className={classes.layoutDetailsPrimaryInfo}>{primaryInfo}</Box>
                {secondaryInfoTop || secondaryInfoBottom ? (
                    <Box className={classes.layoutDetailsSecondaryInfo}>
                        {secondaryInfoTop}
                        {secondaryInfoBottom}
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};
