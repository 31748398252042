import React from 'react';
import {FormattedNumber} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import {renderValueWithTooltip} from '@components/data-grid/mui';
import {PlayerBonus} from '@models/generated/graphql';

import {useJurisdictionConfig} from '../../app/config/hooks';

type PlayerBonusAmountProps = {
    amount?: number;
    bonusDescription: string;
};

const PlayerBonusAmount = ({amount, bonusDescription}: PlayerBonusAmountProps) => {
    const {currency} = useJurisdictionConfig();
    const mttBonusDescription = 'MTT Ticket';

    return amount === null || amount === undefined ? null : bonusDescription === mttBonusDescription ? (
        <FormattedNumber value={amount ?? 0} />
    ) : (
        <FormattedNumber value={amount ?? 0} style="currency" currency={currency} />
    );
};

export const renderPlayerBonusAmount = (params: GridCellParams) => {
    const bonus: PlayerBonus = params.row as PlayerBonus;
    const value: number = params.value as number;

    return renderValueWithTooltip(<PlayerBonusAmount amount={value} bonusDescription={bonus.desc} />);
};
