import produce from 'immer';
import {ActionType, createReducer, Reducer} from 'typesafe-actions';
import {v4 as uuid} from 'uuid';

import {IReducerCreator} from '@redux';

import {notificationsActions} from './actions';
import {NotificationList} from './types';

export class NotificationReducerCreator implements IReducerCreator<NotificationList, ActionType<typeof notificationsActions>> {
    createReducer(): Reducer<NotificationList, ActionType<typeof notificationsActions>> {
        return createReducer<NotificationList, ActionType<typeof notificationsActions>>({})
            .handleAction(notificationsActions.show, (state, action) => {
                return produce(state, draftState => {
                    const id = uuid();
                    draftState[id] = {...action.payload, id};
                });
            })
            .handleAction(notificationsActions.hide, (state, action) => {
                return produce(state, draftState => {
                    delete draftState[action.payload.id];
                });
            });
    }
}
