import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {ChipCounter} from '@components/chip/ChipCounter';
import {ChipType} from '@components/chip/types';

import {UserProfileGridItem} from '../../data-grids/types';
import {useBulkPlayerLabels, useBulkPlayerLabelsUnassign} from '../hooks';

const useStyles = makeStyles()(theme => ({
    labels: {
        '& .MuiChip-root': {
            margin: theme.spacing(0.375),
        },
    },
}));

type BulkPlayerLabelsProps = {
    players: UserProfileGridItem[];
    className?: string;
};

export function BulkLabelApplyStepChipList({players, className = ''}: BulkPlayerLabelsProps) {
    const {classes, cx} = useStyles();
    const {bulkLabels} = useBulkPlayerLabels(players);
    const {handleDelete} = useBulkPlayerLabelsUnassign(players);

    return (
        <Box className={cx(classes.labels, className)}>
            {bulkLabels.map(label => (
                <ChipCounter
                    key={label?.id}
                    chipType={ChipType.Status}
                    chipVariant={label?.group?.color}
                    label={label?.name}
                    fillWidth={false}
                    onDelete={() => {
                        handleDelete(label?.id, label?.name);
                    }}
                    count={label?.count}
                    isCrossedOut={label?.isCrossedOut}
                />
            ))}
        </Box>
    );
}
