import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';

import {EmptyLabel} from '@components/label';

type TextListCellProps = {
    list: (string | MessageDescriptor)[];
};

export function TextListCell({list}: TextListCellProps) {
    const {formatMessage} = useIntl();
    return list?.length ? list.map(i => (typeof i === 'string' ? i : formatMessage(i)))?.join(', ') : <EmptyLabel defaultSymbol="dash" />;
}
