import React from 'react';

import {localized} from '@components/filter/dateRangeFilter/localized';
import {DateRangePicker} from '@components/input';

import {Wrapper} from './WrapperDev';

export const DateRangePickers = () => {
    return (
        <Wrapper label="Date Range Picker">
            <DateRangePicker onChange={null} locale="en" placeholder={localized.placeholder} />
        </Wrapper>
    );
};
