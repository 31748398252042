import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';

import Button from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {Icon, IconColor} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {ModalContext} from '@components/modal';

import {BlockPlayerLabelCreateLabelForm} from './BlockPlayerLabelCreateLabelForm';

const localized = defineMessages({
    buttonLabel: {
        id: 'blockPlayerLabelCreateLabel_buttonLabel',
        defaultMessage: 'Create new Label',
    },
    modalTitle: {
        id: 'blockPlayerLabelCreateLabel_modalTitle',
        defaultMessage: 'New Label',
    },
});

export function BlockPlayerLabelCreateLabel() {
    const {openModal} = useContext(ModalContext);

    const onClick = () => {
        openModal({
            body: <BlockPlayerLabelCreateLabelForm />,
            title: <LocalizedText label={localized.modalTitle} />,
        });
    };

    return (
        <Button
            label={localized.buttonLabel}
            onClick={onClick}
            endIcon={<Icon color={IconColor.Secondary} icon={CustomIcon.AddCircleOutline} />}
            placement={'stretch'}
        />
    );
}
