import React from 'react';
import {MessageDescriptor} from 'react-intl';
import {Typography, TypographyProps} from '@mui/material';
import {default as MuiCard} from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';

const useStyles = makeStyles()(theme => ({
    cardContainer: {
        width: 'max-content',
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius * 2,
    },
    cardContent: {
        padding: `${theme.spacing(3)} !important`,
    },
}));

type CardProps = Pick<TypographyProps, 'variant'> & {
    title?: string | MessageDescriptor;
    children: React.ReactNode;
    className?: string;
    styledContent?: boolean;
    titleClassName?: string;
};

export function Card({title, children, className, variant = 'h6', styledContent = true, titleClassName}: CardProps) {
    const {classes, cx} = useStyles();
    return (
        <MuiCard className={cx(classes.cardContainer, className)}>
            <CardContent className={cx(styledContent && classes.cardContent)}>
                <Typography variant={variant} className={titleClassName}>
                    <LocalizedText label={title} />
                </Typography>
                {children}
            </CardContent>
        </MuiCard>
    );
}
