import {createGridEpics} from '../../features/module-shared/epics';
import {TransactionGridItem, TransactionItem} from '../../features/transactions/types';

import {columnsSelector, filterSelector} from './selectors';
import {domain} from './types';

const transactionHistoryEpics = createGridEpics<TransactionItem, TransactionGridItem>(
    domain,
    c => c.transactionService,
    filterSelector,
    columnsSelector
);

export default transactionHistoryEpics;
