import React, {memo} from 'react';
import {IntlProvider} from 'react-intl';
import {useSelector} from 'react-redux';
import {CssBaseline} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {RootState} from 'typesafe-actions';

import {EditableFormProvider} from '@components/editable/EditProvider';
import {ErrorUnstyled} from '@components/error';
import Menu from '@components/Menu';
import {ModalProvider} from '@components/modal';
import SideBar from '@components/sidebar/SideBar';
import {RoutingTabsProvider} from '@components/tab/RoutingTabsProvider';
import {withTraceErrorBoundary} from '@otel';
import {CustomTheme} from '@style';

import {useJurisdictionConfig} from 'src/features/app/config/hooks';
import {defaultLocale} from 'src/features/app/intl/const';
import {Notifications} from 'src/features/block-notifications/components/Notifications';
import {EditableProvider} from 'src/features/module-temp-storage/components/editable/EditableProvider';
import {SideModalProvider} from 'src/features/shared/side-modal/SideModalProvider';
import {RoutesProvider} from '../../common/routing/RoutesProvider';
import {EmbeddedIFramesProvider} from '../../features/block-embedded-report/components/EmbeddedIFramesProvider';
import {AppRoutes} from '../router/AppRoutes';
import routes from '../router/routes';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    appRoot: {
        display: 'flex',
    },
    appModuleMaximized: {
        flexGrow: 1,
        position: 'absolute',
        left: theme.custom.sideBarWidth,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',

        [theme.breakpoints.down('md')]: {
            left: 0,
        },
    },
    appModuleMinimized: {
        flexGrow: 1,
        position: 'absolute',
        left: theme.custom.sideBarMinimizedWidth,
        top: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',

        [theme.breakpoints.down('md')]: {
            left: 0,
        },
    },
}));

function ProtectedAuthorizedApp() {
    const {classes} = useClasses();
    const [sidebarStick, setSidebarStick] = React.useState(true);

    const handleSidebarStick = (stick: boolean) => {
        setSidebarStick(stick);
    };

    return (
        <div className={classes.appRoot}>
            <CssBaseline />
            <Menu />
            <SideBar stick={sidebarStick} handleDrawerStick={handleSidebarStick} />
            <Notifications></Notifications>
            <main className={sidebarStick ? classes.appModuleMaximized : classes.appModuleMinimized}>
                <EditableFormProvider>
                    <ModalProvider>
                        <SideModalProvider>
                            <EditableProvider>
                                <RoutingTabsProvider>
                                    <EmbeddedIFramesProvider>
                                        <AppRoutes></AppRoutes>
                                    </EmbeddedIFramesProvider>
                                </RoutingTabsProvider>
                            </EditableProvider>
                        </SideModalProvider>
                    </ModalProvider>
                </EditableFormProvider>
            </main>
        </div>
    );
}

function LocalizedApp() {
    const {messages} = useSelector((s: RootState) => s.localization);
    const isResourcesLoaded = messages && Object.keys(messages).length;
    const {timezone, locale} = useJurisdictionConfig();

    return (
        <IntlProvider
            defaultLocale={defaultLocale}
            locale={isResourcesLoaded ? locale : defaultLocale}
            messages={messages}
            timeZone={timezone}
            defaultRichTextElements={{
                strong: text => <strong>{text}</strong>,
                /**
                 * Self-closing tag is broken in defaultRichTextElements
                 * @see https://github.com/formatjs/formatjs/issues/3982
                 */
                // @ts-expect-error: foramtjs typing issue with <br />
                br: <br />,
            }}
        >
            <RoutesProvider routes={routes}>
                <ProtectedAuthorizedApp />
            </RoutesProvider>
        </IntlProvider>
    );
}

export const AuthorizedApp = memo(withTraceErrorBoundary(LocalizedApp, ErrorUnstyled));
