import React from 'react';

import {ModuleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {DownloadCsvButton} from 'src/features/block-download';
import {withPermissionAndJurisdictionConfig} from '../../app/permission/PermissionHoc';
import {useUserLoginDownload} from '../hooks';
import {UserLoginViewModelKeys} from '../types';

type UserLoginDownloadCsvButtonProps = {
    filterString: string;
    filename: string;
    keys: UserLoginViewModelKeys[];
};

function UserLoginDownloadCsvButtonInner(props: UserLoginDownloadCsvButtonProps) {
    const {handleClick, isProgress} = useUserLoginDownload(props);
    return <DownloadCsvButton onClick={handleClick} loading={isProgress} />;
}

export const userLoginDownloadPermissions = {
    moduleName: ModuleName.PlayerManagement,
    permissions: [PermissionEnum.Read],
};

export const UserLoginDownloadCsvButton = withPermissionAndJurisdictionConfig(
    UserLoginDownloadCsvButtonInner,
    userLoginDownloadPermissions
);
