import {defineMessages} from 'react-intl';

import {FeatureAccessStatus} from '@models/generated/graphql';

export const localizedFeatureAccessStatus = defineMessages({
    [FeatureAccessStatus.Unlocked]: {
        id: 'featureAccessStatusUnlocked',
        defaultMessage: 'Unlocked',
    },
    [FeatureAccessStatus.Locked]: {
        id: 'featureAccessStatusLocked',
        defaultMessage: 'Locked',
    },
});
