import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

export enum Jurisdiction {
    NJ = 'NJ',
    MT = 'MT',
    PKW = 'PKW',
    IM = 'IM',
    WPTO = 'WPTO',
    Dev = 'Dev',
}

export enum JurisdictionTimezone {
    NJ = 'America/New_York',
    MT = 'Europe/Malta',
    IM = 'Europe/Isle_of_Man',
    PKW = 'Hongkong',
    WPTO = 'Etc/UTC',
    Dev = 'Etc/UTC',
}

export type JurisdictionLocales = 'zh-CN' | 'en' | 'ko-KR';

export enum JurisdictionLocalFormats {
    'en' = 'L',
    'zh-CN' = 'LL',
}

export enum JurisdictionCurrency {
    USD = 'USD',
    RMB = 'RMB',
    EUR = 'EUR',
    GBP = 'GBP',
}

export enum FeatureName {
    Test1 = 'Test1',
    Test2 = 'Test2',

    NDRPPage = 'NDRPPage',

    UserFinanceSummary = 'UserFinanceSummary',
    AggregatedFinancialSummary = 'AggregatedFinancialSummary',

    AgentPathStatisticsVn = 'AgentPathStatisticsVn',

    AgentReportingVnTotalGridRow = 'AgentReportingVnTotalGridRow',

    LoginHistoryLogoutInfo = 'LoginHistoryLogoutInfo',
    SportsbookLockOnSecurityWorkspace = 'SportsbookLockOnSecurityWorkspace',
    P2POptionsHideForPHRoles = 'P2POptionsHideForPHRoles',
    FinanceActionsNewPolicies = 'FinanceActionsNewPolicies',
}

export type JurisdictionConfiguration = {
    jurisdiction: Jurisdiction;
    locale: JurisdictionLocales;
    timezone: JurisdictionTimezone;
    currency: JurisdictionCurrency;
    limitedFeatures: JurisdictionFeature[];
};

export type JurisdictionFeature = {
    moduleName?: ModuleName;
    submoduleName?: SubmoduleName;
    featureName?: FeatureName;
    permissions?: PermissionEnum[];
};
