import {EntityType} from '@redux/entity';

import {withBulkSummaryStep} from '../../block-bulk-actions';

import {DataGridBulkTransactionsClient} from './DataGridBulkTransaction';

export const BulkTransactionManualSummaryStepModal = withBulkSummaryStep(
    DataGridBulkTransactionsClient,
    ['rowIndex', 'uid', 'type', 'amount'],
    EntityType.Transaction
);

export const BulkTransactionP2PTransferSummaryStepModal = withBulkSummaryStep(
    DataGridBulkTransactionsClient,
    ['uid', 'type', 'amount'],
    EntityType.Transaction
);
