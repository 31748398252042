import {DateRangeFilterValue} from '@components/filter';
import {Timestamp} from '@models/shared';
import {TicketStatus} from '@models/sportsbook-transaction/type';

export class SportsbookTransactionViewModel {
    id: string;
    ticketId: number;
    compUserId: string;
    sabaTicketId: number;
    ticketStatus: TicketStatus;
    matchNames: string[];
    tournamentNames: string[];
    matchIds: number[];
    betOptions: string[];
    betOdds: number[];
    betAmount: number;
    settleAmount: number;
    betTime: Timestamp;
    currency: string;
}

export type SportsbookTransactionViewModelKeys = keyof SportsbookTransactionViewModel;
export type SportsbookTransactionFilterKeys =
    | 'ticketId'
    | 'matchId'
    | 'compUserId'
    | 'tournamentName'
    | 'betTimeStart'
    | 'betTimeEnd'
    | 'ticketStatus';

export type SportsbookTransactionFilterName = 'ticketId' | 'matchId' | 'compUserId' | 'tournamentName' | 'betTime' | 'ticketStatus';

export type SportsbookTransactionFilterModel = {
    ticketId: number;
    matchId: number;
    compUserId: string;
    tournamentName: string;
    betTime: DateRangeFilterValue;
    ticketStatus: TicketStatus;
};
