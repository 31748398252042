import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Box, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CurrencyCell} from '@components/data-grid/mui';
import {KeyValueCell, KeyValueColumn, KeyValueTable} from '@components/key-value-table';
import {AggregatedFinanceSummaryViewModel, AggregatedFinanceSummaryViewModelKeys, GgrViewModel} from '@models/aggregated-finance-summary';
import {AggregatedFinanceSummaryFilterKeys} from '@redux/entity';
import {UseDetailsViewEntityProps, UseDetailsViewEntityResult} from '@redux/view';

import {useJurisdictionConfig} from 'src/features/app/config/hooks';
import {useAggregatedFinanceSummaryDetails} from 'src/features/block-aggregated-financial-summary-details';
import {FeatureName} from '../../app/config/types';
import {withPermissionAndJurisdictionConfig} from '../../app/permission/PermissionHoc';

import {AggregatedFinancialSummaryFilters} from './AggregatedFinancialSummaryFilters';
import {GGR} from './GGR';

const localized = defineMessages({
    ggrTitle: {
        id: 'AggregatedFinancialSummary_ggrTitle',
        defaultMessage: 'GGR',
    },
    ggrDate: {
        id: 'AggregatedFinancialSummary_ggrDate',
        defaultMessage: 'as of {dateTime}',
    },
    depositTotalTitle: {
        id: 'AggregatedFinancialSummary_depositTotalTitle',
        defaultMessage: 'Total Deposit',
    },
    withdrawalTotalTitle: {
        id: 'AggregatedFinancialSummary_withdrawalTotalTitle',
        defaultMessage: 'Total Withdrawal',
    },
    bonusReceivedTitle: {
        id: 'AggregatedFinancialSummary_bonusReceivedTitle',
        defaultMessage: 'Bonus Received',
    },
    bonusRatioTitle: {
        id: 'AggregatedFinancialSummary_bonusRatioTitle',
        defaultMessage: 'Bonus Ratio',
    },
    aggregatedFinanceSummaryDisplayName: {
        id: 'AggregatedFinanceSummary_displayName',
        defaultMessage: 'Aggregated Finance Summary',
    },
});

const useStyles = makeStyles()(theme => ({
    aggregateFinancialSummary: {
        marginBottom: theme.spacing(2.5),
    },
    aggregateFinancialSummaryFilters: {
        marginLeft: theme.spacing(4),
    },
    ggrTitle: {
        lineHeight: theme.spacing(2.5),
    },
}));

type AggregatedFinancialSummary = {
    uid: string;
};

type UseAggregatedFinanceSummary = UseDetailsViewEntityResult<AggregatedFinanceSummaryViewModel, AggregatedFinanceSummaryViewModelKeys> & {
    ggrTotal: number;
};

function useAggregatedFinanceSummary({
    id,
    fields,
    viewType,
}: UseDetailsViewEntityProps<AggregatedFinanceSummaryFilterKeys, AggregatedFinanceSummaryViewModelKeys>): UseAggregatedFinanceSummary {
    const {item, handleFilterChange, filterString, searchFilter} = useAggregatedFinanceSummaryDetails({
        id,
        fields,
        viewType,
        displayName: localized.aggregatedFinanceSummaryDisplayName,
    });

    return {
        item,
        handleFilterChange,
        filterString,
        searchFilter,
        ggrTotal: item?.ggr
            ? Object.keys(item.ggr).reduce((previousValue, currentValue: keyof GgrViewModel) => previousValue + item?.ggr[currentValue], 0)
            : null,
    };
}

const domain = 'aggregated-finance-summary';

function AggregatedFinancialSummaryInner({uid}: AggregatedFinancialSummary) {
    const {formatMessage} = useIntl();
    const {currency} = useJurisdictionConfig();
    const {item, ggrTotal, handleFilterChange, filterString} = useAggregatedFinanceSummary({
        id: uid,
        viewType: 'AggregatedFinanceSummary',
        fields: [
            'end_at',
            'lifetime_bonus',
            'bonus_ratio',
            'ggr.mtt_service_fee',
            'ggr.poker_rake',
            'ggr.poker_insurance',
            'ggr.poker_features_purchase',
            'ggr.poker_guess_hand',
            'ggr.casino_poker_flips',
            'ggr.casino_connector',
            'deposit.successful_amount',
            'withdrawal.successful_amount',
        ],
    });

    const {classes} = useStyles();

    return (
        <Box className={classes.aggregateFinancialSummary}>
            <Box className={classes.aggregateFinancialSummaryFilters}>
                <AggregatedFinancialSummaryFilters domain={domain} onFilterChange={handleFilterChange} filterString={filterString} />
            </Box>
            <KeyValueTable>
                <KeyValueColumn keyWidth="lg">
                    <KeyValueCell title={formatMessage(localized.depositTotalTitle)}>
                        <CurrencyCell value={item?.deposit?.successful_amount} currency={currency} />
                    </KeyValueCell>
                    <KeyValueCell title={formatMessage(localized.bonusReceivedTitle)}>
                        <CurrencyCell value={item?.lifetime_bonus} currency={currency} />
                    </KeyValueCell>
                </KeyValueColumn>
                <KeyValueColumn keyWidth="lg">
                    <KeyValueCell title={formatMessage(localized.withdrawalTotalTitle)}>
                        <CurrencyCell value={item?.withdrawal?.successful_amount} currency={currency} />
                    </KeyValueCell>
                    <KeyValueCell
                        title={
                            <Box className={classes.ggrTitle}>
                                {formatMessage(localized.ggrTitle)}
                                <Typography variant="subtitle2">{formatMessage(localized.ggrDate, {dateTime: item?.end_at})}</Typography>
                            </Box>
                        }
                    >
                        <GGR ggr={item?.ggr} total={ggrTotal} currency={currency} dateTimeString={item?.end_at} />
                    </KeyValueCell>
                    <KeyValueCell title={formatMessage(localized.bonusRatioTitle)}>
                        <CurrencyCell value={item?.bonus_ratio} currency={currency} />
                    </KeyValueCell>
                </KeyValueColumn>
            </KeyValueTable>
        </Box>
    );
}

export const AggregatedFinancialSummary = withPermissionAndJurisdictionConfig(AggregatedFinancialSummaryInner, {
    featureName: FeatureName.AggregatedFinancialSummary,
});
