import {IModuleGridItem} from '@components/data-grid';
import {AccountVerification, KycCheckResults, UserBusinessPool} from '@models/generated/graphql';
import {AccountVerificationStatus, AccountVerificationType, KycDocument} from '@models/generated/graphql';
import {isRecent} from '@utils';

import {ColumnConfiguration} from 'src/common/types';
import {IModuleItem} from '../module-shared/types';

export const domain = 'kyc';

export class KYCColumnsConfiguration extends ColumnConfiguration {
    withUid: boolean;
    withKycCaseId: boolean;
    withUsername: boolean;
    withDid: boolean;
    withDocRefId: boolean;
    withDocType: boolean;
    withStatus: boolean;
    withUploadedTs: boolean;
    withAccountVerificationStatus: boolean;
    withDocIdSubType: boolean;
    withLocation: boolean;
    withClientIp: boolean;
    withNumber: boolean;
    withFirstName: boolean;
    withLastName: boolean;
    withGender: boolean;
    withDOB: boolean;
    withInitiatedAt: boolean;
    withIpVerified: boolean;
    withDeviceVerified: boolean;
    withGeolocationVerified: boolean;
    withType: boolean;

    constructor() {
        super();
        this.withUid = false;
        this.withKycCaseId = false;
        this.withUsername = false;
        this.withDid = false;
        this.withDocRefId = false;
        this.withDocType = false;
        this.withStatus = false;
        this.withUploadedTs = false;
        this.withAccountVerificationStatus = false;
        this.withDocIdSubType = false;
        this.withLocation = false;
        this.withClientIp = false;
        this.withNumber = false;
        this.withFirstName = false;
        this.withLastName = false;
        this.withGender = false;
        this.withDOB = false;
        this.withInitiatedAt = false;
        this.withIpVerified = false;
        this.withDeviceVerified = false;
        this.withGeolocationVerified = false;
        this.withType = false;
    }
}

export class KycItem extends KycCheckResults implements IModuleItem {
    get id() {
        return this.uid;
    }
}

export class KycHistoryGridItem extends AccountVerification implements IModuleGridItem {
    serverId: string;
    doc_front_link?: string;
    doc_back_link?: string;
    latestDocument: KycDocument;

    get isNew() {
        return isRecent(this.initiated_at, 1);
    }
}

export class KycGridItem extends KycHistoryGridItem implements IModuleGridItem {
    history: KycHistoryGridItem[];
    businessPool: UserBusinessPool;
    vipLevel: UserBusinessPool;

    get isNew() {
        return isRecent(this.initiated_at, 1);
    }
}

export enum Classifier {
    Front = 'Front',
    Back = 'Back',
    Face = 'Face',
}

export class DownloadDocumentParams {
    docRefId: string;
    classifier: Classifier;
}

export type EditKycStatusModel = {
    id: string;
    status: AccountVerificationStatus;
    type: AccountVerificationType;
};

export enum ActionType {
    Approve,
    OnHold,
    Reject,
}
