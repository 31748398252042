import React, {useEffect, useState} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import produce from 'immer';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {useService} from '@inversify';
import {TransactionType} from '@models/generated/graphql';
import {TransactionViewModelEditableKeys} from '@models/transaction/types';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {bulkActionsActions, BulkStepProps, itemsSelector} from '../../block-bulk-actions';
import {DataGridTransactionWithUserProfileEditableClient} from '../../block-transaction-list';
import {ApplyP2PTransfersRequest, ApplyP2PTransfersStrategy} from '../services/applyStrategy';
import {P2PTransferCounterpartUser, P2PTransferModel} from '../types';

const useClasses = makeStyles()(() => ({
    p2pTransferGrid: {
        height: '500px',
        flex: 'auto',
    },
}));

const localized = defineMessages({
    title: {
        id: 'BulkStepApplyP2PTransfer_title',
        defaultMessage: 'P2P Transfer',
    },
    prev: {
        id: 'BulkStepApplyP2PTransfer_prev',
        defaultMessage: 'Cancel',
    },
    next: {
        id: 'BulkStepApplyP2PTransfer_next',
        defaultMessage: 'Validate',
    },
});

type P2PTransferGridItem = P2PTransferModel;
type P2PTransferGridRow = P2PTransferGridItem & {
    p2p_transfer_amount_editable?: {
        amount?: string;
        currency?: string;
    };
    p2p_direction_editable?: TransactionType;
};

export function BulkTransactionP2PTransferApplyStepModal({onPrev, onNext}: BulkStepProps) {
    const {classes} = useClasses();
    const dispatch = useDispatch();
    const {currency} = useJurisdictionConfig();
    const strategy = useService<ApplyP2PTransfersStrategy>('ApplyP2PTransfersStrategy');

    const selectedItems = useSelector(itemsSelector) as P2PTransferCounterpartUser[];
    const [items, setItems] = useState(getDefaultValues);
    useEffect(() => {
        setItems(getDefaultValues());
    }, [selectedItems?.map(i => i.uid)?.join()]);

    function getDefaultValues(): P2PTransferGridItem[] {
        return selectedItems?.map(i => ({
            id: i.id,
            serverId: i.serverId,
            uid: i.uid,
            transaction_type: i.transaction_type ?? TransactionType.P2PTransferCredit,
            amount: i.amount ?? 0,
            finance: i.finance,
            currency,
        }));
    }

    const handleCellValueChange = (newRow: P2PTransferGridRow, oldRow: P2PTransferGridRow) => {
        const updatedItems: P2PTransferGridItem[] = produce(items, draft => {
            const amount: TransactionViewModelEditableKeys = 'p2p_transfer_amount_editable';
            const direction: TransactionViewModelEditableKeys = 'p2p_direction_editable';
            const updatedItem: P2PTransferGridItem = draft?.find(i => i.id === newRow.id);
            if (Number(newRow[amount].amount) !== oldRow.amount) {
                updatedItem.amount = Number(newRow[amount].amount) ?? 0;
            }
            if (newRow[direction] !== oldRow[direction]) {
                updatedItem.transaction_type = newRow[direction] as TransactionType;
            }
        });
        setItems(updatedItems);
        dispatch(bulkActionsActions.setBulkActionsItems(updatedItems));
        return updatedItems.find(i => i.id === newRow.id);
    };

    function handleNextClick() {
        const request: ApplyP2PTransfersRequest = {transactions: items};
        dispatch(bulkActionsActions.apply({strategy, request}));
        onNext();
    }
    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent>
                <DataGridTransactionWithUserProfileEditableClient
                    className={classes.p2pTransferGrid}
                    columns={['uid', 'p2p_direction', 'finance.balance', 'p2p_transfer_amount_editable']}
                    rows={items}
                    processRowUpdate={handleCellValueChange}
                />
            </BulkModalContent>
            <ModalFooter>
                <Button label={localized.prev} onClick={onPrev} />
                <Button color="primary" label={localized.next} onClick={handleNextClick} disabled={false} />
            </ModalFooter>
        </>
    );
}
