import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import LocalizedText from '@components/i18n/LocalizedText';
import {AccountVerificationDurationViewModel, AccountVerificationStatusLogViewModel} from '@models/account-verification';
import {AccountVerificationStatus} from '@models/generated/graphql';
import {Timestamp} from '@models/shared/types';
import {sortByTimestamp, timestampSecondsToMoment} from '@utils/date';

export type AccountVerificationOpenDurationProps = Omit<AccountVerificationDurationViewModel, 'initiated_at'> & {
    initiatedAt: Timestamp;
};

function AccountVerificationOpenDuration({history, initiatedAt}: AccountVerificationOpenDurationProps) {
    const sortHistory = (first: AccountVerificationStatusLogViewModel, second: AccountVerificationStatusLogViewModel): number =>
        sortByTimestamp(first.logged_at, second.logged_at);

    const sortedHistory = history ? [...history].sort(sortHistory) : [];

    const startedDate = timestampSecondsToMoment(initiatedAt?.seconds);
    const pendingStatuses = [
        AccountVerificationStatus.InitWithdrawalKyc,
        AccountVerificationStatus.InitLoginKyc,
        AccountVerificationStatus.InitDepositKyc,
        AccountVerificationStatus.InitPaymentKyc,
        AccountVerificationStatus.OnHoldDepositKyc,
        AccountVerificationStatus.OnHoldLoginKyc,
        AccountVerificationStatus.OnHoldPaymentKyc,
        AccountVerificationStatus.OnHoldWithdrawalKyc,
    ];
    const endDateTimestamp = sortedHistory.find(
        i => i.account_verification_status && !pendingStatuses.includes(i.account_verification_status)
    )?.logged_at;
    const endDate = timestampSecondsToMoment(endDateTimestamp?.seconds);

    const formattedValue = endDate ? startedDate?.from(endDate, true) : startedDate?.fromNow(true);

    return <LocalizedText label={formattedValue} />;
}

export const renderAccountVerificationOpenDuration = (params: GridCellParams) => {
    const accountVerificationDurationProps: AccountVerificationOpenDurationProps = params.value as AccountVerificationOpenDurationProps;

    return (
        <AccountVerificationOpenDuration
            history={accountVerificationDurationProps?.history}
            initiatedAt={accountVerificationDurationProps?.initiatedAt}
        />
    );
};
