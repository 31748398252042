import {PlayersRelationType, SecurityCaseType} from '@models/generated/graphql';
import {Timestamp} from '@models/shared';
import {UserProfileViewModel} from '@models/user-profile/types';

export class PlayersRelationViewModel {
    id: string;
    created_at: Timestamp;
    player: UserProfileViewModel;
    related_player: UserProfileViewModel;
    relation_type: PlayersRelationType;
    relevance: string;
    security_case_types?: SecurityCaseType[];
}

type RelatedPlayersKeys = keyof Pick<UserProfileViewModel, 'uid' | 'security_cases' | 'username'>;

export type PlayersRelationViewModelKeys = keyof PlayersRelationViewModel | `related_player.${RelatedPlayersKeys}`;
