import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {Box, Grid, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {MemoizedDefaultChip} from '@components/chip/ChipRenderer';
import {ChipMapping, chipsMapping} from '@config/chip';

import {WrapperChild} from './WrapperDev';

const useStyles = makeStyles()(() => {
    return {
        chipItem: {
            display: 'flex',
            columnGap: 10,
            marginTop: 5,
        },
    };
});

export const ChipRendererDev = () => {
    const {formatMessage} = useIntl();
    const {classes} = useStyles();

    return (
        <Box margin={10}>
            <Grid container spacing={2}>
                {Object.keys(chipsMapping).map(chipType => (
                    <Grid item xs={12} md={4} key={`grid-${chipType}`}>
                        <WrapperChild label={chipType}>
                            <Box marginTop={1}>
                                {Object.keys(chipsMapping[chipType]).map(item => {
                                    const chip = chipsMapping[chipType][item] as ChipMapping;

                                    return (
                                        <Box className={classes.chipItem} key={`box-${chipType}-${item}`}>
                                            <Typography variant="body2" color="secondary">
                                                {item}
                                            </Typography>
                                            <MemoizedDefaultChip
                                                startIconClass={chip.startIconClass}
                                                type={chip.chipType}
                                                variant={chip.chipVariant}
                                                value={
                                                    chip.labelTypeString
                                                        ? (chip.label as string)
                                                        : formatMessage(chip.label as MessageDescriptor)
                                                }
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                        </WrapperChild>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
