import React from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {EditableAgentPlayerId} from 'src/features/block-user-profile-actions/components/EditableAgentPlayerId';
import {userProfileActions} from '../../../features/block-user-profile-actions/actions';
import {UserGridItem} from '../types';

export const renderAddPlayerIdAction = (params: GridCellParams) => {
    const playerId = params.row[nameof<UserGridItem>(u => u.playerId)];
    const boUserId = params.row[nameof<UserGridItem>(u => u.id)];

    return (
        <EditableAgentPlayerId
            playerId={playerId}
            boUserId={boUserId}
            updatePlayerIdAction={userProfileActions.updateAccessManagementPlayerId}
        />
    );
};
