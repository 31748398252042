import React from 'react';
import {DateRange} from 'react-day-picker';

import {DateRangeFilter, DateRangeFilterValue} from '@components/filter';
import {DateRangePickerProps} from '@components/input';
import {endDateNow} from '@utils';

import {useJurisdictionConfig} from '../../../../app/config/hooks';
import {getDateRangeFilterKey} from '../../utils';
import {FilterInnerProps} from '../ModuleFilterHoc';

import withModuleFilter from './../ModuleFilterHoc';

function withFilterDateRangePickerObsolete(WrappedComponent: typeof DateRangeFilter, {rangeMode}: Pick<DateRangePickerProps, 'rangeMode'>) {
    return function FilterDateRangePicker({filter, value, onSubmit}: FilterInnerProps<string>) {
        const prefix = filter.options as string;
        const {locale} = useJurisdictionConfig();
        const fromKey = getDateRangeFilterKey(
            prefix,
            nameof<DateRange>(d => d.from)
        );
        const toKey = getDateRangeFilterKey(
            prefix,
            nameof<DateRange>(d => d.to)
        );
        const from = parseInt(value.find(v => v.key === fromKey)?.value);
        const toValue = value.find(v => v.key === toKey)?.value;

        const handleDateRangeChange = (date: DateRangeFilterValue) => {
            onSubmit([
                {key: fromKey, value: date.from},
                {key: toKey, value: date.to},
            ]);
        };

        return (
            <WrappedComponent
                rangeMode={rangeMode}
                value={{from, to: toValue !== endDateNow ? parseInt(toValue) : toValue}}
                onChange={handleDateRangeChange}
                locale={locale}
            />
        );
    };
}

const FilterDateRangePicker = withFilterDateRangePickerObsolete(DateRangeFilter, {rangeMode: 'default'});

/**
 * @deprecated Use {@link DateRangeFilter} instead
 */
export const ModuleFilterDateRangeObsolete = withModuleFilter(FilterDateRangePicker, 350);
