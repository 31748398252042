import {createAction, PayloadActionCreator} from 'typesafe-actions';

import {RealtimeSubscriber, RealtimeUpdatesMeta} from './types';

const enableRealtimeAction = '/enableRealtime';
const disableRealtimeAction = '/disableRealtime';
const subscribeToCreateAction = '/subscribeToCreate';
const subscribeToUpdateAction = '/subscribeToUpdate';
const subscribeToDeleteAction = '/subscribeToDelete';
const stopRealtimeAction = '/stopRealtime';

const itemAddedAction = '/itemAdded';
const itemUpdatedAction = '/itemUpdated';
const itemDeletedAction = '/itemDeleted';
const compareItemsWithPrefetchAction = '/compareItemsWithPrefetch';

const notifyUpdatesAction = '/notifyUpdates';
const clearNotificationsForDomainAction = '/clearNotificationsForDomain';
const clearNotificationAction = '/clearNotification';
const reloadAction = '/reload';
const reloadAllAction = '/reloadAll';

export const createEntityRealtimeActions = (domain: string) => ({
    enableRealtime: createAction(`${domain}${enableRealtimeAction}`)<RealtimeSubscriber>(),

    disableRealtime: createAction(`${domain}${disableRealtimeAction}`)<string>(),

    subscribeToCreate: createAction(`${domain}${subscribeToCreateAction}`)<void>(),

    subscribeToUpdate: createAction(`${domain}${subscribeToUpdateAction}`)<void>(),

    subscribeToDelete: createAction(`${domain}${subscribeToDeleteAction}`)<void>(),

    stopRealtime: createAction(`${domain}${stopRealtimeAction}`)<void>(),
});

export const createSubscriberRealtimeActions = <TModel>(domain: string) => ({
    itemAdded: createAction(`${domain}${itemAddedAction}`)<TModel>(),

    itemUpdated: createAction(`${domain}${itemUpdatedAction}`)<TModel>() as PayloadActionCreator<string, TModel>,

    itemDeleted: createAction(`${domain}${itemDeletedAction}`)<void>(),

    compareItemsWithPrefetch: createAction(`${domain}${compareItemsWithPrefetchAction}`)<void>(),
});

export const realtimeNotificationActions = {
    notifyUpdates: createAction(notifyUpdatesAction)<RealtimeUpdatesMeta>(),

    reload: createAction(reloadAction)<string>(),

    reloadAll: createAction(reloadAllAction)<void>(),

    clearNotificationsForDomain: createAction(clearNotificationsForDomainAction)<string>(),

    clearNotification: createAction(clearNotificationAction)<void>(),
};
