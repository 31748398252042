import React from 'react';

type PentagonSvgIconProps = {
    color: string;
};

export function PentagonSvgIcon({color}: PentagonSvgIconProps) {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.5226 4.80543L10.6002 1.23352C10.2203 0.769253 9.65211 0.5 9.05224 0.5H2C0.895431 0.5 0 1.39543 0 2.5V9.5C0 10.6046 0.89543 11.5 2 11.5H9.48155C10.1214 11.5 10.7227 11.1938 11.099 10.6763L13.5922 7.24825C14.127 6.51285 14.0984 5.50921 13.5226 4.80543Z"
                fill={color}
            />
        </svg>
    );
}
