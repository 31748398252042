import React from 'react';

import {QueryFilterAdapterModel, QueryFilterAdapterProps, withQueryFilter} from '@components/filter';
import {UserProfileServerFilterKeys, UserProfileServerTextFilterKeys} from '@redux/entity';

import {UserProfileFilterModel, UserProfileFilterName} from '../types';

import {UserProfileFilters} from './UserProfileFilters';

type UserProfileFiltersAdapterProps = QueryFilterAdapterProps<
    UserProfileServerFilterKeys,
    UserProfileFilterName,
    UserProfileServerTextFilterKeys
>;

function UserProfileFiltersAdapter({model, onChange, ...props}: UserProfileFiltersAdapterProps) {
    const filterModel: UserProfileFilterModel = {
        uid: model?.uid as string,
        username: model?.username as string,
        nickname: model?.nickname as string,
        labels: model?.labels as string,
        email: model?.email as string,
        phone: model?.phone as string,
        security_cases: model?.security_cases as string,
        networks_PKW: model?.networks_PKW as string,
        networks_BL: model?.networks_BL as string,
        referrerId: model?.referrerId as string,
        accountStatus: model?.accountStatus as string[],
        firstName: model?.firstName as string,
        lastName: model?.lastName as string,
        registrationCountry: model?.registrationCountry as string[],
        registrationDate: {
            from: model?.joinedFrom as number,
            to: model?.joinedTo as number | 'now',
        },
        loginCountry: model?.loginCountry as string,
        casinoGgr: {
            from: model?.casinoGgrFrom as number,
            to: model?.casinoGgrTo as number,
        },
        pokerGgr: {
            from: model?.pokerGgrFrom as number,
            to: model?.pokerGgrTo as number,
        },
        sportsGgr: {
            from: model?.sportsGgrFrom as number,
            to: model?.sportsGgrTo as number,
        },
        depositTotal: {
            from: model?.depositTotalFrom as number,
            to: model?.depositTotalTo as number,
        },
        withdrawalTotal: {
            from: model?.withdrawalTotalFrom as number,
            to: model?.withdrawalTotalTo as number,
        },
        marketingCode: model?.marketingCode as string,
    };

    function handleChange(model: UserProfileFilterModel) {
        const result: QueryFilterAdapterModel<UserProfileServerFilterKeys, UserProfileServerTextFilterKeys> = {
            uid: model?.uid,
            username: model?.username,
            nickname: model?.nickname,
            labels: model?.labels,
            email: model?.email,
            phone: model?.phone,
            security_cases: model?.security_cases,
            networks_PKW: model?.networks_PKW,
            networks_BL: model?.networks_BL,
            referrerId: model?.referrerId,
            accountStatus: model?.accountStatus,
            firstName: model?.firstName,
            lastName: model?.lastName,
            registrationCountry: model?.registrationCountry,
            joinedFrom: model?.registrationDate?.from,
            joinedTo: model?.registrationDate?.to,
            loginCountry: model?.loginCountry,
            casinoGgrFrom: model?.casinoGgr?.from,
            casinoGgrTo: model?.casinoGgr?.to,
            pokerGgrFrom: model?.pokerGgr?.from,
            pokerGgrTo: model?.pokerGgr?.to,
            sportsGgrFrom: model?.sportsGgr?.from,
            sportsGgrTo: model?.sportsGgr?.to,
            depositTotalFrom: model?.depositTotal?.from,
            depositTotalTo: model?.depositTotal?.to,
            withdrawalTotalFrom: model?.withdrawalTotal?.from,
            withdrawalTotalTo: model?.withdrawalTotal?.to,
            marketingCode: model?.marketingCode,
        };
        onChange(result);
    }

    return <UserProfileFilters model={filterModel} onChange={handleChange} {...props} />;
}

export const UserProfileQueryFilters = withQueryFilter<UserProfileServerFilterKeys, UserProfileFilterName, UserProfileServerTextFilterKeys>(
    UserProfileFiltersAdapter,
    {
        uid: ['uid'],
        uidWithPlaceholder: ['uid'],
        username: ['username'],
        nickname: ['nickname'],
        labels: ['labels'],
        email: ['email'],
        phone: ['phone'],
        security_cases: ['security_cases'],
        networks_PKW: ['networks_PKW'],
        networks_BL: ['networks_BL'],
        referrerId: ['referrerId'],
        firstName: ['firstName'],
        lastName: ['lastName'],
        registrationCountry: ['registrationCountry'],
        loginCountry: ['loginCountry'],
        marketingCode: ['marketingCode'],
    }
);
