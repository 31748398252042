import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useValueRangePickerClasses = makeStyles()((theme: CustomTheme) => {
    return {
        valueRangePickerContainer: {
            padding: theme.spacing(1.25),
        },
        valueRangePickerClearAll: {
            height: theme.custom.denseButtonHeight,
            fontWeight: 500,
            width: '100%',
        },
        valueRangePickerApply: {
            height: theme.custom.denseButtonHeight,
            borderRadius: theme.custom.roundedButtonRadius,
            border: 0,
            width: '100%',
        },
        valueRangePickerOperatorSelectContainer: {
            marginBottom: theme.spacing(0.5),
        },
        valueRangePickerRelativeRange: {
            maxWidth: 300,
            '& input': {
                height: theme.custom.denseButtonHeight,
            },
        },
        valueRangePickerOperatorSelect: {
            flex: 1,
            '&:not(:last-of-type)': {
                marginRight: theme.spacing(2),
            },
        },
    };
});
