import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    EditorComponent,
    FloatingWrapper,
    MentionAtomState,
    OnChangeHTML,
    Remirror,
    useCommands,
    useMentionAtom,
    useRemirror,
} from '@remirror/react';
import {
    BoldExtension,
    BulletListExtension,
    EmojiExtension,
    FlatEmoji,
    HardBreakExtension,
    HistoryExtension,
    ItalicExtension,
    LinkExtension,
    MarkdownExtension,
    MentionAtomExtension,
    MentionAtomNodeAttributes,
    PlaceholderExtension,
    PositionerExtension,
    TrailingNodeExtension,
} from 'remirror/extensions';
import {makeStyles} from 'tss-react/mui';

import {Suggestion} from '@components/comment';
import {CustomTheme} from '@style';

import data from 'src/assets/emoji.json';

import {EditorToolbar, EditorToolbarsButtons} from './EditorToolbar';
import {useConverters} from './hooks';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    markdownEditorWrapper: {
        '& .remirror-editor-wrapper': {
            overflow: 'auto',
        },
        '& .remirror-editor.ProseMirror-focused': {
            outline: 'none',
            '& .remirror-emoji-image': {
                height: theme.spacing(2),
                width: 'auto',
            },
        },
        '& .remirror-editor': {
            p: {
                margin: theme.spacing(1, 0),
            },
            a: {
                color: theme.palette.primary.main,
                textDecorationColor: theme.palette.primary.main,
            },
        },
    },
    mention: {
        position: 'relative',
        '& [data-id="remirror-positioner-widget"]': {
            position: 'absolute',
            '& .remirror-positioner': {
                position: 'absolute',
            },
        },
    },
    placeholder: {
        '& .remirror-is-empty:first-of-type::before': {
            position: 'absolute',
            color: '#aaa',
            pointerEvents: 'none',
            height: '0',
            content: 'attr(data-placeholder)',
        },
    },
    markdownEditorSuggestionPopup: {
        minWidth: '150px',
        maxHeight: '150px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.custom.palette.content.border}`,
        boxShadow: '0px 2px 4px rgba(0, 21, 41, 0.15)',
        borderRadius: theme.shape.borderRadius * 1.5,
    },
    markdownEditorSuggestionItem: {
        padding: theme.spacing(1, 0.5),
        cursor: 'pointer',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    markdownEditorSuggestionItemHover: {
        backgroundColor: theme.palette.action.hover,
    },
    markdownEditorSuggestionPopupContainer: {
        zIndex: theme.zIndex.tooltip,
    },
}));

export type MarkdownEditorProps = {
    readOnly?: boolean;
    value?: string;
    forceExpand: boolean;
    suggestions?: Suggestion[];
    placeholder?: string;
    onStartEdit?: () => void;
    onStopEdit?: () => void;
    onChange: (value: string) => void;
    toolbarCustomButtons?: React.ReactElement[];
};

type SuggestorProps = {
    users?: MentionAtomNodeAttributes[];
};

function Suggestor({users}: SuggestorProps) {
    const [state, setState] = useState<MentionAtomState<MentionAtomNodeAttributes> | null>(null);
    const items = useMemo(() => {
        if (!state) {
            return [];
        }

        const query = state.query.partial.toLowerCase().trim() ?? '';
        return users.filter(item => item.label.toLowerCase().includes(query)).sort();
    }, [state, users]);

    return <MentionComponent users={items} onChange={setState} />;
}

interface MentionComponentProps {
    users?: MentionAtomNodeAttributes[];
    onChange: (mentionAtomState: MentionAtomState<MentionAtomNodeAttributes> | null) => void;
}

function MentionComponent({users, onChange}: MentionComponentProps) {
    const {classes, cx} = useClasses();
    const {state, getMenuProps, getItemProps, indexIsSelected} = useMentionAtom({items: users});

    const {focus} = useCommands();

    const enabled = users.length > 0;

    useEffect(() => {
        onChange(state);
    }, [state, onChange]);

    return (
        <FloatingWrapper
            positioner="cursor"
            enabled={enabled}
            placement="bottom-start"
            renderOutsideEditor
            containerClass={classes.markdownEditorSuggestionPopupContainer}
        >
            <div {...getMenuProps()} className={classes.markdownEditorSuggestionPopup}>
                {enabled
                    ? users.map((item, index) => {
                          const isHighlighted = indexIsSelected(index);
                          return (
                              <div
                                  key={item.id}
                                  className={cx(
                                      classes.markdownEditorSuggestionItem,
                                      isHighlighted && classes.markdownEditorSuggestionItemHover
                                  )}
                                  {...getItemProps({
                                      onClick: () => {
                                          console.log(item);
                                          state?.command(item);
                                          focus();
                                      },
                                      item,
                                      index,
                                  })}
                              >
                                  <span>{item.label}</span>
                              </div>
                          );
                      })
                    : null}
            </div>
        </FloatingWrapper>
    );
}

const buttons: EditorToolbarsButtons[] = ['bold', 'italic', 'add-link', 'emoji'];

export function MarkdownEditor(props: MarkdownEditorProps) {
    const {classes} = useClasses();
    const {readOnly, value, suggestions, forceExpand, placeholder, onStartEdit, onStopEdit, onChange, toolbarCustomButtons} = props;
    const converters = useConverters();

    const users = useMemo(() => suggestions?.map(suggestion => ({id: suggestion.url, label: suggestion.text})), [suggestions]);
    const initialContent = useMemo(() => (value ? converters.markdownToHtml(value) : null), [value]);

    const extensions = useCallback(
        () => [
            new PlaceholderExtension({placeholder}),
            new MentionAtomExtension({
                matchers: [{name: 'at', char: '@', matchOffset: 0, supportedCharacters: /\w[\s\w'.]*/}],
            }),
            new EmojiExtension({plainText: true, data: data as unknown as FlatEmoji[]}),
            new BoldExtension({}),
            new ItalicExtension(),
            new LinkExtension({autoLink: false, selectTextOnClick: true}),
            new MarkdownExtension({}),
            new HistoryExtension({}),
            new PositionerExtension({}),
            new TrailingNodeExtension({}),
            new BulletListExtension({}),
            new HardBreakExtension({}),
        ],
        [placeholder]
    );

    const {manager} = useRemirror({
        extensions,
        extraAttributes: [
            {
                identifiers: ['mention', 'emoji'],
                attributes: {role: {default: 'presentation'}},
            },
            {identifiers: ['mention'], attributes: {href: {default: null}}},
        ],
        stringHandler: 'html',
    });

    const handleChange = useCallback(
        (html: string) => {
            if (onChange) {
                const mdx = converters.htmlToMarkdown(html);
                onChange(mdx);
            }
        },
        [onChange]
    );

    const handleFocus = useCallback(() => {
        if (onStartEdit) {
            onStartEdit();
        }
    }, [onStartEdit]);

    const handleBlur = useCallback(() => {
        if (onStopEdit) {
            onStopEdit();
        }
    }, [onStopEdit]);

    useEffect(() => {
        if (!initialContent) {
            manager.view.updateState(manager.createState({content: ''}));
        }
    }, [initialContent]);

    return (
        <div className={classes.markdownEditorWrapper}>
            <Remirror
                classNames={[classes.mention, classes.placeholder]}
                manager={manager}
                editable={!readOnly}
                onFocus={handleFocus}
                onBlur={handleBlur}
                initialContent={initialContent}
                placeholder={placeholder}
            >
                <OnChangeHTML onChange={handleChange} />
                {forceExpand ? <EditorToolbar buttons={buttons} customButtons={toolbarCustomButtons} /> : null}
                <EditorComponent />
                <Suggestor users={users} />
            </Remirror>
        </div>
    );
}
