type Enum = {[name: string]: any};

export const toEnumString = <T extends Enum>(enumObject: T, key: string | number): string => {
    if (typeof key === 'string') {
        return enumObject[enumObject[key]];
    } else {
        return enumObject[key];
    }
};

const getEnumKeys = <T extends Enum>(enumObject: T) => {
    return Object.keys(enumObject).filter(key => isNaN(Number(key)));
};

export const getEnumValues = <T extends Enum>(enumObject: T) => {
    return getEnumKeys(enumObject).map(key => enumObject[key]);
};

export const formatCamelCaseEnumValue = (value: string): string => {
    return value
        ?.split(/(?=[A-Z])/)
        .map(upperFirst)
        .map(upperAbbreviations)
        .join(' ')
        .replace(/([A-Z]+) (?=([A-Z]($|\s)))/g, '$1');
};

export const formatSnakeCaseEnumValue = (value: string): string => {
    return value?.split('_')?.map(upperFirst)?.map(upperAbbreviations)?.join(' ');
};

const upperFirst = (str: string) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1, str.length).toLowerCase();
};

const upperAbbreviations = (str: string): string => {
    const abbreviations: string[] = ['EDD', 'KYC'];
    const abbreviationsRegexp = new RegExp(`\\b${abbreviations.join('|')}\\b`, 'gi');
    return str.replaceAll(abbreviationsRegexp, match => match.toUpperCase());
};
