import React from 'react';
import {defineMessages} from 'react-intl';

import {formatDate} from '@utils';

import {ConfigurationKeyValuePair} from 'src/common/types';
import actions from '../../legal-docs/actions';
import {LegalDocs} from '../../legal-docs/components/LegalDocs';
import {termsSelector} from '../../legal-docs/selectors';
import {domain} from '../../legal-docs/types';
import {LegalDocType, LegalDocumentItem} from '../../legal-docs/types';
import {WorkspaceDetailsComponentProps} from '../types';

import {WorkspaceKeyValueTable} from './WorkspaceKeyValueTable';

const getTableRows = (document: LegalDocumentItem): ConfigurationKeyValuePair[] => [
    {
        field: nameof<LegalDocumentItem>(l => l.content),
        key: localizedUserTermsAndConditions.userTermsAndConditionsAcceptanceVersion,
        value: <LegalDocs uid={document?.uid} legalDocType={LegalDocType.TermsAndConditions} document={document} />,
        configuration: [],
    },
    {
        field: nameof<LegalDocumentItem>(l => l.acceptedAt),
        key: localizedUserTermsAndConditions.userTermsAndConditionsDateAccepted,
        value: formatDate(document?.acceptedAt, 'date-time'),
        configuration: [],
    },
];

const localizedUserTermsAndConditions = defineMessages({
    userTermsAndConditionsHeader: {
        id: 'UTAC_userTermsAndConditionsHeader',
        defaultMessage: 'Terms and Conditions',
    },
    userTermsAndConditionsDateAccepted: {
        id: 'UTAC_userTermsAndConditionsDateAccepted',
        defaultMessage: 'Date Accepted',
    },
    userTermsAndConditionsAcceptanceVersion: {
        id: 'UTAC_userTermsAndConditionsAcceptanceVersion',
        defaultMessage: 'Acceptance Version',
    },
});

export const UserTermsAndConditions = ({itemId, className, fields, isUnderlined, hideTitle}: WorkspaceDetailsComponentProps) => {
    return (
        <WorkspaceKeyValueTable
            itemId={itemId}
            domain={domain}
            className={className}
            header={localizedUserTermsAndConditions.userTermsAndConditionsHeader}
            fields={fields}
            getTableRows={getTableRows}
            dataSelector={termsSelector}
            hideTitle={hideTitle}
            isUnderlined={isUnderlined}
            columnsCount={2}
            requestAction={actions.loadAcceptedTerms.request}
        />
    );
};
