import {combineReducers} from 'redux';
import {createReducer} from 'typesafe-actions';

import {loadModulesAction, selectModuleAction} from './actions';
import {Module} from './types';

export const modulesListReducer = createReducer([] as Module[]).handleAction(loadModulesAction.success, (_, action) => action.payload);
export const selectedModuleReducer = createReducer('' as string).handleAction(selectModuleAction, (_, action) => action.payload);

const modulesReducer = combineReducers({
    items: modulesListReducer,
    currentModuleName: selectedModuleReducer,
});

export default modulesReducer;
export type ModulesState = ReturnType<typeof modulesReducer>;
