import React, {useEffect, useState} from 'react';
import Autosizer from 'react-virtualized-auto-sizer';
import {FixedSizeList} from 'react-window';

import {TreeView} from '@components/tree-view/TreeView';
import {TreeOption, TreeViewItemExpandableOption} from '@components/tree-view/TreeViewItemExpandableOption';

type VirtualizedTreeViewExpandableOptionProps = {
    options: TreeOption[];
    className?: string;
    onItemClick?: (value: string) => void;
    selectedValue?: string;
    itemSize?: number;
};

export function VirtualizedExpandableTreeView({
    options,
    className,
    onItemClick,
    selectedValue,
    itemSize = 44,
}: VirtualizedTreeViewExpandableOptionProps) {
    const [flatOptions, setFlatOptions] = useState<TreeOption[]>([]);
    const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set());

    const updatedFlatOptions: TreeOption[] = mapToFlatOptions(options);
    const optionsIds: string[] = updatedFlatOptions?.map(o => o.value);
    useEffect(() => {
        setFlatOptions(updatedFlatOptions);
        setExpandedIds(new Set(optionsIds));
    }, [optionsIds?.join()]);

    function mapToFlatOptions(options: TreeOption[], depth = 0, parentId: string = null): TreeOption[] {
        const result: TreeOption[] = [];
        options?.forEach((option, index) => {
            option.depth = depth;
            option.parentId = parentId;
            option.isLastChild = options?.length - 1 === index;
            result.push(option, ...mapToFlatOptions(option.subOptions, depth + 1, option.value));
        });
        return result;
    }

    function handleExpand(e: React.MouseEvent<Element, MouseEvent>, nodeId: string) {
        e.stopPropagation();
        let newList: TreeOption[];
        const index = flatOptions.findIndex(i => i.value === nodeId);
        if (expandedIds.has(nodeId)) {
            removeIdFromExpanded(nodeId);
            newList = getListAfterCollapse(flatOptions, index);
        } else {
            addIdFromExpanded(nodeId);
            newList = getListAfterExpand(flatOptions, index);
        }
        setFlatOptions(newList);
    }

    function getListAfterCollapse(list: TreeOption[], collapsedIndex: number): TreeOption[] {
        const result: TreeOption[] = [...list];
        const nextVisibleIndexAfterCollapsed = list.slice(collapsedIndex + 1).findIndex(i => i.depth <= list[collapsedIndex].depth);

        const collapsedItemsCount =
            nextVisibleIndexAfterCollapsed > -1
                ? list.slice(collapsedIndex + 1, nextVisibleIndexAfterCollapsed + collapsedIndex + 1)?.length
                : list.slice(collapsedIndex + 1).length;

        result.splice(collapsedIndex + 1, collapsedItemsCount);
        return result;
    }

    function getListAfterExpand(list: TreeOption[], expandedIndex: number): TreeOption[] {
        const start = list.slice(0, expandedIndex + 1);
        const mid = getSubExpandedItems(flatOptions[expandedIndex].subOptions);
        const end = flatOptions.slice(expandedIndex + 1);
        function getSubExpandedItems(options: TreeOption[]) {
            const result: TreeOption[] = [];
            options?.forEach(option => {
                result.push(option, ...(expandedIds.has(option.value) ? getSubExpandedItems(option.subOptions) : []));
            });
            return result;
        }

        return [start, mid, end].flat();
    }

    function removeIdFromExpanded(id: string) {
        setExpandedIds(prevState => {
            prevState.delete(id);
            return new Set(prevState);
        });
    }
    function addIdFromExpanded(id: string) {
        setExpandedIds(prevState => {
            prevState.add(id);
            return new Set(prevState);
        });
    }

    const optionsStorage: Record<string, TreeOption> = {};
    flatOptions?.forEach(option => {
        optionsStorage[option.value] = option;
    });

    function getExistenceOfParentDepthLines(item: TreeOption): boolean[] {
        const parent = optionsStorage[item.parentId];
        return parent ? [...getExistenceOfParentDepthLines(parent), !parent?.isLastChild] : [];
    }

    return (
        <Autosizer>
            {({height, width}) => (
                <TreeView>
                    <FixedSizeList height={height} width={width} itemSize={itemSize} itemCount={flatOptions?.length} className={className}>
                        {({index, style}) => {
                            const item = flatOptions[index];
                            return (
                                <TreeViewItemExpandableOption
                                    label={item.label}
                                    depth={item.depth}
                                    nodeId={item.value}
                                    onClick={onItemClick}
                                    onExpand={e => handleExpand(e, item.value)}
                                    style={style}
                                    parentsDepthLines={getExistenceOfParentDepthLines(item)}
                                    isLastChild={item.isLastChild}
                                    isExpanded={expandedIds?.has(item.value)}
                                    isSelected={selectedValue === item.value}
                                    hasSubOptions={!!item?.subOptions?.length}
                                />
                            );
                        }}
                    </FixedSizeList>
                </TreeView>
            )}
        </Autosizer>
    );
}
