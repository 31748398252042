import {useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {useConfig} from '../../../configuration';
import {configAsia} from '../../../configuration/configAsia';
import {allPermissions} from '../../module-shared/types';
import {Module} from '../../modules/types';

import {JurisdictionConfiguration, JurisdictionFeature} from './types';

export const getJurisdictionFeaturePermissions = (limitedFeatures: JurisdictionFeature[], checkedFeature: JurisdictionFeature) => {
    const isModule = (limited: JurisdictionFeature) => limited.moduleName && !limited.submoduleName && !limited.featureName;
    const isSubmodule = (limited: JurisdictionFeature) => limited.moduleName && limited.submoduleName && !limited.featureName;
    const isFeature = (limited: JurisdictionFeature) => limited.featureName;

    const checkModuleName = (limited: JurisdictionFeature) => limited.moduleName === checkedFeature.moduleName;
    const checkSubModuleName = (limited: JurisdictionFeature) => limited.submoduleName === checkedFeature.submoduleName;
    const checkFeatureName = (limited: JurisdictionFeature) => limited.featureName === checkedFeature.featureName;

    const checkFeaturePermissions = (limited: JurisdictionFeature) => {
        const checkedPermissions = (f: JurisdictionFeature) =>
            f.permissions?.length ? f.permissions.flatMap(p => (p === PermissionEnum.All ? allPermissions : [p])) : allPermissions;

        const availablePermissions = checkedPermissions(checkedFeature).filter(p => !checkedPermissions(limited).includes(p));

        return availablePermissions;
    };

    const featurePredicates = [
        (f: JurisdictionFeature) => isModule(f) && checkModuleName(f),
        (f: JurisdictionFeature) => isSubmodule(f) && checkSubModuleName(f),
        (f: JurisdictionFeature) => isFeature(f) && checkFeatureName(f),
    ];

    const limitedFeature = limitedFeatures?.find(f => featurePredicates.find(p => p(f)));
    const limitedPermissions = limitedFeature ? checkFeaturePermissions(limitedFeature) : checkedFeature.permissions ?? allPermissions;

    return limitedPermissions;
};

export const useJurisdictionConfig = () => {
    const {application} = useConfig();
    const jurisdictionConfig = useSelector((s: RootState) => s.jurisdictionConfig as JurisdictionConfiguration);
    return application === 'agents' ? configAsia : jurisdictionConfig;
};

export const useJurisdictionFeature = (feature: JurisdictionFeature) => {
    const checkAvailability = useJurisdictionFeatureChecker();
    return checkAvailability(feature);
};

export const useJurisdictionFeaturePermissions = (feature: JurisdictionFeature) => {
    const getPermissions = useJurisdictionFeaturePermissionsChecker();
    return getPermissions(feature);
};

export const useJurisdictionFeatureChecker = () => {
    const {limitedFeatures: hiddenFeatures} = useJurisdictionConfig();
    return (feature: JurisdictionFeature) => {
        const availablePermissions = getJurisdictionFeaturePermissions(hiddenFeatures, feature);
        const featurePermissions = feature?.permissions ?? allPermissions;
        return featurePermissions?.every(p => availablePermissions.includes(p));
    };
};

export const useJurisdictionFeaturePermissionsChecker = () => {
    const {limitedFeatures: hiddenFeatures} = useJurisdictionConfig();
    return (feature: JurisdictionFeature) => getJurisdictionFeaturePermissions(hiddenFeatures, feature);
};

export const filterJurisdictionModules = (hiddenFeatures: JurisdictionFeature[], modules: Module[]) => {
    const getModule = (name: string) => Object.values(ModuleName).find((v: string) => v === name);
    const getSubmodule = (name: string) => Object.values(SubmoduleName).find((v: string) => v === name);

    return modules?.filter(
        m =>
            getJurisdictionFeaturePermissions(hiddenFeatures, {
                moduleName: m.parent ? getModule(m.parent.name) : getModule(m.name),
                submoduleName: m.parent ? getSubmodule(m.name) : undefined,
            })?.length > 0
    );
};
