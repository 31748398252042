import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useClasses = makeStyles()(theme => ({
    layoutDetailsItemGroup: {
        display: 'flex',
        gap: theme.spacing(1),
        '& > *': {
            flex: 1,
        },
    },
    LayoutDetailsItemGroupColumn: {
        flexDirection: 'column',
    },
}));

type LayoutDetailsItemGroupProps = {
    id?: string;
    children: React.ReactNode;
    orientation: 'horizontal' | 'vertical';
};

export function LayoutDetailsItemGroup({children, id, orientation}: LayoutDetailsItemGroupProps) {
    const {classes, cx} = useClasses();

    return (
        <Box
            className={cx({[classes.LayoutDetailsItemGroupColumn]: orientation === 'vertical'}, classes.layoutDetailsItemGroup)}
            data-testid={id}
        >
            {children}
        </Box>
    );
}
