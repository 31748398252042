import React from 'react';
import {Box, CheckboxProps} from '@mui/material';
import {TreeItem, TreeItemProps} from '@mui/x-tree-view/TreeItem';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {StyledCheckbox} from '../checkbox/StyledCheckbox';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    treeViewItemCheckboxContent: {
        padding: 0,
    },

    treeViewItemCheckboxGroup: {
        padding: theme.spacing(0.5, 0),
    },

    treeViewItemCheckboxIcon: {
        '& .MuiTreeItem-iconContainer': {
            display: 'none',
        },
    },

    treeViewItemCheckboxDisabled: {
        opacity: '0.5',
    },
    treeViewItemCheckbox: {
        display: 'flex',
    },
    treeViewItemCheckboxLabel: {
        flex: 1,
        margin: 'auto',
    },
}));

type TreeViewItemCheckboxProps = TreeItemProps & CheckboxProps & {};

export function TreeViewItemCheckbox({value, label, nodeId, disabled, ...otherProps}: TreeViewItemCheckboxProps) {
    const {classes, cx} = useStyles();
    const isChecked = value === true;
    return (
        <TreeItem
            nodeId={nodeId}
            classes={{
                content: cx(classes.treeViewItemCheckboxContent, classes.treeViewItemCheckboxIcon),
                group: cx(classes.treeViewItemCheckboxGroup, disabled && classes.treeViewItemCheckboxDisabled),
            }}
        >
            <Box className={classes.treeViewItemCheckbox}>
                <StyledCheckbox
                    checked={isChecked}
                    disabled={disabled}
                    checkedIconType={disabled && isChecked ? 'minus' : 'checkmark'}
                    {...otherProps}
                ></StyledCheckbox>
                <Box className={classes.treeViewItemCheckboxLabel}>{label}</Box>
            </Box>
        </TreeItem>
    );
}
