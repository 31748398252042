import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    keyValueColumnGrid: {
        display: 'grid',
        columnGap: theme.spacing(1),
        gridAutoRows: 'min-content',
    },
    keyValueColumnGridSM: {
        gridTemplateColumns: 'minmax(100px, max-content) auto',
    },
    keyValueColumnGridMD: {
        gridTemplateColumns: 'minmax(165px, max-content) auto',
    },
    keyValueColumnGridLG: {
        gridTemplateColumns: 'minmax(200px, max-content) auto',
    },
    keyValueColumnFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

type KeyWidth = 'sm' | 'md' | 'lg';

type KeyValueColumnProps = {
    children: React.ReactNode;

    className?: string;
    isGrid?: boolean;
    keyWidth?: KeyWidth;
};

export function KeyValueColumn({children, isGrid = true, className = '', keyWidth = 'md'}: KeyValueColumnProps) {
    const {classes, cx} = useClasses();
    const keyValueColumnGridClasses = cx({
        [classes.keyValueColumnGrid]: true,
        [classes.keyValueColumnGridSM]: keyWidth === 'sm',
        [classes.keyValueColumnGridMD]: keyWidth === 'md',
        [classes.keyValueColumnGridLG]: keyWidth === 'lg',
    });
    return <Box className={cx(isGrid ? keyValueColumnGridClasses : classes.keyValueColumnFlex, className)}>{children}</Box>;
}
