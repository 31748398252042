import {BulkActionKey, usePerformBulkOperation} from '../block-bulk-actions';

import {ServiceTypes} from './types';

export function usePerformKYCActions() {
    return {
        initPaymentKYC: usePerformBulkOperation(ServiceTypes.PerformKYCInitStrategy, BulkActionKey.InitiatePaymentKYC),
        rejectKYC: usePerformBulkOperation(ServiceTypes.PerformKYCRejectStrategy, BulkActionKey.KYCReject),
    };
}
