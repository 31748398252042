import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {useBreadcrumb} from '../../app/breadcrumb/hooks';
import BackButton from '../../module-shared/components/BackButton';
import Breadcrumb from '../../module-shared/components/BreadCrumb';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    workspaceHeader: {
        display: 'flex',
        height: theme.custom.pageHeaderHeight,
        minHeight: theme.custom.pageHeaderHeight,
        backgroundColor: theme.palette.background.paper,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.custom.smallSizesNavbarHeight,
        },
        padding: theme.spacing(0, 4, 0),
    },
    workspaceHeaderBreadcrumbs: {
        marginLeft: theme.spacing(2),
    },
    workspaceHeaderInfo: {
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: theme.spacing(4),
    },
}));

type WorkspaceHeaderProps = {
    children: React.ReactNode;
};

export const WorkspaceHeader = ({children}: WorkspaceHeaderProps) => {
    const {classes} = useClasses();
    const breadcrumbs = useBreadcrumb();

    return (
        <Box className={classes.workspaceHeader}>
            <BackButton />
            <Box className={classes.workspaceHeaderBreadcrumbs}>
                <Breadcrumb breadcrumbs={breadcrumbs} />
            </Box>
            <Box className={classes.workspaceHeaderInfo}>{children}</Box>
        </Box>
    );
};
