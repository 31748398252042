import React, {useEffect} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import Typography from '@mui/material/Typography';
import {makeStyles} from 'tss-react/mui';

import Button, {OutlinedButton} from '@components/button/Buttons';
import {KeyValueFilter} from '@components/filter/types';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {UserProfileViewModel} from '@models/user-profile/types';
import {BonusCodeServerFilterKeys, UserProfileServerFilterKeys} from '@redux/entity';
import {getNonEmptyValueValidator, ViewType} from '@redux/view';
import {CustomTheme} from '@style';

import {bulkActionsActions, BulkStepProps} from '../../block-bulk-actions';
import {BulkBonusModalTitleBreadcrumb} from '../../block-player-bonus-bulk-actions';
import {DataGridUserProfileClient, UserProfileGridItem, UserProfileQueryFilters, useUserProfiles} from '../../block-user-profile-list';
import {localizedFilters} from '../../shared/filter';
import {useBonusCodes} from '../hooks';
import {BonusCodeDetailsViewModel, BonusCodeDetailsViewModelKeys, BonusTriggerSelectedItems} from '../types';

type UseMultiTriggerBonusUsersProps = {
    selectedBonusesIds: string[];
};

export type SelectedItem = {bonusId: string; playerIds: string[]};

type UseMultiTriggerBonusUsersResult = {
    bonuses: BonusCodeDetailsViewModel[];
    users: UserProfileGridItem[];
    userProfileKeys: UserProfileViewModelKeys[];
    filterString: string;
    handleFilterChange: (filters: KeyValueFilter<UserProfileServerFilterKeys>[]) => void;
    selected: SelectedItem[];
    handleSelect: (bonusId: string, uids: string[]) => void;
    selectedUniqueUsersCount: number;
    setSelectedItems: () => void;
};

export function useBonusSelectUsersStep({selectedBonusesIds}: UseMultiTriggerBonusUsersProps): UseMultiTriggerBonusUsersResult {
    const dispatch = useDispatch();
    const [selected, setSelectedIds] = React.useState<SelectedItem[]>([]);

    const viewType: ViewType = 'BulkMultiBonusSelectUsers';
    const bonusCodeKeys: BonusCodeDetailsViewModelKeys[] = ['bonus_id', 'bonus_name'];
    const {items: bonuses} = useBonusCodes({
        ids: selectedBonusesIds,
        viewType,
        fields: bonusCodeKeys,
        validateFilter: getNonEmptyValueValidator<BonusCodeServerFilterKeys>('bonusId'),
    });
    const userProfileKeys: UserProfileViewModelKeys[] = ['uid', 'account_status', 'deposit_status', 'withdrawal_status', 'security_cases'];
    const maxUsersCount = 200;
    const {
        items: users,
        handleFilterChange,
        filterString,
    } = useUserProfiles({
        viewType,
        fields: userProfileKeys,
        defaultPaging: {page: 1, pageSize: maxUsersCount},
        validateFilter: getNonEmptyValueValidator<UserProfileServerFilterKeys>('uid'),
    });

    const userIds = users?.map(u => u.uid);

    useEffect(() => {
        selectedBonusesIds?.forEach(id => {
            handleSelect(id, userIds);
        });
    }, [userIds.join()]);

    function handleSelect(bonusId: string, uids: string[]) {
        setSelectedIds(prevState => [...prevState.filter(i => i.bonusId !== bonusId), {bonusId, playerIds: uids}]);
    }

    function setSelectedItems() {
        const selectedObject: Record<string, BonusTriggerSelectedItems> = selected.reduce((items, selectedItem) => {
            let result: Record<string, BonusTriggerSelectedItems> = items;
            const bonusId = selectedItem.bonusId;
            const bonusViewModelItem = bonuses?.find(b => b.bonus_id === bonusId);
            const bonusItem = {id: bonusViewModelItem.bonus_id, name: bonusViewModelItem.bonus_name};

            const userIds = selectedItem.playerIds;
            userIds?.forEach(uid => {
                const userItem: UserProfileViewModel = users?.find(u => u.uid === uid) as unknown as UserProfileViewModel;
                result = {
                    ...result,
                    [uid]: {...userItem, bonuses: result[uid] ? [...result[uid].bonuses, bonusItem] : [bonusItem]},
                };
            });
            return result;
        }, {} as Record<string, BonusTriggerSelectedItems>);
        dispatch(bulkActionsActions.setBulkActionsItems(Object.values(selectedObject)));
    }

    const selectedUniqueUsersCount = [...new Set(selected.map(i => i.playerIds).flat())].length;

    return {
        bonuses,
        users,
        userProfileKeys,
        filterString,
        handleFilterChange,
        selectedUniqueUsersCount,
        selected,
        handleSelect,
        setSelectedItems,
    };
}

export const localizedBulkSelectUsersModal = defineMessages({
    cancelButtonLabel: {
        id: 'BSSU_bulkStepSelectUsersCancelBulkAction',
        defaultMessage: 'Cancel',
    },
    confirmButtonLabel: {
        id: 'BSSU_bulkStepSelectUsersConfirmBulkAction',
        defaultMessage: 'Confirm',
    },
    itemsSelectedMessage: {
        id: 'BSSU_bulkStepSelectUsersItemsSelected',
        defaultMessage: '{value} Items Selected',
    },
    emptyListMessage: {
        id: 'BSSU_emptyList',
        defaultMessage: 'Player list not yet added. You can add a list of users to the field by specifying player ID.',
    },
    bulkStepSelectUsersDisplayName: {
        id: 'BSSU_displayName',
        defaultMessage: 'Trigger Bonus Select Users Step',
    },
});

const useStyles = makeStyles()((theme: CustomTheme) => ({
    bulkMultiBonusSelectUsersModelSubmitButton: {
        minWidth: theme.spacing(4),
        maxHeight: theme.custom.denseButtonHeight,
    },
    bulkMultiBonusSelectUsersModelFilterContainer: {
        margin: theme.spacing(2.5, 0, 0),
    },
    bulkMultiBonusSelectUsersModelBonusTitle: {
        margin: theme.spacing(2.5, 0, 2, 2.5),
    },
}));

type BulkBonusSelectUsersModalProps = BulkStepProps & {selectedBonusesIds: string[]};

export function BulkBonusSelectUsersModal({selectedBonusesIds, onPrev, onNext}: BulkBonusSelectUsersModalProps) {
    const {classes} = useStyles();
    const {formatMessage} = useIntl();

    const {
        bonuses,
        users,
        userProfileKeys,
        filterString,
        handleFilterChange,
        selected,
        handleSelect,
        selectedUniqueUsersCount,
        setSelectedItems,
    } = useBonusSelectUsersStep({selectedBonusesIds});

    function handleCancelClick() {
        onPrev();
    }

    function handleNextClick() {
        setSelectedItems();
        onNext();
    }

    const rowsPerPage = 100;

    return (
        <>
            <BulkModalTitle children={<BulkBonusModalTitleBreadcrumb />} />
            <BulkModalContent>
                <div className={classes.bulkMultiBonusSelectUsersModelFilterContainer}>
                    <UserProfileQueryFilters
                        model={filterString}
                        onChange={handleFilterChange}
                        availableFilters={['uid']}
                        viewMode="flex"
                        submitButton={
                            <OutlinedButton
                                type="submit"
                                className={classes.bulkMultiBonusSelectUsersModelSubmitButton}
                                color="primary"
                                label={localizedFilters.submitButtonLabel}
                                data-testid="textInputSubmitButton"
                            />
                        }
                    />
                </div>
                <div>
                    {bonuses?.map(bonus => (
                        <>
                            <div className={classes.bulkMultiBonusSelectUsersModelBonusTitle}>
                                <Typography variant="h4">{bonus.bonus_name}</Typography>
                            </div>
                            <DataGridUserProfileClient
                                columns={userProfileKeys}
                                rows={users}
                                rowsPerPageOptions={[rowsPerPage]}
                                checkboxSelection={true}
                                selectedIds={selected.find(i => i.bonusId === bonus?.bonus_id)?.playerIds ?? []}
                                onSelect={(ids: string[]) => handleSelect(bonus?.bonus_id, ids)}
                                hidePagination={rowsPerPage >= users?.length}
                                emptyMessage={localizedBulkSelectUsersModal.emptyListMessage}
                                autoHeight
                            />
                        </>
                    ))}
                </div>
            </BulkModalContent>
            <ModalFooter
                infoMessage={
                    selectedUniqueUsersCount
                        ? formatMessage(localizedBulkSelectUsersModal.itemsSelectedMessage, {
                              value: selectedUniqueUsersCount,
                          })
                        : ''
                }
            >
                <Button label={localizedBulkSelectUsersModal.cancelButtonLabel} onClick={handleCancelClick} />
                <Button
                    color="primary"
                    label={localizedBulkSelectUsersModal.confirmButtonLabel}
                    onClick={handleNextClick}
                    disabled={!selectedUniqueUsersCount}
                />
            </ModalFooter>
        </>
    );
}
