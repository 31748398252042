import React from 'react';
import {makeStyles} from 'tss-react/mui';

import {FormTextInputDefault, FormTextInputDefaultProps} from '@components/input/FormInput';

import {RuleFormConditionProps, withRuleFormCondition} from './RuleFormConditionHoc';

const useStyles = makeStyles()({
    ruleFormConditionInput: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            paddingLeft: 0,
        },
    },
});

const RuleConditionInputInternal = withRuleFormCondition(FormTextInputDefault);
export function RuleFormConditionInput(props: RuleFormConditionProps & Pick<FormTextInputDefaultProps<unknown>, 'type'>) {
    const {classes} = useStyles();
    return (
        <RuleConditionInputInternal
            hasBottomSpacing={false}
            fullWidth={false}
            textAlign="right"
            placeholder="0"
            className={classes.ruleFormConditionInput}
            requiredValidation
            {...props}
        />
    );
}
