import ExcelJS from 'exceljs';
import {from, Observable} from 'rxjs';

import {DataWorksheetColDefs} from '../types';

import {BaseExcelDocumentBuilder} from './BaseExcelDocumentBuilder';

type ParseResult<TKeys extends string> = Record<TKeys | 'rowIndex', string>;

export class XlsxDocumentBuilder extends BaseExcelDocumentBuilder {
    public parseFile<TKeys extends string>(
        file: File,
        columns: DataWorksheetColDefs<TKeys>
    ): Observable<Record<TKeys | 'rowIndex', string>[]> {
        const promise = new Promise<ParseResult<TKeys>[]>((resolve, reject) => {
            const workbook = new ExcelJS.Workbook();
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = () => {
                workbook.xlsx.load(reader.result as ArrayBuffer).then(workbook => {
                    const result: ParseResult<TKeys>[] = [];
                    workbook.eachSheet(sheet => {
                        try {
                            if (sheet.state === 'visible') {
                                result.push(...this.parseDataSheet(sheet, columns));
                            }
                        } catch (e) {
                            reject(e);
                        }
                    });
                    resolve(result);
                });
            };
        });
        return from(promise);
    }

    public getDocument(): ExcelJS.Xlsx {
        const result = this.workbook.xlsx;
        this.reset();

        return result;
    }
}
