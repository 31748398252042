import React from 'react';

import {DownloadCsvButton} from 'src/features/block-download';
import {useBonusCodeDownload, UseBonusCodeDownloadProps} from '../hooks';

type BonusCodeDownloadButtonProps = UseBonusCodeDownloadProps;

export function BonusCodeDownloadButton(props: BonusCodeDownloadButtonProps) {
    const {handleClick, isProgress} = useBonusCodeDownload(props);
    return <DownloadCsvButton onClick={handleClick} loading={isProgress} />;
}
