import React from 'react';

import {IModuleGridItem} from '@components/data-grid';
import {FeatureAccessStatus, PlayerBonus, SecurityCaseType, UserAccountStatus, UserProfile} from '@models/generated/graphql';

import {DataGridUserProfileClient} from '../../../features/block-user-profile-list';
import {DataGridUserProfileWithPlayerBonusClient} from '../../../features/data-grids/components/DataGridUserProfileWithPlayerBonus';

import {Wrapper} from './WrapperDev';

export const EntityDataGridsDev = () => {
    const rows: (UserProfile & PlayerBonus & IModuleGridItem)[] = [
        ...[...Array(50).keys()].map(i => ({
            uid: `1111111${i}`,
            id: `1111111${i}`,
            serverId: `1111111${i}`,
            isNew: false,
            account_status: i % 2 === 1 ? UserAccountStatus.Active : UserAccountStatus.Locked,
            deposit_status: i % 9 === 8 ? FeatureAccessStatus.Unlocked : FeatureAccessStatus.Locked,
            withdrawal_status: FeatureAccessStatus.Locked,
            security_cases: [
                {case_id: `COL_23483276_${i}`, type: SecurityCaseType.Collusion},
                {case_id: 'BOT_23483276_1', type: SecurityCaseType.Bot},
            ],
            bonus_engine_id: '',
            bonus_id: '',
            bonus_engine_code: '',
            bonus_status: null,
            created_at: null,
            expire_at: null,
            marketing_code: 'code',
            total_amount: 1000,
        })),
    ];

    return (
        <Wrapper label="Entity Data Grids">
            <DataGridUserProfileClient
                columns={['uid', 'account_status', 'deposit_status', 'security_cases']}
                rows={rows}
                rowsPerPageOptions={[10]}
                selectedIds={['1111111']}
                style={{height: '400px'}}
            ></DataGridUserProfileClient>

            <DataGridUserProfileWithPlayerBonusClient
                columns={['uid', 'account_status', 'deposit_status', 'security_cases', 'total_amount', 'marketing_code']}
                rows={rows}
                rowsPerPageOptions={[100]}
                selectedIds={['11111111']}
                style={{height: '400px'}}
                checkboxSelection={true}
                onSelect={ids => {
                    console.log(ids);
                }}
            ></DataGridUserProfileWithPlayerBonusClient>
        </Wrapper>
    );
};
