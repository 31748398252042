import React from 'react';
import {MessageDescriptor} from 'react-intl';

import LocalizedText from '@components/i18n/LocalizedText';
import {LayoutDetailsActionsCollapsible} from '@components/layout';
import {agentKrReadPolicy, agentVnReadPolicy} from '@models/permissions/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';

import {UserProfileActions, UserProfileActionsProps} from './UserProfileActions';

type UserProfileActionsCollapsedProps = UserProfileActionsProps & {
    title: string | MessageDescriptor;
};

const UserProfileActionsCollapsed = ({title, ...props}: UserProfileActionsCollapsedProps) => {
    return (
        <LayoutDetailsActionsCollapsible header={<LocalizedText label={title} />}>
            <UserProfileActions {...props} />
        </LayoutDetailsActionsCollapsible>
    );
};

export default withMultiplePermission(UserProfileActionsCollapsed, {
    restrictedPermissions: [agentVnReadPolicy, agentKrReadPolicy],
});
