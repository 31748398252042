import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {UserContactInfo} from '@models/generated/graphql';

export type ModifiedEmailValue = {
    contact: UserContactInfo;
};

export const useModifiedEmailFormatter = (json: string): ChipKeyValue[] => {
    const value: ModifiedEmailValue = JSON.parse(json);

    return value?.contact?.email ? [{key: value.contact.email}] : null;
};
