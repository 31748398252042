import {ChipType, ChipVariant} from '@components/chip/types';
import {GeoCheckStatus} from '@models/generated/graphql';

import {localizedGeoCheckStatus} from '../../../features/app/intl/shared-resources/geoCheckStatus';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export const getGeoCheckStatusChipsMapping = () => ({
    [GeoCheckStatus.Success]: {
        label: localizedGeoCheckStatus[GeoCheckStatus.Success],
        chipType: ChipType.Status,
        chipVariant: ChipVariant.Green,
    },
    [GeoCheckStatus.Failed]: {
        label: localizedGeoCheckStatus[GeoCheckStatus.Failed],
        chipType: ChipType.Status,
        chipVariant: ChipVariant.Disabled,
    },
});
