import {ChipProps as MuiChipProps} from '@mui/material';

import {SystemColorsEnum} from '@style';

export enum ChipType {
    Tag = 'Tag',
    TagMark = 'TagMark',
    TagMarkIcon = 'TagMarkIcon',
    Status = 'Status',
    StatusV2 = 'StatusV2',
    Mark = 'Mark',
    Prefix = 'Prefix',
    Workspace = 'Workspace',
    Note = 'Note',
}

//TODO: [BO-2756] Rename ChipVariant to ColorOption and move to style.ts
export enum ChipVariant {
    Transparent = 'transparent',
    Disabled = 'disabled',
    Default = 'default',
    DefaultReversed = 'defaultReversed',
    LightBlue = 'lightBlue',
    Blue = 'blue',
    Bronze = 'bronze',
    Green = 'green',
    GreenDark = 'greenDark',
    LightGreen = 'lightGreen',
    Orange = 'orange',
    OrangeDark = 'orangeDark',
    Purple = 'purple',
    Turquoise = 'turquoise',
    Yellow = 'yellow',
    Grey = 'grey',
    LightGrey = 'lightGrey',
    DarkGrey = 'darkGrey',
    GreyInverted = 'greyInverted',
    BronzeInverted = 'bronzeInverted',
    Violet = 'violet',
    Coffee = 'coffee',
    DropdownDefault = 'dropdownDefault',
    PrimaryButton = 'primaryButton',
    Beige = 'beige',
    Mint = 'mint',
    YellowNeon = 'yellowNeon',
    Burgundy = 'burgundy',
    SkyBlue = 'skyBlue',
    Pink = 'pink',
    WarmYellow = 'warmYellow',
    Red = 'red',
}

export type ChipProps = MuiChipProps & {
    chipType: ChipType;
    chipVariant: ChipVariant | SystemColorsEnum | string;
    value?: string | number;
    startIconClass?: string;
    cursorPointer?: boolean;
    fillWidth?: boolean;
    isCrossedOut?: boolean;
};
