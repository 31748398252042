import {NormalizedCacheObject} from '@apollo/client';
import {GraphQLError} from 'graphql';

import {ApolloClientProxy} from '@services/gql-api';

import {getErrorCodeFromMessage} from '../../../features/app/error-handling/utils';
import {ColumnConfiguration, Filter, SearchFilter} from '../../types';
import {MutationError} from '../gql-api/gqlService';

import {BaseService} from './baseService';
import {GraphQLServiceBaseObsolete} from './gqlServiceBaseObsolete';
import {IGQlFilterVariables, IGQlSearchFilter} from './types';

/**
 * @deprecated should use services from src/common/services
 */
export abstract class GQLBasedService<TServerModel> extends BaseService {
    protected _client: GraphQLServiceBaseObsolete;

    constructor(client: ApolloClientProxy<NormalizedCacheObject>) {
        super();
        this._client = new GraphQLServiceBaseObsolete(client);
    }

    protected abstract getGQLVariables(
        filter: SearchFilter,
        filterMappingFunction?: (filters: Filter[]) => IGQlSearchFilter
    ): IGQlFilterVariables;

    protected mapModels<TClientModel>(clientModel: TClientModel, serverModel: TServerModel): TClientModel {
        return Object.assign(clientModel, serverModel);
    }

    protected getGQLConfiguration<TConfigurationModel extends ColumnConfiguration>(
        configuration: TConfigurationModel,
        fields?: string[]
    ): TConfigurationModel {
        fields?.forEach(key => {
            if (Object.prototype.hasOwnProperty.call(configuration, key)) {
                const configurationKey: keyof TConfigurationModel = key as keyof TConfigurationModel;
                configuration[configurationKey] = true as TConfigurationModel[keyof TConfigurationModel];
            }
        });

        return configuration as TConfigurationModel;
    }

    protected getGraphQLErrorCode = (error: GraphQLError) => getErrorCodeFromMessage(error?.message);

    protected getMutationErrorCode = (error: MutationError) => error?.errorType;
}
