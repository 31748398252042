import {ErrorConstant, invariant, isElementDomNode} from '@remirror/core';
import {marked} from 'marked';
import TurndownService from 'turndown';

// Turndow and marked configs are copied from remirror library and added mention handling

function isHeadingRow(tableRow: Node): tableRow is HTMLTableRowElement {
    const parentNode = tableRow.parentNode;

    if (!isElementDomNode(parentNode)) {
        return false;
    }

    if (parentNode.nodeName === 'THEAD') {
        return true;
    }

    if (parentNode.nodeName !== 'TABLE' && !isFirstTbody(parentNode)) {
        return false;
    }

    const childNodes: ChildNode[] = [];
    tableRow.childNodes.forEach(n => childNodes.push(n));
    return childNodes.every(n => n.nodeName === 'TH') && childNodes.some(n => !!n.textContent);
}

function isControllerHeadingCell(cell: unknown): cell is HTMLTableCellElement {
    return isElementDomNode(cell) && cell.matches('th[data-controller-cell]');
}

function isControllerHeadingRow(tableRow: Node): tableRow is HTMLTableRowElement {
    const parentNode = tableRow.parentNode;

    if (!isElementDomNode(parentNode)) {
        return false;
    }

    if (parentNode.nodeName !== 'TABLE' && !isFirstTbody(parentNode)) {
        return false;
    }

    const childNodes: ChildNode[] = [];
    tableRow.childNodes.forEach(n => childNodes.push(n));
    return childNodes.every(n => isControllerHeadingCell(n));
}

function isFirstTbody(element: Node): element is HTMLTableSectionElement {
    if (element.nodeName !== 'TBODY') {
        return false;
    }

    const previousSibling = element.previousSibling;

    if (!previousSibling) {
        return true;
    }

    return isElementDomNode(previousSibling) && previousSibling.nodeName === 'THEAD' && !previousSibling.textContent?.trim();
}

function isNestedTable(element: HTMLElement): boolean {
    const currentTable = element.closest('table');

    if (!currentTable) {
        return false;
    }

    const {parentNode} = currentTable;

    if (!parentNode) {
        return true;
    }

    return !!(parentNode as HTMLElement).closest('table');
}

function cell(content: string, node: Node) {
    const childNodes: ChildNode[] = [];
    node.parentNode?.childNodes.forEach(n => {
        if (isControllerHeadingCell(n)) {
            return;
        }

        childNodes.push(n);
    });

    const index = childNodes.indexOf(node as ChildNode);
    const prefix = index === 0 ? '| ' : ' ';

    return `${prefix + content.trim()} |`;
}

export const turndownService = new TurndownService({codeBlockStyle: 'fenced', headingStyle: 'atx'})
    .addRule('taskListItems', {
        filter: node => node.nodeName === 'LI' && node.hasAttribute('data-task-list-item'),
        replacement: (content, node) => {
            const isChecked = (node as HTMLElement).hasAttribute('data-checked');
            return `- ${isChecked ? '[x]' : '[ ]'} ${content.trimStart()}`;
        },
    })
    .addRule('tableCell', {
        filter: ['th', 'td'],
        replacement: (content, node) => {
            if (isControllerHeadingCell(node)) {
                return '';
            }

            return cell(content, node as ChildNode);
        },
    })
    .addRule('tableRow', {
        filter: 'tr',
        replacement: (content, node) => {
            let borderCells = '';
            const alignMap = {left: ':--', right: '--:', center: ':-:'};

            const childNodes: ChildNode[] = [];
            node.childNodes.forEach(n => {
                if (!isControllerHeadingCell(n)) {
                    childNodes.push(n);
                }
            });

            if (isHeadingRow(node)) {
                for (const childNode of childNodes) {
                    if (!isElementDomNode(childNode)) {
                        continue;
                    }

                    let border = '---';
                    const align = (childNode.getAttribute('align') ?? '').toLowerCase() as keyof typeof alignMap;

                    if (align) {
                        border = alignMap[align] || border;
                    }

                    borderCells += cell(border, childNode);
                }
            }

            return `\n${content}${borderCells ? `\n${borderCells}` : ''}`;
        },
    })
    .addRule('table', {
        filter: node => {
            if (node.nodeName !== 'TABLE') {
                return false;
            }

            if (isNestedTable(node)) {
                return false;
            }
            const nodeRows = (node as HTMLTableElement).rows;
            const rows = [];
            for (let i = 0; i < nodeRows.length; i++) {
                if (!isControllerHeadingRow(nodeRows[i])) {
                    rows.push(nodeRows[i]);
                }
            }

            return isHeadingRow(rows[0]);
        },

        replacement: content => {
            content = content.replace('\n\n', '\n');
            return `\n\n${content}\n\n`;
        },
    })
    .addRule('tableSection', {
        filter: ['thead', 'tbody', 'tfoot'],
        replacement: function (content) {
            return content;
        },
    })
    .keep(node => node.nodeName === 'TABLE' && !isHeadingRow((node as HTMLTableElement).rows[0] as any))
    .keep(node => node.nodeName === 'TABLE' && isNestedTable(node))
    .addRule('strikethrough', {
        filter: ['del', 's', 'strike' as 'del'],
        replacement: function (content) {
            return `~${content}~`;
        },
    })

    .addRule('fencedCodeBlock', {
        filter: (node, options) =>
            !!(options.codeBlockStyle === 'fenced' && node.nodeName === 'PRE' && node.firstChild && node.firstChild.nodeName === 'CODE'),

        replacement: (_, node, options) => {
            invariant(isElementDomNode(node.firstChild), {
                code: ErrorConstant.EXTENSION,
                message: `Invalid node \`${node.firstChild?.nodeName}\` encountered for codeblock when converting html to markdown.`,
            });

            const className = node.firstChild.getAttribute('class') ?? '';
            const language =
                className.match(/(?:lang|language)-(\S+)/)?.[1] ?? node.firstChild.getAttribute('data-code-block-language') ?? '';

            return `\n\n${options.fence}${language}\n${node.firstChild.textContent}\n${options.fence}\n\n`;
        },
    })

    // Custom rule for handling mentions
    .addRule('mention', {
        filter: node => {
            return node.nodeName === 'SPAN' && node.hasAttribute('data-mention-atom-id');
        },
        replacement: function (content, node) {
            return '[@' + content + '](' + (node as HTMLElement).getAttribute('data-mention-atom-id') + ')';
        },
    });

export function markedParse(markdown: string): string {
    marked.use({
        renderer: {
            list(body: string, isOrdered: boolean, start: number): string {
                if (isOrdered) {
                    const startAttr = start !== 1 ? `start="${start}"` : '';
                    return `<ol ${startAttr}>\n${body}</ol>\n`;
                }

                const taskListAttr = body.startsWith('<li data-task-list-item ') ? 'data-task-list' : '';
                return `<ul ${taskListAttr}>\n${body}</ul>\n`;
            },
            listitem(text: string, isTask: boolean, isChecked: boolean): string {
                if (!isTask) {
                    return `<li>${text}</li>\n`;
                }

                const checkedAttr = isChecked ? 'data-checked' : '';
                return `<li data-task-list-item ${checkedAttr}>${text}</li>\n`;
            },

            // Custom rule for handling mentions
            link(href, title, text) {
                if (href === null) {
                    return text;
                }

                let out = '';

                if (text[0] === '@') {
                    out += `<span class="remirror-mention-atom remirror-mention-atom-at" data-mention-atom-id="${href}" data-mention-atom-name="at">${text.slice(
                        1
                    )}</span>`;
                } else {
                    out += '<a href="' + href + '"';
                    if (title) {
                        out += ' title="' + title + '"';
                    }
                    out += '>' + text + '</a>';
                }
                return out;
            },
        },
    });
    return marked.parse(markdown, {gfm: true}) as string;
}
