import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';

import Button from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {ModalContext} from '@components/modal';
import {gameTemplateCreatePermissions} from '@models/permissions/permissions';
import {ManagedGameType} from '@redux/entity';

import {LackOfPermissionIndicator, withMultiplePermission} from 'src/features/app/permission/PermissionHoc';
import {useAsyncActionState} from 'src/features/shared/async-action/hooks';
import {gameTemplateActions} from '../actions';

import {GameTemplateModal} from './GameTemplateModal';

const localized = defineMessages({
    buttonLabel: {
        id: 'GameTemplateCreateButton_label',
        defaultMessage: 'Create Template',
    },
    modalTitle: {
        id: 'GameTemplateCreateButton_modalTitle',
        defaultMessage: 'Template Creation',
    },
});

type GameTemplateCreateButtonProps = {
    gameType: ManagedGameType;
};

function GameTemplateCreateButtonInner({gameType}: GameTemplateCreateButtonProps) {
    const {openModal, closeModal} = useContext(ModalContext);
    const {isProgress} = useAsyncActionState(gameTemplateActions.createTemplate);

    const handleClick = () => {
        openModal({
            title: <LocalizedText label={localized.modalTitle} />,
            body: <GameTemplateModal onClose={closeModal} mode="create" gameType={gameType} />,
        });
    };
    return <Button color="primary" label={localized.buttonLabel} onClick={handleClick} disabled={isProgress} />;
}

export const GameTemplateCreateButton = withMultiplePermission(GameTemplateCreateButtonInner, {
    allowedPermissions: gameTemplateCreatePermissions,
    indicator: LackOfPermissionIndicator.Hidden,
});
