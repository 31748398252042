import React, {useContext} from 'react';
import {defineMessages, IntlContext} from 'react-intl';

const localized = defineMessages({
    fileSizeByte: {
        id: 'FILE_SIZE_BYTE',
        defaultMessage: 'B',
    },
    fileSizeKilobyte: {
        id: 'FILE_SIZE_KILOBYTE',
        defaultMessage: 'KB',
    },
    fileSizeMegabyte: {
        id: 'FILE_SIZE_MEGABYTE',
        defaultMessage: 'MB',
    },
    fileSizeGigabyte: {
        id: 'FILE_SIZE_GIGABYTE',
        defaultMessage: 'GB',
    },
    fileSizeTerabyte: {
        id: 'FILE_SIZE_TERABYTE',
        defaultMessage: 'TB',
    },
});

export const useHumanFileSize = () => {
    const {formatMessage} = useContext(IntlContext);

    return (size: number): string => {
        if (size <= 0) return '0';
        const i = Math.floor(Math.log(size) / Math.log(1024));
        const num = (size / Math.pow(1024, i)) * 1;
        const metric = [
            formatMessage(localized.fileSizeByte),
            formatMessage(localized.fileSizeKilobyte),
            formatMessage(localized.fileSizeMegabyte),
            formatMessage(localized.fileSizeGigabyte),
            formatMessage(localized.fileSizeTerabyte),
        ][i];
        return num.toFixed(2) + ' ' + metric;
    };
};

type HumanFileSizeProps = {
    size: number;
};

export const HumanFileSize = ({size}: HumanFileSizeProps) => {
    const humanFileSize = useHumanFileSize();
    const fileSize = humanFileSize(size);
    return <>{fileSize}</>;
};
