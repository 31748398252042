import React from 'react';
import {defineMessages, FormattedNumber} from 'react-intl';
import {Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {Card} from '@components/card/Card';
import {RealtimeUpdatesMode} from '@redux/realtime';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {useUserProfileDetails} from '../../block-user-profile-details';

const localized = defineMessages({
    currentBalanceTitle: {
        id: 'CurrentBalance_title',
        defaultMessage: 'Current Balance',
    },
});

const useClasses = makeStyles()(theme => ({
    currentBalance: {
        marginTop: theme.spacing(5),
    },
}));

type CurrentBalanceProps = {
    uid: string;
};

export function CurrentBalance({uid}: CurrentBalanceProps) {
    const {classes} = useClasses();
    const {currency} = useJurisdictionConfig();
    const {item} = useUserProfileDetails({
        id: uid,
        fields: ['balance'],
        viewType: 'CurrentBalance',
        realtimeMode: RealtimeUpdatesMode.Silent,
    });

    return (
        <Card title={localized.currentBalanceTitle}>
            <Typography className={classes.currentBalance} variant="h5">
                <FormattedNumber value={item?.finance?.balance ?? 0} style="currency" currency={currency} />
            </Typography>
        </Card>
    );
}
