import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useDropzoneContentClasses = makeStyles()((theme: CustomTheme) => ({
    dropzoneContentContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dropzoneContentSuccess: {
        color: theme.palette.success.main,
    },
    dropzoneContentReject: {
        color: theme.custom.palette.status.bronze.main,
    },
    dropzoneContentReloadFile: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: theme.spacing(2.5),
    },
    dropzoneContentReloadFileButton: {
        fontWeight: 500,
    },

    dropzoneContentIcon: {
        marginBottom: theme.spacing(1.25),
    },
    dropzoneContentDefaultIcon: {
        fontSize: '3em',
    },
    dropzoneContentResultIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2.5),
        borderRadius: '100%',
    },
    dropzoneContentSuccessIcon: {
        backgroundColor: theme.palette.success.main,
        fontSize: '1.5em',
    },
    dropzoneContentRejectIcon: {
        backgroundColor: theme.custom.palette.status.bronze.main,
    },
}));
