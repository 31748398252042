import {defineMessages} from 'react-intl';

export const sharedLocalization = defineMessages({
    textFullFilter: {
        id: 'AgentPlayerReportingFilter_textFullFilter',
        defaultMessage: 'All',
    },
    userIdFilter: {
        id: 'AgentPlayerReportingFilter_userIdFilter',
        defaultMessage: 'Player ID',
    },
    emailFilter: {
        id: 'AgentPlayerReportingFilter_emailFilter',
        defaultMessage: 'Email',
    },
    usernameFilter: {
        id: 'AgentPlayerReportingFilter_usernameFilter',
        defaultMessage: 'Username',
    },
    firstName: {
        id: 'shared-resources.firstName',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'shared-resources.lastName',
        defaultMessage: 'Last Name',
    },
    agentPlayerId: {
        id: 'shared-resources.agentPlayerId',
        defaultMessage: 'Agent Player ID',
    },
    registrationCodeFilter: {
        id: 'AgentPlayerReportingFilter_registrationCodeFilter',
        defaultMessage: 'Registration bonus code',
    },
    userIdFilterPlaceholder: {
        id: 'AgentPlayerReportingFilter_userIdFilterPlaceholder',
        defaultMessage: '123',
    },
    emailFilterPlaceholder: {
        id: 'AgentPlayerReportingFilter_emailFilterPlaceholder',
        defaultMessage: 'For example, antonio@xyz.com',
    },
    usernameFilterPlaceholder: {
        id: 'AgentPlayerReportingFilter_usernameFilterPlaceholder',
        defaultMessage: 'For example, Antonio',
    },
    registrationCodeFilterPlaceholder: {
        id: 'AgentPlayerReportingFilter_registrationCodeFilterPlaceholder',
        defaultMessage: 'REG_00001',
    },
    expandButtonLabel: {
        id: 'FilterGroup_expandButtonLabel',
        defaultMessage: 'More',
    },
    collapseButtonLabel: {
        id: 'FilterGroup_collapseButtonLabel',
        defaultMessage: 'Less',
    },
    submitButtonLabel: {
        id: 'FilterGroup_submitButtonLabel',
        defaultMessage: 'Search',
    },
    drawerLabel: {
        id: 'multiStepFilterContainer_label',
        defaultMessage: 'Filters',
    },
    backButtonLabel: {
        id: 'multiStepFilterHeader_backButtonLabel',
        defaultMessage: 'Back',
    },
    apply: {
        id: 'AgentPlayerReportingFilterReferrer_apply',
        defaultMessage: 'Apply',
    },
    cancel: {
        id: 'AgentPlayerReportingFilterReferrer_cancel',
        defaultMessage: 'Cancel',
    },
});
