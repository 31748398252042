import React from 'react';
import {Box, ListItemIcon, MenuItem, Select, SelectProps, useTheme} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

import {SelectOption} from '../../features/module-shared/types';

import {Icon, IconColor} from './icons/Icon';
import {CustomIcon} from './icons/types';

//TODO: [BO-2655] Move files to src/common/components/input
const useClasses = makeStyles<void, 'styledSelectConfirmIcon' | 'styledSelectStartIcon'>()((theme: CustomTheme, _params, classes) => {
    return {
        styledSelectStartIcon: {
            minWidth: theme.spacing(3),
        },
        styledSelectButton: {
            background: theme.palette.background.paper,
        },
        styledSelectMenuItem: {
            '&:hover': {
                backgroundColor: 'unset',
                color: theme.palette.primary.main,
                '& .MuiListItemIcon-root': {
                    color: theme.palette.primary.main,
                },
            },
            '&[aria-selected=true]': {
                backgroundColor: 'unset !important',
                color: theme.palette.primary.main,
                [`& > .${classes.styledSelectConfirmIcon}`]: {
                    display: 'block !important',
                    width: theme.spacing(3.5),
                    paddingLeft: theme.spacing(1.5),
                },
                [`& > .${classes.styledSelectStartIcon}`]: {
                    color: `${theme.palette.primary.main} !important`,
                },
            },
        },
        styledSelectConfirmIcon: {
            display: 'none',
            marginLeft: 'auto',
        },
        styledSelectMenu: {
            maxHeight: theme.custom.styledSelectMenuMaxHeight,
        },
        styledSelectIcon: {
            color: theme.palette.secondary.main,
        },
        styledSelectMenuAlignLeft: {
            marginLeft: 0,
        },
    };
});
export class StyledSelectProps {
    options: SelectOption[];
    dropdownClassName?: string;
    alignLeft?: boolean;
    iconClassName?: string;
}

const StyledSelect = (props: StyledSelectProps & SelectProps) => {
    const theme = useTheme();
    const {options, dropdownClassName, alignLeft, onOpen, onClose, open, MenuProps, ...selectProps} = props;
    const {classes, cx} = useClasses();
    const [icon, setIcon] = React.useState(getIcon(open));
    const iconComponent = () => <Box component="span" className={cx(props.iconClassName, classes.styledSelectIcon, icon)} fontSize={10} />;

    function handleOpen(e: React.SyntheticEvent) {
        onOpen?.(e);
        setIcon(getIcon(true));
    }

    function handleClose(e: React.SyntheticEvent) {
        onClose?.(e);
        setIcon(getIcon(false));
    }

    function getIcon(open: boolean) {
        return open ? CustomIcon.ArrowUp : CustomIcon.ArrowDown;
    }

    return (
        <Select
            IconComponent={iconComponent}
            {...selectProps}
            MenuProps={{
                anchorOrigin: MenuProps?.anchorOrigin ?? {
                    vertical: 'bottom',
                    horizontal: alignLeft ? 'left' : 'center',
                },
                transformOrigin: MenuProps?.transformOrigin ?? {
                    vertical: 'top',
                    horizontal: alignLeft ? 'left' : 'center',
                },
                className: cx(dropdownClassName, classes.styledSelectMenu),
                classes: {
                    paper: cx(alignLeft && classes.styledSelectMenuAlignLeft, dropdownClassName),
                },
                // The default zIndex has been changed to display the Drawer above the burger menu
                style: {zIndex: theme.zIndex.tooltip},
            }}
            data-testid="styledSelect"
            inputProps={{'data-testid': 'selectInput'}}
            className={classes.styledSelectButton}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            {options.map((o: SelectOption) => (
                <MenuItem
                    className={classes.styledSelectMenuItem}
                    disabled={o.disabled}
                    key={o.value as string | number}
                    value={o.value as string | number}
                >
                    {o.startIcon ? (
                        <ListItemIcon className={classes.styledSelectStartIcon}>
                            <Box component="span" className={o.startIcon} fontSize={10} />
                        </ListItemIcon>
                    ) : null}
                    <LocalizedText label={o.label} />
                    <Icon icon={CustomIcon.Confirm} color={IconColor.Primary} className={classes.styledSelectConfirmIcon} />
                </MenuItem>
            ))}
        </Select>
    );
};
export default StyledSelect;
