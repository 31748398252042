import {defineMessages} from 'react-intl';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Epic} from 'redux-observable';
import {Observable, of} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {isActionOf, PayloadAction} from 'typesafe-actions';

import {fileActions} from '@file/actions';
import {ServiceTypes} from '@inversify';
import {AgentReportingDownloadModel} from '@models/agent-player-reporting';
import {AgentPlayerReporting, AgentPlayerReportingVn} from '@models/generated/graphql';
import {mergeMap} from '@otel';
import {BaseEpicsBuilder} from '@redux';
import {AgentPlayerReportingQueryFields, EntityFetchRequestPayload, EntityType} from '@redux/entity';
import {AgentPlayerReportingService} from '@services';
import {maxPageSize, ServerResponseStatus} from '@services/types';

import {showErrorAction} from '../message-snack-bar/actions';
import {Message} from '../message-snack-bar/types';

import {AgentReportingCsvFileFactory} from './services/AgentReportingCsvFileFactory';
import {agentReportingActions} from './actions';

export const localized = defineMessages({
    downloadFailure: {
        id: 'BlockAgentReportingActions_failureMessage',
        defaultMessage: 'Failed to download Agent Report',
    },
    totalCountErrorMessage: {
        id: 'BlockAgentReportingActions_totalCountErrorMessage',
        defaultMessage: 'Total count of downloaded items cannot be more than {maxPageSize}',
    },
});

@injectable()
export class AgentReportingActionsEpicsBuilder extends BaseEpicsBuilder {
    private _mapper: Mapper;
    private _agentPlayerReportingService: AgentPlayerReportingService;
    private _fileFactory: AgentReportingCsvFileFactory;

    constructor(
        @inject(ServiceTypes.AgentReportingCsvFileFactory)
        fileFactory: AgentReportingCsvFileFactory,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.AgentPlayerReportingService) agentPlayerReportingService: AgentPlayerReportingService
    ) {
        super();
        this._fileFactory = fileFactory;
        this._mapper = mapper;
        this._agentPlayerReportingService = agentPlayerReportingService;
    }

    protected buildEpicList(): Epic[] {
        return [this.buildDownloadEpic(), this.buildDownloadSuccessEpic(), this.buildDownloadFailureEpic()];
    }

    private buildDownloadEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(agentReportingActions.download.request)),
                mergeMap(action => {
                    const {filter, fields, filename, worksheetKeys, agentReportType} = action.payload;
                    const fetchPayload: EntityFetchRequestPayload<AgentPlayerReportingQueryFields> = {
                        type: EntityType.AgentPlayerReporting,
                        filter,
                        fields,
                    };
                    const failureObservable = of(agentReportingActions.download.failure({message: localized.downloadFailure}));

                    return this._agentPlayerReportingService.get(fetchPayload).pipe(
                        mergeMap(response => {
                            let result: Observable<unknown>;

                            if (response?.status === ServerResponseStatus.Success) {
                                if (response?.responsePayload?.total <= maxPageSize) {
                                    const modelIdentifier = agentReportType === 'Regular' ? AgentPlayerReporting : AgentPlayerReportingVn;
                                    const worksheetData = response.responsePayload?.items?.map(i =>
                                        this._mapper.map(i, modelIdentifier, AgentReportingDownloadModel)
                                    );
                                    result = this._fileFactory.createFile(worksheetData, worksheetKeys).pipe(
                                        map(arrayBuffer => agentReportingActions.download.success({arrayBuffer, filename})),
                                        catchError(() => failureObservable)
                                    );
                                } else {
                                    result = of(
                                        agentReportingActions.download.failure({
                                            message: localized.totalCountErrorMessage,
                                            values: {maxPageSize: maxPageSize.toString()},
                                        })
                                    );
                                }
                            } else {
                                result = failureObservable;
                            }

                            return result;
                        }),
                        catchError(() => failureObservable)
                    );
                })
            );
    }

    private buildDownloadSuccessEpic(): Epic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(agentReportingActions.download.success)),
                map(action => {
                    const {filename, arrayBuffer} = action.payload;
                    return fileActions.download({
                        file: new Blob([arrayBuffer]),
                        title: filename,
                        type: 'csv',
                    });
                })
            );
    }

    private buildDownloadFailureEpic(): Epic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(agentReportingActions.download.failure)),
                map((action: PayloadAction<string, Message>) => showErrorAction(action.payload))
            );
    }
}
