import React from 'react';
import {FormattedMessage, MessageDescriptor} from 'react-intl';

export type ErrorCodeMessage = {
    message: MessageDescriptor;
    rank?: 0 | 1 | 2 | 3;
};

export type CodeErrorMessageProps<TSupportedCodes extends number> = {
    error: string;
    hasError: boolean;
    errorCodeMessages: Record<TSupportedCodes, ErrorCodeMessage>;
    defaultErrorMessage?: MessageDescriptor;
    errorsToShow?: 'all' | 'highestRanked' | 'allHighestRanked';
};

function extractUniqueNumbersFromString(str: string): number[] {
    return [...new Set(str?.match(/\d+/g)?.map(Number) ?? [])];
}

export function CodeErrorMessage<TSupportedCodes extends number>({
    error,
    errorCodeMessages,
    defaultErrorMessage,
    errorsToShow = 'highestRanked',
    hasError,
}: CodeErrorMessageProps<TSupportedCodes>) {
    const errorCodes = extractUniqueNumbersFromString(error)
        ?.map(e => errorCodeMessages[e as TSupportedCodes])
        .filter(e => e !== undefined);
    const sortedErrors = errorCodes.sort((a, b) => (b.rank ?? 0) - (a.rank ?? 0));

    function getErrorMessages() {
        const messages: MessageDescriptor[] = [];
        if (sortedErrors.length === 0) {
            messages.push(defaultErrorMessage);
        } else {
            switch (errorsToShow) {
                case 'highestRanked':
                    {
                        const highestRankedError = sortedErrors[0];
                        if (highestRankedError) {
                            messages.push(highestRankedError.message);
                        }
                    }
                    break;
                case 'allHighestRanked':
                    {
                        const highestRankedErrors = sortedErrors.filter(e => e.rank === sortedErrors[0].rank);
                        highestRankedErrors.forEach(e => messages.push(e.message));
                    }
                    break;
                case 'all':
                    sortedErrors.forEach(e => messages.push(e.message));
                    break;
                default:
                    break;
            }
        }

        return messages;
    }

    return <>{hasError && getErrorMessages().map((message, index) => <FormattedMessage key={index} {...message}></FormattedMessage>)}</>;
}
