import {DocumentNode, gql} from '@apollo/client';
import {Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {
    AccountVerification,
    AccountVerificationFilter,
    AccountVerificationStatus,
    AccountVerificationType,
    Query,
    UserProfile,
} from '@models/generated/graphql';
import {ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlFilterVariables, IGQlSearchFilter} from '@services/deprecated/types';
import {ignoreCase, toGQKMultiselectFilter, toGQLDateFilter, toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';

import {
    AccountVerificationColumnsConfiguration,
    AccountVerificationGridItem,
    AccountVerificationItem,
    kycWorkspaceFullSearch,
} from './types';

const getUserProfilesQuery = (configuration: AccountVerificationColumnsConfiguration) => gql`
     query GetUserItems($filter: UserProfileFilter, $sort: Sorting, $start: Int, $end: Int) {
        getUserProfiles(filter: $filter, sort: $sort, start: $start, end: $end) {
            items {
                uid
                platform ${configuration.withPlatform ? '' : '@client'}
                vip_level @client
            }
        }
    }
`;

const getAccountVerificationsQuery = (configuration: AccountVerificationColumnsConfiguration) => gql`
    query GetAccountVerificationsPage($filter: AccountVerificationFilter, $sort: Sorting, $start: Int, $end: Int) {
        getAccountVerifications(filter: $filter, sort: $sort, start: $start, end: $end) {
            items {
                id
                kyc_case_id ${configuration.withKycCaseId ? '' : '@client'}
                uid ${configuration.withUid ? '' : '@client'}
                username ${configuration.withUsername ? '' : '@client'}
                initiated_at ${configuration.withInitiatedAt ? '' : '@client'} {
                    seconds
                }
                type ${configuration.withType ? '' : '@client'}
                account_verification_status ${configuration.withAccountVerificationStatus ? '' : '@client'}
                status_log ${configuration.withStatusLog ? '' : '@client'} {
                    kyc_status
                    account_verification_status 
                    logged_at {
                        seconds
                    }
                }
                documents ${configuration.withDocuments ? '' : '@client'} {
                    doc_ref_id
                    doc_type
                    status
                    uploaded_ts {
                        seconds
                    }
                    country_info {
                        name
                        iso_alpha2_code
                    }
                    device_id
                }
                email ${configuration.withEmail ? '' : '@client'}
            }
            total_count
        }
    }
`;

export class KycProcessingService extends ReadonlyRealtimeGridServiceBase<
    AccountVerificationItem,
    AccountVerificationGridItem,
    AccountVerification
> {
    getItems(_filter?: SearchFilter): Observable<AccountVerificationGridItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsPage(filter?: SearchFilter, itemFields?: string[]): Observable<ItemsPage<AccountVerificationGridItem>> {
        const configuration = this.getGQLConfiguration(new AccountVerificationColumnsConfiguration(), itemFields);

        let items$ = this._client.executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter)).pipe(
            map<Query, ItemsPage<AccountVerificationGridItem>>(res => {
                return {
                    items:
                        res?.getAccountVerifications?.items.map(
                            (i, index) =>
                                ({
                                    ...i,
                                    id: i.id ?? `${index}`,
                                    serverId: i.id ?? `${index}`,
                                } as AccountVerificationGridItem)
                        ) ?? [],
                    total: res?.getAccountVerifications?.total_count ?? 0,
                };
            })
        );

        if (configuration.withVipLevel || configuration.withPlatform) {
            items$ = items$.pipe(
                mergeMap(kycItems => {
                    return this.getUsersInfo(configuration, kycItems).pipe(
                        map<UserProfile[], ItemsPage<AccountVerificationGridItem>>(users => {
                            return {
                                items: kycItems.items?.map(i => {
                                    const user = users?.find(u => u.uid === i.uid);
                                    return {
                                        ...i,
                                        platform: user?.platform,
                                        vipLevel: 2,
                                    } as AccountVerificationGridItem;
                                }),
                                total: kycItems.total,
                            };
                        })
                    );
                })
            );
        }

        return items$;
    }

    private getUsersInfo(
        configuration: AccountVerificationColumnsConfiguration,
        kycInfo: ItemsPage<AccountVerificationGridItem>
    ): Observable<UserProfile[]> {
        const uids = kycInfo?.items?.map(i => i.uid) ?? [];
        const uidFilter = [...new Set(uids)].join('|');
        const userFilter = {
            filter: {
                text: [
                    {
                        search_in: [nameof<UserProfile>(u => u.uid)],
                        text: uidFilter,
                    },
                ],
            },
            start: 0,
            end: uids.length,
        } as IGQlFilterVariables;

        return this._client
            .executeQuery(getUserProfilesQuery(configuration), userFilter)
            .pipe(map<Query, UserProfile[]>(res => res?.getUserProfiles?.items));
    }

    getItem(_id: string): Observable<AccountVerificationItem> {
        throw new Error('Method not implemented.');
    }

    protected getItemsPageQuery(configuration: AccountVerificationColumnsConfiguration): DocumentNode {
        return getAccountVerificationsQuery(configuration);
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        const accountVerificationStatus = toGQKMultiselectFilter<AccountVerificationStatus>(
            filters,
            nameof<AccountVerificationFilter>(m => m.account_verification_status)
        );

        const filter = {
            account_verification_status: accountVerificationStatus,
            text: [
                toGQLTextFilter(
                    filters,
                    nameof.toArray<AccountVerification>(m => [m.uid, m.username])
                ),
                toGQLTextFilter(
                    filters,
                    [
                        nameof<AccountVerification>(m => m.id),
                        nameof<AccountVerification>(m => m.uid),
                        nameof<AccountVerification>(m => m.email),
                    ],
                    kycWorkspaceFullSearch
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<AccountVerification>(m => [m.id]),
                    nameof<AccountVerification>(m => m.id)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<AccountVerification>(m => [m.uid]),
                    nameof<AccountVerification>(m => m.uid)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<AccountVerification>(m => [m.email]),
                    nameof<AccountVerification>(m => m.email),
                    ignoreCase
                ),
            ],
            type: toGQKMultiselectFilter<AccountVerificationType>(
                filters,
                nameof<AccountVerificationFilter>(m => m.type)
            ),
            iso_alpha2_country_code: toGQKMultiselectFilter(filters, 'registrationCountry'),
        } as AccountVerificationFilter;

        const dateFilter = toGQLDateFilter(
            filters,
            nameof<AccountVerificationFilter>(m => m.initiated_at)
        );
        if (dateFilter) {
            filter.initiated_at = dateFilter;
        }

        return filter;
    }
}
