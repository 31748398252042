import {createAsyncAction} from 'typesafe-actions';

import {Message} from '../message-snack-bar/types';

import {DownloadKeys, IDownloadStrategy} from './types';

const domain = 'block-download';

export type DownloadPayload<TKeys extends string> = {
    keys: DownloadKeys<TKeys>;
    worksheetKeys: TKeys[];
    filter: string;
    headers: Record<TKeys, string>;
    filename: string;
    total: number;
    strategy: IDownloadStrategy<TKeys>;
};

export type DownloadSuccessActionPayload = {
    arrayBuffer: ArrayBuffer;
    filename: string;
};

export const downloadActions = {
    download: createAsyncAction(`${domain}/downloadRequest`, `${domain}/downloadSuccess`, `${domain}/downloadFailed`)<
        DownloadPayload<string>,
        DownloadSuccessActionPayload,
        Message
    >(),
};
