import React from 'react';
import {defineMessages} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {ErrorModal} from '@components/error';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {ModuleName} from '@models/modules';
import {pageRuleReadPermissions} from '@models/permissions/permissions';
import {withTraceErrorBoundary} from '@otel';
import {Policy} from '@auth';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';

import {CreateRuleButton, useRules} from '../features/block-automated-rule';
import {DataGridRuleClient} from '../features/block-automated-rule/components/DataGridRule';
import {RuleViewModelKeys} from '../features/block-automated-rule/types';
import {withModule} from '../features/module-shared/components/ModuleHoc';

const localized = defineMessages({
    title: {
        id: 'Rules_title',
        defaultMessage: 'Rules',
    },
});

function Rules() {
    const keys: RuleViewModelKeys[] = ['order', 'name', 'status', 'actions'];
    const {items} = useRules({
        viewType: 'Rules',
        fields: keys,
        displayName: localized.title,
        realtimeMode: RealtimeUpdatesMode.Silent,
        triggers: [{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Update}, {type: RealtimeMessageTrigger.Delete}],
        validateFilter: () => true,
    });

    return (
        <LayoutQueue
            header={<PageHeader header={localized.title} />}
            toolbar={
                <Toolbar>
                    <ToolbarItemsContainer primary>
                        <ToolbarItems streched />
                        <ToolbarItems secondary>
                            <CreateRuleButton />
                        </ToolbarItems>
                    </ToolbarItemsContainer>
                </Toolbar>
            }
            body={<DataGridRuleClient autoHeight={true} columns={keys} rows={items} pageSize={100} />}
        />
    );
}

export const rulesResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'Rules',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageRules, null, 'read')]),
});

export default withModule(withTraceErrorBoundary(Rules, ErrorModal), pageRuleReadPermissions);
