import React, {useContext} from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon} from '@components/icons';
import {ModalContext} from '@components/modal';

import {useAsyncActionState} from '../../shared/async-action/hooks';
import {ruleActions} from '../actions';

import {RuleModal} from './RuleModal';

const localized = defineMessages({
    buttonLabel: {
        id: 'CreateRuleButton_label',
        defaultMessage: 'Create new rule',
    },
    modalTitle: {
        id: 'CreateRuleButton_modalTitle',
        defaultMessage: 'Create rule',
    },
});

const useStyles = makeStyles()(theme => ({
    createRuleButton: {
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

export function CreateRuleButton() {
    const {classes} = useStyles();

    const {openModal, closeModal} = useContext(ModalContext);
    const {isProgress} = useAsyncActionState(ruleActions.createRule);

    const handleClick = () => {
        openModal({
            title: <LocalizedText label={localized.modalTitle} />,
            body: <RuleModal mode="create" onClose={closeModal} />,
            styleParams: {
                isLimitedBody: false,
            },
        });
    };

    return (
        <Button
            className={classes.createRuleButton}
            color="primary"
            startIcon={<Icon icon={CustomIcon.AddCircleOutline} />}
            label={localized.buttonLabel}
            disabled={isProgress}
            onClick={handleClick}
        />
    );
}
