import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {GameRoom, GameRoomFilterInput} from '@models/game-room/types';
import {map} from '@otel';
import {
    EntityFetchRequestPayload,
    EntityFetchResponsePayload,
    EntityFetchServiceResponsePayload,
    GameRoomFilterKeys,
    ManagedGameType,
} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {
    CloseGameRoomRequestPayload,
    CloseRoomServiceResponsePayload,
    IGameManagementApiService,
    KickoffPlayerRequestPayload,
    KickoffPlayerServiceResponsePayload,
    RoomServiceResponsePayload,
} from '@services/rest-api/plo5GameManagementApiService';
import {ServerResponseStatus, ServiceResponsePayload} from '@services/types';
import {getValueFromQueryFilter} from '@utils/query';

@injectable()
export class GameRoomService implements IEntityReadService {
    private readonly _apiServiceFactory: (gameType: ManagedGameType) => IGameManagementApiService;
    private readonly _builder: GameRoomRequestBuilder;

    constructor(
        @inject(ServiceTypes.GameManagementApiServiceFactory) apiServiceFactory: (gameType: ManagedGameType) => IGameManagementApiService
    ) {
        this._apiServiceFactory = apiServiceFactory;
        this._builder = new GameRoomRequestBuilder();
    }

    public get(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload<GameRoom>> {
        const gameType: ManagedGameType = getValueFromQueryFilter<GameRoomFilterKeys, ManagedGameType>(requestPayload.filter, 'gameType');
        const apiService: IGameManagementApiService = this._apiServiceFactory(gameType);
        const filter = this._builder.getRoomsFilterInput(requestPayload.filter);

        return apiService
            ? apiService.getExtendedRooms(filter, gameType)?.pipe(
                  map((r: RoomServiceResponsePayload) => ({
                      ...r,
                      requestPayload,
                  }))
              )
            : this.getServiceNotFoundResponsePayload<EntityFetchRequestPayload, EntityFetchResponsePayload<GameRoom>>(requestPayload);
    }

    public closeGameRoom(requestPayload: CloseGameRoomRequestPayload): Observable<CloseRoomServiceResponsePayload> {
        const apiService: IGameManagementApiService = this._apiServiceFactory(requestPayload.gameType);
        return apiService
            ? apiService.closeGameRoom(requestPayload)
            : this.getServiceNotFoundResponsePayload<CloseGameRoomRequestPayload, null>(requestPayload);
    }

    public kickoffPlayer(requestPayload: KickoffPlayerRequestPayload): Observable<KickoffPlayerServiceResponsePayload> {
        const apiService: IGameManagementApiService = this._apiServiceFactory(requestPayload.gameType);
        return apiService
            ? apiService.kickoffPlayerFromRoom(requestPayload)
            : this.getServiceNotFoundResponsePayload<KickoffPlayerRequestPayload, null>(requestPayload);
    }

    private getServiceNotFoundResponsePayload<TRequestPayload, TResponsePayload>(
        requestPayload: TRequestPayload
    ): Observable<ServiceResponsePayload<TRequestPayload, TResponsePayload>> {
        return of({
            status: ServerResponseStatus.Failed,
            message: 'Service for provided type was not founded',
            requestPayload,
            responsePayload: null,
        });
    }
}

export class BaseGameManagementRequestBuilder {
    protected toNumber(value: string): number {
        const result = Number(value);
        return value !== null ? (isNaN(result) ? undefined : result) : null;
    }
}

export class GameRoomRequestBuilder extends BaseGameManagementRequestBuilder {
    public getRoomsFilterInput(filter: string): GameRoomFilterInput {
        const filterObject = Object.fromEntries(new URLSearchParams(filter)) as Record<GameRoomFilterKeys, string>;
        return {
            room_name: filterObject?.roomName,
            template_id: this.toNumber(filterObject?.templateId),
            ...this.buildPaging(filterObject),
        };
    }

    private buildPaging(filterObject: Record<GameRoomFilterKeys, string>): Pick<GameRoomFilterInput, 'page_no' | 'per_page_count'> {
        const page_no = this.toNumber(filterObject.page);
        return {
            page_no: page_no ? page_no - 1 : 0,
            per_page_count: this.toNumber(filterObject.size) ?? 10,
        };
    }
}
