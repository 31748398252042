const allPermissions = ['create', 'read', 'update', 'delete'] as const;
type Permission = (typeof allPermissions)[number];

export class Policy {
    private readonly module: string;
    private readonly submodule: string;
    private readonly permission: string;

    constructor(policy: string);
    constructor(module: string, submodule?: string, permission?: Permission);
    constructor(...args: string[]) {
        const policyParts = args?.length === 1 && args[0]?.includes(':') ? args[0].split(':') : args;
        this.module = policyParts[0];
        if (allPermissions.find(permission => permission === policyParts[1])) {
            this.permission = policyParts[1];
        } else {
            this.submodule = policyParts[1];
            this.permission = policyParts[2];
        }
    }

    public toString(): string {
        let policy: string = this.module;
        if (this.submodule) {
            policy = `${policy}:${this.submodule}`;
        }
        if (this.permission) {
            policy = `${policy}:${this.permission}`;
        }

        return policy;
    }

    public parse(): {module: string; submodule: string; permission: string} {
        return {module: this.module, submodule: this.submodule, permission: this.permission};
    }
}
