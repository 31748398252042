import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledAccordion from '@components/StyledAccordion';

import {FinanceSummary} from '../../workspace-details/user-finance-summary/components/FinanceSummary';

import {AggregatedFinancialSummary} from './AggregatedFinancialSummary';
import {FinancialInfo} from './FinancialInfo';
import {TransactionHistory} from './TransactionHistory';
import {UserFinanceSummary} from './UserFinanceSummary';

const localized = defineMessages({
    financialInfo: {
        id: 'UserDetailsFinancialInfo_financialInfo',
        defaultMessage: 'Financial Info',
    },
});

const useClasses = makeStyles()(() => ({
    userDetailsFinancialInfoContainer: {
        width: '100%',
    },
    userDetailsFinancialInfo: {
        paddingTop: 0,
        boxShadow: 'none',
    },
}));

type UserDetailsFinancialInfoProps = {
    userId: string;
};

export const UserDetailsFinancialInfo = ({userId}: UserDetailsFinancialInfoProps) => {
    const {classes} = useClasses();

    return (
        <StyledAccordion
            header={localized.financialInfo}
            componentId="financialInfo"
            isCollapsed
            unmountOnCollapse
            children={
                <Box className={classes.userDetailsFinancialInfoContainer}>
                    <UserFinanceSummary uid={userId} />
                    <AggregatedFinancialSummary uid={userId} />
                    <FinancialInfo userId={userId} />
                    <FinanceSummary userId={userId} />
                    <TransactionHistory userId={userId} />
                </Box>
            }
        />
    );
};
