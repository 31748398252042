import React, {useContext} from 'react';

import {IconButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons';
import {ModalContext} from '@components/modal';
import {ManagedGameType} from '@redux/entity';

import {GamePlayersModal} from './GamePlayersModal';

type RemovePlayerModalButtonProps = {
    roomId: string;
    roomName: string;
    gameType: ManagedGameType;
};

export function GamePlayersModalButton({roomId, roomName, gameType}: RemovePlayerModalButtonProps) {
    const {openModal} = useContext(ModalContext);

    const handleClick = () => {
        openModal({
            body: <GamePlayersModal roomId={roomId} roomName={roomName} gameType={gameType} />,
            styleParams: {maxWidth: 'sm'},
        });
    };

    return <IconButton iconClassName={CustomIcon.Eye} onClick={handleClick} />;
}
