import React from 'react';
import {Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useClasses = makeStyles()(() => ({
    formGroupTitle: {
        fontWeight: 'bold',
    },
}));

type FormGroupTitleProps = {
    groupTitle?: string;
};

export function FormGroupTitle({groupTitle}: FormGroupTitleProps) {
    const {classes} = useClasses();

    return (
        <Typography variant="body1" className={classes.formGroupTitle}>
            {groupTitle}
        </Typography>
    );
}
