import {inject, injectable} from 'inversify';
import {forkJoin, Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {Workspace} from '@models/generated/graphql';
import {map} from '@otel';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {RestService} from '@services/rest-api/restService';
import {RestRequest, ServerResponseStatus, ServiceResponsePayload} from '@services/types';

type DownloadAttachmentRequestPayload = {
    fileId: string;
    fileName: string;
};

export type Attachment = {
    id: string;
    name: string;
    size: number;
    type: string;
};

export interface IAttachmentsApiService {
    upload(files: FileList, workspace: Workspace): Observable<ServiceResponsePayload<RestRequest, Attachment[]>>;

    download(downloadPayload: DownloadAttachmentRequestPayload): Observable<ServiceResponsePayload<DownloadAttachmentRequestPayload, Blob>>;
}

@injectable()
export class AttachmentsApiService implements IAttachmentsApiService {
    private readonly _apiRouteService: ApiRoutesService;
    private readonly _restService: RestService;
    private readonly _apiVersion = 1;

    constructor(
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService,
        @inject(ServiceTypes.RestService) restService: RestService
    ) {
        this._apiRouteService = apiRouteService;
        this._restService = restService;
    }

    download({
        fileName,
        fileId,
    }: DownloadAttachmentRequestPayload): Observable<ServiceResponsePayload<DownloadAttachmentRequestPayload, Blob>> {
        const endpoint = this._apiRouteService?.getAttachmentDownloadEndpoint();

        return this._restService
            .get({endpoint, query: `fileId=${fileId}&api-version=${this._apiVersion}`}, null, true, 'blob')
            .pipe(map(r => ({...r, requestPayload: {fileId, fileName}, responsePayload: r.responsePayload?.response})));
    }

    upload(files: FileList, workspace: Workspace): Observable<ServiceResponsePayload<RestRequest, Attachment[]>> {
        const uploads$: Observable<ServiceResponsePayload<RestRequest, Attachment>>[] = [];
        const workspaceNumber = this.getWorkspaceNumber(workspace);
        for (let i = 0; i < files?.length; i++) {
            const file = files[i];
            uploads$.push(this.uploadSingle(file, workspaceNumber));
        }

        return uploads$.length > 0
            ? forkJoin(uploads$).pipe(
                  map(results => {
                      const failedUpload = results.find(r => r.status !== ServerResponseStatus.Success);
                      return failedUpload
                          ? {
                                ...failedUpload,
                                responsePayload: results?.map(r => r.responsePayload),
                            }
                          : {
                                status: ServerResponseStatus.Success,
                                requestPayload: null,
                                responsePayload: results?.map(r => r.responsePayload),
                            };
                  })
              )
            : of({status: ServerResponseStatus.Success, requestPayload: null, responsePayload: []});
    }

    private uploadSingle(file: File, workspace: number): Observable<ServiceResponsePayload<RestRequest, Attachment>> {
        const endpoint = this._apiRouteService?.getAttachmentUploadEndpoint();
        const body = new FormData();
        body.append('File', file);

        return this._restService
            .post({endpoint, query: `workspace=${workspace}&api-version=${this._apiVersion}`, body}, null, true, 'auto')
            .pipe(
                map(r => ({
                    ...r,
                    responsePayload: {id: r.responsePayload?.response?.id, type: file.type, size: file.size, name: file.name},
                }))
            );
    }

    private getWorkspaceNumber(workspace: Workspace): number {
        const workspaceMapping = {
            [Workspace.Payment]: 0,
            [Workspace.Kyc]: 1,
            [Workspace.Security]: 2,
            [Workspace.CustomerSupport]: 3,
            [Workspace.Global]: 4,
        };

        return workspaceMapping[workspace];
    }
}
