import {defineMessages} from 'react-intl';

import {BonusCreditType} from '@models/generated/graphql';

export const localizedBeCreditType = defineMessages({
    [BonusCreditType.MttToken]: {
        id: 'localizedBeCreditTypeMttToken',
        defaultMessage: 'MTT Ticket',
    },
    [BonusCreditType.CashBonus]: {
        id: 'localizedBeCreditTypeCashBonus',
        defaultMessage: 'Cash Bonus',
    },
    [BonusCreditType.CasinoCoin]: {
        id: 'localizedBeCreditTypeCasinoCoin',
        defaultMessage: 'Casino Coin',
    },
});
