import {injectable} from 'inversify';
import {v4 as uuid} from 'uuid';

import {BulkItemStatus} from '@models/generated/graphql';

import {ApplyStrategyResponse, BulkActionItemPayload, BulkActionKey, IBulkApplyStrategy} from '../../block-bulk-actions';
import {BonusTriggerViewModel} from '../types';

export type ApplyTriggerBonusRequest = {bonuses: BonusTriggerViewModel[]};

abstract class ApplyTriggerBonusStrategy implements IBulkApplyStrategy<ApplyTriggerBonusRequest> {
    process({bonuses}: ApplyTriggerBonusRequest): ApplyStrategyResponse {
        const items = bonuses.map<BulkActionItemPayload>(item => ({
            actionKey: item.bonus_id,
            value: item,
            itemId: uuid(),
            status: BulkItemStatus.Pending,
        }));

        return {actionKey: this.getActionKey(), items};
    }

    protected abstract getActionKey(): BulkActionKey;
}

@injectable()
export class ApplyTriggerMttBonusStrategy extends ApplyTriggerBonusStrategy {
    process({bonuses}: ApplyTriggerBonusRequest): ApplyStrategyResponse {
        const mttBonusesWithSingleTicket = bonuses.flatMap<BonusTriggerViewModel>(bonus => {
            return Array.from({length: bonus.ticket_count}, (_, index) => ({
                ...bonus,
                ticket_index: index,
            }));
        });

        return super.process({bonuses: mttBonusesWithSingleTicket});
    }

    protected getActionKey(): BulkActionKey {
        return BulkActionKey.ManualTriggerMTTTicket;
    }
}

@injectable()
export class ApplyTriggerImmediateCashBonusStrategy extends ApplyTriggerBonusStrategy {
    protected getActionKey(): BulkActionKey {
        return BulkActionKey.ManualTriggerImmediateCashBonus;
    }
}

@injectable()
export class ApplyTriggerDepositCashMatchBonusStrategy extends ApplyTriggerBonusStrategy {
    protected getActionKey(): BulkActionKey {
        return BulkActionKey.ManualTriggerDepositCashMatch;
    }
}
