import {MessageDescriptor} from 'react-intl';

import {useMessageFormatter} from '../../../features/app/intl/hooks';
import {chipsMapping} from '../../config/chip';

export const useChipValue = (value: string, chipName: string, label?: string | MessageDescriptor) => {
    const formatMessage = useMessageFormatter();
    const chip = chipsMapping[chipName][value];
    const chipLabel = chip?.label
        ? chip.labelTypeString
            ? (chip.label as string)
            : formatMessage(chip.label as MessageDescriptor)
        : label
        ? formatMessage(label)
        : value;

    return {chip, chipLabel};
};
