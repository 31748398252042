import React from 'react';

import {WorkspaceDetailsComponentProps} from 'src/features/block-workspace-details/types';
import {WorkspaceKeyValueTable} from '../../../block-workspace-details/components/WorkspaceKeyValueTable';
import {useRealtimeUpdates} from '../../../realtime-updates/hooks';
import {SubscriptionType} from '../../../realtime-updates/types';
import {domain as entityDomain} from '../../../user-profile/types';
import {dataSelector, filterSelector} from '../selectors';
import {domain} from '../types';

import getTableRows from './UserRegionRelatedInfo.config';
import {localized} from './UserRegionRelatedInfo.localize';

export const UserRegionRelatedInfo = ({itemId, fields, hideTitle}: WorkspaceDetailsComponentProps) => {
    useRealtimeUpdates(entityDomain, domain, [SubscriptionType.Updated]);

    return (
        <WorkspaceKeyValueTable
            itemId={itemId}
            domain={domain}
            header={localized.header}
            fields={fields}
            getTableRows={getTableRows}
            dataSelector={dataSelector}
            filterSelector={filterSelector}
            columnsCount={2}
            hideTitle={hideTitle}
        />
    );
};
