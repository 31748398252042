import {MessageDescriptor} from 'react-intl';

//TODO: [BO-2363] Move emptyCellCharacter to common folder
export const emptyCellCharacter = '—';

export const isBlank = (str: string | number) => {
    return !str || /^\s*$/.test(str.toString());
};

export const isMessageDescriptor = (message: unknown): message is MessageDescriptor => {
    return message && (<MessageDescriptor>message).defaultMessage !== undefined;
};
