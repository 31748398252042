import {defineMessages} from 'react-intl';

export const localized = defineMessages({
    infoMessage: {
        id: 'infoMessage',
        defaultMessage: 'Something went wrong.',
    },
    showMoreLabel: {
        id: 'showMoreLabel',
        defaultMessage: 'More info',
    },
});
