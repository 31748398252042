import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    securityCasesFieldsModalListItemAutocomplete: {
        width: theme.custom.autocompleteWidth,
        paddingLeft: 0,
        '& .MuiAutocomplete-inputRoot': {
            paddingTop: 0,
        },
        '& .MuiTextField-root': {
            paddingLeft: theme.spacing(1),
        },
        '&.Mui-focused': {
            borderTopRightRadius: theme.shape.borderRadius * 1.5,
            borderTopLeftRadius: theme.shape.borderRadius * 1.5,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
    },
    securityCasesFieldsModalList: {
        paddingTop: 0,
    },
    securityCasesFieldsModalListItem: {
        paddingLeft: 0,
        paddingRight: 0,
        '&:not(:last-of-type)': {
            paddingBottom: 0,
        },
    },
    securityCasesFieldsModalSecondaryAction: {
        width: theme.custom.autocompleteWidth,
        right: 0,
    },
}));
