import React from 'react';
import {useDispatch} from 'react-redux';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {SquareIconButton} from '@components/button';
import {CustomIcon} from '@components/icons';
import {BonusCreditType, BonusReleaseMechanism, PlayerBonusStatus} from '@models/generated/graphql';
import {CustomTheme} from '@style';

import {useAsyncActionState} from '../../shared/async-action/hooks';
import {playerBonusActions} from '../actions';

const useClasses = makeStyles()((theme: CustomTheme) => ({
    cancelButton: {
        color: theme.custom.palette.status.bronze.main,
    },
}));

export type ExpirePlayerBonusProps = {
    bonusId: string;
    bonusStatus: PlayerBonusStatus;
    releaseMechanism: BonusReleaseMechanism;
    creditType: BonusCreditType;
};

export function ExpirePlayerBonus({bonusId, bonusStatus, releaseMechanism, creditType}: ExpirePlayerBonusProps) {
    const {classes} = useClasses();
    const dispatch = useDispatch();
    const {isProgress, actionId} = useAsyncActionState(playerBonusActions.forceExpire, true);

    const completedBonusStatuses: PlayerBonusStatus[] = [PlayerBonusStatus.Complete, PlayerBonusStatus.Expired];
    const isDisabled =
        isProgress ||
        completedBonusStatuses.includes(bonusStatus) ||
        releaseMechanism === BonusReleaseMechanism.Immediate ||
        creditType === BonusCreditType.MttToken;

    function handleClick() {
        if (!isDisabled) {
            dispatch(playerBonusActions.forceExpire.request({bonusId}, {actionId}));
        }
    }

    return (
        <SquareIconButton
            onClick={handleClick}
            disabled={isDisabled}
            iconClassName={CustomIcon.CancelOutline}
            className={classes.cancelButton}
        />
    );
}

export function renderExpirePlayerBonusCell(params: GridCellParams) {
    const value = params.value as ExpirePlayerBonusProps;
    return <ExpirePlayerBonus {...value} />;
}
