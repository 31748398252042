import {defineMessages} from 'react-intl';

import {JumioDocumentType} from '@models/generated/graphql';

export const localizedJumioDocumentTypeAbbreviated = defineMessages({
    [JumioDocumentType.IdCard]: {
        id: 'jumioDocumentTypeAbbreviatedID',
        defaultMessage: 'ID',
    },
    [JumioDocumentType.LicenseDriver]: {
        id: 'jumioDocumentTypeAbbreviatedLicense',
        defaultMessage: 'LD',
    },
    [JumioDocumentType.Passport]: {
        id: 'jumioDocumentTypeAbbreviatedPassport',
        defaultMessage: 'Passport',
    },
    [JumioDocumentType.Unsupported]: {
        id: 'jumioDocumentTypeAbbreviatedUnsupported',
        defaultMessage: 'Unsupported',
    },
    [JumioDocumentType.Visa]: {
        id: 'jumioDocumentTypeAbbreviatedVisa',
        defaultMessage: 'Visa',
    },
    [JumioDocumentType.ProofOfAddress]: {
        id: 'jumioDocumentTypeAbbreviatedProofOfAddress',
        defaultMessage: 'PA',
    },
    [JumioDocumentType.ProofOfPayment]: {
        id: 'jumioDocumentTypeAbbreviatedProofOfPayment',
        defaultMessage: 'PP',
    },
    [JumioDocumentType.CustomId]: {
        id: 'jumioDocumentTypeAbbreviatedCustomId',
        defaultMessage: 'Custom Id',
    },
});
