import React from 'react';
import {Box} from '@mui/material';

import {MemoizedDefaultChip} from './ChipRenderer';
import {ChipType, ChipVariant} from './types';

type UserChipProps = {
    userName: string;
};

export const UserChip = ({userName}: UserChipProps) => {
    return (
        <Box>
            <MemoizedDefaultChip value={userName} variant={ChipVariant.Grey} type={ChipType.Status} />
        </Box>
    );
};
