import {defineMessages} from 'react-intl';

export const localizedLegalDocs = defineMessages({
    privacyPolicy: {
        id: 'LD_privacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
    termsAndConditions: {
        id: 'LD_termsAndConditions',
        defaultMessage: 'Terms and Conditions',
    },
    dateAccepted: {
        id: 'LD_dateAccepted',
        defaultMessage: 'Date Accepted',
    },
    acceptanceVersion: {
        id: 'LD_acceptanceVersion',
        defaultMessage: 'Acceptance Version',
    },
    showVersion: {
        id: 'LD_showVersion',
        defaultMessage: 'Show Version',
    },
});
