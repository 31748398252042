import {defineMessages} from 'react-intl';

import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {PageModuleAction} from '../../../features/module-shared/types';
import {renderLegalVersionHistoryAction} from '../history/components/LegalVersionHistoryAction';

import LegalEditAction from './LegalEditAction';

const localized = defineMessages({
    editLabel: {
        id: 'LegalDocs_editLabel',
        defaultMessage: 'Edit',
    },
    viewLabel: {
        id: 'LegalDocs_viewLabel',
        defaultMessage: 'View',
    },
    versionHistoryLabel: {
        id: 'LegalDocs_versionHistoryLabel',
        defaultMessage: 'Version History',
    },
    versionLabel: {
        id: 'LegalDocs_versionLabel',
        defaultMessage: 'version {version}',
    },
    backLabel: {
        id: 'LegalDocs_backLabel',
        defaultMessage: 'Back',
    },
    privacyPolicy: {
        id: 'LegalDocs_privacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
    termsAndConditions: {
        id: 'LegalDocs_termsAndConditions',
        defaultMessage: 'Terms and Conditions',
    },
    documentContent: {
        id: 'LegalDocs_documentContent',
        defaultMessage: 'Document content',
    },
    isRequired: {
        id: 'LegalDocs_isRequired',
        defaultMessage: 'Is required',
    },
    required: {
        id: 'LegalDocs_required',
        defaultMessage: 'required',
    },
});

const getActions = (domain: string, isEditMode: boolean, submoduleName: SubmoduleName): PageModuleAction[] => [
    {
        render: () => LegalEditAction({domain, isEditMode}),
        permissions: [PermissionEnum.Update],
        moduleName: ModuleName.LegalContentManagement,
        submoduleName: submoduleName,
    },
    {
        render: () => renderLegalVersionHistoryAction(domain, localized.versionHistoryLabel),
        permissions: [PermissionEnum.Read],
        moduleName: ModuleName.LegalContentManagement,
        submoduleName: submoduleName,
    },
];

export default {
    localized,
    getActions,
};
