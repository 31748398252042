import {ContainerFacade} from '@inversify/containerFacade';

import {ApplyKYCRejectStrategy} from './services/applyStrategy';
import {ApplyKYCInitStrategy, PerformKYCInitStrategy} from './services/initKYCStrategy';
import {LoadAccountVerificationItemsStrategy} from './services/loadAccountVerificationItemsStrategy';
import {PerformKYCRejectStrategy} from './services/performStrategy';

export const ServiceTypes = {
    ApplyKYCInitStrategy: Symbol('ApplyKYCInitStrategy'),
    PerformKYCInitStrategy: Symbol('PerformKYCInitStrategy'),
    LoadAccountVerificationItemsStrategy: Symbol('LoadAccountVerificationItemsStrategy'),
    ApplyKYCRejectStrategy: Symbol('ApplyKYCRejectStrategy'),
    PerformKYCRejectStrategy: Symbol('PerformKYCRejectStrategy'),
};

export class KYCBulkActionContainerFacade extends ContainerFacade {
    bindAll() {
        this._container.bind(ServiceTypes.ApplyKYCInitStrategy).to(ApplyKYCInitStrategy);
        this._container.bind(ServiceTypes.PerformKYCInitStrategy).to(PerformKYCInitStrategy);
        this._container.bind(ServiceTypes.LoadAccountVerificationItemsStrategy).to(LoadAccountVerificationItemsStrategy);
        this._container.bind(ServiceTypes.ApplyKYCRejectStrategy).to(ApplyKYCRejectStrategy);
        this._container.bind(ServiceTypes.PerformKYCRejectStrategy).to(PerformKYCRejectStrategy);
    }
}
