import {GridValueGetterParams} from '@mui/x-data-grid';

import {IModuleGridItem} from '@components/data-grid';
import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    DataGridEntityOptions,
    MuiDataGridClient,
    MuiDataGridServer,
    renderCellHeader,
    renderCurrencyCell,
    renderDefaultChipCell,
    withDataGridEntityClient,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {PlayerBonus} from '@models/generated/graphql';

import {localizedHeaders} from './DataGridPlayerBonus.localize';

export const dataGridPlayerBonusColumnKeys = ['total_amount', 'marketing_code'] as const;

export type DataGridPlayerBonusColumn = (typeof dataGridPlayerBonusColumnKeys)[number];

export class DataGridPlayerBonusConfigFactory extends DataGridEntityConfigFactory<
    DataGridPlayerBonusColumn,
    PlayerBonus & IModuleGridItem
> {
    getColumnConfig(
        options: DataGridEntityOptions
    ): Record<DataGridPlayerBonusColumn, DataGridEntityColDef<DataGridPlayerBonusColumn, PlayerBonus & IModuleGridItem>> {
        const bonusConfig: Record<
            DataGridPlayerBonusColumn,
            DataGridEntityColDef<DataGridPlayerBonusColumn, PlayerBonus & IModuleGridItem>
        > = {
            total_amount: {
                field: 'total_amount',
                valueGetter: p => this.getAmount(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridAmount),
                renderCell: params => renderCurrencyCell(params, options.currency as string),
                flex: 0.1,
                sortable: false,
            },
            marketing_code: {
                field: 'marketing_code',
                valueGetter: p => this.getMarketingCode(p),
                renderHeader: () => renderCellHeader(localizedHeaders.playerBonusGridMarketingCode),
                renderCell: renderDefaultChipCell,
                flex: 0.15,
                sortable: false,
            },
        };

        return bonusConfig;
    }

    private getAmount(p: GridValueGetterParams) {
        return this.getRow(p)?.total_amount;
    }

    private getMarketingCode(p: GridValueGetterParams) {
        return this.getRow(p)?.marketing_code;
    }
}

export const DataGridPlayerBonusClient = withDataGridEntityClient<DataGridPlayerBonusColumn, PlayerBonus & IModuleGridItem>(
    MuiDataGridClient,
    options => new DataGridPlayerBonusConfigFactory().getColumnConfig(options)
);

export const DataGridPlayerBonusServer = withDataGridEntityServer<DataGridPlayerBonusColumn, PlayerBonus & IModuleGridItem>(
    MuiDataGridServer,
    options => new DataGridPlayerBonusConfigFactory().getColumnConfig(options)
);
