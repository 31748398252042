import React from 'react';
import {ListItemIcon, ListItemText, MenuItem, Typography} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';

import {SelectOption} from '../../../features/module-shared/types';

import {useSelectMenuItemCreatorClasses} from './SelectMenuItemCreator.style';
import {useGeneralSelectClasses} from './selectStyle';

//TODO: [BO-2668] Move to src/common/components/dropdown (rename or merge?)
//TODO: [BO-2669] Move dropdown components to input folder (?)
type SelectMenuItemCreatorProps = {
    options: SelectOption[];
    onSubmit: (value: any) => void;
};

export const SelectMenuItemCreator = ({options, onSubmit}: SelectMenuItemCreatorProps) => {
    const {classes} = useSelectMenuItemCreatorClasses();
    const {classes: generalSelectClasses} = useGeneralSelectClasses();

    return (
        <>
            {(options as SelectOption[])?.map((option, index) => (
                <MenuItem
                    className={generalSelectClasses.selectWithConfirmMenuItem}
                    value={option.value as string}
                    key={`select-with-button-${index}`}
                    onClick={() => onSubmit(option.value)}
                >
                    {option.startIcon ? (
                        <ListItemIcon className={classes.selectItemStartIcon}>
                            <Icon icon={option.startIcon} />
                        </ListItemIcon>
                    ) : null}
                    <ListItemText
                        className={generalSelectClasses.listItemText}
                        disableTypography
                        primary={
                            <Typography variant="button" className={generalSelectClasses.listItemTextTypography}>
                                <LocalizedText label={option.label} />
                            </Typography>
                        }
                    />
                    <Icon icon={CustomIcon.Confirm} color={IconColor.Primary} className={generalSelectClasses.selectWithConfirmIcon} />
                </MenuItem>
            ))}
        </>
    );
};
