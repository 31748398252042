import React, {useContext} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import Button from '@components/button/Buttons';
import {BulkModalContent, BulkModalContext, BulkModalTitle, ModalFooter} from '@components/modal';

import {BulkInitKYCButton} from 'src/features/block-account-verification-bulk-actions/components/BulkKYCInitButton';
import {UserProfileGridItem} from 'src/features/data-grids/types';
import {BulkActionKey, BulkNoteApplyStepButton, BulkStepProps, useBulkModalStatus, useSelectedItems} from '../../block-bulk-actions';

import {BulkUserProfielSecurityCaseButton} from './BulkUserProfielSecurityCaseButton';
import {BulkUserProfileAccountLockGroup} from './BulkUserProfileAccountLockGroup';
import {BulkUserProfileApplyNotesModal} from './BulkUserProfileApplyNotesModal';
import {BulkUserProfileFeatureLockGroup} from './BulkUserProfileFeatureLockGroup';
import {BulkUserProfileSecurityCaseModal} from './BulkUserProfileSecurityCaseModal';
import {BulkUserProfileSendMessageButton} from './BulkUserProfileSendMessageButton';
import {BulkUserProfileSendMessageModal} from './BulkUserProfileSendMessageModal';

const localized = defineMessages({
    title: {
        id: 'BulkStepChooseActions_actionBulkPlayerSecurity',
        defaultMessage: 'Add Bulk Action',
    },
    cancel: {
        id: 'BulkStepChooseActions_Cancel',
        defaultMessage: 'Cancel',
    },
    confirm: {
        id: 'BulkStepChooseActions_Confirm',
        defaultMessage: 'Confirm',
    },
    itemsSelected: {
        id: 'BulkStepChooseActions_ItemsSelected',
        defaultMessage: '{value} Items Selected',
    },
    bulkFeatureLock: {
        id: 'BulkStepChooseActions_FeatureLock',
        defaultMessage: 'Feature Lock',
    },
});

type BulkStepPlayerSecurityChooseActionsProps = BulkStepProps & {
    onApply: () => void;
    actionKeys: BulkActionKey[];
};

export function BulkUserProfileChooseActionsModal({onPrev, onNext, onApply, actionKeys}: BulkStepPlayerSecurityChooseActionsProps) {
    const {formatMessage} = useIntl();
    const selectedItems = useSelectedItems<UserProfileGridItem>();
    const {hasProcessingItem} = useBulkModalStatus();
    const {chooseStep} = useContext(BulkModalContext);

    const openSecurityCases = () => chooseStep(securityCasesStep);
    const openSendMessage = () => chooseStep(sendMessageStep);
    const openNotes = () => chooseStep(notes);

    const handleCancelClick = () => {
        onPrev();
    };

    const handleNextClick = () => {
        onNext();
    };

    const totalItemsInfoMessage: string = formatMessage(localized.itemsSelected, {
        value: selectedItems?.length,
    });

    const securityCasesStep = <BulkUserProfileSecurityCaseModal onNext={onApply} onPrev={onApply} />;
    const sendMessageStep = <BulkUserProfileSendMessageModal onPrev={onApply} onNext={onApply} />;
    const notes = <BulkUserProfileApplyNotesModal onPrev={onApply} onNext={onApply} />;

    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent>
                <BulkUserProfileAccountLockGroup actionKeys={actionKeys} />
                <BulkUserProfileFeatureLockGroup actionKeys={actionKeys} />
                {actionKeys.includes(BulkActionKey.SecurityCases) ? (
                    <BulkUserProfielSecurityCaseButton onClick={openSecurityCases} />
                ) : null}
                {actionKeys.includes(BulkActionKey.Message) ? <BulkUserProfileSendMessageButton onClick={openSendMessage} /> : null}
                {actionKeys.includes(BulkActionKey.NotesAndAttachments) ? <BulkNoteApplyStepButton onClick={openNotes} /> : null}
                {actionKeys.includes(BulkActionKey.InitiatePaymentKYC) ? (
                    <BulkInitKYCButton players={selectedItems?.map(p => ({id: p.serverId, uid: p.uid}))} />
                ) : null}
            </BulkModalContent>
            <ModalFooter infoMessage={totalItemsInfoMessage}>
                <Button label={localized.cancel} onClick={handleCancelClick} />
                <Button color="primary" label={localized.confirm} onClick={handleNextClick} disabled={!hasProcessingItem} />
            </ModalFooter>
        </>
    );
}
