import {defineMessages, useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {useAutoMapper} from '@auto-mapper';
import {AddUserLabelInput, LabelGroup, LabelInput} from '@models/generated/graphql';
import {EntityType, LabelGroupFilterKeys, LabelGroupQueryFields} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit, ViewType} from '@redux/view';
import {SystemColorsEnum} from '@style';

import {Label, labelGroupColors, LabelGroupForm, LabelGroupSourceType, UserLabelsForm} from '../block-label-group-list/types';
import {notificationsActions} from '../block-notifications/actions';

import {AddLabelGroupRequestPayload, blockUserLabelActions, UpdateLabelGroupRequestPayload} from './actions';

const localized = defineMessages({
    playerLabelAssignDisplayName: {
        id: 'playerLabelAssignDisplayName',
        defaultMessage: 'Player Labels',
    },
    blockLabelUnassignConfirmMessage: {
        id: 'blockPlayerActionLabelUnassignConfirmMessage',
        defaultMessage: 'Label {label} will be removed',
    },
    blockLabelGroupCreateDisplayName: {
        id: 'blockLabelGroupCreateDisplayName',
        defaultMessage: 'Label Group Create',
    },
});

type UsePlayerLabelAssign = {
    handleAssign: (data: UserLabelsForm) => void;
};

export function usePlayerLabelAssign(uid: string): UsePlayerLabelAssign {
    const dispatch = useDispatch();

    function handleAssign(data: UserLabelsForm) {
        const newLabels = data.groups
            .flatMap(g => g?.labels)
            ?.filter(l => l && l?.selected && !l?.disabled)
            ?.map(l => l?.id);
        if (newLabels.length > 0) {
            const payload: AddUserLabelInput = {
                label_ids: newLabels,
                uid,
            };
            dispatch(blockUserLabelActions.addUserLabels.request(payload));
        }
    }

    return {
        handleAssign,
    };
}

type UsePlayerLabelUnassign = {
    handleUnassign: (uid: string, label: Label) => void;
};

export function usePlayerLabelUnassign(): UsePlayerLabelUnassign {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();

    function handleUnassign(uid: string, label: Label) {
        if (label) {
            dispatch(
                notificationsActions.show({
                    autoHideDuration: 4000,
                    action: blockUserLabelActions.deleteUserLabel.request({uid, label_id: label.id}),
                    text: formatMessage(localized.blockLabelUnassignConfirmMessage, {
                        label: label.name,
                    }),
                    type: 'Undo',
                })
            );
        }
    }

    return {handleUnassign};
}

type UsePlayerLabelCreate = {
    handleCreateLabel: (data: LabelInput) => void;
};

export function usePlayerLabelCreateLabel(): UsePlayerLabelCreate {
    const dispatch = useDispatch();

    function handleCreateLabel(label: LabelInput) {
        dispatch(blockUserLabelActions.addLabel.request({label}));
    }

    return {
        handleCreateLabel,
    };
}

type UseLabelGroupModify = {
    colors: SystemColorsEnum[];
    saveGroup: (data: LabelGroupForm) => void;
};

export function useLabelGroupModify(defaultLabel?: LabelGroupForm): UseLabelGroupModify {
    const dispatch = useDispatch();
    const mapper = useAutoMapper();
    const viewType: ViewType = 'BlockPlayerLabelsGroupModify';

    const {items} = useViewInit<LabelGroup, LabelGroupFilterKeys, LabelGroupQueryFields>({
        displayName: localized.blockLabelGroupCreateDisplayName,
        viewType,
        entity: {
            entity: EntityType.LabelGroup,
            fields: ['id', 'color'],
        },
        realtime: {
            entity: EntityType.LabelGroup,
            mode: RealtimeUpdatesMode.Silent,
            triggers: [{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Update}],
        },
        defaultPaging: {page: 1, pageSize: labelGroupColors.length},
        defaultFilters: [{key: 'source_type', value: LabelGroupSourceType.Custom}],
        defaultSorting: [{field: 'name', sort: 'asc'}],
        validateFilter: () => true,
    });

    const usedColors = items?.map(g => g.color) ?? [];
    const colors = labelGroupColors.filter(color => !usedColors.includes(color) || color === defaultLabel?.color);

    function saveGroup(data: LabelGroupForm) {
        if (defaultLabel) {
            const payload = mapper.map(data, LabelGroupForm, UpdateLabelGroupRequestPayload);
            dispatch(blockUserLabelActions.updateLabelGroup.request(payload));
        } else {
            const payload = mapper.map(data, LabelGroupForm, AddLabelGroupRequestPayload);
            dispatch(blockUserLabelActions.addLabelGroup.request(payload));
        }
    }

    return {colors, saveGroup};
}

type UsePlayerLabelGroups = {
    labelIds: string[];
    labelGroups: LabelGroup[];
};

export function usePlayerLabelGroups(): UsePlayerLabelGroups {
    const viewType: ViewType = 'BlockPlayerLabelAssign';

    const {items} = useViewInit<LabelGroup, LabelGroupFilterKeys, LabelGroupQueryFields>({
        viewType,
        displayName: localized.playerLabelAssignDisplayName,
        entity: {
            entity: EntityType.LabelGroup,
            fields: ['id', 'name', 'color', 'source_type', 'labels.id', 'labels.name'],
        },
        realtime: {
            entity: EntityType.LabelGroup,
            mode: RealtimeUpdatesMode.Silent,
            triggers: [{type: RealtimeMessageTrigger.Add}, {type: RealtimeMessageTrigger.Update}],
        },
        defaultFilters: [{key: 'source_type', value: LabelGroupSourceType.Custom}],
        defaultSorting: [{field: 'name', sort: 'asc'}],
        defaultPaging: {page: 1, pageSize: labelGroupColors.length},
        validateFilter: () => true,
    });
    const labelIds = items
        ?.flatMap(g => g?.labels)
        ?.map(l => l?.id)
        ?.filter(l => l);

    return {
        labelIds,
        labelGroups: items,
    };
}
