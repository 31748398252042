import {piiActions} from '../actions';

import {localized} from './UserProfileNationalId.localize';
import {withUserProfileTextField} from './UserProfileTextField';

export const UserProfileNationalId = withUserProfileTextField({
    action: piiActions.editNationalId,
    placeholder: localized.piiNationalIdFieldName,
    submitModelMapper: m => ({
        fieldValue: m?.user?.region_player_info?.national_id,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            region_player_info: {
                national_id: m?.fieldValue,
            },
        },
        userId: m?.userId,
    }),
    //TODO: [IGP-2179] Added because of the backend partial update logic. Rework after backend change
    isRequired: true,
});
