import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {ModuleName, SubmoduleName} from '@models/modules';
import {Policy} from '@auth';

export const batchTransactionCreateResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'BatchTransactionCreateResource',
    rules: new PoliciesAccessRule([
        new Policy(ModuleName.ActionCenter),
        new Policy(ModuleName.ActionCenter, null, 'create'),
        new Policy(ModuleName.ActionCenter, SubmoduleName.BatchTransaction),
        new Policy(ModuleName.ActionCenter, SubmoduleName.BatchTransaction, 'create'),
    ]),
});
