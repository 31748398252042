import {AsyncAction} from '@redux';

export class RequestPayload {
    errorCode?: number;
    data?: any;
}

export class LoadPayload<TFilter> extends RequestPayload {
    filter?: TFilter | void;

    dataKey: string;
}

export class PatchPayload extends RequestPayload {
    id: string;
    item: any;
    field: string;
    description?: string;

    fieldKey: string;
    modelKey: string;
    model?: unknown;

    messageType?: MessageType = MessageType.ModuleMessage;
}

export class PatchAsyncAction extends AsyncAction<string, PatchPayload, string, PatchPayload, string, PatchPayload> {}

export enum MessageType {
    FieldMessage,
    ModuleMessage,
}
