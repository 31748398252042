export const filterNotNulls = <T>(array: T[]) => array?.filter(i => i);

export function removeItemsFromArray<TItem>(array: TItem[], ...removeArray: TItem[]): TItem[] {
    return array?.filter(i => !removeArray?.includes(i));
}

export function arrayDifference<T>(deductible: T[], reduced: T[]): T[] {
    const result: T[] = [];
    const reducedSet = new Set(reduced);
    deductible?.forEach(i => {
        if (!reducedSet.has(i)) {
            result.push(i);
        }
    });
    return result;
}

export function getObjectListWithUniqueKey<T>(array: T[], key: keyof T): T[] {
    const uniqueKeyValues = [...new Set(array?.map(i => i[key])?.filter(i => i))];
    return uniqueKeyValues?.map(value => array?.find(i => i[key] === value));
}
