import {createAsyncAction} from 'typesafe-actions';

import {
    BaseRuleRequestPayload,
    BaseRuleResponsePayload,
    ChangeRuleOrderRequestPayload,
    ChangeRuleOrderResponsePayload,
    UpdateRuleRequestPayload,
    UpdateRuleResponsePayload,
} from '@services/rest-api/ruleApiService';

export const domain = 'block-automated-rule';

export const ruleActions = {
    createRule: createAsyncAction(`${domain}/createRuleRequest`, `${domain}/createRuleSuccess`, `${domain}/createRuleFailed`)<
        UpdateRuleRequestPayload,
        UpdateRuleResponsePayload,
        UpdateRuleResponsePayload
    >(),
    editRule: createAsyncAction(`${domain}/editRuleRequest`, `${domain}/editRuleSuccess`, `${domain}/editRuleFailed`)<
        UpdateRuleRequestPayload,
        UpdateRuleResponsePayload,
        UpdateRuleResponsePayload
    >(),
    activateRule: createAsyncAction(`${domain}/activateRuleRequest`, `${domain}/activateRuleSuccess`, `${domain}/activateRuleFailed`)<
        BaseRuleRequestPayload,
        BaseRuleResponsePayload,
        BaseRuleResponsePayload
    >(),
    deactivateRule: createAsyncAction(
        `${domain}/deactivateRuleRequest`,
        `${domain}/deactivateRuleSuccess`,
        `${domain}/deactivateRuleFailed`
    )<BaseRuleRequestPayload, BaseRuleResponsePayload, BaseRuleResponsePayload>(),
    deleteRule: createAsyncAction(`${domain}/deleteRuleRequest`, `${domain}/deleteRuleSuccess`, `${domain}/deleteRuleFailed`)<
        BaseRuleRequestPayload,
        BaseRuleResponsePayload,
        BaseRuleResponsePayload
    >(),
    changeRuleOrder: createAsyncAction(
        `${domain}/changeRuleOrderRequest`,
        `${domain}/changeRuleOrderSuccess`,
        `${domain}/changeRuleOrderFailed`
    )<ChangeRuleOrderRequestPayload, ChangeRuleOrderResponsePayload, ChangeRuleOrderResponsePayload>(),
};
