import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledAccordion from '@components/StyledAccordion';
import {agentReadPolicies} from '@models/permissions/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';

import {UserRelatedPlayers} from './UserRelatedPlayers';
import {UserSecurityInfo} from './UserSecurityInfo';

const localized = defineMessages({
    securityInfo: {
        id: 'UD_securityInfo',
        defaultMessage: 'Security Info',
    },
});

const useClasses = makeStyles()(() => ({
    userDetailsSecurityInfoContainer: {
        width: '100%',
    },
    userDetailsSecurityInfo: {
        paddingTop: 0,
        boxShadow: 'none',
    },
}));

type UserDetailsSecurityInfoProps = {
    userId: string;
};

const UserDetailsSecurityInfoInner = ({userId}: UserDetailsSecurityInfoProps) => {
    const {classes} = useClasses();

    return (
        <StyledAccordion
            header={localized.securityInfo}
            componentId="securityInfo"
            isCollapsed
            unmountOnCollapse
            children={
                <Box className={classes.userDetailsSecurityInfoContainer}>
                    <UserSecurityInfo className={classes.userDetailsSecurityInfo} itemId={userId} hideTitle />
                    <UserRelatedPlayers userId={userId} />
                </Box>
            }
        />
    );
};

export const UserDetailsSecurityInfo = withMultiplePermission(UserDetailsSecurityInfoInner, {
    restrictedPermissions: agentReadPolicies,
});
