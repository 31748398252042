import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledAccordion from '@components/StyledAccordion';
import {agentReadPolicies} from '@models/permissions/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {UserKYCInfo} from '../../block-account-verification/components/UserKYCInfo';

const localized = defineMessages({
    kycInfo: {
        id: 'UD_kycInfo',
        defaultMessage: 'KYC Info',
    },
});

const useClasses = makeStyles()(theme => ({
    userDetailsKYCInfoContainer: {
        margin: theme.spacing(2, 4, 4),
    },
}));

type UserDetailsKYCInfoProps = {
    userId: string;
};

const UserDetailsKYCInfoInner = ({userId}: UserDetailsKYCInfoProps) => {
    const {classes} = useClasses();

    return (
        <StyledAccordion
            header={localized.kycInfo}
            componentId="kycInfo"
            unmountOnCollapse
            isCollapsed
            children={
                <Box className={classes.userDetailsKYCInfoContainer}>
                    <UserKYCInfo userId={userId} />
                </Box>
            }
        />
    );
};

export const UserDetailsKYCInfo = withMultiplePermission(UserDetailsKYCInfoInner, {restrictedPermissions: agentReadPolicies});
