import {MessageDescriptor, useIntl} from 'react-intl';

import {useJurisdictionConfig} from '../config/hooks';

//TODO: [BO-2658] Move files to src/common/i18n

//Currency formatting
export function useCurrencyFormatter() {
    const {currency} = useJurisdictionConfig();
    const {formatNumber} = useIntl();
    return (value: number) =>
        formatNumber(value || 0, {
            style: 'currency',
            currency,
        });
}

export function useCurrency(value: number) {
    const formatCurrency = useCurrencyFormatter();
    return formatCurrency(value);
}

export function useCurrencySymbol() {
    const {currency} = useJurisdictionConfig();
    const {formatNumberToParts} = useIntl();
    return formatNumberToParts(0, {
        style: 'currency',
        currency,
    }).find(p => p.type === 'currency')?.value;
}

export function usePercentage() {
    const {formatNumber} = useIntl();
    return (value: number) => formatNumber(value ? value / 100 : 0, {style: 'percent'});
}

export function useDaysFormatter() {
    const {formatRelativeTime} = useIntl();
    return (value: number) => formatRelativeTime(value, 'day', {style: 'narrow'});
}

//Text formatting
export function useMessageFormatter() {
    const {formatMessage} = useIntl();
    return (text: string | MessageDescriptor, values?: any): string => {
        return text ? (typeof text === 'string' ? text : formatMessage(text, values)) : '';
    };
}
