import {createAsyncAction} from 'typesafe-actions';

import {PatchPayload} from '../../module-temp-storage/types';

import {domain} from './types';

const setVerificationStatusAction = '/setVerificationStatus';
const setVerificationStatusSucceedAction = '/setVerificationStatusSucceed';
const setVerificationStatusFailedAction = '/setVerificationStatusFailed';

export const actions = {
    setVerificationStatus: createAsyncAction(
        `${domain}${setVerificationStatusAction}`,
        `${domain}${setVerificationStatusSucceedAction}`,
        `${domain}${setVerificationStatusFailedAction}`
    )<PatchPayload, PatchPayload, PatchPayload>(),
};
