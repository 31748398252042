import {defineMessages} from 'react-intl';

import {NoteType} from '@models/generated/graphql';

export const localizedNoteType = defineMessages({
    [NoteType.Global]: {
        id: 'localizedNoteTypeGlobal',
        defaultMessage: 'Player Record',
    },
    [NoteType.Request]: {
        id: 'localizedNoteTypeRequest',
        defaultMessage: 'Current Request ID',
    },
});
