import {inject, injectable} from 'inversify';
import {filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {fileActions} from '@file/actions';
import {IFileFactory} from '@file/services/IFileFactory';
import {ServiceTypes} from '@inversify';
import {mergeMap} from '@otel';
import {BaseEpicsBuilder, RootEpic} from '@redux';

import {transactionsBulkActions} from './actions';
import {BulkDebitCreditAddModel, ParseManualTransactionFileData} from './types';

@injectable()
export class ActionBulkTransactionEpicsBuilder extends BaseEpicsBuilder {
    private _templateFactory: IFileFactory<ParseManualTransactionFileData, BulkDebitCreditAddModel[]>;

    constructor(
        @inject(ServiceTypes.ManualTransactionExcelTemplateFactory)
        templateFactory: IFileFactory<ParseManualTransactionFileData, BulkDebitCreditAddModel[]>
    ) {
        super();
        this._templateFactory = templateFactory;
    }

    protected buildEpicList(): RootEpic[] {
        return [this.buildDownloadBulkManualTransactionTemplateExcelEpic()];
    }

    private buildDownloadBulkManualTransactionTemplateExcelEpic(): RootEpic {
        return actions$ =>
            actions$.pipe(
                filter(isActionOf(transactionsBulkActions.downloadManualTransactionTemplate)),
                mergeMap(({payload}) =>
                    this._templateFactory.createFile(null, null, payload.transactionType).pipe(
                        map((doc: ArrayBuffer) =>
                            fileActions.download({
                                file: new Blob([doc]),
                                title: `Manual transaction bulk ${payload.transactionType} template`,
                                type: 'xlsx',
                            })
                        )
                    )
                )
            );
    }
}
