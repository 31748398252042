import React, {FC, useEffect, useRef, useState} from 'react';
import {Box} from '@mui/material';

import {StyledTooltip} from '../Tooltip';

import {useAutoAdjustedComplexTooltipClasses} from './AutoAdjustedComplexTooltip.styles';

type AutoAdjustedComplexTooltipProps = {
    value: React.ReactNode;
    tooltip: string;
};

export const AutoAdjustedComplexTooltip: FC<AutoAdjustedComplexTooltipProps> = ({value, tooltip}) => {
    const {classes} = useAutoAdjustedComplexTooltipClasses();
    const [isOverflowed, setIsOverflow] = useState(false);
    const hiddenAbsoluteElementRef = useRef<HTMLDivElement>(null);
    const visibleElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (visibleElementRef?.current && hiddenAbsoluteElementRef?.current) {
            setIsOverflow(hiddenAbsoluteElementRef.current.clientWidth > visibleElementRef.current.clientWidth);
        }
    }, [value, hiddenAbsoluteElementRef.current, visibleElementRef.current]);

    return (
        <>
            <StyledTooltip
                element={
                    <div ref={visibleElementRef} className={classes.autoAdjustedComplexTooltipElement}>
                        {value}
                    </div>
                }
                title={<Box>{tooltip}</Box>}
                hideTooltip={!isOverflowed}
            />
            <div ref={hiddenAbsoluteElementRef} className={classes.autoAdjustedComplexTooltipHiddenElement}>
                {value}
            </div>
        </>
    );
};

type AutoAdjustedComplexTooltipHOCProps = {
    hideTooltip?: boolean;
    tooltipLabel?: string;
};

export const withAutoAdjustedComplexTooltip =
    <TProps extends AutoAdjustedComplexTooltipHOCProps>(WrappedComponent: React.ComponentType<TProps>) =>
    (props: TProps) => {
        return props?.hideTooltip ? (
            <WrappedComponent {...(props as TProps)} />
        ) : (
            <AutoAdjustedComplexTooltip value={<WrappedComponent {...(props as TProps)} />} tooltip={props?.tooltipLabel} />
        );
    };
