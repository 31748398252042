import {RootState} from 'typesafe-actions';

import {BeBonusGridItem} from './types';

export const realtimeSubscribersSelector = (state: RootState) => state.beBonus.state.realtime.realtimeSubscribers;

export const subscribedTypesSelector = (state: RootState) => state.beBonus.state.realtime.enabledSubscriptionTypes;

export const itemSelector = (state: RootState) => state.beBonus.data.item as BeBonusGridItem;

export const filterSelector = (state: RootState) => state.beBonus.state.filter;

export const columnsSelector = (state: RootState) => state.beBonus.state.columns;
