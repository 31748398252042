import React, {PropsWithChildren} from 'react';
import {makeStyles} from 'tss-react/mui';

import {MessageWithIcon} from '@components/alerts/MessageWithIcon';
import {FormLabel} from '@components/form/FormLabel';
import {CustomIcon} from '@components/icons';
import {CustomTheme} from '@style';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    formControlGroup: {
        backgroundColor: 'transparent',
        color: theme.custom.palette.content.bodyText,
        width: '100%',
    },
    formControlGroupWithLeftLabel: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        alignItems: 'center',
        columnGap: theme.spacing(1),
        rowGap: theme.spacing(1),
    },
    formControlGroupInputs: {
        width: '100%',
    },
    formControlGroupHint: {
        color: theme.palette.secondary.main,
        gridColumn: 2,
    },
}));

export class FormControlGroupProps {
    label?: string;
    labelVariant?: 'caption' | 'default';
    labelPlacement?: 'top' | 'left';
    hint?: string;
}

export function FormControlGroup({
    children,
    label,
    labelVariant = 'caption',
    labelPlacement = 'top',
    hint,
}: PropsWithChildren<FormControlGroupProps>) {
    const {classes, cx} = useStyles();

    return (
        <div
            className={cx(
                {
                    [classes.formControlGroupWithLeftLabel]: label && labelPlacement === 'left',
                },
                classes.formControlGroup
            )}
        >
            {label ? <FormLabel label={label} labelVariant={labelVariant} /> : null}
            <div className={classes.formControlGroupInputs}>{children}</div>
            {hint ? <MessageWithIcon className={classes.formControlGroupHint} icon={CustomIcon.WarningOutline} label={hint} /> : null}
        </div>
    );
}
