import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'typesafe-actions';

import tempStateStorageActions from '../../actions';
import {tempStorageDataSelector, tempStorageProgressSelector} from '../../selectors';
import {getTempStorageKey} from '../../utils';

//TODO: move to editable or remove not used once we update modals usage
export const getTempStorageEntityKeys = (typeName: string, id: string, field: string) => {
    const fieldKey = getTempStorageKey(typeName, id, field);
    const rowKey = getTempStorageKey(typeName, id);

    return {
        rowKey,
        fieldKey,
    };
};

export const useTempStorageEditState = (key: string) => {
    const dispatch = useDispatch();
    const isEditing = useSelector((s: RootState) => s.tempStorage.edit[key]) === true;

    const startEdit = () => {
        if (!isEditing) {
            dispatch(tempStateStorageActions.changeEditState({key: key, value: true}));
        }
    };

    const stopEdit = () => {
        if (isEditing) {
            dispatch(tempStateStorageActions.changeEditState({key: key, value: false}));
        }
    };

    return {
        isEditing,
        startEdit,
        stopEdit,
    };
};

export const useTempStorageInProgressState = (key: string) => {
    return {
        isUpdateInProgress: useSelector((s: RootState) => s.tempStorage.inProgress[key]),
    };
};

export const useTempStorageDataLoad = <TData>(key: string) => {
    return {
        isLoading: useSelector(tempStorageProgressSelector(key)),
        data: useSelector(tempStorageDataSelector<TData>(key)),
    };
};

//TODO: remove appropriate reducer when we fully migrate to EditableProvider for work with edit mode
export const useTempStorageEditRow = (typeName: string, fields: string[]) => {
    const editTempState = useSelector((s: RootState) => s.tempStorage.edit);
    const fieldRegexp = new RegExp(`(${fields?.join('|')})`);
    const rowInEdit = Object.keys(editTempState).filter(s => s.includes(typeName) && s.match(fieldRegexp));
    const [, id, field] = rowInEdit?.length ? rowInEdit[0].split('.') : [];

    return {
        typeName,
        id,
        field,
    };
};
