import {RootState} from 'typesafe-actions';

import {RequestStatus} from './types';

export const asyncActionStatusSelector = (actionRequest: string) => (state: RootState) =>
    state.asyncActions ? state.asyncActions[actionRequest]?.requestStatus ?? RequestStatus.None : RequestStatus.None;

export const asyncActionErrorMessageSelector = (actionRequest: string) => (state: RootState) =>
    state.asyncActions?.[actionRequest]?.errorMessage;

export const asyncActionErrorMessageValuesSelector = (actionRequest: string) => (state: RootState) =>
    state.asyncActions?.[actionRequest]?.errorMessageValues;
