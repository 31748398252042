import React from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Box} from '@mui/material';
import moment from 'moment';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

import {SelectOption} from '../../../features/module-shared/types';

import {FormSelect} from './FormInput';

const useStyles = makeStyles()((theme: CustomTheme) => {
    return {
        datePickerYearMonthContainer: {
            display: 'flex',
            gap: theme.spacing(0.75),
            width: '100%',
        },
        datePickerYearMonthFormMonth: {
            flexBasis: '50%',
        },
        datePickerYearMonthFormDropdown: {
            marginTop: theme.spacing(0),
        },
    };
});

export const localized = defineMessages({
    datePickerYearMonthFormJanuary: {
        id: 'datePickerYearMonthFormJanuary',
        defaultMessage: 'January',
    },
    datePickerYearMonthFormFebruary: {
        id: 'datePickerYearMonthFormFebruary',
        defaultMessage: 'February',
    },
    datePickerYearMonthFormMarch: {
        id: 'datePickerYearMonthFormMarch',
        defaultMessage: 'March',
    },
    datePickerYearMonthFormApril: {
        id: 'datePickerYearMonthFormApril',
        defaultMessage: 'April',
    },
    datePickerYearMonthFormMay: {
        id: 'datePickerYearMonthFormMay',
        defaultMessage: 'May',
    },
    datePickerYearMonthFormJune: {
        id: 'datePickerYearMonthFormJune',
        defaultMessage: 'June',
    },
    datePickerYearMonthFormJuly: {
        id: 'datePickerYearMonthFormJuly',
        defaultMessage: 'July',
    },
    datePickerYearMonthFormAugust: {
        id: 'datePickerYearMonthFormAugust',
        defaultMessage: 'August',
    },
    datePickerYearMonthFormSeptember: {
        id: 'datePickerYearMonthFormSeptember',
        defaultMessage: 'September',
    },
    datePickerYearMonthFormOctober: {
        id: 'datePickerYearMonthFormOctober',
        defaultMessage: 'October',
    },
    datePickerYearMonthFormNovember: {
        id: 'datePickerYearMonthFormNovember',
        defaultMessage: 'November',
    },
    datePickerYearMonthFormDecember: {
        id: 'datePickerYearMonthFormDecember',
        defaultMessage: 'December',
    },
});

const months = [
    localized.datePickerYearMonthFormJanuary,
    localized.datePickerYearMonthFormFebruary,
    localized.datePickerYearMonthFormMarch,
    localized.datePickerYearMonthFormApril,
    localized.datePickerYearMonthFormMay,
    localized.datePickerYearMonthFormJune,
    localized.datePickerYearMonthFormJuly,
    localized.datePickerYearMonthFormAugust,
    localized.datePickerYearMonthFormSeptember,
    localized.datePickerYearMonthFormOctober,
    localized.datePickerYearMonthFormNovember,
    localized.datePickerYearMonthFormDecember,
];
const minAvailableYear = 1900;
const maxAvailableYearFromToday = 100; //100 years future can be toDate

type DatePickerYearMonthFormProps = {
    date: Date;
    onChange?: (date: Date) => void;
    disableFutureDates?: Boolean;
};

export function useYearsAndMonthsOptions({disableFutureDates = false}: Omit<DatePickerYearMonthFormProps, 'date' | 'onChange'>) {
    const {formatMessage} = useIntl();
    const maxDate = disableFutureDates ? moment().toDate() : moment().add(maxAvailableYearFromToday, 'years').toDate();
    const monthsOptions = months.map((m, index) => ({label: formatMessage(m), value: index}));
    const yearsOptions = getYearsOptions();

    function getYearsOptions(): SelectOption[] {
        const yearsOptions: SelectOption[] = [];
        const maxAvailableYear = maxDate.getFullYear();

        for (let index = minAvailableYear; index <= maxAvailableYear; index += 1) {
            yearsOptions.push({label: `${index}`, value: index});
        }

        return yearsOptions;
    }

    return {yearsOptions, monthsOptions};
}

export function DatePickerYearMonthForm({date, onChange, disableFutureDates}: DatePickerYearMonthFormProps) {
    const {classes} = useStyles();
    const month = date.getMonth();
    const year = date.getFullYear();
    const {yearsOptions, monthsOptions} = useYearsAndMonthsOptions({
        disableFutureDates,
    });

    function handleMonthChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newMonth = Number(e.target.value);
        const newDate = new Date(year, newMonth);
        onChange(newDate);
    }

    function handleYearChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newYear = Number(e.target.value);
        const newDate = new Date(newYear, month);
        onChange(newDate);
    }

    return (
        <Box className={classes.datePickerYearMonthContainer}>
            <FormSelect
                className={classes.datePickerYearMonthFormMonth}
                hasBottomSpacing={false}
                fullWidth={false}
                isCompareOptionsAvailable={false}
                value={month}
                onChange={handleMonthChange}
                options={monthsOptions}
                dropdownClassName={classes.datePickerYearMonthFormDropdown}
                displayEmpty
                alignLeft
            />
            <FormSelect
                hasBottomSpacing={false}
                fullWidth={false}
                isCompareOptionsAvailable={false}
                value={year}
                onChange={handleYearChange}
                options={yearsOptions}
                dropdownClassName={classes.datePickerYearMonthFormDropdown}
                displayEmpty
                alignLeft
            />
        </Box>
    );
}
