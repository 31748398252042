import React, {useEffect} from 'react';
import {Controller, useFieldArray, useFormContext, useFormState} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {Box, Checkbox, List, ListItem, ListItemText, TextField} from '@mui/material';

import {FormError, useCustomValidationFormatter} from '@components/input';
import {SecurityCase, SecurityCaseType} from '@models/generated/graphql';

import {localizedSecurityCaseType} from '../../../app/intl/shared-resources/securityCaseType';
import {getTempStorageKey} from '../../../module-temp-storage/utils';
import {EditSecurityCasesFieldModel, SecurityCaseEditModel} from '../types';

import {SecurityCasesAutocomplete} from './SecurityCasesAutocomplete';
import {useClasses} from './SecurityCasesFieldsForm.style';

const localized = defineMessages({
    caseIdValidationMessage: {
        id: 'caseIdValidationMessage',
        defaultMessage: 'Wrong format for security case ID. Please follow ‘type_MMDDYYYY_number, example: {type}_01112022_1001',
    },
});

type SecurityCasesFieldsFormProps = {
    id: string;
    value: EditSecurityCasesFieldModel;
    typeName: string;
};

const getTypePrefix = (type: SecurityCaseType) => type.substring(0, 3)?.toUpperCase();

const getCaseIdRegex = (type: SecurityCaseType) =>
    new RegExp(`^((${getTypePrefix(type)})_((0[1-9]|1[0-2])(0[1-9]|[12]\\d|3[01])[12]\\d{3})_(\\d+))?$`);

export const SecurityCasesFieldsForm = ({id, value, typeName}: SecurityCasesFieldsFormProps) => {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();
    const customValidationFormatter = useCustomValidationFormatter();

    const {control, reset} = useFormContext<EditSecurityCasesFieldModel>();
    const {fields, replace} = useFieldArray({
        control,
        name: 'cases',
    });
    const {errors} = useFormState({control});

    useEffect(() => {
        reset({
            cases: value.cases,
        });
    }, [id]);

    const getLabel = (securityCase: SecurityCase): string => formatMessage(localizedSecurityCaseType[securityCase.type]);

    const handleAutocompleteChange = (securityCase: SecurityCaseEditModel) => {
        replace(
            fields?.map(i => {
                const editCase = i as unknown as SecurityCaseEditModel;
                return securityCase.type === editCase.type
                    ? {...editCase, case_id: securityCase.case_id, isNew: securityCase.isNew}
                    : editCase;
            })
        );
    };

    return (
        <List classes={{padding: classes.securityCasesFieldsModalList}} data-testid="securityCasesList">
            {fields.map((i, index) => {
                const securityCase = i as unknown as SecurityCaseEditModel;
                return (
                    <ListItem className={classes.securityCasesFieldsModalListItem} key={`${securityCase.type}-${index}`}>
                        <ListItemText primary={getLabel(securityCase)} />
                        <Box className={classes.securityCasesFieldsModalSecondaryAction}>
                            <Box display="none">
                                <Controller
                                    render={_ => <TextField hidden />}
                                    name={`cases.${index}.type` as 'cases.0.type'}
                                    control={control}
                                    defaultValue={securityCase.type}
                                />
                                <Controller
                                    render={({field}) => <Checkbox checked={field.value} value={field.value} hidden color="secondary" />}
                                    name={`cases.${index}.isNew` as 'cases.0.isNew'}
                                    control={control}
                                    defaultValue={securityCase.isNew}
                                />
                            </Box>
                            <Controller
                                render={({field}) => {
                                    const fieldKey = getTempStorageKey(typeName, id, field.name);
                                    return (
                                        <Box
                                            className={classes.securityCasesFieldsModalListItemAutocomplete}
                                            data-testid={`${securityCase.type}_autocomplete`}
                                        >
                                            <SecurityCasesAutocomplete
                                                classes={{root: classes.securityCasesFieldsModalListItemAutocomplete}}
                                                value={{type: securityCase.type, case_id: field.value, isNew: securityCase.isNew}}
                                                onChange={handleAutocompleteChange}
                                                fieldKey={fieldKey}
                                                type={securityCase.type}
                                                userId={id}
                                            />
                                        </Box>
                                    );
                                }}
                                defaultValue={securityCase.case_id}
                                name={`cases.${index}.case_id` as 'cases.0.case_id'}
                                control={control}
                                rules={{
                                    required: false,
                                    pattern: {
                                        value: getCaseIdRegex(securityCase.type),
                                        message: customValidationFormatter(localized.caseIdValidationMessage, {
                                            type: getTypePrefix(securityCase.type),
                                        }),
                                    },
                                }}
                            />
                            {errors?.cases?.[index]?.case_id?.message ? (
                                <FormError data-testid={`${securityCase.type}_error-message`}>
                                    {errors?.cases?.[index]?.case_id?.message}
                                </FormError>
                            ) : null}
                        </Box>
                    </ListItem>
                );
            })}
        </List>
    );
};
