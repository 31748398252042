import React, {useContext} from 'react';

import {Button, ButtonProps} from '@components/button/Buttons';
import {BulkModalContext} from '@components/modal';

import {BulkBonusSelectUsersModal} from '../../block-bonus-code';
import {
    BulkBonusDepositCashMatchApplyMarketingCodeStep,
    BulkBonusDepositCashMatchApplyTotalAmountStep,
} from '../../block-bonus-code/components/BulkBonusApplyStep';
import {BulkBonusDepositCashMatchChangesSummaryStep} from '../../block-bonus-code/components/BulkBonusChangesSummaryStep';
import {BulkBonusDepositCashMatchExecutionStep} from '../../block-bonus-code/components/BulkBonusExecutionStep';
import {useBulkAction} from '../../block-bulk-actions';

type BulkBonusDepositCashMatchChooseActionButtonProps = ButtonProps & {bonusId: string};

export const BulkBonusDepositCashMatchChooseActionButton = ({bonusId, ...props}: BulkBonusDepositCashMatchChooseActionButtonProps) => {
    const {chooseStep, closeModal} = useContext(BulkModalContext);
    const {resetBulkState} = useBulkAction();

    const openChooseAction = () => chooseStep(chooseActionStep, 'md');
    const openChangesSummary = () => chooseStep(changesSummaryStep, 'md');
    const openExecutionSummary = () => chooseStep(executionSummaryStep, 'md');
    const openAddAmountStep = () => chooseStep(addAmountStep, 'md');
    const openAddCodeStep = () => chooseStep(addCodeStep, 'md');

    const chooseActionStep = <BulkBonusSelectUsersModal onNext={openAddAmountStep} onPrev={closeModal} selectedBonusesIds={[bonusId]} />;
    const addAmountStep = <BulkBonusDepositCashMatchApplyTotalAmountStep onNext={openAddCodeStep} onPrev={closeModal} />;
    const addCodeStep = <BulkBonusDepositCashMatchApplyMarketingCodeStep onNext={openChangesSummary} onPrev={closeModal} />;
    const changesSummaryStep = <BulkBonusDepositCashMatchChangesSummaryStep onNext={openExecutionSummary} onPrev={closeModal} />;
    const executionSummaryStep = <BulkBonusDepositCashMatchExecutionStep onNext={closeModal} hasRetry={true} />;

    const handleBulkOpenClick = () => {
        resetBulkState();
        openChooseAction();
    };

    return <Button {...props} onClick={handleBulkOpenClick} />;
};
