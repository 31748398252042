import {createAsyncAction} from 'typesafe-actions';

import {DownloadRequestActionPayload, DownloadSuccessActionPayload} from '@file/types';
import {
    ManualTransaction,
    MutationAddManualTransactionArgs,
    MutationClaimRevenueShareArgs,
    ReasonCode,
    RevenueShareClaimResult,
    TransactionStatus,
} from '@models/generated/graphql';
import {TransactionViewModelKeys} from '@models/transaction/types';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {TransactionQueryFields} from '@redux/entity';
import {GqlMutationRequest, RestRequest, ServiceResponsePayload} from '@services/types';

import {Message} from '../message-snack-bar/types';

import {ManualTransactionAddViewModel} from './types';

const domain = 'block-transaction-actions';

export type SetWithdrawalStatusRequestPayload = {
    transactionId: string;
    status: TransactionStatus;
    reason?: ReasonCode;
    reasonText?: string;
    //TODO: [IGP-2481] Remove after fix for admin id in event log
    userId?: string;
    adminId?: string;
    adminName?: string;
};

export type OpenCashierPayload = {
    transactionId: string;
};

export type ClaimRevenueShareRequestPayload = {
    uid: string;
    amount: number;
};

export type AddManualTransactionRequestPayload = ManualTransactionAddViewModel;

export type TransactionDownloadPayload = Omit<DownloadRequestActionPayload<TransactionViewModelKeys, TransactionQueryFields>, 'headers'> & {
    userWorksheetKeys?: UserProfileViewModelKeys[];
    headers: Partial<Record<TransactionViewModelKeys | UserProfileViewModelKeys, string>>;
};

export const transactionActions = {
    setWithdrawalPaymentStatus: createAsyncAction(
        `${domain}/setWithdrawalPaymentStatusRequest`,
        `${domain}/setWithdrawalPaymentStatusSucceed`,
        `${domain}/setWithdrawalPaymentStatusFailure`
    )<SetWithdrawalStatusRequestPayload, ServiceResponsePayload<RestRequest, unknown>, ServiceResponsePayload<RestRequest, unknown>>(),

    setWithdrawalRiskStatus: createAsyncAction(
        `${domain}/setWithdrawalRiskStatusRequest`,
        `${domain}/setWithdrawalRiskStatusSucceed`,
        `${domain}/setWithdrawalRiskStatusFailure`
    )<SetWithdrawalStatusRequestPayload, ServiceResponsePayload<RestRequest, unknown>, ServiceResponsePayload<RestRequest, unknown>>(),

    openCashier: createAsyncAction(`${domain}/openCashierRequest`, `${domain}/openCashierSuccess`, `${domain}/openCashierFailure`)<
        OpenCashierPayload,
        ServiceResponsePayload<RestRequest, string>,
        ServiceResponsePayload<RestRequest, string>
    >(),

    transactionDownloadCsv: createAsyncAction(
        `${domain}/transactionWithUserProfileDownloadCsvRequest`,
        `${domain}/transactionWithUserProfileDownloadCsvSuccess`,
        `${domain}/transactionWithUserProfileDownloadCsvFailed`
    )<TransactionDownloadPayload, DownloadSuccessActionPayload, Message>(),

    claimRevenueShare: createAsyncAction(
        `${domain}/claimRevenueShareRequest`,
        `${domain}/claimRevenueShareSucceed`,
        `${domain}/claimRevenueShareFailure`
    )<
        ClaimRevenueShareRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationClaimRevenueShareArgs>, RevenueShareClaimResult>,
        ServiceResponsePayload<GqlMutationRequest<MutationClaimRevenueShareArgs>, RevenueShareClaimResult>
    >(),

    addManualTransaction: createAsyncAction(
        `${domain}/addManualTransactionRequest`,
        `${domain}/addManualTransactionSuccess`,
        `${domain}/addManualTransactionFailure`
    )<
        AddManualTransactionRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionArgs>, ManualTransaction>,
        ServiceResponsePayload<GqlMutationRequest<MutationAddManualTransactionArgs>, ManualTransaction>
    >(),
};
