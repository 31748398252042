import {MarketingCode} from '@models/generated/graphql';

import {protectEpics} from '../../features/app/error-handling/epics';
import {MarketingCodeGridItem, MarketingCodeItem} from '../../features/marketing-code/types';
import {createGridEpics} from '../../features/module-shared/epics';
import {createGridSubscriberRealtimeEpics} from '../../features/realtime-updates/epics';
import {SubscriptionType} from '../../features/realtime-updates/types';

import {localizedMarketingBonusCode} from './components/MarketingBonusCode.localize';
import {columnsSelector, filterSelector, itemsSelector} from './selectors';
import {domain} from './types';

export default protectEpics(
    createGridEpics<MarketingCodeItem, MarketingCodeGridItem>(domain, c => c.marketingBonusCodeService, filterSelector, columnsSelector),
    createGridSubscriberRealtimeEpics<MarketingCode, string>(
        {realtimeKey: domain, domain, name: localizedMarketingBonusCode.marketingBonusCode},
        itemsSelector,
        u => `${u?.marketing_code}-${u?.type}`,
        [SubscriptionType.Added, SubscriptionType.Deleted],
        container => container.marketingBonusCodeService,
        filterSelector
    )
);
