import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Link as MuiLink, LinkProps as MuiLinkProps} from '@mui/material';
import {push} from '@vs-centaurea/connected-react-router';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';
import {appendSelectedItemToQuery} from '@utils';

const useStyles = makeStyles()((theme: CustomTheme) => ({
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));

type LinkTarget = '_blank' | '_top' | '_self' | '_parent';

export type LinkProps = Omit<MuiLinkProps, 'title' | 'target' | 'color'> & {
    title?: string | MessageDescriptor;
    target?: LinkTarget;
};

export function withLink<TProps extends LinkProps>(WrappedComponent: React.ComponentType<TProps>, underlineAlways: boolean) {
    return function LinkHOC({...props}: TProps) {
        const {classes} = useStyles();
        const {title, onClick, className, underline, href, target = '_self'} = props;
        const {formatMessage} = useIntl();
        const formattedTitle = title ? (typeof title === 'string' ? title : formatMessage(title as MessageDescriptor)) : '';

        return (
            <MuiLink
                underline={underline ?? (underlineAlways ? 'always' : 'none')}
                href={href}
                target={target}
                className={`${classes.link} ${className}`}
                onClick={onClick}
                data-testid="linkAction"
                title={formattedTitle}
            >
                <WrappedComponent {...props}></WrappedComponent>
            </MuiLink>
        );
    };
}

type ReduxLinkProps = LinkProps & {
    path: string;
    id?: string;
};

export function withReduxLink<TProps extends ReduxLinkProps>(WrappedComponent: React.ComponentType<LinkProps>) {
    return function ReduxLinkHOC({...props}: TProps) {
        const {path, id} = props;
        const fullUrl = id && id?.length ? appendSelectedItemToQuery(path, id) : path;
        const dispatch = useDispatch();

        const handleClick = () => {
            dispatch(push(fullUrl));
        };

        return <WrappedComponent {...props} onClick={handleClick}></WrappedComponent>;
    };
}
