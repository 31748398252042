import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useBulkAddBonusCodeButtonStyles = makeStyles()((theme: CustomTheme) => ({
    bulkActionBonusFormContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: theme.spacing(2),
        rowGap: theme.spacing(2),
        maxWidth: '240px',
    },
    bulkActionBonusFormLabel: {
        minWidth: theme.spacing(20),
        marginRight: theme.spacing(6),
    },
    bulkActionBonusFormInput: {
        marginBottom: 0,
    },
    bulkActionBonusFormActions: {
        margin: theme.spacing(1),
    },
}));
