import {defineMessages} from 'react-intl';

import {RevenueShareOfferingStatus} from '@models/generated/graphql';

export const localizedRevenueShareOfferingStatus = defineMessages({
    [RevenueShareOfferingStatus.Active]: {
        id: 'localizedRevenueShareOfferingStatusActive',
        defaultMessage: 'Active',
    },
    [RevenueShareOfferingStatus.Inactive]: {
        id: 'localizedRevenueShareOfferingStatusInactive',
        defaultMessage: 'Inactive',
    },
});
