import React, {SyntheticEvent} from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TreeView as MuiTreeView, TreeViewProps as MuiTreeViewProps} from '@mui/x-tree-view/TreeView';

type TreeViewProps = MuiTreeViewProps<boolean> & {};

export function TreeView({defaultExpandIcon, defaultCollapseIcon, ...otherProps}: TreeViewProps) {
    const handleClick = (e: SyntheticEvent<HTMLElement, Event>) => {
        e.stopPropagation();
    };
    return (
        <MuiTreeView
            {...otherProps}
            onClick={handleClick}
            defaultExpandIcon={defaultExpandIcon ?? <ExpandLessIcon />}
            defaultCollapseIcon={defaultCollapseIcon ?? <ExpandMoreIcon />}
        ></MuiTreeView>
    );
}
