import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';

import {RestHttpService} from './restHttpService';

/**
 * @deprecated should use services from src/common/services
 */
export abstract class ApiService extends RestHttpService {
    additionalEndpoint: string;
    additionalEndpointParam: string;

    constructor(
        apiUrl: string,
        endpoint: string,
        tracingService: ITracingService,
        userManager: UserManagerExtended,
        additionalEndpoint?: string,
        additionalEndpointParam?: string
    ) {
        super(`${apiUrl}/${endpoint}`, tracingService, userManager);
        this.additionalEndpoint = additionalEndpoint;
        this.additionalEndpointParam = additionalEndpointParam;
    }
}
