import React, {useRef} from 'react';
import {defineMessages} from 'react-intl';
import {useSelector} from 'react-redux';
import {makeStyles} from 'tss-react/mui';

import {useAutoMapper} from '@auto-mapper';
import {CommentEditor, CommentEditorSize, CommentFormModel} from '@components/comment';
import {BulkModalContent, BulkModalTitle} from '@components/modal';

import {BulkApplyStepModalHeader, BulkStepProps, notesAttachmentsResultValueSelector} from '../../block-bulk-actions';
import {useNotesUserSuggestions} from '../../block-note/hooks';
import {NoteInputViewModel} from '../../block-note/types';
import {NoteActionItem} from '../types';

const useClasses = makeStyles()(() => ({
    bulkStepAddNotesContent: {
        display: 'flex',
    },
}));

const localized = defineMessages({
    addNotesAndAttachment: {
        id: 'BulkAddNoteModal_AddNotesAndAttachment',
        defaultMessage: 'Add Notes and Attachment',
    },
    title: {
        id: 'BulkAddNoteModal_actionBulkPlayerSecurity',
        defaultMessage: 'Add Bulk Action',
    },
});

type BulkAddNoteModalProps = Omit<BulkStepProps, 'onNext'> & {
    onNext: (commentFormModel: CommentFormModel) => void;
};

export function BulkNoteApplyStepModal({onPrev, onNext}: BulkAddNoteModalProps) {
    const mapper = useAutoMapper();
    const notesAndAttachmentResultValue: NoteActionItem = useSelector(notesAttachmentsResultValueSelector);
    const initialValue = mapper.map(notesAndAttachmentResultValue, NoteInputViewModel, CommentFormModel);
    const {classes} = useClasses();
    const commentEditorRef = useRef(null);
    const suggestions = useNotesUserSuggestions('BulkStepNotes');

    function onApplyClicked() {
        if (commentEditorRef?.current) {
            commentEditorRef.current.submitForm();
        }
    }

    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent className={classes.bulkStepAddNotesContent}>
                <BulkApplyStepModalHeader back={onPrev} apply={onApplyClicked} label={localized.addNotesAndAttachment} />
                <CommentEditor
                    ref={commentEditorRef}
                    size={CommentEditorSize.FullHeight}
                    hideFooterActions={true}
                    isEditMode={true}
                    suggestions={suggestions}
                    onSubmit={onNext}
                    isProgress={false}
                    initialValue={initialValue}
                />
            </BulkModalContent>
        </>
    );
}
