import React from 'react';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

const useClasses = makeStyles()(theme => ({
    layoutWorkspaceDetailsHeaderActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: theme.spacing(2),
    },
}));

type LayoutDetailsHeaderActionsProps = {
    children: React.ReactNode;
};

export const LayoutDetailsHeaderActions = ({children}: LayoutDetailsHeaderActionsProps) => {
    const {classes} = useClasses();

    return <Box className={classes.layoutWorkspaceDetailsHeaderActions}>{children}</Box>;
};
