import {createAsyncAction} from 'typesafe-actions';

import {UserAddressInfoInput, UserProfile, UserProfileInput} from '@models/generated/graphql';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

const domain = 'user-profile-address-info';

export type EditAddressInfoRequestPayload = {
    user: Pick<UserProfileInput, 'uid'> & {
        address: Pick<UserAddressInfoInput, 'city' | 'country_info' | 'state' | 'post_code' | 'street'> & {
            stateName?: string;
        };
    };
    userId: string;
};

export const addressInfoActions = {
    editAddressCity: createAsyncAction(
        `${domain}/editAddressCityRequest`,
        `${domain}/editAddressCitySuccess`,
        `${domain}/editAddressCityFailure`
    )<
        EditAddressInfoRequestPayload,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>
    >(),

    editAddressCountry: createAsyncAction(
        `${domain}/editAddressCountryRequest`,
        `${domain}/editAddressCountrySuccess`,
        `${domain}/editAddressCountryFailure`
    )<
        EditAddressInfoRequestPayload,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>
    >(),

    editAddressPostCode: createAsyncAction(
        `${domain}/editAddressPostCodeRequest`,
        `${domain}/editAddressPostCodeSuccess`,
        `${domain}/editAddressPostCodeFailure`
    )<
        EditAddressInfoRequestPayload,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>
    >(),

    editAddressStreet: createAsyncAction(
        `${domain}/editAddressStreetRequest`,
        `${domain}/editAddressStreetSuccess`,
        `${domain}/editAddressStreetFailure`
    )<
        EditAddressInfoRequestPayload,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>,
        ServiceResponsePayload<GqlMutationRequest, UserProfile>
    >(),
};
