import {IModuleGridItem} from '@components/data-grid';
import {TransactionGroupSummary, TransactionType} from '@models/generated/graphql';

export const domain = 'user-finance-summary';

export class FinanceSummaryModel implements IModuleGridItem {
    id: string;
    isNew: boolean;
    serverId: string;

    type: TransactionType;
    method: string;
    reference: string;
    successful_amount: number;
    failed_amount: number;
    successful_count: number;
    failed_count: number;
    currency: string;
    rejected_amount: number;
    rejected_count: number;

    constructor(type: TransactionType, summary: TransactionGroupSummary) {
        this.id = `${type}_${summary?.category}_${summary?.group_name}`;
        this.type = type;
        this.method = summary?.category;
        this.reference = summary?.group_name;
        this.successful_amount = summary?.successful_amount;
        this.failed_amount = summary?.failed_amount;
        this.successful_count = summary?.successful_count;
        this.failed_count = summary?.failed_count;
        this.currency = summary?.currency;
        this.rejected_amount = summary?.rejected_amount;
        this.rejected_count = summary?.rejected_count;
    }
}
