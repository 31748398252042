import React from 'react';
import {Box} from '@mui/material';

import {useLayoutQueueTabsClasses} from './LayoutQueueTabs.style';

type LayoutQueueTabsProps = {
    header?: JSX.Element;
    body?: JSX.Element;
    footer?: JSX.Element;
    'data-testid'?: string;
};

export const LayoutQueueTabs = (props: LayoutQueueTabsProps) => {
    const {classes} = useLayoutQueueTabsClasses();
    const {header, body, footer, 'data-testid': dataTestId} = props;

    return (
        <Box className={classes.layoutQueueTabsContainer} data-testid={dataTestId}>
            {header ? <Box className={classes.layoutQueueTabsHeader}>{header}</Box> : null}
            {body ? <Box className={classes.layoutQueueTabsBody}>{body}</Box> : null}
            {footer ? <Box className={classes.layoutQueueTabsFooter}>{footer}</Box> : null}
        </Box>
    );
};
