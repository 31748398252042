import {UserManagerSettings} from 'oidc-client-ts';

export type AuthServer = 'internal' | 'keycloak';

export function getUserManagerSettings(
    authType: AuthServer,
    authApiUrl: string,
    clientId: string,
    silentRefreshPage: string
): UserManagerSettings {
    const clientUrl = location.origin;
    const path = authType === 'internal' ? '' : '/realms/master';

    return {
        authority: `${authApiUrl}${path}`,
        client_id: clientId,
        redirect_uri: `${clientUrl}`,
        post_logout_redirect_uri: clientUrl,
        response_type: 'code',
        scope: 'openid profile',
        filterProtocolClaims: true,
        loadUserInfo: true,
        silent_redirect_uri: `${clientUrl}/${silentRefreshPage}`,
    };
}
