import {ChipType, ChipVariant} from '@components/chip/types';
import {ChipMapping} from '@config/chip/chipConfig';
import {TransactionType} from '@models/generated/graphql';

import {localizedTransactionType} from '../../../features/app/intl/shared-resources/transactionType';

//TODO: [BO-2691] Remove localization configuration from chip configuration - should be managed through EnumLocalizer BO-2688
export const getTransactionTypeChipsMapping = (chipType: ChipType): Record<TransactionType, ChipMapping> => ({
    [TransactionType.Credit]: {
        label: localizedTransactionType[TransactionType.Credit],
        chipType,
        chipVariant: ChipVariant.LightGreen,
    },
    [TransactionType.Debit]: {
        label: localizedTransactionType[TransactionType.Debit],
        chipType,
        chipVariant: ChipVariant.LightGreen,
    },
    [TransactionType.Deposit]: {
        label: localizedTransactionType[TransactionType.Deposit],
        chipType,
        chipVariant: ChipVariant.Turquoise,
    },
    [TransactionType.Withdrawal]: {
        label: localizedTransactionType[TransactionType.Withdrawal],
        chipType,
        chipVariant: ChipVariant.LightBlue,
    },
    [TransactionType.BuyIn]: {
        label: localizedTransactionType[TransactionType.BuyIn],
        chipType,
        chipVariant: ChipVariant.Grey,
    },
    [TransactionType.BuyOut]: {
        label: localizedTransactionType[TransactionType.BuyOut],
        chipType,
        chipVariant: ChipVariant.Bronze,
    },
    [TransactionType.Bonus]: {
        label: localizedTransactionType[TransactionType.Bonus],
        chipType,
        chipVariant: ChipVariant.Grey,
    },
    [TransactionType.RevenueShare]: {
        label: localizedTransactionType[TransactionType.RevenueShare],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.MttCredit]: {
        label: localizedTransactionType[TransactionType.MttCredit],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.MttDebit]: {
        label: localizedTransactionType[TransactionType.MttDebit],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.PokerAddonCredit]: {
        label: localizedTransactionType[TransactionType.PokerAddonCredit],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.PokerAddonDebit]: {
        label: localizedTransactionType[TransactionType.PokerAddonDebit],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.Release]: {
        label: localizedTransactionType[TransactionType.Release],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.PokerFlipsPayout]: {
        label: localizedTransactionType[TransactionType.PokerFlipsPayout],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.PokerFlipsWager]: {
        label: localizedTransactionType[TransactionType.PokerFlipsWager],
        chipType,
        chipVariant: ChipVariant.Default,
    },

    [TransactionType.CasinoWager]: {
        label: localizedTransactionType[TransactionType.CasinoWager],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.CasinoPayout]: {
        label: localizedTransactionType[TransactionType.CasinoPayout],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.CasinoFreeSpinWager]: {
        label: localizedTransactionType[TransactionType.CasinoFreeSpinWager],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.CasinoFreeSpinPayout]: {
        label: localizedTransactionType[TransactionType.CasinoFreeSpinPayout],
        chipType,
        chipVariant: ChipVariant.Default,
    },

    [TransactionType.CasinoWagerRefund]: {
        label: localizedTransactionType[TransactionType.CasinoWagerRefund],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.CasinoPayoutRefund]: {
        label: localizedTransactionType[TransactionType.CasinoPayoutRefund],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.CasinoFreeSpinWagerRefund]: {
        label: localizedTransactionType[TransactionType.CasinoFreeSpinWagerRefund],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.CasinoFreeSpinPayoutRefund]: {
        label: localizedTransactionType[TransactionType.CasinoFreeSpinPayoutRefund],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.P2PTransferCredit]: {
        label: localizedTransactionType[TransactionType.P2PTransferCredit],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.P2PTransferDebit]: {
        label: localizedTransactionType[TransactionType.P2PTransferDebit],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.Chargeback]: {
        label: localizedTransactionType[TransactionType.Chargeback],
        chipType,
        chipVariant: ChipVariant.Red,
    },
    [TransactionType.Refund]: {
        label: localizedTransactionType[TransactionType.Refund],
        chipType,
        chipVariant: ChipVariant.Red,
    },
    [TransactionType.Reserve]: {
        label: localizedTransactionType[TransactionType.Reserve],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.Reverse]: {
        label: localizedTransactionType[TransactionType.Reverse],
        chipType,
        chipVariant: ChipVariant.Red,
    },
    [TransactionType.LiveEventBuyIn]: {
        label: localizedTransactionType[TransactionType.LiveEventBuyIn],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.SportsBetSettlement]: {
        label: localizedTransactionType[TransactionType.SportsBetSettlement],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.SportsBuyout]: {
        label: localizedTransactionType[TransactionType.SportsBuyout],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.SportsBuyin]: {
        label: localizedTransactionType[TransactionType.SportsBuyin],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.Fee]: {
        label: localizedTransactionType[TransactionType.Fee],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.FeeReverse]: {
        label: localizedTransactionType[TransactionType.FeeReverse],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.Payin]: {
        label: localizedTransactionType[TransactionType.Payin],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.Payout]: {
        label: localizedTransactionType[TransactionType.Payout],
        chipType,
        chipVariant: ChipVariant.Default,
    },
    [TransactionType.PayoutReturn]: {
        label: localizedTransactionType[TransactionType.PayoutReturn],
        chipType,
        chipVariant: ChipVariant.Default,
    },
});
