import React from 'react';
import './style.css';
import {Icon as MuiIcon, IconProps as MuiIconProps} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {CustomTheme, SystemColorsEnum, useSystemColors} from '@style';

import {CustomIconValues} from './types';

export enum IconColor {
    Success = 'Success',
    InProgress = 'InProgress',
    Primary = 'Primary',
    Secondary = 'Secondary',
    PrimaryContrastText = 'PrimaryContrastText',
    Warning = 'Warning',
    Bronze = 'Bronze',
    SuitRed = 'SuitRed',
    SuitBlack = 'SuitBlack',
}

export type IconProps = Omit<MuiIconProps, 'color'> & {
    icon: CustomIconValues;
    className?: string;
    color?: IconColor | SystemColorsEnum;
};

const useIconStyles = makeStyles()((theme: CustomTheme) => {
    return {
        icon: {
            fontSize: '1em',
        },
        success: {
            color: theme.custom.palette.content.secondarySuccess,
        },
        warning: {
            color: theme.palette.warning.main,
        },
        inProgress: {
            color: theme.custom.palette.content.secondaryInProgress,
        },
        primary: {
            color: theme.palette.primary.main,
        },
        secondary: {
            color: theme.palette.secondary.main,
        },
        bronze: {
            color: theme.custom.palette.status.bronze.main,
        },
        primaryContrastText: {
            color: theme.palette.primary.contrastText,
        },
        suitRed: {
            color: theme.custom.palette.content.suitRed,
        },
        suitBlack: {
            color: theme.custom.palette.content.suitBlack,
        },

        fontSizeSmall: {
            fontSize: `${theme.typography.h6.fontSize} !important`,
        },
    };
});

export function useIconColorClasses() {
    const systemColors = useSystemColors();
    const {classes} = useIconStyles();
    const iconColors: Record<IconColor | SystemColorsEnum, string> = {
        [IconColor.Success]: classes.success,
        [IconColor.InProgress]: classes.inProgress,
        [IconColor.Primary]: classes.primary,
        [IconColor.Secondary]: classes.secondary,
        [IconColor.PrimaryContrastText]: classes.primaryContrastText,
        [IconColor.Warning]: classes.warning,
        [IconColor.Bronze]: classes.bronze,
        [IconColor.SuitRed]: classes.suitRed,
        [IconColor.SuitBlack]: classes.suitBlack,
        ...systemColors,
    };
    return iconColors;
}

export const Icon = ({icon, className, color, ...otherProps}: IconProps) => {
    const {classes, cx} = useIconStyles();
    const colorClasses = useIconColorClasses();
    const colorClass: string = color ? colorClasses[color] : null;

    return (
        <MuiIcon
            classes={{fontSizeSmall: classes.fontSizeSmall}}
            className={cx(classes.icon, icon, colorClass, className)}
            {...otherProps}
        ></MuiIcon>
    );
};
