import produce from 'immer';

export class CanvasRenderingContext2DExtended {
    private readonly _ctx: CanvasRenderingContext2D;

    constructor(ctx: CanvasRenderingContext2D) {
        this._ctx = ctx;
    }

    fillTextWithEllipsis(text: string, maxTextWidth: number, x: number, y: number): void {
        let result: string = text;
        let width = this._ctx.measureText(text).width;
        const ellipsis = '…';
        const ellipsisWidth = this._ctx.measureText(ellipsis).width;

        if (width > maxTextWidth && width > ellipsisWidth) {
            let len = text.length;
            result = produce(text, draft => {
                while (width >= maxTextWidth - ellipsisWidth && len-- > 0) {
                    draft = draft.substring(0, len);
                    width = this._ctx.measureText(draft).width;
                }

                return `${draft}${ellipsis}`;
            });
        }

        this._ctx.fillText(result, x, y);
    }
}
