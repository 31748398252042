import {defineMessages} from 'react-intl';

import {Workspace} from '@models/generated/graphql';

export const localizedWorkspaces = defineMessages({
    [Workspace.Global]: {
        id: 'workspaceGlobal',
        defaultMessage: 'Blank',
    },
    [Workspace.Kyc]: {
        id: 'workspaceKYC',
        defaultMessage: 'KYC',
    },
    [Workspace.Payment]: {
        id: 'workspacePayment',
        defaultMessage: 'Payment',
    },
    [Workspace.Security]: {
        id: 'workspaceSecurity',
        defaultMessage: 'Security',
    },
    [Workspace.CustomerSupport]: {
        id: 'workspaceCustomerSupport',
        defaultMessage: 'Customer Support',
    },
    GlobalRead: {
        id: 'workspaceGlobalRead',
        defaultMessage: 'Global',
    },
});
