import {withUserProfileTextField} from '../../player-actions/pii-actions/components/UserProfileTextField';
import {addressInfoActions} from '../actions';

import {localized} from './UserProfileCity.localize';

export const UserProfileCity = withUserProfileTextField({
    action: addressInfoActions.editAddressCity,
    placeholder: localized.piiAddressCityFieldName,
    submitModelMapper: m => ({
        fieldValue: m?.user?.address?.city,
        userId: m?.userId,
    }),
    formModelMapper: m => ({
        user: {
            uid: m?.userId,
            address: {
                city: m?.fieldValue as string,
            },
        },
        userId: m?.userId,
    }),
    isRequired: true,
});
