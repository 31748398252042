import stringify from 'json-stringify-pretty-compact';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {ChipVariant} from '@components/chip/types';

import {ActionType} from '../types';

import {useAccountClosedFormatter} from './useAccountClosedFormatter';
import {useAccountVerificationStatusFormatter} from './useAccountVerificationStatusFormatter';
import {useAdminBlacklistedPlayerFormatter} from './useAdminBlacklistedPlayerFormatter';
import {useAmlLabelFormatter} from './useAmlLabelFormatter';
import {useBlacklistedPlayerFormatter} from './useBlacklistedPlayerFormatter';
import {useBlockedUserFormatter} from './useBlockedUserFormatter';
import {useBotGroupChangedFormatter} from './useBotGroupChangedFormatter';
import {useDepositStatusFormatter} from './useDepositStatusFormatter';
import {useEmptyFormatter} from './useEmptyFormatter';
import {useFriendsGroupUsersFormatter} from './useFriendsGroupUsersFormatter';
import {useLobbyAccessStatusFormatter} from './useLobbyAccessStatusFormatter';
import {useModifiedEmailFormatter} from './useModifiedEmailFormatter';
import {useModifiedMobileFormatter} from './useModifiedMobileFormatter';
import {useP2PFeatureStatusFormatter} from './useP2PFeatureStatusFormatter';
import {useSecurityCasesFormatter} from './useSecurityCasesFormatter';
import {useTransactionStatusFormatter} from './useTransactionStatusFormatter';
import {useUserAccountStatusFormatter} from './useUserAccountStatusFormatter';
import {useUserLabelFormatter} from './useUserLabelFormatter';
import {useUserTwoFAFormatter} from './useUserTwoFAFormatter';
import {useWithdrawalStatusFormatter} from './useWithdrawalStatusFormatter';

type ActionTypeMapping = {
    formatter: (json: string) => ChipKeyValue[];
    chipVariant: ChipVariant;
};

const actionTypeMap = new Map<string, ActionTypeMapping>([
    [ActionType.AdminChangedAmlLabel, {chipVariant: ChipVariant.LightBlue, formatter: useAmlLabelFormatter}],
    [ActionType.AdminResentUsersRestoreCode, {chipVariant: ChipVariant.Blue, formatter: useEmptyFormatter}],
    [ActionType.AdminDisabledUsersTwoFA, {chipVariant: ChipVariant.Bronze, formatter: useUserTwoFAFormatter}],
    [ActionType.AdminModifiedUsersMobile, {chipVariant: ChipVariant.Green, formatter: useModifiedMobileFormatter}],
    [ActionType.AdminChangedBotGroup, {chipVariant: ChipVariant.LightGreen, formatter: useBotGroupChangedFormatter}],
    [ActionType.AdminSecurityCaseOpened, {chipVariant: ChipVariant.Grey, formatter: useSecurityCasesFormatter}],
    [ActionType.AdminSecurityCaseClosed, {chipVariant: ChipVariant.Yellow, formatter: useSecurityCasesFormatter}],
    [ActionType.AdminLockedUsersLogin, {chipVariant: ChipVariant.Turquoise, formatter: useUserAccountStatusFormatter}],
    [ActionType.AdminUnlockedUsersLogin, {chipVariant: ChipVariant.Turquoise, formatter: useUserAccountStatusFormatter}],
    [ActionType.AdminLockedUsersWithdrawal, {chipVariant: ChipVariant.Violet, formatter: useWithdrawalStatusFormatter}],
    [ActionType.AdminUnlockedUsersWithdrawal, {chipVariant: ChipVariant.Violet, formatter: useWithdrawalStatusFormatter}],
    [ActionType.AdminLockedUsersLobbyAccess, {chipVariant: ChipVariant.LightBlue, formatter: useLobbyAccessStatusFormatter}],
    [ActionType.AdminUnlockedUsersLobbyAccess, {chipVariant: ChipVariant.LightBlue, formatter: useLobbyAccessStatusFormatter}],
    [ActionType.AdminBlacklistedPlayer, {chipVariant: ChipVariant.Bronze, formatter: useAdminBlacklistedPlayerFormatter}],
    [ActionType.BlacklistedPlayer, {chipVariant: ChipVariant.Green, formatter: useBlacklistedPlayerFormatter}],
    [ActionType.KickedBlacklistedPlayer, {chipVariant: ChipVariant.Green, formatter: useEmptyFormatter}],
    [ActionType.AdminBlockedUser, {chipVariant: ChipVariant.Orange, formatter: useBlockedUserFormatter}],
    [ActionType.AdminModifiedUsersKYCStatus, {chipVariant: ChipVariant.Bronze, formatter: useAccountVerificationStatusFormatter}],
    [ActionType.AdminModifiedUsersEmail, {chipVariant: ChipVariant.LightBlue, formatter: useModifiedEmailFormatter}],
    [ActionType.AdminLockedUsersDeposit, {chipVariant: ChipVariant.Bronze, formatter: useDepositStatusFormatter}],
    [ActionType.AdminUnlockedUsersDeposit, {chipVariant: ChipVariant.Green, formatter: useDepositStatusFormatter}],
    [ActionType.AdminPlayerAccountClosed, {chipVariant: ChipVariant.LightGreen, formatter: useAccountClosedFormatter}],
    [ActionType.AdminUpdatedFriendsGroupUsers, {chipVariant: ChipVariant.Grey, formatter: useFriendsGroupUsersFormatter}],
    [ActionType.AdminKickedUser, {chipVariant: ChipVariant.Yellow, formatter: useEmptyFormatter}],
    [ActionType.InitiatedPaymentKYC, {chipVariant: ChipVariant.Bronze, formatter: useAccountVerificationStatusFormatter}],
    [ActionType.InitiatedSecurityKYC, {chipVariant: ChipVariant.Orange, formatter: useAccountVerificationStatusFormatter}],
    [ActionType.UpdatedKYCCaseStatus, {chipVariant: ChipVariant.Yellow, formatter: useAccountVerificationStatusFormatter}],
    [ActionType.UpdatedTransactionStatus, {chipVariant: ChipVariant.LightGreen, formatter: useTransactionStatusFormatter}],
    [ActionType.UpdatedP2PFeatureStatus, {chipVariant: ChipVariant.LightBlue, formatter: useP2PFeatureStatusFormatter}],
    [ActionType.AdminSetUserP2PFeature, {chipVariant: ChipVariant.Turquoise, formatter: useP2PFeatureStatusFormatter}],
    [ActionType.UserLabel, {chipVariant: ChipVariant.Turquoise, formatter: useUserLabelFormatter}],
]);

export const useActionHistoryFormatter = (actionType: string, json: string): ChipKeyValue[] => {
    const formattedValue: ChipKeyValue[] =
        actionType && actionTypeMap.has(actionType) ? actionTypeMap.get(actionType).formatter(json) : [{key: getJsonValue(json)}];

    return formattedValue;
};

export const getActionHistoryChipVariant = (actionType: string): ChipVariant => {
    return actionType && actionTypeMap.has(actionType) ? actionTypeMap.get(actionType).chipVariant : ChipVariant.Default;
};

const getJsonValue = (json: string): string => (json ? stringify(JSON.parse(json), {maxLength: 0}) : json);
