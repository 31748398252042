import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {ActiveStatus, Rule} from '@models/rule/types';
import {map, mergeMap} from '@otel';
import {BaseEpicsBuilder, RootEpic} from '@redux';
import {entityActions, EntityType, RuleNormalized} from '@redux/entity';
import {realtimeActions, RealtimeMessageTrigger} from '@redux/realtime';
import {BaseRuleRequestPayload, ChangeRuleOrderRequestPayload, UpdateRuleRequestPayload} from '@services/rest-api/ruleApiService';
import {RuleService} from '@services/ruleService';

import {showErrorAction, showMessageAction} from '../message-snack-bar/actions';

import {ruleActions} from './actions';

const localized = defineMessages({
    createRuleSuccessMessage: {
        id: 'RuleActionsEpicsBuilder_createRuleSuccessMessage',
        defaultMessage: 'Rule created',
    },
    createRuleFailureMessage: {
        id: 'RuleActionsEpicsBuilder_createRuleFailureMessage',
        defaultMessage: 'Rule creation failed',
    },
    editRuleSuccessMessage: {
        id: 'RuleActionsEpicsBuilder_editRuleSuccessMessage',
        defaultMessage: 'Rule updated',
    },
    editRuleFailureMessage: {
        id: 'RuleActionsEpicsBuilder_editRuleFailureMessage',
        defaultMessage: 'Rule edition failed',
    },
    deleteRuleSuccessMessage: {
        id: 'RuleActionsEpicsBuilder_deleteRuleSuccessMessage',
        defaultMessage: 'Rule removed',
    },
    deleteRuleFailureMessage: {
        id: 'RuleActionsEpicsBuilder_deleteRuleFailureMessage',
        defaultMessage: 'Rule deletion failed',
    },
    activateRuleSuccessMessage: {
        id: 'RuleActionsEpicsBuilder_activateRuleSuccessMessage',
        defaultMessage: 'Rule active',
    },
    activateRuleFailureMessage: {
        id: 'RuleActionsEpicsBuilder_activateRuleFailureMessage',
        defaultMessage: 'Rule activation failed',
    },
    deactivateRuleSuccessMessage: {
        id: 'RuleActionsEpicsBuilder_deactivateRuleSuccessMessage',
        defaultMessage: 'Rule inactive',
    },
    deactivateRuleFailureMessage: {
        id: 'RuleActionsEpicsBuilder_deactivateRuleFailureMessage',
        defaultMessage: 'Rule deactivation failed',
    },
    changeRuleOrderSuccessMessage: {
        id: 'RuleActionsEpicsBuilder_changeRuleOrderSuccessMessage',
        defaultMessage: 'Rule order changed',
    },
    changeRuleOrderFailureMessage: {
        id: 'RuleActionsEpicsBuilder_changeRuleOrderFailureMessage',
        defaultMessage: 'Rule order change failed',
    },
});

@injectable()
export class RuleActionsEpicsBuilder extends BaseEpicsBuilder {
    private readonly _service: RuleService;

    constructor(@inject(ServiceTypes.RuleService) service: RuleService) {
        super();
        this._service = service;
    }

    protected buildEpicList(): RootEpic[] {
        return [
            this.buildCreateRuleRequestEpic(),
            this.buildCreateRuleSuccessEpic(),
            this.buildCreateRuleFailureEpic(),
            this.buildEditRuleRequestEpic(),
            this.buildEditRuleSuccessEpic(),
            this.buildEditRuleFailureEpic(),
            this.buildDeleteRuleRequestEpic(),
            this.buildDeleteRuleSuccessEpic(),
            this.buildDeleteRuleFailureEpic(),
            this.buildActivateRuleRequestEpic(),
            this.buildActivateRuleSuccessEpic(),
            this.buildActivateRuleFailureEpic(),
            this.buildDeactivateRuleRequestEpic(),
            this.buildDeactivateRuleSuccessEpic(),
            this.buildDeactivateRuleFailureEpic(),
            this.buildChangeRuleOrderRequestEpic(),
            this.buildChangeRuleOrderSuccessEpic(),
            this.buildChangeRuleOrderFailureEpic(),
        ];
    }

    private buildCreateRuleRequestEpic(): RootEpic {
        return this.buildRequestEpic<UpdateRuleRequestPayload, UpdateRuleRequestPayload, Rule>(ruleActions.createRule, payload =>
            this._service.createRule(payload)
        );
    }

    private buildCreateRuleSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.createRule.success)),
                mergeMap(() =>
                    of(
                        // event to update client state
                        realtimeActions.event({entity: EntityType.Rule, items: [], trigger: {type: RealtimeMessageTrigger.Add}}),
                        showMessageAction({message: localized.createRuleSuccessMessage})
                    )
                )
            );
    }

    private buildCreateRuleFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.createRule.failure)),
                map(() => showErrorAction({message: localized.createRuleFailureMessage}))
            );
    }

    private buildEditRuleRequestEpic(): RootEpic {
        return this.buildRequestEpic<UpdateRuleRequestPayload, UpdateRuleRequestPayload, Rule>(ruleActions.editRule, payload =>
            this._service.editRule(payload)
        );
    }

    private buildEditRuleSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.editRule.success)),
                mergeMap(() =>
                    of(
                        // event to update client state
                        realtimeActions.event({entity: EntityType.Rule, items: [], trigger: {type: RealtimeMessageTrigger.Update}}),
                        showMessageAction({message: localized.editRuleSuccessMessage})
                    )
                )
            );
    }

    private buildEditRuleFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.editRule.failure)),
                map(() => showErrorAction({message: localized.editRuleFailureMessage}))
            );
    }

    private buildDeleteRuleRequestEpic(): RootEpic {
        return this.buildRequestEpic<BaseRuleRequestPayload, BaseRuleRequestPayload, null>(ruleActions.deleteRule, payload =>
            this._service.deleteRule(payload)
        );
    }

    private buildDeleteRuleSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.deleteRule.success)),
                mergeMap(() =>
                    of(
                        // event to update client state
                        realtimeActions.event({entity: EntityType.Rule, items: [], trigger: {type: RealtimeMessageTrigger.Delete}}),
                        showMessageAction({message: localized.deleteRuleSuccessMessage})
                    )
                )
            );
    }

    private buildDeleteRuleFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.deleteRule.failure)),
                map(() => showErrorAction({message: localized.deleteRuleFailureMessage}))
            );
    }

    private buildActivateRuleRequestEpic(): RootEpic {
        return this.buildRequestEpic<BaseRuleRequestPayload, BaseRuleRequestPayload, null>(ruleActions.activateRule, payload =>
            this._service.activateRule(payload)
        );
    }

    private buildActivateRuleSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.activateRule.success)),
                mergeMap(action =>
                    of(
                        entityActions.updateItem({
                            type: EntityType.Rule,
                            id: action?.payload?.requestPayload?.ruleId,
                            updatedItem: {status: ActiveStatus.Active} as RuleNormalized,
                        }),
                        showMessageAction({message: localized.activateRuleSuccessMessage})
                    )
                )
            );
    }

    private buildActivateRuleFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.activateRule.failure)),
                map(() => showErrorAction({message: localized.activateRuleFailureMessage}))
            );
    }

    private buildDeactivateRuleRequestEpic(): RootEpic {
        return this.buildRequestEpic<BaseRuleRequestPayload, BaseRuleRequestPayload, null>(ruleActions.deactivateRule, payload =>
            this._service.deactivateRule(payload)
        );
    }

    private buildDeactivateRuleSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.deactivateRule.success)),
                mergeMap(action =>
                    of(
                        entityActions.updateItem({
                            type: EntityType.Rule,
                            id: action?.payload?.requestPayload?.ruleId,
                            updatedItem: {status: ActiveStatus.Inactive} as RuleNormalized,
                        }),
                        showMessageAction({message: localized.deactivateRuleSuccessMessage})
                    )
                )
            );
    }

    private buildDeactivateRuleFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.deactivateRule.failure)),
                map(() => showErrorAction({message: localized.deactivateRuleFailureMessage}))
            );
    }

    private buildChangeRuleOrderRequestEpic(): RootEpic {
        return this.buildRequestEpic<ChangeRuleOrderRequestPayload, ChangeRuleOrderRequestPayload, null>(
            ruleActions.changeRuleOrder,
            payload => this._service.changeRuleOrder(payload)
        );
    }

    private buildChangeRuleOrderSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.changeRuleOrder.success)),
                mergeMap(() =>
                    of(
                        // event to update client state
                        realtimeActions.event({entity: EntityType.Rule, items: [], trigger: {type: RealtimeMessageTrigger.Update}}),
                        showMessageAction({message: localized.changeRuleOrderSuccessMessage})
                    )
                )
            );
    }

    private buildChangeRuleOrderFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(ruleActions.changeRuleOrder.failure)),
                map(() => showErrorAction({message: localized.changeRuleOrderFailureMessage}))
            );
    }
}
