import React from 'react';

import {AttachmentsItem} from './AttachmentsItem';
import {Attachment} from './types';

type AttachmentsProps = {
    attachments: Attachment[];
};

export const Attachments = ({attachments}: AttachmentsProps) => {
    return (
        <>
            {attachments?.map(item => {
                return <AttachmentsItem key={item.id} {...item} />;
            })}
        </>
    );
};
