import {defineMessages} from 'react-intl';
import {inject, injectable} from 'inversify';
import {of} from 'rxjs';
import {filter} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';

import {ServiceTypes} from '@inversify';
import {map, mergeMap} from '@otel';
import {RootEpic} from '@redux';
import {EntityType} from '@redux/entity';
import {realtimeActions, RealtimeMessageTrigger} from '@redux/realtime';
import {GameRoomService} from '@services/gameRoomService';
import {CloseGameRoomRequestPayload, KickoffPlayerRequestPayload} from '@services/rest-api/plo5GameManagementApiService';

import {GameActionsEpicsBuilder} from '../block-game-management/epics';
import {showMessageAction} from '../message-snack-bar/actions';

import {gameRoomActions} from './actions';

const localized = defineMessages({
    closeRoomSuccessMessage: {
        id: 'GameRoomActionsEpicsBuilder_closeRoomSuccessMessage',
        defaultMessage: 'Table will be closed',
    },
    closeRoomFailureMessage: {
        id: 'GameRoomActionsEpicsBuilder_closeRoomFailureMessage',
        defaultMessage: 'Failed attempt to close the table. Try again later',
    },
    closeRoomFailureMessageWithErrorInfo: {
        id: 'GameRoomActionsEpicsBuilder_closeRoomFailureMessageWithErrorInfo',
        defaultMessage: 'Failed attempt to close the table: {message}{br}Trace id: {traceId}',
    },
    kickoffPlayerSuccessMessage: {
        id: 'GameRoomActionsEpicsBuilder_kickoffPlayerSuccessMessage',
        defaultMessage: 'Player was removed from table',
    },
    kickoffPlayerFailureMessage: {
        id: 'GameRoomActionsEpicsBuilder_kickoffPlayerFailureMessage',
        defaultMessage: 'Failed attempt to remove the player. Try again later',
    },
    kickoffPlayerFailureMessageWithErrorInfo: {
        id: 'GameRoomActionsEpicsBuilder_kickoffPlayerFailureMessageWithErrorInfo',
        defaultMessage: 'Failed attempt to remove the player: {message}{br}Trace id: {traceId}',
    },
});

@injectable()
export class GameRoomActionsEpicsBuilder extends GameActionsEpicsBuilder {
    private readonly _service: GameRoomService;

    constructor(@inject(ServiceTypes.GameRoomService) service: GameRoomService) {
        super();
        this._service = service;
    }

    protected buildEpicList(): RootEpic[] {
        return [
            this.buildCloseGameRoomRequestEpic(),
            this.buildCloseGameRoomSuccessEpic(),
            this.buildCloseGameRoomFailureEpic(),
            this.buildKickoffPlayerRequestEpic(),
            this.buildKickoffPlayerSuccessEpic(),
            this.buildKickoffPlayerFailureEpic(),
        ];
    }

    private buildCloseGameRoomRequestEpic(): RootEpic {
        return this.buildRequestEpic<CloseGameRoomRequestPayload, CloseGameRoomRequestPayload, null>(gameRoomActions.closeRoom, payload =>
            this._service.closeGameRoom(payload)
        );
    }

    private buildCloseGameRoomSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameRoomActions.closeRoom.success)),
                mergeMap(() =>
                    of(
                        realtimeActions.event({entity: EntityType.GameRoom, items: [], trigger: {type: RealtimeMessageTrigger.Delete}}),
                        showMessageAction({message: localized.closeRoomSuccessMessage})
                    )
                )
            );
    }

    private buildCloseGameRoomFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameRoomActions.closeRoom.failure)),
                map(action =>
                    this.showOperationFailureMessage(
                        action?.payload,
                        localized.closeRoomFailureMessage,
                        localized.closeRoomFailureMessageWithErrorInfo
                    )
                )
            );
    }

    private buildKickoffPlayerRequestEpic(): RootEpic {
        return this.buildRequestEpic<KickoffPlayerRequestPayload, KickoffPlayerRequestPayload, null>(
            gameRoomActions.kickoffPlayer,
            payload => this._service.kickoffPlayer(payload)
        );
    }

    private buildKickoffPlayerSuccessEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameRoomActions.kickoffPlayer.success)),
                mergeMap(() =>
                    of(
                        realtimeActions.event({entity: EntityType.GameRoom, items: [], trigger: {type: RealtimeMessageTrigger.Update}}),
                        showMessageAction({message: localized.kickoffPlayerSuccessMessage})
                    )
                )
            );
    }

    private buildKickoffPlayerFailureEpic(): RootEpic {
        return action$ =>
            action$.pipe(
                filter(isActionOf(gameRoomActions.kickoffPlayer.failure)),
                map(action =>
                    this.showOperationFailureMessage(
                        action?.payload,
                        localized.kickoffPlayerFailureMessage,
                        localized.kickoffPlayerFailureMessageWithErrorInfo
                    )
                )
            );
    }
}
