import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {
    Attachment as GqlAttachment,
    EntityType,
    Mutation,
    MutationAddNoteArgs,
    MutationUpdateNoteArgs,
    Note,
    NoteInput,
    NotesFilterType,
    QueryGetNotesArgs,
} from '@models/generated/graphql';
import {Filter, NoteFilterKeys, NoteQueryFields, NoteTextFilterKeys} from '@redux/entity';
import {EntityBaseGqlService, IEntityReadService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {Attachment} from '@services/rest-api/attachmentsApiService';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';
import {GqlMutationRequest, ServiceResponsePayload} from './types';

export interface INoteService extends IEntityReadService {
    updateNote(id: string, note: NoteInput): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateNoteArgs>, Note>>;
    addNote(item: NoteInput, attachments?: Attachment[]): Observable<ServiceResponsePayload<GqlMutationRequest<MutationAddNoteArgs>, Note>>;
}

@injectable()
export class NoteService extends EntityBaseGqlService<QueryGetNotesArgs, NoteQueryFields, NoteFilterKeys> implements INoteService {
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new NoteRequestBuilder());
    }

    public updateNote(id: string, note: NoteInput): Observable<ServiceResponsePayload<GqlMutationRequest<MutationUpdateNoteArgs>, Note>> {
        return this._service
            .mutate<Mutation, MutationUpdateNoteArgs>(this.getUpdateNoteMutation(), {id, note})
            .pipe(map(res => ({...res, responsePayload: res?.responsePayload?.updateNote})));
    }

    public addNote(
        note: NoteInput,
        attachments?: Attachment[]
    ): Observable<ServiceResponsePayload<GqlMutationRequest<MutationAddNoteArgs>, Note>> {
        const attachmentInputs = attachments?.map(a => ({
            id: a.id,
            filename: a.name,
            size: a.size,
            extension: a.name?.split('.').pop(),
            type: a.type,
        }));

        return this._service
            .mutate<Mutation, MutationAddNoteArgs>(this.getAddNoteMutation(), {note: {...note, attachments: attachmentInputs}})
            .pipe(map(res => ({...res, responsePayload: res?.responsePayload?.addNote})));
    }

    private getUpdateNoteMutation(): DocumentNode {
        return gql`
            mutation UpdateNote($id: String!, $note: NoteInput!) {
                updateNote(id: $id, note: $note) {
                    id
                    entity {
                        id
                        type
                        parent {
                            id
                            type
                        }
                    }
                    note_type
                    workspace
                    body
                    posted_at {
                        seconds
                    }
                    posted_by_uid
                    users_tagged
                    attachments {
                        id
                        url
                        extension
                        filename
                        size
                        type
                    }
                    is_pinned
                }
            }
        `;
    }

    private getAddNoteMutation(): DocumentNode {
        return gql`
            mutation AddNote($note: NoteInput!) {
                addNote(note: $note) {
                    id
                    entity {
                        id
                        type
                        parent {
                            id
                            type
                        }
                    }
                    note_type
                    workspace
                    body
                    posted_at {
                        seconds
                    }
                    posted_by_uid
                    users_tagged
                    attachments {
                        id
                        url
                        extension
                        filename
                        size
                    }
                    is_pinned
                }
            }
        `;
    }
}

export class NoteRequestBuilder extends GqlRequestBuilder<QueryGetNotesArgs, NoteQueryFields, NoteFilterKeys> {
    public buildQuery = (fields: NoteQueryFields[]): DocumentNode => gql`
        query GetNotes($filter: NotesFilterInput, $sort: Sorting, $start: Int, $end: Int) {
            getNotes(filter: $filter, sort: $sort, end: $end, start: $start) {
                items {
                    id
                    entity @include(if: ${this.hasAnyField(fields, this.getEntityQueryItems())}) {
                        id @include(if: ${this.hasField(fields, 'entity.id')})
                        type @include(if: ${this.hasField(fields, 'entity.type')})
                        parent {
                            id @include(if: ${this.hasField(fields, 'entity.parent.id')})
                            type @include(if: ${this.hasField(fields, 'entity.parent.type')})
                        }
                    }
                    note_type @include(if: ${this.hasField(fields, 'note_type')})
                    workspace @include(if: ${this.hasField(fields, 'workspace')})
                    body @include(if: ${this.hasField(fields, 'body')})
                    posted_at @include(if: ${this.hasAnyField(fields, this.getPostedAtQueryItems())}) {
                        seconds @include(if: ${this.hasField(fields, 'posted_at.seconds')})
                    }
                    posted_by_uid @include(if: ${this.hasField(fields, 'posted_by_uid')})
                    is_pinned @include(if: ${this.hasField(fields, 'is_pinned')})
                    users_tagged @include(if: ${this.hasField(fields, 'users_tagged')})
                    attachments @include(if: ${this.hasAnyField(fields, this.getAttachmentsQueryItems())}) {
                        id @include(if: ${this.hasField(fields, 'attachments.id')})
                        url @include(if: ${this.hasField(fields, 'attachments.url')})
                        extension @include(if: ${this.hasField(fields, 'attachments.extension')})
                        filename @include(if: ${this.hasField(fields, 'attachments.filename')})
                        size @include(if: ${this.hasField(fields, 'attachments.size')})
                        type @include(if: ${this.hasField(fields, 'attachments.type')})
                    }
                }
                total_count
            }
        }
    `;

    protected buildFilter(filter: Filter<NoteFilterKeys>): Pick<QueryGetNotesArgs, 'filter'> {
        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: NoteTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string)
                    )
                ),
                entity: {
                    id: this.toGQLStringFilter(filter, 'entity.id'),
                    type: this.toGQLStringFilter(filter, 'entity.type') as EntityType,
                    parent: {
                        id: this.toGQLStringFilter(filter, 'entity.parent.id'),
                        type: this.toGQLStringFilter(filter, 'entity.parent.type') as EntityType,
                    },
                },
                type: this.toGQLStringFilter(filter, 'type') as NotesFilterType,
                workspace: this.toGQLMultiselectFilter(filter, 'workspace'),
                is_pinned: filter.is_pinned !== undefined ? this.toGQLBooleanFilter(filter, 'is_pinned') : undefined,
            },
        };
    }

    private getEntityQueryItems(): NoteQueryFields[] {
        return ['entity.id', 'entity.type', 'entity.parent.id', 'entity.parent.type'];
    }

    private getAttachmentsQueryItems(): NoteQueryFields[] {
        return [
            'attachments.id',
            'attachments.url',
            'attachments.extension',
            'attachments.filename',
            'attachments.size',
            'attachments.type',
        ];
    }

    private getPostedAtQueryItems(): NoteQueryFields[] {
        return ['posted_at.seconds'];
    }

    private readonly filterFieldsMapper: Record<NoteTextFilterKeys, string[]> = {
        'attachments.filename': [`${nameof<Note>(m => m.attachments)}.${nameof<GqlAttachment>(m => m.filename)}`],
    };
}
