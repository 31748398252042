import {createAsyncAction} from 'typesafe-actions';

import {AccountVerification, InitKycInput, MutationInitPaymentKycArgs} from '@models/generated/graphql';
import {UpdateKycArgs, UpdateKycStatusModel} from '@services/accountVerificationService';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

const domain = 'block-account-verification-actions';

export type UpdateKycStatusRequestPayload = UpdateKycStatusModel;

export const accountVerificationActions = {
    initiatePaymentKyc: createAsyncAction(
        `${domain}/initiatePaymentKycRequest`,
        `${domain}/initiatePaymentKycSucceed`,
        `${domain}/initiatePaymentKycFailure`
    )<
        InitKycInput,
        ServiceResponsePayload<GqlMutationRequest<MutationInitPaymentKycArgs>, AccountVerification>,
        ServiceResponsePayload<GqlMutationRequest<MutationInitPaymentKycArgs>, AccountVerification>
    >(),

    updateKycStatus: createAsyncAction(
        `${domain}/updateKycStatusRequest`,
        `${domain}/updateKycStatusSucceed`,
        `${domain}/updateKycStatusFailure`
    )<
        UpdateKycStatusRequestPayload,
        ServiceResponsePayload<GqlMutationRequest<UpdateKycArgs>, AccountVerification>,
        ServiceResponsePayload<GqlMutationRequest<UpdateKycArgs>, AccountVerification>
    >(),
};
