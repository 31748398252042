import {Observable, of} from 'rxjs';

import {ITracingService} from '@otel';
import {UserManagerExtended} from '@auth';
import {AjaxResponse} from '@services/rest-api';

import {globalConfig} from '../../../configuration';
import {IModuleItem} from '../../../features/module-shared/types';
import {SearchFilter} from '../../types';

import {RestHttpService} from './restHttpService';

/**
 * @deprecated should use services from src/common/services
 */
export class PlayerManagementApiService extends RestHttpService {
    constructor(tracingService: ITracingService, userManager: UserManagerExtended, endpoint?: string) {
        super(`${globalConfig.getConfig().playerManagementApiUrl}/api/v1` + (endpoint ? `/${endpoint}` : ''), tracingService, userManager);
    }

    addItem<TModel extends IModuleItem>(_item: TModel): Observable<string> {
        return of();
    }

    editItem<TModel extends IModuleItem>(_item: TModel): Observable<string> {
        return of();
    }

    deleteItem(_id: string): Observable<AjaxResponse> {
        return of();
    }

    protected toSearchQuery(filter: SearchFilter): string {
        let result = '';

        const filters = filter && filter.filter ? filter.filter.map(f => `${f.key}=${f.value}`).join('&') : '';

        const sort =
            filter.sorting && filter.sorting.length !== 0
                ? `sort=${filter.sorting.map(s => s.field).join()}&order=${filter.sorting.map(s => s.sort).join()}`
                : '';

        let page = '';
        let size = '';
        if (filter.paging) {
            const pageNumber = filter.paging?.page - 1;
            const sizeNumber = filter.paging?.pageSize;

            page = pageNumber ? `page=${pageNumber}` : '';
            size = sizeNumber ? `size=${sizeNumber}` : '';
        }

        result = [filters, sort, page, size].filter(i => i).join('&');

        return result;
    }

    protected getItemsTotalCount(response: AjaxResponse): number {
        return response.response?.count;
    }
}
