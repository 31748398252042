import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {FriendGroup} from '@models/generated/graphql';

export type FriendsGroupsValue = {
    friend_groups: FriendGroup[];
};

export const useFriendsGroupUsersFormatter = (json: string): ChipKeyValue[] => {
    const value: FriendsGroupsValue = JSON.parse(json);

    return (
        value?.friend_groups
            ?.filter(g => g?.group_name)
            .map(g => ({
                key: g?.group_name,
            })) ?? []
    );
};
