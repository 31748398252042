import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    editableSelect: {
        width: '100%',
        display: 'flex',
        marginLeft: 'auto',
        position: 'relative',
        overflow: 'visible !important',
        padding: '0 !important',
    },
    editableSelectButton: {
        width: '100%',
        height: '32px',
        padding: theme.spacing(1, 1.5),
        justifyContent: 'space-between',
    },
    editableSelectButtonOpen: {
        border: `1px solid ${theme.custom.palette.content.border}`,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
}));
