import React from 'react';
import {defineMessages} from 'react-intl';
import {GridEditCellValueParams, GridRenderCellParams} from '@mui/x-data-grid';

import LocalizedText from '@components/i18n/LocalizedText';
import {Select, SelectStyle} from '@components/select';
import {TransactionType} from '@models/generated/graphql';

import {SelectOption} from '../../module-shared/types';

const localized = defineMessages({
    p2pCredit: {
        id: 'P2PTransferDirection_p2pCredit',
        defaultMessage: 'Credit',
    },
    p2pDebit: {
        id: 'P2PTransferDirection_p2pDebit',
        defaultMessage: 'Debit',
    },
});

export function renderP2PTransferDirectionEditableCell(params: GridRenderCellParams) {
    const types: TransactionType[] = [TransactionType.P2PTransferCredit, TransactionType.P2PTransferDebit];
    const selectOptions: SelectOption[] = types.map(t => ({
        label: t === TransactionType.P2PTransferDebit ? localized.p2pDebit : localized.p2pCredit,
        value: t,
    }));

    function handleChange(value: TransactionType[]) {
        const editValue: GridEditCellValueParams = {id: params.id, value: value?.[0], field: params.field};
        if (params.cellMode === 'view') {
            params.api.startCellEditMode({id: params.id, field: params.field});
        }
        params.api.setEditCellValue(editValue);
    }

    return (
        <Select
            options={selectOptions}
            value={params.value ?? TransactionType.Credit}
            onSubmit={handleChange}
            selectStyle={SelectStyle.Default}
        />
    );
}

export function renderP2PTransferDirection(params: GridRenderCellParams) {
    const label = params.value === TransactionType.P2PTransferDebit ? localized.p2pDebit : localized.p2pCredit;

    return <LocalizedText label={label} />;
}
