import React, {useEffect} from 'react';
import {Box, Popper, Typography} from '@mui/material';
import {Variant} from '@mui/material/styles/createTypography';

import {useMessageClasses} from './Message.style';

export enum MessageOrientation {
    Inline = 'Inline',
    Floating = 'Floating',
}

export enum MessageType {
    Info = 'Info',
    Success = 'Success',
    Error = 'Error',
    Warning = 'Warning',
}

type MessageInnerProps = {
    message?: React.ReactNode;
    messageOrientation?: MessageOrientation;
    messageType?: MessageType;
    className?: string;
    variant?: Variant | 'inherit';
    dataTestId?: string;
};

function MessageInner({
    message,
    className,
    variant,
    messageOrientation = MessageOrientation.Inline,
    messageType = MessageType.Info,
    dataTestId,
}: MessageInnerProps) {
    const {classes, cx} = useMessageClasses();

    return message === null || message === undefined ? null : (
        <Box
            className={cx(
                classes.message,
                {
                    [classes.floatingMessage]: messageOrientation === MessageOrientation.Floating,
                    [classes.floatingErrorMessage]: messageOrientation === MessageOrientation.Floating && messageType === MessageType.Error,
                    [classes.floatingSuccessMessage]:
                        messageOrientation === MessageOrientation.Floating && messageType === MessageType.Success,
                    [classes.floatingWarningMessage]:
                        messageOrientation === MessageOrientation.Floating && messageType === MessageType.Warning,
                    [classes.floatingInfoMessage]: messageOrientation === MessageOrientation.Floating && messageType === MessageType.Info,
                    [classes.errorMessage]: messageType === MessageType.Error,
                    [classes.successMessage]: messageType === MessageType.Success,
                    [classes.warningMessage]: messageType === MessageType.Warning,
                    [classes.infoMessage]: messageType === MessageType.Info,
                },
                className
            )}
        >
            <Typography data-testid={dataTestId ?? 'message'} variant={variant ?? 'body1'}>
                {message}
            </Typography>
        </Box>
    );
}

export type MessageProps = MessageInnerProps & {
    hideMessage?: boolean;
    containerRef?: React.MutableRefObject<HTMLElement>;
};

export function Message({hideMessage, messageOrientation = MessageOrientation.Inline, containerRef, ...props}: MessageProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const visible = !hideMessage && Boolean(props.message);

    const showMessagePopover = () => {
        setAnchorEl(containerRef?.current);
    };

    const hideMessagePopover = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (visible) {
            showMessagePopover();
        } else {
            hideMessagePopover();
        }
    }, [visible]);

    const messageInnerProps = {...props, messageOrientation};

    return messageOrientation === MessageOrientation.Floating ? (
        <Popper open={visible} anchorEl={anchorEl} placement="bottom-start">
            <MessageInner {...messageInnerProps} />
        </Popper>
    ) : (
        <MessageInner {...messageInnerProps} />
    );
}
