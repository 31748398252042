import React, {useContext, useEffect} from 'react';
import {Controller, RegisterOptions} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {FormControl} from '@mui/material';

import Button from '@components/button/Buttons';
import {FormGroup, FormLabel} from '@components/form';
import {FormMultilineText, FormSelectWithLabel, RuleType, StyledInput, StyledToggle, useValidationFormatter} from '@components/input';
import {BulkModalTitle, ModalContext, ModalFooter} from '@components/modal';
import {ReasonCode, TransactionType} from '@models/generated/graphql';
import {useAuthUser} from '@auth';

import {useCurrency, useCurrencySymbol} from '../../app/intl/hooks';
import {localizedReasonCode} from '../../app/intl/shared-resources/transactionReasonCode';
import {SelectOption} from '../../module-shared/types';
import {useReduxForm} from '../../shared/form/hooks';
import {transactionActions} from '../actions';
import {creditReasonCodes, debitReasonCodes, ManualTransactionAddViewModel} from '../types';

const localized = defineMessages({
    modalTitle: {
        id: 'ManualTransactionAddModal_modalTitle',
        defaultMessage: 'Manual Debit/Credit',
    },
    type: {
        id: 'ManualTransactionAddModal_type',
        defaultMessage: 'Type',
    },
    amount: {
        id: 'ManualTransactionAddModal_amount',
        defaultMessage: 'Amount',
    },
    reason: {
        id: 'ManualTransactionAddModal_reason',
        defaultMessage: 'Reason',
    },
    referencePlaceholder: {
        id: 'ManualTransactionAddModal_referencePlaceholder',
        defaultMessage: 'Enter reference',
    },
    reference: {
        id: 'ManualTransactionAddModal_reference',
        defaultMessage: 'Reference',
    },
    commentPlaceholder: {
        id: 'ManualTransactionAddModal_commentPlaceholder',
        defaultMessage: 'Enter comment',
    },
    comment: {
        id: 'ManualTransactionAddModal_comment',
        defaultMessage: 'Comments',
    },
    cancel: {
        id: 'ManualTransactionAddModal_cancel',
        defaultMessage: 'Close',
    },
    confirm: {
        id: 'ManualTransactionAddModal_confirm',
        defaultMessage: 'Send',
    },
    credit: {
        id: 'ManualTransactionAddModal_credit',
        defaultMessage: 'Credit',
    },
    debit: {
        id: 'ManualTransactionAddModal_debit',
        defaultMessage: 'Debit',
    },
    creditMaxAmountValidationMessage: {
        id: 'ManualTransactionAddModal_creditMaxAmountValidationMessage',
        defaultMessage: 'Amount cannot exceed {value}',
    },
});

type ManualTransactionAddModalProps = {
    uid: string;
};

export function ManualTransactionAddModal({uid}: ManualTransactionAddModalProps) {
    const {closeModal} = useContext(ModalContext);
    const {formatMessage} = useIntl();
    const validationMessageFormatter = useValidationFormatter();
    const currencySymbol = useCurrencySymbol();
    const {sub} = useAuthUser();
    const {control, handleSubmit, state, submit, watch, formState, trigger} = useReduxForm<
        ManualTransactionAddViewModel,
        ManualTransactionAddViewModel
    >({
        initialModel: {created_by_uid: sub, uid, transaction_type: TransactionType.Debit},
        asyncAction: transactionActions.addManualTransaction,
        map: m => m,
        onSuccess: () => closeModal(),
        shouldResetOnError: false,
        mode: 'onChange',
    });

    const transactionType = watch('transaction_type');
    useEffect(() => {
        trigger('amount');
    }, [transactionType]);
    const transactionTypeOptions: SelectOption[] = [
        {
            value: TransactionType.Debit,
            label: formatMessage(localized.debit),
        },
        {
            value: TransactionType.Credit,
            label: formatMessage(localized.credit),
        },
    ];

    function getReasons(type: TransactionType): SelectOption[] {
        const reasons: ReasonCode[] = type === TransactionType.Credit ? creditReasonCodes : debitReasonCodes;
        return reasons.map(reason => ({value: reason, label: formatMessage(localizedReasonCode[reason])}));
    }

    function getAmountValidationRules(): Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> {
        const minTransactionAmount = 0.01;

        return {
            required: validationMessageFormatter(RuleType.Required, localized.amount),
            min: {
                value: minTransactionAmount,
                message: validationMessageFormatter(RuleType.Min, localized.amount, useCurrency(minTransactionAmount)),
            },
        };
    }

    return (
        <div>
            <BulkModalTitle title={localized.modalTitle} />
            <form id="addManualTransaction" onSubmit={handleSubmit(submit)}>
                <FormGroup>
                    <Controller
                        control={control}
                        name="transaction_type"
                        defaultValue={TransactionType.Debit}
                        render={({field}) => (
                            <StyledToggle value={field.value} options={transactionTypeOptions} onChange={field.onChange} />
                        )}
                        rules={{required: validationMessageFormatter(RuleType.Required, localized.type)}}
                    />
                    <Controller
                        control={control}
                        name="amount"
                        render={({field, fieldState}) => (
                            <StyledInput
                                value={field.value}
                                onChange={field.onChange}
                                label={localized.amount}
                                fieldState={fieldState}
                                endAdornment={currencySymbol}
                                type="number"
                                textAlign="right"
                                hasBottomSpacing={false}
                                fullWidth={false}
                            />
                        )}
                        rules={getAmountValidationRules()}
                    />
                    <Controller
                        control={control}
                        name="reason"
                        render={({field, fieldState}) => (
                            <FormSelectWithLabel
                                value={field.value}
                                onChange={field.onChange}
                                options={getReasons(transactionType)}
                                fieldState={fieldState}
                                label={localized.reason}
                                displayEmpty
                                hasBottomSpacing={false}
                                fullWidth={false}
                            />
                        )}
                        rules={{required: validationMessageFormatter(RuleType.Required, localized.reason)}}
                    />
                    <Controller
                        render={({field, fieldState}) => (
                            <StyledInput
                                value={field.value}
                                onChange={field.onChange}
                                placeholder={localized.referencePlaceholder}
                                label={localized.reference}
                                fieldState={fieldState}
                                hasBottomSpacing={false}
                                fullWidth={false}
                            />
                        )}
                        name="reference"
                        control={control}
                    />
                    <FormControl>
                        <FormLabel label={formatMessage(localized.comment)} labelVariant="caption" />
                        <Controller
                            render={({field, fieldState}) => (
                                <FormMultilineText
                                    placeholder={localized.commentPlaceholder}
                                    value={field.value}
                                    onChange={field.onChange}
                                    fieldState={fieldState}
                                />
                            )}
                            name="comment"
                            control={control}
                        />
                    </FormControl>
                </FormGroup>
            </form>
            <ModalFooter>
                <Button label={formatMessage(localized.cancel)} onClick={closeModal} />
                <Button
                    label={formatMessage(localized.confirm)}
                    type="submit"
                    form="addManualTransaction"
                    color="primary"
                    disabled={!formState.isValid || state.isProgress}
                />
            </ModalFooter>
        </div>
    );
}
