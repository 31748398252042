import {combineEpics, StateObservable} from 'redux-observable';
import {merge, Observable, of} from 'rxjs';
import {AjaxError} from 'rxjs/ajax';
import {catchError, filter, ignoreElements, tap} from 'rxjs/operators';
import {isActionOf, PayloadAction, RootAction, RootState} from 'typesafe-actions';

import {RootEpic} from '@redux';
import {errorActions} from '@redux/error-handling/action';

import {ServiceContainer} from '../../../app/inversify/serviceContainer';

import {initDefaultErrorHandlerAction, logErrorAction} from './actions';
import {ErrorCategory, LogErrorActionPayload} from './types';

/**
 * @deprecated
 * <p>{@link BaseEpicsBuilder}</p>
 */
const protectEpic =
    (epic: RootEpic) => (action$: Observable<RootAction>, state$: StateObservable<RootState>, dependencies: ServiceContainer) =>
        epic(action$, state$, dependencies).pipe(
            catchError((err: Error, source) => {
                const actions: PayloadAction<string, unknown>[] = [];
                if (err instanceof AjaxError) {
                    actions.push(errorActions.error({error: err, type: 'ajax', code: err.status}));
                } else {
                    actions.push(errorActions.error({error: err, type: 'middleware'}));
                }
                actions.push(logErrorAction({error: err, category: ErrorCategory.ReduxMiddleware}));

                return merge(source, ...actions.map(action => of(action)));
            })
        );

export const logErrorEpic: RootEpic = (action$, _, container) =>
    action$.pipe(
        filter(isActionOf(logErrorAction)),
        tap((action: PayloadAction<string, LogErrorActionPayload>) => {
            const {error, category, func} = action.payload;
            container.errorHandlingService.logError(error, category, func);
            return of();
        }),
        ignoreElements()
    );

export const initDefaultErrorHandlerEpic: RootEpic = (action$, _, container) =>
    action$.pipe(
        filter(isActionOf(initDefaultErrorHandlerAction)),
        tap(() => container.errorHandlingService.initDefaultErrorHandler()),
        ignoreElements()
    );

/**
 * @deprecated
 * <p>{@link BaseEpicsBuilder}</p>
 */
export const protectEpics = (...epics: RootEpic[]) => {
    return epics.length === 1 ? protectEpic(epics[0]) : combineEpics(...epics.map(epic => protectEpic(epic)));
};
