import React from 'react';
import {Box} from '@mui/material';
import {TreeItem, TreeItemProps} from '@mui/x-tree-view/TreeItem';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon, Icon, IconColor} from '@components/icons';
import {CustomTheme} from '@style';

const useStyles = makeStyles<void, 'treeViewItemSelectOptionIcon'>()((theme: CustomTheme, _params, classes) => ({
    treeViewItemSelectOptionContent: {
        '& .MuiTreeItem-content': {
            padding: 0,
        },
    },
    treeViewItemSelectOptionGroup: {
        '& .MuiTreeItem-group': {
            padding: theme.spacing(0.5, 0),
        },
    },
    treeViewItemSelectOptionLabel: {
        '& .MuiTreeItem-content .MuiTreeItem-label': {
            paddingLeft: 0,
        },
    },
    treeViewItemSelectOptionIcon: {
        '& .MuiTreeItem-content .MuiTreeItem-iconContainer': {
            display: 'none',
        },
    },
    treeViewItemSelectOption: {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: 0,
        padding: theme.spacing(1.5, 2),
        '&:hover': {
            background: theme.palette.primary.light,
        },
    },
    treeViewItemSelectOptionSelected: {
        background: theme.palette.primary.light,
        [`& .${classes.treeViewItemSelectOptionIcon}`]: {
            display: 'block',
        },
    },
}));

type TreeViewItemSelectOptionProps = Omit<TreeItemProps, 'onClick' | 'label'> & {
    label: string;
    selected?: boolean;
    onClick: (value: string) => void;
};

export function TreeViewItemSelectOption({label, nodeId, onClick, selected}: TreeViewItemSelectOptionProps) {
    const {classes, cx} = useStyles();

    function handleClick() {
        onClick(label);
    }

    return (
        <div
            className={cx(
                classes.treeViewItemSelectOptionIcon,
                classes.treeViewItemSelectOptionContent,
                classes.treeViewItemSelectOptionGroup,
                classes.treeViewItemSelectOptionLabel
            )}
        >
            <TreeItem
                nodeId={nodeId}
                onClick={handleClick}
                label={
                    <Box
                        className={cx(
                            {
                                [classes.treeViewItemSelectOptionSelected]: selected,
                            },
                            classes.treeViewItemSelectOption
                        )}
                    >
                        <LocalizedText label={label} />
                        {selected ? (
                            <Icon icon={CustomIcon.Confirm} color={IconColor.Primary} className={classes.treeViewItemSelectOptionIcon} />
                        ) : null}
                    </Box>
                }
            ></TreeItem>
        </div>
    );
}
