import React, {useContext, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Span} from '@opentelemetry/api';

import {applyLocationAttrs} from './OtelSpan';
import {TraceContext} from './TraceProvider';

type ErrorState = {
    error: Error;
    info: {
        componentStack: string;
    };
};

type ErrorDetailsComponentProps = {
    error: Error;
    componentStack: string;
};

const reactPrefix = 'react';
const ReactComponentAttributes = {
    REACT_COMPONENT: `${reactPrefix}.component`,
    REACT_COMPONENT_STACK: `${reactPrefix}.stack`,
    REACT_COMPONENT_ERROR: `${reactPrefix}.error`,
};

const withTraceErrorBoundary =
    <P extends object>(WrappedComponent: React.ComponentType<P>, ErrorDetailsComponent: React.ComponentType<ErrorDetailsComponentProps>) =>
    (props: P) => {
        const {tracingService} = useContext(TraceContext);
        const [errorState, setErrorState] = useState<ErrorState>({error: null, info: {componentStack: ''}});

        function applyComponentAttrs(span: Span, component: string, stack: string, error?: Error) {
            span?.setAttributes({
                [ReactComponentAttributes.REACT_COMPONENT]: component,
                [ReactComponentAttributes.REACT_COMPONENT_STACK]: stack,
                [ReactComponentAttributes.REACT_COMPONENT_ERROR]: error?.message,
            });
        }

        function handleError(error: Error, info: {componentStack: string}) {
            setErrorState({error, info});
            console.dir(error);
            console.dir(info);

            const tracer = tracingService.getTracer();

            tracer.startActiveSpan('REACT COMPONENT', span => {
                const lengthLimit = 500;
                const shortStack =
                    info.componentStack?.length > lengthLimit ? info.componentStack?.substring(0, lengthLimit) : info.componentStack;

                applyLocationAttrs(span);
                applyComponentAttrs(span, WrappedComponent?.displayName ?? WrappedComponent?.name, shortStack, error);
                tracingService.endSpanFailed(span, `${error?.message} ${shortStack}`);
            });
        }

        function renderFallback() {
            return (
                <ErrorDetailsComponent error={errorState?.error} componentStack={errorState?.info.componentStack}></ErrorDetailsComponent>
            );
        }

        return (
            <ErrorBoundary onError={handleError} fallbackRender={renderFallback}>
                <WrappedComponent {...(props as P)} />
            </ErrorBoundary>
        );
    };

export {withTraceErrorBoundary};
