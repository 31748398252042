import React, {useContext} from 'react';

import {ModalContext, ModalWidth} from './types';

type BulkModalContextProps = {
    chooseStep: (step: JSX.Element, stepWidth?: ModalWidth) => void;
    closeModal: () => void;
};

export const BulkModalContext = React.createContext<BulkModalContextProps>(undefined);

type BulkModalProviderProps = {
    children: React.ReactNode;
};

export const BulkModalProvider = ({children}: BulkModalProviderProps) => {
    const {openModal, closeModal} = useContext(ModalContext);

    const chooseStep = (step: JSX.Element, stepWidth?: ModalWidth): void => {
        openModal({body: step, styleParams: {maxWidth: stepWidth}});
    };

    return <BulkModalContext.Provider value={{chooseStep, closeModal}}>{children}</BulkModalContext.Provider>;
};
