import {defineMessages} from 'react-intl';

import {EnablementMode} from '@config/enablementMode';

export const localizedSecuritySettings = defineMessages({
    [EnablementMode.Enabled]: {
        id: 'securityQuestionEnabled',
        defaultMessage: 'Enabled',
    },
    [EnablementMode.Disabled]: {
        id: 'securityQuestionDisabled',
        defaultMessage: 'Disabled',
    },
    [EnablementMode.Null]: {
        id: 'securityQuestionNull',
        defaultMessage: '-',
    },
});
