import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {Button} from '@components/button/Buttons';

type FormSubmitActionProps = {
    label?: string | MessageDescriptor;
    component?: React.ElementType;
    className?: string;
};

const localized = defineMessages({
    formSubmitLabel: {
        id: 'FORM_SUBMIT_BUTTON_LABEL',
        defaultMessage: 'Submit',
    },
});

const FormSubmitAction = ({className, label, component}: FormSubmitActionProps) => {
    const ComponentType = component;

    return ComponentType ? (
        <ComponentType label={label ?? localized.formSubmitLabel} type="submit" />
    ) : (
        <Button className={className} color="primary" label={label ?? localized.formSubmitLabel} type="submit" />
    );
};

export default FormSubmitAction;
