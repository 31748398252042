import React, {useEffect, useRef} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {Divider, ListSubheader, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {v4 as uuid} from 'uuid';

import {StyledInput} from '@components/input';
import {
    MultiSelectFooter,
    MultiSelectItem,
    MultiSelectWithTextCounterValue,
    SelectArrowIcon,
    SelectFormattedOption,
    selectOptionFilter,
    VirtualizedMultiSelectOptionList,
} from '@components/select';
import {removeItemsFromArray} from '@utils/array';

import {SelectOption} from '../../../features/module-shared/types';

import {SelectProps} from './Select';
import {useFilterSelectClasses, useGeneralSelectClasses} from './selectStyle';

const localized = defineMessages({
    allSearchOptionLabel: {
        id: 'MultiSelect_allSearchOptionLabel',
        defaultMessage: 'Select All',
    },
});

export const selectAllOptionValue = 'all';

//TODO: [BO-2668] Move to src/common/components/dropdown (rename or merge?)
//TODO: [BO-2669] Move dropdown components to input folder (?)
export const MultiSelect = <T extends unknown[]>({
    options,
    value,
    emptyValue,
    onSubmit,
    enumFormatter,
    label,
    chipType,
    showResetButton,
    horizontalPosition,
    hasSearch,
    iconLabel,
    mode = 'client',
    onFilterChange,
    showPagination,
    page,
    pageSize,
    total,
    onPageChange,
    searchPlaceholder,
    hasSelectAll,
    renderValue,
    onSelectAll,
    defaultSelectAll,
    disabled,
    showApplyButton,
    onApply,
    virtualization,
}: SelectProps<T>) => {
    const maxVisibleOptionsCount = 6;
    const {classes, cx} = useFilterSelectClasses();
    const {classes: generalSelectClasses} = useGeneralSelectClasses();
    const {formatMessage} = useIntl();
    const {current: multiselectId} = useRef(uuid());

    const [selectedValue, setSelectedValue] = React.useState([] as T);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [visibleOptions, setVisibleOptions] = React.useState<SelectOption[]>(options);
    const [inputValue, setInputValue] = React.useState('');
    const [selectAll, setSelectAll] = React.useState(defaultSelectAll);

    useEffect(() => {
        setSelectAll(defaultSelectAll);
    }, [defaultSelectAll]);

    useEffect(() => {
        if (value) {
            setSelectedValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (options) {
            setVisibleOptions(options);
        }
    }, [options?.map(o => `${o.label}${o.value}`).join()]);

    const handleClose = () => {
        setSelectOpen(false);
    };

    const handleOpen = () => {
        setSelectOpen(true);
    };

    function handleChange(event: SelectChangeEvent<T>) {
        const targetValue = event.target.value;
        const targetArray = (Array.isArray(targetValue) ? targetValue.flat() : [targetValue]) as string[];
        const flatValues = targetArray.filter(v => v) as string[];
        if (flatValues[flatValues?.length - 1] !== selectAllOptionValue) {
            const handledArray: string[] = [];
            flatValues.forEach(fv => {
                const index = handledArray.indexOf(fv);
                if (index > -1) handledArray.splice(index, 1);
                else handledArray.push(fv);
            });

            setSelectedValue(handledArray as T);
            onSubmit(handledArray.length > 0 ? handledArray : null);
        }
    }

    function handleItemClick(itemValue: string) {
        let result: T;
        if (selectedValue?.includes(itemValue)) {
            result = removeItemsFromArray(selectedValue, itemValue) as T;
        } else {
            result = [...selectedValue, itemValue] as T;
        }
        handleChange({target: {value: result}} as SelectChangeEvent<T>);
    }

    function handleClearSelection(e: React.BaseSyntheticEvent) {
        e.stopPropagation();
        setSelectedValue([] as T);
        onSubmit(null);
    }

    function handleSearchChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        e.stopPropagation();
        const filterValue = e.target.value as string;
        setInputValue(filterValue);
        if (mode === 'client') {
            const filteredOptions = selectOptionFilter(getFormattedOptions(options), filterValue);
            setVisibleOptions(filteredOptions);
            if (onFilterChange) {
                onFilterChange(filterValue);
            }
        } else {
            onFilterChange(filterValue);
        }
    }

    function handleSelectAll() {
        if (onSelectAll) {
            onSelectAll(!selectAll);
        } else {
            const newValue = selectAll ? ([] as T) : (options?.map(o => o.value) as T);
            setSelectedValue(newValue);
            onSubmit(newValue);
            setSelectAll(!selectAll);
        }
    }

    function handleFilterChange(page: number) {
        if (mode === 'client') {
            throw new Error('method not implemented');
        } else {
            onPageChange(page);
        }
    }

    function handleApply() {
        if (onApply) {
            onApply();
        }
        handleClose();
    }

    function getInputValue(selected: T) {
        return renderValue ? (
            renderValue(selected)
        ) : (
            <MultiSelectWithTextCounterValue
                selected={selected as string[]}
                enumFormatter={enumFormatter}
                label={label}
                options={options}
                emptyValue={emptyValue}
            />
        );
    }

    function getFormattedOptions(options: SelectOption[]): SelectFormattedOption[] {
        return options?.map(o => ({...o, label: o?.label ? (typeof o.label === 'string' ? o.label : formatMessage(o.label)) : null}));
    }

    function doesInclude(a: any, b: any) {
        if (Array.isArray(a) && Array.isArray(b)) return a.some(ai => b.includes(ai));
        return a.indexOf(b) > -1;
    }

    return (
        <Select
            disabled={disabled}
            displayEmpty
            value={selectedValue}
            multiple
            onChange={handleChange}
            onClose={handleClose}
            onOpen={handleOpen}
            open={selectOpen}
            renderValue={iconLabel ? () => iconLabel : getInputValue}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: horizontalPosition,
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: horizontalPosition,
                },
                PopoverClasses: {
                    paper: classes.selectPopover,
                },
                autoFocus: false,
            }}
            IconComponent={() => (iconLabel ? null : <SelectArrowIcon selectOpen={selectOpen} />)}
            className={cx(classes.selectInput, {[classes.selectInputIconLabel]: !!iconLabel})}
            disableUnderline
            data-testid="multiselect"
            inputProps={{'data-testid': 'multiSelectInput'}}
        >
            {hasSearch ? (
                <ListSubheader disableGutters>
                    <StyledInput
                        value={inputValue}
                        className={classes.selectSearch}
                        onKeyDown={e => e.stopPropagation()}
                        onChange={handleSearchChange}
                        placeholder={searchPlaceholder}
                    />
                </ListSubheader>
            ) : null}
            <MenuItem value="" className={generalSelectClasses.selectItemHidden}></MenuItem>
            {hasSelectAll && !inputValue ? (
                <MultiSelectItem
                    key={`multiselect-${multiselectId}-${selectAllOptionValue}`}
                    value={selectAllOptionValue}
                    label={localized.allSearchOptionLabel}
                    isSelected={selectAll}
                    onClick={handleSelectAll}
                    chipType={chipType}
                />
            ) : null}
            <Divider className={generalSelectClasses.divider} />
            {virtualization ? (
                <VirtualizedMultiSelectOptionList
                    items={visibleOptions}
                    multiselectId={multiselectId}
                    selectedValue={selectedValue}
                    chipType={chipType}
                    onItemClick={handleItemClick}
                    visibleOptionCount={visibleOptions?.length > maxVisibleOptionsCount ? maxVisibleOptionsCount : visibleOptions?.length}
                />
            ) : (
                visibleOptions?.map(({value, label}) => (
                    <MultiSelectItem
                        key={`multiselect-${multiselectId}-${value}-${uuid()}`}
                        value={value as string}
                        chipValue={value as string}
                        label={label}
                        isSelected={doesInclude(selectedValue, value)}
                        chipType={chipType}
                    />
                ))
            )}

            <MultiSelectFooter
                showResetButton={showResetButton}
                onClear={handleClearSelection}
                showPagination={showPagination}
                page={page}
                pageSize={pageSize}
                total={total}
                onPageChange={handleFilterChange}
                showApplyButton={showApplyButton}
                onApply={handleApply}
            />
        </Select>
    );
};
