import React, {useContext} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import Button from '@components/button/Buttons';
import LocalizedText from '@components/i18n/LocalizedText';
import {Icon, IconColor} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {ModalContext} from '@components/modal';

import {BlockPlayerLabelGroupModal} from '../../block-label-group-list/components/BlockPlayerLabelGroupModal';

const localized = defineMessages<string, MessageDescriptor>({
    buttonTitle: {
        id: 'BlockPlayerLabelGroupCreate_buttonTitle',
        defaultMessage: 'Create New Group',
    },
    modalTitle: {
        id: 'BlockPlayerLabelGroupCreate_modalTitle',
        defaultMessage: 'New Group',
    },
});

export function BlockPlayerLabelGroupCreate() {
    const {openModal} = useContext(ModalContext);

    function handleClick() {
        openModal({
            body: <BlockPlayerLabelGroupModal />,
            title: <LocalizedText label={localized.modalTitle} />,
        });
    }

    return (
        <Button
            label={localized.buttonTitle}
            onClick={handleClick}
            endIcon={<Icon color={IconColor.Secondary} icon={CustomIcon.AddCircleOutline} />}
            placement={'stretch'}
        />
    );
}
