import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useMessageClasses = makeStyles()((theme: CustomTheme) => ({
    message: {
        margin: 'auto',
    },
    floatingMessage: {
        position: 'absolute',
        marginTop: theme.spacing(1),
        borderRadius: theme.shape.borderRadius * 2,
        padding: theme.spacing(0.75, 1.5),
        zIndex: 1,
        background: theme.palette.background.paper,
        width: 'max-content',
        maxWidth: '220px',
    },
    errorMessage: {
        color: theme.palette.error.main,
    },
    floatingErrorMessage: {
        border: `1px solid ${theme.palette.error.main}`,
    },
    successMessage: {
        color: theme.palette.success.main,
    },
    floatingSuccessMessage: {
        border: `1px solid ${theme.palette.success.main}`,
    },
    infoMessage: {
        color: theme.palette.info.main,
    },
    floatingInfoMessage: {
        border: `1px solid ${theme.palette.info.main}`,
    },
    warningMessage: {
        color: theme.palette.warning.main,
    },
    floatingWarningMessage: {
        border: `1px solid ${theme.palette.warning.main}`,
    },
}));
