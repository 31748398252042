import {createReducer} from 'typesafe-actions';

import {IBreadcrumb} from 'src/common/types';

import {setBreadcrumbSuccessAction} from './actions';

export const breadcrumbReducer = createReducer([] as Array<IBreadcrumb>).handleAction(
    setBreadcrumbSuccessAction,
    (_, action) => action.payload
);
