import React, {useState} from 'react';

import SideModal from '@components/SideModal';

type SideModalContextProps = {
    isModalOpened: boolean;
    openModal: (content: JSX.Element, props?: any) => void;
    closeModal: () => void;
    props?: any;
};

type SideModalProviderProps = {
    children: JSX.Element;
};

const SideModalContext = React.createContext<SideModalContextProps>(undefined);

const SideModalProvider = ({children}: SideModalProviderProps) => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [modalContent, setModalContent] = useState(<></>);
    const [props, setModalProps] = useState();

    const openModal = (content: JSX.Element, props?: any) => {
        setModalContent(content);
        setIsModalOpened(true);
        setModalProps(props);
    };

    const closeModal = () => {
        setModalContent(<></>);
        setIsModalOpened(false);
        setModalProps(null);
    };

    return (
        <SideModalContext.Provider value={{isModalOpened, openModal, closeModal, props}}>
            {children}
            <SideModal modalId="sideModal" isOpen={isModalOpened} onClose={closeModal}>
                {modalContent}
            </SideModal>
        </SideModalContext.Provider>
    );
};

export {SideModalContext, SideModalProvider};
