import React, {Fragment} from 'react';
import {defineMessages, useIntl} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {AgentPlayerReportingViewModelKeys} from '@models/agent-player-reporting';
import {ModuleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';
import {Policy} from '@auth';
import {extendedViewCleanDelay, ViewType} from '@redux/view';

import {usePlayerLevelReportSettings} from 'src/features/block-agent-player-reporting-list/hooks';
import {AgentPlayerReportDownloadCsvButton} from '../features/block-agent-player-reporting-actions';
import {
    AgentPlayerReportingQueryFilters,
    DataGridAgentPlayerReporting,
    useAgentPlayerReporting,
} from '../features/block-agent-player-reporting-list';
import {useDefaultReferrerFilter} from '../features/block-agent-profile';
import {withModule} from '../features/module-shared/components/ModuleHoc';

const localized = defineMessages({
    title: {
        id: 'PlayerLevelReport_title',
        defaultMessage: 'Player Level Report',
    },
});

function PlayerLevelReport() {
    const viewType: ViewType = 'PlayerLevelReport';
    const {formatMessage} = useIntl();

    const pinnedColumns: AgentPlayerReportingViewModelKeys[] = ['uid_link', 'username', 'email'];
    const columns: AgentPlayerReportingViewModelKeys[] = (
        [
            ...pinnedColumns,
            'country',
            'referrer_agent_id',
            'referrer_agent_username',
            'register_marketing_code',
            'ggr_summary.poker_rake',
            'ggr_summary.poker_ggr',
            'ngr_summary.poker_ngr',
            'ggr_summary.poker_addons',
            'ggr_summary.cash_ggr',
            'ggr_summary.poker_mtt_fees',
            'ggr_summary.mtt_ggr',
            'deduction_summary.psp_fee',
            'bonus_summary.realized_bonus_total',
            'bonus_summary.mtt_ticket_bonus',
            'bonus_summary.poker_bonus',
            'bonus_summary.casino_bonus',
            'bonus_summary.cost_global_spins_tickets',
            'ggr_summary.plo_ggr_high_stake',
            'ggr_summary.plo_ggr_low_stake',
            'ggr_summary.plo_ggr_medium_stake',
            'ggr_summary.plo_ggr_micro_stake',
            'ggr_summary.plo_ggr_total',
            'ggr_summary.plo_ggr_unknown_stake',
            'ggr_summary.poker_share',
            'ggr_summary.casino_share',
            'ggr_summary.total_share',
            'ggr_summary.internal_casino_ggr',
            'ggr_summary.external_casino_ggr',
            'ggr_summary.casino_ggr',
            'ggr_summary.global_spin_ggr',
            'ggr_summary.internal_casino_bet_amount',
            'ggr_summary.external_casino_bet_amount',
            'ggr_summary.total',
            'player_games_summary.player_net_deposit',
            'player_games_summary.cash_game_hand_played',
            'player_games_summary.mtt_played',
            'player_games_summary.player_profit_loss',
            'other_summary.cost_tournament_overlay_excl_freeroll',
            'ggr_summary.mtt_total_buyin',
            'pro_summary.is_pro_shortdeck',
            'pro_summary.cost_spins_tournament_tickets_pro',
            'pro_summary.cost_ftd_bonus_pro',
            'pro_summary.cost_psp_fee_pro',
            'pro_summary.poker_ggr_usd_pro',
            'pro_summary.nlhe_ggr_usd_pro',
            'pro_summary.shortdeck_ggr_usd_pro',
            'pro_summary.nlhe_micro_ggr_usd_pro',
            'pro_summary.nlhe_low_ggr_usd_pro',
            'pro_summary.nlhe_medium_ggr_usd_pro',
            'pro_summary.nlhe_high_ggr_usd_pro',
            'ngr_summary.mtt_ngr',
            'ngr_summary.cash_ngr',
            'ngr_summary.casino_ngr',
        ] as AgentPlayerReportingViewModelKeys[]
    ).filter(c => c);
    const {visibleColumns, onVisibleColumnsChange} = usePlayerLevelReportSettings({
        settingId: 'PlayerLevelReport',
        defaultColumns: columns,
        pinnedColumns,
    });
    const nonColumnKeys: AgentPlayerReportingViewModelKeys[] = ['has_summary'];
    const keys: AgentPlayerReportingViewModelKeys[] = [...columns, ...nonColumnKeys];

    const {
        items,
        summary,
        totalCount,
        searchFilter,
        filterString,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
        handleFilterChange,
    } = useAgentPlayerReporting({
        viewType,
        fields: keys,
        displayName: localized.title,
        defaultSorting: [{field: 'ggr_summary.poker_share', sort: 'desc'}],
        syncWithUrl: true,
        validateFilter: null,
        cleanDelay: extendedViewCleanDelay,
    });

    const {boAgentPlayerId} = useDefaultReferrerFilter({viewType, onFilterChange: handleFilterChange});

    return (
        <Fragment>
            <LayoutQueue
                header={
                    <PageHeader
                        header={localized.title}
                        actions={
                            <AgentPlayerReportDownloadCsvButton
                                filterString={filterString}
                                filename={formatMessage(localized.title)}
                                keys={[...visibleColumns, ...nonColumnKeys]}
                                agentReportType="Regular"
                            />
                        }
                    />
                }
                toolbar={
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems streched>
                                <AgentPlayerReportingQueryFilters
                                    viewType="PlayerLevelReportFilters"
                                    model={filterString}
                                    onChange={handleFilterChange}
                                    availableFilters={['uid_email_uname_rmc', 'agent', 'countries', 'started_at']}
                                    boAgentPlayerId={boAgentPlayerId}
                                />
                            </ToolbarItems>
                        </ToolbarItemsContainer>
                    </Toolbar>
                }
                body={
                    <DataGridAgentPlayerReporting
                        columns={columns}
                        rows={items}
                        pinnedRow={summary}
                        paging={{page: searchFilter?.paging?.page, pageSize: searchFilter?.paging?.pageSize, rowCount: totalCount}}
                        sortModel={{sortModel: searchFilter?.sorting}}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortChange={handleSortChange}
                        configurableColumns={{
                            visibleColumns,
                            pinnedColumns,
                            onVisibleColumnsChange,
                        }}
                        frozenColumnsCount={{desktop: 2, mobile: 1}}
                    />
                }
            />
        </Fragment>
    );
}

export const playerLevelReportingResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'PlayerLevelReporting',
    rules: new PoliciesAccessRule([new Policy(ModuleName.PageAgentReport, null, 'read')]),
});

export default withModule(PlayerLevelReport, [{moduleName: ModuleName.PageAgentReport, permissions: [PermissionEnum.Read]}]);
