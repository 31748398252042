import React from 'react';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import StyledAccordion from '@components/StyledAccordion';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionAccessibleItem, PermissionEnum} from '@models/permissions';
import {agentReadPolicies} from '@models/permissions/permissions';

import {withMultiplePermission} from '../../app/permission/PermissionHoc';
import {LegalDocumentItem} from '../../legal-docs/types';

import {UserPrivacyPolicy} from './UserPrivacyPolicy';

type UserDetailsPrivacyPolicyInfoProps = {
    userId: string;
};

const localizedUserDetailsPrivacyPolicyInfo = defineMessages({
    privacyPolicy: {
        id: 'UD_privacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
});

const useUserDetailsPrivacyPolicyInfoClasses = makeStyles()(() => ({
    userDetailsPrivacyPolicyInfoContainer: {
        width: '100%',
    },
    userDetailsPrivacyPolicyInfo: {
        paddingTop: 0,
        boxShadow: 'none',
    },
}));

function UserDetailsPrivacyPolicyInfoInner({userId}: UserDetailsPrivacyPolicyInfoProps) {
    const {classes} = useUserDetailsPrivacyPolicyInfoClasses();

    return (
        <StyledAccordion
            header={localizedUserDetailsPrivacyPolicyInfo.privacyPolicy}
            componentId="privacyPolicy"
            isCollapsed
            unmountOnCollapse
            children={
                <Box className={classes.userDetailsPrivacyPolicyInfoContainer}>
                    <UserPrivacyPolicy
                        itemId={userId}
                        className={classes.userDetailsPrivacyPolicyInfo}
                        fields={[nameof<LegalDocumentItem>(l => l.content), nameof<LegalDocumentItem>(l => l.acceptedAt)]}
                        hideTitle
                    />
                </Box>
            }
        />
    );
}

const allowedPermissions: PermissionAccessibleItem[] = [
    {moduleName: ModuleName.LegalContentManagement, permissions: [PermissionEnum.Read]},
    {moduleName: ModuleName.LegalContentManagement, submoduleName: SubmoduleName.PrivacyPolicy, permissions: [PermissionEnum.Read]},
];

const restrictedPermissions: PermissionAccessibleItem[] = agentReadPolicies;

export const UserDetailsPrivacyPolicyInfo = withMultiplePermission(UserDetailsPrivacyPolicyInfoInner, {
    allowedPermissions,
    restrictedPermissions,
});
