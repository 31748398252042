import {MuiDataGridClient, MuiDataGridServer, withDataGridEntityClient, withDataGridEntityServer} from '@components/data-grid/mui';

import {DataGridPageViewersFactory, RecordWithPageViewers, RecordWithPageViewersKeys} from 'src/features/block-page-view';
import {DataGridUserProfileConfigFactory} from 'src/features/block-user-profile-list';
import {DataGridTransactionWithUserProfileColumns, TransactionWithUserProfileGridItem} from '../types';

import {DataGridTransactionConfigFactory} from './DataGridTransaction';

export const DataGridTransactionWithUserProfileClient = withDataGridEntityClient<
    DataGridTransactionWithUserProfileColumns | RecordWithPageViewersKeys,
    TransactionWithUserProfileGridItem | RecordWithPageViewers
>(MuiDataGridClient, () => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridTransactionConfigFactory().getColumnConfig(),
    ...new DataGridPageViewersFactory().getColumnConfig(),
}));

export const DataGridTransactionWithUserProfileServer = withDataGridEntityServer<
    DataGridTransactionWithUserProfileColumns | RecordWithPageViewersKeys,
    TransactionWithUserProfileGridItem | RecordWithPageViewers
>(MuiDataGridServer, () => ({
    ...new DataGridUserProfileConfigFactory().getColumnConfig(),
    ...new DataGridTransactionConfigFactory().getColumnConfig(),
    ...new DataGridPageViewersFactory().getColumnConfig(),
}));
