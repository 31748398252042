import {IModuleGridItem} from '@components/data-grid';
import {BonusCodeDetails} from '@models/generated/graphql';

import {ColumnConfiguration} from 'src/common/types';
import {IModuleItem} from '../module-shared/types';

export const domain = 'be-bonus';

export class BeBonusItem extends BonusCodeDetails implements IModuleItem {
    id: string;
}

export class BeBonusGridItem extends BonusCodeDetails implements IModuleGridItem {
    id: string;
    isNew: boolean;
    serverId: string;
}

export class BeBonusColumnsConfiguration extends ColumnConfiguration {
    withBonusId: boolean;
    withBonusName: boolean;
    withPlatform: boolean;
    withBonusStatus: boolean;
    withCreateTime: boolean;
    withLastUpdate: boolean;
    withStartTime: boolean;
    withEndTime: boolean;
    withThreshold: boolean;
    withCreditType: boolean;
    withBonusToCredit: boolean;
    withReleaseMetric: boolean;
    withReleaseMechanism: boolean;
    withMeta: boolean;
    withDescription: boolean;
    withMarketingCodes: boolean;
    withTotalPaidOutAmount: boolean;

    constructor() {
        super();
        this.withBonusId = false;
        this.withBonusName = false;
        this.withPlatform = false;
        this.withBonusStatus = false;
        this.withCreateTime = false;
        this.withLastUpdate = false;
        this.withStartTime = false;
        this.withEndTime = false;
        this.withThreshold = false;
        this.withCreditType = false;
        this.withBonusToCredit = false;
        this.withReleaseMetric = false;
        this.withReleaseMechanism = false;
        this.withMeta = false;
        this.withDescription = false;
        this.withMarketingCodes = false;
        this.withTotalPaidOutAmount = false;
    }
}

export const beBonusFullSearch = 'beBonusFullSearch';
