import React from 'react';
import {useState} from 'react';

import {StyledToggle} from '@components/input';
import {UserBusinessPool} from '@models/generated/graphql';
import {formatSnakeCaseEnumValue, getEnumValues} from '@utils';

import {SelectOption} from '../../../features/module-shared/types';

import {Wrapper} from './WrapperDev';

export const StyledToggles = () => {
    const toggleSelectedValue = UserBusinessPool.Pkw;
    const toggleOptions = getEnumValues(UserBusinessPool).map(v => ({label: formatSnakeCaseEnumValue(v), value: v} as SelectOption));
    const [toggleSelectedOption, setToggleSelectedOption] = useState(toggleSelectedValue);
    return (
        <Wrapper label="Styled Toggle">
            <StyledToggle
                label={'Business pool'}
                value={toggleSelectedOption}
                options={toggleOptions}
                onChange={v => setToggleSelectedOption(v as UserBusinessPool)}
            />
        </Wrapper>
    );
};
