import React, {ForwardedRef, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Controller, FieldErrors, useFieldArray, useForm, useFormState, useWatch} from 'react-hook-form';
import {defineMessages, IntlContext} from 'react-intl';
import {Box, CircularProgress} from '@mui/material';
import {CSSObject} from 'tss-react';
import {makeStyles} from 'tss-react/mui';
import {v4 as uuid} from 'uuid';

import {MarkdownEditor} from '@components/rich-text-editor';
import {CustomTheme} from '@style';

import Button from '../button/Buttons';
import {FormError, RuleType, useCustomValidationFormatter, useValidationFormatter} from '../input';

import {AttachmentOption, attachmentOptionClassName} from './AttachmentOption';
import {Attachments} from './Attachments';
import {CommentSelectNoteType, CommentSelectWorkspace} from './CommentSelect';
import {useHumanFileSize} from './HumanFileSize';
import {Attachment, CommentFormModel, defaultCommentFormModel, Suggestion} from './types';
import {getMarkdownMentions} from './utils';

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    commentEditorWrapper: {
        minHeight: 44,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.custom.palette.content.border}`,
        borderRadius: theme.shape.borderRadius * 1.5,
        position: 'relative',
        flexShrink: 0,
    },
    commentEditorFormFullHeight: {
        flexGrow: 1,
    },
    commentEditorWrapperReadMode: {
        padding: theme.spacing(0.5, 1),
    },
    commentEditorWrapperEditMode: {
        padding: theme.spacing(1),
    },
    commentEditorWrapperEditModeFullHeight: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    commentEditorWrapperFooter: {
        marginTop: 'auto',
        overflow: 'auto',
        maxHeight: theme.spacing(25),
        padding: theme.spacing(0.5, 0),
    },
    commentEditorProgress: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    commentEditorCustomInputs: {
        margin: theme.spacing(-0.5, 0),
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    commentEditorAttachmentList: {
        marginTop: theme.spacing(2),
    },
    commentEditorSubmitButtons: {
        display: 'flex',
        margin: theme.spacing(0.5, 0),
        '& button': {
            padding: theme.spacing(0.5, 1),
        },
    },
    commentEditorSelects: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    editorContent: {
        overflow: 'initial',
        minHeight: 26,
        ...(theme.typography.body1 as CSSObject),
        '& .remirror-mention-atom': {
            borderRadius: theme.spacing(2),
            color: theme.palette.text.secondary,
            padding: theme.spacing(0.25, 1),
            background: theme.custom.palette.content.borderLight,
            '&:before': {
                display: 'inline-block',
                content: '"@"',
                verticalAlign: 'text-bottom',
            },
        },
        '& .remirror-editor': {
            a: {
                color: theme.palette.primary.main,
                textDecorationColor: theme.palette.primary.main,
            },
        },
    },
    editorToolbarCustomButton: {
        [`.${attachmentOptionClassName}`]: {
            order: 1,
            marginLeft: 'auto !important',
        },
    },
    editorContentActive: {
        '& .remirror-editor-wrapper': {
            marginLeft: theme.spacing(2.5),
            marginRight: theme.spacing(2.5),
        },
    },
}));

//TODO: [BO-2659] Remove dependency on features (introduce own model)
export enum CommentEditorSize {
    Small = 'Small',
    FullHeight = 'FullHeight',
}

type CommentEditorProps = {
    value?: string;
    suggestions?: Suggestion[];
    isProgress: boolean;
    onSubmit?: (model: CommentFormModel) => void;
    hideFooterActions?: boolean;
    isEditMode?: boolean;
    size?: CommentEditorSize;
    initialValue?: CommentFormModel;
};

const megaBytesToBytes = Math.pow(1024, 2);
const bodyMaxLength = 4 * megaBytesToBytes;
const fileMaxLength = 50 * megaBytesToBytes;

const localized = defineMessages({
    commentEditorSubmit: {
        id: 'COMMENT_EDITOR_SUBMIT',
        defaultMessage: 'Submit',
    },
    commentEditorCancel: {
        id: 'COMMENT_EDITOR_CANCEL',
        defaultMessage: 'Cancel',
    },
    commentEditorPlaceholder: {
        id: 'COMMENT_EDITOR_PLACEHOLDER',
        defaultMessage: 'Comment or add others with @',
    },
    commentEditorLabel: {
        id: 'COMMENT_EDITOR_LABEL',
        defaultMessage: 'Comment text',
    },
    commentEditorAttachment: {
        id: 'COMMENT_EDITOR_ATTACHMENT',
        defaultMessage: 'Attachment',
    },
    commentEditorFileMax: {
        id: 'COMMENT_EDITOR_FILE_MAX',
        defaultMessage: '{file} is too large. Accepted max file size for this type is {size}',
    },
});

export const CommentEditor = forwardRef(
    (
        {suggestions, onSubmit, isProgress, hideFooterActions, isEditMode, size, initialValue}: CommentEditorProps,
        ref: ForwardedRef<unknown>
    ) => {
        const {classes, cx} = useClasses();
        const refFileInput = useRef(null);
        const {formatMessage} = useContext(IntlContext);
        const [isEdit, setIsEdit] = useState(isEditMode);
        const methods = useForm<CommentFormModel>({defaultValues: {...defaultCommentFormModel, ...initialValue}});
        const {reset, trigger, control, handleSubmit, setValue} = methods;
        const {errors, isValid} = useFormState({control});
        const validationFormatter = useValidationFormatter();
        const customValidationFormatter = useCustomValidationFormatter();
        const humanFileSize = useHumanFileSize();
        const {fields: fileMeta, remove, prepend} = useFieldArray({control, name: 'fileMeta', keyName: 'id'});
        const [attachmentsInputValue, setAttachmentsInputValue] = React.useState('');

        useImperativeHandle(ref, () => {
            return {
                submitForm: handleSubmit(onSubmitInternal, onInvalid),
                resetForm: handleCancel,
            };
        });

        const attachments = useWatch({
            name: 'attachments',
            control,
        });

        const attachmentsFinal = useWatch({
            name: 'attachmentsFinal',
            control,
        });

        useEffect(() => {
            if (attachments?.length) {
                const dt = new DataTransfer();

                if (attachments?.length) {
                    for (let i = 0; i < attachments.length; i++) {
                        const attachment = attachments.item(i);
                        dt.items.add(attachment);
                    }
                }

                if (attachmentsFinal?.length) {
                    for (let i = 0; i < attachmentsFinal.length; i++) {
                        const attachment = attachmentsFinal.item(i);
                        dt.items.add(attachment);
                    }
                }

                setValue('attachmentsFinal', dt.files);
                setValue('attachments', null);

                const fileMeta = parseFileList(attachments);
                prepend(fileMeta);
                trigger();
            }
        }, [attachments]);

        const handleRemove = (idx: number) => {
            remove(idx);

            const dt = new DataTransfer();
            for (let i = 0; i < attachmentsFinal.length; i++) {
                if (i !== idx) {
                    dt.items.add(attachmentsFinal.item(i));
                }
            }

            setValue('attachmentsFinal', dt.files);
        };

        const parseFileList = (files: FileList | []): Attachment[] => {
            return Object.values(files).map(({name, size, type}, index) => ({
                filename: name,
                size,
                type,
                id: `${uuid()}`,
                extension: '',
                url: '',
                posted_at: null,
                onRemove: () => handleRemove(index),
            }));
        };

        const onSubmitInternal = (data: CommentFormModel) => {
            data.users_tagged = getMarkdownMentions(data.body);
            onSubmit(data);
        };

        const onInvalid = (errors: FieldErrors) => {
            console.log({errors});
        };

        const handleCancel = () => {
            setAttachmentsInputValue('');
            reset(defaultCommentFormModel);
            setIsEdit(false);
        };

        const handleStartEdit = () => {
            setIsEdit(true);
        };

        const handleSelectOpen = () => {
            setIsEdit(true);
        };

        const editModeClassName = cx(
            classes.commentEditorWrapperEditMode,
            size === CommentEditorSize.FullHeight && classes.commentEditorWrapperEditModeFullHeight
        );

        const formClassName = size === CommentEditorSize.FullHeight ? classes.commentEditorFormFullHeight : '';

        return (
            <form onSubmit={handleSubmit(onSubmitInternal, onInvalid)} data-testid="notesForm" className={formClassName}>
                <Box
                    className={cx(
                        classes.commentEditorWrapper,
                        isEdit && editModeClassName,
                        !isEdit && classes.commentEditorWrapperReadMode
                    )}
                >
                    <Controller
                        render={({field}) => (
                            <input
                                type="file"
                                ref={refFileInput}
                                hidden
                                multiple
                                value={attachmentsInputValue}
                                onChange={e => {
                                    setAttachmentsInputValue(e.target.value);
                                    field.onChange(e.target.files);
                                }}
                            />
                        )}
                        control={control}
                        name="attachments"
                    />
                    <Controller
                        render={({field}) => (
                            <div
                                className={cx(
                                    isEdit && classes.editorContentActive,
                                    classes.editorToolbarCustomButton,
                                    classes.editorContent
                                )}
                            >
                                <MarkdownEditor
                                    value={field.value}
                                    forceExpand={isEdit}
                                    onStartEdit={handleStartEdit}
                                    onChange={field.onChange}
                                    readOnly={isProgress}
                                    suggestions={suggestions}
                                    placeholder={formatMessage(localized.commentEditorPlaceholder)}
                                    toolbarCustomButtons={[<AttachmentOption refFileInput={refFileInput} />]}
                                />
                            </div>
                        )}
                        control={control}
                        name="body"
                        rules={{
                            required: validationFormatter(RuleType.Required, localized.commentEditorLabel),
                            maxLength: {
                                value: bodyMaxLength,
                                message: validationFormatter(RuleType.Max, localized.commentEditorLabel, bodyMaxLength),
                            },
                        }}
                    />
                    {isEdit && (
                        <>
                            <Box className={classes.commentEditorWrapperFooter}>
                                {fileMeta.map((f: Attachment, number) => (
                                    <Box key={`file_meta.${f.id}`}>
                                        <Controller
                                            control={control}
                                            render={({field}) => <input hidden type="number" {...field}></input>}
                                            name={`fileMeta.${number}.size`}
                                            rules={{
                                                validate: {
                                                    max: v =>
                                                        v > fileMaxLength
                                                            ? customValidationFormatter(localized.commentEditorFileMax, {
                                                                  file: f.filename,
                                                                  size: humanFileSize(fileMaxLength),
                                                              })
                                                            : null,
                                                },
                                            }}
                                            defaultValue={f.size}
                                        ></Controller>
                                        <Controller
                                            control={control}
                                            render={({field}) => <input hidden type="text" {...field}></input>}
                                            name={`fileMeta.${number}.type`}
                                            defaultValue={f.type}
                                        ></Controller>
                                        <Controller
                                            control={control}
                                            render={({field}) => <input hidden {...field}></input>}
                                            name={`fileMeta.${number}.id`}
                                            defaultValue={f.id}
                                        ></Controller>
                                        <Controller
                                            control={control}
                                            render={({field}) => <input hidden {...field}></input>}
                                            name={`fileMeta.${number}.filename`}
                                            defaultValue={f.filename}
                                        ></Controller>
                                    </Box>
                                ))}
                                <Box className={classes.commentEditorAttachmentList}>
                                    <Attachments attachments={parseFileList(attachmentsFinal ? attachmentsFinal : [])} />
                                </Box>
                                {/** tags_position */}
                                <Box className={classes.commentEditorCustomInputs}>
                                    <Box className={classes.commentEditorSelects}>
                                        <Controller
                                            render={({field}) => (
                                                <CommentSelectNoteType
                                                    value={field.value}
                                                    onSubmit={field.onChange}
                                                    onOpen={handleSelectOpen}
                                                />
                                            )}
                                            control={control}
                                            name="note_type"
                                        />
                                        <Controller
                                            render={({field}) => (
                                                <CommentSelectWorkspace
                                                    value={field.value}
                                                    onSubmit={field.onChange}
                                                    onOpen={handleSelectOpen}
                                                />
                                            )}
                                            control={control}
                                            name="workspace"
                                        />
                                    </Box>
                                    {!hideFooterActions ? (
                                        <Box className={classes.commentEditorSubmitButtons}>
                                            <Button
                                                size="small"
                                                disabled={isProgress}
                                                onClick={handleCancel}
                                                label={localized.commentEditorCancel}
                                            />
                                            <Box position="relative" ml={1}>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    size="small"
                                                    variant="contained"
                                                    disabled={isProgress}
                                                    label={localized.commentEditorSubmit}
                                                />
                                                {isProgress && (
                                                    <Box className={classes.commentEditorProgress}>
                                                        <CircularProgress size={12} />
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    ) : null}
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
                {!isValid ? (
                    <>
                        <FormError>{errors?.body?.message}</FormError>
                        {errors?.fileMeta?.length ? (
                            errors?.fileMeta.map((e, i) => (
                                <Box key={`file_type_error_${i}`}>
                                    {e?.type ? <FormError>{e?.type.message}</FormError> : <></>}
                                    {e?.size ? <FormError>{e?.size.message}</FormError> : <></>}
                                </Box>
                            ))
                        ) : (
                            <></>
                        )}
                    </>
                ) : null}
            </form>
        );
    }
);
