import React from 'react';
import {GridEditCellValueParams, GridRenderCellParams} from '@mui/x-data-grid';

import {AmountCurrencyCellProps} from '@components/data-grid/mui';
import {StyledInput} from '@components/input';

import {useCurrencySymbol} from '../../app/intl/hooks';

export function renderP2PTransferAmountEditableCell(params: GridRenderCellParams) {
    const currencySymbol = useCurrencySymbol();
    const value = params.value as AmountCurrencyCellProps;
    function handleChange(amount: number) {
        const editValue: GridEditCellValueParams = {id: params.id, value: {amount, currency: params.row.currency}, field: params.field};
        if (params.cellMode === 'view') {
            params.api.startCellEditMode({id: params.id, field: params.field});
        }

        setTimeout(() => {
            params.api.setEditCellValue(editValue);
        }, 0);
    }
    return (
        <StyledInput
            value={value?.amount ?? 0}
            onChange={e => handleChange(e.target.value as number)}
            endAdornment={currencySymbol}
            textAlign="right"
            type="number"
            hasBottomSpacing={false}
            fullWidth={false}
        />
    );
}
