import React, {useContext} from 'react';
import {Control, Controller, FieldPath, useForm} from 'react-hook-form';
import {defineMessages, IntlContext, MessageDescriptor} from 'react-intl';
import {Box, InputAdornment, List, ListItem, ListItemText} from '@mui/material';
import {format, parse} from 'date-fns';
import {makeStyles} from 'tss-react/mui';

import {useAutoMapper} from '@auto-mapper';
import Button from '@components/button/Buttons';
import {FormTextInputDefault, numberRegex, RuleType, useValidationFormatter} from '@components/input';
import {ModalFooter} from '@components/modal';
import {AgentPokerCashViewModel, AgentRevenueShareViewModel} from '@models/user-profile/types';
import {sortByDate} from '@utils/date';

type AgentRevenueShareModalProps = {
    playerRevenueShare: string;
    onSave: (value: string) => void;
};

const localized = defineMessages({
    agentRevenueSharePokerCash: {
        id: 'AgentRevenueShareModal_agentRevenueSharePokerCash',
        defaultMessage: 'Poker Cash',
    },
    agentRevenueShareGrossDeposit: {
        id: 'AgentRevenueShareModal_agentRevenueShareGrossDeposit',
        defaultMessage: 'Gross Deposit',
    },
    agentRevenueSharePlayerWinloss: {
        id: 'AgentRevenueShareModal_agentRevenueSharePlayerWinloss',
        defaultMessage: 'Player Win / Loss',
    },
    agentRevenueShareNetDepositShare: {
        id: 'AgentRevenueShareModal_agentRevenueShareNetDepositShare',
        defaultMessage: 'Net Deposit Share',
    },
    agentRevenueShareCasinoShare: {
        id: 'AgentRevenueShareModal_agentRevenueShareCasinoShare',
        defaultMessage: 'Casino Share',
    },
    agentRevenueShareSaveButtonLabel: {
        id: 'AgentRevenueShareModal_agentRevenueShareSaveButtonLabel',
        defaultMessage: 'Save',
    },
    agentRevenueShareSpins: {
        id: 'AgentRevenueShareModal_agentRevenueShareSpins',
        defaultMessage: 'Spins',
    },
    agentRevenueSharePokerMtt: {
        id: 'AgentRevenueShareModal_agentRevenueSharePokerMtt',
        defaultMessage: 'Poker MTT',
    },
    agentRevenueShareSports: {
        id: 'AgentRevenueShareModal_agentRevenueShareSports',
        defaultMessage: 'Sports',
    },
    agentRevenueSharePlo: {
        id: 'AgentRevenueShareModal_agentRevenueSharePokerPlo',
        defaultMessage: 'Plo',
    },
    agentRevenueSharePokerSquid: {
        id: 'AgentRevenueShareModal_agentRevenueSharePokerSquid',
        defaultMessage: 'Squid',
    },
    agentRevenueSharePokerAllinFold: {
        id: 'AgentRevenueShareModal_agentRevenueSharePokerAllinFold',
        defaultMessage: 'All-in or Fold',
    },
    agentRevenueSharePokerNlhSd: {
        id: 'AgentRevenueShareModal_agentRevenueSharePokerNlhSd',
        defaultMessage: 'NLH + SD',
    },
});

const useClasses = makeStyles()(theme => ({
    agentRevenueShareModalList: {
        paddingTop: theme.spacing(3),
        maxHeight: 720,
        overflow: 'auto',
    },
    agentRevenueShareModalListItem: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
    agentRevenueShareModalListItemText: {
        minWidth: theme.spacing(25),
        marginBottom: theme.spacing(2),
        textTransform: 'capitalize',
        '& .MuiTypography-body1': {
            fontWeight: 600,
        },
    },
    agentRevenueShareModalListSubItemText: {
        paddingLeft: theme.spacing(2),
        '& .MuiTypography-body1': {
            fontWeight: 400,
        },
    },
}));

export function AgentRevenueShareModal({playerRevenueShare, onSave}: AgentRevenueShareModalProps) {
    const {classes} = useClasses();
    const mapper = useAutoMapper();

    const dateMask = 'yyyy-MM-dd';
    const revenueShareHistory: AgentRevenueShareViewModel[] = getRevenuShareValue();
    const latestRevenueShare: AgentRevenueShareViewModel = revenueShareHistory?.[revenueShareHistory.length - 1];
    const defaultRevenueShare: AgentRevenueShareViewModel = {
        active_since: '',
        casino_share: 0,
        net_deposit_share: 0,
        player_winloss: 0,
        gross_deposit: 0,
        spins: 0,
        poker_mtt: 0,
        sports: 0,
        poker_cash: {
            plo: 0,
            squid: 0,
            allin_fold: 0,
            nlh_sd: 0,
        },
    };

    const {control, handleSubmit} = useForm<AgentRevenueShareViewModel>({defaultValues: latestRevenueShare ?? defaultRevenueShare});

    function getRevenuShareValue(): AgentRevenueShareViewModel[] {
        function sortHistory(first: AgentRevenueShareViewModel, second: AgentRevenueShareViewModel): number {
            const firstDate = parse(first.active_since, dateMask, new Date());
            const secondDate = parse(second.active_since, dateMask, new Date());
            return sortByDate(firstDate, secondDate);
        }

        const revenueShareHistory: AgentRevenueShareViewModel[] = mapper.map(
            playerRevenueShare,
            String,
            nameof<AgentRevenueShareViewModel>()
        );
        return revenueShareHistory?.slice()?.sort(sortHistory) ?? [];
    }

    function handleFormSubmit(data: AgentRevenueShareViewModel) {
        const updatedValue: AgentRevenueShareViewModel = {...data, active_since: format(new Date(), dateMask)};
        const updatedHistory: AgentRevenueShareViewModel[] = [...revenueShareHistory, updatedValue];
        const revenueShareJson = mapper.map(updatedHistory, nameof<AgentRevenueShareViewModel>(), String).toString();
        onSave(revenueShareJson);
    }

    function getPokerCashLabel(key: keyof AgentPokerCashViewModel): MessageDescriptor {
        const localizationMapper: Record<keyof AgentPokerCashViewModel, MessageDescriptor> = {
            plo: localized.agentRevenueSharePlo,
            squid: localized.agentRevenueSharePokerSquid,
            allin_fold: localized.agentRevenueSharePokerAllinFold,
            nlh_sd: localized.agentRevenueSharePokerNlhSd,
        };
        return localizationMapper[key];
    }

    return (
        <Box>
            <form>
                <List classes={{padding: classes.agentRevenueShareModalList}}>
                    <AgentRevenueShareListItem label={localized.agentRevenueSharePokerCash} fieldName={'poker_cash'} />
                    {Object.keys(defaultRevenueShare?.poker_cash).map((key: keyof AgentPokerCashViewModel) => (
                        <AgentRevenueShareListItem
                            label={getPokerCashLabel(key)}
                            fieldName={`poker_cash.${key}`}
                            control={control}
                            secondary
                        />
                    ))}
                    <AgentRevenueShareListItem
                        label={localized.agentRevenueShareCasinoShare}
                        fieldName={'casino_share'}
                        control={control}
                    />
                    <AgentRevenueShareListItem
                        label={localized.agentRevenueShareNetDepositShare}
                        fieldName={'net_deposit_share'}
                        control={control}
                    />
                    <AgentRevenueShareListItem
                        label={localized.agentRevenueSharePlayerWinloss}
                        fieldName={'player_winloss'}
                        control={control}
                    />
                    <AgentRevenueShareListItem
                        label={localized.agentRevenueShareGrossDeposit}
                        fieldName={'gross_deposit'}
                        control={control}
                    />
                    <AgentRevenueShareListItem label={localized.agentRevenueShareSpins} fieldName={'spins'} control={control} />
                    <AgentRevenueShareListItem label={localized.agentRevenueSharePokerMtt} fieldName={'poker_mtt'} control={control} />
                    <AgentRevenueShareListItem label={localized.agentRevenueShareSports} fieldName={'sports'} control={control} />
                </List>
            </form>
            <ModalFooter>
                <Button
                    color="primary"
                    variant="contained"
                    label={localized.agentRevenueShareSaveButtonLabel}
                    onClick={handleSubmit(handleFormSubmit)}
                />
            </ModalFooter>
        </Box>
    );
}

type AgentRevenueShareListItemProps = {
    label: MessageDescriptor;
    fieldName: FieldPath<AgentRevenueShareViewModel>;
    control?: Control<AgentRevenueShareViewModel>;
    secondary?: boolean;
};

function AgentRevenueShareListItem({label, fieldName, control, secondary = false}: AgentRevenueShareListItemProps) {
    const {classes, cx} = useClasses();
    const validationMessageFormatter = useValidationFormatter();
    const {formatMessage} = useContext(IntlContext);

    return (
        <ListItem key={fieldName} className={classes.agentRevenueShareModalListItem}>
            <ListItemText
                className={cx(secondary && classes.agentRevenueShareModalListSubItemText, classes.agentRevenueShareModalListItemText)}
                primary={formatMessage(label)}
            />
            {!control ? null : (
                <Controller
                    render={({field, fieldState}) => (
                        <FormTextInputDefault
                            value={field.value}
                            onChange={field.onChange}
                            fieldState={fieldState}
                            textAlign="right"
                            type="number"
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        ></FormTextInputDefault>
                    )}
                    control={control}
                    name={fieldName}
                    rules={{
                        required: validationMessageFormatter(RuleType.Required, label),
                        pattern: {
                            value: numberRegex,
                            message: validationMessageFormatter(RuleType.Number, label),
                        },
                        min: {
                            value: 0,
                            message: validationMessageFormatter(RuleType.Min, label, 0),
                        },
                        max: {
                            value: 100,
                            message: validationMessageFormatter(RuleType.Max, label, 100),
                        },
                    }}
                />
            )}
        </ListItem>
    );
}
