import React from 'react';
import {useDispatch} from 'react-redux';
import {StyledComponentProps} from '@mui/material/styles';

import {Autocomplete, AutocompleteOptionType} from '@components/autocomplete';
import {SecurityCase, SecurityCaseType} from '@models/generated/graphql';
import {sortByTimestamp} from '@utils';

import {useTempStorageDataLoad} from '../../../module-temp-storage/components/editable-components/hooks';
import {actions} from '../actions';
import {localizedSecurityCases} from '../localize';
import {SecurityCaseEditModel, SecurityCaseFilter} from '../types';

type SecurityCasesAutocompleteProps = {
    userId: string;
    fieldKey: string;
    value: SecurityCaseEditModel;
    onChange?: (value: SecurityCaseEditModel) => void;
    type: SecurityCaseType;
};

export function SecurityCasesAutocomplete({
    value,
    onChange,
    fieldKey,
    type,
    userId,
    classes,
}: SecurityCasesAutocompleteProps & StyledComponentProps) {
    const {isLoading, data: cases} = useTempStorageDataLoad<SecurityCase[]>(fieldKey);
    const dispatch = useDispatch();

    const handleValueChange = (value: AutocompleteOptionType) => {
        const isSuggestedValue = value?.inputValue !== null && value?.inputValue !== undefined;
        onChange({
            type: type,
            case_id: value?.value,
            isNew: isSuggestedValue,
        });
    };

    const handleFilterChange = (value: string) => {
        const filter: SecurityCaseFilter = {type: type, id: value, userId: userId};
        dispatch(
            actions.loadSecurityCases.request({
                dataKey: fieldKey,
                filter,
            })
        );
    };

    const mapper = (v: SecurityCaseEditModel): AutocompleteOptionType => {
        return v ? {label: v.case_id, value: v.case_id, isSuggested: v.isNew} : null;
    };

    const compareOptions = (first: SecurityCase, second: SecurityCase) => {
        return sortByTimestamp(second.opened_at, first.opened_at);
    };

    const sortedCases = cases?.slice()?.sort(compareOptions);
    const casesOptions = sortedCases?.map(mapper);

    return (
        <Autocomplete
            classes={classes}
            loading={isLoading}
            defaultOption={mapper(value)}
            value={value?.case_id}
            options={casesOptions}
            onValueChange={handleValueChange}
            onFilterChange={handleFilterChange}
            suggestAlways
            suggestCreation
            suggestPlaceholder={localizedSecurityCases.createCaseLabel}
            clearOnSuggestionSelect
            triggerFilterChangeOnFirstOpen
            allowEmptyValue
            allowFreeInputValue
        />
    );
}
