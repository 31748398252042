import React, {useMemo, useState} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {defineMessages} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import produce from 'immer';
import {makeStyles} from 'tss-react/mui';
import {RootState} from 'typesafe-actions';

import Button from '@components/button/Buttons';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';
import {ServiceTypesKeys, useService} from '@inversify';
import {CustomTheme} from '@style';

import {
    actionItemsSelector,
    BulkActionItemPayload,
    BulkActionKey,
    bulkActionsActions,
    BulkStepProps,
    IBulkApplyStrategy,
    itemsSelector,
} from '../../block-bulk-actions';
import {BulkBonusAddAmountButton, BulkBonusAddCodeButton, BulkBonusModalTitleBreadcrumb} from '../../block-player-bonus-bulk-actions';
import {localizedBulkModalManualTrigger} from '../../block-player-bonus-bulk-actions/components/BulkBonusApplyForm.localize';
import {ApplyTriggerBonusRequest} from '../services/applyStrategy';
import {BonusTriggerSelectedItems, BonusTriggerViewModel, BonusTriggerViewModelKeys} from '../types';

import {BonusTriggerEditableColumns, DataGridBonusTriggerClient} from './DataGridBonusTrigger';

const localized = defineMessages({
    bulkBonusApplyStepCancel: {
        id: 'BulkBonusApplyStep_Cancel',
        defaultMessage: 'Cancel',
    },
    bulkBonusApplyStepConfirm: {
        id: 'BulkBonusApplyStep_Confirm',
        defaultMessage: 'Confirm',
    },
    bulkBonusApplyStepItemsSelected: {
        id: 'BulkBonusApplyStep_ItemsSelected',
        defaultMessage: '{value} Items Selected',
    },
});

const useClasses = makeStyles()((theme: CustomTheme) => ({
    bulkBonusApplyStepBonusGrid: {
        marginTop: theme.spacing(4),
        display: 'grid',
        rowGap: theme.spacing(2),
    },
    bulkBonusApplyStepToolbar: {
        display: 'flex',
        gap: theme.spacing(2),
    },
}));

type BonusApplyStrategyKey = Extract<
    ServiceTypesKeys,
    'ApplyTriggerMttBonusStrategy' | 'ApplyTriggerImmediateCashBonusStrategy' | 'ApplyTriggerDepositCashMatchBonusStrategy'
>;

type UseBonusApplyStepProps = {
    strategyKey: BonusApplyStrategyKey;
};

type UseBonusApplyStepResult = {
    bonuses: BonusTriggerViewModel[];
    groupBonusesByBonusId: (bonuses: BonusTriggerViewModel[]) => Record<string, BonusTriggerViewModel[]>;
    apply: (bonuses: BonusTriggerViewModel[]) => void;
};

function useBonusApplyStep({strategyKey}: UseBonusApplyStepProps): UseBonusApplyStepResult {
    const dispatch = useDispatch();
    const strategy = useService<IBulkApplyStrategy<ApplyTriggerBonusRequest>>(strategyKey);
    const selectedItems: BonusTriggerSelectedItems[] = useSelector((state: RootState) => itemsSelector<BonusTriggerSelectedItems>(state));
    const actionKeyMap: Record<BonusApplyStrategyKey, BulkActionKey> = {
        ApplyTriggerDepositCashMatchBonusStrategy: BulkActionKey.ManualTriggerDepositCashMatch,
        ApplyTriggerImmediateCashBonusStrategy: BulkActionKey.ManualTriggerImmediateCashBonus,
        ApplyTriggerMttBonusStrategy: BulkActionKey.ManualTriggerMTTTicket,
    };
    const actionItems: BulkActionItemPayload<BonusTriggerViewModel>[] = useSelector(
        (state: RootState) => actionItemsSelector(state, actionKeyMap[strategyKey]) as BulkActionItemPayload<BonusTriggerViewModel>[]
    );
    const bonuses = useMemo(
        () => (actionItems?.length > 0 ? actionItems?.map(i => i.value) : getPlayerBonuses(selectedItems)),
        [selectedItems?.map(i => i.uid)]
    );

    function getPlayerBonuses(selectedUsers: BonusTriggerSelectedItems[]): BonusTriggerViewModel[] {
        const playerBonuses: BonusTriggerViewModel[] = [];

        selectedUsers.forEach(item => {
            const {bonuses: _, ...user} = item;
            item.bonuses.forEach(bonus => {
                const playerBonus: BonusTriggerViewModel = {
                    ...user,
                    id: `${bonus.id}_${user.uid}`,
                    bonus_id: bonus.id,
                    bonus_name: bonus.name,
                };
                playerBonuses.push(playerBonus);
            });
        });

        return playerBonuses;
    }

    function groupBonusesByBonusId(userBonuses: BonusTriggerViewModel[]): Record<string, BonusTriggerViewModel[]> {
        const grouped: Record<string, BonusTriggerViewModel[]> = {};
        userBonuses.forEach(item => {
            if (!grouped[item.bonus_id]) {
                grouped[item.bonus_id] = [item];
            } else {
                grouped[item.bonus_id].push(item);
            }
        });

        return grouped;
    }

    function apply(bonuses: BonusTriggerViewModel[]) {
        const request: ApplyTriggerBonusRequest = {bonuses};
        dispatch(bulkActionsActions.apply({strategy, request}));
    }

    return {groupBonusesByBonusId, apply, bonuses};
}

type BonusFormValues = {
    items: BonusTriggerViewModel[];
};

type BulkBonusEditableColumns = 'ticket_count' | 'marketing_code' | 'total_amount';

type BulkBonusApplyStepProps = BulkStepProps & {
    strategyKey: BonusApplyStrategyKey;
    columns: (BonusTriggerViewModelKeys | BonusTriggerEditableColumns)[];
    editableColumns: BulkBonusEditableColumns[];
};

type BonusTriggerRow = BonusTriggerViewModel & {
    ticket_count_editable?: {
        value?: string;
    };
};

export function BulkBonusApplyStep({onNext, onPrev, editableColumns, columns, strategyKey}: BulkBonusApplyStepProps) {
    const {classes} = useClasses();
    const {apply, groupBonusesByBonusId, bonuses} = useBonusApplyStep({strategyKey: strategyKey});
    const [selectedIds, setSelectedIds] = useState<Record<string, string[]>>({});
    const allSelectedIds = [].concat(...Object.values(selectedIds));
    const isMarketingCodeEditable = editableColumns.includes('marketing_code');
    const isAmountEditable = editableColumns.includes('total_amount');
    const isTicketCountEditable = editableColumns.includes('ticket_count');

    const {control, formState} = useForm<BonusFormValues>({
        defaultValues: {items: bonuses.map(b => ({...b, ticket_count: isTicketCountEditable ? 1 : undefined}))},
    });
    const {fields, replace, update} = useFieldArray({control, name: 'items', keyName: 'formId'});
    const groupedBonuses = groupBonusesByBonusId(fields);

    function handleCancelClick() {
        onPrev();
    }

    function handleNextClick() {
        apply(fields);
        onNext();
    }

    function handleUpdateMarketingCode(code: string) {
        const updatedRows: BonusTriggerViewModel[] = fields.map(f => (allSelectedIds.includes(f.id) ? {...f, marketing_code: code} : f));
        replace(updatedRows);
        setSelectedIds({});
    }

    function handleUpdateAmount(amount: number) {
        const updatedRows: BonusTriggerViewModel[] = fields.map(f => (allSelectedIds.includes(f.id) ? {...f, total_amount: amount} : f));
        replace(updatedRows);
        setSelectedIds({});
    }

    function handleCellValueChange(newRow: BonusTriggerRow, oldRow: BonusTriggerRow, bonus: string): BonusTriggerViewModel {
        const updatedItems: BonusTriggerViewModel[] = produce(groupedBonuses[bonus], draft => {
            const updatedItem: BonusTriggerViewModel = draft?.find(i => i.id === newRow.id);
            const value = Number(newRow.ticket_count_editable.value);
            if (value !== oldRow.ticket_count) {
                updatedItem.ticket_count = value ?? 0;
                const formItemIndex = fields.findIndex(f => f.id === oldRow?.id);
                update(formItemIndex, {...fields[formItemIndex], ticket_count: value ?? 0});
            }
        });
        return updatedItems.find(i => i.id === newRow.id);
    }

    function handleSelect(bonusId: string, newSelectedIds: string[]) {
        const allSelectedIds = {...selectedIds};
        allSelectedIds[bonusId] = newSelectedIds;
        setSelectedIds(allSelectedIds);
    }

    const rowsPerPage = 25;

    return (
        <>
            <BulkModalTitle children={<BulkBonusModalTitleBreadcrumb />} />
            <BulkModalContent>
                <div>
                    <form>
                        {fields.map((item, index) => (
                            <div key={item.uid}>
                                {isMarketingCodeEditable ? (
                                    <Controller
                                        render={({field}) => <input hidden {...field} />}
                                        name={`items.${index}.marketing_code`}
                                        control={control}
                                    />
                                ) : null}
                                {isAmountEditable ? (
                                    <Controller
                                        render={({field}) => <input hidden {...field} />}
                                        name={`items.${index}.total_amount`}
                                        control={control}
                                        rules={{required: true, min: 0.01, validate: (value: number) => Number.isInteger(value)}}
                                    />
                                ) : null}
                                {isTicketCountEditable ? (
                                    <Controller
                                        render={({field}) => <input hidden {...field} />}
                                        name={`items.${index}.ticket_count`}
                                        control={control}
                                        defaultValue={1}
                                        rules={{required: true, min: 1, validate: (value: number) => Number.isInteger(value)}}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </form>
                    <div>
                        <div className={classes.bulkBonusApplyStepToolbar}>
                            {isMarketingCodeEditable ? (
                                <BulkBonusAddCodeButton onSubmit={handleUpdateMarketingCode} disabled={!allSelectedIds.length} />
                            ) : null}
                            {isAmountEditable ? (
                                <BulkBonusAddAmountButton onSubmit={handleUpdateAmount} disabled={!allSelectedIds.length} />
                            ) : null}
                        </div>
                        {Object.keys(groupedBonuses).map(bonus => (
                            <div className={classes.bulkBonusApplyStepBonusGrid}>
                                <Typography variant="h4">{groupedBonuses[bonus]?.[0]?.bonus_name}</Typography>
                                <DataGridBonusTriggerClient
                                    columns={columns}
                                    autoHeight={true}
                                    rows={groupedBonuses[bonus]}
                                    rowsPerPageOptions={[rowsPerPage]}
                                    checkboxSelection={true}
                                    selectedIds={selectedIds[bonus]}
                                    onSelect={selectedIds => handleSelect(bonus, selectedIds)}
                                    hidePagination={rowsPerPage >= groupedBonuses[bonus]?.length}
                                    emptyMessage={localizedBulkModalManualTrigger.bonusEmptyList}
                                    processRowUpdate={(newRow, oldRow) => handleCellValueChange(newRow, oldRow, bonus)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </BulkModalContent>
            <BulkBonusApplyFooter onCancel={handleCancelClick} onConfirm={handleNextClick} isConfirmDisabled={!formState.isValid} />
        </>
    );
}

export function BulkBonusMttApplyStep({onNext, onPrev}: BulkStepProps) {
    return (
        <BulkBonusApplyStep
            onNext={onNext}
            onPrev={onPrev}
            strategyKey="ApplyTriggerMttBonusStrategy"
            columns={[
                'uid',
                'account_status',
                'deposit_status',
                'withdrawal_status',
                'security_cases',
                'marketing_code',
                'ticket_count_editable',
            ]}
            editableColumns={['marketing_code', 'ticket_count']}
        />
    );
}

export function BulkBonusImmediateCashApplyStep({onNext, onPrev}: BulkStepProps) {
    return (
        <BulkBonusApplyStep
            onNext={onNext}
            onPrev={onPrev}
            strategyKey="ApplyTriggerImmediateCashBonusStrategy"
            columns={['uid', 'account_status', 'deposit_status', 'withdrawal_status', 'security_cases', 'marketing_code']}
            editableColumns={['marketing_code']}
        />
    );
}

export function BulkBonusDepositCashMatchApplyMarketingCodeStep({onNext, onPrev}: BulkStepProps) {
    return (
        <BulkBonusApplyStep
            onNext={onNext}
            onPrev={onPrev}
            strategyKey="ApplyTriggerDepositCashMatchBonusStrategy"
            columns={['uid', 'total_amount', 'account_status', 'deposit_status', 'withdrawal_status', 'security_cases', 'marketing_code']}
            editableColumns={['marketing_code']}
        />
    );
}

export function BulkBonusDepositCashMatchApplyTotalAmountStep({onNext, onPrev}: BulkStepProps) {
    return (
        <BulkBonusApplyStep
            onNext={onNext}
            onPrev={onPrev}
            strategyKey="ApplyTriggerDepositCashMatchBonusStrategy"
            columns={['uid', 'total_amount', 'account_status', 'deposit_status', 'withdrawal_status', 'security_cases', 'marketing_code']}
            editableColumns={['total_amount']}
        />
    );
}

type BulkBonusApplyFooterProps = {
    onCancel: () => void;
    onConfirm: () => void;
    isConfirmDisabled: boolean;
};

function BulkBonusApplyFooter({onCancel, onConfirm, isConfirmDisabled}: BulkBonusApplyFooterProps) {
    return (
        <ModalFooter>
            <Button label={localized.bulkBonusApplyStepCancel} onClick={onCancel} />
            <Button color="primary" label={localized.bulkBonusApplyStepConfirm} onClick={onConfirm} disabled={isConfirmDisabled} />
        </ModalFooter>
    );
}
