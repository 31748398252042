import {createAsyncAction} from 'typesafe-actions';

import {BonusCodeInput, MutationDeactivateBonusArgs} from '@models/generated/graphql';
import {AddBonusCodeServiceResponsePayload, DeactivateBonusServiceResponsePayload} from '@services/bonusCodeService';

const domain = 'block-bonus-code';

export const bonusCodeActions = {
    deactivateBonus: createAsyncAction(
        `${domain}/deactivateBonusRequest`,
        `${domain}/deactivateBonusSuccess`,
        `${domain}/deactivateBonusFailure`
    )<MutationDeactivateBonusArgs, DeactivateBonusServiceResponsePayload, DeactivateBonusServiceResponsePayload>(),

    addBonusCode: createAsyncAction(`${domain}/addBonusRequest`, `${domain}/addBonusSuccess`, `${domain}/addBonusFailure`)<
        BonusCodeInput,
        AddBonusCodeServiceResponsePayload,
        AddBonusCodeServiceResponsePayload
    >(),
};
