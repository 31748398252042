import {MessageDescriptor} from 'react-intl';

import {CustomIcon} from '@components/icons/types';

import {SelectOption} from '../../../../module-shared/types';

import {localizedValueRangePicker} from './localizeValueRangePicker';

export enum Operators {
    isLessThan = 'isLessThan',
    isGreaterThan = 'isGreaterThan',
    equals = 'equals',
    between = 'between',
}

export enum ValueRangePickerType {
    Number = 'Number',
    Hours = 'Hours',
}

export type ValueRangePickerOptions = {
    type: ValueRangePickerType;
    rangeLabel: MessageDescriptor;
};

export type ValueRangePickerModel = {
    from: string;
    to: string;
    operator: Operators;
};

export const defaultValueRangeModel: ValueRangePickerModel = {
    operator: Operators.isLessThan,
    from: '',
    to: '',
};

export const valueRangePickerOptions: SelectOption[] = [
    {
        value: Operators.isLessThan,
        label: localizedValueRangePicker.isLessThan,
        startIcon: CustomIcon.LessThan,
    },
    {
        value: Operators.isGreaterThan,
        label: localizedValueRangePicker.isGreaterThan,
        startIcon: CustomIcon.GreaterThan,
    },
    {
        value: Operators.equals,
        label: localizedValueRangePicker.equals,
        startIcon: CustomIcon.Equals,
    },
    {
        value: Operators.between,
        label: localizedValueRangePicker.between,
        startIcon: CustomIcon.Between,
    },
];
