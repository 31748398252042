import React from 'react';
import {FileRejection} from 'react-dropzone';
import {Box} from '@mui/material';

import {OutlinedButton} from '../button/Buttons';

import {EditMode} from './Dropzone';
import {localized} from './DropzoneContent.localize';
import {useDropzoneContentClasses} from './DropzoneContent.style';
import DropzoneContentDefault from './DropzoneContentDefault';
import DropzoneContentFailed from './DropzoneContentFailed';
import DropzoneContentSuccess from './DropzoneContentSuccess';

type DropzoneContentProps = {
    successFile: File;
    rejectFile: FileRejection;
    isDragOrDialogActive: boolean;
    onOpen: () => void;
    editMode: EditMode;
};

export const DropzoneContent = ({successFile, rejectFile, isDragOrDialogActive, onOpen, editMode}: DropzoneContentProps) => {
    const {classes, cx} = useDropzoneContentClasses();

    return (
        <Box
            className={cx(
                editMode === EditMode.Failed ? classes.dropzoneContentReject : '',
                editMode === EditMode.Success ? classes.dropzoneContentSuccess : '',
                classes.dropzoneContentContainer
            )}
        >
            {successFile || rejectFile ? (
                <Box className={classes.dropzoneContentReloadFile}>
                    <OutlinedButton
                        className={classes.dropzoneContentReloadFileButton}
                        label={localized.dropzoneContentReloadFileButton}
                        onClick={onOpen}
                    />
                </Box>
            ) : null}

            {editMode === EditMode.Failed ? (
                <DropzoneContentFailed rejectFile={rejectFile} />
            ) : editMode === EditMode.Success ? (
                <DropzoneContentSuccess file={successFile} />
            ) : (
                <DropzoneContentDefault isDragOrDialogActive={isDragOrDialogActive} onOpen={onOpen} />
            )}
        </Box>
    );
};
