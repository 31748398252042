import React, {useRef} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {makeStyles} from 'tss-react/mui';

import {Button} from '@components/button/Buttons';
import {BulkModalContent, BulkModalTitle, ModalFooter} from '@components/modal';

import {BulkStepProps, itemsSelector, useBulkModalStatus} from '../../block-bulk-actions';
import {BlockPlayerLabelAssignButton} from '../../block-label-group-actions/components/BlockPlayerLabelAssignButton';
import {UserProfileGridItem} from '../../data-grids/types';

import {BulkLabelApplyStepChipList} from './BulkLabelApplyStepChipList';
import {BulkLabelAssignToPlayerForm} from './BulkLabelAssignToPlayerForm';

const localized = defineMessages({
    title: {
        id: 'ManageLabelApplyStep_title',
        defaultMessage: 'Manage Label',
    },
    cancel: {
        id: 'ManageLabelApplyStep_Cancel',
        defaultMessage: 'Cancel',
    },
    confirm: {
        id: 'ManageLabelApplyStep_Confirm',
        defaultMessage: 'Confirm',
    },
    itemsSelected: {
        id: 'ManageLabelApplyStep_ItemsSelected',
        defaultMessage: 'Available labels for {value} selected users',
    },
});

const useClasses = makeStyles()(theme => ({
    bulkApplyStepContainer: {
        position: 'relative',
    },
    bulkApplyStepButton: {
        width: '100%',
    },
    bulkApplyStepRoot: {
        position: 'static !important' as any,
    },
    bulkApplyStepPaper: {
        top: 'initial !important' as any,
        left: '0 !important' as any,
        right: 0,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        maxWidth: 'unset',
    },
    bulkApplyStepList: {
        marginBottom: theme.spacing(2.5),
    },
}));

export const BulkLabelChooseActionStepModal = ({onPrev, onNext}: BulkStepProps) => {
    const {classes} = useClasses();
    const {formatMessage} = useIntl();
    const containerRef = useRef<HTMLDivElement>(null);
    const selectedItems = useSelector(itemsSelector) as UserProfileGridItem[];
    const {hasProcessingItem} = useBulkModalStatus();

    const handleCancelClick = () => {
        onPrev();
    };

    const handleNextClick = () => {
        onNext();
    };

    const totalItemsInfoMessage: string = formatMessage(localized.itemsSelected, {
        value: selectedItems?.length,
    });

    return (
        <>
            <BulkModalTitle title={localized.title} />
            <BulkModalContent subInfoMessage={totalItemsInfoMessage} className={classes.bulkApplyStepContainer}>
                <BulkLabelApplyStepChipList className={classes.bulkApplyStepList} players={selectedItems} />
                <div ref={containerRef}>
                    <BlockPlayerLabelAssignButton
                        className={classes.bulkApplyStepButton}
                        container={containerRef.current}
                        styles={{
                            dropdownRoot: classes.bulkApplyStepRoot,
                            dropdownPaper: classes.bulkApplyStepPaper,
                        }}
                        hideIcon
                    >
                        <BulkLabelAssignToPlayerForm players={selectedItems} />
                    </BlockPlayerLabelAssignButton>
                </div>
            </BulkModalContent>
            <ModalFooter>
                <Button label={localized.cancel} onClick={handleCancelClick} />
                <Button color="primary" label={localized.confirm} onClick={handleNextClick} disabled={!hasProcessingItem} />
            </ModalFooter>
        </>
    );
};
