import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {map} from '@otel';
import {EntityFetchRequestPayload, EntityFetchServiceResponsePayload} from '@redux/entity';
import {IEntityReadService} from '@services/entity';
import {CountriesApiService} from '@services/rest-api';

import {ServiceTypes} from '../inversify';

@injectable()
export class CountriesService implements IEntityReadService {
    private readonly _countriesApiService: CountriesApiService;

    constructor(@inject(ServiceTypes.CountriesApiService) countriesApiService: CountriesApiService) {
        this._countriesApiService = countriesApiService;
    }

    public get(requestPayload: EntityFetchRequestPayload): Observable<EntityFetchServiceResponsePayload> {
        return this._countriesApiService.getCountries().pipe(
            map(response => ({
                ...response,
                requestPayload,
                responsePayload: {items: response?.responsePayload, total: response?.responsePayload?.length},
            }))
        );
    }
}
