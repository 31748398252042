import {makeStyles} from 'tss-react/mui';

export const useLayoutQueueTabsClasses = makeStyles()(() => ({
    layoutQueueTabsContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
    layoutQueueTabsHeader: {},
    layoutQueueTabsBody: {
        display: 'contents',
    },
    layoutQueueTabsFooter: {},
}));
