import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';

import {EntityFilterType, EntityInput, EventLogFilter, EventLogRecord, QueryGetEventLogsArgs} from '@models/generated/graphql';
import {EventLogFilterKeys, EventLogQueryFields, EventLogTextFilterKeys, Filter} from '@redux/entity';
import {EntityBaseGqlService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';

import {ServiceTypes} from '../inversify';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

@injectable()
export class EventLogService extends EntityBaseGqlService<QueryGetEventLogsArgs, EventLogQueryFields, EventLogFilterKeys> {
    constructor(
        @inject(ServiceTypes.ApolloClientIGPMocked) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new EventLogRequestBuilder());
    }
}

export class EventLogRequestBuilder extends GqlRequestBuilder<QueryGetEventLogsArgs, EventLogQueryFields, EventLogFilterKeys> {
    public buildQuery = (fields: EventLogQueryFields[]): DocumentNode => gql`
        query GetEventLogs($filter: EventLogFilter, $sort: Sorting, $start: Int, $end: Int) {
            getEventLogs(filter: $filter, sort: $sort, end: $end, start: $start) {
                items {
                    id ${this.hasQueryItem(fields, 'id')}
                    event_name ${this.hasQueryItem(fields, 'event_name')}
                    event_description ${this.hasQueryItem(fields, 'event_description')}
                    trigger_by ${this.hasQueryItem(fields, 'trigger_by')}
                    trigger_by_id ${this.hasQueryItem(fields, 'trigger_by_id')}
                    trigger_at ${this.hasAnyOfQueryItems(fields, this.getEventLogTriggeredTsQueryItems())} {
                        seconds ${this.hasQueryItem(fields, 'trigger_at.seconds')}
                    }
                    entity ${this.hasAnyOfQueryItems(fields, this.getEventLogEntityQueryItems())} {
                        id ${this.hasQueryItem(fields, 'entity.id')}
                        type ${this.hasQueryItem(fields, 'entity.type')}
                        parent ${this.hasAnyOfQueryItems(fields, this.getEventLogEntityParentQueryItems())} {
                            id ${this.hasQueryItem(fields, 'entity.parent.id')}
                            type ${this.hasQueryItem(fields, 'entity.parent.id')}
                        }
                    }
                    entity_relation_type ${this.hasQueryItem(fields, 'entity_relation_type')}
                    prev_value ${this.hasQueryItem(fields, 'prev_value')}
                    new_value ${this.hasQueryItem(fields, 'new_value')}
                }
                total_count
            }
        }
    `;

    protected buildFilter(filter: Filter<EventLogFilterKeys>): {filter: EventLogFilter} {
        const entityFilter = this.toGQLObjectFilter<EntityInput>(filter, 'entity');
        return {
            filter: {
                text: this.getGQLTextFilter([
                    ...Object.keys(this.filterFieldsMapper).map((key: EventLogTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string)
                    ),
                    //TODO: [IGP-2453] Remove temporary filter for action history
                    {
                        text: '**',
                        search_in: ['trigger_by_id'],
                    },
                ]),
                entity: entityFilter,
                type: filter?.type as EntityFilterType,
                //TODO: [IGP-2868] Hide duplicated action history records by adding side effect filter
                initiator: this.toGQLMultiselectFilter(filter, 'initiator'),
            },
        };
    }

    private getEventLogTriggeredTsQueryItems(): EventLogQueryFields[] {
        return ['trigger_at.seconds'];
    }

    private getEventLogEntityQueryItems(): EventLogQueryFields[] {
        return ['entity.id'];
    }

    private getEventLogEntityParentQueryItems(): EventLogQueryFields[] {
        return ['entity.parent.id', 'entity.parent.type'];
    }

    private filterFieldsMapper: Record<EventLogTextFilterKeys, string[]> = {
        id: nameof.toArray<EventLogRecord>(m => [m.id]),
        event_description: nameof.toArray<EventLogRecord>(m => [m.event_description]),
        event_name: nameof.toArray<EventLogRecord>(m => [m.event_name]),
        entity_relation_type: nameof.toArray<EventLogRecord>(m => [m.entity_relation_type]),
        trigger_by_id: nameof.toArray<EventLogRecord>(m => [m.trigger_by_id]),
        prev_value: nameof.toArray<EventLogRecord>(m => [m.prev_value]),
        new_value: nameof.toArray<EventLogRecord>(m => [m.new_value]),
    };
}
