import {TransactionStatus} from '@models/generated/graphql';
import {StatusLog, TransactionDuration} from '@models/transaction/types';
import {sortByTimestamp, timestampSecondsToMoment} from '@utils';

export function getTransactionDuration({history, started_at}: TransactionDuration) {
    function sortHistory(first: StatusLog, second: StatusLog): number {
        return sortByTimestamp(first.logged_at, second.logged_at);
    }

    const sortedTransactionHistory: StatusLog[] = history ? [...history].sort(sortHistory) : [];

    const startedDate = timestampSecondsToMoment(started_at?.seconds);
    const endDateTimestamp = sortedTransactionHistory.find(
        i => i.status === TransactionStatus.InProgress || i.status === TransactionStatus.Failed || i.status === TransactionStatus.Rejected
    )?.logged_at;
    const endDate = timestampSecondsToMoment(endDateTimestamp?.seconds);

    const formattedValue = endDate ? startedDate?.from(endDate, true) : startedDate?.fromNow(true);
    return formattedValue;
}
