import {ChipType, ChipVariant} from '@components/chip/types';
import {GamePlatform} from '@models/game-template';

import {localizedGamePlatform} from '../../../features/app/intl/shared-resources/gamePlatform';

export function getGamePlatformChipsMapping() {
    return {
        [GamePlatform.Me]: {
            label: localizedGamePlatform[GamePlatform.Me],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Grey,
        },
        [GamePlatform.WPK]: {
            label: localizedGamePlatform[GamePlatform.WPK],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Blue,
        },
        [GamePlatform.BL]: {
            label: localizedGamePlatform[GamePlatform.BL],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.LightBlue,
        },
        [GamePlatform.MemePoker]: {
            label: localizedGamePlatform[GamePlatform.MemePoker],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Bronze,
        },
        [GamePlatform.WPTG]: {
            label: localizedGamePlatform[GamePlatform.WPTG],
            chipType: ChipType.Status,
            chipVariant: ChipVariant.Green,
        },
    };
}
